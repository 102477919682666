import { AppPermissions } from "@core/models/app-permissions.model";
import { AppSections } from "@core/models/app-sections.model";
import { appPermissions } from "@core/store/auth/auth.selectors";
import { select, Store } from "@ngrx/store";
import { AppState } from "@store/reducers";
import { Observable } from "rxjs";

type ApplicationSectionType = keyof AppSections;

export class AppPermissionService {
  appPermissions: AppPermissions;

  constructor(private store: Store<AppState>) {}

  getAppPermissions(): Observable<AppPermissions> {
    return this.store.pipe(select(appPermissions));
  }

  /**
   * Check if section is enabled (status = 1 in DB).
   *
   * @param section section name
   * @returns boolean
   */
  isSectionEnabled(section: ApplicationSectionType): boolean {
    const appSections = JSON.parse(localStorage.getItem("user_app_sections")) as AppSections;
    return appSections[section] || false;
  }

  /**
   * Determines whether the current user's authorized role should use the new role permissions for the specified application section.
   *
   * @param section - The application section to check.
   * @returns `true` if the new role permissions should be used for the specified section, `false` otherwise.
   */
  isSectionUsingNewPermissions(section: ApplicationSectionType): boolean {
    const userData = JSON.parse(localStorage.getItem("user_data"));

    // use new role if:
    // - Authorized role is either 2 (New Role) or 3 (Mixed)
    // - If authorized role is mixed, then application role must be set, and section is enabled
    const useNewRolePermissions = userData.authorizedRole === "application_role" || 
                                  (
                                    userData.authorizedRole === "mixed" &&
                                    userData.application_role_id &&
                                    this.isSectionEnabled(section)
                                  );

    return useNewRolePermissions;
  }
}
