import { Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UploadHttpService } from '@core/services/upload-http.service';
import { MemberBankAccountHttpService } from '@core/services/member-bank-account-http.service';
import { ShowDocumentComponent } from '../../../../member-bank-account-verification/dialogs/show-document/show-document.component';

@Component({
  templateUrl: './reupload-document.component.html',
  styleUrls: ['./reupload-document.component.scss']
})
export class ReuploadDocumentDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;

  form: FormGroup;
  private subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  messages$ = this.memberBankAccountHttpService.messages$;

  fileName: Array<Array<string>> = [];
  isFileUploading: Array<Array<boolean>> = [];
  fields: any;
  maxFileUpload = 10;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bankAccount: any },
    public dialogRef: MatDialogRef<ReuploadDocumentDialogComponent>,
    private memberBankAccountHttpService: MemberBankAccountHttpService,
    private uploadService: UploadHttpService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.formInit();    
    this.fields = this.sortingFieldPosition(this.data.bankAccount.verification_field);
    this.addFieldsToForm(this.fields);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });

    const formData = {
      ...this.form.value
    };
    Object.keys(formData).forEach((key) => (formData[key] == null || formData[key] === '' || formData[key] < 1) && delete formData[key]);
    this.subscription = this.memberBankAccountHttpService.reuploadDocument(formData, this.data.bankAccount.id).pipe(
      tap((res) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        this.dialogRef.close(true);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private addFieldsToForm(fields: any[]) {
    const fieldsArray = this.form.get('fields') as FormArray;
    fieldsArray.clear();

    fields.forEach(field => {
      const fieldGroup = new FormGroup({
        field_id: new FormControl(field.id || null) // Add field_id here
      });

      if (field.type === 1) {
        fieldGroup.addControl('textbox', new FormControl('', Validators.required));
      } else if (field.type === 2) {
        const documentsArray = new FormArray([new FormControl('', Validators.required)], this.requiredFileArrayValidator);
        fieldGroup.addControl('document', documentsArray);
      } 
  
      fieldsArray.push(fieldGroup);
    });

    this.isFileUploading = Array.from({ length: fields.length }, () => []);
  }

  private requiredFileArrayValidator(control: AbstractControl): ValidationErrors | null {
    const array = control as FormArray;
    return array.controls.length === 0 ? { required: true } : null;
  }

  getFieldName(field_details: any[]){
    const defaultLangCode = 'EN';
    const result = field_details.find(item => item.lang_code === defaultLangCode);

    // If found, return the name
    if (result) {
      return result.name;
    }
  }
  
  getFileName(fieldIndex: number, docIndex: number): string {
    return this.fileName[fieldIndex] && this.fileName[fieldIndex][docIndex] ? this.fileName[fieldIndex][docIndex] : 'Choose Files';
  }

  getFieldPosition(field_details: any[]){
    const defaultLangCode = 'EN';
    const result = field_details.find(item => item.lang_code === defaultLangCode);

    return result ? result.position : null;
  }

  sortingFieldPosition(fields: any[]){
    const sortedFields = fields.map(field => {
      const position = this.getFieldPosition(field.field_details); // Use getFieldPosition function

      // If a valid position exists, return the field with position
      if (position !== null) {
        return {
          ...field,
          position // Add position for sorting later
        };
      }
    }).filter(field => field !== undefined) // Filter out undefined results
      .sort((a, b) => a.position - b.position); // Sort by position in ascending order

    return sortedFields;
  }

  addDocument(fieldIndex: number) {
    const fieldsArray = this.form.get('fields') as FormArray;
    const documentArray = fieldsArray.at(fieldIndex).get('document') as FormArray;
    const newDocumentControl = new FormControl('', [Validators.required]);
    if (documentArray.length < this.maxFileUpload) {
      documentArray.push(newDocumentControl);
    }
  }

  removeDocument(fieldIndex: number, docIndex: number) {
    const fieldsArray = this.form.get('fields') as FormArray;
    const documentArray = fieldsArray.at(fieldIndex).get('document') as FormArray;
    documentArray.removeAt(docIndex);
    if (this.fileName[fieldIndex]) {
      this.fileName[fieldIndex].splice(docIndex, 1);
    }
  }

  onUploadFile(event: any, fieldIndex: number, docIndex: number) {
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'uploads');
  
    // Ensure the file uploading state is tracked per field and document index
    if (!this.isFileUploading[fieldIndex]) {
      this.isFileUploading[fieldIndex] = [];
    }
    this.isFileUploading[fieldIndex][docIndex] = true;
  
    this.uploadService.upload(formData).pipe(
      tap(res => {
        const fieldsArray = this.form.get('fields') as FormArray;
        const documentArray = fieldsArray.at(fieldIndex).get('document') as FormArray;
        documentArray.at(docIndex).patchValue(res[0]);
        
        if (!this.fileName[fieldIndex]) {
          this.fileName[fieldIndex] = [];
        }
        this.fileName[fieldIndex][docIndex] = event.target.files[0].name;
        this.isFileUploading[fieldIndex][docIndex] = false;
      }),
      catchError((error) => {
        this.isFileUploading[fieldIndex][docIndex] = false;
        throw error;
      })
    ).subscribe();
  }

  onOpenDialog(mode: string, data?: any) {
    if (mode == 'show-document') {
      if (data.value != '') {
        const documentData = {
          value: data.value
        };
        const documentArray = [documentData];
  
        this.openDialogBy(ShowDocumentComponent, { mode: mode, documents: documentArray });
      }
    }
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, documents?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        documents: data.documents,
        mode: data.mode
      },
      autoFocus: false
    });
  }

  private formInit() {
    this.form = new FormGroup({
      fields: new FormArray([])  // Dynamic fields
    });
  }

}
