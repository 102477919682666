import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy, AfterViewInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Banner } from '@core/models/banner.model';
import { BannerEntityService } from '../../services/banner-entity.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, map, delay, catchError } from 'rxjs/operators';
import { Subscription, forkJoin, of } from 'rxjs';
import { UploadHttpService } from '@core/services/upload-http.service';
import { DatePipe } from '@angular/common';
import { BannerDataService } from '../../services/banner-data.service';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import * as moment from 'moment-timezone';

@Component({
  templateUrl: './banner-edit.component.html',
  styleUrls: ['./banner-edit.component.scss']
})
export class BannerEditDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;
  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  buttonLoading = false;
  form: FormGroup;
  dropdown = {
    statuses:  this.dropdownHttpService.statuses,
    locales: this.dropdownHttpService.locales,
    bannerTypes: this.dropdownHttpService.bannerTypes
  };
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  dateTimeFormat = 'yyyy-MM-dd HH:mm:';
  messages$ = this.bannerDataService.messages$;
  imagePreview = [];
  refreshStatus: boolean;
  dateTimeStack = {
    start_datetime: null,
    end_datetime: null
  };
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  private datePickerSubscription = new Subscription();

  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { banner: Banner, mode: string },
    public dialogRef: MatDialogRef<BannerEditDialogComponent>,
    private bannerService: BannerEntityService,
    private bannerDataService: BannerDataService,
    private dropdownHttpService: DropdownHttpService,
    private uploadService: UploadHttpService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    this.onRefresh();
  }

  ngAfterViewInit(){
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'start_datetime'),
      this.buildDatePicker(1, 'end_datetime')
    ]).subscribe();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onFocusField() {
    of(null).pipe(
        delay(0), tap(() => this.focusfield.first.nativeElement.focus()
    )).subscribe();
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, index === 0 ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.form.patchValue({ [formKey] : date });
        this.onFocusField();
      }),
    );
  }

  onUploadFile(event: any) {
    this.loading = true;
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'banners');
    this.uploadService.upload(formData).subscribe(res => {
      this.imagePreview = res;
      this.form.patchValue({
        image_path: this.imagePreview[0]
      });
      this.form.get('image_path').markAsDirty();
      this.form.markAllAsTouched();
      this.onFocusField();
      this.loading = false;
    });
  }

  onSave(banner: Banner, mode?: string) {
    this.buttonLoading = true;
    const data = {
      id: banner ? banner.id : null,
      ...this.form.value,
      start_datetime: moment(this.form.value.start_datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:00'),
      end_datetime: this.form.value.end_datetime? moment(this.form.value.end_datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:59') : ''
    };
    Object.keys(data).forEach((key) => (data[key] == null || (data[key] === '' && key !== 'end_datetime')) && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.bannerService.update(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.bannerDataService.add(data).pipe(
            tap((res: any) => {
              this.form.setErrors(null);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.bannerDataService.messages$
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    const dateFormat = 'yyyy-MM-dd HH:mm:ss';
    let name = null;
    let bannerTypeId = null;
    let imagePath = this.imagePreview[0];
    let settingLocaleId = null;
    let status = 1;
    let position = 99;
    let link = null;
    let startDate = null;
    let endDate = null;
    let platformTypeId = null;

    if (this.data.mode === 'edit'){
      this.dateTimeStack = {
        start_datetime: new Date(new Date(this.data.banner.start_datetime).getTime() + this.offset - this.clientOffset),
        end_datetime: this.data.banner.end_datetime !== null ? new Date(new Date(this.data.banner.end_datetime).getTime() + this.offset - this.clientOffset) : null,
      };
      name =  this.data.banner.name;
      bannerTypeId = this.data.banner.banner_type_id;
      imagePath = imagePath ? imagePath : this.data.banner.image_path;
      settingLocaleId = this.data.banner.settings_locale_id;
      status =  this.data.banner.status;
      position = this.data.banner.position;
      link = this.data.banner.link;
      startDate = moment(new Date(new Date(this.data.banner.start_datetime).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss');
      endDate = moment(new Date(new Date(this.data.banner.end_datetime).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss');
      platformTypeId = this.data.banner.platform_type_id;
    }
    this.form = new FormGroup({
      banner_type_id: new FormControl(bannerTypeId, [Validators.required]),
      image_path: new FormControl(imagePath, [Validators.required]),
      name: new FormControl(name, [Validators.required]),
      position: new FormControl(position, [Validators.required, Validators.pattern('[0-9]+')]),
      settings_locale_id: new FormControl(settingLocaleId, [Validators.required]),
      status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false }),
      link: new FormControl(link),
      start_datetime: new FormControl(startDate, [Validators.required]),
      end_datetime: new FormControl(endDate),
      platform_type_id: new FormControl(platformTypeId, [Validators.required]),
    });

  }


}
