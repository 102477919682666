<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h5 class="modal-title">Display Settings _ {{ dialogType == 'imageText' ? 'Image/Text' : 'Icons & Quick Links'}} </h5> -->
                <h5 class="modal-title">Display Settings - {{ data.rows.section_name }} </h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="row px-3">
                    <div class="col-3 form-group">
                        <label>Section Name <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-9 form-group">
                        <input type="text" class="form-control" [attr.disabled]="true" [value]="data.rows.section_name">
                    </div>
                </div>
                <div class="row px-3">
                    <div class="col-3 form-group">
                        <label>Section Type <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-9 form-group">
                        <ng-container *ngFor="let value of dropdown.section_types| async">
                            <ng-container *ngIf="value.id == data.rows.section_type_id">
                                <input type="text" class="form-control" [attr.disabled]="true" [value]="value.name">
                            </ng-container>
                        </ng-container>   
                    </div>
                </div>
                <div class="row px-3">
                    <div class="col-3 form-group">
                        <label>Content Type <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-9 form-group">
                        <kt-dropdown-wo-lazyload
                        [form] = 'form'
                        [dropdownList] = 'contentTypeList'
                        [dropdownSettings] = 'contentTypeSettings'
                        [formName] = "'contentType'"
                        [selectionAttributes] = "'code'"
                        [selectedItems] = 'contentTypeSelectedItems'
                        [ngClass]="{'is-invalid':form.get('contentType').errors}">
                        </kt-dropdown-wo-lazyload>
                    </div>    
                </div>
                <div class="row px-3" *ngIf="dialogType == 'imageText'">
                    <div class="col-3 form-group">
                        <label>Redirect Link</label>
                    </div>
                    <div class="col-9 form-group">
                        <input type="text" class="form-control" formControlName="redirect_link">
                    </div>
                    <ng-container *ngIf="languages.length > 0; else elseLoading">
                        <mat-tab-group animationDuration="0ms" class="col-12 form-group" formGroupName="setting_locales">
                            <mat-tab *ngFor="let language of languages; let i = index" [formGroupName]="language.id">
                                <ng-template mat-tab-label>
                                  {{language.code}}
                                </ng-template>
                                <input type="hidden" formControlName="setting_locale_id" [value]="language.id" />
                              
                                <div class="col-12 form-group row">
                                    <label class="col-3 col-form-label">Upload Image</label>
                                    <div class="col-3">
                                        <label class="btn btn-default btn-sm float-left">
                                            <span>Choose file</span>
                                            <input type="file" hidden accept="image/*" (change)="onUploadFile($event, language.id)" />
                                        </label>
                                        <div class="spinner-wrapper" *ngIf="loading">
                                            <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                        </div>
                                    </div>
                                </div>
                
                                <div class="col-12 form-group" *ngIf="getMediaSource(language.id)">
                                    <div class="row">
                                        <label class="col-3 col-form-label">Preview</label>
                                        <div class="col-9">
                                            <div class="image-wrapper">
                                            <img
                                                class="img-fluid" alt="Image Promotion"
                                                [src]="getMediaSource(language.id)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                
                                <div class="col-12 form-group">
                                    <label>Content</label>
                                    <ckeditor #editors [config]="editorConfig" [editor]="editor" formControlName="content"></ckeditor>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </ng-container>
                </div>
                <div class="row px-3" *ngIf="dialogType == 'iconQuickLink'">
                    <div class="text-center m-5 container font-weight-bold">Content Selection</div>
                    <ng-container *ngIf="languages.length > 0; else elseLoading">
                        <div formGroupName="setting_locales" class="col-12 p-0">
                            <div class="col-md-12 form-group" *ngFor="let language of languages; let i = index" [formGroupName]="language.id">
                                <div class="row">
                                    <div class="col-3">
                                        <label class="control-label">{{ language.code }}</label>
                                    </div>
                                    <input type="hidden" formControlName="setting_locale_id" [value]="language.id" />
                                    <div class="col-9 form-group">                        
                                        <kt-dropdown-wo-lazyload-footer
                                        [form] = 'form'
                                        [dropdownList] = 'contentSelectionList'
                                        [dropdownSettings] = 'contentSelectionSettings'
                                        [selectionAttributes] = "'content_selection'"
                                        [Name] = "'setting_locales'"
                                        [Index] = "language.id"
                                        [formName] = "'contentSelection'"
                                        (selectedItemsChanged)="onSelectionChanged($event,language.id)"
                                        [selectedItems] = 'contentSelectionItems[language.id]'>
                                        </kt-dropdown-wo-lazyload-footer>
                                        <p class="selected-selecion-wrapper">
                                            <small *ngFor="let item of contentSelectionItems[language.id]" class="selected-selecion bg-info">
                                                {{ item.combine_name }}
                                                <span class="bg-danger remove" (click)="onRemoveSelection(item.content_selection ,language.id)">x</span>
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <ng-template #elseLoading>
                    <div class="col-md-12 row justify-content-center">
                        <i class="fas fa-circle-notch fa-spin p-0 mr-2"></i>
                    </div>
                </ng-template>  
            </div>    
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button *ngIf="canEditWebsiteDisplaySettings || canEditMobileDisplaySettings" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>