import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription, Subject, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { tap, catchError } from 'rxjs/operators';
import { MessageTemplateDataService } from '@views/pages/apps/superuser/message-template/services/message-template-data.service';
import { AllAffiliatesDataService } from '../../../../all-affiliates/services/all-affiliates-data.service';
import { AffiliateGroupsDataService } from '../../../services/affiliate-groups-data.service';

@Component({
  templateUrl: './affiliate-groups-inbox-bulk-add.component.html',
  styleUrls: ['./affiliate-groups-inbox-bulk-add.component.scss']
})
export class AffiliateGroupsInboxBulkAddComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = this.affiliateGroupsDataService.messages$;
  subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;

  inboxDropdownList = [];
  inboxDropdownListSettings: any;
  inboxListSelectedItems = [];

  affiliateDropdownList = [];
  affiliateDropdownListSettings: any;
  affiliateListSelectedItems = [];
  filteredAffiliateDropdownList = [];

  constructor(
    public dialogRef: MatDialogRef<AffiliateGroupsInboxBulkAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      mode: string, 
      groups: any,
      inboxMessageVisibilitySettings: { inbox_message_affiliate_id: number[], inbox_message_column_visibility: number, inbox_message_toggle: boolean },
    },
    private messageTemplateDataService: MessageTemplateDataService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private affiliateGroupsDataService: AffiliateGroupsDataService,
  ) { }

  ngOnInit() {
    this.inboxDropdownListSettings = {
      autoPosition: false,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'code',
      noDataLabel: '',
      showCheckbox: false,
    };

    this.affiliateDropdownListSettings = {
      autoPosition: false,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'username',
      noDataLabel: '',
      showCheckbox: false,
    };

    this.getDropdown();
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event, refresh = false) {
    this.dialogRef.close(refresh);
  }

  getDropdown() {
    this.inboxDropdownList = [];
    this.messageTemplateDataService.getWithQuery(`?affiliate_group_id=${this.data.groups.id}&is_affiliate_setting=1&paginate=0`).subscribe(res => {
      this.inboxDropdownList = res;
      this.inboxDropdownList = this.inboxDropdownList.filter(x => x.affiliate_group_id == null || x.affiliate_group_id == '');
      this.inboxDropdownList = this.inboxDropdownList.map((x) => {
        return {
          id: x.id,
          name: x.name,
          code: x.code,
          locales: x.locales,
          status: x.status,
          affiliate_group_id: x.affiliate_group_id
        }
      });
    });


    this.allAffiliatesDataService.getWithQuery(`?affiliate_group_id=${this.data.groups.id}&paginate=false`).subscribe(res => {
      this.affiliateDropdownList = res;

      if (this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility == 1) {
        this.affiliateListSelectedItems = this.affiliateDropdownList;
        this.filteredAffiliateDropdownList = this.affiliateDropdownList;
      }
      else {
        this.affiliateListSelectedItems = this.affiliateDropdownList.filter(x => this.data.inboxMessageVisibilitySettings.inbox_message_affiliate_id.includes(x.id));

        if (this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility == 2) {
          this.filteredAffiliateDropdownList = this.affiliateDropdownList.filter(x => this.data.inboxMessageVisibilitySettings.inbox_message_affiliate_id.includes(x.id));
        }
        else {
          this.filteredAffiliateDropdownList = this.affiliateDropdownList;
        }
      }
    });
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    let affiliate_id = this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility == 1 ? [] : this.data.inboxMessageVisibilitySettings.inbox_message_affiliate_id;
    this.form = new FormGroup({
      message_template_id: new FormControl(null, [Validators.required]),
      is_default: new FormControl(1),
      visibility: new FormControl(this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility == 1 ? 1 : this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility),
      affiliate_id: new FormControl(affiliate_id),
    });
  }

  onChangeInboxMessageDefault(event: any) {
    if (event.target.checked) {
      this.form.patchValue({ 
        visibility: this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility,
        affiliate_id: this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility == 1 ? [] : this.data.inboxMessageVisibilitySettings.inbox_message_affiliate_id,
      });
      this.affiliateListSelectedItems = this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility == 1 ? this.affiliateDropdownList : this.affiliateDropdownList.filter(x => this.data.inboxMessageVisibilitySettings.inbox_message_affiliate_id.includes(x.id));
    }
    else {
      if (this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility == 1) {
        this.form.patchValue({ 
          visibility: null,
          affiliate_id: [],
        })
        this.affiliateListSelectedItems = [];
      }
      else {
        this.form.patchValue({ 
          affiliate_id: [],
        })
        this.affiliateListSelectedItems = [];
      }
    }
  }

  onSelectedAffiliateInboxListItems($event: any) {
    if ($event.length == 0) {
      this.affiliateListSelectedItems = [];
    }
    else {
      this.affiliateListSelectedItems = $event;
    }
  }

  onChangeInboxMessageVisbility(event: any) {
    if (event.target.value) {
      this.form.patchValue({ 
        visibility: event.target.value,
      });

      if (event.target.value == 1) {
        this.form.patchValue({ 
          affiliate_id: this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility == 1 ? [] : this.data.inboxMessageVisibilitySettings.inbox_message_affiliate_id,
        });
  
        this.affiliateListSelectedItems = this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility == 1 ? this.affiliateDropdownList : this.affiliateListSelectedItems;
        this.filteredAffiliateDropdownList = this.affiliateDropdownList;
      }
      else {
        if (this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility != 1) {
          this.form.patchValue({ 
            affiliate_id: [],
          });
          
          this.affiliateListSelectedItems = [];
    
          if (event.target.value == 3) {
            this.filteredAffiliateDropdownList = this.affiliateDropdownList;
          }
          else if (event.target.value == 2) {
            this.filteredAffiliateDropdownList = this.affiliateDropdownList.filter(x => this.data.inboxMessageVisibilitySettings.inbox_message_affiliate_id.includes(x.id));
          }
        }
      }
    }
  }

  onSave() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });
    const inboxListData = this.filterFormFields(this.form.value);
    inboxListData['affiliate_group_id'] = this.data.groups.id;

    const inboxMessageVisibilityData = {
      inbox_message_column_visibility: this.data.inboxMessageVisibilitySettings.inbox_message_toggle ? +this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility : 0,
    }
    if (this.data.inboxMessageVisibilitySettings.inbox_message_toggle && +this.data.inboxMessageVisibilitySettings.inbox_message_column_visibility > 1) {
      inboxMessageVisibilityData['affiliate_id'] = this.data.inboxMessageVisibilitySettings.inbox_message_affiliate_id;
    }

    this.subscription = forkJoin([
      this.affiliateGroupsDataService.createBulkInboxMessageVisibilityList(inboxListData),
      this.affiliateGroupsDataService.updateInboxMessageVisibilitySettings(this.data.groups.id, inboxMessageVisibilityData),
    ]).pipe(
      tap(() => {
        this.buttonLoading = false;
        this.form.setErrors(null);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && key !== 'mode') ? fields[key] = formData[key] : key);
    return fields;
  }
}
