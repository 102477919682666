<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Username:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                  <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                              </div>
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Event:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                  <select formControlName="event_id" class="form-control">
                                      <option value="all"> All </option>
                                      <ng-container *ngIf="(events | async) as event">
                                          <option *ngFor="let value of event" [value]="value.id">{{ value.name }}</option>
                                      </ng-container>
                                  </select>
                              </div>
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Status:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                  <select formControlName="status" class="form-control">
                                      <option value="all"> All </option>
                                      <ng-container *ngIf="(dropdown.status | async) as status">
                                          <option *ngFor="let value of status" [value]="value.id">{{ value.name }}</option>
                                      </ng-container>
                                  </select>
                              </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                              <div class="col-md-1 kt-form__label col-form-label">
                                <label>Date/Time:</label>
                              </div>
                              <div class="col-md-5 kt-form__control">
                                <div class="input-group date">
                                    <select formControlName="date_option" class="form-control">
                                        <option value="predicted_at"> Predicted At </option>
                                        <option value="received_at"> Received At </option>
                                  </select>
                                  <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                  <span class="input-group-append">
                                    <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                  </span>
                                </div>
                              </div>
                              <div class="col-md-1 kt-form__label col-form-label">
                                <label>Account Type:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                <select formControlName="account_type_condition" class="form-control" >
                                  <option value="And">AND Condition</option>
                                  <option value="Or">OR Condition</option>
                                </select>
                              </div>
                              <div class="col-md-3 kt-form__control">
                                <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
                                  [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
                                  [formName]="'account_type'" [selectionAttributes]="'id'"
                                  [selectedItems]='accountTypeSelectedItems'>
                                </kt-dropdown-wo-lazyload>
                              </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
                <div>
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canExportPredictionList" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="button_loading"><i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Event</th>
                                    <th>Prediction</th>
                                    <th class="text-center">Status</th>
                                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">Ticket ID</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('received_at')" [ngClass]="sortingConfig.received_at" [class.sort-selected]="sortingSelection.sort_by === 'received_at'">Received At</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Predicted At</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(predictions$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>
                                        <a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog(row.member_account_id)">{{ row.username }} </a>
                                    </td>
                                    <td>{{ row.event_name }}</td>
                                    <td>{{ row.prediction }}</td>
                                    <td class="text-center">
                                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status_id"> {{ row.status_name}}</span>
                                    </td>
                                    <td>{{ row.event_ticket_id }}</td>
                                    <td>{{ row.received_at | date: 'yyyy-MM-dd HH:mm' }}</td>
                                    <td>{{ row.created_at | date: 'yyyy-MM-dd HH:mm' }}</td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
