import { NgModule } from '@angular/core';
import {
  ContentAnimateDirective,
  FirstLetterPipe,
  GetObjectPipe,
  HeaderDirective,
  JoinPipe,
  MenuDirective,
  OffcanvasDirective,
  SafePipe,
  ScrollTopDirective,
  SparklineChartDirective,
  StickyDirective,
  TabClickEventDirective,
  TimeElapsedPipe,
  ToggleDirective
} from './_base/layout';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/auth/auth.effects';
import { authReducer } from './store/auth/auth.reducers';
import { SharedModule } from '@shared/shared.module';
import { DateTimeConversionPipe } from './pipes/date-time-conversion.pipe';
import { TimezoneDatePipe } from './pipes/timezone-date.pipe';
import { AppMotationObserverDirective } from './directive/app-motation-observer.directive';
import { TwoDigitDecimalDirective } from './directive/two-digit-decimal.directive';
import { DefaultValuePipe } from './pipes/default-value.pipe';

const directives = [
  ScrollTopDirective,
  HeaderDirective,
  OffcanvasDirective,
  ToggleDirective,
  MenuDirective,
  TabClickEventDirective,
  SparklineChartDirective,
  ContentAnimateDirective,
  StickyDirective,
  AppMotationObserverDirective,
  TwoDigitDecimalDirective
];

const pipes = [
  TimeElapsedPipe,
  JoinPipe,
  GetObjectPipe,
  SafePipe,
  FirstLetterPipe,
  DateTimeConversionPipe,
  TimezoneDatePipe,
  DefaultValuePipe,
];

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  declarations: [
  ...directives,
  ...pipes,
  ],
  exports: [
  ...directives,
  ...pipes
  ],
  providers: [...pipes]
})
export class CoreModule {
}
