import { DatePipe } from '@angular/common';
import { PromotionCodeEntityService } from './../../../general/promotion-codes/services/promotion-code-entity.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, catchError, map } from 'rxjs/operators';
import { Subscription, forkJoin } from 'rxjs';
import { Status } from '@core/enums/status.enum';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { VipMemberVerificationDataService } from '@views/pages/apps/general/vip-verification/services/vip-member-verification-data.service';
import Swal from "sweetalert2";
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { PromotionCodeDataService } from '@views/pages/apps/general/promotion-codes/services/promotion-code-data.service';
import { MessageTemplateDataService } from '@views/pages/apps/superuser/message-template/services/message-template-data.service';
import { VipSettingsDataService } from '../../settings/services/vip-settings-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { serialMap } from 'chartist';
import { DialogDataService } from '@views/pages/apps/settings/announcements/dialog/services/dialog-data.service';

@Component({
  selector: 'kt-vip-edit',
  templateUrl: './vip-edit.component.html',
  styleUrls: ['./vip-edit.component.scss']
})
export class VipEditDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(OwlDateTimeInputDirective) datePicker: OwlDateTimeInputDirective<any>;
  form: FormGroup;
  messages$ = this.vipMemberVerificationDataService.messages$;
  refreshStatus: boolean;
  buttonLoading = false;
  dropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'labelKey',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false
  };
  dropdown = {
    groups: [],
    reasonTypes: this.dropdownHttpService.reasonTypes,
    smstemplates: this.messageTemplateDataService.getMessageTemplateList(2, 7), // Get sms templates with type = 2 (SMS) and section = 7 (VIPs)
    messagetemplates: this.messageTemplateDataService.getMessageTemplateList(1, 7), // Get message templates with type = 1 (Message) and section = 7 (VIPs)
    statuses: Status,
    //promotionCode: this.data.mode === 'approve' ? this.promotionCodeEntityService.getWithQuery(`?paginate=false&status=1&member_group_id=${this.data.vip.next_member_group_id}&currency_id=${this.data.vip.currency_id}`) : null
  };
  memberDOB = null;
  isMemberDOBPass: boolean;
  isMemberDOBPassReason = '';
  memberGroupId = null;
  nextMemberGroupId = null;
  currencyId = null;
  birthdayPromotionId = null;

  messageTemplateList = [];
  messageTemplateSelectedItem = [];
  smsTemplateList = [];
  smsTemplateSelectedItem = [];

  vipSettings = [];
  filteredMemberGroups = [];

  promotionCodeDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 200, //'auto',
    primaryKey: 'id',
    labelKey: 'name',
    noDataLabel: '',
    showCheckbox: false
  };

  birthdayCodeDropdownSettings = {
    singleSelection: true,
    text: '-',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 200, //'auto',
    primaryKey: 'id',
    labelKey: 'name',
    noDataLabel: '',
    showCheckbox: false,
    disabled: true,
  };

  // Message Templates Dropdown Settings
  messageTemplatesSettings = {
    maxHeight: 200,
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'id',
    labelKey: 'code',
    showCheckbox: false
  };

  promotionCodeDropdownList = [];
  upgradeBonusSelectedItems = [];
  birthdayPromotionSelectedItems = [];

  popupDropdownSettings = {};
  popupDropdownList = [];
  popupDropdownListArray = [];
  popupSelectedItems = [];

  isAdminUser = JSON.parse(localStorage.getItem('user_data')).is_admin;
  roleVersion = JSON.parse(localStorage.getItem('user_data')).authorized_role_version;
  permission = null;

  selectedMember = null;
  currentVipSetting = null;

  private subscription = new Subscription();
  private promoSubscription = new Subscription();
  private datePickerSubscription = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { vip: any, mode: string, editVip: any, member:any},
    private vipMemberVerificationDataService: VipMemberVerificationDataService,
    private dropdownHttpService: DropdownHttpService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<VipEditDialogComponent>,
    private promotionCodeDataService: PromotionCodeDataService,
    private messageTemplateDataService: MessageTemplateDataService,
    private vipSettingsDataService: VipSettingsDataService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
    private dialogDataService: DialogDataService,
  ) { }

  ngOnInit() {
    // const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
    //   if (this.isAdminUser !== 1) {
    //     if (this.roleVersion == 1) {
    //       this.canApproveVip = JSON.parse(localStorage.getItem('user_access'))[8][1]['edit'];
    //     }

    //     if (this.roleVersion == 2) {
    //       this.canApproveVip = appPermissions.approve_vip_upgrade;
    //     }

    //     if (this.roleVersion == 3) {
    //       this.canApproveVip = appPermissions.approve_vip_upgrade == false ? JSON.parse(localStorage.getItem('user_access'))[8][1]['edit'] : appPermissions.approve_vip_upgrade;
    //     }
    //   }
    // });
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      if (this.isAdminUser != 1 && this.roleVersion == 2) {
        this.permission = appPermissions;
      }
    });

    this.formInit();
    this.dropdownHttpService.groups.subscribe(res => {
      this.dropdown.groups = res.filter(x => x.status == 1);
      if (this.data.mode === 'approve' || this.data.mode == 'reject') {
        this.promotionCodeDataService.getWithQuery(`?paginate=false&vip=1&currency_id=${this.data.vip.currency_id}`).subscribe(res => {
          this.promotionCodeDropdownList = res;
          this.promotionCodeDropdownList.map(function (elm) {
              elm['id'] = elm.id;
              elm['name'] = elm.code + ' - ' + elm.name;
              elm['status'] = elm.status;
              elm['valid_from'] = elm.valid_from;
              elm['valid_to'] = elm.valid_to;
          });
        });

        this.vipSettingsDataService.getWithQuery(`?paginate=0&status=1&currency_id=${this.data.vip.currency_id}`).subscribe(async res => {
          this.vipSettings = res;
          await this.changeReasonType(this.data.vip.reason);
          await this.setMessagesDropdown();
          await this.setPopups();

          this.vipMemberVerificationDataService.getVIPSetting(this.data.vip.next_member_group_id, this.currencyId, 1).pipe(
            tap((res) => {
              // Assign the upgrade_promotion_id
              if (res[0] != undefined) {
                this.currentVipSetting = res[0];
                if (res[0].birthday_promotion_id != undefined && +res[0].birthday_promotion_id > 0) {
                  this.form.patchValue({
                    birthday_promotion_id: this.form.value.reason == 2 ? 0 : res[0].birthday_promotion_id,
                  });
                  let selectedPromo = this.promotionCodeDropdownList.find(x => x.id == res[0].birthday_promotion_id);
                  selectedPromo != undefined ? this.birthdayPromotionSelectedItems.push(selectedPromo) : null;
                }

                this.birthdayPromotionId = this.form.value.reason == 2 ? 0 : res[0].birthday_promotion_id;
              }

              let checkMemberGroup = this.filteredMemberGroups.filter(x => x.id == this.data.vip.next_member_group_id);
              if (checkMemberGroup.length > 0) {
                this.form.patchValue({
                  next_member_group_id: this.data.vip.next_member_group_id
                });

                if (this.data.vip.upgrade_promotion_id > 0) {
                  let selectedPromo = this.promotionCodeDropdownList.find(x => x.id == this.data.vip.upgrade_promotion_id);
                  selectedPromo != undefined ? this.upgradeBonusSelectedItems.push(selectedPromo) : null;
                  this.form.patchValue({
                    upgrade_promotion_id: this.data.vip.upgrade_promotion_id
                  })
                }

                if (this.data.vip.message_template_sms_id > 0) {
                  this.smsTemplateSelectedItem = this.smsTemplateList.filter(x => x.id == this.data.vip.message_template_sms_id);
                  this.form.patchValue({
                    message_template_sms_id: this.data.vip.message_template_sms_id
                  })
                }

                if (this.data.vip.message_template_id > 0) {
                  this.messageTemplateSelectedItem = this.messageTemplateList.filter(x => x.id == this.data.vip.message_template_id);
                  this.form.patchValue({
                    message_template_id: this.data.vip.message_template_id
                  })
                }
                
                if (this.data.vip.popup_id > 0) {
                  this.popupSelectedItems = this.popupDropdownListArray.filter(x => x.id == this.data.vip.popup_id);
                  this.form.patchValue({
                    popup_id: this.data.vip.popup_id
                  })
                }
              }
              else {
                this.promotionCodeDropdownList = [];
                this.form.patchValue({
                  next_member_group_id: this.data.mode === 'reject' ? this.data.vip.next_member_group_id : null,
                  message_template_id: null,
                  message_template_sms_id: null,
                });
              }
            }
          )).subscribe();
        });
      }
      else {
        this.setMessagesDropdown();
        this.setPopups();
      }
    })
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  ngAfterViewInit() {
    // if (this.data.mode === 'approve' && this.data.vip.date_of_birth === '0000-00-00') {
    //   this.datePickerSubscription = forkJoin([
    //     this.buildDatePicker(0, 'date_of_birth'),
    //   ]).subscribe();
    // }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(mode?: string) {
    //this.buttonLoading = true;
    const data = {
      ...this.form.value,
      id: mode === 'edit' ? this.data.member.id : mode !== 'create' ? this.data.vip.id : null,
      status: mode === 'reject' ? 2 : this.form.value.status,
      member_group_id: this.memberGroupId,
      remarks: this.form.value.remarks == null || this.form.value.remarks == '' ? '-' : this.form.value.remarks,
      assign_birthday_reward: this.isAllowAssignReward(),
      settings_currency_id: this.currencyId,
    };

    delete data['birthday_promotion_id'];

    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    if (data['date_of_birth'] === '0000-00-00') {
      delete data['date_of_birth'];
    }

    if (data['reason'] == 2) {
      delete data['upgrade_promotion_id'];
    }

    if (mode === 'edit') {
      this.updateVIP(data);
    } else if (mode === 'create' && data['status'] != 1) {
      this.subscription = this.vipMemberVerificationDataService.addVIp(data).pipe(
        tap((res: any) => {
          this.refreshStatus = true;
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else {
      this.onUpdate(mode === 'create' && data['status'] == 1 ? 'approve' : mode, data);
    }
  }

  onUpdate(action: string, data: any) {
    let username = this.data.mode !== 'create' ? this.data.vip.username : this.selectedMember[0].username;
    let actionText = this.data.mode === 'create' ? 'approve' : action;
    Swal.fire({
      title: "Are you sure?",
      text:
        "Do you want to " + actionText + "  " + username + " ?",
      icon: "info",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((resp) => {
      if (resp.value) {
        if (action === "approve") {
          if (this.data.mode === 'create') {
            this.subscription = this.vipMemberVerificationDataService.addVIp(data).pipe(
              tap((res: any) => {
                this.refreshStatus = true;
                this.buttonLoading = false;
              }),
              catchError((error) => {
                this.buttonLoading = false;
                this.form.setErrors(null);
                throw error;
              })
            ).subscribe();
          }
          else {
            this.updateVIP(data);
          }
        } else {
          this.updateVIP(data);
        }
      } else {
        this.buttonLoading = false;
      }
    });
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  async onSelectNextLevel(event: any) {
    this.promoSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.nextMemberGroupId = event;
    this.form.patchValue({ 
      upgrade_promotion_id: null,
      birthday_promotion_id: null,
      message_template_sms_id: null,
      message_template_id: null,
      status: null,
      popup_id: null
    });
    this.smsTemplateSelectedItem = [];
    this.messageTemplateSelectedItem = [];
    this.upgradeBonusSelectedItems = [];
    this.birthdayPromotionSelectedItems = [];
    this.currentVipSetting = null;
    this.popupSelectedItems = [];

    await this.setPromotion(this.nextMemberGroupId, this.currencyId);
    // Get VIP setting based on the member's member group id, setting currency id and upgrade type (type = 1)
    this.subscription = this.vipMemberVerificationDataService.getVIPSetting(event, this.currencyId, 1).pipe(
      tap((res) => {
      // Assign the upgrade_promotion_id
        if (res[0] != undefined) {
          this.currentVipSetting = res[0];
          if (res[0].upgrade_promotion_id != undefined  && +res[0].upgrade_promotion_id > 0) {
            this.form.patchValue({ 
              upgrade_promotion_id: this.form.value.reason == 2 ? 0 : res[0].upgrade_promotion_id,
            });
            let selectedPromo = this.promotionCodeDropdownList.find(x => x.id == res[0].upgrade_promotion_id);
            selectedPromo != undefined ? this.upgradeBonusSelectedItems.push(selectedPromo) : null;
          }

          if (res[0].birthday_promotion_id != undefined && +res[0].birthday_promotion_id > 0) {
            this.form.patchValue({ 
              birthday_promotion_id: this.form.value.reason == 2 ? 0 : res[0].birthday_promotion_id,
            });
            let selectedPromo = this.promotionCodeDropdownList.find(x => x.id == res[0].birthday_promotion_id);
            selectedPromo != undefined ? this.birthdayPromotionSelectedItems.push(selectedPromo) : null;
          }

          this.birthdayPromotionId = this.form.value.reason == 2 ? 0 : res[0].birthday_promotion_id;

          this.form.patchValue({ 
            message_template_sms_id: this.form.value.reason == 2 ? null : res[0].sms_template_id,
            message_template_id: this.form.value.reason == 2 ? null : res[0].message_template_id,
            status: this.data.mode === 'approve' ? 1 : this.data.mode === 'create' && this.form.value.reason == 2 ? 0 : res[0].auto_approve,
            popup_id: this.form.value.reason == 2 ? null : res[0].popup_id,
          });
          this.smsTemplateSelectedItem = this.form.value.reason == 2 ? [] : this.smsTemplateList.filter(x => x.id == res[0].sms_template_id);
          this.messageTemplateSelectedItem = this.form.value.reason == 2 ? [] : this.messageTemplateList.filter(x => x.id == res[0].message_template_id);
          this.popupSelectedItems = this.form.value.reason == 2 ? [] : this.popupDropdownListArray.filter(x => x.id == res[0].popup_id);
        }
      })).subscribe();
  }

  async onSelectedMemberChanged(value) {
    this.selectedMember = value;
    this.currentVipSetting = null;
    this.promoSubscription.unsubscribe();
    this.subscription.unsubscribe();
    if (value.length == 0) {
      this.form.patchValue({
        member_group_id: null,
        date_of_birth: null,
        upgrade_promotion_id: null,
        birthday_promotion_id: null,
        reason: null,
        next_member_group_id: null,
        message_template_sms_id: null,
        message_template_id: null,
        status: this.data.mode === 'approve' ? 1 : null,
      });
      this.memberGroupId = null;
      this.memberDOB = null;
      this.isMemberDOBPass = false;
      this.upgradeBonusSelectedItems = [];
      this.birthdayPromotionSelectedItems = [];
      this.promotionCodeDropdownList = [];
      this.smsTemplateSelectedItem = [];
      this.messageTemplateSelectedItem = [];
      this.filteredMemberGroups = [];
    }
    else {
      await this.setPromotion(this.nextMemberGroupId, this.currencyId);
      this.form.patchValue({ 
        upgrade_promotion_id: null,
        birthday_promotion_id: null,
      });
      this.upgradeBonusSelectedItems = [];
      this.birthdayPromotionSelectedItems = [];
      this.currencyId = value[0].currency_id;
      this.checkMemberAvailableAdjustment(value[0].currency_id);
      this.checkValidDateOfBirth(value[0]);
      this.form.patchValue({
        member_group_id: value[0].member_group_id,
        date_of_birth: value[0].date_of_birth,
        reason: null,
        next_member_group_id: null
      });
      this.memberGroupId = value[0].member_group_id;
      this.filteredMemberGroups = [];
    }
    
  }

  setPopups() {
    return new Promise<void>((resolve, reject) => {
      this.popupDropdownSettings = {
        autoPosition: false,
        singleSelection: true,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'labelKey',
        noDataLabel: '',
        showCheckbox: false
      };

      let contents = [];
      this.dialogDataService.getWithQuery(`?paginate=false`).subscribe(res => {
        res.map(function (elm) {
          elm.contents.forEach(v => contents.push(v));

          let localeTitle = contents.filter(x => x.title != null && x.popup_id == elm.id);
          localeTitle = localeTitle.sort(function(a, b) { 
            return a.locale_id - b.locale_id;
          });

          if (localeTitle.length > 0) {
            let title = (localeTitle[0]['title'].length > 25) ? localeTitle[0]['title'].slice(0, 25 - 1) + ' . . . ' : localeTitle[0]['title'];
            elm['labelKey'] = elm.code + ' (' + title + ')';
          }
          else {
            elm['labelKey'] = elm.code;
          }
        });

        this.popupDropdownList = this.popupDropdownList.concat(res);
        this.popupDropdownList = this.popupDropdownList.map((x) => {
          return {
            id: x.id,
            start_date: x.start_date,
            end_date: x.end_date,
            labelKey: x.labelKey,
            code: x.code,
            status: x.status
          }
        });
        
        this.popupDropdownListArray = this.popupDropdownList;
        resolve();
      })
    });
  }

  private setPromotion(groupId: number, currencyId: number) {
    return new Promise<void>((resolve, reject) => {
      if (this.nextMemberGroupId !== null && this.currencyId !== null) {
        const param = `currency_id=${currencyId}`;
        this.promoSubscription = this.promotionCodeDataService.getWithQuery(`?paginate=false&vip=1&${param}`).subscribe(res => {
          this.promotionCodeDropdownList = res;
          this.promotionCodeDropdownList.map(function (elm) {
              elm['id'] = elm.id;
              elm['name'] = elm.code + ' - ' + elm.name;
              elm['status'] = elm.status;
              elm['valid_from'] = elm.valid_from;
              elm['valid_to'] = elm.valid_to;
          });

          this.form.controls['upgrade_promotion_id'].enable();
          this.form.controls['birthday_promotion_id'].enable();
          resolve();
        });
      }
      else {
        resolve();
      }
    })
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.valueChange.pipe(
      map(res => this.datePipe.transform(res, 'yyyy-MM-dd')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      }),
    );
  }

  private updateVIP(data: any) {
    if (this.data.mode === 'approve') {
      if (data['date_of_birth'] === '0000-00-00') {
        delete data['date_of_birth'];
      }
    }
    
    this.subscription = this.vipMemberVerificationDataService.updateVIp(data).pipe(
      tap((res: any) => {
        this.buttonLoading = false;
        this.refreshStatus = true;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
  }

  private checkValidDateOfBirth(vip: any) {
    const nowDateTime = new Date();

    if (vip.vip_birthday_bonus_status == true) {
      this.isMemberDOBPass = true;
      this.isMemberDOBPassReason = '';
    } else {
      this.isMemberDOBPass = false;
      this.isMemberDOBPassReason = ' - ' + vip.vip_birthday_bonus_reason;
    }

    this.memberDOB = vip.date_of_birth;
  }

  private formInit() {
    let memberAccountId = null;
    let upgradePromotionId = null;
    let birthdayPromotionId = null;
    let messageTemplateId = null;
    let messageTemplateSMSId = null;
    let reason = null;
    let remarks = null;
    let dob = null;
    let popupId = null;

    if (this.data.mode !== 'create') {
      if (this.data.mode === 'edit') {
        memberAccountId = this.data.editVip.id;
        this.nextMemberGroupId = null;
        this.currencyId = this.data.editVip.currency_id;
        dob = this.data.editVip.date_of_birth;
        this.memberGroupId = this.data.editVip.current_group_id;
        if (this.data.editVip.date_of_birth !== '0000-00-00') {
          this.checkValidDateOfBirth(this.data.editVip);
          this.currencyId = this.data.editVip.currency_id;
        }
      } else {
        memberAccountId = this.data.vip.member_account_id;
        this.nextMemberGroupId = this.data.vip.next_member_group_id;
        this.currencyId = this.data.vip.currency_id;
        this.birthdayPromotionId = this.data.vip.birthday_promotion_id;
        messageTemplateId = this.data.vip.message_template_id;
        messageTemplateSMSId = this.data.vip.sms_template_id;
        reason = this.data.vip.reason;
        upgradePromotionId = this.data.vip.upgrade_promotion_id > 0 ? this.data.vip.upgrade_promotion_id : null;
        birthdayPromotionId = null;
        remarks = this.data.vip.remarks;
        dob = this.data.vip.date_of_birth;
        popupId = this.data.vip.popup_id;
        this.memberGroupId = this.data.vip.member_group_id;
        if (this.data.vip.date_of_birth !== '0000-00-00') {
          this.checkValidDateOfBirth(this.data.vip);
          this.currencyId = this.data.vip.currency_id;
        }
      }
    }

    this.form = new FormGroup({
      member_account_id: new FormControl(memberAccountId, [Validators.required]),
      member_group_id: new FormControl({ value: this.memberGroupId, disabled: true }),
      next_member_group_id: new FormControl(null, [Validators.required]),
      upgrade_promotion_id: new FormControl({ value: upgradePromotionId, disabled: this.data.mode === 'edit' || (this.nextMemberGroupId === null && this.currencyId === null) ? true : false }),
      birthday_promotion_id: new FormControl({ value: null, disabled: true }),
      date_of_birth: new FormControl(dob),
      remarks: new FormControl(this.data.mode === 'reject' ? null : remarks, this.data.mode === 'reject' ? [Validators.required] : null),
      message_template_sms_id: new FormControl(null),
      message_template_id: new FormControl(null),
      reason: new FormControl(reason, [Validators.required]),
      popup_id: new FormControl(null),
      status: new FormControl(this.data.mode === 'approve' ? 1 : null, Validators.compose(this.data.mode !== 'reject' ? [Validators.required] : null)),
    });
  }

  changeReasonType(type: any) {
    return new Promise<void>((resolve, reject) => {
      this.promoSubscription.unsubscribe();
      this.subscription.unsubscribe();
      this.upgradeBonusSelectedItems = [];
      this.birthdayPromotionSelectedItems = [];
      this.smsTemplateSelectedItem = [];
      this.messageTemplateSelectedItem = [];
      this.popupSelectedItems = [];
      this.form.patchValue({
        upgrade_promotion_id: null,
        birthday_promotion_id: null,
        next_member_group_id: null,
        message_template_sms_id: null,
        message_template_id: null,
        popup_id: null,
        status: this.data.mode === 'approve' ? 1 : null,
      });

      let filteredVipSettingsGroupIds = null;
      let searchLevelId = [];
      let downgradeIds = [];
      let upgradeIds = [];
      let searchId = [];
      let noDownUpLevelIds = [];
      this.currentVipSetting = null;
      if (this.form.value.member_account_id != null) {
        switch(+type) {
          case 1:
            searchLevelId = Array.from(new Set(this.vipSettings.filter(x => x.member_group_id == this.memberGroupId && x.next_vip_upgrade_id != null && x.status == 1).map((item: any) => item.next_vip_upgrade_id))) ?? [];
            upgradeIds = searchLevelId.length ? searchLevelId : [];

            let defaultGroup = this.dropdown.groups.filter(x => x.default == 1)[0];

            if (upgradeIds.length == 0 && this.memberGroupId == defaultGroup['id']) { // default group
              searchLevelId = Array.from(new Set(this.vipSettings.filter(x => x.upgrade_requirement_type_id == 2 && x.trial == 0 && x.status == 1).map((item: any) => item.member_group_id))) ?? [];
              upgradeIds = searchLevelId.length ? searchLevelId : [];
            }
            
            while(searchLevelId.length > 0) {
              searchId = Array.from(new Set(this.vipSettings.filter(x => searchLevelId.includes(x.member_group_id) && x.status == 1).map((item: any) => item.next_vip_upgrade_id))) ?? [];
              if (searchId.length > 0) {
                searchLevelId = Object.assign([], searchId);
                upgradeIds = Array.from(new Set(upgradeIds.concat(searchId))).filter(function(el) { return el; });
              }
              else {
                searchLevelId = [];
              }
            } 
            this.filteredMemberGroups = this.dropdown.groups.filter(x => upgradeIds.includes(x.id));
            break;
          case 2:
            searchLevelId = Array.from(new Set(this.vipSettings.filter(x => x.next_vip_upgrade_id == this.memberGroupId && x.status == 1).map((item: any) => item.member_group_id))) ?? [];
            downgradeIds = searchLevelId.length ? searchLevelId : [];
            while(searchLevelId.length > 0) {
              searchId = Array.from(new Set(this.vipSettings.filter(x => searchLevelId.includes(x.next_vip_upgrade_id) && x.status == 1).map((item: any) => item.member_group_id))) ?? [];
              if (searchId.length > 0) {
                searchLevelId = Object.assign([], searchId);
                downgradeIds = Array.from(new Set(downgradeIds.concat(searchId))).filter(function(el) { return el; });
              }
              else {
                searchLevelId = [];
              }
            } 
            this.filteredMemberGroups = this.dropdown.groups.filter(x => downgradeIds.includes(x.id));
            break;
          case 3:
            filteredVipSettingsGroupIds = Array.from(new Set(this.vipSettings.filter(x => x.next_vip_upgrade_id == null).map((item: any) => item.member_group_id))) ?? [];
            let existDownUpLevelIds = Array.from(new Set(this.vipSettings.filter(x => filteredVipSettingsGroupIds.includes(x.next_vip_upgrade_id)).map((item: any) => item.next_vip_upgrade_id))) ?? [];
            noDownUpLevelIds = filteredVipSettingsGroupIds.filter(x => !existDownUpLevelIds.includes(x));
            if (noDownUpLevelIds.includes(this.memberGroupId)) {
              let availableMemberGroupIds = this.vipSettings.map((item: any) => item.member_group_id);
              this.filteredMemberGroups = this.dropdown.groups.filter(x => availableMemberGroupIds.includes(x.id) && x.id != this.memberGroupId);
            }
            else {
              this.filteredMemberGroups = this.dropdown.groups.filter(x => noDownUpLevelIds.includes(x.id) && x.id != this.memberGroupId);
            }

            break;
        }
        resolve();
      }
      else {
        resolve();
      }
    });
  }

  private setMessagesDropdown() {
    return new Promise<void>((resolve, reject) => {
      this.dropdown.smstemplates.subscribe(res => {
        this.smsTemplateList = res;
        this.dropdown.messagetemplates.subscribe(res => {
          this.messageTemplateList = res;
          resolve();
        });
      });
    });
  }

  checkMemberAvailableAdjustment(currency_id: any) {
    this.vipSettingsDataService.getWithQuery(`?paginate=0&status=1&currency_id=${currency_id}`).subscribe(res => {
      this.vipSettings = res;
    });
  }
  
  checkValidity(data: any, type: any) {
    switch (type) {
      case 1: // upgrade bonus
        if (data != undefined && data.length > 0) {
          if (data[0]['status'] == 0) {
            return false;
          }
          else {
            var today = new Date();
            var start_date = new Date(data[0]['valid_from']);

            if (data[0]['valid_to'] != null) {
              var end_date = new Date(data[0]['valid_to']);
              if (today > end_date || today < start_date) {
                return false;
              }
              else {
                return true;
              }
            }
            else if (today < start_date) {
              return false;
            }
            else {
              return true;
            }
          }
        }
        else {
          return true;
        }
      case 2: // message or sms message
        if (data != undefined && data.length > 0) {
          if (data[0]['status'] == 0) {
            return false;
          }
          else {
            return true;
          }
        }
        else {
          return true;
        }
      case 3: // dialog popup
          if (data != undefined && data.length > 0) {
              if (data[0]['status'] == 0) {
                  return false;
              }
              else {
                  var today = new Date();
                  var start_date = new Date(data[0]['start_date']);

                  if (data[0]['end_date'] != null) {
                      var end_date = new Date(data[0]['end_date']);
                      if (today > end_date || today < start_date) {
                          return false;
                      }
                      else {
                          return true;
                      }
                  }
                  else if (today < start_date) {
                      return false;
                  }
                  else {
                      return true;
                  }
              }
          }
          else {
              return true;
          }
    }

  }

  checkAllValidity() {
    if (!this.checkValidity(this.upgradeBonusSelectedItems, 1) ||
      !this.checkValidity(this.birthdayPromotionSelectedItems, 1) ||
      !this.checkValidity(this.smsTemplateSelectedItem, 2) ||
      !this.checkValidity(this.messageTemplateSelectedItem, 2) ||
      !this.checkValidity(this.popupSelectedItems, 3)) {
      return false;
    }
    else {
      return true;
    }
  }

  isAllowAssignReward() {
    if (this.birthdayPromotionId == 0 || this.birthdayPromotionId == null || this.memberDOB == null || this.memberDOB == '0000-00-00') {
      return 0;
    }
    else {
      if (this.isMemberDOBPass && this.memberDOB != null && this.memberDOB != '0000-00-00' && this.isMemberDOBPassReason == '') {
        return 1;
      }
      else {
        return 0;
      }
    }
  }
}
