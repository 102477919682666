import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { MarketingWeeklyReport } from '@core/models/marketing-weekly-report.model';

@Injectable()
export class MarketingWeeklyReportEntityService extends EntityCollectionServiceBase<MarketingWeeklyReport> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('WeeklyMonthlyReport', serviceElementsFactory);
  }
}
