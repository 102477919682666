<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create'}} Lucky Draw Settings</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
          <div class="row mt-2">
            <section class="col-lg-12 card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Lucky Draw Settings
                </div>
                <section class="col-12 p-4">
                  <div class="row">
                    <div class="col-md-6 form-group">
                        <label class="control-label">Currency <span class="text-danger">*</span></label>
                        <select class="form-control" formControlName="currency_id">
                            <option [value]="null" [disabled]="true">Please Select</option>
                            <ng-container *ngFor="let value of dropdown.currencies">
                                <option [value]="value.id">{{ value.name }}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="control-label">Name <span class="text-danger">*</span></label>
                        <input type="text" formControlName="name" class="col-12 form-control">
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="control-label">Entry Amount<span class="text-danger">*</span></label>
                        <input type="number" (wheel)="false" formControlName="entry_amount" min="1" class="col-12 form-control">
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="control-label">Max Entry Per Deposit<span class="text-danger">*</span></label>
                        <input type="number" (wheel)="false" formControlName="max_amount_per_entry" min="1" class="col-12 form-control">
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="control-label">Start Date <span class="text-danger">*</span></label>
                        <div class="input-group date">
                            <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack?.start_datetime" [ngModelOptions]="{standalone: true}">
                            <input type="text" formControlName="start_datetime" class="form-control">
                            <span class="input-group-append" [owlDateTimeTrigger]="dateStartRef">
                                <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                            </span>
                            <owl-date-time #dateStartRef></owl-date-time>
                        </div>
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="control-label">End Date <span class="text-danger">*</span></label>
                        <div class="input-group date">
                            <input class="hidden" [owlDateTime]="dateEndRef" [ngModel]="dateTimeStack?.end_datetime" [ngModelOptions]="{standalone: true}">
                            <input type="text" formControlName="end_datetime" class="form-control">
                            <span class="input-group-append" [owlDateTimeTrigger]="dateEndRef">
                                <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                            </span>
                            <owl-date-time #dateEndRef></owl-date-time>
                        </div>
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="control-label">Publish Date <span class="text-danger">*</span></label>
                        <div class="input-group date">
                            <input class="hidden" [owlDateTime]="publishStart" [ngModel]="dateTimeStack?.publish_start" [ngModelOptions]="{standalone: true}">
                            <input type="text" formControlName="publish_start" class="form-control">
                            <span class="input-group-append" [owlDateTimeTrigger]="publishStart">
                                <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                            </span>
                            <owl-date-time #publishStart></owl-date-time>
                        </div>
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="control-label">Unpublish Date <span class="text-danger">*</span></label>
                        <div class="input-group date">
                            <input class="hidden" [owlDateTime]="publishEnd" [ngModel]="dateTimeStack?.publish_end" [ngModelOptions]="{standalone: true}">
                            <input type="text" formControlName="publish_end" class="form-control">
                            <span class="input-group-append" [owlDateTimeTrigger]="publishEnd">
                                <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                            </span>
                            <owl-date-time #publishEnd></owl-date-time>
                        </div>
                    </div>
                    <div class="col-md-6 form-group mb-0" *ngIf="data.mode === 'edit'">
                        <label class="control-label">Status </label>
                        <select class="form-control" formControlName="status">
                          <option [value]="null" [disabled]="true">Please Select</option>
                          <ng-container *ngFor="let item of status | keyvalue">
                            <option [value]="item.key" *ngIf="+item.key >= 0">{{ item.value }}</option>
                          </ng-container>
                      </select>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>

          <div class="row mt-2">
            <section class="col-lg-12 card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Rewards Settings
                </div>
                <section class="col-12 p-4">
                  <div class="kt-section col-12 row d-flex justify-content-end mb-3">
                    <div class="dropdown dropdown-inline">
                      <button *ngIf="canCreateRewardsSettings" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
                    </div>
                </div>
                  <div class="row">
                    <div class="col-md-12 form-group mb-0">
                      <div class="table-responsive">
                          <table class="table table-bordered table-hover table-striped mb-0">
                              <thead>
                                  <tr>
                                      <th>Name</th>
                                      <th>Reward Type</th>
                                      <th class="text-center">Promotion Code / Gift Name</th>
                                      <th class="text-center">Actions</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let item of rewardSettings; let i = index">
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.reward_type_name }}</td>
                                    <td class="text-center">{{ item.reward_type_id === '1' ? item.promotion_code : item.gift_name }}</td>
                                    <td class="text-center">
                                      <button *ngIf="canEditRewardsSettings" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', item, i)">
                                        <i class="fas fa-edit"></i>
                                      </button>
                                      <button *ngIf="canRemoveRewardsSettings" matTooltip="Remove" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onRemoveSetting(i)">
                                        <i class="fas fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button *ngIf="data.mode == 'create' ? canCreateLuckyDrawSettings : canEditLuckyDrawSettings" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.luckyDrawSetting, data.mode)"></kt-submit-button>
        </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
