import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { select, Store } from "@ngrx/store";
import { isLoggedIn } from "../store/auth/auth.selectors";
import { tap } from "rxjs/operators";
import { AppState } from "@store/reducers";
import { SystemSettingDataService } from "@views/pages/apps/superuser/system-setting/services/system-setting-data.service";

@Injectable()
export class TwoFAGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const twoFaVerified = localStorage.getItem("twofaverified") ? localStorage.getItem("twofaverified") : '0';
    const twoFaAccess = localStorage.getItem('twofa_access') == 'true' ? true : false;
    const twoFaFirstSetUp = localStorage.getItem('twoFaFirstSetUp') == 'true' ? true : false;
    const twoFaStatus = JSON.parse(localStorage.getItem('user_data'))?.two_factor_authentication_status;

    if (twoFaStatus == 2 && twoFaAccess) { // Pending for reset
      this.router.navigate(['/twofapending']);
      return false;
    }

    if (!twoFaFirstSetUp && twoFaAccess) {
      this.router.navigate(['/twofafirstsetup']);
      return false;
    }

    if (twoFaVerified == '0' && twoFaAccess) {
      return true;
    } else {
      this.router.navigate(['/dashboard']);
      return false;
    }
  }
}
