<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode === 'create' ? 'Create' : 'Edit'}} Quick Link</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="col-12 form-group row">
                    <label class="col-4 col-form-label">{{ 'Name' }} <span class="text-danger">*</span></label>
                    <input type="text" formControlName="name" class="col-8 form-control">
                </div>

                <div class="col-12 form-group row">
                    <label class="col-4 col-form-label">{{ 'Section Name' }} <span class="text-danger">*</span></label>
                    <kt-dropdown-wo-lazyload #mySelect class="col-8" style="padding:0" [form] = 'form' [dropdownList] = 'sectionDropdownList'
                        [dropdownSettings] = 'sectionDropdownSettings' [formName] = "'section_id'" [dataLoading]="sectionLoading"
                        [selectionAttributes] = "'id'" [selectedItems] = 'sectionSelectedItems' (click)="onChangeSection(mySelect.selectedItems)">
                    </kt-dropdown-wo-lazyload>
                </div>

                <!-- <div class="col-12 form-group row">
                    <label class="col-4 col-form-label">{{ 'Merchant' }} <span class="text-danger">*</span></label>
                    <kt-dropdown-wo-lazyload #mySelect2 class="col-8" style="padding:0" [form]='form' [dropdownList]='merchantDropdownList'
                        [dropdownSettings]='merchantDropdownSettings' [formName]="'merchant_id'" [dataLoading]="merchantLoading"
                        [selectionAttributes]="'id'" [selectedItems]='merchantSelectedItems' (click)="onChangeMerchant(mySelect2.selectedItems)">
                    </kt-dropdown-wo-lazyload>
                </div> -->

                <div class="col-12 form-group row">
                    <label class="col-4 col-form-label"> {{ 'Link' }}<span class="text-danger">*</span><i class="fa fa-info-circle ml-1" placement="right" [ngbTooltip]="linkToolTip" tooltipClass="tooltip-class"></i></label>
                    <input type="text" formControlName="link" class="col-8 form-control" [ngClass]="{'is-invalid': this.form.controls.link.errors?.pattern}">
                    <ng-container *ngIf="this.form.controls.link.errors?.pattern">
                        <div class="col-4"></div>
                        <div class="col-8 text-danger">You may only enter a link that starts with '/'</div>
                    </ng-container>
                </div>
                <ng-template #linkToolTip>
                    <div class="text-left">
                      Internal Link: A hyperlink that points to the other pages on your website. <br>
                      Examples:<br>
                      /promotion?code=CODE0001<br>
                      /referral<br>
                      <br><br>
                      External Link: A hyperlink that points to the target page on other websites.<br>
                      Examples:<br>
                      /external?link=https://example.com/mobileapp.apk<br>
                      /external?link=https://google.com<br>
                    </div>
                </ng-template>
                <div class="col-12 form-group row">
                    <label class="col-4 col-form-label">{{ 'Status' }} <span class="text-danger">*</span></label>
                    <select *ngIf="dropdown.quicklinkStatus" formControlName="status" class="col-8 form-control">
                        <option value="null" disabled>Please Select</option>
                        <option *ngFor="let value of dropdown.quicklinkStatus; let i = index" [value]="i">{{ value }}</option>
                    </select>
                </div>

                <div class="mt-3 mb-3 container">
                    <div class="text-center font-weight-bold">{{ 'Display Name'  }}</div>
                </div>
                <ng-container *ngIf="languages.length > 0; else elseLoading">
                    <div formGroupName="display_name" class="col-12 pr-0 form-group row">
                        <div class="pr-0 col-12 row" *ngFor="let language of languages; let i = index" [formGroupName]="language.id">
                            <div class="col-4">
                                <label class="control-label">{{ language.code }}</label>
                            </div>
                            <div class="col-8 p-0 form-group">
                                <input type="text" formControlName="display_name"  class="col-12 form-control" [attr.disabled]="form.get('display_name.'+language.id+'.same_as_title').value == true ? true : null" >
                                <label class="mt-1"><input type="checkbox" formControlName="same_as_title" (change)="checkSameAsTitle($event , language.id)"> &nbsp;{{ 'Same as Name' }}</label>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #elseLoading>
                    <div class="col-md-12 row justify-content-center" >
                        <i class="fas fa-circle-notch fa-spin p-0 mr-2"></i>
                    </div>
                </ng-template>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button *ngIf="data.mode == 'create' ? canCreateQuickLink : canEditQuickLink" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.rows,data.mode)"></kt-submit-button>
            </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
