<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Release Commission</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div>
                    <div class="col-12 row">
                        <label class="col-lg-3 col-form-label">Remarks</label>
                        <input formControlName="remark" type="text" class="col-lg-9 form-control">
                    </div>
                    <!-- hidden for backup due different affiliate have different conversion rate  -->
                    <!-- <div class="col-12 row">
                        <label class="col-lg-12 col-form-label">Currency Conversion Rates</label>
                        <ng-container *ngIf="(conversionRate$| async) as rates">
                            <ng-container *ngFor="let item of rates">
                                <label class="col-lg-3 col-form-label">{{ item.currency_code }}</label>
                                <input type="text" class="col-lg-9 form-control" [value]="item.conversion_rate" disabled>
                            </ng-container>
                        </ng-container>
                    </div> -->
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <button type="button" class="btn btn-success" (click)="onRelease()"><i class="fas fa-check"></i>Release</button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>