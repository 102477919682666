// Angular
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
// Service
import { AllLeadsDataService } from '../../services/all-leads-data.service';
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { ShowEvidenceComponent } from '../../../my-leads/dialogs/show-evidence/show-evidence.component';  
import { MatDialog } from '@angular/material/dialog';

@Component({
  templateUrl: './lead-history.component.html',
  styleUrls: ['./lead-history.component.scss'],
})
export class LeadHistoryComponent implements OnInit {

    history$: Observable<any>;
    messages$ = this.AllLeadsDataService.messages$;
    loading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { leadID: any},
        public dialogRef: MatDialogRef<LeadHistoryComponent>,
        private AllLeadsDataService: AllLeadsDataService,
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        this.onReload();
    }

    onCloseDialog(event?: Event) {
        this.dialogRef.close();
    }

    onReload() {
        this.history$ = this.AllLeadsDataService.getHistoryById(this.data.leadID).pipe(
        tap(res => {
            this.loading = false;
        }),
        map(res => res),
        catchError(error => {
            this.loading = false;
            // This detect changes is needed to update the table handler
            this.cdr.detectChanges();
            throw (error);
        })
        )
    }

    onOpenDialog(data?: any) {
        this.openDialogBy(ShowEvidenceComponent, { evidences: data });
    }

    
    private openDialogBy(componentRef: any, data?: { evidences?: any }) {
        this.dialog.open(componentRef, {
        width: '800px',
        height: 'auto',
        data: {
            evidences: data.evidences
        },
        autoFocus: false
        });    
    }
}
