
<!--begin::Body-->
<div class="kt-login__body">
  <!--begin::Signin-->
  <div class="kt-login__form">

  <!--begin::Form-->
  <form class="kt-form" [formGroup]="loginForm" autocomplete="off">
    <img class="image" src="/assets/media/logos/quantum-play-white-logo.png" alt="logo">
    <div class="kt-form__control">
      <input type="text" placeholder="Merchant Code" formControlName="merchant_code" autocomplete="off" class="form-control"/>
      <mat-error *ngIf="isControlHasError('merchant_code','required')">
      <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('merchant_code','merchant_code')">
      <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('merchant_code','minlength')">
      <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('merchant_code','maxlength')">
      <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
      </mat-error>

    </div>
    <div class="kt-form__control mt-3">
      <input type="text" placeholder="{{ 'AUTH.INPUT.USERNAME' | translate }}" formControlName="username" (input)="toLowerCaseInput('username', $event)" autocomplete="off" class="form-control" />
      <mat-error *ngIf="isControlHasError('username','required')">
      <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('username','username')">
      <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('username','minlength')">
      <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('username','maxlength')">
      <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
      </mat-error>

    </div>
    <div class="kt-form__control mt-3">
      <input type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off" class="form-control" />
      <mat-error *ngIf="isControlHasError('password','required')">
      <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('password','minlength')">
      <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 6</strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('password','maxlength')">
      <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
      </mat-error>

    </div>
    <!--begin::Action-->
    <div class="kt-login__actions mt-4 d-flex justify-content-center">
      <button class="btn btn-brand btn-block"  [disabled]="!loginForm.valid" (click)="submit()">Login</button>
      <!-- <button (click)="submit()" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary"> Login </button> -->
    </div>
    <!--end::Action-->
  </form>
  <!--end::Form-->


  </div>
  <!--end::Signin-->
</div>
<!--end::Body-->
