import { GameProviderReport } from '@core/models/game-provider-report.model';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Pagination } from '@core/models/pagination.model';
import { CompanyReport } from '@core/models/company-report.model';

@Injectable()
export class CompanyReportDataService extends DefaultDataService<CompanyReport> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  date = this.transactionHttpService.getToday();
  from = this.date.from;
  to = this.date.to;

  constructor(
    http: HttpClient, httpUrlGenerator: HttpUrlGenerator,
    private transactionHttpService: TransactionHttpService
  ) {
    super('CompanyReport', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<CompanyReport[]>{
    return this.http.get<ApiResponse>(`/report/companyreport${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  exportReport(pageParam: string) {
    return this.http.get<ApiResponse>(`/report/exportcompanyreport${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      return this.pagination = res.data.paginations;
    }
  }
}
