import { Component, OnDestroy, OnInit, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RewardStatus } from '@core/enums/reward.enum';
import { forkJoin, Subscription } from 'rxjs';
import { RewardsDataService } from '../../services/rewards-data.service';
import { RewardsHttpService } from '@core/services/rewards-http.service';
import { catchError, tap } from 'rxjs/operators';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';

declare const $: any;

@Component({
  selector: 'kt-import-rewards',
  templateUrl: './import-rewards.component.html',
  styleUrls: ['./import-rewards.component.scss']
})
export class ImportRewardsDialogComponent implements OnInit, OnDestroy {
  @ViewChild('importMessages') importMessages: ElementRef;
  form: FormGroup;
  status = RewardStatus;
  buttonLoading = false;
  checkValidation = false;
  showTextarea = false;
  file: any;
  messages$ = this.rewardsDataService.messages$;
  membersNotFound = [];
  currencyNotAvailable = [];
  memberHaveCampaign = [];
  textareaList: string;
  icon: SweetAlertIcon = 'info';
  refreshStatus: boolean;
  params = `?status=1&paginate=false`;
  rewardsDropdown = [];
  filterRewardsDropdown = [];
  rewardsDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'id',
    labelKey: 'name',
    showCheckbox: false
  };

  dropdown = {
    campaign: this.dropdownHttpService.campaigns,
    currencies: this.dropdownHttpService.currencies,
  };
  showReadOnlyReward = false;
  selectedCampaign: any;
  campaignDropdownList = [];
  selectedRewards: string;

  private subscription = new Subscription();

  @ViewChild('myFile') fileInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ImportRewardsDialogComponent>,
    private rewardsDataService: RewardsDataService,
    private rewardsHttpService: RewardsHttpService,
    private translateService: TranslateService,
    private dropdownHttpService: DropdownHttpService,
    private eventEmitterService: EventEmitterService,
  ) { }

  async ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(event: any) {
    this.checkValidation = true;
    if (this.form.valid) {
      this.buttonLoading = true;
      const formData = new FormData();
      formData.append('attached_file', this.file);
      formData.append('promotion_id', this.form.value.promotion_id);
      formData.append('status', this.form.value.status);
      formData.append('currency_id', this.form.value.currency_id);
      if(this.form.value.campaign_id !== null && this.form.value.campaign_id !== 'null'){
        formData.append('campaign_id', this.form.value.campaign_id);
      }

      if(this.form.value.status == 0 && (this.selectedRewards && this.selectedRewards.length > 0 && this.selectedRewards[0]['auto_approve'] == 0)){
        Swal.fire({
          title: this.translateService.instant('Are you sure?'),
          text: this.translateService.instant('Do you want to create this rewards as status Active'),
          icon: 'info',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          showCancelButton: true,
          confirmButtonText: this.translateService.instant('Yes'),
          cancelButtonText: this.translateService.instant('No'),
          reverseButtons: true
        }).then((resp) => {
          // when close close the popup
          if(resp.dismiss === Swal.DismissReason.backdrop){
            this.buttonLoading = false;
            return;
          } else if(resp.dismiss == Swal.DismissReason.cancel){ //when click button NO
            formData.append('status', '4');// change status to pending approval
            this.onImportReward(formData);
          } else if(resp.value){ //when click button YES
            this.onImportReward(formData);
          }
        });
      } else{
        this.onImportReward(formData);
      }
    }
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onFileUpload(event: any){
    const file: File = event.target.files[0];
    this.file = file;
    this.form.patchValue({attached_file: file});
  }

  onSwal(){
    const nativeElement = this.importMessages.nativeElement;
    nativeElement.style.cssText = 'visibility:visible';
    const textareaNotFound = this.membersNotFound.join('\n');
    const textareaCurrency = this.currencyNotAvailable.join('\n');
    const textareaCampaign = this.memberHaveCampaign.join('\n');
    this.textareaList = [textareaNotFound, textareaCurrency, textareaCampaign].filter(element => element).join('\n\n');
    if(this.textareaList.length !== 0){
      this.showTextarea = true;
    }
    const textareaList = this.textareaList;
    // copy member list to clipboard
    $('#btn-copy').on('click', function() {
      navigator.clipboard.writeText(textareaList);
    });
    Swal.fire({
      title: this.translateService.instant('Import Complete!'),
      html:this.importMessages.nativeElement,
      icon:this.icon,
      heightAuto:false
    }).then(result => {
        if(result) {
          const textArea = $("#NotFound")[0];
          textArea.select();
          navigator.clipboard.writeText(textArea.value);
          this.onCloseDialog();
        }
    });
  }

  onSelectedCampaignChanged(event:any){

    if(this.campaignDropdownList === undefined){
      this.getDropdownCampaign();
    }

    // when null clear the rewards data and bypass other checking
    if(event.target.value === 'null'){ 
      this.showReadOnlyReward = false;
      // set the form data
      if(this.selectedRewards && this.selectedRewards.length > 0){
        this.form.get('promotion_id').setValue(+this.selectedRewards[0]['id']);
      } else{
        this.form.get('promotion_id').setValue(null);
      } 
      return;
    }

    //find the campaign
    this.selectedCampaign = this.campaignDropdownList.find((item) => {
      return item.id === +event.target.value;
    });

    if(this.selectedCampaign !== undefined){
      const rewardData = this.rewardsDropdown.find((reward) => {
                          return reward.code === this.selectedCampaign.promotion_code;
                        });

      // set the form data
      if(rewardData !== undefined){
        this.form.get('promotion_id').setValue(+rewardData.id);
        this.showReadOnlyReward = true;

      } 
      else{
        this.showReadOnlyReward = false;
        //exclude free credit(register) type promotion for campaign (promo_type- 3: free credit, promo_sub_type- 5: register)
        this.filterRewardsDropdown = this.rewardsDropdown.filter(x => !(x.promo_type == 3 && x.promo_sub_type == 5));
        // set the form data
        if(this.selectedRewards && this.selectedRewards.length > 0){
          this.form.get('promotion_id').setValue(+this.selectedRewards[0]['id']);
        } else{
          this.form.get('promotion_id').setValue(null);
        } 
      }                            
    }
  }

  onSelectedItemChange(item) {
    this.selectedRewards = item;
  }

  async onChangeCurrency(currency_id:number){
    this.form.patchValue({
      promotion_id: null,
      status: null,
      campaign_id: null,
    });
    this.showReadOnlyReward = false;
    this.selectedRewards = null;
    const res = await this.rewardsHttpService.getRewardsList(this.params+'&currency_id='+currency_id).toPromise();
    this.rewardsDropdown = res;
    this.rewardsDropdown.map(function (elm) {
      elm['name'] = elm.code + ' - ' + elm.name;
    });
    this.filterRewardsDropdown = this.rewardsDropdown;
    await this.getDropdownCampaign();
    if (this.campaignDropdownList.length > 0) {
      this.campaignDropdownList = this.campaignDropdownList.filter(x => x.currency_id == currency_id);
    }
  }

  onImportReward(formData: any){
    this.refreshStatus = true;
    this.dialogRef.disableClose = true;
    this.rewardsDataService.import(formData).pipe(
      tap((res: any) => {
        this.buttonLoading = false;
        this.membersNotFound = res.members_not_found;
        this.currencyNotAvailable = res.currency_not_available;
        this.memberHaveCampaign = res.member_have_campaign;
        this.dialogRef.disableClose = false;
        this.dialogRef.close(true);
        this.onSwal();
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.dialogRef.disableClose = false;
        this.file = null;
        this.form.patchValue({attached_file: null});
        this.fileInput.nativeElement.value = "";
        throw error;
      })
    ).subscribe();
  }

  private async getDropdownCampaign() {
    return new Promise<void>((resolve) => {
      this.dropdown.campaign.subscribe((data) => {
        this.campaignDropdownList = data;
        resolve();
      });
    });
  }

  private formInit() {
    this.form = new FormGroup({
      promotion_id: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required]),
      attached_file: new FormControl(null, [Validators.required]),
      campaign_id: new FormControl(null),
      currency_id: new FormControl('all', [Validators.required]),
    });
  }

}
