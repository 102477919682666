<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog d-block">
  <form class="kt-form" [formGroup]="form">
    <div class="modal-content">
      <!-- Header -->
      <div class="modal-header">
        <h5 class="modal-title">Set Result - {{ event_name }}</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <!-- Body -->
      <div class="modal-body" *ngIf="(event_info$ | async) as info">
        <div class="row" formArrayName="team">
          <section class="col-md-6 card border-0 form-group" *ngFor="let teamForm of team.controls; let i = index" [formGroupName]="i">
            <div class="col-12 card rounded-0 p-0">
              <div class="card-header">
                {{ info.title_of_placing[i] | titlecase}}
              </div>
              <section class="col-12 px-4 pt-4">
                <!-- Team -->
                <div class="form-group" *ngIf="[1, 3].includes(predictionType)">
                  <label>Team: <span class="text-danger">*</span></label>
                  <select class="form-control" *ngIf="dropdown.teams" formControlName="id" (change)="onSelectedTeamOption(i, $event.target.value)">
                    <option *ngFor="let value of dropdown.teams" [value]="value.id" [attr.disabled] = "selectedTeam.includes(value.id) && value.any_other === 0 ? 'disabled': null">{{ value.team_name }}</option>
                  </select>
                </div>
                <!-- Score -->
                <div class="form-group" *ngIf="[2, 3].includes(predictionType)">
                  <label>Score: <span class="text-danger">*</span></label>
                  <div class="input-group date">
                    <input type="number" (wheel)="false" class="form-control" formControlName="score">
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <section class="col-md-6 card border-0 form-group">
            <div class="col-12 card rounded-0 p-0">
              <div class="card-header">
                Winner Info
              </div>
              <section class="col-12 px-4 pt-4">
                <!-- Total Prize Pool -->
                <div class="form-group">
                  <label>Total Prize Pool: <span class="text-danger">*</span></label>
                  <div class="input-group date">
                    <input type="number" (wheel)="false" class="form-control" formControlName="total_prize_pool" disabled>
                  </div>
                </div>
                <!-- Total Winners -->
                <div class="form-group">
                  <label>Total Winners: <span class="text-danger">*</span></label>
                  <div class="input-group date">
                    <input type="number" (wheel)="false" class="form-control" formControlName="total_winner">
                  </div>
                </div>
              </section>
            </div>
          </section>
          <section class="col-md-6 card border-0 form-group">
            <div class="col-12 card rounded-0 p-0">
              <div class="card-header">
                Result Info
              </div>
              <section class="col-12 px-4 pt-4">
                <!-- Release Date -->
                <div class="form-group">
                  <label>Release Date: <span class="text-danger">*</span></label>
                  <div class="input-group date" [owlDateTimeTrigger]="releaseDateRef" >
                    <input class="hidden" [owlDateTime]="releaseDateRef" [ngModel]="dateTimeStack" [ngModelOptions]="{standalone: true}">
                    <input type="text" formControlName="release_date" class="form-control">
                    <span class="input-group-append">
                        <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                    </span>
                    <owl-date-time #releaseDateRef></owl-date-time>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
      <!-- Footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<!-- Swal Alert -->
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog()"></kt-swal-alert>
