import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { BonusHunterAnalysis } from '@core/models/bonus-hunter-analysis.model';
import { Pagination } from '@core/models/pagination.model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class BonusHunterAnalysisHttpService {

  pagination: Pagination;
  
  constructor(
    private http: HttpClient
  ) { }

  getMemberInfo(pageParam: string): Observable<BonusHunterAnalysis[]> {
    return this.http.get<ApiResponse>(`/bonushunter/memberinfo${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data)
    )
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data?.member_info.paginations;
    }
  }

}
