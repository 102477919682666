import { BetLimitDialogComponent } from './dialogs/bet-limit/bet-limit.component';
import { WithdrawalLimitDialogComponent } from './dialogs/withdrawal-limit/withdrawal-limit.component';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { GroupDataService } from './services/group-data.service';
import { tap, map, exhaustMap } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { GroupEntityService } from './services/group-entity.service';
import { Group } from '@core/models/group.model';
import { Observable, Subscription, interval, of } from 'rxjs';
import { GroupEditDialogComponent } from './dialogs/group-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { SubheaderService } from '@core/_base/layout';
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { Status } from '@core/enums/status.enum';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DepositLimitDialogComponent } from './dialogs/deposit-limit/deposit-limit.component';
import { RebateSettingsDialogComponent } from './dialogs/rebate-settings/rebate-settings.component';
import { AppPermissionService } from '@core/services/app-permission.service';
import { AppPermissions } from '@core/models/app-permissions.model';

@Component({
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    roles: this.dropdownHttpService.roles,
    perPage: this.dropdownHttpService.perPage
  };
  status = Status;
  groups$: Observable<Group[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '1.2';
  sortingConfig = {
    'id': 'desc',
    'code': 'desc',
    'name': 'desc',
    'status': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  // permissions
  canCreateNewGroup: boolean;
  canEditMemberGroup: boolean;
  canViewDepositLimits: boolean;
  canViewWithdrawalLimits: boolean; 
  canViewBetLimit: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private groupEntityService: GroupEntityService,
    private groupDataService: GroupDataService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService
  ) { }

  ngOnInit() {
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));

    this.reload();
    this.formInit();
    this.pagination = this.groupDataService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateNewGroup = appPermissions.create_new_member_group;
      this.canEditMemberGroup = appPermissions.edit_member_group;
      this.canViewDepositLimits = appPermissions.view_deposit_limits;
      this.canViewWithdrawalLimits = appPermissions.view_withdrawal_limits;
      this.canViewBetLimit = appPermissions.view_bet_limit;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.refreshSubcription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    this.loadingBar.start();
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    return this.groups$ = this.groupEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.pagination = this.groupDataService.pagination;
        this.loading = false;
        this.dataLength = res.length;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, memberGroupId?: number, id?: number) {
    if (memberGroupId) {
      if (type === 'edit') {
        const group = this.groupEntityService.getByKey(memberGroupId);
        this.subscription = group.pipe(
          tap((res) => {
            this.openDialogBy(GroupEditDialogComponent, { group: res, mode: 'edit' });
          })
        )
          .subscribe();
      }
      if (type === 'withdrawal-limit') {
        const withdrawLimit = this.groupDataService.getWithdrawLimits(memberGroupId);
        this.subscription = withdrawLimit.pipe(
          tap((res) => {
            this.openDialogBy(WithdrawalLimitDialogComponent, { mode: 'withdrawal-limit', withdrawLimit: res });
          })
        )
          .subscribe();
      }
      if (type === 'deposit-limit') {
        const depositLimit = this.groupDataService.getDepositLimits(memberGroupId);
        this.subscription = depositLimit.pipe(
          tap((res) => {
            this.openDialogBy(DepositLimitDialogComponent, { mode: 'deposit-limit', depositLimit: res });
          })
        )
          .subscribe();
      }
      if (type === 'bet-limit') {
        const betLimit = this.groupDataService.getBetLimits(memberGroupId);
        this.subscription = betLimit.pipe(
          tap((res) => {
            this.openDialogBy(BetLimitDialogComponent, { mode: 'bet-limit', betLimit: res, groupId: memberGroupId });
          })
        )
          .subscribe();
      }
      if (type === 'rebate-settings') {
        this.openDialogBy(RebateSettingsDialogComponent, { mode: 'rebate-settings', groupId: memberGroupId });
      }
    } else {
      this.openDialogBy(GroupEditDialogComponent, { mode: 'create' });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.groups$ = this.groupEntityService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.groupDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private openDialogBy(componentRef: any, data?: { group?: any, mode?: any, withdrawLimit?: any, depositLimit?: any, betLimit?: any, rebateSettings?: any, groupId?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'withdrawal-limit' || data.mode === 'deposit-limit' ? '1200px' : '800px',
      data: {
        group: data.group,
        mode: data.mode,
        withdrawLimit: data.withdrawLimit,
        depositLimit: data.depositLimit,
        betLimit: data.betLimit,
        rebateSettings: data.rebateSettings,
        groupId: data.groupId
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      code: new FormControl(null),
      status: new FormControl('all')
    });
  }

  private refreshEvery(seconds: number) {
    const refreshSource = interval(seconds * 1000);
    refreshSource.subscribe(() => {
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
          this.reload();
        }
      });
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null) ? fields[key] = formData[key] : key);
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

}
