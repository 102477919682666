<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Generate Rebates</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <form class="row align-items-center mb-2" [formGroup]="form">
                    <div class="col-md-6 form-group">
                        <!--<label>Date/Time <span class="text-danger">*</span></label>-->
                        <label>Date <span class="text-danger">*</span></label>
                        <div class="input-group date">
                            <input type="text" class="form-control" (change)="onDateRange($event, 'secondary')"
                                formControlName="defaultDate" [timePicker]="false" [timePickerSeconds]="false"
                                [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true"
                                [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [opens]="'right'"
                                [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd [ngClass]="{'is-invalid': checkValidation && form.controls.start_datetime.errors }" />
                            <span class="input-group-append">
                                <span class="input-group-text pointer-calendar" (click)="onClearDate('secondary')">X</span>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6 form-group">
                        <label>Currency <span class="text-danger">*</span></label>
                        <select class="form-control" formControlName="currency_id" [ngClass]="{'is-invalid': checkValidation && form.controls.currency_id.errors }">
                            <option value="all">All</option>
                            <ng-container *ngFor="let value of dropdown.currencies">
                                <option [value]="value.id">{{ value.name }}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-md-6 form-group">
                        <label>Members </label>
                        <kt-member-dropdown class="dropdown-maxheight"
                            [form]="form"
                            [dropdownSettings]='dropdownSettings'
                            [formName]="'member_account_id'"
                            [selectionAttributes]="'id'">
                        </kt-member-dropdown>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [buttonLoading]="buttonLoading" (confirmed)="onGenerate()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
