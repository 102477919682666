import { AuthHttpService } from '@core/services/auth-http.service';
// Service
import { DropdownHttpService } from "@core/services/dropdown-http.service";
import { TransactionHttpService } from "@core/services/transaction-http.service";
// Model
import { GameTypeReport } from "@core/models/gametype-report.model";
import { Pagination } from "@core/models/pagination.model";
// Angular / Core
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { LoadingBarService } from "@ngx-loading-bar/core";
// Third Party
import { MatDialog } from "@angular/material/dialog";
import { GameProviderHttpService } from "@core/services/game-provider-http.service";
import * as moment from 'moment-timezone';
import { Observable, of, Subscription, throwError } from "rxjs";
import { catchError, map, tap, exhaustMap } from "rxjs/operators";
import { GameProviderReportDetailsComponent } from "../game-provider-report/dialogs/game-provider-report-details.component";
import { GameTypeReportDataService } from "./services/gametype-report-data.service";
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';
import { DateTime } from 'luxon';

@Component({
  selector: "kt-gametype-report",
  templateUrl: "./gametype-report.component.html",
  styleUrls: ["./gametype-report.component.scss"],
})
export class GameTypeReportComponent implements OnInit, OnDestroy {
  form: FormGroup;

  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = ``;
  pagination: Pagination;
  activeOptions = 'game_sub_category';

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  timezoneDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown clear-all-disabled',
    primaryKey: 'offset',
    labelKey: 'offset',
    noDataLabel: '',
    showCheckbox: false
  };
  ranges: any;
  from = this.transactionHttpService.getYesterday().from;
  to = this.transactionHttpService.getToday().to;

  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones')),
    perPage: this.dropdownHttpService.perPage,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    gameProviders: this.gameProviderHttpService.getGameProviders(),
    options: this.dropdownHttpService.options,
    gameCategories: of([]),
    gameSubCategories: of([]),
  };

  usernameDropdownSettings = {};
  gameTypeReport$: Observable<GameTypeReport[]>;
  membersPaginated$ = [];
  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  button_loading = false;
  messages$ = this.gameTypeReportDataService.messages$;
  access$ = this.authHttpService.getUserAccess(5, 'Game Type Report');
  submitted = {
    currencyID: 0,
    startDateTime: null,
    endDateTime: null,
  };
  dataLength: number;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  canExportGameTypeReport: boolean;
  canViewGameTypeBreakdown: boolean;

  lastBetLogSyncAt = null;

  constructor(
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private gameTypeReportDataService: GameTypeReportDataService,
    private transactionHttpService: TransactionHttpService,
    private gameProviderHttpService: GameProviderHttpService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.ranges = this.transactionHttpService.ranges;
    this.pagination = this.gameTypeReportDataService.pagination;
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.selectedTimezone = this.timezone;

    this.usernameDropdownSettings = {
      singleSelection: true,
      text: "Please Select",
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: "dropdown",
      primaryKey: "username",
      labelKey: "username",
      lazyLoading: true,
      noDataLabel: "",
      showCheckbox: false
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canExportGameTypeReport = appPermissions.export_game_type_report;
      this.canViewGameTypeBreakdown = appPermissions.view_game_type_breakdown;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : "";
    this.loadingBar.start();
    return (this.gameTypeReport$ = this.gameTypeReportDataService
      .getWithQuery(`?page=${page}&perPage=${pageSize}${params}`)
      .pipe(
        tap((res) => {
          this.dataLength = res instanceof Object ? Object.keys(res).length : (res as any).length;
          this.pagination = this.gameTypeReportDataService.pagination;
          this.loadingBar.complete();
          this.onSearch = true;
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.getLastBetLogSyncAt();
        }),
        catchError((error) => {
          this.loadingBar.complete();
          this.onSearch = true;
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.getLastBetLogSyncAt();
          return throwError(error);
        })
      ));
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClearDate() {
    if (this.form.value.start_date_time !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }


  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    // To use on openDialog
    this.activeOptions = this.form.value.option;
    this.submitted.currencyID = this.form.value.currency_id;
    this.submitted.startDateTime = moment(this.form.value.defaultDate.startDate).toLocaleString();
    this.submitted.endDateTime = moment(this.form.value.defaultDate.endDate).toLocaleString();

    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        tap((data: any) => {
          if (!this.form.get('currency_id').disabled) {
            if (data['currency_id'] === undefined || data['currency_id'] === null || data['currency_id'] === 0) {
              if (this.dropdown.currencies.length > 0) {
                data['currency_id'] = Object.values(this.dropdown.currencies)[0]['id'];
                this.form.patchValue({ currency_id: data['currency_id'] });
              }
            }
          }
          if (data['start_date_time']) {
            data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
            data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          }
          delete (data['timezone']);

          data = {
            ...data,
            game_provider_code: data.game_provider_code === 'all' ? 'all' : this.extractGameProviderData(data.game_provider_code)?.code
          };
          this.params = Object.keys(data)
            .map((key) => key + '=' + data[key])
            .join('&');
          this.onViewPageBy();
        })
      )
      .subscribe();
  }

  onExport() {
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data: any) => {
        if (!this.form.get('currency_id').disabled) {
          if (data['currency_id'] === undefined || data['currency_id'] === null || data['currency_id'] === 0) {
            if (this.dropdown.currencies.length > 0) {
              data['currency_id'] = Object.values(this.dropdown.currencies)[0]['id'];
              this.form.patchValue({ currency_id: data['currency_id'] });
            }
          }
        }
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);

        data = {
          ...data,
          game_provider_code: data.game_provider_code === 'all' ? 'all' : this.extractGameProviderData(data.game_provider_code)?.code
        };
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        return this.gameTypeReportDataService.exportReport(`?${parameters}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.button_loading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.button_loading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    let default_currency_id: any = null;
    if (this.dropdown.currencies.length > 0) {
      default_currency_id = Object.values(this.dropdown.currencies)[0]['id'];
    }

    this.clearBtnLoading = true;
    this.form.patchValue({
      username: null,
      game_provider_code: 'all',
      game_category_code: 'all',
      sub_category_name: 'all',
      currency_id: default_currency_id,
      start_date_time: null,
      end_date_time: null,
      daily: null,
      option: 'game_sub_category',
      timezone: this.timezone,
      defaultDate: {
        startDate: this.from,
        endDate: this.to
      }
    });
    this.dropdown.gameCategories = of([]);
    this.dropdown.gameSubCategories = of([]);
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.onSubmit(true);
  }

  checkDaily() {

  }

  onGameProviders(e: Event) {
    this.form.patchValue({
      game_category_code: 'all',
      sub_category_name: 'all'
    });
    if ((e.target as HTMLSelectElement).value !== 'all') {
      const data = this.extractGameProviderData((e.target as HTMLSelectElement).value);
      this.dropdown.gameCategories = this.gameProviderHttpService.getGameCategory(+data?.id);
      this.dropdown.gameSubCategories = this.gameProviderHttpService.getSubCategories([data?.code]).pipe(map(res => {
        if (res !== null && res.length >= 1) {
          return res[0].sub_category_name;
        }
      }));
    }
  }

  onCategory(e: Event) {
    this.form.patchValue({
      sub_category_name: 'all'
    });
    let selectedProvider = this.form.get('game_provider_code').value;
    var providerCode = selectedProvider.substring(selectedProvider.indexOf(':') + 1);
    if ((e.target as HTMLSelectElement).value !== 'all') {
      this.dropdown.gameSubCategories = this.gameProviderHttpService.getSubCategories([providerCode], (e.target as HTMLSelectElement).value).pipe(map(res => {
        if (res !== null && res.length >= 1) {
          return res[0].sub_category_name;
        }
      }));
    }
  }

  onOpenDialog(data: GameTypeReport) {
    if (!this.canViewGameTypeBreakdown) {
      Swal.fire('Permission Error', 'You do not have permission to view game type breakdown', 'error');
      return;
    }

    this.dropdown.gameProviders.subscribe((res) => {
      const gameProvider = res.find((x => x.id === data.game_provider_id));
      const param = {
        game_provider_id: gameProvider.id,
        game_provider_code: gameProvider.code,
        game_provider_name: gameProvider.name,
        currency_id: data.currency_id,
        start_date_time: this.submitted.startDateTime,
        end_date_time: this.submitted.endDateTime,
        option: this.form.value.option,
        category_code: data.game_category_code,
        game_sub_category_name: data.game_sub_category_name,
        timezone: this.form.value.timezone,
      }

      const formData: any = {
        value: {
          ...this.form.value,
          currency_id: param.currency_id
        }
      };

      this.openDialogBy(GameProviderReportDetailsComponent, { form: formData, param, type: 'gametype-report' });
    });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private openDialogBy(componentRef: any, data: { form: FormGroup, param: any, type: string }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '90vw',
      height: '90vh',
      data: {
        form: data.form,
        param: data.param,
        type: data.type
      }
    });
  }

  private extractGameProviderData(data: string) {
    if (data !== null || data !== undefined) {
      const result = (data)?.split(':');
      if (result !== null && (result)?.length >= 2) {
        return {
          id: result[0],
          code: result[1]
        };
      }
    }

  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      game_provider_code: new FormControl('all'),
      game_category_code: new FormControl('all'),
      sub_category_name: new FormControl('all'),
      currency_id: new FormControl(null),
      start_date_time: new FormControl(this.from),
      end_date_time: new FormControl(this.to),
      daily: new FormControl(0),
      option: new FormControl('game_sub_category'),
      timezone: new FormControl(this.timezone),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      }) // Do not remove: For Clearing The Range
    });

    this.form.controls.username.valueChanges.subscribe(value => {
      if (value == null || value.length == 0) {
        this.form.controls.currency_id.enable();
      } else {
        this.form.controls.currency_id.disable();
      }
    })
  }

  sanitizeNumericField(previousValue: any) {
    const value = String(previousValue).replace(/[,]+/, "");
    return value;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  getLastBetLogSyncAt(){
    var userTimeZone = JSON.parse(localStorage.getItem('user_data')).timezone;
    var timeInterved = 5; // 5 minutes 
    const d = new Date();
    let minutes = d.getMinutes();
    const intMinutes =  minutes/timeInterved;
    const roundedMinute = (minutes/timeInterved).toFixed(0);
    var minus;
    if( Number(intMinutes) < Number(roundedMinute) || Number(intMinutes) == Number(roundedMinute)){
      if(Number(roundedMinute) > 0) minus = 1;
      else minus = -1;
    }
    else if( Number(intMinutes) > Number(roundedMinute)){
      minus = 0;
    }
    var  minutesConverted5min = ( Number(roundedMinute) - minus )  * timeInterved;
    var newMinutes;
    if(minutesConverted5min < 10){
      newMinutes = '0'+minutesConverted5min.toString();
    }else{
      newMinutes = minutesConverted5min.toString();
    }

    var timeNow =  DateTime.now().setZone(userTimeZone).toFormat('yyyy-MM-dd HH:'+newMinutes+':00 (ZZ)'); 
    this.lastBetLogSyncAt = timeNow.replace('(', '(GMT ');
  }
}
