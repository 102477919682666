import { Injectable } from "@angular/core";
import { DefaultDataService, HttpUrlGenerator } from "@ngrx/data";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { VipMemberVerification } from "@core/models/vip-member-verification.model";
import { ApiResponse } from "@core/models/api-response.model";
import { Pagination } from "@core/models/pagination.model";
import { VipVerificationStatus } from "@core/enums/vip-verification-status.enum";

@Injectable()
export class VipMemberVerificationDataService extends DefaultDataService<VipMemberVerification> {
  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super("VipMemberVerification", http, httpUrlGenerator, { root: "" });
  }

  getWithQuery(pageParam: string): Observable<VipMemberVerification[]> {
    return this.http.get<ApiResponse>(`/vipverification${pageParam}`).pipe(
      tap((res) => this.paginationInit(res)),
      map((res) => {
        return res.data.rows;
      })
    );
  }

  updateStatus(
    id: number,
    status: VipVerificationStatus
  ): Observable<VipMemberVerification> {
    const params = {
      _method: "PUT",
      status: status,
    };
    return this.http.post<ApiResponse>(`/vipverification/${id}`, params).pipe(
      tap((res) => this.messages$.next(res.message)),
      map((res) => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
