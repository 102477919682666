<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Team / Player Settings - {{ data.row.name }} </h5>
            <span class="modal-x-button" (click)="onCloseDialog()"><i class="fa fa-times" aria-hidden="true"></i></span>
        </div>
        <div class="modal-body">
            <div class="col-12">
                <form class="row align-items-center" [formGroup]="form">
                    <div class="col-12 row mb-2 pr-0">
                        <div class="col-md-1 kt-form__label col-form-label">
                            <label>Code:</label>
                        </div>
                        <div class="col-md-2 kt-form__control">
                            <input type="text" formControlName="team_code" placeholder="Search" class="form-control">
                        </div>
                        <div class="col-md-1 kt-form__label col-form-label">
                            <label>Name:</label>
                        </div>
                        <div class="col-md-2 kt-form__control">
                            <input type="text" formControlName="team_name" placeholder="Search" class="form-control">
                        </div>
                        <div class="col-md-1 kt-form__label col-form-label">
                            <label>Group:</label>
                        </div>
                        <div class="col-md-2 kt-form__control">
                            <input type="text" formControlName="group_name" placeholder="Search" class="form-control">
                        </div>
                        <div class="col-md-1 kt-form__label col-form-label">
                            <label>Country:</label>
                        </div>
                        <div class="col-md-2 kt-form__control">
                            <ng-select
                                [items]="countries" bindLabel="name" bindValue="code"
                                formControlName="country_code" placeholder="All">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12 row mb-2 pr-0">
                      <div class="col-md-1 kt-form__label col-form-label">
                          <label>Display:</label>
                      </div>
                      <div class="col-md-2 kt-form__control">
                        <div class="d-flex status-filter-pt">
                          <div class="custom-control custom-checkbox mr-3">
                              <input type="checkbox" class="custom-control-input" (change)="onCheckboxChange()" formControlName="visible_player" id="visible_player" name="visible_player">
                              <label class="custom-control-label text-nowrap" for="visible_player" [ngClass]="{ 'red-border': form.get('visible_player').errors?.required && form.get('hidden_player').errors?.required }">Visible Team / Player</label>
                          </div>
                          <div class="custom-control custom-checkbox mr-3">
                              <input type="checkbox"class="custom-control-input" (change)="onCheckboxChange()" formControlName="hidden_player" id="hidden_player" name="hidden_player">
                              <label class="custom-control-label text-nowrap" for="hidden_player" [ngClass]="{ 'red-border': form.get('visible_player').errors?.required && form.get('hidden_player').errors?.required }">Hidden Team / Player</label>
                          </div>
                        </div>
                      </div>
                    </div>
                </form>
                <div class="kt-section col-12 row d-flex justify-content-between mb-3">
                    <div class="dropdown dropdown-inline">
                        <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading || (form.get('visible_player').errors?.required && form.get('hidden_player').errors?.required)" (search)="onSubmit()"></kt-search-button>
                        <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                    </div>
                    <button *ngIf="canCreateTeamPlayer" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog(data.row.id, data.row.group_type, 'create')"><i class="fas fa-plus"></i>Create</button>
                </div>
                <div class="kt-section mb-0">
                    <div class="kt-section__content">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover table-striped table-sortable">
                                <thead>
                                    <tr>
                                        <th class="sort-enabled" (click)="onSortColumn('team_name')" [ngClass]="sortingConfig.team_name" [class.sort-selected]="sortingSelection.sort_by === 'team_name'" rowspan="2">Name</th>
                                        <th class="sort-enabled" (click)="onSortColumn('team_code')" [ngClass]="sortingConfig.team_code" [class.sort-selected]="sortingSelection.sort_by === 'team_code'" rowspan="2">Code</th>
                                        <th class="sort-enabled" (click)="onSortColumn('position')" [ngClass]="sortingConfig.position" [class.sort-selected]="sortingSelection.sort_by === 'position'" rowspan="2">Position</th>
                                        <th class="sort-enabled" (click)="onSortColumn('current_standings')" [ngClass]="sortingConfig.current_standings" [class.sort-selected]="sortingSelection.sort_by === 'current_standings'" rowspan="2">Standing</th>
                                        <th class="sort-enabled" (click)="onSortColumn('group_name')" [ngClass]="sortingConfig.group_name" [class.sort-selected]="sortingSelection.sort_by === 'group_name'" rowspan="2">Group</th>
                                        <th class="sort-enabled" (click)="onSortColumn('country_code')" [ngClass]="sortingConfig.country_code" [class.sort-selected]="sortingSelection.sort_by === 'country_code'" rowspan="2">Country</th>
                                        <th class="text-center" colspan="2">Preview</th>
                                        <th class="text-center" rowspan="2">Display</th>
                                        <th class="text-center" rowspan="2">Action</th>
                                    </tr>
                                    <tr>
                                        <th class="text-center">Desktop</th>
                                        <th class="text-center">Mobile</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="(teams$ | async) as rows">
                                    <tr *ngFor="let item of rows">
                                        <td>{{ item.team_name }}</td>
                                        <td class="text-center">{{ item.team_code }}</td>
                                        <td class="text-center">{{ item.position }}</td>
                                        <td class="text-center">{{ item.current_standings }}</td>
                                        <td class="text-center">{{ item.group_name }}</td>
                                        <td class="text-center">{{ item.country_name }}</td>
                                        <td class="preview-img text-center">
                                            <ng-container *ngIf="item.logo_desktop !== null; else noImg">
                                                <img [src]="item.logo_desktop">
                                            </ng-container>
                                            <ng-template #noImg>
                                               <span>-</span>
                                            </ng-template>
                                        </td>
                                        <td class="preview-img text-center">
                                            <ng-container *ngIf="item.logo_mobile !== null; else noImg">
                                                <img [src]="item.logo_mobile">
                                            </ng-container>
                                            <ng-template #noImg>
                                                <span>-</span>
                                            </ng-template>
                                        </td>
                                        <td class="text-center">
                                          <div class="custom-control custom-switch">
                                              <input type="checkbox" [disabled]="!canUpdatePlayerDisplayStatus" [(ngModel)]="item.status" class="custom-control-input" (click)="onToggleStatus(item)" [id]="item.id" [checked]="item.status === 1 ? true : false">
                                              <label class="custom-control-label" [for]="item.id"></label>
                                          </div>
                                        </td>
                                        <td class="text-center">
                                            <button *ngIf="canEditTeamPlayer" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog(data.row.id, data.row.group_type, 'edit', item)"><i class="fas fa-edit"></i></button>
                                            <button *ngIf="canRemoveTeamPlayer" matTooltip="Delete" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onDelete(item)"><i class="fas fa-trash"></i></button>
                                        </td>
                                    </tr>
                                    <kt-fallback-row [collection]="rows"></kt-fallback-row>
                                </tbody>
                            </table>
                        </div>
                        <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                                [directionLinks]="true" [boundaryLinks]="true" [rotate]="true"
                                [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                            </ngb-pagination>
                            <div class="kt-pagination__toolbar">
                                <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                                    class="form-control kt-font-brand page-size">
                                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                                </select>
                                <span class="pagination__desc">
                                    Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        </div>
    </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
