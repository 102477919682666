<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Bank List</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center col-12" [formGroup]="form" *ngIf="data.mode === 'edit' && !this.data.new_currency">
                            <div class="col-12 row mb-2 pr-0" >
                                <div class="col-md-2 kt-form__label col-form-label">
                                    <label>E-wallet/Bank:</label>
                                </div>
                                <div class="col-md-4 kt-form__control">
                                    <input type="text" formControlName="bank_name" placeholder="Search" class="form-control">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="kt-section mb-0">
                    <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                        <div class="dropdown dropdown-inline">
                            <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()" *ngIf="data.mode === 'edit' && !this.data.new_currency"></kt-search-button>
                            <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()" *ngIf="data.mode === 'edit' && !this.data.new_currency"></kt-clear-button>
                        </div>
                        <div>
                            <button class="btn btn-brand btn-icon-sm float-right" (click)="onOpenDialog()"><i class="fas fa-plus"></i>Add</button>
                        </div>
                    </div>

                    <div class="kt-section__content">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th class="text-center">Bank Type</th>
                                        <th class="text-center">E-Wallet/Bank</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="verificationSettingBank$.length > 0; else elseNoData">
                                        <tr *ngFor="let row of verificationSettingBank$">                                        
                                            <td class="text-center">{{ row.bank_type_name }}</td>
                                            <td class="text-center">{{ row.name}}</td>
                                            <td class="text-center actions-column">
                                                <button matTooltip="Delete" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onUpdateBank(row)"><i class="fas fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-template #elseNoData>
                                        <kt-fallback-row [collection]="verificationSettingBank$"></kt-fallback-row>
                                    </ng-template>
                                </tbody>
                            </table>
                        </div>
                        <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                            <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
</div>
</div>
<kt-swal-alert [message]="messages$ | async" [data]="data$ | async" (confirmed)="resetData()"></kt-swal-alert>
