<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode | titlecase }} Team / Player</h5>
                <span class="modal-x-button" (click)="onCloseDialog()"><i class="fa fa-times" aria-hidden="true"></i></span>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6 form-group">
                        <label class="col-form-label"> Code <span class="text-danger">*</span></label>
                        <input type="text" formControlName="team_code" class="form-control">
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="col-form-label"> Name <span class="text-danger">*</span> </label>
                        <input type="text" formControlName="team_name" class="form-control">
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="col-form-label"> Position </label>
                        <input type="text" formControlName="position" class="form-control">
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="col-form-label"> Current Standings </label>
                        <input type="text" formControlName="current_standings" class="form-control">
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="col-form-label"> Group Name <span class="text-danger" *ngIf="this.data.groupType == 2">*</span> </label>
                        <input type="text" formControlName="group_name" class="form-control" oninput="this.value = this.value.toLowerCase()">
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="col-form-label"> Country </label>
                        <ng-select
                            [items]="countries" bindLabel="name" bindValue="code"
                            formControlName="country_code" placeholder="Please Select">
                        </ng-select>
                    </div>
                    <section class="col-md-12 card border-0 form-group">
                        <div class="col-12 p-0 card rounded-0">
                            <div class="form-group">
                                <div class="card-header pt-0 pb-0">
                                    <label class="col-form-label"> Image </label>
                                </div>
                                <div class="mx-2 p-0 row">
                                    <div class="col-lg-6 form-group p-0">
                                        <label class="col-12 mt-3"> Desktop <span class="text-danger">*</span></label>
                                        <label class="btn btn-default btn-sm ml-2">
                                            <span>Choose file</span>
                                            <input type="file" hidden accept="image/*" (change)="onUploadFile($event, 'desktop')" [disabled]="(mobileLoading || desktopLoading)">
                                        </label>
                                        <div class="spinner-wrapper row pl-3" *ngIf="desktopLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                        </div>
                                        <div class="image-wrapper preview-image ml-2" *ngIf="data.team?.logo_desktop !== null || desktopImagePreview.length > 0">
                                            <img class="img-fluid" alt="Team Logo Desktop" [src]="desktopImagePreview[0] || data.team.logo_desktop" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6 form-group p-0">
                                        <label class="col-12 mt-3"> Mobile <span class="text-danger">*</span></label>
                                        <label class="btn btn-default btn-sm ml-2">
                                            <span>Choose file</span>
                                            <input type="file" hidden accept="image/*" (change)="onUploadFile($event, 'mobile')" [disabled]="(mobileLoading || desktopLoading)">
                                        </label>
                                        <div class="spinner-wrapper row pl-3" *ngIf="mobileLoading">
                                            <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                        </div>
                                        <div class="image-wrapper preview-image ml-2" *ngIf="data.team?.logo_mobile !== null || mobileImagePreview.length > 0">
                                            <img class="img-fluid" alt="Team Logo Mobile" [src]="mobileImagePreview[0] || data.team.logo_mobile" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button *ngIf="canEditTeamPlayer" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()" #focusfield></kt-submit-button>
            </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
