import { Injectable } from '@angular/core';
import { SalesConversionReport } from '@core/models/sales-conversion-report.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable()
export class SalesConversionReportEntityService extends EntityCollectionServiceBase<SalesConversionReport> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('SalesConversionReport', serviceElementsFactory);
    }
}
