import { AuthHttpService } from '@core/services/auth-http.service';

import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Status } from '@core/enums/status.enum';
import { Operator } from '@core/models/operator.model';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { interval, Observable, of, Subscription } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { OperatorDialogComponent } from './dialogs/operator-profile.component';
import { OperatorDataService } from './services/operator-data.service';
import { OperatorEntityService } from './services/operator-entity.service';
import { SystemSettingDataService } from '../system-setting/services/system-setting-data.service';
import Swal from 'sweetalert2';
import { TwoFADataService } from '../../account/dialogs/account-twofa/services/twofa-data.service';
import { TranslateService } from '@ngx-translate/core';
import { AppPermissionService } from '@core/services/app-permission.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import * as moment from 'moment-timezone';
import { CurrencyHttpService } from '@core/services/currency-http.service';

@Component({
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.scss']
})
export class OperatorComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    applicationRoles: this.dropdownHttpService.applicationRoles,
    perPage: this.dropdownHttpService.perPage,
    currencies: [],
  };
  status = Status;
  operators$: Observable<Operator[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  messages$ = this.twoFADataService.messages$;
  authorizedRoleVersion = null;
  userData = JSON.parse(localStorage.getItem('user_data'));
  user_permissions = JSON.parse(localStorage.getItem('user_permissions'));
  isAdminUser = false;

  appRolesSelectedItems = [];
  appRolesDropdownSettings = {
    singleSelection: false,
    labelKey: 'name',
    enableSearchFilter: true,
    searchBy: ['name']

  }

  currencyDropdownList = [];
  currencySelectedItems = [];
  currencyDropdownSettings = {
    singleSelection: false,
    labelKey: 'name',
    enableSearchFilter: true,
    
  }

  dateTimeFilterType = [
    {
      key: 'created_time',
      label: 'Created Time'
    },
    {
      key: 'updated_time',
      label: 'Updated Time'
    },
    {
      key: 'last_login_date',
      label: 'Last Login Date'
    }
  ]
  ranges = this.transactionHttpService.ranges;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '1.1';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'name': 'desc',
    'role_name': 'desc',
    'application_role_name': 'desc',
    'currency': 'desc',
    'view_masked_mobile': 'desc',
    'two_factor_authentication_status': 'desc',
    'status': 'desc',
    'last_login': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  // permissions
  canCreateOperator: boolean;
  canEditOperator: boolean;
  canResetOperatorPassword: boolean;
  canViewOperator2FA: boolean;
  canResetOperator2FA: boolean;
  
  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private operatorService: OperatorEntityService,
    private operatorDataService: OperatorDataService,
    private dropdownHttpService: DropdownHttpService,
    private authHttpService: AuthHttpService,
    private systemSettingService: SystemSettingDataService,
    private translateService: TranslateService,
    private twoFADataService: TwoFADataService,
    private appPermissionService: AppPermissionService,
    private transactionHttpService: TransactionHttpService,
    private currencyHttpService: CurrencyHttpService,
  ) { }

  ngOnInit() {
    this.reload();
    this.setCurrencyDropdown();
    this.formInit();
    this.pagination = this.operatorDataService.pagination;
    this.authorizedRoleVersion = this.userData.authorized_role_version;
    this.isAdminUser = this.userData.is_admin;

    this.dropdownHttpService.currencies.subscribe(res => {
      this.currencyDropdownList = res;
    });

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateOperator = appPermissions.create_operator;
      this.canEditOperator = appPermissions.edit_operator;
      this.canResetOperatorPassword = appPermissions.reset_operator_password;
      this.canViewOperator2FA = appPermissions.view_operator_2fa;
      this.canResetOperator2FA = appPermissions.reset_operator_2fa;

      if (this.authorizedRoleVersion == 1) {
          this.canViewOperator2FA = this.isAdminUser === true ? true : this.user_permissions.view_and_reset_2fa;
          this.canResetOperator2FA = this.isAdminUser === true ? true : this.user_permissions.view_and_reset_2fa;
      }
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onOpenDialog(type: string, row?: any) {
    if (type === 'reset') {
      this.openDialogBy(OperatorDialogComponent, { operator: row, mode: 'reset' });
    } else {
      if (row) {
        const operator = this.operatorService.getByKey(row.id);
        this.subscription = operator.pipe(
          tap((res) => {
            this.openDialogBy(OperatorDialogComponent, { operator: res, mode: 'edit' });
          })
        )
          .subscribe();
      } else {
        this.openDialogBy(OperatorDialogComponent, { mode: 'create' });
      }
    }
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.operators$ = this.operatorService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.pagination = this.operatorDataService.pagination;
        this.loading = false;
        this.dataLength = res.length;
        this.loadingBar.complete();
      })
    );
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.currencySelectedItems = [];
    this.appRolesSelectedItems = [];
    this.onClearDate();
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.operators$ = this.operatorService.getWithQuery(`?${this.params}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.operatorDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  onReset(user_id) {
    if (this.canResetOperator2FA) {
      Swal.fire({
        title: this.translateService.instant(`Are you sure?`),
        text: this.translateService.instant(`Are you sure you want to reset this operator 2FA setting?`),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.translateService.instant('Yes'),
        cancelButtonText: this.translateService.instant('Cancel'),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.twoFADataService.resetTwoFA(user_id).subscribe();
        }
      });
    }
  }

  private openDialogBy(componentRef: any, data?: { operator?: any, mode?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        operator: data.operator,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      operator_id: new FormControl(null),
      username: new FormControl(null),
      name: new FormControl(null),
      role: new FormControl('all'),
      status: new FormControl('all'),
      datetime_type: new FormControl(this.dateTimeFilterType[0].key),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null),
      currency_id: new FormControl(this.currencySelectedItems),
      app_role_ids: new FormControl(this.appRolesSelectedItems),
    });
  }

  private refreshEvery(seconds: number) {
    const refreshSource = interval(seconds * 1000);
    refreshSource.subscribe(() => {
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
          this.reload();
        }
      });
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== false) {
        fields[key] = formData[key] === true ? 1 : formData[key];
      }
    });
    return fields;
  }

  reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null ? event.startDate._d : event.startDate,
        end_datetime: event.endDate !== null ? event.endDate._d : event.endDate
      });
    }
  }

}
