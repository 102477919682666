<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Edit Whatsapp Bot</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
          <div>
            <div class="col-12 form-group row">
                <label class="col-4 col-form-label">Name <span class="text-danger">*</span></label>
                <input autofocus formControlName="name" class="col-8 form-control">
            </div>
            <div class="col-12 form-group row">
              <label class="col-4 col-form-label">Remark <span class="text-danger"></span></label>
              <input id="remark" formControlName="remark" class="col-8 form-control">
            </div>
            <div class="col-12 form-group row">
              <label class="col-4 col-form-label">Status <span class="text-danger">*</span> </label>
              <select *ngIf="dropdown.statuses" formControlName="status" class="col-8 form-control">
                <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i" >{{ value }}</option>
              </select>
            </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.whatsappBot, data.mode)"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
