import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Member } from '@core/models/member.model';

@Injectable()
export class MemberEntityService extends EntityCollectionServiceBase<Member> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Member', serviceElementsFactory);
    }
}

