import { BankSettingsDialogComponent } from './bank-settings/dialogs/bank-settings-edit.component';
import { BankSettingsEntityService } from './bank-settings/services/bank-settings-entity.service';
import { BankSettingsDataService } from './bank-settings/services/bank-settings-data.service';
import { FaqEntityService } from './faq-management/services/faq-entity.service';
import { FaqDataService } from './faq-management/services/faq-data.service';
import { BotStatusResolver } from '@core/resolvers/bot-status.resolver';
import { BotStatusDataService } from './bot-status/service/bot-status-data.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MerchantBankResolver } from '@core/resolvers/merchant-bank.resolver';
import { BankReceiptResolver } from '@core/resolvers/bank-receipt.resolver';
import { MerchantBankDataService } from './merchant-banks/services/merchant-bank-data.service';
import { MerchantBankEntityService } from './merchant-banks/services/merchant-bank-entity.service';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { SettingsRoutingModule } from './settings-routing.module';
import { BannerComponent } from './banners/banner.component';
import { SettingsPromotionComponent } from './subcomponents/settings-promotion/settings-promotion.component';
import { SettingsMaintainanceComponent } from './subcomponents/settings-maintainance/settings-maintainance.component';
import { ContactComponent } from './contacts/contact.component';
import { AnnouncementComponent } from './announcements/announcement.component';
import { AnnouncementEditDialogComponent } from './announcements/dialogs/announcement-edit/announcement-edit.component';
import { MerchantBankComponent } from './merchant-banks/merchant-bank.component';
import { MerchantBankEditDialogComponent } from './merchant-banks/dialogs/merchant-bank-edit/merchant-bank-edit.component';
import { BannerEditDialogComponent } from './banners/dialogs/banner-edit/banner-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BannerEntityService } from './banners/services/banner-entity.service';
import { BannerResolver } from '@core/resolvers/banner.resolver';
import { BannerDataService } from './banners/services/banner-data.service';
import { ContactEntityService } from './contacts/services/contact-entity.service';
import { ContactDataService } from './contacts/services/contact-data.service';
import { EntityDefinitionService, EntityDataService } from '@ngrx/data';
import { entityMetadata } from 'app/app.module';
import { ContactResolver } from '@core/resolvers/contact.resolver';


import { AnnouncementEntityService } from './announcements/services/announcement-entity.service';
import { AnnouncementDataService } from './announcements/services/announcement-data.service';
import { AnnouncementResolver } from '@core/resolvers/announcement.resolver';

import { SharedModule } from '@shared/shared.module';
import { BankReceiptsComponent } from './bank-receipts/bank-receipts.component';
import { BankReceiptDataService } from './bank-receipts/services/bank-receipt-data.service';
import { BankReceiptEntityService } from './bank-receipts/services/bank-receipt-entity.service';
import { BankReceiptBrowserDialogComponent } from './bank-receipts/dialogs/bank-receipt-browser/bank-receipt-browser.component';
import { BankReceiptVoidDialogComponent } from './bank-receipts/dialogs/bank-receipt-void/bank-receipt-void.component';
import { BankReceiptRemarksDialogComponent } from './bank-receipts/dialogs/bank-receipt-remarks/bank-receipt-remarks.component';
import { BotStatusComponent } from './bot-status/bot-status.component';
import { BotStatusEntityService } from './bot-status/service/bot-status-entity.service';

import { BankTransactionDataService } from './bank-transaction/service/bank-transaction-data.service';
import { BankTransactionComponent } from './bank-transaction/bank-transaction.component';
import { BankTransactionFormDialogComponent } from './bank-transaction/dialogs/bank-transaction-form/bank-transaction-form.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PartialsModule } from '@views/partials/partials.module';

import { TransactionReconciliationComponent } from './transaction-reconciliation/transaction-reconciliation.component';
import { TransactionReconciliationDataService } from './transaction-reconciliation/services/transaction-reconciliation-data.service';
import { TransactionReconciliationEntityService } from './transaction-reconciliation/services/transaction-reconciliation-entity.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FaqManagementComponent } from './faq-management/faq-management.component';
import { FaqEditDialogComponent } from './faq-management/dialogs/faq-edit.component';
import { TermsConditionsDataService } from './terms-conditions/services/terms-conditions-data.service';
import { TermsConditionsEntityService } from './terms-conditions/services/terms-conditions-entity.service';
import { TermsConditionsEditDialogComponent } from './terms-conditions/dialogs/terms-conditions-edit.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { FaqSectionsComponent } from './faq-sections/faq-sections.component';
import { FaqSectionsDataService } from './faq-sections/services/faq-sections-data.service';
import { FaqSectionsEditDialogComponent } from './faq-sections/dialogs/faq-sections-edit.component';
import { LiveChatScriptComponent } from './live-chat-script/live-chat-script.component';
import { LiveChatScriptEditDialogComponent } from './live-chat-script/dialogs/live-chat-script-edit.component';
import { LiveChatScriptDataService } from './live-chat-script/services/live-chat-script-data.service';
import { BankSettingsComponent } from './bank-settings/bank-settings.component';
import { WhatsappNumberDataService } from './whatsapp-number/services/whatsapp-number-data.service';
import { WhatsappNumberEntityService } from './whatsapp-number/services/whatsapp-number-entity.service';
import { WhatsappNumberEditDialogComponent } from './whatsapp-number/dialogs/whatsapp-number-edit/whatsapp-number-edit.component';
import { WhatsappNumberComponent } from './whatsapp-number/whatsapp-number.component';
import { WhatsappBotDataService } from './whatsapp-bot/services/whatsapp-bot-data.service';
import { WhatsappBotEntityService } from './whatsapp-bot/services/whatsapp-bot-entity.service';
import { WhatsappBotEditDialogComponent } from './whatsapp-bot/dialogs/whatsapp-bot-edit/whatsapp-bot-edit.component';
import { WhatsappBotQRDialogComponent } from './whatsapp-bot/dialogs/whatsapp-bot-qr/whatsapp-bot-qr.component';
import { WhatsappBotComponent } from './whatsapp-bot/whatsapp-bot.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { RegistrationPageDataService } from './registration-page/services/registration-page-data.service';
import { RegistrationPageEditComponent } from './registration-page/dialogs/registration-page-edit.component';
import { MobileDisplayComponent } from './footer-settings/mobile-display/mobile-display.component';
import { SectionSettingsComponent } from './footer-settings/section-settings/section-settings.component';
import { WebDisplayComponent } from './footer-settings/web-display/web-display.component';
import { WebDisplayEditDialogComponent } from './footer-settings/web-display/dialogs/web-display-edit.component';
import { WebDisplayDataService } from './footer-settings/web-display/services/web-display-data.service';
import { WebDisplayEntityService } from './footer-settings/web-display/services/web-display-entity.service';
import { FooterSectionDataService } from './footer-settings/section-settings/footer-section/services/footer-section-data.service';
import { FooterSectionEntityService } from './footer-settings/section-settings/footer-section/services/footer-section-entity.service';
import { FooterSectionComponent } from './footer-settings/section-settings/footer-section/footer-section.component';
import { FooterSectionEditDialogComponent } from './footer-settings/section-settings/footer-section/dialogs/footer-section-edit.component';
import { IconLibraryComponent } from './footer-settings/section-settings/icon-library/icon-library.component';
import { IconLibraryEditDialogComponent } from './footer-settings/section-settings/icon-library/dialogs/icon-library-edit.component';
import { IconLibraryDataService } from './footer-settings/section-settings/icon-library/services/icon-library-data.service';
import { IconLibraryEntityService } from './footer-settings/section-settings/icon-library/services/icon-library-entity.service';
import { QuickLinkDataService } from './footer-settings/section-settings/quick-link/services/quick-link-data.service';
import { QuickLinkEntityService } from './footer-settings/section-settings/quick-link/services/quick-link-entity.service';
import { QuickLinkComponent } from './footer-settings/section-settings/quick-link/quick-link.component';
import { QuickLinkEditDialogComponent } from './footer-settings/section-settings/quick-link/dialogs/quick-link-edit.component';
import { SeoSettingsComponent } from './seo-settings/seo-settings.component';
import { SeoSettingsEditComponent } from './seo-settings/dialogs/seo-settings-edit/seo-settings-edit.component';
import { MetaTagSettingsComponent } from './seo-settings/dialogs/meta-tag-settings/meta-tag-settings.component';
import { MetaTagSettingsDuplicateComponent } from './seo-settings/dialogs/meta-tag-settings-duplicate/meta-tag-settings-duplicate.component';
import { RouteSettingsComponent } from './seo-settings/dialogs/route-settings/route-settings.component';
import { SeoSettingsDataService } from './seo-settings/services/seo-settings-data.service';
import { SeoSettingsDomainsDataService } from './seo-settings/services/seo-settings-domains-data.service';
import { RouteSettingsDataService } from './seo-settings/services/route-settings-data.service';
import { MetaTagDataService } from './seo-settings/services/meta-tag-data.service';
import { BankMaintenanceHourComponent } from './bank-settings/dialogs/bank-maintenance-hour/bank-maintenance-hour.component';
import { MarqueesComponent } from './announcements/marquees/marquees.component';
import { MarqueesDataService } from './announcements/marquees/services/marquees-data.service';
import { MarqueesEntityService } from './announcements/marquees/services/marquees-entity.service'
import { TranslateModule } from '@ngx-translate/core';
import { DialogComponent } from './announcements/dialog/dialog.component';
import { DialogDataService } from './announcements/dialog/services/dialog-data.service';
import { DialogEntityService } from './announcements/dialog/services/dialog-entity.service';
import { AboutUsComponent } from './about-us/about-us.component';
import { AboutUsDataService } from './about-us/services/about-us-data.service';
import { AboutUsEntityService } from './about-us/services/about-us-entity.service';
import { ResponsibleGamingComponent } from './responsible-gaming/responsible-gaming.component';
import { ResponsibleGamingDataService } from './responsible-gaming/services/responsible-gaming-data.service';
import { ResponsibleGamingEntityService } from './responsible-gaming/services/responsible-gaming-entity.service';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PrivacyPolicyDataService } from './privacy-policy/services/privacy-policy-data.service';
import { PrivacyPolicyEntityService } from './privacy-policy/services/privacy-policy-entity.service';
import { DialogPreviewComponent } from './announcements/dialog/dialogs/dialog-preview.component';
import { ReferralContentComponent } from './referral-content/referral-content.component';
import { ReferralContentDataService } from './referral-content/services/referral-content-data.service';
import { ReferralContentEntityService } from './referral-content/services/referral-content-entity.service'
import { MenuBarDataService } from './menu-bar/services/menu-bar-data.service';
import { MenuBarEntityService } from './menu-bar/services/menu-bar-entity.service';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { MerchantBankNewEditComponent } from './merchant-banks/dialogs/merchant-bank-new-edit/merchant-bank-new-edit.component';
import { MerchantBankTransactionConfigEditComponent } from './merchant-banks/dialogs/merchant-bank-transaction-config-edit/merchant-bank-transaction-config-edit.component';
import { BankReceiptEditComponent } from './bank-receipts/dialogs/bank-receipt-edit/bank-receipt-edit.component';
import { MerchantBankWrapperComponent } from './merchant-banks/merchant-bank-wrapper/merchant-bank-wrapper.component';

@NgModule({
  declarations: [
    AnnouncementComponent,
    MerchantBankComponent,
    BannerComponent,
    SettingsPromotionComponent,
    ContactComponent,
    SettingsMaintainanceComponent,
    AnnouncementEditDialogComponent,
    MerchantBankEditDialogComponent,
    BannerEditDialogComponent,
    BankReceiptVoidDialogComponent,
    BankReceiptsComponent,
    BankReceiptBrowserDialogComponent,
    BankReceiptRemarksDialogComponent,
    BotStatusComponent,
    BankTransactionComponent,
    BankTransactionFormDialogComponent,
    TransactionReconciliationComponent,
    FaqManagementComponent,
    FaqEditDialogComponent,
    TermsConditionsComponent,
    TermsConditionsEditDialogComponent,
    FaqSectionsComponent,
    FaqSectionsEditDialogComponent,
    LiveChatScriptComponent,
    LiveChatScriptEditDialogComponent,
    BankSettingsComponent,
    BankSettingsDialogComponent,
    WhatsappNumberEditDialogComponent,
    WhatsappNumberComponent,
    WhatsappBotEditDialogComponent,
    WhatsappBotQRDialogComponent,
    WhatsappBotComponent,
    RegistrationPageComponent,
    RegistrationPageEditComponent,
    MobileDisplayComponent,
    SectionSettingsComponent,
    WebDisplayComponent,
    WebDisplayEditDialogComponent,
    FooterSectionComponent,
    FooterSectionEditDialogComponent,
    IconLibraryComponent,
    IconLibraryEditDialogComponent,
    QuickLinkComponent,
    QuickLinkEditDialogComponent,
    SeoSettingsComponent,
    SeoSettingsEditComponent,
    MetaTagSettingsComponent,
    MetaTagSettingsDuplicateComponent,
    RouteSettingsComponent,
    BankMaintenanceHourComponent,
    MarqueesComponent,
    BankMaintenanceHourComponent,
    DialogComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    ResponsibleGamingComponent,
    DialogPreviewComponent,
    ReferralContentComponent,
    MenuBarComponent,
    MerchantBankNewEditComponent,
    MerchantBankTransactionConfigEditComponent,
    BankReceiptEditComponent,
    MerchantBankWrapperComponent,
  ],
  imports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CommonModule,
    MatProgressSpinnerModule,
    NgbModule,
    SettingsRoutingModule,
    SharedModule,
    PartialsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgSelectModule,
    MatDialogModule,
    NgxDaterangepickerMd.forRoot(),
    AngularMultiSelectModule,
    TranslateModule
  ],
  providers: [
    BannerEntityService,
    BannerDataService,
    BannerResolver,
    BankReceiptDataService,
    BankReceiptEntityService,
    BankReceiptResolver,
    ContactEntityService,
    ContactDataService,
    ContactResolver,
    AnnouncementEntityService,
    AnnouncementDataService,
    AnnouncementResolver,
    MerchantBankEntityService,
    MerchantBankDataService,
    MerchantBankResolver,
    BotStatusEntityService,
    BotStatusDataService,
    BotStatusResolver,
    TransactionReconciliationDataService,
    TransactionReconciliationEntityService,
    FaqDataService,
    FaqEntityService,
    TermsConditionsDataService,
    TermsConditionsEntityService,
    FaqSectionsDataService,
    LiveChatScriptDataService,
    BankSettingsDataService,
    BankSettingsEntityService,
    WhatsappNumberDataService,
    WhatsappNumberEntityService,
    WhatsappBotDataService,
    WhatsappBotEntityService,
    RegistrationPageDataService,
    FooterSectionDataService,
    FooterSectionEntityService,
    WebDisplayDataService,
    WebDisplayEntityService,
    IconLibraryDataService,
    IconLibraryEntityService,
    QuickLinkDataService,
    QuickLinkEntityService,
    SeoSettingsDataService,
    SeoSettingsDomainsDataService,
    RouteSettingsDataService,
    MetaTagDataService,
    MarqueesComponent,
    MarqueesDataService,
    MarqueesEntityService,
    DialogDataService,
    DialogEntityService,
    ReferralContentDataService,
    ReferralContentEntityService,
    MenuBarDataService,
    MenuBarEntityService,
    AboutUsDataService,
    AboutUsEntityService,
    PrivacyPolicyDataService,
    PrivacyPolicyEntityService,
    ResponsibleGamingDataService,
    ResponsibleGamingEntityService
  ]
})

export class SettingsModule {
  constructor(
    private eds: EntityDefinitionService,
    private entityDataService: EntityDataService,
    private bannerDataService: BannerDataService,
    private contactDataService: ContactDataService,
    private announcementDataService: AnnouncementDataService,
    private merchantBankDataService: MerchantBankDataService,
    private bankReceiptDataService: BankReceiptDataService,
    private bankTransactionDataService: BankTransactionDataService,
    private botStatusDataService: BotStatusDataService,
    private transactionReconciliationDataService: TransactionReconciliationDataService,
    private faqDataService: FaqDataService,
    private termsConditionsDataService: TermsConditionsDataService,
    private faqSectionsDataService: FaqSectionsDataService,
    private referralContentDataService: ReferralContentDataService,
    private bankSettingsDataService: BankSettingsDataService,
    private whatsappNumberDataService: WhatsappNumberDataService,
    private whatsappBotDataService: WhatsappBotDataService
  ) {
    eds.registerMetadataMap(entityMetadata);
    entityDataService.registerServices({
      MerchantBank: merchantBankDataService,
      Banner: bannerDataService,
      Contact: contactDataService,
      Announcement: announcementDataService,
      BankReceipt: bankReceiptDataService,
      BankTransaction: bankTransactionDataService,
      BotStatus: botStatusDataService,
      TransactionReconciliation: transactionReconciliationDataService,
      Faq: faqDataService,
      TermsAndConditions: termsConditionsDataService,
      FaqSectionsDataService: faqSectionsDataService,
      ReferralContent: referralContentDataService,
      BankSettings: bankSettingsDataService,
      WhatsappNumber: whatsappNumberDataService,
      WhatsappBot: whatsappBotDataService
    });
  }
}
