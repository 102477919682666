<div #container style="position: relative;">
    <angular2-multiselect [data]="dropdownList"
    [settings]="dropdownSettings"
    [loading] = "loading"
    [disabled]="dropdownSettings.disabled"
    [(ngModel)]="selectedItems"
    (onOpen)="onOpen($event); onOpenSelect()"
    (onSelect)="onItemSelect($event)"
    (onSelectAll)="onSelectAll($event)"
    (onDeSelect)="OnItemDeSelect($event)"
    (onDeSelectAll)="onDeSelectAll($event)"
    (onScrollToEnd)="fetchMore($event)">
        <c-search>
            <ng-template>
                <input id="member-search-dropdown-input" type="text" (keyup)="onSearch($event)" placeholder="Please Select" style="border: none;width: 100%; height: 100%;outline: none;" #filterInput/>
            </ng-template>
        </c-search>
        <c-item>
            <ng-template let-item="item">
                <label>{{item[dropdownSettings.labelKey]}}</label>
            </ng-template>
        </c-item>
    </angular2-multiselect>
    <div *ngIf="(selectedItems == undefined || selectedItems.length == 0) && dropdownList.length == 0 && loading" style="position: absolute; right: 30px; top: 0px;" class="text-right">
        <i class='spinner-border spinner-border-sm align-middle' [ngStyle]="{'margin-top': (container.offsetHeight / 3) + 'px'}"></i>
    </div>
</div>