<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode === 'create' ? 'Create' : 'Edit'}} Footer Section</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="row px-3">
                    <div class="col-3 form-group">
                        <label>{{ 'Section Name' }} <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-9 form-group">
                        <input type="text" class="form-control" formControlName="name" [ngClass]="{'is-invalid': checkValidation && form.controls.name.errors }">
                    </div>
                </div>
                <div class="row px-3">
                    <div class="col-3 form-group">
                        <label>{{ 'Section Type' }} <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-9 form-group">
                        <select class="form-control" formControlName="section_type_id" [ngClass]="{'is-invalid': checkValidation && form.controls.section_type_id.errors }">
                            <option value="null" disabled hidden> Please Select </option>
                            <ng-container *ngFor="let value of dropdown.section_types| async">
                                <option [value]="value.id">{{ value.name }}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
                <div class="row px-3">
                    <div class="col-3 form-group">
                        <label>{{ 'Merchant' }} <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-9 form-group">

                        <ng-container *ngFor="let value of merchantSelectedItems">
                            <input type="text" class="form-control" [attr.disabled]="true" [value]="value.name">
                        </ng-container>

                        <!-- <kt-dropdown-wo-lazyload
                        [form] = 'form'
                        [dropdownList] = 'merchantDropdownList'
                        [dropdownSettings] = 'merchantDropdownSettings'
                        [formName] = "'merchant_id'"
                        [selectionAttributes] = "'id'"
                        [selectedItems] = 'merchantSelectedItems'
                        [disabled]="true"
                        [ngClass]="{'is-invalid': checkValidation && form.controls.merchant_id.errors }">
                        </kt-dropdown-wo-lazyload> -->
                    </div>
                </div>
                <div class="row px-3">
                    <div class="col-3 form-group">
                        <label>{{ 'Status' }} <span class="text-danger">*</span></label>
                    </div>
                    <div class="col-9 form-group">
                        <select class="form-control" *ngIf="dropdown.statuses" formControlName="status" [ngClass]="{'is-invalid': checkValidation && form.controls.status.errors }">
                            <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
                        </select>
                    </div>
                </div>

                <div class="mt-3 mb-3 container">
                    <div class="text-center font-weight-bold">{{ 'Display Title' }}</div>
                </div>
                <div class="row px-3">
                    <div class="col-3 form-group">
                        <label>{{ 'Display Title' }}</label>
                    </div>
                    <div class="col-9 form-group">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" (click)="onToggleStatus(display_title)"  [id]="1" [checked]="display_title == 1 ? true : false">
                            <label class="custom-control-label" [for]="1"></label>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="(form.get('display_title').value == 1 && languages.length > 0); else elseLoading">
                    <div formGroupName="section_titles" class="col-12 p-0">
                        <div class="col-md-12 form-group" *ngFor="let language of languages; let i = index" [formGroupName]="language.id">
                            <div class="row">
                                <div class="col-3">
                                    <label class="control-label">{{ language.code }}</label>
                                </div>
                                <div class="col-9 form-group">
                                    <input type="text" formControlName="name"  class="col-12 form-control" [attr.disabled]="form.get('section_titles.'+language.id+'.same_as_title').value == true ? true : null" >
                                    <label class="mt-1"><input type="checkbox" formControlName="same_as_title" (change)="checkSameAsTitle($event , language.id)"> &nbsp;{{ 'Same as Section Name' }}</label>
                                </div>
                            </div>

                        </div>
                    </div>
                </ng-container>
                <ng-template #elseLoading>
                    <div class="col-md-12 row justify-content-center" *ngIf="form.get('display_title').value == 1">
                        <i class="fas fa-circle-notch fa-spin p-0 mr-2"></i>
                    </div>
                </ng-template>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button *ngIf="data.mode === 'create' || (data.mode !== 'create' && canEditFooterSection)" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
