<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <!-- Header -->
      <div class="modal-header">
        <h5 class="modal-title">{{ 'Currency' | translate }} - {{ data.mode === 'edit' ? ('Edit' | translate) : 'Create' | translate }} {{ 'Verification Settings' | translate }}</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>

      <div class="modal-body">
        <mat-tab-group
            animationDuration="0ms"
            class="form-group"
            [(selectedIndex)]="selectedIndex"
            style='min-height:600px'
        >
          <mat-tab *ngFor="let tab of tabs; let i = index">
            <ng-template mat-tab-label>
                <span>{{tab.label}}</span>
                <span class="modal-x-button close-button" (click)="removeTab(i)" *ngIf="tab.ableDelete">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </ng-template>
            <verification-settings-currency-content
                [detail]="tab.detail"
                [mode]="data.mode"
                [verification_setting_id]="data.verificationSettingsID"
                [settings_currency_id]="tab.id"
                [bank_type_id]="data.bank_type"
                [new_currency]="tab.ableDelete"
                (buttonLoadingChange)="buttonLoading = $event"
            >
            </verification-settings-currency-content>
          </mat-tab>
          <mat-tab *ngIf="!isAddingTab">
            <ng-template mat-tab-label>
                <button class="btn btn-primary btn-icon-sm float-right btn-cus" (click)="toggleAddingTab()"><i class="fas fa-plus"></i>Add</button>
            </ng-template>
          </mat-tab>
          <mat-tab *ngIf="isAddingTab">
            <ng-template mat-tab-label>
                <span (click)="openSelect()">Please Select</span>
                <mat-select #selectElement placeholder="Select a tab" formControlName="selectedTab" (selectionChange)="addTab()" [panelClass]="'add-tab-dropdown'">
                    <mat-option [value]="null" disabled selected>Select a tab</mat-option>
                    <mat-option *ngFor="let option of filteredCurrencies" [value]="option.id">
                    {{ option.name }}
                    </mat-option>
                </mat-select>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>{{ 'Close' | translate }}</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event, true)"></kt-swal-alert>
