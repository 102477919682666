import { Pagination } from '@core/models/pagination.model';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class AllAffiliateMemberReportHttpService {

  pagination: Pagination;
  constructor(private http: HttpClient) { }

  getAllAffiliateMemberReport(params?: string): Observable<any[]> {
    return this.http.get<ApiResponse>(`/report/allaffiliatememberreport${params}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

}