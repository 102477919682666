import { Component, OnInit, Inject, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BankTransaction } from '@core/models/bank-transaction.model';
import { BankTransactionEntityService } from '../../service/bank-transaction-entity.service';
import { BankTransactionDataService } from '../../service/bank-transaction-data.service';
import { Subscription, forkJoin } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import * as moment from 'moment-timezone';
import { CurrencyHttpService } from '@core/services/currency-http.service';

@Component({
  selector: 'kt-bank-transaction-form',
  templateUrl: './bank-transaction-form.component.html',
  styleUrls: ['./bank-transaction-form.component.scss']
})
export class BankTransactionFormDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(OwlDateTimeInputDirective) datePicker: OwlDateTimeInputDirective<any>;
  form: FormGroup;
  dropdown = {
    statuses:  this.dropdownHttpService.statuses,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    merchantBanks$: null,
    types: this.dropdownHttpService.createBankTransactionTypes
  };
  messages$ = this.bankTransactionDataService.messages$;
  selectedTransferType: number;
  selectedCurrency: number;
  transferOutBalance = 0;
  transferInBalance = 0;
  refreshStatus: boolean;
  dateTimeStack = null;
  buttonLoading = false;
  params = '&status[0]=1&status[1]=2'

  bankaccountDropdownSettings = {};
  bankaccountDropdownList = [];
  bankaccountSelectedItems: any;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;

  private unsubscribe: Subscription[] = [];
  private datePickerSubscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bankTransaction: BankTransaction, mode: string },
    public dialogRef: MatDialogRef<BankTransactionFormDialogComponent>,
    private bankTransactionService: BankTransactionEntityService,
    private bankTransactionDataService: BankTransactionDataService,
    private merchantBankHttpService: MerchantBankHttpService,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private datePipe: DatePipe,
    private currencyHttpService: CurrencyHttpService
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();

    this.bankaccountDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 220,
      primaryKey: 'name',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };

    // this.dropdownHttpService.merchantBankAccounts.subscribe(
    //   res => {
    //     res.map(function(elm){
    //       elm['name'] = elm['bank_code'] + ' - ' + elm['account_name'] + ' - ' + elm['account_number'];
    //     });
    //     this.bankaccountDropdownList = res;
    //     this.bankaccountDropdownList.unshift({
    //       id: 'all',
    //       name: 'All'
    //     });
    //     this.bankaccountSelectedItems = [this.bankaccountDropdownList.find(v => v.id === 'all')];
    //   }
    // );
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.unsubscribe.forEach(sb => sb.unsubscribe());
    this.onRefresh();
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'trade_time'),
    ]).subscribe();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onTransferType(event: Event) {
    this.selectedTransferType = +(event.target as HTMLSelectElement).value;
    this.selectedCurrency = null;
    this.transferOutBalance = 0;
    this.transferInBalance = 0;
    this.form.patchValue({
      currency_id: null
    });
  }

  onCurrency(event: Event) {
    this.selectedCurrency = +(event.target as HTMLSelectElement).value;
    this.form.patchValue({
      transfer_out: null,
      transfer_in: null
    });
    // this.dropdown.merchantBanks$ = this.merchantBankHttpService.getMerchantBanksByCurrency(this.selectedCurrency, this.params);
    this.merchantBankHttpService.getMerchantBanksByCurrency(this.selectedCurrency, this.params)
      .subscribe(
        res => {
          res.map(function (elm) {
            elm['name'] = elm['bank_code'] + ' - ' + elm['account_name'] + (elm['account_number'] == null ? '' : ' - ' + elm['account_number']);
          });
          this.bankaccountDropdownList = res;
          this.bankaccountDropdownList.unshift({
            id: 'all',
            name: 'All'
          });
          this.bankaccountSelectedItems = [this.bankaccountDropdownList.find(v => v.id === 'all')];
        }
      );

  }

  onBankBalance(event: Event, type?: number) {
    const bankId = event[0].id;
    this.unsubscribe.push(
      this.merchantBankHttpService.getById(bankId).pipe(
        tap(res => {
          if (type === 1) {
            this.transferInBalance = res.balance;
          }
          if (type === 2) {
            this.transferOutBalance = res.balance;
          }
        })
      ).subscribe()
    );
  }

  onSave(bankTransaction: BankTransaction, mode?: string) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      id: bankTransaction ? bankTransaction.id : null,
      ...this.form.value
    };
    
    if (data['trade_time']) {
      data['trade_time'] = moment(data['trade_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
    }

    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    switch (mode) {
      case 'edit':
        this.unsubscribe.push(this.bankTransactionService.update(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe());
        break;
      case 'create':
        this.unsubscribe.push(forkJoin([
          this.bankTransactionDataService.add(data).pipe(
            tap((res: any) => {
              this.bankTransactionDataService.messages$.next(res.message);
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              throw error;
            })
          ),
        ]).subscribe());
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.valueChange.pipe(
      map(res => this.datePipe.transform(res, this.transactionHttpService.dateTimeFormat)),
      tap(date => {
        this.form.patchValue({ [formKey] : date });
      }),
    );
  }

  private formInit() {
    this.dateTimeStack = new Date(new Date(this.transactionHttpService.dateTimeFormat).getTime() + this.offset - this.clientOffset);
    let transactionTypeId = null;
    let settingsCurrencyId = null;
    let transferOut = null;
    let transferIn = null;
    let tradeTime: any = new Date(new Date(this.transactionHttpService.dateTimeFormat).getTime() + this.offset - this.clientOffset);
    let amount = null;
    let processingFee = null;
    let remarks = null;
    let status = 1;
    if (this.data.mode === 'edit') {
      this.dateTimeStack = new Date(this.data.bankTransaction.trade_time);
      transactionTypeId = this.data.bankTransaction.transaction_type_id;
      settingsCurrencyId = this.data.bankTransaction.settings_currency_id;
      transferOut = this.data.bankTransaction.transfer_out;
      transferIn = this.data.bankTransaction.transfer_out;
      tradeTime = this.data.bankTransaction.trade_time;
      amount = this.data.bankTransaction.amount;
      processingFee = this.data.bankTransaction.processing_fee;
      remarks = this.data.bankTransaction.remarks;
      status = this.data.bankTransaction.status;
    }
    this.form = new FormGroup({
      transaction_type_id: new FormControl(transactionTypeId, [Validators.required]),
      currency_id: new FormControl(settingsCurrencyId, [Validators.required]),
      transfer_out: new FormControl(transferOut),
      transfer_in: new FormControl(transferIn),
      trade_time: new FormControl(tradeTime, [Validators.required]),
      amount: new FormControl(amount, [Validators.required, Validators.pattern('^(?=.*[1-9])[0-9]+(\.[0-9]+)?$')]),
      processing_fee: new FormControl(processingFee, [Validators.min(0)]),
      remarks: new FormControl(remarks),
      status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false })
    });

  }

}
