<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div  class="kt-portlet kt-portlet--mobile">
        <div  class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0 filter-row">
                                <!-- new -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'ID' | translate }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="number" formControlName="id" placeholder="{{ 'Search' | translate }}" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Promotion' | translate }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="promotion" placeholder="{{ 'Search' | translate }}" class="form-control" [ngbTypeahead]="this.searchPromo" [resultFormatter]="this.formatPromo" [inputFormatter]="this.formatPromo">
                                </div>
                                <!-- <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Code' | translate }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="code" placeholder="{{ 'Search' | translate }}" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Promo Name' | translate }}:</label>
                                </div> -->
                                <!-- <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="promo_name" placeholder="{{ 'Search' | translate }}" class="form-control">
                                </div> -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Currency' | translate }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        [dropdownList] = "currencyDropdownList"
                                        [dropdownSettings] = "currencyDropdownSettings"
                                        [form] = "form"
                                        [selectionAttributes] = "'id'"
                                        [formName] = "'currency_id'"
                                        [selectedItems]="currencySelectedItems"
                                        (selectedItemsChanged)="currencySelectedItems = $event"
                                    >
                                    </kt-dropdown-wo-lazyload>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Status' | translate }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select  class="form-control" formControlName="status">
                                        <option [value]="null" [disabled]="true">{{ 'Please Select' | translate }}</option>
                                        <option value="all">{{ 'All' | translate }}</option>
                                        <ng-container *ngFor="let value of dropdown.statuses; let i = index;">
                                            <option [value]="i">{{ value | translate }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <!-- <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ "Merchant" | translate }}:</label>
                                  </div>
                                  <div class="col-md-2 kt-form__control">
                                    <select formControlName="merchant_id" class="form-control" [value]="0" (change)="onSelectMerchant($event)">
                                      <option value="all">{{ "All" | translate }}</option>
                                      <option [value]="value.id" *ngFor="let value of dropdown.merchant">{{ value.name }}</option>
                                    </select>
                                  </div>
                                 -->
                            </div>
                            <div class="col-12 row mb-2 pr-0 filter-row">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Member Group' | translate }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <!-- <select class="form-control" formControlName="member_group_id" [dropdownSettings]="memberGroupDropdownSettings">
                                        <option [value]="null" [disabled]="true">{{ 'Please Select' | translate }}</option>
                                        <option value="all">{{ 'All' | translate }}</option>
                                        <ng-container *ngFor="let value of dropdown.promoMemberGroups; let i = index;">
                                            <option [value]="i">{{ value | translate }}</option>
                                        </ng-container>
                                    </select> -->
                                    <kt-dropdown-wo-lazyload
                                        [dropdownList] = "memberGroupDropdownList"
                                        [dropdownSettings] = "memberGroupDropdownSettings"
                                        [form] = "form"
                                        [selectionAttributes] = "'id'"
                                        [formName]="'member_group_id'"
                                        [selectedItems]="memberGroupSelectedItems"
                                        (selectedItemsChanged)="memberGroupSelectedItems = $event"
                                    >
                                    </kt-dropdown-wo-lazyload>
                                </div>
                                <!-- <ng-multiselect-dropdown
                                    [placeholder]="'Select Member Group' | translate"
                                    [settings]="memberGroupDropdownSettings"
                                    [data]="memberGroupDropdownList"
                                    [(ngModel)]="memberGroupsSelectedItems"
                                    formControlName="member_group_id"
                                    [selectAllText]="'Select All'"
                                    [unSelectAllText]="'UnSelect All'"
                                    [allowSearchFilter]="true"
                                >
                                </ng-multiselect-dropdown> -->
                                <!-- <div class="col-md-2 kt-form__control">
                                  <kt-dropdown-wo-lazyload
                                  class=" custom-dropdown"
                                  [form] = 'form'
                                  [dropdownList] = 'filteredMemberGroupDropdownList'
                                  [dropdownSettings] = 'memberGroupDropdownSettings'
                                  [formName] = "'member_group_id'"
                                  [selectionAttributes] = "'id'"
                                  [selectedItems] = 'memberGroupsSelectedItems'
                                  (selectedItemsChanged)="memberGroupsSelectedItems = $event">
                                  </kt-dropdown-wo-lazyload>
                                </div> -->
                                <!-- new -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Game Category' | translate }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        [dropdownList] = "gameCategoryDropdownList"
                                        [dropdownSettings] = "gameCategoryDropdownSettings"
                                        [form] = "form"
                                        [selectionAttributes] = "'id'"
                                        [formName]="'category_id'"
                                        [selectedItems]="gameCategorySelectedItems"
                                        (selectedItemsChanged)="gameCategorySelectedItems = $event"
                                    >
                                    </kt-dropdown-wo-lazyload>
                                </div>
                                <!-- new -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Game Provider' | translate }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        [dropdownList] = "gameProviderDropdownList"
                                        [dropdownSettings] = "gameProviderDropdownSettings"
                                        [form] = "form"
                                        [selectionAttributes] = "'id'"
                                        [formName]="'game_provider_id'"
                                        [selectedItems]="gameProviderSelectedItems"
                                        (selectedItemsChanged)="gameProviderSelectedItems = $event"
                                    >
                                    </kt-dropdown-wo-lazyload>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label no-break">
                                    <label>{{ 'Bonus Type' | translate }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="promo_type" class="form-control">
                                        <option value="all">{{ 'All' | translate }}</option>
                                        <option [value]="row.id" *ngFor="let row of dropdown.promoTypes | async">
                                            {{ row.name | titlecase | translate }}
                                        </option>
                                    </select>
                                </div>
                                <!-- 
                                <ng-container *ngIf="isDepositBonusSetting === 1">
                                    <div class="col-md-1 kt-form__label col-form-label">
                                        <label>{{ 'Bonus Settings' | translate }}:</label>
                                    </div>
                                    <div class="col-md-2 kt-form__control">
                                        <select formControlName="bonus_settings" class="form-control">
                                            <option value="all">{{ 'All' | translate }}</option>
                                            <option [value]="value.id" *ngFor="let value of dropdown.bonusSettings">
                                                {{ value.name | titlecase | translate }}
                                            </option>
                                        </select>
                                    </div>
                                </ng-container>
                                
                                 -->
                            </div>
                            <div class="col-12 row mb-2 pr-0 filter-row">
                                <div class="col-md-1 kt-form__label col-form-label no-break">
                                    <label>{{ 'Target Type' | translate }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="target_type" class="form-control">
                                        <option value="all">{{ 'All' | translate }}</option>
                                        <option [value]="row.id" *ngFor="let row of dropdown.supported_target_type">
                                            {{ row.name | titlecase | translate }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Eligibility Type' | translate }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select  class="form-control" formControlName="eligibility_type">
                                        <option value="all">{{ 'All' | translate }}</option>
                                        <ng-container *ngFor="let value of dropdown.eligibilityTypes">
                                            <option [value]="value.id">{{ value.name | translate }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Bonus Conditions' | translate }}:</label>
                                </div>
                                <div class="col-md-8 kt-form__control">
                                    <div class="d-flex flex-wrap status-filter-pt">
                                        <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.bonusConditions ; let i = index">
                                            <input type="checkbox" [checked]="item.checked" (change)="onSearchBonus($event, item.value, i)" class="custom-control-input" [id]="item.name">
                                            <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name | translate }}</label>
                                        </div>
                                    </div>
                                </div>             
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canCreatePromotionCode" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>
            <div  class="kt-section">
                <div  class="kt-section__content">
                    <div  class="table-responsive">
                        <table  class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th class="text-center">Category</th>
                                    <th>Bonus Type</th>
                                    <th class="text-center">Code</th>
                                    <th>Promo Name</th>
                                    <th>Member Group</th>
                                    <th class="date-column">Validity</th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Currencies</th>
                                    <th>Bonus Rate</th>
                                    <th class="text-center">Game Providers</th>
                                    <th class="text-center">Bonus Conditions</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(promotionCode$ | async) as rows">
                              <tr *ngFor="let row of rows">
                                <td>{{row.id}}</td>
                                <td class="text-center">{{ row.category }}</td>
                                <td>{{ row.bonus_type }}</td>
                                <td class="text-center">{{ row.code }}</td>
                                <td>{{ row.name }}</td>
                                <td>{{ row.member_group }}</td>
                                <td class="no-wrap">From: {{ isValidDate(row.valid_from) ? (row.valid_from | timezoneDate: 'YYYY-MM-DD HH:mm') : '' }}<br>
                                To: {{ isValidDate(row.valid_to) ? (row.valid_to | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</td>
                                <td class="text-center">
                                    <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                                  </td>
                                <td class="text-center">{{ row.currencies }}</td>
                                <td>{{ row.bonus_rate }}</td>
                                <td class="text-center">{{ row.game_provider }}</td>
                                <td class="no-wrap">
                                    <span class="no-wrap">
                                        <i matTooltip="{{ 'Auto Approve' | translate }}" [ngClass]="row.auto_approve > 0 ? 'fas fa-check kt-font-info' : 'fas fa-times kt-font-danger'"></i>
                                        {{ 'Auto Approve' | translate }}
                                    </span><br>
                                    <span class="no-wrap">
                                        <i matTooltip="{{ 'Auto Unlock' | translate }}" [ngClass]="row.auto_unlock > 0 ? 'fas fa-check kt-font-info' : 'fas fa-times kt-font-danger'"></i>
                                        {{ 'Auto Unlock' | translate }}
                                    </span><br>
                                    <span class="no-wrap">
                                        <i matTooltip="{{ 'Limit Provider Transfer In' | translate }}" [ngClass]="row.limit_transfer_in > 0 ? 'fas fa-check kt-font-info' : 'fas fa-times kt-font-danger'"></i>
                                        {{ 'Limit Provider Transfer In' | translate }}
                                    </span><br>
                                    <span class="no-wrap">
                                        <i matTooltip="Limit Provider Transfer Out" [ngClass]="row.limit_transfer_out > 0 ? 'fas fa-check kt-font-info' : 'fas fa-times kt-font-danger'"></i>
                                        {{ 'Limit Provider Transfer Out' | translate }}
                                    </span><br>
                                    <span class="no-wrap">
                                        <i matTooltip="Allow Cancel Promotion" [ngClass]="row.allow_cancel > 0 ? 'fas fa-check kt-font-info' : 'fas fa-times kt-font-danger'"></i>
                                        {{ 'Allow Cancel Promotion' | translate }}
                                    </span><br>
                                    <span class="no-wrap">
                                        <i matTooltip="{{ 'Visible by Affiliate' | translate }}" [ngClass]="row.visible_by_affiliate > 0 ? 'fas fa-check kt-font-info' : 'fas fa-times kt-font-danger'"></i>
                                        {{ 'Visible by Affiliate' | translate }}
                                    </span><br>
                                    <span class="no-wrap">
                                        <i matTooltip="{{ 'Last Deposit' | translate }}" [ngClass]="row.last_deposit > 0 ? 'fas fa-check kt-font-info' : 'fas fa-times kt-font-danger'"></i>
                                        {{ 'Last Deposit' | translate }}
                                    </span><br>
                                </td>
                                <td class="text-center">
                                    <button *ngIf="canViewPromotionCode" matTooltip="Settings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)"><i class="fas fa-cog"></i></button>
                                    <button *ngIf="canDuplicatePromotionCode" matTooltip="Duplicate" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('duplicate', row.id)"><i class="fa fa-clone"></i></button>
                                    <button *ngIf="canCheckMemberEligibility" matTooltip="Check Member Eligible" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('promo-eligible', row.id)"><i class="fas fa-stethoscope"></i></button>
                                </td>
                            </tr>
                          </tbody>
                          <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                          [pageSize]="pageSize"
                          [(page)]="page"
                          [maxSize]="maxSize"
                          [directionLinks]="true"
                          [boundaryLinks]="true"
                          [rotate]="true"
                          [collectionSize]="pagination.total"
                          (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                          <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                              <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                          </select>
                          <span class="pagination__desc">
                            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                          </span>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
  </div>

  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog()"></kt-swal-alert>
