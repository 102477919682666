import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { FooterSection } from '@core/models/footer-section.model';

@Injectable()
export class FooterSectionDataService extends DefaultDataService<FooterSection>{

    pagination: Pagination;
    messages$ = new Subject<any[]>();
  
    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('FooterSection', http, httpUrlGenerator, { root: '' });
    }

    
    getAll(): Observable<FooterSection[]> {
      return this.http.get<ApiResponse>('/footersection').pipe(
        tap(res => this.paginationInit(res)),
        map(res => res.data.rows)
      );
    }

    add(footersection: FooterSection): Observable<FooterSection>{
      return this.http.post<ApiResponse>(`/footersection/store`,footersection).pipe(
        tap( res => this.messages$.next(res.message)),
        concatMap((entity) => this.http.get(`/footersection/${entity.data.rows.id}`).pipe(
          map((row: ApiResponse) => row.data.rows)
        ))
      );
    }
  
    getWithQuery(pageParam: string): Observable<FooterSection[]> {
        return this.http.get<ApiResponse>(`/footersection${pageParam}`).pipe(
          tap(res => this.paginationInit(res)),
          map(res => res.data.rows)
        )
    }

    getById(id: number): Observable<FooterSection> {
        return this.http.get<ApiResponse>(`/footersection/${id}`).pipe(
          map(res => res.data.rows)
        );
      }

    getFooterType(param?: string): Observable<any> {
      return this.http.get<ApiResponse>(`/footer/sectiontype`).pipe(
        map(res => res.data.rows),
      );
    }

    updateFooterSection(id: number, footerSection: FooterSection){
      return this.http.put<ApiResponse>(`/footersection/${id}`, footerSection).pipe(
        tap(res => this.messages$.next(res.message)),
        map(res => res.data.rows)
      );
    }

    private paginationInit(res: any) {
      if (res) {
        this.pagination = res.data.paginations;
      }
    }

}