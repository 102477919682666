<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content" *ngIf="data.mode === 'viewAdCost'">
    <div class="modal-header">
        <h5 class="modal-title">Ad Cost</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
    </div>
    <div class="modal-body">
      <div class="row">
        <table class="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Campaign Name</th>
              <th>Currency</th>
              <th>Date</th>
              <th>Ad Cost USD</th>
              <th>Ad Cost Local</th>
              <th>Target Country</th>
              <th>Remarks</th>
              <th>Created By</th>
              <th class="date-column">Created At</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of data.adCost">
              <td>{{ row.id }}</td>
              <td>{{ row.campaign_name }}</td>
              <td>{{ row.currency_code }}</td>
              <td>{{ row.date | timezoneDate: 'YYYY-MM-DD HH:mm' }}</td>
              <td>{{ row.ad_cost_usd }}</td>
              <td>{{ row.ad_cost_local }}</td>
              <td>{{ row.target_country }}</td>
              <td>{{ row.remarks }}</td>
              <td>{{ row.created_by }}</td>
              <td>{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
  </div>

  <form class="kt-form" [formGroup]="form" *ngIf="data.mode === 'createAdCost' || data.mode === 'edit' || data.mode === 'create'">
    <div class="modal-content">
      <div class="modal-header">
          <h5 class="modal-title">{{ data.mode === 'createAdCost' || data.mode === 'create' ? 'Create' : 'Edit' }} Ad Cost</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
      </div>
      <div class="modal-body">
        <div class="row">
            <div class="col-md-12 form-group required" *ngIf="data.mode === 'create'">
              <label class="control-label">Campaign <span class="text-danger">*</span></label>
              <select class="form-control" formControlName="campaign_id">
                <option [value]="null" [disabled]="true">Please Select</option>
                <ng-container *ngFor="let value of dropdown.campaign | async">
                    <option [value]="value.id">{{ value.name }}</option>
                </ng-container>
            </select>
            </div>
            <div class="col-md-6 form-group required">
                <label class="control-label">Target Country <span class="text-danger">*</span></label>
                <input formControlName="target_country" type="target_country" class="col-12 form-control">
            </div>
            <div class="col-md-6 form-group">
                <label class="control-label">Currency <span class="text-danger">*</span></label>
                <select class="form-control" formControlName="currency_id">
                  <option [value]="null" [disabled]="true">Please Select</option>
                  <ng-container *ngFor="let value of dropdown.currencies">
                      <option [value]="value.id">{{ value.name }}</option>
                  </ng-container>
              </select>
            </div>
            <div class="col-md-6 form-group">
                <label class="control-label">Ad Cost USD <span class="text-danger">*</span></label>
                <input formControlName="ad_cost_usd" type="number" (wheel)="false" class="col-12 form-control">
            </div>
            <div class="col-md-6 form-group">
                <label class="control-label">Ad Cost Local <span class="text-danger">*</span></label>
                <input formControlName="ad_cost_local" type="number" (wheel)="false" class="col-12 form-control">
            </div>
            <div class="col-md-6 form-group">
                <label class="control-label">Date <span class="text-danger">*</span></label>
                <div class="input-group date" [owlDateTimeTrigger]="dateStartRef">
                  <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack" [ngModelOptions]="{standalone: true}">
                  <input type="text" formControlName="date" class="form-control">
                  <span class="input-group-append">
                      <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                  </span>
                  <owl-date-time #dateStartRef></owl-date-time>
                </div>
            </div>
            <div class="col-md-6 form-group">
                <label class="control-label">Remarks</label>
                <input formControlName="remarks" type="text" class="col-12 form-control">
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.adCost, data.mode)" #focusfield></kt-submit-button>
      </div>
    </div>
  </form>

</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>

