import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WhatsappBotEntityService } from '../../services/whatsapp-bot-entity.service';
import { WhatsappBot } from '@core/models/whatsapp-bot.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Subscription, forkJoin, of } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { WhatsappBotDataService } from '../../services/whatsapp-bot-data.service';
import { Status } from '@core/enums/status.enum';

@Component({
  templateUrl: './whatsapp-bot-edit.component.html',
  styleUrls: ['./whatsapp-bot-edit.component.scss']
})
export class WhatsappBotEditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    statuses:  this.dropdownHttpService.statuses
  };
  whatsappStatuses = Status;
  buttonLoading = false;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  messages$ = this.whatsappBotDataService.messages$;
  refreshStatus: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { whatsappBot: WhatsappBot, mode: string },
    public dialogRef: MatDialogRef<WhatsappBotEditDialogComponent>,
    private whatsappBotService: WhatsappBotEntityService,
    private whatsappBotDataService: WhatsappBotDataService,
    private dropdownHttpService: DropdownHttpService
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onSave(whatsappBot: WhatsappBot, mode?: string) {
    this.buttonLoading = true;
    const data = {
      id: whatsappBot ? whatsappBot.id : null,
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.whatsappBotService.update(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    let name = null,
    remark =  null,
    whatsappStatus = 1;
    
    if(this.data.mode === 'edit') {
      name = this.data.whatsappBot.name;
      remark = this.data.whatsappBot.remark;
      whatsappStatus = this.data.whatsappBot.status;

    }
    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      remark : new FormControl(remark),
      status: new FormControl(whatsappStatus)
    });

  }

}
