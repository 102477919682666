import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';
import { MerchantBank } from '@core/models/merchant-bank.model';

@Injectable()
export class MerchantBankHttpService {
  constructor(
    private http: HttpClient,
  ) {}

  /**
   * 
   * Normally bankType will tally with MerchantBank's method, but after bankType = 3, the order is slightly messed up.
   *
   * For QP1, bankType = 4 here is MerchantBank's METHOD_CRYPTO, and will tally with Bank's BANK_TYPE 5 & 6.
   * 
   * @param currencyId 
   * @param bankType 
   * @returns 
   */
  getMerchantBanks(currencyId?: number, bankType = 1): Observable<Dropdown[]> {
    // type : 1 = member | 2 = merchant | 3 = all
    // bank_type : 1 = bank | 2 = payment gateway | 3 = ewallet, 5: Payment Gateway (Crypto), 6: Crypto

    let queryParam;

    // Note that this bankType is actually 'method' based on MerchantBank.php, so it's METHOD_CRYPTO
    if (bankType == 4) {
      const cryptoBankTypeIds = [5]; // refer to Bank.php BANK_TYPE constant for the ID value, only take payment gateway crypto
      const bankTypeQueryParam = cryptoBankTypeIds.map((id, idx) => `bank_type[${idx}]=${id}`).join('&');

      queryParam = `&${bankTypeQueryParam}` + (currencyId ? `&currency_id=${currencyId}` : "");
    } else {
      queryParam = `&bank_type=${bankType}` + (currencyId ? `&currency_id=${currencyId}` : "");
    }

    return this.http
      .get<ApiResponse>(`/bank?paginate=false&type=2&status=1${queryParam}`)
      .pipe(map((res) => res.data.rows));
  }

  getMemberMerchantBanks(params?: string): Observable<Dropdown[]> {
    // type : 1 = member | 2 = merchant | 3 = all
    // bank_type : 1 = bank | 2 = payment gateway | 3 = ewallet
    return this.http
      .get<ApiResponse>(`/bank?type=1${params}`)
      .pipe(map((res) => res.data.rows));
  }

  getMemberMerchantPaymentGateway(currency_id?: number): Observable<Dropdown[]> {
    // type : 1 = member | 2 = merchant | 3 = all
    // bank_type : 1 = bank | 2 = payment gateway | 3 = ewallet
    const parameter = currency_id ? '&currency_id='+currency_id : '';
    return this.http
      .get<ApiResponse>(`/paymentgateway?status=1${parameter}`)
      .pipe(map((res) => res.data.rows));
  }

  getById(id: number): Observable<MerchantBank> {
    return this.http
      .get<ApiResponse>(`/merchantbank/${id}`)
      .pipe(map((res) => res.data.rows));
  }

  getMerchantBankAccounts(params?: string): Observable<Dropdown[]> {
    const parameter = params ? params : "";
    return this.http
      .get<ApiResponse>(`/merchantbank?paginate=false${parameter}`)
      .pipe(map((res) => res.data.rows));
  }

  getMerchantBanksByCurrency(
    currencyId: number,
    params: string
  ): Observable<MerchantBank[]> {
    return this.http
      .get<ApiResponse>(
        `/merchantbank?paginate=false&currency_id=${currencyId}${params}`
      )
      .pipe(map((res) => res.data.rows));
  }

  getMerchantBanksAccount(params?: string): Observable<any[]> {
    const parameter = params ? params : '';
    return this.http.get<ApiResponse>(`/merchantbank/accounts?paginate=false${parameter}`).pipe(
      map(res => res.data.rows)
    );
  }

  getMerchantBankTypes(): Observable<Dropdown[]> {
    return this.http
      .get<ApiResponse>(`/merchantbank/types`)
      .pipe(map((res) => res.data.rows));
  }
}
