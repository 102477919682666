<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'Create Member' | translate }} </h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="col-12 form-group row">
                                <label class="col-4 col-form-label"> {{ 'Username' | translate }} <span class="text-danger">*</span></label>
                                <input type="text" formControlName="username" class="col-8 form-control datetime">
                            </div>

                            <div class="col-12 form-group row">
                                <label class="col-4 col-form-label"> {{ 'Currency' | translate }} <span class="text-danger">*</span></label>
                                <select formControlName="currency_id" class="col-8 form-control" disabled>
                                    <option [value]="null" [disabled]="true">{{ 'Please Select' | translate }}</option>
                                    <option *ngFor="let value of dropdown.currencies | async" [value]="value.id">{{ value.name }}</option>
                                </select>
                            </div>

                            <div class="col-12 form-group row">
                                <label class="col-4 col-form-label">{{'Remarks' | translate }}</label>
                                <input type="text" formControlName="remarks" class="col-8 form-control">
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="col-12 form-group row">
                                <label class="col-4 col-form-label"> {{ 'Name' | translate }} <span class="text-danger">*</span></label>
                                <input type="text" formControlName="name" class="col-8 form-control">
                            </div>
                            <div class="col-12 form-group row">
                                <label class="col-4 col-form-label"> {{ 'Mobile' | translate }} <span class="text-danger">*</span></label>
                                <div class="col-8 pl-0 pr-0">
                                    <div class="input-group">
                                        <input type="text" formControlName="mobile" class="form-control" disabled>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i> {{ 'Close' | translate }}</button>
                <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>