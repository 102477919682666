import { Component, OnInit, ChangeDetectorRef, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { Pagination } from '@core/models/pagination.model';
import { Subscription, of } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { MessageEntityService } from './services/message-entity.service';
import { MessageDataService } from './services/message-data.service';
import * as moment from 'moment-timezone';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-member-message-history',
  templateUrl: './member-message-history.component.html',
  styleUrls: ['./member-message-history.component.scss']
})
export class MemberMessageHistoryComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() member: any;

  form: FormGroup;
  messageHistory$: any = [];
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = ``;
  perPageDropdown = this.dropdownHttpService.perPage;
  loading = false;
  dataLength: number;
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clearBtnLoading = false;
  searchBtnLoading = false;
  activeTab = 0;
  messageType: number;

  // permissions
  canViewSMSMessages: boolean;
  canViewInAppMessages: boolean;
  canViewWebPushMessages: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private messageDataService: MessageDataService,
    private messageEntityService: MessageEntityService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private sanitizer: DomSanitizer,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.ranges = this.transactionHttpService.ranges;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewSMSMessages = appPermissions.view_sms_messages;
      this.canViewInAppMessages = appPermissions.view_in_app_message;
      this.canViewWebPushMessages = appPermissions.view_web_push_message;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.messageHistory$ = [];
    this.loading = true;
    switch (this.activeTab) {
      case 0: // active tab = sms
        this.messageType = 2;
        break;
      case 1: // active tab = in-app
        this.messageType = 1;
        break;
      case 2: // active tab = web push
        this.messageType = 3;
        break;
    }
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        return this.messageDataService.getMemberMessages(`?member_account_id=${this.member.id}&page=${this.page}&perPage=${this.pageSize}${parameters}&message_type=${this.messageType}`).pipe(
          tap((res: any) => {
            this.messageHistory$ = res;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.messageDataService.pagination;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      keyword: null,
      start_datetime: null,
      end_datetime: null,
      defaultDate: null
    });
    this.onSubmit(true);
  }

  onSelectTab($event) {
    this.activeTab = $event.index;
    this.page = 1;
    this.form.patchValue({
      keyword: null,
      start_datetime: null,
      end_datetime: null,
      defaultDate: null
    });
    this.onSubmit(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.messageHistory$ = [];
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    switch (this.activeTab) {
      case 0: // active tab = sms
        this.messageType = 2;
        break;
      case 1: // active tab = in-app
        this.messageType = 1;
        break;
      case 2: // active tab = web push
        this.messageType = 3;
        break;
    }    this.loading = true;
    this.messageDataService.getMemberMessages(`?member_account_id=${this.member.id}&page=${this.page}&perPage=${this.pageSize}${params}&message_type=${this.messageType}`).pipe(
      tap(res => {
        this.messageHistory$ = res;
        this.dataLength = res.length;
        this.pagination = this.messageDataService.pagination;
        this.loading = false;
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  sanitize(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private formInit() {
    this.form = new FormGroup({
      keyword: new FormControl(null),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null),
    });
  }
}
