import { Component, OnDestroy, OnInit, ChangeDetectorRef  } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RaffleEvents } from '@core/models/raffle-events.model';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { RaffleEventsDataService } from '../services/raffle-events-data.service';
import { RaffleEventsEntityService } from '../services/raffle-events-entity.service';
import { Observable, Subscription, of } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';
import { RaffleEventsEditDialogComponent } from './dialogs/raffle-event-detail-edit/raffle-event-edit.component';
import { AppPermissionService } from "@core/services/app-permission.service";

@Component({
  selector: 'kt-raffle-event-detail',
  templateUrl: './raffle-event-detail.component.html',
  styleUrls: ['./raffle-event-detail.component.scss']
})
export class RaffleEventDetailComponent implements OnInit, OnDestroy {

  form: FormGroup;
  events$: Observable<RaffleEvents[]>;
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
    ticketFrequncy: this.dropdownHttpService.ticketFrequncy,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: [
      {
        name: 'Upcoming', value: 0, checked: true
      },
      {
        name: 'Ongoing', value: 1, checked: true
      },
      {
        name: 'Ended', value: 2,checked: true
      },
      {
        name: 'Inactive', value: 3, checked: false
      }
    ],
  };

  dropdownSettings = {
    autoPosition: true,
    maxHeight: 150,
    singleSelection: false,
    text: 'Please Select',
    enableFilterSelectAll: true,
    enableSearchFilter: false,
    classes: 'dropdown',
    primaryKey: 'id',
    labelKey: 'name',
    lazyLoading: false,
    noDataLabel: '',
    showCheckbox: false
  };

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  ranges = this.transactionHttpService.ranges;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dataLength = 0;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  createBtnLoading = false;
  currencies = JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies'));

  // permissions
  canCreateEvent: boolean;
  canViewEventDetails: boolean;

  searchStatus = [0,1,2];

  private subscriptions = new Subscription();
  private localesSub = new Subscription();

  currenciesSelectedItems = [];
  ticketFrequncyItems = [];

  sortingConfig = {
    'id': 'asc',
    'name': 'desc',
    'start_datetime': 'desc',
    'reset_frequency': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc'
  }

  default_sort_by = 'id';
  default_sort_order = 'desc';

  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order,
  };

  eventNamedropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'name',
    labelKey: 'name',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    maxHeight: 150
  };

  eventNameSelectedItems = [];
  eventNameList = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private raffleEventsDataService: RaffleEventsDataService,
    private raffleEventsEntityService: RaffleEventsEntityService,
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private transactionHttpService: TransactionHttpService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {

    this.raffleEventsDataService.getALL(null).subscribe(res => {
      const raffleEventNameList = [];
      res.forEach(item => {
        raffleEventNameList.push({
          id: item.id,
          name: item.name
        });
      })
      this.eventNameList = raffleEventNameList;
      this.cdr.detectChanges(); 
    })

    this.createBtnLoading = true;
    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.raffleEventsDataService.locales$.next(res);
      this.createBtnLoading = false;
    })).subscribe();

    this.formInit();
    this.pagination = this.raffleEventsDataService.pagination;
    this.onSubmit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateEvent = appPermissions.create_raffle_event;
      this.canViewEventDetails =  appPermissions.view_raffle_event_details;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.localesSub.unsubscribe();
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClearDate() {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.dataLength = 0;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        // Remove unused param
        Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] === 'Invalid date') && delete data[key]);
        // const statusParams = this.generateStatusParams();
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.events$ = this.raffleEventsEntityService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.dataLength = res.length;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.page = 1;
            this.pagination = this.raffleEventsDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.searchStatus = [0,1,2];
    this.clearBtnLoading = true;
    this.form.patchValue({
      name: null,
      date_type: 'event_date',
      defaultDate: null,
      start_datetime: null,
      end_datetime: null,
      currency_id: null,
      reset_frequency: null,
      status: this.searchStatus
    });

    this.dropdown.statuses.forEach((child) => {
      if(child.name == 'Inactive'){
        child.checked = false;
      }else{
        child.checked = true;
      } 
    });

    this.currenciesSelectedItems = [];
    this.ticketFrequncyItems = [];
    this.eventNameSelectedItems = [];
    this.onSubmit(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.events$ = this.raffleEventsEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.dataLength = res.length;
        this.loading = false;
        this.pagination = this.raffleEventsDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(mode: string, row?: any) {
    this.openDialogBy(RaffleEventsEditDialogComponent, { row: row,mode: mode });
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private openDialogBy(componentRef: any, data?: { row: any, mode?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '1200px',
      data: {
        row: data.row,
        mode: data.mode,
        currencies: this.dropdown.currencies ? this.dropdown.currencies : this.currencies
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();

        this.raffleEventsDataService.getALL(null).subscribe(res => {
          const raffleEventNameList = [];
          res.forEach(item => {
            raffleEventNameList.push({
              id: item.id,
              name: item.name
            });
          })
          this.eventNameList = raffleEventNameList;
          this.cdr.detectChanges(); 
        })
      }
    });
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      date_type: new FormControl('event_date'),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      currency_id: new FormControl(null),
      reset_frequency: new FormControl(null),
      status: new FormControl(this.searchStatus)
    });
  }

  onSearchStatus(event: any, id: number, ind: number) {
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }
    this.form.patchValue({ status: this.searchStatus });
  }

  private generateStatusParams() {
    return {
      newStatusParams: this.searchStatus.length > 0 ? '&' + Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&') : ''
    };
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

}
