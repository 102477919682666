import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppState } from '@store/reducers';
import { Store } from '@ngrx/store';
import { AuthActions } from '@core/store/auth/auth-action.types';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { EventEmitterService } from '@core/services/event-emitter.service';
import { SKIP_ERROR_INTERCEPTOR_HEADER } from '@utils/constants';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {

  constructor(private matSnackBar: MatSnackBar, private store: Store<AppState>, private router: Router, private eventEmitterService: EventEmitterService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip if the request has the 'Skip-Error-Interceptor' header
    // Let client handle the error themselves, e.g. can obtain data
    if (request.headers.has(SKIP_ERROR_INTERCEPTOR_HEADER)) {
      const newRequest = request.clone({
        headers: request.headers.delete(SKIP_ERROR_INTERCEPTOR_HEADER)
      });

      return next.handle(newRequest);
    }

    let message: string;
    const buildMessage = (stack: any[] | string) => {
      let result = '';
      if (stack !== 'The given data was invalid.') {
        if (stack.length > 0 && typeof stack !== 'string') {
          stack.map((m: any) => result += `<li>${m}</li>`);
        } else  if(typeof stack === 'string' && !message.includes(stack)){
          result += `<li>${stack}</li>`;
        }
      }
      return result;
    };
    return next.handle(request).pipe(
      timeout(100000), // 60 seconds timeout
      catchError((e: HttpErrorResponse) => {
        // Handle timeout error
        if (e instanceof TimeoutError) {
          message = '<ul><li>Timeout error in fetching data. Please try again</li></ul>';
          Swal.fire('System Message', message, 'error');
          return throwError(message);
        }
        // Normal error 
        else {
          const system = e.error;
          if (e.status !== 403) {
            message = '<ul>';
            if ((!!system?.errors)) {
              const errors = system.errors;
              Object.keys(errors).forEach(key => message += buildMessage(errors[key]));
            } else { // skip 'message'/'messages' if 'errors' array exist
              message += buildMessage(system.message ?? system.messages);
            }
            message += '</ul>';
            // identical registration page settings error 
            if (e.status === 409) {
              this.eventEmitterService.onDetectIdenticalSettings(system);
            } else {
              Swal.fire('System Message', message, 'error');
            }
            return throwError(system);
          } else {
            this.store.dispatch(AuthActions.logout());
          }
        }
      })
    );
  }
}
