import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { SubheaderService } from '@core/_base/layout';
import { MatDialog } from '@angular/material/dialog';
import { WhatsappNumberEditDialogComponent } from './dialogs/whatsapp-number-edit/whatsapp-number-edit.component';
import { WhatsappNumberEntityService } from './services/whatsapp-number-entity.service';
import { WhatsappNumber } from '@core/models/whatsapp-number.model';
import { Observable, Subscription, interval, of } from 'rxjs';
import { tap, map, exhaustMap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { WhatsappNumberDataService } from './services/whatsapp-number-data.service';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Status } from '@core/enums/status.enum';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  templateUrl: './whatsapp-number.component.html',
  styleUrls: ['./whatsapp-number.component.scss']
})
export class WhatsappNumberComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  dropdown = {
    statuses:  this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage,
    whatsappCurrencies: this.dropdownHttpService.whatsappCurrencies
  };
  status = Status;
  whatsappNumbers$: Observable<WhatsappNumber[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';

  locales: any;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  private subscription = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private whatsappNumberService: WhatsappNumberEntityService,
    private whatsappNumberDataService: WhatsappNumberDataService,
    private dropdownHttpService: DropdownHttpService
  ) {

    this.dropdownHttpService.locales.subscribe(res => this.locales = res);
   }

  ngOnInit() {
    this.reload();
    this.formInit();
    this.pagination = this.whatsappNumberDataService.pagination;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onOpenDialog(type: string, whatsappNumberId?: number) {

    if(whatsappNumberId) {
      const whatsappNumber = this.whatsappNumberDataService.getById(whatsappNumberId);
      this.subscription = whatsappNumber.pipe(
        tap( (res) => {
        switch (type) {
          case 'edit':
            this.openDialogBy(WhatsappNumberEditDialogComponent, { whatsappNumber: res, mode: 'edit' });
            break;
        }
      })
      )
      .subscribe();
    } else {
      this.openDialogBy(WhatsappNumberEditDialogComponent, { mode: 'create' });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
  this.loading = true;
  pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.whatsappNumbers$ = this.whatsappNumberService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.whatsappNumberDataService.pagination;
        this.loading = false;
        this.dataLength = res.length;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event){
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.whatsappNumbers$ = this.whatsappNumberService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.whatsappNumberDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { whatsappNumber?: any, mode?: any}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        whatsappNumber: data.whatsappNumber,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result === true){
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      status: new FormControl('all'),
      keyword: new FormControl(null),
      currency_id: new FormControl('all')
    });
  }

  private refreshEvery(seconds: number) {
    const refreshSource = interval(seconds * 1000);
    refreshSource.subscribe(() => {
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
          this.reload();
        }
      });
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key =>  (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

}
