import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Pagination } from '@core/models/pagination.model';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ApplicationPermissionEditDialogComponent } from './dialogs/application-permission-edit.component';
import { ApplicationHttpService } from '@core/services/application-http.service';
import { ApplicationSection } from '@core/models/application-section.model';
import { catchError, map, tap } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';
import { ApplicationPermissionDetails } from '@core/models/application-permission-details.model';
import { AppPermissionService } from '@core/services/app-permission.service';
@Component({
  templateUrl: './application-permissions.component.html',
  styleUrls: ['./application-permissions.component.scss']
})
export class ApplicationPermissionsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  formSubSection: number[] = [];
  params = '';

  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;

  sortingConfig = {
    'id': 'asc',            // ID
    'action_title': 'asc',  // Action Title
    'display_title': 'asc', // Display Title
    'description': 'asc',   // Description
    'section': 'asc',       // Section
    'created_by': 'asc',    // Created By
    'updated_by': 'asc',    // Updated By
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  permissions: any = [];

  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;

  allSection: ApplicationSection[] = this.applicationHttpService.allSection;
  subSectionLvlOne: ApplicationSection[] = [];
  subSectionLvlTwo: ApplicationSection[] = [];

  // permissions
  canEditPermission: boolean;

  private subscriptions = new Subscription();

  constructor(
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private applicationHttpService: ApplicationHttpService,
    private appPermissionService: AppPermissionService,
  ) {
    if (this.allSection == undefined) {
      this.applicationHttpService.getAllSection().subscribe(() => {
        this.allSection = this.applicationHttpService.allSection;
      });
    }
  }

  ngOnInit() {
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditPermission = appPermissions.edit_permission;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onViewPageBy() {
    this.loading = true;
    this.loadingBar.start();
    this.permissions = [];

    this.applicationHttpService.getPermissions(`page=${this.page}&perPage=${this.pageSize}&${this.params}`).pipe(
      tap(res => {
        this.permissions = res;
        this.loading = false;
        this.loadingBar.complete();
        this.dataLength = res.length;
        this.pagination = this.applicationHttpService.permissionPagination;
      }),
      catchError(err => {
        this.loading = false;
        this.loadingBar.complete();
        throw err;
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy();
  }

  onOpenDialog(permissionID: any) {
    this.applicationHttpService.getPermissionDetails(permissionID).subscribe(res => {
      this.openDialogBy(ApplicationPermissionEditDialogComponent, { permissionDetails: res });
    })
  }

  onSortColumn(column: string) {
    if (this.sortingSelection.sort_by == column) {
      this.sortingSelection.sort_order = this.sortingSelection.sort_order == 'desc' ? 'asc' : 'desc';
    } else {
      this.sortingSelection.sort_by = column;
      this.sortingSelection.sort_order = 'desc';
    }
    this.form.patchValue({
      'sort_by': this.sortingSelection.sort_by,
      'sort_order': this.sortingSelection.sort_order,
    });
    this.onSubmit();
  }

  onSubmit() {
    this.loading = true;
    this.loadingBar.start();
    this.searchBtnLoading = true;
    this.page = 1
    this.permissions = [];

    if (this.formSubSection.length > 0) {
      this.form.patchValue({ 'sub_section': this.formSubSection })
    } else {
      this.form.patchValue({ 'sub_section': null })
    }

    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap(data => {
        this.params = Object.keys(data)
          .filter(key => data[key] && data[key] != 'null')
          .map(key => {
            if (data[key] instanceof Array) {
              let temp = '';
              data[key].forEach((item, index) => {
                temp += (index > 0 ? '&' : '') + (key + '[]=' + item)
              })
              return temp;
            } else {
              return key + '=' + data[key]
            }
          }).join('&');

        this.applicationHttpService.getPermissions(`page=${this.page}&perPage=${this.pageSize}&${this.params}`).pipe(
          tap(res => {
            this.permissions = res;
            this.loading = false;
            this.loadingBar.complete();
            this.searchBtnLoading = false;
            this.clearBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.applicationHttpService.permissionPagination;
          }),
          catchError(err => {
            this.loading = false;
            this.loadingBar.complete();
            this.searchBtnLoading = false;
            this.clearBtnLoading = false;
            throw err;
          })
        ).subscribe();
      })
    ).subscribe();
  }

  onClear() {
    // Reset sorting
    this.sortingSelection.sort_by = 'id';
    this.sortingSelection.sort_order = 'desc';
    this.form.patchValue({
      'sort_by': this.sortingSelection.sort_by,
      'sort_order': this.sortingSelection.sort_order,
    });
    // End

    // Reset sub section value
    this.formSubSection = [];
    this.subSectionLvlOne = [];
    this.subSectionLvlTwo = [];
    // End

    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit();
  }

  onSectionChange() {
    var section = this.allSection.find(x => x.id == this.form.controls['section_id'].value);
    this.formSubSection = [];
    this.subSectionLvlOne = [];
    this.subSectionLvlTwo = [];

    if (section && section.children.length > 0) {
      this.subSectionLvlOne = section.children;
    }
  }

  onSubSectionChange(level: number, event: any) {
    if (level == 1) {
      var levelOneId = event.target.value,
        subSectionOne = this.subSectionLvlOne.find(x => x.id == levelOneId);

      this.formSubSection = levelOneId == 'null' ? [] : [levelOneId];

      if (subSectionOne && subSectionOne.children.length > 0) {
        this.subSectionLvlTwo = subSectionOne.children;
      } else {
        this.subSectionLvlTwo = [];
      }
    } else {
      var levelTwoId = event.target.value;
      if (levelTwoId != 'null') {
        this.formSubSection.length > 1 ? this.formSubSection[1] = levelTwoId : this.formSubSection.push(levelTwoId);
      }
    }
  }

  private formInit() {
    this.form = new FormGroup({
      action_title: new FormControl(null),
      display_title: new FormControl(null),
      section_id: new FormControl(null),
      sub_section: new FormControl(null),
      sort_by: new FormControl(this.sortingSelection.sort_by),
      sort_order: new FormControl(this.sortingSelection.sort_order)
    });
  }

  private openDialogBy(componentRef: any, data?: { permissionDetails: ApplicationPermissionDetails }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        permissionDetails: data.permissionDetails,
        // languages: res
      }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.onViewPageBy();
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }
}
