import { Component, OnInit, Inject, ChangeDetectorRef} from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FooterSectionDataService } from '../services/footer-section-data.service';
import { FooterSectionEntityService } from '../services/footer-section-entity.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Subscription, forkJoin } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import Swal from "sweetalert2";
import { AppPermissionService } from '@core/services/app-permission.service';
@Component({
    selector: 'kt-footer-section-edit',
    templateUrl: './footer-section-edit.component.html',
    styleUrls: ['./footer-section-edit.component.scss']
})
export class FooterSectionEditDialogComponent implements OnInit {

    form: FormGroup;
    messages$ = this.footerSectionDataService.messages$;
    buttonLoading = true;
    refreshStatus: boolean;
    private subscription = new Subscription();
    private subscriptions = new Subscription();
    private unsubscribe: Subscription[] = [];
    private localesSub = new Subscription();
    display_title  = 0;
    locales = this.dropdownHttpService.locales;
    languages = [];
    dropdown = {
        statuses: this.dropdownHttpService.statuses,
        display_titles: this.dropdownHttpService.display_titles,
        section_types:this.dropdownHttpService.footerTypes,
        locales: this.dropdownHttpService.locales,
    };

    merchantDropdownSettings = {};
    merchantDropdownList = [];
    merchantSelectedItems = [];
    checkValidation = false;

    // permissions
    canEditFooterSection: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { rows: any; mode: string},
        public dialogRef: MatDialogRef<FooterSectionEditDialogComponent>,
        private footerSectionDataService: FooterSectionDataService,
        private dropdownHttpService: DropdownHttpService,
        private cdr: ChangeDetectorRef,
        private appPermissionService: AppPermissionService,
    ) { }

    ngOnInit() {
      this.dropdownHttpService.merchantSites.subscribe(res => {
          this.merchantDropdownList =  res;
          this.merchantSelectedItems.push(res.find(v => v.id === 1));
      });

      this.formInit();
      this.addSectionTitlesForm();
      this.merchantDropdownSettings = {
        autoPosition: true,
        maxHeight: 150,
        singleSelection: false,
        text: 'Please Select',
        enableFilterSelectAll: true,
        enableSearchFilter: false,
        classes: 'dropdown',
        primaryKey: 'id',
        labelKey: 'name',
        lazyLoading: true,
        noDataLabel: '',
        showCheckbox: false
      };

      const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
        this.canEditFooterSection = appPermissions.edit_footer_section;
      });

      this.subscriptions.add(apSub);
    }

    onCloseDialog(event?: Event) {
        this.dialogRef.close();
    }

    private formInit() {
        let status = this.data.mode === 'edit' ? this.data.rows.status : 1;
        this.form = new FormGroup({
          name: new FormControl(null, [Validators.required]),
          section_type_id: new FormControl(null, [Validators.required]),
          display_title: new FormControl(this.display_title),
          status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false }),
           // here to hard code the merchant site
          merchant_id: new FormControl([1], [Validators.required])
        });

        this.form.markAllAsTouched();
        this.form.markAsDirty();
    }

    private addSectionTitlesForm(){
        this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
          this.languages = res;
          const buildSubDetails = () => {
            let contents = {};
            res.map((element, index) => {
              const contentsGroup = new FormGroup({
                setting_locale_id: new FormControl(element.id),
                name: new FormControl(null),
                same_as_title: new FormControl(0),
              });
              contents = { ...contents, [element.id]: contentsGroup };
            });
            return contents;
          };
          this.form.addControl('section_titles', new FormGroup(buildSubDetails()));

          if (this.data.mode === 'edit') {
            this.form.patchValue({
                name: this.data.rows.name,
                section_type_id: this.data.rows.section_type_id,
                display_title: this.data.rows.display_title,
                merchant_id: this.data.rows.merchant_id.split(",").map(Number),
                status: this.data.rows.status
              });
            this.display_title  = this.data.rows.display_title;
            this.data.rows.section_titles.map((res, index)=> {
              const detailsGroup = {
                setting_locale_id: res.setting_locale_id,
                name: res.same_as_title ? this.data.rows.name : res.name,
                same_as_title: res.same_as_title,
              }
              this.form.patchValue({
                section_titles : {[res.setting_locale_id]: detailsGroup}
              });
            });
          }

          this.form.get('name').valueChanges.subscribe(value => {
            Object.keys(this.form.get('section_titles').value).forEach(key => {
              if (this.form.get('section_titles.'+key+'.same_as_title').value == true) {
                  const detailsGroup = {
                    name: value
                  }
                  this.form.patchValue({
                    section_titles : {[key]: detailsGroup}
                  });
              }
            });
          });

          this.buttonLoading = false;
          this.cdr.detectChanges();
        })).subscribe();
      }

    ngOnDestroy() {
        this.localesSub.unsubscribe();
        this.subscription.unsubscribe();
        this.subscriptions.unsubscribe();
        this.onRefresh();
    }

    onSave(){
        this.checkValidation = true;
        this.buttonLoading = true;

        const data = {
          ...this.form.value
        };
        data.display_title = data.display_title ? 1 : 0;

        Object.keys(data.section_titles).forEach((key) => {
            data.section_titles[key].same_as_title  = data.section_titles[key].same_as_title ? 1 : 0;
        });

        if (this.form.valid) {

          if( this.data.mode === 'edit' && this.data.rows && this.data.rows.display.length > 0 && this.data.rows.status == 1 && data.status == 0){
            Swal.fire({
              title: "The Footer Section is currently in use",
              text: `Do you want to save the changes? This action will remove corresponding footer content from the user portal.`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
              reverseButtons: true,
            }).then((result) => {
              if (result.value) {
                // To set "Save" button to disable (To prevent call API in multiple times when double click)
                this.form.setErrors({ 'invalid': true });
                switch (this.data.mode) {
                  case 'edit':
                    this.subscription = forkJoin([
                      this.footerSectionDataService.updateFooterSection(this.data.rows.id, data).pipe(
                        tap(() => {
                          this.buttonLoading = false;
                          this.checkValidation = false;
                          // To enable "Save" button after get response
                          this.form.setErrors(null);
                        }),
                        catchError((error) => {
                          this.buttonLoading = false;
                          this.checkValidation = false;
                          // To enable "Save" button after get response
                          this.form.setErrors(null);
                          throw error;
                        })
                      ),
                      this.footerSectionDataService.messages$
                    ]).subscribe();
                    break;
                  case 'create':
                    this.subscription = forkJoin([
                      this.footerSectionDataService.add(data).pipe(
                        tap(() => {
                          this.checkValidation = false;
                          this.buttonLoading = false;
                          this.form.setErrors(null);
                        }),
                        catchError((error) => {
                          this.checkValidation = false;
                          this.buttonLoading = false;
                          this.form.setErrors(null);
                          throw error;
                        })
                      ),
                      this.footerSectionDataService.messages$
                    ]).subscribe();
                    break;
                }
                this.refreshStatus = true;
              }else{
                this.checkValidation = false;
                this.buttonLoading = false;
                this.form.setErrors(null);
              }
            });
          }else{
            // To set "Save" button to disable (To prevent call API in multiple times when double click)
            this.form.setErrors({ 'invalid': true });
            switch (this.data.mode) {
              case 'edit':
                this.subscription = forkJoin([
                  this.footerSectionDataService.updateFooterSection(this.data.rows.id, data).pipe(
                    tap(() => {
                      this.buttonLoading = false;
                      this.checkValidation = false;
                      // To enable "Save" button after get response
                      this.form.setErrors(null);
                    }),
                    catchError((error) => {
                      this.buttonLoading = false;
                      this.checkValidation = false;
                      // To enable "Save" button after get response
                      this.form.setErrors(null);
                      throw error;
                    })
                  ),
                  this.footerSectionDataService.messages$
                ]).subscribe();
                break;
              case 'create':
                this.subscription = forkJoin([
                  this.footerSectionDataService.add(data).pipe(
                    tap(() => {
                      this.checkValidation = false;
                      this.buttonLoading = false;
                      this.form.setErrors(null);
                    }),
                    catchError((error) => {
                      this.checkValidation = false;
                      this.buttonLoading = false;
                      this.form.setErrors(null);
                      throw error;
                    })
                  ),
                  this.footerSectionDataService.messages$
                ]).subscribe();
                break;
            }
            this.refreshStatus = true;
          }
        }else{
          this.buttonLoading = false;
        }
    }

    onRefresh(){
        if (this.refreshStatus === true){
          this.dialogRef.close(true);
        }
    }

    checkSameAsTitle(event:any, key){
      let detailsGroup = {}
      if (event.target.checked == true){
        detailsGroup = {
          name: this.form.get('name').value
        }
      }else{
        detailsGroup = {
          name: ""
        }
      }

      this.form.patchValue({
        section_titles : {[key]: detailsGroup}
      });
    }

    onToggleStatus(value: any){
      this.display_title  = value == 1 ? 0 : 1;
      this.form.patchValue({
        display_title: this.display_title
      });
    }
}


