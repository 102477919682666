<div class="kt-section">
    <div class="kt-section__content">
        <!-- Summary -->
        <ng-container *ngIf="summary && listType === 'all-member-report'">
            <h5 class="summary">Summary</h5>
            <!-- Table -->
            <div class="table-responsive summary-table">
                <table class="table table-bordered table-hover table-striped">
                    <!-- Header -->
                    <thead>
                        <tr class="text-top">
                            <th rowspan="2" class="text-center">Currency</th>
                            <th colspan="4" class="text-center">Transfer</th>
                            <th colspan="2" class="text-center">Deposit</th>
                            <th colspan="2" class="text-center">Withdrawal</th>
                            <th colspan="9" class="text-center">Bets</th>
                            <th colspan="3" class="text-center">Promotion</th>
                            <th colspan="2" class="text-center">Company Processing Fee</th>
                            <th rowspan="2" class="text-center">Net Game Revenue (NGR)</th>
                        </tr>
                        <tr>
                            <th class="text-center">Total In<br>Count</th>
                            <th class="text-center">Total In<br>Amount</th>
                            <th class="text-center">Total Out<br>Count</th>
                            <th class="text-center">Total Out<br>Amount</th>
                            <th class="text-center">Total Deposit<br>Count</th>
                            <th class="text-center">Total Deposit<br>Amount</th>
                            <th class="text-center">Total Withdrawal<br>Count</th>
                            <th class="text-center">Total Withdrawal<br>Amount</th>
                            <th class="text-center">Total Bet Count</th>
                            <th class="text-center">Total Bet Amount</th>
                            <th class="text-center">Total Valid Bet Amount</th>
                            <th class="text-center">Player W/L</th>
                            <th class="text-center">Total <br />Jackpot Contribution</th>
                            <th class="text-center">Total <br />Jackpot Win</th>
                            <th class="text-center">Nett <br />Jackpot</th>
                            <th class="text-center">Game Bet <br />Amount</th>
                            <th class="text-center">Gross Game Revenue <br />(GGR)</th>
                            <th class="text-center">Total Bonus</th>
                            <th class="text-center">Total Cancelled Bonus</th>
                            <th class="text-center">Total <br />Rebate</th>
                            <th class="text-center">Total Deposit Processing Fee</th>
                            <th class="text-center">Total Withdrawal Processing Fee</th>
                        </tr>
                    </thead>
                    <!-- Body -->
                    <tbody>
                        <tr>
                            <td class="text-center">{{ summary.currency_code }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.transfer_in_total) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.transfer_in_total_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.transfer_out_total) | number : '1.0-0'  }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.transfer_out_total_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.deposit_total) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.deposit_total_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.withdraw_total) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.withdraw_total_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_bet_count) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_bet_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_valid_bet_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.player_win_lose) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_jackpot_contribution) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_jackpot_win) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.nett_jackpot) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.game_bet_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.gross_game_revenue) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_bonus) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_cancelled_bonus) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_rebate) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_deposit_processing_fee) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_withdrawal_processing_fee) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.nett_game_revenue) | number : '1.2-2' }}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!onSearch" class="pre-search-table">
                        <tr>
                            <td colspan="30">
                                <span *ngIf="!loading && !isModal">Press "Search" to load reports.</span>
                                <div class="spinner-wrapper" *ngIf="loading">
                                    <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>

        <!-- Content -->
        <div class="table-responsive">
            <!-- Table -->
            <table class="table table-bordered table-hover table-striped">
                <!-- Header -->
                <thead>
                    <tr class="text-top">
                        <!-- Only Member Report -->
                        <ng-container *ngIf="listType === 'member-report'">
                            <th rowspan="2" class="text-center">Currency</th>
                            <th rowspan="2" class="text-center">Game Provider</th>
                            <th rowspan="2">Username</th>
                        </ng-container>
                        <!-- Only All Member Report -->
                        <ng-container *ngIf="listType === 'all-member-report'">
                            <th *ngIf="isAgent" rowspan="2">Agent</th>
                            <th rowspan="2">Member Group</th>
                            <th rowspan="2">Username</th>
                            <th rowspan="2" class="text-center">Currency</th>
                        </ng-container>
                        <!-- Common -->
                        <th colspan="4" class="text-center">Transfer</th>
                        <th colspan="2" class="text-center">Deposit</th>
                        <th colspan="2" class="text-center">Withdrawal</th>
                        <th colspan="9" class="text-center">Bets</th>
                        <th colspan="3" class="text-center">Promotion</th>
                        <th colspan="2" class="text-center">Company Processing Fee</th>
                        <th rowspan="2">Net Game Revenue (NGR)</th>
                        <th *ngIf="isAgent" rowspan="2" class="text-center">Total Commission</th>
                    </tr>
                    <tr>
                        <th class="text-center">Total <br />In Count</th>
                        <th class="text-center">Total <br />In Amount</th>
                        <th class="text-center">Total <br />Out Count</th>
                        <th class="text-center">Total <br />Out Amount</th>
                        <th class="text-center">Total Deposit<br>Count</th>
                        <th class="text-center">Total Deposit<br>Amount</th>
                        <th class="text-center">Total Withdrawal<br>Count</th>
                        <th class="text-center">Total Withdrawal<br>Amount</th>
                        <th class="text-center">Total <br />Bet Count</th>
                        <th class="text-center">Total <br />Bet Amount</th>
                        <th class="text-center">Total <br />Valid Bet Amount</th>
                        <th class="text-center">Player <br />W/L</th>
                        <th class="text-center">Total <br />Jackpot Contribution</th>
                        <th class="text-center">Total <br />Jackpot Win</th>
                        <th class="text-center">Nett <br />Jackpot</th>
                        <th class="text-center">Game Bet <br />Amount</th>
                        <th class="text-center">Gross Game Revenue <br />(GGR)</th>
                        <th class="text-center">Total <br />Bonus</th>
                        <th class="text-center">Total <br />Cancelled Bonus</th>
                        <th class="text-center">Total <br />Rebate</th>
                        <th class="text-center">Total Deposit Processing Fee</th>
                        <th class="text-center">Total Withdrawal Processing Fee</th>
                    </tr>
                </thead>

                <!-- Body -->
                <tbody *ngIf="(data | async) as rows">
                    <!-- Member report -->
                    <ng-container *ngIf="listType === 'member-report'">
                        <tr *ngFor="let row of rows">
                            <td class="text-center">{{ row.currency_code }}</td>
                            <td class="text-center sort-enabled">{{ row.game_provider_code }}</td>
                            <td >
                                <a [ngClass]="row.game_provider_code === '-' ? '' : 'text-primary font-weight-bold'" (click)="row?.game_provider_code !== '-' ? onOpenDialog(row) : ''">{{ row.game_account }} </a>
                            </td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_number_of_transfer_in) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_transfer_in_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_number_of_transfer_out) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_transfer_out_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_number_of_deposit) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_deposit) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_number_of_withdrawal) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_withdrawal) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_bet_count) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_bet_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_valid_bet_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.win_lose) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_jackpot_contribution) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_jackpot_win) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.nett_jackpot) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.game_bet_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.gross_game_revenue) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_bonus) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_cancelled_bonus) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_rebate) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_deposit_processing_fee) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_withdrawal_processing_fee) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.nett_game_revenue) | number : '1.2-2' }}</td>
                        </tr>
                        <tr class="summary-row" *ngIf="rows.length > 0 && summary">
                            <td class="text-center">{{ summary.currency_code }}</td>
                            <td class="text-center">Summary</td>
                            <td>{{ summary.username }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_number_of_transfer_in) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_transfer_in_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_number_of_transfer_out) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_transfer_out_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_number_of_deposit) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_deposit) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_number_of_withdrawal) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_withdrawal) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_bet_count) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_bet_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_valid_bet_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.win_lose) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_jackpot_contribution) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_jackpot_win) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.nett_jackpot) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.game_bet_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.gross_game_revenue) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_bonus) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_cancelled_bonus) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_rebate) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_deposit_processing_fee) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.total_withdrawal_processing_fee) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(summary.nett_game_revenue) | number : '1.2-2' }}</td>
                        </tr>
                    </ng-container>
                    <!-- All Member Report -->
                    <ng-container *ngIf="listType === 'all-member-report'">
                        <tr *ngFor="let row of rows">
                            <td *ngIf="isAgent">{{row.agent}}</td>
                            <td>{{row.member_group}}</td>
                            <td><a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog(row)">{{row.username}}</a></td>
                            <td class="text-center">{{ row.currency_code}}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.transfer_in_total) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.transfer_in_total_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.transfer_out_total) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.transfer_out_total_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.deposit_total) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.deposit_total_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.withdraw_total) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.withdraw_total_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_bet_count) | number : '1.0-0' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_bet_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_valid_bet_amount) | number : '1.2-2'}}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.player_win_lose) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_jackpot_contribution) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_jackpot_win) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.nett_jackpot) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.game_bet_amount) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.gross_game_revenue) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_bonus) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_cancelled_bonus) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_rebate) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_deposit_processing_fee) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.total_withdrawal_processing_fee) | number : '1.2-2' }}</td>
                            <td class="text-right">{{ sanitizeNumericField(row.nett_game_revenue) | number : '1.2-2'}}</td>
                            <td *ngIf="isAgent" class="text-right">{{ sanitizeNumericField(row.total_commission) | number : '1.2-2'}}</td>
                        </tr>
                    </ng-container>
                    <!-- If no record -->
                    <kt-fallback-row [collection]="rows"></kt-fallback-row>
                </tbody>
                <tbody *ngIf="!onSearch" class="pre-search-table">
                    <tr>
                        <td colspan="30">
                            <span *ngIf="!loading && !isModal">Press "Search" to load reports.</span>
                            <div class="spinner-wrapper" *ngIf="loading">
                                <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Pagination -->
        <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                </select>
                <span class="pagination__desc">
                    Showing {{pagination.from}} to {{pagination.to}} of
                    {{ pagination.total | number : '1.0' }} records
                </span>
            </div>
        </div>

    </div>
</div>
