import { LoadingBarService } from '@ngx-loading-bar/core';
import { tap } from 'rxjs/operators';
import { AgentTreeViewDataService } from './services/agent-tree-view-data.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TreeViewComponent, NodeSelectEventArgs } from '@syncfusion/ej2-angular-navigations';
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';

@Component({
  selector: 'kt-agent-tree-view',
  templateUrl: './agent-tree-view.component.html',
  styleUrls: ['./agent-tree-view.component.scss']
})
export class AgentTreeViewComponent implements OnInit {
  @ViewChild ('listTreeObj') listTreeObj: TreeViewComponent;
  form: FormGroup;
  localData = [];
  filteredHierarchy = [];
  field = {};
  constructor(
    private agentTreeViewDataService: AgentTreeViewDataService,
    private loadingBar: LoadingBarService
  ) { }

  ngOnInit() {
    this.formInit();
    this.loadingBar.start();
    this.agentTreeViewDataService.getAll().pipe(
      tap(res => {
        res[0].seniormaster.map( (srMaster, index) => {
          this.localData.push({
            ...srMaster,
            hasChild: true
          });
          this.appenChild(srMaster);
        });
        this.field = { dataSource: this.localData, id: 'id', parentID: 'pid', text: 'name', hasChildren: 'hasChild' };
      })
    ).subscribe();
  }

  onSearchNodes() {
    this.filteredHierarchy = [];
    const params = this.form.value.filter;
    if (params === '' || params === null) {
      this.localData = this.localData.filter((v, i, a) => a.findIndex(t => ( t.id === v.id) ) === i );
      this.changeDataSource(this.localData);
      this.loadingBar.complete();
    } else {
        const predicate = new Predicate('name', 'startswith', params, true);
        // predicate = predicate.or('city', 'startswith', params, true); IF NECCESSARY
        const filteredList = new DataManager(this.localData).executeLocal(new Query().where(predicate));
        if (filteredList.length > 0){
          filteredList.map(res => {
            this.filteredHierarchy.push(res);
            this.getParent(res);
          });
        }
        this.changeDataSource(this.filteredHierarchy);
        this.loadingBar.complete();
        setTimeout(() => {
          this.listTreeObj.expandAll();
        }, 1000);
    }
  }

  onExpand(data: NodeSelectEventArgs){
    const node = this.listTreeObj.getTreeData(data.node)[0];
    this.appenChild(node);
  }

  private appenChild(data: any, firstLoad?: boolean){
    this.agentTreeViewDataService.getWithQuery(`?agent=${data.username}`).pipe(
      tap( res => {
        if (res[0].master){
          if (res[0].master.length > 0){
            res[0].master.map( master => {
              this.localData.push({
                ...master,
                pid: data.id,
                hasChild: true
              });
              //   this.listTreeObj.expandAll([`${data.id}`]); TO EXPAND SPECIFIC NODE
              this.appenChild(master, true);
            });
          }
        }

        if (res[0].agent){
          if (res[0].agent.length > 0){
            this.loadingBar.start();
            res[0].agent.map( agent => {
              this.localData.push({
                ...agent,
                pid: data.id
              });
              if (firstLoad){
                this.onSearchNodes();
              }
            });
          }
        }
      })
    ).subscribe();
  }

  private getParent(filteredList: any) {
    const predicate = new Predicate('id', 'equal', filteredList.pid, true);
    const filtered = new DataManager(this.localData).executeLocal(new Query().where(predicate));
    if ( filtered.length > 0){
      this.filteredHierarchy.push(filtered[0]);
      this.getParent(filtered[0]);
    }
  }

  private changeDataSource(data: any) {
    const uniqueFam = data.filter((v, i, a) => a.findIndex(t => ( t.id === v.id) ) === i );
    this.listTreeObj.fields = {
        dataSource: uniqueFam, id: 'id', text: 'name', parentID: 'pid', hasChildren: 'hasChild'
    };
  }

  private formInit() {
    this.form = new FormGroup({
      filter: new FormControl(null)
    });
  }

}
