import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { ReferralContent } from '@core/models/referral-content.model';

@Injectable()
export class ReferralContentEntityService extends EntityCollectionServiceBase<ReferralContent> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('ReferralContent', serviceElementsFactory);
  }
}
