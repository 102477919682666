import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { CampaignAdCostReport } from '@core/models/campaign-ad-cost-report.model';

@Injectable()
export class CampaignAdCostReportEntityService extends EntityCollectionServiceBase<CampaignAdCostReport>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('CampaignAdCostReport', serviceElementsFactory)  }
}
