import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { LuckyDrawSettings } from '@core/models/lucky-draw-settings.model';

@Injectable()
export class LuckyDrawSettingsDataService extends DefaultDataService<LuckyDrawSettings>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('LuckyDrawSettings', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<LuckyDrawSettings[]> {
    return this.http.get<ApiResponse>('/luckydraw/settings').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<LuckyDrawSettings[]>{
    return this.http.get<ApiResponse>(`/luckydraw/settings${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getById(id: number): Observable<LuckyDrawSettings>{
    return this.http.get<ApiResponse>(`/luckydraw/settings/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  add(luckyDrawSettings: LuckyDrawSettings): Observable<LuckyDrawSettings>{
    return this.http.post<ApiResponse>(`/luckydraw/settings`, luckyDrawSettings).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/luckydraw/settings/${entity.data.content.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  updateLuckyDraw(luckyDrawSettings: LuckyDrawSettings): Observable<LuckyDrawSettings>{
    return this.http.put<ApiResponse>(`/luckydraw/settings/${luckyDrawSettings.id}`, luckyDrawSettings).pipe(
      tap( res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
