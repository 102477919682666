import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AllLeadsDataService } from '../../services/all-leads-data.service';
import { AuthHttpService } from '@core/services/auth-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { AllAffiliatesDataService } from '../../../all-affiliates/services/all-affiliates-data.service';
import { MemberDataService } from '../../../members/services/member-data.service';
import { GroupHttpService } from '@core/services/group-http.service';
import * as moment from 'moment-timezone';
@Component({
  templateUrl: './all-leads-assign.component.html',
  styleUrls: ['./all-leads-assign.component.scss']
})
export class AllLeadsAssignDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  form: FormGroup;
  availableLead = 0;
  telemarketerDropdownSettings = {};
  telemarketerDropdownList = [];
  telemarketerSelectedItems = [];
  private subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  freshLeads = 0;
  inactiveLeads = 0;
  retargetLeads = 0;
  getLeadsList = false;
  minLeadsRequired = 0;
  leadIndicator = '';

  access$ = this.authHttpService.getUserAccess(13, 'All Leads');
  params = '';
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  from = this.transactionHttpService.getLast7Days().from;
  to = this.transactionHttpService.getToday().to;

  currencies = JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies'));

  sourceTypeDropdownSettings = {};
  sourceTypeDropdownList =  [];
  sourceTypeSelectedItems = [];
  
  sourceAccountDropdownSettings = {};
  sourceAccountDropdownList =  [];
  sourceAccountSelectedItems = []; 

  affiliateGroupDropdownSettings = {};
  affiliateGroupDropdownList =  [];
  affiliateGroupSelectedItems = []; 

  sourceTypesLists = [
    { id: 0, name: 'Affiliate'},
    { id: 1, name: 'Telemarketer'},
    { id: 2, name: 'Player Referral'},
    { id: 3, name: 'Organic'}
  ];

  dropdown = {
    initialLeadTypes: this.dropdownService.initialLeadType,
    memberTypes: this.dropdownService.memberType,
    affiliateTypes: this.dropdownService.affiliateType
  };

  isMemberLead = false;
  isNonMemberLead = false;
  isDepositor = false;
  isAffiliate = false;
  isSourceAccount = false;
  currencyId: number;
  affiliateGroupIds: number;
  affiliateType: number;

  constructor(
    public dialogRef: MatDialogRef<AllLeadsAssignDialogComponent>,
    private allLeadsDataService: AllLeadsDataService,
    private dropdownService: DropdownHttpService,
    private cdr: ChangeDetectorRef,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private transactionHttpService: TransactionHttpService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private memberDataService: MemberDataService,
    private groupHttpService: GroupHttpService,
  ) { }

  ngOnInit() {
    this.sourceTypeDropdownList = this.sourceTypesLists;
    //this.sourceTypeSelectedItems = this.sourceTypesLists;
    if (this.currencies.length === 0) {
      this.dropdownService.currencies.subscribe(res => {
        this.currencies = res;
      });
    }
    this.formInit();
    this.allLeadsDataService.getTelemarketerList(``).subscribe(res => {
      this.telemarketerDropdownList = res;
    });

    this.telemarketerDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      maxHeight: 200,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.affiliateGroupDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      maxHeight: 100,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.sourceTypeDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      maxHeight: 'auto',
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: false,
      noDataLabel: '',
      showCheckbox: false
    };

    this.sourceAccountDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      maxHeight: this.isAffiliate || this.form.controls['source_type'].value == 3 ? 'auto' : 200,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'username',
      noDataLabel: '',
      showCheckbox: false,
      lazyLoading: true,
    };

    this.affiliateGroupDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      maxHeight: 150,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: false,
      noDataLabel: '',
      showCheckbox: false
    };

    this.ranges = this.transactionHttpService.ranges;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onExport() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });
    delete this.form.value.defaultDate;
    const data = {
      ...this.form.value
    };
    Object.keys(data).forEach((key) => ( key !== 'source_type' && (data[key] == null || data[key] === '')) && delete data[key]);
    if (this.isMemberLead == true && this.form.controls.registration_start_datetime.value !== null && this.form.controls.registration_end_datetime.value !== null) {
      data['registration_start_datetime'] = moment(this.form.controls.registration_start_datetime.value).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
      data['registration_end_datetime'] = moment(this.form.controls.registration_end_datetime.value).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
    } else {
      delete data['registration_start_datetime'];
      delete data['registration_end_datetime'];
    }

    if (data['inactive_leads'] === undefined) delete data['inactivity_period'];

    if (data['source_type'] != null){
      data['source_type'] = this.form.controls['source_type'].value;
    } else {
      data['source_type'] = 4
    }
   
    this.subscription = this.allLeadsDataService.export(data).pipe(
      tap(() => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        this.dialogRef.close(true);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onChangeCurrency(value: any) {
    this.form.patchValue({
      settings_currency_id: value,
      telemarketer_id: null,
      fresh_leads: null,
      inactive_leads: null,
      retarget_leads: null,
    });
    this.freshLeads = 0;
    this.inactiveLeads = 0;
    this.retargetLeads = 0;
    this.getLeadsList = false;
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.allLeadsDataService.getTelemarketerList(`?settings_currency_id=${value}`).subscribe(res => {
      this.telemarketerDropdownList = res;
      this.cdr.detectChanges();
    });
  }

  getLeadStatistic() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    let from = null;
    let to = null;
    let sourceTypesList = 4;

    if (this.isMemberLead == true && this.form.controls.registration_start_datetime.value !== null && this.form.controls.registration_end_datetime.value !== null) {
      from = moment(this.form.controls.registration_start_datetime.value).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
      to = moment(this.form.controls.registration_end_datetime.value).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
    }

    let telemarketerIdList = this.form.controls['telemarketer_id'].value;
    let telemarketerID = '';
    telemarketerIdList.forEach(function (value, index) {
      telemarketerID = telemarketerID + `telemarketer_id[${index}]=${value}&`
    })

    this.params = `?` + telemarketerID + `settings_currency_id=${this.form.controls['settings_currency_id'].value}&initial_lead_type=${this.form.controls['initial_lead_type'].value}`;

    if (this.form.controls['initial_lead_type'].value == 1) {
      this.params += `&member_type=${this.form.controls['member_type'].value}`; 

      if (this.form.controls['source_type'].value != null) {
        sourceTypesList = this.form.controls['source_type'].value;
      }
    } 

    this.params += `&source_type=` +sourceTypesList;

    if (from !== null && to !== null) {
      this.params += `&registration_start_datetime=${from}&registration_end_datetime=${to}`;
    }

    if (this.form.controls['deposit_inactivity_period'].value != null) {
      this.params += `&deposit_inactivity_period=${this.form.controls['deposit_inactivity_period'].value}`;
    }

    if (sourceTypesList == 0 && this.form.controls['affiliate_type'].value != null) {
      this.params += `&affiliate_type=${this.form.controls['affiliate_type'].value}`;
      if (this.form.controls['affiliate_group'].value != null) {
        let affiliateGroupList = this.form.controls['affiliate_group'].value;
        let affiliateGroupID = '';
        affiliateGroupList.forEach(function (value, index) {
          affiliateGroupID = affiliateGroupID + `affiliate_group[${index}]=${value}&`
        })
        this.params += `&` + affiliateGroupID;
      }
    }

    if (this.form.controls['source_account'].value != null) {
      let sourceAccountList = this.form.controls['source_account'].value;
      let sourceAccountID = '';
      sourceAccountList.forEach(function (value, index) {
        sourceAccountID = sourceAccountID + `source_account[${index}]=${value}&`
      })
      this.params += `&` + sourceAccountID;
    }
    
    this.buttonLoading = true;
    this.allLeadsDataService.getLeadStatistic(this.params).subscribe(res => {

      this.form.patchValue({
        fresh_leads: null,
        inactive_leads: null,
        retarget_leads: null,
      });
      
      this.freshLeads = res.fresh_leads;
      this.inactiveLeads = res.inactive_leads;
      this.retargetLeads = res.retarget_leads;
      this.getLeadsList = true;
      this.buttonLoading = false;
    });
  }

  onTelemarketerIdChanges() {
    //const list = this.dropdownService.sourceTypesList;
    //this.sourceTypeDropdownList =  list;
    const val = this.form.get('telemarketer_id').value;
    this.minLeadsRequired = val.length;
    //const source_type =this.form.get('source_type').value;

    if (val !== null) {
      if (val.length > 0 && this.form.controls['initial_lead_type'].value != null) {
        this.getLeadStatistic();
      }
      if (val.length == 0) {
        this.freshLeads = 0;
        this.inactiveLeads = 0;
        this.retargetLeads = 0;
        this.getLeadsList = false;
        this.isMemberLead = false;
        this.isNonMemberLead = false;
        this.isDepositor = false;
        this.isAffiliate = false;
        this.isSourceAccount = false;
        this.form.patchValue({
          initial_lead_type: null
        });
        this.cdr.detectChanges();
      }
    } else {
      this.freshLeads = 0;
      this.inactiveLeads = 0;
      this.retargetLeads = 0;
      this.getLeadsList = false;
      this.isMemberLead = false;
      this.isNonMemberLead = false;
      this.isDepositor = false;
      this.isAffiliate = false;
      this.isSourceAccount = false;
      this.form.patchValue({
        initial_lead_type: null
      });
      this.cdr.detectChanges();
    }
    this.form.patchValue({
      fresh_leads: null,
      inactive_leads: null,
      retarget_leads: null,
    });
    this.form.controls['fresh_leads'].setValidators([Validators.min(val.length)]);
    this.form.controls['inactive_leads'].setValidators([Validators.min(val.length)]);
    this.form.controls['retarget_leads'].setValidators([Validators.min(val.length)]);
    this.form.controls['fresh_leads'].updateValueAndValidity();
    this.form.controls['inactive_leads'].updateValueAndValidity();
    this.form.controls['retarget_leads'].updateValueAndValidity();
  }

  private formInit() {
    this.form = new FormGroup({
      settings_currency_id: new FormControl(null),
      telemarketer_id: new FormControl(null, [Validators.required]),
      initial_lead_type: new FormControl(null, [Validators.required]),
      member_type: new FormControl(2), //Default: Non-Depositor
      fresh_leads: new FormControl(null, [Validators.min(1)]),
      inactive_leads: new FormControl(null, [Validators.min(1)]),
      deposit_inactivity_period: new FormControl(null),
      retarget_leads: new FormControl(null, [Validators.min(1)]),
      registration_start_datetime: new FormControl(this.from),
      registration_end_datetime: new FormControl(this.to),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to,
      }),
      source_type: new FormControl(null),
      affiliate_type: new FormControl(0, this.isAffiliate == true ? [Validators.required] : []), //Default: All
      affiliate_group: new FormControl(null),
      source_account: new FormControl(null),
    });

    this.form.controls['deposit_inactivity_period'].valueChanges.subscribe((val) => {
      if (val !== null) {
        this.getLeadStatistic();
      } else {
        this.inactiveLeads = 0;
        this.retargetLeads = 0;
      }
    });

    this.form.controls['affiliate_group'].valueChanges.subscribe((val) => {
      this.currencyId = this.form.controls['settings_currency_id'].value;
      this.affiliateGroupIds = this.form.controls['affiliate_group'].value;
      this.affiliateType = this.form.controls['affiliate_type'].value;
      if (val == null) {
        this.freshLeads = 0;
        this.inactiveLeads = 0;
        this.retargetLeads = 0;
        this.getLeadsList = false;
        this.cdr.detectChanges();
      } else {
        this.getLeadStatistic();
      }
    });

    this.form.controls['source_account'].valueChanges.subscribe((val) => {
      this.currencyId = this.form.controls['settings_currency_id'].value;
      this.affiliateGroupIds = this.form.controls['affiliate_group'].value;
      this.affiliateType = this.form.controls['affiliate_type'].value;
      if (val == null) {
        this.freshLeads = 0;
        this.inactiveLeads = 0;
        this.retargetLeads = 0;
        this.getLeadsList = false;
        this.cdr.detectChanges();
      } else {
        this.getLeadStatistic();
      }
    });

    this.form.controls['telemarketer_id'].valueChanges.subscribe((val) => {
      if (val == null) {
        this.freshLeads = 0;
        this.inactiveLeads = 0;
        this.retargetLeads = 0;
        this.getLeadsList = false;
        this.cdr.detectChanges();
      }
    });

    this.form.controls['fresh_leads'].valueChanges.subscribe((val) => {
      this.leadIndicator = '';
      if (val != null && val > 0) {
        this.updateAssignLeadIndicator();
      }
    });

    this.form.controls['inactive_leads'].valueChanges.subscribe((val) => {
      this.leadIndicator = '';
      if (val != null && val > 0) {
        this.updateAssignLeadIndicator();
      }
    });

    this.form.controls['retarget_leads'].valueChanges.subscribe((val) => {
      this.leadIndicator = '';
      if (val != null && val > 0) {
        this.updateAssignLeadIndicator();
      }
    });
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        registration_start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        registration_end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });

      if (event.startDate !== null && event.startDate !== undefined && this.form.controls['telemarketer_id'].value !== null) {
        this.getLeadStatistic();
      }
      
      this.form.patchValue({ 
        inactive_leads: null,
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onClearDate() {
    this.form.patchValue({ 
      defaultDate: null,
      registration_start_datetime: null,
      registration_end_datetime: null,
    });
    this.getLeadStatistic();
  }

  checkAssignLead(){
    var selectedFreshLeads = false;
    var selectedInactiveleads = false;
    var selectedRetargetLeads = false;
    
    if(this.freshLeads > 0){
      if(this.form.value.fresh_leads > 0){
        selectedFreshLeads = true;
      }
    }

    if(this.inactiveLeads > 0){
      if(this.form.value.inactive_leads > 0){
        selectedInactiveleads = true;
      }
    }

    if(this.retargetLeads > 0){
      if(this.form.value.retarget_leads > 0){
        selectedRetargetLeads = true;
      }
    }

    return selectedFreshLeads == true || selectedInactiveleads == true || selectedRetargetLeads == true ? true : false;
  }

  onChangeInitialLeadType(value: any) {
    this.form.patchValue({
      initial_lead_type: value,
      member_type: null,
      fresh_leads: null,
      inactive_leads: null, // member lead
      retarget_leads: null,
    });
    this.freshLeads = 0;
    this.inactiveLeads = 0;
    this.retargetLeads = 0;
    this.isAffiliate = false;
    this.isSourceAccount = false;

    // 1 = Member Lead; 0 = Non Member Lead
    if (value == 0) {
      this.isNonMemberLead = true;
      this.isMemberLead = false;
    } else {
      this.isMemberLead = true;
      this.isNonMemberLead = false;
    }
    this.getLeadStatistic();
  }

  onChangeMemberType(value: any) {
    this.form.patchValue({
      member_type: value,
      deposit_inactivity_period: null
    });

    // 1 = Depositor; 2 = Non-Depositor
    this.isDepositor = value == 1 ? true : false;
    if (this.isDepositor == true) {
      this.form.controls['deposit_inactivity_period'].setValidators([Validators.min(1), Validators.required]);
      this.form.controls['deposit_inactivity_period'].updateValueAndValidity();
      this.inactiveLeads = 0;
      this.retargetLeads = 0;
    } else {
      this.form.controls['deposit_inactivity_period'].setValidators(null);
      this.form.controls['deposit_inactivity_period'].updateValueAndValidity();
      this.getLeadStatistic();
    }
  }

  onChangeAffiliateType(value: any) {
    this.form.patchValue({
      affiliate_type: value,
    });

    this.affiliateType = this.form.controls['affiliate_type'].value;

    this.getLeadStatistic();
  }

  onSelectedSourceTypeChanged(value?: any) {
    this.isAffiliate = false;
    this.isSourceAccount = false;
    this.sourceAccountDropdownList = [];
    this.sourceAccountSelectedItems = [];
    this.currencyId = this.form.controls['settings_currency_id'].value;

    if (value.length > 0) {
      let sourceType = value[0]['id'];
      this.form.patchValue({
        source_type: sourceType
      });
      
      if (sourceType === 0) {
        // Affiliate
        this.isAffiliate = true;
        this.isSourceAccount = true;
        this.groupHttpService.getAffiliateGroups(`?currency_id=${this.form.controls['settings_currency_id'].value}&status=1`).subscribe(
          res => {
            this.affiliateGroupDropdownList = res.map(item => {
              return { id: item.id, name: item.name };
            });
          }
        )
      } else if (sourceType === 1 || sourceType === 2) {
        // Telemarketer or Player Referral
        this.isAffiliate = false;
        this.isSourceAccount = true;
        if (sourceType === 1) {
          this.sourceAccountDropdownList = this.telemarketerDropdownList.filter(x => !this.form.get('telemarketer_id').value.includes(x.id));
        }
      } else if (sourceType === 3) {
        // Organic
        this.isAffiliate = false;
        this.isSourceAccount = false;
        this.affiliateGroupSelectedItems = [];
        this.sourceAccountSelectedItems = [];
      }
    } else {
      this.form.patchValue({
        source_type: 4 //No source type
      });
      this.isAffiliate = false;
      this.isSourceAccount = false;
      this.affiliateGroupSelectedItems = [];
      this.sourceAccountSelectedItems = [];
    }
    this.getLeadStatistic();
  }

  onSelectedAffiliateGroupChanged(value?: any) {
    this.currencyId = this.form.controls['settings_currency_id'].value;
    this.affiliateGroupIds = this.form.controls['affiliate_group'].value;
    this.affiliateType = this.form.controls['affiliate_type'].value;
    this.getLeadStatistic();
  }

  onSelectedSourceAccountChanged(value?: any) {
    this.getLeadStatistic();
  }

  updateAssignLeadIndicator() {
    var number_of_telemarketer_id = this.form.controls['telemarketer_id'].value.length;
    var total_leads = 0;

    // Calculate total leads by summing all lead types
    total_leads += (this.form.controls['fresh_leads'].value ?? 0);
    total_leads += (this.form.controls['inactive_leads'].value ?? 0);
    total_leads += (this.form.controls['retarget_leads'].value ?? 0);

    this.leadIndicator = ''; // Clear previous indicators

    // Calculate base leads per telemarketer and any extra leads to distribute
    const baseLeads = Math.floor(total_leads / number_of_telemarketer_id);
    let extraLeads = total_leads % number_of_telemarketer_id;

    // Distribute leads to each telemarketer
    this.form.controls['telemarketer_id'].value.forEach((tele_id, index) => {
        // Each telemarketer gets the base leads, plus one if there are remaining extra leads
        const assignedLeads = baseLeads + (extraLeads > 0 ? 1 : 0);
        extraLeads = Math.max(0, extraLeads - 1);  // Decrease extra leads as they're assigned

        // Get telemarketer information
        var tele = this.telemarketerDropdownList.find(x => x.id === tele_id);
        
        if (tele) {
            // Build the lead distribution message
            if (this.leadIndicator === '') {
                this.leadIndicator = `${tele.username} get ${assignedLeads} leads`;
            } else {
                this.leadIndicator += `, ${tele.username} get ${assignedLeads} leads`;
            }
        }
    });
  }
}
