import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class BannerHttpService {

  constructor(private http: HttpClient) { }

  getBannerTypes(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>('/banner/create').pipe(
      map(res => res.data.banner_type)
    );
  }
}
