import { AgentTreeView } from '@core/models/agent-tree-view.model';
import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class AgentTreeViewDataService extends DefaultDataService<AgentTreeView>  {

  pagination: Pagination;
  agents$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('AgentTreeView', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<AgentTreeView[]> {
    return this.http.get<ApiResponse>('/agent/treeview').pipe(
      map(res => res.data)
    );
  }

  getWithQuery(pageParam: string): Observable<AgentTreeView[]>{
    return this.http.get<ApiResponse>(`/agent/treeview${pageParam}`).pipe(
      map(res => res.data)
    );
  }

  // codes below load all on one shot

  getAllAgent(){
    this.http.get<ApiResponse>('/agent/treeview').pipe(
      map(res => {
        res.data[0].seniormaster.map( srMaster => {
          this.agents$.next([{
            id: srMaster.id,
            name: srMaster.name,
            username: srMaster.username,
            hasChild: true
          }]);
          this.getMaster(srMaster);
        });
      })
    ).subscribe();
  }

  getMaster(srMaster: any){
    this.http.get<ApiResponse>(`/agent/treeview?agent=${srMaster.username}`).pipe(
      map(res => {
        if (res.data[0].master){
          if (res.data[0].master.length > 0){
            res.data[0].master.map( master => {
              this.agents$.next([{
                ...master,
                pid: srMaster.id,
                hasChild: true
              }]);
              this.getAgent(master);
            });
          }
        }
      })
    ).subscribe();
  }

  getAgent(master: any){
    this.http.get<ApiResponse>(`/agent/treeview?agent=${master.username}`).pipe(
      map(res => {
        if (res.data[0].agent){
          if (res.data[0].agent.length > 0){
            res.data[0].agent.map( agent => {
              this.agents$.next([{
                ...agent,
                pid: master.id
              }]);
            });
          }
        }
      })
    ).subscribe();
  }
}
