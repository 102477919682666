<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__body">
              <div class="kt-form kt-form--label-right mb-2">
                  <div class="row align-items-center">
                      <div class="col-xl-12 order-2 order-xl-1 pr-0">
                          <form class="row align-items-center pr-0" [formGroup]="form">
                              <div class="col-12 row mb-2 mr-0 pr-0">
                                  <!-- Agent -->
                                  <div class="col-md-1 kt-form__label col-form-label">
                                      <label>Agent:</label>
                                  </div>
                                  <div class="col-md-2 kt-form__control">
                                      <kt-member-dropdown class="dropdown-maxheight" [form]="form"
                                          [isAgent]="true" [dropdownSettings]='dropdownSettings' [formName]="'username'"
                                          [selectionAttributes]="'username'"></kt-member-dropdown>
                                  </div>
                                  <div class="col-md-1 kt-form__label col-form-label">
                                      <label>Code:</label>
                                  </div>
                                  <div class="col-md-2 kt-form__control">
                                      <input type="text" formControlName="code" placeholder="Search" class="form-control">
                                  </div>
                                  <div class="col-md-1 kt-form__label col-form-label">
                                      <label>Name:</label>
                                  </div>
                                  <div class="col-md-2 kt-form__control">
                                      <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                  </div>
                                  <div class="col-md-1 kt-form__label col-form-label">
                                      <label>Status:</label>
                                  </div>
                                  <div class="col-md-2 kt-form__control">
                                      <select class="form-control" formControlName="status">
                                          <option [value]="null" [disabled]="true">Please Select</option>
                                          <option value="all">All</option>
                                          <ng-container  *ngFor="let value of dropdown.statuses ; let i = index">
                                              <option [value]="i">{{ value }}</option>
                                          </ng-container>
                                      </select>
                                  </div>
                              </div>
                              <div class="col-12 row mb-2 mr-0 pr-0">
                                  <div class="col-md-1 kt-form__label col-form-label">
                                      <label>Currency:</label>
                                  </div>
                                  <div class="col-md-2 kt-form__control">
                                      <select class="form-control" formControlName="currency_id">
                                          <option [value]="null" [disabled]="true">Please Select</option>
                                          <option value="all">All</option>
                                          <ng-container *ngFor="let value of dropdown.currencies">
                                              <option [value]="value.id">{{ value.name }}</option>
                                          </ng-container>
                                      </select>
                                  </div>
                                  <div class="col-md-1 kt-form__label col-form-label col-form-label">
                                      <label>Date/Time:</label>
                                  </div>
                                  <div class="col-md-5 kt-form__control">
                                      <div class="input-group date">
                                          <input type="text" class="form-control" placeholder="Search"
                                              (change)="onDateRange($event)" formControlName="defaultDate"
                                              [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true"
                                              [ranges]="ranges" [showCustomRangeLabel]="true"
                                              [locale]="dateTimePickerLocale" [timePicker24Hour]="true"
                                              [showDropdowns]="true" (click)="updateDateRange()"
                                          ngxDaterangepickerMd/>
                                          <span class="input-group-append">
                                              <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
              <div class="kt-section col-12 row d-flex justify-content-between mb-3">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                  <button class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
              </div>
              <div class="kt-section col-12 mb-1 mt-3" *ngIf="downlineUsername.length > 1">
                <ng-container *ngFor="let trail of breadcrumb; let i = index">
                  <span [ngClass]="breadcrumb.length - 1 !== i ? 'kt-badge kt-badge--inline kt-badge--pill kt-badge--wide agent-badge pointer-calendar' : ''"
                  (click)="downlineUsername.length - 1 !== i && i !== 0 ? onDownLine(trail, 'history') : onClear()"
                  >
                      {{ trail }}
                  </span>
                  <label *ngIf="breadcrumb.length - 1 !== i"><i class="fas fa-angle-right ml-1 mr-1"></i></label>
              </ng-container>
              </div>
              <div class="kt-section">
                  <div class="kt-section__content">
                      <div class="table-responsive">
                          <table class="table table-bordered table-hover table-striped">
                              <thead>
                                  <tr>
                                      <th>ID</th>
                                      <th>Name</th>
                                      <th>Username</th>
                                      <th *ngIf="downlineUsername.length === limitDownLine">Code</th>
                                      <th class="text-center">Currency</th>
                                      <th class="text-center">Position Taking (%)</th>
                                      <th class="text-center">Total Clicks</th>
                                      <th class="text-center">Total Members</th>
                                      <th class="text-center">Status</th>
                                      <th>Remarks</th>
                                      <th class="date-column">Created By</th>
                                      <th class="date-column">Updated By</th>
                                      <th class="text-center">Actions</th>
                                  </tr>
                              </thead>
                              <tbody *ngIf="(agents$ | async) as rows">
                                  <tr *ngFor="let row of rows">
                                  <td>{{ row.id }}</td>
                                  <td>{{ row.name }}</td>
                                  <td *ngIf="downlineUsername.length !== limitDownLine">
                                      <a class="text-primary font-weight-bold" (click)="onDownLine(row.username, 'table', row.id)">{{ row.username }} </a>
                                  </td>
                                  <td *ngIf="downlineUsername.length === limitDownLine">{{ row.username }}</td>
                                  <td *ngIf="downlineUsername.length === limitDownLine">
                                      <ng-container *ngIf="row.code">
                                          <a [href]="'http:' + signUpUrl + '?aff=' + row.code" class="username-link" target="_blank">{{ row.code }}</a>
                                          <a style="margin-left:5px" [cdkCopyToClipboard]="'http:' + signUpUrl + '?aff=' + row.code">
                                              <i class="fa fa-paste" ngbTooltip="Copied!" [autoClose]="true" triggers="click"></i>
                                          </a>
                                      </ng-container>
                                  </td>
                                  <td class="text-center">{{ row.currency_code }}</td>
                                  <td class="text-right">{{ row.commission_rate }}</td>
                                  <td class="text-right">{{ row.total_clicks }}</td>
                                  <td class="text-right">{{ row.total_members }}</td>
                                  <td class="text-center">
                                      <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status">{{ row.status === 0 ? 'Inactive' : 'Active' }}</span>
                                  </td>
                                  <td>{{ row.remarks }}</td>
                                  <td>
                                    {{ row.created_by || 'System' }}<br/>
                                    <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                    <ng-template #createdDateTime>
                                        {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                    </ng-template>
                                  </td>
                                  <td>
                                    {{ row.updated_by || 'System' }}<br/>
                                    <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                    <ng-template #updatedDateTime>
                                        {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                    </ng-template>
                                  </td>
                                  <td class="text-center">
                                      <button matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
                                  </td>
                                  </tr>
                              </tbody>
                              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                          </table>
                      </div>
                      <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
  </div>
</div>
