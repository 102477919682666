import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { AllSmsMessages } from '@core/models/all-sms-messages.model';

@Injectable()
export class AllSmsMessagesDataService extends DefaultDataService<AllSmsMessages>  {

  messages$ = new Subject<any[]>();
  pagination: Pagination;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('AllSmsMessages', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<AllSmsMessages[]>{
    return this.http.get<ApiResponse>(`/message${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  export(pageParam: string) {
    return this.http.get<ApiResponse>(`/message/export${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getMember(id: number, pageParam?: string): Observable<AllSmsMessages> {
    const newPageParam = pageParam ? pageParam : '';
    return this.http.get<ApiResponse>(`/member/${id}${newPageParam}`).pipe(
      map(res => res.data.rows)
    );
  }

  sendMessage(data: any): Observable<any>{
    return this.http.post<ApiResponse>(`/message/send`, data).pipe(
      tap( res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      return this.pagination = res.data.paginations;
    }
  }
}
