<div class="col-12 mb-3 p-0 card rounded-0">
  <div *ngIf="(memberInfo$ | async) as row" class="card-header larger-text mb-10" [ngClass]="'risk-level-' + row.summary.general.universal_risk_level"><strong>Universal Risk Level: {{row.summary.general.universal_risk_level_text}}</strong>
    <button  matTooltip="Last Data Sync At {{ row.last_data_sync_time | timezoneDate: 'YYYY-MM-DD HH:mm'  }} {{ timezoneDateWithTimezone() }}" class="float-right btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"><i class="fas fa-history"></i></button>
  </div>
  
</div>
<div *ngIf="canViewGeneralAnalysis" class="col-12 p-0 card rounded-0">
    <div class="card-header larger-text">
      <strong>General Analysis</strong>
      <button *ngIf="(memberInfo$ | async) as row"  matTooltip="Last Data Sync At {{ row.last_data_sync_time | timezoneDate: 'YYYY-MM-DD HH:mm'  }} {{ timezoneDateWithTimezone() }}" class="float-right btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"><i class="fas fa-history"></i></button>
    </div>
    <div class="col-12 pt-4 pb-4">
      <div class="row table-responsive mx-auto mb-2 indent">
        <strong>Same Merchant Detection</strong>
          <table class="table table-bordered table-hover">
              <thead>
                  <tr>
                      <th class="text-center" style="font-weight: normal !important;">Same Name</th>
                      <th class="text-center" style="font-weight: normal !important;">Same Password</th>
                      <th class="text-center" style="font-weight: normal !important;">Same Email</th>
                      <th class="text-center" style="font-weight: normal !important;">Same IP Address</th>
                      <th class="text-center" style="font-weight: normal !important;">Same Fingerprint</th>
                  </tr>
              </thead>
              <tbody *ngIf="(memberInfo$ | async) as row">
                <tr>
                  <td class="text-center">{{ row.summary.local.name }}</td>
                  <td class="text-center">{{ row.summary.local.password }}</td>
                  <td class="text-center">{{ row.summary.local.email }}</td>
                  <td class="text-center">{{ row.summary.local.ip }}</td>
                  <td class="text-center">{{ row.summary.local.fingerprint }}</td>
                </tr>
              </tbody>
          </table>
      </div>
      <div class="row table-responsive mx-auto mb-2 indent">
        <strong>Cross Merchant Detection</strong>
          <table class="table table-bordered table-hover">
              <thead>
                  <tr>
                      <th class="text-center" style="font-weight: normal !important;">Same Name</th>
                      <th class="text-center" style="font-weight: normal !important;">Same Password</th>
                      <th class="text-center" style="font-weight: normal !important;">Same Mobile</th>
                      <th class="text-center" style="font-weight: normal !important;">Same Email</th>
                      <th class="text-center" style="font-weight: normal !important;">Same IP Address</th>
                      <th class="text-center" style="font-weight: normal !important;">Same Fingerprint</th>
                  </tr>
              </thead>
              <tbody *ngIf="(memberInfo$ | async) as row">
                <tr>
                  <td class="text-center">{{ row.summary.cross.name }}</td>
                  <td class="text-center">{{ row.summary.cross.password }}</td>
                  <td class="text-center">{{ row.summary.cross.mobile }}</td>
                  <td class="text-center">{{ row.summary.cross.email }}</td>
                  <td class="text-center">{{ row.summary.cross.ip }}</td>
                  <td class="text-center">{{ row.summary.cross.fingerprint }}</td>
                </tr>
              </tbody>
          </table>
      </div>
    </div>
</div>

<div *ngIf="canViewMemberAnalysis" class="py-4">
  <div class="col-12 p-0 card rounded-0">
      <div class="card-header larger-text">
          <strong>Member Analysis</strong>
          <button  *ngIf="(memberInfo$ | async) as row" matTooltip="Last Data Sync At {{ row.last_data_sync_time | timezoneDate: 'YYYY-MM-DD HH:mm'  }} {{ timezoneDateWithTimezone() }}" class="float-right btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"><i class="fas fa-history"></i></button>
      </div>
      <div class="row col-12 pt-4 pb-4 mx-auto mb-2 indent">
        <form class="row align-items-center" [formGroup]="form">
          <div class="col-12 row mb-2 pr-0">
            <div class="col-md-1 kt-form__label col-form-label">
              <label>Currency:</label>
            </div>
            <div class="col-md-2 kt-form__control">
                <select formControlName="currency_id" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                        {{ value.name }}
                    </option>
                </select>
            </div>
            <div class="col-md-1 kt-form__label col-form-label">
                <label>Status:</label>
            </div>
            <div class="col-md-2 kt-form__control">
                <select formControlName="status" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                      {{ value }}
                    </option>
                </select>
            </div>
          </div>
          <div class="col-12 row mb-2 pr-0">
            <div class="col-md-1 kt-form__label col-form-label">
                <label>Risk Type:</label>
            </div>
            <div class="col-md-2 kt-form__control">
              <div class="d-flex status-filter-pt">
                <div class="custom-control custom-checkbox mr-4">
                  <input type="checkbox" formControlName="name" class="custom-control-input" id="sameName">
                  <label class="custom-control-label text-nowrap" for="sameName">Same Name</label>
                </div>
                <div class="custom-control custom-checkbox mr-4">
                  <input type="checkbox" formControlName="password" class="custom-control-input" id="samePassword">
                  <label class="custom-control-label text-nowrap" for="samePassword">Same Password</label>
                </div>
                <div class="custom-control custom-checkbox mr-4">
                  <input type="checkbox" formControlName="email" class="custom-control-input" id="sameEmail">
                  <label class="custom-control-label text-nowrap" for="sameEmail">Same Email</label>
                </div>
                <div class="custom-control custom-checkbox mr-4">
                  <input type="checkbox" formControlName="ip" class="custom-control-input" id="sameRegisterIp">
                  <label class="custom-control-label text-nowrap" for="sameRegisterIp">Same IP Address</label>
                </div>
                <div class="custom-control custom-checkbox mr-4">
                  <input type="checkbox" formControlName="fingerprint" class="custom-control-input" id="sameLastLogin">
                  <label class="custom-control-label text-nowrap" for="sameLastLogin">Same Fingerprint</label>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="kt-section col-12 row d-flex justify-content-between mb-3">
          <div class="dropdown dropdown-inline">
            <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
            <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
          </div>
      </div>
          <div class="table-responsive">
              <table class="table table-bordered table-hover table-sortable">
                  <thead>
                      <tr>
                        <th class="sort-enabled text-center" style="font-weight: normal !important;" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                        <th class="sort-enabled text-center" style="font-weight: normal !important;" (click)="onSortColumn('category')" [ngClass]="sortingConfig.category" [class.sort-selected]="sortingSelection.sort_by === 'category'">Category</th>
                        <th class="sort-enabled text-center" style="font-weight: normal !important;" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                        <th class="text-center" style="font-weight: normal !important;">Currency</th>
                        <th class="text-center" style="font-weight: normal !important;">Group</th>
                        <th class="text-center" style="font-weight: normal !important;">Status</th>
                        <th class="text-center" style="font-weight: normal !important;">Registration Info</th>
                        <th class="text-center" style="font-weight: normal !important;">Last Login</th>

                      </tr>
                  </thead>
                  <tbody *ngIf="(memberInfo$ | async) as rows">
                      <tr *ngFor="let row of rows.member_info.rows">
                          <td class="text-center"><a class="text-primary font-weight-bold" (click)="onOpenDialog(row.member_account_id)">{{ row.username }}</a></td>
                          <td class="text-center">{{ row.category }}</td>
                          <td class="text-center">{{ row.name }}</td>
                          <td class="text-center">{{ row.currency }}</td>
                          <td class="text-center">{{ row.group_name }}</td>
                          <td class="text-center">
                            <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status_name }}</span>
                          </td>
                          <td class="text-center"><span class="no-wrap">{{ row.register_date | date:'yyyy-MM-dd HH:mm' }}</span><br>
                            <span placement="top" [ngbTooltip]="row.registration_ip" container="body" *ngIf="row.registration_ip" class="text-ellipsis"> {{ row.registration_ip }}</span>
                            <span class="no-wrap">{{ row.fingerprint_id}}</span><br *ngIf="row.fingerprint_id">
                            <ng-container *ngIf="urlIsValid(row.registration_site); else invalidRegistrationSite">
                              <a [href]="row.registration_site" target="_blank"> {{ row.registration_site ? getUrl(row.registration_site) : ''}}</a><br>
                            </ng-container>
                            <ng-template #invalidRegistrationSite>
                                <a> {{ row.registration_site }}</a><br>
                            </ng-template>
                            {{ row.locale_code }}</td>
                          <td class="text-center"><span class="no-wrap">{{ row.last_login_date | date:'yyyy-MM-dd HH:mm' }}</span>
                            <i class="fas fa-check-circle icon-success pl-1" [matTooltip]="row.last_login_status == 1 ? ('Success Login' | translate) : row.last_login_status == 0 ? ('Failed to Login' | translate) :('Unknown' | translate) " [ngClass]="row.last_login_status == 1 ? 'fas fa-check-circle icon-success' : row.last_login_status == 0 ? 'fas fa-times-circle icon-failed' : 'fas fa-question-circle icon-unknown'"></i>
                            <br>
                            <span placement="top" [ngbTooltip]="row.last_login_ip" container="body" *ngIf="row.last_login_ip" class="text-ellipsis"> {{ row.last_login_ip }}</span>
                            <span class="no-wrap">{{ row.fingerprint_id}}</span><br>
                            <ng-container *ngIf="urlIsValid(row.last_login_url); else invalidLastLoginUrl">
                              <a [href]="row.last_login_url" target="_blank"> {{ row.last_login_url ? getUrl(row.last_login_url) : ''}}</a>
                            </ng-container>
                            <ng-template #invalidLastLoginUrl>
                              <a> {{ row.last_login_url ? getUrl(row.last_login_url) : ''}}</a>
                            </ng-template>
                          </td>
                      </tr>
                      <kt-fallback-row [collection]="rows.member_info.rows"></kt-fallback-row>
                  </tbody>
                  <tbody *ngIf="memberInfoConfig.loading" class="pre-search-table">
                      <tr>
                        <td colspan="30">
                          <div class="spinner-wrapper">
                            <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                          </div>
                        </td>
                      </tr>
                  </tbody>
              </table>
              <div class="kt-pagination kt-pagination--brand mt-2 col-12" *ngIf="memberInfoConfig.pagination !== undefined && memberInfoConfig.pagination !== null">
                  <ngb-pagination [pageSize]="pageSize" [(page)]="memberInfoConfig.page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="memberInfoConfig.pagination.total" (pageChange)="getMemberInfoAnalysis(memberInfoConfig.page)">
                  </ngb-pagination>
                  <div class="kt-pagination__toolbar">
                    <span class="pagination__desc">
                      Showing {{ memberInfoConfig.pagination.from }} to {{memberInfoConfig.pagination.to}} of {{ memberInfoConfig.pagination.total | number : '1.0' }} records
                    </span>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
