import { MatDialog } from '@angular/material/dialog';
import { BankSettingsDataService } from './services/bank-settings-data.service';
import { map, tap, exhaustMap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BankSettingsDialogComponent } from './dialogs/bank-settings-edit.component';
import { BankMaintenanceHourComponent } from './dialogs/bank-maintenance-hour/bank-maintenance-hour.component';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-bank-settings',
  templateUrl: './bank-settings.component.html',
  styleUrls: ['./bank-settings.component.scss']
})
export class BankSettingsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  pagination: Pagination;
  pageSize = 30; // Only for BankReceipts
  page = 1;
  maxSize = 5;
  params = `type=3&currency_id=all&bank_type=all`;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    bankType: [],
    perPage: this.dropdownHttpService.perPage,
    types: [
      { id: 3, name: 'All' },
      { id: 1, name: 'Member' },
      { id: 2, name: 'Merchant' },
    ]
  };
  bankSettings$ = [];
  statuses = this.dropdownHttpService.statuses;
  bankTypes = {};
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  canCreateBankSetting: boolean;
  canUpdateBankSetting: boolean;
  canEditMaintenanceHour: boolean;

  constructor(
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private bankSettingsDataService: BankSettingsDataService,
    private currencyHttpService: CurrencyHttpService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.dropdownHttpService.merchantBankTypes.subscribe(res => {
      this.dropdown.bankType = res;
      res.map(row => {
        this.bankTypes = {
          ...this.bankTypes,
          [row.id]: row.name
        }
      });
    });
    this.reload();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateBankSetting = appPermissions.create_bank_settings;
      this.canUpdateBankSetting = appPermissions.update_bank_settings;
      this.canEditMaintenanceHour = appPermissions.edit_bank_settings_maintenance_hours;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.bankSettings$ = [];
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.bankSettingsDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.bankSettings$ = res;
            this.page = 1;
            this.pagination = this.bankSettingsDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    this.bankSettings$ = [];
    return this.bankSettingsDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.bankSettings$ = res;
        this.pagination = this.bankSettingsDataService.pagination;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  onOpenDialog(type: string, data?: any) {
    if (type === 'create') {
      this.openDialogBy(BankSettingsDialogComponent, { mode: 'create' });
    } else if(type === 'edit') {
      const bankSetting = this.bankSettingsDataService.getById(data);
      this.subscription = bankSetting.pipe(
        tap((res) => {
          this.openDialogBy(BankSettingsDialogComponent, { mode: 'edit', bankSetting: res });
        })
      ).subscribe();
    } else{
      const bankMaintenanceHourSetting = this.bankSettingsDataService.getById_maintenancehours(data);
      this.subscription = bankMaintenanceHourSetting.pipe(
        tap((res) => {
          this.openDialogBy(BankMaintenanceHourComponent, { mode: 'edit-maintenanceHours', bankSetting: res });
        })
      ).subscribe();
    }
  }

  onExtractData(currency: any) {
    let currencies = '';
    currency.map((res) => {
      var available_currency = this.dropdown.currencies.filter(x => x['name'] == res['currency_code']);
      if (res.status === 1 && available_currency.length > 0) {
        currencies += ', ' + res['currency_code'];
      }
    });

    return currencies.substring(2);
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  findType(id: number) {
    return this.dropdown.types.find(x => x.id == id);
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private openDialogBy(componentRef: any, data: { mode: string, bankSetting?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        mode: data.mode,
        bankSetting: data.bankSetting
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page);
      }
    });
  }


  private reload() {
    this.onViewPageBy(this.page);
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      currency_id: new FormControl('all'),
      code: new FormControl(null),
      name: new FormControl(null),
      bank_type: new FormControl('all'),
      type: new FormControl(3)
    });
  }

  onDesktopImgError(event, i) {
    (event.target as HTMLImageElement).style.display = 'none';
    document.getElementById("desktop-image-"+i).innerHTML = '-';
  }

  onMobileImgError(event, i) {
    (event.target as HTMLImageElement).style.display = 'none';
    document.getElementById("mobile-image-"+i).innerHTML = '-';
  }

}
