import { navigations } from '@core/store/auth/auth.selectors';
import { AuthHttpService } from '@core/services/auth-http.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as objectPath from 'object-path';
import { LayoutConfigService, MenuConfigService, PageConfigService } from '@core/_base/layout';
import { HtmlClassService } from '../html-class.service';
import { LayoutConfig } from '@core/_config/layout.config';
import { MenuConfig } from '@core/_config/menu.config';
import { PageConfig } from '@core/_config/page.config';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { accessSections } from '@core/store/auth/auth.selectors';
import { AccessSection } from '@core/models/access-section.model';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'kt-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {

  selfLayout: string;
  asideDisplay: boolean;
  asideSecondary: boolean;
  subheaderDisplay: boolean;
  desktopHeaderDisplay: boolean;
  fitTop: boolean;
  fluid: boolean;
  accessSections$: Observable<AccessSection>;
  navigations$: Observable<any>;

  menuConfig: any;

  private unsubscribe: Subscription[] = [];

  constructor(
    private layoutConfigService: LayoutConfigService,
    private menuConfigService: MenuConfigService,
    private pageConfigService: PageConfigService,
    private htmlClassService: HtmlClassService,
    private store: Store<AppState>,
    private auth: AuthHttpService
  ) {
    this.loadRolesWithPermissions();
    this.accessSections$ = this.store.pipe(select(accessSections));
    this.navigations$ = this.store.pipe(select(navigations));
    this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
    this.menuConfigService.loadConfigs(new MenuConfig().configs);
    this.pageConfigService.loadConfigs(new PageConfig().configs);
    this.htmlClassService.setConfig(this.layoutConfigService.getConfig());
    const subscr = this.layoutConfigService.onConfigUpdated$.subscribe(layoutConfig => {
      document.body.className = '';
      this.htmlClassService.setConfig(layoutConfig);
    });
    this.unsubscribe.push(subscr);
  }

  ngOnInit() {
      const config = this.layoutConfigService.getConfig();
      this.selfLayout = objectPath.get(config, 'self.layout');
      this.asideDisplay = objectPath.get(config, 'aside.self.display');
      this.subheaderDisplay = objectPath.get(config, 'subheader.display');
      this.desktopHeaderDisplay = objectPath.get(config, 'header.self.display');
      this.fitTop = objectPath.get(config, 'content.fit-top');
      this.fluid = objectPath.get(config, 'content.width') === 'fluid';

      this.menuConfig = {};
      const items = [{
        title: 'Dashboard',
        icon: 'fas fa-poll',
        page: 'dashboard',
        submenu: null
      }];
      this.navigations$.pipe(
        tap (res => {
          Object.keys(res).forEach((firstKey) => {
            const submenu = [];
            res[firstKey].sub_menu.map( (row) => {
              submenu.push({
                title: row.name,
                icon: row.icon,
                page: row.link
              });
            });
            if(res[firstKey].link != null){
              items.push({
                title: firstKey,
                icon: res[firstKey].icon,
                page: res[firstKey].link,
                submenu: null
              });
            }else{
              items.push({
                title: firstKey,
                icon: res[firstKey].icon,
                page: null,
                submenu
              });
            }
          });
        })
      ).subscribe();

      this.menuConfig = {
        aside: {
          items
        }
      };

      this.menuConfigService.loadConfigs(this.menuConfig);

      const onConfigSub = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
        setTimeout(() => {
          this.selfLayout = objectPath.get(cfg, 'self.layout');
        });
      });
      this.unsubscribe.push(onConfigSub);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

  loadRolesWithPermissions() { }

}
