<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="swal2-header">
      <div class="swal2-icon swal2-info swal2-icon-show" style="display: flex;"><div class="swal2-icon-content">i</div></div>
      <h2 class="swal2-title" id="swal2-title" style="display: flex;">Reset Your Password</h2>
    </div>

    <div class="modal-body justify-content-center px-5">
      <form class="row mb-0" [formGroup]="form" id="password-form" name="password-form">
        <div class="col-12 form-group">
          <span>New Password:</span>
          <input (keyup)="onCheckPasswordFormat(form.value.password)" type="password" formControlName="password" autocomplete="off" class="form-control mb-2" />
          <ng-container *ngIf="form.value.password !== null && form.value.password !== '' && !form.get('password').valid">
            <p *ngIf="form.value.password.length > 0 && form.value.password.length < 8" class="mb-0 err-msg text-danger">
              <i class="fa fa-exclamation-triangle position-relative text-danger mr-2"></i>
              <small for="password">At least 8 characters long</small>
            </p>
            <p *ngIf="form.value.password.length > 0 && !passwordCheck.contain_numbers" class="mb-0 err-msg text-danger">
              <i class="fa fa-exclamation-triangle position-relative text-danger mr-2"></i>
              <small for="register-form-username">Contains at least one number</small>
            </p>
            <p *ngIf="form.value.password.length > 0 && !passwordCheck.contain_uppercase" class="mb-0 err-msg text-danger">
              <i class="fa fa-exclamation-triangle position-relative text-danger mr-2"></i>
              <small for="register-form-username">Contains at least one uppercase character</small>
            </p>
            <p *ngIf="form.value.password.length > 0 && !passwordCheck.contain_lowercase" class="mb-0 err-msg text-danger">
              <i class="fa fa-exclamation-triangle position-relative text-danger mr-2"></i>
              <small for="register-form-username">Contains at least one lowercase character</small>
            </p>
            <p *ngIf="form.value.password.length > 0 && !passwordCheck.contain_special_character" class="mb-0 err-msg text-danger">
              <i class="fa fa-exclamation-triangle position-relative text-danger mr-2"></i>
              <small for="register-form-username">Contains at least one special character</small>
            </p>
            <p *ngIf="!passwordCheck.is_valid_character && form.value.password.length > 0" class="mb-0 err-msg text-danger">
              <i class="fa fa-exclamation-triangle position-relative text-danger mr-2"></i>
              <small for="register-form-username">
                {{ 'Password contains invalid characters.' }}<br>
                {{ 'Only letters, numbers, and the following special characters are allowed:' }}<br>
                {{ '! @ # $ % ^ & * ( ) _ + - = { } [ ] | \ : ; "'}} ' {{ '< > , . ? / ~ `' }}
              </small>
            </p>
          </ng-container>
        </div>
        <div class="col-12 form-group mt-3">
          <span>Confirm New Password:</span>
          <input type="password" (keyup)="onCheckPasswordFormat(form.value.password)" formControlName="confirm_password" autocomplete="off" class="form-control mb-2" />
          <ng-container *ngIf="showConfirmPasswordHint">
            <p class="mb-0 err-msg text-danger">
              <i class="fa fa-exclamation-triangle position-relative text-danger mr-2"></i>
              <small>Confirmation password must match with the password</small>
            </p>
          </ng-container>
        </div>
        <div class="col-12 form-group mb-5">
          <button class="btn btn-brand btn-block" type="submit" [disabled]="!form.valid || showConfirmPasswordHint || loading" (click)="onSubmit()">Reset Password</button>
        </div>
      </form>
    </div>
  </div>
</div>