import { RecoveryPromotion } from '@core/models/recovery-promotion.model';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable()
export class RecoveryPromotionEntityService extends EntityCollectionServiceBase<RecoveryPromotion> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('RecoveryPromotion', serviceElementsFactory);
    }
}

