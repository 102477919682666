import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadHttpService } from '@core/services/upload-http.service';
import { AllEventsDataService } from '../../services/all-events-data.service';
import { tap, catchError } from 'rxjs/operators';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-event-teams-edit',
  templateUrl: './event-teams-edit.component.html',
  styleUrls: ['./event-teams-edit.component.scss']
})
export class EventTeamsEditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = this.allEventsDataService.messages$;
  desktopLoading = false;
  mobileLoading = false;
  desktopImagePreview = [];
  mobileImagePreview = [];
  buttonLoading = false;
  countries = this.dropDownHttpService.allCountries;

  // permissions
  canEditTeamPlayer: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { eventId: number, groupType: number, mode: string, team?: any },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EventTeamsEditDialogComponent>,
    private allEventsDataService: AllEventsDataService,
    private uploadService: UploadHttpService,
    private dropDownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditTeamPlayer = appPermissions.edit_team_player;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave() {
    this.buttonLoading = true;
    this.getCountryName();

    const value = {
      ...this.form.value
    }

    if (this.data.mode === 'create') {
      this.allEventsDataService.addTeam(value).pipe(
        tap((res: any) => {
          this.form.setErrors(null);
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else {
      this.allEventsDataService.updateTeam(this.data.team.id, value).pipe(
        tap((res: any) => {
          this.form.setErrors(null);
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
  }

  onUploadFile(event: any, type: string) {
    if (type === 'desktop') {
      this.desktopLoading = true;
    } else {
      this.mobileLoading = true;
    }
    const file: File = event.target.files[0];
    const formData = new FormData();
    if (file !== undefined) {
      formData.append('files', file, file.name);
      formData.append('type', 'uploads');
      this.uploadService.upload(formData).subscribe(res => {
        if (type === 'desktop') {
          this.desktopLoading = false;
          this.desktopImagePreview = res;
          this.form.patchValue({
            logo_desktop: this.desktopImagePreview[0]
          });
        } else {
          this.mobileLoading = false;
          this.mobileImagePreview = res;
          this.form.patchValue({
            logo_mobile: this.mobileImagePreview[0]
          });
        }
      });
    } else {
      this.mobileLoading = false;
      this.desktopLoading = false;
    }
  }

  private getCountryName(){
    if (this.form.value.country_code != null){
      for (let country of this.countries){
        if (this.form.value.country_code == country.code){
          this.form.value.country_name = country.name;
          break;
        };
      }
    }
  }

  private formInit() {
    let code = null;
    let name = null;
    let position = null;
    let groupName = null;
    let currentStandings = null;
    let desktopLogo = null;
    let mobileLogo = null;
    let countryCode = null;

    if (this.data.mode === 'edit') {
      code = this.data.team.team_code;
      name = this.data.team.team_name;
      position = this.data.team.position;
      groupName = this.data.team.group_name;
      currentStandings = this.data.team.current_standings;
      desktopLogo = this.data.team.logo_desktop;
      mobileLogo = this.data.team.logo_mobile;
      countryCode = this.data.team.country_code;
    }

    this.form = new FormGroup({
      event_id: new FormControl(this.data.eventId),
      team_code: new FormControl(code, [Validators.required]),
      team_name: new FormControl(name, [Validators.required]),
      position: new FormControl(position),
      group_name: new FormControl(groupName),
      current_standings: new FormControl(currentStandings),
      logo_desktop: new FormControl(desktopLogo, [Validators.required]),
      logo_mobile: new FormControl(mobileLogo, [Validators.required]),
      country_code: new FormControl(countryCode),
      country_name: new FormControl(null),
    });

    if (this.data.groupType == 2) {
      this.form.get('group_name').setValidators(Validators.required);
    }
  }
}
