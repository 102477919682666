import { Observable, Subject } from 'rxjs';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { TransactionReconciliation } from '@core/models/transaction-reconciliation.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { map, tap, concatMap } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';

@Injectable()
export class TransactionReconciliationDataService extends DefaultDataService<TransactionReconciliation> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('TransactionReconciliation', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<TransactionReconciliation[]>{
    return this.http.get<ApiResponse>(`/transaction/reconciliation${pageParam}`).pipe(
      map(res => [res.data])
    )
  }

}
