import { OperatorLog } from '@core/models/operator-log.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class OperatorLogsEntityService extends EntityCollectionServiceBase<OperatorLog>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('OperatorLog', serviceElementsFactory)  }
}
