import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { TelemarketerSettingsDataService } from '../../services/telemarketer-settings-data.service';
import { catchError, tap } from 'rxjs/operators';

@Component({
    templateUrl: './telemarketer-settings-edit.component.html',
    styleUrls: ['./telemarketer-settings-edit.component.scss']
})
export class TelemarketerSettingsEditDialogComponent implements OnInit {

    form: FormGroup;

    messages$ = this.telemarketerSettingsDataService.messages$;
    buttonLoading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { telemarketerSettings: any, mode: string },
        public dialogRef: MatDialogRef<TelemarketerSettingsEditDialogComponent>,
        private telemarketerSettingsDataService: TelemarketerSettingsDataService
    ) { }

    ngOnInit() {
        this.formInit();
    }

    onCloseDialog(event?: Event, refresh = false) {
        this.dialogRef.close(refresh);
    }

    onSave() {
        this.buttonLoading = true;
        // To set "Save" button to disable (To prevent call API in multiple times when double click)
        this.form.setErrors({ 'invalid': true });

        const data = {
            ...this.form.value,
        };
        
        switch (this.data.mode) {
            case 'edit':
                this.telemarketerSettingsDataService.update(this.data.telemarketerSettings.id, data).pipe(
                    tap((res: any) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                    }),
                    catchError((error) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                        throw error;
                    })
                ).subscribe();
            break;
        }
    }


    private formInit() {
        let follow_up_day         = this.data.mode=='edit'? this.data.telemarketerSettings.follow_up_day : null;
        let validity_day          = this.data.mode=='edit'? this.data.telemarketerSettings.validity_day : null;
        let ftd_target_day        = this.data.mode=='edit'? this.data.telemarketerSettings.ftd_target_day : null;
        let target_deposit_amount = this.data.mode=='edit'? this.data.telemarketerSettings.target_deposit_amount : null;
        let inactive_period       = this.data.mode=='edit'? this.data.telemarketerSettings.inactive_period : null;
        let tier0_amount          = this.data.mode=='edit'? this.data.telemarketerSettings.tier0_amount : null;
        let tier1_amount          = this.data.mode=='edit'? this.data.telemarketerSettings.tier1_amount : null;
        let tier2_amount          = this.data.mode=='edit'? this.data.telemarketerSettings.tier2_amount : null;
        let tier3_amount          = this.data.mode=='edit'? this.data.telemarketerSettings.tier3_amount : null;

        this.form = new FormGroup({
            follow_up_day: new FormControl(follow_up_day, [Validators.required]),
            validity_day: new FormControl(validity_day, [Validators.required]),
            ftd_target_day: new FormControl(ftd_target_day, [Validators.required]),
            target_deposit_amount: new FormControl(target_deposit_amount, [Validators.required]),
            inactive_period: new FormControl(inactive_period, [Validators.required, this.incrementValidator(0.25)]),
            tier0_amount: new FormControl(tier0_amount, [Validators.required]),
            tier1_amount: new FormControl(tier1_amount, [Validators.required]),
            tier2_amount: new FormControl(tier2_amount),
            tier3_amount: new FormControl(tier3_amount)
        });
    }

    private  incrementValidator(increment: number) {
        return (control: AbstractControl): ValidationErrors | null => {
          const value = control.value;
          if (value !== null && value % increment !== 0) {
            return { incrementError: `Value must be a multiple of ${increment}` };
          }
          return null;
        };
    }
}
