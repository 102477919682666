<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Currency Settings ({{ data.groups.name }})</h5>
                <span class="modal-x-button" (click)="onCloseDialog()"><i class="fa fa-times" aria-hidden="true"></i></span>
            </div>
            <div class="modal-body">
                <div class="col-md-12 form-group row">
                    <label class="col-3 col-form-label">Year<span class="text-danger">*</span></label>
                    <select class="form-control col-5" formControlName="year" (change)="onSelectYM()">
                        <option *ngFor="let value of dropdown.year" [value]="value">{{ value }}
                        </option>
                    </select>
                </div>
                <div class="col-12 form-group row">
                    <label class="col-3 col-form-label">Month<span class="text-danger">*</span></label>
                    <select class="form-control col-5" formControlName="month" (change)="onSelectYM()">
                        <option *ngFor="let value of dropdown.month; let i = index" [value]="value">{{ value }}
                        </option>
                    </select>
                </div>
                <div class="col-12 form-group row">
                    <div class="custom-control custom-checkbox offset-3">
                        <input type="checkbox" formControlName="all_year_and_month" class="custom-control-input" id="all_year_and_month" (change)="onAllYearMonth()">
                        <label class="custom-control-label" for="all_year_and_month">All Year & Month</label>
                    </div>
                </div>
                <section formGroupName="rates">
                    <div class="col-12 form-group row" *ngFor="let settings of rates; let i = index" [formGroupName]="i">
                        <label class="col-3 col-form-label">{{ settings.currency }}<span class="text-danger">*</span></label>
                        <input type="number" (wheel)="false" formControlName="monthly_rate" min="0" step="0.01"class="col-5 form-control" [readonly]="form.value.rates[i].settings_currency_id == 6">
                        <div class="col-form-label col-4">
                            <span> {{ (1 | number: '1.2-2') + ' USD = ' + (form.value.rates[i].monthly_rate != null ? (form.value.rates[i].monthly_rate | number: '1.2-2') + ' ' + settings.currency : 'NaN') }}
                            </span>
                        </div>
                    </div>
                </section>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true)"></kt-swal-alert>