import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { FaqSections } from '@core/models/faq-sections.model';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class FaqSectionsDataService extends DefaultDataService<FaqSections>{

    pagination: Pagination;
    messages$ = new Subject<any[]>();

    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
        super('FaqSections', http, httpUrlGenerator, { root: '' });
    }

    getWithQuery(pageParam: string): Observable<FaqSections[]> {
        return this.http.get<ApiResponse>(`/faq/section${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        )
    }

    getById(id: number): Observable<FaqSections> {
        return this.http.get<ApiResponse>(`/faq/section/${id}`).pipe(
            map(res => res.data.rows)
        );
    }

    addSections(faqSections: FaqSections): Observable<any> {
        return this.http.post<ApiResponse>(`/faq/section`, faqSections).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    updateSections(id: number, faqSections: FaqSections) {
        return this.http.put<ApiResponse>(`/faq/section/${id}`, faqSections).pipe(
            tap(res => this.messages$.next(res.message)),
        );
    }

    private paginationInit(res: any) {
        if (res) {
            this.pagination = res.data.paginations;
        }
    }

}