<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">SMS Recovery Report Details</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>

        <div class="modal-body">
            <div class="table-responsive">
                <table class="table table-bordered table-hover table-striped">
                    <thead>
                        <tr>
                            <th class="text-center">Member Group</th>
                            <th>Username</th>
                            <th class="text-center">Currency</th>
                            <th>First Deposit Amount</th>
                            <th>First Bonus Amount</th>
                            <th>First Applied Promotion</th>
                            <th>Total Deposit Amount</th>
                            <th>Total Bonus Amount</th>
                            <th>SMS Recovery Type</th>
                            <th class="date-column">Date Sent</th>
                            <th class="date-column">Last Login</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="(smsRecoveryDetails$ | async) as rows">
                        <tr *ngFor="let row of rows">
                        <td class="text-center">{{ row.member_group }}</td>
                        <td>{{ row.username }}</td>
                        <td class="text-center">{{ row.currency }}</td>
                        <td class="text-right">{{ row.first_deposit_amount | number : '1.2' }}</td>
                        <td class="text-right">{{ row.first_bonus_amount | number : '1.2' }}</td>
                        <td>{{ row.first_applied_promotion }}</td>
                        <td class="text-right">{{ row.total_deposit_amount | number : '1.2' }}</td>
                        <td class="text-right">{{ row.total_bonus_amount | number : '1.2' }}</td>
                        <td>{{ row.sms_recover_type }}</td>
                        <td>{{ timezoneDateWithTimezone(row.date_sent, timezone, 'YYYY-MM-DD HH:mm') }}</td>
                        <td>{{ row.last_login }}</td>
                      </tr>
                      <kt-fallback-row [collection]="rows"></kt-fallback-row>
                    </tbody>
                </table>
            </div>
            <!-- Pagination -->
            <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                <ngb-pagination
                    [pageSize]="pageSize"
                    [(page)]="page"
                    [maxSize]="maxSize"
                    [directionLinks]="true"
                    [boundaryLinks]="true"
                    [rotate]="true"
                    [collectionSize]="pagination.total"
                    (pageChange)="onViewPageBy(page)">
                </ngb-pagination>
                <div class="kt-pagination__toolbar">
                    <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                        class="form-control kt-font-brand page-size">
                        <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                    </select>
                    <span class="pagination__desc">
                        Showing {{pagination.from}} to {{pagination.to}} of
                        {{ pagination.total | number : '1.0' }} records
                    </span>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        </div>
    </div>
</div>
