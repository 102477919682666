import { EventEmitterService } from '@core/services/event-emitter.service';
import { DatePipe } from '@angular/common';
// Angular
import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { NotificationHttpService } from '@core/services/notification-http.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['notification.component.scss']
})
export class NotificationComponent {

  // Set skin color, default to light
  @Input() skin: 'light' | 'dark' = 'light';

  @Input('isOpen') isOpenPanel: boolean;

  @Output('redirected') redirected = new EventEmitter<string>();
  @ViewChildren('notificationTrigger') notificationTrigger: QueryList<ElementRef>;

  notificationsList = [];
  page = 1;
  lastPage = 1;

  showLoading: boolean;
  unreadNotificationIds = [];

  asReadSubscription = new Subscription;
  notificationSubscription = new Subscription;
  isRequestCompleted = false;
  isOpen = false;
  newMessage = this.notificationHttpService.messageSubject;
  readPushNoti = this.notificationHttpService.readPushNoti;

  private newMessagesSubscription: Subscription[] = [];

  constructor(
    private datePipe: DatePipe,
    private eventEmitterService: EventEmitterService,
    private notificationHttpService: NotificationHttpService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit() {
    this.eventEmitterService.openNotificationMenuVar = this.eventEmitterService.openNotificationMenu.subscribe(() => {
      this.notificationTrigger.first.nativeElement.click()
    });

    this.notificationHttpService.notificationMessagesWebsocket();

    this.newMessagesSubscription.push(
      this.newMessage.subscribe((res) => {
        //only push message when panel is open or else duplicate id error will be encountered
        if(this.isOpenPanel === true) {
          res['read'] = 0;
          this.notificationsList.splice(0, 0, res);
          this.cdr.detectChanges();
        }
      })
    );

    this.readPushNoti.subscribe((id) => {
      this.setRowAsRead(id);
      this.filterUnreadNotificationIds();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filterUnreadNotificationIds();
  }

  ngOnDestroy() {
    // Reset
    this.page = 1;
    this.lastPage = 1;
    this.isOpenPanel = false;
    this.notificationsList = [];
  }

  openChange(event: boolean) {
    // Call ngOnDestroy when the dropdown is collapsed
    if (!event) {
      this.ngOnDestroy();
    } else {
      // Else get the notification list
      this.getNotificationList();
    }
  }

  getJsonResult(jsonString: string) {
    return JSON.parse(jsonString);
  }

  private generateBoCurrencyIdsParams () {
    var currency_ids = JSON.parse(localStorage.getItem('user_data'))['bo_currency_ids'];
    var params = Object.keys(currency_ids).map(key => `currency_ids[${key}]=${currency_ids[key]}`).join('&')

    return params;
  }

  getNotificationList() {
    this.showLoading = true; // Init

    this.notificationSubscription = this.notificationHttpService.getNotificationMessages(this.page, this.generateBoCurrencyIdsParams()).subscribe((response: any) => {
      this.notificationsList = [...this.notificationsList, ...response.rows];
      this.lastPage = response.paginations.last_page;
      this.showLoading = false;
      this.filterUnreadNotificationIds();
    });

  }

  onScrollDown(event?: any) {
    if (this.page > this.lastPage) {
      return;
    }

    // Bottom part
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !this.showLoading) {
      this.page++;
      this.getNotificationList();
    }

  }

  setRowAsRead(unreadId: number) {
    for (let i = 0; i < this.notificationsList.length; i++) {
      if (this.notificationsList[i].id === unreadId) {
        this.notificationsList[i].read = 1;
      }
    }
    this.unreadNotificationIds = []; // Clear processed record
  }

  onRedirect(data: string) {
    let type = this.getJsonResult(data);
    if( 'autoLoadKey' in type && 'autoLoadData' in type ) {
      if( this.router.url == type.link ) {
        this.eventEmitterService.onNotificationRedirect(type);
      } else {
        localStorage.setItem(type.autoLoadKey, type.autoLoadData);
      }
    }
    // Give enough time for API to complete prior to redirect
    setTimeout(() => {
      // Unusual Callback
      if (type.icon == 'fas fa-satellite-dish') {
        localStorage.setItem('NotificationUnusualCallback', 'true');
      }
      this.redirected.emit(type.link)
    }, 1500);
  }

  onGetLapsedTime(created_at: string) {
    let lapsed = '';
    var now = this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss');
    var then = this.datePipe.transform(new Date(created_at), 'dd/MM/yyyy HH:mm:ss');

    var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"));
    var outPut = moment.duration(ms);
    if (outPut['_data'].days > 0) {
      if (outPut['_data'].days > 1) {
        lapsed = outPut['_data'].days + 1 + ' days ago';
      } else {
        lapsed = outPut['_data'].days + ' day ago';
      }
    }

    if (outPut['_data'].days === 0 && outPut['_data'].hours > 0) {
      if (outPut['_data'].hours > 1) {
        lapsed = outPut['_data'].hours + ' hours ago';
      } else {
        lapsed = outPut['_data'].hours + ' hour ago';
      }
    }

    if (outPut['_data'].days === 0 && outPut['_data'].hours === 0 && outPut['_data'].minutes > 0) {
      if (outPut['_data'].minutes > 1) {
        lapsed = outPut['_data'].minutes + ' mins ago';
      } else {
        lapsed = outPut['_data'].minutes + ' min ago';
      }
    }

    if (outPut['_data'].days === 0 && outPut['_data'].hours === 0 && outPut['_data'].minutes === 0 && (outPut['_data'].seconds > 0 && outPut['_data'].seconds < 60)) {
      lapsed = '< 1 min ago';
    }

    return lapsed;
  }

  private filterUnreadNotificationIds() {
    this.unreadNotificationIds = [...(this.notificationsList.filter(i => (Number.isInteger(i.id) && i.read === 0))).map(i => i.id)];
    this.markNotificationAsRead();
    this.cdr.detectChanges();
  }

  // Server side
  private markNotificationAsRead() {
    this.asReadSubscription.unsubscribe();

    if (this.unreadNotificationIds.length >= 1 && this.isOpenPanel) {

      // Won't proceed to redirect current request is completed
      this.isRequestCompleted = false;

      setTimeout(() => {
        this.asReadSubscription = this.notificationHttpService.asRead(this.unreadNotificationIds).pipe(
          tap(res => {
            this.unreadNotificationIds.forEach(id => this.setRowAsRead(id));
            this.isRequestCompleted = true;
          })
        ).subscribe();
        this.cdr.detectChanges();
      }, 500); // Show unread for half a second
    } else {

      // Can still redirect even the status is 'read'
      this.isRequestCompleted = true;
    }
  }

}
