export class MenuConfig {
  public defaults: any = {
    header: { },
    aside: {
      self: {},
      items: [
      /*
        {
          title: 'Dashboard',
          icon: 'flaticon2-architecture-and-city',
          page: '/dashboard',
          translate: 'MENU.DASHBOARD',
        },
        {section: 'Superuser'},
        {
          title: 'Operators',
          icon: 'flaticon2-user',
          page: '/superuser/operators'
        },
        {
          title: 'Operator Roles',
          icon: 'flaticon2-user-1',
          page: '/superuser/operator-roles'
        },
        // {
        //   title: 'Reports',
        //   root: false,
        //   icon: 'flaticon2-analytics',
        //   submenu: [
        //   {
        //     title: 'Win Lose Simple',
        //     page: '/superuser/reports/winlose-simple'
        //   },
        //   {
        //     title: 'Win Lose Full',
        //     page: '/superuser/reports/winlose-full'
        //   },
        //   {
        //     title: 'Member In Out',
        //     page: '/superuser/reports/member-inout'
        //   },
        //   {
        //     title: 'Member Account',
        //     page: '/superuser/reports/member-account'
        //   },
        //   ]
        // },
        // {
        //   title: 'Cash Flow',
        //   icon: 'flaticon2-chart',
        //   page: '/superuser/cashflow'
        // },
        {section: 'General'},
        {
          title: 'Members',
          root: false,
          icon: 'flaticon2-avatar',
          submenu: [
          {
            title: 'All Members',
            icon: 'flaticon-users-1',
            page: '/general/members'
          },
          {
            title: 'Member Groups',
            icon: 'flaticon2-group',
            page: '/general/member-groups'
          },
          // {
          //   title: 'Player Blacklist',
          //   page: '/general/users/player-blacklist'
          // },
          // {
          //   title: 'User Levels',
          //   page: '/general/users/levels'
          // },
          // {
          //   title: 'Rollover',
          //   page: '/general/users/rollover'
          // },
          ]
        },
        // {
        //   title: 'Wallets',
        //   icon: 'flaticon2-send',
        //   page: '/general/wallets'
        // },
        // {
        //   title: 'Messages',
        //   root: false,
        //   icon: 'flaticon-paper-plane-1',
        //   submenu: [
        //   {
        //     title: 'Create',
        //     page: '/general/messages/create'
        //   },
        //   {
        //     title: 'All Messages',
        //     page: '/general/messages'
        //   }
        //   ]
        // },
        {
          title: 'Transactions',
          root: false,
          icon: 'flaticon-interface-7',
          submenu: [
          // {
          //   title: 'Withdrawals',
          //   page: '/general/transactions/withdrawals'
          {
            title: 'Deposits',
            icon: 'flaticon-interface-5',
            page: '/general/deposits'
          },
          {
            title: 'Withdrawals',
            icon: 'flaticon-interface-6',
            page: '/general/withdrawals'
          },
          {
            title: 'Transfers',
            icon: 'flaticon-arrows',
            page: '/general/transfers'
          },
          {
            title: 'Transactions',
            icon: 'flaticon-interface-7',
            page: '/general/transactions'
          }
          ]
        },
        {
          title: 'Promotions',
          root: false,
          icon: 'flaticon-price-tag',
          submenu: [
            {
              title: 'Promotion Codes',
              icon: 'flaticon-alert-2',
              page: '/general/promotion-codes'
            },
            {
              title: 'Member Promotions',
              icon: 'flaticon-medal',
              page: '/general/member-promotions'
            },
            {
              title: 'Promotion Contents',
              icon: 'flaticon-notes',
              page: '/general/promotion-contents'
            }
          ]
        },
        // {
        //   title: 'Game History',
        //   root: false,
        //   icon: 'flaticon-clock-2',
        //   submenu: [
        //   {
        //     title: 'Maxbet',
        //     page: '/general/game-history/maxbet',
        //     icon: 'flaticon-apps'
        //   },
        //   {
        //     title: 'All Live',
        //     page: '/general/game-history/all-live',
        //     icon: 'flaticon-signs'
        //   },
        //   {
        //     title: 'All Bet',
        //     page: '/general/game-history/all-bet',
        //     icon: 'flaticon-share'
        //   },
        //   {
        //     title: 'AG Gaming',
        //     page: '/general/game-history/ag-gaming',
        //     icon: 'flaticon-interface-6'
        //   },
        //   {
        //     title: 'Sexy',
        //     page: '/general/game-history/sexy',
        //     icon: 'flaticon2-menu-1'
        //   },
        //   {
        //     title: 'FGG',
        //     page: '/general/game-history/fgg',
        //     icon: 'flaticon-mark'
        //   }
        //   ]
        // },
        {section: 'Settings'},
        {
          title: 'Announcements',
          icon: 'flaticon2-speaker',
          page: '/settings/announcements'
        },
        {
          title: 'Merchant Bank Accounts',
          icon: 'flaticon-coins',
          page: '/settings/merchant-banks'
        },
        {
          title: 'Banners',
          icon: 'flaticon2-ui',
          page: '/settings/banners'
        },
        {
          title: 'Contacts',
          icon: 'flaticon2-phone',
          page: '/settings/contacts'
        },
        // {
        //   title: 'Maintainance',
        //   icon: 'flaticon-warning-sign',
        //   page: '/settings/maintainance'
        // },

        // {section: 'Platform'},
        // {
        //   title: 'Games',
        //   icon: 'flaticon2-list-2',
        //   page: '/platform/games'
        // }
        */
      ]
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
