<!-- begin:: Brand -->
<div class="kt-aside__brand kt-grid__item" [ngClass]="htmlClassService.getClasses('brand', true)" id="kt_aside_brand">
  <div class="kt-aside__brand-logo">
    <a routerLink="/" class="logo">
      <img class="qply-image" src="/assets/media/logos/quantum-play-white-logo.png" alt="logo">
    </a>
  </div>
  <div class="kt-aside__brand-tools">
    <button ktToggle [options]="toggleOptions" class="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left"
      id="kt_aside_toggler">
      <span><i class="fas fa-2x fa-angle-double-left text-secondary"></i></span>
      <span><i class="fas fa-2x fa-angle-double-right text-secondary"></i></span>
    </button>
  </div>
</div>
<!-- end:: Brand -->