import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouteSettingsDataService } from '../../services/route-settings-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Pagination } from '@core/models/pagination.model';
import { catchError, tap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import Swal from 'sweetalert2';
import { AppPermissionService } from '@core/services/app-permission.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-route-settings',
  templateUrl: './route-settings.component.html',
  styleUrls: ['./route-settings.component.scss']
})
export class RouteSettingsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  searchForm: FormGroup;
  messages$ = this.routeSettingsDataService.messages$;
  buttonLoading = false;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
  }
  route$ = [];
  loading = false;
  dataLength: number;
  isOnAddClicked = false;
  checkValidation = false;

  // permissions
  canAddRoute: boolean;
  canEditRoute: boolean;
  canDeleteRoute: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, seo: any },
    public dialogRef: MatDialogRef<RouteSettingsComponent>,
    private routeSettingsDataService: RouteSettingsDataService,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private fb: FormBuilder,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.searchFormInit();
    this.formInit();
    this.onSubmit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canAddRoute = appPermissions.add_route;
      this.canEditRoute = appPermissions.edit_route;
      this.canDeleteRoute = appPermissions.delete_route;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onSubmit(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    this.resetValue();
    const data = this.filterFormFields(this.searchForm.value);
    this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    this.routeSettingsDataService.getWithQuery(`?page=${page}&perPage=${this.pageSize}${parameters}`).subscribe(res => {
      this.route$ = res;
      if (this.route$.length > 0) {
        this.route$.map(item => {
          this.onAddRow(item);
        });
      }
      this.dataLength = res.length;
      this.pagination = this.routeSettingsDataService.pagination;
      this.loading = false;
      this.loadingBar.complete();
    });
  }

  onAddRow(data?: any) {
    this.checkValidation = false;
    let name = data !== undefined ? data.name : null;
    let route = data !== undefined ? data.route : null;
    let canonical = data !== undefined ? data.custom_canonical : null;
    let desktop = data !== undefined ? data.desktop : null;
    let mobile = data !== undefined ? data.mobile : null;

    const form = new FormGroup({
      id: new FormControl(data !== undefined ? data.id : null),
      name: new FormControl(name, [Validators.required]),
      route: new FormControl(route, [Validators.required]),
      custom_canonical: new FormControl(canonical),
      desktop: new FormControl(desktop),
      mobile: new FormControl(mobile),
      mode: new FormControl(data !== undefined ? 'edit' : 'create')
    });
    this.routesArr.push(form);
    if (data !== undefined) {
      this.routesArr.controls.forEach(control => {
        control.disable();
      });
    } else {
      this.isOnAddClicked = true;
    }
  }

  enableEdit(index: number) {
    this.routesArr.controls[index].enable();
  }

  onSave(row: any, index?: number,) {
    this.checkValidation = true;
    const data = row.mode === 'create' ? this.filterFormFields(row) : row;
    if (this.routesArr.controls[index].valid) {
      switch (row.mode) {
        case 'create':
          this.buttonLoading = true;
          this.routeSettingsDataService.add(data).pipe(
            tap((res: any) => {
              this.buttonLoading = false;
              this.isOnAddClicked = false;
              this.checkValidation = false;
              this.routesArr.controls[index].disable();
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.checkValidation = false;
              this.isOnAddClicked = true;
              throw error;
            })
          ).subscribe();
          break;
        case 'edit':
          this.routeSettingsDataService.update(row.id, data).pipe(
            tap((res: any) => {
              this.buttonLoading = false;
              this.checkValidation = false;
              this.routesArr.controls[index].disable();
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.checkValidation = false;
              throw error;
            })
          ).subscribe();
          break;
      }
    }
  }

  onDismissRow(mode: string, index: number) {
    switch (mode) {
      case 'create':
        this.routesArr.removeAt(index);
        this.isOnAddClicked = false;
        break;
      case 'edit':
        this.routesArr.controls[index].disable();
        break;
    }
  }

  onDelete(id: number) {
    Swal.fire({
      text: `Are you sure you want to delete this route settings?`,
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((res) => {
      if (res.value) {
        this.routeSettingsDataService.delete(id).pipe(
          tap((res: any) => {
            this.messages$ = this.routeSettingsDataService.messages$;
            this.onSubmit();
          }),
          catchError((error) => {
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
      }
    });
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onSubmit(this.page, this.pageSize, this.params);
  }

  get routesArr(): FormArray {
    return this.form.get('route') as FormArray;
  }

  private resetValue() {
    this.route$ = [];
    this.routesArr.clear();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && key !== 'mode') ? fields[key] = formData[key] : key);
    return fields;
  }

  private searchFormInit() {
    this.searchForm = new FormGroup({
      keyword: new FormControl(null)
    });
  }

  private formInit() {
    this.form = this.fb.group({
      route: new FormArray([]),
    });
  }
}
