import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';
import { Promotion } from '@core/models/promotion.model';

@Injectable()
export class PredictionStatusHttpService {

  constructor(private http: HttpClient) { }

  getPredictionStatus(): Observable<any[]> {
    return this.http.get<ApiResponse>('/eventpredictionstatus').pipe(
      map(res => res.data.rows)
    );
  }
}
