export enum TransactionStatus {
  Pending = 0,
  Risky = 4,
  'In Progress' = 3,
  Approved = 1,
  Rejected = 2,
  'Pending Callback' = 5,
  Failed = 6,
  'Pending Receipt' = 7,
}
