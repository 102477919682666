import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class TransactionCallbackHttpService {

  constructor(private http: HttpClient) { }

  getCallbackLogs(queryString: any): Observable<any[]> {
    return this.http.get<ApiResponse>(`/transactioncallback/logs${queryString}`).pipe(
      map(res => res.data)
    );
  }
}
