<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">{{ data.mode === 'edit'? 'Edit' : 'Create'}} Terms and Conditions</h5>
              <span class="modal-x-button" (click)="onCloseDialog()">
                  <i class="fa fa-times" aria-hidden="true"></i>
              </span>
          </div>
          <div class="modal-body">
              <div class="form-group p-0" style="margin-top: 2rem;">
                  <label>Position <span class="text-danger">*</span></label>
                  <input type="number" (wheel)="false" step="1" formControlName="position" class="form-control">

                  <label class="mt-3">Platform <span class="text-danger">*</span></label>
                  <select formControlName="platform_type_id" class="form-control">
                    <option value="null" hidden>Please Select</option>
                    <option *ngFor="let item of dropdown.sitePlatforms" [value]="item.id">{{ item.name }}</option>
                  </select>
              </div>
              <mat-tab-group animationDuration="0ms" class="form-group" formGroupName="contents">
                  <ng-container *ngIf="data.locales.length > 0">
                      <mat-tab *ngFor="let language of data.locales;" [formGroupName]="language.id">
                          <ng-template mat-tab-label>
                              {{language.code}}
                          </ng-template>
                          <input type="hidden" formControlName="settings_locale_id" [value]="language.id" />
                          <div class="form-group">
                              <label>Title <span class="text-danger">*</span></label>
                              <input type="text" formControlName="title" class="form-control">
                          </div>

                          <div class="form-group">
                              <label>Content <span class="text-danger">*</span></label>
                              <ckeditor [config]="editorConfig" [editor]="editor" formControlName="content"></ckeditor>
                          </div>
                      </mat-tab>
                  </ng-container>
              </mat-tab-group>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
              <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.termsConditions, data.mode)" #focusfield></kt-submit-button>
          </div>
      </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
