import { Component, OnInit, Inject, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PromotionContent } from '@core/models/promotion-content.model';

@Component({
  selector: 'kt-promotion-application',
  templateUrl: './promotion-application.component.html',
  styleUrls: ['./promotion-application.component.scss']
})
export class PromotionApplicationComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { promotionContent: PromotionContent, isModal: boolean }
  ) { }

  ngOnInit() {}

  onCloseDialog() {
    this.dialog.closeAll();
  }

}
