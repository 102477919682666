// Services
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { VerificationSettingsDataService } from './services/verification-settings-data.service';
// Angular
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
// Model & Enum
import { Pagination } from '@core/models/pagination.model';
// RXJS & Other
import { tap, map, catchError } from 'rxjs/operators';
import { Observable, Subscription, of } from 'rxjs';
import moment from 'moment';
import { VerificationSettingsEditDialogComponent } from './dialogs/verification-settings-edit/verification-settings-edit.component';
import { DomSanitizer } from '@angular/platform-browser';
import { animate, state, style, transition, trigger } from '@angular/animations';
declare const $: any;

@Component({
  templateUrl: './verification-settings.component.html',
  styleUrls: ['./verification-settings.component.scss'],
  animations: [
    trigger('panelState', [
      state('closed', style({ height: '20px', overflow: 'hidden' })),
      state('open', style({ height: '*' })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]
})
export class VerificationSettingsComponent implements OnInit, OnDestroy  {

  form: FormGroup;
  verificationSettings$: Observable<[]>;
  verificationSettings = [];

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';

  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    status: this.dropdownHttpService.statuses,
    type: this.dropdownHttpService.verificationSettingType,
    bankType: [],
    perPage: this.dropdownHttpService.perPage,
  };

  sortingConfig = {
    'id': 'desc',
    'type': 'desc',
    'currency_code': 'desc',
    'bank_type': 'desc',
    'status': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
  };

  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  messages$ = this.verificationSettingsDataService.messages$;

  currencyColumnItem = 3; // Maximum number of item can display in currency column when 'Read Less'
  showFullContent: boolean = false;

  isRowsExpanded: boolean;
  rowIcon = [];
  expandedElement: string | null = null;

  @ViewChild('contentDiv') contentDiv!: ElementRef;
  // isExpanded = false;
  showReadMore = false;
  maxCollapsedHeight = 20;
  maxNumberofCollapsedField = 1;

  // permissions
  canCreateVerificationSettings: boolean;
  canEditVerificationSettings: boolean;
  canDuplicateVerificationSettings: boolean;
  canUpdateVerificationSettingStatus: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private verificationSettingsDataService: VerificationSettingsDataService,
    private dropdownHttpService: DropdownHttpService,
    public sanitizer: DomSanitizer,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.dropdownHttpService.merchantBankTypes.subscribe(res => {
      this.dropdown.bankType = res.filter(item => item.id === 1 || item.id === 3);
    });
    this.formInit();
    this.pagination = this.verificationSettingsDataService.pagination;
    this.onSubmit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateVerificationSettings = appPermissions.create_verification_settings;
      this.canEditVerificationSettings = appPermissions.edit_verification_settings;
      this.canDuplicateVerificationSettings = appPermissions.duplicate_verification_settings;
      this.canUpdateVerificationSettingStatus = appPermissions.update_verification_setting_status;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    this.loadingBar.start();

    //change all row icon back to '+'
    this.rowIcon = this.rowIcon.map(x => '+');
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    return this.verificationSettings$ = this.verificationSettingsDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.verificationSettings = res;
        res.map((element, index) => {
          this.rowIcon.push('+');
        });
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.verificationSettingsDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, verificationSettingID?: any) {
    if (type === 'create') {
      this.openDialogBy(VerificationSettingsEditDialogComponent, { mode: type });
    } else if (type === 'edit' || type === 'duplicate') {
      this.verificationSettingsDataService.getById(verificationSettingID).subscribe(
        res => {
          this.openDialogBy(VerificationSettingsEditDialogComponent, { mode: type, verificationSettings: res });
        }
      )
    }
  }

  onClear() {
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    //change all row icon back to '+'
    this.rowIcon = this.rowIcon.map(x => '+');
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.verificationSettings$ = this.verificationSettingsDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.verificationSettings = res;
            res.map((element, index) => {
              this.rowIcon.push('+');
            });
            this.dataLength = res.length;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.page = 1;
            this.pagination = this.verificationSettingsDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onChangeStatus(status, id, settings_currency_id){
    const data = {
      id: id,
      settings_currency_id: settings_currency_id,
      status: status
    };
    this.subscription = this.verificationSettingsDataService.updateStatus(data).pipe(
      tap((res: any) => {
      this.messages$.next([...res.message]);
    })
    ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { mode: string, verificationSettings?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        verificationSettings: data.verificationSettings,
        mode: data.mode,
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onSubmit();
      }
    });
  }

  reload() {
    this.onViewPageBy(this.page);
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  toggleContent(event: Event) {
    event.preventDefault();
    this.showFullContent = !this.showFullContent;
  }

  private isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  private formInit() {
    this.form = new FormGroup({
      currency_id: new FormControl('all'),
      bank_type: new FormControl('all'),
      type: new FormControl('all'),
      status: new FormControl('all'),
    });
  }
  
  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  toggleExpandableRows() {
    this.isRowsExpanded = !this.isRowsExpanded;
  }

  onExpandCollpse(id: number, index: number) {
    $(document).find('#detail-' + id).attr('style', 'display: contents !important');
    var targetrow = $(document).find('#detail-' + id);
    this.rowIcon[index] === '+'? targetrow.find('td').css('display','none') : targetrow.find('td').css('display','');
    targetrow.show().find('td').slideToggle('fast');
    this.rowIcon[index] = this.rowIcon[index] === '+' ? '-' : '+';
  }

  onExpandCollpseAll() {
    this.verificationSettings.map((res, index) => {
      const isRowsExpanded = this.isRowsExpanded ? '+' : '-';
      if (isRowsExpanded === this.rowIcon[index]) {
        this.onExpandCollpse(res.id, index);
      }
    });
  }

  getUniqueIdentifier(info: any): string {
    return `${info.verification_setting_id}_${info.settings_currency_id}`;
  }

  stripHTMLTags(text: string){
    return text.replace( /(<([^>]+)>)/ig, '');
  }

  onContent(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>'));
  }

  toggleReadMore(info: any): void {
    const uniqueIdentifier = this.getUniqueIdentifier(info);
    if (this.expandedElement === uniqueIdentifier) {
      this.expandedElement = null;
    } else {
      this.expandedElement = uniqueIdentifier;
    }
  }

  // Determine if the content should be expanded
  // isExpanded(info: any): boolean {
  //   const uniqueId = this.getUniqueIdentifier(info);
  //   return this.expandedElement === uniqueId;
  // }

  // Determine how many items to show based on expansion state
  getDisplayFields(info: any): any[] {
    const fields = info.field;
    return this.getUniqueIdentifier(info) == this.expandedElement ? fields : fields.slice(0, this.maxNumberofCollapsedField);
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }

    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }
}
