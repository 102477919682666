<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form" [formGroup]="form" (submit)="onSave(data.mode)">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Agent</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 form-group">
                        <label class="control-label">Name <span class="text-danger">*</span></label>
                        <input type="text" formControlName="name" class="col-12 form-control">
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Username <span class="text-danger" *ngIf="data.mode === 'create'">*</span></label>
                        <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" class="col-12 form-control">
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Password <span class="text-danger" *ngIf="data.mode === 'create'">*</span></label>
                        <input type="password" formControlName="password" class="col-12 form-control">
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Confirm Password <span class="text-danger" *ngIf="data.mode === 'create'">*</span></label>
                        <input type="password" formControlName="confirm_password" class="col-12 form-control">
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Currency <span class="text-danger" *ngIf="data.mode === 'create'">*</span></label>
                        <select class="form-control" formControlName="currency_id">
                            <option [value]="null" [disabled]="true">Please Select</option>
                            <ng-container *ngFor="let value of currencyDropdown">
                                <option [value]="value.id">{{ value.name }}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-12 form-group">
                        <label class="control-label">Status <span class="text-danger">*</span></label>
                        <select *ngIf="dropdown.statuses" class="col-12 form-control" formControlName="status">
                          <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
                        </select>
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Position Taking (%) <span class="text-danger" *ngIf="data.mode === 'create'">*</span></label>
                        <input type="number" (wheel)="false" formControlName="commission_rate" class="col-12 form-control">
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Remarks </label>
                        <input type="text" formControlName="remarks" class="col-12 form-control">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
              <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.mode)"></kt-submit-button>
            </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
