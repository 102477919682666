<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="align-items-center" [formGroup]="form">
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="currency_id" (change)="onSelectCurrency()">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <ng-container *ngFor="let currency of dropdown.currencies">
                                            <option [value]="currency.id">{{ currency.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Bank Account:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-dropdown-wo-lazyload [form]='form' [dropdownList]='bankaccountDropdownList'
                                        [dropdownSettings]='bankaccountDropdownSettings' [formName]="'merchant_bank_id'"
                                        [selectionAttributes]="'id'" [selectedItems]='bankaccountSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group date">
                                        <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)"
                                            formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true"
                                            [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true"
                                            [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                    </div>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Merchant Bank Status:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="d-flex flex-wrap status-filter-pt">
                                        <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.merchantBankStatus ; let i = index">
                                            <input type="checkbox" [checked]="item.checked" (change)="onMerchantBankStatus($event, item.value, i)" class="custom-control-input" [id]="item.name">
                                            <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name | translate }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Filter Action -->
            <div class="kt-section col-12 row mb-3 mt-3">
                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
            </div>
            <!-- Table -->
            <div class="kt-section ">
                <div class="kt-section__content">
                    <div class="row kt-margin-b-20">
                        <div class="col-12">
                            <!-- Deposit -->
                            <h5>Deposit</h5>
                            <div class="table-responsive">
                                <table class="table table-bordered table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Bank Account/PG</th>
                                            <th class="text-center">Bank Balance</th>
                                            <th class="text-center">Total Amount</th>
                                            <th class="text-center">Approved Transactions</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="(transactionReconciliation$ | async) as rows">
                                        <tr *ngFor="let deposit of rows[0].deposit.rows">
                                            <td>{{deposit.bank_code + ' - ' + deposit.bankaccount_pg }}</td>
                                            <td class="text-right">{{deposit.bank_balance | number : '1.2-2' }}</td>
                                            <td class="text-right">{{deposit.total_amount | number : '1.2-2'}}</td>
                                            <td class="text-center">{{deposit.approved_transactions}}</td>
                                        </tr>
                                        <tr *ngIf="rows[0].deposit.rows.length > 0">
                                            <td colspan="2">&nbsp;</td>
                                            <td class="text-right"><b>{{ rows[0].deposit.total.amount | number : '1.2-2'}}</b></td>
                                            <td class="text-center"><b>{{ rows[0].deposit.total.approved }}</b></td>
                                        </tr>
                                    </tbody>
                                    <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength.deposit" class="text-gray-600 fw-bold"></tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row kt-margin-b-20">
                        <div class="col-12">
                            <!-- Withdrawal -->
                            <h5>Withdrawal</h5>
                            <div class="table-responsive">
                                <table class="table table-bordered table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Bank Account/PG</th>
                                            <th class="text-center">Bank Balance</th>
                                            <th class="text-center">Total Amount</th>
                                            <th class="text-center">Approved Transactions</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="(transactionReconciliation$ | async) as rows">
                                        <tr *ngFor="let withdrawal of rows[0].withdrawal.rows">
                                            <td>{{withdrawal.bank_code + ' - ' + withdrawal.bankaccount_pg}}</td>
                                            <td class="text-right">{{withdrawal.bank_balance | number : '1.2-2'}}</td>
                                            <td class="text-right">{{withdrawal.total_amount | number : '1.2-2'}}</td>
                                            <td class="text-center">{{withdrawal.approved_transactions}}</td>
                                        </tr>
                                        <tr *ngIf="rows[0].withdrawal.rows.length > 0">
                                            <td colspan="2">&nbsp;</td>
                                            <td class="text-right"><b>{{ rows[0].withdrawal.total.amount | number : '1.2-2'}}</b></td>
                                            <td class="text-center"><b>{{ rows[0].withdrawal.total.approved }}</b></td>
                                        </tr>
                                    </tbody>
                                    <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength.withdrawal" class="text-gray-600 fw-bold"></tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row kt-margin-b-20">
                        <div class="col-12">
                            <!-- Transfer -->
                            <h5>Transfer</h5>
                            <div class="table-responsive">
                                <table class="table table-bordered table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Bank Account/PG</th>
                                            <th class="text-center">In Amount</th>
                                            <th class="text-center">Out Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="(transactionReconciliation$ | async) as rows">
                                        <tr *ngFor="let transfer of rows[0].transfer.rows">
                                            <td>{{transfer.bank_code + ' - ' + transfer.bankaccount_pg}}</td>
                                            <td class="text-right">{{transfer.in_amount | number : '1.2-2'}}</td>
                                            <td class="text-right">{{transfer.out_amount | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="rows[0].transfer.rows.length > 0">
                                            <td colspan="1">&nbsp;</td>
                                            <td class="text-right"><b>{{ rows[0].transfer.total.in_amount | number : '1.2-2'}}</b></td>
                                            <td class="text-right"><b>{{ rows[0].transfer.total.out_amount | number : '1.2-2'}}</b></td>
                                        </tr>
                                    </tbody>
                                    <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength.transfer" class="text-gray-600 fw-bold"></tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row kt-margin-b-20">
                        <div class="col-12">
                            <!-- Agent Deposit -->
                            <h5>Agent Deposit</h5>
                            <div class="table-responsive">
                                <table class="table table-bordered table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Bank Account/PG</th>
                                            <th class="text-center">Bank Balance</th>
                                            <th class="text-center">Total Amount</th>
                                            <th class="text-center">Approved Transactions</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="(transactionReconciliation$ | async) as rows">
                                        <tr *ngFor="let agent_deposit of rows[0].agent_deposit.rows">
                                            <td>{{agent_deposit.bank_code + ' - ' + agent_deposit.bankaccount_pg}}</td>
                                            <td class="text-right">{{agent_deposit.bank_balance | number : '1.2-2'}}</td>
                                            <td class="text-right">{{agent_deposit.total_amount | number : '1.2-2'}}</td>
                                            <td class="text-center">{{agent_deposit.approved_transactions}}</td>
                                        </tr>
                                        <tr *ngIf="rows[0].agent_deposit.rows.length > 0">
                                            <td colspan="2">&nbsp;</td>
                                            <td class="text-right"><b>{{ rows[0].agent_deposit.total.amount | number : '1.2-2'}}</b></td>
                                            <td class="text-center"><b>{{ rows[0].agent_deposit.total.approved }}</b></td>
                                        </tr>
                                    </tbody>
                                    <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength.agent_deposit" class="text-gray-600 fw-bold"></tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row kt-margin-b-20">
                        <div class="col-12">
                            <!-- Agent Withdrawal -->
                            <h5>Agent Withdrawal</h5>
                            <div class="table-responsive">
                                <table class="table table-bordered table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Bank Account/PG</th>
                                            <th class="text-center">Bank Balance</th>
                                            <th class="text-center">Total Amount</th>
                                            <th class="text-center">Approved Transactions</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="(transactionReconciliation$ | async) as rows">
                                        <tr *ngFor="let agent_withdrawal of rows[0].agent_withdrawal.rows">
                                            <td>{{agent_withdrawal.bank_code + ' - ' + agent_withdrawal.bankaccount_pg}}</td>
                                            <td class="text-right">{{agent_withdrawal.bank_balance | number : '1.2-2'}}</td>
                                            <td class="text-right">{{agent_withdrawal.total_amount | number : '1.2-2'}}</td>
                                            <td class="text-center">{{agent_withdrawal.approved_transactions}}</td>
                                        </tr>
                                        <tr *ngIf="rows[0].agent_withdrawal.rows.length > 0">
                                            <td colspan="2">&nbsp;</td>
                                            <td class="text-right"><b>{{ rows[0].agent_withdrawal.total.amount | number : '1.2-2'}}</b></td>
                                            <td class="text-center"><b>{{ rows[0].agent_withdrawal.total.approved }}</b></td>
                                        </tr>
                                    </tbody>
                                    <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength.agent_withdrawal" class="text-gray-600 fw-bold"></tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
