import { delay, tap, catchError } from 'rxjs/operators';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GameProvider } from '@core/models/game-provider.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { PromotionCodeDataService } from '../../services/promotion-code-data.service';
import { BlacklistedRequest } from './get-blacklisted-request.interface';
import { BlacklistedData } from './get-blacklisted-data.interface';
import { trigger, state, style, transition, animate } from '@angular/animations';
import Swal from 'sweetalert2';
import { AppPermissionService } from '@core/services/app-permission.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kt-game-provider-new-blacklist',
  templateUrl: './game-provider-new-blacklist.component.html',
  styleUrls: ['./game-provider-new-blacklist.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('expanded', style({
        height: '*',
        opacity: 1,
        transform: 'rotate(0deg)',
      })),
      state('collapsed', style({
        height: '0',
        opacity: 0,
        transform: 'rotate(90deg)',
      })),
      transition('expanded <=> collapsed', [
        animate('1000ms ease-out'),
      ]),
    ]),
  ],
})
export class GameProviderNewBlacklistComponent implements OnInit, OnDestroy {

  form: FormGroup;
  currencyForm: FormGroup;

  selectedIndex = 0;
  dropdown = {
    currencies: [],
  }
  keyword: string = '';
  selectedCurrency: {
    id: number,
    name: string,
  };

  providersByCurrency: GameProvider[][] = [];
  unFilterProvidersByCurrency: GameProvider[][] = [];

  selectedGameProvider: GameProvider[] = [];

  blacklisData: Array<BlacklistedData>;

  dataLoading: boolean = true;
  formReady: boolean = false;
  filteredForm: FormArray;

  showCurrencyDialog: boolean = false;

  selectedAppliedCurrencies: number[] = [];

  beforeApplied: boolean = true;
  afterApplied: boolean = false;

  newBlacklistedGameProvidersData = null;

  messages$ = this.promotionCodeDataService.messages$;

  // permissions
  canEditGameProviderBlacklist: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { providers: GameProvider[], promotion_id: number | null , categories: string[]},
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<GameProviderNewBlacklistComponent>,
    private dropdownHttpService: DropdownHttpService,
    private promotionCodeDataService: PromotionCodeDataService,
    private appPermissionService: AppPermissionService,
  ) { }

  async ngOnInit() {
    await this.getCurrencies();

    // Init an empty array first for all the currencies
    // The array providersByCurrency is to store every game provider which have supported currencies
    // Please note that in this array, the index of the array is same with the index of the dropdown->currencies. It is not the currency id
    this.dropdown.currencies.forEach(() => {
      this.providersByCurrency.push([]);
    });

    // Loop all the providers that pass from the promotion code details
    this.data.providers.forEach(item => {
      // Split the currency code that supported by the game provider
      const currency_string = item.currency_code.split(', ').map(curr => curr.trim());
      // Loop the currency
      currency_string.forEach(curr => {
        // Find that is the currency supported by game provider is matched with the currencies under dropdown->currencies
        const currObj = this.dropdown.currencies.find(cat => cat.name === curr);
        if (currObj) {
          // If found, we get the index of the found currency from dropdown->currencies
          const index = this.dropdown.currencies.indexOf(currObj);
          // Push the provider into the array providersByCurrency
          this.providersByCurrency[index].push(item);
        }
      });
    });

    // Shallow clone a providersByCurrency for filtering use
    this.unFilterProvidersByCurrency = [...this.providersByCurrency];
    // In here we init the selected game provider for each currency tab
    // We loop the providersByCurrency
    // The providersByCurrency is 2d array which currency_index is the row ([currency_index]][game_provider_details])
    this.providersByCurrency.forEach(x => {
      // First we loop the providersByCurrency
      // If the length of the providersByCurrency of the "x" index of currency is 0, we push null
      // If it is greater, we push the first item of the "x" index of currency to the selectedGameProvider array
      // The result will be like
      // [0]-> First Game in MYR
      // [1]-> First Game in SGD
      if (x.length > 0) {
        this.selectedGameProvider.push(x[0]);
      } else {
        this.selectedGameProvider.push(null);
      }
    })
    // Then, we declare an object for request to get all the blacklisted detail from api
    const blacklistRequest: BlacklistedRequest = {
      promotion_id: this.data.promotion_id ?? null,
      game_provider_ids: this.data.providers.map(x => x.id),
      categories: this.data.categories
    };
    // Get the behavior subject value
    // This is for when a user open a blacklist, edit and save, then he open back during create or update, the form value will not be initialized to the default value
    await this.onNewBlacklistedGameProviders();
    if (this.newBlacklistedGameProvidersData) {
      // If the behavior subject got value, we do the form init
      this.blacklisData = this.newBlacklistedGameProvidersData;
      this.formInit();
      // After form init done, we have to filter the form for the first currency and for the first selected game provider
      // This is because for the best user experience
      // We can always display the sub category form for the first game provider for the first array, because we always have them when open this modal
      this.getFilteredForm(this.selectedGameProvider[0].id);
      this.dataLoading = false;
    } else {
      // If no behavior subject found, Call the api
      this.promotionCodeDataService.getBlacklistedData(blacklistRequest).pipe(
        tap((res) => {
          this.blacklisData = res;
          // After getting the result, we do the form init
          this.formInit();
          // After form init done, we have to filter the form for the first currency and for the first selected game provider
          // This is because for the best user experience
          // We can always display the sub category form for the first game provider for the first array, because we always have them when open this modal
          this.getFilteredForm(this.selectedGameProvider[0].id);
          this.dataLoading = false;
        }),
        catchError((error) => {
          this.dataLoading = false;
          throw error;
        })
      ).subscribe();
    }

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditGameProviderBlacklist = appPermissions.edit_game_provider_blacklist;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onSubmit() {
    this.onCloseDialog();
  }

  private formInit() {
    // Declare a form array categories
    this.form = this.fb.group({
      categories: this.fb.array([]),
    });
    const categoriesArray = this.form.get('categories') as FormArray;
    // Loop all the data we get from the api
    this.blacklisData.forEach(item => {
      // First, declare the sub categories as form array sub categories
      const subCategoriesArray = this.fb.array([]);
      // Loop all the sub categories of the item
      // Initialize the value to the form group
      item.sub_categories.forEach((subCategory) => {
        const subCategoryGroup = this.fb.group({
          name: [subCategory.name],
          status: [subCategory.status],
        });
  
        // Pushed the variable to the subCategoriesArray
        subCategoriesArray.push(subCategoryGroup);
      });

      // Then, we initialize the variables for the categoryGroup
      const categoryGroup = this.fb.group({
        game_provider_id: [item.game_provider_id],
        game_provider_code: [item.game_provider_code],
        category_id: [item.category_id],
        category_code: [item.category_code],
        category_name: [item.category_name],
        settings_currency_id: [item.settings_currency_id],
        // Init expanded to false first to collapse all the sub categories dropdowns
        expanded: [false],
        sub_categories: subCategoriesArray,
      });

      // Finally, push the categoryGroup to the categoriesArray
      categoriesArray.push(categoryGroup);
    });
    this.formReady = true;
  }

  getCurrencies() {
    return this.dropdownHttpService.currencies.pipe(
      tap(res => {
        this.dropdown.currencies = res;
        this.selectedCurrency = res[0];
      })
    ).toPromise();
  }

  setProvider(gameProviderId: number) {
    // this.selectedIndex is the current index of the mat tab, which is also the index for the dropdown->currencies
    // We set the selectedGameProvider to the provider selected for the specific currency
    // So that when user switching the tab, it will always stay at the previous selection
    this.selectedGameProvider[this.selectedIndex] = this.providersByCurrency[this.selectedIndex].find(x => x.id === gameProviderId);
    // After select, display the specifc sub category form
    this.getFilteredForm(gameProviderId);
  }

  onSearch(event?) {
    this.keyword = event ? event.target.value : this.keyword;
    if (event && event.key === "Enter" && this.keyword) {
      this.searchProvider(this.keyword);
    }
    else if (!event && this.keyword) {
      this.searchProvider(this.keyword);
    }
  }

  searchProvider(keyword: any) {
    this.providersByCurrency[this.selectedIndex] = this.unFilterProvidersByCurrency[this.selectedIndex].filter(
      x =>
        (x['code'].toUpperCase().includes(keyword.toUpperCase())
          || x['name'].toUpperCase().includes(keyword.toUpperCase())
        )
        &&
          x['currency_code'].includes(this.selectedCurrency.name));
  }

  resetKeyword(event) {
    if (!event.target.value) {
      this.keyword = '';
      this.searchProvider('');
    }
  }

  setCurrency(event) {
    // Set the selected currency
    this.selectedCurrency = this.dropdown.currencies[event.index];
    // Empty the selectedAppliedCurrencies to empty array. This is to ensure that the selection in apply to other currency field is reset
    this.selectedAppliedCurrencies = [];
    // Close the apply to other currency field
    this.showCurrencyDialog = false;
    // Reset the display of button in apply to other currency field
    this.appliedToggle(1);
    // Set the selected Index for setting the selected game provider used
    this.selectedIndex = event.index;
    if (this.formReady) {
      // Only call this filtered form function as everything is init properly
      // When we load this dialog, this function is need not to call as we always displaying the sub category form for the first selected game provider for the first tab
      this.getFilteredForm(this.selectedGameProvider[this.selectedIndex] ? this.selectedGameProvider[this.selectedIndex].id : 0);
    }
  }

  getFilteredForm(gameProviderId: number) {
    // We filter the form by game provider id and also the current selected currency (mat tab)
    this.filteredForm =  this.form.get('categories')['controls'].filter(provider => (
      provider.value.game_provider_id === gameProviderId &&
      provider.value.settings_currency_id === this.selectedCurrency.id
    )) as FormArray;
  }

  changeMainCategoryStatus(event, gameProviderId: number, categoryCode: string) {
    // We get the input from the main category
    const statusToUpdate = (event.target as HTMLInputElement).checked ? 1 : 0;
    // Then, we find the specific sub category form
    const formToUpdate = this.form.get('categories')['controls'].find(provider => (
      provider.value.game_provider_id == gameProviderId &&
      provider.value.settings_currency_id == this.selectedCurrency.id &&
      provider.value.category_code == categoryCode
    ));
    if (formToUpdate) {
      // If we found, we get all their's aub category
      const subCategoriesArray = formToUpdate.get('sub_categories') as FormArray;
      // Then we set all of them to the value of the checkbox
      subCategoriesArray.controls.forEach((control: FormGroup) =>
        control.get('status').setValue(statusToUpdate)
      );
    }
  }

  changeSubCategoryStatus(event, gameProviderId: number, categoryCode: string, subCategoryName: string) {
    // We get the input from the sub category
    const statusToUpdate = (event.target as HTMLInputElement).checked ? 1 : 0;
    // Then, we find the specific sub category form
    const formToUpdate = this.form.get('categories')['controls'].find(provider => (
      provider.value.game_provider_id == gameProviderId &&
      provider.value.settings_currency_id == this.selectedCurrency.id &&
      provider.value.category_code == categoryCode
    ));
    if (formToUpdate) {
      // If we found, we get all their's aub category
      const subCategoriesArray = formToUpdate.get('sub_categories') as FormArray;
      // Find the sub category which has the same name of the input subCategoryName
      const tableSubCategory = subCategoriesArray.controls.find((control: FormGroup) =>
        control.get('name').value === subCategoryName
      );
      if (tableSubCategory) {
        // If found, then we set the value of the specific sub category to the value of the checkbox
        tableSubCategory.get('status').setValue(statusToUpdate);
      }
    }
  }

  toggleDropdown(gameProviderId: number, categoryCode: string) {
    // Find the form which match the gameProviderId and categoryCode
    const formToUpdate = this.form.get('categories')['controls'].find(provider => (
      provider.value.game_provider_id == gameProviderId &&
      provider.value.settings_currency_id == this.selectedCurrency.id &&
      provider.value.category_code == categoryCode
    ));
    if (formToUpdate) {
      // If found, set the value of expanded to the inverse of the current expanded value for the expand and collapse of dropdown
      formToUpdate.get('expanded').setValue(!formToUpdate.get('expanded').value);
    }
  }

  /**
   * The function checks if all sub-categories under a specific game provider and category code are
   * checked.
   * @param {number} gameProviderId - The gameProviderId is a number that represents the ID of a game
   * provider. It is used to identify a specific game provider in the code.
   * @param {string} categoryCode - The categoryCode parameter is a string that represents the code of
   * a category.
   * @returns a boolean value indicating whether all sub-categories under a specific game provider,
   * currency, and category code are checked or not.
   */
  checkMainCategoryChecked(gameProviderId: number, categoryCode: string) {
    let allChecked = false;
    const formToUpdate = this.form.get('categories')['controls'].find(provider => (
      provider.value.game_provider_id == gameProviderId &&
      provider.value.settings_currency_id == this.selectedCurrency.id &&
      provider.value.category_code == categoryCode
    ));
    if (formToUpdate) {
      const subCategoriesArray = formToUpdate.get('sub_categories') as FormArray;
      for (let i = 0; i < subCategoriesArray.length; i++) {
        const control = subCategoriesArray.at(i);
        if (control.get('status').value == 1) {
          allChecked = true;
        } else {
          allChecked = false;
          break;
        }
      }
    }
    return allChecked;
  }

  onCurrencyDialog() {
    // Toggle the show currency dialog
    this.showCurrencyDialog = !this.showCurrencyDialog;
    // Reset the display of button in apply to other currency field
    this.appliedToggle(1);
  }

  getCurrencyDialogList() {
    // Shallow clone a object for the dropdown->currencies
    const currencyDialog = [...this.dropdown.currencies];
    // Filter the list of currencies where eliminates the selected currency from the list
    // This is for the apply to other currency field
    // We will not display the currency of the current tab in the apply to other currency field
    return currencyDialog.filter(x => x.id !== this.selectedCurrency.id);
  }

  /**
   * The toggleAll function updates the selectedAppliedCurrencies array based on whether a checkbox is
   * checked or unchecked.
   * @param {any} event - The event parameter is an object that represents the event that triggered the
   * function. It could be an event like a button click or a checkbox change.
   */
  toggleAll(event: any) {
    if (event.target.checked) {
      this.selectedAppliedCurrencies = this.dropdown.currencies
      .filter(currency => currency.id !== this.selectedCurrency.id)
      .map(currency => currency.id);
    } else {
      this.selectedAppliedCurrencies = [];
    }
  }

  toggleCurrency(currencyId: number) {
    // If the currencyId is presented in the selectedAppliedCurrencies
    // Means this action is unchecked
    // So the filter out the currency
    if (this.selectedAppliedCurrencies.includes(currencyId)) {
      this.selectedAppliedCurrencies = this.selectedAppliedCurrencies.filter(id => id !== currencyId);
    } else {
      // IF the currencyId is not present in the selectedAppliedCurrencies
      // Means this action is checked
      // Add the currency to the selectedAppliedCurrencies
      this.selectedAppliedCurrencies.push(currencyId);
    }
  }

  checkAllCurrencyChecked() {
    return this.selectedAppliedCurrencies.length == this.dropdown.currencies.filter(currency => currency.id !== this.selectedCurrency.id).length;
  }

  applyToOtherCurrencies() {
    // Set loading to true when apply to other currnecy
    this.dataLoading = true;
    // Get the whole form value
    const allForm = this.form.get('categories').value as Array<BlacklistedData>;
    // Then, filter only the form where currency equals to the current currency
    const originalForm = allForm.filter(x => x.settings_currency_id == this.selectedCurrency.id);
    this.selectedAppliedCurrencies.forEach(currency => {
      // Loop all the currency that user want to apply
      originalForm.forEach(form => {
        // Loop the filteredForm
        // Then we find the form matched all the value
        const formToUpdate = this.form.get('categories')['controls'].find(provider => (
          provider.value.game_provider_id == form.game_provider_id &&
          provider.value.settings_currency_id == currency &&
          provider.value.category_code == form.category_code
        ));

        if (formToUpdate) {
          // If found the form
          // Get its sub categories
          const subCategoriesArray = formToUpdate.get('sub_categories') as FormArray;
          // Loop all the sub categories
          for (let i = 0; i < subCategoriesArray.length; i++) {
            const control = subCategoriesArray.at(i);
            // Get the sub category name
            const controlName = control.get('name').value;
            // Then we find the name matched with the filtered form
            const oriFormValue = form.sub_categories.find(x => x.name == controlName);
            // If found, update the status of the form to the value of the filtered form
            if (oriFormValue) {
              control.get('status').setValue(oriFormValue.status);
            }
          }
        }
      })
    })
    // Set the button to green to indicate success
    this.appliedToggle(2);
    setTimeout(() => {
      // After 1 second, set the button to blue again
      this.appliedToggle(1);
      // Close the currency dialog
      this.showCurrencyDialog = false;
      // Remove all the selected applied currencies
      this.selectedAppliedCurrencies = [];
    }, 1000);
    this.dataLoading = false;
  }

  appliedToggle(mode: number) {
    if (mode == 1) {
      this.beforeApplied = true;
      this.afterApplied = false;
    } else {
      this.beforeApplied = false;
      this.afterApplied = true;
    }
  }

  checkFormValid() {
    let finalStatus = true;
    const allForm = this.form.get('categories').value as Array<BlacklistedData>;
    // Create an object to store the status of subcategories for each game provider
    const providerStatusMap = {};

    // Iterate through the data array
    for (const item of allForm) {
      const providerId = item.game_provider_id;
      const subcategories = item.sub_categories;

      if (subcategories.length == 0) {
        continue;
      }
      // Initialize the status for the provider if not already set
      if (!(providerId in providerStatusMap)) {
        providerStatusMap[providerId] = true;
      }

      // Check the status of subcategories for the current item
      for (const subCategory of subcategories) {
        if (subCategory.status !== 1) {
          // If any subcategory has a status other than 1, set the status for the provider to false
          providerStatusMap[providerId] = false;
          break; // No need to check other subcategories for this provider
        }
      }
    }

    // If there is a provider with all subcategories blacklisted for all currencies
    // We push the game provider code to an array for display the erro message
    const providersWithStatusOne = [];
    for (const providerId in providerStatusMap) {
      if (providerStatusMap[providerId] === true) {
        const gameProvider = allForm.find(x => x.game_provider_id == Number(providerId));
        if (gameProvider) {
          providersWithStatusOne.push(gameProvider.game_provider_code);
        }
        
      }
    }

    // Determine that if all the requirement met
    finalStatus = Object.values(providerStatusMap).every(status => status === false);
    if (!finalStatus) {
      // If not met, swal the error message
      if (providersWithStatusOne.length > 0) {
        const errorMessage = `<ul>${providersWithStatusOne.map(item => `<li>${item}</li>`).join('')}</ul>`;
        Swal.fire({
          icon: 'error',
          title: 'At least one sub category is not blacklisted for each provider for all currencies',
          html: 'Game Provider Not Fulfilled Requirement:<br>' + errorMessage,
          width: '500px'
        });
      }
    } else {
      // If the submit action is for update, directly update the blacklist
      if (this.data.promotion_id && this.data.promotion_id !== undefined && this.data.promotion_id > 0) {
        const updateBlacklistData = {
          promotion_id: this.data.promotion_id,
          black_list_sub_categories: this.form.get('categories').value
        }
        this.dataLoading = true;
        this.formReady = false;
        this.promotionCodeDataService.updateBlacklistedData(updateBlacklistData).pipe(
          tap((res) => {
            this.dataLoading = false;
            this.formReady = true;
            this.messages$.next([...res.message]);
            this.promotionCodeDataService.newBlacklistedGameProviders$.next(null);
          }),
          catchError((error) => {
            this.dataLoading = false;
            throw error;
          })
        ).subscribe();
      } else {
        // Store the current form value to a behavior subject
        this.promotionCodeDataService.newBlacklistedGameProviders$.next(this.form.get('categories').value);
        // CLose the dialog
        this.onCloseDialog();
      }
      
    }
  }

  async onNewBlacklistedGameProviders() {
    return new Promise<void>((resolve) => {
      this.promotionCodeDataService.newBlacklistedGameProviders$.subscribe(res => {
        this.newBlacklistedGameProvidersData = res;
        resolve();
      });
    });
  }
}
