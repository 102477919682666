import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { LuckyDrawSettings } from '@core/models/lucky-draw-settings.model';

@Injectable()
export class LuckyDrawSettingsEntityService extends EntityCollectionServiceBase<LuckyDrawSettings> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('LuckyDrawSettings', serviceElementsFactory);
  }
}
