import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, Observable, of } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { MemberDataService } from '../../services/member-data.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, tap, exhaustMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
declare var $:any;
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'kt-game-log-details-details',
  templateUrl: './member-game-log-details.component.html',
  styleUrls: ['./member-game-log-details.component.scss']
})
export class MemberGameLogDetailsDialogComponent implements OnInit {
  form: FormGroup;
  dataLink: string;
  member : any;
  game : any;
  page = 1;
  tableContentDetails: any;
  loading = false;
  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { dataLink: any, member: any, existing_game_account: any },
    public dialogRef: MatDialogRef<MemberGameLogDetailsDialogComponent>,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    public loadingBar: LoadingBarService,
    private memberDataService: MemberDataService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
  ) { }

  private subscription = new Subscription();

  ngOnInit() {
    this.dataLink = String(this.data.dataLink);
    this.member = this.data.member;
    this.game = this.data.existing_game_account;
    this.formInit();
    
  }

  htmlRenderDetails(){
    $('#contentDetailsTable').off('click');
      $( "#contentDetailsTable" ).on( "click", "a",(events)=> {
        this.onOpenDialog($(events.currentTarget).attr('data-link'), this.data.member, this.game);
      });
    
  }

  onChangePage(mode:any) {
    if(mode === 'next'){
      this.page = this.page + 1;
    }else{
      this.page = this.page - 1;
    }
    this.formInit();
  }

  onOpenDialog(dataLink: String, member: any, game?: any) {
    this.openDialogBy(MemberGameLogDetailsDialogComponent, { dataLink: dataLink, member: member, existing_game_account: game})
  }

  private openDialogBy(componentRef: any, data?: { dataLink?: String, member?:any, existing_game_account?:any}) {
      this.dialog.open(componentRef, {
        width: '800px',
        data: {
          dataLink: data.dataLink,
          member: data.member,
          existing_game_account : data.existing_game_account
        }
      });

    
  }

  private formInit(){
    this.loading = true;
    this.buttonLoading = true;
    this.tableContentDetails = `<div class="spinner-wrapper">
      <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
    </div>`;
    this.loadingBar.start();

    var re = /&/gi; 
    var str = this.dataLink;
    var newstr = str.replace(re, "*"); 
    var member_account_id = this.data.member.id === undefined ? this.data.member.member_account_id : this.data.member.id;
    const member = this.memberDataService.getMemberGameLogDetails(`?page_number=${this.page}&page_link=${newstr}&username=${this.data.member.username}&member_account_id=${member_account_id}&game_provider_code=${this.game.game_provider_code ? this.game.game_provider_code : this.data.member.game_provider_code}&currency_code=${this.data.member.currency_code}`);

    this.subscription = member.pipe(
      tap((res) => {
        if (res) {
              this.loadingBar.complete();
              this.loading = false;
              this.buttonLoading = false;
              this.tableContentDetails = this.sanitizer.bypassSecurityTrustHtml(res);
        }
      })
    ).subscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

}
