import { TransactionHttpService } from '@core/services/transaction-http.service';
import { RebateReleaseDataService } from '../services/rebate-release-data.service';
import { Subscription, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Rebate } from '@core/models/rebate.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { tap } from "rxjs/operators";
import { CurrencyTimezone } from "@core/enums/currency-timezone.enum";
import { Router } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";
import { MemberReportDetailsDialogComponent } from '@views/pages/apps/reports/member-report/dialogs/member-report-details.component';

@Component({
  selector: 'kt-target-amount-details',
  templateUrl: './target-amount-details.component.html',
  styleUrls: ['./target-amount-details.component.scss'],
})
export class TargetAmountDetailsDialogComponent implements OnInit, OnDestroy {


  private subscription = new Subscription();

  loading = true;
  targetAmountDetails : any = [];
  summary  : any = [];
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  params = "";
  currencyTimezone = CurrencyTimezone;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { row:any, details:any },
    private rebateReleaseDataService: RebateReleaseDataService,
    public dialogRef: MatDialogRef<TargetAmountDetailsDialogComponent>,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

    const data = {
      member_account_id: this.data.details['member_account_id'],
      member_promotion_id: this.data.row['id'],
      game_provider_id: this.data.details['game_provider_id'],
      start_date_time: moment(this.data.details['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date_time: moment(this.data.row['gsys_transaction_completed_at']).utc().format('YYYY-MM-DD HH:mm:ss')
    };

    this.params = Object.keys(data).map((key) => key + "=" + data[key]).join("&");

    this.rebateReleaseDataService.gettargetamountdetail(this.params).subscribe(val => {
      this.targetAmountDetails = val;
      this.loading = false;
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onOpenDialog(row?: any) {
    const data = {
      game_account: row.game_account,
      game_provider_id: row.game_provider_id,
      game_provider_code: row.game_provider_code,
      username: row.username,
      member_account_id: this.data.details.member_account_id,
      start_date_time: moment(this.data.details['start_datetime']).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date_time:  moment(this.data.row['gsys_transaction_completed_at']).utc().format('YYYY-MM-DD HH:mm:ss'),
      type: 'member-report',
      viewType: 'dialog',
      currency_code: row.currency_code,
      game_type: row.game_type,
      timezone: "UTC"  // use time-zone gz: 0 becuase the start_date_time and end_date_time already convert
    };
    this.openDialogBy(MemberReportDetailsDialogComponent, data);
  }
  
  private openDialogBy(componentRef: any, data?: { game_account: string, game_provider_id: number, game_provider_code: string, username: string, start_date_time: string, end_date_time: string, type: string, currency_code: string, game_type: number,member_account_id: number, timezone: string }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '90vw',
      height: '90vh',
      data: {
        game_account: data.game_account,
        game_provider_id: data.game_provider_id,
        game_provider_code: data.game_provider_code,
        username: data.username,
        member_account_id: data.member_account_id,
        start_date_time: data.start_date_time,
        end_date_time: data.end_date_time,
        type: data.type,
        currency_code: data.currency_code,
        game_type: data.game_type,
        timezone: data.timezone,
        showPastTransactions: false
      }
    });
  }

 
}
