import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { Dropdown } from '@core/models/dropdown.model';
import { AccountManagement } from '@core/models/account-management.model';
import { AccountManagementLog } from '@core/models/account-management-log.model';
import { AccountManagementKpiSettings } from '@core/models/account-management-kpi-settings.model';

@Injectable()
export class AccountManagementDataService extends DefaultDataService<AccountManagement> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  data$ = new Subject<any[]>();

  totalAssignedLeads = 0;
  totalUnassignedLeads = 0;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('AccountManagement', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<AccountManagement[]> {
    return this.http.get<ApiResponse>(`/accountmanagement${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
      
    );
  }

  getAccountManagementLog(pageParam: string): Observable<AccountManagementLog[]> {
    return this.http.get<ApiResponse>(`/accountmanagement/log${pageParam}`).pipe(
      tap(res => {
        this.paginationInit(res);
      }),
      map(res => res.data.rows)
      
    );
  }

  updateKpiSettings(data) {
    return this.http.put<ApiResponse>(`/accountmanagement/kpisettings/update`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getKpiSettings(developmentStage = 1, leadStage = 1): Observable<AccountManagementKpiSettings[]> {
    return this.http.get<ApiResponse>(`/accountmanagement/kpisettings?development_stage=${developmentStage}&lead_stage=${leadStage}`).pipe(
      map(res => res.data)
    )
  }

  getAccountManager(params): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/accountManagerList${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  getNormalAccountManager(params): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/normalaccountManagerList${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  getVipAccountManager(params): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/vipaccountManagerList${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  assignLead(data) {
    return this.http.post<ApiResponse>(`/accountmanagement/assignlead`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getLeadStatistic(params: string): Observable<any> {
    return this.http.get<ApiResponse>(`/accountmanagement/leadstatistic${params}`).pipe(
      map(res => res.data)
    );
  }

  updateRemarks(data, id) {
    return this.http.put<ApiResponse>(`/accountmanagement/remark/${id}`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getRemarks(params: string): Observable<any> {
    return this.http.get<ApiResponse>(`/accountmanagement/remark/show${params}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data)
    );
  }
  
  export(pageParam: string) {
    return this.http.get<ApiResponse>(`/accountmanagement/export${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
