import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';


@Pipe({
  name: 'dateTimeConversion',
})
export class DateTimeConversionPipe implements PipeTransform {
  transform(value: string, currency: string, format?: string): string {
    let timeOffSet = '';
    switch (currency) {
      case 'MYR': timeOffSet = '+0800'; break;
      case 'THB': timeOffSet = '+0700'; break;
      case 'SGB': timeOffSet = '+0800'; break;
      default: timeOffSet = '+0000'; break;
    }

    // Set default format if format is not provided
    const finalFormat = format ? format : 'YYYY-MM-DD HH:mm:ss';
    const formattedDate = moment(value).utcOffset(timeOffSet).format(finalFormat);

    // Append time offset only if a custom format is provided
    return format ? `${formattedDate} (${timeOffSet})` : formattedDate;
  }
}
