<div class="kt-form kt-form--label-right">
    <div class="row align-items-center">
        <div class="col-xl-12 order-2 order-xl-1">
            <form class="align-items-center" [formGroup]="form">
                <div class="row mb-2">
                    <div class="col-md-1 kt-form__label col-form-label">
                        <label>Section Name:</label>
                    </div>
                    <div class="col-md-2 kt-form__control">
                        <input type="text" formControlName="section_name" placeholder="Search" class="form-control">
                    </div>
                    <!-- <div class="col-md-1 kt-form__label col-form-label">
                        <label>Merchant:</label>
                    </div>
                    <div class="col-md-2 kt-form__control">
                        <select class="form-control" formControlName="merchant_id">
                            <option value="all">All</option>
                            <ng-container *ngFor="let value of dropdown.merchant | async">
                                <option [value]="value.id">{{ value.name }}</option>
                            </ng-container>
                        </select>
                    </div> -->
                    <div class="col-md-1 kt-form__label col-form-label">
                        <label>Status:</label>
                    </div>
                    <div class="col-md-2 kt-form__control">
                        <select class="form-control" formControlName="status">
                            <option value="all">All</option>
                                <option [value]="i" *ngFor="let value of dropdown.quicklinkStatus; let i = index">{{ value }}</option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Action Button -->
<div class="kt-section col-12 row d-flex justify-content-between mb-3">
    <div class="dropdown dropdown-inline">
        <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
        <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>                  
    </div>
    <button *ngIf="canCreateQuickLink" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
</div>            


<div class="kt-section">
    <div class="kt-section__content">
        <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
                <thead>
                    <tr class="text-top">
                        <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                        <th class="sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                        <th class="sort-enabled text-center" (click)="onSortColumn('section_name')" [ngClass]="sortingConfig.section_name" [class.sort-selected]="sortingSelection.sort_by === 'section_name'">Section Name</th>
                        <!-- <th>Merchant</th> -->
                        <th class="sort-enabled" (click)="onSortColumn('link')" [ngClass]="sortingConfig.link" [class.sort-selected]="sortingSelection.sort_by === 'link'">Redirect Link</th>
                        <th class="sort-enabled text-center" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                        <th class="sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                        <th class="sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                        <th class="text-center">Action</th>
                    </tr>
                </thead>
                <tbody *ngIf="(quicklink$ | async) as rows">
                    <tr *ngFor="let row of rows">  
                        <td>{{ row.id }}</td>
                        <td>{{ row.name }}</td>
                        <td class="text-center">{{ row.section_name }}</td>
                        <!-- <td>{{ row.merchant_name }}</td> -->
                        <td><a [href]="row.link">{{ row.link }}</a></td>
                        <td class="text-center">
                            <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                        </td>
                        <td>
                            {{ row.created_by ? row.created_by : 'System' }}<br/>
                            <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                            <ng-template #createdDateTime>
                                {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                            </ng-template>
                        </td>
                        <td>
                            {{ row.updated_by ? row.updated_by : 'System' }}<br/>
                            <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                            <ng-template #updatedDateTime>
                                {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                            </ng-template>
                        </td>
                        <td class="text-center">
                            <button *ngIf="canViewQuickLinkDetails" title="Quick Link Settings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-cog"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
        </div>
        <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination
                [pageSize]="pageSize"
                [(page)]="page"
                [maxSize]="maxSize"
                [directionLinks]="true"
                [boundaryLinks]="true"
                [rotate]="true"
                [collectionSize]="pagination.total"
                (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                </select>
                <span class="pagination__desc">
                    Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                </span>
            </div>
        </div>
    </div>
</div>

<!-- <kt-swal-alert [message]="messages$ | async" (confirmed)="onReload()"></kt-swal-alert> -->