import { delay, tap } from 'rxjs/operators';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GameProvider } from '@core/models/game-provider.model';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { PromotionCodeDetailsDialogComponent } from '../promotion-code-details/promotion-code-details';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-game-provider-blacklist',
  templateUrl: './game-provider-blacklist.component.html',
  styleUrls: ['./game-provider-blacklist.component.scss']
})
export class GameProviderBlacklistComponent implements OnInit {

  form: FormGroup;
  providers = new FormArray([]);

  gameProviderId: number;
  subCategories: {
    [k: number]: any[];
  } = {};

  object = Object;

  private subscription: Subscription[] = [];

  // permissions
  canEditGameProviderBlacklist: boolean;

  gameProvidersDropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'name',
    labelKey: 'name',
    lazyLoading: true,
    noDataLabel: '',
    maxHeight: 150,
    showCheckbox: false,
    autoPosition: false,
    position: 'bottom',
  };

  selectedSubCategories$: BehaviorSubject<any>;
  DefaultSelectSubCategories: any;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { providers: GameProvider[], blacklist_sub_categories: any[], default_blacklist_sub_categories: any[] },
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PromotionCodeDetailsDialogComponent>,
    private gameProviderHttpService: GameProviderHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();

    const providerCodes = this.data.providers.map(item => item.code);

    this.subscription.push(
      this.gameProviderHttpService.getSubCategories(providerCodes).subscribe(res => {
        res.forEach((item: any, index: number) => {

          // Convert to OBJECT for multi-select dropdown requirement
          const subCategoryNames = (res[index].sub_category_name).map( (value: any, index: number) => {
            return {
              id: `${item.game_provider_id}-${index}`,
              name: value
            }
          })

          this.subCategories = {
            ...this.subCategories,
            [res[index].game_provider_id]: (res[index].sub_category_name).length  ? subCategoryNames : null
          }
        });
      })
    );
    this.iterateProviders();
    this.selectedSubCategories$ = this.gameProviderHttpService.selectedSubCategories$;

    // Edit mode
    if(this.gameProviderHttpService.selectionMode === 'fresh') {
      if(this.data.blacklist_sub_categories !== null && (this.data.blacklist_sub_categories).length >= 1) {
        let previouslySelectSubCategories = {}
        let subCategoryNames = []
        this.data.blacklist_sub_categories.forEach((row: any, index: number) => {
          if((row.sub_category_name).length >= 1) {
            subCategoryNames = (row.sub_category_name).map((item: any, index: number) => {
              return {
                id: `${row.game_provider_id}-${index}`,
                name: item
              }
            })
          }
          previouslySelectSubCategories = {
            ...previouslySelectSubCategories,
            [row.game_provider_id]: subCategoryNames
          }
        });
        this.selectedSubCategories$.next(previouslySelectSubCategories);
      } else {
        this.selectedSubCategories$.next(null);
      }
    }

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditGameProviderBlacklist = appPermissions.rebate_settings_update_game_provider_blacklist;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.map(sub => sub.unsubscribe);
    this.subscriptions.unsubscribe();
    this.setDefaultList();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onAddBlacklist(gameProviderId: number) {
    const group = new FormGroup({
      game_provider_id: new FormControl(gameProviderId),
      blacklist_sub_categories: new FormControl(null)
    });
    this.providers.push(group);
  }

  onRemoveItem(index: number) {
    this.providers.removeAt(index);
  }

  onSubmit() {
    this.gameProviderHttpService.selectionMode = null;
    this.onCloseDialog();
  }

  private setDefaultList(){
    const providerKeys = Object.keys(this.form.value.game_providers)
    providerKeys.forEach((key) => {
      if( (this.form.value.game_providers[key]).blacklist_sub_categories !== null) {
        const selectedSubCategoryNames = ((this.form.value.game_providers[key]).blacklist_sub_categories).map((row: any) => row.name);
        this.form.value.game_providers[key].blacklist_sub_categories = selectedSubCategoryNames
      }
    });
    this.dialogRef.afterClosed().subscribe(_ => {
      let userSelections = {};
      this.form.value.game_providers.forEach((row:any) => {
        if(row.blacklist_sub_categories !== null) {
          userSelections = {
            ...userSelections,
            [row.game_provider_id]: row.blacklist_sub_categories !== null ? row.blacklist_sub_categories.map((value:any, index: number) => {
              return {
                id: `${row.game_provider_id}-${index}`,
                name: value
              }
            }) : null
          }
        }
      });
      this.gameProviderHttpService.selectedSubCategories$.next(userSelections);
      this.gameProviderHttpService.blacklistedGameProviders$.next(this.form.value);
    })
  }

  private iterateProviders() {
    this.data.providers.forEach((value: GameProvider) => this.onAddBlacklist(value.id))
  }

  private formInit() {
    this.form = this.fb.group({
      game_providers: this.providers
    })
  }

  // To reset default SubCategories
  onItemDeSelectAll(items: any) {
    if(this.data.default_blacklist_sub_categories !== null && (this.data.default_blacklist_sub_categories).length >= 1) {
      if(items.length == 0){
          let previouslySelectSubCategories = {}
          let subCategoryNames = []
          this.data.default_blacklist_sub_categories.forEach((row: any, index: number) => {
            if((row.sub_category_name).length >= 1) {
              subCategoryNames = (row.sub_category_name).map((item: any, index: number) => {
                return {
                  id: `${row.game_provider_id}-${index}`,
                  name: item
                }
              })
            }
            previouslySelectSubCategories = {
              ...previouslySelectSubCategories,
              [row.game_provider_id]: subCategoryNames
            }
          });
          this.selectedSubCategories$.next(previouslySelectSubCategories);
      }
    }
  }

  // To prevent default SubCategories to remove
  onItemDeSelect(items: any,providerId: any,list: any) {
    if(this.data.default_blacklist_sub_categories !== null && (this.data.default_blacklist_sub_categories).length >= 1) {
      let previouslySelectSubCategories = {}
      let subCategoryNames = []
      this.data.default_blacklist_sub_categories.forEach((row: any, index: number) => {
        if((row.sub_category_name).length >= 1) {
          subCategoryNames = (row.sub_category_name).map((item: any, index: number) => {
            return {
              id: `${row.game_provider_id}-${index}`,
              name: item
            }
          })
        }
        previouslySelectSubCategories = {
          ...previouslySelectSubCategories,
          [row.game_provider_id]: subCategoryNames
        }
      });
      
      if( (this.form.value.game_providers) !== null) {
        previouslySelectSubCategories[providerId].forEach(element => {
            if(items.name == element.name){
              list[providerId].push(items);
              this.selectedSubCategories$.next(list);
            }
        });
      }
    
    }

  }

}
