import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Banner } from '@core/models/banner.model';
import { Pagination } from '@core/models/pagination.model';

@Injectable()
export class BannerDataService extends DefaultDataService<Banner> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('Banner', http, httpUrlGenerator, { root: '' });
  }

  // getAll(): Observable<Banner[]> {
  //   return this.http.get<ApiResponse>('/banner').pipe(
  //     tap(res => this.paginationInit(res)),
  //     map(res => res.data.rows)
  //   );
  // }

  // add(banner: Banner): Observable<Banner>{
  //   return this.http.post<ApiResponse>(`/banner`, banner).pipe(
  //     tap( res => this.messages$.next(res.message)),
  //     concatMap((entity) => this.http.get(`/banner/${entity.data.rows.id}`).pipe(
  //       map((row: ApiResponse) => row.data.rows)
  //     ))
  //   );
  // }

  // getWithQuery(pageParam: string): Observable<Banner[]>{
  //   return this.http.get<ApiResponse>(`/banner${pageParam}`).pipe(
  //     tap(res => this.paginationInit(res)),
  //     map(res => res.data.rows)
  //   )
  // }

  // getById(id: number): Observable<Banner>{
  //   return this.http.get<ApiResponse>(`/banner/${id}`).pipe(
  //     map(res => res.data.rows)
  //   );
  // }

  // private paginationInit(res: any) {
  //   if(res) {
  //     this.pagination = res.data.paginations;
  //   }
  // }
  getWithQuery(pageParam: string): Observable<Banner[]> {
    return this.http.get<ApiResponse>(`/banner${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => {
        Object.keys(res.data.rows).forEach(x => {
          let localeList = [];

          Object.keys(res.data.rows[x].images).forEach(y => {
            localeList.push(res.data.rows[x].images[y].locale_name);
          })
          res.data.rows[x].locale_list = localeList.toString().split(',').join(', ');
        })
        return res.data.rows
      }
    ));
  }

  addBanner(param: any) {
    return this.http.post<ApiResponse>(`/banner`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  updateBanner(id: number, param: any) {
    return this.http.put<ApiResponse>(`/banner/${id}`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  updateStatus(param: any) {
    return this.http.put<ApiResponse>(`/banner/statusupdate`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }
  
  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }
}
