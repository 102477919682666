<div
  class="modal-dialog view-receipt-modal modal-dialog-scrollable m-0 scrollable-dialog"
>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">View Attachment</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="!isPDF; else elsePdf">
            <img class="img-fluid" [src]="imagePreview" alt="{{ 'Image Preview' | translate }}" />
        </ng-container>
        <ng-template #elsePdf>
            <iframe width="100%" height="100%" [src]="imagePreview" frameborder="0" style="border:0; min-height: 500px;" allowfullscreen></iframe>
          </ng-template>
    </div>
  </div>
</div>
