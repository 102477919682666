import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static email(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const emailRegex = /^\w+[-.\w]*@\w*?\.\w+[-.\w]*$/i;

      const isValid = emailRegex.test(control.value);

      // Validate against built-in email pattern (similar to RFC check)
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const isValidFormat = emailPattern.test(control.value);

      if (!isValid || !isValidFormat) {
        return { email: true };
      }

      return null;
    };
  }
}
