import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { ResponsibleGaming } from '@core/models/responsible-gaming.model';

@Injectable()
export class ResponsibleGamingDataService extends DefaultDataService<ResponsibleGaming>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('ResponsibleGaming', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<ResponsibleGaming[]> {
    return this.http.get<ApiResponse>('/responsiblegaming').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(responsibleGamingContents: ResponsibleGaming): Observable<any>{
    return this.http.post<ApiResponse>(`/responsiblegaming`, responsibleGamingContents).pipe(
      tap(res => res.message)
    );
  }

  getWithQuery(pageParam: string): Observable<ResponsibleGaming[]>{
    return this.http.get<ApiResponse>(`/responsiblegaming${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data)
    )
  }

  getById(id: number): Observable<ResponsibleGaming>{
    return this.http.get<ApiResponse>(`/responsiblegaming/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  getContentTypesStatus(){
    return this.http.get<ApiResponse>('/contenttypesstatus').pipe(
      map(res => res.data.rows)
    );
  }

  getContentTypes(){
    return this.http.get<ApiResponse>(`/contenttypes`).pipe(
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.contents.paginations;
    }
  }

  updateResponsibleGamingContent(id: number, responsibleGaming: any) {
    return this.http.put<ApiResponse>(`/responsiblegaming/${id}`, responsibleGaming).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }
  
  updateStatus(param: any) {
    return this.http.put<ApiResponse>(`/responsiblegaming/statusupdate`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  duplicate(param: any) {
    return this.http.post<ApiResponse>(`/responsiblegaming/duplicate`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }
}
