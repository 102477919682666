import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { of, Subscription } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { AffiliateInformationComponent } from './../all-affiliates/dialogs/affiliate-information/affiliate-information.component';
import { AllAffiliatesDataService } from './../all-affiliates/services/all-affiliates-data.service';
import { AffiliateApprovalEditDialogComponent } from './dialog/affiliate-approval-edit.component';
import { AffiliateApprovalDataService } from './services/affiliate-approval-data.service';
import Echo from 'laravel-echo';
import { environment } from '@env/environment';
import { CurrencyHttpService } from '@core/services/currency-http.service';

@Component({
  selector: 'kt-affiliate-approval',
  templateUrl: './affiliate-approval.component.html',
  styleUrls: ['./affiliate-approval.component.scss']
})
export class AffiliateApprovalComponent implements OnInit, OnDestroy {

  form: FormGroup;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = "";
  searchStatus = [0];
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage,
    statuses: [
      {
        name: 'Pending', value: 0, checked: true
      },
      {
        name: 'Approved', value: 1, checked: false
      },
      {
        name: 'Rejected', value: 2, checked: false
      }
    ],
  };
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  ranges = this.transactionHttpService.ranges;
  dataLength = 0;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;

  affiliateApproval$ = [];
  echo: Echo;

  private datePickerSubscription = new Subscription();
  private subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private affiliateApprovalDataService: AffiliateApprovalDataService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private authHttpService: AuthHttpService,
    private cdr: ChangeDetectorRef,
    private currencyHttpService: CurrencyHttpService
  ) {
    const SocketIoClient = require('socket.io-client');
    const socketHost: string = environment.socketHost;
    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        client: SocketIoClient,
        host: socketHost
      });
    } catch (e) {
      console.log(e);
    }
   }

  ngOnInit() {
    this.formInit();
    this.onSubmit(true);
    this.updateApprovalList();
    this.setCurrencyDropdown();
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.echo.disconnect();
  }

  private updateApprovalList() {

    const listener = (newAffiliate) => {
      //find whether new data exist in current list
      var indexOfUpdateItem = this.affiliateApproval$.findIndex(item => item.id === newAffiliate.id);
      //update approval list for new incoming approval request
      if (this.searchStatus.includes(newAffiliate.status)) {
        //if incoming data not exist in current list, push to top
        if (indexOfUpdateItem === -1) {
          this.pagination.total = this.pagination.total + 1;
          //push new approval request when user on first page
          if (this.pagination.current_page === 1) {
            this.affiliateApproval$.unshift(newAffiliate)

            //if this page can show more request
            if (this.pagination.to < this.pagination.per_page) {
              this.pagination = {
                ...this.pagination,
                from: 1,
                to: this.pagination.to + 1,
              };
            }
            else {
              //remove last request when this page cannot show more request.
              this.affiliateApproval$.splice(this.pagination.per_page, 1)
            }
          }
        }
        else {
          //update relevant affiliate rows
          this.affiliateApproval$[indexOfUpdateItem] = newAffiliate;
        }
      }
      // remove the existing row from current list when status is being updated to another status.
      else {
        if (indexOfUpdateItem !== -1) {
          this.affiliateApproval$.splice(indexOfUpdateItem, 1);
          this.pagination.total = this.pagination.total - 1;
          if (this.pagination.to <= this.pagination.per_page) {
            this.pagination = {
              ...this.pagination,
              from: 1,
              to: this.pagination.to - 1,
            };
          }
        }
      }
      this.cdr.detectChanges();
    }

    const newAffiliateChannel = this.echo.channel('affiliate-account-channel');
    newAffiliateChannel.listen(`.AffiliateAccountEvent`, listener);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.affiliateApproval$ = [];
        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef + '' + statusParams.newStatusParams;
        this.loadingBar.start();
        return this.affiliateApprovalDataService.getWithQuery(`?${this.params}&perPage=${this.pageSize}`).pipe(
          tap((res: any) => {
            this.affiliateApproval$ = res;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.affiliateApprovalDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onSearchStatus(event: any, id: number, ind: number) {
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    this.loadingBar.start();
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.affiliateApproval$ = [];
    return this.affiliateApprovalDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap((res: any) => {
        this.affiliateApproval$ = res;
        this.pagination = this.affiliateApprovalDataService.pagination;
        this.dataLength = res.length;
        this.loading = false;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onSubmit();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.pageSize = 30;
    this.searchStatus = [0];
    this.dropdown.statuses.forEach((child) => {
      if (child.value === 0) {
        child.checked = true;
      } else {
        child.checked = false;
      }
    });
    this.form.patchValue({
      username: null,
      name: null,
      currency_id: 'all',
      start_datetime: null,
      end_datetime: null,
      defaultDate: null
    });
    this.onSubmit(true);
  }

  onOpenDialog(mode: string, row?: any) {
    if (mode === 'information') {
      const affiliate = this.allAffiliatesDataService.getById(row.id);
      this.subscription = affiliate.pipe(
        tap((res: any) => {
          if (res) {
            res = {
              ...res,
              currency_code: row.currency
            };
            this.openDialogBy(AffiliateInformationComponent, { affiliate: res, mode: mode });
          }
        })
      ).subscribe();
    } else {
      this.openDialogBy(AffiliateApprovalEditDialogComponent, { affiliate: row, mode: mode });
    }
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private openDialogBy(componentRef: any, data?: { affiliate?: any, mode?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'information' ? '1500px' : '800px',
      data: {
        affiliate: data.affiliate,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onSubmit();
      }
    });
  }

  private generateStatusParams() {
    return {
      newStatusParams: this.searchStatus.length > 0 ? '&' + Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&') : ''
    };
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      name: new FormControl(null),
      currency_id: new FormControl("all"),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null) // Do not remove: For Clearing The Range,
    });
  }

}
