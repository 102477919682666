import { catchError, tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { GroupDataService } from '../../services/group-data.service';
import { DepositLimit } from '@core/models/deposit-limit.model';
import { SitePrefix } from '@core/enums/site-prefix.enum';
import { environment } from '@env/environment';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-deposit-limit',
  templateUrl: './deposit-limit.component.html',
  styleUrls: ['./deposit-limit.component.scss']
})
export class DepositLimitDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  messages$ = this.groupDataService.messages$;
  sitePrefix: string = environment.sitePrefix;
  isWhiteLabel: boolean = false;
  depositLimitFields = ['min', 'max', 'daily_max_count', 'daily_max'];
  refreshStatus: boolean;
  buttonLoading = false;

  // permissions
  canEditDepositLimits: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, depositLimit: DepositLimit },
    public dialogRef: MatDialogRef<DepositLimitDialogComponent>,
    private groupDataService: GroupDataService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditDepositLimits = appPermissions.edit_deposit_limits;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    this.subscription = this.groupDataService.updateDepositLimit(data).pipe(
      tap((res: any) => {
        this.messages$.next([...res.message]);
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    const depositLimitForm = () => {
      const depositLimits = this.data.depositLimit;
      let depositLimit = {};
      let limitData = {};
      for (const row in depositLimits) {
        if (row) {
          Object.keys(depositLimits[row]).forEach((key) => {
            limitData = { ...limitData, [key]: new FormControl(depositLimits[row][key], Validators.compose([Validators.required, Validators.min(0)])) }
          });
          depositLimit = { ...depositLimit, [row]: new FormGroup(limitData) }
        }
      }
      return depositLimit;
    }
    this.form = new FormGroup({
      deposit_limits: new FormGroup(depositLimitForm())
    });
  }

}
