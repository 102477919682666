
import { ReferralCommissionSettingsComponent } from './referral-commission-settings/referral-commission-settings.component';
import { ReferralCommissionHistoryComponent } from './referral-commission-history/referral-commission-history.component';
import { Routes, RouterModule} from '@angular/router';
import { NgModule } from '@angular/core';
import { ReferralCommissionSettingsEditDialogComponent } from './referral-commission-settings/dialogs/referral-commission-settings-edit.component';
import { ReferralCommissionComponent } from './referral-commission/referral-commission.component';
import { ReferralCommissionDialogComponent } from './referral-commission/dialogs/referral-commission-releases/referral-commission-generate.component';
import { ReferralCommissionDetailsComponent } from './referral-commission/dialogs/referral-commission-details.component';

const routes: Routes = [
  {
    path: 'settings',
    component: ReferralCommissionSettingsComponent
  },
  {
    path: 'history',
    component: ReferralCommissionHistoryComponent
  },
  {
    path: 'release',
    component: ReferralCommissionComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  entryComponents: [
    ReferralCommissionSettingsEditDialogComponent,
    ReferralCommissionDialogComponent,
    ReferralCommissionDetailsComponent
  ]
})

export class ReferralCommissionRoutingModule { }
