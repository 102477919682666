import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { ResponsibleGaming } from '@core/models/responsible-gaming.model';

@Injectable()
export class ResponsibleGamingEntityService extends EntityCollectionServiceBase<ResponsibleGaming> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('ResponsibleGaming', serviceElementsFactory);
  }
}
