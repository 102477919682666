import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { Lightbox } from 'ngx-lightbox';
import { Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { loggedUser } from './../../../../../../core/store/auth/auth.selectors';
import { AppState } from './../../../../../../store/reducers';
import { AffiliateDepositsDataService } from './../services/affiliate-deposits-data.service';
import { AffiliateDepositsEntityService } from './../services/affiliate-deposits-entity.service';

@Component({
  selector: 'kt-affiliate-deposit-details',
  templateUrl: './deposit-details.component.html',
  styleUrls: ['./deposit-details.component.scss']
})
export class AffiliateDepositDetailsComponent implements OnInit, OnDestroy {

  isValidRemarks = true;
  depositStatus: number;
  depo = {
    ...this.data.deposit
  };

  form: FormGroup;
  @ViewChild(OwlDateTimeInputDirective) datePicker: OwlDateTimeInputDirective<any>;

  deposit = {
    newBoUserId: 0
  };
  messages$ = this.depositDataService.messages$;
  isSelectedMerchantBank = false;
  refreshStatus: boolean;

  bankaccountDropdownSettings = {};
  bankaccountDropdownList = [];
  bankaccountSelectedItems: any;

  buttonLoading = false;

  receiptList = [];
  currentUsername: string;

  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { deposit: any, mode: string, newHandler: boolean },
    public dialogRef: MatDialogRef<AffiliateDepositDetailsComponent>,
    public dialog: MatDialog,
    private depositEntityService: AffiliateDepositsEntityService,
    private depositDataService: AffiliateDepositsDataService,
    private merchantBankHttpService: MerchantBankHttpService,
    private store: Store<AppState>,
    private lightbox: Lightbox
  ) { }

  ngOnInit() {
    this.store.pipe(select(loggedUser)).subscribe((res) => { this.currentUsername = res.username; });
    this.receiptList.push({
      src: this.data.deposit['receipt_path'],
      caption: (this.data.deposit['username'] + ' deposit receipt').toLocaleUpperCase(),
      thumb: this.data.deposit['receipt_path']
    });
    this.formInit();
    this.refreshStatus = this.data.newHandler;
    this.merchantBankHttpService.getMerchantBankAccounts(`&status[0]=1&status[1]=2&purpose=1&currency_id=${this.depo.currency_id}`).pipe(
      map(res => res.map(function (elm) {
        elm['labelKey'] = elm.bank_code + ' - ' + elm.account_name + ' - ' + elm.account_number;
        elm['value'] = [elm.id, elm.account_name, elm.account_number, elm.bank_name];
        return elm;
      }))
    ).subscribe(res => {
      var selectedBank = res.find(v => v.account_number == this.data.deposit.account_number);
      this.bankaccountDropdownList = res;
      this.bankaccountSelectedItems = selectedBank ? [selectedBank] : [];
    });

    this.bankaccountDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'labelKey',
      maxHeight: 170,
      labelKey: 'labelKey',
      noDataLabel: '',
      showCheckbox: false
    };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  openReceipt() {
    this.lightbox.open(this.receiptList, 0, { centerVertically: true, disableScrolling: true, fitImageInViewPort: true, fadeDuration: 0.01, resizeDuration: 0.01 });
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(deposit: any, type: string) {
    this.buttonLoading = true;
    this.subscription = this.depositEntityService.getByKey(deposit.id).pipe(
      tap((res: any) => {
        this.deposit.newBoUserId = res.bo_user_id;
        const data = {
          id: deposit ? deposit.id : null,
          ...this.form.value,
          status: (type === 'approve') ? 1 : 2,
        };

        Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
        const updateDeposit = (takeover = false) => {
          data.takeover = takeover;
          this.depositDataService.updateCurrentDeposit(data).pipe(
            tap((row: any) => {
              this.messages$.next([...row.message]);
              this.buttonLoading = false;
            })
          ).subscribe();
        };
        Swal.fire({
          title: 'Are you sure?',
          text: 'Do you want to ' + type + ' this deposit?',
          icon: 'info',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          reverseButtons: true
        }).then((resp) => {
          if (resp.value) {
            if (type === 'approve') {
              this.isValidRemarks = true;
              updateDeposit();
            }
            if (type === 'reject') {
              if (this.form.get('remarks').value) {
                updateDeposit();
              }
              else {
                Swal.fire({
                  title: 'Input Error',
                  text: 'Remarks required to reject',
                  icon: 'error',
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Yes',
                }).then(() => {
                  this.isValidRemarks = false;
                });
              }
            }
          }
          this.buttonLoading = false;
        });
      })).subscribe();
    this.refreshStatus = true;
  }

  onUpdateRemarks() {
    const data = {
      id: this.data.deposit.id,
      ...this.form.value,
    };

    Object.keys(data).forEach((key) => (key !== 'remarks' && key !== 'id') && delete data[key]);
    this.depositDataService.updateRemarks(data).pipe(
      tap((row: any) => {
        this.refreshStatus = true;
      })
    ).subscribe();
  }

  onAdd() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      ...this.form.value,
      datetime: moment(this.form.value.datetime).utc().format('YYYY-MM-DD HH:mm:ss'),
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] < 1) && delete data[key]);
    this.depositEntityService.add(data).pipe(
      tap((res: any) => {
        this.form.setErrors(null);
        this.buttonLoading = false;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    );
    this.messageSubscription = this.depositDataService.messages$.subscribe(message => {
      if (message.length > 0) {
        this.messages$.next([...message]);
        this.buttonLoading = false;
      }
    });
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onSelectMerchantBank(bankID: number) {
    const bankId = bankID;
    const merchantBankData = this.merchantBankHttpService.getById(bankId);
    this.isSelectedMerchantBank = bankId ? true : false;
    if (bankId) {
      this.subscription = merchantBankData.pipe(
        tap((res) => {
          this.form.patchValue({
            account_name: res.account_name,
            account_number: res.account_number,
            merchant_bank_account: res.bank_code + " - " + res.account_name + " - " + res.account_number,
            merchant_bank_id: bankID
          });
        })).subscribe();
    }
  }

  private formInit() {
    let merchantAccountName = null;
    let merchantAccountNumber = null;
    let merchantBank = null;
    let merchantBankName = null;
    let merchantBankAccount = null;
    let remarks = null;
    let status = 1;

    if (this.data.mode === 'edit') {

      this.depositStatus = this.depo.status === 0 ? 3 : this.depo.status;

      merchantBank = this.data.deposit.merchant_bank_id,
      merchantBankName = this.data.deposit.merchant_bank,
      merchantAccountName = this.data.deposit.account_name;
      merchantAccountNumber = this.data.deposit.account_number;
      merchantBankAccount = this.data.deposit.merchant_bank_account;

      remarks = this.data.deposit.remarks;
      status = this.data.deposit.status;
      this.form = new FormGroup({
        merchant_bank_id: new FormControl(merchantBank),
        merchant_bank_details: new FormControl([merchantBank, merchantAccountName, merchantAccountNumber, merchantBankName]),
        account_name: new FormControl(merchantAccountName),
        account_number: new FormControl(merchantAccountNumber),
        merchant_bank_account: new FormControl(merchantBankAccount),
        remarks: new FormControl(remarks, [Validators.min(3)]),
        status: new FormControl(status),
      });

      this.form.get('merchant_bank_details').valueChanges.subscribe(res => {
        res !== null ? this.onSelectMerchantBank(res[0]) : '';
      });

      this.form.get('remarks').valueChanges.subscribe(res => {
        if (res) {
          this.isValidRemarks = true;
        }
      });
    }
  }

}
