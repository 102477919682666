import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { AgentReportSummary } from '@core/models/agent-report-summary.model';
import { AgentReport } from '@core/models/agent-report.model';
import { AgentReportDataService } from '../../../agent-report/services/agent-report-data.service';
import { AgentReportEntityService } from '../../../agent-report/services/agent-report-entity.service';

@Component({
  templateUrl: './all-agent-reports-dialog.component.html',
  styleUrls: ['./all-agent-reports-dialog.component.scss']
})
export class AllAgentReportsDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  paginationTotal = 1;
  from = this.transactionHttpService.getLast24Hours().from;
  to = this.transactionHttpService.getLast24Hours().to;
  currencyCode = 'MYR'; // Default

  dropdownSettings = {};
  params = `agent_username=${this.data.agent_username}&start_date_time=${moment(this.data.start_date_time).tz(this.data.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date_time=${moment(this.data.end_date_time).tz(this.data.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&currency_id=${this.data.currency_id}`;
  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };

  agentReport$: Observable<AgentReport[]>;

  pagination: Pagination;
  summary$: AgentReportSummary;
  messages$ = this.agentReportDataService.messages$;
  ranges: any;
  onSearch = false;
  private datePickerSubscription = new Subscription();
  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {agent_username: string, currency_id: number, start_date_time: string, end_date_time: string, timezone: string },
    public dialogRef: MatDialogRef<AllAgentReportsDialogComponent>,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private agentReportDataService: AgentReportDataService,
    private agentReportEntityService: AgentReportEntityService,
    private loadingBar: LoadingBarService
  ) { }

  ngOnInit() {
    this.onViewPageBy();
    this.formInit();
  }

  ngAfterViewInit() {
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.agentReport$ = this.agentReportEntityService.getWithQuery(`&page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.agentReportDataService.pagination;
        this.summary$ = this.agentReportDataService.summary;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private formInit() {
    this.form = new FormGroup({
      agent_username: new FormControl(this.data.agent_username),
      currency_id: new FormControl(this.data.currency_id, [Validators.required]), // Default
      start_date_time: new FormControl(this.data.start_date_time, [Validators.required]),
      end_date_time: new FormControl(this.data.end_date_time, [Validators.required]),
      timezone: new FormControl(this.data.timezone),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      }) // Do not remove: For Clearing The Range
    });
  }

}
