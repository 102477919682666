import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { PrivacyPolicy } from '@core/models/privacy-policy.model';

@Injectable()
export class PrivacyPolicyDataService extends DefaultDataService<PrivacyPolicy>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('PrivacyPolicy', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<PrivacyPolicy[]> {
    return this.http.get<ApiResponse>('/privacypolicy').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(privacyPolicyContents: PrivacyPolicy): Observable<any>{
    return this.http.post<ApiResponse>(`/privacypolicy`, privacyPolicyContents).pipe(
      tap(res => res.message)
    );
  }

  getWithQuery(pageParam: string): Observable<PrivacyPolicy[]>{
    return this.http.get<ApiResponse>(`/privacypolicy${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data)
    )
  }

  getById(id: number): Observable<PrivacyPolicy>{
    return this.http.get<ApiResponse>(`/privacypolicy/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  getContentTypesStatus(){
    return this.http.get<ApiResponse>('/contenttypesstatus').pipe(
      map(res => res.data.rows)
    );
  }

  getContentTypes(){
    return this.http.get<ApiResponse>(`/contenttypes`).pipe(
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.contents.paginations;
    }
  }

  updatePrivacyPolicyContent(id: number, faq: any) {
    return this.http.put<ApiResponse>(`/privacypolicy/${id}`, faq).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  updateStatus(param: any) {
    return this.http.put<ApiResponse>(`/privacypolicy/statusupdate`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  duplicate(param: any) {
    return this.http.post<ApiResponse>(`/privacypolicy/duplicate`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }
}
