<div class="row">
  <section class="col-lg-12 card border-0">
    <div class="col-12 p-0 card rounded-0">
      <div class="card-header">
        Deposit Details
      </div>
      <section class="col-12 p-4">
        <div class="row border-top border-left">
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Member ID</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ deposit.member_id }}</span>
          </div>
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Username</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ deposit.username }}</span>
          </div>
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Name</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ deposit.name }}</span>
          </div>
        </div>

        <div class="row border-top border-left">
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Payment Method</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ deposit.payment_method }}</span>
          </div>
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Confirmed Amount</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span>{{ deposit.confirmed_amount }}</span>
          </div>
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Status</span>
          </div>
          <div class="col-md-2 border-right p-3">
            <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + (deposit.status === 7 ? '5' : deposit.status)"> {{ status[deposit.status] }}</span>
          </div>
        </div>

        <div class="row border-top border-left border-bottom">
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">References</span>
          </div>
          <div class="col-md-10 border-right p-3">
            <span>{{ deposit.pg_reference_id ? deposit.pg_reference_id : '-' }}</span>
          </div>
        </div>

        <div class="row border-top border-left border-bottom">
          <div class="col-lg-2 col-md-2 card-header border-right p-3">
            <span class="kt-font-bold">Latest Remark</span>
          </div>
          <div class="col-md-10 border-right p-3">
            <span>{{ deposit.remarks ? deposit.remarks : '-' }}</span>
          </div>
        </div>
      </section>
    </div>
  </section>
</div>

<div class="row mt-5">
  <section class="col-lg-12 card border-0">
    <div class="col-12 p-0 card rounded-0" *ngIf="allLogs">
      <div class="card-header">
        Timeline
      </div>
      <div class="row ml-1" *ngFor="let row of allLogs">
        <div class="col-1 p-3">
          {{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }}
        </div>
        <div class="col-11 p-3 timelinediv">
          <!-- The Timeline -->
          <div class="timeline">
            <!-- Item 1 -->
            <div>
              <div class="direction-r">
                <div class="flag-wrapper">
                  <span class="flag" [ngClass]="'border-color--' + row.status">
                    <section class="col-12 p-3">
                      <div class="row border-top border-left">
                        <div class="col-lg-3 col-md-3 card-header border-right p-3">
                          <span class="kt-font-bold">Action</span>
                        </div>
                        <div class="col-md-9 border-right p-3">
                          <span>{{ row.action_name }}</span>
                        </div>
                      </div>

                      <div class="row border-top border-left" *ngIf="row.action == 2 || row.action == 6 || row.action == 7 || row.action == 8 || row.action == 9 || row.action == 10 || row.action == 11">
                        <div class="col-lg-3 col-md-3 card-header border-right p-3">
                          <span class="kt-font-bold">Remark</span>
                        </div>
                        <div class="col-md-9 border-right p-3">
                          <span>{{ row.remarks }}</span>
                        </div>
                      </div>

                      <div class="row border-top border-left" *ngIf="row.action == 6">
                        <div class="col-lg-3 col-md-3 card-header border-right p-3">
                          <span class="kt-font-bold">Reason</span>
                        </div>
                        <div class="col-md-9 border-right p-3">
                          <span>{{ row.ext_remarks ? (row.ext_remarks.startsWith('Reason: ') ? row.ext_remarks.replace('Reason: ', '') : row.ext_remarks) : '' }}</span>
                        </div>
                      </div>

                      <div class="row border-top border-left border-bottom">
                        <div class="col-lg-3 col-md-3 card-header border-right p-3">
                          <span class="kt-font-bold">Receipt</span>
                        </div>
                        <div class="col-md-3 border-right p-3">
                          <!-- <span>{{ row.id }}</span> -->
                          <button *ngIf="canViewReceipts && depositReceipts.length > 0" (click)="openReceipt()" type="button" class="btn btn-primary mr-2">View Receipt</button>
                        </div>
                        <div class="col-lg-3 col-md-3 card-header border-right p-3">
                          <span class="kt-font-bold">Handler</span>
                        </div>
                        <div class="col-md-3 border-right p-3">
                          <span class="text-right"> {{ row.created_by ? row.created_by : '-' }} <br> {{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</span>
                        </div>
                      </div>
                    </section>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-3 text-right" *ngIf="allLogs.length > 0 && pagination?.current_page < pagination?.last_page">
        <button type="button" class="btn btn-loadmore" (click)="loadMoreLogs()"><i [ngClass]="(loadMoreButtonLoading) ? 'spinner-border spinner-border-sm align-middle mr-2' : 'fas fa-ellipsis-h'"></i>Load More</button>
      </div>
    </div>
  </section>
</div>