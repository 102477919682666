import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Dialog } from '@core/models/dialog.model';

@Injectable()
export class DialogEntityService extends EntityCollectionServiceBase<Dialog> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('Dialog', serviceElementsFactory);
  }
}
