import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { EditorService } from '@core/services/editor.service';
import { VerificationSettingsDataService } from '../../services/verification-settings-data.service';
import { VerificationSettingsBankComponent } from '../verification-settings-bank/verification-settings-bank.component';
import { VerificationSettingBankDataService } from '../verification-settings-bank/services/verification-settings-bank-data.service';

interface MemberGroup {
  id: number;
  code: string;
  name: string;
  color: string;
  status: number;
  default: number;
  vip: number;
  trial: number;
  image: string;
  remarks: string | null
}
@Component({
  selector: 'verification-settings-currency-content',
  templateUrl: './verification-settings-currency-content.component.html',
  styleUrls: ['./verification-settings-currency-content.component.scss']
})

export class VerificationSettingsCurrencyContentComponent implements OnInit, OnDestroy {
  @Output() buttonLoadingChange = new EventEmitter<boolean>();

  @Input()
  detail: any;

  @Input()
  mode: string;

  @Input()
  verification_setting_id: number;
  
  @Input()
  settings_currency_id: number;

  @Input()
  bank_type_id: number;

  @Input()
  new_currency: boolean;

  // Begin: CKEditor Part
  public editor = Editor;
  editorConfig = this.editorService.config;

  formContent: FormGroup;

  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    memberGroups: this.dropdownHttpService.groups,
    locales$: this.verificationSettingsDataService.locales$,
    locales: [],
    fields: []
  };

  memberGroupDropdownList: MemberGroup[] = [];
  memberGroupSelectedItems: MemberGroup[] = [];
  memberGroupDropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'name',
    labelKey: 'name',
    // lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    disabled: false
  };

  selectedPurpose: number;
  private localesSub = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
    private editorService: EditorService,
    private verificationSettingsDataService: VerificationSettingsDataService,
    private verificationSettingBankDataService: VerificationSettingBankDataService,
  ) { }

  ngOnInit(): void {
    this.dropdown.memberGroups.subscribe((res: MemberGroup[]) => {
      this.memberGroupDropdownList = res;
  
      if (Object.keys(this.detail).length > 0) {
        const memberGroupIds = this.detail.member_group.map(id => Number(id));
        this.memberGroupSelectedItems = this.memberGroupDropdownList.filter((item: MemberGroup) => memberGroupIds.includes(item.id));
      }
    });

    this.localesSub = this.dropdown.locales$.subscribe(res => {
      this.dropdown.locales = res.filter(x => x['settings_currency_id'] == this.settings_currency_id);
    });

    this.formInit();
  }

  ngOnDestroy() {
    this.localesSub.unsubscribe();
  }

  onOpenDialog(dialog: string) {
    if (dialog === 'bank-list') {
      this.openDialogBy(VerificationSettingsBankComponent, {mode: this.mode, new_currency: this.new_currency});
    }
  }

  private openDialogBy(componentRef: any, data?: { mode: string, new_currency: boolean}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        mode: data.mode,
        verificationSettingsID: this.verification_setting_id,
        currencyID: this.settings_currency_id,
        bankTypeID: this.bank_type_id,
        new_currency: data.new_currency
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (this.mode == 'create' || (this.mode == 'edit' && this.new_currency)) {
        this.formContent.patchValue({
          bank: localStorage.getItem('createVerificationSettingsBank') === null ? [] : JSON.parse(localStorage.getItem('createVerificationSettingsBank')).filter(item => item.settings_currency_id === this.settings_currency_id).map(item => item.id)
        });
      } else if (this.mode == 'edit' && !this.new_currency) {
        if (JSON.parse(localStorage.getItem('updateVerificationSettingsBank')) == 1) {
          this.buttonLoadingChange.emit(true);

          this.verificationSettingBankDataService.getWithQuery(`?paginate=false&verification_setting_id=${this.verification_setting_id}&settings_currency_id=${this.settings_currency_id}`).subscribe(res => {
            this.formContent.patchValue({
              bank: res.map((item: { bank_id: number }) => item.bank_id)
            });

            localStorage.removeItem('updateVerificationSettingsBank');
            this.buttonLoadingChange.emit(false);
          })
        }
      }
    });
  }

  onChangeType(event: any, index: number) {
    // To set all type dropdown in every locale into same value
    (this.formContent.get('field') as FormArray).at(index).get('type')?.setValue(event.target.value, { emitEvent: false });
  }

  private formInit() {
    let status = null;
    let member_group = [];
    let bank = [];

    if (Object.keys(this.detail).length > 0) {
      status = this.detail.status;
      member_group = this.detail.member_group;
      bank = this.detail.bank;
    }
  
    const buildContents = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          settings_locale_id: new FormControl(element.id),
          settings_locale_code: new FormControl(element.code),
          lang_code: new FormControl(element.lang_code),
          title: new FormControl(null, [Validators.required]),
          content: new FormControl(null, [Validators.required])
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    const buildField = () => {
      return new FormArray([this.createFieldGroup()]);
    };
  
    this.formContent = new FormGroup({
      settings_currency_id: new FormControl(this.settings_currency_id, [Validators.required]),
      status: new FormControl(status, [Validators.required]),
      member_group: new FormControl(member_group, [Validators.required]),
      bank: new FormControl(bank, [Validators.required]),
      content: new FormGroup(buildContents()),
      field: buildField()
    });
  
    if (Object.keys(this.detail).length > 0) {
      // Patch content value
      let contentArray: any[];
      if (Array.isArray(this.detail.content)) {
        contentArray = this.detail.content;
      } else {
        contentArray = Object.values(this.detail.content);
      }
      
      contentArray.forEach((ct: any) => {
        this.dropdown.locales.forEach((lo: any) => {
          if(lo.id === ct.settings_locale_id) {
            this.formContent.patchValue({ content: { [lo.id]: { ...ct }}})
          }
        });  
      });

      // Patch field value
      const fieldArray = this.formContent.get('field') as FormArray;
      this.detail.field.forEach((ct: any, index: number) => {
        let mappedFieldDetails = {};
        // Map field_details array to object with locale keys
        if (Array.isArray(ct.field_details)) {
          mappedFieldDetails = ct.field_details.reduce((acc, detail) => {
              acc[detail.settings_locale_id] = detail;
              return acc;
          }, {});
        } else if (typeof ct.field_details === 'object' && ct.field_details !== null) {
          mappedFieldDetails = { ...ct.field_details };
        }

        // Patch each form control in fieldArray at 'index'
        fieldArray.at(index).patchValue({
          id: ct.id,
          status: ct.status,
          type: ct.type,
          field_details: mappedFieldDetails
        });

        if (this.detail.field.length - 1 > index) {
          this.addField();
        }
      });
    }
  }

  buildFieldDetail = () => {
    let fields = {};
    this.dropdown.locales.forEach((element: any) => {
      const subFields = new FormGroup({
        settings_locale_id: new FormControl(element.id, [Validators.required]),
        settings_locale_code: new FormControl(element.code, [Validators.required]),
        lang_code: new FormControl(element.lang_code, [Validators.required]),
        name: new FormControl(null, [Validators.required]),
        position: new FormControl(null, [Validators.required])
      });
      fields = { ...fields, [element.id]: subFields };
    });
    return fields;
  };

  createFieldGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      type: new FormControl(null, [Validators.required]),
      status: new FormControl(1, [Validators.required]),
      field_details: new FormGroup(this.buildFieldDetail())
    });
  }

  addField() {
    const fieldArray = this.formContent.get('field') as FormArray;
    fieldArray.push(this.createFieldGroup());
  }
  
  removeField(index: number) {
    const fieldArray = this.formContent.get('field') as FormArray;
    if (fieldArray.length > 1) {
      fieldArray.removeAt(index);
    }
  }

  isFromDetailField(index: number): boolean {
    // Unable to delete or change Type if data is created before
    return this.detail && this.detail.field && index < this.detail.field.length;
  }
}
