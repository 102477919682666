<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div  class="kt-portlet kt-portlet--mobile">
      <div  class="kt-portlet__body">
          <div class="kt-form kt-form--label-right">
              <div class="row align-items-center">
                  <div class="col-xl-12 order-2 order-xl-1 pr-0">
                      <form class="row align-items-center" [formGroup]="form">
                          <div class="col-12 row pr-0">
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Status:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                                <select formControlName="status" class="form-control">
                                    <option value="all"> All </option>
                                    <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                                        {{ value }}
                                    </option>
                                </select>
                            </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
          <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
              <div class="dropdown dropdown-inline">
                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
              </div>
              <button *ngIf="canCreatePromotionContentCategory" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
          </div>
          <div  class="kt-section">
              <div  class="kt-section__content">
                  <div  class="table-responsive">
                      <table  class="table table-bordered table-hover table-striped">
                          <thead>
                            <tr class="text-top">
                                <th>Name</th>
                                <th class="text-center">Status</th>
                                <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of promotionCategory$ | async">
                              <td>{{ row.name }} </td>
                              <td class="text-center">
                                <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status">{{ row.status === 0 ? 'Inactive' : 'Active' }}</span>
                              </td>
                              <td class="text-center">
                                <button *ngIf="canEditPromotionContentCategory" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                      </table>
                  </div>
                  <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                    <ngb-pagination
                      [pageSize]="pageSize"
                      [(page)]="page"
                      [maxSize]="maxSize"
                      [directionLinks]="true"
                      [boundaryLinks]="true"
                      [rotate]="true"
                      [collectionSize]="pagination.total"
                      (pageChange)="onViewPageBy(page)">
                    </ngb-pagination>
                    <div class="kt-pagination__toolbar">
                        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                        </select>
                        <span class="pagination__desc">
                          Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                        </span>
                    </div>
                  </div>
              </div>
          </div>
          <!-- table ends -->
      </div>
  </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onReload()"></kt-swal-alert>
