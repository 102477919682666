import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PromotionMember } from '@core/models/promotion-member.model';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PromotionMemberListDataService } from '../services/promotion-member-list-data.service';
import { tap, catchError } from 'rxjs/operators';
import { Status } from '@core/enums/status.enum';

@Component({
  selector: 'kt-promotion-member-list',
  templateUrl: './promotion-member-list-details.component.html',
  styleUrls: ['./promotion-member-list-details.component.scss']
})
export class PromotionMemberDetailsComponent implements OnInit, OnDestroy {

  status = Status;
  form: FormGroup;
  dropdown = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'id',
    labelKey: 'username',
    noDataLabel: '',
    showCheckbox: false,
    lazyLoading: true,
  };
  messages$ = this.promotionMemberDataService.messages$;
  refreshStatus: boolean;
  buttonLoading = false;
  selectedPerson = [];
  private subscription = new Subscription();
  private messageSubscription = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { promotionPerson: any, mode: string, promotionId: number, promotionCodeMode: string, promoCodeFormData: any },
    private promotionMemberDataService: PromotionMemberListDataService,
    public dialogRef: MatDialogRef<PromotionMemberDetailsComponent>,
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  private formInit() {
    let promotionId = null;
    let settingsPersonId = null;

    if (this.data.mode === 'edit') {
      promotionId = this.data.promotionCodeMode === 'create' ? null : this.data.promotionPerson.promotion_id;
      settingsPersonId = +this.data.promoCodeFormData.eligible_types !== 2 ? (this.data.promotionCodeMode === 'create' ? null : this.data.promotionPerson.member_ids) : (this.data.promotionCodeMode === 'create' ? null : this.data.promotionPerson.affiliate_ids);
    }

    this.form = new FormGroup({
      promotion_id: new FormControl(localStorage.getItem('promoId')), // to refactor soon
      [+this.data.promoCodeFormData.eligible_types !== 2 ? 'member_ids' : 'affiliate_ids']: new FormControl({value: settingsPersonId, disabled: this.data.mode === 'edit'}),
    });
  }

  onSave(promotionPersonListDetails: PromotionMember, mode?: string) {
    let data = {
      id: promotionPersonListDetails ? promotionPersonListDetails.id : null,
      ...this.form.value,
      promotion_id: this.data.promotionCodeMode === 'create' ? null : (mode === 'create') ? this.data.promotionId : this.data.promotionPerson.promotion_id,
    };

    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);

    if(this.data.promotionCodeMode === 'edit'){
      this.buttonLoading = true;
      if(mode == 'create') {
        var persons = [];
        let selectedMemberUsernames = [];

        this.selectedPerson.forEach(function sampleFunc(res, index) {
          var searchItemData = persons != null?persons.filter(res => res === res.username):[];
          if(searchItemData.length <= 0){
            persons.push(res.username);
            selectedMemberUsernames.push(res.username);
          }
        });

        if (+this.data.promoCodeFormData.eligible_types !== 2){
          data = {
            ...data,
            members: selectedMemberUsernames,
            new: true
          };
          this.subscription = this.promotionMemberDataService.addMember(data).pipe(
            tap(() => {
              localStorage.setItem('updatePromoCodeMembers', '1');
              this.dialogRef.close(true);
            }),
            catchError((error) => {
                throw error;
            })
          ).subscribe();
        }else{
          data = {
            ...data,
            affiliates: persons
          };

          this.subscription = this.promotionMemberDataService.addAffiliate(data).pipe(
            tap(() => {
              localStorage.setItem('updatePromoCodeAffiliates', '1');
              this.dialogRef.close(true);
            }),
            catchError((error) => {
                throw error;
            })
          ).subscribe();
        }
      }
      this.refreshStatus = true;
    }else{
      if (this.data.mode === 'create'){
        if (+this.data.promoCodeFormData.eligible_types !== 2 ){
          if (this.form.value.member_ids !== null){
            let updatedData = [data];

            this.selectedPerson.forEach(function sampleFunc(member, index) {
              const createPromoCodeMembers = localStorage.getItem('createPromoCodeMembers');
              data = {
                id: member.id,
                username: member.username,
                group: member.group,
              };

              updatedData = [data];

              var memberArray = JSON.parse(createPromoCodeMembers);
              var searchItemData = memberArray != null?memberArray.filter(res => +res.id === +member.id):[];
              if(searchItemData.length <= 0){
                if (createPromoCodeMembers !== null){
                  JSON.parse(createPromoCodeMembers).map( (res: any) => {
                    updatedData.push(res)
                  });
                };
                localStorage.setItem('createPromoCodeMembers', JSON.stringify(updatedData));
              }

            });
          }
        }else{
          if (this.form.value.affiliate_ids !== null){
            let updatedData = [data];

            this.selectedPerson.forEach(function sampleFunc(result, index) {
              const createPromoCodeAffiliates = localStorage.getItem('createPromoCodeAffiliates');
              data = {
                id: result.id,
                username: result.username,
                group: result.affiliate_group,
              };

              updatedData = [data];

              var memberArray = JSON.parse(createPromoCodeAffiliates);
              var searchItemData = memberArray != null?memberArray.filter(res => +res.id === +result.id):[];
              if(searchItemData.length <= 0){
                if (createPromoCodeAffiliates !== null){
                  JSON.parse(createPromoCodeAffiliates).map( (res: any) => {
                    updatedData.push(res)
                  });
                };
                localStorage.setItem('createPromoCodeAffiliates', JSON.stringify(updatedData));
              }

            });
          }
        }
      }
      this.dialogRef.close(1);
    }
  }

  onSelectedPersonChanged(value) {
    this.selectedPerson = value;
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

}
