import { Injectable } from '@angular/core';
import { AffiliateDeposits } from '@core/models/affiliate-deposits.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable()
export class AffiliateDepositsEntityService extends EntityCollectionServiceBase<AffiliateDeposits> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('AffiliateDeposits', serviceElementsFactory);
  }
}

