import { EditorService } from '@core/services/editor.service';
import { SampleMessageDataService } from './../services/sample-message-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, catchError } from 'rxjs/operators';
import { SampleMessageEntityService } from './../services/sample-message-entity.service';
import { Subscription, forkJoin } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SampleMessage} from '@core/models/sample-message.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CustomUploadAdapterPlugin } from '@core/ckeditor-upload-adapter/CustomUploadAdapterPlugin';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'kt-member-sample-message-form',
  templateUrl: './sample-message-edit.component.html',
  styleUrls: ['./sample-message-edit.component.scss']
})
export class SampleMessageEditDialogComponent implements OnInit, OnDestroy  {

  form: FormGroup;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  messages$ = this.sampleMessageDataService.messages$;
  refreshStatus: boolean;

  dropdown = {
    statuses: this.dropdownHttpService.statuses
  };
  @ViewChild( 'editors' ) editorComponent: CKEditorComponent;
  buttonLoading = false;
  public editor = Editor;
  editorConfig = this.editorService.config;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { sampleMessage: SampleMessage, mode: string},
    public dialogRef: MatDialogRef<SampleMessageEditDialogComponent>,
    private sampleMessageService: SampleMessageEntityService,
    private sampleMessageDataService: SampleMessageDataService,
    private dropdownHttpService: DropdownHttpService,
    private editorService: EditorService
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(sampleMessage: SampleMessage, mode?: string) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      id: sampleMessage? sampleMessage.id : null,
      preset_msg: sampleMessage? sampleMessage.preset_msg : '',
      position: sampleMessage? sampleMessage.position : 99,
      ...this.form.value,
    };
    Object.keys(data).forEach((key) => (data[key] == null) && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.sampleMessageDataService.updateSampleMessage(data, data.id).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.sampleMessageDataService.add(data).pipe(
            tap((res: any) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              // this.messages$.next([...res.message]);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              throw error;
            })
          )
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    let status = 1;
    let preset_msg = null;
    let position = 99;

    if (this.data.mode === 'edit'){
      status =  this.data.sampleMessage.status;
      preset_msg =  this.data.sampleMessage.preset_msg;
      position =  this.data.sampleMessage.position;
    }
    this.form = new FormGroup({
      preset_msg: new FormControl(preset_msg, [Validators.required]),
      position: new FormControl({ value: position, disabled: this.data.mode === 'create' ? true : false }),
      status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false }),
    });
  }

}
