import { Clipboard } from '@angular/cdk/clipboard';
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, Inject } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
  selector: 'kt-swal-alert',
  templateUrl: './swal-alert.component.html',
  styleUrls: ['./swal-alert.component.scss']
})
export class SwalAlertComponent implements OnInit, OnChanges {
  @Input()
  title = 'System Message';

  @Input()
  icon: SweetAlertIcon = 'info';

  @Input()
  iconColor: any = '';

  @Input()
  message: any[];

  @Input()
  data: any;

  @Output()
  confirmed = new EventEmitter<boolean>();

  @Output()
  confirmed2 = new EventEmitter<boolean>();

  @Output()
  reassign = new EventEmitter<boolean>();

  @Output()
  cancelled = new EventEmitter<boolean>();

  constructor(
    private clipboard: Clipboard,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    this.message = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    // Avoid alert pop-up if the only change is in `data` (no changes in message, etc)
    if (Object.keys(changes).length == 1 && Object.keys(changes)[0] == 'data') return;

    if (Object.keys(changes)[0] != 'icon' || Object.keys(changes).length > 1) {
      this.alert(this.message);
    }
  }

  private alert(message: any[]) {
    let icon = null;
    let iconColor = null;

    if ((!!message)) {
      let title = null;
      let messages = '<ul>';
      let showCancelButton = false;
      let confirmButtonText = 'OK';
      let cancelButtonColor = '';
      let cancelButtonText = '';
      this.message.map(row => messages += `<li>${row}</li>`);
      messages += '</ul>';

      if (this.data) {
        // To be refined if there is any other APIs return `failed_records` in data as this is for `affiliate-lead-setting/create` API only
        if (this.data['exist_records'] && this.data['exist_records'].length > 0) {
          title = 'Add Complete';
          icon = 'info';

          if (this.data['exist_records'].length > 0) {
            const copyIcon = `<i class="fas fa-copy copy-icon copy-exist-records"></i><div class="swal2-copy-tooltip">Copied!</div>`;
            let data = `<div class="text-left mx-2 mt-3">Existing Records: ${copyIcon}</div><div class="exist-records-section p-2 m-2"><ul>`;
            this.data['exist_records'].map(row => data += `<li>${row}</li>`);
            data += '</ul></div>';

            messages += data;
          }
        } else if (this.data['failed_records'] && this.data['failed_records'].length > 0) {
          title = 'Add Complete';
          icon = 'info';
          iconColor = '#f27474';

          if (this.data['failed_records'].length > 0) {
            const copyIcon = `<i class="fas fa-copy copy-icon copy-failed-records"></i><div class="swal2-copy-tooltip">Copied!</div>`;
            let data = `<div class="text-left mx-2 mt-3">Failed Records: ${copyIcon}</div><div class="failed-records-section p-2 m-2"><ul>`;
            this.data['failed_records'].map(row => data += `<li>${row}</li>`);
            data += '</ul></div>';

            messages += data;
          }
        }
        else if ((this.data['linked_promotions'] && this.data['linked_promotions'].length > 0) ||
         (this.data['linked_campaigns'] && this.data['linked_campaigns'].length > 0) ||
         (this.data['linked_aff_campaigns'] && this.data['linked_aff_campaigns'].length > 0)) {
          title = 'Linkage Detection';

          if (this.data['linked_promotions'] != undefined && this.data['linked_promotions'].length > 0) {;
            let data = `<div class="text-left mx-2 mt-3">Promotion(s): </div><div class="linked-promotions-section p-2 m-2"><ul>`;
            this.data['linked_promotions'].map(row => data += `<li>${row}</li>`);
            data = data + '</div>';

            messages += data;
          }

          if (this.data['linked_campaigns'] != undefined && this.data['linked_campaigns'].length > 0) {
            let data = `<div class="text-left mx-2 mt-3">Campaign(s): </div><div class="linked-campaigns-section p-2 m-2"><ul>`;
            this.data['linked_campaigns'].map(row => data += `<li>${row}</li>`);
            data = data + '</div>';

            messages += data;
          }

          if (this.data['linked_aff_campaigns'] != undefined && this.data['linked_aff_campaigns'].length > 0) {
            let data = `<div class="text-left mx-2 mt-3">Affiliate Campaign(s): </div><div class="linked-aff-campaigns-section p-2 m-2"><ul>`;
            this.data['linked_aff_campaigns'].map(row => data += `<li>${row}</li>`);
            data = data + '</div>';

            messages += data;
          }

          Swal.fire({
            title: title,
            html: messages,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            heightAuto: false
          }).then(result => {
            if (result.isConfirmed) {
              this.confirmed2.emit(true);
            } else if (result.isDenied || result.dismiss) {
              this.cancelled.emit(true);
            }
          });
          // SKIP default dialog
          return;
        }
        else if ((this.data['leads_exist'] && this.data['leads_exist'].length > 0) || (this.data['member_exist'] && this.data['member_exist'].length > 0)) {
          title = 'Import Complete!';
          icon = 'info';
          iconColor = '#f27474';

          if (this.data['leads_exist'].length > 0) {
            const copyIcon1 = `<i class="fas fa-copy copy-icon copy-leads-exist-records"></i><div class="swal2-copy-tooltip copy-leads-exist-tooltip">Copied!</div>`;
            let data = `<div class="text-left mx-2 mt-3">Duplicate Leads: ${copyIcon1}</div><div class="failed-records-section p-2 m-2"><ul>`;
            this.data['leads_exist'].map(row => data += `<li>${row}</li>`);
            data += '</ul></div>';

            messages += data;
          }

          if (this.data['member_exist'].length > 0) {
            const copyIcon2 = `<i class="fas fa-copy copy-icon copy-member-exist-records"></i><div class="swal2-copy-tooltip copy-member-exist-tooltip">Copied!</div>`;
            let data = `<div class="text-left mx-2 mt-3">Existing Member Phone Number: ${copyIcon2}</div><div class="failed-records-section p-2 m-2"><ul>`;
            this.data['member_exist'].map(row => data += `<li>${row}</li>`);
            data += '</ul></div>';

            messages += data;
          }
        }
        else if (this.data['reassign_reward'] !== undefined && this.data.reassign_reward) {
          // Custom swal-alert for Reassign Reward popup
          title = 'The member is not eligible to the promotion';
          Swal.fire({
            title: title,
            html: messages,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel'
          }).then(result => {
            if (result.isConfirmed) {
              this.reassign.emit(true);
            } else if (result.isDenied || result.isDismissed) {
              this.cancelled.emit(true);
            }
          });

          // SKIP default dialog
          return;
        } 
        else if ((this.data['rebate_duplicateIDs'] && this.data['rebate_duplicateIDs'].length > 0) ) {
          title = 'Successfully Released Rebates';
          icon = 'info';
          iconColor = '#f27474';
          const copyIcon1 = `<i class="fas fa-copy copy-icon copy-rebate-exist-records"></i><div class="swal2-copy-tooltip copy-rebate-exist-tooltip">Copied!</div>`;
          let data = `<div class="text-left mx-2 mt-3">Duplicate Rebate IDs: ${copyIcon1}</div><div class="failed-records-section p-2 m-2"><ul>`;
          this.data['rebate_duplicateIDs'].map(row => data += `<li>${row}</li>`);
          data += '</ul></div><ul><li>Please review these duplicate rebate entries for further action</li></ul>';

          messages += data;

        }else {
          if (this.data['custom_affiliate_swal'] == true) {
            if (this.data['failed_records'] && this.data['failed_records'].length == 0) {
              title = 'Successfully added ' + this.message.toString().split(" ", 1) + ' records';
              icon = 'success';
              messages = ''
            }
            else if (this.data['failed_records'] == undefined) {
              title = this.message;
              icon = 'success';
              messages = ''
            }
          } else if (this.data['custom_promotion_member_swal'] == true || this.data['custom_verification_setting_bank_swal'] == true) {
            if (this.data['exist_records'] && this.data['exist_records'].length == 0) {
              title = 'Successfully added ' + this.message.toString().split(" ", 1) + ' records';
              icon = 'success';
              messages = ''
            }
            else if (this.data['exist_records'] == undefined) {
              title = this.message;
              icon = 'success';
              messages = ''
            }
          } else if (this.data['custom_bank_receipt_check_duplicate_swal']) {
            title = 'Duplicated Entries Detected';
            icon = 'info';
            messages = '';
            confirmButtonText = 'Yes';
            showCancelButton = true;
            cancelButtonColor = '#d33';
            cancelButtonText = 'No';

            if (this.data['duplicate_transactions'].length > 0) {
              const copyIcon1 = `<i class="fas fa-copy copy-icon copy-receipt-exist-records"></i><div class="swal2-copy-tooltip copy-receipt-exist-tooltip">Copied!</div>`;
              let data = `<div class="text-left mx-2 mt-3">Existing Records: ${copyIcon1}</div><div class="failed-records-section p-2 m-2"><ul>`;
              this.data['duplicate_transactions'].map(row => data += `<li>${row}</li>`);
              data += '</ul></div><br /><p>Are you sure you want to proceeed with the bank receipt creation?</p>';

              messages += data;
            }
          }

          if (this.data['custom_leads_import_swal'] == true) {
            if (this.data['leads_exist'] && this.data['leads_exist'].length == 0 && this.data['member_exist'] && this.data['member_exist'].length == 0) {
              title = 'Successfully imported ' + this.message.toString().split(" ", 1) + ' records';
              icon = 'success';
              messages = ''
            }
            else if (this.data['leads_exist'] == undefined && this.data['member_exist'] == undefined) {
              title = this.message;
              icon = 'success';
              messages = ''
            }
          }
        }
      }

      if (message && message.length > 0 && message[0] == 'Successfully connected to the call.') {
        title = 'Call Connected';
      }

      Swal.fire({
        title: title ?? this.title,
        html: messages,
        icon: icon ? icon : this.icon,
        iconColor: iconColor ? iconColor : this.iconColor,
        confirmButtonText,
        showCancelButton,
        reverseButtons: true,
        cancelButtonColor,
        cancelButtonText,
        didOpen: () => {
          // Bind function to the icon
          const copyIcon = document.querySelector('.copy-failed-records') ? document.querySelector('.copy-failed-records') : document.querySelector('.copy-exist-records');
          const copyIcon1 = document.querySelector('.copy-leads-exist-records');
          const copyIcon2 = document.querySelector('.copy-member-exist-records');
          const copyIcon3 = document.querySelector('.copy-receipt-exist-records');
          const copyIcon4 = document.querySelector('.copy-rebate-exist-records');

          if (copyIcon) {
            const tooltip = document.querySelector('.swal2-copy-tooltip') as HTMLElement;
            copyIcon.addEventListener('click', () => {
              document.querySelector('.copy-failed-records') ? this.copyFailedRecords() : this.copyExistRecords();

              tooltip.style.display = 'block';
              setTimeout(() => {
                tooltip.style.display = 'none';
              }, 2000);
            });
          }

          if (copyIcon1) {
            const tooltip1 = document.querySelector('.copy-leads-exist-tooltip') as HTMLElement;
            copyIcon1.addEventListener('click', () => {
              this.copyLeadsExistRecords();

              tooltip1.style.display = 'block';
              setTimeout(() => {
                tooltip1.style.display = 'none';
              }, 2000);
            });
          }
          if (copyIcon2) {
            const tooltip2 = document.querySelector('.copy-member-exist-tooltip') as HTMLElement;
            copyIcon2.addEventListener('click', () => {
              this.copyMembersExistRecords();

              tooltip2.style.display = 'block';
              setTimeout(() => {
                tooltip2.style.display = 'none';
              }, 2000);
            });
          }

          if (copyIcon3) {
            const tooltip3 = document.querySelector('.copy-receipt-exist-tooltip') as HTMLElement;
            copyIcon3.addEventListener('click', () => {
              this.copyBankReceiptExistRecords();

              tooltip3.style.display = 'block';
              setTimeout(() => {
                tooltip3.style.display = 'none';
              }, 2000);
            });
          }

          if (copyIcon4) {
            const tooltip4 = document.querySelector('.copy-rebate-exist-tooltip') as HTMLElement;
            copyIcon4.addEventListener('click', () => {
              this.copyRebateExistRecords();

              tooltip4.style.display = 'block';
              setTimeout(() => {
                tooltip4.style.display = 'none';
              }, 2000);
            });
          }
        }
      }).then(result => {
        if (result.isDenied || result.isDismissed) {
          this.cancelled.emit(true);
        } else if (result) {
          this.confirmed.emit(true);
        }
      });
    }
  }

  copyFailedRecords() {
    const clipboardText = this.data['failed_records'].join('\n');
    this.clipboard.copy(clipboardText);
  }

  copyExistRecords() {
    const clipboardText = this.data['exist_records'].join('\n');
    this.clipboard.copy(clipboardText);
  }

  copyLeadsExistRecords() {
    const clipboardText = this.data['leads_exist'].join('\n');
    this.clipboard.copy(clipboardText);
  }

  copyMembersExistRecords() {
    const clipboardText = this.data['member_exist'].join('\n');
    this.clipboard.copy(clipboardText);
  }

  copyBankReceiptExistRecords() {
    const clipboardText = this.data['duplicate_transactions'].join('\n');
    this.clipboard.copy(clipboardText);
  }

  copyRebateExistRecords() {
    const clipboardText = this.data['rebate_duplicateIDs'].join('\n');
    this.clipboard.copy(clipboardText);
  }

}
