import { Observable, Subject } from 'rxjs';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { SalesConversionReport } from '@core/models/sales-conversion-report.model';
import { SalesConversionReportDetails } from '@core/models/sales-conversion-report-details.model';

@Injectable()
export class SalesConversionReportDataService extends DefaultDataService<SalesConversionReport> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('SalesConversionReport', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<SalesConversionReport[]>{
    return this.http.get<ApiResponse>(`/report/salesconversionreport${pageParam}`).pipe(
      tap(res => { this.paginationInit(res) }),
      map(res => res.data.rows)
    )
  }

  getDetails(pageParam: string): Observable<SalesConversionReportDetails[]>{
    return this.http.get<ApiResponse>(`/report/salesconversionreportdetails${pageParam}`).pipe(
      tap(res => { this.paginationInit(res) }),
      map(res => res.data.rows)
    )
  }

  exportReport(pageParam: string) {
    return this.http.get<ApiResponse>(`/report/exportsalesconversion${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  exportReportDetails(pageParam: string) {
    return this.http.get<ApiResponse>(`/report/exportsalesconversiondetails${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      return (this.pagination = res.data.paginations);
    }
  }

}
