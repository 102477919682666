<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Game Provider Blacklist</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
          <ng-container formArrayName="game_providers">
            <div class="form-group row">
              <div class="col-5">Game Provider</div>
              <div class="col-6">Sub Category</div>
            </div>
             <div class="form-group row" *ngFor="let provider of providers.controls; index as i" [formGroupName]="i">
              <div class="col-5">
                <select class="form-control" formControlName="game_provider_id" disabled>
                  <option value="null" [disabled]="true">Please Select</option>
                  <option *ngFor="let item of data.providers" [value]="item.id"> {{ item.name }}</option>
                </select>
              </div>
              <div class="col-6">
                <ng-container *ngFor="let providerId of object.keys(subCategories)">
                  <!-- By provider -->
                  <ng-container *ngIf="+providers.controls[i].value.game_provider_id === +providerId">

                    <!-- User selection -->
                    <ng-container *ngIf="selectedSubCategories$ | async as _selections">
                      <ng-container>
                        <!-- Selected -->
                        <ng-container *ngIf="_selections !== null">
                          <ng-container *ngFor="let pid of object.keys(_selections)">
                            <ng-container *ngIf="+pid === +providerId; else selectionFallback">
                              <angular2-multiselect
                                [data]="subCategories[providerId]"
                                [(ngModel)]="_selections[pid]"
                                [settings]="gameProvidersDropdownSettings"
                                (onDeSelect)="onItemDeSelect($event,providerId,_selections)"
                                (onDeSelectAll)="onItemDeSelectAll($event)"
                                formControlName="blacklist_sub_categories">
                              </angular2-multiselect>
                            </ng-container>
                          </ng-container>
                        </ng-container>

                      </ng-container>
                    </ng-container>

                    <!-- Fallback -->
                    <angular2-multiselect
                      [data]="subCategories[providerId]"
                      [settings]="gameProvidersDropdownSettings"
                      formControlName="blacklist_sub_categories">
                    </angular2-multiselect>

                  </ng-container>
                </ng-container>
              </div>
              <!-- <div class="col-1 pl-0">
                <button type="button" class="btn btn-warning w-100" (click)="onRemoveItem(i)">Remove</button>
              </div> -->
            </div>
          </ng-container>

      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Cancel</button>
        <kt-submit-button *ngIf="canEditGameProviderBlacklist" [isDisabled]="false" [buttonLoading]="false" (confirmed)="onSubmit()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
