import { TransferEntityService } from '@views/pages/apps/general/transfers/services/transfer-entity.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, tap, take } from 'rxjs/operators';

@Injectable()
export class TransferResolver implements Resolve<boolean> {

    constructor(private transferEntityService: TransferEntityService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.transferEntityService.loaded$.pipe(
          tap(loaded =>  {
            if(!loaded) {
              this.transferEntityService.getAll().pipe(
                take(1)
              );
            }
          }),
          filter(loaded => !!loaded),
          first()
        );
    }

}
