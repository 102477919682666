import { Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Deposit } from '@core/models/deposit.model';
import { DepositDataService } from '../../services/deposit-data.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { RequestReceiptDialogComponent } from '@shared/request-receipt-dialog/request-receipt-dialog.component';
import { UploadReceiptComponent } from '../upload-receipt/upload-receipt.component';

@Component({
  selector: 'deposit-log-wrapper',
  templateUrl: './deposit-log-wrapper.component.html',
  styleUrls: ['./deposit-log-wrapper.component.scss'],
})
export class DepositLogWrapperComponent implements OnInit {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  pagination: Pagination;
  refreshStatus: boolean;
  buttonLoading = false;
  messages$ = this.depositDataService.messages$;
  loading = false;
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
  };
  maxSize = 5;
  pageSize = 30;
  page = 1;
  depositReceipts = [];

  // permissions
  canRequestReceipts: boolean;
  canUploadReceipts: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { deposit: Deposit },
    public dialogRef: MatDialogRef<DepositLogWrapperComponent>,
    private depositDataService: DepositDataService,
    public dialog: MatDialog,
    public loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canRequestReceipts = this.data.deposit.is_crypto === 1 ?
        false :
        appPermissions.request_receipts;
      this.canUploadReceipts = this.data.deposit.is_crypto === 1 ?
        false :
        appPermissions.upload_receipts;

      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  requestReceipt() {
    if (this.data.deposit.status === 7) {
      Swal.fire({
        icon: "error",
        title: 'System Message',
        text: 'Receipt already requested'
      });
      return;
    }

    const requetReceiptDialogRef = this.dialog.open(RequestReceiptDialogComponent, {
      width: "800px",
      data: {
        deposit: this.data.deposit,
      },
    });

    requetReceiptDialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.dialogRef.close(true);
      }
    });
  }

  openUploadReceipt() {
    const dialogRef = this.dialog.open(UploadReceiptComponent, {
      width: '800px',
      data: {
        remarks: this.data.deposit.remarks,
        deposit: this.data.deposit,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getReceipts();
      if (result === true) {
        this.refreshStatus = true;
      }
    });
  }

  getReceipts() {
    this.depositDataService.getReceipt(this.data.deposit.id).pipe(
      map(res => {
        this.depositReceipts = res;
      })
    ).subscribe();
  }
}