<div *ngIf="canViewSMSProviders || canViewCredentials || canViewSMSProviderPermissions" id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="kt-form kt-form--label-right  kt-margin-b-10">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1">
            <mat-tab-group animationDuration="0ms" (selectedTabChange)="onSelectTab()">
              <ng-container>
                <mat-tab label="SMS Provider" *ngIf="canViewSMSProviders">
                  <app-provider-settings [currencies]="dropdown.currencies"></app-provider-settings>
                </mat-tab>
                <mat-tab label="Credentials" *ngIf="canViewCredentials">
                  <app-provider-credentials [regions]="dropdown.region"></app-provider-credentials>
                </mat-tab>
                <mat-tab label="Permissions" *ngIf="isAdmin || canViewSMSProviderPermissions">
                  <app-provider-permissions [regions]="dropdown.region"></app-provider-permissions>
                </mat-tab>
              </ng-container>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async" [icon]="icon" (confirmed)="onCloseDialog()"></kt-swal-alert>