import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { Rewards } from '@core/models/rewards.model';

@Injectable()
export class TelemarketerRewardsDataService extends DefaultDataService<Rewards> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('Reward', http, httpUrlGenerator, { root: '' });
  }

  getById(id: number): Observable<Rewards>{
    return this.http.get<ApiResponse>(`/reward/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  updateReward(data: Rewards): Observable<Rewards>{
    return this.http.put<ApiResponse>(`/reward/${data.id}`, data).pipe(
      tap( res => this.messages$.next(res.message)),
      map((row: ApiResponse) => row.data.rows)
    );
  }

  import(data: FormData) {
    return this.http.post<ApiResponse>(`/reward/import`, data).pipe(
      tap(res => this.messages$.next(res.message)),
      map((res) => res.data)
    );
  }

}
