<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{data.mode == 'edit' ? 'Edit' : 'Create'}} Campaign</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
          <div class="row">
              <div class="col-md-6 form-group required">
                  <label class="control-label">Name <span class="text-danger">*</span></label>
                  <input formControlName="name" type="text" class="col-12 form-control">
              </div>
              <div class="col-md-6 form-group">
                  <label class="control-label">Currency <span class="text-danger" *ngIf="data.mode === 'create'">*</span></label>
                  <select class="form-control" formControlName="currency_id">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <ng-container *ngFor="let value of dropdown.currencies">
                        <option [value]="value.id">{{ value.name }}</option>
                    </ng-container>
                </select>
              </div>
              <div class="col-md-6 form-group">
                  <label class="control-label">Status <span class="text-danger">*</span></label>
                  <select class="form-control" formControlName="status">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <ng-container *ngFor="let value of dropdown.statuses; let i = index;">
                      <option [value]="i">
                        {{ value }}
                      </option>
                    </ng-container>
                </select>
              </div>

              <div class="col-md-6 form-group">
                <label class="control-label">Promotion</label>
                <kt-dropdown-wo-lazyload
                    [form] = 'form'
                    [dropdownList] = 'promotionCodeDropdownList'
                    [dropdownSettings] = 'promotionCodeDropdownSettings'
                    [formName] = "'promotion_id'"
                    [selectionAttributes] = "'id'"
                    [selectedItems] = 'promotionCodeSelectedItems'
                    (selectedItemsChanged)="onSelectedPromoChange($event)">
                </kt-dropdown-wo-lazyload>
              </div>
              <div class="col-md-6 form-group">
                <label class="control-label">Member Group
                  <i class="fas fa-info-circle"
                  matTooltipClass="custom-tooltip" matTooltipHideDelay="0"
                  matTooltip="The member group to be assigned to the member after registration."
                  matTooltipPosition="above">
                </i>
              </label>
                <select class="form-control" formControlName="member_group_id">
                  <ng-container *ngFor="let value of dropdown.groups | async">
                      <option [value]="value.id">{{ value.name }}</option>
                  </ng-container>
              </select>
            </div>
            <div class="col-md-6 form-group">
              <label class="control-label">Inbox Message
                <i class="fas fa-info-circle"
                  matTooltipClass="custom-tooltip" matTooltipHideDelay="0"
                  matTooltip="The inbox message template to be sent to member after registration. "
                  matTooltipPosition="above">
                </i>
              </label>
              <kt-dropdown-wo-lazyload
                  [form] = 'form'
                  [dropdownList] = 'messageTemplateList'
                  [dropdownSettings] = 'messageTemplatesSettings'
                  [formName] = "'message_template_id'"
                  [selectionAttributes] = "'id'"
                  [selectedItems] = 'messageTemplateSelectedItem'>
              </kt-dropdown-wo-lazyload>
            </div>
              <div class="col-md-6 form-group" *ngIf="showToggle">
                <label class="control-label">Auto Assign Promotion
                  <i class="fas fa-info-circle"
                    matTooltipClass="custom-tooltip campaign-custom-tooltip" matTooltipHideDelay="0"
                    [matTooltip]="autoAssignPromotionTooltipText" matTooltipPosition="above">
                  </i>
                </label>
                <label class="switch col-12">
                  <input type="checkbox" formControlName="auto_assign_promo" class="custom-control-input" id="auto_assign_promo">
                  <div class="slider round">
                   <!--ADDED HTML -->
                   <label class="verified-label" for="auto_assign_promo"></label>
                   <!--END-->
                  </div>
                 </label>
              </div>
              <div class="col-md-6 form-group" *ngIf="showToggle && showRewardStatus">
                <label class="control-label">Reward Status
                  <i class="fas fa-info-circle"
                    matTooltipClass="custom-tooltip campaign-custom-tooltip" matTooltipHideDelay="0"
                    matTooltip="The status of the promotion assigned in the Reward Page. (Only applicable for free credit type)"
                    matTooltipPosition="above">
                  </i>
                </label>
                <select class="form-control" formControlName="reward_status">
                  <ng-container *ngFor="let value of dropdown.rewardStatus; let i = index;">
                    <option [value]="value.id" [disabled]="value.id == 0">{{ value.name }}</option>
                  </ng-container>
                </select>
              </div>
              <div class="col-md-6 form-group">
                <label class="control-label">{{ 'Dialog Popup' }}
                  <i class="fas fa-info-circle"
                    matTooltipClass="custom-tooltip tooltip-multi-line" matTooltipHideDelay="0"
                    [matTooltip]="popupTooltipText" matTooltipPosition="above">
                  </i>
                </label>
                <kt-dropdown-wo-lazyload
                    [form] = 'form'
                    [dropdownList] = 'filteredPopupDropdownList'
                    [dropdownSettings] = 'popupDropdownSettings'
                    [formName] = "'dialog_popup'"
                    [selectionAttributes] = "'id'"
                    [selectedItems] = 'popupSelectedItems'
                    (selectedItemsChanged)="onSelectedPopupItems($event)">
                </kt-dropdown-wo-lazyload>
                <div *ngIf="checkDialogValidityDate()" class="campaign-dialog-container mt-2">
                  <span class="d-flex">
                    <i class="fa fa-exclamation-circle promotion-code-detail-tooltip" placement="top"></i>
                    <p>{{ 'The selected dialog popup is not within its validity period or has been expired.' }}</p>
                  </span>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label class="control-label">Remarks</label>
                <input formControlName="remarks" type="text" class="col-12 form-control">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.campaign, data.mode)"></kt-submit-button>
        </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
