import { Component, Inject, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs/operators';
import { MemberBankAccountHttpService } from '@core/services/member-bank-account-http.service';
import { CCID } from '@core/models/ccid.model';

@Component({
  templateUrl: './ccid-lookup.component.html',
  styleUrls: ['./ccid-lookup.component.scss']
})
export class CCIDLookupDialogComponent implements AfterViewInit {

  ccidHtml: CCID;
  loading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { accountNumber: string },
    public dialogRef: MatDialogRef<CCIDLookupDialogComponent>,
    private memberBankAccountHttpService: MemberBankAccountHttpService
  ) { }

  ngAfterViewInit() {
    this.lookupCCID();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  lookupCCID() {
    const data = {
      bank_account: this.data.accountNumber
    }
    this.memberBankAccountHttpService.lookupCCID(data).pipe(
      tap(res => {
      this.ccidHtml = res;
      this.loading = false;
      }),
      catchError((error) => {
        this.onCloseDialog();
        throw error;
      })
    ).subscribe();
  }

}
