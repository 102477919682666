import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Transaction } from '@core/models/transaction.model';
import { Pagination } from '@core/models/pagination.model';

@Injectable()
export class TransactionDataService extends DefaultDataService<Transaction> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('Transaction', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<Transaction[]> {
    return this.http.get<ApiResponse>('/transaction').pipe(
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<Transaction[]>{
    return this.http.get<ApiResponse>(`/transaction${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  export(pageParam: string) {
    return this.http.get<ApiResponse>(`/transaction/export${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getById(id: number): Observable<Transaction>{
    return this.http.get<ApiResponse>(`/transaction/${id}`).pipe(
      map(res => res.data.user)
    );
  }

  getTransactionType(){
    return this.http.get<ApiResponse>(`/transactionstype`).pipe(
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }

}
