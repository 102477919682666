import { ApiResponse } from './../models/api-response.model';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Notification } from '@core/models/notification.model';
import Echo from 'laravel-echo';
import { environment } from '@env/environment';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { PushNotificationComponent } from "@shared/push-notification/push-notification.component";

@Injectable()
export class NotificationHttpService {

  echo: Echo;
  notification$ = new BehaviorSubject<Notification>({
    affiliateDeposit: 0,
    affiliateWithdraw: 0,
    affiliateAccount: 0,
    deposit: 0,
    withdraw: 0,
    memberPromotion: 0,
    memberVerification: 0,
    notification_messages: "",
    pending_tac: 0,
    rebate_release_pending: 0,
    rebate_release_pending_v2: 0,
    reward: 0,
    downloads: 0,
    notification_count: 0,
    transfer: 0
  });
  
  notificationData: Notification;
  messageSubject = new Subject<any>();
  readPushNoti = new Subject<any>();
  boUserId = JSON.parse(localStorage.getItem('user_data')).id;
  isAdmin: boolean = JSON.parse(localStorage.getItem('user_data')).is_admin;
  isTelemarketer: boolean = JSON.parse(localStorage.getItem('user_data')).role_id === 1 ? true : false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(
    private http: HttpClient,
    private eventEmitterService: EventEmitterService,
    private snackBar: MatSnackBar,
    ) {
    const SocketIoClient = require('socket.io-client');
    const socketHost: string = environment.socketHost;
    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        client: SocketIoClient,
        host: socketHost
      });
    } catch (e) {
      console.log(e);
    }
   }

  getNotificationCounter(params:string): Observable<Notification> {
    return this.http.get<ApiResponse>(`/transaction/incomplete${params}`).pipe(
      map(res => {
        this.notificationData = res.data
        return res.data
      })
    );
  }

  asRead(ids: number[]): Observable<any>{
    return this.http.post<ApiResponse>('/notification/read', {
      id: ids,
      read: 1
    });
  }

  getNotificationMessages(page: number, params:string):Observable<any>{
    return this.http.get<ApiResponse>(`/transaction/incomplete?paginate=true&page=${page}&${params}`).pipe(
      map(res => res.data.notification_messages)
    );
  }

  notificationCountWebsocket(){
    const listener = (data) => {
      Object.entries(data).forEach(([key, value]) => {
        var sumOfCount = 0;
        if(key === 'deposit' || key === 'withdraw' || key === 'affiliateDeposit' || key === 'affiliateWithdraw' || key === 'transfer') {
          var currency_ids = JSON.parse(localStorage.getItem('user_data'))['bo_currency_ids'];

          Object.values(currency_ids).forEach(val => {
            Object.values(value).forEach(element => {
              if (val === element['settings_currency_id']) {
                sumOfCount += element['count'];
              }
            });
          });
          this.notificationData[key] = sumOfCount;
        } else if (key !== 'notification_messages'){
          this.notificationData[key] = value as number;
        }

        if ((key === 'reward' || key === 'pending_tac') && (this.isAdmin || !this.isTelemarketer)) {
          if (this.notificationData[key] === 0 && value > 0) {
            this.notificationData.notification_count = this.notificationData.notification_count + 1;
          }
        }

        if(key === 'notification_count') {
          if (!this.isAdmin && this.isTelemarketer) {
            this.notificationData.notification_count = 0
          }
        }
      })
      this.notification$.next(this.notificationData);
      sessionStorage.setItem('newNotifications', JSON.stringify(this.notificationData));
      //event to trigger the change detector ref to detect changes
      this.eventEmitterService.onListenNotificationWebsocket();
    }

    const notificationCountChannel = this.echo.channel('notification-count-channel');
    //for general event such as deposit, withdraw, rebate, reward and etc
    notificationCountChannel.listen(".NotificationCountEvent", listener);
    //for bo user own channel event where listening to new notification message generate by themselves
    notificationCountChannel.listen(".NotificationCountEvent-"+this.boUserId, listener);
    //for every bo user to join as all user can see system generated messages
    notificationCountChannel.listen('.NotificationCountEvent-0', ()=> {
      this.notificationData.notification_count = this.notificationData.notification_count + 1;
      this.notification$.next(this.notificationData);
      this.eventEmitterService.onListenNotificationWebsocket();
    })
  }

  notificationMessagesWebsocket() {
    const listener = (data) => {
      this.messageSubject.next(data.notification_messages);

      // open push notification on receive any message
      let snackBarRef = this.snackBar.openFromComponent(PushNotificationComponent, {
        data: data.notification_messages,
        duration: 8000, // dismiss in milliseconds
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['balloon-notification'], // scss class
      });

      // when snackBar dismiss
      snackBarRef.afterDismissed().subscribe((event) => {
        // only dismiss by action is consider as read
        if (event.dismissedByAction) {
          // mark message as read
          this.asRead([data.notification_messages.id]).subscribe();
          this.readPushNoti.next(data.notification_messages.id);
        }
      });
    }

    this.eventEmitterService.onListenNotificationWebsocket();
    const notificationMessagesChannel = this.echo.channel('notification-messages-channel');
    notificationMessagesChannel.listen(".NotificationMessagesEvent-"+this.boUserId, listener);
    notificationMessagesChannel.listen(".NotificationMessagesEvent-0", listener);
  }
}
