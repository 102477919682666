// Angular or Core
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
// Model
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { LiveChat } from '@core/models/live-chat.model';
// Third Party
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class LiveChatDataService extends DefaultDataService<LiveChat> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super('LiveChat', http, httpUrlGenerator, { root: '' });
  }

  getLiveChatToken(param?: string): Observable<LiveChat> {
    return this.http.get<ApiResponse>(`/livechat/gettoken`).pipe(
      map(res => res.data),
    );
  }

  getLiveChatPlatform(param?: string): Observable<LiveChat> {
    return this.http.get<ApiResponse>(`/livechat/platform`).pipe(
      map(res => res.data.rows),
    );
  }
}
