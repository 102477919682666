import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VerificationSettingsDataService } from '../../services/verification-settings-data.service';
import { catchError, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { MessageTemplateDataService } from '../../../../superuser/message-template/services/message-template-data.service';
import { MessageTemplateEditComponent } from '../../../../superuser/message-template/dialogs/message-template-edit.component';
import { VerificationSettingsCurrencyDialogComponent } from '../verification-settings-currency/verification-settings-currency.component';
@Component({
    templateUrl: './verification-settings-edit.component.html',
    styleUrls: ['./verification-settings-edit.component.scss']
})
export class VerificationSettingsEditDialogComponent implements OnInit {

    form: FormGroup;

    messages$ = this.verificationSettingsDataService.messages$;
    loading = false;
    buttonLoading = false;
    messageTemplateBtnLoading = false;
    currencyBtnLoading = false;
    isCallingApi = false;
    verificationSettingCurrency: any;

    dropdown = {
        currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
        status: this.dropdownHttpService.statuses,
        type: this.dropdownHttpService.verificationSettingType,
        bankType: [],
        messageTemplateDropdownList: [],
    };

    sendMessageTemplateDropdownSettings = {
        singleSelection: true,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'code',
        noDataLabel: '',
        showCheckbox: false,
        autoPosition: false,
        position: 'bottom',
    };
    selectedApproveMessageTemplate = [];
    selectedRejectMessageTemplate = [];

    private localesSub = new Subscription();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { verificationSettings: any, mode: string },
        public dialogRef: MatDialogRef<VerificationSettingsEditDialogComponent>,
        private verificationSettingsDataService: VerificationSettingsDataService,
        private dropdownHttpService: DropdownHttpService,
        private messageTemplateDataService: MessageTemplateDataService,
        public dialog: MatDialog,
    ) { }

    ngOnInit() {
        localStorage.removeItem("createVerificationSettingsBank");
        localStorage.removeItem("createVerificationSettingsCurrency");

        this.verificationSettingCurrency = this.data.verificationSettings ? this.data.verificationSettings.currency : [];

        this.dropdownHttpService.merchantBankTypes.subscribe(res => {
            this.dropdown.bankType = res.filter(item => item.id === 1 || item.id === 3);
        });
        this.initMessageTemplateDropdown();
        this.formInit();
    }

    ngOnDestroy() {
        this.localesSub.unsubscribe();
      }

    onCloseDialog(event?: Event, refresh = false) {
        this.dialogRef.close(refresh);
    }

    onOpenDialog(dialog: string, mode: string) {
        if (dialog === 'create message template') {
            this.messageTemplateBtnLoading = true;
            this.setLocales().subscribe(() => {
                this.messageTemplateBtnLoading = false;
                this.openDialogBy(MessageTemplateEditComponent, { mode: mode, dialog: 'MessageTemplateEditComponent' });
            });
        } else if (dialog === 'currency') {
            this.currencyBtnLoading = true;
            let verificationSettingCurrencies = [];
            let currencyDialogMode = '';

            if (mode == 'create' || mode =='duplicate') {
                currencyDialogMode = 'create';
                const storedData = JSON.parse(localStorage.getItem('createVerificationSettingsCurrency') || '{}');
                verificationSettingCurrencies = Object.keys(storedData).map(key => {
                    return { id: key, ...storedData[key] };
                });
            } else if (mode == 'edit') {
                currencyDialogMode = 'edit';
                verificationSettingCurrencies = this.verificationSettingCurrency;
            }
            
            this.setCurrencyLocales().subscribe(() => {
                this.currencyBtnLoading = false;
                this.openDialogBy(VerificationSettingsCurrencyDialogComponent, { mode: currencyDialogMode, dialog: 'VerificationSettingsCurrencyDialogComponent', verificationSettingsID: this.data.mode === 'edit' ? this.data.verificationSettings.id : null, bank_type: this.form.getRawValue().bank_type, verificationSettingsCurrency: verificationSettingCurrencies });
            });
        }
    }

    private openDialogBy(componentRef: any, data?: { mode: string, dialog: string, verificationSettingsID?: number, bank_type?: number, verificationSettingsCurrency?: any }) {
        const dialogRef = this.dialog.open(componentRef, {
            width: '800px',
            data: {
                mode: data.mode,
                bank_type: data.bank_type,
                verificationSettingsID: data.verificationSettingsID,
                verificationSettingsCurrency: data.verificationSettingsCurrency
            },
            autoFocus: false
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (data.dialog == 'MessageTemplateEditComponent') {
                    this.initMessageTemplateDropdown();
                } else if (data.dialog == 'VerificationSettingsCurrencyDialogComponent' && data.mode == 'edit') {
                    this.getVerificationSettingCurrency();
                }
            }
        });
    }

    onSave() {
        this.buttonLoading = true;
        // To set "Save" button to disable (To prevent call API in multiple times when double click)
        this.form.setErrors({ 'invalid': true });

        let data = {
            ...this.form.value,
            type: this.form.getRawValue().type,
            bank_type: this.form.getRawValue().bank_type
        };

        Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
        
        switch (this.data.mode) {
            case 'edit':
                this.verificationSettingsDataService.update(this.data.verificationSettings.id, data).pipe(
                    tap((res: any) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                    }),
                    catchError((error) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                        throw error;
                    })
                ).subscribe();
                break;
            case 'create':
            case 'duplicate':
                data = {
                    ...data,
                    currency: JSON.parse(localStorage.getItem('createVerificationSettingsCurrency'))
                };

                this.verificationSettingsDataService.add(data).pipe(
                    tap((res: any) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                    }),
                    catchError((error) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                        throw error;
                    })
                ).subscribe();
                break;
        }
    }

    onUpdateValidator() {    
        if (this.form.get('type').value == 1) {
            this.form.get('bank_type').setValidators([Validators.required]);
        } else {
            this.form.get('bank_type').clearValidators();
        }
    
        this.form.get('bank_type').updateValueAndValidity();
    }

    getVerificationSettingCurrency() {
        this.verificationSettingsDataService.getById(this.data.verificationSettings.id).subscribe(
            res => {
                this.verificationSettingCurrency = res.currency;
                this.isCallingApi = false;
            }
        );
    }

    isActiveCurrencyAvailable() {
        if (this.isCallingApi) return true;
        
        if (this.data.mode === 'edit') {
            const currencyData = this.verificationSettingCurrency;
            if (currencyData && currencyData.length > 0) {
                return currencyData.some(x => x.status == 1);
            }
            else {
                // Show red dot
                return false;
            }
        } else {
            const currencyData = JSON.parse(localStorage.getItem('createVerificationSettingsCurrency'));
            if (currencyData && Object.keys(currencyData).length > 0) {
                // Check if any currency has Active status '1'
                return Object.values(currencyData).some((item: any) => item.status == "1");
            } else {
                // Show red dot
                return false;
            }
        }
    }

    isCurrencyAvailable() {
        if (this.isCallingApi) return true;

        const currencyData = this.data.mode === 'edit' ? this.verificationSettingCurrency : JSON.parse(localStorage.getItem('createVerificationSettingsCurrency'));

        return currencyData && (Array.isArray(currencyData) ? currencyData.length > 0 : Object.keys(currencyData).length > 0);
    }

    private initMessageTemplateDropdown() {
        this.loading = true;
        this.messageTemplateDataService.getWithQuery('?type[]=1&section=12&page=1&perPage=30').subscribe(res => {
            this.loading = false;
    
            // Filter only active message templates (status = 1)
            const activeTemplates = res.filter(v => v.status === 1);
            this.dropdown.messageTemplateDropdownList = activeTemplates;
    
            if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
                // Find selected approve template (including inactive)
                const selectedApprove = res.find(v => v.id === this.data.verificationSettings.approve_message_template_id);
                this.selectedApproveMessageTemplate = selectedApprove ? [selectedApprove] : [];
    
                // Handle error for inactive approve template
                if (selectedApprove && selectedApprove.status === 0) {
                    this.form.controls['approve_message_template_id'].setErrors({ inactive: true });
                } else {
                    this.form.controls['approve_message_template_id'].setErrors(null);
                }
    
                // Find selected reject template (including inactive)
                const selectedReject = res.find(v => v.id === this.data.verificationSettings.reject_message_template_id);
                this.selectedRejectMessageTemplate = selectedReject ? [selectedReject] : [];
    
                // Handle error for inactive reject template
                if (selectedReject && selectedReject.status === 0) {
                    this.form.controls['reject_message_template_id'].setErrors({ inactive: true });
                } else {
                    this.form.controls['reject_message_template_id'].setErrors(null);
                }
    
                // Mark fields as touched
                this.form.controls['approve_message_template_id'].markAsTouched();
                this.form.controls['reject_message_template_id'].markAsTouched();
            }
        });
    }

    onTemplateSelect(selectedItem: any, type: string) {
        if (type === 'approve') {
            const control = this.form.controls['approve_message_template_id'];
            if (selectedItem[0].status == 0) {
                control.setErrors({ inactive: true });
            } else {
                control.setErrors(null);
            }
            control.markAsTouched(); // Mark the control as touched to show validation feedback
        } else if (type === 'reject') {
            const control = this.form.controls['reject_message_template_id'];
            if (selectedItem[0].status == 0) {
                control.setErrors({ inactive: true });
            } else {
                control.setErrors(null);
            }
            control.markAsTouched(); // Mark the control as touched to show validation feedback
        }
    }
    
    private setLocales(): Observable<any> {
        return this.dropdownHttpService.locales.pipe(
            tap(res => {
                this.messageTemplateDataService.locales$.next(res);
            })
        );
    }

    private setCurrencyLocales(): Observable<any> {
        return this.dropdownHttpService.locales.pipe(
            tap(res => {
                this.verificationSettingsDataService.locales$.next(res);
            })
        );
    }

    private formInit() {
        let type = this.data.mode === 'edit' || this.data.mode === 'duplicate' ? this.data.verificationSettings.type : null;
        let bank_type = this.data.mode === 'edit' ? this.data.verificationSettings.bank_type : null;
        let approve_message_template_id = this.data.mode === 'edit' || this.data.mode === 'duplicate' ? this.data.verificationSettings.approve_message_template_id : null;
        let reject_message_template_id = this.data.mode === 'edit' || this.data.mode === 'duplicate' ? this.data.verificationSettings.reject_message_template_id : null;
    
        this.form = new FormGroup({
            type: new FormControl({ value: type, disabled: this.data.mode === 'edit' ? true : false },[Validators.required]),
            bank_type: new FormControl({ value: bank_type, disabled: this.data.mode === 'edit' ? true : false },[Validators.required]),
            approve_message_template_id: new FormControl(approve_message_template_id, [Validators.required]),
            reject_message_template_id: new FormControl(reject_message_template_id, [Validators.required]),
        });
    }
}
