import { Component, Inject } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { tap, catchError } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { BankSettingsDataService } from '@views/pages/apps/settings/bank-settings/services/bank-settings-data.service';
import moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'bank-maintenance-hour',
  templateUrl: './bank-maintenance-hour.component.html',
  styleUrls: ['./bank-maintenance-hour.component.scss']
})

export class BankMaintenanceHourComponent {
  dayList = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  form: FormGroup;
  
  messages$ = this.bankSettingsDataService.messages$;
  refreshStatus: boolean;
  buttonLoading = false;
  
  timezone: any;
  display: any;
  timezoneDropdownList: any;
  timezoneDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'timezone',
    labelKey: 'offset',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false
  };
  selectedTimezone = [];

  alldaySelectedTime: any[][] = [];
  mondaySelectedTime: any[][] = [];
  tuesdaySelectedTime: any[][] = [];
  wednesdaySelectedTime: any[][] = [];
  thursdaySelectedTime: any[][] = [];
  fridaySelectedTime: any[][] = [];
  saturdaySelectedTime: any[][] = [];
  sundaySelectedTime: any[][] = [];
  alldaySelectedEndTime: any[][] = [];
  mondaySelectedEndTime: any[][] = [];
  tuesdaySelectedEndTime: any[][] = [];
  wednesdaySelectedEndTime: any[][] = [];
  thursdaySelectedEndTime: any[][] = [];
  fridaySelectedEndTime: any[][] = [];
  saturdaySelectedEndTime: any[][] = [];
  sundaySelectedEndTime: any[][] = [];

  private subscription = new Subscription();
  haveError: any[] = [];
  formattedAlldaySelectedTime: any[][] = [];
  formattedMondaySelectedTime: any[][] = [];
  formattedTuesdaySelectedTime: any[][] = [];
  formattedWednesdaySelectedTime: any[][] = [];
  formattedThursdaySelectedTime: any[][] = [];
  formattedFridaySelectedTime: any[][] = [];
  formattedSaturdaySelectedTime: any[][] = [];
  formattedSundaySelectedTime: any[][] = [];
  maxStartTime = moment().set({ hour: 23, minute: 59, seconds: 59 });
  defaultSelectedTime = [{ "to": "23:59", "from": "00:00" }];

  availability = [
    { id: 1, name: 'Deposit page' },
    { id: 2, name: 'Withdrawal page' },
    { id: 3, name: 'Deposit & Withdrawal page' }
  ];

  get details() {
    return this.form.controls.details as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, bankSetting?: any },
    public dialogRef: MatDialogRef<BankMaintenanceHourComponent>,
    private bankSettingsDataService: BankSettingsDataService
  ) {
    this.timezoneDropdownList = JSON.parse(localStorage.getItem("timezones"));
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  async onSave() {
    this.buttonLoading = true;

    await this.details.controls.forEach(async (element, i) => {
      let promises = [];

      this.formattedAlldaySelectedTime.push([]);
      this.formattedMondaySelectedTime.push([]);
      this.formattedTuesdaySelectedTime.push([]);
      this.formattedWednesdaySelectedTime.push([]);
      this.formattedThursdaySelectedTime.push([]);
      this.formattedFridaySelectedTime.push([]);
      this.formattedSaturdaySelectedTime.push([]);
      this.formattedSundaySelectedTime.push([]);

      if (element.value.all_dayCheckbox) promises.push(this.validateSelectedTime(this.alldaySelectedTime[i], this.alldaySelectedEndTime[i], "All day", this.formattedAlldaySelectedTime, i));
      if (element.value.mondayCheckbox) promises.push(this.validateSelectedTime(this.mondaySelectedTime[i], this.mondaySelectedEndTime[i], "Monday", this.formattedMondaySelectedTime, i));
      if (element.value.tuesdayCheckbox) promises.push(this.validateSelectedTime(this.tuesdaySelectedTime[i], this.tuesdaySelectedEndTime[i], "Tuesday", this.formattedTuesdaySelectedTime, i));
      if (element.value.wednesdayCheckbox) promises.push(this.validateSelectedTime(this.wednesdaySelectedTime[i], this.wednesdaySelectedEndTime[i], "Wednesday", this.formattedWednesdaySelectedTime, i));
      if (element.value.thursdayCheckbox) promises.push(this.validateSelectedTime(this.thursdaySelectedTime[i], this.thursdaySelectedEndTime[i], "Thursday", this.formattedThursdaySelectedTime, i));
      if (element.value.fridayCheckbox) promises.push(this.validateSelectedTime(this.fridaySelectedTime[i], this.fridaySelectedEndTime[i], "Friday", this.formattedFridaySelectedTime, i));
      if (element.value.saturdayCheckbox) promises.push(this.validateSelectedTime(this.saturdaySelectedTime[i], this.saturdaySelectedEndTime[i], "Saturday", this.formattedSaturdaySelectedTime, i));
      if (element.value.sundayCheckbox) promises.push(this.validateSelectedTime(this.sundaySelectedTime[i], this.sundaySelectedEndTime[i], "Sunday", this.formattedSundaySelectedTime, i));

      // run function parallelly
      await Promise.all(promises);

    });

    if (this.haveError.length) {
      let text = "";
      this.haveError.forEach(element => {
        if (element.error == "cross time") {
          text += `Time fields for ${element.day}, the field ${element.currentIndex} is larger than field ${element.lastIndex}. \n`
        } else if (element.error == "cross day") {
          text += `Start time for field ${element.currentIndex} of ${element.day} is exceed 00.00. \n`;
        }
      });

      Swal.fire({
        title: 'Error',
        text: text,
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Cancel',
      }).then(() => {
        this.buttonLoading = false;
        this.resetFormError();
        return;
      });
    } 
    else {
      let details = this.form.value.details.map((x, i) => {
        return {
          setting_currency_id: x.settings_currency_id,
          display: x.display != 'null' ? x.display : null,
          timezone: x.timezone,
          enable_all_day: x.all_dayCheckbox,
          enable_monday: x.mondayCheckbox,
          enable_tuesday: x.tuesdayCheckbox,
          enable_wednesday: x.wednesdayCheckbox,
          enable_thursday: x.thursdayCheckbox,
          enable_friday: x.fridayCheckbox,
          enable_saturday: x.saturdayCheckbox,
          enable_sunday: x.sundayCheckbox,
          all_day: x.all_dayCheckbox ? this.formattedAlldaySelectedTime[i] : this.defaultSelectedTime,
          monday: x.mondayCheckbox ? this.formattedMondaySelectedTime[i] : this.defaultSelectedTime,
          tuesday: x.tuesdayCheckbox ? this.formattedTuesdaySelectedTime[i] : this.defaultSelectedTime,
          wednesday: x.wednesdayCheckbox ? this.formattedWednesdaySelectedTime[i] : this.defaultSelectedTime,
          thursday: x.thursdayCheckbox ? this.formattedThursdaySelectedTime[i] : this.defaultSelectedTime,
          friday: x.fridayCheckbox ? this.formattedFridaySelectedTime[i] : this.defaultSelectedTime,
          saturday: x.saturdayCheckbox ? this.formattedSaturdaySelectedTime[i] : this.defaultSelectedTime,
          sunday: x.sundayCheckbox ? this.formattedSundaySelectedTime[i] : this.defaultSelectedTime
        }
      });

      const data = {
        id: this.data.bankSetting[0].bank_id ? this.data.bankSetting[0].bank_id : null,
        availability_control: this.form.value.availability_control != 'null' ? this.form.value.availability_control : null,
        details: details
      };

      this.subscription = forkJoin([
        this.bankSettingsDataService.updateBank_maintenancehours(data).pipe(
          tap((res: any) => {
            this.form.setErrors(null);
            this.buttonLoading = false;
            this.refreshStatus = true;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.resetFormError();
            throw error;
          })
        ),
        this.bankSettingsDataService.messages$
      ]).subscribe();
    }
  }

  // validate and format selected time (startDate...endDate) -> (from...to)
  private async validateSelectedTime(selectedTimes, selectedEndTimes, day, formattedSelectedTime, index) {
    if (selectedTimes.length) {
      if(formattedSelectedTime.length < index+1) formattedSelectedTime.push([]);
      await selectedTimes.forEach(async (element, i) => {
        let hasError = false;

        // if not first time field, check if current start time before previous end time  
        if (i > 0) {
          let prevEndTime = moment(selectedEndTimes[i - 1].startDate, 'HH:mm').subtract(1, 'd');
          let currStartTime = moment(element.startDate, 'HH:mm');

          if (currStartTime.isSameOrBefore(prevEndTime)) {
            hasError = true;
            this.haveError.push({
              day: day,
              lastIndex: i,
              currentIndex: i + 1,
              error: "cross time"
            });
          }

          // if last time field, check if current start time is after maxStartTime(23:59)
          if (i == (selectedTimes.length - 1) && !hasError) {
            if (currStartTime.isSameOrAfter(this.maxStartTime)) {
              hasError = true;
              this.haveError.push({
                day: day,
                currentIndex: i + 1,
                error: "cross day"
              });
            }
          }
        }

        // format the time and push to array if no error
        if (!hasError) {
          let inputDateList = {
            'from': moment(element.startDate, 'HH:mm').format('HH:mm'),
            'to': moment(selectedEndTimes[i].startDate, 'HH:mm').format('HH:mm'),
          }
          formattedSelectedTime[index].push(inputDateList);
        }
      });
    }
  }

  private formInit() {
    let bank_name = this.data.bankSetting[0].bank_name;
    let bank_type_name = this.data.bankSetting[0].bank_type_name;
    let bank_id = this.data.bankSetting[0].bank_id;
    let availability_control = this.data.bankSetting[0].availability_control;

    this.form = new FormGroup({
      bank_name: new FormControl({ value: bank_name, disabled: true }),
      bank_type_name: new FormControl({ value: bank_type_name, disabled: true }),
      bank_id: new FormControl(bank_id, [Validators.required]),
      availability_control: new FormControl(availability_control),
      details: new FormArray([])
    });

    this.data.bankSetting.map((elm, i) => {
      this.details.push(this.createTab(elm));
      if(elm?.enable_all_day) {
        this.onAllDay(i);
      }
      else {
        this.onDayCheckbox(i);
      }
    });
  }

  // create currency tab and fields
  private createTab(element?: any) {
    this.alldaySelectedTime.push(this.pushFormattedTime('start', element?.all_day));
    this.mondaySelectedTime.push(this.pushFormattedTime('start', element?.monday));
    this.tuesdaySelectedTime.push(this.pushFormattedTime('start', element?.tuesday));
    this.wednesdaySelectedTime.push(this.pushFormattedTime('start', element?.wednesday));
    this.thursdaySelectedTime.push(this.pushFormattedTime('start', element?.thursday));
    this.fridaySelectedTime.push(this.pushFormattedTime('start', element?.friday));
    this.saturdaySelectedTime.push(this.pushFormattedTime('start', element?.saturday));
    this.sundaySelectedTime.push(this.pushFormattedTime('start', element?.sunday));

    this.alldaySelectedEndTime.push(this.pushFormattedTime('end', element?.all_day));
    this.mondaySelectedEndTime.push(this.pushFormattedTime('end', element?.monday));
    this.tuesdaySelectedEndTime.push(this.pushFormattedTime('end', element?.tuesday));
    this.wednesdaySelectedEndTime.push(this.pushFormattedTime('end', element?.wednesday));
    this.thursdaySelectedEndTime.push(this.pushFormattedTime('end', element?.thursday));
    this.fridaySelectedEndTime.push(this.pushFormattedTime('end', element?.friday));
    this.saturdaySelectedEndTime.push(this.pushFormattedTime('end', element?.saturday));
    this.sundaySelectedEndTime.push(this.pushFormattedTime('end', element?.sunday));

    let timezone = [];
    if (element?.timezone) {
      timezone = this.timezoneDropdownList.filter(x => x.timezone === element?.timezone);
    }
    this.selectedTimezone.push(timezone);
    
    let detailsGroup = {
      setting_currency_id: element?.currency_id,
      all_dayCheckbox: element?.enable_all_day,
      mondayCheckbox: element?.enable_monday,
      tuesdayCheckbox: element?.enable_tuesday,
      wednesdayCheckbox: element?.enable_wednesday,
      thursdayCheckbox: element?.enable_thursday,
      fridayCheckbox: element?.enable_friday,
      saturdayCheckbox: element?.enable_saturday,
      sundayCheckbox: element?.enable_sunday,
      timezone: element?.timezone || null,
      display: element?.display
    }

    return new FormGroup({
      settings_currency_id: new FormControl(detailsGroup.setting_currency_id),
      all_dayCheckbox: new FormControl(detailsGroup.all_dayCheckbox),
      mondayCheckbox: new FormControl(detailsGroup.mondayCheckbox),
      tuesdayCheckbox: new FormControl(detailsGroup.tuesdayCheckbox),
      wednesdayCheckbox: new FormControl(detailsGroup.wednesdayCheckbox),
      thursdayCheckbox: new FormControl(detailsGroup.thursdayCheckbox),
      fridayCheckbox: new FormControl(detailsGroup.fridayCheckbox),
      saturdayCheckbox: new FormControl(detailsGroup.saturdayCheckbox),
      sundayCheckbox: new FormControl(detailsGroup.sundayCheckbox),
      timezone: new FormControl(detailsGroup.timezone),
      display: new FormControl(detailsGroup.display)
    });
  }

  private pushFormattedTime(type, dayTime?: any) {
    let timeArray = [];
    if (dayTime?.length) {
      dayTime.map(x => {
        timeArray.push(this.formatTime(type, x));
      });
    } else {
      timeArray.push(this.formatTime(type));
    }
    return timeArray;
  }

  // format time (from...to) -> (startDate...endDate)
  private formatTime(type, time?: any) {
    let result;

    if (type == 'start') {
      result = {
        startDate: moment().set({ hour: 0, minute: 0 }),
        endDate: moment().set({ hour: 0, minute: 0 })
      };

      if (time) {
        let startTime = time.from.split(":");
        result = {
          startDate: moment().set({ hour: startTime[0], minute: startTime[1] }),
          endDate: moment().set({ hour: startTime[0], minute: startTime[1] })
        }
      }
    } else {
      result = {
        startDate: moment().set({ hour: 23, minute: 59 }).add(1, 'd'),
        endDate: moment().set({ hour: 23, minute: 59 }).add(1, 'd')
      };

      if (time) {
        let endTime = time.to.split(":");
        result = {
          startDate: moment().set({ hour: endTime[0], minute: endTime[1] }).add(1, 'd'),
          endDate: moment().set({ hour: endTime[0], minute: endTime[1] }).add(1, 'd')
        };
      }
    }

    return result;
  }

  // add time when click (+) button
  addNewTime(i, day) {
    switch (day) {
      case "allday":
        this.alldaySelectedTime[i].push(this.formatTime('start'));
        this.alldaySelectedEndTime[i].push(this.formatTime('end'));
        break;
      case "monday":
        this.mondaySelectedTime[i].push(this.formatTime('start'));
        this.mondaySelectedEndTime[i].push(this.formatTime('end'));
        break;
      case "tuesday":
        this.tuesdaySelectedTime[i].push(this.formatTime('start'));
        this.tuesdaySelectedEndTime[i].push(this.formatTime('end'));
        break;
      case "wednesday":
        this.wednesdaySelectedTime[i].push(this.formatTime('start'));
        this.wednesdaySelectedEndTime[i].push(this.formatTime('end'));
        break;
      case "thursday":
        this.thursdaySelectedTime[i].push(this.formatTime('start'));
        this.thursdaySelectedEndTime[i].push(this.formatTime('end'));
        break;
      case "friday":
        this.fridaySelectedTime[i].push(this.formatTime('start'));
        this.fridaySelectedEndTime[i].push(this.formatTime('end'));
        break;
      case "saturday":
        this.saturdaySelectedTime[i].push(this.formatTime('start'));
        this.saturdaySelectedEndTime[i].push(this.formatTime('end'));
        break;
      case "sunday":
        this.sundaySelectedTime[i].push(this.formatTime('start'));
        this.sundaySelectedEndTime[i].push(this.formatTime('end'));
        break;
      default: break;
    }
  }

  // remove time when click (-) button
  deleteTime(i, day) {
    switch (day) {
      case "allday":
        this.alldaySelectedTime[i].splice(-1, 1);
        this.alldaySelectedEndTime[i].splice(-1, 1);
        break;
      case "monday":
        this.mondaySelectedTime[i].splice(-1, 1);
        this.mondaySelectedEndTime[i].splice(-1, 1);
        break;
      case "tuesday":
        this.tuesdaySelectedTime[i].splice(-1, 1);
        this.tuesdaySelectedEndTime[i].splice(-1, 1);
        break;
      case "wednesday":
        this.wednesdaySelectedTime[i].splice(-1, 1);
        this.wednesdaySelectedEndTime[i].splice(-1, 1);
        break;
      case "thursday":
        this.thursdaySelectedTime[i].splice(-1, 1);
        this.thursdaySelectedEndTime[i].splice(-1, 1);
        break;
      case "friday":
        this.fridaySelectedTime[i].splice(-1, 1);
        this.fridaySelectedEndTime[i].splice(-1, 1);
        break;
      case "saturday":
        this.saturdaySelectedTime[i].splice(-1, 1);
        this.saturdaySelectedEndTime[i].splice(-1, 1);
        break;
      case "sunday":
        this.sundaySelectedTime[i].splice(-1, 1);
        this.sundaySelectedEndTime[i].splice(-1, 1);
        break;
      default: break;
    }
  } 

  // update selected time
  updateSelectedTime(i, j, time, day, type) {
    switch (day) {
      case "allday":
        if(type == 'start') {
          this.alldaySelectedTime[i][j] = time;
        }
        else {
          this.alldaySelectedEndTime[i][j] = time;
        }
        break;
      case "monday":
        if(type == 'start') {
          this.mondaySelectedTime[i][j] = time;
        }
        else {
          this.mondaySelectedEndTime[i][j] = time;
        }
        break;
      case "tuesday":
        if(type == 'start') {
          this.tuesdaySelectedTime[i][j] = time;
        }
        else {
          this.tuesdaySelectedEndTime[i][j] = time;
        }
        break;
      case "wednesday":
        if(type == 'start') {
          this.wednesdaySelectedTime[i][j] = time;
        }
        else {
          this.wednesdaySelectedEndTime[i][j] = time;
        }
        break;
      case "thursday":
        if(type == 'start') {
          this.thursdaySelectedTime[i][j] = time;
        }
        else {
          this.thursdaySelectedEndTime[i][j] = time;
        }
        break;
      case "friday":
        if(type == 'start') {
          this.fridaySelectedTime[i][j] = time;
        }
        else {
          this.fridaySelectedEndTime[i][j] = time;
        }
        break;
      case "saturday":
        if(type == 'start') {
          this.saturdaySelectedTime[i][j] = time;
        }
        else {
          this.saturdaySelectedEndTime[i][j] = time;
        }
        break;
      case "sunday":
        if(type == 'start') {
          this.sundaySelectedTime[i][j] = time;
        }
        else {
          this.sundaySelectedEndTime[i][j] = time;
        }
        break;
      default: break;
    }
  }  

  // copy time from one field to other available field(s)
  onCopyTime(i, day) {
    let itemFormGroup = <FormGroup>this.details.controls[i];
    let selectedTime: any;
    let selectedEndTime: any;

    switch (day) {
      case "monday":
        selectedTime = this.mondaySelectedTime[i];
        selectedEndTime = this.mondaySelectedEndTime[i];
        break;
      case "tuesday":
        selectedTime = this.tuesdaySelectedTime[i];
        selectedEndTime = this.tuesdaySelectedEndTime[i];
        break;
      case "wednesday":
        selectedTime = this.wednesdaySelectedTime[i];
        selectedEndTime = this.wednesdaySelectedEndTime[i];
        break;
      case "thursday":
        selectedTime = this.thursdaySelectedTime[i];
        selectedEndTime = this.thursdaySelectedEndTime[i];
        break;
      case "friday":
        selectedTime = this.fridaySelectedTime[i];
        selectedEndTime = this.fridaySelectedEndTime[i];
        break;
      case "saturday":
        selectedTime = this.saturdaySelectedTime[i];
        selectedEndTime = this.saturdaySelectedEndTime[i];
        break;
      case "sunday":
        selectedTime = this.sundaySelectedTime[i];
        selectedEndTime = this.sundaySelectedEndTime[i];
        break;
      default: break;
    }

    if (itemFormGroup.value.mondayCheckbox){
      this.mondaySelectedTime[i] = Object.assign([], selectedTime);
      this.mondaySelectedEndTime[i] = Object.assign([], selectedEndTime);
    }
    if (itemFormGroup.value.tuesdayCheckbox){
      this.tuesdaySelectedTime[i] = Object.assign([], selectedTime);
      this.tuesdaySelectedEndTime[i] = Object.assign([], selectedEndTime);
    } 
    if (itemFormGroup.value.wednesdayCheckbox){
      this.wednesdaySelectedTime[i] = Object.assign([], selectedTime);
      this.wednesdaySelectedEndTime[i] = Object.assign([], selectedEndTime);
    } 
    if (itemFormGroup.value.thursdayCheckbox){
      this.thursdaySelectedTime[i] = Object.assign([], selectedTime);
      this.thursdaySelectedEndTime[i] = Object.assign([], selectedEndTime);
    } 
    if (itemFormGroup.value.fridayCheckbox){
      this.fridaySelectedTime[i] = Object.assign([], selectedTime);
      this.fridaySelectedEndTime[i] = Object.assign([], selectedEndTime);
    } 
    if (itemFormGroup.value.saturdayCheckbox){
      this.saturdaySelectedTime[i] = Object.assign([], selectedTime);
      this.saturdaySelectedEndTime[i] = Object.assign([], selectedEndTime);
    } 
    if (itemFormGroup.value.sundayCheckbox){
      this.sundaySelectedTime[i] = Object.assign([], selectedTime);
      this.sundaySelectedEndTime[i] = Object.assign([], selectedEndTime);
    } 
  }

  // set / remove required validation for timezone
  onAllDay(i) {
    let itemFormGroup = <FormGroup>this.details.controls[i];

    // if all_day is checked, then disable all other checkboxes
    if (itemFormGroup.value.all_dayCheckbox) {
      itemFormGroup.controls["timezone"].setValidators([Validators.required]);
      itemFormGroup.controls["timezone"].updateValueAndValidity();

      this.dayList.forEach((dayLists) => {
        itemFormGroup.controls[dayLists + 'Checkbox'].disable();
        itemFormGroup.controls[dayLists + 'Checkbox'].setValue(0);
      });
    } 
    else {
      itemFormGroup.controls["timezone"].clearValidators();
      itemFormGroup.controls["timezone"].updateValueAndValidity();

      this.dayList.forEach((dayLists) => {
        itemFormGroup.controls[dayLists + 'Checkbox'].enable();
        // change the monday to sunday checkboxes based on the variable previous value
        itemFormGroup.controls[dayLists + 'Checkbox'].setValue(this.data.bankSetting[i]['enable_' + dayLists]);
      });
    }
  }

  // set / remove required validation for timezone
  onDayCheckbox(i) {
    let itemFormGroup = <FormGroup>this.details.controls[i];

    if (
      this.isDisabled(i, 'allday') == false ||
      this.isDisabled(i, 'monday') == false ||
      this.isDisabled(i, 'tuesday') == false ||
      this.isDisabled(i, 'wednesday') == false ||
      this.isDisabled(i, 'thursday') == false ||
      this.isDisabled(i, 'friday') == false ||
      this.isDisabled(i, 'saturday') == false ||
      this.isDisabled(i, 'sunday') == false
    ) {
      itemFormGroup.controls["timezone"].setValidators([Validators.required]);
      itemFormGroup.controls["timezone"].updateValueAndValidity();
    }
    else {
      itemFormGroup.controls["timezone"].clearValidators();
      itemFormGroup.controls["timezone"].updateValueAndValidity();
    }
  }

  // set field / button whether to disable
  isDisabled(i, day?: string) {
    let itemFormGroup = <FormGroup>this.details.controls[i];

    if (day) {
      if (itemFormGroup?.controls[day + 'Checkbox']?.value) {
        return false;
      }
    } else {
      if (itemFormGroup.value.all_dayCheckbox) {
        return false;
      }
    }
    return true;
  }

  resetFormError(){
    this.haveError = [];
    this.formattedAlldaySelectedTime = [];
    this.formattedMondaySelectedTime = [];
    this.formattedTuesdaySelectedTime = [];
    this.formattedWednesdaySelectedTime = [];
    this.formattedThursdaySelectedTime = [];
    this.formattedFridaySelectedTime = [];
    this.formattedSaturdaySelectedTime = [];
    this.formattedSundaySelectedTime = [];
  }
}
