<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filfer -->
            <div class="kt-form kt-form--label-right  mb-2">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="currency_id">
                                        <ng-container *ngFor="let value of dropdown.currencies">
                                            <option [value]="value.id">{{ value.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <input type="text" class="form-control" placeholder="Search"
                                        (change)="onDateRange($event)" formControlName="defaultDate"
                                        [timePicker]="true" [timePickerSeconds]="true"
                                        [alwaysShowCalendars]="true" [ranges]="ranges"
                                        [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                        [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                        ngxDaterangepickerMd />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Action Button -->
            <div class="kt-section col-12 row mb-0">
                <button class="btn btn-success btn-icon-sm mr-2" (click)="onSubmit()">
                    <i class="fas fa-search"></i>Search
                </button>
                <button class="btn btn-danger btn-icon-sm" (click)="onClear()">
                    <i class="fas fa-times"></i>Clear
                </button>
            </div>
            <!-- Statistics -->
            <div class="row col-12 pr-0 pl-0 mb-3 mr-0 d-flex justify-content-between" *ngIf="statisticsReport">
                <div class="row col-xl-12 col-md-12 pr-0 mr-0">
                    <!-- Member -->
                    <div class="col mt-3 pr-0 pointer-calendar">
                        <div class="card card-custom bg-light-primary">
                            <div class="card-body p-4">
                                <span>
                                    <i class="fas fa-users dashboard-icon-user"></i>
                                </span>
                                <span class="card-title fw-600 text-dark-75 font-size-h2 mb-0 mt-3 d-block">
                                    {{ statisticsReport.member.total_new_registration }} <sup>NEW</sup>
                                </span>
                                <span class="card-title fw-600 text-dark-75 font-size-h3 mb-0 mt-1 d-block">
                                    {{ statisticsReport.member.total_first_deposits }} w/
                                    Deposits
                                </span>
                                <span class="card-title fw-600 text-dark-75 font-size-h3 mb-0 d-block">&nbsp;</span>
                            </div>
                        </div>
                    </div>
                    <!-- Deposit -->
                    <div class="col mt-3 pr-0 pointer-calendar">
                        <div class="card card-custom bg-blue">
                            <div class="card-body p-4">
                                <span>
                                    <i class="fa fa-download dashboard-icon-withdraw"></i>
                                </span>
                                <span class="card-title fw-600 text-dark-75 font-size-h2 mb-0 mt-3 d-block">
                                    {{ statisticsReport.deposit.total_deposit_count }} <sup>TOTAL</sup> /
                                    {{ statisticsReport.deposit.total_unique_deposit_count }} <sup> UNIQUE</sup>
                                </span>
                                <span class="card-title fw-600 text-dark-75 font-size-h3 mb-0 mt-1 d-block">
                                    {{ selectedCurrency }} {{ statisticsReport.deposit.total_deposit_amount | number : '1.2' }}
                                </span>
                                <span class="card-title fw-600 font-size-h3 mb-0 d-block text-danger">
                                    {{ selectedCurrency }} {{ statisticsReport.deposit.total_bonus | number : '1.2' }} <sup>BONUS</sup>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- Withdrawal -->
                    <div class="col mt-3 pr-0 pointer-calendar">
                        <div class="card card-custom bg-pink">
                            <div class="card-body p-4">
                                <span>
                                    <i class="fa fa-upload dashboard-icon-deposit"></i>
                                </span>
                                <span class="card-title fw-600 text-dark-75 font-size-h2 mb-0 mt-3 d-block">
                                    {{ statisticsReport.withdrawal.total_withdraw_count }} <sup>TOTAL</sup> /
                                    {{ statisticsReport.withdrawal.total_unique_withdraw_count }} <sup>UNIQUE</sup>
                                </span>
                                <span class="card-title fw-600 text-dark-75 font-size-h3 mb-0 mt-1 d-block">
                                    {{ selectedCurrency }} {{ statisticsReport.withdrawal.ttotal_withdraw_amount | number : '1.2' }}
                                </span>
                                <span class="card-title fw-600 font-size-h3 mb-0 d-block text-success">
                                    {{ selectedCurrency }} {{ statisticsReport.withdrawal.total_bonus_cancelled | number : '1.2' }} <sup>BONUS CANCELLED</sup>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
