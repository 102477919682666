<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ data.mode === 'edit' ? ('Edit' ) : 'Create' }} {{ 'Site Domain' }}</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
            <div class="col-12 form-group row">
              <label class="col-4 control-label">{{ 'Domain' }}<span class="text-danger">*</span></label>
              <input type="text" formControlName="domain" class="col-8 form-control">
            </div>

            <div class="col-12 form-group row">
              <label class="col-4 control-label">{{ 'Type' }}<span class="text-danger">*</span></label>
              <select formControlName="type" class="col-8 form-control">
                <option value="null" disabled hidden> Please Select </option>
                <option [value]="item.id" *ngFor="let item of dropdown.type">{{ item.name }}</option>
              </select>
            </div>

            <div class="col-12 form-group row">
              <label class="col-4 control-label">{{ 'Device Type' }}<span class="text-danger">*</span></label>
              <select formControlName="device_type" class="col-8 form-control">
                <option value="null" disabled hidden> Please Select </option>
                <option [value]="item.id" *ngFor="let item of dropdown.deviceType">{{ item.name }}</option>
              </select>
            </div>

            <div class="col-12 form-group row">
              <label class="col-4 control-label">{{ 'Status' }}<span class="text-danger">*</span></label>
              <select formControlName="status" class="col-8 form-control">
                <option value="null" disabled hidden> Please Select </option>
                <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">{{ value }}</option>
              </select>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>{{ 'Close' }}</button>
          <kt-submit-button *ngIf="data.mode === 'edit' ? canEditSiteDomain : canCreateSiteDomain" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
        </div>
      </div>
    </form>
  </div>
  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true)"></kt-swal-alert>
