<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{data.mode == 'edit' ? 'Edit' : 'Create'}} Operator Role</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="col-12 form-group row pr-0">
          <label class="col-2 col-form-label">Name <span class="text-danger">*</span> </label>
          <input type="text" formControlName="name" min="1" class="col-10 form-control">
        </div>
        <div class="col-12 form-group row pr-0">
          <label class="col-2 col-form-label">Status <span class="text-danger">*</span> </label>
          <select formControlName="status" class="col-10 form-control">
            <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
          </select>
        </div>
        <div class="col-12 form-group row pr-0">
          <label class="col-2 col-form-label">Remarks </label>
          <input type="text" formControlName="remarks" min="1" class="col-10 form-control">
        </div>
        <div class="col-12 table-responsive form-group pr-0 pl-0" formGroupName="access_sections">
          <section class="col-12 heading">
            <div class="col-4">
              <!-- <input type="checkbox"> --> <!-- Note: Temporarily Hide-->
              <label>Function Name</label>
            </div>
            <div class="col-2" *ngFor="let column of functionAccess; let i = index">
              <!-- <input type="checkbox" (click)="onSelectColumn(column)">  --> <!-- Note: Temporarily Hide-->
              <label>{{ column | titlecase }}</label>
            </div>
          </section>
          <ng-container *ngFor="let section of data.collection; let i = index" [formGroupName]="section.id">
            <section class="parent">
              <div class="col-4">
                <i class="fas fa-angle-down" *ngIf="section.functions.length > 0"></i>
                <input type="checkbox" (click)="onSelectSectionRow(section.id, $event)"
                  [checked]="sections['section_'+section.id]" [id]="'section_'+section.id">
                <label>{{ section.name }}</label>
              </div>
              <div class="col-2" *ngFor="let column of functionAccess; let j = index">
                <input type="checkbox" (click)="onSelectColumn(column, section.id, $event)"
                  [checked]="access['action_'+section.id+'_'+j]" [id]="'action_'+section.id+'_'+j">
              </div>
            </section>
            <ng-container *ngIf="section.functions">
              <section class="child" *ngFor="let function of section.functions; let k = index"
                [formGroupName]="function.id">
                <div class="col-4">
                  <input type="checkbox" (click)="onSelectAccessRow(section.id, function.id, $event)"
                    [checked]="functions['function_'+section.id+'_'+function.id]"
                    [id]="'function_'+section.id+'_'+function.id">
                  <label>{{ function.name }}</label>
                </div>
                <div class="col-2" *ngFor="let column of functionAccess">
                  <input type="checkbox" [formControlName]="column" (click)="valueChanges()">
                </div>
              </section>
            </ng-container>
          </ng-container>
        </div>

        <div class="col-12 table-responsive form-group pr-0 pl-0">
          <section class="parent">
            <div class="col-4">
              <label>Special Permissions</label>
            </div>
          </section>

          <section class="child" *ngFor="let item of data.permissions" formGroupName="permissions">
            <div class="col-4">
              <label class="m-0">
                <input type="checkbox" (change)="onSelectPermissions(item.tag, $event)"
                  [checked]="this.form.get('permissions.'+item.tag).value"> <span class="mx-2"> {{ customTitleCase(item.name) }} </span>
              </label>
            </div>
          </section>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i
            class="fas fa-ban"></i>Close</button>
        <kt-submit-button *ngIf="data.mode === 'create' ? canCreateRole : canEditRole" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading"
          (confirmed)="onSave(data.role, data.mode)"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>