import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class AvailableLocaleHttpService {
    
    constructor(private http: HttpClient) { }

    getAvailableLocale(params?: string): Observable<Dropdown[]> {
        return this.http.get<ApiResponse>(`/availablelocales${params}`).pipe(
          map(res => res.data.rows)
        );
      }
}