<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Duplicate Rebate Entries: 
                    <span class="p-l-0" *ngIf="!loading  && displayUsername">
                        {{ displayUsername }} {{ displayStartdate == null ? '' : '|'}}
                    </span>
                    <span *ngIf="!loading && displayStartdate">
                        {{ displayStartdate | timezoneDate: 'YYYY-MM-DD HH:mm' }} - {{ displayEnddate | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                    </span>
                  </h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                 <!-- Filter -->
                <div class="kt-form kt-form--label-right">
                    <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <!-- Form -->
                        <form class="row align-items-center" [formGroup]="form">
                        <!-- First Row -->
                        <div class="col-12 row mb-2 pr-0">
                            <!-- Username -->
                            <div class="col-md-1 kt-form__label col-form-label">
                            <label>Username:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                            <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                            </div>
                            <!-- Date Time -->
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Date/Time:</label>
                            </div>
                            <div class="col-md-2 kt-form__control d-flex">
                                <select class="form-control" formControlName="date_type">
                                    <ng-container *ngFor="let row of dropdown.dateType">
                                        <option [value]="row.value">{{ row.name }}</option>
                                    </ng-container>
                                </select>
                            </div>
                            <div class="col-md-3 kt-form__control">
                                <div class="input-group date">
                                    <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event, 'main')" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                    <span class="input-group-append">
                                      <span class="input-group-text pointer-calendar" (click)="onClearDate('main')">X</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- Second Row -->
                        <div class="col-12 row pr-0">                            
                            <!-- Status -->
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Status:</label>
                            </div>
                            <div class="col-md-5 kt-form__control" formArrayName="status">
                                <div class="d-flex flex-wrap status-filter-pt">
                                <ng-container *ngFor="let item of (dropdown.statuses | keyvalue)">
                                    <ng-container *ngIf="item.key >= 0">
                                      <div class="custom-control custom-checkbox mr-3">
                                        <input type="checkbox" [value]="item.key" class="custom-control-input" [id]="'d-'+item.key" [formControlName]="item.key">
                                        <label class="custom-control-label text-nowrap" [for]="'d-'+item.key">{{ item.value }}</label>
                                      </div>
                                    </ng-container>
                                </ng-container>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                <!-- Action Button -->
                <div class="kt-section col-12 row mb-3 mt-2 d-flex justify-content-between">
                    <div>
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                    </div>
                    <div>
                    <button *ngIf="canReleaseRebate" class="btn btn-success btn-icon-sm mr-2" (click)="rebateAction('release', 'selected')" [disabled]="!(selectedRebate.length > 0)"><i class="fas fa-check"></i>Release Selected</button>
                    <button *ngIf="canReleaseRebate" class="btn btn-success btn-icon-sm mr-2" (click)="rebateAction('release', 'all')" [disabled]="selectedRebate.length > 0"><i class="fas fa-check"></i>Release All</button>
                    <button *ngIf="canRejectRebate" class="btn btn-danger btn-icon-sm mr-2" (click)="rebateAction('reject', 'selected')" [disabled]="!(selectedRebate.length > 0)"><i class="fas fa-ban"></i>Reject Selected</button>
                    <button *ngIf="canRejectRebate" class="btn btn-danger btn-icon-sm mr-2" (click)="rebateAction('reject', 'all')" [disabled]="selectedRebate.length > 0"><i class="fas fa-ban"></i>Reject All</button>
                    </div>
                </div>
                <div class="col-12 form-group">
                  <div class="table-responsive mb-2">
                    <table class="table table-bordered table-hover table-striped">
                      <thead>
                          <tr>
                            <th><input type="checkbox" [(ngModel)]="toggleAll" (change)="checkboxToggleAll()"></th>
                            <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                            <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                            <th class="sort-enabled" (click)="onSortColumn('member_group_name')" [ngClass]="sortingConfig.member_group_name" [class.sort-selected]="sortingSelection.sort_by === 'member_group_name'">Member Group</th>
                            <!-- <th class="text-center">Game Provider</th>
                            <th class="text-center">Category</th> -->
                            <th class="text-center sort-enabled" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency</th>
                            <th class="date-column sort-enabled" (click)="onSortColumn('start_datetime')" [ngClass]="sortingConfig.start_datetime" [class.sort-selected]="sortingSelection.sort_by === 'start_datetime'">Date</th>
                            <th class="text-center">Rebate Setting</th>
                            <th class="text-center">Rebate Setting Updated</th>
                            <th class="text-center">Min Rebate Limit</th>
                            <th class="text-center">Max Rebate Limit</th>
                            <th class="text-center sort-enabled" (click)="onSortColumn('total_rebate_amount')" [ngClass]="sortingConfig.total_rebate_amount" [class.sort-selected]="sortingSelection.sort_by === 'total_rebate_amount'">Rebate Amount</th>
                            <th class="text-center sort-enabled" (click)="onSortColumn('release_amount')" [ngClass]="sortingConfig.release_amount" [class.sort-selected]="sortingSelection.sort_by === 'release_amount'">Release Amount</th>
                            <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                            <th class="sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                            <th class="sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                          </tr>
                      </thead>
                      <tbody *ngIf="(rebateRelease$ | async) as rows">
                        <tr *ngFor="let row of rows">
                          <th [formGroup]="checkboxForm"><input type="checkbox" [formControlName]="row.id"></th>
                          <td>{{ row.id }}</td>
                          <td>
                            <a class="text-primary font-weight-bold" (click)="onOpenDialog('member-information', row.member_account_id)">
                              {{ row.username }}
                            </a>
                            <br>
                            <span matTooltip="Duplicate Rebate Detected" matTooltipClass="custom-tooltip" matTooltipHideDelay="0"  *ngIf="row.duplicate" class="bonus-hunter" (click)="onOpenDialog('rebate-list',row.id ,row)">
                              <i  class="fa fa-exclamation-triangle"></i>
                            </span>
                          </td>
                          <td>{{ row.member_group_name }}</td>
                          <!-- <td>{{ row.game_provider_name }}</td>
                          <td>{{ row.category_name }}</td> -->
                          <td class="text-center">{{ row.currency }}</td>
                          <td>
                            {{ row.start_datetime | timezoneDate: 'YYYY-MM-DD HH:mm': currencyTimezone[row.currency] }} - {{ row.end_datetime | timezoneDate: 'YYYY-MM-DD HH:mm': currencyTimezone[row.currency] }}
                          </td>
                          <td>
                            <a class="text-primary font-weight-bold" (click)="onOpenDialog('show-rebates-setting-details', row.rebate_setting_id)">
                              {{ row.rebate_setting_id }} - {{ row.rebate_setting_name }}
                            </a>
                          </td>
                          <td class="text-center">
                            <ng-container *ngIf="row.update_setting_history && row.update_setting_history != null else elseIsNull">
                              <a class="text-primary font-weight-bold" (click)="onOpenDialog('show-rebates-setting-update-details',null, row.update_setting_history)">
                                {{ row.update_setting_history.updated_by }} 
                                <br>
                                {{ row.update_setting_history.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm' }} 
                              </a>
                            </ng-container>
                            <ng-template #elseIsNull>
                                -
                            </ng-template>
                          </td>
                          <td class="text-right">{{ row.min_rebate_limit }}</td>
                          <td class="text-right">{{ row.max_rebate_limit }}</td>
                          <td class="text-right">
                            <a class="text-primary font-weight-bold" (click)="onOpenDialog('show-rebates-details', row.id, row)">
                              {{ row.total_rebate_amount | number : '1.2' }}
                            </a>
                          </td>
                          <td class="text-right">{{ row.release_amount | number : '1.2' }}</td>
                          <td class="text-center">
                            <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status_id"> {{ row.status }} </span>
                          </td>
                          <td>{{ row.created_by ? row.created_by : '-'}} <br>
                              {{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '' }}
                          </td>
                          <td>{{ row.updated_by ? row.updated_by : '-' }} <br>
                              {{ row.updated_at ? (row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '' }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                    </table>
                  </div>
                  <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                    <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                    </ngb-pagination>
                    <div class="kt-pagination__toolbar">
                      <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                        <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                      </select>
                      <span class="pagination__desc">
                        Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                      </span>
                    </div>
                  </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
              </div>
        </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog()"></kt-swal-alert>