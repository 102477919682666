import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { GameProviderPositionEntityService } from './services/game-provider-position-entity.service';
import { GameProviderPositionDataService } from './services/game-provider-position-data.service';
import { Status } from '@core/enums/status.enum';
import { Subscription, of, Observable } from 'rxjs';
import { Pagination } from '@core/models/pagination.model';
import { tap, map, exhaustMap } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MatDialog } from '@angular/material/dialog';
import { GameProviderPosition } from '@core/models/game-provider-position.model';
import { GameProviderPositionEditDialogComponent } from './dialogs/game-provider-position-edit.component';
import { SitePrefix } from '@core/enums/site-prefix.enum';
import { environment } from '@env/environment';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-game-provider-position',
  templateUrl: './game-provider-position.component.html',
  styleUrls: ['./game-provider-position.component.scss']
})
export class GameProviderPositionComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  status = Status;
  perPageDropdown = this.dropdownHttpService.perPage;
  gameProviderPosition$: Observable<GameProviderPosition[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params: any;
  messages$ = this.gameproviderPositionDataService.messages$;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    categories: this.dropdownHttpService.gameCategories.pipe(
      tap(res => {
        sessionStorage.setItem('game_category', JSON.stringify(res));
      })
    ),
    gameProviders: [],
    status: this.dropdownHttpService.miniGameStatus
  };
  defaultGameProviders: any;
  dataLength = 0;
  loading = false;
  sitePrefix: string = environment.sitePrefix;
  clearBtnLoading = false;
  searchBtnLoading = false;
  disabledEditButton = false;

  sortingConfig = {
    'code': 'asc',
    'name': 'desc',
    'status': 'desc'
  }
  sortingSelection = {
    'sort_by': 'code',
    'sort_order': 'asc',
  };

  // permissions
  canEditGameProviderPosition: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private messageSubscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private gameproviderPositionEntity: GameProviderPositionEntityService,
    private gameproviderPositionDataService: GameProviderPositionDataService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.pagination = this.gameproviderPositionDataService.pagination;
    this.setCurrencyDropdown();
    this.dropdownHttpService.gameProviders.subscribe( res => {
      this.defaultGameProviders = res;
      this.dropdown.gameProviders = res;
    });

    this.reload();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditGameProviderPosition = appPermissions.edit_game_provider_position;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.messageSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.gameProviderPosition$ = this.gameproviderPositionEntity.getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.dataLength = res.length;
        this.loading = false;
        this.pagination = this.gameproviderPositionDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onRefreshGameProviderDropdown();
    this.onSubmit(true);
  }

  reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.gameProviderPosition$ = this.gameproviderPositionEntity.getWithQuery(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.dataLength = res.length;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.page = 1;
            this.pagination = this.gameproviderPositionDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(gameProviderPosition?: any) {
    this.disabledEditButton = true;
    const gameProviderData = this.gameproviderPositionDataService.getGameProvider(gameProviderPosition.id);
    this.subscription = gameProviderData.pipe(
      tap((res: any) => {
        if (res) {
          this.openDialogBy(GameProviderPositionEditDialogComponent, { gameProviderPosition: res });
        }
        this.disabledEditButton = false;
      })
    ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { gameProviderPosition?: any}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        gameProviderPosition: data.gameProviderPosition
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true){
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  onGetRowCount(data: any, category: boolean){
    let row_span = 0;
    if (!category) {
      if (data.category_list.length > 0) {
        data.category_list.forEach(value => {
          row_span += value.position_list.length;
       });
      }
    } else {
      row_span = data.position_list.length;
    }

    if (row_span == 0) {
      row_span = 1;
    }

    return row_span;
  }

  onRefreshGameProviderDropdown(){
    const categoryCode = this.form.value.category_code;
    const currencyID = this.form.value.currency_id;
    let filteredData = this.defaultGameProviders;
    if (categoryCode != 'all') {
      /** Filter the game provider based on a category */
      filteredData = filteredData.filter((element) => {
        const category = element.categories.filter((item) => item.code === categoryCode);
        return category.length > 0;
      });
    }

    if (currencyID != 'all') {
      const currencyCode = this.dropdown.currencies.find(i => i.id == currencyID).name;
      /** Filter the game provider based on a currency */
      filteredData = filteredData.filter((element) => {
        return element.currency_code.includes(currencyCode)
      });
    }

    this.dropdown.gameProviders = filteredData;
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private formInit() {
    this.form = new FormGroup({
      category_code: new FormControl('all'),
      currency_id: new FormControl('all'),
      game_provider_id: new FormControl('all'),
      status: new FormControl('all')
    });
  }
}
