import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllAffiliatesDataService } from '../../services/all-affiliates-data.service';
import { Subject, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'kt-all-affiliates-password',
  templateUrl: './all-affiliates-password.component.html',
  styleUrls: ['./all-affiliates-password.component.scss']
})
export class AllAffiliatesPasswordDialogComponent implements OnInit {

  form: FormGroup;
  messages$ = new Subject<any[]>();
  hasError: boolean;
  refreshStatus: boolean;
  buttonLoading = false;
  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { affiliate: any },
    public dialogRef: MatDialogRef<AllAffiliatesPasswordDialogComponent>,
    private allAffiliatesDataService: AllAffiliatesDataService,
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSubmit(){
    this.buttonLoading = true;
    this.subscription = this.allAffiliatesDataService.changePassword(this.form.value).pipe(
      tap((res) => {
        this.hasError = !!res.success;
        this.messages$.next([...res.message]);
        this.form.setErrors(null);
        this.buttonLoading = false;
      }),
      catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(this.data.affiliate.id),
      password: new FormControl(null, [Validators.required, Validators.minLength(6)])
    });
  }

}
