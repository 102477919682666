<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title"> {{ 'Campaign Members - '  }} {{campaign_data.name}}</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
                <div class="kt-portlet kt-portlet--mobile">
                    <div class="kt-portlet__body">
                        <div class="kt-form kt-form--label-right mb-2">
                            <div class="row align-items-center">
                                <div class="col-xl-12 order-2 order-xl-1 pr-0">
                                    <form class="row align-items-center" [formGroup]="form">
                                        <div class="col-12 row mb-2 pr-0">
                                            <div class="col-md-1 kt-form__label col-form-label">
                                                <label>{{ 'ID'  }}:</label>
                                            </div>
                                            <div class="col-md-2 kt-form__control ">
                                                <input type="text" formControlName="id" placeholder="{{ 'Search'  }}" class="form-control">
                                            </div>
                                            <div class="col-md-1 kt-form__label col-form-label">
                                                <label>{{ 'Username'  }}:</label>
                                            </div>
                                            <div class="col-md-2 kt-form__control ">
                                                <input type="text" formControlName="username" placeholder="{{ 'Search'  }}" class="form-control">
                                            </div>
                                            <div class="col-md-1 kt-form__label col-form-label">
                                                <label>{{ 'Date/Time'  }}:</label>
                                            </div>
                                            <div class="col-md-5 kt-form__control ">
                                                <div class="input-wrap">
                                                    <select formControlName="datetime_type" class="form-control col-5">
                                                        <option value=''> {{ '--Please Select Date Time--'  }} </option>
                                                        <option [value]="value.value" *ngFor="let value of datetime_dropdown">
                                                            {{ value.name}}
                                                        </option>
                                                    </select>
                                                    <div class="input-group date col-7">
                                                        <input type="text" class="form-control" placeholder="{{ 'Search'  }}"
                                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                                            [timePicker]="true" [timePickerSeconds]="true"
                                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                                            [timePicker24Hour]="true" [showDropdowns]="true" [opens]="'left'"
                                                        ngxDaterangepickerMd/>
                                                        <span class="input-group-append">
                                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-12 row mb-2 pr-0">
                                            <div class="col-md-1 kt-form__label col-form-label">
                                                <label>Account Type:</label>
                                            </div>
                                            <div class="col-md-2 kt-form__control">
                                                <select formControlName="account_type_condition" class="form-control">
                                                    <option value="And">AND Condition</option>
                                                    <option value="Or">OR Condition</option>
                                                </select>
                                            </div>
                                            <div class="col-md-3 kt-form__control">
                                                <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
                                                    [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
                                                    [formName]="'account_type'" [selectionAttributes]="'id'"
                                                    [selectedItems]='accountTypeSelectedItems'>
                                                </kt-dropdown-wo-lazyload>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="kt-section col-12 row d-flex justify-content-between mb-3">
                            <div class="dropdown dropdown-inline">
                                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                            </div>
                        </div>
                        <div class="kt-section">
                            <div class="kt-section__content">
                                <div class="table-responsive">
                                    <table class="table table-bordered table-hover table-striped table-sortable">
                                        <thead>
                                            <tr>
                                                <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">{{ 'ID' }}</th>
                                                <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">{{ 'Username'  }}</th>
                                                <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('registration_date')" [ngClass]="sortingConfig.registration_date" [class.sort-selected]="sortingSelection.sort_by === 'registration_date'">{{ 'Registration Date' }}</th>
                                                <th colspan="4" class="text-center">{{ 'Promotion'  }}</th>
                                                <th rowspan="2" class="text-center">{{ 'Actions'  }}</th>
                                            </tr>
                                            <tr>
                                                <th class="text-center sort-enabled" (click)="onSortColumn('promo_code')" [ngClass]="sortingConfig.promo_code" [class.sort-selected]="sortingSelection.sort_by === 'promo_code'">{{ 'Promotion Code' }}</th>
                                                <th class="text-center sort-enabled" (click)="onSortColumn('claimed_at')" [ngClass]="sortingConfig.claimed_at" [class.sort-selected]="sortingSelection.sort_by === 'claimed_at'">{{ 'Claimed At' }}</th>
                                                <th class="text-center sort-enabled" (click)="onSortColumn('expiry_date')" [ngClass]="sortingConfig.expiry_date" [class.sort-selected]="sortingSelection.sort_by === 'expiry_date'">{{ 'Expiry Date' }}</th>
                                                <th class="text-center sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">{{ 'Created By' }}</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="(campaignMemberList$ | async) as rows">
                                            <tr *ngFor="let row of rows">
                                                <td>{{ row.id }}</td>
                                                <td>{{ row.username }}</td>
                                                <td>{{ row.registration_date }}</td>
                                                <td>
                                                    <ng-container *ngIf="row.promo_code == null">
                                                        -
                                                    </ng-container>
                                                    {{ row.promo_code }}
                                                </td>
                                                <td>{{ row.claimed_at != null ? (row.claimed_at | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }}</td>
                                                <td>{{ row.expiry_date != null ? (row.expiry_date | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }}</td>
                                                <td>
                                                    <ng-container>
                                                        {{ row.bo_user_username != null ? row.bo_user_username : '-' }}<br/>
                                                    </ng-container>
                                                        {{ row.created_at != null ? (row.created_at | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }}
                                                </td>
                                                <td class="text-center">
                                                    <button *ngIf="row.created_at === null && canAssignReward" matTooltip="{{ 'Assign Rewards'  }}" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('assignRewards_campaign', row.id)"><i class="fas fa-gift"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                                    </table>
                                </div>
                                <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                                    <ngb-pagination
                                    [pageSize]="pageSize"
                                    [(page)]="page"
                                    [maxSize]="maxSize"
                                    [directionLinks]="true"
                                    [boundaryLinks]="true"
                                    [rotate]="true"
                                    [collectionSize]="pagination.total"
                                    (pageChange)="onViewPageBy(page)">
                                    </ngb-pagination>
                                    <div class="kt-pagination__toolbar">
                                        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                                        </select>
                                        <span class="pagination__desc">
                                        {{ 'Showing'  }} {{pagination.from}} {{ 'to'  }} {{pagination.to}} {{ 'of'  }} {{ pagination.total | number : '1.0' }} {{ 'records'  }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
