import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { RoleEnum } from '@core/enums/role.enum';
import { Subscription } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MyLeadsDataService } from '../../services/my-leads-data.service';

@Component({
    templateUrl: './create-member.component.html',
    styleUrls: ['./create-member.component.scss']
})
export class CreateMemberDialogComponent implements OnInit, OnDestroy {

    form: FormGroup;

    dropdown = {
        currencies: this.dropdownHttpService.currencies,
    };
    role = RoleEnum;
    buttonLoading = false;
    dropdownSettings = {};

    private subscription = new Subscription();
    private datePickerSubscription = new Subscription();
    messages$ = this.myLeadsDataService.messages$;
    refreshStatus: boolean;

    constructor(
        public dialogRef: MatDialogRef<CreateMemberDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { leadData: any },
        public dialog: MatDialog,
        private myLeadsDataService: MyLeadsDataService,
        private dropdownHttpService: DropdownHttpService,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        this.formInit();
        this.dropdownSettings = {
            autoPosition: true,
            maxHeight: 150,
            singleSelection: true,
            text: this.translateService.instant('Please Select'),
            enableFilterSelectAll: false,
            enableSearchFilter: true,
            classes: 'dropdown',
            primaryKey: 'id',
            labelKey: 'username',
            lazyLoading: true,
            noDataLabel: '',
            showCheckbox: false
        };
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.datePickerSubscription.unsubscribe();
    }

    onCloseDialog(event?: Event) {
        this.dialogRef.close(event);
    }

    onSave() {
        this.buttonLoading = true;
        // To set "Save" button to disable (To prevent call API in multiple times when double click)
        this.form.setErrors({ 'invalid': true });
        const data = {
            ...this.form.value,
            username: this.form.get('username').value,
        };
        Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
        this.subscription = this.myLeadsDataService.createMember(data).pipe(
            tap((res: any) => {
                this.messages$.next([...res.message]);
                this.buttonLoading = false;
                this.form.setErrors(null);
            }),
            catchError((error) => {
                this.buttonLoading = false;
                this.form.setErrors(null);
                throw error;
            })
        ).subscribe();
        this.refreshStatus = true;
    }

    onRefresh() {
        if (this.refreshStatus === true) {
            this.dialogRef.close(true);
        }
    }

    private formInit() {

        let username = null;
        let name = this.data.leadData.name;
        let currency = this.data.leadData.settings_currency_id;
        let mobile = this.data.leadData.mobile;
        let remarks = null;

        this.form = new FormGroup({
            username: new FormControl(username, [Validators.required]),
            name: new FormControl(name, [Validators.required]),
            currency_id: new FormControl(currency, [Validators.required]),
            mobile: new FormControl(mobile, [Validators.required]),
            remarks: new FormControl(remarks),
        });
    }

}
