
<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Edit Verification</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <div class="container p-0">
        <div class="row">
          <section class="col-lg-6 card border-0">
            <div class="col-12 p-0 card rounded-0">
              <div class="card-header">
                Member Info
              </div>
              <div class="col-12 border-top-0 p-4">
                <section class="row border-top border-left">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                    <span class="kt-font-bold">Member ID</span>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                    {{ data.memberVerification.member_id }}
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                    <span class="kt-font-bold">Username</span>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                    {{ data.memberVerification.username }}
                  </div>
                </section>
                <section class="row border-left">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                    <span class="kt-font-bold">Name</span>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                    {{ data.memberVerification.member_name }}
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                    <span class="kt-font-bold">Group</span>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                    {{ data.memberVerification.member_group }}
                  </div>
                </section>
                <section class="row border-left">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                    <span class="kt-font-bold">Email</span>
                  </div>
                  <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 border-right border-bottom p-3 d-flex justify-content-between">
                    <span>{{ data.memberVerification.email }}</span>
                  </div>
                </section>
                <section class="row border-left">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                    <span class="kt-font-bold">Mobile</span>
                  </div>
                  <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 border-right border-bottom p-3 d-flex justify-content-between">
                    <span>{{ data.memberVerification.mobile }}</span>
                  </div>
                </section>
              </div>
            </div>
          </section>

          <section class="col-lg-6 card border-0">
            <div class="col-12 p-0 card rounded-0">
              <div class="card-header">
                Wallet Info
              </div>
              <div class="col-12 p-4">
                <section class="row border-top border-left">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                    <span class="kt-font-bold">Wallet Type</span>
                  </div>
                  <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 border-right border-bottom p-3">
                    {{ data.memberVerification.bank_type_name }}
                  </div>
                </section>
                <section class="row border-top border-left">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                    <span class="kt-font-bold">Wallet Name</span>
                  </div>
                  <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 border-right border-bottom p-3">
                    {{ data.memberVerification.bank_name }}
                  </div>
                </section>
                <section class="row border-left">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                    <span class="kt-font-bold">Account Number</span>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 d-flex align-items-center border-right border-bottom p-3">
                    {{ data.memberVerification.account_number }}
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                    <span class="kt-font-bold">Account Name</span>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 d-flex align-items-center border-right border-bottom p-3">
                    {{ data.memberVerification.account_name }}
                  </div>
                </section>
                <section class="row border-top border-left">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                    <span class="kt-font-bold">Select Wallet</span>
                  </div>
                  <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 border-right border-bottom p-3">
                    <kt-dropdown-wo-lazyload
                      [form] = 'form'
                      [dropdownList] = 'bankaccountDropdownList'
                      [dropdownSettings] = 'dropdownSettings'
                      [selectionAttributes] = "'bank_name'"
                      [selectedItems]='selectedBankAccount'
                      [dataLoading]="bankaccountLoading">
                    </kt-dropdown-wo-lazyload>
                  </div>
                </section>
              </div>
            </div>
          </section>

          <section class="col-lg-12 card border-0">
            <div class="col-12 p-0 card rounded-0">
              <div class="card-header">
                Verification Info
              </div>
              <div class="col-12 p-4">
                <section class="row border-top border-left">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                    <span class="kt-font-bold">ID</span>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                    {{ data.memberVerification.id }}
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                    <span class="kt-font-bold">Created At</span>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                    {{ data.memberVerification.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                  </div>
                </section>
                <section class="row border-top border-left">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                    <span class="kt-font-bold">Status</span>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                    <span [ngClass]="'kt-badge kt-badge--inline kt-badge--pill kt-badge--wide kt-badge--' + data.memberVerification.status">{{ data.memberVerification.status_name }}</span>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                    <span class="kt-font-bold">Handler</span>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                    {{ data.newHandler ? data.newHandler : data.memberVerification.handler}}
                  </div>
                </section>
                <section class="row border-left" *ngFor="let field of data.memberVerification.fields">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                    <span class="kt-font-bold">{{ field.field_name }}</span>
                  </div>
                  <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 d-flex align-items-center border-right border-bottom p-3">
                    <ng-container *ngIf="field.type == 1">
                      {{ field.verification_text != '' && field.verification_text != null ? field.verification_text : '-' }}
                    </ng-container>
                    <ng-container *ngIf="field.type == 2 || field.type == ''">
                      <ng-container *ngIf="field.documents.length > 0; else emptyDocument">
                        <button (click)="onOpenDialog('show-document', field)" [disabled]="field.documents.length <= 0" type="button" class="btn btn-primary mr-2">View Document</button>
                      </ng-container>
                      <ng-template #emptyDocument>
                        -
                      </ng-template>
                    </ng-container>
                  </div>
                </section>
                <section class="row border-left">
                  <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                    <span class="kt-font-bold">Remarks</span>
                  </div>
                  <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 d-flex align-items-center border-right border-bottom p-3">
                    <ng-container *ngIf="(data.memberVerification.remarks); else nullTemplate">
                      <ng-container *ngIf="canViewVerificationRemarkHistory; else noRemarkPermissionTemplate">
                        <a class="text-primary font-weight-bold" (click)="onOpenDialog('show-remarks', data.memberVerification)">
                          {{ data.memberVerification.remarks }}
                        </a>
                      </ng-container>
                      <ng-template #noRemarkPermissionTemplate>
                        {{ data.memberVerification.remarks }}
                      </ng-template>
                    </ng-container>
                    <ng-template #nullTemplate>
                        -
                    </ng-template>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div *ngIf="(canEditMemberBankAccountVerification || canEditVerificationDetail) && data.memberVerification.status != 1 && data.memberVerification.status != 2" class="modal-footer justify-content-between">
      <button type="button" class="btn btn-danger" [disabled]="buttonLoading" (click)="onSave(data.memberVerification, 'reject')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fa fa-ban'"></i>Reject</button>
      <button type="button" class="btn btn-success" [disabled]="buttonLoading" (click)="onSave(data.memberVerification, 'approve')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Approve</button>
    </div>
  </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
