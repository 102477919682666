import { AffiliateApproval } from '@core/models/affiliate-approval.model';
import { CommissionReleases } from '@core/models/commission-releases.model';
import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';

@Injectable()
export class AffiliateApprovalDataService extends DefaultDataService<AffiliateApproval>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('AffiliateApproval', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<AffiliateApproval[]> {
    return this.http.get<ApiResponse>('/affiliate/approval').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<AffiliateApproval[]>{
    return this.http.get<ApiResponse>(`/affiliate/approval${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  statusUpdate(affiliateApproval: any) {
    return this.http.put<ApiResponse>(`/affiliate/statusupdate`, affiliateApproval).pipe(
      tap((res) => {
        this.messages$.next(res.message);
      }),
    );;
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }
}
