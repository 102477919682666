import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {tap, switchMap, delay, catchError, mergeMap, map} from 'rxjs/operators';
import { AuthActions } from './auth-action.types';
import { AuthHttpService } from '@core/services/auth-http.service';
import { Router } from '@angular/router';
import { TimezoneHttpService } from '@core/services/timezone-http.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { forkJoin } from 'rxjs';
@Injectable()
export class AuthEffects {

  // login$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.login),
  //     tap(action => {
  //       localStorage.setItem('user_data', JSON.stringify(action.user));
  //       localStorage.setItem('user_token', JSON.stringify(action.token));
  //       localStorage.setItem('user_access', JSON.stringify(action.access_sections));
  //       localStorage.setItem('navigations', JSON.stringify(action.navigations));
  //       localStorage.setItem('user_permissions', JSON.stringify(action.permissions));
  //       localStorage.setItem('user_app_permissions', JSON.stringify(action.app_permissions));
  //       localStorage.setItem('user_app_sections', JSON.stringify(action.app_sections));
  //       this.timezoneHttpService.setTimezone().subscribe();
  //       this.currencyHttpService.setCurrency().subscribe();
  //     }
  //   )),
  //   { dispatch: false });
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      tap(action => {
        localStorage.setItem('user_data', JSON.stringify(action.user));
        localStorage.setItem('user_token', JSON.stringify(action.token));
        localStorage.setItem('user_access', JSON.stringify(action.access_sections));
        localStorage.setItem('navigations', JSON.stringify(action.navigations));
        localStorage.setItem('user_permissions', JSON.stringify(action.permissions));
        localStorage.setItem('user_app_permissions', JSON.stringify(action.app_permissions));
        localStorage.setItem('user_app_sections', JSON.stringify(action.app_sections));
      }),
      mergeMap(action => {
        return forkJoin([
          this.timezoneHttpService.setTimezone(),
          this.currencyHttpService.setCurrency()
        ]).pipe(
          map(() => AuthActions.loginSuccess(action))
        );
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      tap( () => {
        var notificationSound = localStorage.getItem('notificationSound') !== null ? localStorage.getItem('notificationSound') : 'on';
        localStorage.clear();
        localStorage.removeItem('timezones');
        sessionStorage.removeItem('currencies');
        localStorage.setItem('notificationSound', notificationSound);
        sessionStorage.removeItem('isShowEditingAffiliateConfirmation');
        // this.router.navigateByUrl('/login');
        window.location.href = '/login'; // Note: Native redirect to destroy browser!
      })
    ),
    { dispatch: false });

    constructor(
      private router: Router,
      private actions$: Actions,
      private authService: AuthHttpService,
      private timezoneHttpService: TimezoneHttpService,
      private currencyHttpService: CurrencyHttpService,
    ) { }

}
