import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AllTickets } from '@core/models/all-tickets.model';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MemberInformationDialogComponent } from '../members/dialogs/member-information/member-information.component';
import { MemberDataService } from './../members/services/member-data.service';
import { AllTicketsDataService } from './services/all-tickets-data.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import Swal from "sweetalert2";
import { AppPermissionService } from '@core/services/app-permission.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-all-tickets',
  templateUrl: './all-tickets.component.html',
  styleUrls: ['./all-tickets.component.scss']
})
export class AllTicketsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  tickets$: Observable<AllTickets[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  events: Observable<any[]>;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage
  };
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  sortingConfig = {
    'username': 'asc',
    'currency_code': 'asc',
    'event_id': 'desc',
    'used_tickets': 'asc',
    'voided_tickets': 'asc',
    'total_tickets': 'asc'
  }
  sortingSelection = {
    'sort_by': 'event_id',
    'sort_order': 'desc',
  };

  messages$ = this.allTicketsDataService.messages$;

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  // permissions
  canViewMemberDialog: boolean;
  canExportTicketList: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    private allTicketsDataService: AllTicketsDataService,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private memberDataService: MemberDataService,
    private transactionHttpService: TransactionHttpService,
    public dialog: MatDialog,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.accountTypeDropdownList = this.dropdownHttpService.accountTypesLists;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    
    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.setCurrencyDropdown();
    this.getEvents();
    this.formInit();
    this.reload();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.all_tickets_view_member_dialog;
      this.canExportTicketList = appPermissions.export_tickets_list;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.tickets$ = this.allTicketsDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.allTicketsDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onClear() {
    this.clearBtnLoading = true;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    this.onSortColumn('event_id', true);
    this.formInit();
    this.onSubmit(true);
  }

  onSortColumn(property: string, clear = false) {
    if (this.sortingSelection.sort_by == property && !clear) {
      this.sortingSelection.sort_order = this.sortingSelection.sort_order == 'asc' ? 'desc' : 'asc';
      this.sortingConfig[property] = this.sortingSelection.sort_order;
    } else {
      this.sortingSelection.sort_by = property;
      this.sortingSelection.sort_order = 'desc';
      Object.keys(this.sortingConfig).forEach(key => {
        if (key == property) {
          this.sortingConfig[key] = 'desc';
        } else {
          this.sortingConfig[key] = 'asc';
        }
      })
    }
    if (!clear) {
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        if(data['account_type']){
          data['account_type'] = data['account_type'].toString();
        }
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.tickets$ = this.allTicketsDataService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.allTicketsDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onExport() {
    if(this.form.value.account_type){
      this.form.value.account_type = this.form.value.account_type.toString();
    }
    this.params = Object.keys(this.form.value).map(key => key + '=' + this.form.value[key]).join('&');
    this.allTicketsDataService.exportEvent(`?${this.params}&${this.generateSortingParam()}`).subscribe();
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(memberId?: number) {
    if (!this.canViewMemberDialog) {
      Swal.fire('Permission Error', 'You do not have permission to view member information', 'error');
      return;
    }

    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    const member = this.memberDataService.getById(memberId, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
    this.subscription = member.pipe(
      tap((res) => {
        if (res) {
          this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' });
        }
      })
    ).subscribe();
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '1500px',
      height: '80vh',
      data,
      autoFocus: false
    });
  }

  private getEvents() {
    return this.events = this.allTicketsDataService.getEvents();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null) ? fields[key] = formData[key] : key);
    return fields;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }
  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      currency_id: new FormControl('all'),
      event_id: new FormControl('all'),
      account_type_condition: new FormControl('Or'),
      account_type: new FormControl([1,2,3]),
    });
  }

}
