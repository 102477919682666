import { Injectable } from '@angular/core';
import { PromotionCategory } from '@core/models/promotion-content-category.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable()
export class PromotionCategoryEntityService extends EntityCollectionServiceBase<PromotionCategory> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('PromotionCategory', serviceElementsFactory);
    }
}

