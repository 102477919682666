import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ExportCRMBonusActionService {

  constructor(private http: HttpClient) { }

  getDownload(params?: string): Observable<any> {
    return this.http.get<ApiResponse>(`/export-crm-bonus-action?${params}`);
  }
}
