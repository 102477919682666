import { Component, OnInit, Input, Output, ChangeDetectorRef,EventEmitter } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { GameProviderDataService } from '@views/pages/apps/games/game-providers/services/game-provider-data.service';
import { tap, catchError, delay } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'kt-sync-save-button',
  templateUrl: './sync-save-button.component.html',
  styleUrls: ['./sync-save-button.component.scss'],
  host: {
    '(document:mousedown)': 'onClickOutside($event)',
  }
})
export class SyncSaveButtonComponent implements OnInit {

  @ViewChild('syncdrop', null) navdrop: ElementRef; // Angular Version 8
  
  @Input()
  setting_currencies_id_to: number;

  @Input()
  buttonLoading: number;

  @Input()
  game_tag_id: number;

  @Input()
  game_provider_code: string;

  @Input()
  isDisabled: boolean;

  @Input()
  checkupdate: boolean;

  firstCheckUpdate = true;

  @Output()
  confirmed = new EventEmitter<boolean>();

  dropdown = {
    currencies: [],
  };

  siteDropdownSettings = {};
  siteDropdownList = [];
  siteSelectedItems = [];
  
  form: FormGroup;

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private translateService: TranslateService,
    private gameProviderDataService: GameProviderDataService,
    private cdr: ChangeDetectorRef,
    private _eref: ElementRef,
  ) { }

  ngOnInit(): void {

    this.dropdownHttpService.currencies.subscribe(res => {
      this.dropdown.currencies = res;
    })
    
    this.formInit();

  }
  
  private formInit() {
    // Create form

    // default first site_id 
    this.form = new FormGroup({
      site_id: new FormControl(1, [Validators.required])
    });

  }

  dropClick() {
    if( this.checkupdate ==  true && this.firstCheckUpdate ==  true ){
      Swal.fire({
        title: '<div class="text-center">This page contains unsaved changes</div>',
        html: '<div class="text-center">' + this.translateService.instant('If you leave this page, changes you made may not be saved') +'</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Go Back'),
        confirmButtonText: this.translateService.instant('Continue'),
        icon:'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {

          this.firstCheckUpdate = this.firstCheckUpdate == true ? false : true;
          this.navdrop.nativeElement.classList.toggle("visibility");

        } else if (result.isDenied) {
        }

      });
    }else{
      this.firstCheckUpdate = this.firstCheckUpdate == true ? false : true;
      this.navdrop.nativeElement.classList.toggle("visibility");
    } 
  }

  syncClick(currency_id){
   
    var currencies_id_from = this.dropdown.currencies.filter(x => x.id === currency_id);
    var currencies_id_to = this.dropdown.currencies.filter(x => x.id === this.setting_currencies_id_to);
    
    Swal.fire({
      // title: '<div class="fa fa-exclamation-circle">' + this.svg.rewardAlertIcon + '</div>',
      title: '<div class="text-center">Sync Game List</div>',
      html: '<div class="text-center">' + this.translateService.instant('Are you sure you want to sync the game list from ') + currencies_id_from[0]['name'] + this.translateService.instant(' to ') + currencies_id_to[0]['name'] + this.translateService.instant(' ? ') +'</div>',
      showDenyButton: true,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: true,
      reverseButtons: true,
      denyButtonText: this.translateService.instant('Cancel'),
      confirmButtonText: this.translateService.instant('Yes'),
      icon:'warning',
      customClass: {
        denyButton: 'deny-button',
        confirmButton: 'confirm-button',
      }
    }).then(result => {

      if (result.isConfirmed) {
        
        const params = {
          game_tag_id: this.game_tag_id,
          setting_currencies_id_from: currency_id,
          setting_currencies_id_to: this.setting_currencies_id_to,
          game_provider_code: this.game_provider_code
        };

        this.gameProviderDataService.syncGameGameTag(params).pipe(
          tap((res: any) => {
            this.dropClick();
          }),
          catchError((error) => {
            this.dropClick();
            throw error;
          })
        ).subscribe();
        
      } else if (result.isDenied) {
        this.dropClick();
      }

    });
  }

  onClickOutside(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      of(null).pipe(
        delay(105), tap(() => this.navdrop.nativeElement.classList.add("visibility")
        )).subscribe();
    }
  }

}
