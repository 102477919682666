import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { AffiliateGroups } from '@core/models/affiliate-groups.model';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subject } from 'rxjs';
import { tap, map, concatMap } from 'rxjs/operators';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';

@Injectable()
export class AffiliateGroupsDataService extends DefaultDataService<AffiliateGroups>  {

    pagination: Pagination;
    messages$ = new Subject<any[]>();

    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
        super('AffiliateGroups', http, httpUrlGenerator, { root: '' });
    }

    // Affiliate Groups API
    getAll(): Observable<AffiliateGroups[]> {
        return this.http.get<ApiResponse>('/affiliategroup').pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    getWithQuery(pageParam?: string): Observable<AffiliateGroups[]> {
        return this.http.get<ApiResponse>(`/affiliategroup${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    getById(id: number): Observable<AffiliateGroups> {
        return this.http.get<ApiResponse>(`/affiliategroup/${id}`).pipe(
            map(res => res.data.rows)
        );
    }

    add(group: AffiliateGroups): Observable<AffiliateGroups> {
        return this.http.post<ApiResponse>(`/affiliategroup`, group).pipe(
            tap(res => this.messages$.next(res.message)),
            concatMap((entity) => this.http.get(`/affiliategroup/${entity.data.rows.id}`).pipe(
                map((row: ApiResponse) => row.data.rows)
            ))
        );
    }

    updateGroup(id: number, group: AffiliateGroups) {
        return this.http.put<ApiResponse>(`/affiliategroup/${id}`, group).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    // Commission Settings API
    getCommissionSettings(id: number) {
        return this.http.get<ApiResponse>(`/affiliategroup/commissions/settings?affiliate_group_id=${id}`).pipe(
            map(res => res.data.rows)
        );
    }

    getCurrencySettings(id: number, defaultMY: any) {
        return this.http.get<ApiResponse>(`/affiliategroup/currencies/settings?affiliate_group_id=${id}&month=${defaultMY.month}&year=${defaultMY.year}`).pipe(
            map(res => res.data.rows)
        );
    }

    addCommissionSettings(settings: AffiliateGroups) {
        return this.http.post<ApiResponse>(`/affiliategroup/commissions/settings`, settings).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    updateCommissionSettings(id: number, settings: AffiliateGroups) {
        return this.http.put<ApiResponse>(`/affiliategroup/commissions/settings/${id}`, settings).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    addCurrencySettings(settings: AffiliateGroups) {
        return this.http.post<ApiResponse>(`/affiliategroup/currency/settings`, settings).pipe(
            map(res => res.data.rows)
        );
    }

    // Settlement Settings API
    getSettlementSettings(id: number) {
        return this.http.get<ApiResponse>(`/affiliategroup/settlements/settings?affiliate_group_id=${id}`).pipe(
            map(res => res.data.rows)
        );
    }

    addSettlementSettings(settings: any) {
        return this.http.post<ApiResponse>(`/affiliategroup/settlements/settings`, settings).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    updateCurrencySettings(settings: AffiliateGroups) {
        return this.http.put<ApiResponse>(`/affiliategroup/currencies/settings/bulkupdate`, settings).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    updateSettlementSettings(id: number, settings: any) {
        return this.http.put<ApiResponse>(`/affiliategroup/settlements/settings/${id}`, settings).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    // Commission Tier Settings API
    getTierSettings(settingId: number) {
        return this.http.get<ApiResponse>(`/affiliategroup/commissions/tiers?affiliate_group_commission_setting_id=${settingId}`).pipe(
            map(res => res.data.rows)
        );
    }

    addTierSettings(tierSettings: any) {
        return this.http.post<ApiResponse>(`/affiliategroup/commissions/tiers`, tierSettings).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    updateTierSettings(tierId: number, tierSettings: AffiliateGroups) {
        return this.http.put<ApiResponse>(`/affiliategroup/commissions/tiers/${tierId}`, tierSettings).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    bulkUpdateTier(tierSettings: AffiliateGroups) {
        return this.http.put<ApiResponse>(`/affiliategroup/commissions/tiers/bulkupdate`, tierSettings).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    deleteTier(tierId: number) {
        return this.http.delete<ApiResponse>(`/affiliategroup/commissions/tiers/${tierId}`).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    getWithdrawlimit(id: number): Observable<any> {
        return this.http.get<ApiResponse>(`/affiliategroup/withdrawlimit?affiliate_group_id=${id}`).pipe(
            map(res => res.data.rows)
        );
    }

    updateWithdrawlimit(limit: any) {
        return this.http.put<ApiResponse>(`/affiliategroup/withdrawlimit`, limit);
    }

    // Referral Visibility Settings
    getReferralVisibilitySettings(affiliateGroupID: number) {
        return this.http.get<ApiResponse>(`/affiliategroup/referralvisibility?affiliate_group_id=${affiliateGroupID}`).pipe(
            map(res => res.data.rows)
        );
    }

    updateReferralVisibilitySettings(affiliateGroupID: number, data: any) {
        return this.http.put<ApiResponse>(`/affiliategroup/referralvisibility/${affiliateGroupID}`, data).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    // Dialog Popup Visibility Settings
    getDialogPopupVisibilitySettings(affiliateGroupID: number) {
        return this.http.get<ApiResponse>(`/affiliategroup/dialogpopupvisibility?affiliate_group_id=${affiliateGroupID}`).pipe(
            map(res => res.data.rows)
        );
    }

    updateDialogPopupVisibilitySettings(affiliateGroupID: number, data: any) {
        return this.http.put<ApiResponse>(`/affiliategroup/dialogpopupvisibility/${affiliateGroupID}`, data).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    // Inbox Message Visibility Settings
    getInboxMessageVisibilitySettings(affiliateGroupID: number) {
        return this.http.get<ApiResponse>(`/affiliategroup/inboxmessagevisibility?affiliate_group_id=${affiliateGroupID}`).pipe(
            map(res => res.data.rows)
        );
    }

    updateInboxMessageVisibilitySettings(affiliateGroupID: number, data: any) {
        return this.http.put<ApiResponse>(`/affiliategroup/inboxmessagevisibility/${affiliateGroupID}`, data).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    // Promotion Visibility List
    getPromotionVisibilityList(pageParam: string) {
        return this.http.get<ApiResponse>(`/affiliategroup/promotion/settings${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    createUpdatePromotionVisibilityList(mode: any, data: any) {
        if (mode === 'create') {
            return this.http.post<ApiResponse>(`/affiliategroup/promotion/settings`, data).pipe(
                tap(res => this.messages$.next(res.message))
            );
        }
        else {
            return this.http.put<ApiResponse>(`/affiliategroup/promotion/settings/${data.id}`, data).pipe(
                tap(res => this.messages$.next(res.message))
            );
        }
    }

    createBulkPromotionVisibilityList(data: any) {
        return this.http.post<ApiResponse>(`/affiliategroup/promotion/settings/bulkstore`, data).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    deletePromotionVisibilityList(id: any) {
        return this.http.delete<ApiResponse>(`/affiliategroup/promotion/settings/${id}`).pipe(
            tap(res => {
                this.messages$.next(res.message);
            })
        )
    }

    // Dialog Popup Visibility List
    getDialogPopupVisibilityList(pageParam: string) {
        return this.http.get<ApiResponse>(`/affiliategroup/dialogpopup/settings${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    createUpdateDialogPopupVisibilityList(mode: any, data: any) {
        if (mode === 'create') {
            return this.http.post<ApiResponse>(`/affiliategroup/dialogpopup/settings`, data).pipe(
                tap(res => this.messages$.next(res.message))
            );
        }
        else {
            return this.http.put<ApiResponse>(`/affiliategroup/dialogpopup/settings/${data.id}`, data).pipe(
                tap(res => this.messages$.next(res.message))
            );
        }
    }

    createBulkDialogPopupVisibilityList(data: any) {
        return this.http.post<ApiResponse>(`/affiliategroup/dialogpopup/settings/bulkstore`, data).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    deleteDialogPopupVisibilityList(id: any) {
        return this.http.delete<ApiResponse>(`/affiliategroup/dialogpopup/settings/${id}`).pipe(
            tap(res => {
                this.messages$.next(res.message);
            })
        )
    }

    // Inbox Message Visibility List
    getInboxMessageVisibilityList(pageParam: string) {
        return this.http.get<ApiResponse>(`/affiliategroup/inboxmessage/settings${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    createUpdateInboxMessageVisibilityList(mode: any, data: any) {
        if (mode === 'create') {
            return this.http.post<ApiResponse>(`/affiliategroup/inboxmessage/settings`, data).pipe(
                tap(res => this.messages$.next(res.message))
            );
        }
        else {
            return this.http.put<ApiResponse>(`/affiliategroup/inboxmessage/settings/${data.id}`, data).pipe(
                tap(res => this.messages$.next(res.message))
            );
        }
    }

    createBulkInboxMessageVisibilityList(data: any) {
        return this.http.post<ApiResponse>(`/affiliategroup/inboxmessage/settings/bulkstore`, data).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    deleteInboxMessageVisibilityList(id: any) {
        return this.http.delete<ApiResponse>(`/affiliategroup/inboxmessage/settings/${id}`).pipe(
            tap(res => {
                this.messages$.next(res.message);
            })
        )
    }
    
    private paginationInit(res: any) {
        if (res) {
            this.pagination = res.data.paginations;
        }
    }
}
