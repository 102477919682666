import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { MaintenanceHours } from '@core/models/maintenance-hours.model';

@Injectable()
export class MaintenanceHoursDataService extends DefaultDataService<MaintenanceHours>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('MaintenanceHours', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<MaintenanceHours[]> {
    return this.http.get<ApiResponse>('/gameprovider/maintenance').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<MaintenanceHours[]>{
    return this.http.get<ApiResponse>(`/gameprovider/maintenance${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  createGameProviderMaintenanceHour(param: any){
    return this.http.post<ApiResponse>(`/gameprovider/maintenance/createnew`, param).pipe(
      tap( res => this.messages$.next(res.message)),
    );
  }

  updateGameProviderMaintenanceHour(id: number, param: any){
    return this.http.post<ApiResponse>(`/gameprovider/maintenance/update/${id}`, param).pipe(
      tap( res => this.messages$.next(res.message)),
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }
}
