import { AuthHttpService } from '@core/services/auth-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TransferStatus } from '@core/enums/transfer-status.enum';
import { Pagination } from '@core/models/pagination.model';
import { Transfer } from '@core/models/transfer.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { accessSections, loggedUser } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AppState } from '@store/reducers';
import * as moment from 'moment-timezone';
import { forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MemberInformationDialogComponent } from '../members/dialogs/member-information/member-information.component';
import { MemberDataService } from './../members/services/member-data.service';
import { TransferEditDialogComponent } from './dialogs/transfer-edit.component';
import { TransferDataService } from './services/transfer-data.service';
import { TransferEntityService } from './services/transfer-entity.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { StatusCheckDialogComponent } from './dialogs/status-check/status-check.component';
import { AppPermissionService } from '@core/services/app-permission.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-transfers',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    gameProviders: this.dropdownHttpService.gameProviders,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: [
      {
        name: 'Pending', value: 0, checked: true
      },
      {
        name: 'In Progress', value: 3, checked: true
      },
      {
        name: 'Approved', value: 1, checked: true
      },
      {
        name: 'Rejected', value: 2, checked: true
      },
      {
        name: 'Failed', value: 4, checked: true
      },
      {
        name: 'Refunded', value: 5, checked: true
      }
    ],
    perPage: this.dropdownHttpService.perPage
  };
  status = TransferStatus;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  transfers$: Observable<Transfer[]>;
  allTransfer = [];

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  ranges: any;
  dropdownSettings = {};
  messages$ = this.transferDataService.messages$;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '2.3';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'transfer_from': 'desc',
    'transfer_to': 'desc',
    'status': 'desc',
    'currency': 'desc',
    'amount': 'desc',
    'bonus': 'desc',
    'created_at': 'desc', // Column : Created By
    'updated_at': 'desc', // Column : Updated By
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  isAdmin: boolean = JSON.parse(localStorage.getItem('user_data')).is_admin;
  // userAccess = JSON.parse(localStorage.getItem('user_access'));
  createTransfers: boolean;
  originalParams = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;
  checkTransferClicked = false;
  transferCheckingIndex: number;
  from = this.transactionHttpService.getLast24Hours().from;
  to = this.transactionHttpService.getLast24Hours().to;

  searchStatus = [0,1,2,3,4,5];
  buttonLoading = false;
  canExportTransfers: boolean;

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  // permissions
  // transfersUseNewPermissions: boolean;
  canViewMemberDialog: boolean;
  canCreateTransfer: boolean;
  canApproveTransfers: boolean;
  canRejectTransfers: boolean;
  canRefundTransfers: boolean;
  canRetryTransfers: boolean;
  canCheckStatus: boolean;
  canMarkAsFailed: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private datePickerSubscription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private transferEntityService: TransferEntityService,
    private transferDataService: TransferDataService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private memberDataService: MemberDataService,
    private eventEmitterService: EventEmitterService,
    private store: Store<AppState>,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));

    // this.createTransfers = this.userAccess[2][3]?.create;
    this.store.pipe(select(accessSections)).subscribe(userAccess => {
      this.createTransfers = userAccess[2][3]?.create;
    });

    this.pagination = this.transferDataService.pagination;
    this.formInit();
    this.originalParams = this.params;
    this.ranges = this.transactionHttpService.ranges;
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.accountTypeDropdownList = this.dropdownHttpService.accountTypesLists;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];

    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    // this.transfersUseNewPermissions = this.appPermissionService.isSectionUsingNewPermissions('transfers');
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.transaction_transfers_view_member_dialog;
      this.canCreateTransfer = appPermissions.create_transfer;
      this.canApproveTransfers = appPermissions.approve_transfers;
      this.canRejectTransfers = appPermissions.reject_transfers;
      this.canRefundTransfers = appPermissions.refund_transfers;
      this.canRetryTransfers = appPermissions.retry_transfers;
      this.canCheckStatus = appPermissions.check_transfer_status;
      this.canMarkAsFailed = appPermissions.mark_as_failed;
      this.canExportTransfers = appPermissions.export_transfers_list;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);

    if (localStorage.getItem('filterPendingTransfer')) {
      localStorage.removeItem('filterPendingTransfer');
      
      this.searchStatus = [0,3];
      this.form.patchValue({ 
        status: this.searchStatus,
        start_date:null,
        end_date:null,
        defaultDate: null
      });

      this.dropdown.statuses.forEach(child => {
        if (child.name !== 'In Progress' && child.name !== 'Pending') {
          child.checked = false;
        } else {
          child.checked = true;
        }
      });
      this.onSubmit(true);
    }

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, id?: number) {
    if (id) {
      const member = this.memberDataService.getById(id, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&additional_info=1`);
      this.subscription = member.pipe(
        tap((res) => {
          if (res) {
            if (!this.canViewMemberDialog) {
              Swal.fire('Permission Error', 'You do not have permission to view member dialog', 'error');
              return;
            }

            this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' });
          }
        })
      ).subscribe();
    } else {
      this.openDialogBy(TransferEditDialogComponent, { mode: 'create' });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.allTransfer = [];
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.transfers$ = this.transferEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap((res: any) => {
        this.allTransfer = res;
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.transferDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onCheckTransfer(row: any, index: number) {
    this.transferCheckingIndex = index;
    this.checkTransferClicked = true;
    this.loadingBar.start();
    this.transferDataService.transferCheck(row.id).pipe(
      tap((res: any) => {
        const data = {
          id: row.id,
          username: row.username,
          created_at: row.created_at,
          from: row.transfer_from,
          to: row.transfer_to,
          currency: row.currency,
          amount: row.amount,
          cms_status: row.status,
          cms_status_name: row.status_name,
          gsys_status: res.data?.tx_status ?? 0,
          gsys_status_name: res.data?.tx_status_name ?? 'Failed to check transaction'
        },
        dialogRef = this.dialog.open(StatusCheckDialogComponent, {
          width: '600px',
          height: 'auto',
          data: data
        });
        dialogRef.afterClosed().subscribe((result) => {
          if(result === true)
            this.onViewPageBy(this.page).subscribe();
        })

        this.transferCheckingIndex = null;
        this.checkTransferClicked = false;
        this.loadingBar.complete();
      }),
      catchError((error) => {
        this.transferCheckingIndex = null;
        this.checkTransferClicked = false;
        this.loadingBar.complete();
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
  }

  onFailedTransfer(row, type: string) {
    var title = `Are you sure?`;
    var text = `<p>You want to ${type === 'refund' ? `refund into ${row.transfer_from}?` : `retry this transfer?`}</p><p style="color: red">`;
    var footer = `${type === 'refund' ?
      `Please make sure the both ${row.transfer_from} and ${row.transfer_to} wallet do not have the refund amount before proceeding</p>` :
      `Please make sure the amount is still in ${row.transfer_from} wallet before proceeding`}</p>`;
    Swal.fire({
      title: title,
      html: text + '' + footer,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.onMark(row, type)
      }
    });
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.searchStatus = [0,1,2,3,4,5];
    this.eventEmitterService.onClearMemberSearch();
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    this.form.patchValue({
      id: null,
      username: null,
      name: null,
      currency_id: 'all',
      status:  this.searchStatus,
      game_provider: 'all',
      date_type: 'created_by',
      start_date: this.from,
      end_date: this.to,
      defaultDate: {
        startDate: this.from,
        endDate: this.to
      },
      remarks: null,
      account_type_condition: 'Or',
      account_type: [1, 2, 3]
    });
    this.dropdown.statuses.forEach(child => {
      child.checked = true;
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.allTransfer = [];
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        if(data['account_type']){
          data['account_type'] = this.form.controls['account_type'].value.toString();
        }
        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.transfers$ = this.transferEntityService.getWithQuery(`?perPage=${this.pageSize}&${this.generateSortingParam()}${parameters}`).pipe(
          tap(res => {
            this.allTransfer = res;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.transferDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onExport() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.buttonLoading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.transferDataService.export(`?perPage=${this.pageSize}&${this.generateSortingParam()}${parameters}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.buttonLoading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.buttonLoading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  reload(refresh: boolean) {
    if (!refresh) {
      this.onViewPageBy(this.page).subscribe();
    } else {
      this.appendNew(this.page);
    }
  }

  appendNew(page = 1, pageSize?: number, params?: string) {
    this.loadingBar.start();
    let newTransfers$ = null;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    newTransfers$ = this.transferEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.transferDataService.pagination;
      })
    );

    newTransfers$.subscribe(newTransfers => {
      this.transfers$.subscribe(res => {
        if (res.length < newTransfers.length) {
          for (let i = 0; i < (newTransfers.length - res.length); i++) {
            res.unshift(newTransfers[newTransfers.length - 1 - i]);
          }
        } else if (res.length > newTransfers.length) {
          this.onViewPageBy(this.page).subscribe();
        }
      });
      this.loadingBar.complete();
    });

  }

  onMark(row: any, type: string) {
    this.store.pipe(select(loggedUser)).subscribe((res) => {
      let message = '';
      let placeHolder = ''
      if (type === 'fail') {
        message = `Marked as failed by ${res.username}`;
        placeHolder = 'reason'
      } else {
        message = type === 'refund' ? 'Refund' : 'Retry';
        placeHolder = 'remarks';
      }
      Swal.fire({
        title: message,
        input: 'text',
        inputPlaceholder: `Enter ${placeHolder}`,
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Submit',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          if (type === 'fail') {
            if (result.value.length > 0) {
              message += ` due to : ${result.value}`;
            }
            let transfer: Transfer;
            transfer = {
              ...transfer,
              id: row.id,
              status: 4,
              remarks: message
            };
            this.subscription = forkJoin([
              this.transferDataService.updateTransfer(transfer),
              this.transferDataService.messages$
            ]).subscribe();
          } else {
            const data = {
              id: row.id,
              remarks: result.value
            }
            if (type === 'refund') {
              this.subscription = this.transferDataService.refund(data).pipe(
                tap((res: any) => {
                  this.messages$.next([...res.message]);
                  this.form.setErrors(null);
                }),
                catchError((error) => {
                  this.form.setErrors(null);
                  throw error;
                })
              ).subscribe();
            } else {
              this.subscription = this.transferDataService.retry(data).pipe(
                tap((res: any) => {
                  this.messages$.next([...res.message]);
                  this.form.setErrors(null);
                }),
                catchError((error) => {
                  this.form.setErrors(null);
                  throw error;
                })
              ).subscribe();
            }
          }
        }
      })
    });
  }

  onUpdateStatus(row: any, type: string) {
    this.store.pipe(select(loggedUser)).subscribe((res) => {
      var title = `Are you sure?`;
      var text = `<p>You want to mark this transfer as ${type === 'approve' ? `Approved` : `Rejected`}?</p>`;
      var footer = `<p style="color: red">Please make sure the amount ${type === 'approve' ? `has been successfully transferred from ${row.transfer_from}` : `in ${row.transfer_from} has not been transferred`} to ${row.transfer_to} before proceeding.</p>`;
      Swal.fire({
        title: title,
        html: text + '' + footer,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          if (type === 'approve' || type === 'reject') {
            let transfer: Transfer;
            transfer = {
              ...transfer,
              id: row.id,
              status: type === 'approve' ? 1 : 2,
              remarks: type === 'approve' ? `Approved by ${res.username}` : `Rejected by ${res.username}`,
            };
            this.subscription = forkJoin([
              this.transferDataService.updateTransfer(transfer),
              this.transferDataService.messages$
            ]).subscribe();
          }
        }
      })
    });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'member-information' ? '1500px' : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data: {
        member: data.member,
        mode: data.mode
      },
      autoFocus: data.mode === 'member-information' ? false : true
    });
    if (data.mode !== 'member-information') {
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.onViewPageBy(this.page).subscribe();
        }
      });
    }
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null),
      name: new FormControl(null),
      currency_id: new FormControl('all'),
      status: new FormControl(this.searchStatus),
      game_provider: new FormControl('all'),
      date_type: new FormControl('created_by'),
      start_date: new FormControl(this.from),
      end_date: new FormControl(this.to),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      }), // Do not remove: For Clearing The Range
      remarks: new FormControl(null),
      account_type_condition: new FormControl('Or'),
      account_type: new FormControl([1, 2, 3])
    });
  }


  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onSearchStatus(event: any, id: number, ind: number) {
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }
    this.form.patchValue({ status: this.searchStatus });
  }

  private generateStatusParams() {
    return {
      newStatusParams: Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&'),
      oldStatusParams: 'status=' + Object.keys(this.searchStatus).map(key => this.searchStatus[key]).join(','),
    };
  }
}
