import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';
import { Pagination } from '@core/models/pagination.model';

@Injectable()
export class DownloadAreaDataService {

  pagination: Pagination;

  constructor(private http: HttpClient) { }

  getDownload(params?: string): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/report/download?${params}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }
}
