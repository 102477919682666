<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <div>
                <h5 class="modal-title">Affiliate - {{ data.affiliate.username }} ({{ data.affiliate.id }}) </h5>
            </div>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div class="row mb-4">
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Name</label></div>
                    <div class="col-12">
                        <h4>{{ data.affiliate.name }} </h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Code</label></div>
                    <div class="col-12">
                        <h4>{{ data.affiliate?.code ? data.affiliate.code : '-' }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Partner Network</label></div>
                    <div class="col-12">
                        <h4>{{ data.affiliate?.partner_network_name ? data.affiliate.partner_network_name : '-' }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Group</label></div>
                    <div class="col-12">
                        <h4>[{{ data.affiliate.currency_code }}] {{ data.affiliate?.affiliate_group ? data.affiliate.affiliate_group : '-' }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Register Date</label></div>
                    <div class="col-12">
                        <h4>{{ data.affiliate.register_date | timezoneDate: 'YYYY-MM-DD HH:mm'}}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Register IP</label></div>
                    <div class="col-12">
                        <ng-container *ngIf="data.affiliate.registration_ip">
                            <span placement="top" [ngbTooltip]="data.affiliate.registration_ip" container="body">
                                <h4
                                    class="ipCLass text-ellipsis"
                                    (click)="ipTooltip.show()"
                                    [cdkCopyToClipboard]="data.affiliate.registration_ip"
                                    mat-raised-button #ipTooltip="matTooltip"
                                    (mouseenter)="$event.stopImmediatePropagation()"
                                    (mouseleave)="$event.stopImmediatePropagation(); ipTooltip.hide(1000)"
                                    matTooltip="Copied!"
                                    matTooltipPosition="above"
                                >
                                    {{ data.affiliate.registration_ip }}
                                </h4>
                            </span>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Status</label></div>
                    <div class="col-12">
                        <h4>
                            <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide"[ngClass]="'kt-badge--' + data.affiliate.status">
                                {{ data.affiliate.status_name }}
                            </span>
                        </h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Email</label></div>
                    <div class="col-12">
                        <h4 class="text-email">{{ data.affiliate?.email ? data.affiliate.email : '-' }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Mobile</label></div>
                    <div class="col-12">
                        <h4>{{ data.affiliate?.mobile ? data.affiliate.mobile : '-' }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Member Homepage URL</label></div>
                    <div class="col-12">
                        <ng-container *ngIf="data.affiliate.member_homepage_url">
                            <span placement="top" [ngbTooltip]="data.affiliate.member_homepage_url" container="body">
                                <h4
                                    class="ipCLass text-ellipsis"
                                    (click)="ipTooltip.show()"
                                    [cdkCopyToClipboard]="data.affiliate.member_homepage_url"
                                    mat-raised-button #ipTooltip="matTooltip"
                                    (mouseenter)="$event.stopImmediatePropagation()"
                                    (mouseleave)="$event.stopImmediatePropagation(); ipTooltip.hide(1000)"
                                    matTooltip="Copied!"
                                    matTooltipPosition="above"
                                >
                                    {{ data.affiliate.member_homepage_url}}
                                </h4>
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Member Register URL</label></div>
                    <div class="col-12">
                        <ng-container *ngIf="data.affiliate.member_register_url">
                            <span placement="top" [ngbTooltip]="data.affiliate.member_register_url" container="body">
                                <h4
                                    class="ipCLass text-ellipsis"
                                    (click)="ipTooltip.show()"
                                    [cdkCopyToClipboard]="data.affiliate.member_register_url"
                                    mat-raised-button #ipTooltip="matTooltip"
                                    (mouseenter)="$event.stopImmediatePropagation()"
                                    (mouseleave)="$event.stopImmediatePropagation(); ipTooltip.hide(1000)"
                                    matTooltip="Copied!"
                                    matTooltipPosition="above"
                                >
                                    {{ data.affiliate.member_register_url}}
                                </h4>
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Sub-Affiliate Register URL</label></div>
                    <div class="col-12">
                        <ng-container *ngIf="data.affiliate.subaffiliate_register_url">
                            <span placement="top" [ngbTooltip]="data.affiliate.subaffiliate_register_url" container="body">
                                <h4
                                    class="ipCLass text-ellipsis"
                                    (click)="ipTooltip.show()"
                                    [cdkCopyToClipboard]="data.affiliate.subaffiliate_register_url"
                                    mat-raised-button #ipTooltip="matTooltip"
                                    (mouseenter)="$event.stopImmediatePropagation()"
                                    (mouseleave)="$event.stopImmediatePropagation(); ipTooltip.hide(1000)"
                                    matTooltip="Copied!"
                                    matTooltipPosition="above"
                                >
                                    {{ data.affiliate.subaffiliate_register_url}}
                                </h4>
                            </span>
                        </ng-container>
                    </div>
                </div>
            </div>

            <hr/>

            <div class="row mb-4">
                <div class="col-12">
                    <form class="row align-items-center" [formGroup]="infoForm">
                        <div class="col-md-1 p-0">
                            <div class="col-12 text-muted"><label>Date/Time:</label></div>
                        </div>

                        <div class="col-md-5 p-0">
                            <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)"
                                formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true"
                                [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true"
                                [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [opens]="'bottom'"
                                [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                        </div>

                        <div class="col-md-3 p-0 pl-2">
                            <kt-search-button [loading]="searchBtnLoading" [disabledButton]="searchBtnLoading" (search)="onInfoSubmit()"></kt-search-button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="row mb-4">
                <h5 class="pl-2">Affiliate Information</h5>
            </div>

            <div class="row mb-4">
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Total Members</label></div>
                    <div class="col-12">
                        <h4>{{ info$?.total_members ? info$.total_members : 0 }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Total Active Members</label></div>
                    <div class="col-12">
                        <h4>{{ info$?.total_active_member ? info$.total_active_member : 0 }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Total New Registrations</label></div>
                    <div class="col-12">
                        <h4>{{ info$?.total_new_registration ? info$.total_new_registration : 0 }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Total Clicks</label></div>
                    <div class="col-12">
                        <h4>{{ info$?.total_clicks ? info$.total_clicks : 0 }}</h4>
                    </div>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Total First Deposit</label></div>
                    <div class="col-12">
                        <h4>USD {{ (info$?.total_first_deposit ? info$.total_first_deposit : 0) | number: '1.2-2' }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Total Turnover</label></div>
                    <div class="col-12">
                        <h4>USD {{ (info$?.total_turnover ? info$.total_turnover : 0) | number: '1.2-2' }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Total Operating Cost</label></div>
                    <div class="col-12">
                        <h4>USD {{ (info$?.total_operating_cost ? info$.total_operating_cost : 0) | number: '1.2-2' }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Net Company Revenue</label></div>
                    <div class="col-12">
                        <h4>USD {{ (info$?.net_company_revenue ? info$.net_company_revenue : 0) | number: '1.2-2' }}</h4>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="providerSummary$">
                <div class="row">
                    <section class="col-lg-4 card border-0 mb-4" *ngFor="let providerSummary of providerSummary$">
                        <div class="col-12 p-0 card rounded-0">
                            <div class="card-header d-flex justify-content-center">
                                Current Month {{ providerSummary.game_provider_code }}
                            </div>
                            <section class="col-12 p-3">
                                <div class="col-12 d-flex justify-content-between" [ngClass]="{'mb-2': item.label !== 'Turnover'}" *ngFor="let item of currentMonthData">
                                    <span>{{ item.label }}</span>
                                    <span>{{ providerSummary[item.param] }}</span>
                                </div>
                            </section>
                        </div>
                    </section>
                </div>
            </ng-container>

            <div class="row mb-4">
                <div class="col-12 pl-2">Note:</div>
                <div class="col-12 pl-4">Telemarketer targeted members are not included in the calculation.</div>
                <div class="col-12 pl-4">All amounts are calculated based on the current month's currency rate.</div>
                <div class="col-12 pl-4">Real-time data may differ from Affiliate Commission History as data may have changed over time.</div>
            </div>

            <hr/>

            <div class="row mb-4">
                <div class="col-12">
                    <form class="row align-items-center" [formGroup]="commInfoForm">
                        <div class="col-md-1 p-0">
                            <div class="col-12 text-muted"><label>Year/Month:</label></div>
                        </div>

                        <div class="col-md-3 p-0">
                            <input type="month" class="form-control" placeholder="Start Year/Month" formControlName="start_yearmonth" [min]="commInfoFrom" [max]="commInfoTo" />
                        </div>
            
                        <div class="col-md-3 p-0 pl-2">
                            <input type="month" class="form-control" placeholder="End Year/Month" formControlName="end_yearmonth" [min]="commInfoFrom" [max]="commInfoTo" />
                        </div>

                        <div class="col-md-3 p-0 pl-2">
                            <kt-search-button [loading]="searchBtnLoading2" [disabledButton]="searchBtnLoading2" (search)="onCommInfoSubmit()"></kt-search-button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="row mb-4">
                <h5 class="pl-2">Affiliate Commission History</h5>
            </div>

            <div class="row mb-4">
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Net Company Revenue</label></div>
                    <div class="col-12">
                        <h4>USD {{ (commInfo$?.nett_company_revenue ? commInfo$.nett_company_revenue : 0) | number: '1.2-2' }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Total Commission</label></div>
                    <div class="col-12">
                        <h4>USD {{ (commInfo$?.commission ? commInfo$.commission : 0) | number: '1.2-2' }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Total Carry Forward</label></div>
                    <div class="col-12">
                        <h4>USD {{ (commInfo$?.carried_over_balance ? commInfo$.carried_over_balance : 0) | number: '1.2-2' }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Total Entitled Commission</label></div>
                    <div class="col-12">
                        <h4>USD {{ (commInfo$?.entitled_commission ? commInfo$.entitled_commission : 0) | number: '1.2-2' }}</h4>
                    </div>
                </div>
                <div class="col-md-2 p-0">
                    <div class="col-12 text-muted"><label>Total Released Commission</label></div>
                    <div class="col-12">
                        <h4>USD {{ (commInfo$?.released_commission ? commInfo$.released_commission : 0) | number: '1.2-2' }}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        </div>
    </div>
</div>
