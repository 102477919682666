import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { AllTickets } from '@core/models/all-tickets.model';

@Injectable()
export class AllTicketsEntityService extends EntityCollectionServiceBase<AllTickets>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('AllTickets', serviceElementsFactory)  }
}
