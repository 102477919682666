<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Bulk Add Promotion Visibility</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="col-md-12 p-0">
                    <div class="col-12 p-0 mb-3 dialog-dropdown">
                        <label class="col-4 col-form-label control-label sub_tab_title p-0">Bonus Type <span class="text-danger">*</span></label>
                        <select formControlName="promo_type" class="form-control" (change)="onPromoTypeDropdown($event.target.value)">
                            <option value="null" disabled>Please Select</option>
                            <option [value]="row.id" *ngFor="let row of dropdown.promoTypes">
                                {{ row.name | titlecase | translate }}
                            </option>
                        </select>
                    </div>
                    <div class="col-12 p-0 mb-3 dialog-dropdown">
                        <label class="col-4 col-form-label control-label sub_tab_title p-0">Promotion Code <span class="text-danger">*</span></label>
                        <div class="col-12 p-0" [ngClass]="{'div-disabled': form.value.promo_type == null }">
                            <kt-dropdown-wo-lazyload 
                                [form]='form' 
                                [dropdownList]='filteredPromotionDropdownList' 
                                [dropdownSettings]='promotionDropdownListSettings' 
                                [formName]="'promotion_id'" 
                                [selectionAttributes]="'id'" 
                                [selectedItems]="promotionListSelectedItems">
                            </kt-dropdown-wo-lazyload>
                        </div>
                    </div>
                    <div class="col-12 mb-3">
                        <label class="row col-4 col-form-label control-label sub_tab_title p-0">Visibility <span class="text-danger">*</span></label>
                        <div class="row">
                            <select class="col-3 form-control" formControlName="visibility" (change)="onChangePromotionVisbility($event)">
                                <option value="null" disabled>Please Select</option>
                                <option value="1">All</option>
                                <option value="2">Include Selected</option>
                                <option value="3">Exclude Selected</option>
                            </select>
                            <ng-container *ngIf="form.value.visibility > 1">
                                <div class="col-9 pr-0" [ngClass]="{'is-invalid': form.value.visibility != 1 && form.value.affiliate_id.length == 0 && form.value.visibility != null }">
                                    <div [ngClass]="{'div-disabled': form.value.visibility == null }">
                                        <kt-dropdown-wo-lazyload
                                            [form]='form'
                                            [dropdownList]='affiliateDropdownList'
                                            [dropdownSettings]='affiliateDropdownListSettings'
                                            [formName]="'affiliate_id'"
                                            [selectionAttributes]="'id'"
                                            [selectedItems]="affiliateListSelectedItems"
                                            (selectedItemsChanged)="onSelectedAffiliatePromotionListItems($event)">>
                                        </kt-dropdown-wo-lazyload>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [isDisabled]="!form.valid || (affiliateListSelectedItems.length == 0 && form.value.visibility != 1)" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event, true)"></kt-swal-alert>