import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Campaign } from '@core/models/campaign.model';

@Injectable()
export class CampaignEntityService extends EntityCollectionServiceBase<Campaign> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('Campaign', serviceElementsFactory);
  }
}
