<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ data.mode === 'edit'? 'Edit' : 'Create' }} Member Crypto Wallet</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="col-12 form-group row pr-0">
              <label class="col-4 col-form-label">Member</label>
              <select class="form-control col-8" disabled>
                <option [value]="null">{{ data.member.id }} - {{ data.member.name }}</option>
            </select>
          </div>
          <div class="col-12 form-group row pr-0">
              <label class="col-4 col-form-label">Token <span class="text-danger">*</span></label>
              <kt-dropdown-wo-lazyload
                id="tokenDropdown"
                class="col-8"
                style="padding:0"
                [form] = 'form'
                [dropdownList] = 'filteredTokenDropdownList'
                [dropdownSettings] = 'tokenDropdownSettings'
                [formName] = "'crypto_token_id'"
                [selectionAttributes] = "'token_id'"
                [selectedItems] = 'tokenSelectedItems'
                (selectedItemsChanged)="onTokenChanged($event)"
                >
              </kt-dropdown-wo-lazyload>
          </div>
          <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">Wallet Address <span class="text-danger">*</span></label>
            <input type="text" formControlName="wallet_address" [ngClass]="{'is-invalid': this.data.mode === 'create' && this.form.value.wallet_address != ''&& this.form.value.wallet_address != null && this.selectedNetwork != '' && !this.validated, 'disabled': this.data.mode === 'edit'}" class="col-8 form-control">
          </div>
          <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">Wallet Nickname</label>
            <input type="text" formControlName="wallet_nickname" class="col-8 form-control">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
        </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
