import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RaffleEvents } from '@core/models/raffle-events.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import * as moment from 'moment-timezone';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { DatePipe } from '@angular/common';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { forkJoin, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { RaffleEventsDataService } from '../../../services/raffle-events-data.service';
import { MessageTemplateDataService } from '@views/pages/apps/superuser/message-template/services/message-template-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { MatDialog } from '@angular/material/dialog';
import { RaffleEventNameDialogComponent } from '../raffle-event-name-dialog/raffle-event-name-dialog.component';


@Component({
  selector: 'kt-raffle-event-edit',
  templateUrl: './raffle-event-edit.component.html',
  styleUrls: ['./raffle-event-edit.component.scss']
})
export class RaffleEventsEditDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;

  form: FormGroup;

  messages$ = this.raffleEventsDataService.messages$;
  predictionTypeIs1 = false;

  dropdown = {
    currencies: this.data.currencies,
    statuses: [...this.dropdownHttpService.statuses],
    smstemplates: this.messageTemplateDataService.getMessageTemplateList(2,10), // Get sms templates with type = 2 (SMS) and section = 10 (Events)
    messagetemplates: this.messageTemplateDataService.getMessageTemplateList(1,10), // Get message templates with type = 1 (Message) and section = 10 (Events)
    locales$: this.raffleEventsDataService.locales$,
    locales: []
  };

  dateTimeStack = {
    dateStart: null,
    dateEnd: null,
  };
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  dateTimeFormat = 'yyyy-MM-dd HH:mm:';

  buttonLoading = false;

  messageTemplatesSettings = {};
  messageTemplateList = [];
  messageTemplateSelectedItem = [];
  smsTemplateList = [];
  smsTemplateSelectedItem = [];
  nonEligibleMessageTemplateSelectedItem = [];
  nonEligibleSmsTemplateSelectedItem = [];

  private datePickerSubscription = new Subscription();
  private subscriptions = new Subscription();
  private localesSub = new Subscription();

  // permissions
  canEditEvent: boolean;
  canUpdateEventStatus: boolean;

  frequencyTypes = [ // TODO: Will change once API collection is available
    { id: 0, name: 'None', value: 'Daily' },
    { id: 1, name: 'Daily', value: 'Daily' },
    { id: 2, name: 'Weekly', value: 'Weekly' },
    { id: 3, name: 'Bi-Weekly', value: 'BiWeekly' },
    { id: 4, name: 'Monthly', value: 'Monthly' },
  ];

  frequencyMonths = this.buildFrequencyMonths();

  frequencyWeekdays = Object.keys(moment.weekdays()).map(el => {
    const id = (+el + 1);
    const name = String(moment.weekdays(id)).substring(0, 3);
    return { id, name };
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { row: RaffleEvents, mode: string; currencies: any, locales: any },
    public dialogRef: MatDialogRef<RaffleEventsEditDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private datePipe: DatePipe,
    private raffleEventsDataService: RaffleEventsDataService,
    private messageTemplateDataService: MessageTemplateDataService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.dropdown.locales$.subscribe(locales => {
      this.dropdown.locales = locales;
    });

    this.formInit();

    // Message Templates Dropdown Settings
    this.messageTemplatesSettings = {
      maxHeight: 200,
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'code',
      showCheckbox: false,
      autoPosition: false,
      position: 'bottom',
    };
    this.setMessagesDropdown();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditEvent = appPermissions.edit_raffle_event;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'start_datetime'),
      this.buildDatePicker(1, 'end_datetime'),
    ]).subscribe();
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.localesSub.unsubscribe();
  }

  onSave() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
    this.buttonLoading = true;

    const data = {
      ...this.form.value,
      start_datetime: this.isValidDate(this.form.value.start_datetime) ? moment(this.form.value.start_datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') : null,
      end_datetime: this.isValidDate(this.form.value.end_datetime) ? moment(this.form.value.end_datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') : null,
      reset_day: (this.form.value.reset_frequency == 0 || this.form.value.reset_frequency == 1) ? 0 : (this.form.value.reset_dayMonthly === null ? this.form.value.reset_dayWeekly : this.form.value.reset_dayMonthly)
    };

    if (this.data.mode == 'create') {
      this.raffleEventsDataService.addRaffleEvent(data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          throw error;
        })
      ).subscribe();
    } else {
      this.raffleEventsDataService.updateRaffleEvent(this.data.row.id, data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          throw error;
        })
      ).subscribe();
    }
  }

  onCloseDialog() {
    this.dialogRef.close(true);
  }


  private formInit() {

    // Define default value
    let
      name = this.data.mode == 'edit' ? this.data.row.name : null,
      start_datetime = this.data.mode == 'edit' ? this.isValidDate(this.data.row?.start_datetime) ? moment(new Date(new Date(this.data.row.start_datetime).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null : null,
      end_datetime = this.data.mode == 'edit' ? this.isValidDate(this.data.row?.end_datetime) ? moment(new Date(new Date(this.data.row.end_datetime).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null : null,
      reset_frequency = this.data.mode == 'edit' ? this.data.row.reset_frequency : 0,
      status = this.data.mode == 'edit' ? this.data.row.status : 1,
      smsTemplate = this.data.mode == 'edit' ? this.data.row.message_template_sms_id : 0,
      messageTemplate = this.data.mode == 'edit' ? this.data.row.message_template_id : 0,
      nonEligibleSmsTemplate = this.data.mode == 'edit' ? this.data.row.non_eligible_message_template_sms_id : 0,
      nonEligibleMessageTemplate = this.data.mode == 'edit' ? this.data.row.non_eligible_message_template_id : 0;
    
    let  resetDayWeekly = null;
    let  resetDayMonthly = null;
      
    // Set date time if edit
    if (this.data.mode == 'edit') {
     
      resetDayMonthly = this.data.row.reset_frequency === 4 ? this.data.row.reset_day : null;
      resetDayWeekly = (this.data.row.reset_frequency === 2 || this.data.row.reset_frequency === 3) ? this.data.row.reset_day : null;

      this.dateTimeStack = {
        dateStart: this.data.row.start_datetime !== null ? new Date(new Date(this.data.row.start_datetime).getTime() + this.offset - this.clientOffset) : null,
        dateEnd: this.data.row.end_datetime !== null ? new Date(new Date(this.data.row.end_datetime).getTime() + this.offset - this.clientOffset) : null,
      };
    }

    // Build sub form for ticket amount
    const buildTicketAmount = () => {
      let result = {};
      this.dropdown.currencies.forEach((item, index) => {
        var amount_per_ticket = this.data.row?.raffle_ticket_amounts.find(x => x.settings_currency_id === item.id)?.amount_per_ticket;
        const ticketAmountGroup = new FormGroup({
          settings_currency_id: new FormControl(item.id),
          amount_per_ticket: new FormControl(amount_per_ticket ? amount_per_ticket : 0, [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)])
        })
        result = { ...result, [index]: ticketAmountGroup };
      });
      return result;
    }

    // Build sub form for ticket amount
    const buildEventName = () => {
      let result = {};
      this.dropdown.locales.forEach((item, index) => {
        const event_name = this.data.row?.raffle_event_names.find(x => x.settings_locale_id === item.id)?.name;
        const eventNameGroup = result[item.id] = new FormGroup({
          settings_locale_id: new FormControl(item.id),
          name: new FormControl(event_name || null),
        });
        result = { ...result, [item.id]: eventNameGroup };
      });
      return result;
    }

    // Build main form
    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      start_datetime: new FormControl(start_datetime, [Validators.required]),
      end_datetime: new FormControl(end_datetime, [Validators.required]),
      reset_frequency: new FormControl(reset_frequency, [Validators.required]),
      status: new FormControl(status, [Validators.required]),
      raffle_event_setting_ticket_amount: new FormGroup(buildTicketAmount()),
      message_template_id: new FormControl(messageTemplate),
      message_template_sms_id: new FormControl(smsTemplate),
      reset_dayMonthly: new FormControl(resetDayMonthly),
      reset_dayWeekly: new FormControl(resetDayWeekly),
      non_eligible_message_template_id: new FormControl(nonEligibleMessageTemplate),
      non_eligible_message_template_sms_id: new FormControl(nonEligibleSmsTemplate),
      raffle_event_names: new FormGroup(buildEventName()),
    });
  }

  private isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, formKey.includes('start') ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      })
    );
  }

  private setMessagesDropdown() {

    // Dropdown list for SMS templates
    this.dropdown.smstemplates.subscribe(res => {
      this.smsTemplateList = res;
      if (this.data.mode === 'edit') {
        if (this.data.row.message_template_sms_id !== 0) {
          this.smsTemplateSelectedItem = [
            this.smsTemplateList.find(x => x.id === this.data.row.message_template_sms_id) ?
              this.smsTemplateList.find(x => x.id === this.data.row.message_template_sms_id) : []
          ];
        }

        if (this.data.row.non_eligible_message_template_sms_id !== 0) {
          this.nonEligibleSmsTemplateSelectedItem = [
            this.smsTemplateList.find(x => x.id === this.data.row.non_eligible_message_template_sms_id) ?
              this.smsTemplateList.find(x => x.id === this.data.row.non_eligible_message_template_sms_id) : []
          ];
        }

      }
    });
    // Dropdown list for Message templates
    this.dropdown.messagetemplates.subscribe(res => {

      this.messageTemplateList = res;
      if (this.data.mode === 'edit') {
        if (this.data.row.message_template_id !== 0) {
          this.messageTemplateSelectedItem = [
            this.messageTemplateList.find(x => x.id === this.data.row.message_template_id) ?
              this.messageTemplateList.find(x => x.id === this.data.row.message_template_id) : []
          ];
        }

        if (this.data.row.non_eligible_message_template_id !== 0) {
          this.nonEligibleMessageTemplateSelectedItem = [
            this.messageTemplateList.find(x => x.id === this.data.row.non_eligible_message_template_id) ?
              this.messageTemplateList.find(x => x.id === this.data.row.non_eligible_message_template_id) : []
          ];
        }
      }
    });
  }

  onResetPrequency(event: Event) {
    this.form.patchValue({
      reset_dayMonthly: (this.data.mode === 'edit' ) && this.data.row.reset_frequency === 4 ? this.data.row.reset_day : null,
      reset_dayWeekly: (this.data.mode === 'edit' ) && (this.data.row.reset_frequency === 2 || this.data.row.reset_frequency === 3) ? this.data.row.reset_day : null,
    });
  }

  private buildFrequencyMonths() {
    const result = [];
    for (let i = 1; i <= +moment().endOf('month').format('DD'); i++) {
      result.push({ id: i, name: i });
    }
    return result;
  }

  onResetFrequency() {
    let target = this.form.get('reset_frequency'), 
    value = target.value;

    let reset_dayWeekly = this.form.get('reset_dayWeekly');
    let reset_dayMonthly = this.form.get('reset_dayMonthly');

    if (value == 2 || value == 3) {
      reset_dayWeekly.setValidators(Validators.required);
      reset_dayWeekly.updateValueAndValidity();
      reset_dayMonthly.clearValidators();
      reset_dayMonthly.updateValueAndValidity();
    } else if(value == 4){
      reset_dayMonthly.setValidators(Validators.required);
      reset_dayMonthly.updateValueAndValidity();
      reset_dayWeekly.clearValidators();
      reset_dayWeekly.updateValueAndValidity();
    }else if (value == 0 || value == 1) {
      reset_dayWeekly.clearValidators();
      reset_dayWeekly.updateValueAndValidity();
      reset_dayMonthly.clearValidators();
      reset_dayMonthly.updateValueAndValidity();
    } 

  }
  
  onOpenDialog(dialog: string, mode: string) {
    if (dialog == 'event_name') {
      this.openDialogBy(RaffleEventNameDialogComponent, { mode: mode, event_name: this.form.value.raffle_event_names});
    }
  }

  private openDialogBy(componentRef: any, data?: { mode: any, event_name: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        mode: data.mode,
        event_name: data.event_name
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.form.patchValue({
          raffle_event_names: result
        });
      }
    });
  }
}
