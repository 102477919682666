<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <ng-container *ngIf="data.type === 'gametype-report'; else headingTemplate">
        <h5 class="modal-title">
          {{ data.param.game_provider_name }} <span *ngIf="data.param.game_sub_category_name !== null">- {{ data.param.game_sub_category_name }}</span>  - Report : {{ unconvertedFrom  }} to {{ unconvertedTo }}
        </h5>
      </ng-container>
      <ng-template #headingTemplate>
        <h5 class="modal-title">{{ data.param.game_provider_name }} Report : {{ timezoneDateWithTimezone(unconvertedFrom, data.param.timezone, 'YYYY-MM-DD HH:mm')  }} to {{ timezoneDateWithTimezone(unconvertedTo, data.param.timezone, 'YYYY-MM-DD HH:mm') }}</h5>
      </ng-template>
      <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
        <div>
          <kt-search-button [disabledButton]="loading" hidden></kt-search-button>
        </div>
        <button *ngIf="data.type === 'gametype-report' ? canExportGameTypeBreakdown : canExportGameProviderBreakdown" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="button_loading"><i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
      </div>
      <div class="kt-section col-12 row d-flex mb-0">
        Last Bet Log Sync At <span class="font-weight-bold">{{ lastBetLogSyncAt ? lastBetLogSyncAt : '-'}}</span>
      </div>
      <kt-member-report-list
        [data]="memberReport$"
        [loading]="loading"
        [pageSize]="pageSize"
        [page]="page"
        [maxSize]="maxSize"
        [pagination]="pagination"
        [params]="params"
        [isModal]="true"
        [listType]="'all-member-report'"
        [showSummary]="false"
        [form]="form"
        [fromGameProvider]="true"
        [fromGameTypeReport]="data.type === 'gametype-report'"
        [gameProviderData]="data.param"
        [onSearch]="false"
      ></kt-member-report-list>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
  </div>
</div>


<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>