import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EditorService } from '@core/services/editor.service';
import { UploadHttpService } from '@core/services/upload-http.service';
import { RegistrationPageDataService } from '../services/registration-page-data.service';
import { CampaignDataService } from '@views/pages/apps/marketeer/campaign/services/campaign-data.service';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { forkJoin, of, Subject, Subscription, throwError } from 'rxjs';
import { tap, map, catchError, delay } from 'rxjs/operators';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { Lightbox } from 'ngx-lightbox';
import { EventEmitterService } from '@core/services/event-emitter.service';
import Swal from 'sweetalert2';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-registration-page-edit',
  templateUrl: './registration-page-edit.component.html',
  styleUrls: ['./registration-page-edit.component.scss']
})
export class RegistrationPageEditComponent implements OnInit {
  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;
  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;

  form: FormGroup;
  buttonLoading = false;
  dropdown = {
    campaign: [],
    statuses: this.dropdownHttpService.statuses,
    types: this.dropdownHttpService.displayTypes,
    locales: this.dropdownHttpService.locales,
    // platform: this.dropdownHttpService.sitePlatforms,
    platform : [
      { id: 1, name: 'User Portal' }
    ],
    layout: [
      { id: 1, name: 'Layout 1' }
    ],
    webSectionPosition: [
      { id: 1, name: 'Left' },
      { id: 2, name: 'Right' }
    ],
    sectionType: [
      { id: 1, name: 'Text' },
      { id: 2, name: 'Image' },
      { id: 3, name: 'Icons' }
    ],
    bannerType: [
      { id: 1, label: 'Top Banner' },
      { id: 2, label: 'Bottom Banner' }
    ],
    imageRatio: ["0.2", "0.4", "0.6", "0.8", "1"],
    alignment: {
      vertical: [
        { id: 1, name: 'Top' },
        { id: 2, name: 'Center' },
        { id: 3, name: 'Bottom' }
      ],
      horizontal: [
        { id: 1, name: 'Left' },
        { id: 2, name: 'Center' },
        { id: 3, name: 'Right' },
      ]
    }
  };
  campaignDropdownSettings = {
    singleSelection: false,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 200, //'auto',
    primaryKey: 'id',
    labelKey: 'name',
    noDataLabel: '',
    showCheckbox: false,
    disabled: this.data.mode === 'edit' ? true : false
  };
  campaignDropdownList = [];
  campaignSelectedItems = [];

  dateTimeFormat = 'yyyy-MM-dd HH:mm:';
  dateTimeStack = {
    start_date_time: null,
    end_date_time: null
  };
  messages$ = this.registrationPageDataService.messages$;
  messages2$ = new Subject<any[]>();
  bannerForm = [
    { section_type_id: 1, images: [], preview: new FormArray([]) },
    { section_type_id: 2, images: [], preview: new FormArray([]) }
  ];
  contentContainer = [];
  imagePreview: FormGroup; // For show image loading & preview only.
  layoutImg = '';
  sample = [];
  // Begin: CKEditor Part
  public editor = Editor;
  editorMaxChar = 200;
  editorConfig = this.editorService.config;
  editorCharCount = {}; // its key is content container's index
  // End: CKEditor Part

  // permissions
  canEditRegistrationPage: boolean;

  private datePickerSubscription = new Subscription();
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string; registrationPage: any },
    public dialogRef: MatDialogRef<RegistrationPageEditComponent>,
    private registrationPageDataService: RegistrationPageDataService,
    private dropdownHttpService: DropdownHttpService,
    private campaignDataService: CampaignDataService,
    private editorService: EditorService,
    private uploadService: UploadHttpService,
    private eventEmitterService: EventEmitterService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private lightbox: Lightbox,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.campaignDataService.getWithQuery(`?paginate=false`).subscribe(res => {
      this.dropdown.campaign = res;
      this.campaignDropdownList = res;
      if (this.data.mode === 'edit') {
        this.campaignSelectedItems.push(this.campaignDropdownList.find(v => v.id === this.data.registrationPage.campaign_id));
      }
    });
    this.eventEmitterService.detectIdenticalSettingsVar = this.eventEmitterService.detectIdenticalSettings.subscribe(res => {
      if (res.data.rows.campexisted) {
        this.onDetectIdentical(res.data.rows);
      }
    });

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditRegistrationPage = appPermissions.edit_registration_page;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.data.mode !== 'duplicate') {
      this.datePickerSubscription = forkJoin([
        this.buildDatePicker(0, 'start_date_time'),
        this.buildDatePicker(1, 'end_date_time')
      ]).subscribe();
    }
  }

  getEditorConfig(idx) {
    return  ({
      ...this.editorService.config,
      wordCount: {
        displayCharacters: true,
        displayWords: true,
        onUpdate: this.onUpdateEditor(idx)
      },
    });
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onFocusField() {
    of(null).pipe(
      delay(0), tap(() => this.focusfield.first.nativeElement.focus()
      )).subscribe();
  }

  onUpdateEditor(idx: number) {
    return (stats) => {
      const chars = stats.characters;
      const isLimitExceeded = chars > this.editorMaxChar;
      const charactersBox =   document.getElementById(`wordcount-container-${idx}`);

      charactersBox.textContent = 'Characters: ' + `${chars}/${this.editorMaxChar}`;
      charactersBox.style.color = isLimitExceeded ? 'red' : 'black';

      this.editorCharCount[idx] = chars;
    }
  }

  onSave(mode: string, identicalSettings?: any) {
    const editorCharExceedLimit = Object.values(this.editorCharCount).some((c: number) => c > this.editorMaxChar);

    if (editorCharExceedLimit) {
      this.messages2$.next(['Your text content exceeded maximum characters allowed (' + this.editorMaxChar + '). Please check again.']);
      return;
    }

    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    for (let index = 0; index < this.form.value.containers.length; index++) {
      if (this.form.value.containers[index].position == null) {
        const positionField = ((this.form.get('containers') as FormArray).controls[index])['controls'].position;
        positionField.setValue(index + 1);
      }
    }
    const data = {
      id: this.data.mode === 'edit' && this.data.registrationPage ? this.data.registrationPage.id : null,
      duplicateId: identicalSettings ? identicalSettings : null,
      ...this.form.getRawValue(),
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    if (mode !== 'edit') {
      this.registrationPageDataService.addSettings(data).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else {
      this.registrationPageDataService.update(this.data.registrationPage.id, data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
  }

  onDetectIdentical(data: any) {
    const text = 'This action will update the registration page to use the currect design and deactivate the previous design.'
    Swal.fire({
      title: 'Registration Page already exists for the same campaign and locale.',
      html: '<div class="text-center">Do you want to replace it? <br> <div class="my-3 text-red">' + text + '<div></div>',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        this.onSave('create', data.duplicateId);
      }
    });
  }

  onChangeType(event: any) {
    this.contentContainer = [];
    (this.form.get('containers') as FormArray).clear();
    if (this.form.value.type_id !== null) {
      this.form.patchValue({
        registration_form_position: null
      });
      this.layoutImg = event.target.value == 2 ? '/assets/img/registration-page/mobile-layout-1.png' : '/assets/img/registration-page/web-layout-1.png'
    }
    if (event.target.value == 1) {
      this.contentContainer.push(
        { section: "top", preview: new FormArray([]) },
        {
          section: "bottom", preview: new FormArray([])
        });
    } else if (event.target.value == 2) {
      this.contentContainer.push({ section: "top", preview: new FormArray([]) });
    }
    if (this.contentContainer) {
      this.contentContainer.forEach((container) => {
        this.addContainer(container);
      });
    }
  }

  onChangeSectionType(event: any, index: number) {
    this.resetValue(index);
    if (this.form.value.containers[index].section_type_id !== null) {
      if (event.target.value != 1) {
        this.onAddImageIcon(index);
      }
    }
  }

  onUploadFile(event: any, section: string, sectionInd: any, imageInd: number) {
    switch (section) {
      case 'banner':
        this.bannerForm[sectionInd].preview.value[imageInd].loading = true;
        break;
      case 'content':
        this.contentContainer[sectionInd].preview.value[imageInd].loading = true;
        break;
    }
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'uploads');

    this.uploadService.upload(formData).pipe(
      tap((res: any) => {
        if (section === 'content') {
          const containersControl = ((this.form.get('containers') as FormArray).controls[sectionInd])['controls'].image_icon.controls[imageInd];
          res.forEach(item => {
            this.contentContainer[sectionInd].preview.value[imageInd].url = item;
            containersControl.controls['image_icon'].setValue(item);
          });
          this.contentContainer[sectionInd].preview.value[imageInd].loading = false;
        } else if (section === 'banner') {
          const bannerControl = ((this.form.get('banners') as FormArray).controls[sectionInd])['controls'].images.controls[imageInd];
          res.forEach(item => {
            this.bannerForm[sectionInd].preview.value[imageInd].url = item;
            bannerControl.controls['image'].setValue(item);
          });
          this.bannerForm[sectionInd].preview.value[imageInd].loading = false;
        }
      }),
      catchError(err => {
        switch (section) {
          case 'content':
            this.contentContainer[sectionInd].preview.value[imageInd].loading = false;
            break;
          case 'banner':
            this.bannerForm[sectionInd].preview.value[imageInd].loading = false;
            break;
        }
        throw err;
      })
    ).subscribe();
  }

  onViewLayout(src: any, displayTypes: number) {
    const type = displayTypes == 2 ? 'Mobile' : 'Website'
    this.sample = [{
      src: src,
      caption: 'Layout - ' + `${type}`,
      thumb: src
    }];
    this.lightbox.open(this.sample, 0, { centerVertically: true, disableScrolling: true, fitImageInViewPort: true, fadeDuration: 0.01, resizeDuration: 0.01 });
  }

  onAddImageIcon(contentIndex: number) {
    let fg = this.fb.group({
      image_icon: new FormControl(null),
      image_icon_position: new FormControl(null),
      image_link: new FormControl(null),
      image_ratio: new FormControl(null),
      image_alignment: new FormControl(null),
      image_vertical_alignment: new FormControl(null),
      icon_title: new FormControl(null),
      description: new FormControl(null),
    });
    (<FormArray>(<FormGroup>(<FormArray>this.form.controls['containers']).controls[contentIndex]).controls['image_icon']).push(fg);
    const imagePreview = new FormGroup({
      loading: new FormControl(false),
      url: new FormControl(''),
    });
    this.contentContainer[contentIndex].preview.push(imagePreview);
  }

  deleteImageIcon(contentIndex: number, index: number) {
    (<FormArray>(<FormGroup>(<FormArray>this.form.controls['containers']).controls[contentIndex]).controls['image_icon']).removeAt(index);
    this.contentContainer[contentIndex].preview.removeAt(index);
  }

  onAddBannerImage(bannerIndex: number, data?: any) {
    let fg = this.fb.group({
      image: new FormControl(null),
      position: new FormControl(null),
      image_link: new FormControl(null),
      image_ratio: new FormControl(null, [Validators.required]),
      alignment: new FormControl(null),
    });
    (<FormArray>(<FormGroup>(<FormArray>this.form.controls['banners']).controls[bannerIndex]).controls['images']).push(fg);

    const imagePreview = new FormGroup({
      loading: new FormControl(false),
      url: new FormControl(''),
    });
    this.bannerForm[bannerIndex].preview.push(imagePreview);
  }

  deleteBannerImage(bannerIndex: number, index: number) {
    (<FormArray>(<FormGroup>(<FormArray>this.form.controls['banners']).controls[bannerIndex]).controls['images']).removeAt(index);
    this.bannerForm[bannerIndex].preview.removeAt(index);
  }

  private resetValue(containerIndex: number) {
    // to reset FormArray value
    const formArr = ((this.form.get('containers') as FormArray).controls[containerIndex])['controls'].image_icon;
    formArr.clear();
    const imgFormArr = this.contentContainer[containerIndex].preview;
    imgFormArr.clear();
    const title = ((this.form.get('containers') as FormArray).controls[containerIndex])['controls'].title;
    title.reset();
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, index === 0 ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      }),
    );
  }

  private formInit() {
    let campaignId = [];
    let name = null;
    let typeId = null;
    let platformId = null;
    let layoutId = 1;
    let localeId = null;
    let startDateTime = null;
    let endDateTime = null;
    let status = null;
    let registrationFormPosition = null;

    if (this.data.mode !== 'create') {
      this.dateTimeStack = {
        start_date_time: this.data.registrationPage.start_date_time !== null ? new Date(this.data.registrationPage.start_date_time) : null,
        end_date_time: this.data.registrationPage.end_date_time !== null ? new Date(this.data.registrationPage.end_date_time) : null
      };
      if (this.data.mode === 'duplicate') {
        campaignId.push(this.data.registrationPage.campaign_id);
      } else {
        campaignId = this.data.registrationPage.campaign_id;
      }
      name = this.data.registrationPage.name;
      typeId = this.data.registrationPage.type_id;
      platformId = this.data.registrationPage.platform_id;
      localeId = this.data.registrationPage.settings_locale_id;
      startDateTime = this.data.registrationPage.start_date_time;
      endDateTime = this.data.registrationPage.end_date_time;
      status = this.data.mode === 'duplicate' ? 0 : this.data.registrationPage.status;
      registrationFormPosition = this.data.registrationPage.registration_form_position;
      this.layoutImg = this.data.registrationPage.type_id == 2 ? '/assets/img/registration-page/mobile-layout-1.png' : '/assets/img/registration-page/web-layout-1.png'
    }

    this.form = new FormGroup({
      campaign_id: new FormControl(campaignId, [Validators.required]),
      name: new FormControl(name, [Validators.required]),
      type_id: new FormControl(typeId),
      platform_id: new FormControl(platformId),
      layout_id: new FormControl(layoutId),
      settings_locale_id: new FormControl({ value: localeId, disabled: this.data.mode === 'edit' ? true : false }, [Validators.required]),
      start_date_time: new FormControl(startDateTime),
      end_date_time: new FormControl(endDateTime),
      status: new FormControl(status, [Validators.required]),
      registration_form_position: new FormControl(registrationFormPosition, [Validators.required]),
      containers: this.fb.array([]),
      banners: this.fb.array([])
    });

    if (this.data.mode === 'create') {
      if (this.bannerForm) {
        this.bannerForm.forEach((banner) => {
          this.addBanner(banner);
        });
      }
    } else {
      if (this.data.registrationPage.type_id == 1) {
        this.contentContainer.push({ section: "top", preview: new FormArray([]) }, { section: "bottom", preview: new FormArray([]) });
      } else {
        this.contentContainer.push({ section: "top", preview: new FormArray([]) });
      }
      this.data.registrationPage.banners.forEach((item: any, index: number) => {
        this.addBanner(item, index);
      });
      this.data.registrationPage.containers.forEach((container: any, ci: number) => {
        this.addContainer(container, ci);
      });
    }
  }

  private addContainer(container?: any, ci?: any) {
    let fg: any;
    if (this.data.mode === 'create') {
      fg = this.fb.group({
        section_type_id: new FormControl(null),
        position: new FormControl(null),
        text_content: new FormControl(null),
        title: new FormControl(null),
        image_icon: this.fb.array([])
      });
    } else {
      fg = this.fb.group({
        rfc_id: new FormControl(container.rfc_id),
        section_type_id: new FormControl(container.section_type_id),
        position: new FormControl(container.position),
        text_content: new FormControl(container.text_content),
        title: new FormControl(container.title),
        image_icon: this.fb.array([])
      });
    }
    (<FormArray>this.form.get('containers')).push(fg);
    if (this.data.mode !== 'create') {
      this.data.registrationPage.containers[ci]?.image_icon?.forEach((imageIcon, ciIndex) => {
        this.onIterateImageIcon(ci, ciIndex);
      })
    }
  }

  onIterateImageIcon(contentIndex: number, ciIndex?: number) {
    let fg = this.fb.group({
      rfcs_id: new FormControl(this.data.registrationPage.containers[contentIndex].image_icon[ciIndex].rfcs_id),
      image_icon: new FormControl(this.data.registrationPage.containers[contentIndex].image_icon[ciIndex].image_icon),
      image_icon_position: new FormControl(this.data.registrationPage.containers[contentIndex].image_icon[ciIndex].image_icon_position),
      image_link: new FormControl(this.data.registrationPage.containers[contentIndex].image_icon[ciIndex].image_link),
      image_ratio: new FormControl(this.data.registrationPage.containers[contentIndex].image_icon[ciIndex].image_ratio),
      image_alignment: new FormControl(this.data.registrationPage.containers[contentIndex].image_icon[ciIndex].image_alignment),
      image_vertical_alignment: new FormControl(this.data.registrationPage.containers[contentIndex].image_icon[ciIndex].image_vertical_alignment),
      icon_title: new FormControl(this.data.registrationPage.containers[contentIndex].image_icon[ciIndex].icon_title),
      description: new FormControl(this.data.registrationPage.containers[contentIndex].image_icon[ciIndex].description),
    });
    (<FormArray>(<FormGroup>(<FormArray>this.form.controls['containers']).controls[contentIndex]).controls['image_icon']).push(fg);
    const imagePreview = new FormGroup({
      loading: new FormControl(false),
      url: new FormControl(this.data.registrationPage.containers[contentIndex].image_icon[ciIndex].image_icon),
    });
    this.contentContainer[contentIndex].preview.push(imagePreview);
  }

  private addBanner(banner?: any, index?: any) {
    let fg: any;
    if (this.data.mode === 'create') {
      fg = this.fb.group({
        section_type_id: [banner ? banner.section_type_id : '', Validators.compose([Validators.required])],
        images: this.fb.array([]),
      });
    } else {
      fg = this.fb.group({
        rfb_id: banner.rfb_id,
        section_type_id: [banner.section_type_id, Validators.compose([Validators.required])],
        images: this.fb.array([]),
      });
    }
    (<FormArray>this.form.get('banners')).push(fg);
    let bannerIndex = this.data.mode === 'create' ? ((<FormArray>this.form.get('banners')).length - 1) : index;
    if (this.data.mode === 'create') {
      this.onAddBannerImage(bannerIndex);
    } else {
      this.data.registrationPage.banners[index].images.forEach((bi, biIndex) => {
        this.onIterateBannerImg(bannerIndex, biIndex);
      });
    }
  }

  onIterateBannerImg(bannerIndex: number, biIndex?: number) {
    let fg: any;
    fg = this.fb.group({
      image: new FormControl(this.data.registrationPage.banners[bannerIndex].images[biIndex].image),
      position: new FormControl(this.data.registrationPage.banners[bannerIndex].images[biIndex].position),
      image_link: new FormControl(this.data.registrationPage.banners[bannerIndex].images[biIndex].image_link),
      image_ratio: new FormControl(this.data.registrationPage.banners[bannerIndex].images[biIndex].image_ratio),
      alignment: new FormControl(this.data.registrationPage.banners[bannerIndex].images[biIndex].alignment),
      rfbs_id: new FormControl(this.data.registrationPage.banners[bannerIndex].images[biIndex].rfbs_id),
    });
    (<FormArray>(<FormGroup>(<FormArray>this.form.controls['banners']).controls[bannerIndex]).controls['images']).push(fg);
    const imagePreview = new FormGroup({
      loading: new FormControl(false),
      url: new FormControl(this.data.registrationPage.banners[bannerIndex].images[biIndex].image),
    });
    this.bannerForm[bannerIndex].preview.push(imagePreview);
  }
}
