import { EditorService } from '@core/services/editor.service';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { CustomUploadAdapterPlugin } from '@core/ckeditor-upload-adapter/CustomUploadAdapterPlugin';
import { Member } from '@core/models/member.model';
import { AllSmsMessagesDataService } from '../service/all-sms-messages-data.service';

@Component({
  selector: 'kt-all-sms-messages-dialog',
  templateUrl: './all-sms-messages-dialog.component.html',
  styleUrls: ['./all-sms-messages-dialog.component.scss']
})
export class AllSmsMessagesDialogComponent implements OnInit {

  form: FormGroup;
  dropdownSettings = {};
  selectedMembers = [];
  messages$ = this.allSmsMessagesDataService.messages$;

  // Begin: CKEditor Part
  public editor = Editor;
  editorConfig = this.editorService.config;
  // End: CKEditor Part

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { member: Member },
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AllSmsMessagesDialogComponent>,
    private allSmsMessagesDataService: AllSmsMessagesDataService,
    private editorService: EditorService
  ) { }

  ngOnInit() {
    this.formInit();
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onMemberChanged(event: Event) {
    this.selectedMembers.push((event as any)[0]);
    this.updateMemberSelection(this.selectedMembers);
  }

  onRemoveMember(id: number) {
    const result = this.selectedMembers.filter((i: Member) => i.id !== id);
    this.selectedMembers = result;
    this.updateMemberSelection(this.selectedMembers);
  }

  onSubmit() {
    const data = {
      ...this.form.value,
      type: 1, // Default 1 per API
      member_account_id: this.selectedMembers.map(i => i.id)
    }

  this.allSmsMessagesDataService.sendMessage(data).subscribe();
  }

  private updateMemberSelection(selectedMembers: Member[]) {
    this.form.patchValue({
      member_account_id: selectedMembers.map(i => i.id)
    });
    this.cdr.detectChanges();
  }

  private formInit() {
    this.form = new FormGroup({
      member_account_id: new FormControl(null),
      title: new FormControl(null, [Validators.required]),
      content: new FormControl(null, [Validators.required]),
    });
  }

}
