
<button (click)="dropClick()" [disabled]="isDisabled || buttonLoading" type="button" class="btn btn-warning mr-2"><i class='fas fa-sync'></i>{{ 'Sync' }}</button>

<div role="tablist" #syncdrop id="syncdrop" class='visibility sync-button'>
    
    <form class="kt-form" [formGroup]="form">
        <h4 class="kt-font-bold text-dark mb-4">{{ 'Sync from' }}
            <i
            class="fa fa-exclamation-circle sync-tooltip text-secondary ml-2" placement="top"
            ngbTooltip="{{ 'The Sync button can synchronize the games from another currency that are supported by the current currency.' }}">
            </i>
        </h4>

        <!-- Currency -->
        <ng-container *ngIf="dropdown.currencies.length > 0 && form.get('site_id').value">
            <ng-container   *ngFor="let value of dropdown.currencies" >
                <ng-container *ngIf="setting_currencies_id_to != value.id">
                    <div class="select-currency" (click)="syncClick(value.id)">
                        <img class="img-fluid" [src]="'assets/img/flag/' + value.name + '.png'" />
                        <p>{{ value.name }}</p>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </form>
</div>