import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { PromotionContent } from '@core/models/promotion-content.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class PromotionContentDataService extends DefaultDataService<PromotionContent>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  delete_messages$ = new Subject<any[]>();
  locales$ = new BehaviorSubject([]);

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('PromotionContent', http, httpUrlGenerator, { root: '' });
  }

  dropdown(pageParam: string): Observable<PromotionContent[]>{
    return this.http.get<ApiResponse>(`/promotioncontents${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getAll(): Observable<PromotionContent[]> {
    return this.http.get<ApiResponse>('/promotioncontent').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(promotionContent: PromotionContent): Observable<PromotionContent>{
    return this.http.post<ApiResponse>(`/promotioncontent`, promotionContent).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((res) => this.http.get(`/promotioncontent/${res.data.content.id}`).pipe(
        map((row: ApiResponse) => row.data.content)
      ))
    );
  }

  getWithQuery(pageParam: string): Observable<PromotionContent[]>{
    return this.http.get<ApiResponse>(`/promotioncontent${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  getById(id: number): Observable<PromotionContent>{
    return this.http.get<ApiResponse>(`/promotioncontent/${id}`).pipe(
      map((res: any) => {
        return {
          id: res.data.content.id,
          ...res.data
        }
      })
    );
  }

  deleteLocale(detailsId: number): Observable<PromotionContent>{
    return this.http.delete<ApiResponse>(`/promotioncontent/details/${detailsId}`).pipe(
      map((row: ApiResponse) => row.message)
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }
}
