<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content" style="overflow: auto;">
      <div class="modal-header form-group">
        <h5 class="modal-title">{{ 'Edit Maintenance Hours' }}</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>

      <div class="col-12 form-group row justify-content-center">
        <label class="col-5 col-form-label">Name <span class="text-danger">*</span></label>
        <input type="text" formControlName="bank_name" class="col-6 form-control">
      </div>
      <div class="col-12 form-group row justify-content-center">
        <label class="col-5 col-form-label">Bank Type <span class="text-danger">*</span></label>
        <input type="text" formControlName="bank_type_name" class="col-6 form-control">
      </div>
      <div class="col-12 form-group row justify-content-center">
        <label class="col-5 col-form-label">Availability Control<i class="fa fa-info-circle ml-2 mr-4" placement="bottom"
          ngbTooltip="{{ 'Select the page that required the bank availability control. Bank or Payment Gateway will be hidden or shown based on the maintenance hours on the selected page.' }}"></i></label>
        <select formControlName="availability_control" class="col-6 form-control">
          <option [value]="null">Please Select</option>
          <option *ngFor="let value of availability" [value]="value.id">{{ value.name }}</option>
        </select>
      </div>

      <div class="col-11 table-responsive maintenance-hour-content">
        <mat-tab-group animationDuration="0ms" class="form-group tab-margin" formArrayName="details">
          <ng-container *ngIf="details.controls.length">
            <mat-tab *ngFor="let item of details.controls; let i = index" [formGroupName]="i">
              <ng-template mat-tab-label>
                {{ data?.bankSetting[i]?.currency_code }}
              </ng-template>
              <input type="hidden" formControlName="settings_currency_id" />

              <div class="col-12 row item-margin pt-4 pb-5">
                <label class="col-4 col-form-label pl-0">Maintenance Dialog<i class="fa fa-info-circle ml-2 mr-4" placement="bottom"
                  ngbTooltip="{{ 'Turn ON to display the maintenance hours on UP/MUP.' }}"></i></label>
                <div class="col-7 pl-0">
                  <select formControlName="display" class="form-control">
                    <option [value]="null">Please Select</option>
                    <option *ngFor="let value of availability" [value]="value.id">{{ value.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-12 row item-margin pb-5">
                <label class="col-4 col-form-label pl-0">Timezone <span
                    *ngIf="isDisabled(i) == false || isDisabled(i, 'monday') == false || isDisabled(i, 'tuesday') == false || isDisabled(i, 'wednesday') == false ||
                      isDisabled(i, 'thursday') == false || isDisabled(i, 'friday') == false || isDisabled(i, 'saturday') == false || isDisabled(i, 'sunday') == false"
                    class="text-danger">*</span></label>
                <div class="col-7 pl-0">
                  <kt-dropdown-wo-lazyload [form]='details.controls[i]' [dropdownList]='timezoneDropdownList'
                    [dropdownSettings]='timezoneDropdownSettings' [formName]="'timezone'"
                    [selectionAttributes]="'timezone'" [selectedItems]="selectedTimezone[i]">
                  </kt-dropdown-wo-lazyload>
                </div>
              </div>

              <!-- allday -->
              <div class="col-12 heading pb-5" style="display: inline-flex;">
                <div class="col-1">
                  <input type="checkbox" (change)="onAllDay(i)" formControlName="all_dayCheckbox">
                </div>
                <div class="col-2">
                  <label>{{ 'All Days' }}</label>
                </div>
                <div class="col-9" id="allday-container">
                  <ng-container #containerAllday
                    *ngFor="let selectedTime of alldaySelectedTime[i]; let a = index; let last = last;">
                    <span [ngStyle]="{'display': (last == true)? '': 'block'}">
                      <input type="text" [disabled]="isDisabled(i)" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, a, selectedTime, 'allday', 'start')" [(ngModel)]="selectedTime"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                      -
                      <input type="text" [disabled]="isDisabled(i)" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, a, alldaySelectedEndTime[i][a], 'allday', 'end')" [(ngModel)]="alldaySelectedEndTime[i][a]"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                    </span>
                  </ng-container>

                  <button class="plus-minus-btn" [ngClass]="{'btn-disable': isDisabled(i)}" [disabled]="isDisabled(i)"
                    (click)="addNewTime(i, 'allday')"><i class="fas fa-plus"></i></button>
                  <button class="plus-minus-btn" *ngIf="alldaySelectedTime[i]?.length > 1"
                    [ngClass]="{'btn-disable': isDisabled(i)}" [disabled]="isDisabled(i)"
                    (click)="deleteTime(i, 'allday')"><i class="fas fa-minus"></i></button>
                </div>
              </div>

              <!-- monday -->
              <div class="col-12 heading pb-5" style="display: inline-flex;">
                <div class="col-1">
                  <input type="checkbox" formControlName="mondayCheckbox" (change)="onDayCheckbox(i)">
                </div>
                <div class="col-2">
                  <label>{{ 'Monday' }}</label>
                </div>
                <div class="col-9" id="monday-container">
                  <ng-container #containerMonday
                    *ngFor="let selectedTime of mondaySelectedTime[i]; let b = index; let last = last;">
                    <span [ngStyle]="{'display': (last == true)? '': 'block'}">
                      <input type="text" [disabled]="isDisabled(i, 'monday')" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, b, selectedTime, 'monday', 'start')" [(ngModel)]="selectedTime"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                      -
                      <input type="text" [disabled]="isDisabled(i, 'monday')" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, b, mondaySelectedEndTime[i][b], 'monday', 'end')" [(ngModel)]="mondaySelectedEndTime[i][b]"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                    </span>
                  </ng-container>

                  <button class="plus-minus-btn" [ngClass]="{'btn-disable': isDisabled(i, 'monday')}"
                    [disabled]="isDisabled(i, 'monday')" (click)="addNewTime(i, 'monday')"><i
                      class="fas fa-plus"></i></button>
                  <button class="plus-minus-btn" *ngIf="mondaySelectedTime[i]?.length > 1"
                    [ngClass]="{'btn-disable': isDisabled(i, 'monday')}" [disabled]="isDisabled(i, 'monday')"
                    (click)="deleteTime(i, 'monday')"><i class="fas fa-minus"></i></button>
                  <button class="copy-time-to-all" [ngClass]="{'btn-copy-disable': isDisabled(i, 'monday')}"
                    [disabled]="isDisabled(i, 'monday')" (click)="onCopyTime(i, 'monday')"><i class="fas fa-copy"> Copy
                      time to all</i></button>
                </div>
              </div>

              <!-- tuesday -->
              <div class="col-12 heading pb-5" style="display: inline-flex;">
                <div class="col-1">
                  <input type="checkbox" formControlName="tuesdayCheckbox" (change)="onDayCheckbox(i)">
                </div>
                <div class="col-2">
                  <label>{{ 'Tuesday' }}</label>
                </div>
                <div class="col-9" id="tuesday-container">
                  <ng-container #containerTuesday
                    *ngFor="let selectedTime of tuesdaySelectedTime[i]; let c = index; let last = last;">
                    <span [ngStyle]="{'display': (last == true)? '': 'block'}">
                      <input type="text" [disabled]="isDisabled(i, 'tuesday')" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, c, selectedTime, 'tuesday', 'start')" [(ngModel)]="selectedTime"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                      -
                      <input type="text" [disabled]="isDisabled(i, 'tuesday')" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, c, tuesdaySelectedEndTime[i][c], 'tuesday', 'end')" [(ngModel)]="tuesdaySelectedEndTime[i][c]"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                    </span>
                  </ng-container>

                  <button class="plus-minus-btn" [ngClass]="{'btn-disable': isDisabled(i, 'tuesday')}"
                    [disabled]="isDisabled(i, 'tuesday')" (click)="addNewTime(i, 'tuesday')"><i
                      class="fas fa-plus"></i></button>
                  <button class="plus-minus-btn" *ngIf="tuesdaySelectedTime[i]?.length > 1"
                    [ngClass]="{'btn-disable': isDisabled(i, 'tuesday')}" [disabled]="isDisabled(i, 'tuesday')"
                    (click)="deleteTime(i, 'tuesday')"><i class="fas fa-minus"></i></button>
                  <button class="copy-time-to-all" [ngClass]="{'btn-copy-disable': isDisabled(i, 'tuesday')}"
                    [disabled]="isDisabled(i, 'tuesday')" (click)="onCopyTime(i, 'tuesday')"><i class="fas fa-copy">
                      Copy time to all</i></button>
                </div>
              </div>

              <!-- wednesday -->
              <div class="col-12 heading pb-5" style="display: inline-flex;">
                <div class="col-1">
                  <input type="checkbox" formControlName="wednesdayCheckbox" (change)="onDayCheckbox(i)">
                </div>
                <div class="col-2">
                  <label>{{ 'Wednesday' }}</label>
                </div>
                <div class="col-9" id="wednesday-container">
                  <ng-container #containerWednesday
                    *ngFor="let selectedTime of wednesdaySelectedTime[i]; let d = index; let last = last;">
                    <span [ngStyle]="{'display': (last == true)? '': 'block'}">
                      <input type="text" [disabled]="isDisabled(i, 'wednesday')" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, d, selectedTime, 'wednesday', 'start')" [(ngModel)]="selectedTime"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                      -
                      <input type="text" [disabled]="isDisabled(i, 'wednesday')" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, d, wednesdaySelectedEndTime[i][d], 'wednesday', 'end')" [(ngModel)]="wednesdaySelectedEndTime[i][d]"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                    </span>
                  </ng-container>

                  <button class="plus-minus-btn" [ngClass]="{'btn-disable': isDisabled(i, 'wednesday')}"
                    [disabled]="isDisabled(i, 'wednesday')" (click)="addNewTime(i, 'wednesday')"><i
                      class="fas fa-plus"></i></button>
                  <button class="plus-minus-btn" *ngIf="wednesdaySelectedTime[i]?.length > 1"
                    [ngClass]="{'btn-disable': isDisabled(i, 'wednesday')}" [disabled]="isDisabled(i, 'wednesday')"
                    (click)="deleteTime(i, 'wednesday')"><i class="fas fa-minus"></i></button>
                  <button class="copy-time-to-all" [ngClass]="{'btn-copy-disable': isDisabled(i, 'wednesday')}"
                    [disabled]="isDisabled(i, 'wednesday')" (click)="onCopyTime(i, 'wednesday')"><i class="fas fa-copy">
                      Copy time to all</i></button>
                </div>
              </div>

              <!-- thursday -->
              <div class="col-12 heading pb-5" style="display: inline-flex;">
                <div class="col-1">
                  <input type="checkbox" formControlName="thursdayCheckbox" (change)="onDayCheckbox(i)">
                </div>
                <div class="col-2">
                  <label>{{ 'Thursday' }}</label>
                </div>
                <div class="col-9" id="thursday-container">
                  <ng-container #containerThursday
                    *ngFor="let selectedTime of thursdaySelectedTime[i]; let e = index; let last = last;">
                    <span [ngStyle]="{'display': (last == true)? '': 'block'}">
                      <input type="text" [disabled]="isDisabled(i, 'thursday')" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, e, selectedTime, 'thursday', 'start')" [(ngModel)]="selectedTime"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                      -
                      <input type="text" [disabled]="isDisabled(i, 'thursday')" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, e, thursdaySelectedEndTime[i][e], 'thursday', 'end')" [(ngModel)]="thursdaySelectedEndTime[i][e]"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                    </span>
                  </ng-container>

                  <button class="plus-minus-btn" [ngClass]="{'btn-disable': isDisabled(i, 'thursday')}"
                    [disabled]="isDisabled(i, 'thursday')" (click)="addNewTime(i, 'thursday')"><i
                      class="fas fa-plus"></i></button>
                  <button class="plus-minus-btn" *ngIf="thursdaySelectedTime[i]?.length > 1"
                    [ngClass]="{'btn-disable': isDisabled(i, 'thursday')}" [disabled]="isDisabled(i, 'thursday')"
                    (click)="deleteTime(i, 'thursday')"><i class="fas fa-minus"></i></button>
                  <button class="copy-time-to-all" [ngClass]="{'btn-copy-disable': isDisabled(i, 'thursday')}"
                    [disabled]="isDisabled(i, 'thursday')" (click)="onCopyTime(i, 'thursday')"><i class="fas fa-copy">
                      Copy time to all</i></button>
                </div>
              </div>

              <!-- friday -->
              <div class="col-12 heading pb-5" style="display: inline-flex;">
                <div class="col-1">
                  <input type="checkbox" formControlName="fridayCheckbox" (change)="onDayCheckbox(i)">
                </div>
                <div class="col-2">
                  <label>{{ 'Friday' }}</label>
                </div>
                <div class="col-9" id="friday-container">
                  <ng-container #containerFriday
                    *ngFor="let selectedTime of fridaySelectedTime[i]; let f = index; let last = last;">
                    <span [ngStyle]="{'display': (last == true)? '': 'block'}">
                      <input type="text" [disabled]="isDisabled(i, 'friday')" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, f, selectedTime, 'friday', 'start')" [(ngModel)]="selectedTime"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                      -
                      <input type="text" [disabled]="isDisabled(i, 'friday')" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, f, fridaySelectedEndTime[i][f], 'friday', 'end')" [(ngModel)]="fridaySelectedEndTime[i][f]"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                    </span>
                  </ng-container>

                  <button class="plus-minus-btn" [ngClass]="{'btn-disable': isDisabled(i, 'friday')}"
                    [disabled]="isDisabled(i, 'friday')" (click)="addNewTime(i, 'friday')"><i
                      class="fas fa-plus"></i></button>
                  <button class="plus-minus-btn" *ngIf="fridaySelectedTime[i]?.length > 1"
                    [ngClass]="{'btn-disable': isDisabled(i, 'friday')}" [disabled]="isDisabled(i, 'friday')"
                    (click)="deleteTime(i, 'friday')"><i class="fas fa-minus"></i></button>
                  <button class="copy-time-to-all" [ngClass]="{'btn-copy-disable': isDisabled(i, 'friday')}"
                    [disabled]="isDisabled(i, 'friday')" (click)="onCopyTime(i, 'friday')"><i class="fas fa-copy"> Copy
                      time to all</i></button>
                </div>
              </div>

              <!-- saturday -->
              <div class="col-12 heading pb-5" style="display: inline-flex;">
                <div class="col-1">
                  <input type="checkbox" formControlName="saturdayCheckbox" (change)="onDayCheckbox(i)">
                </div>
                <div class="col-2">
                  <label>{{ 'Saturday' }}</label>
                </div>
                <div class="col-9" id="saturday-container">
                  <ng-container #containerSaturday
                    *ngFor="let selectedTime of saturdaySelectedTime[i]; let g = index; let last = last;">
                    <span [ngStyle]="{'display': (last == true)? '': 'block'}">
                      <input type="text" [disabled]="isDisabled(i, 'saturday')" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, g, selectedTime, 'saturday', 'start')" [(ngModel)]="selectedTime"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                      -
                      <input type="text" [disabled]="isDisabled(i, 'saturday')" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, g, saturdaySelectedEndTime[i][g], 'saturday', 'end')" [(ngModel)]="saturdaySelectedEndTime[i][g]"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                    </span>
                  </ng-container>

                  <button class="plus-minus-btn" [ngClass]="{'btn-disable': isDisabled(i, 'saturday')}"
                    [disabled]="isDisabled(i, 'saturday')" (click)="addNewTime(i, 'saturday')"><i
                      class="fas fa-plus"></i></button>
                  <button class="plus-minus-btn" *ngIf="saturdaySelectedTime[i]?.length > 1"
                    [ngClass]="{'btn-disable': isDisabled(i, 'saturday')}" [disabled]="isDisabled(i, 'saturday')"
                    (click)="deleteTime(i, 'saturday')"><i class="fas fa-minus"></i></button>
                  <button class="copy-time-to-all" [ngClass]="{'btn-copy-disable': isDisabled(i, 'saturday')}"
                    [disabled]="isDisabled(i, 'saturday')" (click)="onCopyTime(i, 'saturday')"><i class="fas fa-copy">
                      Copy time to all</i></button>
                </div>
              </div>

              <!-- sunday -->
              <div class="col-12 heading pb-5" style="display: inline-flex;">
                <div class="col-1">
                  <input type="checkbox" formControlName="sundayCheckbox" (change)="onDayCheckbox(i)">
                </div>
                <div class="col-2">
                  <label>{{ 'Sunday' }}</label>
                </div>
                <div class="col-9" id="sunday-container">
                  <ng-container #containerSunday
                    *ngFor="let selectedTime of sundaySelectedTime[i]; let h = index; let last = last;">
                    <span [ngStyle]="{'display': (last == true)? '': 'block'}">
                      <input type="text" [disabled]="isDisabled(i, 'sunday')" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, h, selectedTime, 'sunday', 'start')" [(ngModel)]="selectedTime"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                      -
                      <input type="text" [disabled]="isDisabled(i, 'sunday')" class="form-control DatePickerInput"
                        placeholder="Search" [timePicker]="true" [locale]="{format: 'HH:mm'}" [timePicker24Hour]="true"
                        drops="down" opens="left" ngxDaterangepickerMd
                        (change)="updateSelectedTime(i, h, sundaySelectedEndTime[i][h], 'sunday', 'end')" [(ngModel)]="sundaySelectedEndTime[i][h]"
                        [ngModelOptions]="{standalone: true}" [singleDatePicker]="true" />
                    </span>
                  </ng-container>

                  <button class="plus-minus-btn" [ngClass]="{'btn-disable': isDisabled(i, 'sunday')}"
                    [disabled]="isDisabled(i, 'sunday')" (click)="addNewTime(i, 'sunday')"><i
                      class="fas fa-plus"></i></button>
                  <button class="plus-minus-btn" *ngIf="sundaySelectedTime[i]?.length > 1"
                    [ngClass]="{'btn-disable': isDisabled(i, 'sunday')}" [disabled]="isDisabled(i, 'sunday')"
                    (click)="deleteTime(i, 'sunday')"><i class="fas fa-minus"></i></button>
                  <button class="copy-time-to-all" [ngClass]="{'btn-copy-disable': isDisabled(i, 'sunday')}"
                    [disabled]="isDisabled(i, 'sunday')" (click)="onCopyTime(i, 'sunday')"><i class="fas fa-copy"> Copy
                      time to all</i></button>
                </div>
              </div>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()">
          <i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"
          #focusfield></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
