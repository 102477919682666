import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AllLeadsDataService } from '../../services/all-leads-data.service';
import { AuthHttpService } from '@core/services/auth-http.service';
import { TranslateService } from '@ngx-translate/core';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import * as moment from 'moment-timezone';

@Component({
  templateUrl: './reassign-lead.component.html',
  styleUrls: ['./reassign-lead.component.scss']
})
export class ReassignLeadsDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  form: FormGroup;
  dropdown= {
    merchantSites: [],
  };
  currentStep = 1;

  telemarketerDropdownList = [];

  fromTelemarketerDropdownSettings = {};
  fromTelemarketerSelectedItems = [];
  fromTelemarketerDropdownList = [];

  toTelemarketerDropdownSettings = {};
  toTelemarketerSelectedItems = [];
  toTelemarketerDropdownList = [];

  leadsDropdownSettings = {};
  leadsSelectedItems = [];
  leadsDropdownList = [];

  refreshStatus: boolean;
  buttonLoading: boolean;

  private subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<ReassignLeadsDialogComponent>,
    private allLeadsDataService: AllLeadsDataService,
    private dropdownService: DropdownHttpService,
    private cdr: ChangeDetectorRef,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private translateService: TranslateService,
    private transactionHttpService: TransactionHttpService,
  ) { }

  ngOnInit() {
    this.formInit();

    this.allLeadsDataService.getTelemarketerList(``).subscribe(res => {
      this.telemarketerDropdownList = res;
      this.fromTelemarketerDropdownList = this.telemarketerDropdownList.filter((value, index, self) => 
        self.findIndex(v => v.id === value.id) === index
      );

      this.fromTelemarketerDropdownList.sort((a, b) => a.username.localeCompare(b.username));

      this.form.patchValue({ 
        telemarketer_id: null,
        from_telemarketer_id: null,
        to_telemarketer_id: null,
        lead_ids: null,
      });
    });

    this.fromTelemarketerDropdownSettings = {
      singleSelection: true,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'username',
      lazyLoading: false,
      noDataLabel: '',
      showCheckbox: false
    };

    this.toTelemarketerDropdownSettings = {
      singleSelection: true,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'username',
      lazyLoading: false,
      noDataLabel: '',
      showCheckbox: false
    };

    this.leadsDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'labelKey',
      lazyLoading: false,
      noDataLabel: '',
      showCheckbox: false
    };

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }
  
  onSubmit() {
    this.buttonLoading = true;

    const data = {
      ...this.form.value
    };

    Object.keys(data).forEach((key) => {
      if (key == 'telemarketer_id') {
        delete data[key];
      }
    });
    
    this.subscription = this.allLeadsDataService.reassignLead(data).pipe(
      tap(() => {
        this.form.setErrors(null);
        this.dialogRef.close(true);
      }),
      catchError((error) => {
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    this.form = new FormGroup({
      telemarketer_id: new FormControl(null, [Validators.required]),
      from_telemarketer_id: new FormControl(null, [Validators.required]),
      to_telemarketer_id: new FormControl(null, [Validators.required]),
      lead_ids: new FormControl(null, [Validators.required]),
    });

    this.form.controls['telemarketer_id'].valueChanges.subscribe((value) =>{
      if (value) {
        this.form.patchValue({ 
          from_telemarketer_id: value,
          to_telemarketer_id: null,
        });
        
        this.allLeadsDataService.getTelemarketerUnusedLeads(value).subscribe(res => {
          this.toTelemarketerDropdownList = res['available_telemarketers'].sort((a, b) => a.username - b.username);
          this.leadsDropdownList = res['available_leads'];
          this.leadsDropdownList.map(function (elm) {
            if (elm.name != null) {
              elm['labelKey'] = elm.name + ' (' + elm.id + ')';
            } else {
              elm['labelKey'] = '(' + elm.id + ')';
            }
          });
        });

        this.toTelemarketerSelectedItems = [];
        this.leadsSelectedItems = [];
      }
      else {
        this.form.patchValue({ 
          from_telemarketer_id: null,
        });
        
        this.clearDropdownList();
      }
    });
  }

  clearDropdownList() {
    this.toTelemarketerDropdownList = [];
    this.leadsDropdownList = [];

    this.toTelemarketerSelectedItems = [];
    this.leadsSelectedItems = [];
  }

  onNext() {
    this.currentStep++;
  }

  onBack() {
    this.currentStep--;
  }

  isDisabled() {
    switch(this.currentStep) {
      case 1:
        return this.form.value.from_telemarketer_id && this.form.value.to_telemarketer_id ? false : true;
      case 2:
        return this.form.value.lead_ids && this.form.value.lead_ids.length > 0 ? (this.buttonLoading ? true : false) : true;
    }
  }
}
