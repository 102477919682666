<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="align-items-center" [formGroup]="form">
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Merchant Bank:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <kt-dropdown-wo-lazyload [form]='form' [dropdownList]='bankaccountDropdownList' [dropdownSettings]='bankaccountDropdownSettings' [formName]="'merchant_bank_id'" [selectionAttributes]="'id'" [selectedItems]='bankaccountSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row mb-3">
                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Merchant Bank Account</th>
                                    <th class="text-center">Stage</th>
                                    <th class="text-center">Status</th>
                                    <th>Last Run</th>
                                    <th class="date-column">Created By</th>
                                    <th class="date-column">Updated By</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of botStatus$ | async">
                                    <td>{{ row.id }} </td>
                                    <td>{{ row.bank_account }}</td>
                                    <td class="text-center">
                                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide bot-stage " [ngClass]="'bot-stage-' + row.stage_name"> {{ row.stage_name }}</span>
                                    </td>
                                    <td class="text-center">
                                        <!-- <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide bot-switch" [ngClass]="'bot-switch-' + row.switch">{{ row.switch == 0 ? 'Disabled' : 'Enabled' }}</span> -->
                                        <div class="btn-group dropdown">
                                            <button type="button" class="btn btn-pill btn-sm dropdown-base" [ngClass]="'kt-badge--' + (row.switch === 2 ? '11' : row.switch)"> {{ statuses[row.switch] }} </button>
                                            <ng-container *ngIf="canUpdateBankBotStatus">
                                              <button type="button" class="btn btn-pill btn-sm dropdown-toggle" [ngClass]="'kt-badge--' + (row.switch === 2 ? '11' : row.switch)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                  <i class="fa fa-angle-down"></i>
                                              </button>
                                              <div class="dropdown-menu">
                                                  <ng-container *ngFor="let status of statuses; let key = index">
                                                      <button class="dropdown-item" (click)="updateSwitch(row, key)" type="button">{{ statuses[key] }}</button>
                                                  </ng-container>
                                              </div>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td>{{ row.last_run }}</td>
                                    <td>
                                        System<br/>
                                        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        System<br/>
                                        <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        <button *ngIf="canEnterTAC" type="button" class="btn btn-primary btn-sm" [disabled]="row.tac != 1" (click)="updateTAC(row.bank_account_id)">Enter TAC</button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>
