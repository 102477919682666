import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';;
import { tap, finalize } from 'rxjs/operators';
import { PromotionCodeDataService } from '../../../promotion-codes/services/promotion-code-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DropdownHttpService } from '@core/services/dropdown-http.service';

@Component({
  templateUrl: './promotion-content-member-eligible.component.html',
  styleUrls: ['./promotion-content-member-eligible.component.scss']
})
export class PromotionContentMemberEligibleDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = new Subject<any[]>();
  hasError: boolean;
  subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  showDetails = false;

  dropdownSettings = {
    singleSelection: true,
    text: this.translateService.instant('Please Select'),
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'id',
    labelKey: 'username',
    maxHeight: 200,
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false
  };

  gameProviderDropdownSettings = {
    singleSelection: true,
    text: this.translateService.instant('Please Select'),
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 200, //'auto',
    primaryKey: 'code',
    labelKey: 'name',
    noDataLabel: '',
    showCheckbox: false
  };

  gameProviderDropdownList = [];
  gameProviderSelectedItems = [];
  gameProviderSpinnerLoading = false;

  message = null;
  eligibleCondition = [];
  eligibleDetail = [];
  preRequisites = [];
  importantNote = false;

  constructor(
    public dialogRef: MatDialogRef<PromotionContentMemberEligibleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { promotionContent: any},
    private translateService: TranslateService,
    private promotionCodeDataService: PromotionCodeDataService,
    private cdr: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.onGetGameProviders();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }


  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    this.form = new FormGroup({
      member_account_id: new FormControl(null, [Validators.required]),
      game_provider_code: new FormControl(null)
    });

    this.form.get('member_account_id').valueChanges.subscribe(res =>
      this.importantNote = false,
    );

    this.form.get('game_provider_code').valueChanges.subscribe((value) => {
      this.importantNote = false,
      this.message = null,
      this.eligibleCondition = [],
      this.eligibleDetail = [],
      this.preRequisites = [],
      this.cdr.detectChanges()
    });
  }

  onCheckEligible() {
    this.buttonLoading = true;

    var game_provider_code = this.form.value.game_provider_code != null ? this.form.value.game_provider_code : '';
    this.form.value.game_provider_code == null ? this.importantNote = true : this.importantNote = false;

    this.promotionCodeDataService.checkEligibility(this.data.promotionContent.promotion_id, this.form.value.member_account_id, 0, game_provider_code).subscribe(res => {
      this.message = res.message;
      this.eligibleCondition = res.data.rows['types'];
      this.eligibleDetail = res.data.rows['details'];
      this.preRequisites = res.data.rows['pre_requisites'];
      this.buttonLoading = false;
      this.cdr.detectChanges();
    });
  }

  replaceLineBreaks(data: string) {
    return data.replace('<br>', '\n');
  }

  expandDetails() {
    this.showDetails = this.showDetails == false ? true : false;
  }

  onSelectedMemberChanged(value) {
    if (value.length > 0) {
      if (value[0].id !== this.form.value.member_account_id) {
        this.form.patchValue({
          member_account_id: value[0].id
        })

        this.message = null;
        this.eligibleCondition = [];
        this.eligibleDetail = [];
        this.preRequisites = [];
        this.cdr.detectChanges();
      }
    }
    else {
      this.form.patchValue({
        member_account_id: null
      })

      this.message = null;
      this.eligibleCondition = [];
      this.eligibleDetail = [];
      this.preRequisites = [];
      this.cdr.detectChanges();
    }
  }

  onGetGameProviders() {
    this.gameProviderSpinnerLoading = true;
    this.form.patchValue({ game_provider_code: null });

    this.dropdownHttpService.gameProviders.pipe(
      finalize(() => this.gameProviderSpinnerLoading = false),
      tap(res => {
        this.gameProviderDropdownList = [];
        Object.keys(res).map(key => {
          this.gameProviderDropdownList.push(res[key]);
        });
      })
    ).subscribe();
  }
}
