import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ProviderPermissionsDataService } from '../service/providers-permissions-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Observable, Subscription, of } from 'rxjs';
import { SmsProviderPermissions } from '@core/models/sms-provider-permissions.model';
import { catchError, map, tap } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { Status } from '@core/enums/status.enum';
import { ProviderPermissionsEditDialogComponent } from '../dialogs/provider-permissions-edit/provider-permissions-edit.component';
import Swal from 'sweetalert2';
import { ProvidersDataService } from '../service/providers-data.service';
import { Dropdown } from '@core/models/dropdown.model';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'app-provider-permissions',
  templateUrl: './provider-permissions.component.html',
  styleUrls: ['./provider-permissions.component.scss']
})
export class ProviderPermissionsComponent implements OnInit, OnDestroy {
  
  @Input()
  regions: Dropdown [];

  form: FormGroup;
  loading = false;
  searchBtnLoading = false;
  clearBtnLoading = false;
  permissions$: Observable<SmsProviderPermissions[]>;
  dataLength: number;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dropdown = {
    status: this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage
  };
  status = Status;
  isAdmin: boolean = JSON.parse(localStorage.getItem('user_data')).is_admin;
  pagination: Pagination;
  messages$ = this.providerPermissionsDataService.messages$;

  // permissions
  canEditVendor: boolean;
  canUpdateSMSProviderStatus: boolean;

  private subscriptions = new Subscription();
  
  constructor(
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
    private providerPermissionsDataService: ProviderPermissionsDataService,
    private dropdownHttpService: DropdownHttpService,
    private providersDataService: ProvidersDataService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.pagination = this.providerPermissionsDataService.pagination;
    this.onSubmit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditVendor = appPermissions.edit_vendor;
      this.canUpdateSMSProviderStatus = appPermissions.update_sms_provider_status;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        this.permissions$ = this.providerPermissionsDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.providerPermissionsDataService.pagination;
            this.loadingBar.complete();
          }),
          catchError((error) => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = undefined;
            this.loadingBar.complete();
            throw error;
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onOpenDialog(mode: string, id: number) {
    if (id) {
      this.providerPermissionsDataService.getById(id).subscribe(res => {
        this.openDialogBy(ProviderPermissionsEditDialogComponent, { mode: mode, id: id, permissions: res[0] })
      })
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.permissions$ = this.providerPermissionsDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.providerPermissionsDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize);
  }

  onChangeStatus(selectedStatus: number, row: any) {
    const data = {
      id: row.id,
      status: selectedStatus
    };
    const text = 'This action will unbound the SMS Provider from the corresponding SMS Type.';
    if (selectedStatus !== row.status) {
      if (row.status == 1) {
        Swal.fire({
          title: 'The SMS Provider is currently in use!',
          html: '<div class="text-center">Do you want to save the changes? <br> <div class="my-3 text-red">' + text + '<div></div>',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Yes',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.updateStatus(data);
          }
        });
      } else {
        this.updateStatus(data);
      }
    }
  }

  private updateStatus(provider: any) {
    this.providersDataService.updateSmsProvider(provider).pipe(
      tap(() => {
        this.onViewPageBy(this.page);
      })
    ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { mode?: string, id: number, permissions?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.onViewPageBy(this.page);
      }
    })
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null) ? fields[key] = formData[key] : key);
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      code: new FormControl(null),
      name: new FormControl(null),
      region: new FormControl('all'),
      status: new FormControl('all')
    });
  }

}
