import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { FooterSection } from '@core/models/footer-section.model';
import { FooterSectionDataService } from './services/footer-section-data.service';
import { FooterSectionEditDialogComponent } from './dialogs/footer-section-edit.component';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import moment from 'moment';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-footer-section',
  templateUrl: './footer-section.component.html',
  styleUrls: ['./footer-section.component.scss']
})
export class FooterSectionComponent implements OnInit {

  messages$ = this.footerSectionDataService.messages$;

  footerSections$: Observable<FooterSection[]>;

  form: FormGroup;
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
    status: this.dropdownHttpService.statuses,
    display_titles: this.dropdownHttpService.display_titles,
    merchant: this.dropdownHttpService.merchantSites,
    section_types:this.dropdownHttpService.footerTypes
  };


  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '7.2';
  sortingConfig = {
    'id': 'desc',
    'name': 'desc',
    'display_title': 'desc',
    'status': 'desc',
    'section_type_id': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc'
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };  

  loading = false;
  searchBtnLoading = false;
  clearBtnLoading = false;
  dataLength = 0;

  // permissions
  canCreateFooterSection: boolean;
  canViewFooterSectionDetails: boolean;
  canEditFooterSection: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private footerSectionDataService: FooterSectionDataService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateFooterSection = appPermissions.create_footer_section;
      this.canViewFooterSectionDetails = appPermissions.view_footer_section_details;
      this.canEditFooterSection = appPermissions.edit_footer_section;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    const data = {
      ...this.form.value,
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.footerSections$ = this.footerSectionDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
      tap(res => {
        this.page = 1;
        this.pagination = this.footerSectionDataService.pagination;
        this.dataLength = res.length;
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.loadingBar.complete();
      })
    );
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.footerSections$ = this.footerSectionDataService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.footerSectionDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.form.patchValue({
      name: null,
      display_title: 'all',
      status: 'all',
      merchant_id: 'all',
      section_type_id: 'all'
    });
    this.onSubmit(true);
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      status: new FormControl('all'),
      display_title: new FormControl('all'),
      merchant_id: new FormControl('all'),
      section_type_id: new FormControl('all')
    });
  }  

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = key === 'start_date' || key === 'end_date' ? moment(formData[key]).utc().format('YYYY-MM-DD HH:mm:ss') : formData[key];
      }
    });
    return fields;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onOpenDialog(type: string, rows?: any) { 
    switch (type) {
      case 'edit':
        const footerSectionData = this.footerSectionDataService.getById(rows.id);
        this.subscription = footerSectionData.pipe(
          tap((res) => {
            this.openDialogBy(FooterSectionEditDialogComponent, { mode: 'edit', rows: res });
          })
        ).subscribe();
        break;
      case 'create':
        this.openDialogBy(FooterSectionEditDialogComponent, { mode: 'create' });
        break;
    }
  }

  private openDialogBy(componentRef: any, data?: { mode?: string, rows?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        rows: data.rows,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true){
        this.reload();
      }
    });
  }

  reload() {
    this.onViewPageBy(this.page);
  }

}
