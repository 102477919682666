<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <!-- Header -->
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create'}} VIP Settings</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>

      <div class="modal-body">
        <div class="col-12 kt-mb-10">
          <strong>General</strong>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Name <span class="text-danger">*</span></label>
          <input type="text" formControlName="name" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row" *ngIf="data.mode === 'edit'">
          <label class="col-4 col-form-label">Status <span class="text-danger">*</span></label>
          <select class="col-8 form-control" formControlName="status">
            <option [value]="null" [disabled]="true">Please Select</option>
            <ng-container *ngFor="let item of dropdown.statuses | keyvalue">
              <option [value]="item.value" *ngIf="!(+item.key >= 0)">{{ item.key }}</option>
            </ng-container>
          </select>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Currency <span class="text-danger">*</span></label>
          <select class="col-8 form-control" formControlName="currency_id">
            <option [value]="null" [disabled]="true">Please Select</option>
            <ng-container *ngFor="let value of dropdown.currencies">
              <option [value]="value.id">{{ value.name }}</option>
            </ng-container>
          </select>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Member Group <span class="text-danger">*</span></label>
          <kt-dropdown-wo-lazyload [form]="form" class="col-8 p-0" [dropdownList]="memberGroupDropdownList" [selectedItems]="memberGroupSelectedItems" [selectionAttributes]="'id'" [formName]="'member_group_id'" [dropdownSettings]="memberGroupDropdownSettings"></kt-dropdown-wo-lazyload>
        </div>
        <div class="col-12 form-group row" *ngIf="form.value.target_amount === null || form.value.target_amount === '' || (data.mode === 'edit' && form.value.ftd)">
          <label class="col-4 col-form-label">First Time Deposit <span class="text-danger">*</span></label>
          <div class="custom-control custom-checkbox col-8 form-control border-0">
            <input type="checkbox" formControlName="ftd" class="custom-control-input" id="ftd" (change)="onFTDTargetAmount('ftd')">
            <label class="custom-control-label" for="ftd"></label>
          </div>
        </div>
        <div class="col-12 form-group row" *ngIf="!form.value.ftd">
          <label class="col-4 col-form-label">Target Amount <span *ngIf="!memberGroupSelectedItems[0]?.trial" class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="target_amount" min="0" class="col-8 form-control" (keyup)="onFTDTargetAmount('target-amount')">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label d-block">Upgrade Bonus</label>
          <div class="col-5" style="padding:0">
            <kt-dropdown-wo-lazyload
              [form] = 'form'
              [dropdownList] = 'promotionCodeDropdownList'
              [dropdownSettings] = 'promotionCodeDropdownSettings'
              [formName] = "'upgrade_promotion_id'"
              [selectionAttributes] = "'id'"
              [selectedItems] = 'upgradeBonusSelectedItems'>
            </kt-dropdown-wo-lazyload>
          </div>
          <a *ngIf="canViewPromotionCodeList" class="btn btn-brand promotion-btn promocode-button" href="/general/promotion-codes" routerLinkActive="active" target="_blank" rel="bookmark">3.2 Promotion Codes</a>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label d-block">Birthday Bonus</label>
          <div class="col-5" style="padding:0">
            <kt-dropdown-wo-lazyload
              [form] = 'form'
              [dropdownList] = 'promotionCodeDropdownList'
              [dropdownSettings] = 'promotionCodeDropdownSettings'
              [formName] = "'birthday_promotion_id'"
              [selectionAttributes] = "'id'"
              [selectedItems] = 'birthdayBonusSelectedItems'>
            </kt-dropdown-wo-lazyload>
          </div>
            <a *ngIf="canViewPromotionCodeList" class="btn btn-brand promotion-btn promocode-button" href="/general/promotion-codes" routerLinkActive="active" target="_blank" rel="bookmark">3.2 Promotion Codes</a>
        </div>

        <div class="col-12 kt-mb-10">
          <strong>CMS</strong>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Days <span class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="days" min="0" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Service Priority <span class="text-danger">*</span></label>
          <input type="text" formControlName="service_priority" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Upgrade Rebates <span class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="reward_bonus" min="0" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Birthday Rebates <span class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="birth_rebate" min="0" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Live Rebates <span class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="lc_rebate" min="0" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Slots Rebates <span class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="sl_rebate" min="0" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Sports Rebates <span class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="sp_rebate" min="0" class="col-8 form-control">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event, true)"></kt-swal-alert>
