import { AffiliateApproval } from '@core/models/affiliate-approval.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AffiliateApprovalDataService } from './../services/affiliate-approval-data.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'kt-affiliate-approval-edit',
  templateUrl: './affiliate-approval-edit.component.html',
  styleUrls: ['./affiliate-approval-edit.component.scss']
})
export class AffiliateApprovalEditDialogComponent implements OnInit , OnDestroy{

  messages$ = this.affiliateApprovalDataService.messages$;
  form: FormGroup;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { affiliate: AffiliateApproval, mode: string },
    public dialogRef: MatDialogRef<AffiliateApprovalEditDialogComponent>,
    private affiliateApprovalDataService: AffiliateApprovalDataService
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(affiliate: AffiliateApproval, mode?: string){
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      id: affiliate ? affiliate.id : null,
      status: mode === 'reject' ? 2 : 1,
      ...this.form.value
    };

    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to ${mode} this affiliate?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.subscription = this.affiliateApprovalDataService.statusUpdate(data).pipe(
          tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          this.refreshStatus = true;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
        ).subscribe();
      }else{
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
      }
    });
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    let approval_remarks = null;
    approval_remarks = this.data.affiliate.approval_remarks === '-' ? null : this.data.affiliate.approval_remarks;

    this.form = new FormGroup({
      approval_remarks: new FormControl(approval_remarks, [Validators.required]),
    });

  }

}
