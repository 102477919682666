import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'kt-game-result',
  templateUrl: './game-result.component.html',
  styleUrls: ['./game-result.component.scss']
})
export class GameResultComponent implements OnInit {

  resultURL: string = undefined;

  loading: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { gfURL: string },
    public dialogRef: MatDialogRef<GameResultComponent>,
    public dialog: MatDialog,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.http.get(this.data.gfURL).subscribe((res: any) => {
      if (res.data?.url) {
        this.resultURL = res.data.url;
      } else {
        this.resultURL = res.data;
      }
      this.loading = false;
    })
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

}
