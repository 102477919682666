<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
          <h5 class="modal-title">Adjust Wallet</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
      </div>
      <div class="modal-body">
          <div>
              <div class="col-12 form-group row">
                  <label class="col-lg-3 col-form-label">Member <span class="text-danger">*</span></label>
                  <div class="col-lg-9">
                    <kt-member-dropdown class="dropdown-maxheight col-8 pl-0 p-0" [form]="form" [dropdownSettings]='dropdownSettings'
                    [formName]="'id'" [selectionAttributes]="'id'" [selectedItems]="selectedItems" [ngClass]="{'is-invalid': checkValidation && form.controls.id.errors }" (selectedItemsChanged)="onSelectMember($event)"></kt-member-dropdown>
                  </div>
              </div>
              <div class="col-12 form-group row">
                  <label class="col-lg-3 control-label">Main Wallet </label> <span class="col-lg-9"> {{ currentWallet | number : '1.2-2' }}</span>
              </div>
              <div class="form-group row" style="display: none;">
                  <label for="amount" class="col-lg-3 col-form-label">Input <span class="text-danger">*</span></label>
                  <div class="col-lg-9">
                      <input type="text" placeholder="0.00" class="form-control" formControlName="amount">
                  </div>
              </div>
              <div class="col-12 form-group row">
                  <label class="col-lg-3 col-form-label">Type <span class="text-danger">*</span></label>
                  <div class="col-lg-9">
                      <div class="kt-radio-list">
                          <label class="kt-radio mr-3" *ngFor="let value of dropdown.transactionType | async">
                            <input type="radio" formControlName="transaction_type_id" [value]="value.id"> {{ value.name }} <span [ngClass]="{'radio-btn is-invalid': checkValidation && form.controls.transaction_type_id.errors }"></span>
                          </label>
                      </div>
                  </div>
              </div>
              <div class="col-12 form-group row" *ngIf="+form.value.transaction_type_id !== 12 && +form.value.transaction_type_id !== 9">
                <label class="col-lg-3 col-form-label">Bank</label>
                <div class="col-lg-9">
                  <kt-dropdown-wo-lazyload
                    [form] = 'form'
                    [dropdownList] = 'bankaccountDropdownList'
                    [dropdownSettings] = 'bankaccountDropdownSettings'
                    [formName] = "'merchant_bank'"
                    [selectionAttributes] = "'id'"
                    [selectedItems] = '[]'
                    [dataLoading]="bankaccountLoading">
                  </kt-dropdown-wo-lazyload>
                </div>
              </div>
              <div class="col-12 form-group row">
                <label class="col-lg-3 col-form-label">Amount <span class="text-danger">*</span></label>
                <div class="col-lg-9">
                    <input type="number" (wheel)="false" min="0" formControlName="amount" class="form-control" ktDecimalDirective [ngClass]="{'is-invalid': checkValidation && form.controls.amount.errors }">
                </div>
              </div>

              <div class="col-12 form-group row">
                <label class="col-lg-3 col-form-label">Remarks <span class="text-danger">*</span></label>
                <div class="col-lg-9">
                    <input type="text" formControlName="remarks" class="form-control" [ngClass]="{'is-invalid': checkValidation && form.controls.remarks.errors }">
                </div>
              </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [buttonLoading]="buttonLoading" (confirmed)="onSave(form.get('id').value, form.value)"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
