import { AllAgentReportSummary } from '@core/models/all-agent-report-summary.model';
import { AllAgentReport } from '@core/models/all-agent-report.model';
import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';

@Injectable()
export class AllAgentReportDataService extends DefaultDataService<AllAgentReport>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  summary: AllAgentReportSummary;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('AllAgentReport', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<AllAgentReport[]> {
    return this.http.get<ApiResponse>('/agent/allreport?paginate=true').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<AllAgentReport[]>{
    return this.http.get<ApiResponse>(`/agent/allreport?paginate=true${pageParam}`).pipe(
      tap(res => {
        this.paginationInit(res);
        this.getSummary(res);
      }),
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if (res){
      this.pagination = res.data.paginations;
    }
  }

  private getSummary(res: any) {
    if (res) {
      return this.summary = res.data.summary;
    }
  }
}
