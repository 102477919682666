import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VipSettingsComponent } from './settings/vip-settings.component';
import { AllVipsComponent } from './all-vips/all-vips.component';
import { VipSettingsEditDialogComponent } from './settings/dialogs/vip-settings-edit.component';
import { VipEditDialogComponent } from './all-vips/dialogs/vip-edit.component';
import { VipMemberLogsComponent } from './vipmemberlogs/vip-member-logs.component';

const routes: Routes = [
  {
    path: 'all-vips',
    component: AllVipsComponent,
    data: { title: '13.1 All VIPs' }
  },
  {
    path: 'settings',
    component: VipSettingsComponent,
    data: { title: '13.2 VIP Settings' }
  },
  {
    path: 'vipmemberlogs',
    component: VipMemberLogsComponent,
    data: { title: '13.3 VIP Member Logs' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  entryComponents: [
    VipSettingsEditDialogComponent,
    VipEditDialogComponent
  ]
})
export class VipRoutingModule { }
