import { AllAgentReport } from '@core/models/all-agent-report.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class AllAgentReportEntityService extends EntityCollectionServiceBase<AllAgentReport> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('AllAgentReport', serviceElementsFactory);
  }
}
