import { Pagination } from './../../../../../../../core/models/pagination.model';
import { tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { MemberPromotionDataService } from './../../../member-promotions/services/member-promotion-data.service';
import { MemberPromotionEntityService } from './../../../member-promotions/services/member-promotion-entity.service';
import { PromotionTransferStatus } from '@core/enums/member-promotion-transfer-status.enum';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import moment from 'moment';
import { MemberPromotion } from '@core/models/member-promotion.model';
import { MemberPromotionHistoryComponent } from '../member-promotion-history/member-promotion-history.component';
import { MatDialog } from '@angular/material/dialog';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-promotion-history',
  templateUrl: './promotion-history.component.html',
  styleUrls: ['./promotion-history.component.scss']
})
export class PromotionHistoryComponent implements OnInit, OnDestroy {

  @Input() member: any;
  memberPromotions$: Observable<MemberPromotion[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  perPageDropdown = this.dropdownHttpService.perPage;
  promotionTransferStatus = PromotionTransferStatus;

  // permissions
  canViewPromotionHistoryLog: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private memberPromotionEntityService: MemberPromotionEntityService,
    private memberPromotionDataService: MemberPromotionDataService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.pagination = this.memberPromotionDataService.pagination;
    this.reload();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewPromotionHistoryLog = appPermissions.view_promotion_history_log;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
   this.subscription.unsubscribe();
   this.subscriptions.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number) {
    pageSize = this.pageSize;
    return this.memberPromotions$ = this.memberPromotionEntityService.getWithQuery(`?member_account_id=${this.member.id}&page=${page}&perPage=${pageSize}`).pipe(
      tap(res => {
        this.pagination = this.memberPromotionDataService.pagination;
      })
    );
  }

  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize);
  }


  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  onShowHistory(promotionId: number) {
    this.dialog.open(MemberPromotionHistoryComponent, {
      data: {
        promotion_id: promotionId,
      },
      width: '1200px',
      autoFocus: false
    });
  }

}
