import { Withdraw } from '@core/models/withdraw.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class WithdrawEntityService extends EntityCollectionServiceBase<Withdraw> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('Withdraw', serviceElementsFactory);
  }
}
