import { MemberPromotionEntityService } from '@views/pages/apps/general/member-promotions/services/member-promotion-entity.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, tap, take } from 'rxjs/operators';

@Injectable()
export class MemberPromotionResolver implements Resolve<boolean> {

    constructor(private memberPromotionEntityService: MemberPromotionEntityService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.memberPromotionEntityService.loaded$.pipe(
          tap(loaded =>  {
            if(!loaded) {
              this.memberPromotionEntityService.getAll().pipe(
                take(1)
              );
            }
          }),
          filter(loaded => !!loaded),
          first()
        );
    }

}
