import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { BankSetting, BankSettingUpdate } from '@core/models/bank-settings.model';

@Injectable()
export class BankSettingsDataService extends DefaultDataService<BankSetting>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  selectedReceipt$ = new BehaviorSubject<BankSetting[]>(null);

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('BankSetting', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<BankSetting[]> {
    return this.http.get<ApiResponse>('/bank').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(bankSetting: BankSetting): Observable<BankSetting>{
    return this.http.post<ApiResponse>(`/bank`, bankSetting).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/bank/${entity.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  updateBank(bankSetting: BankSetting) {
    return this.http.put<ApiResponse>(`/bank/${bankSetting.id}`, bankSetting).pipe(
        tap(res => this.messages$.next(res.message))
    )
  }

  getById(data: any) {
    return this.http.get<ApiResponse>(`/bank/${data.id}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows[0])
    );
  }

  getWithQuery(pageParam: string): Observable<BankSetting[]>{
    return this.http.get<ApiResponse>(`/bank${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  getById_maintenancehours(data: any) {
    return this.http.get<ApiResponse>(`/bank/maintenancehours/${data.id}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  updateBank_maintenancehours(bankSetting: BankSettingUpdate) {
    return this.http.put<ApiResponse>(`/bank/maintenancehours/${bankSetting.id}`, bankSetting).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }

  getVerificationSetting() {
    return this.http.get<ApiResponse>('/verificationsetting').pipe(
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }
}
