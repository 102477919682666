<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Tier Rate Settings</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="kt-section col-12 row d-flex flex-row-reverse mb-3 mt-1">
          <button class="btn btn-brand btn-icon-sm" (click)="addRow()"><i class="fas fa-plus"></i>Add</button>
        </div>      
        <div class="kt-section mb-0">
          <div class="kt-section__content">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped">
                <thead>
                  <tr class="tier-rate-table">
                    <th></th>
                    <th>Max Value</th>
                    <th></th>
                    <th>Active Member</th>
                    <th>Commission Rate (%)</th>
                    <th></th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of data.tier.rows; let i = index;">
                    <td class="align-middle">if W/L Amount >=</td>
                    <td class="align-middle">
                      <div class="kt-form__control">
                        <input type="number" (wheel)="false" class="form-control" [value]="item.max_amount" (keyup)="onGenerate('amount', $event, i, 'edit')">
                      </div>
                    </td>
                    <td class="align-middle">And Active Members >=</td>
                    <td class="align-middle">
                      <div class="kt-form__control">
                        <input type="number" (wheel)="false" class="form-control" [value]="item.active_members" (keyup)="onGenerate('members', $event, i, 'edit')">
                      </div>
                    </td>
                    <td class="align-middle">
                      <div class="kt-form__control">
                        <input type="number" (wheel)="false" class="form-control" [value]="item.commission_rate" (keyup)="onGenerate('rate', $event, i, 'edit')">
                      </div>
                    </td>
                    <td class="align-middle">Else go to next level</td>
                    <td class="text-center">
                      <button type="button" class="btn btn-delete btn-icon-sm" (click)="onDelete(item.id)">
                        <i class="fa fa-times p-0"></i>
                      </button>
                    </td>
                  </tr>
                  <tr *ngFor="let item of newArr; let i = index;">
                    <td class="align-middle">if W/L Amount >=</td>
                    <td class="align-middle">
                      <div class="kt-form__control">
                        <input type="number" (wheel)="false" class="form-control" [value]="item.max_amount" (keyup)="onGenerate('amount', $event, i, 'add')">
                      </div>
                    </td>
                    <td class="align-middle">And Active Members >=</td>
                    <td class="align-middle">
                      <div class="kt-form__control">
                        <input type="number" (wheel)="false" class="form-control" [value]="item.active_members"  (keyup)="onGenerate('members', $event, i, 'add')">
                      </div>
                    </td>
                    <td class="align-middle">
                      <div class="kt-form__control">
                        <input type="number" (wheel)="false" class="form-control" [value]="item.commission_rate" (keyup)="onGenerate('rate', $event, i, 'add')">
                      </div>
                    </td>
                    <td class="align-middle">Else go to next level</td>
                    <td class="text-center">
                      <button type="button" class="btn btn-delete btn-icon-sm" (click)="onDelete(null, i)">
                        <i class="fa fa-times p-0"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody class="text-gray-600 fw-bold" *ngIf="data.tier.rows.length == 0 && newArr.length == 0">
                  <tr>
                    <td colspan="7" style="text-align: center;">No data available</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [buttonLoading]="buttonLoading" [isDisabled]="data.tier.rows.length == 0 && newArr.length == 0" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true)"></kt-swal-alert>