import { BetLimit } from '@core/models/bet-limit.model';
import { WithdrawLimit } from '@core/models/withdraw-limit.model';
import { DepositLimit } from '@core/models/deposit-limit.model';
import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { Group } from '@core/models/group.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { MerchantBank } from '@core/models/merchant-bank.model';
import { MemberGroupSelectAccount } from '@core/models/member-group-select-account.model';

@Injectable()
export class GroupDataService extends DefaultDataService<Group>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  id: any;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('MemberGroup', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<Group[]> {
    return this.http.get<ApiResponse>('/membergroup').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<Group[]>{
    return this.http.get<ApiResponse>(`/membergroup${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getMerchants(pageParam: string): Observable<MemberGroupSelectAccount[]>{
    return this.http.get<ApiResponse>(`/membergroup${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  updateMerchants( merchant: MemberGroupSelectAccount): Observable<MemberGroupSelectAccount[]>{
    this.id = localStorage.getItem('memberId');
    return this.http.put<ApiResponse>(`/membergroup/${this.id}/updateaccounts`, merchant).pipe(
      tap( res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  add(group: Group): Observable<Group>{
    return this.http.post<ApiResponse>(`/membergroup`, group).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/membergroup/${entity.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  getById(id: number): Observable<Group>{
    return this.http.get<ApiResponse>(`/membergroup/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  getWithdrawLimits(id: number): Observable<MerchantBank>{
    return this.http.get<ApiResponse>(`/membergroup/withdrawlimit?member_group_id=${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  updateWithdrawLimit(withdrawLimit: WithdrawLimit) {
    return this.http.put<ApiResponse>(`/membergroup/withdrawlimit`, withdrawLimit);
  }

  getDepositLimits(id: number): Observable<MerchantBank>{
    return this.http.get<ApiResponse>(`/membergroup/depositlimit?member_group_id=${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  updateDepositLimit(depositLimit: DepositLimit) {
    return this.http.put<ApiResponse>(`/membergroup/depositlimit`, depositLimit);
  }

  getBetLimits(id: number){
    return this.http.get<ApiResponse>(`/membergroup/betlimit?member_group_id=${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  updateBetLimit(betLimit: BetLimit){
    return this.http.put<ApiResponse>(`/membergroup/betlimit`, betLimit);
  }

  getRebateSettings(params: string){
    return this.http.get<ApiResponse>(`/membergroup/rebate${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  updateRebateSettings(data: any){
    return this.http.put<ApiResponse>(`/membergroup/rebate`, data);
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
