import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DatePipe } from '@angular/common';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { DropdownHttpService } from "@core/services/dropdown-http.service";
import { MerchantBankHttpService } from "@core/services/merchant-bank-http.service";
import * as moment from 'moment-timezone';
import { Subject, Subscription, forkJoin } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { TransactionHttpService } from "@core/services/transaction-http.service";
import { BankReceiptDataService } from "../../services/bank-receipt-data.service";

@Component({
  selector: "bank-receipt-edit-dialog",
  templateUrl: "./bank-receipt-edit.component.html",
  styleUrls: ["./bank-receipt-edit.component.scss"],
})
export class BankReceiptEditComponent implements OnInit, OnDestroy, AfterViewInit {
  form: FormGroup;
  @ViewChild(OwlDateTimeInputDirective) datePicker: OwlDateTimeInputDirective<any>;

  timezone = JSON.parse(localStorage.getItem('user_data')).timezone
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem("currencies")) === null ? [] : JSON.parse(sessionStorage.getItem("currencies")),
    bankReceiptTypes: [
      { id: 1, name: 'Bot' },
      { id: 2, name: 'Manual' },
    ],
    bankReceiptTransactionTypes: this.dropdownHttpService.bankReceiptTransactionTypes,
  };
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;

  messages$ = this.bankReceiptDataService.messages$; // for duplication confirmation message
  messages2$ = new Subject<any[]>(); // for create/update success
  data$ = this.bankReceiptDataService.data$;

  buttonLoading: boolean;
  bankAccounts: Array<any>;
  filteredBankAccounts: Array<any>;
  bankAccountDropdownSettings = {};
  bankAccountSelectedItems = [];
  bankAccountsLoading = false;
  checkValidation = false;

  currentDate = new Date();

  private datePickerSubscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, bankReceipt: any, bankAccountDropdown: any[] },
    public dialogRef: MatDialogRef<BankReceiptEditComponent>,
    private datePipe: DatePipe,
    private dropdownHttpService: DropdownHttpService,
    private merchantBankHttpService: MerchantBankHttpService,
    private transactionHttpService: TransactionHttpService,
    private bankReceiptDataService: BankReceiptDataService,
  ) {}

  ngOnInit() {
    if (this.dropdown.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe((res) => {
        this.dropdown.currencies = res;
      });
    }

    this.bankAccountDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      // maxHeight: 'auto',
      primaryKey: 'name',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false,
      disabled: this.data.mode === 'edit'
    };

    this.bankAccountsLoading = true;
    this.merchantBankHttpService.getMerchantBanksAccount().subscribe(
      res => {
        this.bankAccounts = this.filteredBankAccounts = res
          .filter(elm => elm.bank_type !== 2)  // exclude "Payment Gateway" type banks
          .map(elm => ({
            ...elm,
            name: elm['bank_code'] + ' - ' + elm['account_name'] + ' - ' + elm['account_number']
          }));
        this.bankAccountsLoading = false;

        if (this.data.mode === 'edit') {
          this.bankAccountSelectedItems = [this.bankAccounts.find(v => v.banksys_id === this.data.bankReceipt.merchant_bank_id)];
        }
      },
    );

    this.formInit();
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'transaction_timestamp'),
    ]).subscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close(true);
  }

  onConfirm(event?: Event) {
    const data = {
      ...this.form.value,
    };

    // need to convert to utc first
    data.transaction_timestamp = moment(data.transaction_timestamp).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');

    this.createOrUpdateReceipt(data);
  }

  onCancelUpdate(event?: Event) {
    this.buttonLoading = false;
    this.checkValidation = false;
  }

  onSelectCurrency(currency_id) {
    this.filteredBankAccounts = this.bankAccounts.filter(elm => elm.currency_id == currency_id);
    this.bankAccountSelectedItems = [];
  }

  createOrUpdateReceipt(data) {
    this.checkValidation = true;

    Object.keys(data).forEach(
      (key) => (data[key] == null || data[key] === "" || data[key].length === 0) && delete data[key]
    );    

    const pipes = [
      tap((res: any) => {
        this.buttonLoading = false;
        this.checkValidation = false;
        this.messages2$.next(res.message);
      }),
      catchError((err) => {
        this.buttonLoading = false;
        throw err
      })
    ];

    if (this.data.mode === 'create') {
      this.bankReceiptDataService.createManualReceipt(data)
        .pipe(...(pipes as []))
        .subscribe();
    } else {
      data = {
        ...data,
        status: this.data.bankReceipt.status // required for Update API
      };

      this.bankReceiptDataService.updateReceipt(this.data.bankReceipt.id, data)
        .pipe(...(pipes as []))
        .subscribe();
    }

  }

  onSave(mode?: string) {
    this.buttonLoading = true;

    const data = {
      ...this.form.value,
    };

    // need to convert to utc first
    data.transaction_timestamp = moment(data.transaction_timestamp).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');

    if (this.data.mode === 'edit') {
      this.createOrUpdateReceipt(data);
    } else {
      this.bankReceiptDataService.checkDuplicateReceipt(data).pipe(
        catchError(err => {
          this.buttonLoading = false;
          throw err
        })
      ).subscribe(res => {
        // got duplicate receipts, wait for duplicated modal response
        if (res.duplicate_transactions?.length !== 0) {
          return;
        }

        this.createOrUpdateReceipt(data);
      });
    }
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.valueChange.pipe(
      map(res => this.datePipe.transform(res, this.transactionHttpService.dateTimeFormat)),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      }),
    );
  }

  private formInit() {
    let currencyId = null;
    let merchant_bank_id = null;
    let type = 2; // default to 2=Manual
    let transaction_type = null;
    let transaction_timestamp = null;
    let desc = null;
    let amount = 0;
    let remarks = null;

    if (this.data.mode === "edit") {
      currencyId = this.dropdown.currencies.find(c => c.name === this.data.bankReceipt.currency)?.id;
      type = this.data.bankReceipt.type;
      merchant_bank_id = this.data.bankReceipt.merchant_bank_id;
      transaction_type = this.data.bankReceipt.transaction_type_value;
      transaction_timestamp = moment(this.data.bankReceipt.bank_time).format('YYYY-MM-DD HH:mm:ss');
      desc = this.data.bankReceipt.desc;
      amount = this.data.bankReceipt.debit != 0 ? this.data.bankReceipt.debit : this.data.bankReceipt.credit;
      remarks = this.data.bankReceipt.remarks;
    }

    this.form = new FormGroup({
      currency_id: new FormControl(currencyId, [Validators.required]),
      merchant_bank_id: new FormControl(merchant_bank_id, [Validators.required]),
      type: new FormControl(type, [Validators.required]),
      transaction_type: new FormControl(transaction_type, [Validators.required]),
      transaction_timestamp: new FormControl(transaction_timestamp, [Validators.required]),
      desc: new FormControl(desc, [Validators.required]),
      amount: new FormControl(amount, [Validators.required, Validators.min(0)]),
      remarks: new FormControl(remarks, this.data.mode === 'edit' ? [Validators.required] : []),
    });
  }
}
