<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filfer -->
            <div class="kt-form kt-form--label-right  kt-margin-b-10">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <!-- Agent -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Agent:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-member-dropdown class="dropdown-maxheight" [form]="form"
                                    [isAgent]="true" [dropdownSettings]='dropdownSettings' [formName]="'agent_username'"
                                    [selectionAttributes]="'username'"></kt-member-dropdown>
                                </div>
                                <!-- Currency -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="currency_id">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <ng-container *ngFor="let value of dropdown.currencies">
                                            <option [value]="value.id">{{ value.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <!-- Date/Time -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <input type="text" class="form-control" placeholder="Search"
                                    (change)="onDateRange($event)" formControlName="defaultDate"
                                    [timePicker]="true" [timePickerSeconds]="true"
                                    [alwaysShowCalendars]="true" [ranges]="ranges"
                                    [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                    [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()"
                                    ngxDaterangepickerMd />
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Timezone' }}:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        #timezone
                                        (click)="onChangeTimezone(timezone.selectedItems)"
                                        [form] = 'form'
                                        [dropdownList] = 'dropdown.timezones'
                                        [dropdownSettings] = 'timezoneDropdownSettings'
                                        [formName] = "'timezone'"
                                        [selectionAttributes] = "'timezone'"
                                        [selectedItems] = 'timezoneSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Action Button -->
            <div class="kt-section col-12 row mb-3">
                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <h5 class="summary">Summary</h5>
                    <div class="table-responsive" [ngClass]="summary$ ? 'summary-table' : ''">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr class="text-top">
                                    <th rowspan="2" class="text-center">Currency</th>
                                    <th colspan="6" class="text-center">Bets</th>
                                    <th rowspan="2" class="text-center">Total Commission</th>
                                </tr>
                                <tr>
                                    <th class="text-center">Total Bet Count</th>
                                    <th class="text-center">Total Bet Amount</th>
                                    <th class="text-center">Player W/L</th>
                                    <th class="text-center">Total Bonus</th>
                                    <th class="text-center">Total Cancelled Bonus</th>
                                    <th class="text-center">Total W/L</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="summary$">
                                <tr>
                                    <td class="text-center">{{ summary$.currency_code }}</td>
                                    <td class="text-right">{{ sanitizeNumericField(summary$.total_bet_count) | number : '1.0-0' }}</td>
                                    <td class="text-right">
                                        {{ sanitizeNumericField(summary$.total_bet_amount) | number : '1.2-2' }}
                                    </td>
                                    <td class="text-right">
                                        {{ sanitizeNumericField(summary$.total_win_lose) | number : '1.2-2' }}
                                    </td>
                                    <td class="text-right">
                                        {{ sanitizeNumericField(summary$.total_bonus) | number : '1.2-2' }}
                                    </td>
                                    <td class="text-right">
                                        {{ sanitizeNumericField(summary$.total_cancelled_bonus) | number : '1.2-2' }}
                                    </td>
                                    <td class="text-right">
                                        {{ sanitizeNumericField(summary$.total_win_lose) | number : '1.2-2' }}
                                    </td>
                                    <td class="text-right">
                                        {{ sanitizeNumericField(summary$.total_commission) | number : '1.2-2' }}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>Agent</th>
                                    <th class="text-center">Currency</th>
                                    <th class="text-center">Clicks</th>
                                    <th>Members </th>
                                    <th class="text-center">Total Deposits</th>
                                    <th class="text-center">Total Withdrawal</th>
                                    <th class="text-center">Valid Turnover</th>
                                    <th class="text-center">W/L</th>
                                    <th class="text-center">Total Bonus</th>
                                    <th class="text-center">Total Cancelled Bonus</th>
                                    <th class="text-center">Total W/L</th>
                                    <th class="text-center">Total Commission</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(agentReport$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td><a class="text-primary font-weight-bold" (click)="onOpenDialog(row)">{{ row.agent }}</a></td>
                                    <td class="text-center">{{ row.currency_code }}</td>
                                    <td class="text-right">{{ row.total_clicks  | number : '1.0-0' }}</td>
                                    <td class="text-right">{{ row.total_members | number : '1.0-0' }}</td>
                                    <td class="text-right">{{ row.deposit_total_amount | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.withdraw_total_amount | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.total_valid_bet_amount | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.player_win_lose | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.total_bonus | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.total_cancelled_bonus | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.total_win_lose | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.total_commission | number : '1.2-2' }}</td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <!-- Pagination -->
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of
                                {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
