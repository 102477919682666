<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__body">
            <form [formGroup]="form">
              <div class="kt-section col-12 mb-3">
                <input id="filter" type="text" formControlName="filter"placeholder="Search" class="form-control col-md-2">
              </div>
              <div class="kt-section col-12 mb-3">
                <button class="btn btn-success btn-icon-sm mr-2" (click)="onSearchNodes()"><i class="fas fa-search"></i>Search</button>
              </div>
              <ejs-treeview #listTreeObj [fields]='field'>
              </ejs-treeview>
            </form>
          </div>
  </div>
</div>
