import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Sites } from '@core/models/sites.model';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Dropdown } from '@core/models/dropdown.model';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IconLibrary } from '@core/models/icon-library.model';

@Injectable()
export class IconLibraryDataService extends DefaultDataService<IconLibrary>{

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('IconLibrary', http, httpUrlGenerator, { root: '' });
  }
  
  getWithQuery(pageParam: string): Observable<IconLibrary[]>{
      return this.http.get<ApiResponse>(`/footericon${pageParam}`).pipe(
        tap(res => this.paginationInit(res)),
        map(res => res.data.rows)
      );
  }

  showFooterIcon(footericonId: number): Observable<IconLibrary[]>{
      return this.http.get<ApiResponse>(`/footericon/${footericonId}`).pipe(
          map(res => res.data.rows[0])
      )
  }
        
  storeFooterIcon(data: IconLibrary) {
    return this.http.post<ApiResponse>(`/footericon/store`, data).pipe(
          tap(res => this.messages$.next(res.message)),
      );
  }

  updateFooterIcon(data: IconLibrary, footericonId: number){
    return this.http.put<ApiResponse>(`/footericon/${footericonId}`, data).pipe(
        tap(res => this.messages$.next(res.message)),
    );
  }

  private paginationInit(res: any) {
      if(res) {
        this.pagination = res.data.paginations;
      }
    }
}