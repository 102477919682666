import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable, Subscription, of, throwError } from "rxjs";
import { catchError, map, tap } from 'rxjs/operators';

import { Pagination } from '@core/models/pagination.model';
import { SiteDomain } from '@core/models/site-domain.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { SiteDomainEditComponent } from './dialog/site-domain-edit.component';
import { SiteDomainDataService } from './services/site-domain-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-site-domain',
  templateUrl: './site-domain.component.html',
  styleUrls: ['./site-domain.component.scss']
})
export class SiteDomainComponent implements OnInit, OnDestroy {

  form: FormGroup;
  siteDomain$: Observable<SiteDomain[]>;
  dataLength: number;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dropdown = {
    type: this.dropdownHttpService.siteDomainTypes,
    deviceType: this.dropdownHttpService.deviceTypes,
    statuses: this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage,
  }

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';

  default_sort_by = 'id';
  default_sort_order = 'asc';

  sortingConfig = {
    'id': 'asc',
    'domain': 'desc',
    'type': 'desc',
    'device_type': 'desc',
    'status': 'desc',
  };
  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order,
  };

  private subscriptions = new Subscription();

  // permissions
  canCreateSiteDomain: boolean;
  canEditSiteDomain: boolean;

  constructor(
    private siteDomainDataService: SiteDomainDataService,
    private loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.onSubmit(true);

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateSiteDomain = appPermissions.create_site_domain;
      this.canEditSiteDomain = appPermissions.edit_site_domain;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        tap((data: any) => {
          this.params = Object.keys(data).length > 0 ? '&' + Object.keys(data).map(key => key + '=' + data[key]).join('&') : '';
          // Reset sorting to deafault
          this.sortingConfig[this.sortingSelection.sort_by] = 'desc';
          this.sortingConfig[this.default_sort_by] = this.default_sort_order;
          this.sortingSelection.sort_by = this.default_sort_by;
          this.sortingSelection.sort_order = this.default_sort_order;
          // Get data
          this.onViewPageBy();
        })
      ).subscribe();
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];

    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      domain: null,
      type: 'all',
      device_type: 'all',
      status: 'all'
    });
    this.onSubmit(true);
  }

  onOpenDialog(mode: string, siteDomain?: SiteDomain) {
    if (mode === 'create') {
      this.openDialogBy(SiteDomainEditComponent, { mode: mode });
    }
    else {
      this.siteDomainDataService.getById(siteDomain.id).subscribe(res => {
        this.openDialogBy(SiteDomainEditComponent, { mode: mode, siteDomain: res});
      });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return (this.siteDomain$ = this.siteDomainDataService
      .getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`)
      .pipe(
        tap((res) => {
          this.dataLength = res.length;
          this.pagination = this.siteDomainDataService.pagination;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.loadingBar.complete();
          this.loading = false;
        }),
        catchError((err) => {
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.loadingBar.complete();
          return throwError(err);
        })
    ));
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private openDialogBy(componentRef: any, data: { mode: string, siteDomain?: SiteDomain }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      height: 'auto',
      data: {
        mode: data.mode,
        siteDomain: data.siteDomain
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      domain: new FormControl(null),
      type: new FormControl('all'),
      device_type: new FormControl('all'),
      status: new FormControl('all'),
    });
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

}
