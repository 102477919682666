import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'kt-view-attachment',
  templateUrl: './view-attachment.component.html',
  styleUrls: ['./view-attachment.component.scss']
})
export class ViewAttachmentComponent implements OnInit {
  isPDF: boolean = false;
  imagePreview: any;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ViewAttachmentComponent>,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    if(this.data.data.indexOf('.pdf') >= 0) {
      this.isPDF = true;
      this.imagePreview = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.data);
    } else {
      this.isPDF = false;
      this.imagePreview = this.data.data;
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

}
