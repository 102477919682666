import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { QuickLink } from '@core/models/quick-link.model';

@Injectable()
export class QuickLinkDataService extends DefaultDataService<QuickLink>{

    pagination: Pagination;
    messages$ = new Subject<any[]>();

    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
        super('QuickLink', http, httpUrlGenerator, { root: '' });
    }

    getWithQuery(pageParam: string): Observable<QuickLink[]> {
        return this.http.get<ApiResponse>(`/footerquicklink${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        )
    }

    getById(id: number): Observable<QuickLink> {
        return this.http.get<ApiResponse>(`/footerquicklink/${id}`).pipe(
            map(res => res.data.rows)
        );
    }

    updateQuickLink(id: number, quicklink: any) {
        return this.http.put<ApiResponse>(`/footerquicklink/${id}`, quicklink).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    addQuickLink(quicklink?: QuickLink): Observable<QuickLink>{
        return this.http.post<ApiResponse>(`/footerquicklink/store`, quicklink).pipe(
          tap( res => this.messages$.next(res.message)),
          concatMap((entity) => this.http.get(`/footerquicklink/${entity.data.rows.id}`).pipe(
            map((row: ApiResponse) => row.data.rows)
          ))
        );
      }

    private paginationInit(res: any) {
        if (res) {
            this.pagination = res.data.paginations;
        }
    }

}