import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription, Subject, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { tap, catchError } from 'rxjs/operators';
import { DialogDataService } from '@views/pages/apps/settings/announcements/dialog/services/dialog-data.service';
import { AllAffiliatesDataService } from '../../../../all-affiliates/services/all-affiliates-data.service';
import { AffiliateGroupsDataService } from '../../../services/affiliate-groups-data.service';

@Component({
  templateUrl: './affiliate-groups-dialog-bulk-add.component.html',
  styleUrls: ['./affiliate-groups-dialog-bulk-add.component.scss']
})
export class AffiliateGroupsDialogBulkAddComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = this.affiliateGroupsDataService.messages$;
  subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;

  popupDropdownList = [];
  popupDropdownListSettings: any;
  popupListSelectedItems = [];

  affiliateDropdownList = [];
  affiliateDropdownListSettings: any;
  affiliateListSelectedItems = [];
  filteredAffiliateDropdownList = [];

  constructor(
    public dialogRef: MatDialogRef<AffiliateGroupsDialogBulkAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      mode: string, 
      groups: any,
      dialogPopupVisibilitySettings: { dialog_affiliate_id: number[], dialog_popup_column_visibility: number, dialog_toggle: boolean },
    },
    private dialogDataService: DialogDataService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private affiliateGroupsDataService: AffiliateGroupsDataService,
  ) { }

  ngOnInit() {
    this.popupDropdownListSettings = {
      autoPosition: false,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'labelKey',
      noDataLabel: '',
      showCheckbox: false,
    };

    this.affiliateDropdownListSettings = {
      autoPosition: false,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'username',
      noDataLabel: '',
      showCheckbox: false,
    };

    this.getDropdown();
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event, refresh = false) {
    this.dialogRef.close(refresh);
  }

  getDropdown() {
    this.popupDropdownList = [];
    this.dialogDataService.getWithQuery(`?paginate=false&status=1&always_pop=0&&affiliates_visibility=1&affiliate_group_id=${this.data.groups.id}`).subscribe(res => {
      let contents = [];
      res.map(function (elm) {
        elm.contents.forEach(v => contents.push(v));

        let localeTitle = contents.filter(x => x.title != null && x.popup_id == elm.id);
        localeTitle = localeTitle.sort(function(a, b) { 
          return a.locale_id - b.locale_id;
        });

        if (localeTitle.length > 0) {
          let title = (localeTitle[0]['title'].length > 22) ? localeTitle[0]['title'].slice(0, 22 - 1) + ' . . . ' : localeTitle[0]['title'];
          elm['labelKey'] = elm.code + ' (' + title + ')';
        }
        else {
          elm['labelKey'] = elm.code;
        }
      });

      this.popupDropdownList = this.popupDropdownList.concat(res);
      this.popupDropdownList = this.popupDropdownList.map((x) => {
        return {
          id: x.id,
          labelKey: x.labelKey,
          code: x.code,
          locale_name: x.locale_name.replace(',', ', '),
        }
      });
    });


    this.allAffiliatesDataService.getWithQuery(`?affiliate_group_id=${this.data.groups.id}&paginate=false`).subscribe(res => {
      this.affiliateDropdownList = res;

      if (this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 1) {
        this.affiliateListSelectedItems = this.affiliateDropdownList;
        this.filteredAffiliateDropdownList = this.affiliateDropdownList;
      }
      else {
        this.affiliateListSelectedItems = this.affiliateDropdownList.filter(x => this.data.dialogPopupVisibilitySettings.dialog_affiliate_id.includes(x.id));

        if (this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 2) {
          this.filteredAffiliateDropdownList = this.affiliateDropdownList.filter(x => this.data.dialogPopupVisibilitySettings.dialog_affiliate_id.includes(x.id));
        }
        else {
          this.filteredAffiliateDropdownList = this.affiliateDropdownList;
        }
      }
    });
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    let affiliate_id = this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 1 ? [] : this.data.dialogPopupVisibilitySettings.dialog_affiliate_id;
    this.form = new FormGroup({
      popup_id: new FormControl(null, [Validators.required]),
      is_default: new FormControl(1),
      visibility: new FormControl(this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 1 ? 1 : this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility),
      affiliate_id: new FormControl(affiliate_id),
    });
  }

  onChangePopupDialogDefault(event: any) {
    if (event.target.checked) {
      this.form.patchValue({ 
        visibility: this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility,
        affiliate_id: this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 1 ? [] : this.data.dialogPopupVisibilitySettings.dialog_affiliate_id,
      });
      this.affiliateListSelectedItems = this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 1 ? this.affiliateDropdownList : this.affiliateDropdownList.filter(x => this.data.dialogPopupVisibilitySettings.dialog_affiliate_id.includes(x.id));
    }
    else {
      if (this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 1) {
        this.form.patchValue({ 
          visibility: null,
          affiliate_id: [],
        })
        this.affiliateListSelectedItems = [];
      }
      else {
        this.form.patchValue({ 
          affiliate_id: [],
        })
        this.affiliateListSelectedItems = [];
      }
    }
  }

  onSelectedAffiliateDialogListItems($event: any) {
    if ($event.length == 0) {
      this.affiliateListSelectedItems = [];
    }
    else {
      this.affiliateListSelectedItems = $event;
    }
  }

  onChangePopupDialogVisbility(event: any) {
    if (event.target.value) {
      this.form.patchValue({ 
        visibility: event.target.value,
      });

      if (event.target.value == 1) {
        this.form.patchValue({ 
          affiliate_id: this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 1 ? [] : this.data.dialogPopupVisibilitySettings.dialog_affiliate_id,
        });
  
        this.affiliateListSelectedItems = this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 1 ? this.affiliateDropdownList : this.affiliateListSelectedItems;
        this.filteredAffiliateDropdownList = this.affiliateDropdownList;
      }
      else {
        if (this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility != 1) {
          this.form.patchValue({ 
            affiliate_id: [],
          });
          
          this.affiliateListSelectedItems = [];
    
          if (event.target.value == 3) {
            this.filteredAffiliateDropdownList = this.affiliateDropdownList;
          }
          else if (event.target.value == 2) {
            this.filteredAffiliateDropdownList = this.affiliateDropdownList.filter(x => this.data.dialogPopupVisibilitySettings.dialog_affiliate_id.includes(x.id));
          }
        }
      }
    }
  }

  onSave() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });
    const dialogListData = this.filterFormFields(this.form.value);
    dialogListData['affiliate_group_id'] = this.data.groups.id;

    const dialogPopupVisibilityData = {
      dialog_popup_column_visibility: this.data.dialogPopupVisibilitySettings.dialog_toggle ? +this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility : 0,
    }
    if (this.data.dialogPopupVisibilitySettings.dialog_toggle && +this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility > 1) {
      dialogPopupVisibilityData['affiliate_id'] = this.data.dialogPopupVisibilitySettings.dialog_affiliate_id;
    }

    this.subscription = forkJoin([
      this.affiliateGroupsDataService.createBulkDialogPopupVisibilityList(dialogListData),
      this.affiliateGroupsDataService.updateDialogPopupVisibilitySettings(this.data.groups.id, dialogPopupVisibilityData),
    ]).pipe(
      tap(() => {
        this.buttonLoading = false;
        this.form.setErrors(null);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && key !== 'mode') ? fields[key] = formData[key] : key);
    return fields;
  }
}
