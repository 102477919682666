import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-superuser-cashflow',
  templateUrl: './superuser-cashflow.component.html',
  styleUrls: ['./superuser-cashflow.component.scss']
})
export class SuperuserCashflowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
