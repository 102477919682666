// Service
import { ReferralCommissionDataService } from "./services/referral-commission-data.service";
import { AuthHttpService } from '@core/services/auth-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { MemberDataService } from "../../general/members/services/member-data.service";
import { ReferralCommissionSettingsDataService } from '../referral-commission-settings/services/referral-commission-settings-data.service';
// Angular / Core
import { FormBuilder,  FormGroup, FormControl } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { LoadingBarService } from "@ngx-loading-bar/core";
// Third Party
import * as moment from 'moment-timezone';
import { Pagination } from '@core/models/pagination.model';
import { Observable, of, Subscription } from 'rxjs';
import { tap, map, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import Swal from "sweetalert2";
import { MatDialog } from "@angular/material/dialog";
// models
import { ReferralCommission } from '@core/models/referral-commission.model';
import { ReferralCommissionSetting } from '@core/models/referral-commission-setting.model';
// dialog
import { MemberInformationDialogComponent } from "../../general/members/dialogs/member-information/member-information.component";
import { ReferralCommissionDialogComponent } from "./dialogs/referral-commission-releases/referral-commission-generate.component";
import { ReferralCommissionDetailsComponent } from './dialogs/referral-commission-details.component';

@Component({
  selector: "kt-referral-commission",
  templateUrl: "./referral-commission.component.html",
  styleUrls: ["./referral-commission.component.scss"],
})

export class ReferralCommissionComponent implements OnInit {
  form: FormGroup;
  referralCommission$: Observable<ReferralCommission[]>;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  referralCommission = [];
  dataLength = 0;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  currenciesDropdownList = JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies'));
  dropdown = {
    groups: this.dropdownHttpService.groups,
    perPage: this.dropdownHttpService.perPage,
    types : this.dropdownHttpService.referralCommSettingTypes,
    statuses: [
      {
        name: 'Pending', value: 0, checked: true
      },
      {
        name: 'Released', value: 1, checked: false
      },
      {
        name: 'Rejected', value: 2, checked: false
      },
      {
        name: 'Non-Released', value: 3, checked: false
      }
    ],
  };

  searchStatus = [0];

  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'referral_commission_setting_name': 'desc',
    'member_group_name': 'desc',
    'currency_code': 'desc',
    'start_datetime': 'desc',
    'commission_name': 'desc',
    'total_release_amount': 'desc',
    'exchange_rate': 'desc',
    'status': 'desc',
    'remarks': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
    'downline_tier_1': 'desc',
    'downline_tier_2': 'desc',
    'downline_tier_3': 'desc'
  };

  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '17.1';

  // permissions
  canViewReferralCommission: boolean;
  canGenerateReferralCommission: boolean;
  canReleaseReferralCommission: boolean;
  canRejectReferralCommission: boolean;
  canViewReferralCommissionDetails: boolean;
  canViewMemberDialog: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  
  checkboxForm: FormGroup;
  selectedCommission = [];
  availableCommission = [];
  totalCommissions = 0;
  toggleAll: boolean = false;
  selectedTotalCommissions = 0;
  currentPageTotalCommissions = 0;
  
  referralCommissionSettings = [];
  messages$ = this.referralCommissionDataService.messages$;

  constructor(
    private loadingBar: LoadingBarService,
    private referralCommissionDataService: ReferralCommissionDataService,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
    private authHttpService: AuthHttpService,
    private fb: FormBuilder,
    private memberDataService: MemberDataService,
    public dialog: MatDialog,
    private referralCommissionSettingsDataService: ReferralCommissionSettingsDataService
  ) { }

  ngOnInit() {
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.referralCommissionSettingsDataService.getWithQuery(`?paginate=false`).subscribe((res: any) => {
      this.referralCommissionSettings = res;
    });
    this.pagination = this.referralCommissionDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.formInit();
    this.onSubmit(true);

    const apSub = this.appPermissionService.getAppPermissions().subscribe((appPermissions) => {
      this.canViewReferralCommission = appPermissions.view_referral_commission_releases_list;
      this.canGenerateReferralCommission = appPermissions.generate_referral_commission;
      this.canReleaseReferralCommission = appPermissions.release_referral_commission;
      this.canRejectReferralCommission = appPermissions.reject_referral_commission;
      this.canViewReferralCommissionDetails = appPermissions.referral_commission_view_referral_commission_details; 
      this.canViewMemberDialog = appPermissions.referral_commission_releases_view_member_dialog;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    if (this.form.value.commission_name) {
      this.form.value.commission_name = this.form.value.commission_name.name;
    }
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        this.params = paramsRef.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        this.referralCommission$ = this.referralCommissionDataService.getWithQuery(`?${this.params}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.availableCommission = res;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.referralCommissionDataService.pagination;
            this.selectedTotalCommissions = 0;
            this.currentPageTotalCommissions = this.referralCommissionDataService.currentPageTotal;
            this.totalCommissions = this.referralCommissionDataService.totalAmount;
            this.toggleAll = false;
            this.selectedCommission = [];
            this.checkboxFormInit();
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();

  }

  onClear() {
    this.clearBtnLoading = true;
    this.dropdown.statuses.forEach((child) => {
      if (child.name !== 'Pending') {
        child.checked = false;
      } else {
        child.checked = true;
      }
    });
    this.searchStatus = [0];
    this.form.patchValue({
      id: null,
      username: null,
      member_group_id: 'all',
      settings_currency_id: 'all',
      type: 'all',
      commission_name: null,
      total_release_amount: null,
      defaultDate: null,
      start_datetime: null,
      end_datetime: null,
      date_type: 'calculation_date',
      status: this.searchStatus
    });
    this.onSubmit(true);
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null),
      member_group_id: new FormControl('all'),
      settings_currency_id: new FormControl('all'),
      commission_name: new FormControl(null),
      type: new FormControl('all'),
      total_release_amount: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      date_type: new FormControl('calculation_date'),
      status: new FormControl(this.searchStatus)
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClearDate() {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.searchBtnLoading =  true;
    this.clearBtnLoading = true;
    this.loadingBar.start();

    return this.referralCommission$ = this.referralCommissionDataService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.pagination = this.referralCommissionDataService.pagination;
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.dataLength = res.length;
        this.selectedTotalCommissions = 0;
        this.currentPageTotalCommissions = this.referralCommissionDataService.currentPageTotal;
        this.totalCommissions = this.referralCommissionDataService.totalAmount;
        this.toggleAll = false;
        this.selectedCommission = [];
        this.availableCommission = res;
        this.checkboxFormInit();
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }
  
  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  filterSetting(term: string): ReferralCommissionSetting[] {
    if (this.referralCommissionSettings) {
      return this.referralCommissionSettings.filter(
        setting => setting.name.toLowerCase().includes(term.toLowerCase())
      );
    } else {
      return [];
    }
  }

  searchSetting = (search$: Observable<string>): Observable<ReferralCommissionSetting[]> => {
    return search$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(term => term.trim() === '' ? [] : this.filterSetting(term))
    )
  }

  formatSetting(setting: ReferralCommissionSetting): string {
    return `${setting.name} (${setting.type_name})`;
  }

  onSearchStatus(event: any, id: number, ind: number) {
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }
    this.form.patchValue({ status: this.searchStatus });
  }

  private generateStatusParams() {
    return {
      newStatusParams: Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&'),
      oldStatusParams: 'status=' + Object.keys(this.searchStatus).map(key => this.searchStatus[key]).join(','),
    };
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private checkboxFormInit() {
    let prepareForm = {};
    this.availableCommission.forEach(item => {
      prepareForm = { ...prepareForm, [item.id]: [{ value: false, disabled: item.status != 0 }] }
    });
    this.checkboxForm = this.fb.group(prepareForm);

    this.checkboxForm.valueChanges.subscribe(val => {
      this.selectedCommission = [];
      this.selectedTotalCommissions = 0;
      Object.keys(val).forEach(key => {
        if (val[key]) {
          this.selectedTotalCommissions += +(this.availableCommission.find(x => x.id == key).total_release_amount / this.availableCommission.find(x => x.id == key).exchange_rate).toFixed(2);
          this.selectedCommission.push(key);
        }
      })
    })
  }

  checkboxToggleAll() {
    let patchValue = {};
    if (this.toggleAll) {
      this.availableCommission.forEach(item => {
        if (item.status == 0) {
          patchValue = { ...patchValue, [item.id]: true };
        }
      });
    } else {
      this.availableCommission.forEach(item => {
        patchValue = { ...patchValue, [item.id]: false };
      });
    }
    this.checkboxForm.patchValue(patchValue);
  }

  referralCommissionAction(action: string, mode: string, id?: number,) {
    let message = '';
    let data = {};
    switch (mode) {
      case 'single':
        message = `Do you want to ${action} the referral commissions ?`;
        data = { referral_commission_id: [id] };
        break;
      case 'selected':
        message = `Do you want to ${action} the selected referral commissions below ?`;
        data = { referral_commission_id: this.selectedCommission };
        break;
      case 'all':
        message = `Do you want to ${action} all the referral commissions ?`;
        data = {};
        break;
    }

    Swal.fire({
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (action === 'release') {
          this.referralCommissionDataService.release(data).subscribe();
        } else {
          this.referralCommissionDataService.reject(data).subscribe();
        }
      }
    });
  }

  onOpenDialog(type: string, id?: number, row?: any, level?: any) {
    if (type === 'member-information') {
      this.subscription = this.memberDataService.getById(id, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&additional_info=1`).pipe(
        tap((res) => {
          if (res) {
            this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' }, '1500px');
          }
        })
      ).subscribe();
    } else if (type === 'referral-commission-details') {
      this.openDialogBy(ReferralCommissionDetailsComponent, { details: row, mode: 'referral-commission-details', level: level }, 'auto');
    } else {
      this.openDialogBy(ReferralCommissionDialogComponent, null, {
        width: '800px',
        class: 'generate-rebate-custom-dialog'
      });
    }

  }

  private openDialogBy(componentRef: any, data?: { member?: any, details?: any, mode?: any, level?: number }, options?: any) {
    const dialogRef = this.dialog.open(componentRef, {
      width: options.width,
      panelClass: options.class ? options.class : '',
      data: (data?.mode === 'referral-commission-details') ?
        {
          currency: data.details.currency_code,
          username: data.details.username,
          referral_commission_id: data.details.id,
          member_account_id: data.details.member_account_id,
          referral_commission: data.details,
          tier: data.level
        } : data
    });

    if (data?.mode !== 'member-information') {
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.onViewPageBy(this.page);
        }
      });
    }
  }

  onCloseDialog() {
    this.reload();
  }

  private reload() {
    this.onViewPageBy(this.page);
  }

}