<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form" [formGroup]="form">
      <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Member Verification</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
          </div>
          <div class="modal-body">
              <div>
                  <div class="row profile-photo-wrapper">
                    <img src="/assets/media/users/default.jpg" class="photo" alt="Change photo" />
                  </div>
                  <div class="row">
                      <div class="col-md-6">

                        <div class="col-12 form-group row">
                          <label class="col-4 col-form-label"> Username <span class="text-danger">*</span></label>
                          <fieldset class="col-8" [disabled]="true">
                            <input type="text" class="form-control" formControlName="username" (input)="toLowerCaseInput('username', $event)">
                          </fieldset>
                        </div>

                        <div class="col-12 form-group row">
                          <label class="col-4 col-form-label"> SMS Verified <span class="text-danger">*</span></label>
                          <fieldset class="col-8" [disabled]="true">
                            <input type="text" class="form-control" formControlName="sms_verified">
                          </fieldset>
                        </div>

                      </div>
                      <div class="col-md-6">
                          <div class="col-12 form-group row">
                            <label class="col-4 col-form-label">Name <span class="text-danger">*</span></label>
                            <fieldset class="col-8" [disabled]="true">
                              <input type="text" class="form-control" formControlName="name">
                            </fieldset>
                          </div>

                          <div class="col-12 form-group row">
                            <label class="col-4 col-form-label">Status</label>
                            <fieldset class="col-8" [disabled]="true">
                              <input type="text" class="form-control" formControlName="status_name">
                            </fieldset>
                          </div>

                      </div>
                  </div>

                  <ng-container>
                    <hr>

                    <div class="row">
                    <div class="col-md-6">
                      <div class="col-12 form-group row">
                        <label class="col-4">Verified At</label>
                        <span class="col-8 mt-10"> {{ data.memberVerification.verified_at }} </span>
                    </div>
                    </div>

                    <div class="col-md-6">
                  <div class="col-12 form-group row">
                    <label class="col-4">Verified By</label>
                    <span class="col-8 mt-10"> {{ data.memberVerification.verified_by }} </span>
                  </div>
                    </div>

                    <div class="col-md-6">
                    <div class="col-12 form-group row">
                      <label class="col-4">Created At</label>
                      <span class="col-8 mt-10"> {{ data.memberVerification.created_at }} </span>
                  </div>
                    </div>

                    <div class="col-md-6">
                      <div class="col-12 form-group row">
                        <label class="col-4">Last Login: </label>
                        <span class="col-8 mt-10"> {{ data.memberVerification.last_login }} </span>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="col-12 form-group row">
                       <label class="col-4">Last <br />Login IP: </label>
                       <span class="col-8 mt-10"> {{ data.memberVerification.last_login_ip }} </span>
                      </div>
                    </div>

                  <div class="col-md-6">
                  <div class="col-12 form-group row mt-n3">
                    <label class="col-4 col-form-label">Verification Remarks</label>
                    <input type="text" class="col-8 mt-3 form-control" formControlName="remarks">
                  </div>
                  </div>

                </div>
                  </ng-container>
              </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
            <kt-submit-button [isDisabled]="false" [buttonLoading]="buttonLoading" (confirmed)="onVerify(data.memberVerification)"></kt-submit-button>
          </div>
      </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
