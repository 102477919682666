import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CreditLimit } from '@core/models/credit-limit.model';

@Injectable()
export class CreditLimitHttpService {

  constructor(private http: HttpClient) { }

  getBalance(): Observable<CreditLimit> {
    return this.http.get<ApiResponse>(`/creditlimit/balance`).pipe(
      map(res => res.data.rows)
    );
  }
}

