import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllEventsEditDialogComponent } from '../all-events-edit/all-events-edit.component';
import { EventTeamsEditDialogComponent } from '../event-teams-edit/event-teams-edit.component';
import { AllEvents } from '@core/models/all-events.model';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AllEventsDataService } from '../../services/all-events-data.service';
import { Observable, of, Subscription } from 'rxjs';
import { exhaustMap, map, shareReplay, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AppPermissionService } from '@core/services/app-permission.service';
@Component({
  selector: 'kt-event-teams',
  templateUrl: './event-teams.component.html',
  styleUrls: ['./event-teams.component.scss']
})
export class EventTeamsDialogComponent implements OnInit, OnDestroy {
  redborder: boolean = false;
  form: FormGroup;
  teams$: Observable<AllEvents[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 10;
  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };
  messages$ = this.allEventsDataService.messages$;
  searchBtnLoading = false;
  clearBtnLoading = false;
  loading = false;
  countries = this.dropdownHttpService.allCountries;
  subscription = new Subscription();
  params: any;

  sortingConfig = {
    'team_name': 'asc',
    'team_code': 'desc',
    'position': 'desc',
    'current_standings': 'desc',
    'group_name': 'desc',
    'country_code': 'desc',
  };
  sortingSelection = {
    'sort_by': 'team_name',
    'sort_order': 'asc',
  };

  // permissions
  canCreateTeamPlayer: boolean;
  canEditTeamPlayer: boolean;
  canRemoveTeamPlayer: boolean;
  canUpdatePlayerDisplayStatus: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { row: AllEvents },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AllEventsEditDialogComponent>,
    private allEventsDataService: AllEventsDataService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.reload();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateTeamPlayer = appPermissions.create_team_player;
      this.canEditTeamPlayer = appPermissions.edit_team_player;
      this.canRemoveTeamPlayer = appPermissions.remove_team_player;
      this.canUpdatePlayerDisplayStatus = appPermissions.update_player_display_status;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onCheckboxChange(){
    const checkbox1value = this.form.get('visible_player').value;
    const checkbox2value = this.form.get('hidden_player').value;
    if (checkbox1value || checkbox2value) {
      this.form.get('visible_player').setErrors(null);
      this.form.get('hidden_player').setErrors(null);
    } else {
      this.form.get('visible_player').setErrors({required: true});
      this.form.get('hidden_player').setErrors({required: true});
    }
  }
  onOpenDialog(eventId: number, groupType: number, mode: string, team?: AllEvents) {
    this.dialog.open(EventTeamsEditDialogComponent, {
      width: '800px',
      data: {
        eventId,
        groupType,
        mode,
        team
      }
    }).afterClosed().subscribe(res => this.reload());
  }

  onViewPageBy(page = 1, pageSize?: number) {
    const eventName = this.data.row.name;
    pageSize = this.pageSize;
    const params = this.params ? `&${this.params}` : '&status=1';
    return this.teams$ = this.allEventsDataService.getTeams(`?page=${page}&perPage=${pageSize}&event_name=${eventName}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.pagination = this.allEventsDataService.pagination;
      })
    );
  }

  onDelete(team: any) {
    Swal.fire({
      text: `Are you sure you want to delete ${team.team_name} ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.allEventsDataService.deleteTeam(team.id).subscribe(() => {
          this.messages$ = this.allEventsDataService.messages$;
          this.reload();
        });
      }
    });
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize);
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }

    // Load Data
    this.onViewPageBy(this.page);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    const eventName = this.data.row.name;

    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap(data => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        return this.teams$ = this.allEventsDataService.getTeams(`?event_name=${eventName}&${this.params}&${this.generateSortingParam()}`).pipe(
          shareReplay(),
          tap(res => {
            this.pagination = this.allEventsDataService.pagination;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.page = 1;
          })
        );
      })
    ).subscribe();
  }

  onToggleStatus(item: any){
    var action = item.status === 0 ? "add" : "remove";
    var action2 = item.status === 0 ? "to" : "from";
    var title = `The option is currently in use!`;
    var text = `<p>Do you want to save the changes?</p><p style="color: red">`;
    var footer = `The action will ${action} the team or player ${action2} the prediction list on User Portal.</p>`;

    Swal.fire({
      title: item.status === 1 ? title : "",
      html: text + '' + footer,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        const data = {
          ...item,
          status: item.status == 0 ? 0 : 1
        };

        item.status = item.status == 0 ? 0 : 1;
        this.subscription = this.allEventsDataService.updateTeam(item.id, data).subscribe(() =>
          this.messages$ = this.allEventsDataService.messages$
        );
        this.reload();
      } else {
        item.status = item.status == 0 ? 1 : 0;
      }
    });
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      team_code: null,
      team_name: null,
      group_name: null,
      country_code: null,
      visible_plaer: 1,
      hidden_player: 0
    });
    this.onSubmit(true);
  }

  private filterFormFields(formData: any) {
    const fields = {};
    fields['status'] = (formData.visible_player && formData.hidden_player) || (!formData.visible_player && !formData.hidden_player) ? 'all' : formData.visible_player ? 1 : 0;

    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && key !== 'visible_player' && key !== 'hidden_player' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      team_code: new FormControl(null),
      team_name: new FormControl(null),
      group_name: new FormControl(null),
      country_code: new FormControl(null),
      visible_player: new FormControl(1),
      hidden_player: new FormControl(0)
    });
  }

  private reload() {
    this.onViewPageBy(this.page);
  }

}
