import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOnlineOperator'
})
export class FilterOnlineOperatorPipe implements PipeTransform {

  transform(operators: any[], searchTerm: string): any[] {
    if (!operators || !searchTerm) {
      return operators;
    }
    return operators.filter(operator =>
      operator.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
}
