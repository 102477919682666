import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FaqSectionsDataService } from './../services/faq-sections-data.service';
import { Subscription, forkJoin } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Component({
    selector: 'kt-faq-sections-edit',
    templateUrl: './faq-sections-edit.component.html',
    styleUrls: ['./faq-sections-edit.component.scss']
})
export class FaqSectionsEditDialogComponent implements OnInit {

    form: FormGroup;
    messages$ = this.faqSectionsDataService.messages$;
    buttonLoading = false;
    refreshStatus: boolean;
    private subscription = new Subscription();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { sections: any; mode: string, locales: any },
        public dialogRef: MatDialogRef<FaqSectionsEditDialogComponent>,
        private faqSectionsDataService: FaqSectionsDataService,
    ) { }

    ngOnInit() {
        this.formInit();
    }

    onCloseDialog(event?: Event) {
        this.dialogRef.close();
    }

    onSave(mode: string) {
        // To set "Save" button to disable (To prevent call API in multiple times when double click)
        this.form.setErrors({ 'invalid': true });
        const data = {
            ...this.form.value
        };
        Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
        Object.keys(data.details).forEach((key) => {
            if (data.details[key].section_title === null || data.details[key].section_title === '') {
                delete data.details[key]
            }
        });
        switch (mode) {
            case 'edit':
                this.subscription = forkJoin([
                    this.faqSectionsDataService.updateSections(this.data.sections.id, data).pipe(
                        tap((res: any) => {
                            this.form.setErrors(null);
                            this.buttonLoading = false;
                        }),
                        catchError((error) => {
                            this.buttonLoading = false;
                            this.form.setErrors(null);
                            this.form.enable();
                            throw error;
                        })
                    ),
                    this.faqSectionsDataService.messages$
                ]).subscribe();
                break;
            case 'create':
                this.subscription = forkJoin([
                    this.faqSectionsDataService.addSections(data).pipe(
                        tap((res: any) => {
                            this.form.setErrors(null);
                            this.buttonLoading = false;
                        }),
                        catchError((error) => {
                            this.buttonLoading = false;
                            this.form.setErrors(null);
                            this.form.enable();
                            throw error;
                        })
                    ),
                    this.faqSectionsDataService.messages$
                ]).subscribe();
                break;
        }
        this.refreshStatus = true;
    }

    private formInit() {
        const buildDetails = () => {
            let details = {};
            this.data.locales.map((element, index) => {
                const detailsGroup = new FormGroup({
                    settings_locale_id: new FormControl(element.id),
                    section_title: new FormControl(null),
                });
                details = { ...details, [element.id]: detailsGroup };
            });
            return details;
        };

        this.form = new FormGroup({
            section_title: new FormControl(this.data.mode === 'edit' ? this.data.sections.title : null),
            position: new FormControl(this.data.mode === 'edit' ? this.data.sections.position : null),
            details: new FormGroup(buildDetails())
        });

        if (this.data.mode === 'edit') {
            this.data.sections.locales.map((res) => {
                const editDetails = {
                    settings_locale_id: res.locale_id,
                    section_title: res.section_title
                }
                this.form.patchValue({ details: { [res.locale_id]: editDetails } });
            });
        };
        this.form.markAllAsTouched();
        this.form.markAsDirty();
    }

}


