import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { PromotionApplication } from '@core/models/promotion-application.model';

@Injectable()
export class PromotionApplicationDataService extends DefaultDataService<PromotionApplication> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('PromotionApplication', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<PromotionApplication[]> {
    return this.http.get<ApiResponse>('/promotionapplication').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<PromotionApplication[]>{
    return this.http.get<ApiResponse>(`/promotionapplication${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getById(id: number): Observable<PromotionApplication>{
    return this.http.get<ApiResponse>(`/promotionapplication/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  updateStatus(id: number[], status: number): Observable<PromotionApplication>{
    return this.http.post<ApiResponse>(`/promotionapplication/update`, {
      member_promotion_apply_id: id,
      status
    }).pipe(
      tap( res => this.messages$.next(res.message)),
      map((row: ApiResponse) => row.data.rows)
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }

}
