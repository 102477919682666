import { Pagination } from '@core/models/pagination.model';
import { MiniGame } from '@core/models/mini-games.model';
import { ApiResponse } from '@core/models/api-response.model';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class AssignGameDataService extends DefaultDataService<MiniGame> {
  queryString = window.location.search;
  urlParams: any;
  pagination: Pagination;
  category_code: string;
  game_provider_code: string;
  miniGames = [];

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private route: ActivatedRoute) {
      super('MiniGame', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<MiniGame[]> {
    this.urlParams = new URLSearchParams(window.location.search);
      if(this.urlParams.get('category') === null || this.urlParams.get('provider') === null){
        this.category_code = window.location.pathname.substr(15,2);
        this.game_provider_code = window.location.pathname.substr(27,2);
      }
      else{
        this.category_code = this.urlParams.get('category');
        this.game_provider_code = this.urlParams.get('provider');
      }

    return this.http.get<ApiResponse>(`/gameprovider/getallgamelist?paginate=1&category_code=${this.category_code}&game_provider_code=${this.game_provider_code}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => this.setMiniGame(res))
    );
  }

  getWithQuery(pageParam: string): Observable<MiniGame[]>{
    return this.http.get<ApiResponse>(`/gameprovider/getallselfgamelist${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => this.setMiniGame(res))
    )
  }

  getGameTag(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/game-tags`).pipe(
      map(res => res.data)
    );
  }

  private setMiniGame(res: any) {
    this.miniGames = [];
    if(res && res.success && res.data != null){
      Object.keys(res.data.rows).forEach(key =>  {
          this.miniGames.push(res.data.rows[key]);
      });
    }
    return this.miniGames;
  }

  private paginationInit(res: any) {
    if(res && res.success && res.data != null) {
      this.pagination = res.data.paginations;
    }else{
      this.pagination = undefined;
    }
  }

}
