import { EditorService } from '@core/services/editor.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChildren, QueryList, OnDestroy, AfterViewInit } from '@angular/core';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { Subscription, forkJoin } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { CustomUploadAdapterPlugin } from '@core/ckeditor-upload-adapter/CustomUploadAdapterPlugin';
import { TermsConditionsEntityService } from '../services/terms-conditions-entity.service';
import { TermsConditionsDataService } from '../services/terms-conditions-data.service';
import { TermsConditions } from '@core/models/terms-conditions.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';

@Component({
  selector: 'kt-terms-conditions-edit',
  templateUrl: './terms-conditions-edit.component.html',
  styleUrls: ['./terms-conditions-edit.component.scss']
})

export class TermsConditionsEditDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  // Begin: CKEditor Part
  public editor = Editor;
  editorConfig = this.editorService.config;
  // End: CKEditor Part

  form: FormGroup;
  buttonLoading = false;
  termsConditionsDetails: any;

  dropdown = {
    sitePlatforms: this.dropdownHttpService.sitePlatforms
  };

  private subscription = new Subscription();
  messages$ = this.termsConditionsDataService.messages$;
  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { termsConditions: any; mode: string, locales: any[], position: number, platform_type_id:number },
    public dialogRef: MatDialogRef<TermsConditionsEditDialogComponent>,
    private termsConditionsService: TermsConditionsEntityService,
    private termsConditionsDataService: TermsConditionsDataService,
    private editorService: EditorService,
    private dropdownHttpService: DropdownHttpService,
  ) {
   }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {

  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(termsConditions: TermsConditions, mode?: string) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    const data = {
      id: termsConditions ? termsConditions.id : null,
      ...this.form.value
    };

    Object.keys(data).forEach((mf) => {
      if(mf === 'contents') {
        Object.keys(data[mf]).forEach((sf) => {
          data[mf][sf]['platform_type_id'] = data['platform_type_id'];
          if(
            (data[mf][sf]['title'] === null || data[mf][sf]['title'] === '') &&
            (data[mf][sf]['content'] === null || data[mf][sf]['content'] === '')
          ) {
            delete data[mf][sf];
          }
        })
      }
    });

    switch (mode) {
      case 'edit':
        this.subscription = this.termsConditionsService.update(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
            this.dialogRef.close(true);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.form.enable();
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = this.termsConditionsDataService.add(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res]);
            this.buttonLoading = false;
            this.dialogRef.close(true);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.form.enable();
            throw error;
          })
        ).subscribe();
        break;
    }
  }

  private formInit() {
    const validatorOptions = []; 
    const buildContents = () => {
      let fields = {};
      this.data.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          title: new FormControl(null),
          settings_locale_id: new FormControl(null),
          content: new FormControl(null),
          platform_type_id: new FormControl(null, validatorOptions),
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    // Form
    this.form = new FormGroup({
      contents: new FormGroup(buildContents()),
      position: new FormControl(null, [Validators.required]),
      platform_type_id: new FormControl(null, validatorOptions),
    });

    // Create / Edit
    if(this.data.locales.length >= 1) {

      this.data.locales.forEach((lo: any) => {

        // Create
        if(this.data.mode === 'create' || this.data.mode === 'edit') {
          this.form.patchValue({
            contents: {
              [lo.id]: {
                settings_locale_id: lo.id
              }}
          });
        }

        // Edit (Form content)
        if(this.data.mode === 'edit' && this.data.termsConditions.contents.length >= 1) {
          this.data.termsConditions.contents.forEach((tc: any) => {
            if(lo.id === tc.settings_locale_id) {
              this.form.patchValue({ contents: { [lo.id]: { ...tc }}})
            }
          });
          this.form.patchValue({
            platform_type_id: this.data.platform_type_id ?? null,
            position: this.data.position,
          })
        }

      });

    }
  }

}
