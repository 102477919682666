import { Observable, Subject } from 'rxjs';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { TelemarketerReport } from '@core/models/telemarketer-report.model';

@Injectable()
export class TelemarketerReportDataService extends DefaultDataService<TelemarketerReport> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('TelemarketerReport', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<TelemarketerReport[]>{
    return this.http.get<ApiResponse>(`/report/telemarketerreport${pageParam}`).pipe(
      tap(res => { this.paginationInit(res) }),
      map(res => res.data.rows)
    )
  }

  exportReport(pageParam: string) {
    return this.http.get<ApiResponse>(`/report/exporttelemarketerreport${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }
  
  private paginationInit(res: any) {
    if (res) {
      return (this.pagination = res.data.paginations);
    }
  }

}
