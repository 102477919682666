<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">

            <div class="kt-section col-12 d-flex flex-row-reverse mb-3">
                <button class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>

            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr class="text-top">
                                    <th>Name</th>
                                    <th class="text-center">Position</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(faqSections$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.title }}</td>
                                    <td class="text-center">{{ row.position }}</td>
                                    <td class="text-center">
                                        <button matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)"><i class="fas fa-edit"></i></button>
                                    </td>
                                </tr>
                                <kt-fallback-row [collection]="rows"></kt-fallback-row>
                            </tbody>
                        </table>
                    </div>

                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onReload()"></kt-swal-alert>