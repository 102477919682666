import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ProvidersDataService } from "../../service/providers-data.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SmsProvider } from "@core/models/sms-provider.model";
import { catchError, tap } from "rxjs/operators";

@Component({
  selector: "kt-provider-settings-edit",
  templateUrl: "./provider-settings-edit.component.html",
  styleUrls: ["./provider-settings-edit.component.scss"],
})
export class ProviderSettingsEditDialogComponent implements OnInit {
  form: FormGroup;
  currencyID: number;
  providers: SmsProvider[];
  settings = [
    {
      type_id: 1,
      type: "otp",
      name: "OTP",
      provider_id: 0,
    },
    {
      type_id: 2,
      type: "transaction",
      name: "Transaction",
      provider_id: 0,
    },
    {
      type_id: 3,
      type: "register",
      name: "Register",
      provider_id: 0,
    },
    {
      type_id: 4,
      type: "forgot_password",
      name: "Forgot Password",
      provider_id: 0,
    },
    {
      type_id: 5,
      type: "recovery",
      name: "Recovery",
      provider_id: 0,
    },
    {
      type_id: 6,
      type: "promotional",
      name: "Promotional",
      provider_id: 0,
    },
  ];

  buttonLoading = false;

  messages$ = this.providersDataService.messages$;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { row: any; mode: string; currencies: any; currencyID: any },
    public dialogRef: MatDialogRef<ProviderSettingsEditDialogComponent>,
    private providersDataService: ProvidersDataService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.setProviders();
  }

  onCloseDialog() {
    this.dialogRef.close(true);
  }

  private setProviders() {
    this.providersDataService
      .getProviders(`?currency_id=${this.data.currencyID}`)
      .subscribe((res) => {
        this.providers = res;
        this.formInit();
        this.setSettings();
        this.cd.detectChanges();
      });
  }

  getDropdown(type_id: any) {
    return this.providers.filter(x => x.sms_type.includes(type_id));
  }

  private setSettings() {
    const smsTypeId = this.data.row?.sms_type_id;
    this.settings = this.settings.map((setting) => {
      let info = this.getProviderInfo(smsTypeId[setting.type]);
      let provider_id = info?.id;

      if (!provider_id) {
        return setting;
      }

      return { ...setting, provider_id };
    });
  }

  private formInit() {
    const smsTypeId = this.data.row?.sms_type_id;
    let otp = this.getProviderInfo(smsTypeId.otp);
    let transaction = this.getProviderInfo(smsTypeId.transaction);
    let register = this.getProviderInfo(smsTypeId.register);
    let forgot_password = this.getProviderInfo(smsTypeId.forgot_password);
    let recovery = this.getProviderInfo(smsTypeId.recovery);
    let promotional = this.getProviderInfo(smsTypeId.promotional);

    this.form = new FormGroup({
      otp: new FormControl(otp?.id),
      transaction: new FormControl(transaction?.id),
      register: new FormControl(register?.id),
      forgot_password: new FormControl(forgot_password?.id),
      recovery: new FormControl(recovery?.id),
      promotional: new FormControl(promotional?.id),
    });
  }

  copyOTPProviderToAll() {
    const otpProvider = this.form.value.otp;

    this.form.patchValue({
      transaction: this.validateCopy('transaction', otpProvider),
      register: this.validateCopy('register', otpProvider),
      forgot_password: this.validateCopy('forgot_password', otpProvider),
      recovery: this.validateCopy('recovery', otpProvider),
      promotional: this.validateCopy('promotional', otpProvider),
    });
  }

  onSave() {
    this.buttonLoading = true;

    const data = {
      otp: this.form.value.otp,
      transaction: this.form.value.transaction,
      register: this.form.value.register,
      forgot_password: this.form.value.forgot_password,
      recovery: this.form.value.recovery,
      promotional: this.form.value.promotional,
    };

    if (this.data.mode === "edit") {
      this.providersDataService.updateSettings(this.data.row.id, data).pipe(
        tap(
          (res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          },
          catchError((error) => {
            this.buttonLoading = false;
            throw error;
          })
        )
      ).subscribe();
    }
  }

  private getProviderInfo(id: any) {
    let providers = this.providers.find((provider) => provider.id === id);
    if (providers) {
      return {id: providers.id, sms_type: providers.sms_type};
    }
    return {id: 0, sms_type: null};
  }

  private validateCopy(type: string, provider: any) {
    const setting = this.settings.find((item) => item.type === type);
    const dropdown = setting ? this.getDropdown(setting.type_id) : null;
    const result = dropdown.find((item) => item.id === provider);
    if (result) {
      return provider;
    }

    return 0;
  }
}
