import { AgentTreeView } from '@core/models/agent-tree-view.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class AgentTreeViewEntityService extends EntityCollectionServiceBase<AgentTreeView> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('AgentTreeView', serviceElementsFactory);
  }
}
