<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode === 'create' ? 'Create' : 'Edit'}} FAQ Sections</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 form-group">
                        <label class="control-label"> Name <span class="text-danger">*</span></label>
                        <input type="text" class="col-12 form-control" formControlName="section_title">
                    </div>

                    <div class="col-md-12 form-group">
                        <label class="control-label"> Position <span class="text-danger">*</span></label>
                        <input type="text" class="col-12 form-control" formControlName="position">
                    </div>

                    <div formGroupName="details" class="col-12 p-0">
                        <div class="col-md-12 form-group" *ngFor="let item of data.locales; let i = index" [formGroupName]="item.id">
                            <label class="control-label">{{ item.code }}</label>
                            <input type="text" formControlName="section_title" class="col-12 form-control">
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [isDisabled]="false" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.mode)"></kt-submit-button>
            </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>