import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { SampleMessage } from '@core/models/sample-message.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class SampleMessageDataService extends DefaultDataService<SampleMessage>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  id: any;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('SampleMessage', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<SampleMessage[]> {
    return this.http.get<ApiResponse>('/livechat/samplemessage').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<SampleMessage[]>{
    return this.http.get<ApiResponse>(`/livechat/samplemessage${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(sampleMessage: SampleMessage): Observable<SampleMessage>{
    return this.http.post<ApiResponse>(`/livechat/samplemessage`, sampleMessage).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/livechat/samplemessage/${entity.data.id}`).pipe(
        map((row: ApiResponse) => row.data)
      ))
    );
  }

  getById(id: number): Observable<SampleMessage>{
    return this.http.get<ApiResponse>(`/livechat/samplemessage/${id}`).pipe(
      map(res => res.data)
    );
  }

  updateSampleMessage(sampleMessage: SampleMessage, sampleMessageId: number) {
    return this.http.put<ApiResponse>(`/livechat/samplemessage/${sampleMessageId}`, sampleMessage);
  }

  deleteSampleMessage(sampleMessageId: number) {
    return this.http.delete<ApiResponse>(`/livechat/samplemessage/${sampleMessageId}`).pipe(      
      tap( res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
