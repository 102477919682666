import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription, Subject, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, Form, FormBuilder, FormArray } from '@angular/forms';
import { tap, catchError } from 'rxjs/operators';
import { RewardsHttpService } from '@core/services/rewards-http.service';
import { AllAffiliatesDataService } from '../../../all-affiliates/services/all-affiliates-data.service';
import { AffiliateGroupsDataService } from '../../../affiliate-groups/services/affiliate-groups-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Pagination } from '@core/models/pagination.model';
import { PromotionSettingHttpService } from '@core/services/promotion-setting-http.service';
import { GroupHttpService } from '@core/services/group-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from "sweetalert2";

@Component({
  templateUrl: './promotion-affiliate.component.html',
  styleUrls: ['./promotion-affiliate.component.scss']
})
export class PromotionAffiliateComponent implements OnInit, OnDestroy {

  form: FormGroup;
  searchForm: FormGroup;
  affGroupVisibilityArrayForm: FormGroup;
  
  messages$ = this.affiliateGroupsDataService.messages$;
  subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  loading = false;
  searchBtnLoading = false;
  clearBtnLoading = false;
  dataLength: number;
  pagination: Pagination;
  pageSize = 10;
  page = 1;
  maxSize = 5;
  params = '';

  affGroupDropdownListAll = [];
  affGroupDropdownList = [];

  affiliateDropdownList: any = [];
  affiliateDropdownListSettings: any;
  affiliateSelectedList = [];

  affiliateListDropdownList: any = [];
  affiliateListSelectedItems = [];
  filteredAffiliateDropdownList = [];

  filteredAffGroupDropdownList = [];
  affGroupSelectedItems = [];
  affGroupSearchSelectedItems = [];
  affGroupDropdownSettings = {};

  affGroupVisibility$ = localStorage.getItem('createPromoAffiliateGroups') === null ? [] : JSON.parse(localStorage.getItem('createPromoAffiliateGroups'));

  isOnAddClicked = false;
  checkValidation = false;

  backUpAffGroupArr: any;
  initAffGroup: any;

  dropdown = {
    perPage: this.dropdownHttpService.perPage,
  }

  private affGroupSubscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<PromotionAffiliateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { mode: any, promotion: any },
    private allAffiliatesDataService: AllAffiliatesDataService,
    private affiliateGroupsDataService: AffiliateGroupsDataService,
    private dropdownHttpService: DropdownHttpService,
    private groupHttpService: GroupHttpService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private loadingBar: LoadingBarService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.affGroupDropdownSettings = {
      singleSelection: true,
      text: 'Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false,
    };
    
    this.affiliateDropdownListSettings = {
      autoPosition: true,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'username',
      noDataLabel: '',
      showCheckbox: false,
    };

    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event, refresh = false) {
    if (this.checkupdate()) {
      const result = Swal.fire({
        title: '<div class="text-center">Unsaved Changes</div>',
        html: '<div class="text-center">Do you want to save the changes of affiliate list?</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        allowOutsideClick: false,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('No'),
        confirmButtonText: this.translateService.instant('Yes'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          if (this.data.mode == 'edit') {
            this.onSave();
          }
          else {
            let tempAffGroup = [];
            this.affGroupVisibilityArr.controls.forEach((control, index)=> {
              const affGroupListData = control.value.mode === 'create' ? this.filterFormFields(control.value) : control.value;
              delete affGroupListData['mode'];
              delete affGroupListData['id'];

              if (control.value.promotion_id !== null && control.value.visibility !== null) {
                tempAffGroup.push(affGroupListData);
              }
            });
            
            if (tempAffGroup.length > 0) {
              localStorage.setItem("createPromoAffiliateGroups", JSON.stringify(tempAffGroup));
            }
            else {
              localStorage.removeItem("createPromoAffiliateGroups");
            }
            
            this.dialogRef.close(false);
          }
        } else if (result.isDenied) {
          this.dialogRef.close(refresh);
        }
      });
    }
    else {
      this.dialogRef.close(refresh);
    }
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.searchForm.patchValue({
      affiliate_group_id: null,
    });
    this.affGroupSearchSelectedItems = [];
    this.page = 1;
    this.getAffGroupVisibility(this.page, this.pageSize, this.params, true);
  }

  private formInit() {
    this.loading = true;
    this.allAffiliatesDataService.getWithQuery(`?paginate=false`).subscribe(res => {
      this.affiliateDropdownList = res;
      this.affGroupInit();
    });

    if (this.searchForm == undefined) {
      this.searchForm = new FormGroup({
        affiliate_group_id: new FormControl(null),
      });
    }

    // Promotion Visibility List
    this.affGroupVisibilityArrayForm = this.fb.group({
      affGroupVisibility: new FormArray([]),
    });
  }

  get affGroupVisibilityArr(): FormArray {
    return this.affGroupVisibilityArrayForm.get('affGroupVisibility') as FormArray;
  }

  private affGroupInit() {
    setTimeout(() => {
      this.page = 1;
      this.getAffGroupVisibility(this.page, this.pageSize, this.params);
    }, 250);
  }

  getAffGroupVisibility(page = 1, pageSize?: number, params?: string, clearSearch?: boolean) {
    this.affGroupSubscription.unsubscribe();
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    
    if (this.data.mode == 'edit') {
      this.groupHttpService.getAffiliateGroups(`&promotion_id=${this.data.promotion.id}`).subscribe((res: any) => {
        this.affGroupDropdownListAll = res;
        this.affGroupDropdownList = this.affGroupDropdownListAll.filter(x => x.promotion_id == null || x.promotion_id == '');
          this.resetValue();
          const data = this.filterFormFields(this.searchForm.value);
          data['promotion_id'] = this.data.promotion.id;
          this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
          const parameters = this.params ? `&${this.params}` : '';
          this.affGroupSubscription = this.affiliateGroupsDataService.getPromotionVisibilityList(`?page=${page}&perPage=${this.pageSize}${parameters}`).subscribe(res => {
            this.affGroupVisibility$ = res;
            if (this.affGroupVisibility$.length > 0) {
              this.affGroupVisibility$.map(item => {
                this.onAffGroupAddRow(false, item);
              });
            }
  
            this.assignAffGroupList();
            this.dataLength = res.length;
            this.pagination = this.affiliateGroupsDataService.pagination;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.loading = false;
            this.loadingBar.complete();
            this.isOnAddClicked = false;
            this.updateInitAffGroup();
          });
  
          this.cdr.detectChanges();
      });
    }
    else {
      this.affGroupVisibilityArr.clear();
      this.groupHttpService.getAffiliateGroups().subscribe((res: any) => {
        this.affGroupDropdownListAll = res;
        if (this.affGroupVisibility$.length > 0) {
          this.affGroupVisibility$.map(item => {
            this.onAffGroupAddRow(false, item);
          });
        }
        this.assignAffGroupList();
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.loading = false;
        this.loadingBar.complete();
        this.isOnAddClicked = false;
        this.updateInitAffGroup();
      });
    }
  }

  onAffGroupAddRow(isNew = false, data?: any) {
    this.checkValidation = false;
    let affiliate_group_id = data !== undefined ? data.affiliate_group_id : null;;
    let visibility = data !== undefined ? data.visibility : null;
    let affiliate_id = data !== undefined ? data.affiliate_id : [];

    const form = new FormGroup({
      id: new FormControl(data !== undefined ? data.id : null),
      affiliate_group_id: new FormControl(affiliate_group_id, [Validators.required]),
      visibility: new FormControl(visibility, [Validators.required]),
      affiliate_id: new FormControl(affiliate_id),
      mode: new FormControl(data !== undefined ? 'edit' : 'create')
    });

    if (isNew) {
      this.affGroupVisibilityArr.controls.slice().reverse().forEach((control, index) => {
        const currentGroup = this.affGroupVisibilityArr.controls[index];
        this.affGroupVisibilityArr.insert(index+1, currentGroup)
        this.affGroupVisibilityArr.removeAt(index);
      })

      this.affGroupVisibilityArr.insert(0, form);
    } 
    else {
      this.affGroupVisibilityArr.push(form);
    }
    
    let index = this.affGroupVisibilityArr.length - 1;
    if (data !== undefined) {
      this.affGroupDropdownList.push(this.affGroupDropdownListAll.filter(x => x.id == data.affiliate_group_id)[0]);
      this.affGroupDropdownList.sort(function(a, b) { 
        return - (a.id - b.id);
      });

      this.affiliateListSelectedItems[index] = this.affiliateDropdownList.filter(x => data.affiliate_id.includes(x.id));
      this.affGroupSelectedItems[index] = this.affGroupDropdownList.filter(x => x.id == data.affiliate_group_id);
      this.affGroupVisibilityArr.controls.forEach(control => {
        control.disable();
      });
    } else {
      // this.affGroupDropdownList = this.affGroupDropdownListAll;
      this.affiliateListSelectedItems.unshift([]);
      this.affGroupSelectedItems.unshift([]);
      this.filteredAffGroupDropdownList.unshift([]);
      this.isOnAddClicked = true;
      this.assignAffGroupList();
    }
  }

  assignAffGroupList() {
    let selectedGroup = [];
    selectedGroup = this.affGroupVisibilityArr.controls.filter(function(obj) {
      return obj.value.affiliate_group_id != null;
    }).map(function(obj) { return obj.value.affiliate_group_id; });

    this.affGroupVisibilityArr.controls.forEach((control, index)=> {
      const exist = selectedGroup.indexOf(parseInt(control.value.affiliate_group_id));

      let affGroupIds =  [...selectedGroup];
      if (exist > -1) {
        affGroupIds.splice(exist, 1);
      }
      
      if (control.value.affiliate_group_id != null) {
        if (this.data.mode == 'edit') {
          this.filteredAffGroupDropdownList[index] = this.affGroupDropdownList.filter(x => !affGroupIds.includes(x.id));
          this.affiliateListDropdownList[index] = this.affiliateDropdownList.filter(x => x.affiliate_group_id == control.value.affiliate_group_id);
        }
        else {
          this.filteredAffGroupDropdownList[index] = this.affGroupDropdownListAll.filter(x => !affGroupIds.includes(x.id));
          this.affiliateListDropdownList[index] = this.affGroupDropdownListAll.filter(x => x.affiliate_group_id == control.value.affiliate_group_id);
        }
      }
      else {
        if (this.data.mode == 'edit') {
          this.filteredAffGroupDropdownList[index] = this.affGroupDropdownList.filter(x => !affGroupIds.includes(x.id));
        }
        else {
          this.filteredAffGroupDropdownList[index] = this.affGroupDropdownListAll.filter(x => !affGroupIds.includes(x.id));
        }
        this.affiliateListDropdownList[index] = [];
        this.affiliateListSelectedItems[index] = [];
        control.patchValue({
          affiliate_id: [],
        })
      }
    });
  }

  private resetValue() {
    this.affGroupVisibility$ = [];
    this.affGroupVisibilityArr.clear();
    this.cdr.detectChanges();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && key !== 'mode') ? fields[key] = formData[key] : key);
    return fields;
  }
  
  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.page = 1;
    this.getAffGroupVisibility(this.page, this.pageSize, this.params);
  }

  onViewPageBy(page = 1, pageSize?: number) {
    this.page = page;
    this.pageSize = pageSize;

    this.getAffGroupVisibility(this.page, this.pageSize, this.params);
  }

  onSelectedAffiliatePromotionListItems($event: any, index: any) {
    if ($event.length == 0) {
      this.affiliateListSelectedItems[index] = [];
    }
    else {
      this.affiliateListSelectedItems[index] = $event;
    }
  }

  checkValidationAffiliatePromotionList(i?: any) {
    if (!this.loading) {
      if (i !== undefined) {
        if (this.affiliateListSelectedItems[i].length == 0) {
          return true;
        }
        else {
          return false;
        }
      }
    }
  }

  onChangeAffGroupVisbility(event: any, i: any) {
    if (event.target.value) {
      this.affGroupVisibilityArr.controls[i].patchValue({ 
        visibility: event.target.value,
      });

      if (event.target.value == 1) {
        this.affGroupVisibilityArr.controls[i].patchValue({ 
          affiliate_id: [],
        });
  
        this.affiliateListSelectedItems[i] = [];
      }
    }
    else {
      this.affGroupVisibilityArr.controls[i].patchValue({ 
        visibility: null,
      });
    }
  }

  onSelectedAffGroupListItems(event: any, i: any) {
    if (event.length > 0) {
      this.affGroupVisibilityArr.controls[i].patchValue({ 
        affiliate_group_id: event[0].id,
      });
      this.affiliateListDropdownList[i] = this.affiliateDropdownList.filter(x => x.affiliate_group_id == event[0].id);
      this.affiliateListSelectedItems[i] = [];
    }
    else {
      this.affGroupVisibilityArr.controls[i].patchValue({ 
        visibility: null,
        affiliate_id: [],
      });
      this.affiliateListSelectedItems[i] = [];
      this.affiliateListDropdownList[i] = [];
    }
  }

  enableAffGroupEdit(index: number) {
    this.backUpAffGroupArr = ({...this.affGroupVisibilityArr.controls[index].value});
    this.affGroupVisibilityArr.controls.forEach(control => {
      control.disable();
    });
    this.affGroupVisibilityArr.controls[index].enable();
    this.cdr.detectChanges();
  }

  onDismissAffGroupRow(mode: string, index: number) {
    switch (mode) {
      case 'create':
      case 'duplicate':
        this.affGroupVisibilityArr.removeAt(index);
        this.affGroupSelectedItems.shift();
        this.affiliateListSelectedItems.shift();
        this.filteredAffGroupDropdownList.shift();
        this.filteredAffiliateDropdownList.shift();
        this.isOnAddClicked = false;
        break;
      case 'edit':
        this.affGroupVisibilityArr.controls[index].patchValue({ 
          ...this.backUpAffGroupArr,
        })
        
        if (this.backUpAffGroupArr['visibility'] == 1) {
          this.affiliateListSelectedItems[index] = [];
          this.affGroupVisibilityArr.controls[index].patchValue({ 
            affiliate_id: [],
          })
        }
        else {
          this.affiliateListSelectedItems[index] = this.affiliateDropdownList.filter(x => this.backUpAffGroupArr.affiliate_id.includes(x.id));
        }

        this.affGroupSelectedItems[index] = this.affGroupDropdownList.filter(x => x.id == this.backUpAffGroupArr.affiliate_group_id);
        this.assignAffGroupList();
        this.affGroupVisibilityArr.controls[index].disable();
        break;
    }
    this.cdr.detectChanges();
  }

  validationAffGroupList(i?: any) {
    if (i != undefined) {
      const promotionArr = ({...this.affGroupVisibilityArr.controls[i].value});
      if (promotionArr.promotion_id !== null && promotionArr.visibility !== null) {
          return false;
      }
      else {
        return true;
      }  
    }
    else {
      const statues = this.affGroupVisibilityArr.controls.map(x => x.enabled);
      if (statues.includes(true)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  onAffGroupSave(row: any, index?: number,) {
    let tempAffGroup = [];
    this.checkValidation = true;
    const affGroupListData = row.mode === 'create' ? this.filterFormFields(row) : row;

    if (this.data.mode == 'edit') {
      affGroupListData['promo_type'] = this.data.promotion.promo_type;
      affGroupListData['promotion_id'] = this.data.promotion.id;
      this.subscription = forkJoin([
        this.affiliateGroupsDataService.createUpdatePromotionVisibilityList(row.mode, affGroupListData),
      ]).pipe(
        tap(() => {
        }),
        catchError((error) => {
          throw error;
        })
      ).subscribe(res => {
        this.formInit();
      });
    }
    else {
      this.affGroupVisibilityArr.controls.forEach((control, index)=> {
        const affGroupListData = control.value.mode === 'create' ? this.filterFormFields(control.value) : control.value;
        delete affGroupListData['mode'];
        delete affGroupListData['id'];

        if (control.value.promotion_id !== null && control.value.visibility !== null) {
          tempAffGroup.push(affGroupListData);
        }
      });
      
      if (tempAffGroup.length > 0) {
        localStorage.setItem("createPromoAffiliateGroups", JSON.stringify(tempAffGroup));
        this.affGroupVisibility$ = JSON.parse(localStorage.getItem('createPromoAffiliateGroups'));
      }
      else {
        localStorage.removeItem("createPromoAffiliateGroups");
      }
      
      this.affGroupInit();
    }
    
  }

  onAffGroupDelete(row: any) {
    Swal.fire({
      title: 'System Alert',
      html: '<div>Are you sure you want to delete?<br><p style="color: red">This action will remove the associated promotion from the affiliate campaign settings.</p></div>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
      allowOutsideClick: false
    }).then((response) => {
      if (response.isConfirmed) {
        this.checkValidation = true;
        this.buttonLoading = true;

        if (this.data.mode == 'edit') {
          this.subscription = forkJoin([
            this.affiliateGroupsDataService.deletePromotionVisibilityList(row.id)
          ]).pipe(
            tap(() => {
              this.buttonLoading = false;
              this.getAffGroupVisibility(this.page, this.pageSize, this.params);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              throw error;
            })
          ).subscribe();
        }
        else {
          this.buttonLoading = false;
          this.affGroupVisibility$ = this.affGroupVisibility$.filter(x => x.affiliate_group_id != row.affiliate_group_id);

          if (this.affGroupVisibility$.length > 0) {
            localStorage.setItem("createPromoAffiliateGroups", JSON.stringify(this.affGroupVisibility$));
          }
          else {
            localStorage.removeItem("createPromoAffiliateGroups");
          }

          this.affGroupInit();
        }
      }
    });
  }

  checkupdate() {
    return this.initAffGroup !== JSON.stringify({ ...this.affGroupVisibilityArr.value });
  }

  updateInitAffGroup() {
    this.initAffGroup = JSON.stringify({ ...this.affGroupVisibilityArr.value }) == undefined ? {} : JSON.stringify({ ...this.affGroupVisibilityArr.value });
  }

  onSave() {
    this.affGroupVisibilityArr.controls.forEach((control, index)=> {
      if (control.enabled && !this.validationAffGroupList(index)) {
        const affGroupListData = control.value.mode === 'create' ? this.filterFormFields(control.value) : control.value;
        affGroupListData['promo_type'] = this.data.promotion.promo_type;
        affGroupListData['promotion_id'] = this.data.promotion.id;

        this.subscription = forkJoin([
          this.affiliateGroupsDataService.createUpdatePromotionVisibilityList(control.value.mode, affGroupListData),
        ]).pipe(
          tap(() => {
          }),
          catchError((error) => {
            throw error;
          })
        ).subscribe(res => {
          this.dialogRef.close(false);
        });
      }
      else if (control.enabled && this.validationAffGroupList(index)) {
        this.dialogRef.close(false);
      }
      
      if (index == this.affGroupVisibilityArr.controls.length) {
        this.dialogRef.close(false);
      }
    });
  }
}
