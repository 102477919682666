import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SeoSettingsDomainsDataService } from '../../services/seo-settings-domains-data.service';
import { MetaTagDataService } from '../../services/meta-tag-data.service';

@Component({
  selector: 'kt-meta-tag-settings-duplicate',
  templateUrl: './meta-tag-settings-duplicate.component.html',
  styleUrls: ['./meta-tag-settings-duplicate.component.scss']
})
export class MetaTagSettingsDuplicateComponent implements OnInit {

  domainList = [];
  domainDropdownSettings = {};
  domainSelectedItems: any;
  form: FormGroup;
  isCheckAll = false;
  selectedRoutes = [];
  targetMetatagsSettings: any;
  settingLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { seo_setting_id: any, device_type: any, routes: any },
    public dialogRef: MatDialogRef<MetaTagSettingsDuplicateComponent>,
    private seoSettingsDomainsDataService: SeoSettingsDomainsDataService,
    private metaTagDataService: MetaTagDataService,
  ) { }

  ngOnInit() {
    this.getDomains();

    for (let i = 0; i < this.data.routes.length; i++) {
      this.data.routes[i].checked = false;
    }

    this.formInit();

    this.domainDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'name',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
  }

  getDomains() {
    const data = this.filterFormFields({seo_setting_id: this.data.seo_setting_id});
    const params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = params ? `${params}` : '';
    this.seoSettingsDomainsDataService.getDomains(`?${parameters}`).subscribe(
      res => {
        this.domainList = res;
      }
    );
  }

  async onDomainChange(selectedDomain){
    if(selectedDomain.length <= 0) {
      return;
    }

    this.settingLoading = true;

    // get selected domain's meta tag settings
    this.targetMetatagsSettings = {};
    const field = {
      seo_setting_id: selectedDomain[0].seo_setting_id,
      device_type: this.data.device_type,
      paginate: false,
    }
    const data = this.filterFormFields(field);
    const params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = params ? `${params}` : '';
    await this.metaTagDataService.getWithQuery(`?${parameters}`).toPromise().then(res => {
      if( res ) {
        res.forEach((item: any) => {
          if (!this.targetMetatagsSettings[item.seo_setting_route_id]) {
            this.targetMetatagsSettings[item.seo_setting_route_id] = {};
          }
      
          if (!this.targetMetatagsSettings[item.seo_setting_route_id][item.locale]) {
            this.targetMetatagsSettings[item.seo_setting_route_id][item.locale] = {};
          }
      
          this.targetMetatagsSettings[item.seo_setting_route_id][item.locale] = {
            setting_locale_id: item.setting_locale_id,
            meta_title: item.meta_title,
            meta_desc: item.meta_desc,
            meta_keyword: item.meta_keyword,
            meta_robot: item.meta_robot,
            open_graph_title: item.open_graph_title,
            open_graph_desc: item.open_graph_desc,
            open_graph_url: item.open_graph_url,
            open_graph_type: item.open_graph_type,
            open_graph_img: item.open_graph_img,
          };
        });
      }
    });

    // loop through route and selected meta tag settings and define `is_blank` param
    for(let i = 0; i < this.data.routes.length; i++) {
      this.data.routes[i]['is_blank'] = true;
      if( this.targetMetatagsSettings[this.data.routes[i]['id']] !== undefined ) {
        const locale_keys = Object.keys(this.targetMetatagsSettings[this.data.routes[i]['id']]);
        locale_keys.forEach(locale_key => {
          const locale = this.targetMetatagsSettings[this.data.routes[i]['id']][locale_key];
          if( this.data.routes[i]['is_blank'] === true ) {
            if( locale.meta_title !== null
                || locale.meta_desc !== null
                || locale.meta_keyword !== null
                || locale.meta_robot !== null
                || locale.open_graph_title !== null
                || locale.open_graph_desc !== null
                || locale.open_graph_url !== null
                || locale.open_graph_type != 1
                || locale.open_graph_img !== null
            ) {
              this.data.routes[i]['is_blank'] = false;
            }
          }
        });
      }
    }
    
    this.settingLoading = false;
  }

  private formInit() {
    this.form = new FormGroup({
      target_seo_setting_id: new FormControl(null, [Validators.required]),
      routes: new FormControl(this.selectedRoutes),
      device_type: new FormControl(this.data.device_type)
    })
  }

  onSelectRoute(event: any, id: number, ind: number) {
    const position = this.selectedRoutes.indexOf(id);
    if (event.target.checked) {
      this.selectedRoutes.push(id);
      this.data.routes[ind].checked = true;
    } else {
      this.selectedRoutes.splice(position, 1);
      this.data.routes[ind].checked = false;
    }
    this.form.patchValue({ routes: this.selectedRoutes });
  }

  onSelectAllChange(event: any) {
    const isChecked = event.target.checked;
    this.selectedRoutes = [];
    for (let i = 0; i < this.data.routes.length; i++) {
      this.data.routes[i].checked = isChecked;
      if( isChecked ) {
        this.selectedRoutes.push(this.data.routes[i].id);
      }
    }
    this.form.patchValue({ routes: this.selectedRoutes });
  }

  onDuplicate() {
    if( !this.form.valid || this.form.get('routes').value.length <= 0 ) {
      return;
    }

    this.onCloseDialog(true, this.form.value);
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined) ? fields[key] = formData[key] : key);
    return fields;
  }

  onCloseDialog(doDuplicate?: boolean, formValue?: any) {
    if( doDuplicate ) {
      this.dialogRef.close(formValue);
    } else {
      this.dialogRef.close();
    }
  }
}