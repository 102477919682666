import { Component, OnInit, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { Subscription, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { DashboardHttpService } from '@core/services/dashboard-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { Pagination } from '@core/models/pagination.model';
import { SMSRecoveryReportDetailDataService } from './services/sms-recovery-report-detail-data.service';
import { SMSRecoveryReportDetailEntityService } from './services/sms-recovery-report-detail-entity.service';
import { SMSRecoveryReportDetail } from '@core/models/sms-recovery-report-detail.model';
import * as moment from 'moment-timezone';

@Component({
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss']
})
export class SMSRecoveryReportDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  paginationTotal = 1;
  timezone = this.data.form.timezone ? this.data.form.timezone : JSON.parse(localStorage.getItem('user_data')).timezone;
  datetime = {
    start: moment(this.data.form.start_date_time).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
    end: moment(this.data.form.end_date_time).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')
  };
  params = `start_date_time=${this.datetime.start}&end_date_time=${this.datetime.end}&currency_id=1`;

  dropdownSettings = {};
  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };
  
  smsRecoveryDetails$: Observable<SMSRecoveryReportDetail[]>;

  pagination: Pagination;
  messages$ = this.smsRecoveryReportDetailDataService.messages$;
  ranges: any;
  private datePickerSubscription = new Subscription();
  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number, form: any},
    public dialogRef: MatDialogRef<SMSRecoveryReportDetailsComponent>,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private smsRecoveryReportDetailDataService: SMSRecoveryReportDetailDataService,
    private loadingBar: LoadingBarService,
  ) { }

  ngOnInit() {
    this.onViewPageBy();
    this.pagination = this.smsRecoveryReportDetailDataService.pagination;
  }

  ngAfterViewInit() {
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    this.loadingBar.start();
    params = this.params ? `&${this.params}` : '';
    return this.smsRecoveryDetails$ = this.smsRecoveryReportDetailDataService.getWithQuery(`${this.data.id}?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.smsRecoveryReportDetailDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }
}
