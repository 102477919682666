import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AffiliateTransfers } from '@core/models/affiliate-transfers.model';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Subject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class AffiliateTransfersDataService extends DefaultDataService<AffiliateTransfers>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('AffiliateTransfers', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<AffiliateTransfers[]> {
    return this.http.get<ApiResponse>(`/affiliate/transfer`).pipe(
      tap(res => { this.paginationInit(res) }),
      map(res => res.data.rows)
    )
  }

  getWithQuery(pageParam: string): Observable<AffiliateTransfers[]> {
    return this.http.get<ApiResponse>(`/affiliate/transfer${pageParam}`).pipe(
      tap(res => { this.paginationInit(res) }),
      map(res => res.data.rows)
    )
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}