import { Component, OnInit, Inject } from '@angular/core';
import { AllAffiliatesDataService } from './../../services/all-affiliates-data.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TestFirePostbackLogs } from '@core/models/test-fire-postback-logs.model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';

@Component({
  selector: 'kt-test-fire-postback-logs',
  templateUrl: './test-fire-postback-logs.component.html',
  styleUrls: ['./test-fire-postback-logs.component.scss']
})
export class TestFirePostbackLogsComponent implements OnInit {

  form: FormGroup;
  testFirePostbackLogs$: Observable<TestFirePostbackLogs[]>;
  searchBtnLoading = false;
  dataLength = 0;
  loading = false;
  params = '';
  clearBtnLoading = false;
  pagination: Pagination;
  pageSize = 15;
  page = 1;
  timezone = '';
  buttonLoading = false;
  messages$ = this.allAffiliatesDataService.messages$;

  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { affiliate: any },
    public loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    public dialogRef: MatDialogRef<TestFirePostbackLogsComponent>) { }

  ngOnInit(): void {
    this.formInit();
    this.onSubmit(true);
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(this.data.affiliate.username),
      currency_code: new FormControl(this.data.affiliate.currency_code),
      click_id: new FormControl(null),
    });
  }

  onSubmit(reset: boolean = false) {
    this.page = reset ? 1 : this.page;
    this.dataLength = 0;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        this.testFirePostbackLogs$ = this.allAffiliatesDataService.getLogsWithQuery(`?${this.params}&page=${this.page}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.dataLength = res.length;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.pagination = this.allAffiliatesDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }
  onExport() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.buttonLoading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        data['paginate'] = false;
        data['timezone'] = this.timezone;
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        return this.allAffiliatesDataService.exportTestFireLog(`?${parameters}`).pipe(
          tap(res => {
            this.messages$ = this.allAffiliatesDataService.messages$;
            this.loadingBar.complete();
            this.buttonLoading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.buttonLoading = false;
            return throwError(err);
          })
        );

      }),

    ).subscribe();
  }


  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onSubmit(false);
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.pageSize = 15;
    this.form.patchValue({
      click_id: null,
    })
    this.onSubmit(true);
  }
}
