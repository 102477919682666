<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form" (keydown)="onKeyPress($event)">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Change Password</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="col-12 form-group row">
            <label class="col-3 col-form-label control-label">New Password <span class="text-danger">*</span></label>
            <input (keyup)="onCheckPasswordFormat(form.value.password)" type="password" formControlName="password"  class="col-9 form-control" />
            <ng-container *ngIf="form.value.password !== null && form.value.password !== '' && !form.get('password').valid">
              <div class="col-3"></div>
              <div class="col-9 mt-2">
                <p *ngIf="form.value.password.length > 0 && form.value.password.length < 8" class="justify-content-between mb-0 err-msg text-danger">
                  <i class="fa fa-exclamation-triangle position-relative text-danger mr-2"></i>
                  <small for="password">At least 8 characters long</small>
                </p>
                <p *ngIf="form.value.password.length > 0 && !passwordCheck.contain_numbers" class="justify-content-between mb-0 err-msg text-danger">
                  <i class="fa fa-exclamation-triangle position-relative text-danger mr-2"></i>
                  <small for="register-form-username">Contains at least one number</small>
                </p>
                <p *ngIf="form.value.password.length > 0 && !passwordCheck.contain_uppercase" class="justify-content-between mb-0 err-msg text-danger">
                  <i class="fa fa-exclamation-triangle position-relative text-danger mr-2"></i>
                  <small for="register-form-username">Contains at least one uppercase character</small>
                </p>
                <p *ngIf="form.value.password.length > 0 && !passwordCheck.contain_lowercase" class="justify-content-between mb-0 err-msg text-danger">
                  <i class="fa fa-exclamation-triangle position-relative text-danger mr-2"></i>
                  <small for="register-form-username">Contains at least one lowercase character</small>
                </p>
                <p *ngIf="form.value.password.length > 0 && !passwordCheck.contain_special_character" class="justify-content-between mb-0 err-msg text-danger">
                  <i class="fa fa-exclamation-triangle position-relative text-danger mr-2"></i>
                  <small for="register-form-username">Contains at least one special character</small>
                </p>
                <p *ngIf="!passwordCheck.is_valid_character && form.value.password.length > 0" class="justify-content-between mb-0 err-msg text-danger">
                  <i class="fa fa-exclamation-triangle position-relative text-danger mr-2"></i>
                  <small for="register-form-username">
                    {{ 'Password contains invalid characters.' }}<br>
                    {{ 'Only letters, numbers, and the following special characters are allowed:' }}<br>
                    {{ '! @ # $ % ^ & * ( ) _ + - = { } [ ] | \ : ; "'}} ' {{ '< > , . ? / ~ `' }}
                  </small>
                </p>
              </div>
            </ng-container>
          </div>
          <div class="col-12 form-group row">
              <label class="col-3 col-form-label control-label">Confirm Password <span class="text-danger">*</span></label>
              <input (keyup)="onCheckPasswordFormat(form.value.password)" type="password" formControlName="password_confirmation"  class="col-9 form-control" />
              <ng-container *ngIf="showConfirmPasswordHint">
                <div class="col-3"></div>
                <div class="col-9 mt-2">
                  <p class="justify-content-between mb-0 err-msg text-danger">
                    <i class="fa fa-exclamation-triangle position-relative text-danger mr-2"></i>
                    <small>Confirmation password must match with the password</small>
                  </p>
                </div>
              </ng-container>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid || showConfirmPasswordHint" [buttonLoading]="loading" (confirmed)="onChangePassword()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)" [icon]="!!hasError ? 'info' : 'error'"></kt-swal-alert>
