// Service
import { DownloadHttpService } from "@core/services/download-http.service";
import { ExportCRMBonusActionService } from "./services/export-crm-bonus-action.service";
// Angular / Core
import { Component, Renderer2, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoadingBarService } from "@ngx-loading-bar/core";
// Third Party
import { tap } from "rxjs/operators";
import { of } from "rxjs";
import * as moment from 'moment-timezone';


@Component({
  templateUrl: "./export-crm-bonus-action.component.html",
  styleUrls: ["./export-crm-bonus-action.component.scss"],
})
export class ExportCRMBonusActionComponent implements OnInit {
  form: FormGroup;

  constructor(
    private downloadHttpService: DownloadHttpService,
    private ExportCRMService: ExportCRMBonusActionService,
    private loadingBar: LoadingBarService,
    private titleService: Title,
    private route: ActivatedRoute,
    private renderer: Renderer2,
  ) {
    // set title at the beginning
    this.titleService.setTitle(this.titleService.getTitle() + ' Export CRM Report');

    // Clear existing content in the body
    this.renderer.setProperty(document.body, 'innerHTML', '');
  }

  ngOnInit() {
    this.loadingBar.start();

    this.formInit();
    this.submit();
  }

  private submit() {
    of(this.form.value)
      .pipe(
        tap((data: any) => {
          const currentUrl = window.location.href;
          const urlObject = new URL(currentUrl);
          const pathSegments = urlObject.pathname.split('/');
          const timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
          if (data['datefrom']) {
            data['datefrom'] = moment(data['datefrom']).tz(timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          }
          if (data['dateto']) {
            data['dateto'] = moment(data['dateto']).tz(timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          }
          data['permission_type'] = pathSegments[1] == 'admin' ? 1 : 2;

          const params = Object.keys(data).map(key => key + '=' + data[key]).join('&');

          this.ExportCRMService.getDownload(params).pipe(
            tap(res => {
              let content = res?.message[0] ?? 'Unknown error';

              if (res.success) {
                let execution = 'Execution time: ' + res.data.execution + 's';
                let created_by = 'Created by: ' + res.data.created_by;
                let completed_at = 'Completed at: ' + res.data.completed_at;

                content = `${execution}\n${created_by}\n${completed_at}`;

                if (res?.data?.download_url) {
                  this.downloadHttpService.downloadFileByUrl(res.data.download_url);
                }
              }

              // Append content to the body with <pre> tags
              const preElement = this.renderer.createElement('pre');
              const textNode = this.renderer.createText(content);
              this.renderer.appendChild(preElement, textNode);

              // Set margin style
              this.renderer.setStyle(preElement, 'margin', '20px');

              // Append to the body
              this.renderer.appendChild(document.body, preElement);

              this.loadingBar.complete();
            })
          ).subscribe();
        })
      )
      .subscribe();
  }

  private formInit() {
    this.form = new FormGroup({});

    this.route.queryParams.subscribe(params => {
      // Loop through each query parameter and patch it into the form
      Object.keys(params).forEach(key => {
        let control = new FormControl(params[key]);

        // Add validation for datefrom and dateto fields
        if (['datefrom', 'dateto'].includes(key)) {
          control.setValidators([Validators.required, this.isValidDateTime]);
        }

        this.form.addControl(key, control);
      });
    });
  }

  private isValidDateTime(control: FormControl) {
    const value = control.value;

    // Check if the value is a valid date-time format
    if (!value || isNaN(Date.parse(value))) {
      return { 'invalidDateTime': true };
    }

    return null;
  }
}
