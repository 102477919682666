<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ data.mode | titlecase }} Affiliate</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="col-12 form-group">
              <label class="control-label">Reason <span class="text-danger">*</span></label>
              <input type="text" formControlName="approval_remarks" class="col-12 form-control">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.affiliate, data.mode)"></kt-submit-button>
        </div>
      </div>
    </form>
  </div>

  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
