import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AllMemberVerification } from '@core/models/allmember-verification.model';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { MemberVerificationDetailsComponent } from './dialogs/member-verification-details/member-verification-details.component';
import { MemberVerificationDataService } from './services/member-verification-data.service';
import { MemberVerificationEntityService } from './services/member-verification-entity.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-member-verification',
  templateUrl: './member-verification.component.html',
  styleUrls: ['./member-verification.component.scss']
})
export class MemberVerificationComponent implements OnInit, OnDestroy {

  form: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  pagination: Pagination;
  ranges: any;

  dropdownSettings = {};
  dropdown = {
    groups: this.dropdownHttpService.groups,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: this.dropdownHttpService.verificationStatuses,
    perPage: this.dropdownHttpService.perPage
  };
  defaultStart = this.transactionHttpService.getToday().from;
  defaultEnd = this.transactionHttpService.getToday().to;
  memberVerification$: Observable<AllMemberVerification[]>;
  params = ``;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '1.3';
  sortingConfig = {
    'id': 'desc',
    'name': 'desc',
    'username': 'desc',
    'sms_verified': 'desc',
    'status': 'desc',
    'verified_by': 'desc',
    'verify_at': 'desc',
    'updated_at': 'desc',
    'created_at': 'desc'
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  // permissions
  canEditMemberVerification: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    public dialog: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private memberVerificationService: MemberVerificationEntityService,
    private memberVerificationDataService: MemberVerificationDataService,
    private loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));

    this.accountTypeDropdownList = this.dropdownHttpService.accountTypesLists;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    
    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.formInit();
    this.pagination = this.memberVerificationDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.onSubmit(true);
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditMemberVerification = appPermissions.edit_member_verification;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.memberVerification$ = this.memberVerificationService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.memberVerificationDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    this.form.patchValue({
      start_date: this.defaultStart,
      end_date: this.defaultEnd,
      defaultDate: {
        startDate: this.defaultStart,
        endDate: this.defaultEnd
      },
      name: null,
      username: null,
      group_id: 'all',
      currency_id: 'all',
      status: 'all',
      account_type_condition: 'Or',
      account_type: [1, 2, 3]
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if(data['account_type']){
          data['account_type'] = this.form.controls['account_type'].value.toString();
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.memberVerification$ = this.memberVerificationService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.memberVerificationDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      username: new FormControl(null),
      group_id: new FormControl('all'),
      currency_id: new FormControl('all'),
      status: new FormControl('all'),
      start_date: new FormControl(this.defaultStart),
      end_date: new FormControl(this.defaultEnd),
      account_type_condition: new FormControl('Or'),
      account_type: new FormControl([1,2,3]),
      defaultDate: new FormControl({
        startDate: this.defaultStart,
        endDate: this.defaultEnd
      }) // Do not remove: For Clearing The Range
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private openDialogBy(componentRef: any, data?: { memberVerification?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        memberVerification: data.memberVerification
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  onOpenDialog(memberVerificationRow: any) {
    this.openDialogBy(MemberVerificationDetailsComponent, { memberVerification: memberVerificationRow });
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }
}
