import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { Transaction } from '@core/models/transaction.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { exhaustMap, map, tap, catchError } from 'rxjs/operators';
import { MemberInformationDialogComponent } from './../members/dialogs/member-information/member-information.component';
import { MemberDataService } from './../members/services/member-data.service';
import { TransactionWalletDialogComponent } from './dialogs/transaction-wallet/transaction-wallet.component';
import { TransactionDataService } from './services/transaction-data.service';
import { TransactionEntityService } from './services/transaction-entity.service';
import * as moment from 'moment-timezone';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit, OnDestroy {

  form: FormGroup;
  transactions$: Observable<Transaction[]>;
  messages$ = this.transactionDataService.messages$;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  ranges: any;
  transactionTypes = [];
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage
  };
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  buttonLoading = false;
  dataLength: number;
  from = this.transactionHttpService.getLast24Hours().from;
  to = this.transactionHttpService.getLast24Hours().to;

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '2.4';
  sortingConfig = {
    'id': 'desc',
    'transaction_type_id': 'desc',
    'username': 'desc',
    'currency': 'desc',
    'amount': 'desc',
    'balance': 'desc',
    'refer_id': 'desc',
    'remarks': 'desc',
    'operator': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  // permissions
  canViewMemberDialog: boolean;
  canAdjustWallet: boolean;
  canExportTransactions: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private transactionEntityService: TransactionEntityService,
    private transactionDataService: TransactionDataService,
    public dialog: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private memberDataService: MemberDataService,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.formInit();
    this.dropdownHttpService.transactionTypes.subscribe(res => {
      res.forEach(item => {
        item.checked = true;
      })
      this.transactionTypes = res;
      this.form.patchValue({
        transaction_type_id: this.getTransactionTypes()
      })
      this.ranges = this.transactionHttpService.ranges;
    })

    this.accountTypeDropdownList = this.dropdownHttpService.accountTypesLists;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];

    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.transaction_transaction_view_member_dialog;
      this.canAdjustWallet = appPermissions.adjust_wallet;
      this.canExportTransactions = appPermissions.export_transactions_list;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onOpenDialog(type: string, id?: number) {
    switch (type) {
      case 'transaction':
        this.openDialogBy(TransactionWalletDialogComponent, { member: null });
        break;
      case 'member-information':
        if (!this.canViewMemberDialog) {
          Swal.fire('Permission Error', 'You do not have permission to view member information', 'error');
          return;
        }

        this.subscription = this.memberDataService.getById(id, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`).pipe(
          tap((res) => {
            if (res) {
              this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' }, '1500px');
            }
          })
        ).subscribe();
        break;
    }
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any }, dimension?: string) {
    const dialogRef = this.dialog.open(componentRef, {
      width: dimension ? dimension : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data: {
        member: data.member
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `${this.params}` : '';
    this.loadingBar.start();
      return this.transactions$ = this.transactionEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.transactionDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    this.transactionTypes.forEach(item => {
      item.checked = true;
    })
    this.form.patchValue({
      id: null,
      username: null,
      name: null,
      transaction_type_id: this.getTransactionTypes(),
      refer_id: null,
      start_date:  this.from,
      end_date:  this.to,
      defaultDate: {
        startDate: this.from,
        endDate: this.to
      },
      currency_id: 'all',
      amount: null,
      account_type_condition: 'Or',
      account_type: [1, 2, 3]
    });
    this.onSubmit(true);
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.eventEmitterService.onClearMemberSearch();
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        if(data['account_type']){
          data['account_type'] = this.form.controls['account_type'].value.toString();
        }

        const transactionParams = this.generateTransactionParams().transactionParams !== '' ? `&${this.generateTransactionParams().transactionParams}` : '';
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = (this.params ? '&' + this.params : '') + transactionParams;
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        return this.transactions$ = this.transactionEntityService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.transactionDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onExport() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.buttonLoading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        const transactionParams = this.generateTransactionParams().transactionParams !== '' ? `&${this.generateTransactionParams().transactionParams}` : '';
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = (this.params ? '&' + this.params : '') + transactionParams;
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        return this.transactionDataService.export(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.buttonLoading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.buttonLoading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private generateTransactionParams() {
    return {
      transactionParams: this.form.value.transaction_type_id === 'all' ? '' : Object.keys(this.form.value.transaction_type_id).map(key => `transaction_type_id[${key}]=${this.form.value.transaction_type_id[key]}`).join('&')
    };
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all' && key !== 'transaction_type_id') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null),
      name: new FormControl(null),
      transaction_type_id: new FormControl([]),
      refer_id: new FormControl(null),
      start_date: new FormControl(this.from),
      end_date: new FormControl(this.to),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      }), // Do not remove: For Clearing The Range
      currency_id: new FormControl('all'),
      amount: new FormControl(null),
      account_type_condition: new FormControl('Or'),
      account_type: new FormControl([1, 2, 3])
    });
  }

  private reload(refresh: boolean) {
    if (!refresh) {
      this.onViewPageBy(this.page).subscribe();
    } else {
      this.appendNew(this.page);
    }
  }

  appendNew(page = 1, pageSize?: number, params?: string) {
    this.loadingBar.start();
    const before = this.pagination.total;
    pageSize = this.pageSize;
    params = this.params ? `${this.params}` : '';
    let newTransactions$ = this.transactionEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.transactionDataService.pagination;
      })
    );

    newTransactions$.subscribe(newTransactions => {
      this.transactions$.subscribe(res => {
        // if (res.length < newTransactions.length){
        //   for (let i = 0; i < (newTransactions.length - res.length); i++) {
        //     res.unshift(newTransactions[newTransactions.length - 1 - i]);
        //   }
        // } else if (res.length > newTransactions.length){
        //   this.onViewPageBy(this.page).subscribe();
        // }
        if (this.pagination.total > before) {
          for (let i = (this.pagination.total - before); i >= 0; i--) {
            res.unshift(newTransactions[i]);
          }
        } else if (before > this.pagination.total) {
          this.onViewPageBy(this.page).subscribe();
        }
      });
      this.loadingBar.complete();
    });

  }

  onChangeType(event: any, id: string) {
    this.transactionTypes.find(x => x.id === id).checked = event.target.checked;
    this.form.patchValue({
      transaction_type_id: this.getTransactionTypes()
    })
  }

  private getTransactionTypes() {
    let transaction_type_ids = [];
    this.transactionTypes.forEach(item => {
      if (item.checked) {
        transaction_type_ids.push(item.id);
      }
    });
    return transaction_type_ids;
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }

    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }
}
