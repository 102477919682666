import { Operator } from '@core/models/operator.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class OperatorEntityService extends EntityCollectionServiceBase<Operator>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Operator', serviceElementsFactory)  }
}
