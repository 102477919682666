<div id="kt_content" class="kt-content kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="kt-form kt-form--label-right kt-margin-b-10">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <!-- first row -->
              <div class="col-12 row pr-0">
                <!-- Name -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Name:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="name" placeholder="Search" class="form-control" />
                </div>

                <!-- Status -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="status">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <option value="all">All</option>
                    <ng-container *ngFor="let value of dropdown.statuses; let i = index">
                      <option [value]="i">{{ value }}</option>
                    </ng-container>
                  </select>
                </div>

                <!-- Parent -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Parent:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="parent_id">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <option value="all">All</option>
                    <option *ngFor="let parent of dropdown.parents" [value]="parent.id">{{ parent.name }}</option>
                  </select>
                </div>

                <!-- Child -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Child:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="child_id">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <option value="all">All</option>
                    <option *ngFor="let child of dropdown.children" [value]="child.id">{{ child.name }}</option>
                  </select>
                </div>
              </div>

              <!-- second row -->
              <div class="col-12 row mt-3 pr-0">
                <!-- Name -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Remarks:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="remarks" placeholder="Search" class="form-control" />
                </div>

                <!-- Date/Time -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date/Time:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="input-group date">

                    <select formControlName="datetime_type" class="form-control" style="width: 30%;">
                      <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                    </select>

                    <input type="hidden" formControlName="start_datetime">
                    <input type="hidden" formControlName="end_datetime">

                    <div class="input-group date" style="width: 70%;">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search"
                        (change)="onDateRange($event)"
                        formControlName="defaultDate"
                        [timePicker]="true"
                        [timePickerSeconds]="true"
                        [alwaysShowCalendars]="true"
                        [ranges]="ranges"
                        [showCustomRangeLabel]="true"
                        [locale]="dateTimePickerLocale"
                        [timePicker24Hour]="true"
                        [opens]="'left'"
                        [showDropdowns]="true"
                        (click)="updateDateRange()"
                        ngxDaterangepickerMd
                      />
                      <span class="input-group-append">
                        <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                      </span>
                    </div>

                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <button *ngIf="canCreateApplicationRole" class="btn btn-brand btn-icon-sm" [disabled]="createBtnLoading" (click)="onOpenDialog('create')"><i [ngClass]="createBtnLoading ? 'spinner-border spinner-border-sm align-middle mr-2' : 'fas fa-plus'"></i>Create</button>
      </div>
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="text-center sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                  <th class="text-center sort-enabled" [ngStyle]="{ width: '400px' }" (click)="onSortColumn('parent_id')" [ngClass]="sortingConfig.parent_id" [class.sort-selected]="sortingSelection.sort_by === 'parent_id'">Hierarchy</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('remarks')" [ngClass]="sortingConfig.remarks" [class.sort-selected]="sortingSelection.sort_by === 'remarks'">Remarks</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                  <th class="text-center sort-enabled date-column" (click)="onSortColumn('created_by')" [ngClass]="sortingConfig.created_by" [class.sort-selected]="sortingSelection.sort_by === 'created_by'">Created By</th>
                  <th class="text-center sort-enabled date-column" (click)="onSortColumn('updated_by')" [ngClass]="sortingConfig.updated_by" [class.sort-selected]="sortingSelection.sort_by === 'updated_by'">Updated By</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody *ngIf="roles.length > 0">
                <tr *ngFor="let row of roles">
                  <td>{{ row.id }}</td>
                  <td>{{ row.name }}</td>
                  <td>
                    <b>Parent: </b> {{ row.parent_name }}
                    <br />
                    <b>Child: </b> {{ row.children_name || '-' }}
                  </td>
                  <td>{{ row.remarks }}</td>
                  <td class="text-center">
                    <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                  </td>
                  <td>
                    {{ row.created_by || "System" }}
                    <br />
                    <span placement="top" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #createdDateTime>
                      {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                  <td>
                    {{ row.updated_by || "-" }}
                    <br />
                    <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #updatedDateTime>
                      {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                  <td class="kt-datatable__cell" class="text-center">
                    <button *ngIf="canEditApplicationRole" matTooltip="Edit Role" [disabled]="editBtnLoading[row.id]" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)"><i [class]="editBtnLoading[row.id] ? 'fas fa-circle-notch fa-spin' : 'fas fa-edit'"></i></button>
                    <button *ngIf="canDuplicateApplicationRole" matTooltip="Duplicate" [disabled]="duplicateBtnLoading[row.id]" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('duplicate', row.id)"><i [class]="duplicateBtnLoading[row.id] ? 'fas fa-circle-notch fa-spin' : 'fa fa-clone'"></i></button>
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)"> </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc"> Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total | number : "1.0" }} records </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
