import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kt-fallback-row',
  templateUrl: './fallback-row.component.html',
  styleUrls: ['./fallback-row.component.scss']
})
export class FallbackRowComponent implements OnInit {

  @Input()
  columns = 30;

  @Input()
  content = 'No data available';

  @Input()
  collection: any[];

  constructor() { }

  ngOnInit() { }

}
