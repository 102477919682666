// Service
import { AllMemberReportEntityService } from "./../../all-member-reports/services/all-member-report-entity.service";
import { AllMemberReportDataService } from "../../all-member-reports/services/all-member-report-data.service";
// Angular / Core
import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AllMemberReport } from "@core/models/allmember-report.model";
import { Pagination } from "@core/models/pagination.model";
// Third Party
import { Observable, Subscription, of, throwError } from "rxjs";
import { tap, catchError, map, exhaustMap  } from "rxjs/operators";
import { FormGroup } from "@angular/forms";
import * as moment from 'moment-timezone';
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';
import { DateTime } from 'luxon';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AuthHttpService } from '@core/services/auth-http.service';
import { AppPermissionService } from "@core/services/app-permission.service";
@Component({
  selector: "kt-game-provider-report-details",
  templateUrl: "./game-provider-report-details.component.html",
  styleUrls: ["./game-provider-report-details.component.scss"],
})
export class GameProviderReportDetailsComponent implements OnInit, OnDestroy {
  memberReport$: Observable<AllMemberReport[]>;
  pagination: Pagination;
  unconvertedFrom = moment(this.data.param.start_date_time).tz(this.data.param.timezone, true).utc(); // For display only
  unconvertedTo = moment(this.data.param.end_date_time).tz(this.data.param.timezone, true).utc(); // For display only
  from = moment(this.data.param.start_date_time).tz(this.data.param.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
  to = moment(this.data.param.end_date_time).tz(this.data.param.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
  pageSize = 15;
  page = 1;
  params = `currency_id=${this.data.form.value.currency_id}&game_provider_id=${this.data.param.game_provider_id}&start_date_time=${this.from}&end_date_time=${this.to}`;
  maxSize = 5;
  form = this.data.form;

  button_loading = false;
  loading = false;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  access$ = this.authHttpService.getUserAccess(5, 'Member Report');
  messages$ = this.memberReportDataService.messages$;

  // permissions
  canExportGameProviderBreakdown: boolean;
  canExportGameTypeBreakdown: boolean;

  
  lastBetLogSyncAt = null;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      form: FormGroup,
      param: any,
      type: string
    },
    public dialogRef: MatDialogRef<GameProviderReportDetailsComponent>,
    private memberReportDataService: AllMemberReportDataService,
    private memberReportEntityService: AllMemberReportEntityService,
    private timeZoneDate: TimezoneDatePipe,
    private loadingBar: LoadingBarService,
    private authHttpService: AuthHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if(this.data.param.option == 'game_sub_category' || this.data.param.option == 'game'){
      if(this.data.param.game_sub_category_name != undefined && this.data.param.game_sub_category_name != null){
        this.params += `&sub_category_name=${this.data.param.game_sub_category_name}`;
      }
    }
    this.reload();
    this.pagination = this.memberReportDataService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      // note that game provider breakdown is from Game Provider report,
      // and game type breakdown is from Game Type Report
      this.canExportGameProviderBreakdown = appPermissions.export_game_provider_breakdown;
      this.canExportGameTypeBreakdown = appPermissions.export_game_type_breakdown;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : "";
    this.loading = true;
    return (this.memberReport$ = this.memberReportEntityService
      .getWithQuery(`&page=${page}&perPage=${pageSize}${params}`)
      .pipe(
        tap((res) => {
          this.loading = false;
          this.pagination = this.memberReportDataService.pagination;
          this.getLastBetLogSyncAt();
        })
      ));
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private reload() {
    this.onViewPageBy().subscribe();
  }

  timezoneDateWithTimezone(date: any, timezone: any, format: any) {
    let returnDate =  this.timeZoneDate.transformWithTimezone(date, timezone, format);
    let todayDate = DateTime.now().setZone(timezone).toFormat('(ZZ)');
    todayDate = todayDate.replace('(', '(GMT ');
    returnDate = returnDate + ' ' + todayDate;
    return returnDate;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onExport() {
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time'] && !data['daily']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        } else {
          data['start_date_time'] = moment(this.unconvertedFrom).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(this.unconvertedTo).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params += `&game_provider_id=${this.data.param.game_provider_id}`;
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        var filename = this.data.param.game_provider_name + 'Report_' + moment(this.form.get('start_date_time').value).format('YYYYMMDD') + '-' + moment(this.form.get('end_date_time').value).format('YYYYMMDD') + '.xlsx';
        return this.memberReportDataService.exportGameProviderDetail(`?${parameters}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.button_loading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.button_loading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  getLastBetLogSyncAt(){
    var userTimeZone = JSON.parse(localStorage.getItem('user_data')).timezone;
    var timeInterved = 5; // 5 minutes 
    const d = new Date();
    let minutes = d.getMinutes();
    const intMinutes =  minutes/timeInterved;
    const roundedMinute = (minutes/timeInterved).toFixed(0);
    var minus;
    if( Number(intMinutes) < Number(roundedMinute) || Number(intMinutes) == Number(roundedMinute)){
      if(Number(roundedMinute) > 0) minus = 1;
      else minus = -1;
    }
    else if( Number(intMinutes) > Number(roundedMinute)){
      minus = 0;
    }
    var  minutesConverted5min = ( Number(roundedMinute) - minus )  * timeInterved;
    var newMinutes;
    if(minutesConverted5min < 10){
      newMinutes = '0'+minutesConverted5min.toString();
    }else{
      newMinutes = minutesConverted5min.toString();
    }

    var timeNow =  DateTime.now().setZone(userTimeZone).toFormat('yyyy-MM-dd HH:'+newMinutes+':00 (ZZ)'); 
    this.lastBetLogSyncAt = timeNow.replace('(', '(GMT ');
  }
}
