import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { RemarkDialogComponent } from '../../../all-leads/dialogs/remark/remark.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-call-log',
  templateUrl: './call-log.component.html',
  styleUrls: ['./call-log.component.scss']
})
export class CallLogDialogComponent implements OnInit {

  callLogData: [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { callLog: any },
    public dialogRef: MatDialogRef<CallLogDialogComponent>,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.callLogData = this.data.callLog;
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onOpenDialog(type: string, id?: any, data?: any) {
    if (type === 'remarks') {
      this.openDialogBy(RemarkDialogComponent, { mode: type, leadHistoryID: id, leadID: data });
    } 
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, leadHistoryID?: any, leadID?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      data: {
        leadHistoryID: data.leadHistoryID,
        leadID: data.leadID,
      },
      autoFocus: false
    });
  }
}
