import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { CampaignAdCost } from '@core/models/campaign-ad-cost.model';

@Injectable()
export class CampaignAdCostDataService extends DefaultDataService<CampaignAdCost>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('CampaignAdCost', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<CampaignAdCost[]> {
    return this.http.get<ApiResponse>('/campaign/adcost').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<CampaignAdCost[]>{
    return this.http.get<ApiResponse>(`/campaign/adcost${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getById(id: number): Observable<CampaignAdCost>{
    return this.http.get<ApiResponse>(`/campaign/adcost/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  updateAdCost(campaignAdCost: CampaignAdCost) {
    return this.http.put<ApiResponse>(`/campaign/adcost/${campaignAdCost.id}`, campaignAdCost);
  }

  add(campaignAdCost: CampaignAdCost): Observable<CampaignAdCost>{
    return this.http.post<ApiResponse>(`/campaign/adcost`, campaignAdCost).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/campaign/adcost/${entity.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }
}