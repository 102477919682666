// Angular
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class DownloadHttpService {
  /**
   * Service Constructor
   *
   * @param http: HttpClient
   */
  constructor(private http: HttpClient) { }

  downloadFile(data: Blob, filename: string) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.href = url;
    anchor.download = filename;
    anchor.click();
    window.URL.revokeObjectURL(url);
    anchor.remove();
  }

  downloadFileByUrl(url: string) {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.href = url;
    anchor.click();
    window.URL.revokeObjectURL(url);
    anchor.remove();
  }

  updateDownloadCount() {
    return this.http.get(`/report/downloadCount`)
  }

  updateImportCount() {
    return this.http.get(`/imports/importcount`);
  }
}
