import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';;
import { PromotionCodeDataService } from '../../../promotion-codes/services/promotion-code-data.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  templateUrl: './reward-member-eligible.component.html',
  styleUrls: ['./reward-member-eligible.component.scss']
})
export class RewardMemberEligibleDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = new Subject<any[]>();
  hasError: boolean;
  subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  showDetails = false;

  message = null;
  eligibleCondition = [];
  eligibleDetail = [];
  preRequisites = [];
  promotionList: any;
  promo = null;

  constructor(
    public dialogRef: MatDialogRef<RewardMemberEligibleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { reward: any},
    private promotionCodeDataService: PromotionCodeDataService,
    private cdr: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.onCheckEligible();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  onCheckEligible() {
    this.buttonLoading = true;
    this.promotionCodeDataService.checkEligibility(this.data.reward.promotion_id, this.data.reward.member_id, 1, '').subscribe(res => {
      this.message = res.message;
      this.eligibleCondition = res.data.rows['types'];
      this.eligibleDetail = res.data.rows['details'];
      this.preRequisites = res.data.rows['pre_requisites'];
      this.buttonLoading = false;
      this.cdr.detectChanges();
    });
  }

  replaceLineBreaks(data: string) {
    return data.replace('<br>', '\n');
  }

  expandDetails() {
    this.showDetails = this.showDetails == false ? true : false;
  }
}
