import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'kt-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent implements OnInit {

  @Input()
  buttonLoading: boolean;

  @Input()
  isDisabled: boolean;

  @Output()
  confirmed = new EventEmitter<boolean>();

  @Input()
  text = 'Submit';

  constructor() { }

  ngOnInit(): void {
  }

  onSave(){
    this.confirmed.emit(true);
  }

}
