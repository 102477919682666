import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AccountManagementDataService } from '../../services/account-management-data.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppPermissionService } from '@core/services/app-permission.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import * as moment from 'moment-timezone';
import { LuckyDrawDialogComponent } from '../../../lucky-draw-settings/dialogs/lucky-draw-edit.component';
import { LuckyDrawSettingsDataService } from '../../../lucky-draw-settings/services/lucky-draw-settings-data.service';

@Component({
  templateUrl: './kpi-settings-dialog.component.html',
  styleUrls: ['./kpi-settings-dialog.component.scss']
})
export class AccountManagementKpiSettingsDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    developmentStage: this.dropdownHttpService.developmentStage,
    normalLeadStage: this.dropdownHttpService.normalLeadStage,
    vipLeadStage: this.dropdownHttpService.vipLeadStage,
  };
  messages$ = this.luckyDrawSettingsDataService.messages$;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  dateTimeFormat = 'yyyy-MM-dd HH:mm:';
  refreshStatus: boolean;
  buttonLoading = false;
  updatedBy = '';
  updatedAt = '';

  // permissions
  canEditKPISettings: boolean;


  private subscription = new Subscription();
  private subscriptions = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, kpiSettings: any },
    private dropdownHttpService: DropdownHttpService,
    public dialogRef: MatDialogRef<LuckyDrawDialogComponent>,
    private cdr: ChangeDetectorRef,
    private luckyDrawSettingsDataService: LuckyDrawSettingsDataService,
    public dialog: MatDialog,
    private AccountManagementDataService: AccountManagementDataService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.getKpiSettings();
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditKPISettings = appPermissions.edit_kpi_settings;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.onRefresh();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    const data = {
      ...this.form.value,
    };
    Object.keys(data).forEach((key) => (data[key] == null) && delete data[key]);
    this.subscription = this.AccountManagementDataService.updateKpiSettings(data).pipe(
      tap((res: any) => {
        this.messages$.next([...res.message]);
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  getKpiSettings() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });
    this.AccountManagementDataService.getKpiSettings(this.form.value.development_stage, this.form.value.lead_stage).pipe(
      tap((res: any) => {
        this.updatedBy = res.rows.updated_by;
        this.updatedAt = res.rows.updated_at;
        this.form.patchValue({
          task_durations: res.rows.task_durations,
          average_deposit_days: res.rows.average_deposit_days,
          increase_deposit_count_percentage: res.rows.increase_deposit_count_percentage,
          increase_deposit_amount_percentage: res.rows.increase_deposit_amount_percentage,
          min_inactive_days: res.rows.min_inactive_days,
          max_inactive_days: res.rows.max_inactive_days
        });
        this.buttonLoading = false;
        this.form.setErrors(null);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
  }

  private formInit() {
    let development_stage = 1;
    let lead_stage = 1;
    let task_durations = null;
    let average_deposit_days = null;
    let min_inactive_days = null;
    let max_inactive_days = null;
    let increase_deposit_count_percentage = null;
    let increase_deposit_amount_percentage = null;

    this.form = new FormGroup({
      development_stage: new FormControl(development_stage, [Validators.required]),
      lead_stage: new FormControl(lead_stage, [Validators.required]),
      task_durations: new FormControl(task_durations, [Validators.required]),
      average_deposit_days: new FormControl(average_deposit_days, [Validators.required]),
      min_inactive_days: new FormControl(min_inactive_days),
      max_inactive_days: new FormControl(max_inactive_days),
      increase_deposit_count_percentage: new FormControl(increase_deposit_count_percentage, [Validators.required]),
      increase_deposit_amount_percentage: new FormControl(increase_deposit_amount_percentage, [Validators.required]),
    });

    this.form.controls['development_stage'].valueChanges.subscribe((val) => {
      if (val == 2) {
        this.form.patchValue({
          lead_stage: 1,
        });
        this.form.get('lead_stage').disable();
        this.form.get('min_inactive_days').disable();
        this.form.get('max_inactive_days').disable();
      } else {
        this.form.get('lead_stage').enable();
        if (this.form.value.lead_stage == 1) {
          this.form.get('min_inactive_days').enable();
          this.form.get('max_inactive_days').enable();
        } else {
          this.form.get('min_inactive_days').disable();
          this.form.get('max_inactive_days').disable();
        }
      }
    });

  }

}
