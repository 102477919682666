import { catchError, tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { GroupDataService } from '../../services/group-data.service';
import { WithdrawLimit } from '@core/models/withdraw-limit.model';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-withdrawal-limit',
  templateUrl: './withdrawal-limit.component.html',
  styleUrls: ['./withdrawal-limit.component.scss']
})
export class WithdrawalLimitDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  messages$ = this.groupDataService.messages$;
  withdrawLimitFields = ['min', 'max', 'daily_max_count', 'daily_max']
  refreshStatus: boolean;
  buttonLoading = false;

  // permissions
  canEditWithdrawalLimits: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, withdrawLimit: WithdrawLimit },
    public dialogRef: MatDialogRef<WithdrawalLimitDialogComponent>,
    private groupDataService: GroupDataService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditWithdrawalLimits = appPermissions.edit_withdrawal_limits;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    this.subscription = this.groupDataService.updateWithdrawLimit(data).pipe(
      tap((res: any) => {
        this.messages$.next([...res.message]);
        this.buttonLoading = false; 
        // To enable "Save" button after get response
        this.form.setErrors(null);   
      }),
      catchError((error) => {
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    const withdrawLimitForm = () => {
      const withdrawLimits = this.data.withdrawLimit;
      let withdrawLimit = {};
      let limitData = {};
      for (const row in withdrawLimits) {
        if (row) {
          Object.keys(withdrawLimits[row]).forEach((key) => {
              limitData = {...limitData, [key]: new FormControl(withdrawLimits[row][key], Validators.compose([Validators.required, Validators.min(0)]))}
          });
          withdrawLimit = {...withdrawLimit, [row]: new FormGroup(limitData)}
        }
      }
      return withdrawLimit;
    }
    this.form = new FormGroup({
      withdraw_limits: new FormGroup(withdrawLimitForm())
    });
  }

}
