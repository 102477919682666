<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right  kt-margin-b-10">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form [formGroup]="form" class="row align-items-center">
                            <div class="col-12 row pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="type_id">
                                      <option value="all">All</option>
                                        <option [value]="item.id" *ngFor="let item of dropdown.types">
                                            {{ item.name | titlecase }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Platform:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="platform_id">
                                        <option [value]="item.id" *ngFor="let item of dropdown.platform">
                                            {{ item.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Layout:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="layout_id">
                                        <option value="all">All</option>
                                        <!-- <option [value]="item.id" *ngFor="let item of dropdown.layout">
                                            {{ item.name }}
                                        </option> -->
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row pr-0 mt-3">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Code:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <input type="text" formControlName="code" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Locale:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="settings_locale_id">
                                        <option value="all">All</option>
                                        <option [value]="item.id" *ngFor="let item of dropdown.locales | async">
                                            {{ item.code }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                  <select  class="form-control" formControlName="status">
                                    <option value="all">All</option>
                                    <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">{{ value }}</option>
                                </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
                <div class="dropdown dropdown-inline">
                  <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                  <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canCreateRegistrationPage" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>  
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Campaign</th>
                                    <th class="text-center">Type</th>
                                    <th class="text-center">Platform</th>
                                    <th class="text-center">Layout</th>
                                    <th class="text-center">Locale</th>
                                    <th class="text-center">Code</th>
                                    <th class="text-center">Status</th>
                                    <th>Start Time</th>
                                    <th>End Time</th> 
                                    <th>Created By</th>     
                                    <th>Updated By</th>                               
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="registrationPage$.length > 0">
                                <tr *ngFor="let row of registrationPage$">
                                    <td>{{ row.id }}</td>
                                    <td>{{ row.name }}</td>
                                    <td>
                                        <ng-container *ngFor="let item of row.campaigns">
                                            <span>{{ item.campaign_name }}</span><br>
                                        </ng-container>
                                    </td>
                                    <td class="text-center">{{ row.type_name }}</td>
                                    <td class="text-center">{{ row.platform_name }}</td>
                                    <td class="text-center">{{ row.layout_id }}</td>
                                    <td class="text-center">{{ row.settings_locale_code }}</td>
                                    <td class="text-center">
                                        <ng-container *ngFor="let item of row.campaigns">
                                            <a class="text-primary" [href]="(row.type_id === 1 ? signUpUrl : mobileSignUpUrl) + '?campaign=' + item.campaign_code" target="_blank">{{ item.campaign_code }}</a>
                                            <span class="ml-2" style="cursor: pointer" (click)="tooltip.show();" [cdkCopyToClipboard]="(row.type_id === 1 ? signUpUrl : mobileSignUpUrl) + '?campaign=' + item.campaign_code" (mouseleave) = "tooltip.hide(2000)">
                                                <i class="fa fa-paste" mat-raised-button #tooltip="matTooltip"
                                                    (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()"
                                                    matTooltip="Copied!" matTooltipPosition="above">
                                                </i>
                                            </span>
                                            <br>    
                                        </ng-container>
                                    </td>
                                    <td class="text-center">
                                        <div class="btn-group dropdown">
                                            <button type="button" class="btn btn-pill btn-sm dropdown-base"  [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }} </button>
                                            <ng-container *ngIf="canUpdateRegistrationPageStatus">
                                                <button type="button" class="btn btn-pill btn-sm dropdown-toggle" [ngClass]="'kt-badge--' + row.status" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fa fa-angle-down"></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <ng-container *ngFor="let value of dropdown.statuses; let i = index">
                                                        <button class="dropdown-item" type="button" (click)="onChangeStatus(i, row)" *ngIf="+i >= 0">{{ value }}</button>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td>{{ row.start_time | timezoneDate: 'YYYY-MM-DD HH:mm' }}</td>
                                    <td>{{ row.end_time | timezoneDate: 'YYYY-MM-DD HH:mm' }}</td>
                                    <td>
                                        {{ row.created_by ? row.created_by : 'System' }} <br>
                                        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        {{ row.updated_by ? row.updated_by : 'System' }} <br>
                                        <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        <button *ngIf="canViewRegistrationPageDetails" matTooltip="Settings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)"><i class="fas fa-cog"></i></button>
                                        <button *ngIf="canDuplicateRegistrationPage" matTooltip="Duplicate" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('duplicate', row.id)"><i class="fa fa-clone"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                            Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>