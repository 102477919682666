<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">{{ data.gameProviderCode }} - Bet Details - {{ data.transactionId }}</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">

      <div class="table-responsive">
        <table class="table table-bordered table-hover table-striped">
          <thead>
              <tr class="text-top">
                <th>ID</th>
                <th>Game Provider ID</th>
                <th>Ticket ID</th>
                <th>Parlay No.</th>
                <th>Match Name</th>
                <th>Home Name</th>
                <th>Away Name</th>
                <th>Half Score</th>
                <th>Full Score</th>
                <th>Is Live</th>
                <th>Live Score</th>
                <th>HDP</th>
                <th>Odds Style</th>
                <th>Odds</th>
                <th>Sport</th>
                <th>Market</th>
                <th>Bet Option</th>
                <th>Stake</th>
                <th class="date-column">Bet Date Time</th>
                <th class="date-column">Match Date Time</th>
                <th class="date-column">W/L Date Time</th>
                <th>Ticket Status</th>
                <th class="date-column">Created At</th>
                <th class="date-column">Updated At</th>
              </tr>
          </thead>

          <tbody>
            <ng-container *ngIf="data.ticketInfo as rows">
              <tr *ngFor="let row of rows">
                <td>{{ row.id }}</td>
                <td>{{ row.game_provider_id }}</td>
                <td>{{ row.ticket_id }}</td>
                <td>{{ row.parlay_no }}</td>
                <td>{{ row.match_name }}</td>
                <td>{{ row.home_name }}</td>
                <td>{{ row.away_name }}</td>
                <td>{{ row.half_score }}</td>
                <td>{{ row.full_score }}</td>
                <td>{{ row.is_live }}</td>
                <td>{{ row.live_score }}</td>
                <td>{{ row.hdp }}</td>
                <td>{{ row.odds_style }}</td>
                <td>{{ row.odds }}</td>
                <td>{{ row.sport }}</td>
                <td>{{ row.market }}</td>
                <td>{{ row.bet_option }}</td>
                <td>{{ row.stake }}</td>
                <td>{{ row.bet_datetime }}</td>
                <td>{{ row.match_datetime }}</td>
                <td>{{ row.winlost_datetime }}</td>
                <td>{{ row.ticket_status }}</td>
                <td>{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</td>
                <td>{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</td>
              </tr>
            </ng-container>
            <kt-fallback-row [collection]="data.ticketInfo"></kt-fallback-row>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
      </div>

    </div>
  </div>
</div>
