import { ApiResponse } from '@core/models/api-response.model';
import { tap, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';
import { ApiKeySetting } from '@core/models/api-key-setting.model';

@Injectable({
  providedIn: 'root'
})
export class ApiKeySettingDataService {

  pagination: Pagination
  messages$ = new Subject<any[]>();

  constructor(private http: HttpClient) { }

  getWithQuery(pageParam: string): Observable<ApiKeySetting[]> {
    return this.http.get<ApiResponse>(`/apikey${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getById(id: number): Observable<ApiKeySetting> {
    return this.http.get<ApiResponse>(`/apikey/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  add(data: any) {
    return this.http.post<ApiResponse>(`/apikey`, data).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }

  update(apiKeySetting: ApiKeySetting) {
    return this.http.put<ApiResponse>(`/apikey/${apiKeySetting.id}`, apiKeySetting).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
