<div  class="modal-content">
  <div  class="modal-header">
      <h5 class="modal-title">Deduct</h5>
  </div>
  <div  class="modal-body">
      <div  >
          <div   class="form-group row">
              <label   for="remarks" class="col-lg-3 col-form-label">Remarks:</label>
              <input   type="text" id="remarks" name="remarks" placeholder="Required"  class="col-lg-9 form-control is-invalid">
          </div>
          <div   class="form-group row">
              <label   class="col-lg-3 control-label">User:</label> <span   class="col-lg-9"> (ting01)</span></div>
          <div   class="form-group row">
              <label   for="wallet" class="col-lg-3 col-form-label">
                  Wallet Balance:</label>
              <input   id="wallet" name="wallet"  disabled="disabled" class="col-lg-9 form-control">
          </div>
          <div   class="form-group row">
              <label   for="amount" class="col-lg-3 col-form-label">Amount:</label>
              <input   type="number" (wheel)="false" id="amount" name="amount" min="0.01"  class="col-lg-9 form-control">
          </div>
          <div   class="form-group row">
              <label   class="col-lg-3 col-form-label">Option:</label>
              <div   class="col-lg-9">
                  <div   class="kt-radio-list">
                      <label   class="kt-radio">
                          <input   type="radio" name="group1" value="0">Deduct<span  ></span></label>
                      <label   class="kt-radio">
                          <input   type="radio" name="group1" value="1"> Create Withdrawal <span  ></span></label>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    <kt-submit-button [isDisabled]="false" [buttonLoading]="false"></kt-submit-button>
  </div>
</div>
