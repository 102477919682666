import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class SampleMessageHttpService {

  constructor(private http: HttpClient) { }

  getSampleMessage(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>('/livechat/samplemessage?paginate=false').pipe(
      map(res => res.data)
    );
  }

}
