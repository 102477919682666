<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Test Fire ({{ data.type == "ftd" ? "FTD Postback Domain" : "Registration Postback Domain" }})</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Test URL<span class="text-danger">*</span></label>
            <input type="text" formControlName="url" class="col-8 form-control" />
          </div>
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label" >Registration Postback Domain<span class="text-danger">*</span></label>
            <input type="text" formControlName="postbackUrl" class="col-8 form-control" readonly/>
          </div>
          <div class="col-12 row">
            <div class="col-4"></div>
            <div class="col-8">
              <div formArrayName="params">
                <div *ngFor="let param of form.get('params')['controls']; let i = index" class="form-group row">
                  <input type="text" class="col-4 ml-2 form-control" [formControl]="param.get('key')" readonly/>
                  <input type="text" class="col-4 ml-2 form-control" [formControl]="param.get('value')" [readonly]="param.get('readonly').value || form.get('empty_value').value ? true : null"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 form-group row">
            <div class="d-flex col-4"></div>
            <div class="d-flex col-8">
              <input type="checkbox" formControlName="empty_value" id="empty_value"/>
              <label for="empty_value" class="ml-2 mb-0">{{ "Trigger a test with empty values." | translate }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [buttonLoading]="buttonLoading" [isDisabled]="!this.form.valid" (confirmed)="onSubmit()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true)"></kt-swal-alert>
