<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Promotion Names</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
          <!-- Currency -->
          <div class="col-12 form-group row">
              <label class="col-4 col-form-label">Currency <span class="text-danger">*</span> </label>
              <select formControlName="currency_id" class="col-8 form-control" (change)="onCurrencyChange()">
                <option [value]="null" [disabled]="true">Please Select</option>
                <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                    {{ value.name }}
                </option>
            </select>
          </div>
          <!-- Locale -->
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Locale <span class="text-danger">*</span> </label>
            <select *ngIf="dropdown.locales" formControlName="settings_locale_id" class="col-8 form-control">
              <option [value]="null" [disabled]="true">Please Select</option>
              <option *ngFor="let value of dropdown.locales | async" [value]="value.id">{{ value.code }}</option>
            </select>
          </div>
          <!-- Promotion Name -->
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Promotion Name <span class="text-danger">*</span> </label>
            <input type="text" class="col-8 form-control" formControlName="promotion_name">
          </div>

          <!--Rewards Name-->
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Rewards Name </label>
            <input type="text" class="col-8 form-control" formControlName="rewards_name">
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.promotionMessage, data.mode)" #focusfield></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
