import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { Audits } from '@core/models/audits.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuditsDataService {

  constructor(
    private http: HttpClient
  ) { }

  getWithQuery(params: string): Observable<Audits[]> {
    return this.http.get<ApiResponse>(`/operator/audits?${params}`).pipe(
      map(res => res.data.rows)
    );
  }
}
