<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header" style="display: inline">
            <div>
                <h5 class="modal-title" style="display: inline">Test Fire History </h5>
            </div>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
          <div class="row mb-4">
            <div class="col-md-3 p-0">
                <div class="col-12 text-muted"><label>Username</label></div>
                <div class="col-12">
                    <h4>{{ data.affiliate.username }} </h4>
                </div>
            </div>
            <div class="col-md-3 p-0">
                <div class="col-12 text-muted"><label>Code</label></div>
                <div class="col-12">
                    <h4>{{ data.affiliate.code === '' ? '-' : data.affiliate.code }}</h4>
                </div>
            </div>
            <div class="col-md-3 p-0">
                <div class="col-12 text-muted"><label>Currency</label></div>
                <div class="col-12">
                    <h4>{{ data.affiliate.currency_code }}</h4>
                </div>
            </div>
            <div class="col-md-3 p-0">
                <div class="col-12 text-muted"><label>Status</label></div>
                <div class="col-12">
                    <h4>
                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide"[ngClass]="'kt-badge--' + data.affiliate.status">
                            {{ data.affiliate.status_name }}
                        </span>
                    </h4>
                </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-12">
                <form class="row align-items-center ml-2" [formGroup]="form">
                  <div class="col-md-1 p-0">
                      <label>Click ID:</label>
                  </div>
                  <div class="col-md-3 p-0">
                      <input type="text" formControlName="click_id" placeholder="Search" class="form-control">
                  </div>
                </form>
            </div>
          </div>

          <div class="kt-section col-12 row mb-3 mt-1 justify-content-between">
            <div class="dropdown dropdown-inline">
                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit(true)"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
            </div>
            <div class="dropdown dropdown-inline">
                <button class="btn btn-warning btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="buttonLoading"><i
                    [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped">
              <thead>
                <tr>
                  <th>Campaign</th>
                  <th>Postback Type</th>
                  <th>Test Fire URL</th>
                  <th>Postback Domain</th>
                  <th>Status</th>
                  <th class="date-column">Date Time</th>
                </tr>
              </thead>
              <tbody *ngIf="(testFirePostbackLogs$ | async) as rows">
                <tr *ngFor="let row of rows">
                  <td>{{ row.campaign_code ? row.campaign_code : '-' }}</td>
                  <td>{{ row.postback_type }} ({{ row.postback_target_type }})</td>
                  <td class="break-long-word"><p>{{ row.test_fire_url }}</p></td>
                  <td class="break-long-word"><p>{{ row.postback_domain }}</p></td>
                  <td class="text-center">
                      <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status_name }}</span>
                  </td>
                  <td>{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}<br>{{ row.created_by ? row.created_by : '-' }}</td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onSubmit(false)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                </select>
                <span class="pagination__desc">
                    Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                </span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        </div>
    </div>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="loadingBar.complete()"></kt-swal-alert>
