import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { Observable, of , forkJoin, Subscription } from 'rxjs';
import { NewRebateReleaseDataService } from '../../services/new-rebate-release-data.service';
import { MemberReportDetailsDialogComponent } from '@views/pages/apps/reports/member-report/dialogs/member-report-details.component';
import { CurrencyTimezone } from "@core/enums/currency-timezone.enum";
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Pagination } from "@core/models/pagination.model";
import { TransactionHttpService } from "@core/services/transaction-http.service";
import { RebateReleaseStatus } from "@core/enums/rebate-release.enum";
import { exhaustMap, map, tap } from "rxjs/operators";
import { AppPermissionService } from "@core/services/app-permission.service";
import { RebateRelease } from "@core/models/rebate-release-model";
import { NewRebateReleaseEntityService } from "../../services/new-rebate-release-entity.service";
import * as moment from 'moment-timezone';
import { AuthHttpService } from '@core/services/auth-http.service';
import Swal from "sweetalert2";
import { DatePipe } from '@angular/common';
@Component({
  selector: 'kt-rebate-list',
  templateUrl: './rebate-list.component.html',
  styleUrls: ['./rebate-list.component.scss'],
})
export class RebateListDialogComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  buttonLoading = false;
  currencyTimezone = CurrencyTimezone;
  checkValidation = false;
  eligibleAmountByCategory = [];
  
  dropdown = {
    gameProviders: this.dropdownHttpService.gameProviders,
    statuses: RebateReleaseStatus,
    dateType: this.dropdownHttpService.rebateDateTypes,
    perPage: this.dropdownHttpService.perPage,
  };

  form: FormGroup;
  pagination: Pagination;
  checkboxForm: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = "";
  dropdownSettings = {};
  messages$ = this.rebateReleaseDataService.messages$;
  ranges = this.transactionHttpService.ranges;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '4.2';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'member_group_name': 'desc',
    'game_provider_name': 'desc',
    'category_name': 'desc',
    'currency': 'desc',
    'start_datetime': 'desc',
    'valid_turnover': 'desc',
    'promo_turnover': 'desc',
    'rate': 'desc',
    'winloss': 'desc',
    'total_rebate_amount': 'desc',
    'release_amount': 'desc',
    'status': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',

  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  loading = true;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  rebateRelease$: Observable<RebateRelease[]>;
  selectedRebate = [];
  availableRebate = [];
  toggleAll: boolean = false;

  // permissions
  private subscriptions = new Subscription();
  canViewMemberDialog: boolean;
  canGenerateRebate: boolean;
  canReleaseRebate: boolean;
  canRejectRebate: boolean;
  canUpdateRebate: boolean;

  displayUsername = null;
  displayStartdate = null;
  displayEnddate = null;
  init = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { rebateRelease : any },
    private rebateReleaseDataService: NewRebateReleaseDataService,
    public dialogRef: MatDialogRef<RebateListDialogComponent>,
    public dialog: MatDialog,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private appPermissionService: AppPermissionService,
    private rebateReleaseEntityService: NewRebateReleaseEntityService,
    private fb: FormBuilder,
    private authHttpService: AuthHttpService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.formInit();
    this.onSubmit(null,true);

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.rebate_releases_view_member_dialog_v2;
      this.canGenerateRebate = appPermissions.generate_rebates_v2;
      this.canReleaseRebate = appPermissions.release_rebates_v2;
      this.canRejectRebate = appPermissions.reject_rebates_v2;
      this.canUpdateRebate = appPermissions.update_rebates_setting;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private formInit() {
 
    var start_datetime = moment(this.data.rebateRelease['start_datetime']).tz(this.timezone, true).format('YYYY-MM-DD HH:mm:ss');
    var end_datetime = moment(this.data.rebateRelease['end_datetime']).tz(this.timezone, true).format('YYYY-MM-DD HH:mm:ss');

    this.form = new FormGroup({
      username: new FormControl(this.data.rebateRelease.username),
      start_datetime: new FormControl(start_datetime),
      end_datetime: new FormControl(end_datetime),
      defaultDate: new FormControl({
        startDate: start_datetime,
        endDate: end_datetime,
      }), // Do not remove: For Clearing The Range,
      date_type: new FormControl(this.dropdown.dateType[0].value),
      status: new FormGroup(this.getStatusControls()),
      check_duplicate: new FormControl(true),
      // rebate_release_id: new FormControl(null)
    });
  }

  private getStatusControls(isReset: boolean = false) {
    let statusControls = {};
    const statusValues = (Object.values(this.dropdown.statuses)).filter(row => +row >= 0);

    statusValues.forEach(row => {
      if (isReset) {
        statusControls = { ...statusControls, [row]: (row === 0 || row == '1' ? true : false) }
      } else {
        statusControls = { ...statusControls, [row]: new FormControl(row === 0 || row == '1' ? true : false) };
      }
    });

    // Pending selected by default
    return statusControls;
  }

  onCloseDialog(event?: Event) {
    this.onViewPageBy(this.page);
    this.dialogRef.close();
  }

  onOpenDialog(row :any) {
    this.dropdown.gameProviders.subscribe((res) => {
    
      const data = {
        game_account: row.game_account,
        game_provider_id: (res.find((x) => { return x.code === ( row?.game_provider_code) }))?.id,
        game_provider_code: row?.game_provider_code,
        username: row?.username,
        member_account_id: row?.member_account_id,
        start_date_time: row.start_datetime ,
        end_date_time: row.end_datetime ,
        type: 'member-report',
        viewType: 'dialog',
        timezone: this.timezone,
        currency_code: row.currency,
        game_type: (res.find((x) => { return x.code === row.game_provider_code })).type,
      };
      this.openDialogBy(MemberReportDetailsDialogComponent, data);
    });

  }

  private openDialogBy(componentRef: any, data?: { game_account: string, game_provider_id: number, game_provider_code: string, username: string, start_date_time: string, end_date_time: string, type: string, currency_code: string, timezone: string, game_type: number, member_account_id: number }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '90vw',
      height: '90vh',
      data: {
        game_account: data.game_account,
        game_provider_id: data.game_provider_id,
        game_provider_code: data.game_provider_code,
        username: data.username,
        member_account_id: data.member_account_id,
        start_date_time: data.start_date_time,
        end_date_time: data.end_date_time,
        type: data.type,
        currency_code: data.currency_code,
        timezone: data.timezone,
        game_type: data.game_type,
      }
    });
  }

  
  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : "";
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        exhaustMap((data: any) => {

          // Status checkbox as query string
          let statusParameter = ''
          Object.keys(data.status).forEach((row, index) => {
            if (data.status[row] === true) {
              statusParameter += `&status[${index}]=${row}`
            }
          });
          delete data.status;

          return (this.rebateRelease$ = this.rebateReleaseEntityService
            .getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}${statusParameter}`)
            .pipe(
              tap((res) => {
                this.loading = false;
                this.dataLength = res.length;
                this.toggleAll = false;
                this.selectedRebate = [];
                this.availableRebate = res;
                this.checkboxFormInit();
                this.pagination = this.rebateReleaseDataService.pagination;
              })
            ));
        })
      )
      .subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach((key) => {
      if (
        formData[key] !== "" &&
        formData[key] !== null &&
        formData[key] !== undefined &&
        key !== "defaultDate"
      ) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private checkboxFormInit() {
    let prepareForm = {};
    this.availableRebate.forEach(item => {
      prepareForm = { ...prepareForm, [item.id]: [{ value: false, disabled: item.status_id != 0 }] }
    });
    this.checkboxForm = this.fb.group(prepareForm);

    this.checkboxForm.valueChanges.subscribe(val => {
      this.selectedRebate = [];
      Object.keys(val).forEach(key => {
        if (val[key]) {
          this.selectedRebate.push(key);
        }
      })
    })
  }

  onDateRange(event: any, type: string) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onSubmit(clearSearch?: boolean,init = false) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        exhaustMap((data: any) => {
          if (data['start_datetime']) {
            data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
            data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          }

          if(init == false){
            delete data['rebate_release_id'];
          }

          // Status checkbox as query string
          let statusParameter = ''
          Object.keys(data.status).forEach((row, index) => {
            if (data.status[row] === true) {
              statusParameter += `&status[${index}]=${row}`
            }
          });
          delete data.status;

          this.params = Object.keys(data)
            .map((key) => key + "=" + data[key])
            .join("&");
          const parameter = this.params ? "&" + this.params : "";
          return (this.rebateRelease$ = this.rebateReleaseEntityService
            .getWithQuery(`?perPage=${this.pageSize}${parameter}${statusParameter}`)
            .pipe(
              tap((res) => {
                this.loading = false;
                this.clearBtnLoading = false;
                this.searchBtnLoading = false;
                this.dataLength = res.length;
                this.toggleAll = false;
                this.selectedRebate = [];
                this.availableRebate = res;
                this.checkboxFormInit();
                this.page = 1;
                this.pagination = this.rebateReleaseDataService.pagination;

                if(this.form.value.username) this.displayUsername = this.form.value.username;
                if(this.form.value.start_datetime) this.displayStartdate = this.form.value.start_datetime;
                if(this.form.value.end_datetime) this.displayEnddate = this.form.value.end_datetime;
                if(init == false) this.init = false;
              })
            ));
        })
      )
      .subscribe();
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }
    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  checkboxToggleAll() {
    let patchValue = {};
    if (this.toggleAll) {
      this.availableRebate.forEach(item => {
        if (item.status_id == 0) {
          patchValue = { ...patchValue, [item.id]: true };
        }
      });
    } else {
      this.availableRebate.forEach(item => {
        patchValue = { ...patchValue, [item.id]: false };
      });
    }
    this.checkboxForm.patchValue(patchValue);
  }

  onClearDate(type: string) {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({
        start_datetime: null,
        end_datetime: null,
        defaultDate: null,
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;

    this.form.patchValue({
      username: null,
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
      status: this.getStatusControls(true),
      date_type: this.dropdown.dateType[0].value,
      rebate_release_id: null
    });
    this.onSubmit(true);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  rebateAction(action: string, mode: string, id?: number,) {
    let message = '';
    let data = {};
    switch (mode) {
      case 'single':
        message = `Do you want to ${action} the rebate ?`;
        data = { rebate_id: [id] };
        break;
      case 'selected':
        message = `Do you want to ${action} the selected rebate below ?`;
        data = { rebate_id: this.selectedRebate };
        
        break;
      case 'all':
        message = `Do you want to ${action} all the rebate ?`;
        data = {};
        break;
    }

    if(this.init == true){
      data['rebate_release_id'] = this.data.rebateRelease.id;
    }
    
    data['check_duplicate'] = true;

    Swal.fire({
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (action === 'release') {
          this.rebateReleaseDataService.release(data).subscribe();
        } else {
          this.rebateReleaseDataService.reject(data).subscribe();
        }
      }
    });
  }
}
