<form class="kt-form" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Merchant Banks</h5>
        </div>
        <div class="modal-body">
            <label class="control-label"><strong>Add/Remove Merchant Bank</strong></label>
            <div class="row">
                <div class="col-md-8 p-3 ml-n3">
                    <kt-dropdown-wo-lazyload
                        [form] = 'form'
                        [dropdownList] = 'banksDropdownList'
                        [dropdownSettings] = 'banksDropdownSettings'
                        [formName] = "'merchant_bank_id'"
                        [selectionAttributes] = "'id'"
                        [selectedItems] = 'banksSelectedItems'>
                    </kt-dropdown-wo-lazyload>
                </div>
                <div class="col-md-4 p-3">
                    <kt-submit-button [isDisabled]="false" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
                </div>
            </div>
            <div class="form-group p-0">
                <!-- table -->
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Account Number</th>
                                    <th>Account Name</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(memberGroup | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{row.id}}</td>
                                    <td> {{ row.account_number }}</td>
                                    <td>{{row.account_name}}</td>
                                </tr>
                                <kt-fallback-row [collection]="rows"></kt-fallback-row>
                            </tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" formControlName="perPage" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
                <!-- table -->
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        </div>
    </div>
  </form>

  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
