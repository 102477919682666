import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountSettingsDialogComponent } from './dialogs/account-settings/account-settings.component';
import { AccountPasswordDialogComponent } from './dialogs/account-password/account-password.component';
import { AccountTimezoneDialogComponent } from './dialogs/account-timezone/account-timezone.component';
import { AccountTwoFADialogComponent } from './dialogs/account-twofa/account-twofa.component';

const routes: Routes = [
  {
    path: 'settings',
    component: AccountSettingsDialogComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  entryComponents: [
    AccountPasswordDialogComponent,
    AccountTimezoneDialogComponent,
    AccountTwoFADialogComponent
  ]
})
export class AccountRoutingModule { }
