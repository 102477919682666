import { EditorService } from '../../../../../../../core/services/editor.service';
import { map, catchError, tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, forkJoin } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AfffiliateMessageTemplateDataService } from '../../services/message-template-affiliate-data.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Component, OnInit, Inject, ViewChildren, QueryList } from '@angular/core';
import { MessageTemplateDataService } from '../../services/message-template-data.service';
import { MessageTemplate } from '@core/models/message-template.model';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { EventEmitterService } from '@core/services/event-emitter.service';
declare var $: any;

@Component({
  selector: 'kt-affiliate-message-template-edit',
  templateUrl: './affiliate-message-template-edit.component.html',
  styleUrls: ['./affiliate-message-template-edit.component.scss']
})
export class AffiliateMessageTemplateEditComponent implements OnInit {
  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    locales: this.dropdownHttpService.locales,
    perPage: this.dropdownHttpService.perPage,
    type: this.dropdownHttpService.messageTemplateType,
    section: [],
    locales$: this.messageTemplateDataService.locales$
  };

  messages$ = this.messageTemplateDataService.messages$;
  formFields = [];
  formFieldsSelectedItems = [];
  details: any;
  refreshStatus: boolean;
  buttonLoading = false;
  section: string = '';
  type: string = '';
  name: string = '';
  locale: string = '';
  code: string = '';

  // permissions
  canApproveMessageTemplate: boolean;
  canRejectMessageTemplate: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  private localesSub = new Subscription();

  languages: { id: number; code: string; name: string }[] = [];

  icon: SweetAlertIcon = 'info';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { messageTemplate: any, mode: 'create' | 'edit',row },
    private dropdownHttpService: DropdownHttpService,
    private messageTemplateDataService: MessageTemplateDataService,
    public loadingBar: LoadingBarService,
    public dialogRef: MatDialogRef<AffiliateMessageTemplateEditComponent>,
    private appPermissionService: AppPermissionService,
    private affiliateMessageTemplateDataService: AfffiliateMessageTemplateDataService,
    private eventEmitterService: EventEmitterService,
  ) { }

  ngOnInit() {
    this.dropdownHttpService.fillInForms.subscribe(res => {
      Object.keys(res).forEach(key => {
        this.formFields.push({
          name: res[key],
          id: key
        });
      });
    });

    this.localesSub = this.dropdown.locales$.pipe(tap(res => this.languages = res)).subscribe(res => {
      res.forEach(item => {
        if (this.data.mode === 'edit') {
          let language_id = item.id;
          let details = null;
          if (this.data.messageTemplate.message_details[language_id]) {
            Object.keys(this.data.messageTemplate.message_details[language_id]).forEach((key) => {
              details = {
                ...details,
                [key]:
                  this.data.messageTemplate.message_details[language_id][key]
              };
            });
          }
          this.details = {
            ...this.details,
            [language_id]: {
              ...details
            }
          };
        }
      })
    });

    this.formInit();
    this.dropdownHttpService.messageTemplateSection(this.data.mode).subscribe(res => {
      this.dropdown.section = res;
      this.updateForm();
    });

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canApproveMessageTemplate = appPermissions.approve_affiliate_message_template;
      this.canRejectMessageTemplate = appPermissions.reject_affiliate_message_template;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.localesSub.unsubscribe();
    this.onRefresh();
  }


  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  async onUpdate(messageTemplate: MessageTemplate, mode?: string) {
    this.buttonLoading = true;

    let title = mode =='approve' ? 'Approve Message Template':'Reject Message Template';
    let messages = null;
    let iconColor = mode =='approve' ? null  : '#f27474' ;

    if(mode =='approve'){
      messages = '<div class="mt-3 mb-3">Remark (Optional)</div>'+
                 '<input id="swal-remark" class="swal2-input m-0">'+
                 '<div class="mt-3">Do you want to approve this message template?</div>'
    }else if(mode =='update'){
      var statusName = this.form.value.status == 1 ? 'active' : 'inactive';
      title = this.form.value.status == 1 ? 'Active Message Template' : 'Inactive Message Template';
      messages = '<div class="mt-3 mb-3">Remark (Optional)</div>'+
                 '<input id="swal-remark" class="swal2-input m-0">'+
                 '<div class="mt-3">Do you want to update this message template to '+statusName +'?</div>'
    }else{
       messages = '<div class="text-left mt-3 mb-3 text-danger">Remark</div>'+
                  '<input id="swal-remark" class="swal2-input mt-0">' +
                  '<div class="text-left">Reason (Optional)</div>'+
                  '<textarea rows="3" id="swal-reason" class="swal2-input pt-2"></textarea>'+
                  '<div class="text-left mt-3">Do you want to reject this message template?</div>'
    }
    Swal.fire({
      title: title,
      html: messages,
      icon: this.icon,
      iconColor: iconColor,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      reverseButtons: true,
      heightAuto: false,
      focusConfirm: false,
      onOpen: function () {
        // if mode reject disable the button until the remark in fill;
        if(mode =='reject'){
          // Swal.disableButtons();
          $(".swal2-confirm").attr('disabled', true);
          document.querySelector('#swal-remark').addEventListener('keyup', function(e) {
            var remark =(document.getElementById('swal-remark') as HTMLInputElement).value;
            if(remark == null || remark == '') {
              // Swal.disableButtons();
              $(".swal2-confirm").attr('disabled', true);
            } else {
              // Swal.enableButtons()
              $(".swal2-confirm").attr('disabled', false);
            }
          })
        }
      }
    }).then(result => {

      var remark =(document.getElementById('swal-remark') as HTMLInputElement).value;
      // if mode reject get reason input
      if(mode =='reject'){
        var reason = (document.getElementById('swal-reason') as HTMLInputElement).value;
      }
      const data = {
        id: messageTemplate['message_template'].id,
        remark: remark
      };

      if (result.isConfirmed) {
         // status active
        data['status'] = 1;

        if(mode =='reject'){
          // status reject 
          // this.form.value.status  !== 3 mean is pending approval , for pending approval update to reject
          data['status'] = this.form.value.status  == 3 ? 1  : 2;
          data['reason'] = reason;
          data['update_to_reject'] = true;
        }else if(mode =='update'){
          // status active or inactive
          data['status'] = this.form.value.status;
          data['reason'] = reason;

        }

        Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
        this.affiliateMessageTemplateDataService.statusUpdate(data).pipe(
            tap((res: any) => {
              this.buttonLoading = false;
              this.messages$.next([...res.message]);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
        ).subscribe();
      }

      this.buttonLoading = false;
    });
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.eventEmitterService.onUpdateAffiliateMessageTemplateTabCount();
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    if (this.data.mode === 'edit') {
      this.code = this.data.messageTemplate.message_template.code;
      this.section = this.data.messageTemplate.message_template.section_name + '.';
      this.type = this.data.messageTemplate.message_template.type_name + '.';
      this.name = this.data.messageTemplate.message_template.name + '.';
    }
    let name = this.data.mode === 'create' ? null : this.data.messageTemplate.message_template.name;
    let section = this.data.mode === 'create' ? null : this.data.messageTemplate.message_template.section;
    let type = this.data.mode === 'create' ? null : this.data.messageTemplate.message_template.type;
    let status = this.data.mode === 'create' ? 1 : this.data.messageTemplate.message_template.status;

    const validatorOptions = [];

    const buildSubDetails = () => {
      let details = {};
      const isEditMode = () => {
        let edit = {};
        if (this.data.mode === 'edit') {
          edit = { ...edit, id: new FormControl(null) };
        }
        return edit;
      };

      this.languages.map(element => {
        const detailsGroup = new FormGroup({
          settings_locale_id: new FormControl(element.id, validatorOptions),
          subject: new FormControl(null, validatorOptions),
          message: new FormControl(null),
          ...isEditMode()
        });
        details = { ...details, [element.id]: detailsGroup };
      });
      return details;
    };

    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      section: new FormControl(section, [Validators.required]),
      type: new FormControl(type, [Validators.required]),
      status: new FormControl(status),
      details: new FormGroup(buildSubDetails()),
    });
    if (this.data.mode === 'edit') {
      const content = this.data.messageTemplate.message_template;
      const details = this.details;
      this.form.patchValue({ ...content, details});
    }

  }

  private updateForm() {
    if (this.data.mode === 'edit') {
      this.form.controls.name.disable();
      this.form.controls.section.disable();
      this.form.controls.type.disable();
    }
  }
}
