import { UploadHttpService } from '@core/services/upload-http.service';
import { tap, catchError } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { GameProvider } from '@core/models/game-provider.model';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { GameProviderDataService } from '../../game-providers/services/game-provider-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
@Component({
  selector: 'kt-mini-games-edit',
  templateUrl: './mini-games-edit.component.html',
  styleUrls: ['./mini-games-edit.component.scss']
})
export class MiniGamesEditDialogComponent implements OnInit, OnDestroy {

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;
  form: FormGroup;

  messages$ = this.gameProviderDataService.messages$;
  refreshStatus: boolean;
  subCategoryDropdown: any;
  imagePreview = [];

  dropdown = {
    status: this.dropdownHttpService.miniGameStatus,
    // gameTag: [],
  }

  // selectedGameTags = [];
  // gameTagsDropdownSettings = {};

  private subscription = new Subscription();
  private datePickerSubscription = new Subscription();

  buttonLoading = false;
  imageUploading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { gameProvider: any, mode: string, maintenanceMode?: string },
    public dialogRef: MatDialogRef<MiniGamesEditDialogComponent>,
    private gameProviderDataService: GameProviderDataService,
    private uploadService: UploadHttpService,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit() {
    this.gameProviderDataService.getSubCategory(`?game_provider_code=${this.data.gameProvider.provider_code}&category_code=${this.data.gameProvider.category_code}`).subscribe(res => {
      this.subCategoryDropdown = res;
    });
    this.formInit();
    // this.setGameTagsDropdown();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    this.onRefresh();
  }

  onUploadFile(event: any) {
    this.imageUploading = true;
    // Patch the value to null first to trigger the validation function
    this.form.patchValue({
      image_path: null
    });
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'slot_game');
    this.uploadService.upload(formData).subscribe(res => {
      this.imagePreview = res;
      this.form.patchValue({
        image_path: this.imagePreview[0]
      });
      this.form.get('image_path').markAsDirty();
      this.form.markAllAsTouched();
      this.imageUploading = false;
    });
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(gameProvider: GameProvider) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    // Create variable to hold the value will be pass to API
    const data = {
      id: gameProvider.id ? gameProvider.id : null,
      ...this.form.value,
      category_code: this.data.gameProvider.category_code,
      desktop: Number(this.form.value.desktop),
      mobile: Number(this.form.value.mobile)
    };
    // Remove unused param
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] === 'Invalid date') && delete data[key]);
    if (this.data.mode === 'create') {
      this.gameProviderDataService.addGame(data).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else {
      this.gameProviderDataService.updateGame(data).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }

    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    let providerCode = this.data.gameProvider.provider_code;
    let categoryName = null;
    let name = null;
    let code = null;
    let imagePath = null;
    let statusId = 1;
    let desktop = 1;
    let mobile = 1;
    let frb_code = null;
    let game_tag = [];

    if (this.data.mode === 'edit') {
      // let allGameTags = this.data.gameProvider.position.map(x => ({'id': x.tag_id, 'name': x.tag_name}));
      // let filteredGameTags = null;

      // if (allGameTags.length > 0) {
      //   filteredGameTags = allGameTags.reduce((unique, o) => {
      //     if (!unique.some(obj => obj.id == o.id && obj.name == o.name)) {
      //       unique.push(o);
      //     }
      //     return unique;
      //   }, []);

      //   Object.keys(filteredGameTags).forEach((key) =>
      //     this.selectedGameTags[key] = {
      //       name: filteredGameTags[key].name,
      //       id: filteredGameTags[key].id
      //     }
      //   );
      // }

      // let gameTagId = this.data.gameProvider.position.map(x => x.tag_id);
      // if (gameTagId.length > 0) {
      //   gameTagId = [...new Set(gameTagId)];
      // }
      // else {
      //   gameTagId = [];
      // }

      categoryName = this.data.gameProvider.game_sub_category_name;
      providerCode = this.data.gameProvider.game_provider_code;
      name = this.data.gameProvider.name;
      code = this.data.gameProvider.code;
      statusId = this.data.gameProvider.status,
      imagePath = this.data.gameProvider.local_image_path ? this.data.gameProvider.local_image_path : this.data.gameProvider.image_path
      desktop = this.data.gameProvider.desktop;
      mobile = this.data.gameProvider.mobile;
      frb_code = this.data.gameProvider.frb_code ?? null;
      // game_tag = gameTagId;
    }

    this.form = new FormGroup({
      id: new FormControl(this.data.gameProvider.id, [Validators.required]),
      game_category_code: new FormControl(this.data.gameProvider.category_code, [Validators.required]),
      game_category_name: new FormControl(this.data.gameProvider.game_category_name, [Validators.required]),
      game_provider_code: new FormControl(providerCode, [Validators.required]),
      game_provider_name: new FormControl(this.data.gameProvider.game_provider_name, [Validators.required]),
      game_sub_category_name: new FormControl(categoryName, [Validators.required]),
      image_path: new FormControl(imagePath, [Validators.required]),
      status: new FormControl(statusId),
      name: new FormControl(name, [Validators.required]),
      code: new FormControl(code, [Validators.required]),
      desktop: new FormControl(Boolean(desktop)),
      mobile: new FormControl(Boolean(mobile)),
      frb_code: new FormControl(frb_code),
      // game_tag: new FormControl(game_tag),
    });

    // this.form.get('game_tag').valueChanges.subscribe((value) => {
    //   const gameTagALL = this.dropdown.gameTag.filter((gameTag) => {
    //     return gameTag.name === 'All';
    //   });
    //   const gameTagAllExist = value.includes(gameTagALL[0].id);
    //   if (gameTagAllExist === false) {
    //     value.push(gameTagALL[0].id) 
    //     this.form.patchValue({ game_tag: value});
    //     this.selectedGameTags = [...this.selectedGameTags, {
    //       name: 'All',
    //       id: gameTagALL[0].id
    //     }]
    //   }
    // });
  }

  // onSelectGameTag(newSelectedGameTag: any) {
  //   this.selectedGameTags = newSelectedGameTag;
  // }

  // private setGameTagsDropdown(){
    // this.dropdownHttpService.gameTags.subscribe(res => {
    //   let tags = res.map(x => ({'id': x.id, 'name': x.name}));
    //   tags = tags.sort(function(a, b) {
    //     return a.name.localeCompare(b.name);
    //   });

    //   this.dropdown.gameTag = tags;
    // });

    // this.gameTagsDropdownSettings = {
    //   autoPosition: true,
    //   maxHeight: 150,
    //   singleSelection: false,
    //   text: 'Please Select',
    //   enableFilterSelectAll: true,
    //   enableSearchFilter: true,
    //   classes: 'dropdown',
    //   primaryKey: 'id',
    //   labelKey: 'name',
    //   lazyLoading: true,
    //   noDataLabel: '',
    //   showCheckbox: false
    // };
  // }
}
