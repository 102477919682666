import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { MarketingReport } from '@core/models/marketing-report.model';

@Injectable()
export class MarketingReportEntityService extends EntityCollectionServiceBase<MarketingReport> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('MarketingReport', serviceElementsFactory);
  }
}
