import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthHttpService } from '@core/services/auth-http.service';
import { Subscription, Subject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiResponse } from '@core/models/api-response.model';
import { REGEX_PATTERNS } from '@utils/regex.constants';

@Component({
  templateUrl: './account-password.component.html',
  styleUrls: ['./account-password.component.scss']
})
export class AccountPasswordDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = new Subject<any[]>();
  hasError: boolean;
  subscription = new Subscription();
  
  showConfirmPasswordHint = false;
  passwordCheck = {
    contain_numbers: false,
    contain_uppercase: false,
    contain_lowercase: false,
    contain_special_character: false,
    is_valid_character: true,
  };
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<AccountPasswordDialogComponent>,
    private authHttpService: AuthHttpService
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onKeyPress(event){
    if (event.keyCode === 13) {
      this.onChangePassword();
    }
  }

  onChangePassword() {
    this.loading = true;

    if( !this.form.valid || this.showConfirmPasswordHint ) {
      this.loading = false;
      return;
    }

    this.form.disable();
    this.subscription = this.authHttpService.changePassword({ password: this.form.value.password, password_confirmation: this.form.value.password_confirmation }).pipe(
      tap((res) => {
        this.hasError = !!res.success;
        this.messages$.next([...res.message]);
        this.form.enable();
        this.loading = false;
      }),
      catchError((error) => {
        this.form.enable();
        this.loading = false;
        throw error;
    })
    ).subscribe();
    
  }

  private formInit() {
    this.form = new FormGroup({
      password: new FormControl(null, Validators.compose([
        Validators.required,
        // at least 8 characters long, contain uppercase and lowercase letters, a number, and a special character available on a standard keyboard
        Validators.pattern(REGEX_PATTERNS.password),
    ])),
      password_confirmation: new FormControl(null, [Validators.required]),
    });
  }

  onCheckPasswordFormat(password: any) {
    // Regex check for the guidance
    this.passwordCheck = {
      contain_numbers: REGEX_PATTERNS.contain_numbers.test(password),
      contain_uppercase: REGEX_PATTERNS.contain_uppercase.test(password),
      contain_lowercase: REGEX_PATTERNS.contain_lowercase.test(password),
      contain_special_character: REGEX_PATTERNS.contain_special_character.test(password),
      is_valid_character: REGEX_PATTERNS.is_valid_character.test(password),
    };

    // Matching of confirm_password field
    this.showConfirmPasswordHint = false;
    if( this.form.value.password_confirmation !== null && this.form.value.password_confirmation !== '' ) {
      if( this.form.value.password_confirmation !== this.form.value.password ) {
        this.showConfirmPasswordHint = true;
      }
    }
  }

}
