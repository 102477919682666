<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div  class="kt-portlet kt-portlet--mobile">
        <div  class="kt-portlet__body">
            <mat-tab-group>
                <mat-tab *ngIf="canViewWalletTab">
                    <!-- E-Wallet Tab -->
                    <ng-template mat-tab-label>
                        Wallet
                    </ng-template>
                    <kt-member-bank-account-verification></kt-member-bank-account-verification>
                </mat-tab>
                <mat-tab *ngIf="canViewAccountTab">
                    <!-- Member Account Tab -->
                    <ng-template mat-tab-label>
                        Account
                    </ng-template>
                    <kt-member-verification></kt-member-verification>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>