<div id="kt_content" class="kt-content kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <!-- Filter -->
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1">
            <form class="align-items-center" [formGroup]="form">
              <div class="row mb-2">
                <!-- Telemarketer -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ "Telemarketer" | translate }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <kt-dropdown-wo-lazyload [form]="form" [dropdownList]="telemarketerList" [selectedItems]="selectedTelemarketer" [dropdownSettings]="telemarketerDropdownSettings" [formName]="'telemarketer'" [selectionAttributes]="'username'"> </kt-dropdown-wo-lazyload>
                </div>
                <!-- Username -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ "Username" | translate }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="username" placeholder="{{ 'Search' | translate }}" class="form-control" />
                </div>
                <!-- Contact -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ "Contact" | translate }}: </label>
                </div>
                <div class="col-md-4 kt-form__control">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <select class="form-control" (change)="onContactType($event.target.value)">
                        <option value="Mobile" selected>{{ "Mobile" | translate }}</option>
                        <option value="Email">{{ "Email" | translate }}</option>
                      </select>
                    </div>
                    <input type="text" (change)="onContact($event)" placeholder="{{ 'Search' | translate }}" class="form-control" [disabled]="selectedContactType === '' && selectedContactType === null" #contactRef />
                    <input type="hidden" formControlName="mobile" />
                    <input type="hidden" formControlName="email" />
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <!-- Currency -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ "Currency" | translate }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="currency_id" class="form-control">
                    <option value="all">{{ "All" | translate }}</option>
                    <option [value]="value.id" *ngFor="let value of dropdown.currencies | async">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
                <!-- Registration Site -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ "Registration Site" | translate }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="registration_site" placeholder="{{ 'Search' | translate }}" class="form-control" />
                </div>
                <!-- Date Type & Date Range -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ "Date" | translate }}: </label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <select class="form-control" formControlName="date_type">
                        <option value="registration_date" selected>{{ "Registration Date" | translate }}</option>
                        <option value="remark_date">{{ "Remark Date" | translate }}</option>
                      </select>
                    </div>
                    <input type="text" class="form-control" placeholder="{{ 'Search' | translate }}" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" ngxDaterangepickerMd />
                    <span class="input-group-append">
                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <!-- Keyword -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ "Keyword" | translate }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="keyword" placeholder="{{ 'Search' | translate }}" class="form-control" />
                </div>
                <!-- Lead ID -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ "Lead ID" | translate }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="lead_id" placeholder="{{ 'Search' | translate }}" class="form-control" />
                </div>
                <!-- Timezone -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ "Timezone" | translate }}:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <kt-dropdown-wo-lazyload #timezone (click)="onChangeTimezone(timezone.selectedItems)" [form]="form" [dropdownList]="timezonesDropdownList" [dropdownSettings]="timezonesDropdownSettings" [formName]="'timezone'" [selectionAttributes]="'timezone'" [selectedItems]="timezonesSelectedItems"> </kt-dropdown-wo-lazyload>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Dummy Account:<i class="fa fa-info-circle ml-1" placement="bottom" [ngbTooltip]="dummyToolTip" tooltipClass="tooltip-class"></i></label>
                </div>  
                <div class="col-md-2 kt-form__control">
                  <label class="switch mr-3" style="align-self: center;">
                    <input type="checkbox" formControlName="dummy" [checked]="form.value.dummy == 1" (change)="onChangeDummy($event)">
                      <div class="slider round">
                        <span class="on">INC</span>
                        <span class="off">EXC</span>
                      </div>
                  </label>
                </div> 
                <ng-template #dummyToolTip>
                  <div class="text-left">
                    INC (Include): Include dummy accounts.<br>EXC (Exclude): Exclude dummy accounts.
                  </div>
                </ng-template>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()" (click)="contactRef.value = ''"></kt-clear-button>
        </div>
        <button *ngIf="canExportLeadRemarks" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="buttonLoading"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>{{ 'Export' | translate }}</button>
      </div>
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="sort-enabled" (click)="onSortColumn('lead_id')" [ngClass]="sortingConfig.lead_id" [class.sort-selected]="sortingSelection.sort_by === 'lead_id'">{{ "Lead ID" | translate }}</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">{{ "Currency" | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('telemarketer')" [ngClass]="sortingConfig.telemarketer" [class.sort-selected]="sortingSelection.sort_by === 'telemarketer'">{{ "Telemarketer" | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">{{ "Username" | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">{{ "Name" | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('mobile')" [ngClass]="sortingConfig.mobile" [ngClass]="sortingConfig.mobile" [class.sort-selected]="sortingSelection.sort_by === 'mobile'">{{ "Mobile" | translate }}</th>
                  <th class="date-column sort-enabled" (click)="onSortColumn('remark')" [ngClass]="sortingConfig.remark" [class.sort-selected]="sortingSelection.sort_by === 'remark'">{{ "Remark" | translate }}</th>
                  <th>{{ 'Evidence' | translate }}</th>
                  <th class="date-column sort-enabled" (click)="onSortColumn('remark_date')" [ngClass]="sortingConfig.remark_date" [class.sort-selected]="sortingSelection.sort_by === 'remark_date'">{{ "Remark Date" | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('registration_created_at')" [ngClass]="sortingConfig.registration_created_at" [class.sort-selected]="sortingSelection.sort_by === 'registration_created_at'">
                    {{ "Registration Info" | translate }}
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="LeadRemarks$ | async as rows">
                <tr *ngFor="let row of rows">
                  <td>{{ row.lead_id ? row.lead_id : "-" }}</td>
                  <td class="text-center">{{ row.currency }}</td>
                  <td class="text-left">{{ row.telemarketer }}</td>
                  <td [ngClass]="row.username && row.dummy == 1 ? 'text-dummy-unclickable text-left' : (row.username && row.dummy == 0 ? 'text-primary text-left' : 'text-center')">{{ row.username ? row.username : "-" }}</td>
                  <td [ngClass]="row.name ? 'text-left' : 'text-center'">{{ row.name ? row.name : "-" }}</td>
                  <td [ngClass]="row.mobile ? 'text-left' : 'text-center'">{{ row.mobile ? row.mobile : "-" }}</td>
                  <td class="text-left">{{ row.remark }}</td>
                  <td class="text-center">
                    <ng-container *ngIf="row.remark_asset.length > 0 else NoEvidence">
                        <button matTooltip="Show Evidence" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"
                            (click)="onOpenDialog(row.remark_asset)">
                            <span class="text-collapsed"><i class="fas fa-images"></i></span>
                        </button>
                    </ng-container>
                    <ng-template #NoEvidence>
                        - 
                    </ng-template>
                </td>
                  <td class="text-left">{{ timezoneDateWithTimezone(row.remark_date, this.form.value.timezone, "YYYY-MM-DD HH:mm") }}</td>
                  <td class="width-170">
                    <ng-container *ngIf="row.registration_ip !== null || row.registration_created_at !== null || row.registration_site !== null; else nullTemplate">
                      <span class="no-wrap">{{ timezoneDateWithTimezone(row.registration_created_at, this.form.value.timezone, "YYYY-MM-DD HH:mm") }}</span
                      ><br />
                      <span placement="top" [matTooltip]="row.registration_ip" container="body" class="text-ellipsis"> {{ row.registration_ip }}</span>
                      <ng-container *ngIf="urlIsValid(row.registration_site); else invalidRegistrationSite">
                        <a [href]="row.registration_site" target="_blank"> {{ getUrl(row.registration_site) }}</a><br>
                      </ng-container>
                      <ng-template #invalidRegistrationSite>
                          <a> {{ row.registration_site }}</a><br>
                      </ng-template>
                      {{ row.registration_locale_code }}
                    </ng-container>
                    <ng-template #nullTemplate> - <br /> </ng-template>
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)"> </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc"> {{ "Showing" | translate }} {{ pagination.from }} {{ "to" | translate }} {{ pagination.to }} {{ "of" | translate }} {{ pagination.total | number : "1.0" }} {{ "records" | translate }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="loadingBar.complete()"></kt-swal-alert>
