import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { CampaignAdCostReport } from '@core/models/campaign-ad-cost-report.model';
import { CampaignAdCostReportEntityService } from './campaign-ad-cost-report-entity.service';

@Injectable()
export class CampaignAdCostReportDataService extends DefaultDataService<CampaignAdCostReport>  {

  messages$ = new Subject<any[]>();
  pagination: Pagination;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('CampaignAdCostReport', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<any[]> {
    return this.http.get<ApiResponse>('/campaign/adcost/report').pipe(
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<any[]>{
    return this.http.get<ApiResponse>(`/campaign/adcost/report${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  exportReport(pageParam: string) {
    return this.http.get<ApiResponse>(`/campaign/adcost/export${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getCampaignClicks(pageParam: string): Observable<any[]>{
    return this.http.get<ApiResponse>(`/campaign/adcost/campaignclick${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }
  
  private paginationInit(res: any) {
    if(res) {
      return this.pagination = res.data.paginations;
    }
  }
}
