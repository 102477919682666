import { Component, Inject, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';

@Component({
  selector: 'kt-parlay-info',
  templateUrl: './parlay-info.component.html',
  styleUrls: ['./parlay-info.component.scss']
})
export class ParlayInfoDialogComponent implements OnInit {

  headers: any = [];
  parameter: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ParlayInfoDialogComponent>,
    public dialog: MatDialog,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.setHeader();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  setHeader() {
    if (this.data.info !== null) {
      Object.keys(this.data.info[0]).map(key => {
        const header = key.replace(/_/g, " ");
        this.headers.push(header)
        this.parameter.push(key);

      });
    }
  }

  onFormatLog(log) {
    if (typeof (log) === 'number') {
      return '<span>' + log + '</span><br>';
    } else if (this.isJson(log) || typeof (log) === 'object') {
      let result = '';
      let jsonObject = typeof (log) === 'object' ? log : JSON.parse(log);
      if (jsonObject != null && jsonObject != 'null') {
        Object.keys(jsonObject).forEach(key => {
          if (typeof (jsonObject[key]) === 'object') {
            result += '<strong>' + key + '~</strong><br>' + this.onFormatLog(JSON.stringify(jsonObject[key]));
          } else {
            result += '<span>' + key + ' : ' + jsonObject[key] + '</span><br>';
          }
        });
      } else {
        result += '<span>null</span><br>';
      }
      return result;
    } else if (moment(log, 'YYYY-MM-DD\THH:mm:ss\Z', true).isValid()) {
      return '<div class="date-column">' + this.datePipe.transform(log, 'yyyy-MM-dd HH:mm:ss') + '</div><br>';
    } else {
      return '<span>' + log + '</span><br>';
    }
  }

  private isJson(data: string) {
    try {
      if (data.includes('{')) {
        JSON.parse(data);
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  }

}
