import {
 createReducer, on, Action
} from '@ngrx/store';
import { AuthActions } from './auth-action.types';
import { Auth } from '@core/models/auth.model';

export interface AuthState {
  user: Auth;
}

const initialAuthState: AuthState = {
  user: undefined
};

export const reducer = createReducer(

  initialAuthState,

  on(AuthActions.login, (state, action) => {
    return {...state, user: action }
  }),

  on(AuthActions.loginSuccess, (state, action) => {
    return {...state, user: {
      user: action.user,
      token: action.token,
      access_sections: action.access_sections,
      navigations: action.navigations,
      permissions: action.permissions,
      app_permissions: action.app_permissions,
      app_sections: action.app_sections
    }}
  }),

  on(AuthActions.logout, (state, action) => {
    return {...state, user: {}};
  }),

  on(AuthActions.updateAccessSections, (state, action) => {
    return {...state, user: {...state.user, access_sections: action.accessSections}};
  }),

  on(AuthActions.updateAppPermissions, (state, action) => {
    return {...state, user: {...state.user, app_permissions: action.appPermissions}};
  }),

  on(AuthActions.updateNavigations, (state, action) => {
    return {...state, user: {...state.user, navigations: action.navigations}};
  }),

  on(AuthActions.updateUserPermissions, (state, action) => {
    return {...state, user: {...state.user, permissions: action.userPermissions }};
  })
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return reducer(state, action);
}



