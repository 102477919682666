import { VipModule } from './apps/vip/vip.module';
import { SmsModule } from './apps/sms/sms.module';
// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '@core/core.module';
import { GeneralModule } from './apps/general/general.module';
import { AccountModule } from './apps/account/account.module';
import { SettingsModule } from './apps/settings/settings.module';
import { SuperuserModule } from './apps/superuser/superuser.module';
import { SharedModule } from '@shared/shared.module';
import { PlatformModule } from './apps/games/platform.module';
import { ReportsModule } from './apps/reports/reports.module';
import { MarketeerModule } from './apps/marketeer/marketeer.module';
import { RebatesModule } from './apps/rebates/rebates.module';
import { DownloadAreaComponent } from './apps/download-area/download-area.component';
import { DownloadAreaDataService } from './apps/download-area/services/download-area-data.service';
import { ImportHistoryComponent } from './apps/import-history/import-history.component';
import { ImportHistoryDataService } from './apps/import-history/services/import-history-data.service';
import { ExportCRMBonusActionComponent } from './apps/export-crm-bonus-action/export-crm-bonus-action.component';
import { ExportCRMBonusActionService } from './apps/export-crm-bonus-action/services/export-crm-bonus-action.service';
import { TwoFADataService } from './apps/account/dialogs/account-twofa/services/twofa-data.service';
import { ReferralCommissionModule } from './apps/referral-commission/referral-commission.module';
@NgModule({
  declarations: [
    DownloadAreaComponent,
    ImportHistoryComponent,
    ExportCRMBonusActionComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    SharedModule,
    PartialsModule,
    GeneralModule,
    PlatformModule,
    AccountModule,
    SettingsModule,
    SuperuserModule,
    MarketeerModule,
    ReportsModule,
    RebatesModule,
    SmsModule,
    ReactiveFormsModule,
    VipModule,
    ReferralCommissionModule,
    NgxDaterangepickerMd.forRoot()
  ],
  providers: [
    DownloadAreaDataService,
    ImportHistoryDataService,
    ExportCRMBonusActionService,
    TwoFADataService
  ]
})
export class PagesModule { }
