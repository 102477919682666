import { Component, ElementRef, Inject, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MetaTagDataService } from '../../services/meta-tag-data.service';
import { RouteSettingsDataService } from '../../services/route-settings-data.service';
import { UploadHttpService } from '@core/services/upload-http.service';
import Swal from 'sweetalert2';
import { MetaTagSettingsDuplicateComponent } from '../meta-tag-settings-duplicate/meta-tag-settings-duplicate.component';
import { MatDialog } from '@angular/material/dialog';
import { AppPermissionService } from '@core/services/app-permission.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-meta-tag-settings',
  templateUrl: './meta-tag-settings.component.html',
  styleUrls: ['./meta-tag-settings.component.scss']
})
export class MetaTagSettingsComponent implements OnInit, OnDestroy {
  @ViewChild("keyword") keyword: ElementRef;

  @HostListener('window:beforeunload', ['$event'])
  confirmLeavingPageBeforeSaving($event) {
    if (this.hasChangesToSave()) {
      $event.preventDefault();
      $event.returnValue = true;
      return $event;
    }
  }

  form: FormGroup;
  dropdown = {
    openGraphType: this.dropdownHttpService.openGraphType,
    deviceType: [
      { id: 1, name: 'Desktop', icon: 'fas fa-desktop' },
      { id: 2, name: 'Mobile', icon: 'fas fa-mobile-alt' }
    ]
  };
  selectedDevice = this.dropdown.deviceType[0];
  defaultLocaleTab = 0;
  messages$ = this.metaTagDataService.messages$;
  isImageUploading = false;
  routesLoading = false;
  routes = [];
  routesBackUp: any;
  routeName = '';
  selectedRoute: any;
  params: any;
  metatags: any;
  metatagsLoading = false;
  words: any;
  wordCount: any;
  metatagsSettings: any;
  metatagsSettingsInitial: any;
  metatagsSettingsDuplicated: any;
  processingRoutes = [];
  isSavingAll = false;

  // permissions
  canEditMetaTagSettings: boolean;
  canDuplicateMetaTagSettings: boolean;

  private subscriptions = new Subscription();
 
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, seo: any, locales: any },
    public dialogRef: MatDialogRef<MetaTagSettingsComponent>,
    private dropdownHttpService: DropdownHttpService,
    private metaTagDataService: MetaTagDataService,
    private routeSettingsDataService: RouteSettingsDataService,
    private uploadService: UploadHttpService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    // init form
    this.formInit();

    // get all meta settings from api
    this.getAllMetaSettings();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditMetaTagSettings = appPermissions.edit_meta_tag_settings;
      this.canDuplicateMetaTagSettings = appPermissions.duplicate_meta_tag_settings;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  unloadNotification($event: any): void {
    // Display a confirmation message
    $event.returnValue = true;
  }

  async onCloseDialog() {
    if (this.hasChangesToSave()) {
      const resp = await Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to proceed without saving the changes?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#dd3333',
        denyButtonColor: '#0abb87',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        denyButtonText: 'Save All',
        reverseButtons: true,
      });

      if (resp.isConfirmed || resp.isDenied) {
        if (resp.isDenied) {
          await this.onSave(3);
        }
        this.dialogRef.close(true);
      }
    } else {
      this.dialogRef.close(true);
    }
  }

  async onSelectDevice(device: any) {
    if (this.isSavingAll || this.processingRoutes.length > 0) {
      return;
    }

    if (this.hasChangesToSave()) {
      const resp = await Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to proceed without saving the changes?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#dd3333',
        denyButtonColor: '#0abb87',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        denyButtonText: 'Save All',
        reverseButtons: true,
      });

      if (resp.isConfirmed || resp.isDenied) {
        if (resp.isDenied) {
          await this.onSave(3);
        }

        this.selectedDevice = device;
        this.form.patchValue({
          device_type: this.selectedDevice.id
        });
        this.getAllMetaSettings();
      }
    } else {
      this.selectedDevice = device;
      this.form.patchValue({
        device_type: this.selectedDevice.id
      });
      this.getAllMetaSettings();
    }
  }

  onSearch() {
    this.routes = [];
    if (this.routeName !== undefined && this.routeName !== '' && this.routeName !== null) {
      this.routes = this.routesBackUp.filter(x => x.name.toLowerCase().includes(this.routeName.toLowerCase()));
    } else {
      this.routes = this.routesBackUp;
      // this.getRoutes();
    }
  }

  onSelectRoute(item: any) {
    if (this.processingRoutes.length > 0 || this.isSavingAll) {
      return;
    }
    this.selectedRoute = item;

    this.form.patchValue({
      seo_setting_route_id: item.id
    });

    this.data.locales.map(locale => {
      const metatagDetails = {
        setting_locale_id: this.metatagsSettings['route_data'][item.id] && this.metatagsSettings['route_data'][item.id][locale.code] ? this.metatagsSettings['route_data'][item.id][locale.code].id : locale.id,
        meta_title: this.metatagsSettings['route_data'][item.id] && this.metatagsSettings['route_data'][item.id][locale.code] ? this.metatagsSettings['route_data'][item.id][locale.code].meta_title : null,
        meta_desc: this.metatagsSettings['route_data'][item.id] && this.metatagsSettings['route_data'][item.id][locale.code] ? this.metatagsSettings['route_data'][item.id][locale.code].meta_desc : null,
        meta_keyword: this.metatagsSettings['route_data'][item.id] && this.metatagsSettings['route_data'][item.id][locale.code] ? this.metatagsSettings['route_data'][item.id][locale.code].meta_keyword : null,
        meta_robot: this.metatagsSettings['route_data'][item.id] && this.metatagsSettings['route_data'][item.id][locale.code] ? this.metatagsSettings['route_data'][item.id][locale.code].meta_robot : null,
        open_graph_title: this.metatagsSettings['route_data'][item.id] && this.metatagsSettings['route_data'][item.id][locale.code] ? this.metatagsSettings['route_data'][item.id][locale.code].open_graph_title : null,
        open_graph_desc: this.metatagsSettings['route_data'][item.id] && this.metatagsSettings['route_data'][item.id][locale.code] ? this.metatagsSettings['route_data'][item.id][locale.code].open_graph_desc : null,
        open_graph_url: this.metatagsSettings['route_data'][item.id] && this.metatagsSettings['route_data'][item.id][locale.code] ? this.metatagsSettings['route_data'][item.id][locale.code].open_graph_url : null,
        open_graph_type: this.metatagsSettings['route_data'][item.id] && this.metatagsSettings['route_data'][item.id][locale.code] ? this.metatagsSettings['route_data'][item.id][locale.code].open_graph_type : 1,
        open_graph_img: this.metatagsSettings['route_data'][item.id] && this.metatagsSettings['route_data'][item.id][locale.code] ? this.metatagsSettings['route_data'][item.id][locale.code].open_graph_img : null,
        img_preview: this.metatagsSettings['route_data'][item.id] && this.metatagsSettings['route_data'][item.id][locale.code] ? this.metatagsSettings['route_data'][item.id][locale.code].open_graph_img : '',
      }
      this.form.patchValue({ details: { [locale.id]: metatagDetails } });
    });

    this.routesLoading = false;
    this.metatagsLoading = false;
  }

  onCountWords() {
    this.wordCount = this.keyword ? this.keyword.nativeElement.value.split(/\s+/) : 0;
    this.words = this.wordCount ? this.wordCount.length : 0;
    if (this.wordCount.length == 1 && this.wordCount[0] == '') {
      this.words = 0;
    }
  }

  onUploadFile(event: any, locale: any) {
    if (this.isSavingAll || this.processingRoutes.length > 0) {
      return;
    }

    this.isImageUploading = true;
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append("files", file, file.name);
    formData.append("type", "uploads");
    this.uploadService.upload(formData).subscribe((res: any) => {
      this.form.value.details[locale.id].open_graph_img = res[0];
      this.form.value.details[locale.id].img_preview = res[0];
      this.metatagsSettings['route_data'][this.form.value.seo_setting_route_id][locale.code]['open_graph_img'] = res[0];
      this.cleanUpMetatagsSettings();
      this.isImageUploading = false;
    });
    this.form.markAllAsTouched();
  }

  async onSave(flag: any, route_id?: any) {
    // prevent double firing the save event
    if (route_id !== undefined) {
      if (this.processingRoutes.includes(route_id)) {
        return;
      }
    } else {
      if (this.isSavingAll) {
        return;
      }
      this.isSavingAll = true;
    }

    this.toggleFormEdit('disable');

    // prepare params
    let data = {
      ...this.metatagsSettings
    };
    if (route_id !== undefined) {
      data.seo_setting_route_id = route_id;
      this.processingRoutes.push(route_id);
    }
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);

    // http request
    try {
      const res = await this.metaTagDataService.add(data).toPromise();

      if( route_id !== undefined ) {
        if( this.metatagsSettings['route_data'][route_id] === undefined) {
          if( this.metatagsSettingsInitial['route_data'][route_id] !== undefined ) {
            delete this.metatagsSettingsInitial['route_data'][route_id];
          }
        } else {
          if( this.metatagsSettingsInitial['route_data'][route_id] === undefined ) {
            this.metatagsSettingsInitial['route_data'][route_id] = {};
          }
          this.metatagsSettingsInitial['route_data'][route_id] = JSON.parse(JSON.stringify(this.metatagsSettings['route_data'][route_id]));
        }
        this.processingRoutes.splice(this.processingRoutes.indexOf(route_id), 1);
      } else {
        this.metatagsSettingsInitial = JSON.parse(JSON.stringify(this.metatagsSettings));
        this.isSavingAll = false;
      }

      // prompt alert
      if ([1, 3].includes(flag)) {
        this.messages$.next(res.message);
      }

      this.toggleFormEdit('enable');
    } catch (error) {
      if (route_id !== undefined) {
        this.processingRoutes.splice(this.processingRoutes.indexOf(route_id), 1);
      } else {
        this.isSavingAll = false;
      }
      this.toggleFormEdit('enable');
    }
  }

  toggleFormEdit(action: string) {
    const setControlState = (control: AbstractControl, action: string) => {
      if (control) {
        action === 'enable' ? control.enable() : control.disable();
      }
    }

    const detailsForm = this.form.get('details') as FormGroup;
    Object.keys(detailsForm.controls).forEach((localeId) => {
      const controlsToToggle = [
        'meta_title',
        'meta_desc',
        'meta_keyword',
        'meta_robot',
        'open_graph_title',
        'open_graph_desc',
        'open_graph_url',
        'open_graph_type'
      ];

      controlsToToggle.forEach((controlName) => {
        const control = detailsForm.get([localeId, controlName]);
        setControlState(control, action);
      });
    });
  }

  private getRoutes() {
    this.routes = [];
    this.routeSettingsDataService.getWithQuery(`?device_type=${this.form.value.device_type}`).subscribe((res: any) => {
      this.routes = res;
      this.routesBackUp = res;
      res.length > 0 ? this.onSelectRoute(res[0]) : '';
      if (this.routes.length <= 0) {
        this.routesLoading = false;
        this.metatagsLoading = false;
      }
    });
  }

  async getAllMetaSettings() {
    this.metatagsLoading = true;
    this.routesLoading = true;

    const field = {
      seo_setting_id: this.data.seo.id,
      device_type: this.form.value.device_type,
      paginate: false,
    }
    const data = this.filterFormFields(field);
    const params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = params ? `${params}` : '';

    // retrieve all meta settings from service
    await this.metaTagDataService.getWithQuery(`?${parameters}`).toPromise().then(res => {

      if (res) {
        this.metatagsSettings = {
          seo_setting_id: this.data.seo.id,
          device_type: this.form.value.device_type,
          route_data: {}
        };

        res.forEach((item: any) => {
          if (!this.metatagsSettings['route_data'][item.seo_setting_route_id]) {
            this.metatagsSettings['route_data'][item.seo_setting_route_id] = {};
          }

          if (!this.metatagsSettings['route_data'][item.seo_setting_route_id][item.locale]) {
            this.metatagsSettings['route_data'][item.seo_setting_route_id][item.locale] = {};
          }

          this.metatagsSettings['route_data'][item.seo_setting_route_id][item.locale] = {
            setting_locale_id: item.setting_locale_id,
            meta_title: item.meta_title,
            meta_desc: item.meta_desc,
            meta_keyword: item.meta_keyword,
            meta_robot: item.meta_robot,
            open_graph_title: item.open_graph_title,
            open_graph_desc: item.open_graph_desc,
            open_graph_url: item.open_graph_url,
            open_graph_type: item.open_graph_type,
            open_graph_img: item.open_graph_img,
          };
        });

      }
    });
    this.metatagsSettingsInitial = JSON.parse(JSON.stringify(this.metatagsSettings));

    // get all routes
    this.getRoutes();
  }

  private resetForm() {
    this.data.locales.map(res => {
      const metatag = this.metatags.find(metatag => metatag.setting_locale_id === res.id);
      const metatagDetails = {
        setting_locale_id: metatag ? metatag.setting_locale_id : res.id,
        meta_title: null,
        meta_desc: null,
        meta_keyword: null,
        meta_robot: null,
        open_graph_title: null,
        open_graph_desc: null,
        open_graph_url: null,
        open_graph_type: 1,
        open_graph_img: null,
        img_preview: '',
      }
      this.form.patchValue({ details: { [res.id]: metatagDetails } });
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined) ? fields[key] = formData[key] : key);
    return fields;
  }

  private formInit() {
    const buildDetails = () => {
      let details = {};
      this.data.locales.map((element, index) => {
        const detailsGroup = new FormGroup({
          setting_locale_id: new FormControl(element.id, [Validators.required]),
          meta_title: new FormControl(null),
          meta_desc: new FormControl(null),
          meta_keyword: new FormControl(null),
          meta_robot: new FormControl(null),
          open_graph_title: new FormControl(null),
          open_graph_desc: new FormControl(null),
          open_graph_url: new FormControl(null),
          open_graph_type: new FormControl(1, [Validators.required]),
          open_graph_img: new FormControl(null),
          img_preview: new FormControl(''),
        });
        details = { ...details, [element.id]: detailsGroup };
      });
      return details;
    }
    this.form = new FormGroup({
      seo_setting_id: new FormControl(this.data.seo.id, [Validators.required]),
      seo_setting_route_id: new FormControl(null, [Validators.required]),
      device_type: new FormControl(this.selectedDevice.id, [Validators.required]),
      details: new FormGroup(buildDetails())
    });
  }

  onInputChange(locale_code: string, name: string, value: string) {
    if (this.metatagsSettings['route_data'][this.form.value.seo_setting_route_id] === undefined) {
      this.metatagsSettings['route_data'][this.form.value.seo_setting_route_id] = {};
    }
    this.data.locales.map((element, index) => {
      if( this.metatagsSettings['route_data'][this.form.value.seo_setting_route_id][element.code] === undefined ) {
        this.metatagsSettings['route_data'][this.form.value.seo_setting_route_id][element.code] = {
          setting_locale_id: element.id,
          meta_title: null,
          meta_desc: null,
          meta_keyword: null,
          meta_robot: null,
          open_graph_title: null,
          open_graph_desc: null,
          open_graph_url: null,
          open_graph_type: 1,
          open_graph_img: null,
          img_preview: ''
        };
      }
    });
    this.metatagsSettings['route_data'][this.form.value.seo_setting_route_id][locale_code][name] = (value == '' ? (name == 'img_preview' ? '' : null) : value);
    this.cleanUpMetatagsSettings();
  }

  cleanUpMetatagsSettings() {
    if (this.metatagsSettingsInitial['route_data'][this.form.value.seo_setting_route_id] === undefined) {
      // is new route, check if is default values
      let is_default = true;
      Object.keys(this.metatagsSettings['route_data'][this.form.value.seo_setting_route_id]).forEach((locale_code) => {
        Object.keys(this.metatagsSettings['route_data'][this.form.value.seo_setting_route_id][locale_code]).forEach((name) => {
          switch (name) {
            case 'meta_title':
            case 'meta_desc':
            case 'meta_keyword':
            case 'meta_robot':
            case 'open_graph_title':
            case 'open_graph_desc':
            case 'open_graph_url':
            case 'open_graph_img':
              if (this.metatagsSettings['route_data'][this.form.value.seo_setting_route_id][locale_code][name] !== null) {
                is_default = false;
              }
              break;
            case 'open_graph_type':
              if (this.metatagsSettings['route_data'][this.form.value.seo_setting_route_id][locale_code][name] !== 1) {
                is_default = false;
              }
              break;
            case 'img_preview':
              if (this.metatagsSettings['route_data'][this.form.value.seo_setting_route_id][locale_code][name] !== '') {
                is_default = false;
              }
              break;
          }
        });
      });

      if (is_default) {
        delete this.metatagsSettings.route_data[this.form.value.seo_setting_route_id];
      }
    }
  }

  shouldShowButton(route_id: any) {
    if (this.metatagsSettingsInitial.route_data[route_id] === undefined) {
      if (this.metatagsSettings.route_data[route_id] !== undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.metatagsSettings.route_data[route_id] === undefined) {
        return true;
      } else {
        if (this.deepEqual(this.metatagsSettingsInitial.route_data[route_id], this.metatagsSettings.route_data[route_id])) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  hasChangesToSave() {
    if (this.deepEqual(this.metatagsSettings, this.metatagsSettingsInitial)) {
      return false;
    } else {
      return true;
    }
  }

  deepEqual(objA, objB) {
    if (objA === objB) {
      return true;
    }

    if (typeof objA !== 'object' || typeof objB !== 'object') {
      return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (const key of keysA) {
      if (!keysB.includes(key) || !this.deepEqual(objA[key], objB[key])) {
        return false;
      }
    }

    return true;
  }

  async onOpenDuplicateDialog() {
    if (this.isSavingAll || this.processingRoutes.length > 0) {
      return;
    }

    let dialogAttr = {
      width: '800px',
      data: {
        seo_setting_id: this.data.seo.id,
        device_type: this.selectedDevice.id,
        routes: this.routes,
      }
    };

    if (this.hasChangesToSave()) {
      const resp = await Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to proceed without saving the changes?',
        icon: 'warning',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#dd3333',
        denyButtonColor: '#0abb87',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        denyButtonText: 'Save All',
        reverseButtons: true,
      });

      if (resp.isConfirmed || resp.isDenied) {
        if (resp.isDenied) {
          await this.onSave(3);
        }
        const childDialogRef = this.dialog.open(MetaTagSettingsDuplicateComponent, dialogAttr);
        childDialogRef.afterClosed().subscribe((res: any) => {
          if (res !== undefined) {
            this.duplicateSetting(res);
          }
        });
      }
    } else {
      const childDialogRef = this.dialog.open(MetaTagSettingsDuplicateComponent, dialogAttr);
      childDialogRef.afterClosed().subscribe((res: any) => {
        if (res !== undefined) {
          this.duplicateSetting(res);
        }
      });
    }
  }

  async duplicateSetting(res: any) {
    this.metatagsLoading = true;
    this.routesLoading = true;

    const field = {
      seo_setting_id: res.target_seo_setting_id,
      device_type: res.device_type,
      paginate: false,
    }
    const data = this.filterFormFields(field);
    const params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = params ? `${params}` : '';

    let newRouteData = {};
    await this.metaTagDataService.getWithQuery(`?${parameters}`).toPromise().then(res => {
      if (res) {
        res.forEach((item: any) => {
          if (!newRouteData[item.seo_setting_route_id]) {
            newRouteData[item.seo_setting_route_id] = {};
          }

          if (!newRouteData[item.seo_setting_route_id][item.locale]) {
            newRouteData[item.seo_setting_route_id][item.locale] = {};
          }

          newRouteData[item.seo_setting_route_id][item.locale] = {
            setting_locale_id: item.setting_locale_id,
            meta_title: item.meta_title,
            meta_desc: item.meta_desc,
            meta_keyword: item.meta_keyword,
            meta_robot: item.meta_robot,
            open_graph_title: item.open_graph_title,
            open_graph_desc: item.open_graph_desc,
            open_graph_url: item.open_graph_url,
            open_graph_type: item.open_graph_type,
            open_graph_img: item.open_graph_img,
          };
        });
      }
    });

    for (let i = 0; i < res.routes.length; i++) {
      if (newRouteData[res.routes[i]] === undefined) {
        if (this.metatagsSettings['route_data'][res.routes[i]] !== undefined) {
          delete this.metatagsSettings['route_data'][res.routes[i]];
        }
      } else {
        this.metatagsSettings['route_data'][res.routes[i]] = newRouteData[res.routes[i]];
      }
    }

    this.onSelectRoute(this.routes[0]);
  }

}
