import { FormGroup, FormControl, Validators } from '@angular/forms';
import { tap, catchError } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2'
import { BankReceiptDataService } from '../../services/bank-receipt-data.service';
import { BankReceipt } from '@core/models/bank-receipt.model';

@Component({
  templateUrl: './bank-receipt-void.component.html',
  styleUrls: ['./bank-receipt-void.component.scss']
})
export class BankReceiptVoidDialogComponent implements OnInit, OnDestroy {

  messages$ = this.bankReceiptDataService.messages$;
  form: FormGroup;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bankReceipt: BankReceipt, mode: string },
    public dialogRef: MatDialogRef<BankReceiptVoidDialogComponent>,
    private bankReceiptDataService: BankReceiptDataService
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(bankReceipt: BankReceipt, mode?: string){
    const data = {
      id: bankReceipt ? bankReceipt.id : null,
      ...this.form.value,
      status: mode === 'reject' ? 4 : 5
    };
    const text = mode === 'reject' ? `${mode}` : `mark as ${mode}`;

    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to ${text} this bank receipt?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.buttonLoading = true;
        this.subscription = this.bankReceiptDataService.voidReceipt(data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
        }),
        catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
        })
        ).subscribe();
      }
    });
    this.refreshStatus = true;
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    this.form = new FormGroup({
      status: new FormControl(4, [Validators.required]),
      remarks: new FormControl(null, [Validators.required])
    });

  }
}
