<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row pr-0 mb-2">
                                <!-- Currency -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="settings_currency_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of currenciesDropdownList">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <!-- Commission Name -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Commission Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="{{ 'Search' | translate }}" class="form-control" [ngbTypeahead]="this.searchSetting" [resultFormatter]="this.formatSetting" [inputFormatter]="this.formatSetting">
                                </div>
                                <!-- Commisison Type -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Commission Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="type" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.types">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <!-- Commission Amount -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Commission Amount:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="number" formControlName="total_amount" placeholder="{{ 'Search' | translate }}" class="form-control">
                                </div>
                            </div>
                            <div class="col-12 row pr-0 mb-2">
                                <!-- Date Time -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <select class="form-control" formControlName="date_type">
                                                <option value="calculation_date" selected>Calculation Date</option>
                                                <option value="created_date">Created Date</option>
                                                <option value="updated_date">Updated Date</option>
                                            </select>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true"
                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                            [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                        ngxDaterangepickerMd/>
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                                <!-- Status -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="status" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.statuses">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('currency_code')" [ngClass]="sortingConfig.currency_code" [class.sort-selected]="sortingSelection.sort_by === 'currency_code'">Currency</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('start_datetime')" [ngClass]="sortingConfig.start_datetime" [class.sort-selected]="sortingSelection.sort_by === 'start_datetime'">Commission Calculation Period</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('commission_name')" [ngClass]="sortingConfig.commission_name" [class.sort-selected]="sortingSelection.sort_by === 'commission_name'">Commission Settings</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('total_amount')" [ngClass]="sortingConfig.total_amount" [class.sort-selected]="sortingSelection.sort_by === 'total_amount'">Commission Amount</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('remarks')" [ngClass]="sortingConfig.remarks" [class.sort-selected]="sortingSelection.sort_by === 'remarks'">Remarks</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(referralCommissionHistory$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td class="text-center">{{ row.id }}</td>
                                    <td class="text-center">{{ row.currency_code }}</td>
                                    <td>
                                        {{ (row.start_datetime | timezoneDate:'YYYY-MM-DD HH:mm:ss (ddd)':true) }} - <br/> 
                                        {{ (row.end_datetime | timezoneDate: 'YYYY-MM-DD HH:mm:ss (ddd)':true) }}
                                    </td>
                                    <td class="text-center">{{ row.commission_name }} ({{ row.commission_type_name }})</td>
                                    <td class="text-right">{{ row.total_amount }}</td>
                                    <td class="text-center">
                                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status">{{ row.status_name }}</span>
                                    </td>
                                    <td>{{ row.remarks }}</td>
                                    <td>
                                        {{ row.created_by ? row.created_by : 'System' }}<br/>
                                        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="row.update_action">
                                            {{ row.updated_by ? row.updated_by : 'System' }}<br/>
                                            <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                            <ng-template #updatedDateTime>
                                                {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                            </ng-template>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>