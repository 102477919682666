
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap,catchError } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subscription} from 'rxjs';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewChildren, QueryList, HostListener} from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PrivacyPolicyDataService } from './services/privacy-policy-data.service';
import { Dropdown } from '@core/models/dropdown.model';
import { DomSanitizer } from '@angular/platform-browser';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EditorService } from '@core/services/editor.service';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { EventEmitterService } from '@core/services/event-emitter.service';
import Swal from "sweetalert2";
import { TranslateService } from '@ngx-translate/core';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  animations: [
    trigger('panelState', [
      state('closed', style({ height: '80px', overflow: 'hidden' })),
      state('open', style({ height: '*' })),
      transition('closed <=> open', animate('300ms ease-in-out'))
    ])
  ]
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy, AfterViewInit {

  @HostListener('window:beforeunload', ['$event'])
  confirmLeavingPageBeforeSaving($event) {

    if(this.checkupdate()){
      $event.preventDefault();
      $event.returnValue = true;
      return $event;
    }
  }
  // Begin: CKEditor Part
  public editor = Editor;
  editorConfig = this.editorService.config2;
  @ViewChildren('editors') editorComponent:QueryList<CKEditorComponent>;

  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    locales: [],
    perPage: this.dropdownHttpService.perPage
  };

  privacyPolicy$: any;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dropdownLocales: Dropdown[];
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  backupPageSize = 30;
  backupPage = 1;
  unsaveStatus = true;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  sections: any;
  selectedPrivacyPolicy = [];
  mode: any;
  displayLocale: any;
  toggleAll: boolean = false;
  availablePrivacyPolicy = [];
  buttonLoading = true;

  privacyPolicyData = {
    main_descriptions: [],
    contents: [],
  }

  privacyPolicy: any;
  viewPrivacyPolicy: any;
  viewMode:any = false;

  formContent: FormGroup;
  initPrivacyPolicy: any;
  changedObjects: any;

  usableVariables = [
    ':merchantprefix',
    ':merchantname',
  ];

  private localesSub = new Subscription();
  messages$ = this.privacyPolicyDataService.messages$;

  show = false;
  showMain = false;
  checkLoadingPage = false;

  // permissions
  canCreatePrivacyPolicy: boolean;
  canViewPrivacyPolicyDetails: boolean;
  canEditPrivacyPolicy: boolean;
  canUpdatePrivacyPolicyStatus: boolean;

  constructor(
    private loadingBar: LoadingBarService,
    private cdr: ChangeDetectorRef,
    private privacyPolicyDataService: PrivacyPolicyDataService,
    private dropdownHttpService: DropdownHttpService,
    public sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private editorService: EditorService,
    private fb: FormBuilder,
    private eventEmitterService: EventEmitterService,
    private appPermissionService: AppPermissionService,
  ) { }

  async canDeactivate(): Promise<boolean | Observable<boolean>> {
    // provide component specific logic to decide if component can or can't be deactivated
    if (this.checkupdate()) {
      const result = await Swal.fire({
        title: '<div class="text-center">Unsaved Changes</div>',
        html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Cancel'),
        confirmButtonText: this.translateService.instant('Discard'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          return (true);
        } else if (result.isDenied) {
          return (false);
        }
      });

      return result;
    }
    return true;
  }

  ngOnInit() {
    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.dropdown.locales = res;
      this.displayLocale = this.dropdown.locales[0];
      this.formInit();
      this.pagination = this.privacyPolicyDataService.pagination;
      this.onSubmit();
      this.initPrivacyPolicy = JSON.stringify({ ...this.formContent.value });
    })).subscribe();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreatePrivacyPolicy = appPermissions.create_privacy_policy;
      this.canViewPrivacyPolicyDetails = appPermissions.view_privacy_policy_details;
      this.canEditPrivacyPolicy = appPermissions.edit_privacy_policy;
      this.canUpdatePrivacyPolicyStatus = appPermissions.update_privacy_policy_status;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.localesSub.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  async onViewPageBy(page = 1, pageSize?: number, params?: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.backupPage = page;
      this.loading = true;
      pageSize = this.pageSize;
      params = this.params ? `&${this.params}` : '';
      this.loadingBar.start();
      this.privacyPolicyDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).subscribe(res => {
          if(res){
            this.privacyPolicy$ = res;
          }
          this.pagination = this.privacyPolicyDataService.pagination;
          this.dataLength = res['contents'].rows.length;
          this.privacyPolicyData.main_descriptions = res['main_descriptions'][0];
          this.privacyPolicyData.contents = res['contents'].rows;
          this.availablePrivacyPolicy = res['contents'].rows;

          this.toggleAll = false;
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.dataLength = res['contents'].rows.length;
          this.loadingBar.complete();
          this.cdr.detectChanges();
      });
    }
    else {
      this.page = this.backupPage;
    }
  }

  async onPerPage(size: Event) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.page = 1;
      this.pageSize = +(size.target as HTMLSelectElement).value;
      this.backupPageSize = this.pageSize;
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
    else {
      this.pageSize = this.backupPageSize;
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
  }

  async onSubmit(clearSearch?: boolean) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.searchBtnLoading = clearSearch ? false : true;
      this.loading = true;

      if (clearSearch) {
        this.onClear();
      }

      const data = {
        ...this.form.value,
      };

      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
      this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
      const parameters = this.params ? `&${this.params}` : '';
      this.loadingBar.start();
      this.privacyPolicyDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).subscribe(res => {
        if(res){
          this.privacyPolicy$ = res;
        }
        this.mode = null;
        this.onClearContent();
        this.pagination = this.privacyPolicyDataService.pagination;
        this.dataLength = res['contents'].rows.length;
        this.privacyPolicyData.main_descriptions = res['main_descriptions'][0];
        this.privacyPolicyData.contents = res['contents'].rows;
        this.availablePrivacyPolicy = res['contents'].rows;

        this.toggleAll = false;
        this.page = 1;
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.dataLength = res['contents'].rows.length;
        this.loadingBar.complete();
        this.cdr.detectChanges();
      })
    }
  }

  private formInit() {
    this.form = new FormGroup({
      status: new FormControl('all'),
      settings_locale_id: new FormControl('all'),
      keyword: new FormControl(null)
    });

    //----- privacy policy ------//
    const buildContents = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          settings_locale_id: new FormControl(element.id),
          title: new FormControl(null),
          description: new FormControl(null)
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    this.formContent = new FormGroup({
      // Create privacy policy
      position: new FormControl(99, [Validators.min(1), Validators.max(99), Validators.required]),
      status: new FormControl(null, [Validators.required]),
      content_type: new FormControl(null),
      contents: new FormGroup(buildContents())
    });

    //----- end privacy policy ------//
  }

  onClearContent() {

    this.formContent.patchValue({
      content_type: null,
      position: 99,
      status:1
    })

    this.dropdown.locales.forEach((element: any) => {
      this.formContent.patchValue({
        contents: {
          [element.id]: {
            title: null,
            description: null
          }
        }
      });
    });
    this.initPrivacyPolicy = JSON.stringify({ ...this.formContent.value });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  reload(clearSearch?: boolean) {
    this.mode = '';
    this.onClearContent();
    this.onSubmit(clearSearch);
    this.initPrivacyPolicy = JSON.stringify({ ...this.formContent.value });
    this.buttonLoading = false;
    this.selectedPrivacyPolicy = [];
  }

  onSanitize(data: string) {
    return this.sanitizer.bypassSecurityTrustHtml(data.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>'))
  }

  onFormatString(data: string) {
    return data.split(",").join(", <br />");
  }

  checkupdate() {
    const privacyPolicycheck = this.initPrivacyPolicy !== JSON.stringify({ ...this.formContent.value }) ? true : false;
    return privacyPolicycheck ? true : false;
  }

  expandRowCreate(mode: any, close?: boolean) {
    let privacyPolicyInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initPrivacyPolicy != privacyPolicyInput ? true : false;

    if (close != true && this.changedObjects == false && this.checkupdate() == false) {
      this.viewMode = null;
      this.changedObjects = false;
      this.initPrivacyPolicy = null;
      this.mode = mode;
      this.cdr.detectChanges();

      this.onClearContent();

      this.checkLoadingPage = false;

      setTimeout(() => {
        this.checkLoadingPage = true;
        this.cdr.detectChanges();
      }, 1500);

    } else {
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();
            // Check if reopen needed
            this.checkReopen(mode);
          }
        });
      }
      else {
        this.mode = '';
        this.unsaveStatus = true;
        this.onClearContent();
      }
    }

    this.cdr.detectChanges();
  }

  expandRowEdit(mode: any, row?: any, close?: boolean) {

    if (close != true) close = this.privacyPolicy == row ? true : false;

    let privacyPolicyInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initPrivacyPolicy && this.initPrivacyPolicy != privacyPolicyInput ? true : false;
    if (close != true && this.changedObjects == false && this.checkupdate() == false) {
      this.privacyPolicy = row;
      this.changedObjects = false;
      this.initPrivacyPolicy = null;
      this.mode = mode;
      this.viewMode = null;
      this.cdr.detectChanges();

      this.formContent.patchValue({
        content_type: this.privacyPolicy.content_type,
        position: this.privacyPolicy.position,
        status: this.privacyPolicy.status
      });

      if(this.privacyPolicy.content_type == 1){
        this.formContent.get('position').clearValidators();
        this.formContent.get('position').updateValueAndValidity();
      }

      // reset all locales to null
      this.dropdown.locales.forEach((element: any) => {
        this.formContent.patchValue({
          contents: {
            [element.id]: {
              title: null,
              description: null
            }
          }
        });
      });

      setTimeout(() => {
        this.privacyPolicy.contents.forEach((element: any) => {
          this.formContent.patchValue({
            contents: {
              [element.settings_locale_id]: {
                title: element.raw_title,
                description: element.raw_description
              }
            }
          });
        });

        this.cdr.detectChanges();

        this.initPrivacyPolicy = JSON.stringify({ ...this.formContent.value });
      }, 500);

      this.checkLoadingPage = false;

      setTimeout(() => {
        this.checkLoadingPage = true;
        this.cdr.detectChanges();
      }, 1500);

    } else {
      this.changedObjects = this.initPrivacyPolicy != JSON.stringify({ ...this.formContent.value }) ? true : false;
      if (this.changedObjects == true || this.checkupdate() == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();
            // Check if reopen needed
            if (this.privacyPolicy == row) {
              this.privacyPolicy = null;
            }
            else {
              this.privacyPolicy = null;
              this.checkReopen(mode, row);
            }
          }
        });
      }
      else {
        this.mode = '';
        this.privacyPolicy = null;
        this.onClearContent();
      }
    }
  }

  checkReopen(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowEdit(mode, row)
        break;
      case 'create':
        this.expandRowCreate(mode);
        break;
    }
  }

  expandRowView(row?: any) {
    this.show = false;

    if(this.viewPrivacyPolicy && row.id != this.viewPrivacyPolicy.id){
      if (this.checkupdate()) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.mode = null;
            this.unsaveStatus = true;
            this.viewMode = true;
            this.onClearContent();
          }else{
            this.viewMode = false;
          }
        });
      }else{
        this.viewMode = true;
        this.mode = null;
        this.onClearContent();
      }
    }else{
      if (this.checkupdate()) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.mode = null;
            this.unsaveStatus = true;
            this.viewMode = true;
            this.onClearContent();
          }else{
            this.viewMode = false;
          }
        });
      }else{
        this.viewMode = this.viewMode ? false : true;
        this.mode = null;
        this.unsaveStatus = true;
        this.onClearContent();
      }
    }

    this.viewPrivacyPolicy = row;
   }

   addTitleVariable(variable: any, id: any) {
    let content = this.formContent.value.contents[id]['title'] ? this.formContent.value.contents[id]['title'] : '';
    this.formContent.patchValue({
      contents: {
        [id]: {
          title: content + variable,
        }
      }
    });
  }

  addDescriptionVariable(variable: any, id: any) {
    let content = this.formContent.value.contents[id]['description'] ? this.formContent.value.contents[id]['description'] : '';
    this.formContent.patchValue({
      contents: {
        [id]: {
          description: content + variable,
        }
      }
    });
  }

  localeIndex() {
    const index = this.dropdown.locales.findIndex(item => {
      return item.id == this.displayLocale['id'];
    });

    return index;
  }

  checkContent(locale: any) {
    let description =  this.formContent.value.contents[locale.id].description ?  this.formContent.value.contents[locale.id].description : null;

    if ((description == null || description == '')) {
      return false;
    }
    else {
      return true;
    }
  }

  onSave() {

    this.buttonLoading = true;

    // all new create privacyPolicy are content_type = 2 (section type)
    if(this.mode == 'create'){
      this.formContent.patchValue({
        content_type: 2
      });
    }

    const data = {
      id: this.privacyPolicy ? this.privacyPolicy.id : null,
      ...this.formContent.value,
    };

    // check must fill in contents and title , either one is fill-in
    let keepcheck = true;
    let fillInTitleNull = true;
    let fillInDescriptionNUll = true;
    let locale = null;

    if(this.formContent.value.content_type == 2){
      Object.keys(data['contents']).forEach((key) => {
        if(keepcheck){
          if( (data['contents'][key]['title'] == null || data['contents'][key]['title'] =='') &&
          (data['contents'][key]['description'] !== null && data['contents'][key]['description'] !=='')
          ){
            fillInTitleNull = false;
            keepcheck = false;
            locale = this.dropdown.locales.filter(x => x.id == key)[0]
          }else if( (data['contents'][key]['title'] !== null && data['contents'][key]['title'] !=='') &&
                    (data['contents'][key]['description'] == null || data['contents'][key]['description'] =='')
          ){
            fillInDescriptionNUll = false;
            keepcheck = false;
            locale = this.dropdown.locales.filter(x => x.id == key)[0]
          }
        }
      });
    }

    if(!fillInTitleNull || !fillInDescriptionNUll){

      let message = null;

      if(!fillInTitleNull){
        message ="Please Fill in Title for locale "+locale['code'];
      }else if(!fillInDescriptionNUll){
        message ="Please Fill in Description for locale "+locale['code'];
      }

      Swal.fire({
        title: '<div class="text-center">'+message+'</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: false,
        denyButtonText: this.translateService.instant('Close'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button'
        }
      }).then(result => {
        this.buttonLoading = false;
      });

      return false;
    }

    let checkcontent = false;

    Object.keys(data['contents']).forEach((key) => {
      if( (data['contents'][key]['title'] !== null && data['contents'][key]['title'] !== '') ||
          (data['contents'][key]['description'] !== null && data['contents'][key]['description'] !== '')
      ){
        checkcontent = true;
      }
    });

    if(!checkcontent){

      Swal.fire({
        title: '<div class="text-center">Fill in at least one locale content</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        reverseButtons: false,
        denyButtonText: this.translateService.instant('Close'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button'
        }
      }).then(result => {
        this.buttonLoading = false;
      });

    }

    Object.keys(data).forEach((key) => {
      if (data[key] == null || data[key] === '' || data[key] === 'Invalid date') {
        delete data[key];
      }
    });

    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.formContent.setErrors({ 'invalid': true });

    if(checkcontent){
      switch (this.mode) {
        case 'edit':
          this.subscription = this.privacyPolicyDataService.updatePrivacyPolicyContent(this.privacyPolicy.id, data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
        case 'create':
          this.subscription = this.privacyPolicyDataService.add(data).pipe(
            tap((res: any) => {
              this.messages$.next(res.message);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.formContent.setErrors(null);
              this.formContent.enable();
              throw error;
            })
          ).subscribe();
          break;
      }
    }
  }

  onChangeStatus($event, row: any) {
    const data = {
      id: row.id,
      status: $event.target.checked ? 1 : 0,
    };

    this.subscription = this.privacyPolicyDataService.updateStatus(data).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.buttonLoading = false;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

  displayLocaleContent(row: any, column: any,  type:any) {
    let mainDescription = [];
    mainDescription.push(this.privacyPolicyData.main_descriptions)
    let privacyPolicy = type =='mainDescription' ? mainDescription.filter(x => x.id == row.id)[0]['contents'] : this.availablePrivacyPolicy.filter(x => x.id == row.id)[0]['contents'];

    privacyPolicy = privacyPolicy.filter(x => x['locale_name'] == this.displayLocale['code']);

    if (privacyPolicy.length > 0) {
      if(column){
        if(column == 'description' ) {
          return privacyPolicy[0]['description'] ?  privacyPolicy[0]['description'] :  '-';
        }
      }
      return privacyPolicy[0]['title']  ? privacyPolicy[0]['title'] :  '-';

    }
    else {
      return '-';
    }
  }

  showReadMoreButton(id){
    var element = document.getElementById(id);
    if (element.offsetHeight < element.scrollHeight ||
         element.offsetWidth < element.scrollWidth) {
         return true;
     } else {
        return false;
     }
 }

  changeLocale(locale: any) {
    this.displayLocale = locale;
    this.showMain = false;
    this.show = false;
  }

  clearContent(locale: any) {
    Swal.fire({
      title: 'Delete Content',
      text: 'Are you sure you want to delete contents for ' + locale.code + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.formContent.patchValue({
          contents: {
            [locale.id]: {
              title: null,
              description:  null
            }
          }
        });

      }
      this.cdr.detectChanges();
    });
  }

  private onCheckUnsave() {
    return new Promise<void>((resolve, reject) => {
      let privacyPolicyInput = JSON.stringify({ ...this.formContent.value });
      this.changedObjects = this.initPrivacyPolicy && this.initPrivacyPolicy != privacyPolicyInput ? true : false;

      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.mode = '';
            this.privacyPolicy = null;
            this.unsaveStatus = true;
            this.onClearContent();
            resolve();
          }
          else {
            this.unsaveStatus = false;
            resolve();
          }
        });
      }
      else {
        resolve();
      }
    });
  }
}
