import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { forkJoin, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PromotionCodeDataService } from '../../promotion-codes/services/promotion-code-data.service';
import { RecoveryPromotionDataService } from './../services/recovery-promotion-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MessageTemplateDataService } from '@views/pages/apps/superuser/message-template/services/message-template-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'kt-recovery-promotion-edit',
  templateUrl: './recovery-promotion-edit.component.html',
  styleUrls: ['./recovery-promotion-edit.component.scss']
})
export class RecoveryPromotionEditDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  // Form
  form: FormGroup;

  // Dropdown
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    promotionCodeDropdownList: this.data.promotions,
    filteredPromotionCodeDropdownList: [],
    smsTemplates: [],
    messageTemplates: []
  };

  // Permissions
  // canViewRecoveryCurrency: boolean;
  // canViewRecoveryMessages: boolean;
  canViewPromotionCodeList: boolean;

  // Dropdown settings
  currencyDropdownSettings = {};
  currencySelectedItems = [];

  promotionCodeDropdownSettings = {};
  promotionCodeSelectedItems = [];

  smsDropdownSettings = {};
  smsSelectedItems = [];

  messageDropdownSettings = {};
  messageSelectedItems = [];

  // Indicator
  buttonLoading = false;

  // Pagination Parameter
  pagination: Pagination;
  currentPage = 1;
  pageSize = 30;

  // Message
  messages$ = this.recoveryPromotionDataService.messages$;

  // Subscriptions array
  private subscriptions: Subscription[] = [];

  // Other
  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  informationNote = 0;
  refreshIndex = false;
  promotionHaveTemplate = false; // Promotion have Message / SMS template
  promotionRequired = true;
  smsRequired = true;
  messageRequired = true;
  smsIsValid = false;
  messageIsValid = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { row: any, mode: 'create' | 'edit' | 'duplicate', promotions: any },
    public dialogRef: MatDialogRef<RecoveryPromotionEditDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
    private messageTemplateDataService: MessageTemplateDataService,
    private recoveryPromotionDataService: RecoveryPromotionDataService,
    private promotionCodeDataService: PromotionCodeDataService,
  ) {
    // Get currencies data
    if (this.dropdown.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe(res => {
        this.dropdown.currencies = res;
      });
    }

    // Get permissions data
    this.subscriptions.push(
      this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
        // this.canViewRecoveryCurrency = appPermissions.view_recovery_currency;
        // this.canViewRecoveryMessages = appPermissions.view_recovery_messages;
        this.canViewPromotionCodeList = appPermissions.view_promotion_codes_list;
      })
    );

    // Get promotions data
    // this.promotionCodeDataService.getWithQuery(`?paginate=false`).subscribe(res => {
    //   this.dropdown.promotionCodeDropdownList = res;
    //   this.dropdown.promotionCodeDropdownList.map(function (elm) {
    //     elm['name'] = elm.code + ' - ' + elm.name;
    //   });
    // });

    // Set dropdown settings
    this.currencyDropdownSettings = {
      autoPosition: true,
      maxHeight: 150,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: true,
      enableSearchFilter: false,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.promotionCodeDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 150, //'auto',
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false,
      disabled: this.data.row ? (this.data.row.has_active_members ? true : false) : false
    };

    this.smsDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 150, //'auto',
      primaryKey: 'id',
      labelKey: 'code',
      noDataLabel: '',
      showCheckbox: false
    };

    this.messageDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 150, //'auto',
      primaryKey: 'id',
      labelKey: 'code',
      noDataLabel: '',
      showCheckbox: false
    };
  }

  ngOnInit() {
    this.formInit();
    this.updateInformationNote();
  }

  ngAfterViewInit(): void {
    this.focusfield.first.nativeElement.focus()
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  onCloseDialog() {
    this.dialogRef.close(this.refreshIndex);
  }

  onChangeCurrency(selected) {
    this.currencySelectedItems = selected;
    let selectedCurrencyCodes = this.currencySelectedItems.map(x => x.name);
    this.filterPromotionList(selectedCurrencyCodes)
    this.getTemplateData(selectedCurrencyCodes)
  }

  onChangeSms(selected, currency_name) {
    this.smsSelectedItems[currency_name] = selected;
    this.updatePromotionRequired();
  }

  onChangeMessage(selected, currency_name) {
    this.messageSelectedItems[currency_name] = selected;
    this.updatePromotionRequired();
  }

  onSelectedPromotionItems(selected) {
    this.promotionCodeSelectedItems = selected;
    if (this.promotionCodeSelectedItems[0]?.message_templates.length > 0 || this.promotionCodeSelectedItems[0]?.sms_message_templates.length > 0) {
      this.promotionHaveTemplate = true;
    } else {
      this.promotionHaveTemplate = false;
    }

    if (this.promotionCodeSelectedItems.length > 0) {
      this.smsRequired = false;
      this.messageRequired = false;
    } else {
      this.smsRequired = true;
      this.messageRequired = true;
    }
    this.updatePromotionRequired();
  }

  onSave() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });

    this.dropdown.currencies.forEach(currency => {
      if (this.currencySelectedItems.find(x => x.id == currency.id) == undefined) {
        this.form.get('sms_message_templates').patchValue({
          [currency.id]: null
        });
        this.form.get('message_templates').patchValue({
          [currency.id]: null
        });
      }
    });

    const data = {
      ...this.form.getRawValue(),
    };

    switch (this.data.mode) {
      case 'edit':
        this.addOrUpdate(data, this.data.row.id);
        break;
      case 'duplicate':
        // Swal.fire({
        //   title: 'Are you sure?',
        //   text: `Are you sure you want to duplicate ${data.name}?`,
        //   icon: 'info',
        //   confirmButtonColor: '#3085d6',
        //   cancelButtonColor: '#d33',
        //   showCancelButton: true,
        //   confirmButtonText: 'Yes',
        //   cancelButtonText: 'No',
        //   reverseButtons: true
        // }).then((resp) => {
        //   if (resp.isConfirmed) {
        //     this.addOrUpdate(data);
        //   } else {
        //     this.buttonLoading = false;
        //     this.form.setErrors(null);
        //   }
        // });
        // break;
      case 'create':
        this.addOrUpdate(data);
        break;
    }
  }

  private formInit() {
    const buildMessageTemplates = (type: 'sms' | 'message') => {
      let currencies = {};
      this.dropdown.currencies.forEach(currency => {
        if (type == 'sms') {
          currencies = { ...currencies, [currency.id]: new FormControl(this.data.mode == 'create' ? null : (this.data.row.sms_message_template_ids.find(x => x.currency_id == currency.id)?.template_id ?? null)) }
        } else {
          currencies = { ...currencies, [currency.id]: new FormControl(this.data.mode == 'create' ? null : (this.data.row.message_template_ids.find(x => x.currency_id == currency.id)?.template_id ?? null)) }
        }
      })
      return currencies;
    };

    let name = null,
      description = null,
      depositor = null,
      inactivity = null,
      status = 1,
      currency = [],
      promotion_id = null;

    if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
      name = this.data.mode === 'edit' ? this.data.row.name : null;
      description = this.data.mode === 'edit' ? this.data.row.description : null;
      depositor = this.data.row.depositor;
      inactivity = this.data.row.inactivity;
      status = this.data.row.status;
      currency = this.data.row.currency_ids;
      promotion_id = this.data.row.promotion_id == 0 ? null : this.data.row.promotion_id;

      this.data.row.currency_ids.forEach(currency => {
        this.currencySelectedItems.push(this.dropdown.currencies.find(x => x.id == currency));
      })

      let selectedCurrencyCodes = this.currencySelectedItems.map(x => x.name);
      this.filterPromotionList(selectedCurrencyCodes)
      this.getTemplateData(selectedCurrencyCodes, true)

      if (this.data.row.promotion_id !== 0) {
        this.onSelectedPromotionItems([this.data.promotions.find(x => x.id == this.data.row.promotion_id)]);
      }
    }

    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      description: new FormControl(description),
      depositor: new FormControl(depositor, [Validators.required]),
      inactivity: new FormControl(inactivity, Validators.compose([Validators.required, Validators.min(0)])),
      status: new FormControl({ value: status, disabled: this.data.mode == 'create' }, [Validators.required]),
      currency: new FormControl(currency, [Validators.required]),
      promotion_id: new FormControl(promotion_id, [Validators.required]),
      sms_message_templates: new FormGroup(buildMessageTemplates('sms')),
      message_templates: new FormGroup(buildMessageTemplates('message'))
    });

    this.form.valueChanges.subscribe(() => this.updateInformationNote());
  }

  private updateInformationNote() {
    const checRecoveryPromotionkMessageTemplate = () => {
      let useRecoveryPromotionMessageTemplate = false;
      this.currencySelectedItems.forEach(currency => {
        if (this.form.value.sms_message_templates[currency.id] != null && this.form.value.sms_message_templates[currency.id] != 'null') {
          useRecoveryPromotionMessageTemplate = true;
        }
        if (this.form.value.message_templates[currency.id] != null && this.form.value.message_templates[currency.id] != 'null') {
          useRecoveryPromotionMessageTemplate = true;
        }
      });
      return useRecoveryPromotionMessageTemplate;
    };

    if (this.currencySelectedItems.length == 0) {
      this.informationNote = 0;
    } else if (
      this.currencySelectedItems.length > 0 &&
      !checRecoveryPromotionkMessageTemplate() &&
      (this.form.value.promotion_id == 0 || this.form.value.promotion_id == null || this.form.value.promotion_id == null)
    ) {
      this.informationNote = 1;
    } else if (
      this.currencySelectedItems.length > 0 &&
      checRecoveryPromotionkMessageTemplate()
    ) {
      this.informationNote = 2;
    } else if (
      this.currencySelectedItems.length > 0 &&
      !checRecoveryPromotionkMessageTemplate() &&
      this.form.value.promotion_id != 0 && this.form.value.promotion_id != null && this.form.value.promotion_id != 'null'
    ) {
      this.informationNote = this.promotionHaveTemplate ? 3 : 1;
    } else {
      this.informationNote = 0;
    }
  }

  private addOrUpdate(data: any, id?: number) {
    if (this.data.mode == 'edit') {
      this.recoveryPromotionDataService.updateCategory(id, data).pipe(
        tap(() => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          this.refreshIndex = true;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else {
      this.recoveryPromotionDataService.add(data).pipe(
        tap(() => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          this.refreshIndex = true;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
  }

  private filterPromotionList(selectedCurrencyCodes = []) {
    this.dropdown.filteredPromotionCodeDropdownList = [];
    if (selectedCurrencyCodes.length > 0) {
      this.dropdown.promotionCodeDropdownList.forEach(x => {
        let added = false;
        x.currencies.split(', ').forEach(c => {
          if (!added && selectedCurrencyCodes.includes(c))
            this.dropdown.filteredPromotionCodeDropdownList.push(x);
          added = true;
        })
      });
    }
  }

  private getTemplateData(selectedCurrencyCodes = [], init = false) {
    if (selectedCurrencyCodes.length > 0) {
      selectedCurrencyCodes.forEach(currency_code => {
        if (!(currency_code in this.dropdown.smsTemplates) && !(currency_code in this.dropdown.messageTemplates)) {
          forkJoin([
            this.messageTemplateDataService.getMessageTemplateList(2, 8, [currency_code], 1), // Get sms templates with type = 2 (SMS) and section = 8 (Promotions)
            this.messageTemplateDataService.getMessageTemplateList(1, 8, [currency_code], 1)  // Get message templates with type = 1 (Message) and section = 8 (Promotions)
          ]).subscribe(([sms, message]) => {
            this.dropdown.smsTemplates[currency_code] = sms;
            this.dropdown.messageTemplates[currency_code] = message;
            if (init) {
              let currency_id = this.dropdown.currencies.find(x => x.name == currency_code).id,
                selectedSms = this.dropdown.smsTemplates[currency_code].find(x => x.id == this.form.get(`sms_message_templates.${currency_id}`).value),
                selectedMessage = this.dropdown.messageTemplates[currency_code].find(x => x.id == this.form.get(`message_templates.${currency_id}`).value);

              this.smsSelectedItems[currency_code] = selectedSms ? [selectedSms] : [];
              this.messageSelectedItems[currency_code] = selectedMessage ? [selectedMessage] : [];
            }
          })
        }
      })
    }
  }

  private updatePromotionRequired() {
    // Single check
    if (this.smsRequired || this.messageRequired) {
      let smsValid = this.smsRequired,
        messageValid = this.messageRequired;

      this.currencySelectedItems.forEach(currency => {
        if (this.smsRequired && smsValid && this.form.get(`sms_message_templates.${currency.id}`).value == null) {
          smsValid = false;
        }
        if (this.messageRequired && messageValid && this.form.get(`message_templates.${currency.id}`).value == null) {
          messageValid = false;
        }
      });
      this.smsIsValid = smsValid;
      this.messageRequired = !this.smsIsValid;

      this.messageIsValid = messageValid;
      this.smsRequired = !this.messageIsValid;

      this.promotionRequired = !(this.smsIsValid || this.messageIsValid);
    }

    // Combine check
    if (!this.messageIsValid && !this.smsIsValid && (this.smsRequired || this.messageRequired)) {
      let smsMessageValid = true;
      this.currencySelectedItems.forEach(currency => {
        if (smsMessageValid && this.form.get(`sms_message_templates.${currency.id}`).value == null && this.form.get(`message_templates.${currency.id}`).value == null) {
          smsMessageValid = false;
        }
      });
      this.messageIsValid = smsMessageValid;
      this.smsIsValid = smsMessageValid;
      this.promotionRequired = !smsMessageValid;
    }

    if (this.form) {
      this.form.controls['promotion_id'].clearValidators();
      this.form.controls['promotion_id'].setValidators(this.promotionRequired ? [Validators.required] : []);
      this.form.controls['promotion_id'].updateValueAndValidity();
    }
  }

}
