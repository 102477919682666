<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" >
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'Promo Turnover Details ' | translate }}</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <!-- Content -->
                <div class="kt-section">
                    <div class="kt-section__content">
                        <div class="table-responsive">
                            <!-- Table -->
                            <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th>{{ 'Member Promotion ID' | translate }}</th>
                                    <th>{{ 'Username' | translate }}</th>
                                    <th>{{ 'Promo Code' | translate }}</th>
                                    <th>{{ 'Start Data Time' | translate }}</th>
                                    <th>{{ 'End Data Time' | translate }}</th>
                                    <th>{{ 'Target Amount' | translate }}</th>
                                    <th>{{ 'Unlocked Data Time' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="!loading && promoTurnoverDetails as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.id }}</td>
                                    <td>
                                        <a class="text-primary font-weight-bold" (click)="onOpenDialog('member-information', row.member_id)">
                                        {{ row.username }}
                                        </a>
                                    </td>
                                    <td>{{ row.promo_code }}</td>
                                    <td>
                                        {{ data.row['start_datetime'] | timezoneDate:'YYYY-MM-DD HH:mm': currencyTimezone[row.currency] }}
                                    </td>
                                    <td>
                                        {{ row.end_datetime | timezoneDate:'YYYY-MM-DD HH:mm': currencyTimezone[row.currency] }}
                                    </td>
                                    <td class="text-center">
                                        <a class="text-primary font-weight-bold" (click)="onOpenDialog('target-amount-details', null,row)">
                                            {{ row.gsys_turnover_amount | number : '1.2' }}
                                        </a>
                                    </td>
                                    <td>
                                        {{ row.unlocked_at | timezoneDate:'YYYY-MM-DD HH:mm': currencyTimezone[row.currency] }}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="1" class="text-gray-600 fw-bold"></tbody>
                            </table>
                        </div>
                    </div>
                </div>                  
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>{{ 'Close' | translate }}</button>
            </div>
        </div>
    </form>
</div>

<!-- <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert> -->
