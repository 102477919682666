<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body pb-0">
        <div class="kt-section mb-0">
          <div class="kt-section__content" [ngClass]="loading ? 'pb-35': ''">
            <div class="kt-form kt-form--label-right kt-margin-b-10">
              <div class="row align-items-center">
                <div class="col-xl-12 order-2 order-xl-1 pr-0">
                  <form class="row align-items-center" [formGroup]="form">
                    <div class="col-10 row mb-2 pr-0">
                      <div class="col-md-2 kt-form__label">
                        <label>Game Tag</label>
                        <select (change)="changeGameTag()" formControlName="game_tag" class="form-control" [(ngModel)]="selectedGameTag" [disabled]="dropdown.gameTags.length == 0">
                          <option [value]="value.id" *ngFor="let value of dropdown.gameTags" [selected]="selectedGameTag === value.id">{{ value.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-2 pr-0 back-button-container">
                      <a (click)="backToGameTagPage()"  class="button-border"><i class="fas fa-arrow-left"></i> Back</a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <hr>
            <div class="loading-icon-container">
              <tbody shared-table-handler [loading]="loading" [dataLength]="1" class="text-gray-600 fw-bold loading-icon">
              </tbody>
            </div>
            <div *ngIf="!loading">
              <div class="kt-section row d-flex mb-3 sync-save-button">
                <div class="dropdown dropdown-inline">
                  <ng-container *ngIf="selectedCurrency && selectedGameProvider && selectedGameTag && canSyncGames">
                    <kt-sync-save-button
                      [checkupdate] = "checkupdate()"
                      [buttonLoading]="buttonLoading"
                      [setting_currencies_id_to]="selectedCurrency['id']"
                      [game_tag_id]="selectedGameTag"
                      [game_provider_code]="selectedGameProvider['code']">
                    </kt-sync-save-button>
                  </ng-container>
                  <button [class.invisible]="!canEditGameTagSettings" type="button" [disabled]="!gameLoaded || submitLoading ||buttonLoading || (games.LC.length == 0 && games.SL.length == 0 && games.FS.length == 0 && games.LT.length == 0 && games.CG.length == 0 && games.TB.length == 0 && games.AR.length == 0 && gameGameTagList.length == 0) || !checkupdate()" class="btn btn-brand btn-icon-sm" (click)="onSave()"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Save</button>
                </div>
              </div>
              <div class="display-contents modal-content mt--48">
                <mat-tab-group animationDuration="0ms" class="form-group pb-20" (selectedTabChange)="setCurrency($event)" [selectedIndex]="selectedIndex" #tabGroup>
                  <mat-tab *ngFor="let currency of dropdown.currencies; let i = index" [disabled]='!gameLoaded'>
                    <ng-template mat-tab-label>
                      {{currency.name}}
                    </ng-template>
                    <ng-container *ngIf="gameProviderDropdown.length > 0 && isMerchantHasProvider; else noProviderData">
                      <div class="row col-12 pr-0 pl-0 ml-0">
                        <div class="col-md-2 pl-0 pr-20 border-right">
                          <div class="col-12 pl-0 pr-0 mb-3 input-group">
                            <div class="col-md-10 pl-0 pr-0 form-outline">
                              <input type="search" id="form1" class="form-control search-input" placeholder="Search provider..." (keyup)="onSearch($event)" (search)="resetKeyword($event)" />
                            </div>
                            <button type="button" class="col-md-2 btn btn-primary search-icon mr-0" (click)="onSearch()">
                              <i class="fas fa-search"></i>
                            </button>
                            <div class="col-12 pt-22 form-control border-none pl-16" *ngIf="dropdown.gameProviders.length == 0">
                              No provider found
                            </div>
                          </div>
                          <div class="col-12 pl-0 pr-0 list-group">
                            <div class="modal-content col-md-12 border-none" id="list-example">
                              <div class="pl-0 pr-0 form-outline" *ngFor="let provider of dropdown.gameProviders">
                                <div [ngClass]="selectedGameProvider['id'] == provider.id ? 'selected-game-div' : ''">
                                  <span class="list-group-item list-group-item-action border-none" (click)="gameLoaded ? setProvider(provider.id) : '' " [ngClass]="selectedGameProvider['id'] == provider.id ? 'selected-game-provider' : ''">
                                    {{ provider.code + ' - ' + provider.name }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-10 pr-0">
                          <div class="col-12">
                            Note: To adjust the display position of the games, simply drag and drop them as desired.
                          </div>
                          <div class="col-12 pr-0" *ngIf="categoryList.length > 0 && currency.name == selectedCurrency['name']">
                            <div id='cat' class="accordion accordion-light accordion-light-borderless accordion-svg-toggle">
                              <div class="card mb-4" *ngFor="let category of categoryList; let last = last; let i = 'index+1'; let j = 'index'" [ngClass]="{'accordion-last-child': last}">
                                <div class="card-header row col-12" [id]="'catHeading' + i" (click)="scrollTop(category.code)">
                                  <ng-container *ngIf="selectedExpandedCategoryCode[j]">
                                    <button class="kt-section row d-flex justify-content-between mb-0 collapse-button" (click)="setCategory(category)">
                                      <a class="card-title text-dark pr-0 dropdown-inline" [ngClass]="selectedExpandedCategoryCode.length > 0 && selectedExpandedCategoryCode[j]['code']  == category.code && selectedExpandedCategoryCode[j]['expand'] == 1 ? '' : 'collapsed'" data-toggle="collapse" aria-expanded="true" [attr.aria-controls]="'cat' + i" role="button">
                                        <div class="col-2 display-content">
                                          <span class="svg-icon svg-icon-primary">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero"></path>
                                                <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) ">
                                                </path>
                                              </g>
                                            </svg>
                                          </span>
                                          <div class="card-label text-dark pl-2">{{category.category}}</div>
                                        </div>
                                        <div class="pl-2">
                                          <tbody shared-table-handler [loading]="!gameLoaded" [dataLength]="1" class="text-gray-600 fw-bold"></tbody>
                                        </div>
                                      </a>
                                    </button>
                                  </ng-container>
                                  <ng-container *ngIf="!selectedExpandedCategoryCode[j]">
                                    <div class="pl-2">
                                      <tbody shared-table-handler [loading]="!gameLoaded" [dataLength]="1" class="text-gray-600 fw-bold"></tbody>
                                    </div>
                                  </ng-container>
                                  <div class="dropdown dropdown-inline assign-reset-button">
                                    <button class="btn btn-success btn-icon-sm mr-2" [hidden]="!canAddGames || selectedGameTagName == 'all'" [disabled]="!gameLoaded" (click)="onOpenDialog('add-game', category)"><i class="fas fa-plus"></i>Games</button>
                                    <button 
                                      *ngIf="selectedGameTagName == 'all' ? canResetGames : canRemoveGames"
                                      class="btn btn-danger btn-icon-sm"
                                      [disabled]="resetLoading || !gameLoaded || getList(category.code).length == 0"
                                      (click)="onOpenDialog('reset-game', category)"
                                    >
                                      <i class="fas fa-times"></i>
                                      {{ selectedGameTagName == 'all' ? 'Reset' : 'Remove All'}}
                                    </button>
                                  </div>
                                </div>
                                <ng-container *ngIf="selectedExpandedCategoryCode[j]">
                                  <div [id]="'cat' + i" class="scroll-container collapse" [ngClass]="selectedExpandedCategoryCode.length > 0 && selectedExpandedCategoryCode[j]['code'] == category.code && selectedExpandedCategoryCode[j]['expand'] == 1 ? 'show' : ''" [attr.aria-labelledby]="'catHeading' + i" data-parent="#cat">
                                    <p *ngIf="getList(category.code).length == 0 && gameLoaded" class="no-games-text">No games added</p>
                                    <div [id]="'cat_point_' + category.code" class="card-body text-dark-50 font-size-lg p-0 scrollable-y" [ngClass]="getList(category.code).length > 0 ? '' : 'no-games'">
                                      <div class="example-container" cdkDropListGroup [ngStyle]="{ '--box-width': boxWidth, '--box-height': boxHeight }">
                                        <div cdkDropList (cdkDropListEntered)="onDropListEntered($event, category.code)" (cdkDropListDropped)="onDropListDropped(category.code)"></div>
                                        <div cdkDropList (cdkDropListEntered)="onDropListEntered($event, category.code)" (cdkDropListDropped)="onDropListDropped(category.code)" *ngFor="let game of getList(category.code)" [cdkDropListData]="getList(category.code)">
                                          <div cdkDrag class="example-box">
                                            <div class="col-12 display-grid p-0">
                                              <div class="img-container">
                                                <img draggable="false" class="img-fluid prevent-select" [src]="game.image_path2 ? game.image_path2 : game.image_path" />
                                              </div>
                                              <div class="game-name-container">
                                                <span class="game-name prevent-select">{{ game.name }}</span>
                                              </div>
                                              <i *ngIf="selectedGameTagName !== 'all' && canRemoveGames" (click)="removeGame(game.game_id, category)" class="fas fa-trash-alt trash-icon"></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #noProviderData>
                      No provider available for this merchant
                    </ng-template>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
