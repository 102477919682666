<style>
body {
    margin: 0;            /* Reset default margin */
}
html, body { height: 100%; }
iframe {
    display: block;       /* iframes are inline by default */
    border: none;         /* Reset default border */
    height: 100vh;        /* Viewport-relative units */
    width: 100%;
    overflow: hidden;
    position: relative;
}
</style>
<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
      <iframe [src]="errorLogResource | safe:'resourceUrl'" height="100%" width="100%"></iframe>
  </div>
</div>
