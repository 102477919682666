import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { AboutUs } from '@core/models/about-us.model';

@Injectable()
export class AboutUsEntityService extends EntityCollectionServiceBase<AboutUs> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('AboutUs', serviceElementsFactory);
  }
}
