<div  class="modal-content">
  <div  class="modal-header">
      <h5 class="modal-title">Topup</h5>
  </div>
  <div  class="modal-body">
      <div  >
          <div   class="form-group row">
              <label   class="col-lg-3 col-form-label text-right">Remarks:</label>
              <div   class="col-lg-9">
                  <input   type="text" placeholder="Required" class="form-control is-invalid">
              </div>
          </div>
          <div   class="form-group row">
              <label   class="col-lg-3 control-label text-right">User:</label> <span   class="col-lg-9"> (ting01)</span></div>
          <div   class="form-group row">
              <label   for="wallet" class="col-lg-3 control-label text-right">
                  Current Balance:</label>
              <div   class="col-lg-9">
                  <input   id="wallet" name="wallet"  disabled="disabled" class="form-control">
              </div>
          </div>
          <hr  >
          <div   class="form-group row" style="display: none;">
              <label   for="amount" class="col-lg-3 col-form-label text-right">Amount:</label>
              <div   class="col-lg-9">
                  <input   type="number" (wheel)="false" min="0.00" step="1" id="amount" name="amount"  placeholder="0.00" class="form-control">
              </div>
          </div>
          <div   class="form-group row">
              <label   class="col-lg-3 col-form-label text-right">Bonus Amount:</label>
              <div   class="col-lg-9">
                  <input   type="number" (wheel)="false" min="0.00" step="1" placeholder="0" class="form-control">
              </div>
          </div>
          <div   class="form-group row">
              <label   class="col-lg-3 col-form-label text-right">Option:</label>
              <div   class="col-lg-9">
                  <div   class="kt-radio-list">
                      <label   class="kt-radio">
                          <input   type="radio" name="group1" value="1"> Custom Bonus<span  ></span></label>
                      <label   class="kt-radio">
                          <input   type="radio" name="group1" value="0"> Normal Deposit<span  ></span></label>
                  </div>
              </div>
          </div>
          <div   class="form-group row" style="display: none;">
              <label   class="col-lg-3 col-form-label text-right">Promotion:</label>
              <div   class="col-lg-9">
                  <select   id="promotion"  class="form-control">
                      <option   value="46">
                          Sport Welcome Bonus 100%
                      </option>
                      <option   value="32">
                          Slot Welcome Bonus 100%
                      </option>
                      <option   value="44">
                          Welcome Bonus 30%
                      </option>
                      <option   value="34">
                          Slot Bonus 10%
                      </option>
                      <option   value="36">
                          Slot 5% Bonus
                      </option>
                      <option   value="45">
                          Live Casino 3%
                      </option>
                      <option   value="47">
                          Live Casino 3%
                      </option>
                      <option   value="41">
                          Sport 10%
                      </option>
                      <option   value="42">
                          For M-Sport / S-Sport
                      </option>
                      <option   value="43">
                          BIrthDay Bonus
                      </option>
                      <option   value="1">
                          I Do Not Accept Any Promotion
                      </option>
                  </select>
              </div>
          </div>
          <div   class="form-group row">
              <label   class="col-lg-3 col-form-label text-right">Rollover(Multiplier):</label>
              <div   class="col-lg-9">
                  <input   type="number" (wheel)="false" min="0.00" step="1" placeholder="0x"  class="form-control">
              </div>
          </div>
          <div   class="form-group row">
              <label   class="col-lg-3 col-form-label text-right">Playable Game:</label>
              <div   class="col-lg-9">
                  <div   class="kt-checkbox-inline">
                      <label   class="kt-checkbox">
                          <input   type="checkbox" name="playable" value="0" checked="checked" disabled="disabled"> All
                          <span  ></span></label>
                  </div>
              </div>
          </div>
          <div   class="form-group row" style="display: none;">
              <label   class="col-lg-3 col-form-label text-right">
                  Company Bank Account:
              </label>
              <div   class="col-lg-9">
                  <select    class="form-control">
                      <option   value="49">
                          1012129000122899 | SHAMINI A/P DEVENDRE | Simpanan Nasional
                      </option>
                      <option   value="48">
                          23000022140 | SNS SAREES COLLECTION | Hong Leong
                      </option>
                      <option   value="47">
                          3814681609 | SNS SAREES COLLECTION | Public Bank
                      </option>
                      <option   value="46">
                          21201300127507 | SNS SAREES COLLECTION | RHB Bank
                      </option>
                      <option   value="45">
                          512147527502 | SNS SAREES COLLECTION | Maybank
                      </option>
                      <option   value="44">
                          23000027358 | KPTD TRANSPORT | Hong Leong
                      </option>
                      <option   value="42">
                          21201300130770 | KPTD TRANSPORT | RHB Bank
                      </option>
                      <option   value="41">
                          1012141100008823 | KARUNAGARAN A/L RATT | Simpanan Nasional
                      </option>
                      <option   value="40">
                          512147533173 | KPTD TRANSPORT | Maybank
                      </option>
                  </select>
              </div>
          </div>
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    <kt-submit-button [isDisabled]="false" [buttonLoading]="false"></kt-submit-button>
  </div>
</div>
