<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog d-block">
  <!-- <form class="kt-form"> -->
  <div class="modal-content">
    <!-- Header -->
    <div class="modal-header">
      <h5 class="modal-title">
        {{ data.mode === "create" ? "Create" : "Edit" }} SMS Provider
      </h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <!-- Body -->
    <div class="modal-body">
      <form *ngIf="providers !== undefined">
        <div class="row col-md-12">
          <div class="col-4 col-form-label">
            <label>Currency</label>
          </div>
          <div class="col-6">
            <select class="form-control" id="currencyDropdown" disabled>
              <ng-container *ngFor="let value of data.currencies">
                <option [value]="value.id" [selected]="value.id === data.currencyID">{{ value.name }}</option>
              </ng-container>
            </select>
          </div>
        </div>

        <form [formGroup]="form">
          <div class="row col-md-12 mt-3" *ngFor="let setting of settings">
            <div class="col-4 col-form-label">
              <label>{{ setting.name }}</label>
            </div>
            <div class="col-6">
              <select class="form-control" [formControlName]="setting.type">
                <option [ngValue]="0">
                  Please Select
                </option>
                <ng-container *ngFor="let provider of getDropdown(setting.type_id)">
                  <option [ngValue]="provider.id">
                    {{ provider.fullname }}
                  </option>
                </ng-container>
              </select>
            </div>
            <div class="col-2 d-flex" *ngIf="setting.type === 'otp'">
              <button class="copy-to-all-btn" (click)="copyOTPProviderToAll()">
                <i class="fa fa-clone"></i> Copy to all
              </button>
            </div>
          </div>
        </form>
      </form>
    </div>
    <!-- Footer -->
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()">
        <i class="fas fa-ban"></i>Close</button>
      <kt-submit-button
        [buttonLoading]="buttonLoading"
        (confirmed)="onSave()"
      ></kt-submit-button>
    </div>
  </div>
  <!-- </form> -->
</div>
<!-- Swal Alert -->
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog()"></kt-swal-alert>