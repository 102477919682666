import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { Pagination } from '@core/models/pagination.model';
import { TransferDataService } from './../services/transfer-data.service';
import { tap, delay, catchError } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, forkJoin, Observable, of } from 'rxjs';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy, ViewChildren, QueryList, ElementRef, Pipe } from '@angular/core';
import { TransactionType } from '@core/enums/transaction-type.enum';
import { Transfer } from '@core/models/transfer.model';
import { MemberDataService } from './../../members/services/member-data.service';
import { MemberEntityService } from './../../members/services/member-entity.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'kt-transfer-edit',
  templateUrl: './transfer-edit.component.html',
  styleUrls: ['./transfer-edit.component.scss']
})
export class TransferEditDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('filterInput') filterInput: QueryList<ElementRef>;
  form: FormGroup;
  messages$ = this.transferDataService.messages$;
  pagination: Pagination;
  username = '';
  pageNumber = 1;
  memberAccId = 0;
  lastPage = null;
  promotions$: any = [];
  params = '';
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    gameProviders: this.dropdownHttpService.gameProviders,
    members: this.dropdownHttpService.members,
  };
  transfertypeRef = TransactionType;
  members$ = [];
  transferType = Object.keys(TransactionType).filter(type => !(parseInt(type, 10) >= 0));
  memberCurrentWalletAmount = 0;
  gameBalance = 0;
  private subscription = new Subscription();
  private messageSubscription = new Subscription();
  refreshStatus: boolean;
  dropdownSettings = {};
  gameProvidersDropdownSettings = {};
  gameProvidersDropdownList = [];
  gameProvidersSelectedItems: any;

  promotionCodeDropdownSettings = {};
  promotionCodeDropdownList = [];
  promotionCodeSelectedItems = [];


  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { transfer: Transfer, mode: string },
    public dialogRef: MatDialogRef<TransferEditDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private transferDataService: TransferDataService,
    private memberEntityService: MemberEntityService,
    private memberDataService: MemberDataService,
    private gameProviderHttpService: GameProviderHttpService
  ) { }

  ngOnInit() {
    this.dropdownDynamicWidth();
    this.formInit();
    this.setMembers();
    this.pagination = this.memberDataService.pagination;

    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'labelKey',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.dropdownHttpService.gameProviders.subscribe(res => {
      this.gameProvidersDropdownList = res;
      this.gameProvidersDropdownList.map(function (elm) {
        elm['labelKey'] = elm.code + ' - ' + elm.name;
      });
    });

    this.gameProvidersDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'name',
      labelKey: 'labelKey',
      noDataLabel: '',
      showCheckbox: false
    };
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onOpenSelect() {
    of(null).pipe(
      delay(0), tap(() => this.filterInput.first.nativeElement.focus()
      )).subscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSelectMember(memberId: number, gameId: number) {
    this.memberAccId = memberId;

    if (memberId > 0 && gameId > 0) {
      this.getProviderWallet(memberId, gameId);
    }
    this.onGetPromotions(this.form.value.game_provider_id);
    this.getMainWallet(memberId);
  }

  onSelectGameProvider(memberId: number, gameId: number) {
    if (memberId > 0 && gameId > 0) {
      this.getProviderWallet(memberId, gameId);
    }
  }

  onSave(transfer: Transfer) {
    this.buttonLoading = true;
    // To set 'Save' button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      ...this.form.value,
      promotion_id: this.form.value.promotion_id === null ? 0 : this.form.value.promotion_id
    };
    if(this.form.value.promotion_id === null || this.form.value.promotion_id === 0 || this.gameBalance === 0 || this.form.value.transfer_type === 2){
      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
      this.subscription = forkJoin([
        this.transferDataService.add(data, false).pipe(
          tap((res: any) => {
            this.buttonLoading = false;
            // To enable 'Save' button after get response
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            // To enable 'Save' button after get response
            this.form.setErrors(null);
            throw error;
          })
        ),
        this.transferDataService.messages$
      ]).subscribe();
      this.refreshStatus = true;
    }else{
      Swal.fire({
        title: 'System Message',
        html: 'There is a remaining balance in the selected game wallet. This balance will be transferred back to the member\'s main wallet before the promotion application.<br><br>Are you sure you want to proceed with the transfer and promotion application?',
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((resp) => {
        if (resp.isConfirmed) {
        // Prepare param for transferring the remaining game wallet balance to main wallet first
        const dataFromGameToMain = {
          amount: this.gameBalance,
          promotion_id: 0,
          transfer_type: 2,
          game_provider_id: this.form.value.game_provider_id,
          member_account_id: this.form.value.member_account_id
        }
        const data2 = {
          ...this.form.value,
          amount: this.form.value.amount
        };
        Object.keys(data2).forEach((key) => (data2[key] == null || data2[key] === '') && delete data2[key]);
        this.onEmptyGameWallet(dataFromGameToMain).toPromise()
        .then(async () => {
          //temporary solution for 918BOT - sleep 15 seconds
          this.gameProvidersSelectedItems = [this.gameProvidersDropdownList.find(x => x.id === this.form.value.game_provider_id)];
          if(this.gameProvidersSelectedItems[0].code === '918BOT'){
            await this.sleep(15000);
          }
          this.subscription = forkJoin([
            this.transferDataService.add(data2, true).pipe(
              tap((res: any) => {
                this.buttonLoading = false;
                // To enable 'Save' button after get response
                this.form.setErrors(null);
                let main_wallet_amount = +this.memberCurrentWalletAmount - +res.amount + +this.gameBalance;
                let game_wallet_balance = +res.amount + +res.bonus_amount;
                let currency_code = this.getCurrencyName(res.settings_currency_id);
                this.onSwal(main_wallet_amount, game_wallet_balance, currency_code);
              }),
              catchError((error) => {
                this.buttonLoading = false;
                // To enable 'Save' button after get response
                this.form.setErrors(null);
                throw error;
              })
            )
            ]).subscribe();
            this.refreshStatus = true;
          })
        }else if (resp.isDenied || resp.isDismissed) {
          this.buttonLoading = false;
          this.form.setErrors(null);
        }
      });
    }
  }

  onSwal(main_wallet_balance: number, game_wallet_balance: number, currency_code: string){
    //const nativeElement = this.walletBalances.nativeElement;
    //nativeElement.style.cssText = 'visibility:visible';
    Swal.fire({
      title: 'System Message',
      icon: 'info',
      heightAuto:false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
      html: '<div class="text-center m-t-20">' +
              'The transfer and promotion have been successfully applied' +
            '</div>' + '<br>' + 
            '<div class="row col-12 form-group text-left">' +
              '<label class="col-5 col-form-label"> Main Wallet : </label>' +
              '<span class="input-group-append col-2 input-group-text">'+currency_code+'</span>'+
              '<span class="col-5 input-group-text">'+main_wallet_balance.toFixed(2).toString()+'</span>' +
            '</div>' +
            '<div class="row col-12 form-group text-left">' +
              '<label class="col-5 col-form-label"> Game Wallet : </label>' +
              '<span class="input-group-append col-2 input-group-text">'+currency_code+'</span>'+
              '<span class="col-5 input-group-text">'+game_wallet_balance.toFixed(2).toString()+'</span>' +
            '</div>'
    }).then((result) => {
      if (result.isConfirmed) {
        this.dialogRef.close(true);
      }
    });
  }

  onEmptyGameWallet(data: any){
    return this.transferDataService.add(data, true).pipe(
      tap((res: any) => {
        this.form.setErrors(null);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    );
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onSearchMember(event?: Event) {
    if (this.username !== (event.target as HTMLSelectElement).value) {
      this.members$ = [];
      this.pageNumber = 1;
      this.username = (event.target as HTMLSelectElement).value;
      this.setMembers();
    }
  }

  onScrollToEnd() {
    if (this.pageNumber >= this.pagination.last_page) {
      return;
    }
    this.pageNumber++;
    this.setMembers();
  }

  onGetPromotions(gameProviderId: number) {
    this.promotionCodeDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };

    this.form.patchValue({ promotion_id: null });
    if (this.form.value.transfer_type === 2) {
      this.form.patchValue({ promotion_id: 0 });
    }

    if (gameProviderId > 0 && this.memberAccId > 0) {
      this.transferDataService.getPromotion(`?member_account_id=${this.memberAccId}&game_provider_id=${gameProviderId}`).pipe(
        tap(res => {
          this.promotionCodeDropdownList = [];
          Object.keys(res).map(key => {
            this.promotionCodeDropdownList.push(res[key]);
          });
          this.promotionCodeDropdownList.map(function (elm) {
            elm['name'] = elm.code + ' - ' + elm.name;
          });
        })
      ).subscribe();
    };
  }

  getCurrencyName(settings_currency_id) {
    return this.dropdown.currencies?.find(c => c.id === settings_currency_id)?.name;
  }

  private setMembers() {
    const members = this.memberEntityService.getWithQuery(`?status=1&page=${this.pageNumber}&username=${this.username}`);
    members.pipe(
      tap((res) => {
        Object.keys(res).forEach(key => this.members$.push(res[key]));
        this.pagination = this.memberDataService.pagination;
      })
    )
      .subscribe();
  }

  private getMainWallet(memberId: number) {
    const mainWallet = this.memberDataService.getById(memberId);
    mainWallet.pipe(
      tap((res) => {
        this.memberCurrentWalletAmount = res.wallets[0].balance;
      })
    ).subscribe();
  }

  private getProviderWallet(memberId: number, gameId: number) {
    const gameProvider = this.gameProviderHttpService.getGameProviderBalance(`?member_account_id=${memberId}&game_provider_id=${gameId}`);
    gameProvider.pipe(
      tap((res) => {
        this.gameBalance = res.balance > 0 ? res.balance : 0;
      })
    ).subscribe();
  }

  private formInit() {
    const memberAccountId = null;
    const amount = null;
    const gameProviderId = null;
    const transferType = null;

    this.form = new FormGroup({
      member_account_id: new FormControl(memberAccountId, [Validators.required]),
      amount: new FormControl(amount, Validators.compose([Validators.required, Validators.min(0)])),
      game_provider_id: new FormControl(gameProviderId, [Validators.required]),
      transfer_type: new FormControl(transferType, [Validators.required]),
      promotion_id: new FormControl(null)
    });

    this.form.get('member_account_id').valueChanges.subscribe(
      res =>
        this.onSelectMember(res, null)
    );

    this.form.get('game_provider_id').valueChanges.subscribe(
      res => {
        this.onSelectGameProvider(this.form.value.member_account_id, res);
        this.onGetPromotions(res);
      }
    );
  }

  private dropdownDynamicWidth() {
    let interval = setInterval(() => {
      let dropdownList = document.querySelector('#gameProviderDropdown .dropdown-list') as HTMLElement;
      if (dropdownList) {
        dropdownList.style.width = `${document.getElementById('gameProviderDropdown').offsetWidth}px`;
        clearInterval(interval);
      }
    });
  }

  private sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
