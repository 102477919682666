<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="kt-form kt-form--label-right  mb-2">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <div class="col-12 row mb-2 pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Username:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                </div>
                <!-- <div class="col-md-1 kt-form__label col-form-label">
                            <label>Name:</label>
                        </div>
                        <div class="col-md-2 kt-form__control">
                          <input type="text" formControlName="name" placeholder="Search" class="form-control">
                        </div> -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Purpose:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="purpose_id">
                    <option value="all"> All </option>
                    <option [value]="purpose.id" *ngFor="let purpose of dropdown.purposes | async">{{ purpose.name }}</option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="status">
                    <option value="all"> All </option>
                    <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">{{ value }}</option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Keyword:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="keyword" placeholder="Search" class="form-control">
                </div>
              </div>

              <div class="col-12 row mb-2 pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date/Time:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="input-group date">
                    <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" (startDateChanged)="selectStartDate($event)" [dateLimit]="dateLimit"
                      [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                    <span class="input-group-append">
                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                    </span>
                  </div>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>SMS Provider:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="d-flex flex-wrap status-filter-pt">
                    <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.smsProvider; let i = index">
                      <input type="checkbox" class="custom-control-input" [checked]="item.checked" (change)="onSearchProvider($event, item.id, i)" [id]="item.name">
                      <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 row mb-2 pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                    <label>Currency:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                    <select class="form-control" formControlName="currency_id" (change)="onCurrencyChange()">
                        <option [value]="null" [disabled]="true">Please Select</option>
                        <option value="all">All</option>
                        <ng-container *ngFor="let value of dropdown.currencies">
                            <option [value]="value.id">{{ value.name }}</option>
                        </ng-container>
                    </select>
                </div>
               
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Account Type:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="account_type_condition" class="form-control" >
                    <option value="And">AND Condition</option>
                    <option value="Or">OR Condition</option>
                  </select>
                </div>
                <div class="col-md-3 kt-form__control">
                  <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
                    [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
                    [formName]="'account_type'" [selectionAttributes]="'id'"
                    [selectedItems]='accountTypeSelectedItems'>
                  </kt-dropdown-wo-lazyload>
                </div>
              
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="kt-section col-12 row mb-3 d-flex justify-content-between">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <div class="dropdown dropdown-inline">
          <button class="btn btn-warning btn-icon-sm" *ngIf="canExportSms" (click)="onExport()" type="button" [disabled]="buttonLoading"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
        </div>
      </div>
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                  <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Recipient</th>
                  <th class="sort-enabled" (click)="onSortColumn('content')" [ngClass]="sortingConfig.content" [class.sort-selected]="sortingSelection.sort_by === 'content'">Content</th>
                  <th class="sort-enabled" (click)="onSortColumn('sms_provider_name')" [ngClass]="sortingConfig.sms_provider_name" [class.sort-selected]="sortingSelection.sort_by === 'sms_provider_name'">SMS Provider</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                  <th>Result Message</th>
                  <th class="sort-enabled" (click)="onSortColumn('purpose_id')" [ngClass]="sortingConfig.purpose_id" [class.sort-selected]="sortingSelection.sort_by === 'purpose_id'">Purpose</th>
                  <th class="date-column sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                  <th class="date-column sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                </tr>
              </thead>
              <tbody *ngIf="(logs$ | async) as rows">
                <tr *ngFor="let row of rows">
                  <td>{{ row.id }}</td>
                  <td>
                    <a *ngIf="row.affiliate_id != 0" class="text-primary font-weight-bold" (click)="onOpenDialog('affiliate-information', row.affiliate_id)">{{ row.affiliate_username }}</a>
                    <a *ngIf="row.member_account_id != 0" [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', row.member_account_id)">{{ row.username }}</a>
                    <br/> {{ row.sent_to }}
                  </td>
                  <td>{{ row.content }}</td>
                  <td>{{ row.sms_provider_name }}</td>
                  <td class="text-center">
                    <span class="kt-badge status-color kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'status-color--' + row.status"> {{ row.status_name }} </span>
                    <!-- {{ row.status_name }} -->
                  </td>
                  <td class="column-limit-width">{{ row.api_results }}</td>
                  <td>{{ row.purpose_name }}</td>
                  <td>
                    {{ row.created_by ? row.created_by : 'System' }} <br />
                    <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #createdDateTime>
                      {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                  <td>
                    System<br/>
                    <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #updatedDateTime>
                      {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kt-swal-alert [message]="messages$ | async"></kt-swal-alert>