import { Router } from '@angular/router';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { tap, map, exhaustMap } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subscription, interval, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubheaderService } from '@core/_base/layout';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewRef } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AgentEntityService } from './services/agent-entity.service';
import { AgentDataService } from './services/agent-data.service';
import { AgentEditDialogComponent } from './dialogs/agent-edit.component';
import { Agent } from '@core/models/agent.model';
import * as moment from 'moment-timezone';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { environment } from '@env/environment';
import { CurrencyHttpService } from '@core/services/currency-http.service';

@Component({
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage
  };
  agents$: Observable<Agent[]>;
  ranges: any;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  affType = JSON.parse(localStorage.getItem('user_data')).agent_type;
  signUpUrl = environment.signUpUrl;
  downlineUsername = [];
  limitDownLine = this.affType === 0 ? 3 : this.affType === 1 ? 2 : this.affType === 2 ? 1 : 0;
  dropdownSettings = {};
  previousUplineID: number;
  currentUplineID = 0;
  currentAgentType = 0;

  selectedItems = [];
  loggedUser: string;
  breadcrumb: any;
  breadcrumbLevels = [];
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  dataLength = 0;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;

  private subscription = new Subscription();
  private refreshSubcription = new Subscription();
  private datePickerSubscription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private agentEntityService: AgentEntityService,
    private agentDataService: AgentDataService,
    private dropdownHttpService: DropdownHttpService,
    private eventEmitterService: EventEmitterService,
    private transactionHttpService: TransactionHttpService,
    private router: Router,
    private currencyHttpService: CurrencyHttpService
    ) { }

  ngOnInit() {
    this.setCurrencyDropdown();

    this.formInit();
    if (this.affType === 3) {
      this.selectedItems.push({
        username: this.loggedUser
      })
      this.form.patchValue({
        agent: this.loggedUser
      })
    }
    this.onSubmit(true);
    this.pagination = this.agentDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      maxHeight: 200,
      noDataLabel: '',
      showCheckbox: false
    };
  }

  ngOnDestroy() {
    this.refreshSubcription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    this.loadingBar.start();
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    return this.agents$ = this.agentEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.agentDataService.pagination;
        this.dataLength = res.length;
        this.loading = false;
        this.loadingBar.complete();
      })
    );
  }

  onDownLine(downlineUsername: string, type: string, uplineID?: number) {
    if (type === 'table') {
      this.currentUplineID = uplineID;
      this.downlineUsername.push(downlineUsername);
    } else {
      this.downlineUsername.pop();
    }
    if (this.affType === 0) {
      this.currentAgentType = this.downlineUsername.length - 1;
    } else {
      this.currentAgentType = this.downlineUsername.length;
    }
    this.form.patchValue({
      username: null,
      agent: downlineUsername,
      code: null,
      name: null,
      status: 'all',
      mobile: null,
      currency_id: 'all',
      start_date: null,
      end_date: null,
      defaultDate: null
    });
    this.onSubmit();
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(mode: string, agentData?: Agent) {
    if (mode === 'create') {
      this.openDialogBy(AgentEditDialogComponent, { mode: 'create', uplineID: this.currentUplineID, agentType: this.currentAgentType });
    } else if (mode === 'edit') {
      const member = this.agentEntityService.getByKey(agentData.id);
      this.subscription = member.pipe(
        tap((res) => {
          if (res) {
            this.openDialogBy(AgentEditDialogComponent, { mode: 'edit', agent: res, uplineID: this.currentUplineID, agentType: this.currentAgentType });
          }
        })
      ).subscribe();
    }
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    if (this.affType === 3) {
      this.currentUplineID = 0;
      this.currentAgentType = 0;
      this.downlineUsername = [JSON.parse(localStorage.getItem('user_data')).username];
      this.form.patchValue({
        agent: null,
        code: null,
        name: null,
        status: 'all',
        mobile: null,
        currency_id: 'all',
        start_date: null,
        end_date: null,
        defaultDate: null
      });
    } else {
      this.eventEmitterService.onClearMemberSearch();
      this.currentUplineID = 0;
      this.currentAgentType = 0;
      this.downlineUsername = [JSON.parse(localStorage.getItem('user_data')).username];
      this.form.patchValue({
        username: null,
        agent: null,
        code: null,
        name: null,
        status: 'all',
        mobile: null,
        currency_id: 'all',
        start_date: null,
        end_date: null,
        defaultDate: null
      });
    }

    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.agents$ = this.agentEntityService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.page = 1;
            this.dataLength = res.length;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.pagination = this.agentDataService.pagination;
            this.breadcrumb = this.agentDataService.breadcrumb;
            this.breadcrumbLevels = this.agentDataService.breadcrumbLevel;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, agent?: Agent, uplineID: number, agentType: number }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '600px',
      data: {
        mode: data.mode,
        agent: data.agent,
        uplineID: data.uplineID,
        agentType: data.agentType
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      agent: new FormControl(null),
      code: new FormControl(null),
      name: new FormControl(null),
      status: new FormControl('all'),
      mobile: new FormControl(null),
      currency_id: new FormControl('all'),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null) // Do not remove: For Clearing The Range
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  redirectAgent(code: string) {
    this.router.navigate([]).then(() => { window.open(environment.signUpUrl + '?aff=' + code, '_blank'); });
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }
}
