import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RebateHistory } from '@core/models/rebate-history.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { RebateHistoryDataService } from './services/rebate-history-data.service';
import { RebateHistoryEntityService } from './services/rebate-history-entity.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';

@Component({
  selector: 'kt-rebate-history',
  templateUrl: './rebate-history.component.html',
  styleUrls: ['./rebate-history.component.scss']
})
export class RebateHistoryComponent implements OnInit {

  form: FormGroup;
  rebateHistory$: Observable<RebateHistory[]>;
  pagination: any;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  ranges: any;
  from = this.transactionHttpService.getLast24Hours().from;
  to = this.transactionHttpService.getLast24Hours().to;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  private datePickerSubscription = new Subscription();

  dropdown = {
    statuses: this.dropdownHttpService.rebateHistoryStatuses,
    perPage: this.dropdownHttpService.perPage,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
  };
  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '4.2';
  sortingConfig = {
    'id': 'desc',
    'game_provider_code': 'desc',
    'currency_code': 'desc',
    'start_datetime': 'desc',
    'total_amount': 'desc',
    'status': 'desc',
    'remarks': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',

  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  constructor(
    private rebateHistoryDataService: RebateHistoryDataService,
    private rebateHistoryEntityService: RebateHistoryEntityService,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private eventEmitterService: EventEmitterService,
    private currencyHttpService: CurrencyHttpService
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.formInit();
    this.onSubmit();

    this.ranges = this.transactionHttpService.ranges;
    this.pagination = this.rebateHistoryDataService.pagination;
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `${this.params}` : '';
    this.loadingBar.start();
    return this.rebateHistory$ = this.rebateHistoryEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}&${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.rebateHistoryDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        this.params = this.params ? `${this.params}` : '';
        return this.rebateHistory$ = this.rebateHistoryEntityService.getWithQuery(`?perPage=${this.pageSize}&${this.params}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.rebateHistoryDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.form.patchValue({
      currency_id: 'all',
      status: 'all',
      start_date_time: this.from,
      end_date_time: this.to,
      defaultDate: {
        startDate: this.from,
        endDate: this.to
      }
    });
    this.onSubmit(true);
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }
    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private formInit() {
    this.form = new FormGroup({
      currency_id: new FormControl("all"),
      status: new FormControl('all'),
      start_datetime: new FormControl(this.from, [Validators.required]),
      end_datetime: new FormControl(this.to, [Validators.required]),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      })
    });
  }

}
