<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Duplicate From ({{ data.device_type == 1 ? 'Desktop' : 'Mobile' }})</h5>
            <span class="modal-x-button" (click)="onCloseDialog()"><i class="fa fa-times" aria-hidden="true"></i></span>
        </div>
        <div class="modal-body">
          <div class="col-12 form-group row pr-0">
            <label class="col-2 col-form-label">Domain <span class="text-danger">*</span></label>
            <div class="col-md-10 form-group required">
                <kt-dropdown-wo-lazyload
                  [form] = 'form'
                  [dropdownList] = 'domainList'
                  [dropdownSettings] = 'domainDropdownSettings'
                  [formName] = "'target_seo_setting_id'"
                  [selectionAttributes] = "'seo_setting_id'"
                  [selectedItems] = 'domainSelectedItems'
                  (selectedItemsChanged) = 'onDomainChange($event)'>
                </kt-dropdown-wo-lazyload>
            </div>
          </div>
          <div class="col-12 table-responsive form-group pr-0 pl-0">

            <section class="col-12 heading">
              <div class="col-12">
                <label>Routes</label>
              </div>
            </section>

            <div class="child" *ngIf="settingLoading">
              <div class="spinner-wrapper d-flex justify-content-center mb-4 mt-4">
                <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
              </div>
            </div>

            <section class="child" *ngIf="!form.valid && !settingLoading">
              <div class="col-12">
                Select Domain to load routes.
              </div>
            </section>

            <section class="parent" *ngIf="data.routes.length > 0 && form.valid && !settingLoading">
              <div class="col-12">
                <input type="checkbox" id="selectAll" (change)="onSelectAllChange($event)">
                <label for="selectAll">Select All</label>
              </div>
            </section>
            
            <ng-container *ngIf="form.valid && !settingLoading">
              <section class="child" *ngFor="let item of data.routes ; let i = index">
                <div class="col-12 pl-4">
                  <input type="checkbox" [checked]="item.checked" (change)="onSelectRoute($event, item.id, i)" [id]="item.name"/>
                  <label [for]="item.name">{{ item.name }} <span class="hint" *ngIf="item.is_blank">(Is Blank)</span></label>
                </div>
              </section>
            </ng-container>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <button class="btn btn-brand" [disabled]="!form.valid || selectedRoutes.length <= 0" (click)="onDuplicate()"><i class="fas fa-save"></i>Duplicate</button>
        </div>
    </div>
  </form>
</div>