import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MemberLog } from '@core/models/member-log.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import * as moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MemberInformationDialogComponent } from './../../general/members/dialogs/member-information/member-information.component';
import { MemberDataService } from './../../general/members/services/member-data.service';
import { MemberLogEntityService } from './services/member-log-entity.service';

@Component({
  selector: 'kt-member-logs',
  templateUrl: './member-logs.component.html',
  styleUrls: ['./member-logs.component.scss']
})
export class MemberLogsComponent implements OnInit {
  form: FormGroup;
  ranges: any;
  maxDate: any;
  dateTimePickerLocale: any;

  memberLogs: MemberLog[] = [];
  page = 1;
  pageSize = 30;
  params = '';
  loading = false;
  loadmore = true;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  private subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private authHttpService: AuthHttpService,
    private memberDataService: MemberDataService,
    private memberLogEntityService: MemberLogEntityService,
    private transactionHttpService: TransactionHttpService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
    this.ranges = this.transactionHttpService.ranges;

    this.formInit();
    this.onSubmit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    const documentHeight = Math.max(
      document.body.scrollHeight || 0,
      document.body.offsetHeight || 0,
      document.documentElement.scrollHeight || 0,
      document.documentElement.offsetHeight || 0
    );
    const scrollbottom = (window.innerHeight + window.scrollY) * 1.1 >= documentHeight;

    if (this.loadmore && !this.loading && scrollbottom) {
      this.onSubmit(true);
    }
  }

  onClear() {
    this.formInit();
    this.onSubmit();
  }

  onSubmit(scrollload?: boolean) {
    this.loading = true;
    this.page++;

    if (!scrollload) {
      this.page = 1;
      this.params = this.getSearchParams();
      this.loadmore = true;
      this.memberLogs = [];
    }

    this.memberLogEntityService.getWithQuery(`?page=${this.page}&perPage=${this.pageSize}&${this.params}`)
    .subscribe((res: MemberLog[]) => {
      this.loading = false;
      if (res) {
        this.memberLogs = [...this.memberLogs, ...res];
        this.cdr.detectChanges();

        if (res.length >= this.pageSize) {
          return;
        }
      }

      this.loadmore = false;
    });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  onDateOpened() {
    this.maxDate = moment();

    const service = this.transactionHttpService;

    const todayservice = service.getToday();
    this.ranges.Today = this.patchDatetime(todayservice.from, todayservice.to);

    const last24hourservice = service.getLast24Hours();
    this.ranges['Last 24 Hours'] = this.patchDatetime(last24hourservice.from, last24hourservice.to);

    const yesterdayservice = service.getYesterday();
    this.ranges.Yesterday = this.patchDatetime(yesterdayservice.from, yesterdayservice.to);

    const thisweekservice = service.getThisWeek();
    this.ranges['This Week'] = this.patchDatetime(thisweekservice.from, thisweekservice.to);

    const thismonthservice = service.getThisMonth();
    this.ranges['This Month'] = this.patchDatetime(thismonthservice.from, thismonthservice.to);
  }

  onDateChange(event: any = null) {
    const patchdatetime = this.patchDatetime(event?.startDate, event?.endDate);

    this.maxDate = moment();

    this.form.patchValue({
      start_date: patchdatetime.startdate,
      end_date: patchdatetime.enddate,
      defaultDate: {
        startDate: patchdatetime.startdate,
        endDate: patchdatetime.enddate,
      }
    });
  }

  onOpenDialog(memberId?: number) {
    const service = this.transactionHttpService;
    const params = {
      start_date: moment(service.getYesterday().from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(service.getLast24Hours().to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      additional_info: '1',
    };

    this.subscription = this.memberDataService.getById(memberId, `?${new URLSearchParams(params).toString()}`).pipe(
      tap((res) => {
        if (res) {
          this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' });
        }
      })
    ).subscribe();
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any}) {
    this.dialog.open(componentRef, {
      data,
      width: data.mode === 'member-information' ? '1500px' : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      autoFocus: data.mode === 'member-information' ? false : true,
    });
  }

  private formInit() {
    const patchdatetime = this.patchDatetime();

    this.maxDate = moment();

    this.form = new FormGroup({
      username: new FormControl(null),
      name: new FormControl(null),
      ip_address: new FormControl(null),
      request_url: new FormControl(null),
      referer: new FormControl(null),
      start_date: new FormControl(patchdatetime.startdate),
      end_date: new FormControl(patchdatetime.enddate),
      defaultDate: new FormControl({
        startDate: patchdatetime.startdate,
        endDate: patchdatetime.enddate,
      }), // Do not remove: For Clearing The Range
    });
  }

  private getSearchParams(): string {
    const formValues = this.form.value;
    const patchdatetime = this.patchDatetime(formValues.start_date, formValues.end_date);

    const newFormValues = {
      ...formValues,
      start_date: patchdatetime.startdate.tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: patchdatetime.enddate.tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
    };

    const filteredFormValues = this.filterFormFields(newFormValues);
    const searchParams = new URLSearchParams(filteredFormValues);

    return searchParams.toString();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] && formData[key] !== 'all' && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private patchDatetime(startDate: any = null, endDate:any = null) {
    if (endDate) endDate = moment(endDate);
    if (startDate) startDate = moment(startDate);

    let enddate = endDate && !endDate.isAfter(moment()) ? endDate : moment().subtract(1, 'minute');
    let startdate = startDate && !startDate.isAfter(moment()) ? startDate : enddate.clone().subtract(1, 'hour');

    enddate = enddate.clone();
    startdate = startdate.clone();

    return {startdate, enddate};
  }
}
