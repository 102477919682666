<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="align-items-center" [formGroup]="form">
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Agent:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-member-dropdown class="dropdown-maxheight" [form]="form"
                                        [dropdownSettings]='dropdownSettings' [formName]="'agent_username'"
                                        [selectionAttributes]="'username'" [isAgent]="true" [selectedItems]="selectedItems"></kt-member-dropdown>
                                </div>
                                <!-- Currency -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="currency_id">
                                        <option *ngIf="!isAgent" [value]="null" [disabled]="true">Please Select</option>
                                        <ng-container *ngFor="let value of dropdown.currencies">
                                            <option [value]="value.id">{{ value.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <!-- Date/Time -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <input type="text" class="form-control" placeholder="Search"
                                        (change)="onDateRange($event)" formControlName="defaultDate"
                                        [timePicker]="true" [timePickerSeconds]="true"
                                        [alwaysShowCalendars]="true" [ranges]="ranges"
                                        [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                        [timePicker24Hour]="true" [showDropdowns]="true" [opens]="'left'" (click)="updateDateRange()"
                                        ngxDaterangepickerMd />
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Timezone' }}:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        #timezone
                                        (click)="onChangeTimezone(timezone.selectedItems)"
                                        [form] = 'form'
                                        [dropdownList] = 'dropdown.timezones'
                                        [dropdownSettings] = 'timezoneDropdownSettings'
                                        [formName] = "'timezone'"
                                        [selectionAttributes] = "'timezone'"
                                        [selectedItems] = 'timezoneSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Action Button -->
            <div class="kt-section col-12 row mb-3">
                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
            </div>

            <kt-member-report-list [isAgent]="true" [data]="agentReport$" [loading]="loading" [onSearch]="onSearch" [summary]="summary$" [form]="form" [pageSize]="pageSize" [page]="page" [maxSize]="maxSize" [pagination]="pagination" [params]="params" [listType]="'all-member-report'"></kt-member-report-list>

        </div>
    </div>
</div>
