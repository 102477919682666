<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Remark for Justification</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <!-- Remarks -->
          <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">Remark <span class="text-danger">*</span> </label>
            <input type="text" formControlName="remarks" class="col-8 form-control">
          </div>
          <div class="col-12 form-group row pr-0" formArrayName="evidence">
            <label class="col-4 col-form-label">Evidence <span class="text-danger">*</span> </label>

            <div class="col-8 input-wrapper pl-0 pr-0" >
              <div class="d-flex mt-2" *ngFor="let evidence of evidence.controls; let i = index">
                <label class="btn btn-default btn-sm image-button">
                  <span>Browse</span>
                  <input class="form-control" type="file" hidden accept="image/*,video/*,.pdf" (change)="onUploadFile($event,i)">
                </label>
                <span class="form-control filename">{{ fileName[i] ? fileName[i] : 'Choose Files' }}</span>
                <button class="btn btn-remove" *ngIf="!isFileUploading[i]" (click)="removeEvidence(i)" > <span>x</span></button>
                <div class="spinner-wrapper d-flex align-items-center" *ngIf="isFileUploading[i]">
                  <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                </div>
              </div>
              <button class="btn btn-warning mt-4" (click)="addEvidence()"><i class="fa fa-plus" aria-hidden="true"></i> Add </button>
            </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i
            class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading"
          (confirmed)="onSubmit()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
