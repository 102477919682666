// Service
import { ReferralCommissionDataService } from "../services/referral-commission-data.service";
import { AuthHttpService } from '@core/services/auth-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { MemberDataService } from "../../../general/members/services/member-data.service";
// Angular / Core
import { FormBuilder,  FormGroup, FormControl } from "@angular/forms";
import { Component, OnInit, Inject } from "@angular/core";
import { LoadingBarService } from "@ngx-loading-bar/core";
// Third Party
import * as moment from 'moment-timezone';
import { Pagination } from '@core/models/pagination.model';
import { Observable, of, Subscription } from 'rxjs';
import { tap, map, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import Swal from "sweetalert2";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
// models
import { ReferralCommission } from '@core/models/referral-commission.model';
// dialog
import { MemberInformationDialogComponent } from "../../../general/members/dialogs/member-information/member-information.component";

@Component({
  selector: "kt-referral-commission-details",
  templateUrl: "./referral-commission-details.component.html",
  styleUrls: ["./referral-commission-details.component.scss"],
})

export class ReferralCommissionDetailsComponent implements OnInit {
  form: FormGroup;
  referralCommissionDetails$: Observable<ReferralCommission[]>;
  messages$: any;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  referralCommission = [];
  dataLength = 0;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dropdown = {
    groups: this.dropdownHttpService.groups,
    perPage: this.dropdownHttpService.perPage,
    currencies: this.dropdownHttpService.currencies,
    tiers: this.dropdownHttpService.referralCommTiers,
    statuses: [
      {
        name: 'Pending', value: 0, checked: true
      },
      {
        name: 'Approved', value: 1, checked: true
      },
      {
        name: 'Rejected', value: 2, checked: true
      },
      {
        name: 'Non-Released', value: 3, checked: true
      }
    ]
  };

  searchStatus = [0, 1, 2, 3];

  sortingConfig = {
    'id': 'desc',
    'downline_username': 'desc',
    'downline_member_group_name': 'desc',
    'from_currency_code': 'desc',
    'exchange_rate': 'desc',
    'deposit_amount': 'desc',
    'tier': 'desc',
    'commission_rate': 'desc',
    'commission_amount': 'desc',
    'final_amount': 'desc',
    'status': 'desc',
    'remarks': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
  };

  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '17.1.1';

  // permissions
  canViewMemberDialog: boolean;
  canViewReferralCommission: boolean;
  canGenerateReferralCommission: boolean;
  canReleaseReferralCommission: boolean;
  canRejectReferralCommission: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  
  checkboxForm: FormGroup;
  toggleAll: boolean = false;

  selectedReferralCommission = [];
  availableReferralCommission = [];

  selectedTotalReferralCommission = 0;
  selectedTotalReferralCommissionConvert = 0;
  currentPageTotalReferralCommission = 0;
  currentPageTotalReferralCommissionConvert = 0;
  totalReferralCommission = 0;
  totalReferralCommissionConvert = 0;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      currency: string,
      username: string,
      referral_commission_id: number,
      member_account_id: number,
      referral_commission: any,
      tier: number
    },
    private loadingBar: LoadingBarService,
    private referralCommissionDataService: ReferralCommissionDataService,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
    private authHttpService: AuthHttpService,
    private fb: FormBuilder,
    private memberDataService: MemberDataService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ReferralCommissionDetailsComponent>,
  ) { }

  ngOnInit() {
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.pagination = this.referralCommissionDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.formInit();
    this.onSubmit(true, true);

    const apSub = this.appPermissionService.getAppPermissions().subscribe((appPermissions) => {
      this.canViewMemberDialog = appPermissions.referral_commission_details_view_member_dialog;
      this.canViewReferralCommission = appPermissions.view_referral_commission_releases_list;
      this.canGenerateReferralCommission = appPermissions.generate_referral_commission;
      this.canReleaseReferralCommission = appPermissions.release_referral_commission;
      this.canRejectReferralCommission = appPermissions.reject_referral_commission;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onSubmit(clearSearch?: boolean, firstVisit?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        this.params = paramsRef.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        this.params += `&referral_commission_id=${this.data.referral_commission_id}`;

        this.referralCommissionDetails$ = this.referralCommissionDataService.getDetails(`?${this.params}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.availableReferralCommission = res;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.referralCommissionDataService.pagination;
            this.currentPageTotalReferralCommission = this.referralCommissionDataService.currentPageTotal;
            this.totalReferralCommission = this.referralCommissionDataService.totalAmount;
            this.currentPageTotalReferralCommissionConvert = this.referralCommissionDataService.currentPageTotalUSD;
            this.totalReferralCommissionConvert = this.referralCommissionDataService.totalAmountUSD;
            this.toggleAll = false;
            this.selectedReferralCommission = [];
            this.checkboxFormInit();
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();

  }

  onClear() {
    this.clearBtnLoading = true;
    this.dropdown.statuses.forEach((child) => {
      if (child.name !== 'Pending') {
        child.checked = false;
      } else {
        child.checked = true;
      }
    });
    this.searchStatus = [0, 1, 2, 3];
    this.form.patchValue({
      id: null,
      username: null,
      member_group_id: 'all',
      settings_currency_id: 'all',
      tier: this.data.tier,
      commission_amount: null,
      defaultDate: null,
      start_datetime: null,
      end_datetime: null,
      date_type: 'created_date',
      status: this.searchStatus
    });
    this.onSubmit(true, true);
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null),
      member_group_id: new FormControl('all'),
      settings_currency_id: new FormControl('all'),
      tier: new FormControl(this.data.tier),
      commission_amount: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      date_type: new FormControl('created_date'),
      status: new FormControl(this.searchStatus)
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClearDate() {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.searchBtnLoading =  true;
    this.clearBtnLoading = true;
    this.loadingBar.start();

    return this.referralCommissionDetails$ = this.referralCommissionDataService.getDetails(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.pagination = this.referralCommissionDataService.pagination;
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.dataLength = res.length;
        this.currentPageTotalReferralCommission = this.referralCommissionDataService.currentPageTotal;
        this.totalReferralCommission = this.referralCommissionDataService.totalAmount;
        this.currentPageTotalReferralCommissionConvert = this.referralCommissionDataService.currentPageTotalUSD;
        this.totalReferralCommissionConvert = this.referralCommissionDataService.totalAmountUSD;
        this.toggleAll = false;
        this.selectedReferralCommission = [];
        this.availableReferralCommission = res;
        this.checkboxFormInit();
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }
  
  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onSearchStatus(event: any, id: number, ind: number) {
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }
    this.form.patchValue({ status: this.searchStatus });
  }

  private generateStatusParams() {
    return {
      newStatusParams: Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&'),
      oldStatusParams: 'status=' + Object.keys(this.searchStatus).map(key => this.searchStatus[key]).join(','),
    };
  }

  private checkboxFormInit() {
    let prepareForm = {};
    this.availableReferralCommission.forEach(item => {
      prepareForm = { ...prepareForm, [item.id]: false }
    });
    this.checkboxForm = this.fb.group(prepareForm);

    this.checkboxForm.valueChanges.subscribe(val => {
      this.selectedReferralCommission = [];
      this.selectedTotalReferralCommission = 0;
      this.selectedTotalReferralCommissionConvert = 0;
      Object.keys(val).forEach(key => {
        if (val[key]) {
          let selectedCommission = this.availableReferralCommission.find(x => x.id == key);
          let selectedCommissionAmountInUSD = selectedCommission.final_amount_in_usd_amount;
          let selectedReleaseAmount = selectedCommission.final_amount;

          this.selectedTotalReferralCommission += +selectedReleaseAmount;
          this.selectedTotalReferralCommissionConvert += +selectedCommissionAmountInUSD;
          this.selectedReferralCommission.push(key);
        }
      })
    })
  }

  checkboxToggleAll() {
    let patchValue = {};
    if (this.toggleAll) {
      this.availableReferralCommission.forEach(item => {
          patchValue = { ...patchValue, [item.id]: true };
      });
    } else {
      this.availableReferralCommission.forEach(item => {
        patchValue = { ...patchValue, [item.id]: false };
      });
    }
    this.checkboxForm.patchValue(patchValue);
  }

  onOpenDialog(type: string, id?: number, row?: any) {
    if (type === 'member-information') {
      this.subscription = this.memberDataService.getById(id, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&additional_info=1`).pipe(
        tap((res) => {
          if (res) {
            this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' }, '1500px');
          }
        })
      ).subscribe();
    }
  }

  private openDialogBy(componentRef: any, data?: any, options?: any) {
    const dialogRef = this.dialog.open(componentRef, {
      width: options.width,
      panelClass: options.class ? options.class : '',
      data
    });

    if (data?.mode !== 'member-information') {
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.onViewPageBy(this.page);
        }
      });
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

}