import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class BankHttpService {

  constructor(private http: HttpClient) { }

  getBanks(params?: string): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/bank${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  getFilteredBanks(params?: string): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/bankaccount${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  getTransactionTypes() {
    return this.http.get<ApiResponse>(`/banktransactionstype`).pipe(
      map(res => res.data.rows)
    );
  }

  getCreateTransactionTypes() {
    return this.http.get<ApiResponse>(`/createbanktransactionstype`).pipe(
      map(res => res.data.rows)
    );
  }

}
