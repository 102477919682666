<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ data.game_provider_code }} Bet Logs: <span class="p-l-0">{{ data.username }}</span> | <span>{{ data.game_account }}</span> | <span>{{ unconvertedFrom }} {{ '(GMT ' + tz + ')' }} - {{ unconvertedTo }} {{ '(GMT ' + tz + ')' }}</span>
      </h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <!-- Summary (Bet Log) -->
      <div class="row">
        <section class="col-lg-12 card border-0">
          <div class="col-12 p-0 card rounded-0">
            <div class="card-header d-flex justify-content-between align-items-center">
              Summaries
              <button matTooltip="{{ 'Last Bet Log Sync At ' + lastBetLogSyncAt }}"  class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"><i class="fas fa-history"></i></button>
            </div>
            <section class="col-12 p-4">
              <div class="row">
                <table class="table table-bordered table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Game Name</th>
                      <th class="text-center">Game Category</th>
                      <th class="text-center">Game Sub Category</th>
                      <th class="text-center">Total Bet Count</th>
                      <th class="text-center">Total Valid Bet Amount</th>
                      <th class="text-center">Player W/L</th>
                      <th class="text-center">Jackpot Contribution</th>
                      <th class="text-center">Jackpot Win</th>
                      <th class="text-center">Nett Jackpot</th>
                      <th class="text-center">Game Bet Amount</th>
                      <th class="text-center">Gross Game Revenue (GGR)</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="(betlogSummary$ | async) as rows">
                    <tr *ngFor="let item of rows[data.currency_code] | keyvalue">
                      <ng-container *ngIf="item.key !== 'summary'">
                        <td>{{ item.value.game_name }}</td>
                        <td class="text-center">{{ item.value.game_category_code }}</td>
                        <td class="text-center">{{ item.value.game_sub_category_name }}</td>
                        <td class="text-right">{{ item.value.total_bet_count | number : '1.0-0' }}</td>
                        <td class="text-right">{{ item.value.total_valid_bet_amount | number : '1.2-2'}}</td>
                        <td class="text-right">{{ item.value.win_lose | number : '1.2-2'}}</td>
                        <td class="text-right">{{ item.value.jackpot_contribution | number : '1.2-2'}}</td>
                        <td class="text-right">{{ item.value.jackpot_win | number : '1.2-2'}}</td>
                        <td class="text-right">{{ item.value.nett_jackpot | number : '1.2-2'}}</td>
                        <td class="text-right">{{ item.value.game_bet_amount | number : '1.2-2'}}</td>
                        <td class="text-right">{{ item.value.gross_game_revenue | number : '1.2-2'}}</td>
                      </ng-container>
                    </tr>
                    <kt-fallback-row [collection]="rows"></kt-fallback-row>
                  </tbody>
                  <tbody *ngIf="betlogSummaryConfig.loading" class="pre-search-table">
                    <tr>
                      <td colspan="30">
                        <div class="spinner-wrapper">
                          <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="kt-pagination kt-pagination--brand mt-2 col-12" *ngIf="betlogSummaryConfig.pagination !== undefined && betlogSummaryConfig.pagination !== null">
                  <ngb-pagination [pageSize]="pageSize" [(page)]="betlogSummaryConfig.page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="betlogSummaryConfig.pagination.total" (pageChange)="onGetBetlogSummary(betlogSummaryConfig.page)">
                  </ngb-pagination>
                  <div class="kt-pagination__toolbar">
                    <span class="pagination__desc">
                      Showing {{ betlogSummaryConfig.pagination.from }} to {{betlogSummaryConfig.pagination.to}} of
                      {{ betlogSummaryConfig.pagination.total | number : '1.0' }} records
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      <!-- Detailed Bet History -->
      <div class="row mt-5">
        <form class="col-lg-12 card border-0" [formGroup]="form" *ngIf="data.game_type === 2 || data.game_provider_code.includes('MEGA')">
          <div class="col-12 p-0 card rounded-0">
            <div class="card-header">
              {{ 'Game Log' }}
            </div>
            <section class="col-12 pl-4 pt-4 pb-4 pr-0">
              <div class="row table-responsive">
                <div class="row">
                  <div class="col-md-4 form-group required">
                    <label class="control-label">{{ 'Date' }} <span class="text-danger">*</span></label>
                    <div class="input-group date">
                      <!-- // calendar only -->
                      <input class="form-control" formControlName="date" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1">
                      <span class="input-group-append" [owlDateTimeTrigger]="dt1">
                        <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                      </span>
                      <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                      <small id="passwordHelpBlock" class="form-text text-muted w-100">The date and time will be based on UTC+8 timezone.</small>
                      <small *ngIf="data.game_provider_code == '918KISS' || data.game_provider_code == '918BOT'" id="passwordHelpBlock" class="form-text text-muted w-100"><span class="text-danger">*</span>Known issue where the DateTime provided by 918KISS is delayed by 15 minute from the actual transaction date and time.</small>
                    </div>
                  </div>
                  <div class="col-md-2 form-group">
                    <label class="control-label">{{ 'Start Time' }} <span class="text-danger"></span></label>
                    <div class="input-group date">
                      <!-- // calendar only -->
                      <input class="form-control" formControlName="start_time" [owlDateTimeTrigger]="st1" [owlDateTime]="st1" placeholder="00:00">
                      <span class="input-group-append" [owlDateTimeTrigger]="st1">
                        <span class="input-group-text pointer-calendar"><i class="far fa-clock"></i></span>
                      </span>
                      <owl-date-time [pickerType]="'timer'" #st1></owl-date-time>
                    </div>
                  </div>
                  <div class="col-md-2 form-group ">
                    <label class="control-label">{{ 'End Time' }} <span class="text-danger"></span></label>
                    <div class="input-group date">
                      <!-- // calendar only -->
                      <input class="form-control" formControlName="end_time" [owlDateTimeTrigger]="et1" [owlDateTime]="et1" placeholder="23:59">
                      <span class="input-group-append" [owlDateTimeTrigger]="et1">
                        <span class="input-group-text pointer-calendar"><i class="far fa-clock"></i></span>
                      </span>
                      <owl-date-time [pickerType]="'timer'" #et1></owl-date-time>
                    </div>
                  </div>
                  <ng-container *ngIf="data.game_provider_code == 'MEGA' || data.game_provider_code == 'MEGAB'">
                    <div class="col-md-2 form-group required">
                      <label class="control-label">{{ 'Type' }}: <span class="text-danger"></span></label>
                      <select class="form-control" formControlName="type">
                        <option [value]="null" [disabled]="true">{{ 'Please Select' }}</option>
                        <option [value]="value" *ngFor="let value of dropdown.memberGameLogType"> {{ value }}
                        </option>
                      </select>
                    </div>
                  </ng-container>
                  <div class="col-md-2 form-group required">
                    <label class="control-label">{{ 'Page' }}: <span class="text-danger">*</span></label>
                    <div class="input-group">
                      <input class="form-control" formControlName="page" >
                      <owl-date-time [pickerType]="'timer'" #et1></owl-date-time>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="gameLogConfig.loading">
                      <div class="spinner-wrapper">
                        <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                      </div>
                </ng-container>
                <div class="table-responsive" id="contentTable" [innerHtml]="gameLogConfig.tableContent" AppMotationObserver (innerHtmlRendered)="htmlRender()" >
                </div>
                <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
                  <div class="dropdown dropdown-inline">
                    <button type="button" class="btn btn-secondary mr-2" (click)="onChangePage('previous')" ><i class="fas fa-angle-double-left"></i>{{ 'Previous' }}</button>
                    <button type="button" class="btn btn-secondary mr-2" (click)="onChangePage('next')" >{{ 'Next' }} <i class="fas fa-angle-double-right"></i></button>
                  </div>
                  <div class="dropdown dropdown-inline">
                    <kt-submit-button [buttonLoading]="gameLogConfig.buttonLoading" (click)="onSubmit()"></kt-submit-button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </form>        
        <section class="col-lg-12 card border-0" *ngIf="data.game_type === 1 && data.game_provider_code != 'MEGA'">
          <form [formGroup]="formDetailBet">
            <div class="col-12 p-0 card rounded-0">
              <div class="card-header d-flex justify-content-between align-items-center">
                Detailed Bet Histories
                <button matTooltip="{{ 'Last Bet Log Sync At ' + lastBetLogSyncAt }}"  class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"><i class="fas fa-history"></i></button>
              </div>
              <section class="col-12 pl-4 pt-4 pb-4 pr-0">
                <div class="row table-responsive">
                  <div class="row mb-2">
                    <div class="col-md-1 kt-form__label col-form-label">
                      <label>Ticket ID:</label>
                    </div>
                    <div class="col-md-2 kt-form__control">
                        <input type="text" formControlName="ticket_id" placeholder="Search" class="form-control">
                    </div>                  
                    <div class="col-md-1 kt-form__label col-form-label">
                      <label>Settlement Time:</label>
                    </div>
                    <div class="col-md-5 kt-form__control">
                        <div class="input-group date">
                            <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)"
                                formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true"
                                [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true"
                                [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()"
                                ngxDaterangepickerMd />
                            <span class="input-group-append">
                                <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                            </span>
                        </div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-1 kt-form__label col-form-label">
                      <label>Timezone:</label>
                    </div>
                    <div class="col-md-5 kt-form__control">
                        <kt-dropdown-wo-lazyload
                            #timezone
                            (click)="onChangeTimezone(timezone.selectedItems)"
                            [form] = 'formDetailBet'
                            [dropdownList] = 'dropdown.timezones'
                            [dropdownSettings] = 'timezoneDropdownSettings'
                            [formName] = "'timezone'"
                            [selectionAttributes] = "'timezone'"
                            [selectedItems] = 'timezoneSelectedItems'>
                        </kt-dropdown-wo-lazyload>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-6 kt-form__control">
                      <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onGetDetailedBetHistory()"></kt-search-button>
                      <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClearDetailBetHistory()"></kt-clear-button>
                    </div>
                  </div>
                  <table class="table table-bordered table-hover table-striped">
                    <thead>
                      <tr>
                        <ng-container *ngIf="dynamicHeader.length > 0">
                          <ng-container *ngFor="let header of dynamicHeader">
                            <th class="table-col-max-w" *ngIf="header !== 'parlay data'">{{ header | titlecase | translate}}</th>
                          </ng-container>
                        </ng-container>
                      </tr>
                    </thead>
                    <tbody *ngIf="(transactionReport$ | async) as rows">
                      <ng-container *ngIf="dynamicHeader.length > 0">
                          <tr *ngFor="let row of rows; let i = index">
                            <ng-container *ngIf="dynamicHeader.length > 0">
                                <ng-container *ngFor="let param of dynamicParameter" >
                                  <td class="table-col-max-w" *ngIf="param !== 'parlay_data'">
                                    <ng-container *ngIf="row.game_log !== null; else elseTemplate">
                                      <ng-container *ngIf="param === 'result_url'; else elseData">
                                        <button class="btn btn-brand btn-sm" (click)="onGetGameResult(row[param])">Details</button>
                                      </ng-container>
                                      <ng-template #elseData>
                                        <ng-container *ngIf="(data.game_provider_code == 'MAX' || data.game_provider_code == 'WBET') && param === 'ticket_id' && row['parlay_data'] !== null; else sexyProvider">
                                          <a (click)="onOpenParlayInfo(data.username, row['ticket_id'], row['parlay_data'])">{{ row['ticket_id'] }}</a>
                                        </ng-container>
                                        <ng-template #sexyProvider>
                                          <ng-container *ngIf="data.game_provider_code == 'SEXY' && param === 'result_details'; else otherProvider">
                                            <span class="result-data" [innerHTML]="onFormatResult(row['result_details'])"></span>
                                          </ng-container>
                                          <ng-template #otherProvider>
                                            <span [innerHTML]="onFormatLog(row[param])"></span>
                                          </ng-template>
                                        </ng-template>
                                      </ng-template>
                                    <ng-template #elseTemplate>
                                      <span></span>
                                    </ng-template>
                                    </ng-container>
                                  </td>
                                </ng-container>
                            </ng-container>
                          </tr>
                      </ng-container>
                      <kt-fallback-row [collection]="rows"></kt-fallback-row>
                    </tbody>
                    <tbody *ngIf="transactionReportConfig.loading" class="pre-search-table">
                      <tr>
                        <td colspan="30">
                          <div class="spinner-wrapper">
                            <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="kt-pagination kt-pagination--brand mt-2 col-12" *ngIf="transactionReportConfig.pagination !== undefined && transactionReportConfig.pagination !== null">
                    <ngb-pagination [pageSize]="pageSize" [(page)]="transactionReportConfig.page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="transactionReportConfig.pagination.total" (pageChange)="onGetDetailedBetHistory(transactionReportConfig.page)">
                    </ngb-pagination>
                    <div class="kt-pagination__toolbar">
                      <span class="pagination__desc">
                        Showing {{transactionReportConfig.pagination.from}} to {{transactionReportConfig.pagination.to}} of {{ transactionReportConfig.pagination.total | number : '1.0' }} records
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </form>
        </section>
      </div>

      <!-- Past Transaction (Transfer Only) -->
      <div class="row mt-5" *ngIf="showPastTransactions">
        <section class="col-lg-12 card border-0">
          <div class="col-12 p-0 card rounded-0">
            <div class="card-header">
              Past Transactions
            </div>
            <section class="col-12 p-4">
              <div class="row">
                <table class="table table-bordered table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Refer ID</th>
                      <th>Transaction Type</th>
                      <th class="text-center">Amount</th>
                      <th class="text-center">Bonus</th>
                      <th class="text-center">Status</th>
                      <th>Remarks</th>
                      <th class="date-column">Created At</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="(transfer$ | async) as rows">
                    <tr *ngFor="let row of rows">
                      <td>{{row.id}}</td>
                      <td>Transfer</td>
                      <td class="text-right">
                        <span [ngClass]="row.amount >= 0 ? 'text-success' : 'text-danger'">{{  row.amount | number : '1.2-2' }}</span>
                      </td>
                      <td class="text-right">
                        {{ row.bonus | number : '1.2-2' }}
                      </td>
                      <td class="text-center">
                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status">
                          {{ status[row.status] }}</span>
                      </td>
                      <td>{{row.details}}</td>
                      <td>{{ timezoneDateWithTimezone(row.created_at, data.timezone, 'YYYY-MM-DD HH:mm') }}</td>
                    </tr>
                    <kt-fallback-row [collection]="rows"></kt-fallback-row>
                  </tbody>
                  <tbody *ngIf="transferConfig.loading" class="pre-search-table">
                    <tr>
                      <td colspan="30">
                        <div class="spinner-wrapper">
                          <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="kt-pagination kt-pagination--brand mt-2 col-12" *ngIf="transferConfig.pagination !== undefined && transferConfig.pagination !== null">
                  <ngb-pagination [pageSize]="pageSize" [(page)]="transferConfig.page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="transferConfig.pagination.total" (pageChange)="onGetTransactions(transferConfig.page)">
                  </ngb-pagination>
                  <div class="kt-pagination__toolbar">
                    <span class="pagination__desc">
                      Showing {{transferConfig.pagination.from}} to {{transferConfig.pagination.to}} of
                      {{ transferConfig.pagination.total | number : '1.0' }} records
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      <!-- Rewards -->
      <div class="row mt-5" *ngIf="(rewardsReport$ | async) as rows">
        <section class="col-lg-12 card border-0">
          <div class="col-12 p-0 card rounded-0">
            <div class="card-header">
              Rewards
            </div>
            <section class="col-12 pl-4 pt-4 pb-4 pr-0">
              <div class="row table-responsive">
                <table class="table table-bordered table-hover table-striped">
                  <thead>
                    <tr>
                      <ng-container *ngIf="dynamicHeaderReward.length > 0">
                        <th class="table-col-max-w" *ngFor="let header of dynamicHeaderReward">{{ header | titlecase }}</th>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="dynamicHeaderReward.length > 0">
                        <tr *ngFor="let row of rows">
                          <ng-container *ngIf="dynamicHeaderReward.length > 0">
                            <td class="table-col-max-w" *ngFor="let param of dynamicParameterReward">
                              <ng-container *ngIf="row.game_log !== null; else elseTemplate">
                                <span [innerHTML]="onFormatLog(row[param])"></span>
                              </ng-container>
                              <ng-template #elseTemplate>
                                <span></span>
                              </ng-template>
                            </td>
                          </ng-container>
                        </tr>
                    </ng-container>
                    <kt-fallback-row [collection]="rows"></kt-fallback-row>
                  </tbody>
                  <tbody *ngIf="rewardsReportConfig.loading" class="pre-search-table">
                    <tr>
                      <td colspan="30">
                        <div class="spinner-wrapper">
                          <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="kt-pagination kt-pagination--brand mt-2 col-12" *ngIf="rewardsReportConfig.pagination !== undefined && rewardsReportConfig.pagination !== null">
                  <ngb-pagination [pageSize]="pageSize" [(page)]="rewardsReportConfig.page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="rewardsReportConfig.pagination.total" (pageChange)="onGetRewards(rewardsReportConfig.page)">
                  </ngb-pagination>
                  <div class="kt-pagination__toolbar">
                    <span class="pagination__desc">
                      Showing {{rewardsReportConfig.pagination.from}} to {{rewardsReportConfig.pagination.to}} of {{ rewardsReportConfig.pagination.total | number : '1.0' }} records
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      <!-- Tips -->
      <div class="row mt-5" *ngIf="(tipsReport$ | async) as rows">
        <section class="col-lg-12 card border-0">
          <div class="col-12 p-0 card rounded-0">
            <div class="card-header">
              Tips
            </div>
            <section class="col-12 pl-4 pt-4 pb-4 pr-0">
              <div class="row table-responsive">
                <table class="table table-bordered table-hover table-striped">
                  <thead>
                    <tr>
                      <ng-container *ngIf="dynamicHeaderTips.length > 0">
                        <th class="table-col-max-w" *ngFor="let header of dynamicHeaderTips">{{ header | titlecase }}</th>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="dynamicHeaderTips.length > 0">
                        <tr *ngFor="let row of rows">
                          <ng-container *ngIf="dynamicHeaderTips.length > 0">
                            <td class="table-col-max-w" *ngFor="let param of dynamicParameterTips">
                              <ng-container *ngIf="row.game_log !== null; else elseTemplate">
                                <span [innerHTML]="onFormatLog(row[param])"></span>
                              </ng-container>
                              <ng-template #elseTemplate>
                                <span></span>
                              </ng-template>
                            </td>
                          </ng-container>
                        </tr>
                    </ng-container>
                    <kt-fallback-row [collection]="rows"></kt-fallback-row>
                  </tbody>
                  <tbody *ngIf="tipsReportConfig.loading" class="pre-search-table">
                    <tr>
                      <td colspan="30">
                        <div class="spinner-wrapper">
                          <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="kt-pagination kt-pagination--brand mt-2 col-12" *ngIf="tipsReportConfig.pagination !== undefined && tipsReportConfig.pagination !== null">
                  <ngb-pagination [pageSize]="pageSize" [(page)]="tipsReportConfig.page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="tipsReportConfig.pagination.total" (pageChange)="onGetTips(tipsReportConfig.page)">
                  </ngb-pagination>
                  <div class="kt-pagination__toolbar">
                    <span class="pagination__desc">
                      Showing {{tipsReportConfig.pagination.from}} to {{tipsReportConfig.pagination.to}} of {{ tipsReportConfig.pagination.total | number : '1.0' }} records
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      <!-- outstanding Bets -->
      <div class="row mt-5" *ngIf="(outstandingBetsReport$ | async) as rows">
        <section class="col-lg-12 card border-0">
          <div class="col-12 p-0 card rounded-0">
            <div class="card-header">
              Outstanding Bets
            </div>
            <section class="col-12 pl-4 pt-4 pb-4 pr-0">
              <div class="row table-responsive">
                <table class="table table-bordered table-hover table-striped">
                  <thead>
                    <tr>
                      <ng-container *ngIf="dynamicHeaderOutstandingBets.length > 0">
                        <th class="table-col-max-w" *ngFor="let header of dynamicHeaderOutstandingBets">{{ header | titlecase }}</th>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="dynamicHeaderOutstandingBets.length > 0">
                        <tr *ngFor="let row of rows">
                          <ng-container *ngIf="dynamicHeaderOutstandingBets.length > 0">
                            <td class="table-col-max-w" *ngFor="let param of dynamicParameterOutstandingBets">
                              <ng-container *ngIf="row.game_log !== null; else elseTemplate">
                                <span [innerHTML]="onFormatLog(row[param])"></span>
                              </ng-container>
                              <ng-template #elseTemplate>
                                <span></span>
                              </ng-template>
                            </td>
                          </ng-container>
                        </tr>
                    </ng-container>
                    <kt-fallback-row [collection]="rows"></kt-fallback-row>
                  </tbody>
                  <tbody *ngIf="outstandingBetsReportConfig.loading" class="pre-search-table">
                    <tr>
                      <td colspan="30">
                        <div class="spinner-wrapper">
                          <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="kt-pagination kt-pagination--brand mt-2 col-12" *ngIf="outstandingBetsReportConfig.pagination !== undefined && outstandingBetsReportConfig.pagination !== null">
                  <ngb-pagination [pageSize]="pageSize" [(page)]="outstandingBetsReportConfig.page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="outstandingBetsReportConfig.pagination.total" (pageChange)="onGetOutstandingBets(outstandingBetsReportConfig.page)">
                  </ngb-pagination>
                  <div class="kt-pagination__toolbar">
                    <span class="pagination__desc">
                      Showing {{outstandingBetsReportConfig.pagination.from}} to {{outstandingBetsReportConfig.pagination.to}} of {{ outstandingBetsReportConfig.pagination.total | number : '1.0' }} records
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button *ngIf="canExportMemberBetLogsReport" class="btn btn-warning btn-icon-sm" type="button" [disabled]="button_loading" (click)="onExport()">
        <i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export
      </button>
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
