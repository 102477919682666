import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { MyLeads } from '@core/models/my-leads.model';

@Injectable()
export class MyLeadsEntityService extends EntityCollectionServiceBase<MyLeads> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('MyLeads', serviceElementsFactory);
    }
}

