import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { WebDisplayDataService } from './services/web-display-data.service';
import { tap, catchError } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WebDisplayEditDialogComponent } from './dialogs/web-display-edit.component';
import Swal from "sweetalert2";
import { AppPermissionService } from '@core/services/app-permission.service';
@Component({
  selector: 'web-display',
  templateUrl: './web-display.component.html',
  styleUrls: ['./web-display.component.scss']
})

export class WebDisplayComponent implements OnInit {
  form: FormGroup;
  messages$ = this.webDisplayDataService.messages$;
  webDisplays =[];

  merchantList = this.dropdownHttpService.merchantSites;
  activeTab = 0;
  
  type = 0 ; // web type
  getmerchant: any;
  currentMerchantID : number;
  loading = false;
  searchBtnLoading = false;
  clearBtnLoading = false;
  dataLength = 0;
  params = '';
  buttonLoading = true;
  checkValidation = false;
  refreshStatus: boolean;
  initialValues:any;
  listControl:any;
  checkRowColoumnList = [];

  // permissions
  canEditWebsiteDisplayPosition: boolean;
  canViewWebsiteDisplaySettings: boolean;
  canUpdateWebsiteDisplayStatus: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private webDisplayDataService: WebDisplayDataService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit(): void {
    this.dropdownHttpService.merchantSites.subscribe(res => {
      this.getmerchant =  res;
      this.currentMerchantID = this.getmerchant[this.activeTab]['id'];
     });
     this.formInit();

     const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditWebsiteDisplayPosition = appPermissions.edit_website_display_position;
      this.canViewWebsiteDisplaySettings = appPermissions.view_website_display_settings;
      this.canUpdateWebsiteDisplayStatus = appPermissions.update_website_display_status;
     });

     this.subscriptions.add(apSub);
  }

  onSelectTab($event) {
    this.activeTab = $event.index;
    this.currentMerchantID = this.getmerchant[this.activeTab]['id'];
    this.onSubmit(this.currentMerchantID);
  }

  onSubmit($merchant_id) {
    this.formInit();
    this.loading = true;
    const data = {};
    data['type']        = this.type;
    data['merchant_id'] = $merchant_id;
    this.webDisplays =[];
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    this.webDisplayDataService.getWithQuery(`?${parameters}`).subscribe(res => {
        this.webDisplays = res;
        this.dataLength = this.webDisplays.length;
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.loadingBar.complete();
        this.buttonLoading = false;
        this.checkRowColoumn(res);
        this.addFooterSectionMerchantForm();
        this.cdr.detectChanges();
    });
  }

  onOpenDialog(rows?: any) { 
    const webDisplayData = this.webDisplayDataService.getById(rows.id);
        this.subscription = webDisplayData.pipe(
          tap((res) => {
            this.openDialogBy(WebDisplayEditDialogComponent, { rows: res });
          })
        ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { mode?: string, rows?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        rows: data.rows,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true){
        this.refreshStatus = true;
        this.reload();
      }
    });
    
  }

  reload() {
    if (this.refreshStatus === true) {
      this.onSubmit(this.currentMerchantID);
    }
    this.buttonLoading = false;
  }

  onSave(){
    this.buttonLoading = true;
   
    const data = {
      ...this.form.value
    };

    Object.keys(data.footer_section_merchant).forEach((key) => {
      if( data.footer_section_merchant[key].web_row == null && data.footer_section_merchant[key].web_row == '') data.footer_section_merchant[key].web_row = 0;
      if( data.footer_section_merchant[key].web_column == null && data.footer_section_merchant[key].web_column == '') data.footer_section_merchant[key].web_column = 0;
    });

    data['type'] = this.type;

    if (this.form.valid) {
      this.form.setErrors({ 'invalid': true });
      this.webDisplayDataService.updateDisplay(data).subscribe((res => {
          this.webDisplayDataService.messages$
          this.buttonLoading = false;
 
            // To enable "Save" button after get response
          this.form.setErrors(null);
          this.cdr.detectChanges();
          // this.refreshStatus = true;
          setTimeout(()=>{this.onSubmit(this.currentMerchantID);}, 1000);

          }),
          catchError((error) => {
            this.webDisplayDataService.messages$
            this.buttonLoading = false;
            // To enable "Save" button after get response
 
            this.form.setErrors(null);
            this.form.enable();
            this.cdr.detectChanges();
            throw error;
          })
        );
    }
    this.buttonLoading = false;
  }

  private formInit() {
    this.form = new FormGroup({
      type: new FormControl(this.type)
    });
    
  }

  private addFooterSectionMerchantForm(){
    const buildSubDetails = () => {
      let contents = {};
      this.webDisplays.map((element, index) => {
        const min = element.web == 1 ? 1 : 0;
        const contentsGroup = new FormGroup({
          footer_section_merchant_id: new FormControl(element.id),
          web: new FormControl(element.web),
          web_row: new FormControl(element.web_row, [Validators.required,Validators.max(3),Validators.min(min),Validators.pattern("^[0-9]*$")]),
          web_column: new FormControl(element.web_column, [Validators.required,Validators.max(6),Validators.min(min),Validators.pattern("^[0-9]*$")]),
        });
        contents = { ...contents, [index]: contentsGroup };
      });
      return contents;
    };
    this.form.addControl('footer_section_merchant', new FormGroup(buildSubDetails()));  
    this.initialValues = this.form.value;
    this.reset();
  } 

  reset(){
    this.form.reset(this.initialValues);
  }

  onToggleStatus(row: any , index: any){
    let message = '';
    let duplicate = false;
    
    let web_row = this.form.get('footer_section_merchant.'+index+'.web_row').value;
    let web_column = this.form.get('footer_section_merchant.'+index+'.web_column').value;

    if(web_row > 0 && web_column > 0){

      Object.keys(this.form.get('footer_section_merchant').value).forEach(key => {
        if ( key != index && this.form.get('footer_section_merchant.'+key+'.web_row').value == web_row && this.form.get('footer_section_merchant.'+key+'.web_column').value == web_column && this.form.get('footer_section_merchant.'+key+'.web').value == 1) {
          duplicate = true;
          message = `Unable to update due to duplicate found. Please check the position again`;
          const ele = document.getElementById(row.id) as HTMLInputElement;
          ele.checked = false;
        }
      });
  
    }

    if(duplicate){
      Swal.fire({
        text: message,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        reverseButtons: true,
      }).then((result) => {
  
      });
    }else{
      const data = {
        type:this.type,
        footer_section_merchant_id: row.id,
        web: row.web == 1 ? 0 : 1
      };
      this.webDisplayDataService.updateStatus(data).subscribe(() =>{
         this.messages$ = this.webDisplayDataService.messages$
         this.refreshStatus = true;
        }
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.reload();
  }

  findDuplicate(index){

    let web_row = this.form.get('footer_section_merchant.'+index+'.web_row').value;
    let web_column = this.form.get('footer_section_merchant.'+index+'.web_column').value;
    let web = this.form.get('footer_section_merchant.'+index+'.web').value;
    let duplicate = false;

    if(web == 1){
      if(web_row > 0 && web_column > 0){

        Object.keys(this.form.get('footer_section_merchant').value).forEach(key => {
          if ( key != index && this.form.get('footer_section_merchant.'+key+'.web_row').value == web_row && this.form.get('footer_section_merchant.'+key+'.web_column').value == web_column && this.form.get('footer_section_merchant.'+key+'.web').value == 1) {
            this.form.get('footer_section_merchant.'+key+'.web_row').setErrors({'duplicate': true});
            this.form.get('footer_section_merchant.'+key+'.web_column').setErrors({'duplicate': true});
            this.form.get('footer_section_merchant.'+index+'.web_row').setErrors({'duplicate': true});
            this.form.get('footer_section_merchant.'+index+'.web_column').setErrors({'duplicate': true});
            duplicate = true;
          }else if(key != index && (this.form.get('footer_section_merchant.'+key+'.web_row').value !== web_row || this.form.get('footer_section_merchant.'+key+'.web_column').value !== web_column && this.form.get('footer_section_merchant.'+key+'.web').value == 1)){
            
            if(duplicate == false){
  
              if (this.form.get('footer_section_merchant.'+index+'.web_row').hasError('duplicate')) {
                delete this.form.get('footer_section_merchant.'+index+'.web_row').errors['duplicate'];
                this.form.get('footer_section_merchant.'+index+'.web_row').updateValueAndValidity();
              }
  
              if (this.form.get('footer_section_merchant.'+index+'.web_column').hasError('duplicate')) {
                delete this.form.get('footer_section_merchant.'+index+'.web_column').errors['duplicate'];
                this.form.get('footer_section_merchant.'+index+'.web_column').updateValueAndValidity();
              }
  
            }
  
            if (this.form.get('footer_section_merchant.'+key+'.web_row').hasError('duplicate')) {
              delete this.form.get('footer_section_merchant.'+key+'.web_row').errors['duplicate'];
              this.form.get('footer_section_merchant.'+key+'.web_row').updateValueAndValidity();
            }
    
            if (this.form.get('footer_section_merchant.'+key+'.web_column').hasError('duplicate')) {
              delete this.form.get('footer_section_merchant.'+key+'.web_column').errors['duplicate'];
              this.form.get('footer_section_merchant.'+key+'.web_column').updateValueAndValidity();
            }
          }
        });
      }else{
  
        Object.keys(this.form.get('footer_section_merchant').value).forEach(key => {
         
          if (this.form.get('footer_section_merchant.'+key+'.web_row').hasError('duplicate')) {
            delete this.form.get('footer_section_merchant.'+key+'.web_row').errors['duplicate'];
            this.form.get('footer_section_merchant.'+key+'.web_row').updateValueAndValidity();
          }
  
          if (this.form.get('footer_section_merchant.'+key+'.web_column').hasError('duplicate')) {
            delete this.form.get('footer_section_merchant.'+key+'.web_column').errors['duplicate'];
            this.form.get('footer_section_merchant.'+key+'.web_column').updateValueAndValidity();
          }
        });
      
      }
    }
  }

  checkRowColoumn(res){
    this.checkRowColoumnList = [];
    var row1 = res.filter(x => x.web_row == 1);
    var row2 = res.filter(x => x.web_row == 2);
    var row3 = res.filter(x => x.web_row == 3);
    row1.find(x => x.web_column == 1 && x.web == 1) ? this.checkRowColoumnList.push(row1.find(x => x.web_column == 1 && x.web == 1)) : row1.find(x => x.web_column == 1 && x.web == 0) ? this.checkRowColoumnList.push(row1.find(x => x.web_column == 1)) : this.checkRowColoumnList.push(row1.find(x => x.web_column != 1 && x.web == 1));
    row2.find(x => x.web_column == 1 && x.web == 1) ? this.checkRowColoumnList.push(row2.find(x => x.web_column == 1 && x.web == 1)) : row2.find(x => x.web_column == 1 && x.web == 0) ? this.checkRowColoumnList.push(row2.find(x => x.web_column == 1)) : this.checkRowColoumnList.push(row2.find(x => x.web_column != 1 && x.web == 1));
    row3.find(x => x.web_column == 1 && x.web == 1) ? this.checkRowColoumnList.push(row3.find(x => x.web_column == 1 && x.web == 1)) : row3.find(x => x.web_column == 1 && x.web == 0) ? this.checkRowColoumnList.push(row3.find(x => x.web_column == 1)) : this.checkRowColoumnList.push(row3.find(x => x.web_column != 1 && x.web == 1));
  }
}
