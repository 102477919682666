import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuickLinkDataService } from '../services/quick-link-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Subscription, forkJoin } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { QuickLink } from '@core/models/quick-link.model';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-quick-link-edit',
  templateUrl: './quick-link-edit.component.html',
  styleUrls: ['./quick-link-edit.component.scss']
})
export class QuickLinkEditDialogComponent implements OnInit {

  form: FormGroup;
  messages$ = this.quickLinkDataService.messages$;
  buttonLoading = false;
  refreshStatus: boolean;
  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private localesSub = new Subscription();

  locales = this.dropdownHttpService.locales;
  languages = [];

  dropdown = {
    quicklinkStatus: this.dropdownHttpService.statuses,
    section: this.dropdownHttpService.footerSection,
    perPage: this.dropdownHttpService.perPage
  };

  sectionDropdownSettings = {};
  sectionDropdownList = [];
  sectionSelectedItems = [];
  sectionLoading = false;

  // permissions
  canCreateQuickLink: boolean;
  canEditQuickLink: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { rows: any; mode: string },
    public dialogRef: MatDialogRef<QuickLinkEditDialogComponent>,
    private quickLinkDataService: QuickLinkDataService,
    private dropdownHttpService: DropdownHttpService,
    private cdr: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.setSectionDropdown();
    this.addDisplaynameForm();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateQuickLink = appPermissions.create_quick_link;
      this.canEditQuickLink = appPermissions.edit_quick_link;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.localesSub.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  private setSectionDropdown() {
    this.sectionLoading = true;
    this.dropdown.section.subscribe(res => {
      this.sectionDropdownList = res.filter(x => x.section_type_code === 'iconQuickLink');
      if (this.data.mode == 'edit' && this.data.rows.section_id !== null) {
        this.sectionSelectedItems = res.filter(x => this.data.rows.section_id == x.id);
      }
      this.sectionLoading = false;
    })

    this.sectionDropdownSettings = {
      autoPosition: true,
      maxHeight: 150,
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
  }

  private addDisplaynameForm() {
    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.languages = res;
      const buildSubDetails = () => {
        let contents = {};
        res.map((element, index) => {
          const contentsGroup = new FormGroup({
            setting_locale_id: new FormControl(element.id),
            display_name: new FormControl(null),
            same_as_title: new FormControl(0),
          });
          contents = { ...contents, [element.id]: contentsGroup };
        });
        return contents;
      };
      this.form.addControl('display_name', new FormGroup(buildSubDetails()));

      if (this.data.mode === 'edit') {

        this.data.rows.display_name.map((res, index) => {
          const detailsGroup = {
            setting_locale_id: res.setting_locale_id,
            display_name: res.same_as_title ? this.data.rows.name : res.display_name,
            same_as_title: res.same_as_title,
          }
          this.form.patchValue({
            display_name: { [res.setting_locale_id]: detailsGroup }
          });
        });
      }

      this.form.get('name').valueChanges.subscribe(value => {
        Object.keys(this.form.get('display_name').value).forEach(key => {
          if (this.form.get('display_name.' + key + '.same_as_title').value == true) {
            const detailsGroup = {
              display_name: value
            }
            this.form.patchValue({
              display_name: { [key]: detailsGroup }
            });
          }
        });
      });

      this.buttonLoading = false;
      this.cdr.detectChanges();
    })).subscribe();
  }

  onChangeSection(selected) {
    this.sectionSelectedItems = selected;
  }

  onSave(quickLinkId: QuickLink, mode: string) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    const data = {
      id: (quickLinkId) ? quickLinkId.id : null,
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);

    Object.keys(data.display_name).forEach((key) => {
      data.display_name[key].same_as_title = data.display_name[key].same_as_title ? 1 : 0;
    });

    switch (mode) {
      case 'edit':
        this.subscription = this.quickLinkDataService.updateQuickLink(data.id, data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.quickLinkDataService.addQuickLink(data).pipe(
            tap((res: any) => {
              this.quickLinkDataService.messages$.next(res.message);
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              //this.form.enable();
              throw error;
            })
          ),
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    const merchant_id = [1];
    this.form = new FormGroup({
      name: new FormControl((this.data.mode === 'edit' ? this.data.rows.name : null), [Validators.required]),
      section_id: new FormControl((this.data.mode === 'edit' ? this.data.rows.section_id : null), [Validators.required]),
      merchant_id: new FormControl((this.data.mode === 'edit' ? this.data.rows.merchant_id : merchant_id), [Validators.required]),
      link: new FormControl((this.data.mode === 'edit' ? this.data.rows.link : null), [Validators.required, Validators.pattern(/^\/.*/)]),
      status: new FormControl((this.data.mode === 'edit' ? this.data.rows.status : 1), [Validators.required]),
    });
    if (this.data.mode === 'create') {
      this.form.get('status').disable();
    }
    this.form.markAllAsTouched();
    this.form.markAsDirty();
  }

  checkSameAsTitle(event: any, key) {
    let detailsGroup = {}
    if (event.target.checked == true) {
      detailsGroup = {
        display_name: this.form.get('name').value
      }
    } else {
      detailsGroup = {
        display_name: ""
      }
    }

    this.form.patchValue({
      display_name: { [key]: detailsGroup }
    });
  }
}
