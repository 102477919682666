<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <!-- Filter Form -->
                        <form class="row align-items-center" [formGroup]="form">
                            <!-- Filter first row -->
                            <div class="col-12 row mb-2 pr-0">
                                <!-- Username -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                  <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>

                                <!-- Game Provider -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Game Provider:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                  <select class="form-control" formControlName="game_provider_code" (change)="onGameProviders($event)">
                                    <option value="all"> All </option>
                                    <ng-container *ngFor="let value of dropdown.gameProviders | async">
                                      <option [value]="value.id + ':' + value.code">{{ value.code }} - {{ value.name }}</option>
                                    </ng-container>
                                  </select>
                                </div>

                                <!-- Category -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Category:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                  <select class="form-control" formControlName="game_category_code" (change)="onCategory($event)">
                                    <option value="all"> All </option>
                                    <ng-container *ngFor="let value of dropdown.gameCategories | async">
                                      <option [value]="value.category_code">{{ value.category_name }}</option>
                                    </ng-container>
                                  </select>
                                </div>

                                <!-- Sub Category -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Sub Category:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                  <select class="form-control" formControlName="sub_category_name">
                                    <option value="all"> All </option>
                                    <ng-container *ngFor="let value of dropdown.gameSubCategories | async">
                                      <option [value]="value">{{ value }}</option>
                                    </ng-container>
                                  </select>
                                </div>

                            </div>

                            <!-- Filter second row -->
                            <div class="col-12 row mb-2 pr-0">
                              <!-- Currency -->
                              <div class="col-md-1 kt-form__label col-form-label">
                                <label>Currency:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                <select class="form-control" formControlName="currency_id">
                                  <ng-container *ngFor="let value of dropdown.currencies">
                                    <option [value]="value.id">{{ value.name }}</option>
                                  </ng-container>
                                </select>
                              </div>
                              <div class="col-md-1 kt-form__label col-form-label">
                                <label>Options:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                <select class="form-control" formControlName="option">
                                  <ng-container *ngFor="let item of dropdown.options | async">
                                    <option [value]="item.value">{{ item.name }}</option>
                                  </ng-container>
                                </select>
                              </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                              <!-- Datetime -->
                              <div class="col-md-1 kt-form__label col-form-label col-form-label">
                                  <label>Date/Time:</label>
                              </div>
                              <div class="col-md-5 kt-form__control">
                                <div class="input-group date">
                                    <input type="text" class="form-control" placeholder="Search"
                                        (change)="onDateRange($event)" formControlName="defaultDate"
                                        [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true"
                                        [ranges]="ranges" [showCustomRangeLabel]="true"
                                        [locale]="dateTimePickerLocale" [timePicker24Hour]="true"
                                        [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                    <span class="input-group-append">
                                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                    </span>
                                </div>
                              </div>
                              <div class="col-md-1 kt-form__label col-form-label">
                                <label>{{ 'Timezone' }}:</label>
                              </div>
                              <div class="col-md-5 kt-form__control">
                                  <kt-dropdown-wo-lazyload
                                      #timezone
                                      (click)="onChangeTimezone(timezone.selectedItems)"
                                      [form] = 'form'
                                      [dropdownList] = 'dropdown.timezones'
                                      [dropdownSettings] = 'timezoneDropdownSettings'
                                      [formName] = "'timezone'"
                                      [selectionAttributes] = "'timezone'"
                                      [selectedItems] = 'timezoneSelectedItems'>
                                  </kt-dropdown-wo-lazyload>
                              </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                              <!-- Daily -->
                              <div class="col-md-1 kt-form__label col-form-label">
                                <label>Daily:</label>
                              </div>
                              <div class="col-md-2 kt-form__control dailycheckbox">
                                <label><input (click)="checkDaily()" type="checkbox" formControlName="daily"> </label>
                              </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Filter Action -->
            <div class="kt-section col-12 row justify-content-between mb-3 mt-2">
                <div class="dropdown dropdown-inline">
                  <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                  <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canExportGameTypeReport" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="button_loading"><i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
            </div>
            <div class="kt-section col-12 row d-flex mb-3">
              Last Bet Log Sync At <span class="font-weight-bold">{{ lastBetLogSyncAt ? lastBetLogSyncAt : '-'}}</span>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                  <th rowspan="2" *ngIf="activeOptions === 'game'">Game Name</th>
                                  <th class="text-center" rowspan="2">Currency</th>
                                  <th class="text-center" rowspan="2" [hidden]="activeOptions === 'game_category'">Game Provider</th>
                                  <th class="text-center" rowspan="2" [hidden]="activeOptions === 'game_provider'">Category</th>
                                  <th class="text-center" rowspan="2" *ngIf="activeOptions === 'game_sub_category' || activeOptions === 'game'">Subcategory</th>
                                  <th class="text-center" rowspan="2">Total Player</th>
                                  <th class="text-center" colspan="9">Bets</th>

                                </tr>
                                <tr>
                                  <th class="text-center">Total Bet Count</th>
                                  <th class="text-center">Total Bet Amount</th>
                                  <th class="text-center">Total Valid Bet Amount</th>
                                  <th class="text-center">Player W/L</th>
                                  <th class="text-center">Total Jackpot Contribution</th>
                                  <th class="text-center">Total Jackpot Win</th>
                                  <th class="text-center">Nett Jackpot</th>
                                  <th class="text-center">Game Bet Amount</th>
                                  <th class="text-center">Gross Game Revenue (GGR)</th>
                                </tr>

                            </thead>
                            <tbody *ngIf="gameTypeReport$ | async as results">
                              <ng-container *ngFor="let rows of (results | keyvalue)">
                                <ng-container *ngFor="let item of (rows.value | keyvalue)">
                                  <tr *ngIf="+item.key >= 0">
                                    <td *ngIf="activeOptions === 'game'">{{ item.value.game_name }}</td>
                                    <td class="text-center">{{ item.value.currency_code }}</td>
                                    <td class="text-center" [hidden]="activeOptions === 'game_category'"><a class="text-primary" (click)="onOpenDialog(item.value)">{{ item.value.game_provider }} </a></td>
                                    <td class="text-center" [hidden]="activeOptions === 'game_provider'">{{ item.value.game_category_code }}</td>
                                    <td *ngIf="activeOptions === 'game_sub_category' || activeOptions === 'game'">{{ item.value.game_sub_category_name }}</td>
                                    <td class="text-right">{{ item.value.total_player | number : '1.0-0' }}</td>
                                    <td class="text-right">{{ item.value.total_bet_count | number : '1.0-0' }}</td>
                                    <td class="text-right">{{ item.value.total_bet_amount | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ item.value.total_valid_bet_amount | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ item.value.win_lose | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ item.value.jackpot_contribution | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ item.value.jackpot_win | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ item.value.nett_jackpot | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ item.value.game_bet_amount | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ item.value.gross_game_revenue | number : '1.2-2' }}</td>
                                  </tr>
                                  <tr *ngIf="item.key === 'summary'" style="background-color: #dbedd2;">
                                    <td>Summary</td>
                                    <td [ngClass]="activeOptions !== 'game' ? 'text-center' : ''">{{ item.value.currency_code }}</td>
                                    <ng-container *ngIf="activeOptions === 'game_sub_category' || activeOptions === 'game'">
                                      <td>
                                        Total Rebates: {{ item.value.total_rebates | number : '1.2-2' }} <br />
                                        Total Bonus: {{ item.value.total_bonus | number : '1.2-2' }}
                                      </td>
                                      <td [attr.colspan]="activeOptions === 'game_sub_category' ? 1 : 2">
                                        Total Cancelled Bonus: {{ item.value.total_cancelled_bonus | number : '1.2-2' }} <br />
                                        Total W/L: {{ item.value.total_win_lose | number : '1.2-2' }}
                                      </td>
                                    </ng-container>
                                    <td class="text-right">{{ item.value.total_player | number : '1.0-0' }}</td>
                                    <td class="text-right">{{ item.value.total_bet_count | number : '1.0-0' }}</td>
                                    <td class="text-right">{{ item.value.total_bet_amount | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ item.value.total_valid_bet_amount | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ item.value.win_lose | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ item.value.jackpot_contribution | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ item.value.jackpot_win | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ item.value.nett_jackpot | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ item.value.game_bet_amount | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ item.value.gross_game_revenue | number : '1.2-2' }}</td>
                                  </tr>
                                </ng-container>
                              </ng-container>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                            [directionLinks]="true" [boundaryLinks]="true" [rotate]="true"
                            [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                                class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of
                                {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
