import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Pagination } from '@core/models/pagination.model';
import { DailyNewDepositReport } from '@core/models/daily-new-deposit-report.model';

@Injectable()
export class DailyNewDepositReportDataService extends DefaultDataService<DailyNewDepositReport> {

  messages$ = new Subject<any[]>();
  pagination: Pagination;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super('DailyNewDepositReport', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<DailyNewDepositReport[]> {
    return this.http.get<ApiResponse>(`/report/dailynewdepositreport`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows),
    );
  }

  getWithQuery(pageParam: string): Observable<DailyNewDepositReport[]>{
    return this.http.get<ApiResponse>(`/report/dailynewdepositreport${pageParam}`).pipe(
      tap(res => {
        this.paginationInit(res);
      }),
      map(res => res.data.rows)
    );
  }

  exportReport(pageParam: string) {
    return this.http.get<ApiResponse>(`/report/exportdailynewdepositreport${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      return this.pagination = res.data.paginations;
    }
  }

}
