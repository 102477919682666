import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription, Subject, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { tap, catchError } from 'rxjs/operators';
import { RewardsHttpService } from '@core/services/rewards-http.service';
import { AllAffiliatesDataService } from '../../../../all-affiliates/services/all-affiliates-data.service';
import { AffiliateGroupsDataService } from '../../../services/affiliate-groups-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';

@Component({
  templateUrl: './affiliate-groups-promotion-bulk-add.component.html',
  styleUrls: ['./affiliate-groups-promotion-bulk-add.component.scss']
})
export class AffiliateGroupsPromotionBulkAddComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = this.affiliateGroupsDataService.messages$;
  subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;

  promotionDropdownListAll = [];
  promotionDropdownList = [];
  promotionDropdownListSettings: any;
  promotionListSelectedItems = [];
  filteredPromotionDropdownList = [];

  affiliateDropdownList = [];
  affiliateDropdownListSettings: any;
  affiliateListSelectedItems = [];
  filteredAffiliateDropdownList = [];

  dropdown = {
    promoTypes: [],
  }

  constructor(
    public dialogRef: MatDialogRef<AffiliateGroupsPromotionBulkAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      mode: string, 
      groups: any,
    },
    private rewardsHttpService: RewardsHttpService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private affiliateGroupsDataService: AffiliateGroupsDataService,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit() {
    this.dropdownHttpService.promoTypes.subscribe(res => {
      this.dropdown.promoTypes = res.filter(x => x.id != 1);
    });
    
    this.promotionDropdownListSettings = {
      autoPosition: false,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'code',
      noDataLabel: '',
      showCheckbox: false,
    };

    this.affiliateDropdownListSettings = {
      autoPosition: false,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'username',
      noDataLabel: '',
      showCheckbox: false,
    };

    this.getDropdown();
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event, refresh = false) {
    this.dialogRef.close(refresh);
  }

  getDropdown() {
    this.promotionDropdownList = [];
    this.rewardsHttpService.getRewardsList(`?affiliate_group_id=${this.data.groups.id}&is_affiliate_setting=1&paginate=0`).subscribe(res => {
      this.promotionDropdownListAll = res;
      this.promotionDropdownListAll = this.promotionDropdownListAll.map((x) => {
        return {
          id: x.id,
          name: x.name,
          code: x.code,
          labelKey: x.name.length > 0 ? x.code + ' - ' + x.name : x.code,
          promo_type: x.promo_type,
          settings_currency_name: x.settings_currency_name.replace(/\,/g, ", "),
          status: x.status,
          affiliate_group_id: x.affiliate_group_id
        }
      });
      
      this.promotionDropdownList = this.promotionDropdownListAll.filter(x => x.affiliate_group_id == null || x.affiliate_group_id == '');
    });

    this.allAffiliatesDataService.getWithQuery(`?affiliate_group_id=${this.data.groups.id}&paginate=false`).subscribe(res => {
      this.affiliateDropdownList = res;
    });
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    this.form = new FormGroup({
      promo_type: new FormControl(null, [Validators.required]),
      promotion_id: new FormControl(null, [Validators.required]),
      visibility: new FormControl(null),
      affiliate_id: new FormControl([]),
    });
  }

  // onChangePopupDialogDefault(event: any) {
  //   if (event.target.checked) {
  //     this.form.patchValue({ 
  //       visibility: this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility,
  //       affiliate_id: this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 1 ? [] : this.data.dialogPopupVisibilitySettings.dialog_affiliate_id,
  //     });
  //     this.affiliateListSelectedItems = this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 1 ? this.affiliateDropdownList : this.affiliateDropdownList.filter(x => this.data.dialogPopupVisibilitySettings.dialog_affiliate_id.includes(x.id));
  //   }
  //   else {
  //     if (this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 1) {
  //       this.form.patchValue({ 
  //         visibility: null,
  //         affiliate_id: [],
  //       })
  //       this.affiliateListSelectedItems = [];
  //     }
  //     else {
  //       this.form.patchValue({ 
  //         affiliate_id: [],
  //       })
  //       this.affiliateListSelectedItems = [];
  //     }
  //   }
  // }

  onSelectedAffiliatePromotionListItems($event: any) {
    if ($event.length == 0) {
      this.affiliateListSelectedItems = [];
    }
    else {
      this.affiliateListSelectedItems = $event;
    }
  }

  onChangePromotionVisbility(event: any) {
    if (event.target.value) {
      this.form.patchValue({ 
        visibility: event.target.value,
      });

      if (event.target.value == 1) {
        this.form.patchValue({ 
          affiliate_id: [],
        });
        this.affiliateListSelectedItems = [];
      }
    }
  }

  onSave() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });
    const promotionListData = this.filterFormFields(this.form.value);
    promotionListData['affiliate_group_id'] = this.data.groups.id;

    this.subscription = forkJoin([
      this.affiliateGroupsDataService.createBulkPromotionVisibilityList(promotionListData),
    ]).pipe(
      tap(() => {
        this.buttonLoading = false;
        this.form.setErrors(null);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
  }

  onPromoTypeDropdown(value: any) {
    this.form.patchValue({
      promotion_id: null,
    })

    this.promotionListSelectedItems = [];
    this.filteredPromotionDropdownList = this.promotionDropdownList.filter(x => x.promo_type == value);
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && key !== 'mode') ? fields[key] = formData[key] : key);
    return fields;
  }
}
