import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class LocaleHttpService {

  constructor(private http: HttpClient) { }

  getLocales(currency_id?: number,): Observable<Dropdown[]> {
    let param = currency_id ? '?currency_id=' + currency_id : '';
    return this.http.get<ApiResponse>('/locale' + param).pipe(
      map(res => res.data.rows)
    );
  }

  getRegions(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>('/region').pipe(
      map(res => res.data.rows)
    );
  }
}
