<div class="kt-form kt-form--label-right">
    <div class="row align-items-center">
        <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
                <div class="col-12 row mb-2 pr-0">
                    <div class="col-md-1 kt-form__label col-form-label">
                        <label>Event Name:</label>
                    </div>
                    <div class="col-md-4 kt-form__control ">
                        <kt-dropdown-wo-lazyload
                        [form] = 'form'
                        [dropdownList] = 'eventNameList'
                        [dropdownSettings] = 'eventNamedropdownSettings'
                        [formName] = "'name'"
                        [selectedItems] = 'eventNameSelectedItems'
                        [selectionAttributes] = "'name'">
                        </kt-dropdown-wo-lazyload>
                    </div>
                    <div class="col-md-1 kt-form__label col-form-label">
                        <label>Date/Time:</label>
                    </div>
                    <div class="col-md-5 kt-form__control">
                        <div class="input-group date">
                            <div class="input-group-prepend">
                                <select class="form-control" formControlName="date_type">
                                    <option value="event_date" selected>Event Date</option>
                                    <option value="created_date" selected>Created Date</option>
                                    <option value="last_updated_date" selected>Last Updated Date</option>
                                </select>
                            </div>
                            <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate"
                                [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true"
                                [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                            <span class="input-group-append">
                                <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-12 row mb-2 pr-0">
                    <div class="col-md-1 kt-form__label col-form-label">
                        <label>Currency:</label>
                    </div>
                    <div class="col-md-2 kt-form__control ">
                        <kt-dropdown-wo-lazyload
                        [form] = 'form'
                        [dropdownList] = 'dropdown.currencies'
                        [dropdownSettings] = 'dropdownSettings'
                        [formName] = "'currency_id'"
                        [selectedItems] = 'currenciesSelectedItems'
                        [selectionAttributes] = "'id'">
                        </kt-dropdown-wo-lazyload>
                    </div>
                    <div class="col-md-1 kt-form__label col-form-label text-right">
                        <label>Ticket Reset Frequency:</label>
                    </div>
                    <div class="col-md-2 kt-form__control ">
                        <kt-dropdown-wo-lazyload
                        [form] = 'form'
                        [dropdownList] = 'dropdown.ticketFrequncy'
                        [dropdownSettings] = 'dropdownSettings'
                        [formName] = "'reset_frequency'"
                        [selectedItems] = 'ticketFrequncyItems'
                        [selectionAttributes] = "'id'">
                        </kt-dropdown-wo-lazyload>
                    </div>
                    <div class="col-1 text-right">
                        <label class="pt-2">Status:</label>
                    </div>
                    <div class="col-md-3 kt-form__control">
                        <div class="d-flex flex-wrap status-filter-pt">
                            <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.statuses; let i = index">
                                <input type="checkbox" [checked]="item.checked" (change)="onSearchStatus($event, item.value, i)" class="custom-control-input" [id]="item.name">
                                <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
    <div class="dropdown dropdown-inline">
        <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
        <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
    </div>
    <button *ngIf="canCreateEvent" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')" [disabled]="createBtnLoading"><i [class]="createBtnLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-plus'"></i>Create</button>
</div>
<div class="kt-section">
    <div class="kt-section__content">
        <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
                <thead>
                    <tr>
                        <th class="sort-enabled"  (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                        <th class="sort-enabled"  (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                        <th>Currency</th>
                        <th class="sort-enabled"  (click)="onSortColumn('start_datetime')" [ngClass]="sortingConfig.start_datetime" [class.sort-selected]="sortingSelection.sort_by === 'start_datetime'">Active Date Time</th>
                        <th class="sort-enabled"  (click)="onSortColumn('reset_frequency')" [ngClass]="sortingConfig.reset_frequency" [class.sort-selected]="sortingSelection.sort_by === 'reset_frequency'">Reset Frequency</th>
                        <th class="text-center">Status</th>
                        <th class="text-center sort-enabled"  (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                        <th class="text-center sort-enabled"  (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                        <th class="text-center">Action</th>
                    </tr>
                </thead>
                <tbody *ngIf="(events$ | async) as rows">
                    <tr *ngFor="let row of rows">
                        <td>{{ row.id }}</td>
                        <td>{{ row.name }}</td>
                        <td class="text-center">{{ row.currencies }}</td>
                        <td> {{ row.start_datetime | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }} - {{ row.end_datetime | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}</td>
                        <td class="text-center">
                            <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'reset-type-kt-badge--' + row.reset_frequency" *ngIf="row.reset_frequency else noneType"> {{ row.reset_frequency_name }}</span>
                            <ng-template #noneType>
                                <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide reset-type-kt-badge--0">
                                    None
                                </span>
                            </ng-template>
                        </td>
                        <td class="text-center">
                            <span *ngIf="row.status == 1 else Inactive" class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'status-type-kt-badge--' + row.raffle_event_status"> {{ row.raffle_event_status }}</span>
                            <ng-template #Inactive>
                                <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide status-type-kt-badge--INACTIVE">
                                    {{ row.status_name }}
                                </span>
                            </ng-template>
                        </td>
                        <td>
                            {{ row.created_by ? row.created_by : '-' }} <br />
                            <span placement="top" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                            <ng-template #createdDateTime>
                                {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                            </ng-template>
                        </td>
                        <td>
                            {{ row.updated_by ? row.updated_by : '-' }} <br />
                          
                            <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at ? (row.updated_at | date:'yyyy-MM-dd HH:mm') : '' }}</span>
                            <ng-template #updatedDateTime>
                                {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                            </ng-template>
                        </td>
                        <td class="text-center">
                            <button  *ngIf="canViewEventDetails" matTooltip="Raffle Event Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-cog"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
        </div>
        <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                </select>
                <span class="pagination__desc">
                    Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                </span>
            </div>
        </div>
    </div>
</div>
            

