import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { WebDisplay } from '@core/models/web-display.model';

@Injectable()
export class WebDisplayEntityService extends EntityCollectionServiceBase<WebDisplay> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('WebDisplay', serviceElementsFactory);
  }
}
