import { tap, catchError } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { GameProviderPosition } from '@core/models/game-provider-position.model';
import { GameProviderPositionDataService } from '../services/game-provider-position-data.service';
@Component({
  selector: 'kt-game-provider-position-edit',
  templateUrl: './game-provider-position-edit.component.html',
  styleUrls: ['./game-provider-position-edit.component.scss']
})
export class GameProviderPositionEditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = this.gameProviderPositionDataService.messages$;
  refreshStatus: boolean;
  buttonLoading = false;

  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { gameProviderPosition: any },
    public dialogRef: MatDialogRef<GameProviderPositionEditDialogComponent>,
    private gameProviderPositionDataService: GameProviderPositionDataService,
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    // Create variable to hold the value will be pass to API
    const data = {
      category_list: this.form.value.category_list,
    };

    this.gameProviderPositionDataService.updateGameProviderPosition(this.data.gameProviderPosition.id, data).pipe(
      tap((res: any) => {
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();

    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    this.form = new FormGroup({
      game_provider_name: new FormControl(this.data.gameProviderPosition.code + ' - ' +this.data.gameProviderPosition.name),
      merchant_name: new FormControl(null),
      category_list: new FormGroup(this.buildCategory()),
    });
    this.form.markAllAsTouched();
    this.form.markAsDirty();
  }

  private buildCategory = () => {
    let formgroup = {};
    this.data.gameProviderPosition.category_list.forEach((ele, index) => {
      formgroup[index] = new FormGroup({
        category_id: new FormControl(ele.category_id),
        position_list: new FormGroup(this.buildPosition(ele.position))
      })
    });
    return formgroup;
  }

  private buildPosition = (position: any) => {
    let positions = {};
    position.map(element => {
      const positionsGroup = new FormGroup({
        settings_currency_id: new FormControl(element.settings_currency_id),
        position: new FormControl(element.position, [Validators.required]),
      });
      positions = { ...positions, [position.indexOf(element)]: positionsGroup };
    });
    return positions;
  };
}
