import { PromotionCodeDataService } from './../promotion-codes/services/promotion-code-data.service';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { PromotionMemberListDataService } from './services/promotion-member-list-data.service';
import { Pagination } from '@core/models/pagination.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { tap, catchError, map, exhaustMap } from 'rxjs/operators';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Status } from '@core/enums/status.enum';
import { PromotionCode } from '@core/models/promotion-code.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PromotionCodeDetailsDialogComponent } from '../promotion-codes/dialogs/promotion-code-details/promotion-code-details';
import { PromotionMemberDetailsComponent } from './dialogs/promotion-member-list-details.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'kt-promotion-member',
  templateUrl: './promotion-member-list.component.html',
  styleUrls: ['./promotion-member-list.component.scss']
})
export class PromotionMemberListComponent implements OnInit {

  @ViewChild(PromotionCodeDetailsDialogComponent) child;
  status = Status;
  promotionMember$ = [] ;
  promotionAffiliate$ =  [];

  messages$ = this.promotionMemberDataService.messages$;
  data$ = this.promotionMemberDataService.data$;
  buttonLoading = false;
  form: FormGroup;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  dataLength: number;
  params = this.data.promotionCodeMode === 'edit' ? `promotion_id=${this.data.promotionCode.id}` : '';
  catchPromotionId: any;
  perPageDropdown = this.dropdownHttpService.perPage;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  importedMemberList: any;
  importedMemberFileName = localStorage.getItem('importedMemberListName');
  uploading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { promotionCode: PromotionCode, promotionCodeMode: string, formData: any, currentImportedMemberList: any },
    private loadingBar: LoadingBarService,
    private promotionMemberDataService: PromotionMemberListDataService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PromotionMemberListComponent>,
    private promotionCodeDataService: PromotionCodeDataService,
    private authHttpService: AuthHttpService,
  ) { }

  ngOnInit() {
    if (this.data.promotionCodeMode === 'edit') {
      this.reload();
      this.pagination = this.promotionMemberDataService.pagination;

      var params = this.params ? `&${this.params}` : '';
      if (+this.data.formData.eligible_types === 2) {
        this.promotionMemberDataService.getAffiliateWithQuery(`?${params}&paginate=0`).pipe(
          tap((res) => {
            localStorage.setItem("updatePromoCodeAffiliates", res.length > 0 ? '1' : '');
          })
        ).subscribe();
      } else {
        this.promotionMemberDataService.getWithQuery(`?${params}&paginate=0`).pipe(
          tap((res) => {
            localStorage.setItem("updatePromoCodeMembers", res.length > 0 ? '1' : '');
          })
        ).subscribe();
      }
    } else {
      this.importedMemberList = this.data.currentImportedMemberList;
    }
    this.formInit();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close(this.importedMemberList);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();


    if (+this.data.formData.eligible_types === 2) {
      return this.promotionMemberDataService.getAffiliateWithQuery(`?&page=${page}&perPage=${pageSize}${params}`).pipe(
        tap(res => {
          this.pagination = this.promotionMemberDataService.pagination;
          this.promotionAffiliate$ = res;
          this.loadingBar.complete();
        })
      ).subscribe();
    } else {
      return this.promotionMemberDataService.getWithQuery(`?&page=${page}&perPage=${pageSize}${params}`).pipe(
        tap(res => {
          this.pagination = this.promotionMemberDataService.pagination;
          this.promotionMember$ = res;
          this.loadingBar.complete();
        })
      ).subscribe();
    }
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onUpdatePerson(row?: any) {
    const rowid = row?.id;

    if (this.data.promotionCodeMode === 'edit') {
      if (rowid >= 0) {
        Swal.fire({
          title: 'Are you sure?',
          text: +this.data.formData.eligible_types === 2 ? 'Are you sure you want to delete this affiliate?' : 'Are you sure you want to delete this member?',
          icon: 'info',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          reverseButtons: true
        }).then((resp) => {
          if (resp.value) {
            if (+this.data.formData.eligible_types === 2) {
              this.promotionMemberDataService.deleteAffiliate(row.promotion_affiliate_id).pipe(
                tap((res: any) => {
                  localStorage.setItem('updatePromoCodeAffiliates', '1');
                  this.buttonLoading = false;
                }),
                catchError((error) => {
                  this.buttonLoading = false;
                  throw error;
                })
              ).subscribe();
            } else {
              this.promotionMemberDataService.deleteMember(row.promotion_member_id).pipe(
                tap((res: any) => {
                  localStorage.setItem('updatePromoCodeMembers', '1');
                  this.buttonLoading = false;
                }),
                catchError((error) => {
                  this.buttonLoading = false;
                  throw error;
                })
              ).subscribe();
            }
            setTimeout(() => {
              this.reload();
            }, 1000);
          }
        });

        
      } else {
        this.openDialogBy(PromotionMemberDetailsComponent, { promotionPerson: null, mode: 'create', promotionId: this.data.promotionCode.id });
      }
    } else {
      if (rowid >= 0) {
        var createPromoCodePerson = +this.data.formData.eligible_types === 2 ? localStorage.getItem("createPromoCodeAffiliates") : localStorage.getItem("createPromoCodeMembers");
        Swal.fire({
          title: 'Are you sure?',
          text: +this.data.formData.eligible_types === 2 ? 'Are you sure you want to delete this affiliate?' : 'Are you sure you want to delete this member?',
          icon: 'info',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          reverseButtons: true
        }).then((resp) => {
          if (resp.value) {
            var personArray = JSON.parse(createPromoCodePerson);
            if (personArray != null) {
              personArray.filter((res, index) => {
                if (res.id == rowid) {
                  personArray.splice(index, 1)
                }
              });
            }
            if (+this.data.formData.eligible_types === 2) {
              localStorage.setItem("createPromoCodeAffiliates", JSON.stringify(personArray));
              this.promotionAffiliate$ = localStorage.getItem('createPromoCodeAffiliates') === null ? [] : JSON.parse(localStorage.getItem('createPromoCodeAffiliates'));
            } else {
              localStorage.setItem("createPromoCodeMembers", JSON.stringify(personArray));
              this.promotionMember$ = localStorage.getItem('createPromoCodeMembers') === null ? [] : JSON.parse(localStorage.getItem('createPromoCodeMembers'));
            }
          }
        });
      } else {
        if (+this.data.formData.eligible_types === 2) {
          this.openDialogBy(PromotionMemberDetailsComponent, { promotionPerson: localStorage.getItem('createPromoCodeAffiliates') === null ? [] : JSON.parse(localStorage.getItem('createPromoCodeAffiliates')), mode: 'create', promotionId: null });
        } else {
          this.openDialogBy(PromotionMemberDetailsComponent, { promotionPerson: localStorage.getItem('createPromoCodeMembers') === null ? [] : JSON.parse(localStorage.getItem('createPromoCodeMembers')), mode: 'create', promotionId: null });
        }
      }
    }
  }

  onMemberUpload(event: any) {
    const file: File = event.target.files[0];
    if (this.data.promotionCodeMode === 'edit') {
      this.uploading = true;
      const formData = new FormData();
      formData.append('files', file);
      formData.append('promotion_id', this.data.promotionCode.id.toString());
      this.loading = true;
      this.promotionCodeDataService.importMemberList(formData, 'edit').pipe(
        tap((_) => {
          this.uploading = false;
          this.onViewPageBy(this.page);
        }),
        catchError((error) => {
          this.uploading = false;
          throw error;
        })
      ).subscribe();
    } else {
      this.importedMemberList = file;
      this.importedMemberFileName = event.target.files[0].name;
      localStorage.setItem('importedMemberListName', this.importedMemberFileName);
    }
    event.target.value = '';
  }

  onRemoveFile() {
    this.importedMemberList = undefined;
    this.importedMemberFileName = '';
    localStorage.removeItem('importedMemberListName');
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private reload() {
    this.onViewPageBy(this.page);
  }

  private openDialogBy(componentRef: any, data?: { promotionPerson?: any, mode?: any, promotionId?: number }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        promotionPerson: data.promotionPerson,
        mode: data.mode,
        promotionId: data.promotionId,
        promotionCodeMode: this.data.promotionCodeMode,
        promoCodeFormData: this.data.formData
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (this.data.promotionCodeMode === 'edit') {
        this.onViewPageBy(this.page);
      } else {
        if (result !== undefined) {
          setTimeout(() => {
            if (+this.data.formData.eligible_types === 2) {
              this.promotionAffiliate$ = localStorage.getItem('createPromoCodeAffiliates') === null ? [] : JSON.parse(localStorage.getItem('createPromoCodeAffiliates'));
            } else {
              this.promotionMember$ = localStorage.getItem('createPromoCodeMembers') === null ? [] : JSON.parse(localStorage.getItem('createPromoCodeMembers'));
            }
          }, 300);
        }
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
    });
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = `promotion_id=${this.data.promotionCode.id}` + `&${Object.keys(data).map(key => key + '=' + data[key]).join('&')}`
        const parameters = `&${this.params}`;
        this.loadingBar.start();

        if (+this.data.formData.eligible_types === 2) {
          return this.promotionMemberDataService.getAffiliateWithQuery(`?&page=${this.page}&perPage=${this.pageSize}${parameters}`).pipe(
            tap(res => {
              this.loading = false;
              this.clearBtnLoading = false;
              this.searchBtnLoading = false;
              this.dataLength = res.length;
              this.page = 1;
              this.pagination = this.promotionMemberDataService.pagination;
              this.promotionAffiliate$ = res;
              this.loadingBar.complete();
            })
          );
        } else {
          return this.promotionMemberDataService.getWithQuery(`?&page=${this.page}&perPage=${this.pageSize}${parameters}`).pipe(
            tap(res => {
              this.loading = false;
              this.clearBtnLoading = false;
              this.searchBtnLoading = false;
              this.dataLength = res.length;
              this.page = 1;
              this.pagination = this.promotionMemberDataService.pagination;
              this.promotionMember$ = res;
              this.loadingBar.complete();
            })
          );
        }
      }),
    ).subscribe();
  }

  resetData() {
    this.loadingBar.complete();
    this.promotionMemberDataService.resetData();
  }
}
