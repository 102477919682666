<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit'? 'Edit' : 'Create'}} Transfer</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">Member <span class="text-danger">*</span></label>
            <kt-member-dropdown class="dropdown-maxheight col-8" style="padding:0" [form]="form" [dropdownSettings]='dropdownSettings'
          [formName]="'member_account_id'" [selectionAttributes]="'id'"></kt-member-dropdown>
        </div>
        <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">Type <span class="text-danger">*</span></label>
            <div *ngFor="let value of transferType" class="col-4 form-check form-check-inline col-form-label mr-0">
              <label class="form-check-label">
                <input class="form-check-input" (change)="onGetPromotions(form.value.game_provider_id)" formControlName="transfer_type" type="radio" name="transfer_type" [value]="transfertypeRef[value]">
                {{ value }}
              </label>
            </div>
        </div>
        <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">Main Wallet Amount </label>
            <label class="col-8 col-form-label pl-0">{{ (memberCurrentWalletAmount ? memberCurrentWalletAmount : 0) | number : '1.2-2'}}</label>
        </div>
        <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">Game Provider <span class="text-danger">*</span></label>
            <kt-dropdown-wo-lazyload
              id="gameProviderDropdown"
              class="col-8"
              style="padding:0"
              [form] = 'form'
              [dropdownList] = 'gameProvidersDropdownList'
              [dropdownSettings] = 'gameProvidersDropdownSettings'
              [formName] = "'game_provider_id'"
              [selectionAttributes] = "'id'">
            </kt-dropdown-wo-lazyload>
        </div>
        <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">Provider Wallet Amount</label>
            <label class="col-8 col-form-label pl-0">{{ (gameBalance ? gameBalance : 0) | number : '1.2-2'}}</label>
        </div>
        <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">Amount <span class="text-danger">*</span></label>
            <input type="number" (wheel)="false" min="1" formControlName="amount" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row pr-0" *ngIf="form.value.transfer_type == 1">
            <label class="col-4 col-form-label">Promotion</label>
            <div class="col-8" style="padding:0">
              <kt-dropdown-wo-lazyload
                [form] = 'form'
                [dropdownList] = 'promotionCodeDropdownList'
                [dropdownSettings] = 'promotionCodeDropdownSettings'
                [formName] = "'promotion_id'"
                [selectionAttributes] = "'id'"
                [selectedItems] = 'promotionCodeSelectedItems'>
              </kt-dropdown-wo-lazyload>
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.transfer)"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
