import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { ReferralContent } from '@core/models/referral-content.model';

@Injectable()
export class ReferralContentDataService extends DefaultDataService<ReferralContent>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('ReferralContent', http, httpUrlGenerator, { root: '' });
  }

  updateData(referralContents: ReferralContent){
    return this.http.put<ApiResponse>(`/referralcontent`, referralContents).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  getWithQuery(pageParam: string): Observable<ReferralContent[]>{
    return this.http.get<ApiResponse>(`/referralcontent${pageParam}`).pipe(
      map(res => res.data.rows)
    )
  }
}
