import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, catchError, map } from 'rxjs/operators';
import { Subscription, forkJoin, of } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit, OnDestroy, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { SystemMaintenance } from '@core/models/system-maintenance.model';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { DatePipe } from '@angular/common';
import { SystemMaintenanceDataService } from '../services/system-maintenance-data.service';
import * as moment from 'moment-timezone';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-system-maintenance-form',
  templateUrl: './system-maintenance-edit.component.html',
  styleUrls: ['./system-maintenance-edit.component.scss']
})
export class SystemMaintenanceEditComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;

  form: FormGroup;
  messages$ = this.systemMaintenanceDataService.messages$;
  refreshStatus: boolean;

  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  dateTimeFormat = 'yyyy-MM-dd HH:mm:';

  dropdown = {
    statuses: this.dropdownHttpService.statuses
  };
  buttonLoading = false;
  dateTimeStack = {
    maintain_from: null,
    maintain_to: null
  };
  private datePickerSubscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  canCreateSystemMaintenance: boolean;
  canEditSystemMaintenance: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, systemMaintenance?: SystemMaintenance },
    public dialogRef: MatDialogRef<SystemMaintenanceEditComponent>,
    private dropdownHttpService: DropdownHttpService,
    private datePipe: DatePipe,
    private systemMaintenanceDataService: SystemMaintenanceDataService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if (this.data.mode === 'edit'){
      this.dateTimeStack = {
        maintain_from: new Date(new Date(this.data.systemMaintenance.maintain_from).getTime() + this.offset - this.clientOffset),
        maintain_to: new Date(new Date(this.data.systemMaintenance.maintain_to).getTime() + this.offset - this.clientOffset),
      };
    }
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateSystemMaintenance = appPermissions.create_system_maintenance;
      this.canEditSystemMaintenance = appPermissions.edit_system_maintenance;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'maintain_from'),
      this.buildDatePicker(1, 'maintain_to')
    ]).subscribe();
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const systemMaintenance = <SystemMaintenance>this.filterFormFields(this.form.value);
    systemMaintenance['maintain_from'] = moment(systemMaintenance['maintain_from']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
    systemMaintenance['maintain_to'] = moment(systemMaintenance['maintain_to']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
    
    if (this.data.mode === 'create') {
      this.systemMaintenanceDataService.add(systemMaintenance).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else {
      systemMaintenance.id = this.data.systemMaintenance.id;
      this.systemMaintenanceDataService.update(systemMaintenance).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, index === 0 ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      })
    );
  }

  private formInit() {
    let maintain_from = this.data.mode === 'edit' ? moment(new Date(new Date(this.data.systemMaintenance.maintain_from).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null;
    let maintain_to = this.data.mode === 'edit' ? moment(new Date(new Date(this.data.systemMaintenance.maintain_to).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null
    let status = this.data.mode === 'edit' ? this.data.systemMaintenance.status : null;
    let remarks = this.data.mode === 'edit' ? this.data.systemMaintenance.remarks : null;

    if (this.data.mode === 'create') {
      this.form = new FormGroup({
        maintain_from: new FormControl(maintain_from, [Validators.required]),
        maintain_to: new FormControl(maintain_to, [Validators.required]),
        remarks: new FormControl(remarks),
      });
    } else {
      this.form = new FormGroup({
        maintain_from: new FormControl(maintain_from, [Validators.required]),
        maintain_to: new FormControl(maintain_to, [Validators.required]),
        status: new FormControl(status, [Validators.required]),
        remarks: new FormControl(remarks),
      });
    }
  }

}
