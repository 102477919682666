import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, tap, take } from 'rxjs/operators';
import { CampaignEntityService } from '@views/pages/apps/marketeer/campaign/services/campaign-entity.service';


@Injectable()
export class CampaignResolver implements Resolve<boolean> {

    constructor(private campaignEntityService: CampaignEntityService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.campaignEntityService.loaded$.pipe(
          tap(loaded =>  {
            if(!loaded) {
              this.campaignEntityService.getAll().pipe(
                take(1)
              );
            }
          }),
          filter(loaded => !!loaded),
          first()
        );
    }
}
