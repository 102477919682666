import { StatusRemarksComponent } from './dialogs/status-remarks/status-remarks.component';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, Input } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MerchantBankEntityService } from './services/merchant-bank-entity.service';
import { MerchantBankEditDialogComponent } from './dialogs/merchant-bank-edit/merchant-bank-edit.component';
import { MerchantBankNewEditComponent } from './dialogs/merchant-bank-new-edit/merchant-bank-new-edit.component';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MerchantBank } from '@core/models/merchant-bank.model';
import { map, exhaustMap, tap, delay } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { MerchantBankDataService } from './services/merchant-bank-data.service';
import { Sync } from '@core/enums/sync.enum';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MerchantBankStatus } from '@core/enums/merchant-bank.enum';
import { RemarksHistoryComponent } from './dialogs/remarks-history/remarks-history.component';
import { MerchantBankPurpose } from '@core/enums/merchant-bank-purpose.enum';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { MerchantBankTransactionConfig } from './dialogs/merchant-bank-transaction-config-edit/merchant-bank-transaction-config';
import { AuthHttpService } from '@core/services/auth-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { MerchantBankComponentType } from 'typings';
declare const $: any;

@Component({
  selector: 'app-merchant-bank',
  templateUrl: './merchant-bank.component.html',
  styleUrls: ['./merchant-bank.component.scss']
})
export class MerchantBankComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  type: MerchantBankComponentType;

  form: FormGroup;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    merchantBanks: this.dropdownHttpService.merchantBanks,
    merchantBankTypes: this.dropdownHttpService.merchantBankTypes,
    bankPurposes: this.dropdownHttpService.bankPurposes,
    perPage: this.dropdownHttpService.perPage,
    statuses: MerchantBankStatus,
    processingFeeType: this.dropdownHttpService.processingFeeType,
  };
  bankPaymentBankTypes = [];
  cryptoBankTypes = [];
  status = MerchantBankStatus;
  sync = Sync;
  merchantBanks$: Observable<MerchantBank[]>;
  merchantBanks = [];
  rowIcon = [];
  isChecked: boolean[][] = [];

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = 'status[0]=1&status[1]=2';
  searchStatus = [1, 2];

  isRowsExpanded: boolean;

  object = Object;

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '7.1';
  sortingConfig = {
    'id': 'desc',
    'currency': 'desc',
    'bank_type': 'desc',
    'bank_name': 'desc',
    'account_name': 'desc',
    'account_number': 'desc',
    'status': 'desc',
    'purpose': 'desc',
    'balance': 'desc'
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;

  access$ = this.authHttpService.getUserAccess(7, 'Merchant Banks');
  purposeEnum = MerchantBankPurpose;
  messages$ = this.merchantBankDataService.messages$;

  // permissions
  // merchantBanksUseNewPermissions: boolean;
  canCreateMerchantBank: boolean;
  canEditMerchantBank: boolean;
  canUpdateMerchantBankStatus: boolean;
  canCreateCryptoMerchantBank: boolean;
  canEditCryptoMerchantAccount: boolean;
  canUpdateCryptoMerchantAccountsStatus: boolean;


  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private merchantBankTypeSub = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private merchantBankService: MerchantBankEntityService,
    private merchantBankDataService: MerchantBankDataService,
    private dropdownHttpService: DropdownHttpService,
    private currencyHttpService: CurrencyHttpService,
    private authHttpService: AuthHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.formInit();
    this.pagination = this.merchantBankDataService.pagination;

    // this.merchantBanksUseNewPermissions = this.appPermissionService.isSectionUsingNewPermissions('merchant_banks');
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      // bank/payment gateway
      this.canCreateMerchantBank = appPermissions.create_merchant_bank;
      this.canEditMerchantBank = appPermissions.edit_merchant_bank;
      this.canUpdateMerchantBankStatus = appPermissions.update_merchant_bank_status;

      // crypto
      this.canCreateCryptoMerchantBank = appPermissions.create_crypto_merchant_bank;
      this.canEditCryptoMerchantAccount = appPermissions.edit_crypto_merchant_account;
      this.canUpdateCryptoMerchantAccountsStatus = appPermissions.update_crypto_merchant_accounts_status;
    });

    this.subscriptions.add(apSub);
    this.merchantBankTypeSub = this.dropdown.merchantBankTypes.subscribe((res) => {
      const cryptoBankTypeIds = [5, 6]; // this ID refer to backend Bank.php BANK_TYPE constant
      this.bankPaymentBankTypes = res.filter(x => !cryptoBankTypeIds.includes(x.id));
      this.cryptoBankTypes = res.filter(x => cryptoBankTypeIds.includes(x.id));
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.merchantBankTypeSub.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onExpandCollpse(id: number, index: number) {
    $(document).find('#detail-' + id).attr('style', 'display: contents !important');
    var targetrow = $(document).find('#detail-' + id);
    this.rowIcon[index] === '+'? targetrow.find('td').css('display','none') : targetrow.find('td').css('display','');
    targetrow.show().find('td').slideToggle('fast');
    this.rowIcon[index] = this.rowIcon[index] === '+' ? '-' : '+';
  }

  onExpandCollpseAll() {
    this.merchantBanks.map((res, index) => {
      const isRowsExpanded = this.isRowsExpanded ? '+' : '-';
      if (isRowsExpanded === this.rowIcon[index]) {
        this.onExpandCollpse(res.id, index);
      }
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onOpenDialog(type: string, bankId?: number, transConfigId?: number, currencyCode?: string) {
    if (type === 'remarksHistory') {
      this.openDialogBy(RemarksHistoryComponent, { bank: { merchantBankId: bankId, merchantBankTransConfigId: transConfigId }, mode: 'remarksHistory' })
    }
    else if (bankId) {
      const bank = this.merchantBankService.getByKey(bankId);
      this.subscription = bank.pipe(
        tap((res) => {
          switch (type) {
            case 'edit':
              // this.openDialogBy(MerchantBankEditDialogComponent, { bank: { ...res, currencyCode }, mode: 'edit' });
              this.openDialogBy(MerchantBankNewEditComponent, { bank: { ...res, currencyCode }, mode: 'edit', type: this.type });
              break;
          }
        })
      )
        .subscribe();
    } else {
      // this.openDialogBy(MerchantBankEditDialogComponent, { mode: 'create' });
      this.openDialogBy(MerchantBankNewEditComponent, { mode: 'create', type: this.type });
    }

  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    //change all row icon back to '+'
    this.rowIcon = this.rowIcon.map(x => '+');
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    const methodParams = this.generateMethodParams();

    this.loadingBar.start();
    return this.merchantBanks$ = this.merchantBankService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}&${methodParams}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.merchantBankDataService.pagination;
        this.merchantBanks = res;
        res.map((element, index) => {
          this.rowIcon.push('+');
          element.merchant_bank_transaction_configs.map((data : any, id) => {
            this.isChecked[index] = this.isChecked[index] || [];
            this.isChecked[index][id] = data.status ? true : false;
          });
        });
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.searchStatus = [1, 2];
    this.formInit();
    this.onSubmit(true);
  }

  onSearchStatus(event: any, value: number) {
    const position = this.searchStatus.indexOf(+value);
    if (event.target.checked) {
      this.searchStatus.push(+value);
    } else {
      this.searchStatus.splice(position, 1);
    }
    this.form.patchValue({ status: this.searchStatus });
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    //change all row icon back to '+'
    this.rowIcon = this.rowIcon.map(x => '+');
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        const parameters = this.params ? `&${this.params}` : '';
        const methodParams = this.generateMethodParams();

        this.loadingBar.start();
        return this.merchantBanks$ = this.merchantBankService.getWithQuery(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}&${methodParams}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.merchantBanks = res;
            res.map((element, index) => {
              this.rowIcon.push('+');
              element.merchant_bank_transaction_configs.map((data : any, id) => {
                this.isChecked[index] = this.isChecked[index] || [];
                this.isChecked[index][id] = data.status ? true : false;
              });
            });
            this.page = 1;
            this.pagination = this.merchantBankDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  toggleExpandableRows() {
    this.isRowsExpanded = !this.isRowsExpanded;
  }

  onCheckBox(item: any) {
    return this.searchStatus.indexOf(+item) >= 0 ? true : false;
  }

  onChangeStatus(status: number, row: MerchantBank) {
    const data = {
      ...row,
      remarks: row.remarks,
      currency_id: row.settings_currency_id,
      member_groups: row.member_groups ? (row.member_groups).map(res => res.id) : null,
      status
    };
    const dialogRef = this.dialog.open(StatusRemarksComponent, {
      width: '800px',
      data: {
        merchantBank: data
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  // onChangePaymentProcessorStatus(status: number, row: MerchantBankTransactionConfig) {
  //   this.subscription = this.merchantBankDataService.togglePaymentGatewayStatus(row.id, status).subscribe((res) => {
  //       this.messages$ = this.merchantBankDataService.messages$
  //       if (res.success) {
  //         this.onViewPageBy(this.page).subscribe();
  //       }
  //     }
  //   );
  // }

  extractAmounts(value: string) {
    let result: any[] = [];
    if (value !== null && value !== undefined) {
      result = value.split('/');
    }
    return result;
  }

  onEllipsisWord(word: string) {
    let newRemark = '';
    if (word.length > 51) {
      for (let i = 0; i < 51; i++) {
        newRemark += word.split('')[i];
      }
      newRemark += '...';
    } else {
      newRemark = word;
    }
    return newRemark;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private generateStatusParams() {
    return {
      newStatusParams: Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&'),
      oldStatusParams: 'status=' + Object.keys(this.searchStatus).map(key => this.searchStatus[key]).join(','),
    };
  }

  private generateMethodParams() {
    const methodIds = this.isTypeCrypto() ? [4] : [1, 2, 3]; // refer to backend MerchantBank.php constants
    return methodIds.map((id, idx) => `method[${idx}]=${id}`).join('&');
  }

  private openDialogBy(componentRef: any, data?: { bank?: any, mode?: any, type?: MerchantBankComponentType }) {
    if (data.mode === 'remarksHistory') {
      const dialogRef = this.dialog.open(componentRef, {
        width: '800px',
        data: {
          merchantBankId: data.bank.merchantBankId,
          merchantBankTransConfigId: data.bank.merchantBankTransConfigId
        }
      });
    } else {
      const dialogRef = this.dialog.open(componentRef, {
        width: '800px',
        data: {
          bank: data.bank,
          mode: data.mode,
          type: data.type,
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.onViewPageBy(this.page).subscribe();
        }
      });
    }
  }

  private formInit() {
    this.form = new FormGroup({
      keyword: new FormControl(null),
      currency_id: new FormControl('all'),
      status: new FormControl(this.searchStatus),
      purpose: new FormControl('all'),
      bank_type: new FormControl('all'),
    });
  }


  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  getDepositProcessingFee(data: MerchantBankTransactionConfig) {
    const text = this.dropdown.processingFeeType.find(x => x.id == data.deposit_processing_fee_type)?.name;
    if (data.deposit_processing_fee_type == 1) {
      return text + ': ' + parseFloat(data.deposit_processing_fee.toString()).toFixed(2);
    } else if (data.deposit_processing_fee_type == 2) {
      return text + ': ' + parseFloat(data.deposit_processing_fee_percentage.toString()).toFixed(2) + '%';
    } else if (data.deposit_processing_fee_type == 3) {
      return text + ': \n' + parseFloat(data.deposit_processing_fee.toString()).toFixed(2) + ' + ' + parseFloat(data.deposit_processing_fee_percentage.toString()).toFixed(2) + '%';
    }
  }

  getWithdrawalProcessingFee(data: MerchantBankTransactionConfig) {
    const text = this.dropdown.processingFeeType.find(x => x.id == data.withdrawal_processing_fee_type)?.name;
    if (data.withdrawal_processing_fee_type == 1) {
      return text + ': ' + parseFloat(data.withdrawal_processing_fee.toString()).toFixed(2);
    } else if (data.withdrawal_processing_fee_type == 2) {
      return text + ': ' + parseFloat(data.withdrawal_processing_fee_percentage.toString()).toFixed(2) + '%';
    } else if (data.withdrawal_processing_fee_type == 3) {
      return text + ': \n' + parseFloat(data.withdrawal_processing_fee.toString()).toFixed(2) + ' + ' + parseFloat(data.withdrawal_processing_fee_percentage.toString()).toFixed(2) + '%';
    }
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  getPurposeNameForPaymentGateway(data: Array<MerchantBankTransactionConfig>) {
    let purpose = '';
    data.forEach((x, index) => {
      if (index !== 0) {
        purpose += ', ';
      }
      purpose += this.purposeEnum[x.purpose];
    });
    return purpose;
  }

  /**
   * Small func to determine whether current merchant bank component is belong to crypto.
   * Note that this 'type' is used in frontend only and doesn't exist in DB
   *
   * @returns 
   */
  isTypeCrypto() {
    return this.type === 'crypto';
  }

}
