import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CoreModule } from '@core/core.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';


@NgModule({
  declarations: [],
  exports: [],
  imports: [
  CommonModule,
  PerfectScrollbarModule,
  CoreModule,
  MatIconModule,
  MatProgressSpinnerModule
  ]
})
export class WidgetModule {
}
