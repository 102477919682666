import { AllAffiliatesDataService } from './../../views/pages/apps/general/all-affiliates/services/all-affiliates-data.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { Component, OnInit, Input, ChangeDetectorRef, ElementRef, QueryList, ViewChildren, EventEmitter, Output, ViewRef, SimpleChanges, OnDestroy } from '@angular/core';
import { distinctUntilChanged, debounceTime, delay, tap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { MemberDataService } from '../../views/pages/apps/general/members/services/member-data.service';
import { FormGroup } from '@angular/forms';
import { AgentDataService } from '@views/pages/apps/general/agent/services/agent-data.service';
import { OperatorDataService } from '@views/pages/apps/superuser/operators/services/operator-data.service';
import { RoleHttpService } from '@core/services/role-http.service';
import { ReferralDataService } from './../../views/pages/apps/general/referrals/services/referral-data.service';

@Component({
  selector: 'kt-member-dropdown',
  templateUrl: './member-dropdown.component.html',
  styleUrls: ['./member-dropdown.component.scss']
})
export class MemberDropdownComponent implements OnInit, OnDestroy {
  @ViewChildren('filterInput') filterInput: QueryList<ElementRef>;
  data: any = [];

  @Input()
  form: FormGroup;

  @Input()
  isAgent: boolean;

  @Input()
  isTelemarketer: boolean;

  @Input()
  isAffiliate: boolean;

  @Input()
  isReferral: boolean;

  @Input()
  selectionAttributes: string;

  @Input()
  dropdownSettings: any;

  @Input()
  formName: string;

  @Input()
  selectedItems;

  @Input()
  agentsAgentOnly = false;

  @Input()
  isFilterByCurrency = false;

  @Input()
  currencyId: any;

  @Input()
  isFilterByMembergroupId= false;

  @Input()
  membergroupId: any;

  @Input()
  memberType: any;

  @Input()
  telemarketer_ids: any;

  @Input()
  normal_account_manager_ids: any;

  @Input()
  vip_account_manager_ids: any;

  @Input()
  affiliate_group_ids: any;

  @Input()
  affiliate_type: any;

  @Input()
  isShowLeadOnly = false;

  @Output()
  selectedItemsChanged = new EventEmitter();

  dropdownList: any = [];
  searchTerm = new BehaviorSubject<string>(null);
  count = 0;
  username = '';
  loading = false;
  pageSize = 30;
  pageNumber = 1;

  constructor(
    private cdr: ChangeDetectorRef,
    private memberDataService: MemberDataService,
    private agentDataService: AgentDataService,
    private operatorDataService: OperatorDataService,
    private eventEmitterService: EventEmitterService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private roleHttpService: RoleHttpService,
    private referralDataService: ReferralDataService
  ) { }

  // If detect any input changes will call this function
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currencyId || changes.membergroupId || (this.currencyId && this.isFilterByCurrency) || (this.membergroupId && this.isFilterByMembergroupId)) {
      this.pageNumber = 1;
      if ( (this.currencyId && this.isFilterByCurrency) || (this.membergroupId && this.isFilterByMembergroupId)) {
        this.selectedItems = [];
        this.dropdownList = [];
        this.cdr.detectChanges();
        if (this.isReferral) {
          this.setReferrals();
        }
        else {
          this.setMembers();
        }
      }
    }

    if (changes.currencyId || changes.affiliate_group_ids || changes.affiliate_type) {
      this.pageNumber = 1;
      if (this.currencyId && this.affiliate_group_ids != null && this.affiliate_type != null) {
        this.selectedItems = [];
        this.dropdownList = [];
        this.cdr.detectChanges();
        this.setAffiliates();
      }
    }
  }

  ngOnInit() {
    if (this.isAgent) {
      this.setAgents();
    }
    else if (this.isTelemarketer) {
      this.setTelemarketer();
    }
    else if (this.isAffiliate) {
      this.setAffiliates();
    }
    else if (this.isReferral) {
      this.setReferrals();
    }
    else {
      this.setMembers();
    }
    this.eventEmitterService.memberSearchSubsVar = this.eventEmitterService.invokeClearMemberSearch.subscribe(() => {
      this.selectedItems = [];
    });
  }

  ngOnDestroy(): void {
    this.eventEmitterService.memberSearchSubsVar.unsubscribe();
  }

  removeItem(value: any) {
    if (value < 1) {
      this.form.controls[this.formName].patchValue(null);
    }
  }

  onOpenSelect() {
    of(null).pipe(
      delay(0), tap(() => this.filterInput.first.nativeElement.focus()
      )).subscribe();
  }

  onOpen(items: any) {
    if (document.querySelectorAll('#selectAll')) {
      document.querySelectorAll('#selectAll').forEach(ele => ele.removeAttribute('id'));
    }
    window.setTimeout(function () {
      document.getElementById('member-search-dropdown-input').focus();
    }, 0);
  }

  onSelectAll(items: any) {
    this.data = [];
    if (this.dropdownSettings.singleSelection === true) {
      this.onItemSelectedChange();
      this.form.controls[this.formName].patchValue(items[this.selectionAttributes]);
    }
    if (this.dropdownSettings.singleSelection !== true && this.selectedItems !== null) {
      Object.keys(this.selectedItems).forEach((key) =>
        this.data.push(this.selectedItems[key][this.selectionAttributes]));
      this.onItemSelectedChange();
      this.form.controls[this.formName].patchValue(this.data);
    }
  }

  onSearch(event: any) {
    this.count++;
    this.searchTerm.next(event.target.value);
    this.searchTerm.pipe(
      distinctUntilChanged(),
      debounceTime(500),
    ).subscribe(data => {
      this.count--;
      if (this.count === 0) {
        this.username = data;
        this.pageNumber = 1;
        this.dropdownList = [];
        this.cdr.detectChanges();
        if (this.isAgent) {
          this.setAgents();
        }
        else if (this.isTelemarketer) {
          this.setTelemarketer();
        }
        else if (this.isAffiliate) {
          this.setAffiliates();
        }
        else if (this.isReferral) {
          this.setReferrals();
        }
        else {
          this.setMembers();
        }
        this.searchTerm = new BehaviorSubject<any>(null);
        this.count = 0;
      }
    });
  }

  onDeSelectAll(items: any) {
    this.data = [];
    this.onItemSelectedChange();
    if (this.dropdownSettings.singleSelection === true) {
      this.form.controls[this.formName].patchValue(null);
    }
    else {
      this.form.controls[this.formName].patchValue(this.data);
    }
  }

  OnItemDeSelect(item: any) {
    this.data = [];
    if (this.dropdownSettings.singleSelection === true) {
      if (this.selectedItems.length > 0) {
        this.onItemSelectedChange();
        this.form.controls[this.formName].patchValue(item[this.selectionAttributes]);
      } else {
        this.onItemSelectedChange();
        this.form.controls[this.formName].patchValue(null);
      }
    }
    if (this.dropdownSettings.singleSelection !== true) {
      Object.keys(this.selectedItems).forEach((key) =>
        this.data.push(this.selectedItems[key][this.selectionAttributes]));
      this.onItemSelectedChange();
      this.form.controls[this.formName].patchValue(this.data);
    }
  }

  onItemSelect(item: any) {
    this.data = [];
    if (this.dropdownSettings.singleSelection === true) {
      this.onItemSelectedChange();
      this.form.controls[this.formName].patchValue(item[this.selectionAttributes]);
    }
    if (this.dropdownSettings.singleSelection !== true && this.selectedItems !== null) {
      Object.keys(this.selectedItems).forEach((key) =>
        this.data.push(this.selectedItems[key][this.selectionAttributes]));
      this.onItemSelectedChange();
      this.form.controls[this.formName].patchValue(this.data);
    }
  }

  onItemSelectedChange() {
    this.selectedItemsChanged.emit(this.selectedItems);
  }

  fetchMore(item: any) {
    if (this.loading === false && item.endIndex === this.dropdownList.length - 1 && this.dropdownList.length % this.pageSize === 0 && this.dropdownList.length !== 0) {
      this.pageNumber++;
      if (this.isAgent) {
        this.setAgents();
      }
      else if (this.isTelemarketer) {
        this.setTelemarketer();
      }
      else if (this.isAffiliate) {
        this.setAffiliates();
      }
      else if (this.isReferral) {
        this.setReferrals();
      }
      else {
        this.setMembers();
      }
    }
  }

  private setMembers() {
    const telemarketer_ids = this.telemarketer_ids !== undefined && this.telemarketer_ids !== '' ? this.telemarketer_ids.map(item => `&telemarketer_ids[]=${item}`): '';
    const normal_account_manager_ids = this.normal_account_manager_ids !== undefined && this.normal_account_manager_ids !== '' ? this.normal_account_manager_ids.map(item => `&normal_account_manager_ids[]=${item}`): '';
    const vip_account_manager_ids = this.vip_account_manager_ids !== undefined && this.vip_account_manager_ids !== '' ? this.vip_account_manager_ids.map(item => `&vip_account_manager_ids[]=${item}`): '';
    const memberType = this.memberType !== undefined? `&member_type=${this.memberType}` : '';
    const username = this.username !== '' ? `&username=${this.username}` : '';
    var currencyId = '';
    var membergroupId = '';
    if(Array.isArray(this.currencyId)){
      Object.keys(this.currencyId).forEach(key => {
        currencyId=currencyId+`&currency_id[]=${this.currencyId[key]['id']}`;
      });
    }else{
      currencyId = this.currencyId && this.isFilterByCurrency && this.currencyId !='all' ? `&currency_id=${this.currencyId}` : '';
    }
    
    if(Array.isArray(this.membergroupId)){
      Object.keys(this.membergroupId).forEach(key => {
        membergroupId=membergroupId+`&member_group_id[]=${this.membergroupId[key]['id']}`;
      });
    }else{
      membergroupId = this.membergroupId && this.isFilterByCurrency && this.membergroupId !='all' ? `&member_group_id=${this.membergroupId}` : '';
    }
    const isShowLeadOnly = this.isShowLeadOnly ? `&show_lead_only=true` : '';
    // const members = this.memberDataService.getWithQueryDropdown(`?page=${this.pageNumber}${username}`);
    this.loading = true;
    this.memberDataService.getWithQueryDropdown(`?page=${this.pageNumber}${username}${memberType}${currencyId}${membergroupId}${telemarketer_ids}${isShowLeadOnly}${normal_account_manager_ids}${vip_account_manager_ids}`).toPromise().then(
      res => {
        res.map(function (elm) {
          elm['labelKey'] = elm.id + '-' + elm.username.toUpperCase() + '-' + elm.name.toUpperCase();
        });
        this.dropdownList = this.dropdownList.concat(res);
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  private setAgents() {
    if (JSON.parse(localStorage.getItem('user_data')).agent_type === 3) {
      this.dropdownSettings.disabled = true;
    } else {
      const username = this.username !== '' ? `&username=${this.username}` : '';
      // const members = this.agentDataService.getWithQuery(`?page=${this.pageNumber}${username}`);
      const agentOnly = this.agentsAgentOnly ? '&agent_only=1' : '';
      this.loading = true;
      this.agentDataService.getAgentList(`?page=${this.pageNumber}${username}${agentOnly}`).toPromise().then(
        res => {
          res.map(function (elm) {
            elm['labelKey'] = elm.id + '-' + elm.username.toUpperCase() + '-' + elm.name.toUpperCase();
          });
          this.dropdownList = this.dropdownList.concat(res);
          this.loading = false;
          if (this.cdr && !(this.cdr as ViewRef).destroyed) {
            this.cdr.detectChanges();
          }
        }
      );
    }
  }

  private setReferrals() {
    this.loading = true;
    const username = this.username !== '' ? `&referrer_username=${this.username}` : '';

    var currencyId = '';
    var membergroupId = '';
    if(Array.isArray(this.currencyId)){
      Object.keys(this.currencyId).forEach(key => {
        currencyId=currencyId+`&currency_id[]=${this.currencyId[key]['id']}`;
      });
    }else{
      currencyId = this.currencyId && this.isFilterByCurrency && this.currencyId !='all' ? `&currency_id=${this.currencyId}` : '';
    }
    
    if(Array.isArray(this.membergroupId)){
      Object.keys(this.membergroupId).forEach(key => {
        membergroupId=membergroupId+`&member_group_id[]=${this.membergroupId[key]['id']}`;
      });
    }else{
      membergroupId = this.membergroupId && this.isFilterByCurrency && this.membergroupId !='all' ? `&member_group_id=${this.membergroupId}` : '';
    }

    if(currencyId != '' && membergroupId != ''){
      this.referralDataService.getWithQuery(`?page=${this.pageNumber}${username}${currencyId}${membergroupId}`).toPromise().then(
        res => {
          res.map(function (elm) {
            elm['labelKey'] = elm.id + '-' + elm.referrer.toUpperCase();
          });
          this.dropdownList = this.dropdownList.concat(res);
          this.loading = false;
          this.cdr.detectChanges();
        });
    }else{
      this.dropdownList = [];
      this.loading = false;
      this.cdr.detectChanges();
    }
  }

  private setAffiliates() {
    this.loading = true;
    const username = this.username !== '' ? `&username=${this.username}` : '';
    const currencyId = this.currencyId ? `&currency_id=${this.currencyId}` : '';
    const affiliateGroupId = this.affiliate_group_ids ? `&affiliate_group_id=${this.affiliate_group_ids}` : '';
    const affiliateType = this.affiliate_type ? `&affiliate_type=${this.affiliate_type}` : '';
    this.allAffiliatesDataService.getWithQuery(`?page=${this.pageNumber}${username}${currencyId}${affiliateGroupId}${affiliateType}`).toPromise().then(
      res => {
        res.map(function (elm) {
          elm['labelKey'] = elm.id + '-' + elm.username.toUpperCase() + '-' + elm.name.toUpperCase();
        });
        this.dropdownList = this.dropdownList.concat(res);
        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  private setTelemarketer() {
    if (JSON.parse(localStorage.getItem('user_data')).agent_type === 3) {
      this.dropdownSettings.disabled = true;
    } else {
      this.loading = true;
      this.roleHttpService.getTelemarketer().pipe(
        tap((res: any) => {
          res.map(function (elm) {
            elm['labelKey'] = elm.id + '-' + elm.username.toUpperCase();
          });
          this.dropdownList = this.dropdownList.concat(res);
          this.loading = false;
          if (this.cdr && !(this.cdr as ViewRef).destroyed) {
            this.cdr.detectChanges();
          }
        }
      )).subscribe();
    }
  }

}
