import { map, tap } from "rxjs/operators";
import { ApiResponse } from "@core/models/api-response.model";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { DefaultDataService, HttpUrlGenerator } from "@ngrx/data";
import { Injectable } from "@angular/core";
import { Pagination } from "@core/models/pagination.model";
import { RegistrationReport } from "@core/models/registration-report.model";

@Injectable()
export class RegistrationReportDataService extends DefaultDataService<RegistrationReport> {
  messages$ = new Subject<any[]>();
  pagination: Pagination;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super("RegistrationReport", http, httpUrlGenerator, { root: "" });
  }

  getWithQuery(pageParam: string): Observable<RegistrationReport[]> {
    return this.http
      .get<ApiResponse>(`/report/registrationreport${pageParam}`)
      .pipe(
        tap((res) => {
          this.paginationInit(res);
        }),
        map((res) => res.data.rows)
      );
  }

  exportReport(pageParam: string) {
    return this.http.get<ApiResponse>(`/report/exportregistrationreport${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      return (this.pagination = res.data.paginations);
    }
  }
}
