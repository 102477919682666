
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { Announcement } from '@core/models/announcement.model';
import { Observable, Subscription, interval, of } from 'rxjs';
import { SubheaderService } from '@core/_base/layout';
import { AnnouncementEditDialogComponent } from './dialogs/announcement-edit/announcement-edit.component';
import { AnnouncementEntityService } from './services/announcement-entity.service';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { map, exhaustMap, tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { AnnouncementDataService } from './services/announcement-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Status } from '@core/enums/status.enum';
import { AnnouncementType } from '@core/enums/announcement-type.enum';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DatePipe } from '@angular/common';

@Component({
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  dropdown = {
    statuses:  this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage
  };
  status = Status;
  announcementType = AnnouncementType;
  announcements$: Observable<Announcement[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  private subscription = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private announcementService: AnnouncementEntityService,
    private announcementDataService: AnnouncementDataService,

    private dropdownHttpService: DropdownHttpService
  ) { }

  ngOnInit() {
    this.reload();
    this.formInit();
    this.pagination = this.announcementDataService.pagination;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onOpenDialog(type: string, announcementId?: number) {
    if (announcementId) {
      const announcement = this.announcementService.getByKey(announcementId);
      this.subscription = announcement.pipe(
        tap( (res) => {
        switch (type) {
          case 'edit':
            this.openDialogBy(AnnouncementEditDialogComponent, { announcement: res, mode: 'edit' });
            break;
        }
      })
      )
      .subscribe();
    } else {
      this.openDialogBy(AnnouncementEditDialogComponent, { mode: 'create' });
    }

  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loading = true;
    this.loadingBar.start();
    return this.announcements$ = this.announcementService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.announcementDataService.pagination;
        this.loading = false;
        this.dataLength = res.length;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.announcements$ = this.announcementService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.loading = false;
            this.searchBtnLoading = false;
            this.clearBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.announcementDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { announcement?: any, mode?: any}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        announcement: data.announcement,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true){
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      status: new FormControl('all')
    });
  }

  private refreshEvery(seconds: number) {
    const refreshSource = interval(seconds * 1000);
    refreshSource.subscribe(() => {
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
          this.reload();
        }
      });
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key =>  (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

}
