// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';

import { AuthGuard } from './core/guards/auth.guard';
import { DownloadAreaComponent } from '@views/pages/apps/download-area/download-area.component';
import { ImportHistoryComponent } from '@views/pages/apps/import-history/import-history.component';
import { ExportCRMBonusActionComponent } from '@views/pages/apps/export-crm-bonus-action/export-crm-bonus-action.component';

import { TwoFAFirstSetUpGuard } from './core/guards/twofafirstsetup.guard';
import { TwoFAGuard } from '@core/guards/twofa.guard';
import { TwofaLoginFirstsetupComponent } from '@views/pages/two-fa/twofa-login-firstsetup/twofa-login-firstsetup.component';
import { TwofaPendingComponent } from '@views/pages/two-fa/twofa-pending/twofa-pending.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('../app/views/pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'twofa',
    loadChildren: () => import('../app/views/pages/two-fa/two-fa.module').then(m => m.TwoFAModule),
    data: {title: 'Two FA Authentication'},
    canActivate: [TwoFAGuard]
  },
  {
    path: 'twofafirstsetup',
    component: TwofaLoginFirstsetupComponent,
    data: {title: 'Set Up Two FA Authentication'},
    canActivate: [TwoFAFirstSetUpGuard]
  },
  {
    path: 'twofapending',
    component: TwofaPendingComponent,
    data: {title: 'Two FA Authentication'}
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {title: 'Dashboard'}
      },
      {
        path: 'superuser',
        loadChildren: () => import('../app/views/pages/apps/superuser/superuser.module').then(m => m.SuperuserModule),
        data: {title: 'Superuser'}
      },
      {
        path: 'general',
        loadChildren: () => import('../app/views/pages/apps/general/general.module').then(m => m.GeneralModule),
        data: {title: 'General'}
      },
      {
        path: 'settings',
        loadChildren: () => import('../app/views/pages/apps/settings/settings.module').then(m => m.SettingsModule),
        data: {title: 'Settings'}
      },
      {
        path: 'game',
        loadChildren: () => import('./views/pages/apps/games/platform.module').then(m => m.PlatformModule),
        data: {title: 'Game'}
      },
      {
        path: 'account',
        loadChildren: () => import('../app/views/pages/apps/account/account.module').then(m => m.AccountModule),
        data: {title: 'Account'}
      },
      {
        path: 'rebates',
        loadChildren: () => import('./views/pages/apps/rebates/rebates.module').then(m => m.RebatesModule),
        data: {title: 'Rebates'}
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/pages/apps/reports/reports.module').then(m => m.ReportsModule),
        data: {title: 'Reports'}
      },
      {
        path: 'reports-v2',
        loadChildren: () => import('./views/pages/apps/reports-v2/reports-v2.module').then(m => m.ReportsV2Module),
        data: {title: 'Reports 2.0'}
      },
      {
        path: 'marketeer',
        loadChildren: () => import('./views/pages/apps/marketeer/marketeer.module').then(m => m.MarketeerModule),
        data: {title: 'Marketeer'}
      },
      {
        path: 'sms',
        loadChildren: () => import('./views/pages/apps/sms/sms.module').then(m => m.SmsModule),
        data: {title: 'SMS'}
      },
      {
        path: 'vip',
        loadChildren: () => import('./views/pages/apps/vip/vip.module').then(m => m.VipModule),
        data: {title: 'VIP'}
      },
      {
        path: 'download-area',
        component: DownloadAreaComponent,
        data: {title: '14 Download Area'}
      },
      {
        path: 'import-history',
        component: ImportHistoryComponent,
        data: {title: 'Import History Log'}
      },
      {
        path: 'admin/export-crm-bonus-action',
        component: ExportCRMBonusActionComponent,
        data: {title: 'Export CRM Campaign Action'}
      },
      {
        path: 'user/export-crm-bonus-action',
        component: ExportCRMBonusActionComponent,
        data: {title: 'Export CRM Campaign Action'}
      },

      {
        path: 'error/403',
        component: ErrorPageComponent,
        data: {
          type: 'error-v6',
          code: 403,
          title: '403... Access forbidden',
          desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
        },
      },
      {
        path: 'error/:type', component: ErrorPageComponent
      },
      {
        path: '**', redirectTo: 'dashboard', pathMatch: 'full'
      },
    ],
    },

  {
    path: '**', redirectTo: 'error/403', pathMatch: 'full'
  },
];
@NgModule({
  imports: [
  RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
