<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Edit Sms Provider</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12 form-group">
                    <label class="control-label">Status</label>
                    <select class="col-12 form-control" formControlName="status">
                      <option *ngFor="let value of statuses; let i = index;" [value]="i">{{ value }}</option>
                    </select>
                </div>
                <div class="col-md-12 form-group">
                    <label class="control-label">Default</label>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" formControlName="default" class="custom-control-input" id="defaultCheck">
                      <label class="custom-control-label" for="defaultCheck">{{ form.value.default ? 'Yes' : 'No' }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
        </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
