<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available"  [formGroup]="form">
        <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">View Message Template</h5>
                    <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    </span>
                </div>
                <div class="modal-body">
                    <table class="col-12 mb-5">
                        <tr class="col-md-12">
                            <th class="col-4">Sections <span class="text-danger">*</span></th>
                            <td class="col-8 form-group">
                                <select class="col-12 form-control sectionSelect" formControlName="section" disabled>
                                    <option [value]="null" disabled> Please Select </option>
                                    <ng-container *ngFor="let value of dropdown.section">
                                        <option [value]="value.id">
                                        {{ value.name }}
                                        </option>
                                    </ng-container>
                                </select>
                            </td>
                        </tr>
                        <tr class="col-md-12 form-group">
                            <th class="col-4">Name <span class="text-danger">*</span></th>
                            <td class="col-8">
                                <input type="text" formControlName="name" class="col-12 form-control" readonly>
                            </td>
                        </tr>
                        <tr class="col-md-12 form-group">
                            <th class="col-4">Code <span class="text-danger">*</span></th>
                            <td class="col-8">
                                <input type="text" [value]="code | uppercase" class="col-12 form-control" readonly>
                            </td>
                        </tr>
                        <tr class="col-md-12 form-group">
                            <th class="col-4">Affiliate <span class="text-danger">*</span></th>
                            <td class="col-8">
                                <input type="text" [value]="data.row.created_by" class="col-12 form-control" readonly>
                            </td>
                        </tr>
                        <tr class="col-md-12">
                            <th class="col-4">Remark</th>
                            <td class="col-8 form-group">
                                <input type="text" [value]="data.messageTemplate.message_template.remark" class="col-12 form-control" readonly>
                            </td>
                        </tr>
                        <tr class="col-md-12" *ngIf="data.mode === 'edit' && (form.value.status == 0 || form.value.status == 1)">
                            <th class="col-4">Status <span class="text-danger">*</span></th>
                            <td class="col-8">
                                <select class="col-12 form-control" formControlName="status">
                                    <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                                        {{ value }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                    </table>
                    <div class="row">
                        <mat-tab-group animationDuration="0ms" class="col-12 form-group" formGroupName="details">
                            <ng-container *ngIf="languages.length > 0">
                                <ng-container *ngFor="let language of languages; let i = index" >
                                    <mat-tab [formGroupName]="language.id" *ngIf="form.value.details[language.id].message">
                                        <ng-template mat-tab-label>
                                            {{language.code}}
                                            </ng-template>
                                            <div class="col-12 form-group">
                                                <label class="control-label">Subject <span class="text-danger">*</span></label>
                                                <input type="text" formControlName="subject" class="col-12 form-control" readonly>
                                            </div>
                                            <div class="col-12 form-group">
                                            <label>Message <span class="text-danger">*</span></label>
                                            <div class="col-12 form-control w-100 h-100" [innerHTML]="form.value.details[language.id].message"  readonly></div>
                                        </div>
                                    </mat-tab>
                                </ng-container>
                            </ng-container>
                        </mat-tab-group>
                    </div>
                </div>
                <div class="modal-footer" [ngClass]="(data.messageTemplate.message_template.status === 3 || data.messageTemplate.message_template.status === 4) && canRejectMessageTemplate ? 'justify-content-between' : ''">
                    <button type="button" *ngIf="canRejectMessageTemplate && (data.messageTemplate.message_template.status === 3 || data.messageTemplate.message_template.status === 4)" class="btn btn-danger" (click)="onUpdate(data.messageTemplate, 'reject')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fa fa-ban'"></i>Reject</button>
                    <button type="button" *ngIf="canApproveMessageTemplate && (data.messageTemplate.message_template.status === 3 || data.messageTemplate.message_template.status === 4)" class="btn btn-success" (click)="onUpdate(data.messageTemplate, 'approve')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Approve</button>
                    <button type="button" *ngIf=" ( (!canRejectMessageTemplate && !canApproveMessageTemplate) && (data.messageTemplate.message_template.status == 3 || data.messageTemplate.message_template.status == 4) ) ||  (data.messageTemplate.message_template.status !== 3 && data.messageTemplate.message_template.status !== 4)" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                    <kt-submit-button *ngIf="data.mode == 'edit' && (data.messageTemplate.message_template.status !== 3 && data.messageTemplate.message_template.status !== 4)" [buttonLoading]="buttonLoading" (confirmed)="onUpdate(data.messageTemplate, 'update')" #focusfield></kt-submit-button>
                </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
