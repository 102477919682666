<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body">
        <div class="kt-form kt-form--label-right  kt-margin-b-10">
          <div class="row align-items-center">
            <div class="col-xl-12 order-2 order-xl-1">
              <mat-tab-group animationDuration="0ms">
                <ng-container>
                  <mat-tab label="General" *ngIf="canViewMessageTemplateList">
                    <app-message-template-general></app-message-template-general>
                  </mat-tab>
                  <mat-tab label="Affiliate" *ngIf="canViewAffiliateMessageTemplateList">
                    <ng-template mat-tab-label>
                      Affiliate <span class="rounded-circle text-light notification-badge badge-header" [ngClass]="affiliatePendingCount == 0 ? 'invisible' : ''">{{ affiliatePendingCount }}</span>
                  </ng-template>
                    <app-message-template-affiliate></app-message-template-affiliate>
                  </mat-tab>
                </ng-container>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <kt-swal-alert [message]="messages$ | async" [icon]="icon" (confirmed)="onCloseDialog()"></kt-swal-alert> -->