import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RaffleEvents } from '@core/models/raffle-events.model';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';

@Injectable()
export class RaffleEventsDataService extends DefaultDataService<RaffleEvents>  {

    pagination: Pagination;
    messages$ = new Subject<any[]>();

    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
        super('RaffleEvents', http, httpUrlGenerator, { root: '' });
    }

    getWithQuery(pageParam: string): Observable<RaffleEvents[]> {
        return this.http.get<ApiResponse>(`/raffleevent${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    getALL(pageParam: string): Observable<any[]> {
        return this.http.get<ApiResponse>(`/raffleevent?paginate=false${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    addRaffleEvent(data: any): Observable<RaffleEvents> {
        return this.http.post<ApiResponse>(`/raffleevent`, data).pipe(
            tap(res => this.messages$.next(res.message)),
            map(res => res.data.rows)
        )
    }

    updateRaffleEvent(id: number, data: any) {
        return this.http.put<ApiResponse>(`/raffleevent/${id}`, data).pipe(
            tap(res => this.messages$.next(res.message))
        )
    }


    private paginationInit(res: any) {
        if (res) {
            this.pagination = res.data.paginations;
        }
    }
}
