import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { WebDisplay } from '@core/models/web-display.model';

@Injectable()
export class WebDisplayDataService extends DefaultDataService<WebDisplay>{


    messages$ = new Subject<any[]>();
  
    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('WebDisplay', http, httpUrlGenerator, { root: '' });
    }

    getAll(): Observable<WebDisplay[]> {
      return this.http.get<ApiResponse>('/footersectionmerchant').pipe(
        tap(),
        map(res => res.data.rows)
      );
    }
  
    getWithQuery(pageParam: string): Observable<WebDisplay[]> {
        return this.http.get<ApiResponse>(`/footersectionmerchant${pageParam}`).pipe(
          tap(),
          map(res => res.data.rows)
        )
    }

    updateDisplay(WebDisplay: WebDisplay){
      return this.http.put<ApiResponse>(`/footersectionmerchant/update`, WebDisplay).pipe(
        tap(res => this.messages$.next(res.message))
      );
    }

    updateStatus(WebDisplay: any){
      return this.http.put<ApiResponse>(`/footersectionmerchant/updatestatus`, WebDisplay).pipe(
        tap(res => this.messages$.next(res.message))
      );
    }

    getById(id: number): Observable<any> {
      return this.http.get<ApiResponse>(`/footersectionmerchant/${id}`).pipe(
        map(res => res.data.rows)
      );
    }

    updateContent(id: number, Content: any){
      return this.http.put<ApiResponse>(`/footersectionmerchant/updatecontent/${id}`,  Content).pipe(
        tap(res => this.messages$.next(res.message))
      );
    }

}