<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right  mb-2">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Username:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                              </div>
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Game Username:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                <input type="text" formControlName="ga_username" placeholder="Search" class="form-control">
                              </div>

                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group date">
                                    <input type="text" class="form-control" placeholder="Search"
                                        (change)="onDateRange($event)" formControlName="defaultDate"
                                        [timePicker]="true" [timePickerSeconds]="true"
                                        [alwaysShowCalendars]="true" [ranges]="ranges"
                                        [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                        [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()"
                                    ngxDaterangepickerMd/>
                                    <span class="input-group-append">
                                        <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                    </span>
                                    </div>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Timezone' }}:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        #timezone
                                        (click)="onChangeTimezone(timezone.selectedItems)"
                                        [form] = 'form'
                                        [dropdownList] = 'dropdown.timezones'
                                        [dropdownSettings] = 'timezoneDropdownSettings'
                                        [formName] = "'timezone'"
                                        [selectionAttributes] = "'timezone'"
                                        [selectedItems] = 'timezoneSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3">
                <div>
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canExportMemberReport" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="button_loading"><i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
            </div>
            <div class="kt-section col-12 row d-flex mb-3">
                Last Bet Log Sync At <span class="font-weight-bold">{{ lastBetLogSyncAt ? lastBetLogSyncAt : '-'}}</span>
            </div>
            <kt-member-report-list [data]="memberReport$" [betLogsDetails]="true" [loading]="loading" [onSearch]="onSearch" [summary]="summary" [form]="form" [pageSize]="pageSize" [page]="page" [maxSize]="maxSize" [pagination]="pagination" [params]="params" [listType]="'member-report'" (submodal)="onOpenDialog($event)"></kt-member-report-list>

        </div>
    </div>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
