import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { LiveChat } from '@core/models/live-chat.model';
import { LiveChatScriptDataService } from './services/live-chat-script-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { TranslateService } from '@ngx-translate/core';
import Swal from "sweetalert2";
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-live-chat-script',
  templateUrl: './live-chat-script.component.html',
  styleUrls: ['./live-chat-script.component.scss']
})
export class LiveChatScriptComponent implements OnInit, OnDestroy {

  @HostListener('window:beforeunload', ['$event'])
  confirmLeavingPageBeforeSaving($event) {

    if(this.checkupdate()){
      $event.preventDefault();
      $event.returnValue = true;
      return $event;
    }
  }

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;

  form: FormGroup;
  formContent: FormGroup;
  messages$ = this.liveChatScriptDataService.messages$;

  loading = false;
  searchBtnLoading = false;
  clearBtnLoading = false;
  dataLength = 0;
  params = '';
  buttonLoading = true;
  initialValues: any;

  mode: any;

  liveChat: any;
  initLiveChat: any;
  availableLiveChat = [];
  isEmptyContent = true;

  liveChat$: Observable<LiveChat[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  displayLocale: any;
  backupPageSize = 30;
  backupPage = 1;
  unsaveStatus = true;

  changedObjects: any;

  sortingConfig = {
    'id': 'desc'
  };

  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    locales: [],
    platform: this.dropdownHttpService.liveChatPlatform,
    perPage: this.dropdownHttpService.perPage,
  };

  iniRequired = true;

  // permissions
  canCreateLiveChatScript: boolean;
  canViewLiveChatScriptDetails: boolean;
  canEditLiveChatScript: boolean;
  canUpdateLiveChatScriptStatus: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private localesSub = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private liveChatScriptDataService: LiveChatScriptDataService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private appPermissionService: AppPermissionService,
  ) { }

  async canDeactivate(): Promise<boolean | Observable<boolean>> {
    // provide component specific logic to decide if component can or can't be deactivated
    if (this.checkupdate()) {
      const result = await Swal.fire({
        title: '<div class="text-center">Unsaved Changes</div>',
        html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Cancel'),
        confirmButtonText: this.translateService.instant('Discard'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          return (true);
        } else if (result.isDenied) {
          return (false);
        }
      });

      return result;
    }
    return true;
  }

  ngOnInit() {
    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.dropdown.locales = res;
      this.formInit();
      this.onSubmit();

      this.initLiveChat = JSON.stringify({ ...this.formContent.value });
    })).subscribe();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateLiveChatScript = appPermissions.create_live_chat_script;
      this.canViewLiveChatScriptDetails = appPermissions.view_live_chat_script_details;
      this.canEditLiveChatScript = appPermissions.edit_live_chat_script;
      this.canUpdateLiveChatScriptStatus = appPermissions.update_live_chat_script_status;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.localesSub.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onClear() {
    this.form.patchValue({
      status: 'all',
      settings_locale_id: 'all',
      live_chat_platform_id: 'all',
    })
  }

  onClearContent() {
    this.formContent.patchValue({
      live_chat_platform_id: null,
      status: 1,
    })

    this.dropdown.locales.forEach((element: any) => {
      this.formContent.patchValue({
        locales: {
          [element.id]: {
            script: null,
            chat_page: null
          }
        }
      });
    });

    this.initLiveChat = JSON.stringify({ ...this.formContent.value });
  }

  reload(clearSearch?: boolean) {
    this.isEmptyContent = true;
    this.mode = '';
    this.onClearContent();
    this.onSubmit(clearSearch);
    this.buttonLoading = false;
  }

  async onSubmitClick(clearSearch?: boolean) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {

      this.isEmptyContent = true;
      this.mode = '';
      this.onClearContent();
      this.onSubmit(clearSearch);
    }
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;

    if (clearSearch) {
      this.onClear();
    }

    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.liveChat$ = this.liveChatScriptDataService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.displayLocale = this.displayLocale ? this.displayLocale : res.length && this.form.value.settings_locale_id != 'all' ? this.dropdown.locales.filter(x => x.id == this.form.value.settings_locale_id)[0] : this.dropdown.locales[0];
            this.page = 1;
            this.pagination = this.liveChatScriptDataService.pagination;
            this.loadingBar.complete();
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.buttonLoading = false;
            this.dataLength = res.length;
            this.availableLiveChat = res;
            this.cdr.detectChanges();
          })
        );
      }),
    ).subscribe();
  }

  onSave() {
    this.buttonLoading = true;

    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.formContent.setErrors({ 'invalid': true });

    const data = {
      id: this.liveChat? this.liveChat.id : null,
      ...this.formContent.value,
    };

    Object.keys(data).forEach((key) => {
      if (data[key] == null || data[key] === '' || data[key] === 'Invalid date') {
        delete data[key];
      }
    });

    switch (this.mode) {
      case 'edit':
        this.subscription = this.liveChatScriptDataService.updateLiveChat(this.liveChat.id, data).pipe(
          tap((res: any) => {
            this.messages$.next(res.message);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.form.enable();
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = this.liveChatScriptDataService.addLiveChat(data).pipe(
          tap((res: any) => {
            this.messages$.next(res.message);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.formContent.setErrors(null);
            this.formContent.enable();
            throw error;
          })
        ).subscribe();
        break;
    }
  }

  changeLocale(locale: any) {
    this.displayLocale = locale;
  }

  clearContent(locale: any) {
    Swal.fire({
      title: 'Delete Content',
      text: 'Are you sure you want to delete contents for ' + locale.code + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.formContent.patchValue({
          locales: {
            [locale.id]: {
              script: null,
              chat_page: null,
            }
          }
        });

        let locales = this.formContent.value.locales;
        this.isEmptyContent = true;

        let itemControls = <FormGroup>this.formContent.controls['locales'];
        Object.keys(locales).forEach((sf) => {
          if (locales[sf]['script'] != null && locales[sf]['chat_page'] != null) {
            this.isEmptyContent = false;
          }

          let itemFormGroup = <FormGroup>itemControls.controls[sf];
          itemFormGroup.controls["chat_page"].clearValidators();
          itemFormGroup.controls["chat_page"].updateValueAndValidity();
          itemFormGroup.controls["script"].clearValidators();
          itemFormGroup.controls["script"].updateValueAndValidity();

        });
      }
      this.cdr.detectChanges();
    });

  }

  onChangeStatus($event, row: any) {
    const data = {
      id: row.id,
      status: $event.target.checked ? 1 : 0,
    };

    this.subscription = this.liveChatScriptDataService.updateStatus(data).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.buttonLoading = false;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        const ele = document.getElementById(row.id) as HTMLInputElement;
        ele.checked = false;
        throw error;
      })
    ).subscribe();
  }

  async onPerPage(size: Event) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.page = 1;
      this.pageSize = +(size.target as HTMLSelectElement).value;
      this.backupPageSize = this.pageSize;
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
    else {
      this.pageSize = this.backupPageSize;
    }
  }

  async onViewPageBy(page = 1, pageSize?: number, params?: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.backupPage = page;
      pageSize = this.pageSize;
      params = this.params ? `&${this.params}` : '';
      this.loading = true;
      this.loadingBar.start();
      return this.liveChat$ = this.liveChatScriptDataService.getWithQuery(`?${this.params}&page=${page}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
        tap(res => {
          this.pagination = this.liveChatScriptDataService.pagination;
          this.loading = false;
          this.dataLength = res.length;
          this.availableLiveChat = res;
          this.loadingBar.complete();
        })
      );
    }
    else {
      this.page = this.backupPage;
    }
  }

  expandRowCreate(mode: any, close?: boolean) {
    let liveChatInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initLiveChat != liveChatInput ? true : false;

    if (close != true && this.changedObjects == false) {
      this.liveChat = null;
      this.changedObjects = false;
      this.initLiveChat = null;

      this.mode = mode;
      this.cdr.detectChanges();
      this.onClearContent();
    } else {
      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {

            this.isEmptyContent = true;
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();

            // Check if reopen needed
            this.checkReopen(mode);
          }
        });
      }
      else {

        this.isEmptyContent = true;
        this.mode = '';
        this.unsaveStatus = true;
        this.onClearContent();
      }
    }

    this.cdr.detectChanges();
  }

  expandRowEdit(mode: any, row?: any, close?: boolean) {
    if (close != true) close = this.liveChat == row ? true : false;
    let liveChatInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initLiveChat && this.initLiveChat != liveChatInput ? true : false;

    if (close != true && this.changedObjects == false) {
      this.liveChat = row;
      this.changedObjects = false;
      this.initLiveChat = null;

      this.mode = mode;

      this.cdr.detectChanges();

      this.formContent.patchValue({
        live_chat_platform_id: this.liveChat.live_chat_platform_id,
        status: this.liveChat.status,
      });

      // reset all locales to null
      this.dropdown.locales.forEach((element: any) => {
        this.formContent.patchValue({
          locales: {
            [element.id]: {
              script: null,
              chat_page: null
            }
          }
        });
      });

      this.liveChat.locales.forEach((element: any) => {
        this.formContent.patchValue({
          locales: {
            [element.settings_locale_id]: {
              script: element.script,
              chat_page: element.chat_page
            }
          }
        });
      });

      this.initLiveChat = JSON.stringify({ ...this.formContent.value });
    } else {
      this.changedObjects = this.initLiveChat != JSON.stringify({ ...this.formContent.value }) ? true : false;
      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {

            this.isEmptyContent = true;
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();

            // Check if reopen needed
            if (this.liveChat == row) {
              this.liveChat = null;
            }
            else {
              this.liveChat = null;
              this.checkReopen(mode, row);
            }
          }
        });
      }
      else {

        this.isEmptyContent = true;
        this.mode = '';
        this.unsaveStatus = true;
        this.liveChat = null;
        this.onClearContent();
      }
    }
  }

  checkReopen(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowEdit(mode, row)
        break;
      case 'create':
        this.expandRowCreate(mode);
        break;
    }
  }

  localeIndex() {
    const index = this.dropdown.locales.findIndex(item => {
      return item.id == this.displayLocale['id'];
    });

    return index;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && formData[key] !== false) {
        fields[key] = (formData[key] === true ? 1 : formData[key]);
      }
    });
    return fields;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  async onSortColumn(property: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      // Reset other columns
      for (const key in this.sortingConfig) {
        if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
          if (key == property) {
            this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
          } else {
            this.sortingConfig[key] = 'desc';
          }
        }
      }

      // User selection
      this.sortingSelection.sort_by = property;

      if (this.sortingSelection.sort_by === property) {
        // Same column
        this.sortingSelection.sort_order = this.sortingConfig[property];
      } else {
        // Switch to other column
        this.sortingConfig[property] = 'asc';
        this.sortingSelection.sort_order = 'asc';
      }
      // Load Data
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
  }

  private formInit() {
    const buildContents = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          settings_locale_id: new FormControl(element.id),
          script: new FormControl(null),
          chat_page: new FormControl(null)
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    this.form = new FormGroup({
      // Filter
      status: new FormControl('all'),
      settings_locale_id: new FormControl('all'),
      live_chat_platform_id: new FormControl('all'),
    });

    this.formContent = new FormGroup({
      // Create dialog
      live_chat_platform_id: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required]),
      locales: new FormGroup(buildContents()),
    });

    this.formContent.valueChanges.subscribe(data => {
      let isContent = false;
      Object.keys(data['locales']).forEach((sf) => {
        if (data['locales'][sf]['script'] != null && data['locales'][sf]['script'] != '' && data['locales'][sf]['chat_page'] != null && data['locales'][sf]['chat_page'] != '') {
          isContent = true;
        }
      })
      this.isEmptyContent = isContent ? false : true;
    })

    this.formContent.markAllAsTouched();
    this.formContent.markAsDirty();
  }

  checkupdate() {
    return this.initLiveChat !== JSON.stringify({ ...this.formContent.value }) ? true : false;
  }

  displayLocaleContent(row: any, type: any) {
    let liveChat = this.availableLiveChat.filter(x => x.id == row.id)[0]['locales'];
    liveChat = liveChat.filter(x => x['locale_name'] == this.displayLocale['code']);

    if (liveChat.length > 0 && liveChat[0][type] !== null) {
      if (type == 'script') {
        return liveChat[0]['script'];
      } else {
        return liveChat[0]['chat_page'];
      }
    }
    else {
      if (type == 'script') {
        return '-';
      } else {
        return '-';
      }
    }
  }

  checkContent(locale: any) {

    let script = this.formContent.value.locales[locale.id].script;
    let chat_page = this.formContent.value.locales[locale.id].chat_page;

    if (script == null || script == '' || chat_page == null || chat_page == '') {
      return false;
    }
    else {
      return true;
    }
  }

  onChangeValidate(event: any , key, field: string){
    let content_type = event.target.value;
    let itemControls = <FormGroup>this.formContent.controls['locales'];
    let itemFormGroup = <FormGroup>itemControls.controls[key];

    if (field == 'script') {
      if (content_type.length != 0) {
        /// add validators if the script is not null
        itemFormGroup.controls["chat_page"].setValidators(Validators.required);
        itemFormGroup.controls["chat_page"].updateValueAndValidity();

      } else {
        itemFormGroup.controls["chat_page"].clearValidators();
        itemFormGroup.controls["chat_page"].updateValueAndValidity();
      }
    } else if (field == 'chat_page') {
      if (content_type.length != 0) {
        /// add validators if the chat_page is not null
        itemFormGroup.controls["script"].setValidators(Validators.required);
        itemFormGroup.controls["script"].updateValueAndValidity();

      } else {
        itemFormGroup.controls["script"].clearValidators();
        itemFormGroup.controls["script"].updateValueAndValidity();
      }
    }
  }

  private onCheckUnsave() {
    return new Promise<void>((resolve, reject) => {
      let liveChatInput = JSON.stringify({ ...this.formContent.value });
      this.changedObjects = this.initLiveChat && this.initLiveChat != liveChatInput ? true : false;

      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {

            this.isEmptyContent = true;
            this.mode = '';
            this.liveChat = null;
            this.unsaveStatus = true;
            this.onClearContent();
            resolve();
          }
          else {
            this.unsaveStatus = false;
            resolve();
          }
        });
      }
      else {
        resolve();
      }
    });
  }
}
