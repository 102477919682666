<!-- Show when loading -->
<ng-container *ngIf="loading">
    <tr>
      <td colspan="30">
        <div class="spinner-wrapper">
          <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
        </div>
      </td>
    </tr>
  </ng-container>
  <!-- Show when finish loading and no data -->
  <ng-container *ngIf="!loading && dataLength == 0">
    <tr>
      <td colspan="30" style="text-align: center;">No data available</td>
    </tr>
  </ng-container>
  <!-- Show when haven't perform any search  -->
  <ng-container *ngIf="!loading && dataLength == undefined">
    <tr>
      <td colspan="30" style="text-align: center;">Press "Search" to load data.</td>
    </tr>
  </ng-container>
