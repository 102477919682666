// Angular / Core
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// Model
import { ApiResponse } from "@core/models/api-response.model";
import { Pagination } from "@core/models/pagination.model";
// Third Party
import { Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable()
export class RouteSettingsDataService {

    pagination: Pagination;
    messages$ = new Subject<any[]>();

    constructor(private http: HttpClient) { }

    getAll(): Observable<[]> {
        return this.http.get<ApiResponse>(`/seo/getRouteSettings`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    getWithQuery(pageParam: string): Observable<[]> {
        return this.http.get<ApiResponse>(`/seo/getRouteSettings${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }


    add(data: any) {
        return this.http.post<ApiResponse>(`/seo/routes`, data).pipe(
            tap(res => this.messages$.next(res.message))
        )
    }

    update(id: number, data: any) {
        return this.http.put<ApiResponse>(`/seo/routes/${id}`, data).pipe(
            tap(res => this.messages$.next(res.message))
        )
    }

    delete(id: number) {
        return this.http.delete<ApiResponse>(`/seo/routes/${id}`).pipe(
            tap(res => this.messages$.next(res.message))
        )
    }

    private paginationInit(res: any) {
        if (res) {
            this.pagination = res.data.paginations;
        }
    }

}
