import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WhatsappBotEntityService } from '../../services/whatsapp-bot-entity.service';
import { WhatsappBot } from '@core/models/whatsapp-bot.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Subscription, forkJoin, of } from 'rxjs';
import { WhatsappBotDataService } from '../../services/whatsapp-bot-data.service';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  templateUrl: './whatsapp-bot-qr.component.html',
  styleUrls: ['./whatsapp-bot-qr.component.scss']
})
export class WhatsappBotQRDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    statuses:  this.dropdownHttpService.statuses
  };
  headerText = "Loading QR...";
  buttonLoading = false;
  loading = false;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  messages$ = this.whatsappBotDataService.messages$;
  refreshStatus: boolean;
  dataLength = 0;
  qrLink = '';


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { botId: number },
    public dialogRef: MatDialogRef<WhatsappBotQRDialogComponent>,
    private whatsappBotService: WhatsappBotEntityService,
    private whatsappBotDataService: WhatsappBotDataService,
    private dropdownHttpService: DropdownHttpService,
    public loadingBar: LoadingBarService,
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event ) {
    this.headerText = "Loading QR...";
    this.dataLength = 0;
    this.dialogRef.close();
  }

  onSave(whatsappBot: WhatsappBot, mode?: string) {
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    this.loading = true;
    const whatsappBot = this.whatsappBotDataService.getQR(this.data.botId);
    this.subscription = whatsappBot
    .subscribe(
      res => {
          this.headerText = res.message[0];
          this.loadingBar.complete();
          this.loading = false;
          this.dataLength = 1;
          if(res.data !== undefined){
            this.qrLink = res.data.rows.QR_link;
          }
      }
    );
    this.form = new FormGroup({
      bot_id: new FormControl(this.data.botId)
    });

  }

}
