import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { MemberReport } from '@core/models/member-report-model';

@Injectable()
export class MemberReportEntityService extends EntityCollectionServiceBase<MemberReport>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('MemberReport', serviceElementsFactory);
  }
}
