import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { GameProvider } from '@core/models/game-provider.model';

@Injectable()
export class GameProviderDataService extends DefaultDataService<GameProvider> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  language$ = new BehaviorSubject([]);

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('GameProviders', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<GameProvider[]> {
    return this.http.get<ApiResponse>('/gameprovider').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<GameProvider[]>{
    return this.http.get<ApiResponse>(`/gameprovider${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getSubCategory(pageParam: string){
    return this.http.get<ApiResponse>(`/gameprovider/subcategory${pageParam}`).pipe(
      map(res => res.data.rows)
    );
  }

  getSelfGameList(pageParam: any){
    return this.http.get<ApiResponse>(`/gameprovider/getallgamelist`, {params: pageParam}).pipe(
      map(res => res.data?.rows ? res.data.rows : res.data)
    );
  }

  getGameGameTag(pageParam: any){
    return this.http.get<ApiResponse>(`/game-game-tags`, {params: pageParam}).pipe(
      map(res => res.data.rows ? res.data.rows : res.data)
    );
  }

  getGameImages(): Observable<GameProvider[]>{
    return this.http.get<ApiResponse>(`/gameimages?paginate=false`).pipe(
      map(res => res.data.rows)
    )
  }

  addGame(gameProvider: any){
    return this.http.post<ApiResponse>(`/gameprovider/addgame`, gameProvider).pipe(
      tap( res => this.messages$.next(res.message))
    );
  }

  updateGame(gameProvider: any){
    return this.http.put<ApiResponse>(`/gameprovider/updategame/${gameProvider.id}`, gameProvider).pipe(
      tap( res => this.messages$.next(res.message))
    );
  }

  updateGameGameTag(params: any){
    return this.http.put<ApiResponse>(`/game-game-tags`, params).pipe(
      tap( res => this.messages$.next(res.message))
    );
  }

  updateGameImages(params: any){
    return this.http.post<ApiResponse>(`/gameimages/`, params).pipe(
      tap( res => res.data.rows)
    );
  }

  resetGame(params: any){
    return this.http.put<ApiResponse>(`/game-game-tags/resetposition`, params).pipe(
      tap( res => this.messages$.next(res.message))
    );
  }

  toggleStatus(id: number, param: any) {
    return this.http.put<ApiResponse>(`/gameprovider/statusUpdate?id=${id}&status=${param.status}`, param).pipe(
      tap(res =>  this.messages$.next(res.message))
    );
  }

  createGameProviderMaintenanceHour(param: any){
    return this.http.post<ApiResponse>(`/gameprovider/maintenance/create`, param).pipe(
      tap( res => this.messages$.next(res.message)),
    );
  }

  updateGameProvider(gameProvider: any): Observable<GameProvider[]> {
    return this.http.put<ApiResponse>(`/gameprovider/${gameProvider.id}`, gameProvider).pipe(
      tap( res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  updateGfGameProvider(gameProvider: any): Observable<GameProvider[]> {
    return this.http.put<ApiResponse>(`/gameprovider/updategamebot/${gameProvider.game_provider_id}`, gameProvider).pipe(
      tap( res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  getGameProvider(gameProviderID: any): Observable<GameProvider[]>{
    return this.http.get<ApiResponse>(`/gameprovider/${gameProviderID}`).pipe(
      map(res => res.data)
    );
  }

  syncGfGameProvider(gameProvider: any): Observable<GameProvider[]> {
    return this.http.put<ApiResponse>(`/gameprovider/gamebotsyncbalance`, gameProvider).pipe(
      tap( res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  syncGameGameTag(params: any){
    return this.http.put<ApiResponse>(`/game-game-tags/sync`, params).pipe(
      tap( res => this.messages$.next(res.message))
    );
  }
  
  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

}
