import { Subscription } from 'rxjs';
import { tap } from "rxjs/operators";
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { ShowDocumentComponent } from '../show-document/show-document.component';
import { MemberBankAccountVerificationDataService } from '../../services/member-bank-account-verification-data.service';
import { MemberBankAccountVerification } from '@core/models/member-bank-account-verification-model';
import { MemberBankAccountVerificationRemarkComponent } from '../member-bank-account-verification-remark/member-bank-account-verification-remark.component';
import Swal from 'sweetalert2';
import { MemberBankAccountHttpService } from '@core/services/member-bank-account-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-member-bank-account-verification-details',
  templateUrl: './member-bank-account-verification-details.component.html',
  styleUrls: ['./member-bank-account-verification-details.component.scss']
})

export class MemberBankAccountVerificationDetailsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = this.memberBankAccountVerificationDataService.messages$;
  private subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  dropdown = {
    statuses:  this.dropdownHttpService.statuses,
  };

  bankaccountDropdownList = [];
  dropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'bank_name',
    labelKey: 'bank_name',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    disabled: true
  };
  selectedBankAccount=[];
  bankaccountLoading = false;

  // permissions
  canEditVerificationDetail: boolean;
  canEditMemberBankAccountVerification: boolean;
  canViewVerificationRemarkHistory: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, memberVerification: MemberBankAccountVerification, newHandler: string },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MemberBankAccountVerificationDetailsComponent>,
    private dropdownHttpService: DropdownHttpService,
    private memberBankAccountVerificationDataService: MemberBankAccountVerificationDataService,
    private memberBankAccountHttpService: MemberBankAccountHttpService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditVerificationDetail = appPermissions.edit_verification_details;
      this.canEditMemberBankAccountVerification = appPermissions.edit_member_verification;
      this.canViewVerificationRemarkHistory = appPermissions.view_verification_remark_history;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
    this.setMemberBankDropdown();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  private setMemberBankDropdown() {
    this.bankaccountLoading = true;
    this.memberBankAccountHttpService.getWithQuery(`?member_account_id=${this.data.memberVerification.member_id}&paginate=false`).subscribe(
      res => {
        res.map(elm => {
          elm.bank_name = elm.bank_code + ' - ' + elm.account_name + (elm.account_number == null ? '' : ' - ' + elm.account_number);
        });

        this.bankaccountDropdownList = res;
        this.selectedBankAccount = [this.bankaccountDropdownList.find(v => v.id === this.data.memberVerification.member_bank_account_id)];
        this.bankaccountLoading = false;
      }
    );
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close(this.data.newHandler != undefined || this.refreshStatus);
  }

  onSave(memberVerification: any, type: string) {
    this.buttonLoading = true;
    const data: { status: number; remarks?: string; reason?: string } = {
      status: (type === 'reject') ? 2 : 1,
    };

    let swalTitle, swalText, remarksHtml = '';
    let remarksRequired = false;
    let reasonRequired = false;
    if (type === 'approve') {
      swalTitle = 'Approve Verification Request';
      swalText = 'Do you want to approve this request?';
      remarksHtml = `<p>Remark (Optional)</p></div><input id="remark-input" class="form-control">`;
    }

    if (type ==='reject') {
      swalTitle = 'Reject Verification Request';
      swalText = 'Do you want to reject this request?';
      remarksRequired = true;
      reasonRequired = true;
      remarksHtml = `
                    <div style="display: flex; align-items: center; margin-bottom: 10px;">
                      <span style="color: red; margin-right: 10px;">Remark</span>
                      <input id="remark-input" class="form-control" style="flex-grow: 1; width: 140px;">
                    </div>
                    <div style="display: flex; align-items: center; margin-bottom: 10px;">
                      <span style="color: red; margin-right: 10px;">Reason</span>
                      <input id="reason-input" class="form-control" style="flex-grow: 1; width: 140px;">
                    </div>
                  `;
    }

    Swal.fire({
      title: swalTitle,
      html: `
        <div style="text-align: center;">
          ${remarksHtml}
          <p style="margin-top: 20px;">${swalText}</p>
          <div id="custom-validation-message" style="color: red; text-align: left; margin-top: 10px;"></div>
        </div>
      `,
      icon: 'info',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
      preConfirm: () => {
        return new Promise((resolve) => {
          const inputValue = (document.getElementById('remark-input') as HTMLInputElement)?.value ?? '';
          const inputValue2 = (document.getElementById('reason-input') as HTMLInputElement)?.value ?? '';
    
          const validationMessageContainer = document.getElementById('custom-validation-message');
          if (validationMessageContainer) {
            validationMessageContainer.innerHTML = '';
          }
    
          const messages = [];
          if (inputValue.trim() === '' && remarksRequired) {
            messages.push('<div class="swal2-validation-message" id="swal2-validation-message" style="display: flex;">Remarks is required.</div>');
          }
          if (inputValue2.trim() === '' && reasonRequired) {
            messages.push('<div class="swal2-validation-message" id="swal2-validation-message" style="display: flex;">Reason is required.</div>');
          }
    
          if (messages.length > 0) {
            if (validationMessageContainer) {
              validationMessageContainer.innerHTML = messages.join('');
            }
            resolve(false); // Returning false to prevent modal from closing
          } else {
            data.remarks = inputValue;
            data.reason = inputValue2;
            resolve(true); // Returning true to proceed with modal closing
          }
        });
      }
    }).then((resp) => {
      if (resp.value) { // Yes
        Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
        this.memberBankAccountVerificationDataService.updateVerification(memberVerification.id, data).pipe(
          tap((row: any) => {
            if ( row.success == false ) {
              Swal.fire({
                icon: 'error',
                title: row.message,
              });
            } else {
              this.messages$.next([...row.message]);
              this.refreshStatus = true;
            }
            this.buttonLoading = false;
          }),
        ).subscribe();
        this.buttonLoading = false;
      } else {  // No
        this.buttonLoading = false;
      }
    });
  }
  
  onOpenDialog(mode: any, memberVerificationRow: any) {
    if (mode == 'show-document') {
      this.openDialogBy(ShowDocumentComponent, { mode: mode, documents: memberVerificationRow.documents });
    } else if (mode == 'show-remarks') {
      this.openDialogBy(MemberBankAccountVerificationRemarkComponent, { mode: mode, memberVerification: memberVerificationRow }, '1200px');
    }
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, documents?: any, memberVerification?: any }, dimension?: string) {
    const dialogRef = this.dialog.open(componentRef, {
      width: dimension ? dimension : '800px',
      data: data.mode === 'show-document' ? {documents: data.documents} : data.mode === 'show-remarks' ? {memberVerification: data.memberVerification} : {}
    });
  }
}
