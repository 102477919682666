<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"> {{ !data ? 'Update Rebates' : 'Rebate Setting Details (Updated)' }}</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <form class="row align-items-center mb-2" [formGroup]="form" *ngIf="!data">
                  <div class="col-md-6 form-group">
                    <label>Date<span class="text-danger">*</span></label>
                    <div class="input-group date">
                        <input type="text" class="form-control" (change)="onDateRange($event, 'secondary')"
                            formControlName="defaultDate" [timePicker]="false" [timePickerSeconds]="false"
                            [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true"
                            [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [opens]="'right'"
                            [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd [ngClass]="{'is-invalid': checkValidation && form.controls.start_datetime.errors }" />
                        <span class="input-group-append">
                            <span class="input-group-text pointer-calendar" (click)="onClearDate('secondary')">X</span>
                        </span>
                    </div>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>Rebate Setting <span class="text-danger">*</span><i *ngIf="dropdownLoading" class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                    <select formControlName="rebate_setting_id" class="form-control" (change)="onRebateSettingChange()" [attr.disabled]="dropdownLoading ? 'disabled' : null">
                      <option value="null" [disabled]="true">Please Select</option>
                      <option *ngFor="let value of rebateSettings" [ngValue]="value.id">{{value.id}} - {{value.name}}</option>
                    </select>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>Percentage<span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="percentage" [ngClass]="{'is-invalid': checkValidation && form.controls.percentage.errors }" [readonly]="buttonLoading" />
                  </div>
                  <div class="col-md-6 form-group">
                      <label>Currencies <span class="text-danger">*</span><i *ngIf="dropdownLoading" class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                      <kt-dropdown-wo-lazyload
                          [form] = 'form'
                          [dropdownList] = 'currenciesDropdownList'
                          [dropdownSettings] = 'currencyDropdownSettings'
                          [formName] = "'currency_id'"
                          [selectionAttributes] = "'id'"
                          [selectedItems] = 'currenciesSelectedItems'
                          (selectedItemsChanged)="onSelectCurrency($event)">
                    </kt-dropdown-wo-lazyload>
                  </div>
                  <div class="col-md-6 form-group">
                      <label>Category <span class="text-danger">*</span><i *ngIf="dropdownLoading" class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                      <kt-dropdown-wo-lazyload
                          [form] = 'form'
                          [dropdownList] = 'categoriesDropdownList'
                          [dropdownSettings] = 'categoriesDropdownSettings'
                          [formName] = "'category_id'"
                          [selectionAttributes] = "'id'"
                          [selectedItems] = 'categoriesSelectedItems'
                          (selectedItemsChanged)="getSelectedCategories($event)">
                      </kt-dropdown-wo-lazyload>
                  </div>
                  <div class="col-md-6 form-group">
                      <label>Game Provider <span class="text-danger">*</span><i *ngIf="dropdownLoading" class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                      <ng-container *ngIf="!gameProvidersDropdownLoading; else elseTemplate">
                          <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'gameProvidersDropdownList'
                            [dropdownSettings] = 'gameProviderDropdownSettings'
                            [formName] = "'provider_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] = 'gameProvidersSelectedItems'
                            (selectedItemsChanged)="gameProvidersSelectedItems = $event">
                          </kt-dropdown-wo-lazyload>
                        </ng-container>
                        <ng-template #elseTemplate>
                          <label class="control-label"><i class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                          <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = '[]'
                            [dropdownSettings] = 'gameProviderDropdownSettings'
                            [formName] = "'provider_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] = 'gameProvidersSelectedItems'
                            (selectedItemsChanged)="gameProvidersSelectedItems = $event">
                          </kt-dropdown-wo-lazyload>
                        </ng-template>
                  </div>
                  <div class="col-md-6 form-group">
                      <label>Member Group <span class="text-danger">*</span><i *ngIf="dropdownLoading" class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                      <kt-dropdown-wo-lazyload
                          [form] = 'form'
                          [dropdownList] = 'filteredMemberGroupDropdownList'
                          [dropdownSettings] = 'memberGroupDropdownSettings'
                          [formName] = "'member_group_id'"
                          [selectionAttributes] = "'id'"
                          [selectedItems] = 'memberGroupsSelectedItems'
                          (selectedItemsChanged)="onSelectMemberGroup($event)">
                      </kt-dropdown-wo-lazyload>
                  </div>
                  <div class="col-md-6 form-group">
                      <label>Members <i *ngIf="dropdownLoading" class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                      <kt-member-dropdown class="dropdown-maxheight"
                          [form]="form"
                          [dropdownSettings]='memberAccountDropdownSettings'
                          [formName]="'member_account_id'"
                          [isFilterByCurrency]="isFilterByCurrency"
                          [currencyId]="currencyId"
                          [isFilterByMembergroupId]="isFilterByMembergroupId"
                          [membergroupId]="membergroupId"
                          [selectionAttributes]="'id'">
                      </kt-member-dropdown>
                  </div>    
                </form>
                <form class="row align-items-center mb-2" [formGroup]="form" *ngIf="data">
                    <div class="col-md-6 form-group">
                        <label>{{ 'Date' | translate }} </label>
                        <input type="text" class="form-control" value="{{ start_datetime +' - '+end_datetime }}" readonly />
                    </div>
                    <div class="col-md-6 form-group">
                      <label>Rebate Setting <i *ngIf="dropdownLoading" class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                      <select formControlName="rebate_setting_id" class="form-control" (change)="onRebateSettingChange()" [attr.disabled]="true">
                        <option value="null" [disabled]="true">Please Select</option>
                        <option *ngFor="let value of rebateSettings" [disabled]="true" [ngValue]="value.id">{{value.id}} - {{value.name}}</option>
                      </select>
                    </div>
                    <div class="col-md-6 form-group">
                      <label>{{ 'Percentage' | translate }} </label>
                      <input type="text" class="form-control" formControlName="percentage" readonly />
                  </div>
                    <div class="col-md-6 form-group">
                        <label>Currencies <i *ngIf="dropdownLoading" class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                        <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'currenciesDropdownList'
                            [dropdownSettings] = 'currencyDropdownSettings'
                            [formName] = "'currency_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] = 'currenciesSelectedItems'
                            (selectedItemsChanged)="onSelectCurrency($event)">
                      </kt-dropdown-wo-lazyload>
                    </div>
                    <div class="col-md-6 form-group">
                        <label>Category <i *ngIf="dropdownLoading" class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                        <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'categoriesDropdownList'
                            [dropdownSettings] = 'categoriesDropdownSettings'
                            [formName] = "'category_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] = 'categoriesSelectedItems'
                            (selectedItemsChanged)="getSelectedCategories($event)">
                        </kt-dropdown-wo-lazyload>
                    </div>
                    <div class="col-md-6 form-group">
                        <label>Game Provider <i *ngIf="dropdownLoading" class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                        <ng-container *ngIf="!gameProvidersDropdownLoading; else elseTemplate">
                            <kt-dropdown-wo-lazyload
                              [form] = 'form'
                              [dropdownList] = 'gameProvidersDropdownList'
                              [dropdownSettings] = 'gameProviderDropdownSettings'
                              [formName] = "'provider_id'"
                              [selectionAttributes] = "'id'"
                              [selectedItems] = 'gameProvidersSelectedItems'
                              (selectedItemsChanged)="gameProvidersSelectedItems = $event">
                            </kt-dropdown-wo-lazyload>
                          </ng-container>
                          <ng-template #elseTemplate>
                            <label class="control-label"><i class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                            <kt-dropdown-wo-lazyload
                              [form] = 'form'
                              [dropdownList] = '[]'
                              [dropdownSettings] = 'gameProviderDropdownSettings'
                              [formName] = "'provider_id'"
                              [selectionAttributes] = "'id'"
                              [selectedItems] = 'gameProvidersSelectedItems'
                              (selectedItemsChanged)="gameProvidersSelectedItems = $event">
                            </kt-dropdown-wo-lazyload>
                          </ng-template>
                    </div>
                    <div class="col-md-6 form-group">
                        <label>Member Group <i *ngIf="dropdownLoading" class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                        <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'filteredMemberGroupDropdownList'
                            [dropdownSettings] = 'memberGroupDropdownSettings'
                            [formName] = "'member_group_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] = 'memberGroupsSelectedItems'
                            (selectedItemsChanged)="onSelectMemberGroup($event)">
                        </kt-dropdown-wo-lazyload>
                    </div>
                    <div class="col-md-6 form-group">
                        <label>Members <i *ngIf="dropdownLoading" class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                        <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'member_list'
                            [dropdownSettings] = 'dropdownSettings'
                            [formName] = "'member_account_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] = 'member_list'
                        >
                        </kt-dropdown-wo-lazyload>
                    </div>
                </form>

                <div class="row" *ngIf="blacklistedGameProvidersDataShow && blacklistedGameProvidersDataShow.length > 0">
                    <h5 class="modal-title col-12">Game Provider Blacklist</h5>
                    <div class="kt-section col-12">
                      <div class="kt-section__content">
                        <div class="table-responsive col-12">
                          <!-- Table -->
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th class="col-4">Game Provider</th>
                                <th class="col-8">Sub Category</th>
                              </tr>
                            </thead>
                            <tbody *ngIf="blacklistedGameProvidersDataShow as rows">
                              <tr *ngFor="let row of rows">
                                <td>{{ row.gameProviders_name }}</td>
                                <td>{{ row.sub_category_name }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" *ngIf="!viewOnly">
                <div class="pl-0 mr-0 col">
                    <button type="button" class="btn btn-dark" *ngIf="gameProvidersSelectedItems.length >= 1" (click)="onBlacklistDialog(gameProvidersSelectedItems)" ><i class="fa fa-plus" aria-hidden="true"></i>{{ 'BlackList' | translate }}</button>
                </div>
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>{{ 'Close' | translate }}</button>
                <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
