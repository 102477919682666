import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from "rxjs";
import * as moment from 'moment-timezone';
import { Pagination } from '@core/models/pagination.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { tap } from 'rxjs/operators';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TelemarketerSettingsDataService } from '../../services/telemarketer-settings-data.service';

@Component({
  selector: 'kt-telemarketer-settings-log',
  templateUrl: './telemarketer-settings-log.component.html',
  styleUrls: ['./telemarketer-settings-log.component.scss']
})
export class TelemarketerSettingsLogComponent implements OnInit {

  telemarketerSettingLog$: Observable<[]>;
  pagination: Pagination;
  maxSize = 5;
  pageSize = 30;
  page = 1;
  params = '';

  loading = false;
  buttonLoading = false;
  dataLength: number;

  dropdown = {
    perPage: this.dropdownHttpService.perPage,
  };

  sortingConfig = {
    'validity_day': 'desc',
    'follow_up_day': 'desc',
    'ftd_target_day': 'desc',
    'target_deposit_amount': 'desc',
    'inactive_period': 'desc',
    'last_updated_at': 'desc'
  }
  sortingSelection = {
    'sort_by': 'last_updated_at',
    'sort_order': 'desc',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { telemarketerSettings: any },
    public dialogRef: MatDialogRef<TelemarketerSettingsLogComponent>,
    public dialog: MatDialog,
    public loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private telemarketerSettingsDataService: TelemarketerSettingsDataService,
  ) { }

  ngOnInit() {
    this.onViewPageBy();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }
  
  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.telemarketerSettingLog$ = this.telemarketerSettingsDataService.getLogById(this.data.telemarketerSettings.id, `?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.dataLength = res.length;
        this.loading = false;
        this.pagination = this.telemarketerSettingsDataService.pagination;
        this.loadingBar.complete();
      })
    );
    // return this.telemarketerSettingLog$ = this.telemarketerSettingsDataService.getLogById(this.data.telemarketerSettings.id);

  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }
}