import { MiniGamesComponent } from './mini-games/mini-games.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GameProvidersComponent } from './game-providers/game-providers.component';
import { MaintenanceHoursComponent } from './maintenance-hours/maintenance-hours.component';
import { MaintenanceHoursEditDialogComponent } from './maintenance-hours/dialogs/maintenance-hours-edit.component';
import { MiniGamesEditDialogComponent } from './mini-games/dialogs/mini-games-edit.component';
import { GameProviderPositionComponent } from './game-provider-position/game-provider-position.component';
import { GameProviderPositionEditDialogComponent } from './game-provider-position/dialogs/game-provider-position-edit.component';
import { GameTagsComponent } from './game-tags/game-tags.component';
import { GameTagsEditDialogComponent } from './game-tags/dialogs/game-tags-edit.component';
import { AssignGamesComponent } from './assign-games/assign-games.component';
import { AssignGamesEditDialogComponent } from './assign-games/dialogs/assign-games-edit.component';
import { UnSaveChangesGuard } from '@core/guards/unsavechanges.guard';
import { QuickLinkSettingsComponent } from './game-providers/dialogs/quick-link-settings/quick-link-settings.component';

const routes: Routes = [
  {
    path: 'providers',
    component: GameProvidersComponent
  },
  {
    path: 'maintenance-hours',
    component: MaintenanceHoursComponent
  },
  {
    path: 'game-tags',
    component: GameTagsComponent
  },
  {
    path: 'category/:category/provider/:provider',
    component: MiniGamesComponent
  },
  {
    path: 'provider-position',
    component: GameProviderPositionComponent
  },
  {
    path: 'assign-games/:gameTagId',
    component: AssignGamesComponent,
    canDeactivate: [UnSaveChangesGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  entryComponents: [
    MaintenanceHoursEditDialogComponent,
    MiniGamesEditDialogComponent,
    GameProviderPositionEditDialogComponent,
    GameTagsEditDialogComponent,
    AssignGamesEditDialogComponent,
    QuickLinkSettingsComponent,
  ]
})
export class PlatformRoutingModule { }
