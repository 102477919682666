<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header" style="display: inline">
            <div>
                <h5 class="modal-title" style="display: inline">Total Member Registration By Affiliate - {{ totalMember }} </h5>
            </div>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div class="col-12">
                <div class="kt-form kt-form--label-right mb-2">
                    <div class="row align-items-center">
                        <div class="col-xl-12 order-2 order-xl-1 pr-0">
                            <form class="row align-items-center" [formGroup]="form">
                                <div class="col-12 row mb-2 pr-0">
                                    <div class="col-md-2 kt-form__label col-form-label">
                                        <label>Account Type:</label>
                                    </div>
                                    <div class="col-md-4 kt-form__control">
                                        <select formControlName="account_type_condition" class="form-control" >
                                            <option value="And">AND Condition</option>
                                            <option value="Or">OR Condition</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6 kt-form__control">
                                        <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
                                            [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
                                            [formName]="'account_type'" [selectionAttributes]="'id'"
                                            [selectedItems]='accountTypeSelectedItems'>
                                        </kt-dropdown-wo-lazyload>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="kt-section col-12 row d-flex justify-content-between mb-3">
                    <div class="dropdown dropdown-inline">
                        <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit(false, this.page, this.pageSize, this.params)"></kt-search-button>
                        <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                    </div>
                </div>
                <div class="kt-section mb-0">
                    <div class="kt-section__content">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover table-striped">
                                <thead>
                                        <tr>
                                            <ng-container >
                                                <th class="text-center">Register Date</th>
                                                <th class="text-center">Currency</th>
                                                <th class="text-center">Member Login</th>
                                                <th class="text-center">Member Name</th>
                                            </ng-container>
                                        </tr>
                                </thead>
                                <tbody *ngIf="affiliateMember$?.length > 0">
                                    <tr *ngFor="let row of affiliateMember$;">
                                        <ng-container>
                                            <td class="text-center align-middle">{{ row.register_date | timezoneDate: 'YYYY-MM-DD HH:mm'}}</td>
                                            <td class="text-center align-middle">{{ row.currency_code }}</td>
                                            <td class="text-center align-middle">{{ row.username }}</td>
                                            <td class="text-center align-middle">{{ row.name }}</td>
                                        </ng-container>
                                    </tr>
                                    <kt-fallback-row [collection]="affiliateMember$"></kt-fallback-row>
                                </tbody>
                                <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                            </table>
                        </div>
                        <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                            <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="getAffiliateMemberData(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        </div>
    </div>
</div>