<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ data.mode === 'edit' ? 'Member Details' : 'Create Member' }} </h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
            <div>
                <div class="row">
                    <div class="col-md-6">

                      <div class="col-12 form-group row">
                        <label class="col-4 col-form-label"> Username <span class="text-danger">*</span></label>
                        <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" class="col-8 form-control datetime">
                      </div>

                      <div class="col-12 form-group row">
                        <label class="col-4 col-form-label">Currency <span class="text-danger">*</span></label>
                        <select formControlName="currency_id" class="col-8 form-control">
                          <option [value]="null" [disabled]="true">Please Select</option>
                          <option *ngFor="let value of dropdown.currencies" [value]="value.id">{{ value.name }}</option>
                        </select>
                      </div>

                      <div class="col-12 form-group row">
                        <label class="col-4 col-form-label">Mobile <span class="text-danger">*</span></label>
                        <div class="col-8 pl-0 pr-0">
                          <div class="input-group">
                            <input type="text" formControlName="mobile" class="form-control">
                            <div class="input-group-append" *ngIf="data.mode === 'edit'">
                              <span class="input-group-text">
                                <label class="checkbox checkbox-inline checkbox-primary mb-0">
                                  <input matTooltip="Verified" type="checkbox" (change)="onCheckbox($event, 'mobile_status')" [checked]="data.member && data.member.mobile_status === 1 ? true : false" formControlName="mobile_status">
                                  <span></span>
                                </label>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 form-group row">
                        <label class="col-4 col-form-label">Date of Birth</label>
                        <div class="col-8 input-group date" [owlDateTimeTrigger]="dateStartRef" >
                          <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack" [ngModelOptions]="{standalone: true}">
                          <input type="text" formControlName="date_of_birth" class="form-control">
                          <span class="input-group-append">
                              <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                          </span>
                          <owl-date-time #dateStartRef [disabled]="!modify_dob"></owl-date-time>
                        </div>
                      </div>

                      <div class="col-12 form-group row">
                        <label class="col-4 col-form-label">Agent</label>
                        <div class="col-8" style="padding:0px!important; margin:0px!important">
                          <kt-member-dropdown [form]="form"
                          [isAgent]="true" [dropdownSettings]='dropdownSettings' [formName]="'agent_id'"
                          [selectionAttributes]="'id'" [selectedItems]="selectedItems" [agentsAgentOnly]="true"></kt-member-dropdown>
                        </div>
                      </div>

                      <ng-container *ngIf="sqsEnable; else elseTemplate">
                        <div class="col-12 form-group row" *ngIf="data.mode === 'edit'">
                          <label class="col-4 col-form-label">Label and Remarks</label>
                          <div class="col-8" style="padding:0px!important; margin:0px!important">
                            <button type="button" class="btn btn-secondary" (click)="onOpenDialog()">Add</button>
                          </div>
                      </div>
                      </ng-container>
                      <ng-template #elseTemplate>
                        <div class="col-12 form-group row">
                          <label class="col-4 col-form-label">Remarks</label>
                          <input type="text" formControlName="remarks" class="col-8 form-control">
                        </div>
                      </ng-template>      
                    </div>

                    <div class="col-md-6">
                        <div class="col-12 form-group row">
                          <label class="col-4 col-form-label">Name <span class="text-danger">*</span></label>
                          <input type="text" formControlName="name"  class="col-8 form-control">
                        </div>
                        <div class="col-12 form-group row">
                          <label class="col-4 col-form-label">Group <span class="text-danger">*</span></label>
                          <select formControlName="member_group_id" class="col-8 form-control">
                            <option [value]="null" [disabled]="true">Please Select</option>
                            <option [value]="value.id" *ngFor="let value of dropdown.groups | async">
                                {{ value.name }}
                            </option>
                          </select>
                        </div>

                        <div class="col-12 form-group row">
                            <label class="col-4 col-form-label">Email</label>
                            <div class="col-8 pl-0 pr-0">
                              <div class="input-group">
                                <input type="text" formControlName="email" class="form-control">
                                <div class="input-group-append" *ngIf="data.mode === 'edit'">
                                  <span class="input-group-text">
                                    <label class="checkbox checkbox-inline checkbox-primary mb-0">
                                      <input matTooltip="Verified" type="checkbox" (change)="onCheckbox($event, 'email_status')" [checked]="data.member && data.member.email_status === 1 ? true : false" formControlName="email_status">
                                      <span></span>
                                    </label>
                                  </span>
                                </div>
                              </div>
                            </div>
                        </div>

                        <div class="col-12 form-group row" *ngIf="data.mode === 'edit'">
                          <label class="col-4 col-form-label">Status</label>
                          <select class="form-control col-8" formControlName="status">
                            <ng-container *ngFor="let value of dropdown.statuses; let i = index;">
                              <option [value]="i">{{ value }}</option>
                            </ng-container>
                          </select>
                        </div>

                        <div class="col-12 form-group row">
                          <label class="col-4 col-form-label">Referrer</label>
                          <input type="text" formControlName="referrer" class="col-8 form-control" [readonly]="data.member && (data.member.last_deposit !== null || data.member.refer_by_id !== null) ? 'readonly' : ''">
                        </div>

                        <div class="col-12 form-group row" *ngIf="data.mode === 'edit' && !sqsEnable">
                          <label class="col-4 col-form-label">Suspicious</label>
                          <div class="col-8">
                            <input type="checkbox" formControlName="suspicious" (change)="onCheckbox($event, 'suspicious')" [checked]="data.member.suspicious === 1 ? true : false" style="margin: 10px 0 0 -10px !important;">
                          </div>
                        </div>

                        <div class="col-12 form-group row" *ngIf="data.mode === 'edit'">
                          <label class="col-4 col-form-label">SMS Subscription</label>
                          <div class="col-8">
                            <input type="checkbox" formControlName="sms_sub" (change)="onSmsSubscription($event)" [checked]="data.member.sms_sub === 1 ? true : false" style="margin: 10px 0 0 -10px !important;">
                          </div>
                        </div>

                        <div class="col-12 form-group row" *ngIf="canCreateDummyAccount || canEditDummyAccount">
                          <label class="col-4 col-form-label">Dummy<i class="fa fa-info-circle ml-2" placement="top" [ngbTooltip]="linkToolTip" tooltipClass="tooltip-class"></i></label>
                          <div class="col-8">
                            <input type="checkbox" formControlName="dummy" (change)="onCheckbox($event, 'dummy')" [checked]="data.member && data.member.dummy === 1 ? true : false" [attr.disabled]="disabledDummy ? 'disabled' : null " style="margin: 10px 0 0 -10px !important;">
                          </div>
                          <ng-template #linkToolTip>
                            <div class="text-left">
                              Checked to create the member as a test account.<br><br>
                              Test accounts are often used for trial purposes or to simulate user experiences without affecting real data. 
                            </div>
                          </ng-template>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="data.member && data.mode === 'edit'">
                  <hr>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="col-12 form-group row">
                       <label class="col-4">Created At: </label>
                       <span class="col-8 mt-10"> {{ data.member.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }} </span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="col-12 form-group row">
                        <label class="col-4">Last Login: </label>
                        <span class="col-8 mt-10"> {{ data.member.last_login ? (data.member.last_login | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }} </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="col-12 form-group row">
                       <label class="col-4">Last <br />Login IP: </label>
                       <span class="col-8 mt-10"> {{ data.member.last_login_ip ? data.member.last_login_ip : '-'}} </span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="col-12 form-group row">
                       <label class="col-4">Last Login <br />Location: </label>
                       <span class="col-8 mt-10"> {{ data.member.last_login_location ? data.member.last_login_location : '-' }} </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="col-12 form-group row">
                       <label class="col-4">Last Deposit: </label>
                       <span class="col-8 mt-10"> {{ data.member.last_deposit ? (data.member.last_deposit | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }} </span>
                      </div>
                    </div>
                  </div>
                </ng-container>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button *ngIf="data.mode == 'edit' ? (canEditMemberDetailsRestricted || canEditMemberDetails) : canCreateNewMember" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.member, data.mode)"></kt-submit-button>
        </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
