<!-- BEGIN: Left Aside -->
<div class="kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
  <!-- begin:: Brand -->
  <kt-brand></kt-brand>
  <!-- end:: Brand -->

  <!-- BEGIN: Aside Menu -->
  <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
    <div #asideMenuRef ktMenu [options]="menuOptions" [ngStyle]="{'position': 'relative', 'overflow-y': 'scroll'}"
      id="kt_aside_menu" class="kt-aside-menu mh-for-sidebar custom-scroll" (mouseenter)="mouseEnter($event)"
      (mouseleave)="mouseLeave($event)" [ngClass]="htmlClassService.getClasses('aside_menu', true)">
      <ul class="kt-menu__nav" [ngClass]="htmlClassService.getClasses('aside_menu_nav', true)">
        <div id="accordion">
          <!-- Dashboard -->
          <div *ngIf="checkDashboardPermissions()" class="card menu-container">
            <div class="card-header" routerLinkActive="current">
              <div class="col-2">
                <i class="fas fa-poll"></i>
              </div>

              <div class="col-10">
                <a routerLink="/dashboard">Dashboard</a>
              </div>

            </div>
          </div>
          <!-- Loop Navigation From API Return -->
          <ng-container *ngFor="let item of navigations | keyvalue: returnZero">
            <div class="card">
              <div class="card-header" [id]="'heading_' + item.key" data-toggle="collapse" [attr.data-target]="'#' + item.key" [attr.aria-expanded]="'false'" [attr.aria-controls]="item.key" [ngClass]="checkRouterLink(item.value) ? 'activeTab': 'collapsed'">
                <h5>
                  <div class="col-2">
                    <i [ngClass]="item.value.icon"></i>
                  </div>

                  <div class="col-10">
                    {{ item.key }}
                    <i class="kt-menu__ver-arrow la la-angle-right"></i>
                  </div>
                </h5>
              </div>
              <div [id]="item.key" class="collapse submenu-container" [attr.aria-labelledby]="'heading_' + item.key" data-parent="#accordion" [ngClass]="checkRouterLink(item.value) ? 'show': ''">
                <div id="accordionSub">
                  <!-- <div class="col-10"> -->
                  <ng-container *ngFor="let submenu of item.value.sub_menu">
                    <div class="card">
                      <ng-container *ngIf="submenu.sub_menu else textTemplate">
                        <!-- Second Layer Submenu -->
                        <div class="card-header" [id]="'subheading_' + submenu.name" data-toggle="collapse" [attr.data-target]="'#menucollapse' + submenu.name" [attr.aria-expanded]="'false'" [attr.aria-controls]="'menucollapse' + submenu.name" [ngClass]="checkRouterLink(submenu) ? 'activeTab': 'collapsed'">
                          <h5>
                            <div class="col-2">
                              <i [ngClass]="submenu.icon"></i>
                            </div>
                            <div class="col-10">
                              {{ submenu.name }}
                              <i class="kt-menu__ver-arrow la la-angle-right"></i>
                            </div>
                          </h5>
                        </div>
                        <div [id]="'menucollapse' + submenu.name" class="collapse submenu-container" [attr.aria-labelledby]="'subheading_' + submenu.name" data-parent="#accordionSub" [ngClass]="checkRouterLink(submenu) ? 'show': ''">
                          <ng-container *ngFor="let innersubmenu of submenu.sub_menu">
                            <div class="second-submenu" routerLinkActive="current">
                              <i></i>
                              <a [routerLink]="innersubmenu.link">{{innersubmenu.name}}</a>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                      <ng-template #textTemplate>
                        <div class="card-header" routerLinkActive="current">
                          <div class="col-2">
                            <i [ngClass]="submenu.icon"></i>
                          </div>
                          <div class="col-10">
                            <a [routerLink]="submenu.link">{{submenu.name}}</a>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </ng-container>
                  <!-- </div> -->

                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ul>
    </div>

    <p class="version-code m-0 pt-2">V {{ version }}</p>
  </div>
  <!-- END: Aside Menu -->
</div>
<!-- END: Left Aside -->
