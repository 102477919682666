import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MerchantBankStatus } from '@core/enums/merchant-bank.enum';
import { Group } from '@core/models/group.model';
import { MerchantBank } from '@core/models/merchant-bank.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { UploadHttpService } from "@core/services/upload-http.service";
import { Lightbox } from "ngx-lightbox";
import { forkJoin, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MerchantBankDataService } from '../../services/merchant-bank-data.service';
import { MerchantBankEntityService } from '../../services/merchant-bank-entity.service';
import { MatSelect } from '@angular/material/select';
import { MerchantBankTransactionConfigEditComponent } from '../merchant-bank-transaction-config-edit/merchant-bank-transaction-config-edit.component';
import { MerchantBankComponentType } from 'typings';

@Component({
  selector: 'kt-merchant-bank-new-edit-dialog',
  templateUrl: "./merchant-bank-new-edit.component.html",
  styleUrls: ["./merchant-bank-new-edit.component.scss"],
})
export class MerchantBankNewEditComponent implements OnInit, OnDestroy {
  form: FormGroup;
  @ViewChild('fileInput') fileInput: any;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    merchantBanks: [],
    groups: this.dropdownHttpService.groups,
    types: this.dropdownHttpService.merchantBanksType,
    methods: [{ id: 1, name: 'Bank Transfer' }, { id: 2, name: 'Payment Gateways' }, { id: 3, name: 'Ewallet' }],
    paymentGateway: [],

    // note that the situation for crypto is slightly special;
    // 1. The id need to set to 4 to tally with METHOD_CRYPTO constant in MerchantBank.php on backend side
    // 2. However, name needs to be 'Payment Gateways' not 'Crypto' due to BA requirement
    cryptoMethods: [{ id: 4, name: 'Payment Gateways' }],
  };

  // All crypto gateways' code will be hardcoded as of current time
  cryptoGatewayCodes = ['C2P'];

  messages$ = this.merchantBankDataService.messages$;
  isSelectedCurrency = false;
  errors: any = [];
  minMaxValid = true;
  buttonLoading = false;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  refreshStatus: boolean;

  selectedCurrencyCode = "";
  selectedBankCode = "";
  selectedBankType: number;

  memberGroupDropdownList = [];
  memberGroupDropdownSettings = {
    text: "Please Select",
    enableSearchFilter: true,
    singleSelection: false,
    classes: "dropdown",
    primaryKey: "id",
    labelKey: "name",
    noDataLabel: "",
    showCheckbox: false,
  };
  paymentGatewaysDropdownSettings = {
    text: "Please Select",
    enableSearchFilter: true,
    singleSelection: false,
    classes: "dropdown",
    primaryKey: "id",
    labelKey: "name",
    noDataLabel: "",
    showCheckbox: false,
    disabled: false
  };
  memberGroupSelectedItems = [];
  paymentGatewaysSelectedItems = [];
  object = Object;
  status = MerchantBankStatus;

  imagePreview = [];
  isImageUploading = false;

  formControlMapping = {
    "purpose": "Purpose",
    "status": "Status",
    "min_balance": "Min Balance",
    "max_balance": "Max Balance",
    "daily_max_deposit_count": "Daily Max Deposit Count",
    "daily_max_withdrawal_count": "Daily Max Withdrawal Count",
    "daily_max_deposit": "Daily Max Deposit Amount",
    "daily_max_withdrawal": "Daily Max Withdrawal Amount",
    "min_deposit_per_transaction": "Minimum Deposit Per Transaction",
    "max_deposit_per_transaction": "Maximum Deposit Per Transaction",
    "min_withdrawal_per_transaction": "Minimum Withdrawal Per Transaction",
    "max_withdrawal_per_transaction": "Maximum Withdrawal Per Transaction",
    "shortcut_amount": "Shortcut Amount",
    "score": "Score",
    "position": "Position",
    "display_name": "Display Name",
    "deposit_processing_fee_type": "Deposit Processing Fee Type",
    "withdrawal_processing_fee_type": "Withdrawal Processing Fee Type",
    "deposit_absorb_processing_fee_type": "Deposit Processing Fee Absorption Type",
    "withdrawal_absorb_processing_fee_type": "Withdrawal Processing Fee Absorption Type",
    "player_deposit_absorb_percentage": "Player Deposit Processing Fee Absorption Percentage",
    "company_deposit_absorb_percentage": "Company Deposit Processing Fee Absorption Percentage",
    "player_withdrawal_absorb_percentage": "Player Withdrawal Processing Fee Absorption Percentage",
    "company_withdrawal_absorb_percentage": "Company Withdrawal Processing Fee Absorption Percentage",
    "deposit_processing_fee": "Deposit Processing Fee Amount",
    "deposit_processing_fee_percentage": "Deposit Processing Fee Percentage",
    "withdrawal_processing_fee": "Withdrawal Processing Fee Amount",
    "withdrawal_processing_fee_percentage": "Withdrawal Processing Fee Percentage"
  };

  tabs: any[] = [];
  selectedIndex: number = 0;
  isAddingTab = false;
  @ViewChild('selectElement') selectElement!: MatSelect;
  @ViewChildren(MerchantBankTransactionConfigEditComponent) subComponents: QueryList<MerchantBankTransactionConfigEditComponent>;
  currencyCode: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bank: MerchantBank; mode: string, type: MerchantBankComponentType },
    public dialogRef: MatDialogRef<MerchantBankNewEditComponent>,
    private merchantBankService: MerchantBankEntityService,
    private merchantBankDataService: MerchantBankDataService,
    private merchantBankHttpService: MerchantBankHttpService,
    private dropdownHttpService: DropdownHttpService,
    private uploadService: UploadHttpService,
    private lightbox: Lightbox,
    private authHttpService: AuthHttpService,
  ) { }

  ngOnInit() {
    if (this.dropdown.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe(res => {
        this.dropdown.currencies = res;
      });
    }

    this.formInit();
    if (this.data.mode === "edit") {
      this.onSelectCurrency(this.data.bank.settings_currency_id);
      this.selectedBankType = this.data.bank.bank_type;
      if (this.data.bank.method == 2 || this.isTypeCrypto()) {
        this.data.bank.merchant_bank_transaction_configs.forEach(pg => {
          this.addTab(pg);
        });
        if (this.data.bank.merchant_bank_transaction_configs.length > 0) {
          setTimeout(() => {
            this.selectedIndex = 0;
          }, 500);
        }
      }
    }
    this.dropdown.groups.subscribe((res) => {
      const data = [];
      res.forEach((row) => {
        data.push({
          id: row.id,
          code: row.code,
          name: row.name,
        });
      });
      this.memberGroupDropdownList = data;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(merchantBank: MerchantBank, mode?: string) {
    this.buttonLoading = true;
    let formValid = true;
    const data = {
      id: merchantBank ? merchantBank.id : null,
      ...this.form.value,
      // sync: +this.form.value.method === 2 ? 0 : (this.form.value.sync ? 1 : 0),
      sync: this.form.value.sync ? 1 : 0,
      business: this.form.value.business ? 1 : 0,
      member_groups:
        this.form.value.member_groups !== null &&
          this.form.value.member_groups[0]?.id !== undefined
          ? this.form.value.member_groups.map((row: Group) => row.id)
          : this.form.value.member_groups,
      no_bonus: this.form.value.no_bonus === 0 || this.form.value.no_bonus === false || this.form.value.no_bonus === null ? 0 : 1,
      detail: {}
    };
    const errorMessages = [];
    if (+this.form.value.method === 2 || this.isTypeCrypto()) {
      // Loop all the transaction config form
      this.subComponents.forEach((component, index) => {
        const tabName = this.tabs[index].label;
        const tabCode = this.tabs[index].code;
        if (!component.formTransaction.valid) {
          // If the form is invalid
          // We check what causes the form invalid and we push the error message
          // Then make the formValid = false
          for (const controlName in component.formTransaction.controls) {
            const control = component.formTransaction.controls[controlName];
            if (control.errors?.required) {
              const displayControlName = this.formControlMapping[controlName] || controlName;
              errorMessages.push(`${displayControlName} in ${tabName} is required.`);
            }
            if (control.errors?.min && control.errors.min.min === 0) {
              const displayControlName = this.formControlMapping[controlName] || controlName;
              errorMessages.push(`${displayControlName} in ${tabName} should be greater than 0.`);
            }
          }
          formValid = false;
        } else {
          // let obj = {};
          // obj[tabName] = component.formTransaction.value;
          // data.detail.push(obj);
          data.detail[tabCode] = component.formTransaction.value;
        }
      });
    } else if (+this.form.value.method === 1 || +this.form.value.method === 3) {
      const component = this.subComponents.first;
      if (!component.formTransaction.valid) {
        for (const controlName in component.formTransaction.controls) {
          const control = component.formTransaction.controls[controlName];
          if (control.errors?.required) {
            const displayControlName = this.formControlMapping[controlName] || controlName;
            errorMessages.push(`${displayControlName} is required.`);
          }
          if (control.errors?.min && control.errors.min.min === 0) {
            const displayControlName = this.formControlMapping[controlName] || controlName;
            errorMessages.push(`${displayControlName} should be greater than 0.`);
          }
        }
        formValid = false;
      } else {
        data.detail[0] = component.formTransaction.value;
      }
    }
    if (formValid) {
      Object.keys(data).forEach(
        (key) => (data[key] == null || data[key] === "" || data[key].length === 0) && delete data[key]
      );
      const alertMessage = `<p>Please verify the information before continuing as you are not allowed to update these information (Currency, Bank, Account Number) after submission.</p>`;
      const alertConfig: any = {
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        reverseButtons: true,
      };
      switch (mode) {
        case "edit":
          this.subscription = this.merchantBankService
            .update(data)
            .pipe(
              tap((res: any) => {
                this.messages$.next([...res.message]);
                this.buttonLoading = false;
              }),
              catchError((error) => {
                this.buttonLoading = false;
                this.form.setErrors(null);
                throw error;
              })
            )
            .subscribe();
          break;
        case "create":
          Swal.fire({
            title: "New Merchant Bank Account",
            html:
              `<p>${this.selectedCurrencyCode} - ${this.selectedBankCode} - ${this.form.value.account_name} ${this.selectedBankType == 1 ? ' - ' + this.form.value.account_number : ""} </p>` +
              alertMessage,
            ...alertConfig,
          }).then((result) => {
            if (result.value === true) {
              this.subscription = forkJoin([
                this.merchantBankDataService.add(data).pipe(
                  tap((res: any) => {
                    this.form.setErrors(null);
                    this.buttonLoading = false;
                  }),
                  catchError((error) => {
                    this.buttonLoading = false;
                    this.form.setErrors(null);
                    throw error;
                  })
                ),
                this.merchantBankDataService.messages$,
              ]).subscribe();
            } else {
              this.buttonLoading = false;
            }
          });
          break;
      }
      this.refreshStatus = true;
    } else {
      // If form not valid for transaction config, swal the error message
      if (errorMessages.length > 0) {
        const message = '<ul>' + errorMessages.map(msg => `<li>${msg}</li>`).join('') + '</ul>';
        Swal.fire({
          icon: 'error',
          title: 'Validation Error',
          html: message,
          width: '650px'
        })
      }
      this.buttonLoading = false;
    }
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onSelectCurrency(id: number) {
    const currenyId = id;
    this.selectedCurrencyCode = this.dropdown.currencies.filter(
      (row) => row.id === +id
    )[0].name;
    if (this.data.mode === "create") {
      this.form.patchValue({ bank_id: null, bank_type: null });
    } else {
      this.form.patchValue({
        bank_id:
          Number(currenyId) === this.data.bank.settings_currency_id
            ? this.data.bank.bank.id
            : null,
      });
    }
    this.setBankOrPaymentGateWay();
    this.isSelectedCurrency = true;
  }

  onSelectBank() {
    let bank = this.dropdown.merchantBanks.filter(
      (row) => row.id === +this.form.value.bank_id
    )[0];
    this.selectedBankCode = bank.code;
    this.selectedBankType = bank.bank_type;
    this.form.patchValue({
      bank_type: this.selectedBankType,
    });
  }

  onSelectMethod() {
    this.form.patchValue({
      payment_gateways: [],
      member_groups: [],
      bank_id: null,
      bank_type: null
    });
    this.memberGroupSelectedItems = [];
    this.paymentGatewaysSelectedItems = [];
    this.paymentGatewaysDropdownSettings = {
      ...this.paymentGatewaysDropdownSettings,
      disabled: +this.form.value.method !== 2
    };
    this.selectedBankType = this.form.value.method;
    // this.updateValidatorPaymentGateWay();
    // this.form.get('payment_gateways').updateValueAndValidity();
    this.setBankOrPaymentGateWay();
  }

  onUploadFile(event: any) {
    this.isImageUploading = true;
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append("files", file, file.name);
    formData.append("type", "bank");
    this.uploadService.upload(formData).subscribe((res: any) => {
      this.imagePreview = res;
      this.form.patchValue({
        qr_image: this.imagePreview[0],
      });
      this.form.get("qr_image").markAsDirty();
      this.form.markAllAsTouched();
      this.isImageUploading = false;
    });
  }

  openImage(label: string, path: string) {
    let imageList = [];
    imageList.push({
      src: path,
      caption: label,
      thumb: path,
    });

    this.lightbox.open(imageList, 0, {
      centerVertically: true,
      disableScrolling: false,
      fitImageInViewPort: true,
      fadeDuration: 0.25,
      resizeDuration: 0.25,
    });
  }

  onCheckBalance() {
    this.minMaxValid =
      this.form.value.min_balance < this.form.value.max_balance ? true : false;
  }

  changeBankTypeValidators() {
    this.form.patchValue({
      qr_image: null,
      username: null,
      password: null,
      business: null,
      sync: null
    });

    if (this.fileInput) {
      this.fileInput.nativeElement.value = ''; // Clear the value of the file input
    }

    if (+this.form.value.method === 2 || this.isTypeCrypto()) {
      // this.form.controls.qr_image.clearValidators();
      this.form.controls.account_number.setValidators(Validators.required);
      this.form.controls.username.clearValidators();
      this.form.controls.password.clearValidators();
      // this.form.get('qr_image').updateValueAndValidity();
      this.form.get('account_number').updateValueAndValidity();
      this.form.get('username').updateValueAndValidity();
      this.form.get('password').updateValueAndValidity();
    } else {
      if (this.form.get("bank_type").value == 3) {
        // this.form.controls.qr_image.setValidators(Validators.required);
        this.form.controls.account_number.setValidators(Validators.required);
        this.form.get('account_number').updateValueAndValidity();
        this.form.controls.username.clearValidators();
        this.form.controls.password.clearValidators();
      } else {
        // this.form.controls.qr_image.clearValidators();
        this.form.controls.account_number.setValidators(Validators.required);
        this.form.controls.username.setValidators(Validators.required);
        this.form.controls.password.setValidators(
          null
          // Validators.compose(
          //   this.data.mode === "create" ? [Validators.required] : null
          // )
        );
      }
      this.updateFormValidity();
    }
  }

  private updateValidatorPaymentGateWay() {
    if (+this.form.value.method === 2) {
      this.form.controls.payment_gateways.clearValidators();
    }
    // else{
    //   this.form.controls.payment_gateways.setValidators([
    //     Validators.required
    //   ]);
    // }
    else {
      this.form.controls.payment_gateways.clearValidators();
    }
    this.form.get('payment_gateways').updateValueAndValidity();
  }

  private formInit() {
    const amountValidation = Validators.min(0);

    let currencyId = null;
    let bankType = null;
    let accountName = null;
    let accountNumber = null;
    let qrImage = null;
    let username = null;
    let password = null;
    let status = 1;
    let sync = 0;
    let business = 0;
    let memberGroups = this.memberGroupDropdownList;
    let type = null;
    let noBonus = null;
    let method = null;

    if (this.data.mode === "edit") {
      currencyId = this.data.bank.settings_currency_id;
      bankType = this.data.bank.bank_type;
      accountName = this.data.bank.account_name;
      accountNumber = this.data.bank.account_number;
      qrImage = this.data.bank.qr_image;
      username = this.data.bank.username;
      password = this.data.bank.password;
      sync = this.data.bank.sync;
      business = this.data.bank.business > 0 ? 1 : 0;
      status = this.data.bank.status;
      type = this.data.bank.type;
      noBonus = this.data.bank.no_bonus;
      method = this.data.bank.method;

      this.memberGroupSelectedItems =
        this.data.bank.member_groups.length >= 1
          ? [...this.memberGroupSelectedItems, ...this.data.bank.member_groups]
          : null;
      memberGroups = this.memberGroupSelectedItems;

    }
    this.form = new FormGroup({
      currency_id: new FormControl(currencyId, [Validators.required]),
      bank_id: new FormControl(null, [Validators.required]),
      bank_type: new FormControl(bankType, [Validators.required]),
      account_name: new FormControl(accountName, [Validators.required]),
      account_number: new FormControl(
        accountNumber,
        Validators.compose(bankType === 1 ? [Validators.required] : null)
      ),
      qr_image: new FormControl(qrImage, []),
      username: new FormControl(
        username,
        Validators.compose(bankType === 1 ? [Validators.required] : null)
      ),
      status: new FormControl(status),
      password: new FormControl(
        password, null
        // Validators.compose(
        //   this.data.mode === "create" ? [Validators.required] : null
        // )
      ),
      sync: new FormControl(sync),
      business: new FormControl(business),
      member_groups: new FormControl(memberGroups, [Validators.required]),
      type: new FormControl(type),
      no_bonus: new FormControl(noBonus),
      method: new FormControl(method),
      selectedTab: new FormControl(null)
    });

    this.form.get("bank_type").valueChanges.subscribe((data) => {
      this.changeBankTypeValidators();
    });

    this.paymentGatewaysDropdownSettings = {
      ...this.paymentGatewaysDropdownSettings,
      disabled: +this.form.value.method !== 2
    };
  }

  private updateFormValidity() {
    Object.keys(this.form.controls).forEach((key) => {
      if (key != "bank_type")
        this.form.get(key).updateValueAndValidity();
    });
  }

  private setBankOrPaymentGateWay() {
    if (this.form.value.currency_id !== null && this.form.value.method !== null) {
      this.merchantBankHttpService.getMerchantBanks(this.form.value.currency_id, this.form.value.method).subscribe(res => {
        this.dropdown.merchantBanks = res;
        if (this.data.mode === 'edit') {
          this.form.patchValue({ bank_id: this.data.bank.bank_id });
        }
      });
    }
    if (+this.form.value.method === 2 || +this.form.value.method === 4) {
      this.merchantBankHttpService.getMemberMerchantPaymentGateway(this.form.value.currency_id).subscribe(res => {
        this.dropdown.paymentGateway = this.isTypeCrypto()
          ? res.filter(r => this.cryptoGatewayCodes.includes(r.code))
          : res.filter(r => !this.cryptoGatewayCodes.includes(r.code));
      });
    }
  }

  addTab(data?: any) {
    let selectedTab = '';
    let paymentGatewayCode = '';
    if (data) {
      selectedTab = data.payment_gateway_name;
      paymentGatewayCode = data.payment_gateway_code;
    } else {
      let selectedPG = this.dropdown.paymentGateway.find(item => item.id === this.form.get('selectedTab').value);
      selectedTab = selectedPG.name;
      paymentGatewayCode = selectedPG.code;
    }
    const newTab = {
      label: selectedTab,
      code: paymentGatewayCode,
      detail: data ?? {}
    };
    this.tabs.push(newTab);
    // Find the index of the newly added tab
    const newIndex = this.tabs.length - 1;

    this.form.get('selectedTab').setValue(null);
    this.isAddingTab = false;
    // Set the selected index to the index of the newly added tab
    setTimeout(() => {
      this.selectedIndex = newIndex;
    });

  }

  removeTab(index: number) {
    Swal.fire({
      title: 'Are you sure you want to delete',
      text: this.tabs[index].label,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.tabs.splice(index, 1);
        const newIndex = this.tabs.length - 1;

        // Set the selected index to the index of the newly added tab
        setTimeout(() => {
          this.selectedIndex = newIndex;
        });
      }
    });
  }

  showDropdown(index: number) {
    this.tabs[index].showDropdown = true;
  }

  hideDropdown(index: number) {
    this.tabs[index].showDropdown = false;
  }

  toggleAddingTab() {
    this.isAddingTab = true;
  }

  openSelect() {
    this.selectElement.open();
  }

  onRemoveFile() {
    this.form.patchValue({
      qr_image: null,
    });
    if (this.fileInput) {
      this.fileInput.nativeElement.value = ''; // Clear the value of the file input
    }
  }

  isTypeCrypto() {
    return this.data.type === "crypto";
  }
}
