import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AllLeadsDataService } from '../../services/all-leads-data.service';

@Component({
  templateUrl: './affiliate-settings-add.component.html',
  styleUrls: ['./affiliate-settings-add.component.scss'],
})
export class AffiliateSettingsAddDialogComponent implements OnInit, OnDestroy {
  @ViewChild('textArea', { read: ElementRef }) textArea: ElementRef;
  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;

  form: FormGroup;
  private subscriptions: Subscription[] = [];
  private isHandlingChange = false;

  dropdownDisabled = false;
  dropdownSettings = {
    singleSelection: false,
    text: 'Please Select',
    enableCheckAll: false,
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'username',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    disabled: false
  };
  leadSettings: any;
  loading = false;
  selectedMembers = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { lead_setting: any },
    public dialogRef: MatDialogRef<AffiliateSettingsAddDialogComponent>,
    private allLeadsDataService: AllLeadsDataService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.formInit();
    
    // Validation Control: To accept either `usernames` or `affiliate codes` only
    this.subscriptions.push(this.form.controls.usernames.valueChanges.subscribe(value => {
      if (!this.isHandlingChange) {
        this.isHandlingChange = true;
        if (value && value.length > 0) {
          this.form.controls.affiliate_codes.disable();
        } else {
          this.form.controls.affiliate_codes.enable();

          // Reset empty array to null
          this.form.controls.usernames.patchValue(null);
        }
        this.isHandlingChange = false;
      }
    }));

    this.subscriptions.push(this.form.controls.affiliate_codes.valueChanges.subscribe(value => {
      if (!this.isHandlingChange) {
        if (!this.isHandlingChange) {
          this.isHandlingChange = true;
          if (value) {
            this.dropdownSettings = {
              ...this.dropdownSettings,
              disabled: true
            };
          } else {
            this.dropdownSettings = {
              ...this.dropdownSettings,
              disabled: false
            };
          }
          this.isHandlingChange = false;
        }
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onSave() {
    this.loading = true;

    const data = {
      ...this.form.value,
      lead_setting_id: this.data.lead_setting.id
    };

    Object.keys(data).forEach((key) => (data[key] === null || data[key] === '' || data[key].length < 1) && delete data[key]);

    if (data.affiliate_codes) {
      // Convert to array
      data.affiliate_codes = data.affiliate_codes.split('\n').filter(line => line.trim() !== '');
    }
    
    this.subscriptions.push(
      this.allLeadsDataService.createAffiliateLeadSetting(data).pipe(
        tap(() => {
          this.loading = false;
          this.dialogRef.close(true);
        }),
        catchError((error) => {
          this.loading = false;
          throw error;
        })
      ).subscribe()
    );
  }

  public autoGrow() {
    const textArea = this.textArea.nativeElement;

    textArea.style.overflow = 'hidden';
    textArea.style.height = 'auto';
    
    const minHeight = 95;
    const height = Math.max(textArea.scrollHeight, minHeight);
    
    textArea.style.height = height + 'px';
  }

  private formInit() {
    this.form = new FormGroup({
      usernames: new FormControl(null),
      affiliate_codes: new FormControl(null, affiliateCodesValidator())
    });
  }

  onSelectionChanged(event: Event) {
    let arr = event as any;
    if (arr.length === 0) {
      this.selectedMembers = []
    }
  }

  onRemoveSelection(id: number) {
    const result = this.selectedMembers.filter((i: any) => i.id !== id);
    this.selectedMembers = result;
    this.updateSelection(this.selectedMembers);
  }

  private updateSelection(selectedMembers) {
    let data = [];
    Object.keys(selectedMembers).forEach((key) => data.push(selectedMembers[key]['username']));
    this.form.get('usernames').patchValue(data);
    this.cdr.detectChanges();
  }
}

export function affiliateCodesValidator(): ValidatorFn {
  // Check if only newlines & empty spaces are entered in `affiliate_codes`

  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (value && value.trim().length > 0 && value.trim() !== '\n') {
      return null;
    }

    return { 'invalidAffiliateCodes': true };
  };
}