import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { AgentReportSummary } from '@core/models/agent-report-summary.model';
import { AgentReport } from '@core/models/agent-report.model';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import * as moment from 'moment-timezone';
import { AgentReportDataService } from './services/agent-report-data.service';
import { AgentReportEntityService } from './services/agent-report-entity.service';
@Component({
  templateUrl: './agent-reports.component.html',
  styleUrls: ['./agent-reports.component.scss']
})
export class AgentReportsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  paginationTotal = 1;
  from = this.transactionHttpService.getLast24Hours().from;
  to = this.transactionHttpService.getLast24Hours().to;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  timezoneDropdownSettings = {
    singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown clear-all-disabled',
      primaryKey: 'offset',
      labelKey: 'offset',
      noDataLabel: '',
      showCheckbox: false
  };
  dropdownSettings = {};
  params = ``;
  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones')),
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage
  };

  agentReport$: Observable<AgentReport[]>;

  pagination: Pagination;
  summary$: AgentReportSummary;
  messages$ = this.agentReportDataService.messages$;
  ranges: any;
  private datePickerSubscription = new Subscription();
  private subscription = new Subscription();

  isAgent = JSON.parse(localStorage.getItem('user_data')).is_agent;
  affType = JSON.parse(localStorage.getItem('user_data')).agent_type;
  selectedItems = [];
  loggedUser: string;

  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;

  constructor(
    public dialog: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private agentReportDataService: AgentReportDataService,
    private agentReportEntityService: AgentReportEntityService,
    private loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private store: Store<AppState>,
  ) {
    this.store.select(loggedUser).subscribe(res => this.loggedUser = res.username);
  }

  ngOnInit() {
    this.formInit();
    if (this.affType === 3) {
      this.selectedItems.push({
        username: this.loggedUser
      })
      this.form.patchValue({
        agent_username: this.loggedUser
      })
    }
    this.setCurrency();
    this.ranges = this.transactionHttpService.ranges;
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.selectedTimezone = this.timezone;
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      maxHeight: 200,
      noDataLabel: '',
      showCheckbox: false
    };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.agentReport$ = this.agentReportEntityService.getWithQuery(`&page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.agentReportDataService.pagination;
        this.summary$ = this.agentReportDataService.summary;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    if (this.affType === 3) {
      this.form.patchValue({
        // currency_id: null,
        start_date_time: this.from,
        end_date_time: this.to,
        timezone: this.timezone,
        defaultDate: {
          startDate: this.from,
          endDate: this.to
        }
      });
    } else {
      let default_currency_id: any = null;
      if (this.dropdown.currencies.length > 0) {
        default_currency_id = Object.values(this.dropdown.currencies)[0]['id'];
      }

      this.eventEmitterService.onClearMemberSearch();
      this.form.patchValue({
        agent_username: null,
        currency_id: default_currency_id,
        start_date_time: this.from,
        end_date_time: this.to,
        timezone: this.timezone,
        defaultDate: {
          startDate: this.from,
          endDate: this.to
        }
      });
    }
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['currency_id'] === undefined || data['currency_id'] === null || data['currency_id'] === 0) {
          if (this.dropdown.currencies.length > 0) {
            data['currency_id'] = Object.values(this.dropdown.currencies)[0]['id'];
            this.form.patchValue({currency_id : data['currency_id']});
          }
        }

        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        this.loading = true;
        return this.agentReport$ = this.agentReportEntityService.getWithQuery(`&${this.params}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.summary$ = this.agentReportDataService.summary;
            this.pagination = this.agentReportDataService.pagination;
            this.loadingBar.complete();
            this.onSearch = true;
          }),
          catchError((error) => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = 0;
            this.form.setErrors(null);
            throw error;
          })
        );
      }),
    ).subscribe();
  }

  sanitizeNumericField(previousValue: any) {
    const value = String(previousValue).replace(/[,]+/, '');
    return value;
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private setCurrency(){
    const selectCurrency = () => {
      this.isAgent ? this.form.patchValue({ currency_id: this.dropdown.currencies[0].id }) : '';
    };

    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
        selectCurrency();
      });
    }else{
      selectCurrency();
    }
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      agent_username: new FormControl(null),
      currency_id: new FormControl(null), // Default
      start_date_time: new FormControl(this.from),
      end_date_time: new FormControl(this.to),
      timezone: new FormControl(this.timezone),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      }) // Do not remove: For Clearing The Range
    });
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }
}
