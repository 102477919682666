<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog tooltip-container">

      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Provider Settings (SMSEdge)</h5>
              <span class="modal-x-button" (click)="onCloseDialog()">
                  <i class="fa fa-times" aria-hidden="true"></i>
              </span>
          </div>
          <div class="modal-body">
            <div class="table-responsive mb-3">
                <table class="table table-bordered table-hover table-striped">
                  <thead>
                    <tr>
                      <th class="text-center">ID</th>
                      <th class="text-center">Region</th>
                      <th class="text-center">Smart Route</th>
                      <th class="text-center">Preferred Route ID</th>
                      <th class="text-center">Shorten URL</th>
                      <th class="text-center">Transactional</th>
                      <th class="text-center">Status</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="settings$ as rows">
                    <tr *ngFor="let row of rows">
                      <td>{{row.id}}</td>
                      <td>{{row.region}}</td>
                      <td class="text-center">
                          <div class="custom-control custom-switch">
                              <input type="checkbox" class="custom-control-input" [disabled]='!canEditProviderCredentialSettings' (click)="onToggleSmartRoute(row, $event)" [id]="row?.id + '-smart-route'" [checked]="row?.smart_route === 1 ? true : false">
                              <label class="custom-control-label" [for]="row?.id + '-smart-route'"></label>
                          </div>
                      </td>
                      <td class="text-center">{{ row?.route_id ? row.route_id + ' ('+ row.route_name +')' : '-' }}</td>
                      <td class="text-center">
                          <div class="custom-control custom-switch">
                              <input type="checkbox" class="custom-control-input" [disabled]='!canEditProviderCredentialSettings' (click)="onToggleShortenURL(row, $event)" [id]="row?.id + '-shorten-url'" [checked]="row?.shorten_url === 1 ? true : false">
                              <label class="custom-control-label" [for]="row?.id + '-shorten-url'"></label>
                          </div>
                      </td>
                      <td class="text-center">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" [disabled]='!canEditProviderCredentialSettings' (click)="onToggleTransactional(row, $event)" [id]="row?.id + '-transactional'" [checked]="row?.transactional === 1 ? true : false">
                            <label class="custom-control-label" [for]="row?.id + '-transactional'"></label>
                        </div>
                    </td>
                      <td class="text-center">
                          <div class="btn-group dropdown" *ngIf="canEditProviderCredentialSettings; else noEditPermission">
                              <button type="button" class="btn btn-pill btn-sm dropdown-base" [ngClass]="'kt-badge--' + row.status"> {{ row.status === 0 ? ('Inactive' | translate) : status[row.status] | translate }} </button>
                              <button type="button" class="btn btn-pill btn-sm dropdown-toggle" [ngClass]="'kt-badge--' + row.status" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i class="fa fa-angle-down"></i>
                              </button>
                              <div class="dropdown-menu">
                                  <ng-container *ngFor="let item of dropdown.status | keyvalue">
                                      <button class="dropdown-item" type="button" (click)="onChangeStatus(row, item.key)" *ngIf="+item.key >= 0">{{ dropdown.status[item.key] | translate }}</button>
                                  </ng-container>
                              </div>
                          </div>
                          <ng-template #noEditPermission>
                            <span class="kt-badge status-color kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status === 0 ? ('Inactive' | translate) : status[row.status] | translate }} </span>
                          </ng-template>
                      </td>
                      <td class="text-center">
                        <button title="Edit" *ngIf="canEditProviderCredentialSettings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                </table>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          </div>
      </div>

  </div>  