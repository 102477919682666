import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { MemberBankAccountVerification } from '@core/models/member-bank-account-verification-model';

@Injectable()
export class MemberBankAccountVerificationDataService extends DefaultDataService<MemberBankAccountVerification>  {

  messages$ = new Subject<any[]>();
  pagination: Pagination;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('MemberBankAccountVerification', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<any[]>{
    return this.http.get<ApiResponse>(`/bankaccount/verification${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  getById(id: number): Observable<MemberBankAccountVerification>{
    return this.http.get<ApiResponse>(`/bankaccount/verification/${id}`).pipe(
      map(res => res.data)
    );
  }

  updateVerification(id: number, data: any) {
    return this.http.put<ApiResponse>(`/bankaccount/verification/update/${id}`, data).pipe(
      tap(res => {
        if (data.triggerMessage && data.triggerMessage !== false) {
          this.messages$.next(res.message);
        }
      })
    );
  }

  getRemarks(pageParam: string): Observable<any[]>{
    return this.http.get<ApiResponse>(`/bankaccount/verification/remarks${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if(res) {
      return this.pagination = res.data.paginations;
    }
  }
}
