import { MemberLog } from '@core/models/member-log.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class MemberLogEntityService extends EntityCollectionServiceBase<MemberLog> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('MemberLog', serviceElementsFactory);
  }
}
