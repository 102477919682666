import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MyLeads } from '@core/models/my-leads.model';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { of, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { RemarkDialogComponent } from '../all-leads/dialogs/remark/remark.component';
import { ShowRemarkComponent } from '../all-leads/dialogs/show-remark/show-remark.component';
import { LeadHistoryComponent } from '../all-leads/dialogs/lead-history/lead-history.component';
import { MemberInformationDialogComponent } from '../members/dialogs/member-information/member-information.component';
import { MemberDataService } from '../members/services/member-data.service';
import { CallLogDialogComponent } from './dialogs/call-logs/call-log.component';
import { MyLeadsDataService } from './services/my-leads-data.service';
import { MyLeadsEntityService } from './services/my-leads-entity.service';
import { RequestJustificationDialogComponent } from '../all-leads/dialogs/request-justification/request-justification.component';
import { CreateMemberDialogComponent } from '../my-leads/dialogs/create-member/create-member.component';
import { AppPermissionService } from '@core/services/app-permission.service';
import { MemberRewardsDialogComponent } from '../members/dialogs/member-rewards/member-rewards.component';
import { MemberEntityService } from '../members/services/member-entity.service';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { specialPermissions } from '@core/store/auth/auth.selectors';
import { DateTime } from 'luxon';

@Component({
  templateUrl: './my-leads.component.html',
  styleUrls: ['./my-leads.component.scss']
})
export class MyLeadsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('textArea', { read: ElementRef }) textArea: ElementRef;
  form: FormGroup;
  tz: any;
  todayDate: any;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    // leadTypeCategory: this.dropdownHttpService.filterLeadTypeCategory,
    perPage: this.dropdownHttpService.perPage,
    statuses: [
      {
        name: 'Open', value: 8, checked: true
      },
      {
        name: 'Active', value: 1, checked: true
      },
      {
        name: 'Following Up', value: 9, checked: true
      },
      {
        name: 'Expired', value: 2, checked: false
      },
      {
        name: 'Closed', value: 0, checked: false
      },
      {
        name: 'Partially Closed', value: 3, checked: false
      },
      {
        name: 'Failed', value: 4, checked: false
      },
      {
        name: 'Pending Justification', value: 6, checked: false
      },
      {
        name: 'Rejected Justification', value: 7, checked: false
      },
    ],
    leadProgress: this.dropdownHttpService.filterLeadProgress,
    leadType: this.dropdownHttpService.filterLeadType,
    sourceType: this.dropdownHttpService.sourceTypes,
  };
  messages$ = this.MyLeadsDataService.messages$;
  selectedLeadType = null;
  dropdownSettings = {};
  MyLeads: MyLeads[];
  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  callBtnLoading = {};
  pagination: Pagination;
  maxSize = 5;
  pageSize = 30;
  page = 1;
  params = '';
  buttonLoading = false;
  currentContact = '';
  selectedContactType = 'Mobile';
  searchStatus = [1, 8, 9];
  searchProgressStatus = [];
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  default_sort_by = 'lead_id';
  default_sort_order = 'desc';

  sortingConfig = {
    'lead_assigned_date': 'desc',
    'lead_id': 'desc',
    'username': 'desc',
    'name': 'desc',
    'mobile': 'desc',
    'source_type': 'desc',
    'source_account': 'desc',
    'campaign': 'desc',
    'currency': 'desc',
    'last_call_attempt': 'desc',
    'status': 'desc',
    'lead_type': 'desc',
    'registration_created_at': 'desc',
    'last_login': 'desc',
    'last_deposit_date': 'desc',
    'password': 'desc',
    'remarks': 'desc',
    'lead_expired_date': 'desc',
    'last_follow_up_date': 'desc',
    'updated_at': 'desc',
    'created_at': 'desc',
  };
  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order
  };
  dataLength: number;
  isAdmin: boolean = JSON.parse(localStorage.getItem('user_data')).is_admin;
  returnedData: any;
  // createMember = localStorage.getItem('user_permissions') !== null ? JSON.parse(localStorage.getItem('user_permissions')).telemarketer_create_member : false ;
  // assignRewardMember = localStorage.getItem('user_permissions') !== null ? JSON.parse(localStorage.getItem('user_permissions')).telemarketer_assign_reward : false ;
  userPermissions$ = this.store.pipe(select(specialPermissions));

  // permissions
  canCall: boolean;
  canViewCallLogsAttempts: boolean;
  canEditRemark: boolean;
  canViewLeadJustification: boolean;
  canExportMyLeadsList: boolean;
  canRequestJustification: boolean;
  canCreateMember: boolean;
  canViewLeadHistory: boolean;
  canAssignReward: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    public loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private MyLeadsEntityService: MyLeadsEntityService,
    private MyLeadsDataService: MyLeadsDataService,
    private memberDataService: MemberDataService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private appPermissionService: AppPermissionService,
    private memberService: MemberEntityService,
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.tz = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.todayDate = DateTime.now().setZone(this.tz);
    if (this.dropdown.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe(res => {
        this.dropdown.currencies = res;
      });
    }
    this.formInit();
    this.pagination = this.MyLeadsDataService.pagination;
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
    this.ranges = this.transactionHttpService.ranges;
    this.onSubmit(true);

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCall = appPermissions.call;
      this.canViewCallLogsAttempts = appPermissions.my_leads_view_call_logs_attempts;
      this.canEditRemark = appPermissions.my_leads_add_remark;
      this.canExportMyLeadsList = appPermissions.export_my_leads_list;
      this.canRequestJustification = appPermissions.request_justification;
      this.canCreateMember = appPermissions.my_leads_create_member;
      this.canViewLeadHistory = appPermissions.my_leads_view_lead_history;
      this.canAssignReward = appPermissions.my_leads_assign_reward;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onOpenDialog(type: string, data?: any, status?: any) {
    if (type == 'callLog') {
      if (data) {
        this.openDialogBy(CallLogDialogComponent, { mode: type, callLog: data });
      }
    } else if (type === 'create-member') {
      this.openDialogBy(CreateMemberDialogComponent, { mode: type, leadData: data });
    } else if (type === 'remarks') {
      this.openDialogBy(RemarkDialogComponent, { mode: type, leadID: data, status: status });
    } else if (type === 'request-justification') {
      this.openDialogBy(RequestJustificationDialogComponent, { mode: type, leadID: data, status: status });
    } else if (type === 'show-remarks') {
      this.openDialogBy(ShowRemarkComponent, { mode: type, leadID: data });
    } else if (type === 'leadHistory') {
      this.openDialogBy(LeadHistoryComponent, { mode: type, leadID: data });
    } else if (type === 'assignRewards') {
      const member = this.memberService.getByKey(data);
      this.subscription = member.pipe(
        tap((res) => {
          if (res) {
            this.openDialogBy(MemberRewardsDialogComponent, { member: res, mode: 'member_reward_by_telemarketer' });
          }
        })
      )
        .subscribe();  
    } else {
      this.subscription = this.memberDataService.getById(data, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}
      &end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&additional_info=1`).pipe(
        tap((res) => {
          if (res) {
            this.openDialogBy(MemberInformationDialogComponent, { mode: type, member: res });
          }
        })
      ).subscribe();
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, member?: any, callLog?: any, leadID?: any, leadData?: any, status?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'callLog' || data.mode === 'remarks' || data.mode === 'request-justification' || data.mode === 'create-member' || data.mode === 'member_reward_by_telemarketer' ? '800px' : '1500px',
      height: data.mode === 'callLog' || data.mode === 'remarks' || data.mode === 'show-remarks' || data.mode === 'request-justification' || data.mode === 'create-member' || data.mode === 'member_reward_by_telemarketer' || data.mode === 'leadHistory' ? 'auto' : '80vh',
      data: {
        member: data.member,
        callLog: data.callLog,
        leadID: data.leadID,
        leadData: data.leadData,
        mode: data.mode,
        status: data.status
      },
      autoFocus: false
    });
    if (data.mode === 'callLog' || data.mode === 'remarks' || data.mode === 'show-remarks') {
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.onViewPageBy();
          this.returnedData = JSON.parse(localStorage.getItem('latest_remark_update')).data;
          if (this.returnedData) {
            localStorage.removeItem('latest_remark_update');
            this.MyLeads.find(x => x['lead_id'] == this.returnedData['id']).remarks = this.returnedData['remarks'];
          }
        }
      });
    } else if (data.mode === 'request-justification' || data.mode === 'create-member') {
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.onViewPageBy();
        }
      });

    }
  }

  onCall(mobile: string, id?: number) {
    // Disable the button and start loading
    const setBtnLoading = this.getBtnLoadingFuncByMode(id);
    setBtnLoading(true);

    return this.MyLeadsDataService.clickToDial(mobile).pipe(
      tap(res => {
      }),
      catchError(err => {
        setBtnLoading(false);
        return throwError(err);
      })
    ).subscribe((result) => {
      setBtnLoading(false);
    });
  }

  resetCallBtnLoading() {
    this.MyLeads.forEach(l => {
      this.callBtnLoading[l.id] = false;
    });
  }

  getBtnLoadingFuncByMode(id?: number) {
    return (flag: boolean) => {
        this.callBtnLoading[id] = flag;
    }
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onChangeStatus(event: any, id: number, ind: number) {
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }
    this.form.patchValue({ status: this.searchStatus });
  }

  onChangeProgressStatus(event: any, id: number, ind: number) {
    const position = this.searchProgressStatus.indexOf(id);
    if (event.target.checked) {
      this.searchProgressStatus.push(id);
      this.dropdown.leadProgress[ind].checked = true;
    } else {
      this.searchProgressStatus.splice(position, 1);
      this.dropdown.leadProgress[ind].checked = false;
    }
    this.form.patchValue({ progress_status: this.searchProgressStatus });
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        tap((data) => {
          if (data['start_date']) {
            data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
            data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          }

          this.params = Object.keys(data)
            .map((key) => key + "=" + data[key])
            .join("&");
          const statusParams = this.generateStatusParams();
          const progressStatusParams = this.generateProgressStatusParams();
          this.params = this.params.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
          this.params = this.params.replace(progressStatusParams.oldProgressStatusParams, progressStatusParams.newProgressStatusParams);
          this.sortingConfig[this.sortingSelection.sort_by] = 'desc';
          this.sortingSelection.sort_by = this.default_sort_by;
          this.sortingSelection.sort_order = this.default_sort_order;
          this.sortingConfig[this.default_sort_by] = this.default_sort_order;
          this.page = 1;
          this.onViewPageBy();
        })
      )
      .subscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : "";
    this.loadingBar.start();
    this.MyLeadsDataService
      .getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`)
      .pipe(
        tap(res => {
          this.MyLeads = res;
          this.resetCallBtnLoading();
          this.dataLength = res.length;
          this.pagination = this.MyLeadsDataService.pagination;
          this.loadingBar.complete();
          this.onSearch = true;
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
        }),
        catchError((err) => {
          this.dataLength = 0;
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.loadingBar.complete();
          return throwError(err);
        })
      ).subscribe();
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private generateStatusParams() {
    return {
      newStatusParams: Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&'),
      oldStatusParams: 'status=' + Object.keys(this.searchStatus).map(key => this.searchStatus[key]).join(','),
    };
  }

  private generateProgressStatusParams() {
    return {
      newProgressStatusParams: Object.keys(this.searchProgressStatus).map(key => `progress_status[${key}]=${this.searchProgressStatus[key]}`).join('&'),
      oldProgressStatusParams: 'progress_status=' + Object.keys(this.searchProgressStatus).map(key => this.searchProgressStatus[key]).join(','),
    };
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.selectedContactType = 'Mobile';
    this.searchStatus = [1, 8, 9];
    this.searchProgressStatus = [];
    this.dropdown.statuses.forEach((child) => {
      if (child.name === 'Active' || child.name === 'Open' || child.name === 'Following Up') {
        child.checked = true;
      } else {
        child.checked = false;
      }
    });
    this.dropdown.leadProgress.forEach((child) => {
      child.checked = false;
    });
    this.form.patchValue({
      username: null,
      name: null,
      id: null,
      currency_id: 'all',
      status: this.searchStatus,
      progress_status: this.searchProgressStatus,
      mobile: null,
      email: null,
      date_type: 'registration_date',
      start_date: null,
      end_date: null,
      defaultDate: null,
      contactType: 'Mobile',
      lead_type: null,
      lead_category: null,
      source_type: null,
      source_account: null,
      campaign_name: null,
      campaign_code: null,
      dummy: 1
    });
    this.form.controls.source_account.disable();
    this.onClearDate();
    this.onSubmit(true);
  }

  onContactType(label: string) {
    this.selectedContactType = label;
    this.contactChanges();
    this.cdr.detectChanges();
  }

  onContact(event: Event) {
    this.currentContact = (event.target as HTMLInputElement).value;
    this.contactChanges();
    this.cdr.detectChanges();
  }

  private contactChanges() {
    if (this.selectedContactType !== '' || this.selectedContactType !== null) {

      if (this.selectedContactType === 'Mobile') {
        this.form.patchValue({
          [this.selectedContactType.toLocaleLowerCase()]: this.currentContact,
          email: null,
        });
      }
      if (this.selectedContactType === 'Email') {
        this.form.patchValue({
          mobile: null,
          [this.selectedContactType.toLocaleLowerCase()]: this.currentContact,
        });
      }
    }
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onExport() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.buttonLoading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        data['paginate'] = false;
        data['timezone'] = this.timezone;

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const statusParams = this.generateStatusParams();
        const progressStatusParams = this.generateProgressStatusParams();
        this.params = this.params.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        this.params = this.params.replace(progressStatusParams.oldProgressStatusParams, progressStatusParams.newProgressStatusParams);
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        return this.MyLeadsDataService.exportReport(`?${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.buttonLoading = false;
          }),
          catchError((err) => {
            this.buttonLoading = false;
            this.loadingBar.complete();
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      name: new FormControl(null),
      id: new FormControl(null),
      currency_id: new FormControl('all'),
      status: new FormControl(this.searchStatus),
      progress_status: new FormControl(this.searchProgressStatus),
      mobile: new FormControl(null),
      email: new FormControl(null),
      date_type: new FormControl('registration_date'),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null),
      contactType: new FormControl('Mobile'),
      lead_type: new FormControl(null),
      lead_category: new FormControl(null),
      source_type: new FormControl(null),
      source_account: new FormControl({value: null, disabled: true}),
      campaign_name: new FormControl(null),
      campaign_code: new FormControl(null),
      dummy: new FormControl(1)
    });

    this.form.get('lead_type').valueChanges.subscribe(res => {
      this.selectedLeadType = res;
      if (res == 3) {
        this.form.patchValue({
          lead_category: null
        })
      }
      else if (res == 1 || res == 2){
        this.form.patchValue({
          lead_category: 1
        })
      }      
    })
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && formData[key] !== false && key !== 'defaultDate' && key !== 'contactType') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  getUrl(site: string) {
    let url = new URL(site);
    return url.host;
  }

  urlIsValid(url: string) {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }

  onSelectSourceType(event: Event) {
    let selectedSourceType = (event.target as HTMLInputElement).value;
    if (selectedSourceType !== null && selectedSourceType !== 'null' && +selectedSourceType != 3) {
      this.form.controls.source_account.enable();
    } else {
      this.form.patchValue({source_account: null});
      this.form.controls.source_account.disable();
    }
  }

  calculateDayLeft(date: any){
    // Target date
    const targetDate = DateTime.fromISO(date, { zone: this.tz });

    // Check if the target date is today or in the past
    if (targetDate <= this.todayDate || targetDate.hasSame(this.todayDate, 'day')) {
      return 0;
    }

    // Calculate the difference in days
    const daysLeft = targetDate.startOf('day').diff(this.todayDate.startOf('day'), 'days').days;
    return daysLeft > 0 ? `${Math.ceil(daysLeft)}` : 0;
  }

  getRegisterStatus(id){
    if (id != null){
      return 1;
    }

    return 0;
  }

  getFTDStatus(last_deposit_date){
    if (last_deposit_date != null){
      return 1;
    }

    return 0;
  }

  onChangeDummy($event) {
    let isChecked = $event.target.checked ? 1 : 0;
    this.form.patchValue({
      dummy: isChecked
    });
  }
}
