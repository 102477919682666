import { FormGroup } from '@angular/forms';
import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { MemberPromotion } from '@core/models/member-promotion.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable, Subject } from 'rxjs';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MemberPromotionDataService extends DefaultDataService<MemberPromotion>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('MemberPromotion', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<MemberPromotion[]> {
    return this.http.get<ApiResponse>('/memberpromotion').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<MemberPromotion[]> {
    return this.http.get<ApiResponse>(`/memberpromotion${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  export(pageParam: string) {
    return this.http.get<ApiResponse>(`/memberpromotion/export${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getById(id: number): Observable<MemberPromotion>{
    return this.http.get<ApiResponse>(`/memberpromotion/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  unlock(memberPromotion: MemberPromotion) {
    return this.http.post<ApiResponse>(`/memberpromotion/unlock/${memberPromotion.id}`, memberPromotion);
  }

  cancel(memberPromotion: MemberPromotion) {
    return this.http.post<ApiResponse>(`/memberpromotion/cancel/${memberPromotion.id}`, memberPromotion);
  }

  sync(promotion_id: number) {
    return this.http.post<ApiResponse>(`/memberpromotion/syncturnover/${promotion_id}`, {});
  }

  getPromotionHistory(pageParam: string, promotion_id: number) {
    return this.http.get<ApiResponse>(`/memberpromotionhistory/${promotion_id}${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
