import { Component, OnInit, Inject, ChangeDetectorRef , ViewChildren, QueryList} from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WebDisplayDataService } from '../services/web-display-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Subscription, forkJoin } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { UploadHttpService } from '@core/services/upload-http.service';
import { EditorService } from '@core/services/editor.service';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { AppPermissionService } from '@core/services/app-permission.service';

type DisplaySuffixTypes = 'image' | 'content';

@Component({
    selector: 'kt-web-display-edit',
    templateUrl: './web-display-edit.component.html',
    styleUrls: ['./web-display-edit.component.scss']
})
export class WebDisplayEditDialogComponent implements OnInit {

    // Begin: CKEditor Part
    public editor = Editor;
    editorConfig = this.editorService.config;
    @ViewChildren('editors') editorComponent:QueryList<CKEditorComponent>;
    dialogType =  this.data.rows.section_type_code === 'imageText' ? 'imageText' : 'iconQuickLink';
        
    form: FormGroup;
    messages$ = this.webDisplayDataService.messages$;
    buttonLoading = true;
    refreshStatus: boolean;
    imagePreview = [];
    loading = false;
    
    private subscription = new Subscription();
    private subscriptions = new Subscription();
    private unsubscribe: Subscription[] = [];
    private localesSub = new Subscription();
    private uploadSub = new Subscription();

    locales = this.dropdownHttpService.locales;
    languages = [];
    dropdown = {
        statuses: this.dropdownHttpService.statuses,
        display_titles: this.dropdownHttpService.display_titles,
        section_types:this.dropdownHttpService.footerTypes,
        locales: this.dropdownHttpService.locales,
    };

    contentTypeSettings = {};
    contentTypeList = this.dropdownHttpService.contentType;
    contentTypeSelectedItems = [];

    contentSelectionSettings = {};
    contentSelectionList = this.data.rows.contentSelection !==undefined ? this.data.rows.contentSelection : [];
    contentSelectionItems = [];

    checkValidation = false;

    // permissions
    canEditWebsiteDisplaySettings: boolean;
    canEditMobileDisplaySettings: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { rows: any; mode: string},
        public dialogRef: MatDialogRef<WebDisplayEditDialogComponent>,
        private webDisplayDataService: WebDisplayDataService,
        private dropdownHttpService: DropdownHttpService,
        private cdr: ChangeDetectorRef,
        private uploadService: UploadHttpService,
        private editorService: EditorService,
        private appPermissionService: AppPermissionService,
    ) { }

    ngOnInit() {
        if (this.data.rows.web == 1) this.contentTypeSelectedItems.push(this.contentTypeList.find(v => v.code == 'web'));
        if (this.data.rows.mobile == 1 ) this.contentTypeSelectedItems.push(this.contentTypeList.find(v => v.code == 'mobile'));
      
        this.formInit();
        this.contentTypeSettings = {
            autoPosition: true,
            maxHeight: 150,
            singleSelection: false,
            text: 'Please Select',
            enableFilterSelectAll: true,
            enableSearchFilter: false,
            classes: 'dropdown',
            primaryKey: 'code',
            labelKey: 'name',
            lazyLoading: true,
            noDataLabel: '',
            showCheckbox: false
          };

        if (this.dialogType == 'iconQuickLink'){
          this.contentSelectionSettings ={
            autoPosition: true,
            maxHeight: 150,
            singleSelection: false,
            text: 'Please Select',
            enableFilterSelectAll: true,
            enableSearchFilter: false,
            classes: 'dropdown',
            primaryKey: 'content_selection',
            labelKey: 'combine_name',
            lazyLoading: true,
            noDataLabel: '',
            showCheckbox: false
          }
        }

        const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
          this.canEditWebsiteDisplaySettings = appPermissions.edit_website_display_settings;
          this.canEditMobileDisplaySettings = appPermissions.edit_mobile_display_settings;
        });

        this.subscriptions.add(apSub);
    }

    onCloseDialog(event?: Event) {
        this.dialogRef.close();
    }

    private formInit() {
        var inicontentTypeList = []
        if (this.data.rows.web == 1)  inicontentTypeList.push('web');
        if (this.data.rows.mobile == 1 ) inicontentTypeList.push('mobile');
        if(this.dialogType == 'imageText'){
          this.form = new FormGroup({
            redirect_link: new FormControl(this.data.rows.redirect_link),
            contentType: new FormControl(inicontentTypeList, [Validators.required])
          });
          this.addSectionTitlesFormImageText();
        }else if(this.dialogType == 'iconQuickLink'){
          this.form = new FormGroup({
            contentType: new FormControl(inicontentTypeList, [Validators.required])
          });
          this.addSectionTitlesFormIconQuicklink();
        }
 
        this.form.markAllAsTouched();
        this.form.markAsDirty();
    }

    private addSectionTitlesFormImageText(){
        this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
          this.languages = res;
          const buildSubDetailsImageText = () => {
            let contents = {};
            res.map((element, index) => {
              const contentsGroup = new FormGroup({
                setting_locale_id: new FormControl(element.id),
                image: new FormControl(null),
                content: new FormControl(null),
              });
              contents = { ...contents, [element.id]: contentsGroup };
            });
            return contents;
          };

          this.form.addControl('setting_locales', new FormGroup(buildSubDetailsImageText()));
          if( typeof this.data.rows.setting_locale !== 'undefined'){
            this.data.rows.setting_locale.map((res, index)=> {
                const detailsGroup = {
                  setting_locale_id: res.setting_locale_id,
                  image: res.image_path,
                  content: res.content
                }
                this.form.patchValue({
                    setting_locales : {[res.setting_locale_id]: detailsGroup} 
                });
            });
          }
          this.buttonLoading = false;
         
          this.cdr.detectChanges();
        })).subscribe();
    }

    private addSectionTitlesFormIconQuicklink(){
      this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
        this.languages = res;
        
        const buildSubDetailsImageText = () => {
          let contents = {};
          res.map((element, index) => {
            const contentsGroup = new FormGroup({
              setting_locale_id: new FormControl(element.id),
              contentSelection: new FormControl(null)
            });
            contents = { ...contents, [element.id]: contentsGroup };
          });
          return contents;
        };
  
        this.form.addControl('setting_locales', new FormGroup(buildSubDetailsImageText()));
        if( typeof this.data.rows.setting_locale !== 'undefined'){
          
          let contentSelectionList = [];
          res.map((element, index) => {
             contentSelectionList[element.id] = [];
             this.contentSelectionItems[element.id] = [];
          });
          
          this.data.rows.setting_locale.map((res, index)=> {
            if(res.footer_icon_id !== null){
              contentSelectionList[res.setting_locale_id].push("footer_icon_id-"+res.footer_icon_id);
              this.contentSelectionItems[res.setting_locale_id].push(this.contentSelectionList.find(v => v.content_selection === "footer_icon_id-"+res.footer_icon_id));
            }  
            if(res.footer_quick_link_id !== null) {
              contentSelectionList[res.setting_locale_id].push("footer_quick_link_id-"+res.footer_quick_link_id);
              this.contentSelectionItems[res.setting_locale_id].push(this.contentSelectionList.find(v => v.content_selection === "footer_quick_link_id-"+res.footer_quick_link_id));
            }
        
          });

          this.data.rows.setting_locale.map((res, index)=> {                      
            const detailsGroup = {
              setting_locale_id: res.setting_locale_id,
              contentSelection: contentSelectionList[res.setting_locale_id],
            }

            this.form.patchValue({
                setting_locales : {[res.setting_locale_id]: detailsGroup} 
            });  
              
          });
        }
        this.buttonLoading = false;
        this.cdr.detectChanges();
      })).subscribe();
  }

    ngOnDestroy() {
        this.localesSub.unsubscribe();
        this.subscription.unsubscribe();
        this.subscriptions.unsubscribe();
        this.uploadSub.unsubscribe();
        this.onRefresh();
    }

    onSave(){
        this.checkValidation = true;
        this.buttonLoading = true;
    
        const data = {
          ...this.form.value
        };

        data['section_type_code'] = this.dialogType;
       
        // here to set on / off mobile and website
        Object.keys(data.contentType).forEach((key) => {
            if(data.contentType[key] == 'web') data['web'] = 1;
            if(data.contentType[key] == 'mobile') data['mobile'] = 1;
        });

        if(typeof data['web'] == 'undefined') data['web'] = 0;
        if(typeof data['mobile'] == 'undefined') data['mobile'] = 0;
        
        if(this.dialogType == 'iconQuickLink'){
          Object.keys(data.setting_locales).forEach((key) => {

            let footer_icon_id = [];
            let footer_quick_link_id = [];
            let settinglocalesArray = data.setting_locales[key].contentSelection;
            if( settinglocalesArray !== null ){
              Object.keys(data.setting_locales[key].contentSelection).forEach((key2) => {
                var  iconQuickLink = data.setting_locales[key].contentSelection[key2];
                var  iconQuickLinkArray = iconQuickLink.split("-");
                
                if(iconQuickLinkArray[0] == 'footer_icon_id') footer_icon_id.push(iconQuickLinkArray[1]);
                if(iconQuickLinkArray[0] == 'footer_quick_link_id') footer_quick_link_id.push(iconQuickLinkArray[1]);  
              })
            }
            data.setting_locales[key]['footer_icon_id'] = footer_icon_id;
            data.setting_locales[key]['footer_quick_link_id'] = footer_quick_link_id;
          });
        }

        if (this.form.valid) {
          // To set "Save" button to disable (To prevent call API in multiple times when double click)
          this.form.setErrors({ 'invalid': true });
          this.subscription = forkJoin([
            this.webDisplayDataService.updateContent(this.data.rows.footer_section_merchant_id,data).pipe(
              tap(() => {
                this.buttonLoading = false;
                this.checkValidation = false;
                // To enable "Save" button after get response
                this.form.setErrors(null);
              }),
              catchError((error) => {
                this.buttonLoading = false;
                this.checkValidation = false;
                // To enable "Save" button after get response
                this.form.setErrors(null);
                throw error;
              })
            ),
            this.webDisplayDataService.messages$
          ]).subscribe();    
            
          this.refreshStatus = true;
        }else{
          this.buttonLoading = false;
        }
    }

    onRefresh(){
        if (this.refreshStatus === true){
          this.dialogRef.close(true);
        }
    }

    onUploadFile(event: any, languageId: number) {
      this.loading = true;
      const file: File = event.target.files[0];
      const formData = new FormData();
      formData.append('files', file, file.name);
      formData.append('type', 'promotions');
      this.uploadSub = this.uploadService.upload(formData).subscribe(res => {
        this.imagePreview = res;
        this.form.patchValue({
          setting_locales: { [languageId]: this.buildMediaPropertyBy('content_type', languageId, 'image') }
        });

        this.loading = false;
      });
    }

    private buildMediaPropertyBy(property: string, languageId: number, propertySuffix?: DisplaySuffixTypes) {
      let output = {};
      output = { ...output, [propertySuffix]: this.imagePreview[0] };
      output = { ...output, settings_locale_id: languageId };
      return output;
    }

    getMediaSource(languageId: number, propertySuffix: DisplaySuffixTypes = 'image') {
      let media: any;
      media = this.form.get(`setting_locales.${languageId}.${propertySuffix}`).value;
      return media;
    }


    onRemoveSelection(content_selection: string, language_Id) {
      const result = this.contentSelectionItems[language_Id].filter((i: any) => i.content_selection !== content_selection);
      this.contentSelectionItems[language_Id] = result;
      this.updateSelection(this.contentSelectionItems[language_Id],language_Id);
    }

    private updateSelection(contentSelectionItems,language_Id) {
 
      let data = [];
      Object.keys(contentSelectionItems).forEach((key) =>
      data.push(contentSelectionItems[key]['content_selection']));
      this.form.get('setting_locales.'+language_Id+'.contentSelection').patchValue(data);
      this.cdr.detectChanges();
    }

    onSelectionChanged(event: Event, language_Id) {
      if(Array.isArray(event)){
        if(event.length == 0){
          this.contentSelectionItems[language_Id] = [];
        }else{
          this.contentSelectionItems[language_Id] = [];
          Object.keys(event).forEach((key) =>
            this.contentSelectionItems[language_Id].push(event[key] as any)
          );
        }
      }

      this.updateSelection(this.contentSelectionItems[language_Id], language_Id);
    }

}


