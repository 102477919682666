import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { MarketingWeeklyReport } from '@core/models/marketing-weekly-report.model';

@Injectable()
export class MarketingWeeklyReportDataService extends DefaultDataService<MarketingWeeklyReport>  {

  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('WeeklyMonthlyReport', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<MarketingWeeklyReport[]> {
    return this.http.get<ApiResponse>('/report/weeklymonthlyreport').pipe(
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<MarketingWeeklyReport[]>{
    return this.http.get<ApiResponse>(`/report/weeklymonthlyreport?${pageParam}`).pipe(
      map(res => res.data.rows)
    );
  }

  exportReport(pageParam: string) {
    return this.http.get<ApiResponse>(`/report/exportweeklymonthlyreport${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

}
