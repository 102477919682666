// Services
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { VipSettingsDataService } from './services/vip-settings-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';
// Angular
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
// Model & Enum
import { Pagination } from '@core/models/pagination.model';
import { Status } from '@core/enums/status.enum';
// RXJS & Other
import { tap, map } from 'rxjs/operators';
import { Observable, Subscription, of } from 'rxjs';
import moment from 'moment';
import { VipSettingsEditDialogComponent } from './dialogs/vip-settings-edit.component';
import { CurrencyHttpService } from '@core/services/currency-http.service';

@Component({
  templateUrl: './vip-settings.component.html',
  styleUrls: ['./vip-settings.component.scss']
})
export class VipSettingsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  statuses = Status;
  vipSettings$: Observable<[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';

  dropdown = {
    perPage: this.dropdownHttpService.perPage,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    memberGroupTypes: this.dropdownHttpService.memberGroupTypes,
  };
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  canCreateVipSettings: boolean;
  canEditVipSettings: boolean;

  sortingConfig = {
    'id': 'desc',
    'name': 'desc',
    'currency_id': 'desc',
    'status': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc'
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  private subscriptions = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private vipSettingsDataService: VipSettingsDataService,
    private dropdownHttpService: DropdownHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.onSubmit();
    this.pagination = this.vipSettingsDataService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateVipSettings = appPermissions.create_vip_settings;
      this.canEditVipSettings = appPermissions.edit_vip_settings;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    this.loadingBar.start();
    pageSize = this.pageSize;
    params = this.params ? `${this.params}` : '';
    return this.vipSettings$ = this.vipSettingsDataService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.dataLength = res.length;
        this.pagination = this.vipSettingsDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, item?: any) {
    if (type === 'edit') {
      this.openDialogBy(VipSettingsEditDialogComponent, { mode: 'edit', vipSettings: item });
    } else {
      this.openDialogBy(VipSettingsEditDialogComponent, { mode: 'create' });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = this.params ? `&${this.params}` : '';
        this.page = 1;
        this.onViewPageBy();
      }),
    ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { mode: string, vipSettings?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        vipSettings: data.vipSettings,
        mode: data.mode,
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onSubmit();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      currency_id: new FormControl('all'),
      status: new FormControl('1'),
      member_group_type: new FormControl('all'),
    });
  }

  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page);
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if(key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if(this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }
}
