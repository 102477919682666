import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { IpWhitelisting } from '@core/models/ip-whitelisting.model';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class IpWhitelistingDataService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(private http: HttpClient) { }

  getAll(): Observable<IpWhitelisting[]> {
    return this.http.get<ApiResponse>('/whitelist').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<IpWhitelisting[]>{
    return this.http.get<ApiResponse>(`/whitelist?${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getById(id: number): Observable<IpWhitelisting> {
    return this.http.get<ApiResponse>(`/whitelist/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  add(data: IpWhitelisting): Observable<IpWhitelisting> {
    return this.http.post<ApiResponse>(`/whitelist`, data).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  update(id: number, data: IpWhitelisting) {
    return this.http.put<ApiResponse>(`/whitelist/${id}`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
