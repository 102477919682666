import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { PromotionMessage } from '@core/models/promotion-message.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PromotionMessageDetailsComponent } from '../promotion-message-details/promotion-message-details.component';
import { PromotionMessageDataService } from './services/promotion-message-data.service';
import { PromotionMessageEntityService } from './services/promotion-message-entity.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-promotion-messages',
  templateUrl: './promotion-messages.component.html',
  styleUrls: ['./promotion-messages.component.scss']
})
export class PromotionMessagesComponent implements OnInit, OnDestroy {

  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };
  promotionMessages$: Observable<PromotionMessage[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';

  // permissions
  canAddPromotionName: boolean;
  canEditPromotionName: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { promotionId: number },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PromotionMessageDetailsComponent>,
    private promotionMessageSerivce: PromotionMessageEntityService,
    private promotionMessageDataService: PromotionMessageDataService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.reload();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canAddPromotionName = appPermissions.add_promotion_name;
      this.canEditPromotionName = appPermissions.edit_promotion_name;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onOpenDialog(mode: string, promotionMessage?: PromotionMessage) {
    this.dialog.open(PromotionMessageDetailsComponent, {
      width: '1300px',
      data: {
        promotionMessage,
        mode,
        promotionId: this.data.promotionId
      }
    }).afterClosed().subscribe(res => this.reload());
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    return this.promotionMessages$ = this.promotionMessageDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}&promotion_id=${this.data.promotionId}`).pipe(
      tap(res => {
        this.pagination = this.promotionMessageDataService.pagination;
      })
    );
  }

  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }


  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key =>  (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page);
  }

}
