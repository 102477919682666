import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { IconLibrary } from '@core/models/icon-library.model';

@Injectable()
export class IconLibraryEntityService extends EntityCollectionServiceBase<IconLibrary> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('IconLibrary', serviceElementsFactory);
  }
}
