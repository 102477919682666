<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Change Timezone</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
          </div>
          <div class="modal-body">
            <div class="col-md-12">
                <div class="col-12 row">
                    <label class="col-3 kt-form__label col-form-label">Timezone</label>
                    <div class="col-9 kt-form__control">
                        <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'dropdown.timezones'
                            [dropdownSettings] = 'timezonesDropdownSettings'
                            [formName] = "'timezone'"
                            [selectionAttributes] = "'timezone'"
                            [selectedItems] = 'timezonesSelectedItems'>
                        </kt-dropdown-wo-lazyload> 
                    </div>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
            <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onChangeTimezone(this.form.value.timezone)"></kt-submit-button>
          </div>
      </div>
    </form>
  </div>
  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)" [icon]="!!hasError ? 'info' : 'error'"></kt-swal-alert>