import { tap, catchError } from 'rxjs/operators';
import { Subscription, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { GameTagsDataService } from '../services/game-tags-data.service';
import { GameTags } from '@core/models/game-tags.model';
import { AvailableLocaleHttpService } from '@core/services/available-locale-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-game-tags-edit',
  templateUrl: './game-tags-edit.component.html',
  styleUrls: ['./game-tags-edit.component.scss']
})
export class GameTagsEditDialogComponent implements OnInit, OnDestroy {

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;
  form: FormGroup;

  messages$ = this.gameTagsDataService.messages$;
  refreshStatus: boolean;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
  };
 
  availableLocales = [];
  searchAvailableLocales: any;
  availableLocaleList = [];
  hideLocale = true;
  editmode = false;
  siteID = 1;
  gameTagName: any;

  // permissions
  canCreateGameTag: boolean;
  canEditGameTag: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  buttonLoading = false;
  checkrender = false;
  checkValidation = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { gameTag: any, mode: string },
    public dialogRef: MatDialogRef<GameTagsEditDialogComponent>,
    private gameTagsDataService: GameTagsDataService,
    private dropdownHttpService: DropdownHttpService,
    private availableLocaleHttpService: AvailableLocaleHttpService,
    private cdr: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if (this.data.mode == 'edit') {
      this.gameTagName = this.data.gameTag.name.toLowerCase();
    }
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateGameTag = appPermissions.create_game_tag;
      this.canEditGameTag = appPermissions.edit_game_tag;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(gameTags: GameTags, mode?: string) {
    this.buttonLoading = true;
    this.checkValidation = true;

    if (this.form.valid) {
      this.buttonLoading = true;
      // To set "Save" button to disable (To prevent call API in multiple times when double click)
      this.form.setErrors({ 'invalid': true });
   
      const data = {
        ...this.form.value,
        site_id: this.siteID
      };

      switch (mode) {
        case 'edit':
          this.subscription = this.gameTagsDataService.updateGameTag(gameTags.id, data).pipe(
            tap((res: any) => {
              this.messages$.next([...res.message]);
              this.buttonLoading = false;
              this.checkValidation = false;
              this.onCloseDialog();
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.checkValidation = false;
              this.form.setErrors(null);
              this.form.enable();
              throw error;
            })
          ).subscribe();
          break;
        case 'create':
          this.subscription = forkJoin([
            this.gameTagsDataService.createGameTag(data).pipe(
              tap((res: any) => {
                this.form.setErrors(null);
                this.buttonLoading = false;
                this.checkValidation = false;
                this.onCloseDialog();
              }),
              catchError((error) => {
                this.buttonLoading = false;
                this.checkValidation = false;
                this.form.setErrors(null);
                this.form.enable();
                throw error;
              })
            ),
            this.gameTagsDataService.messages$
          ]).subscribe();
          break;
      }
      this.refreshStatus = true;
    }
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(this.data.mode === 'edit' ? this.data.gameTag.name : null, [Validators.required]),
      position: new FormControl(this.data.mode === 'edit' ? this.data.gameTag.position : 99, [Validators.required, Validators.max(99)]),
      status: new FormControl({ value: this.data.mode === 'edit' ? this.data.gameTag.status : 1, disabled: this.data.mode === 'create' ? true : false }, Validators.compose(this.data.mode === 'edit' ? [Validators.required] : null)),
    });

    this.onSelectMerchant(this.siteID);

    this.form.markAllAsTouched();
    this.form.markAsDirty();
  }

  onSelectMerchant(site_id) {
    this.form.removeControl('game_tag_locales');
  
    return this.availableLocaleHttpService.getAvailableLocale(`?site_id[]=${site_id}`).pipe(
    tap((res) => {
        this.availableLocales = res;
        if (this.availableLocales.length == 0) {
        this.hideLocale = true;
        } else {
        this.generateLocale();
        this.cdr.detectChanges();
        // Create / Edit
        this.availableLocales.forEach((locale: any) => {
            // Create
            if(this.data.mode === 'create' || this.data.mode === 'edit') {
            this.form.patchValue({
                game_tag_locales: {
                [locale.id]: {
                    setting_locale_id: locale.id
                }}
            });
            }

            // Edit (Form content)
            if(this.data.mode === 'edit' && this.data.gameTag.game_tag_locales.length >= 1) {
            this.data.gameTag.game_tag_locales.forEach((gametag: any) => {
                if(locale.id === gametag.setting_locale_id) {
                this.form.patchValue({ game_tag_locales: { [locale.id]: { ...gametag }}})
                }
            });
            }
        });
        this.hideLocale = false;
        }
    })
    ).subscribe();
  }

  private generateLocale() {

    const buildLocales = () => {
      let fields = {};
      if (this.availableLocales.length > 0) {
        this.availableLocales.map((element: any) => {
          const subFields = new FormGroup({
            setting_locale_id: new FormControl(null),
            display_name: new FormControl(null),
          });
          fields = { ...fields, [element.id]: subFields };
        });
      }
        return fields;
    };
    this.form.addControl('game_tag_locales', new FormGroup(buildLocales()));
  }
}
    

  

  

