<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog tooltip-container">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ 'Edit Provider Setting' | translate }} ({{data.credentials.name}})</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="col-md-12">
            <div class="col-12 form-group row">
              <label class="col-3 col-form-label control-label">{{ 'Region' | translate }} <span class="text-danger">*</span></label>
              <input type="region" formControlName="region" class="col-9 form-control" />
            </div>
            <div class="col-12 form-group row">
              <label class="col-3 col-form-label control-label">{{ 'Smart Route' | translate }}</label>
              <div class="custom-control custom-switch ml-2">
                <input type="checkbox" class="custom-control-input" id="smart_route" (click)="onToggleSmartRoute($event)" [checked]="form.value.smart_route ? true : false">
                <label class="custom-control-label" for="smart_route"></label>
              </div>
            </div>
            <div class="col-12 form-group row" *ngIf="showRouteIdField == true">
              <label class="col-3 col-form-label control-label">{{ 'Preferred Route ID' | translate }}<span class="text-danger">*</span></label>
              <select formControlName="route_id" class="col-9 form-control" (click)="markAsClicked()" [ngClass]="((data.routeJustSwitchedOff || data.transactionalJustSwitchedOff || data.transactionalJustSwitchedOn) && !routeFieldClicked) ? 'red-border' : ''">
                <option value="null" disabled hidden>{{ 'Please Select' | translate }}</option>
                <option *ngFor="let item of dropdown.routeList" [value]="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="col-12 form-group row">
              <label class="col-3 col-form-label control-label">{{ 'Shorten URL' | translate }}</label>
              <div class="custom-control custom-switch ml-2">
                <input type="checkbox" class="custom-control-input" id="shorten_url" (click)="onToggleShortenURL($event)" [checked]="form.value.shorten_url ? true : false">
                <label class="custom-control-label" for="shorten_url"></label>
              </div>
            </div>
            <div class="col-12 form-group row">
                <label class="col-3 col-form-label control-label">{{ 'Transactional' | translate }}</label>
                <div class="custom-control custom-switch ml-2">
                  <input type="checkbox" class="custom-control-input" id="transactional" (click)="onToggleTransactional($event)" [checked]="form.value.transactional ? true : false">
                  <label class="custom-control-label" for="transactional"></label>
                </div>
              </div>
            <div class="col-12 form-group row">
              <label class="col-3 col-form-label control-label">{{ 'Status' | translate }}<span class="text-danger">*</span></label>
              <select formControlName="status" class="col-9 form-control">
                <option [value]="i" *ngFor="let value of dropdown.status; let i = index">{{ value }}</option>
              </select>
            </div>
          </div>
  
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>{{ 'Close' | translate }}</button>
          <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
        </div>
      </div>
    </form>
  </div>
  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true)"></kt-swal-alert>