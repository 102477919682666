import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'kt-pdf-dialog',
  templateUrl: './pdf-dialog.component.html',
  styleUrls: ['./pdf-dialog.component.scss']
})
export class PdfDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { pdfSrc: any },
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<PdfDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.data.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.pdfSrc);
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

}
