import { DatePipe, DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { environment } from '@env/environment';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { accessSections, loggedUser, specialPermissions } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import Echo from 'laravel-echo';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, delay, exhaustMap, map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MemberInformationDialogComponent } from '../members/dialogs/member-information/member-information.component';
import { AppState } from './../../../../../store/reducers';
import { MemberDataService } from './../members/services/member-data.service';
import { WithdrawalEditDialogComponent } from './dialogs/withdrawal-single/withdrawal-edit.component';
import { WithdrawDataService } from './services/withdraw-data.service';
import { WithdrawEntityService } from './services/withdraw-entity.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { CryptoTokenHttpService } from '@core/services/crypto-token-http.service';
import { ShowRemarkHistoryComponent } from '../deposits/dialogs/show-remark-history/show-remark-history.component';
import { AppPermissionService } from '@core/services/app-permission.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.scss'],
  providers: [DatePipe]
})
export class WithdrawalComponent implements OnInit, OnDestroy {
  @Input()
  withdrawalBankType: string;

  form: FormGroup;
  @ViewChildren('filterInput') filterInput: QueryList<ElementRef>;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  isAdmin = JSON.parse(localStorage.getItem('user_data')).is_admin;
  // userPermissionsRisky = this.isAdmin === 1 ? true : (localStorage.getItem('user_permissions') !== null ? JSON.parse(localStorage.getItem('user_permissions')).process_risky_withdrawals : true);
  userPermissions$ = this.store.pipe(select(specialPermissions));
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: [
      {
        name: 'Pending', value: 0, checked: true
      },
      {
        name: 'Risky', value: 4, checked: true
      },
      {
        name: 'In Progress', value: 3, checked: true
      },
      {
        name: 'Incomplete Payout', value: 5, checked: true
      },
      {
        name: 'Approved', value: 1, checked: false
      },
      {
        name: 'Rejected', value: 2, checked: false
      }
    ],
    dateTimeFilterType : [
      {
        key: 'created_at',
        label: 'Created At'
      },
      {
        key: 'updated_at',
        label: 'Updated At'
      },
      {
        key: 'approved_at',
        label: 'Approved Time'
      },
    ],
    currency_type : [
      {
        key: '2',
        label: 'Crypto'
      },
      {
        key: '1',
        label: 'Local'
      },
    ],
    merchantBanks: [],
    merchantBankAccounts: [],
    groups: this.dropdownHttpService.groups,
    paymentMethods: this.dropdownHttpService.paymentMethods,
    perPage: this.dropdownHttpService.perPage
  };
  searchStatus = [0, 3, 4, 5];
  withdrawals$: Observable<any[]>;
  disabledEditButton = false;
  echo: Echo;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = 'status[0]=0&status[1]=3'; // TODO: To refactor soon
  ranges: any;
  currentOperatorId = JSON.parse(localStorage.getItem('user_data')).id;
  editWithdrawRoomId = '';
  extendWithdrawRoomId = '';

  currentUserId: number;
  currentUsername: string;
  currentBoCurrencyIds: Array<number>;

  createWithdrawals: boolean;
  editWithdrawals: boolean;
  createRightsOnly: any;

  selectedCurrency = { name: 'all' };

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '2.2';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'status': 'desc',
    'currency': 'desc',
    'amount': 'desc',
    'processing_fee': 'desc',
    'member_group': 'desc',
    'confirmed_amount': 'desc',
    'cr_exchange_rate': 'desc',
    'member_bank_account': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
    'approved_at': 'desc',
    'handler': 'desc',
    'cr_amount': 'desc',
    'cr_processing_fee': 'desc',
    'cr_confirmed_amount': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  originalParams = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  access$ = this.authHttpService.getUserAccess(2, 'Withdrawals');
  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  // permissions
  canViewWithdrawals: boolean;
  canViewWithdrawalAmount: boolean;
  canViewMemberDialog: boolean;
  canCreateWithdrawal: boolean;
  canViewWithdrawalDetails: boolean;
  canExportWithdrawal: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private datePickerSubscription = new Subscription();

  dropdownSettings = {};
  bankaccountDropdownSettings = {};
  bankaccountDropdownList = [];
  bankaccountSelectedItems: any;
  bankSelectedItems: any;

  tokensDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'id',
    labelKey: 'name',
    noDataLabel: '',
    showCheckbox: false
  };
  tokensDropdownList = [];
  tokensSelectedItems: any;
  button_loading = false;
  messages$ = this.withdrawDataService.messages$;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  constructor(
    private loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private withdrawEntityService: WithdrawEntityService,
    private withdrawDataService: WithdrawDataService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private merchantBankHttpService: MerchantBankHttpService,
    private memberDataService: MemberDataService,
    private store: Store<AppState>,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private cryptoTokenHttpService: CryptoTokenHttpService,
    private appPermissionService: AppPermissionService,
    private decimalPipe: DecimalPipe,
    private translateService: TranslateService,
  ) {
    const SocketIoClient = require('socket.io-client');
    const socketHost: string = environment.socketHost;
    const user_token = JSON.parse(localStorage.getItem('user_token'));
    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        client: SocketIoClient,
        host: socketHost,
        auth: {
          headers: {
              'access-token': user_token.access_token,
              'token-selector': user_token.plaintext_token,
              'X-User-Model': 'bo'
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit() {
    this.setCurrencyDropdown();
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));

    // const permissions = JSON.parse(localStorage.getItem('user_access'));
    // this.createWithdrawals = permissions[2][2]?.create;
    // this.editWithdrawals = permissions[2][2]?.edit;
    // this.createRightsOnly = this.createWithdrawals && !this.editWithdrawals;
    this.store.pipe(select(accessSections)).subscribe(access => {
      this.createWithdrawals = access[2][2]?.create;
      this.editWithdrawals = access[2][2]?.edit;
      this.createRightsOnly = this.createWithdrawals && !this.editWithdrawals;
    })

    this.pagination = this.withdrawDataService.pagination;
    this.formInit();
    
    // If redirect from Notification (Unusual callback)
    if(this.withdrawalBankType == 'banking' && localStorage.getItem('NotificationUnusualCallback')) {
      this.form.patchValue({
        status: null
      });
      this.searchStatus = [];
      this.dropdown.statuses[0].checked = false;
      this.dropdown.statuses[1].checked = false;
      this.dropdown.statuses[2].checked = false;
      this.dropdown.statuses[3].checked = false;
      localStorage.removeItem('NotificationUnusualCallback');
    }
    this.onSubmit(true);
    this.store.pipe(select(loggedUser)).subscribe((res) => {
      this.currentUserId = res.id;
      this.currentUsername = res.username;
      this.currentBoCurrencyIds = res.bo_currency_ids;
    });
    this.originalParams = this.params;
    this.ranges = this.transactionHttpService.ranges;

    this.cryptoTokenHttpService.getTokens().subscribe(res => {
      this.tokensDropdownList = res;
      this.tokensDropdownList.unshift({
        id: 'all',
        name: 'All'
      });
      this.tokensSelectedItems = [this.tokensDropdownList.find(v => v.id === 'all')];
    });

    this.bankaccountDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'name',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };

    this.merchantBankHttpService.getMerchantBanksAccount('&purpose=2').subscribe(
      res => {
        res.map(item => {
          item.name = item.bank_code + ' - ' + item.account_name + ' - ' + item.account_number;
        });
        this.dropdown.merchantBankAccounts = res;
        this.dropdown.merchantBankAccounts.unshift({
          id: 'all',
          name: 'All'
        });
        this.bankaccountDropdownList = this.dropdown.merchantBankAccounts;
        this.bankaccountSelectedItems = [this.bankaccountDropdownList.find(v => v.id === 'all')];
      }
    );

    this.dropdownHttpService.merchantBanks.subscribe(res => {
      this.dropdown.merchantBanks = res;
      this.dropdown.merchantBanks.unshift({
        id: 'all',
        name: 'All'
      });
      this.bankSelectedItems = [this.dropdown.merchantBanks.find(v => v.id === 'all')];
    });
    this.updateTransaction();

    this.accountTypeDropdownList = this.dropdownHttpService.accountTypesLists;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];

    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    // this.withdrawalsUseNewPermissions = this.appPermissionService.isSectionUsingNewPermissions('withdrawals');
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewWithdrawals = this.withdrawalBankType == 'crypto' ? appPermissions.view_crypto_withdrawals : appPermissions.view_banking_withdrawals;
      this.canViewWithdrawalAmount = this.withdrawalBankType == 'crypto' ? appPermissions.view_crypto_withdrawal_amount : appPermissions.view_banking_withdrawal_amount;
      this.canViewMemberDialog = this.withdrawalBankType == 'crypto' ? appPermissions.transaction_withdrawals_crypto_view_member_dialog : appPermissions.transaction_withdrawals_banking_view_member_dialog;
      this.canCreateWithdrawal = this.withdrawalBankType == 'crypto' ? appPermissions.create_crypto_withdrawals : appPermissions.create_withdrawals;
      this.canViewWithdrawalDetails = this.withdrawalBankType == 'crypto' ? appPermissions.view_crypto_withdrawal_details : appPermissions.view_withdrawal_details;
      this.canExportWithdrawal = this.withdrawalBankType == 'crypto' ? appPermissions.export_crypto_withdrawals_list : appPermissions.export_withdrawals_list;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);

    // Add transaction event emitter
    let addTransSub;
    if( this.withdrawalBankType == 'crypto' ) {
      addTransSub = this.eventEmitterService.addTransactionCrypto.subscribe((res) => {
        if (res.action === 'extend') {
          this.withdrawSingleActiveSessionWebsocket(res.withdraw.id, true);
        } else {
          this.leaveWithdrawSingleActiveSessionRoom(this.extendWithdrawRoomId);
        }
      });
    } else {
      addTransSub = this.eventEmitterService.addTransaction.subscribe((res) => {
        if (res.action === 'extend') {
          this.withdrawSingleActiveSessionWebsocket(res.withdraw.id, true);
        } else {
          this.leaveWithdrawSingleActiveSessionRoom(this.extendWithdrawRoomId);
        }
      });
    }
    this.subscriptions.add(addTransSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    this.echo.disconnect();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenSelect() {
    of(null).pipe(
      delay(0), tap(() => this.filterInput.first.nativeElement.focus()
      )).subscribe();
  }

  onOpenDialog(type: string, id?: number) {
    this.disabledEditButton = true;
    if (id) {
      if (type === 'show-remarks') {
        this.openDialogBy(ShowRemarkHistoryComponent, false, { mode: type, id, remarkType: 'withdraw' });
        this.disabledEditButton = false;
      }else if (type !== 'member-information') {
        this.withdrawSingleActiveSessionWebsocket(id, true)
          .then((res) => {
            if (res) {
              const withdraw = this.withdrawEntityService.getByKey(id);
              this.subscription = withdraw.pipe(
                tap((res) => {

                  // prompt take over confirmation dialog
                  const fireTakeOverConfirmation = () => {
                    Swal.fire({
                      title: 'Someone is on the same withdrawal!',
                      text: 'Do you want to take over?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes',
                      reverseButtons: true,
                    }).then((result) => {
                      if (result.value) {
                        const dialogRef = this.openDialogBy(WithdrawalEditDialogComponent, true, { withdraw: res, mode: 'edit', newHandler: this.currentUsername, withdrawalBankType: this.withdrawalBankType });
                        this.withdrawSingleActiveSessionWebsocket(res.id, false, dialogRef);
                      }
                      this.disabledEditButton = false;
                    });
                  };

                  // prompt error dialog
                  const fireActionFailed = (text: string) => {
                    Swal.fire({
                      title: "Action Failed",
                      text,
                      icon: "error",
                      confirmButtonColor: "#3085d6",
                      confirmButtonText: "OK",
                    });
                    this.disabledEditButton = false;
                  };

                  // open withdrawal detail dialog
                  const fireOpenDetailDialog = () => {
                    const dialogRef = this.openDialogBy(WithdrawalEditDialogComponent, true, { withdraw: res, mode: 'edit', withdrawalBankType: this.withdrawalBankType });
                    this.withdrawSingleActiveSessionWebsocket(res.id, false, dialogRef);
                    this.disabledEditButton = false;
                  };

                  // logic
                  if (res.bo_user_id > 0 && res.bo_user_id !== this.currentUserId) {
                    switch( res.status ) {
                      case 1: // Approved
                      case 2: // Rejected
                        fireOpenDetailDialog();
                        break;
                      case 0: // Pending
                      case 3: // In progress
                      case 4: // Risky
                      case 5: // Incomplete status
                        fireTakeOverConfirmation();
                        break;
                    }
                  } else {
                    fireOpenDetailDialog();
                  }
                })).subscribe();
                this.leaveWithdrawSingleActiveSessionRoom(this.extendWithdrawRoomId);
            } else {
              this.disabledEditButton = false;
            }
          })
      } else {
        if (!this.canViewMemberDialog) {
          Swal.fire({
            title: 'Permission Denied',
            text: 'You do not have permission to view member dialog',
            icon: 'error'
          });
          this.disabledEditButton = false;
          return;
        }

        const member = this.memberDataService.getById(id, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
        this.subscription = member.pipe(
          tap((res) => {
            if (res) {
              this.openDialogBy(MemberInformationDialogComponent, false, { member: res, mode: 'member-information' });
              this.disabledEditButton = false;
            }
          })
        ).subscribe();
      }
    } else {
      this.openDialogBy(WithdrawalEditDialogComponent, false, { mode: 'create', withdrawalBankType: this.withdrawalBankType });
      this.disabledEditButton = false;
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.withdrawals$ = this.withdrawEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.withdrawDataService.pagination;
        this.eventEmitterService.withdrawalUpdate.emit({'isTypeCrypto': (this.withdrawalBankType == 'crypto' ? 1 : 0), 'total': this.pagination.total});
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onSearchStatus(event: any, id: number, ind: number) {
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }
    this.form.patchValue({ status: this.searchStatus });
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onExport() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        data['is_crypto'] = this.withdrawalBankType == 'crypto' ? 1 : 0;

        data['paginate'] = false;
        data['timezone'] = this.timezone;

        if(data['account_type']){
          data['account_type'] = this.form.controls['account_type'].value.toString();
        }

        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        this.loadingBar.start();
        return this.withdrawDataService.exportWithdraw(`?${this.params}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.messages$ = this.withdrawDataService.messages$;
            this.loadingBar.complete();
            this.button_loading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.button_loading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.searchStatus = [0, 3, 4, 5];
    this.bankaccountSelectedItems = [this.bankaccountDropdownList.find(v => v.id === 'all')];
    this.bankSelectedItems = [this.dropdown.merchantBanks.find(v => v.id === 'all')];
    this.tokensSelectedItems = [this.tokensDropdownList.find(v => v.id === 'all')];
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    this.form.patchValue({
      id: null,
      username: null,
      name: null,
      currency_id: 'all',
      status: this.searchStatus,
      payment_method: 'all',
      member_group: 'all',
      merchant_bank: 'all',
      merchant_bank_account: 'all',
      tokens: 'all',
      start_date: null,
      end_date: null,
      defaultDate: null,
      unusual_callback: true,
      account_type_condition: 'Or',
      account_type: [1, 2, 3]
    });
    this.dropdown.statuses.forEach(child => {
      if (child.name !== 'In Progress' && child.name !== 'Pending' && child.name !== 'Risky' && child.name !== 'Incomplete Payout') {
        child.checked = false;
      } else {
        child.checked = true;
      }
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        data['is_crypto'] = this.withdrawalBankType == 'crypto' ? 1 : 0;
        if(data['account_type']){
          data['account_type'] = this.form.controls['account_type'].value.toString();
        }
        const statusParams = this.generateStatusParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        this.loadingBar.start();
        return this.withdrawals$ = this.withdrawEntityService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.withdrawDataService.pagination;
            this.eventEmitterService.withdrawalUpdate.emit({'isTypeCrypto': (this.withdrawalBankType == 'crypto' ? 1 : 0), 'total': this.pagination.total});
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onCurrencyChange() {
    this.selectedCurrency = this.dropdown.currencies.find(x => x.id == this.form.value.currency_id);
    if (this.selectedCurrency === undefined) {
      this.selectedCurrency = { name: 'all' };
      this.bankaccountDropdownList = this.dropdown.merchantBankAccounts;
    } else {
      this.bankaccountDropdownList = this.dropdown.merchantBankAccounts.filter(x => x.currency_id == this.form.value.currency_id);
      this.bankaccountDropdownList.unshift({
        id: 'all',
        name: 'All'
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private updateTransaction() {
    const listener = (newData) => {
      if ((this.withdrawalBankType == 'crypto' && !newData.crypto_token_id) || this.withdrawalBankType == 'banking' && newData.crypto_token_id) {
        return;
      }

      const newWithdraw = {
        ...newData,
        member_id: newData.member_account_id
      };

      const dataUpdate = (unshift = true, res: any) => {
        if (unshift){
          res.unshift(newWithdraw);
          this.pagination = {
            ...this.pagination,
            from: 1,
            to: this.pagination.to + 1,
            total: this.pagination.total + 1,
          };
        }else{
          res.pop();
          this.pagination = {
            ...this.pagination,
            to: res.length
          };
        }
        this.dataLength = res.length;
        this.eventEmitterService.withdrawalUpdate.emit({'isTypeCrypto': (this.withdrawalBankType == 'crypto' ? 1 : 0), 'total': this.pagination.total});
        this.cdr.detectChanges();
      };

      const data = {
        ...this.form.value,
        settings_currency_id: this.form.value.currency_id,
        payment_method_id: this.form.value.payment_method,
        group_id: this.form.value.member_group
      };
      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] === 'all' || data[key] < 1 || key === 'defaultDate' || key === 'status' || key === 'unusual_callback') && delete data[key]);

      const toFilter = () => {
        let isFiltered = false;
        Object.keys(data).forEach(key => {
          if (key === 'end_date' || key === 'start_date'){
            var startDate = moment(data.start_date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
                endDate = moment(data.end_date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
                date = moment(newWithdraw.created_at).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
                if (this.withdrawalBankType == 'crypto') {
                  if (data['datetime_type'] == 'updated_at') {
                    date = moment(newWithdraw.updated_at).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');

                  } else if (data['datetime_type'] == 'approved_at') {
                    date = moment(newWithdraw.approved_at).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
                  }
                }
            if (!moment(date).isBetween(startDate, endDate)){
              return isFiltered = true;
            }
          } else if (key === 'username' || key === 'name'){
            if (!(newWithdraw[key].toString()).includes(data[key].toString())) {
              return isFiltered = true;
            }
          } else if (key === 'currency_type' || key === 'datetime_type') {
            return isFiltered = false;
          } else if (key === 'amount' || key === 'datetime_type') {
            if (data['currency_type'] == 1) {
              if (!(parseInt(newWithdraw['confirmed_amount']) == parseInt(data[key]))) {
                return isFiltered = true;
              }
            } else if (data['currency_type'] == 2) {
              if (!(parseInt(newWithdraw['confirmed_crypto_amount']) == parseInt(data[key]))) {
                return isFiltered = true;
              }
            }
          }else{
            // TODO: commenting this will let listener ignore all filters other than if else-if filters above,
            //       table will proceed to add/update data
            // if (newWithdraw[key] !== undefined && newWithdraw[key].toString() !== data[key].toString()) {
            //   return isFiltered = true;
            // }
          }
        });
        return isFiltered;
      };

      if (this.page === 1 && this.searchStatus.includes(0) && newWithdraw.type === 'new'){
        this.withdrawals$.subscribe(res => {
          const isFiltered = toFilter();
          setTimeout(() => {
            if (!isFiltered){
              dataUpdate(true, res);
              if (res.length > this.pageSize) {
                dataUpdate(false, res);
              }
            }
          }, 1500);
        });
      }
      if (newWithdraw.type !== 'new'){
        this.withdrawals$.subscribe(res => {
          const objIndex = res.findIndex((obj => obj.id === newWithdraw.id));
          if (objIndex !== null && objIndex !== undefined){
            res[objIndex] = {
              ...res[objIndex],
              ...newWithdraw
            };
            const isFiltered = toFilter();
            if ((objIndex >= 0) && (isFiltered || !this.searchStatus.includes(+newWithdraw.status) === true)){
              res.splice(objIndex, 1);
              this.pagination = {
                ...this.pagination,
                from: res.length === 0 ? 0 : 1,
                to: res.length === 0 ? 0 : this.pagination.to - 1,
                total: res.length === 0 ? 0 : this.pagination.total - 1,
              };
              this.dataLength = res.length;
            }
            if (!isFiltered && this.page === 1 && this.searchStatus.includes(+newWithdraw.status) && objIndex < 0){
              dataUpdate(true, res);
            }
            this.cdr.detectChanges();
          }
        });
      }
    };

    const withdrawChannel = this.echo.channel('withdraw-channel');
    const currencyIds = this.currentBoCurrencyIds;

    currencyIds.forEach(currencyId => {
      withdrawChannel.listen(`.WithdrawEvent-${currencyId}`, listener);
    });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private openDialogBy(componentRef: any, disableClose: boolean, data?: { withdraw?: any, mode?: any, member?: any, newHandler?: string, id?: any, remarkType?: any, withdrawalBankType?: any }) {
    const dialogWidth = data.mode === 'edit' ? '90%' : data.mode === 'member-information' ? '1500px' : '800px';
    const dialogRef = this.dialog.open(componentRef, {
      width: dialogWidth,
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data: {
        withdraw: data.withdraw,
        mode: data.mode,
        member: data.member,
        newHandler: data.newHandler,
        id: data.id,
        remarkType: data.remarkType,
        withdrawalBankType: data.withdrawalBankType
      },
      autoFocus: data.mode === 'member-information' || 'create' ? false : true,
      disableClose: disableClose
    });
    if (data.mode !== 'member-information') {
      dialogRef.afterClosed().subscribe((result) => {
        this.leaveWithdrawSingleActiveSessionRoom(this.editWithdrawRoomId);
        this.leaveWithdrawSingleActiveSessionRoom(this.extendWithdrawRoomId);
        this.editWithdrawRoomId = this.extendWithdrawRoomId = '';
      });
      return dialogRef;
    }
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null),
      name: new FormControl(null),
      currency_id: new FormControl('all'),
      status: new FormControl(this.searchStatus),
      payment_method: new FormControl('all'),
      member_group: new FormControl('all'),
      merchant_bank: new FormControl('all'),
      merchant_bank_account: new FormControl('all'),
      crypto_token_id: new FormControl('all'),
      currency_type: new FormControl(this.withdrawalBankType == 'crypto' ? this.dropdown.currency_type[0].key: null),
      amount: new FormControl(null),
      datetime_type: new FormControl(this.withdrawalBankType == 'crypto' ? this.dropdown.dateTimeFilterType[0].key: null),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      unusual_callback: new FormControl(true),
      account_type_condition: new FormControl('Or'),
      account_type: new FormControl([1, 2, 3])
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate' && formData[key] !== false) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private generateStatusParams() {
    return {
      newStatusParams: Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&'),
      oldStatusParams: 'status=' + Object.keys(this.searchStatus).map(key => this.searchStatus[key]).join(','),
    };
  }

  async withdrawSingleActiveSessionWebsocket(withdrawId, extend = false, dialogRef = null) {
    return new Promise(async (resolve, reject) => {
      let roomId = '';
      if (extend) {
        this.extendWithdrawRoomId = roomId  = `extend-withdraw-room.${withdrawId}`;
      } else {
        this.editWithdrawRoomId = roomId  = `withdraw-room.${withdrawId}`
      }
      const room = this.echo.join(roomId);
      const fireSessionExpiredSwal = (text: string) => {
        Swal.fire({
          title: "Action Failed",
          text,
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
          if (dialogRef) {
            dialogRef.close(true);
          }
        });
      };

      const startEditingSession = () => {
        setTimeout(() => {
          this.leaveWithdrawSingleActiveSessionRoom(this.extendWithdrawRoomId);
        }, 5 * 60 * 1000);
      };

      try {
        await room
          .here(async (users) => {
            if (extend) {
              if (users.length > 1) {
                this.leaveWithdrawSingleActiveSessionRoom(this.extendWithdrawRoomId);
                if (users[0].id === users[1].id) { //Previous user is same as current user
                  fireSessionExpiredSwal('This withdrawal is currently being processed on the payment gateway on another tab.');
                } else {
                  fireSessionExpiredSwal('This withdrawal is current being processed on the payment gateway by another operator.');
                }
                resolve(false);
              } else {
                startEditingSession();
                resolve(true);
              }
            }
          })
          .joining((user) => {
            if (!extend) {
              const otherOperatorTakeover = user.id !== this.currentOperatorId;
              if (otherOperatorTakeover) {
                fireSessionExpiredSwal(
                  "This withdrawal has been taken over by another operator."
                );
                return;
              }
              // same operator opens same withdrawal on another tab
              fireSessionExpiredSwal("Edit withdrawal is opened on another tab.");
            }
          })
          .leaving((user) => {
          });
      } catch (error) {
        console.error('An error occurred:', error);
        reject(error);
      }
    });
  }

  leaveWithdrawSingleActiveSessionRoom(roomid = null) {
    if (roomid) {
      this.echo.leave(roomid);
    }
  }

  canShowAmount(amount: number, format: string) {
    let retAmount = '***';
    if( this.canViewWithdrawalAmount ) {
      retAmount = this.decimalPipe.transform(amount, format);
    }
    return retAmount;
  }
}
