import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class SendSMSHttpService {

  messages$ = new Subject<any[]>();
  constructor(private http: HttpClient) { }

  sendSms(messageContent: any) {
    return this.http.post<ApiResponse>(`/smsprovider/send`, messageContent).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  sendBulkSms(messageContent: any) {
    return this.http.post<ApiResponse>(`/smscampaign`, messageContent).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }
}
