import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';
import { Promotion } from '@core/models/promotion.model';

@Injectable()
export class PromotionSettingHttpService {

  constructor(private http: HttpClient) { }

  getTargetTypes(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>('/promotion/settings').pipe(
      map(res => res.data.target_types)
    );
  }

  getPromoTypes(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>('/promotion/settings').pipe(
      map(res => res.data.promo_types)
    );
  }

  getPromoCategories(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>('/categories').pipe(
      map(res => res.data.rows)
    );
  }

  getPromoContentCategories(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>('/categorypromocontents').pipe(
      map(res => res.data.rows)
    );
  }

  getAllPromoSettings() {
    return this.http.get<ApiResponse>('/promotion/settings').pipe(
      map(res => res.data)
    );
  }

  getApplyActions() {
    return this.http.get<ApiResponse>('/promotioncontent/applyAction').pipe(
      map(res => res.data.rows)
    );
  }

  getFillInForm() {
    return this.http.get<ApiResponse>('/promotioncontent/fillInForm').pipe(
      map(res => res.data.rows)
    );
  }
}
