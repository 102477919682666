import { AllSubAffiliateReport } from '@core/models/all-sub-affiliate-report.model';
import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class AllSubAffiliateReportDataService extends DefaultDataService<AllSubAffiliateReport> {
  messages$ = new Subject<any[]>();
  pagination: Pagination;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('AllSubAffiliateReport', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<AllSubAffiliateReport[]> {
    return this.http.get<ApiResponse>(`/report/allsubaffiliatereport${pageParam}`).pipe(
      tap((res) => this.paginationInit(res)),
      map((res) => res.data.rows)
    );
  }

  exportReport(pageParam: string) {
    return this.http.get<ApiResponse>(`/report/exportallsubaffiliatesreport${pageParam}`).pipe(
        tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      return (this.pagination = res.data.paginations);
    }
  }
}
