
<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title"> Remarks</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div class="container p-0">
                <div  class="kt-section__content">
                    <div  class="table-responsive">
                        <table  class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th>Created At</th>
                                    <th>Operator</th>
                                    <th>Remark</th>
                                  </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let row of data.remarks; let i = index">
                                  <td>{{row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm'}}</td>
                                  <td>{{row.handler}}</td>
                                  <td>{{row.remarks}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
