<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Rebate</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-6 form-group">
                    <label class="control-label">Name <span class="text-danger">*</span></label>
                    <input formControlName="name" type="text" class="col-12 form-control">
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Minimum Rebate <span class="text-danger">*</span></label>
                    <input formControlName="min_rebate_limit" type="number" (wheel)="false" class="col-12 form-control">
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Maximum Rebate <span class="text-danger">*</span></label>
                    <input formControlName="max_rebate_limit" type="number" (wheel)="false" class="col-12 form-control">
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Percentage <span class="text-danger">*</span></label>
                    <input formControlName="percentage" type="number" (wheel)="false" class="col-12 form-control">
                </div>

            </div>

            <div class="row">
              <div class="col-md-6 form-group">
                <label class="control-label">Currencies <span class="text-danger">*</span></label>
                <kt-dropdown-wo-lazyload
                  [form] = 'form'
                  [dropdownList] = 'dropdown.currencies'
                  [dropdownSettings] = 'dropdownSettings'
                  [formName] = "'currencies'"
                  [selectionAttributes] = "'id'"
                  [selectedItems] = 'currenciesSelectedItems'
                  (selectedItemsChanged)="currenciesSelectedItems = $event">
                </kt-dropdown-wo-lazyload>
              </div>

              <div class="col-md-6 form-group">
                <label class="control-label">Categories <span class="text-danger">*</span></label>
                <kt-dropdown-wo-lazyload
                  [form] = 'form'
                  [dropdownList] = 'categoriesDropdownList'
                  [dropdownSettings] = 'dropdownSettings'
                  [formName] = "'categories'"
                  [selectionAttributes] = "'id'"
                  [selectedItems] = 'categoriesSelectedItems'
                  (selectedItemsChanged)="getSelectedCategories($event)">
                </kt-dropdown-wo-lazyload>
              </div>

              <div class="col-md-6 form-group">
                <label class="control-label">Game Providers <span class="text-danger">*</span></label>
                <ng-container *ngIf="!gameProvidersDropdownLoading; else elseTemplate">
                  <kt-dropdown-wo-lazyload
                    [form] = 'form'
                    [dropdownList] = 'gameProvidersDropdownList'
                    [dropdownSettings] = 'gameProviderDropdownSettings'
                    [formName] = "'game_providers'"
                    [selectionAttributes] = "'id'"
                    [selectedItems] = 'gameProvidersSelectedItems'
                    (selectedItemsChanged)="gameProvidersSelectedItems = $event">
                  </kt-dropdown-wo-lazyload>
                </ng-container>
                <ng-template #elseTemplate>
                  <label class="control-label"><i class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                  <kt-dropdown-wo-lazyload
                    [form] = 'form'
                    [dropdownList] = '[]'
                    [dropdownSettings] = 'gameProviderDropdownSettings'
                    [formName] = "'game_providers'"
                    [selectionAttributes] = "'id'"
                    [selectedItems] = 'gameProvidersSelectedItems'
                    (selectedItemsChanged)="gameProvidersSelectedItems = $event">
                  </kt-dropdown-wo-lazyload>
                </ng-template>

            </div>

              <div class="col-md-6 form-group">
                  <label class="control-label">Member Groups <span class="text-danger">*</span></label>
                  <kt-dropdown-wo-lazyload
                    [form] = 'form'
                    [dropdownList] = 'memberGroupsDropdownList'
                    [dropdownSettings] = 'dropdownSettings'
                    [formName] = "'member_groups'"
                    [selectionAttributes] = "'id'"
                    [selectedItems] = 'memberGroupsSelectedItems'
                    (selectedItemsChanged)="memberGroupsSelectedItems = $event">
                  </kt-dropdown-wo-lazyload>
              </div>

          </div>
        </div>
        <div class="modal-footer">
          <div class="pl-0 mr-0 col">
            <button type="button" class="btn btn-dark" *ngIf="canViewGameProviderBlacklist && gameProvidersSelectedItems.length >= 1" (click)="onBlacklistDialog(gameProvidersSelectedItems)" ><i class="fa fa-plus" aria-hidden="true"></i>BlackList</button>
          </div>
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button *ngIf="data.mode == 'create' ? canCreateRebate : canEditRebate" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.rebate, data.mode)"></kt-submit-button>
        </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
