import { Injectable } from '@angular/core';
import { BankReceipt } from '@core/models/bank-receipt.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable()
export class BankReceiptEntityService extends EntityCollectionServiceBase<BankReceipt> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('BankReceipt', serviceElementsFactory);
    }
}
