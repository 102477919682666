import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { PromotionCategory } from '@core/models/promotion-content-category.model';

@Injectable()
export class PromotionCategoryDataService extends DefaultDataService<PromotionCategory> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  locales$ = new BehaviorSubject([]);

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('PromotionCategory', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<PromotionCategory[]>{
    return this.http.get<ApiResponse>(`/promotioncontent/category${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(promotionCategory: PromotionCategory): Observable<PromotionCategory>{
    return this.http.post<ApiResponse>(`/promotioncontent/category`, promotionCategory).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  updateCategory(id: number, promotionCategory: PromotionCategory){
    return this.http.put<ApiResponse>(`/promotioncontent/category/${id}`, promotionCategory).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

}
