<!-- <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body"> -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="status" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group date">
                                        <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true"
                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                            [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                        ngxDaterangepickerMd/>
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Group:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="group_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.groups | async">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Account Type:</label>
                                  </div>
                                  <div class="col-md-2 kt-form__control">
                                    <select formControlName="account_type_condition" class="form-control" >
                                      <option value="And">AND Condition</option>
                                      <option value="Or">OR Condition</option>
                                    </select>
                                  </div>
                                  <div class="col-md-3 kt-form__control">
                                    <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
                                      [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
                                      [formName]="'account_type'" [selectionAttributes]="'id'"
                                      [selectedItems]='accountTypeSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                  </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section mb-3 mt-3">
                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th class="sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                                    <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                                    <th class="sort-enabled" (click)="onSortColumn('sms_verified')" [ngClass]="sortingConfig.sms_verified" [class.sort-selected]="sortingSelection.sort_by === 'sms_verified'">SMS Verified</th>
                                    <th>Indentity Path</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                                    <th class="sort-enabled" (click)="onSortColumn('verified_by')" [ngClass]="sortingConfig.verified_by" [class.sort-selected]="sortingSelection.sort_by === 'verified_by'">Verified By</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('verify_at')" [ngClass]="sortingConfig.verify_at" [class.sort-selected]="sortingSelection.sort_by === 'verify_at'">Verified At</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated At</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(memberVerification$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.id }}</td>
                                    <td>{{ row.name }}</td>
                                    <td [ngClass]="row.dummy == 1 ? 'text-dummy-unclickable' : 'text-primary'">{{ row.username }}</td>
                                    <td class="text-center">
                                        <span [ngClass]="'kt-badge kt-badge--inline kt-badge--pill kt-badge--wide kt-badge--' + (row.sms_verified === 1 ? '1' : '2')">{{ row.sms_verified === 0 ? "No" : "Yes" }}</span>
                                    </td>
                                    <td>
                                        <a [href]="row.identity_hold_path" target="_blank">Image 1</a> <br />
                                        <a [href]="row.identity_front_path" target="_blank">Image 2</a>
                                    </td>
                                    <td class="text-center">
                                        <span [ngClass]="'kt-badge kt-badge--inline kt-badge--pill kt-badge--wide kt-badge--' + row.status">{{ row.status_name }}</span>
                                    </td>
                                    <td>{{ row.verified_by }}</td>
                                    <td>{{ row.verified_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}</td>
                                    <td>{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}</td>
                                    <td>{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}</td>
                                    <td class="text-center">
                                        <button *ngIf="canEditMemberVerification" matTooltip="Edit" class="col-5 btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog(row)"><i class="fas fa-eye"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                        </div>
                </div>
            </div>
      <!-- </div>
  </div>
</div> -->
