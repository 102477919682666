import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FunctionSection } from '@core/models/function-section.model';

@Injectable()
export class FunctionSectionHttpService {

  constructor(private http: HttpClient) { }

  getFunctionSections(): Observable<FunctionSection[]> {
    return this.http.get<ApiResponse>('/function').pipe(
      map(res => res.data.sections)
    );
  }
}
