import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import Swal from "sweetalert2";
import { TransferDataService } from "../../services/transfer-data.service";
import { Transfer } from "@core/models/transfer.model";
import { Subscription, forkJoin } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AppPermissionService } from "@core/services/app-permission.service";

@Component({
  selector: 'status-check',
  templateUrl: './status-check.component.html',
  styleUrls: ['./status-check.component.scss']
})
export class StatusCheckDialogComponent implements OnInit, OnDestroy {

  boUser = JSON.parse(localStorage.getItem('user_data')).username;
  messages$ = this.transferDataService.messages$;
  success: boolean = false;

  // permissions
  canApproveTransfers: boolean;
  canRejectTransfers: boolean;
  canMarkAsFailed: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      id: string, // Transfer ID
      username: string,
      created_at: string,
      from: string,
      to: string,
      currency: string,
      amount: number,
      cms_status: number,
      cms_status_name: string,
      gsys_status: number,
      gsys_status_name: string
    },
    public dialogRef: MatDialogRef<StatusCheckDialogComponent>,
    private transferDataService: TransferDataService,
    private appPermissionService: AppPermissionService,
  ) {}

  ngOnInit() {
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canApproveTransfers = appPermissions.approve_transfers;
      this.canRejectTransfers = appPermissions.reject_transfers;
      this.canMarkAsFailed = appPermissions.mark_as_failed;
    })

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCloseDialog() {
    this.dialogRef.close(this.success);
  }

  onMarkAs(status: 'Failed' | 'Rejected' | 'Approved') {
    if (status === 'Failed') {
      Swal.fire({
        title: `Marked as failed by ${this.boUser}`,
        input: "text",
        showCancelButton: true,
        confirmButtonText: "Submit",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        inputPlaceholder: 'Enter reason',
        preConfirm: async (value) => {
          let transfer: Transfer;
          transfer = {
            ...transfer,
            id: +this.data.id,
            status: 4,
            remarks: value,
          };
          await this.transferDataService.updateTransfer(transfer).pipe(
            tap(res => {
              this.success = true;
            }),
            catchError(err => {
              this.success = false;
              throw err;
            })
          ).toPromise();

        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) { }
        else { }
      });
    } else {
      var approvedMsg = `Please make sure the amount has been successfully transferred from ${this.data.from} to ${this.data.to} before proceeding.`,
        rejectedMsg = `Please make sure the amount in ${this.data.from} has not been transferred to ${this.data.to} before proceeding.`,
        message = `<div class="swal2-html-container d-block">You want to mark this transfer as ${status}?</div>
        <br>
        <div class="swal2-html-container d-block text-danger">${status === 'Approved' ? approvedMsg : rejectedMsg}<div>`;
      Swal.fire({
        title: 'Are you sure?',
        html: message,
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          let transfer: Transfer;
          transfer = {
            ...transfer,
            id: +this.data.id,
            status: status == 'Approved' ? 1 : 2,
            remarks: `${status} by ${this.boUser}`,
          };
          this.transferDataService.updateTransfer(transfer).pipe(
            tap(res => {
              this.success = true;
            }),
            catchError(err => {
              this.success = false;
              throw err;
            })
          ).subscribe();
        }
      });
    }
  }

}
