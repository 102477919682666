import { Component, Inject, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Rebate } from '@core/models/rebate.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import * as moment from 'moment-timezone';
import { forkJoin, Subscription } from 'rxjs';
import { ReferralCommissionDataService } from '../../services/referral-commission-data.service';
import { ReferralCommissionSettingsDataService } from '../../../referral-commission-settings/services/referral-commission-settings-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-referral-commission-generate',
  templateUrl: './referral-commission-generate.component.html',
  styleUrls: ['./referral-commission-generate.component.scss'],
})
export class ReferralCommissionDialogComponent implements OnInit, OnDestroy {
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
  };
  form: FormGroup;
  messages$ = this.referralCommissionDataService.messages$;
  ranges = this.transactionHttpService.ranges;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  private subscription = new Subscription();

  buttonLoading = false;

  memberAccountDropdownSettings = {
    singleSelection: false,
    text: this.translateService.instant('Please Select'),
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 200,
    primaryKey: 'referrer_id',
    labelKey: 'referrer',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    searchBy: ["referrer"],
    disabled: true,
    autoPosition: false,
    position: 'bottom'
  };

  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  checkValidation = false;
  referralCommissionSettings = [];

  currencyId:any;
  currenciesDropdownList = [];
  currenciesDropdownListDefault = [];
  currenciesSelectedItems: any;

  dropdownLoading = true;
  membergroupId:any;

  currencyDropdownSettings = {
    singleSelection: false,
    text: this.translateService.instant('Please Select'),
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 100,
    position: 'bottom',
    primaryKey: 'id',
    labelKey: 'currencies_code',
    lazyLoading: false,
    noDataLabel: '',
    showCheckbox: false,
    disabled: true
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { rebate: Rebate; mode: string },
    private referralCommissionDataService: ReferralCommissionDataService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    public dialogRef: MatDialogRef<ReferralCommissionDialogComponent>,
    private translateService: TranslateService,
    private referralCommissionSettingsDataService: ReferralCommissionSettingsDataService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
      });
    }

    this.referralCommissionSettingsDataService.getWithQuery(`?paginate=false&status=1`).subscribe((res: any) => {
      this.referralCommissionSettings = res;
      this.dropdownLoading = false;
    });

    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onGenerate() {
    this.checkValidation = true;
    if (this.form.valid) {
      this.buttonLoading = true;
      // To set 'Save' button to disable (To prevent call API in multiple times when double click)
      this.form.setErrors({ 'invalid': true });
      const data = {
        ...this.form.value,
        start_datetime: moment(this.form.value.start_datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
        end_datetime: moment(this.form.value.end_datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
        member_account_id: this.form.value.member_account_id?.length > 0 ? this.form.value.member_account_id : null,
        referral_commission_setting_id: this.form.value.referral_commission_setting_id
      };
      Object.keys(data).forEach(
        (key) =>
          (data[key] == null || data[key] === '' || key === 'defaultDate') &&
          delete data[key]
      );
      this.subscription = forkJoin([
        this.referralCommissionDataService.generateRebate(data),
        this.referralCommissionDataService.messages$,
      ]).subscribe(
        (res) => {
          this.buttonLoading = false;
          this.checkValidation = false;
          // To enable 'Save' button after get response
          this.form.setErrors(null);
        },
        (err) => {
          this.buttonLoading = false;
          this.checkValidation = false;
          // To enable 'Save' button after get response
          this.form.setErrors(null);
        },
        () => { }
      );
      this.messages$.subscribe(() => {
        this.form.patchValue({
          currency_id: 'all',
          start_datetime: null,
          end_datetime: null,
          defaultDate: null,
        });
        this.checkValidation = false;
      });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClearDate() {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({
        start_datetime: null,
        end_datetime: null,
        defaultDate: null,
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private formInit() {
    this.form = new FormGroup({
      referral_commission_setting_id: new FormControl(null, [Validators.required]),
      currency_id: new FormControl('all', [Validators.required]),
      start_datetime: new FormControl(null, [Validators.required]),
      end_datetime: new FormControl(null, [Validators.required]),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      member_account_id: new FormControl(null),
    });
  }

  onChangeCurrency(){
    this.currencyId = this.form.value.currency_id;
  }

  onReferralCommissionSettingChange(){
    var referralCommissionSettingId = this.form.value.referral_commission_setting_id;

    if (referralCommissionSettingId) {
      this.dropdownLoading = true;
      var referralCommissionSetting = this.referralCommissionSettings.find( x => x['id'] == referralCommissionSettingId );
      
      // Pre-selected currencies
      this.currenciesSelectedItems = [];
      this.currenciesDropdownList = referralCommissionSetting.currencies;
      this.currencyId = null;
      this.membergroupId = referralCommissionSetting.member_groups;
      this.currencyDropdownSettings = {
        ...this.currencyDropdownSettings,
        disabled:false
      };

      this.memberAccountDropdownSettings = {
        ...this.memberAccountDropdownSettings,
        disabled: true,
      };

      this.dropdownLoading = false;
      this.cdr.detectChanges();
    }else{
      this.currenciesSelectedItems = [];
      this.currenciesDropdownList = [];
      this.form.patchValue({
          currency_id: null,
          member_account_id: null
      })

      this.memberAccountDropdownSettings = {
            ...this.memberAccountDropdownSettings,
            disabled:true,
      };

      this.currencyDropdownSettings = {
          ...this.currencyDropdownSettings,
          disabled:true
      };

      this.currencyId = null;
      this.membergroupId = null;
      this.cdr.detectChanges();
    }
    

  }

  onSelectCurrency(event: Event){
    this.currenciesSelectedItems = this.currencyId = event;
    this.filterMemberAccountDropdown();
  }

  filterMemberAccountDropdown() {

    if(this.currenciesSelectedItems.length > 0){
      this.memberAccountDropdownSettings = {
        ...this.memberAccountDropdownSettings,
        disabled: false,
      };

      this.cdr.detectChanges();
    }else{
      this.memberAccountDropdownSettings = {
        ...this.memberAccountDropdownSettings,
        disabled: true,
      };
      this.cdr.detectChanges();
    }
  }

}
