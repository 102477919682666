import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Rewards } from '@core/models/rewards.model';

@Injectable()
export class RewardsEntityService extends EntityCollectionServiceBase<Rewards> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Reward', serviceElementsFactory);
    }
}

