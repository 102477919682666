import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Member } from '@core/models/member.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { tap, catchError } from 'rxjs/operators';
import { MemberHttpService } from '@core/services/member-http.service';

@Component({
  templateUrl: './member-password.component.html',
  styleUrls: ['./member-password.component.scss']
})
export class MemberPasswordDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = new Subject<any[]>();
  hasError: boolean;
  subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;

  constructor(
    public dialogRef: MatDialogRef<MemberPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { member: Member},
    private memberService: MemberHttpService
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onResetPassword(member: Member) {
    this.buttonLoading = true;
    this.subscription = this.memberService.resetPassword(member, this.form).pipe(
      tap((res) => {
        this.hasError = !!res.success;
        this.messages$.next([...res.message]);
        this.form.setErrors(null);
        this.buttonLoading = false;
      }),
      catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    this.form = new FormGroup({
      password: new FormControl(null, [Validators.required, Validators.minLength(6)])
    });
  }
}
