import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RewardStatus } from '@core/enums/reward.enum';
import { Rewards } from '@core/models/rewards.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { forkJoin, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { RewardsDataService } from '../../services/rewards-data.service';
import { RewardsEntityService } from '../../services/rewards-entity.service';

@Component({
  selector: 'kt-reward-details',
  templateUrl: './reward-details.component.html',
  styleUrls: ['./reward-details.component.scss']
})
export class RewardDetailsDialogComponent implements OnInit, OnDestroy {

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;
  form: FormGroup;
  status = RewardStatus;
  buttonLoading = false;
  messages$ = this.rewardsDataService.messages$;
  refreshStatus: boolean;
  checkValidation = false;

  private subscription = new Subscription();
  private datePickerSubscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { reward: Rewards, mode: string },
    public dialogRef: MatDialogRef<RewardDetailsDialogComponent>,
    private rewardsService: RewardsEntityService,
    private rewardsDataService: RewardsDataService,
    private dropdownHttpService: DropdownHttpService,
    private transactionService: TransactionHttpService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'expiry_datetime')
    ]).subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(reward: Rewards, mode?: string) {
    this.checkValidation = true;
    if (this.form.valid) {
      this.buttonLoading = true;
      const data = {
        id: reward ? reward.id : null,
        ...this.form.value
      };
      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
      switch (mode) {
        case 'edit':
          this.subscription = this.rewardsDataService.updateReward(data).pipe(
            tap((res: any) => {
              this.buttonLoading = false;
              this.checkValidation = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.checkValidation = false;
              this.form.setErrors(null);
              throw error;
            })
          ).subscribe();
          break;
      }
      this.refreshStatus = true;
    }
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, 'yyyy-MM-dd')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      })
    );
  }

  private formInit() {
    let expiryDate = null;
    let status = null;
    if (this.data.mode === 'edit') {
      expiryDate = this.data.reward.expiry_date;
      status = this.data.reward.status;
    }
    this.form = new FormGroup({
      expiry_datetime: new FormControl(expiryDate, [Validators.required, Validators.pattern('[0-9\-]+')]),
      status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false }, [Validators.required])
    });

  }
}
