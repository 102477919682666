<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-100" [formGroup]="form">
    <div class="modal-content">
      <!-- Edit Game Provider -->
      <div class="modal-header">
        <h5 class="modal-content" class="modal-title">Edit Game Provider</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>

      <div class="modal-body">
        <div class="row">
          <section class="col card border-0">
            <div class="col-12 p-0 card rounded-0">
              <div class="card-header">Basic Info</div>

              <section class="row p-4">
                <!-- Name -->
                <div class="col-md-6 form-group">
                  <label class="control-label">Name</label>
                  <input type="text" formControlName="name" class="form-control" />
                </div>

                <!-- Category -->
                <div class="col-md-6 form-group required">
                  <label class="control-label">Category<span class="text-danger">*</span></label>
                  <kt-dropdown-wo-lazyload [form]="form" [dropdownList]="categoryDropdownList" [dropdownSettings]="categoryDropdownSettings" [formName]="'category_id'" [selectionAttributes]="'id'" [selectedItems]="categorySelectedItems"> </kt-dropdown-wo-lazyload>
                </div>

                <!-- Currency -->
                <div class="col-md-6 form-group required">
                  <label class="control-label">Currency <span class="text-danger">*</span></label>
                  <kt-dropdown-wo-lazyload #mySelect [form]="form" [dropdownList]="dropdown.currencies" [dropdownSettings]="currencyDropdownSettings" [formName]="'settings_currency_id'" [selectionAttributes]="'id'" [selectedItems]="currencySelectedItems" (click)="onChangeCurrency(mySelect.selectedItems)"> </kt-dropdown-wo-lazyload>
                </div>

                <!-- Type -->
                <div class="col-md-6 form-group required">
                  <label class="control-label">Type</label>
                  <input type="text" formControlName="type" class="form-control" readonly />
                </div>

                <!-- Supported Target Type -->
                <div class="col-md-6 form-group">
                  <label class="control-label">Supported Target Type</label>
                  <input type="text" formControlName="supported_target_type_name" class="form-control" readonly>
                </div>

                <div id="gf_account" *ngIf="currencySelectedItems && currencySelectedItems.length > 0 && is_bot && is_credential" class="col-12">
                  <table class="col-12">
                    <tr *ngFor="let currency of currencySelectedItems" class="col-md-12">
                      <th class="col-2">{{ currency ? currency.name : "-" }}</th>
                      <td class="row">
                        <div class="col-md-4 form-group">
                          <label class="control-label">Username</label>
                          <input type="text" #username value="{{ gf_username[currency.name] }}" class="form-control" readonly />
                        </div>
                        <div class="col-md-4 form-group">
                          <label class="control-label">Password</label>
                          <input type="text" #password value="{{ gf_password[currency.name] }}" class="form-control" />
                        </div>
                        <div class="col-md-4 form-group">
                          <label class="control-label">Pin</label>
                          <input type="text" #pin value="{{ gf_pin[currency.name] }}" class="form-control" />
                        </div>
                        <div class="col-md-4 form-group">
                          <label class="control-label">Url</label>
                          <input type="text" #url value="{{ gf_url[currency.name] }}" class="form-control" />
                        </div>
                        <div class="form-group col-md-4">
                          <label class="control-label col-12">Balance</label>
                          <div class="input-group">
                            <input type="text" #balance value="{{ gf_balance[currency.name] }}" class="form-control" readonly />
                            <span class="input-group-append">
                              <button type="button" [disabled]="isDisabled || buttonLoading" class="btn btn-info" (click)="syncBalance(data.gameProvider.code, currency.name)"><i [class]="buttonLoading ? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-sync'" style="color: white"></i>Sync</button>
                            </span>
                          </div>
                        </div>

                        <div class="col-2 form-group submit">
                          <button type="button" [disabled]="isDisabled || buttonLoading" class="btn btn-success" (click)="onSubmit(gf_id[currency.name], gf_site_game_provider_id[currency.name], password.value, pin.value, url.value)"><i [class]="buttonLoading ? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Update</button>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </section>
            </div>
          </section>
        </div>

        <div class="row mt-4">
          <section class="col card border-0">
            <div class="col-12 p-0 card rounded-0">
              <div class="card-header">Game Suspension</div>
              <section class="row p-4">
                <div class="col-md-6 form-group">
                  <div class="d-flex">
                    <label>Suspend Game </label>
                    <div class="custom-control custom-switch ml-2">
                      <input type="checkbox" class="custom-control-input" (change)="onToggleSuspendStatus()" id="suspend_status" [checked]="this.form.value.suspend_status === 1 ? true : false" />
                      <label class="custom-control-label" for="suspend_status"></label>
                    </div>
                  </div>
                  <div [hidden]="this.form.value.suspend_status !== 1" class="mt-4">
                    <label class="control-label">Start:</label>
                    <div class="input-group date">
                      <input class="hidden" [owlDateTime]="suspend_date" [ngModel]="dateTimeStack?.suspend_date" [ngModelOptions]="{ standalone: true }" />
                      <input type="text" formControlName="suspend_date" class="form-control" />
                      <span class="input-group-append" [owlDateTimeTrigger]="suspend_date">
                        <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                      </span>
                      <owl-date-time #suspend_date></owl-date-time>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>

        <div class="row mt-4">
          <section class="col card border-0">
            <div class="col-12 p-0 card rounded-0">
              <div class="card-header">Transfer Suspension</div>
              <section class="row p-4">
                <div class="col-md-6 form-group">
                  <div class="d-flex">
                    <label>Suspend Transfer In </label>
                    <div class="custom-control custom-switch ml-2">
                      <input type="checkbox" class="custom-control-input" id="suspend_transfer_in_status" (change)="onToggleSuspendTransferInStatus()" [checked]="this.form.value.suspend_transfer_in_status === 1 ? true : false" />
                      <label class="custom-control-label" for="suspend_transfer_in_status"></label>
                    </div>
                  </div>
                  <div [hidden]="this.form.value.suspend_transfer_in_status !== 1 " class="mt-4">
                    <label class="control-label">Start:</label>
                    <div class="input-group date">
                      <input class="hidden" [owlDateTime]="suspend_transfer_in_date" [ngModel]="dateTimeStack?.suspend_transfer_in_date" [ngModelOptions]="{ standalone: true }" />
                      <input type="text" formControlName="suspend_transfer_in_date" class="form-control" />
                      <span class="input-group-append" [owlDateTimeTrigger]="suspend_transfer_in_date">
                        <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                      </span>
                      <owl-date-time #suspend_transfer_in_date></owl-date-time>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 form-group">
                  <div class="d-flex">
                    <label>Suspend Transfer Out </label>
                    <div class="custom-control custom-switch ml-2">
                      <input type="checkbox" class="custom-control-input" id="suspend_transfer_out_status" (click)="onToggleSuspendTransferOutStatus()" [checked]="this.form.value.suspend_transfer_out_status === 1 ? true : false" />
                      <label class="custom-control-label" for="suspend_transfer_out_status"></label>
                    </div>
                  </div>
                  <div [hidden]="this.form.value.suspend_transfer_out_status !== 1 " class="mt-4">
                    <label class="control-label">Start:</label>
                    <div class="input-group date">
                      <input class="hidden" [owlDateTime]="suspend_transfer_out_date" [ngModel]="dateTimeStack?.suspend_transfer_out_date" [ngModelOptions]="{ standalone: true }" />
                      <input type="text" formControlName="suspend_transfer_out_date" class="form-control" />
                      <span class="input-group-append" [owlDateTimeTrigger]="suspend_transfer_out_date">
                        <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                      </span>
                      <owl-date-time #suspend_transfer_out_date></owl-date-time>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>

        <!-- SPORT -->
        <section>
          <div class="row mt-4" *ngIf="this.isCategoryExist['SP']">
            <section class="col card border-0">
              <div class="col-12 p-0 card rounded-0">
                <!-- Desktop -->
                <div class="card-header">Desktop / Mobile Media (SPORT)</div>
                <section class="col-12 p-4">
                  <!-- Full Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Full Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'SP', 0)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['SP'][0]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Full Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['SP'][0]) && !isImageUploading['SP'][0]">
                        <label>Full Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Full Logo Preview" [src]="imagePreview['SP'][0] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Icon Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Icon Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'SP', 1)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['SP'][1]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Icon Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['SP'][1]) && !isImageUploading['SP'][1]">
                        <label>Icon Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Icon Logo Preview" [src]="imagePreview['SP'][1] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Lobby Button Background -->
                  <div class="row border-top border-left border-bottom">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Lobby Button Background<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'SP', 2)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['SP'][2]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Lobby Button Background Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['SP'][2]) && !isImageUploading['SP'][2]">
                        <label>Lobby Button Background Preview</label>
                        <div class="image-wrapper mock-preview">
                          <img class="img-fluid preview-image" alt="Lobby Button Background Preview" [src]="imagePreview['SP'][2] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>

        <!-- LIVE CASINO -->
        <section>
          <div class="row mt-4" *ngIf="this.isCategoryExist['LC']">
            <section class="col card border-0">
              <div class="col-12 p-0 card rounded-0">
                <!-- Desktop -->
                <div class="card-header">Desktop / Mobile Media (LIVE CASINO)</div>
                <section class="col-12 p-4">
                  <!-- Full Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Full Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'LC', 0)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['LC'][0]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Full Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['LC'][0]) && !isImageUploading['LC'][0]">
                        <label>Full Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Full Logo Preview" [src]="imagePreview['LC'][0] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Icon Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Icon Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'LC', 1)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['LC'][1]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Icon Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['LC'][1]) && !isImageUploading['LC'][1]">
                        <label>Icon Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Icon Logo Preview" [src]="imagePreview['LC'][1] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Lobby Button Background -->
                  <div class="row border-top border-left border-bottom">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Lobby Button Background<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'LC', 2)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['LC'][2]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Lobby Button Background Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['LC'][2]) && !isImageUploading['LC'][2]">
                        <label>Lobby Button Background Preview</label>
                        <div class="image-wrapper mock-preview">
                          <div class="mock-preview-inner">
                            <div class="row">
                              <div class="col-6">
                                <img class="img-fluid preview-image" alt="Lobby Button Background Preview" [src]="imagePreview['LC'][2] || data.gameProvider.image_path" />
                              </div>
                              <div class="col-6 text-center enter-lobby">
                                <img [src]="imagePreview['LC'][0] || data.gameProvider.image_path">
                                <p>Enter Lobby</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>

        <!-- SLOTS -->
        <section>
          <div class="row mt-4" *ngIf="this.isCategoryExist['SL']">
            <section class="col card border-0">
              <div class="col-12 p-0 card rounded-0">
                <!-- Desktop -->
                <div class="card-header">Desktop / Mobile Media (SLOTS)</div>
                <section class="col-12 p-4">
                  <!-- Full Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Full Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'SL', 0)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['SL'][0]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Full Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['SL'][0]) && !isImageUploading['SL'][0]">
                        <label>Full Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Full Logo Preview" [src]="imagePreview['SL'][0] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Icon Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Icon Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'SL', 1)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['SL'][1]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Icon Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['SL'][1]) && !isImageUploading['SL'][1]">
                        <label>Icon Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Icon Logo Preview" [src]="imagePreview['SL'][1] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Lobby Button Background -->
                  <div class="row border-top border-left border-bottom">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Lobby Button Background<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'SL', 2)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['SL'][2]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Lobby Button Background Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['SL'][2]) && !isImageUploading['SL'][2]">
                        <label>Lobby Button Background Preview</label>
                        <div class="image-wrapper mock-preview">
                          <div class="mock-preview-inner">
                            <div class="row">
                              <div class="col-6">
                                <img class="img-fluid preview-image" alt="Lobby Button Background Preview" [src]="imagePreview['SL'][2] || data.gameProvider.image_path" />
                              </div>
                              <div class="col-6 text-center enter-lobby">
                                <img [src]="imagePreview['SL'][0] || data.gameProvider.image_path">
                                <p>Enter Lobby</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>

        <!-- E-SPORT -->
        <section>
          <div class="row mt-4" *ngIf="this.isCategoryExist['ES']">
            <section class="col card border-0">
              <div class="col-12 p-0 card rounded-0">
                <!-- Desktop -->
                <div class="card-header">Desktop / Mobile Media (E-SPORT)</div>
                <section class="col-12 p-4">
                  <!-- Full Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Full Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'ES', 0)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['ES'][0]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Full Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['ES'][0]) && !isImageUploading['ES'][0]">
                        <label>Full Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Full Logo Preview" [src]="imagePreview['ES'][0] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Icon Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Icon Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'ES', 1)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['ES'][1]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Icon Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['ES'][1]) && !isImageUploading['ES'][1]">
                        <label>Icon Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Icon Logo Preview" [src]="imagePreview['ES'][1] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Lobby Button Background -->
                  <div class="row border-top border-left border-bottom">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Lobby Button Background<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'ES', 2)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['ES'][2]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Lobby Button Background Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['ES'][2]) && !isImageUploading['ES'][2]">
                        <label>Lobby Button Background Preview</label>
                        <div class="image-wrapper mock-preview">
                          <div class="mock-preview-inner">
                            <div class="row">
                              <div class="col-6">
                                <img class="img-fluid preview-image" alt="Lobby Button Background Preview" [src]="imagePreview['ES'][2] || data.gameProvider.image_path" />
                              </div>
                              <div class="col-6 text-center enter-lobby">
                                <img [src]="imagePreview['ES'][0] || data.gameProvider.image_path">
                                <p>Enter Lobby</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>

        <!-- FISHING -->
        <section>
          <div class="row mt-4" *ngIf="this.isCategoryExist['FS']">
            <section class="col card border-0">
              <div class="col-12 p-0 card rounded-0">
                <!-- Desktop -->
                <div class="card-header">Desktop / Mobile Media (FISHING)</div>
                <section class="col-12 p-4">
                  <!-- Full Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Full Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'FS', 0)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['FS'][0]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Full Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['FS'][0]) && !isImageUploading['FS'][0]">
                        <label>Full Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Full Logo Preview" [src]="imagePreview['FS'][0] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Icon Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Icon Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'FS', 1)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['FS'][1]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Icon Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['FS'][1]) && !isImageUploading['FS'][1]">
                        <label>Icon Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Icon Logo Preview" [src]="imagePreview['FS'][1] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Lobby Button Background -->
                  <div class="row border-top border-left border-bottom">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Lobby Button Background<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'FS', 2)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['FS'][2]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Lobby Button Background Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['FS'][2]) && !isImageUploading['FS'][2]">
                        <label>Lobby Button Background Preview</label>
                        <div class="image-wrapper mock-preview">
                          <div class="mock-preview-inner">
                            <div class="row">
                              <div class="col-6">
                                <img class="img-fluid preview-image" alt="Lobby Button Background Preview" [src]="imagePreview['FS'][2] || data.gameProvider.image_path" />
                              </div>
                              <div class="col-6 text-center enter-lobby">
                                <img [src]="imagePreview['FS'][0] || data.gameProvider.image_path">
                                <p>Enter Lobby</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>

        <!-- LOTTERY -->
        <section>
          <div class="row mt-4" *ngIf="this.isCategoryExist['LT']">
            <section class="col card border-0">
              <div class="col-12 p-0 card rounded-0">
                <!-- Desktop -->
                <div class="card-header">Desktop / Mobile Media (LOTTERY)</div>
                <section class="col-12 p-4">
                  <!-- Full Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Full Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'LT', 0)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['LT'][0]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Full Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['LT'][0]) && !isImageUploading['LT'][0]">
                        <label>Full Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Full Logo Preview" [src]="imagePreview['LT'][0] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Icon Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Icon Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'LT', 1)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['LT'][1]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Icon Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['LT'][1]) && !isImageUploading['LT'][1]">
                        <label>Icon Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Icon Logo Preview" [src]="imagePreview['LT'][1] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Lobby Button Background -->
                  <div class="row border-top border-left border-bottom">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Lobby Button Background<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'LT', 2)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['LT'][2]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Lobby Button Background Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['LT'][2]) && !isImageUploading['LT'][2]">
                        <label>Lobby Button Background Preview</label>
                        <div class="image-wrapper mock-preview">
                          <div class="mock-preview-inner">
                            <div class="row">
                              <div class="col-6">
                                <img class="img-fluid preview-image" alt="Lobby Button Background Preview" [src]="imagePreview['LT'][2] || data.gameProvider.image_path" />
                              </div>
                              <div class="col-6 text-center enter-lobby">
                                <img [src]="imagePreview['LT'][0] || data.gameProvider.image_path">
                                <p>Enter Lobby</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>

        <!-- CRASH GAME -->
        <section>
          <div class="row mt-4" *ngIf="this.isCategoryExist['CG']">
            <section class="col card border-0">
              <div class="col-12 p-0 card rounded-0">
                <!-- Desktop -->
                <div class="card-header">Desktop / Mobile Media (CRASH GAME)</div>
                <section class="col-12 p-4">
                  <!-- Full Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Full Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'CG', 0)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['CG'][0]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Full Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['CG'][0]) && !isImageUploading['CG'][0]">
                        <label>Full Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Full Logo Preview" [src]="imagePreview['CG'][0] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Icon Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Icon Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'CG', 1)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['CG'][1]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Icon Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['CG'][1]) && !isImageUploading['CG'][1]">
                        <label>Icon Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Icon Logo Preview" [src]="imagePreview['CG'][1] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Lobby Button Background -->
                  <div class="row border-top border-left border-bottom">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Lobby Button Background<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'CG', 2)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['CG'][2]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Lobby Button Background Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['CG'][2]) && !isImageUploading['CG'][2]">
                        <label>Lobby Button Background Preview</label>
                        <div class="image-wrapper mock-preview">
                          <div class="mock-preview-inner">
                            <div class="row">
                              <div class="col-6">
                                <img class="img-fluid preview-image" alt="Lobby Button Background Preview" [src]="imagePreview['CG'][2] || data.gameProvider.image_path" />
                              </div>
                              <div class="col-6 text-center enter-lobby">
                                <img [src]="imagePreview['CG'][0] || data.gameProvider.image_path">
                                <p>Enter Lobby</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>

        <!-- POKER -->
        <section>
          <div class="row mt-4" *ngIf="this.isCategoryExist['PK']">
            <section class="col card border-0">
              <div class="col-12 p-0 card rounded-0">
                <!-- Desktop -->
                <div class="card-header">Desktop / Mobile Media (POKER)</div>
                <section class="col-12 p-4">
                  <!-- Full Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Full Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'PK', 0)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['PK'][0]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Full Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['PK'][0]) && !isImageUploading['PK'][0]">
                        <label>Full Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Full Logo Preview" [src]="imagePreview['PK'][0] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Icon Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Icon Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'PK', 1)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['PK'][1]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Icon Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['PK'][1]) && !isImageUploading['PK'][1]">
                        <label>Icon Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Icon Logo Preview" [src]="imagePreview['PK'][1] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Lobby Button Background -->
                  <div class="row border-top border-left border-bottom">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Lobby Button Background<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'PK', 2)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['PK'][2]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Lobby Button Background Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['PK'][2]) && !isImageUploading['PK'][2]">
                        <label>Lobby Button Background Preview</label>
                        <div class="image-wrapper mock-preview">
                          <div class="mock-preview-inner">
                            <div class="row">
                              <div class="col-6">
                                <img class="img-fluid preview-image" alt="Lobby Button Background Preview" [src]="imagePreview['PK'][2] || data.gameProvider.image_path" />
                              </div>
                              <div class="col-6 text-center enter-lobby">
                                <img [src]="imagePreview['PK'][0] || data.gameProvider.image_path">
                                <p>Enter Lobby</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>

        <!-- TABLE -->
        <section>
          <div class="row mt-4" *ngIf="this.isCategoryExist['TB']">
            <section class="col card border-0">
              <div class="col-12 p-0 card rounded-0">
                <!-- Desktop -->
                <div class="card-header">Desktop / Mobile Media (TABLE)</div>
                <section class="col-12 p-4">
                  <!-- Full Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Full Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'TB', 0)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['TB'][0]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Full Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['TB'][0]) && !isImageUploading['TB'][0]">
                        <label>Full Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Full Logo Preview" [src]="imagePreview['TB'][0] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Icon Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Icon Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'TB', 1)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['TB'][1]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Icon Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['TB'][1]) && !isImageUploading['TB'][1]">
                        <label>Icon Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Icon Logo Preview" [src]="imagePreview['TB'][1] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Lobby Button Background -->
                  <div class="row border-top border-left border-bottom">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Lobby Button Background<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'TB', 2)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['TB'][2]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>

                      <!-- Lobby Button Background Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['TB'][2]) && !isImageUploading['TB'][2]">
                        <label>Lobby Button Background Preview</label>
                        <div class="image-wrapper mock-preview">
                          <div class="mock-preview-inner">
                            <div class="row">
                              <div class="col-6">
                                <img class="img-fluid preview-image" alt="Lobby Button Background Preview" [src]="imagePreview['TB'][2] || data.gameProvider.image_path" />
                              </div>
                              <div class="col-6 text-center enter-lobby">
                                <img [src]="imagePreview['TB'][0] || data.gameProvider.image_path">
                                <p>Enter Lobby</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>

        <!-- CRICKET -->
        <section>
          <div class="row mt-4" *ngIf="this.isCategoryExist['CK']">
            <section class="col card border-0">
              <div class="col-12 p-0 card rounded-0">
                <!-- Desktop -->
                <div class="card-header">Desktop / Mobile Media (CRICKET)</div>
                <section class="col-12 p-4">
                  <!-- Full Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Full Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'CK', 0)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['CK'][0]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Full Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['CK'][0]) && !isImageUploading['CK'][0]">
                        <label>Full Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Full Logo Preview" [src]="imagePreview['CK'][0] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Icon Logo -->
                  <div class="row border-top border-left">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Icon Logo<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'CK', 1)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['CK'][1]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Icon Logo Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['CK'][1]) && !isImageUploading['CK'][1]">
                        <label>Icon Logo Preview</label>
                        <div class="image-wrapper">
                          <img class="img-fluid preview-image" alt="Icon Logo Preview" [src]="imagePreview['CK'][1] || data.gameProvider.image_path" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Lobby Button Background -->
                  <div class="row border-top border-left border-bottom">
                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                      <span class="kt-font-bold">Lobby Button Background<span class="text-danger">*</span></span>
                    </div>
                    <div class="col-md-8 border-right p-3">
                      <label class="col-4 btn btn-default btn-sm image-button">
                        <span>Choose file</span>
                        <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'CK', 2)" />
                      </label>
                      <div class="spinner-wrapper" *ngIf="isImageUploading['CK'][2]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <!-- Lobby Button Background Preview -->
                      <div class="col-12 form-group mt-3" *ngIf="(data.gameProvider.image_path || imagePreview['CK'][2]) && !isImageUploading['CK'][2]">
                        <label>Lobby Button Background Preview</label>
                        <div class="image-wrapper mock-preview">
                          <div class="mock-preview-inner">
                            <div class="row">
                              <div class="col-6">
                                <img class="img-fluid preview-image" alt="Lobby Button Background Preview" [src]="imagePreview['CK'][2] || data.gameProvider.image_path" />
                              </div>
                              <div class="col-6 text-center enter-lobby">
                                <img [src]="imagePreview['CK'][0] || data.gameProvider.image_path">
                                <p>Enter Lobby</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>

        <div class="descriptionDiv">
          <mat-tab-group animationDuration="0ms" class="col-12 form-group mt-4" formGroupName="details">
            <ng-container>
              <mat-tab *ngFor="let language of languages; let i = index" [formGroupName]="i">
                <ng-template mat-tab-label>
                  {{ language }}
                </ng-template>
                <!-- lang_code -->
                <input type="hidden" formControlName="lang_code" [value]="language" />

                <!-- Description -->
                <div class="col-12 form-group">
                  <label>Description <span class="text-danger">*</span></label>

                  <textarea formControlName="description" rows="5" class="col-12 form-control"></textarea>
                </div>
              </mat-tab>
            </ng-container>
          </mat-tab-group>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button *ngIf="canEditGameProvider" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.gameProvider)"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
