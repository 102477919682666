<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row pr-0 mb-2">
                                <!-- Username -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>
                                <!-- Currency -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="currency_id">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <option value="all">All</option>
                                        <ng-container *ngFor="let value of dropdown.currencies">
                                            <option [value]="value.id">{{ value.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <!-- Event -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Event:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="event_id" class="form-control">
                                        <option value="all"> All </option>
                                        <ng-container *ngIf="(events | async) as event">
                                            <option *ngFor="let value of event" [value]="value.id">{{ value.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Account Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="account_type_condition" class="form-control" >
                                        <option value="And">AND Condition</option>
                                        <option value="Or">OR Condition</option>
                                    </select>
                                </div>
                                <div class="col-md-3 kt-form__control">
                                    <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
                                        [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
                                        [formName]="'account_type'" [selectionAttributes]="'id'"
                                        [selectedItems]='accountTypeSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row mb-3 mt-3 d-flex justify-content-between">
                <div>
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <div>
                    <!-- <button *ngIf="access.export" class="btn btn-warning btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="button_loading">
                        <i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>
                        Export
                    </button> -->
                    <button *ngIf="canExportTicketList" class="btn btn-warning btn-icon-sm mr-2" type="button" (click)="onExport()">
                        <i [class]="false ? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>
                        Export
                    </button>
                </div>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('currency_code')" [ngClass]="sortingConfig.currency_code" [class.sort-selected]="sortingSelection.sort_by === 'currency_code'">Currency</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('event_id')" [ngClass]="sortingConfig.event_id" [class.sort-selected]="sortingSelection.sort_by === 'event_id'">Event</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('used_tickets')" [ngClass]="sortingConfig.used_tickets" [class.sort-selected]="sortingSelection.sort_by === 'used_tickets'">Tickets Predicted</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('voided_tickets')" [ngClass]="sortingConfig.voided_tickets" [class.sort-selected]="sortingSelection.sort_by === 'voided_tickets'">Tickets Voided</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('total_tickets')" [ngClass]="sortingConfig.total_tickets" [class.sort-selected]="sortingSelection.sort_by === 'total_tickets'">Tickets Received</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(tickets$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>
                                        <a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog(row.member_account_id)">{{ row.username }} </a>
                                    </td>
                                    <td class="text-center">{{ row.currency_code }}</td>
                                    <td class="text-center">{{ row.event_name }}</td>
                                    <td class="text-center">{{ row.used_tickets }}</td>
                                    <td class="text-center">{{ row.voided_tickets }}</td>
                                    <td class="text-center">{{ row.total_tickets }}</td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
