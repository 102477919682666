<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Bank Transaction</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
          <div class="row">
              <div class="col-lg-6 form-group">
                <label class="col-form-label">Type <span class="text-danger">*</span></label>
                <select class="form-control" formControlName="transaction_type_id" (change)="onTransferType($event)">
                  <option [value]="null" disabled>Please Select</option>
                  <ng-container *ngFor="let value of dropdown.types | async">
                      <option [value]="value.id">{{ value.name }}</option>
                  </ng-container>
                </select>
              </div>
              <div class="col-lg-6 form-group" *ngIf="selectedTransferType == 1 || selectedTransferType == 3 || selectedTransferType == 99">
                <label class="col-form-label">Currency <span class="text-danger">*</span></label>
                <div class="pl-0 pr-0">
                  <select formControlName="currency_id" class="form-control" (change)="onCurrency($event)">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <option *ngFor="let value of dropdown.currencies" [value]="value.id">{{ value.name }}</option>
                  </select>
                </div>
              </div>
              <!-- <ng-container *ngIf="selectedCurrency >= 1 && (this.dropdown.merchantBanks$ | async) as merchants"> -->
              <ng-container *ngIf="selectedCurrency >= 1">
                <div class="col-lg-6 form-group" *ngIf="selectedTransferType === 3 ||  selectedTransferType === 99">
                  <label class="col-form-label">Transfer Out Bank <span class="text-danger">*</span></label>
                  <kt-dropdown-wo-lazyload class="col-8" style="padding: 0"
                      [form] = 'form'
                      [dropdownList] = 'bankaccountDropdownList'
                      [dropdownSettings] = 'bankaccountDropdownSettings'
                      [formName] = "'transfer_out'"
                      [selectionAttributes] = "'id'"
                      [selectedItems] = 'bankaccountSelectedItems'
                      (selectedItemsChanged)="onBankBalance($event, 2)">
                  </kt-dropdown-wo-lazyload>
                </div>
                <div class="col-lg-6 form-group" *ngIf="selectedTransferType === 3 ||  selectedTransferType === 99">
                  <label class="col-form-label">Transfer Out Bank Balance</label>
                  <input [value]="transferOutBalance | number : '1.2-2'" class="form-control" disabled />
                </div>
                <div class="col-lg-6 form-group" *ngIf="selectedTransferType === 1 ||  selectedTransferType === 99">
                  <label class="col-form-label">Transfer In Bank <span class="text-danger">*</span></label>
                  <kt-dropdown-wo-lazyload class="" style="padding: 0"
                      [form] = 'form'
                      [dropdownList] = 'bankaccountDropdownList'
                      [dropdownSettings] = 'bankaccountDropdownSettings'
                      [formName] = "'transfer_in'"
                      [selectionAttributes] = "'id'"
                      [selectedItems] = 'bankaccountSelectedItems'
                      (selectedItemsChanged)="onBankBalance($event, 1)">
                  </kt-dropdown-wo-lazyload>
                </div>
                <div class="col-lg-6 form-group" *ngIf="selectedTransferType === 1 ||  selectedTransferType === 99">
                  <label class="col-form-label">Transfer In Bank Balance</label>
                  <input [value]="transferInBalance | number : '1.2-2'" class="form-control" disabled />
                </div>
              </ng-container>

              <div class="col-lg-6 form-group">
                <label class="col-form-label">Trade Time <span class="text-danger">*</span></label>
                <div class="input-group date" [owlDateTimeTrigger]="dateStartRef">
                  <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack" [ngModelOptions]="{standalone: true}">
                  <input type="text" formControlName="trade_time" class="form-control">
                  <span class="input-group-append">
                      <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                  </span>
                  <owl-date-time #dateStartRef></owl-date-time>
                </div>
              </div>
              <div class="col-lg-6 form-group">
                <label class="col-form-label">Amount <span class="text-danger">*</span></label>
                <input class="form-control" formControlName="amount" AppTwoDigitDecimal>
              </div>
              <div class="col-lg-6 form-group">
                <label class="col-form-label">Processing Fee <span class="text-danger">*</span></label>
                <input class="form-control" formControlName="processing_fee" AppTwoDigitDecimal>
              </div>
              <div class="col-lg-6 form-group">
                <label class="col-form-label">Remarks</label>
                <input class="form-control" formControlName="remarks">
              </div>
              <div class="col-lg-6 form-group">
                <label class="col-form-label"> Status <span class="text-danger">*</span> </label>
                <select *ngIf="dropdown.statuses" formControlName="status" class="form-control">
                  <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
                </select>
              </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.bankTransaction, data.mode)"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
