import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, tap, take } from 'rxjs/operators';
import { DepositEntityService } from '@views/pages/apps/general/deposits/services/deposit-entity.service';

@Injectable()
export class DepositResolver implements Resolve<boolean> {

    constructor(private depositEntityService: DepositEntityService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.depositEntityService.loaded$.pipe(
          tap(loaded =>  {
            if(!loaded) {
              this.depositEntityService.getAll().pipe(
                take(1)
              );
            }
          }),
          filter(loaded => !!loaded),
          first()
        );
    }
}
