import { tap, catchError } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AffiliateGroups } from '@core/models/affiliate-groups.model';
import { AffiliateGroupsDataService } from './../../services/affiliate-groups-data.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'kt-settlement-settings',
  templateUrl: './settlement-settings.component.html',
  styleUrls: ['./settlement-settings.component.scss']
})
export class SettlementSettingsComponent implements OnInit {

  form: FormGroup;
  buttonLoading = false;
  messages$ = this.affiliateGroupsDataService.messages$;

  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, groups: AffiliateGroups, settings: any },
    public dialogRef: MatDialogRef<SettlementSettingsComponent>,
    public dialog: MatDialog,
    private affiliateGroupsDataService: AffiliateGroupsDataService,
  ) { }

  ngOnInit() {
    this.formInit();
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    const data = {
      ...this.form.value
    }
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    if (this.data.settings.length === 0) {
      this.subscription = this.affiliateGroupsDataService.addSettlementSettings(data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else {
      this.subscription = this.affiliateGroupsDataService.updateSettlementSettings(this.data.settings[0].id, data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
  }

  private formInit() {
    let affiliate_group_id = this.data.groups;
    let active_member_valid_turnover = null;
    let active_member_minimum_deposit = null;
    let total_commission_active_member = null;
    let deposit_transaction_fee_rate = null;
    let withdrawal_transaction_fee_rate = null;

    if (this.data.settings.length !== 0) {
      affiliate_group_id = this.data.settings[0].affiliate_group_id;
      active_member_valid_turnover = this.data.settings[0].active_member_valid_turnover;
      active_member_minimum_deposit = this.data.settings[0].active_member_minimum_deposit;
      total_commission_active_member = this.data.settings[0].total_commission_active_member;
      deposit_transaction_fee_rate = this.data.settings[0].deposit_transaction_fee_rate;
      withdrawal_transaction_fee_rate = this.data.settings[0].withdrawal_transaction_fee_rate;
    }

    this.form = new FormGroup({
      affiliate_group_id: new FormControl(affiliate_group_id),
      active_member_valid_turnover: new FormControl(active_member_valid_turnover, Validators.required),
      active_member_minimum_deposit: new FormControl(active_member_minimum_deposit, Validators.required),
      total_commission_active_member: new FormControl(total_commission_active_member, Validators.required),
      deposit_transaction_fee_rate: new FormControl(deposit_transaction_fee_rate, Validators.required),
      withdrawal_transaction_fee_rate: new FormControl(withdrawal_transaction_fee_rate, Validators.required),
    });
  }

}
