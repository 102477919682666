import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { TransactionReconciliation } from '@core/models/transaction-reconciliation.model';

@Injectable()
export class TransactionReconciliationEntityService extends EntityCollectionServiceBase<TransactionReconciliation> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('TransactionReconciliation', serviceElementsFactory);
    }
}
