import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ApiResponse } from '@core/models/api-response.model';
import { map, tap } from 'rxjs/operators';
import Echo from 'laravel-echo';
import { environment } from '@env/environment';

@Injectable()
export class PanicModeHttpService {
  echo: Echo;
  boUserId = JSON.parse(localStorage.getItem('user_data')).id;

  navigations$ = new Subject<any[]>();
  messages$ = new Subject<any[]>();
  panicModeStatus$ = new Subject<boolean>();
  constructor(
    private http: HttpClient,
  ) {
    const SocketIoClient = require('socket.io-client');
    const socketHost: string = environment.socketHost;
    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        client: SocketIoClient,
        host: socketHost
      });
    } catch (e) {
      console.log(e);
    }
  }

  panicMode(status: number) {
    return this.http.post<ApiResponse>('/panicmode', {status}).pipe(
      tap(res => {
        this.messages$.next(res.message)
        if (res.success) {
          this.panicModeStatus$.next(status.toString() == '1');
        }
      })
    );
  }

  getPanicMode(): Observable<number> {
    return this.http.get<ApiResponse>('/panicmode').pipe(
      map(res => {
        this.panicModeStatus$.next(res.data == '1');
        return res.data;
      })
    );
  }

  panicModeWebsocket() {
    const listener = (data) => {
      if (data.adminid != this.boUserId) {
        this.panicModeStatus$.next(data.status == '1');
      }
    }

    const panicModeChannel = this.echo.channel('panic-mode-status');
    panicModeChannel.listen('.PanicModeEvent', listener);
  }
}
