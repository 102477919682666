<!-- Search Filters -->
<div class="kt-form kt-form--label-right mb-2">
  <div class="row align-items-center">
    <div class="col-xl-12 order-2 order-xl-1 pr-0">
      <form class="row align-items-center" [formGroup]="form">
        <div class="col-12 row pr-0 mb-2">
          <div class="col-md-1 kt-form__label col-form-label">
            <label>Keyword:</label>
          </div>
          <div class="col-md-2 kt-form__control">
            <input type="text" formControlName="keyword" placeholder="Search" class="form-control">
          </div>
          <div class="col-md-1 kt-form__label col-form-label">
            <label>Date/Time:</label>
          </div>
          <div class="col-md-5 kt-form__control">
            <div class="input-group date">
              <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
              <span class="input-group-append">
                <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
    <div class="dropdown dropdown-inline">
      <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
      <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
    </div>
  </div>
</div>

<!-- Tabs -->
<mat-tab-group animationDuration="0ms" [selectedIndex]="activeTab" (selectedTabChange)="onSelectTab($event)">
  <mat-tab *ngIf="canViewSMSMessages" label="SMS">
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Message</th>
              <th class="text-center">Status</th>
              <th>Created By</th>
            </tr>
          </thead>
          <tbody *ngIf="messageHistory$?.length > 0">
            <tr *ngFor="let row of messageHistory$">
                <td>{{ row.id }}</td>
              <td>{{ row.content }}</td>
              <td class="text-center">
                <ng-container *ngIf="row.status === 1; else elseTemplate">
                  <i class="fas fa-check-double text-success" matTooltip="Seen"></i>
                </ng-container>
                <ng-template #elseTemplate>
                  <i class="fas fa-check text-secondary" matTooltip="Sent"></i>
                </ng-template>
              </td>
              <td class="date-column">
                {{row.created_by ? row.created_by : 'System'}} <br>
                <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                <ng-template #createdDateTime>
                  {{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                </ng-template>
              </td>
            </tr>
            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
          </table>
    </div>
    <div class="kt-pagination kt-pagination--brand mt-2 mb-4" *ngIf="pagination !== undefined">
      <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true"
        [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
      </ngb-pagination>
      <div class="kt-pagination__toolbar">
        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
            <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
        </select>
        <span class="pagination__desc">
          Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
        </span>
      </div>
    </div>
  </mat-tab>

  <mat-tab *ngIf="canViewInAppMessages" label="In-App">
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Message</th>
            <th class="text-center">Status</th>
            <th>Created By</th>
          </tr>
        </thead>
        <tbody *ngIf="messageHistory$?.length > 0">
          <tr *ngFor="let row of messageHistory$">
          <td>{{ row.id }}</td>
            <td><strong>{{ row.title }}</strong> <br> <span [innerHTML]="row.content"></span></td>
            <td class="text-center">
              <ng-container *ngIf="row.status === 1; else elseTemplate">
                <i class="fas fa-check-double text-success" matTooltip="Seen"></i>
              </ng-container>
              <ng-template #elseTemplate>
                <i class="fas fa-check text-secondary" matTooltip="Sent"></i>
              </ng-template>
            </td>
            <td class="date-column">
              {{ row.created_by ? row.created_by : 'System' }} <br>
              <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #createdDateTime>
                {{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
            </td>
          </tr>
        </tbody>
        <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
      </table>
    </div>
    <div class="kt-pagination kt-pagination--brand mt-2 mb-4" *ngIf="pagination !== undefined">
      <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true"
        [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
      </ngb-pagination>
      <div class="kt-pagination__toolbar">
        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
            <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
        </select>
        <span class="pagination__desc">
          Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
        </span>
      </div>
    </div>
  </mat-tab>

  <mat-tab *ngIf="canViewWebPushMessages" label="Web Push">
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Message</th>
            <th class="text-center">Status</th>
            <th>Created By</th>
          </tr>
        </thead>
        <tbody *ngIf="messageHistory$?.length > 0">
          <tr *ngFor="let row of messageHistory$">
            <td>{{ row.id }}</td>
            <td [innerHTML]="row.message"></td>
            <td class="text-center">
              <ng-container *ngIf="row.status === 1; else elseTemplate">
                <i class="fas fa-check-double text-success" matTooltip="Seen"></i>
              </ng-container>
              <ng-template #elseTemplate>
                <i class="fas fa-check text-secondary" matTooltip="Sent"></i>
              </ng-template>
            </td>
            <td class="date-column">
              {{ row.created_by ? row.created_by : 'System' }} <br>
              <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #createdDateTime>
                {{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
            </td>
          </tr>
        </tbody>
        <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
      </table>
    </div>
    <div class="kt-pagination kt-pagination--brand mt-2 mb-4" *ngIf="pagination !== undefined">
      <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true"
        [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
      </ngb-pagination>
      <div class="kt-pagination__toolbar">
        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
            <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
        </select>
        <span class="pagination__desc">
          Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
        </span>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
