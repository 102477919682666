import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Status } from '@core/enums/status.enum';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { PromotionCodeDataService } from '@views/pages/apps/general/promotion-codes/services/promotion-code-data.service';
import { catchError, tap } from 'rxjs/operators';
import { VipSettingsDataService } from '../services/vip-settings-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { Subscription } from 'rxjs';

type MemberGroupDropdownListItem = {
    id: number;
    code: string;
    name: string;
    trial: number;
}

@Component({
    templateUrl: './vip-settings-edit.component.html',
    styleUrls: ['./vip-settings-edit.component.scss']
})
export class VipSettingsEditDialogComponent implements OnInit, OnDestroy {

    form: FormGroup;

    dropdown = {
        currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
        promotionCode: [],
        groups: this.dropdownHttpService.groups,
        statuses: Status
    };

    memberGroupDropdownList: Array<MemberGroupDropdownListItem> = [];
    memberGroupDropdownSettings = {
        text: 'Please Select',
        enableSearchFilter: true,
        singleSelection: true,
        classes: 'dropdown',
        primaryKey: 'id',
        labelKey: 'name',
        noDataLabel: '',
        showCheckbox: false
    };
    memberGroupSelectedItems = [];
    promotionCodeDropdownSettings = {
        singleSelection: true,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'name',
        noDataLabel: '',
        showCheckbox: false
    };
    promotionCodeDropdownList = [];
    upgradeBonusSelectedItems = [];
    birthdayBonusSelectedItems = []

    messages$ = this.vipSettingsDataService.messages$;
    buttonLoading = false;

    // permissions
    canViewPromotionCodeList: boolean;

    private subscriptions = new Subscription();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { vipSettings: any, mode: string },
        public dialogRef: MatDialogRef<VipSettingsEditDialogComponent>,
        private dropdownHttpService: DropdownHttpService,
        private vipSettingsDataService: VipSettingsDataService,
        private promotionCodeDataService: PromotionCodeDataService,
        private appPermissionService: AppPermissionService,
    ) { }

    ngOnInit() {
        if(this.dropdown.currencies.length === 0){
            this.dropdownHttpService.currencies.subscribe( res => {
              this.dropdown.currencies = res;
            });
        }
        this.dropdown.groups.subscribe(res => {
            const data = []
            res.forEach(row => {
                data.push({
                    id: row.id,
                    code: row.code,
                    name: row.name,
                    trial: row.trial
                })
            });
            this.memberGroupDropdownList = data;
        });
        this.formInit();
        this.promotionCodeDataService.getWithQuery(`?paginate=false`).subscribe(res => {
            this.dropdown.promotionCode = res;
            this.promotionCodeDropdownList = res;
            this.promotionCodeDropdownList.map(function (elm) {
                elm['name'] = elm.code + ' - ' + elm.name;
            });

            if (this.data.mode === 'edit') {
                if (this.data.vipSettings.upgrade_promotion_id !== 0) {
                    this.upgradeBonusSelectedItems.push({
                        id: this.data.vipSettings.upgrade_promotion_id,
                        name: this.data.vipSettings.upgrade_promotion_code + ' - ' + this.data.vipSettings.upgrade_promotion_name
                    });
                };
                if (this.data.vipSettings.birthday_promotion_id !== 0) {
                    this.birthdayBonusSelectedItems.push({
                        id: this.data.vipSettings.birthday_promotion_id,
                        name: this.data.vipSettings.birthday_promotion_code + ' - ' + this.data.vipSettings.birthday_promotion_name
                    })
                }
            };
        });

        const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
            this.canViewPromotionCodeList = appPermissions.view_promotion_codes_list;
        });

        this.subscriptions.add(apSub);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onCloseDialog(event?: Event, refresh = false) {
        this.dialogRef.close(refresh);
    }

    onSave() {
        this.buttonLoading = true;
        // To set "Save" button to disable (To prevent call API in multiple times when double click)
        this.form.setErrors({ 'invalid': true });

        const data = {
            ...this.form.value,
            ftd: this.form.value.ftd ? 1 : 0,
            upgrade_promotion_id: this.data.mode === 'edit' && this.form.value.upgrade_promotion_id === null ? 0 : this.form.value.upgrade_promotion_id,
            birthday_promotion_id: this.data.mode === 'edit' && this.form.value.birthday_promotion_id === null ? 0 : this.form.value.birthday_promotion_id,
        };
        Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
        switch (this.data.mode) {
            case 'edit':
                this.vipSettingsDataService.update(this.data.vipSettings.id, data).pipe(
                    tap((res: any) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                    }),
                    catchError((error) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                        throw error;
                    })
                ).subscribe();
                break;
            case 'create':
                this.vipSettingsDataService.add(data).pipe(
                    tap((res: any) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                    }),
                    catchError((error) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                        throw error;
                    })
                ).subscribe();
                break;
        }
    }

    onFTDTargetAmount(type: string) {
        if (type === 'ftd') {
            this.form.patchValue({ target_amount: null });
        } else {
            if (this.form.value.target_amount) {
                this.form.patchValue({ ftd: 0 });
            }
        }

        this.form.controls.ftd.clearValidators();
        this.form.controls.target_amount.setValidators([Validators.required, Validators.min(1)]);

        if (this.form.value.ftd) {
            this.form.controls.ftd.setValidators(Validators.required);
            this.form.controls.target_amount.clearValidators();
        }

        if (this.form.value.trial) {
            this.form.controls.target_amount.clearValidators();
        }

        this.updateFormValidity();
    }

    private updateFormValidity() {
        Object.keys(this.form.controls).forEach((key) => {
            if (key === 'target_amount' || key === 'ftd') {
                this.form.get(key).updateValueAndValidity();
            }
        });
    }

    private formInit() {
        let name = null;
        let status = 1;
        let currency_id = null;
        let member_group_id = null;
        let target_amount = null;
        let upgrade_promotion_id = null;
        let birthday_promotion_id = null;
        let days = null;
        let service_priority = null;
        let lc_rebate = null;
        let sl_rebate = null;
        let sp_rebate = null;
        let reward_bonus = null;
        let birth_rebate = null;
        let ftd = 0;
        let trial = 0;

        if (this.data.mode === 'edit') {
            name = this.data.vipSettings.name;
            status = this.data.vipSettings.status;
            currency_id = this.data.vipSettings.settings_currency_id;
            member_group_id = this.data.vipSettings.member_group_id;
            target_amount = this.data.vipSettings.target_amount;
            upgrade_promotion_id = this.data.vipSettings.upgrade_promotion_id > 0 ? this.data.vipSettings.upgrade_promotion_id : null;
            birthday_promotion_id = this.data.vipSettings.birthday_promotion_id > 0 ? this.data.vipSettings.birthday_promotion_id : null;
            days = this.data.vipSettings.days;
            service_priority = this.data.vipSettings.service_priority;
            lc_rebate = this.data.vipSettings.lc_rebate;
            sl_rebate = this.data.vipSettings.sl_rebate;
            sp_rebate = this.data.vipSettings.sp_rebate;
            reward_bonus = this.data.vipSettings.reward_bonus;
            birth_rebate = this.data.vipSettings.birth_rebate;
            ftd = this.data.vipSettings.ftd;
            trial = this.data.vipSettings.trial;

            var interval = setInterval(() => {
                if (this.memberGroupDropdownList.length > 0) {
                    this.memberGroupSelectedItems = [this.memberGroupDropdownList.find(x => x.id === member_group_id)];
                    clearInterval(interval);
                }
            }, 500);
        }

        this.form = new FormGroup({
            name: new FormControl(name, [Validators.required]),                                     // STRING
            status: new FormControl(status, [Validators.required]),                                 // NUMBER
            currency_id: new FormControl(currency_id, [Validators.required]),                       // ID
            member_group_id: new FormControl(member_group_id, [Validators.required]),               // ID
            target_amount: new FormControl(target_amount),                                          // NUMBER
            upgrade_promotion_id: new FormControl(upgrade_promotion_id),                            // ID
            birthday_promotion_id: new FormControl(birthday_promotion_id),                          // ID
            days: new FormControl(days, [Validators.required]),                                     // NUMBER
            service_priority: new FormControl(service_priority, [Validators.required]),             // STRING
            lc_rebate: new FormControl(lc_rebate, [Validators.required]),                           // NUMBER
            sl_rebate: new FormControl(sl_rebate, [Validators.required]),                           // NUMBER
            sp_rebate: new FormControl(sp_rebate, [Validators.required]),                           // NUMBER
            reward_bonus: new FormControl(reward_bonus, [Validators.required]),                     // NUMBER
            birth_rebate: new FormControl(birth_rebate, [Validators.required]),                     // NUMBER
            ftd: new FormControl(ftd, [Validators.required]),                                       // NUMBER
            type: new FormControl(1, [Validators.required]),                                        // NUMBER *NOTE: THIS FIELD IS PERMANENT, NOT CHANGEABLE*
            trial: new FormControl(trial, [Validators.required])                                    // NUMBER, this field is used to validate target amount only, not updated to database
        });

        this.form.get('member_group_id').valueChanges.subscribe(val => {
            const memberGroup = this.memberGroupDropdownList.find(l => l.id === val);
            this.memberGroupSelectedItems = memberGroup ? [memberGroup] : [];

            if (!memberGroup?.trial) {
                this.form.controls.target_amount.setValidators([Validators.required, Validators.min(1)]);
            } else {
                this.form.controls.target_amount.clearValidators();
            }

            this.form.get('target_amount').updateValueAndValidity();

            // update trial form value
            this.form.patchValue({
                trial: memberGroup?.trial
            });
        });

        if (this.data.mode === 'edit') {
            if (this.form.value.ftd) {
                this.form.controls.ftd.setValidators(Validators.required);
                this.form.controls.target_amount.clearValidators();
            } else {
                this.form.controls.ftd.setValidators(Validators.required);

                // target amount is optional if member group is trial
                if (this.form.value.trial == 0) {
                    this.form.controls.target_amount.setValidators([Validators.required, Validators.min(1)]);
                }
            }
            this.updateFormValidity();
        } 
    }
}
