<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Import Leads</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <!-- Currency -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">Currency <span class="text-danger">*</span> </label>
          <select (change)="resetFileUpload($event.target.value)" formControlName="currency_id" class="col-8 form-control">
            <option value="null" disabled hidden> Please Select </option>
            <option [value]="value.id" *ngFor="let value of currencies"> {{ value.name }} </option>
          </select>
        </div>
        <!-- File Upload -->
        <div class="col-12 form-group row pr-0 mb-0">
          <label class="col-4 col-form-label">Leads <span class="text-danger">*</span> </label>
          <!-- <label class="col-2 btn btn-default btn-sm float-left">
            <span>Choose file</span>
            <input type="file" hidden accept="image/*" (change)="onUploadFile($event)">
          </label> -->
          <label class="col-2 btn btn-default btn-sm float-left" [ngClass]="loading || !form.valid ? 'disabled' : ''">
            <ng-container *ngIf="loading; else elseLoaded">
              <mat-spinner [diameter]="25" style="margin: 0 auto;"></mat-spinner>
            </ng-container>
            <ng-template #elseLoaded>
              <span>Choose file</span>
              <input type="file" hidden id="file" accept=".csv" (change)="onFileUpload($event)" #myFileInput [disabled]="loading || !form.valid">
            </ng-template>
          </label>
          <p class="col-6 m-auto file-name m-t-10">{{ fileName }}</p>
        </div>
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label"></label>
          <div class="col-4 template p-0"><i class="fa fa-file-excel"></i><a href="assets/ExampleImportLeads.csv" target="_blank" download="ExampleImportLeads.csv">&nbsp;{{ 'Download Template' }}</a></div>
        </div>
        <!-- <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">Sample</label>
          <ng-container><img class="img-thumbnail w-25 h-auto" role="button" (click)="onOpenSample()" [src] ="'assets/img/import_sample.png'" ></ng-container>
        </div> -->
        <!-- Assign to Telemarketer -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">Assign to Telemarketer</label>
          <kt-dropdown-wo-lazyload class="col-8" style="padding:0"
            [form] = 'form'
            [dropdownList] = 'telemarketerDropdownList'
            [dropdownSettings] = 'telemarketerDropdownSettings'
            [formName] = "'telemarketer_id'"
            [selectionAttributes] = "'id'"
            [selectedItems] = 'telemarketerSelectedItems'>
          </kt-dropdown-wo-lazyload>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()" [disabled]="loading"><i class="fas fa-ban"></i>Close</button>
        <button class="btn btn-primary btn-icon-sm mr-2" (click)="onSave()" type="button" [disabled]="buttonLoading || loading || !form.valid || !fileName"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>{{ 'Submit' }}</button>
        <!-- <input type="file" id="file" accept=".csv" (change)="onFileUpload($event)" #myFileInput style="display:none;" />
        <button id="button" name="button" value="Upload" class="btn btn-brand btn-icon-sm mr-2" (click)="myFileInput.click()" [disabled]="loading || !form.valid">
            <i [ngClass]="loading ? 'spinner-border spinner-border-sm align-middle' : 'fas fa-arrow-right'"></i>Next
        </button> -->
      </div>
    </div>
  </form>
</div>