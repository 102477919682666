<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode | titlecase }} Label and Remarks</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <div class="col-12 form-group row pr-0">
                        <label class="col-4 col-form-label">Merchant <span class="text-danger">*</span></label>
                        <select class="col-8 form-control" formControlName="merchant" disabled>
                            <option value="null"> Please Select </option>
                            <option [value]="item.id" *ngFor="let item of dropdown.merchant | async">{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="col-12 form-group row pr-0">
                        <label class="col-4 col-form-label">Name <span class="text-danger">*</span></label>
                        <kt-member-dropdown class="dropdown-maxheight col-8 p-0" [form]="form" [dropdownSettings]='dropdownSettings' [formName]="'member_account_id'"
                            [selectionAttributes]="'id'" [selectedItems]="selectedMember">
                        </kt-member-dropdown>
                    </div>
                    <div class="col-12 form-group row pr-0">
                        <label class="col-4 col-form-label">Label</label>
                        <select class="col-8 form-control" formControlName="label_code" (change)="onSetLabel($event)">
                            <option value="null"> Please Select </option>
                            <option [value]="item.code" *ngFor="let item of dropdown.label">{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="col-12 form-group row pr-0" *ngIf="selectedLabel.length > 0">
                        <label class="col-4 col-form-label">Link with </label>
                        <div class="col-8 p-0">
                            <kt-member-dropdown class="dropdown-maxheight" [form]="form" [dropdownSettings]='linkedMembersdropdownSettings' [formName]="'links'"
                                [selectionAttributes]="'id'" [selectedItems]="selectedLinkedMembers">
                            </kt-member-dropdown>
                            <p class="selected-member-wrapper">
                                <small *ngFor="let item of selectedLinkedMembers" class="selected-member bg-info">
                                    {{ item.username }}
                                    <span class="bg-danger remove" (click)="onRemoveLinkedMember(item.id)">x</span>
                                </small>
                            </p>
                        </div>
                    </div>
                    <div class="col-12 form-group row pr-0">
                        <label class="col-4 col-form-label">Remarks <span class="text-danger">*</span></label>
                        <div class="col-8 p-0">
                            <textarea rows="5" class="form-control" formControlName="remarks"></textarea>
                            <div class="custom-control custom-checkbox mr-3" *ngIf="data.mode === 'edit'">
                                <input type="checkbox" formControlName="default" class="custom-control-input" id="defaultCheck">
                                <label class="custom-control-label" for="defaultCheck">Default Remark</label>
                              </div>
                        </div>
                    </div>
                    <div class="col-12 form-group row pr-0" *ngIf="data.mode === 'edit'">
                        <label class="col-4 col-form-label">Edit Purpose <span class="text-danger">*</span></label>
                        <textarea rows="5" class="col-8 form-control" formControlName="edit_purpose"></textarea>
                    </div>
                    <div class="col-12 form-group row pr-0">
                        <label class="col-4 col-form-label">Status <span class="text-danger">*</span></label>
                        <select class="col-8 form-control" formControlName="status">
                            <option value="null"> Please Select </option>
                            <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">{{ value }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [buttonLoading]="buttonLoading" [isDisabled]="!form.valid" (confirmed)="onSave()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
