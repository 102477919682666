import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class UploadHttpService {

  constructor(private http: HttpClient) { }

  upload(data: FormData): Observable<any[]> {
    return this.http.post<ApiResponse>('/file',data).pipe(
      map(res => res.data.files)
    );
  }
}
