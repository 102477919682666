import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Agent } from '@core/models/agent.model';
import { Dropdown } from '@core/models/dropdown.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { forkJoin, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AgentDataService } from '../services/agent-data.service';

@Component({
  selector: 'kt-member-agent-form',
  templateUrl: './agent-edit.component.html',
  styleUrls: ['./agent-edit.component.scss']
})
export class AgentEditDialogComponent implements OnInit, OnDestroy  {

  form: FormGroup;
  messages$ = this.agentDataService.messages$;
  refreshStatus: boolean;
  // affType = JSON.parse(localStorage.getItem('user_data')).agent_type;
  // currentUplineID = JSON.parse(localStorage.getItem('user_data')).id;
  currentUplineID: number;

  buttonLoading = false;

  dropdown = {
    statuses: this.dropdownHttpService.statuses
  };
  currencyDropdown = JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies'));
  selectedCurrency: any;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, agent?: Agent, uplineID: number, agentType: number},
    public dialogRef: MatDialogRef<AgentEditDialogComponent>,
    private agentDataService: AgentDataService,
    private dropdownHttpService: DropdownHttpService,
    private authHttpService: AuthHttpService
  ) { }

  ngOnInit() {
    // if (this.data.agentType > 0) {
      this.currentUplineID = this.data.uplineID;
    //   this.affType = this.data.agentType;
    // }
    this.formInit();
    this.setCurrency();
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onCheck(event: Event) {
    const { checked } = event.target as HTMLInputElement;
  }

  onSave(mode?: string) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = forkJoin([
          this.agentDataService.updateAgent(this.data.agent.id, data).pipe(
            tap(() => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.agentDataService.messages$
        ]).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.agentDataService.add(data).pipe(
            tap(() => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.agentDataService.messages$
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.onSave(this.data.mode);
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private setCurrency() {
    const selectCurrency = () => {
      if (this.data.mode === 'edit'){
        this.selectedCurrency = [this.currencyDropdown.find(currency => currency.name === this.data.agent.currency_code)];
      }
    };

    if(this.currencyDropdown.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.currencyDropdown = res;
        selectCurrency();
      });
    }else{
      selectCurrency();
    }
  }

  private formInit() {
    let status = 1;
    let name = null;
    let username = null;
    let remarks = null;
    let commissionRate = null;
    let currencyId = null;

    if (this.data.mode === 'edit'){
      status =  this.data.agent.status;
      username = this.data.agent.username;
      name = this.data.agent.name;
      remarks =  this.data.agent.remarks;
      commissionRate = this.data.agent.commission_rate;
      currencyId = this.data.agent.settings_currency_id;
    }
    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      username: new FormControl(username, this.data.mode === 'create' ? [Validators.required] : null),
      password: new FormControl(null, this.data.mode === 'create' ? [Validators.required] : null),
      confirm_password: new FormControl(null, this.data.mode === 'create' ? [Validators.required] : null),
      commission_rate: new FormControl(commissionRate, this.data.mode === 'create' ? [Validators.required] : null),
      currency_id: new FormControl(currencyId, this.data.mode === 'create' ? [Validators.required] : null),
      status: new FormControl(status, [Validators.required]),
      remarks: new FormControl(remarks),
      // upline_id: new FormControl(this.affType === 0 ? 0 : this.affType === 1 ? null : this.currentUplineID)
      upline_id: new FormControl(this.currentUplineID)
    });
  }

}
