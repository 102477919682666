// Angular
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
// NgBootstrap
import {NgbDropdownModule, NgbTooltipModule, NgbTabsetModule} from '@ng-bootstrap/ng-bootstrap';
// Perfect Scrollbar
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
// Core module
import {CoreModule} from '@core/core.module';
// Layout partials
import {
  LanguageSelectorComponent,
  NotificationComponent,
  QuickPanelComponent,
  ScrollTopComponent,
  SplashScreenComponent,
  Subheader1Component,
  UserProfileComponent,
  OnlineOperatorComponent
} from './layout';
import {PortletModule} from './content/general/portlet/portlet.module';
// Errpr
import {ErrorComponent} from './content/general/error/error.component';
// Extra module
import {WidgetModule} from './content/widgets/widget.module';
import { SharedModule } from "../../shared/shared.module";

const components = [
  SplashScreenComponent,
  ErrorComponent
];

const topbarComponents = [
  QuickPanelComponent,
  ScrollTopComponent,
  Subheader1Component,
  OnlineOperatorComponent,
  LanguageSelectorComponent,
  NotificationComponent,
  UserProfileComponent,
];

const material = [
  MatIconModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatTabsModule,
  MatTooltipModule,
  MatDialogModule,
  MatFormFieldModule,
  MatSelectModule
];

const ngBootstrap = [
  NgbDropdownModule,
  // tslint:disable-next-line: deprecation
  NgbTabsetModule,
  NgbTooltipModule
];

@NgModule({
    declarations: [
        ...components,
        ...topbarComponents,
    ],
    exports: [
        WidgetModule,
        PortletModule,
        CoreModule,
        ...components,
        ...topbarComponents,
        ...material,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        CoreModule,
        PortletModule,
        WidgetModule,
        ...material,
        ...ngBootstrap,
        SharedModule
    ]
})
export class PartialsModule {
}
