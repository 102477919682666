import { MemberPromotion } from '@core/models/member-promotion.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class MemberPromotionEntityService extends EntityCollectionServiceBase<MemberPromotion> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('MemberPromotion', serviceElementsFactory);
  }
}
