<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter Form -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Category:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="category_code" class="form-control" (change)="onRefreshGameProviderDropdown()">
                                        <option value="all"> All </option>
                                        <option *ngFor="let value of dropdown.categories | async" [value]="value.code">
                                            {{ value.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control" (change)="onRefreshGameProviderDropdown()">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Game Provider:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="game_provider_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.gameProviders">
                                            {{ value.code }} - {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                  <select formControlName="status" class="form-control">
                                    <option value="all"> All </option>
                                    <option *ngFor="let item of dropdown.status" [value]="item.id">{{ item.name }}</option>
                                  </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Action Button -->
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <!-- <div class="dropdown dropdown-inline">
                </div> -->
            </div>

            <!-- Table -->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <!-- Header -->
                            <thead>
                                <tr>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('code')" [ngClass]="sortingConfig.code" [class.sort-selected]="sortingSelection.sort_by === 'code'">Code</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                                    <th class="text-center">Category</th>
                                    <th class="text-center">Currency</th>
                                    <th class="text-center">Position</th>
                                    <th class="text-center">Updated By</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <!-- Body -->
                            <tbody *ngIf="gameProviderPosition$ | async as games">
                                <ng-container *ngFor="let row of games; let i = index">
                                    <tr [ngClass]="i % 2 === 0 ? 'even' : 'odd'">
                                        <td [attr.rowspan]="onGetRowCount(row, false)" class="text-center align-middle"> {{ row.code }} </td>
                                        <td [attr.rowspan]="onGetRowCount(row, false)" class="text-center align-middle"> {{ row.name }} </td>
                                        <td [attr.rowspan]="onGetRowCount(row, false)" class="text-center align-middle"> 
                                            <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span> 
                                        </td>
                                        <td [attr.rowspan]="onGetRowCount(row.category_list[0], true)" class="text-center align-middle"> {{ row.category_list[0].category_name }} </td>
                                        <td class="text-center align-middle"> {{ row.category_list[0].position_list[0] ? row.category_list[0].position_list[0].currency_code : '' }} </td>
                                        <td class="text-center align-middle"> {{ row.category_list[0].position_list[0] ? row.category_list[0].position_list[0].position : '' }} </td>
                                        <td [attr.rowspan]="onGetRowCount(row, false)" class="align-middle">
                                            {{ row.updated_by ? row.updated_by : 'System' }} <br />
                                            <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
                                            <ng-template #updatedDateTime>
                                                {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                            </ng-template>
                                        </td>
                                        <td [attr.rowspan]="onGetRowCount(row, false)" class="text-center text-middle">
                                            <button *ngIf="canEditGameProviderPosition" title="Edit Maintenance"
                                                class="col-6 btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"
                                                (click)="onOpenDialog(row)"  
                                                [disabled]="disabledEditButton">
                                                <i class="fas fa-edit"></i>
                                            </button>
                                        </td>
                                    </tr>

                                    <ng-container *ngFor="let position of row.category_list[0].position_list | slice:1">
                                        <tr [ngClass]="i % 2 === 0 ? 'even' : 'odd'">
                                            <td class="text-center align-middle"> {{ position.currency_code ? position.currency_code : '' }} </td>
                                            <td class="text-center align-middle"> {{ position.position ? position.position : '' }} </td>
                                        </tr>
                                    </ng-container>

                                    <!-- If game provider have multiple categories -->
                                    <ng-container *ngIf="row.category_list.length > 1">
                                        <ng-container *ngFor="let category of row.category_list | slice:1">
                                            <tr [ngClass]="i % 2 === 0 ? 'even' : 'odd'">
                                                <td [attr.rowspan]="onGetRowCount(category, true)" class="text-center align-middle"> {{ category.category_name }} </td>
                                                <td class="text-center align-middle"> {{ category.position_list[0] ? category.position_list[0].currency_code : '' }} </td>
                                                <td class="text-center align-middle"> {{ category.position_list[0] ? category.position_list[0].position : '' }} </td>
                                            </tr>

                                            <ng-container *ngFor="let position of category.position_list | slice:1">
                                                <tr [ngClass]="i % 2 === 0 ? 'even' : 'odd'">
                                                    <td class="text-center align-middle"> {{ position.currency_code ? position.currency_code : '' }} </td>
                                                    <td class="text-center align-middle"> {{ position.position ? position.position : '' }} </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </tbody>
                            
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <!-- Pagination -->
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                            [directionLinks]="true" [boundaryLinks]="true" [rotate]="true"
                            [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                                class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number :
                                '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>
