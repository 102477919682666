import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Banner } from '@core/models/banner.model';

@Injectable()
export class BannerEntityService extends EntityCollectionServiceBase<Banner> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Banner', serviceElementsFactory);
    }
}

