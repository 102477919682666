import { TableHandlerComponent } from './table-handler/table-handler.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime/date-time';
import { PromotionApplicationSharedComponent } from './promotion-application/promotion-application-shared.component';
import { SubmitButtonComponent } from './submit-button/submit-button.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwalAlertComponent } from './swal-alert/swal-alert.component';
import { FallbackRowComponent } from './fallback-row/fallback-row.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MemberDropdownComponent } from './member-dropdown/member-dropdown.component';
import { DropdownWoLazyloadComponent } from './dropdown-wo-lazyload/dropdown-wo-lazyload.component';
import { MemberReportListComponent } from './member-report-list/member-report-list.component';
import { NgbPaginationModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSummernoteModule } from 'ngx-summernote';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SearchButtonComponent } from './search-button/search-button.component';
import { ClearButtonComponent } from './clear-button/clear-button.component';
import { PushNotificationComponent } from './push-notification/push-notification.component';
import { DropdownWoLazyloadFooterComponent } from './dropdown-wo-lazyload-footer/dropdown-wo-lazyload-footer.component';
// import { UiSwitchModule } from 'ngx-toggle-switch';
import { SyncSaveButtonComponent } from './sync-save-button/sync-save-button.component'
import { TranslateModule } from '@ngx-translate/core';
// import { UiSwitchModule } from 'ngx-toggle-switch';

const components = [
  MemberDropdownComponent,
  SwalAlertComponent,
  FallbackRowComponent,
  DropdownWoLazyloadComponent,
  MemberReportListComponent,
  SubmitButtonComponent,
  PromotionApplicationSharedComponent,
  SearchButtonComponent,
  TableHandlerComponent,
  ClearButtonComponent,
  PushNotificationComponent,
  DropdownWoLazyloadFooterComponent,
  SyncSaveButtonComponent
];

const entries = [
  PushNotificationComponent,
];

@NgModule({
  declarations: [...components],
  entryComponents: [...entries],
  exports: [
    ...components,
    ClipboardModule,
    NgbPaginationModule,
    NgxSummernoteModule,
    CKEditorModule,
    TranslateModule
    // UiSwitchModule
  ],
  imports: [
    CommonModule,
    AngularMultiSelectModule,
    FormsModule,
    ClipboardModule,
    NgbPaginationModule,
    NgxSummernoteModule,
    MatProgressSpinnerModule,
    CKEditorModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    NgxDaterangepickerMd.forRoot(),
    TranslateModule
    // UiSwitchModule
  ]
})
export class SharedModule { }
