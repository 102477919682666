import { TransactionHttpService } from '@core/services/transaction-http.service';
import { NewRebateReleaseDataService } from '../../services/new-rebate-release-data.service';
import { Subscription, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Rebate } from '@core/models/rebate.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { GameProvider } from '@core/models/game-provider.model';
import { GameProviderBlacklistComponent } from '../../../../general/promotion-codes/dialogs/game-provider-blacklist/game-provider-blacklist.component';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { GameCategory } from '@core/models/game-category.model';
import { catchError, debounceTime, tap, map } from 'rxjs/operators';
import { RebateDataService } from "../../../../../apps/rebates/rebates/services/rebate-data.service";
;
@Component({
  selector: 'kt-rebate-update-setting',
  templateUrl: './rebate-update-setting.component.html',
  styleUrls: ['./rebate-update-setting.component.scss'],
})
export class RebateUpdateSettingDialogComponent implements OnInit, OnDestroy {
  dropdown = {
    members: this.dropdownHttpService.members,
    memberGroups: this.dropdownHttpService.groups,
    gameProviders: this.gameProviderHttpService.getGameProviders(),
    gameCategories: this.dropdownHttpService.gameCategories,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : this.dropdownHttpService.currencies
  };

  memberGroupsDropdownList = [];
  filteredMemberGroupDropdownListDefault  = [];
  memberGroupsSelectedItems: any;
  filteredMemberGroupDropdownList = [];

  gameProvidersDropdownList = [];
  gameProvidersDropdownLoading = false;
  gameProvidersSelectedItems = [];

  categoriesDropdownList  = [];
  categoriesDropdownListDefault  = [];
  categoriesSelectedItems = [];

  currenciesDropdownList = [];
  currenciesDropdownListDefault = [];
  currenciesSelectedItems: any;

  memberAccountSelectedItems = [];

  currencyId:any;
  isFilterByCurrency = true;

  membergroupId:any;
  isFilterByMembergroupId = true;

  form: FormGroup;
  messages$ = this.rebateReleaseDataService.messages$;
  ranges = this.transactionHttpService.ranges;
  dateTimePickerLocale = { format: 'YYYY-MM-DD', firstDay: 1 };
  private subscription = new Subscription();

  buttonLoading = false;
  dropdownLoading = true;

  dropdownSettings = {
    singleSelection: false,
    text: this.translateService.instant('Please Select'),
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 100,
    position: 'bottom',
    primaryKey: 'id',
    labelKey: 'name',
    lazyLoading: false,
    noDataLabel: '',
    showCheckbox: false,
    disabled: true
  };

  currencyDropdownSettings = {
    singleSelection: false,
    text: this.translateService.instant('Please Select'),
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 100,
    position: 'bottom',
    primaryKey: 'id',
    labelKey: 'name',
    lazyLoading: false,
    noDataLabel: '',
    showCheckbox: false,
    disabled: true
  };
  
  memberGroupDropdownSettings = {
    singleSelection: false,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 'auto',
    primaryKey: 'id',
    labelKey: 'name',
    lazyLoading: false,
    noDataLabel: '',
    showCheckbox: false,
    disabled: true
  };

  checkValidation = false;

  categoriesDropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'name',
    labelKey: 'name',
    lazyLoading: false,
    noDataLabel: '',
    showCheckbox: false,
    disabled: true
  };

  gameProviderDropdownSettings = {
    singleSelection: false,
    text: this.translateService.instant('Please Select'),
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 120,
    position: 'top',
    primaryKey: 'id',
    labelKey: 'labelKey',
    lazyLoading: false,
    noDataLabel: '',
    showCheckbox: false,
    disabled: true
  };

  memberAccountDropdownSettings = {
    singleSelection: false,
    text: this.translateService.instant('Please Select'),
    enableFilterSelectAll: true,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 200,
    primaryKey: 'id',
    labelKey: 'username',
    lazyLoading: false,
    noDataLabel: '',
    showCheckbox: false,
    searchBy: ["username"],
    disabled: true
  };

  blacklistedGameProviders$ = this.gameProviderHttpService.blacklistedGameProviders$;
  blacklistedGameProvidersData = null;
  isBlackListFormOpened = false;
  blacklistedGameProvidersDataShow = null;

  allGameProvider: any;
  viewOnly = false;
  start_datetime = null;
  end_datetime = null;
  member_list = [];

  rebateSettings = [];
  blacklist_sub_categories:any;
  default_blacklist_sub_categories:any;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { rebate: Rebate; mode: string },
    private rebateReleaseDataService: NewRebateReleaseDataService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    public dialogRef: MatDialogRef<RebateUpdateSettingDialogComponent>,
    private translateService: TranslateService,
    private gameProviderHttpService: GameProviderHttpService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private rebateDataService: RebateDataService
  ) { 
    this.gameProviderHttpService.selectionMode = 'fresh';
  }

 ngOnInit() {
    this.formInit();
    // Get rebate settings
    this.rebateDataService.getAll().subscribe((res) => {
      this.rebateSettings = res
      let data = [];
      res.forEach(item => {
          if(item.member_groups.length > 0){
            data.push(item)
          }
      });
      this.rebateSettings = data
    });

    if(this.data && this.data.rebate){
      this.start_datetime = moment(this.data.rebate['request_params']['start_datetime']).format('YYYY-MM-DD')
      this.end_datetime = moment(this.data.rebate['request_params']['end_datetime']).format('YYYY-MM-DD')
      this.viewOnly = true;
    }
  }

  ngAfterViewInit() {
    forkJoin(this.dropdown).subscribe((res: any) => {
      this.memberGroupsDropdownList = res.memberGroups;
      this.allGameProvider= res.gameProviders;
      this.filteredMemberGroupDropdownListDefault = this.filteredMemberGroupDropdownList = this.convertArray(this.memberGroupsDropdownList);
      this.categoriesDropdownListDefault = this.categoriesDropdownList = this.convertArray(res.gameCategories);
      this.currenciesDropdownListDefault = res.currencies;
      this.dropdownLoading = false;

      if(this.data && this.data.rebate){

        this.form.patchValue({
          currency_id: this.data.rebate['request_params']['currency_id'] ? this.data.rebate['request_params']['currency_id'] : null,
          member_account_id:  this.data.rebate['request_params']['member_account_id'] ? this.data.rebate['request_params']['member_account_id'] : null,
          percentage:  this.data.rebate['request_params']['percentage'] ? this.data.rebate['request_params']['percentage'] : null,
          provider_id:  this.data.rebate['request_params']['provider_id'] ? this.data.rebate['request_params']['provider_id'] : null,
          category_id:  this.data.rebate['request_params']['category_id'] ? this.data.rebate['request_params']['category_id'] : null,
          member_group_id:  this.data.rebate['request_params']['member_group_id'] ? this.data.rebate['request_params']['member_group_id'] : null,
          rebate_setting_id :  this.data.rebate['request_params']['rebate_setting_id'] ? this.data.rebate['request_params']['rebate_setting_id'] : null,
        });
        this.currenciesSelectedItems = [];
        this.currenciesDropdownListDefault.forEach(item => { this.data.rebate['request_params']['currency_id'].forEach(r => { if(+r === +item.id) { this.currenciesSelectedItems.push(item); }}); });
        this.currenciesSelectedItems = Array.from(new Set(this.currenciesSelectedItems)); 
        this.currenciesDropdownList = Array.from(new Set(this.currenciesSelectedItems)); 
        this.categoriesDropdownList.forEach(item => { this.data.rebate['request_params']['category_id'].forEach(r => { if(+r === +item.id) { this.categoriesSelectedItems.push(item); }}); });

        this.gameProvidersDropdownList = this.allGameProvider.map((item: any) => {
          return {
            id: item.id,
            code: item.code,
            name: item.name,
            labelKey: item.code + " - " + item.name
          };
        });
        this.gameProvidersDropdownList.forEach(item => { this.data.rebate['request_params']['provider_id'].forEach(r => { if(+r === +item.id) { this.gameProvidersSelectedItems.push(item); }}); });
        this.gameProvidersSelectedItems = Array.from(new Set(this.gameProvidersSelectedItems));  

        if(this.data.rebate['request_params']['member_group_id']){
          this.memberGroupsSelectedItems = [];
          this.filteredMemberGroupDropdownList.forEach(item => { this.data.rebate['request_params']['member_group_id'].forEach(r => { if(+r === +item.id) { this.memberGroupsSelectedItems.push(item); }}); });
          this.memberGroupsSelectedItems = Array.from(new Set(this.memberGroupsSelectedItems));  
        }

        if(this.data.rebate['member_list']){
          this.member_list = [];
          var fake_member_account_id = [];
          for(let i = 0; i < this.data.rebate['member_list'].length; i++) {
            const data = {
              id: i,
              name: this.data.rebate['member_list'][i]
            }
            this.member_list.push(data);
            fake_member_account_id.push(key);
          }
          this.member_list = Array.from(new Set(this.member_list));
          this.form.patchValue({ member_account_id: fake_member_account_id});
        }

        let data = [];
        let gameProviders;
        let params;
        for (var key in this.data.rebate['request_params']['blacklist_sub_categories']) {
          gameProviders = this.gameProvidersDropdownList.find( x => x['id'] == key);
          params = {
            gameProviders_name: gameProviders.name,
            sub_category_name: (this.data.rebate['request_params']['blacklist_sub_categories'][key]).toString()
          };
          data.push(params)
        }
        this.blacklistedGameProvidersDataShow = data;
       
        this.memberAccountDropdownSettings = {
          ...this.memberAccountDropdownSettings,
          disabled: true,
        };
  
        this.dropdownSettings = {
          ...this.dropdownSettings,
          disabled:true
        };

        this.currencyDropdownSettings = {
          ...this.currencyDropdownSettings,
          disabled:true
        };
  
        this.categoriesDropdownSettings = {
          ...this.categoriesDropdownSettings,
          disabled:true
        };
  
        this.memberAccountDropdownSettings = {
          ...this.memberAccountDropdownSettings,
          disabled:true,
        };
  
        this.memberGroupDropdownSettings = {
          ...this.memberGroupDropdownSettings,
          disabled:true,
        };

        this.cdr.detectChanges();
  
      }
    });
  }

  ngOnDestroy() {
    this.gameProviderHttpService.blacklistedGameProviders$.next(null);
    this.gameProviderHttpService.selectedSubCategories$.next(null);
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave() {
    this.onBlacklistedGameProviders();
    const blacklistedGameProvidersData = this.blacklistedGameProvidersData;

    this.checkValidation = true;
    if (this.form.valid) {
      this.buttonLoading = true;
      // To set 'Save' button to disable (To prevent call API in multiple times when double click)
      this.form.setErrors({ 'invalid': true });
      const data = {
        ...this.form.value,
        start_datetime: moment(this.form.value.start_datetime)
          // .utc()
          .format('YYYY-MM-DD'),
        end_datetime: moment(this.form.value.end_datetime)
          // .utc()
          .format('YYYY-MM-DD'),
        ...blacklistedGameProvidersData
      };
      Object.keys(data).forEach(
        (key) =>
          (data[key] == null || data[key] === '' || key === 'defaultDate' || (Array.isArray(data[key]) && data[key].length == 0 )) &&
          delete data[key]
      );
       this.subscription = this.rebateReleaseDataService.updateRebate(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
    }
  }

  onDateRange(event: any, type: string) {
    if (event) {
      this.form.patchValue({
        start_datetime:
          event.startDate !== null ? event.startDate._d : event.startDate,
        end_datetime: event.endDate !== null ? event.endDate._d : event.endDate,
      });
    }
  }

  onClearDate(type: string) {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({
        start_datetime: null,
        end_datetime: null,
        defaultDate: null,
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private formInit() {
    this.form = new FormGroup({
      currency_id: new FormControl(null),
      start_datetime: new FormControl(null, [Validators.required]),
      end_datetime: new FormControl(null, [Validators.required]),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      member_account_id: new FormControl(null),
      percentage: new FormControl(null, [Validators.required]),
      sub_category_id: new FormControl(null),
      provider_id: new FormControl(null,[Validators.required]),
      category_id: new FormControl(null,[Validators.required]),
      member_group_id: new FormControl(null,[Validators.required]),
      rebate_setting_id: new FormControl(null, [Validators.required]),
    });
  }

  onBlacklistDialog(selectedProviders: GameProvider[]) {
    const data = {
      providers: selectedProviders,
      blacklist_sub_categories: this.blacklist_sub_categories,
      default_blacklist_sub_categories: this.default_blacklist_sub_categories
    };
    if(this.gameProvidersSelectedItems.length >= 1) {
      this.isBlackListFormOpened = true;
      this.openDialogBy(GameProviderBlacklistComponent, data);
    }
  }

  private openDialogBy(componentRef: any, data?: { promotionCurrency?: any, promotionCode?: any, providers?: any[], blacklist_sub_categories?: any[], promotionType?: any, gameProviderList?: any,default_blacklist_sub_categories?: any[]}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '1300px',
      data: {
        // promotionCurrency: data.promotionCurrency,
        // promotionCode: data.promotionCode,
        providers: data.providers,
        blacklist_sub_categories: data.blacklist_sub_categories,
        promotionType: data.promotionType,
        gameProviderList: data.gameProviderList,
        default_blacklist_sub_categories: data.default_blacklist_sub_categories
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.onBlacklistedGameProviders();
    });
  }

  onBlacklistedGameProviders() {
    this.gameProviderHttpService.blacklistedGameProviders$.subscribe(res => {
      let data: {[k: number]: any[]} = {};
      if (this.isBlackListFormOpened){
        if(res?.game_providers !== null && (res?.game_providers)?.length >= 1) {
            (res?.game_providers.filter((row: any) => row.blacklist_sub_categories !== null)).forEach((item: any) => {
              data = {
                ...data,
                [item.game_provider_id]: item.blacklist_sub_categories
              };
            });
        }
      }else{
        this.gameProvidersSelectedItems.map( res => {
          let list;
          if (this.blacklist_sub_categories.find( x => x['game_provider_id'] === res.id) !== undefined){
            list = this.blacklist_sub_categories.find( x => x['game_provider_id'] === res.id);
            data = {
              ...data,
              [list.game_provider_id]: list['sub_category_name']
            };
          }
        });
      }

      if(Object.keys(data)) {
        const finalData = {
          blacklist_sub_categories: data
        }
        this.blacklistedGameProvidersData = finalData;

        this.blacklistedGameProvidersDataShow = null;

        let param = [];
        let gameProviders;
        let params;

        for (var key in data) {
          gameProviders = this.gameProvidersDropdownList.find( x => x['id'] == key);
          if(data[key]){
            params = {
              gameProviders_name: gameProviders.name,
              sub_category_name: (data[key]).toString()
            };
            param.push(params)
          }
        }

        this.blacklistedGameProvidersDataShow = param;

      }
    });
  }

  onSelectCurrency(event: Event){
    this.currenciesSelectedItems = this.currencyId = event;
    this.cdr.detectChanges();
    this.filterMemberAccountDropdown();
  }

  onSelectMemberGroup(event: Event){
    this.memberGroupsSelectedItems = this.membergroupId = event;
    this.cdr.detectChanges();
    this.filterMemberAccountDropdown();
  }

  filterMemberAccountDropdown() {

    if(this.currenciesSelectedItems.length > 0){
      this.memberAccountDropdownSettings = {
        ...this.memberAccountDropdownSettings,
        disabled: false,
      };

      this.cdr.detectChanges();
    }else{
      this.memberAccountDropdownSettings = {
        ...this.memberAccountDropdownSettings,
        disabled: true,
      };

      this.form.patchValue({
        member_group_id: null
      });

      this.memberGroupsSelectedItems = [];

      this.cdr.detectChanges();
    }
  }

  getSelectedCategories(selections: GameCategory[]) {

    // Reset
    this.gameProvidersSelectedItems = [];
    this.form.patchValue({
      game_providers: null
    })
  }

  convertArray(data){
    var convertData =  [];
    Object.keys(data).forEach(key => {
      var param = {
        'id' :null,
        'name' :null
      };
      param['id']= data[key]['id'];
      param['name'] = data[key]['name'];
      convertData.push(param);
    })
    return convertData;
  }

  onRebateSettingChange(){
    var rebateId = this.form.value.rebate_setting_id;

    if (rebateId) {
      this.dropdownLoading = true;
      this.cdr.detectChanges();
      this.rebateDataService.getById(rebateId).subscribe((res: any) => {
        // Pre-selected currencies
        this.currenciesSelectedItems = [];
        this.currenciesDropdownList = [];
        this.currenciesDropdownListDefault.forEach(item => { res.member_groups.forEach(r => { if(+r.settings_currency_id === +item.id) { 
          this.currenciesSelectedItems.push(item); 
        }}); 
        });
        this.currenciesSelectedItems = Array.from(new Set(this.currenciesSelectedItems));
        this.currenciesDropdownList = Array.from(new Set(this.currenciesSelectedItems));
        this.currencyId = this.currenciesSelectedItems;
        this.cdr.detectChanges();
        // Pre-selected categories
        this.categoriesSelectedItems = [];
        this.categoriesDropdownListDefault.forEach(item => { res.member_groups.forEach(r => { if(+r.category_id === +item.id) { this.categoriesSelectedItems.push(item); }}); });
        this.categoriesDropdownList = Array.from(new Set(this.categoriesSelectedItems)); 
        this.categoriesSelectedItems = Array.from(new Set(this.categoriesSelectedItems));

        // Pre-selected gameProviders
        this.gameProvidersDropdownList = this.allGameProvider.map((item: any) => {
          return {
            id: item.id,
            code: item.code,
            name: item.name,
            labelKey: item.code + " - " + item.name
          };
        });

        this.gameProvidersSelectedItems = [];
        this.gameProvidersDropdownList.forEach(item => { res.member_groups.forEach(r => { if(+r.game_provider_id === +item.id) { this.gameProvidersSelectedItems.push(item); }}); });
        this.gameProvidersDropdownList = Array.from(new Set(this.gameProvidersSelectedItems));
        this.gameProvidersSelectedItems = Array.from(new Set(this.gameProvidersSelectedItems));

        // Pre-selected memberGroups
        this.memberGroupsSelectedItems  = [];
        this.filteredMemberGroupDropdownListDefault.forEach(item => { res.member_groups.forEach(r => { if(+r.member_group_id === +item.id) { this.memberGroupsSelectedItems.push(item); }}); });
        this.memberGroupsSelectedItems = Array.from(new Set(this.memberGroupsSelectedItems));
        this.filteredMemberGroupDropdownList = Array.from(new Set(this.memberGroupsSelectedItems));
        this.membergroupId = this.memberGroupsSelectedItems;

        let data = [];
        let gameProviders;
        let params;
        this.default_blacklist_sub_categories  = this.blacklist_sub_categories = res.blacklist_sub_categories;

        for (var key in res.blacklist_sub_categories) {
          gameProviders = this.gameProvidersDropdownList.find( x => x['id'] == res.blacklist_sub_categories[key]['game_provider_id']);
          if(res.blacklist_sub_categories[key]['sub_category_name']){
            params = {
              gameProviders_name: gameProviders.name,
              sub_category_name: (res.blacklist_sub_categories[key]['sub_category_name']).toString()
            };
            data.push(params)
          }
        }
        this.blacklistedGameProvidersDataShow = data;

        this.form.patchValue({
          currency_id: this.currenciesSelectedItems.map(item => item.id),
          category_id: this.categoriesSelectedItems.map(item => item.id),
          member_group_id: this.memberGroupsSelectedItems.map(item => item.id),
          provider_id: this.gameProvidersSelectedItems.map(item => item.id)
        });

        this.dropdownSettings = {
          ...this.dropdownSettings,
          disabled: false,
        };

        this.currencyDropdownSettings = {
          ...this.currencyDropdownSettings,
          disabled:false
        };

        this.gameProviderDropdownSettings = {
          ...this.gameProviderDropdownSettings,
          disabled: false,
        };

        this.memberGroupDropdownSettings = {
          ...this.memberGroupDropdownSettings,
          disabled: false,
        };

        this.categoriesDropdownSettings = {
          ...this.categoriesDropdownSettings,
          disabled: false,
        };

        this.memberAccountDropdownSettings = {
          ...this.memberAccountDropdownSettings,
          disabled: false,
        };
        this.dropdownLoading = false;
        this.cdr.detectChanges();
      });
    } else {
      this.currenciesSelectedItems = [];
      this.categoriesSelectedItems = [];
      this.gameProvidersSelectedItems = [];
      this.memberGroupsSelectedItems = [];
      this.blacklist_sub_categories = null;
      this.blacklistedGameProvidersDataShow = null;
      this.form.patchValue({
        currency_id: null,
        category_id: null,
        member_group_id: null,
        provider_id: null
      })

      this.dropdownSettings = {
        ...this.dropdownSettings,
        disabled: true,
      };

      this.currencyDropdownSettings = {
        ...this.currencyDropdownSettings,
        disabled:true
      };

      this.gameProviderDropdownSettings = {
        ...this.gameProviderDropdownSettings,
        disabled: true,
      };

      this.memberGroupDropdownSettings = {
        ...this.memberGroupDropdownSettings,
        disabled: true,
      };

      this.categoriesDropdownSettings = {
        ...this.categoriesDropdownSettings,
        disabled: true,
      };

      this.memberAccountDropdownSettings = {
        ...this.memberAccountDropdownSettings,
        disabled: true,
      };

      this.currencyId = null;
      this.membergroupId = null;
      this.cdr.detectChanges();
    }
  }
  
}
