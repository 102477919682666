export enum TrafficSource {
    REFERRAL = 1,
    AFFILIATE_INTERNAL = 2,
    AFFILIATE_EXTERNAL = 3,
    ADS = 4,
    TELEMARKETER = 5,
    ORGANIC = 6
}

// Also create a mapping for display names in frontend
export const TrafficSourceNames = {
    [TrafficSource.REFERRAL]: 'Referral',
    [TrafficSource.AFFILIATE_INTERNAL]: 'Affiliate (Internal)',
    [TrafficSource.AFFILIATE_EXTERNAL]: 'Affiliate (External)',
    [TrafficSource.ADS]: 'Ads (Campaign)',
    [TrafficSource.TELEMARKETER]: 'Telemarketer',
    [TrafficSource.ORGANIC]: 'Organic'
};