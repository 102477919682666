<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'Target Amount Details' | translate }}</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                     <!-- <h5 class="modal-title" style="display: inline">{{ 'Username' | translate }} : 
                        <div style="display: inline" *ngIf="data.details.username">
                            {{ data.details.username }} 
                        </div>
                    </h5> -->
                     <!-- Content -->
                     <div class="kt-section">
                        <div class="kt-section__content">
                            <div class="table-responsive">
                                <!-- Table -->
                                <table class="table table-bordered table-hover table-striped table-sortable">
                                <thead>
                                    <tr class="text-top">
                                        <th rowspan="2">{{ 'Currency' | translate }}</th>
                                        <th rowspan="2">{{ 'Game Provider' | translate }}</th>
                                        <th rowspan="2">{{ 'Game Account' | translate }}</th>
                                        <th colspan="4" class="text-center">{{ 'Bets' | translate }}</th>
                                    </tr>
                                    <tr>
                                        <th class="text-center">{{ 'Total Bet Count' | translate }}</th>
                                        <th class="text-center">{{ 'Total Bet Amount' | translate }}</th>
                                        <th class="text-center">{{ 'Total Valid Bet Amount' | translate }}</th>
                                        <th class="text-center">{{ 'Player W/L' | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="!loading && targetAmountDetails as rows">
                                    <tr *ngFor="let row of rows">
                                        <td>{{ row.currency_code }}</td>
                                        <td>
                                            {{ row.game_provider_code }}
                                        </td>
                                        <td>
                                            <a class="text-primary font-weight-bold" (click)="onOpenDialog(row)">
                                                {{ row.game_account }}
                                            </a>
                                        </td>
                                        <td class="text-center">
                                            {{ row.total_bet_count }}
                                        </td>
                                        <td class="text-center">
                                            {{ row.total_bet_amount | number : '1.2-2' }}
                                        </td>  
                                        <td class="text-center">
                                            {{ row.total_valid_bet_amount | number : '1.2-2' }}
                                        </td>
                                        <td class="text-center">
                                            {{ row.total_player_win_lose | number : '1.2-2' }}
                                        </td>    
                                    </tr>
                                </tbody>
                                <tbody shared-table-handler [loading]="loading" [dataLength]="1" class="text-gray-600 fw-bold"></tbody>
                                </table>
                            </div>
                        </div>
                    </div>  
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>{{ 'Close' | translate }}</button>
            </div>
        </div>
    </form>
</div>

<!-- <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert> -->
