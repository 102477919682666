<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">{{ data.mode === 'create' ? 'Create' : 'Edit'}} Promotion Category</h5>
              <span class="modal-x-button" (click)="onCloseDialog()">
                  <i class="fa fa-times" aria-hidden="true"></i>
              </span>
          </div>
          <div class="modal-body">
            <div class="row">
                <div class="col-md-12 form-group">
                    <label class="control-label">Code <span class="text-danger">*</span></label>
                    <input type="text" maxlength="5" formControlName="code" class="col-12 form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label class="control-label">Position <span class="text-danger">*</span></label>
                    <input type="number" (wheel)="false" class="col-12 form-control" formControlName="position" onkeydown="if (event.key === 'e' || event.key === 'E') event.preventDefault();">
                </div>
                <div class="col-md-12 form-group">
                    <label class="control-label">Label <span class="text-danger">*</span></label>
                    <input type="text" formControlName="label" class="col-12 form-control">
                </div>
                <div class="col-md-12 form-group" *ngIf="data.mode === 'edit'">
                    <label class="control-label">Status <span class="text-danger">*</span></label>
                    <select  class="form-control" formControlName="status">
                        <option [value]="null" [disabled]="true">Please Select</option>
                        <ng-container *ngFor="let value of dropdown.statuses; let i = index;">
                            <option [value]="i">{{ value }}</option>
                        </ng-container>
                    </select>
                </div>
                <ng-container *ngIf="languages.length > 0; else elseLoading">
                    <div formGroupName="contents" class="col-12 p-0">
                        <div class="col-md-12 form-group" *ngFor="let language of languages; let i = index" [formGroupName]="language.id">
                            <label class="control-label">{{ language.code }}</label>
                            <input type="text" formControlName="name" class="col-12 form-control">
                        </div>
                    </div>
                </ng-container>
                <ng-template #elseLoading>
                    <div class="col-md-12 row justify-content-center">
                        <i class="fas fa-circle-notch fa-spin p-0 mr-2"></i>
                    </div>
                </ng-template>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
            <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
          </div>
      </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
