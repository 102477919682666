import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MerchantBankStatus } from '@core/enums/merchant-bank.enum';
import { MerchantBankTransactionConfig } from './merchant-bank-transaction-config';

@Component({
  selector: 'merchant-bank-transaction-config-edit',
  templateUrl: './merchant-bank-transaction-config-edit.component.html',
  styleUrls: ['./merchant-bank-transaction-config-edit.component.scss']
})
export class MerchantBankTransactionConfigEditComponent implements OnInit {
  // These method values below MUST tally with MerchantBank.php method constants
  METHOD_PAYMENT_GATEWAY = 2;
  METHOD_CRYPTO = 4;

  @Input()
  detail: MerchantBankTransactionConfig;

  @Input()
  mode: string;

  @Input()
  currency_code: string;

  @Input()
  method: number;

  formTransaction: FormGroup;

  dropdown = {
    bankPurposes: this.dropdownHttpService.bankPurposes,
    processingFeeType: this.dropdownHttpService.processingFeeType,
    absorbProcessingFeeType: this.dropdownHttpService.absorbProcessingFeeType
  };

  selectedPurpose: number;
  selectedDepositProcessingFeeType: number;
  selectedAbsorbDepositProcessingFeeType: string;
  selectedWithdrawalProcessingFeeType: number;
  selectedAbsorbWithdrawalProcessingFeeType: string;
  object = Object;
  status = MerchantBankStatus;

  // id shall follow key of MerchantBankTransactionConfig::DEPOSIT_FIELD_TYPES array in API side
  depositFieldTypes = [
    {
      id: 1,
      code: 'input_field',
      name: 'Input Field'
    },
    {
      id: 2,
      code: 'dropdown',
      name: 'Dropdown'
    }
  ];

  constructor(
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit(): void {
    this.formInit();

    if (this.isMethodCrypto()) {
      this.dropdown.processingFeeType = this.dropdown.processingFeeType.filter(item => item.name === 'Percentage');
    }
  }

  onPurpose(event: Event) {
    this.selectedPurpose = +(event.target as HTMLSelectElement).value;
    this.formTransaction.patchValue({
      daily_max_deposit: this.detail ? this.detail.daily_max_deposit : 0,
      daily_max_deposit_count: this.detail ? this.detail.daily_max_deposit_count : 0,
      daily_max_withdrawal: this.detail ? this.detail.daily_max_withdrawal : 0,
      daily_max_withdrawal_count: this.detail ? this.detail.daily_max_withdrawal_count : 0,
    });
  }

  onDepositProcessingFeeType(event: Event) {
    this.selectedDepositProcessingFeeType = +(event.target as HTMLSelectElement).value;
    this.formTransaction.controls.deposit_processing_fee.setValidators([
      Validators.required,
      Validators.min(0),
    ]);
    this.formTransaction.controls.deposit_processing_fee_percentage.setValidators([
      Validators.required,
      Validators.min(0),
    ]);
    if (this.selectedDepositProcessingFeeType == 1) {
      this.formTransaction.controls.deposit_processing_fee_percentage.clearValidators();
    } else if (this.selectedDepositProcessingFeeType == 2) {
      this.formTransaction.controls.deposit_processing_fee.clearValidators();
    }
    this.updateFormValidity();
  }

  onAbsorbDepositProcessingFeeType(event: Event) {
    const value = +(event.target as HTMLSelectElement).value;
    let pdap = 0;
    let cdap = 0;
    if (value == 1) {
      cdap = 100;
    } else if (value == 2) {
      pdap = 100;
    } else if (value == 3) {
      pdap = 50;
      cdap = 50;
    }
    this.formTransaction.patchValue({
      player_deposit_absorb_percentage: pdap,
      company_deposit_absorb_percentage: cdap,
    });
    
    const name = this.dropdown.absorbProcessingFeeType.find(x => x.id == value)?.name;
    this.selectedAbsorbDepositProcessingFeeType = '(' + name + ')';
  }
  
  onWithdrawalProcessingFeeType(event: Event) {
    this.selectedWithdrawalProcessingFeeType = +(event.target as HTMLSelectElement).value;
    this.formTransaction.controls.withdrawal_processing_fee.setValidators([
      Validators.required,
      Validators.min(0),
    ]);
    this.formTransaction.controls.withdrawal_processing_fee_percentage.setValidators([
      Validators.required,
      Validators.min(0),
    ]);
    if (this.selectedWithdrawalProcessingFeeType == 1) {
      this.formTransaction.controls.withdrawal_processing_fee_percentage.clearValidators();
    } else if (this.selectedWithdrawalProcessingFeeType == 2) {
      this.formTransaction.controls.withdrawal_processing_fee.clearValidators();
    }
    this.updateFormValidity();
  }

  onAbsorbWtihdrawalProcessingFeeType(event: Event) {
    const value = +(event.target as HTMLSelectElement).value;
    let pwap = 0;
    let cwap = 0;
    if (value == 1) {
      cwap = 100;
    } else if (value == 2) {
      pwap = 100;
    } else if (value == 3) {
      pwap = 50;
      cwap = 50;
    }
    this.formTransaction.patchValue({
      player_withdrawal_absorb_percentage: pwap,
      company_withdrawal_absorb_percentage: cwap,
    });
    const name = this.dropdown.absorbProcessingFeeType.find(x => x.id == value)?.name;
    this.selectedAbsorbWithdrawalProcessingFeeType = '(' + name + ')';
  }

  changeValidators() {
    this.formTransaction.patchValue({
      member_groups: []
    });
    //deposit
    if (this.formTransaction.get("purpose").value == "1") {
      this.formTransaction.controls.daily_max_deposit.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.formTransaction.controls.daily_max_deposit_count.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.formTransaction.controls.min_deposit_per_transaction.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.formTransaction.controls.max_deposit_per_transaction.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.formTransaction.controls.daily_max_withdrawal.clearValidators();
      this.formTransaction.controls.daily_max_withdrawal_count.clearValidators();
      this.formTransaction.controls.min_withdrawal_per_transaction.clearValidators();
      this.formTransaction.controls.max_withdrawal_per_transaction.clearValidators();
      if (this.method == this.METHOD_PAYMENT_GATEWAY) {
        this.formTransaction.controls.deposit_processing_fee_type.setValidators([Validators.required]);
        this.formTransaction.controls.deposit_absorb_processing_fee_type.setValidators([Validators.required]);
        this.formTransaction.controls.player_deposit_absorb_percentage.setValidators([Validators.required, Validators.min(0)]);
        this.formTransaction.controls.company_deposit_absorb_percentage.setValidators([Validators.required, Validators.min(0)]);
        this.formTransaction.controls.withdrawal_processing_fee_type.clearValidators();
        this.formTransaction.controls.withdrawal_absorb_processing_fee_type.clearValidators();
        this.formTransaction.controls.player_withdrawal_absorb_percentage.clearValidators();
        this.formTransaction.controls.company_withdrawal_absorb_percentage.clearValidators();
      }
    }
    //withdrawal
    else if (this.formTransaction.get("purpose").value == "2") {
      this.formTransaction.controls.daily_max_deposit.clearValidators();
      this.formTransaction.controls.daily_max_deposit_count.clearValidators();
      this.formTransaction.controls.min_deposit_per_transaction.clearValidators();
      this.formTransaction.controls.max_deposit_per_transaction.clearValidators();
      this.formTransaction.controls.daily_max_withdrawal.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.formTransaction.controls.daily_max_withdrawal_count.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.formTransaction.controls.min_withdrawal_per_transaction.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.formTransaction.controls.max_withdrawal_per_transaction.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      if (this.method == this.METHOD_PAYMENT_GATEWAY) {
        this.formTransaction.controls.withdrawal_processing_fee_type.setValidators([Validators.required]);
        this.formTransaction.controls.withdrawal_absorb_processing_fee_type.setValidators([Validators.required]);
        this.formTransaction.controls.player_withdrawal_absorb_percentage.setValidators([Validators.required, Validators.min(0)]);
        this.formTransaction.controls.company_withdrawal_absorb_percentage.setValidators([Validators.required, Validators.min(0)]);
        this.formTransaction.controls.deposit_processing_fee_type.clearValidators();
        this.formTransaction.controls.deposit_absorb_processing_fee_type.clearValidators();
        this.formTransaction.controls.player_deposit_absorb_percentage.clearValidators();
        this.formTransaction.controls.company_deposit_absorb_percentage.clearValidators();
      }
      
    }
    //deposit and withdrawal
    else if (this.formTransaction.get("purpose").value == "5") {
      this.formTransaction.controls.daily_max_deposit.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.formTransaction.controls.daily_max_deposit_count.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.formTransaction.controls.daily_max_withdrawal.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.formTransaction.controls.daily_max_withdrawal_count.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      if (this.method == this.METHOD_PAYMENT_GATEWAY) {
        this.formTransaction.controls.deposit_processing_fee_type.setValidators([Validators.required]);
        this.formTransaction.controls.deposit_absorb_processing_fee_type.setValidators([Validators.required]);
        this.formTransaction.controls.player_deposit_absorb_percentage.setValidators([Validators.required, Validators.min(0)]);
        this.formTransaction.controls.company_deposit_absorb_percentage.setValidators([Validators.required, Validators.min(0)]);
        this.formTransaction.controls.withdrawal_processing_fee_type.setValidators([Validators.required]);
        this.formTransaction.controls.withdrawal_absorb_processing_fee_type.setValidators([Validators.required]);
        this.formTransaction.controls.player_withdrawal_absorb_percentage.setValidators([Validators.required, Validators.min(0)]);
        this.formTransaction.controls.company_withdrawal_absorb_percentage.setValidators([Validators.required, Validators.min(0)]);
      }
    }
    // other purposes
    else {
      this.formTransaction.controls.daily_max_deposit.clearValidators();
      this.formTransaction.controls.daily_max_deposit_count.clearValidators();
      this.formTransaction.controls.daily_max_withdrawal.clearValidators();
      this.formTransaction.controls.daily_max_withdrawal_count.clearValidators();
      if (this.method == this.METHOD_PAYMENT_GATEWAY) {
        this.formTransaction.controls.deposit_processing_fee_type.clearValidators();
        this.formTransaction.controls.deposit_absorb_processing_fee_type.clearValidators();
        this.formTransaction.controls.player_deposit_absorb_percentage.clearValidators();
        this.formTransaction.controls.company_deposit_absorb_percentage.clearValidators();
        this.formTransaction.controls.withdrawal_processing_fee_type.clearValidators();
        this.formTransaction.controls.withdrawal_absorb_processing_fee_type.clearValidators();
        this.formTransaction.controls.player_withdrawal_absorb_percentage.clearValidators();
        this.formTransaction.controls.company_withdrawal_absorb_percentage.clearValidators();
      }
    }
    this.updateFormValidity();
  }

  private updateFormValidity() {
    Object.keys(this.formTransaction.controls).forEach((key) => {
      if (key != "bank_type" && key != "purpose")
        this.formTransaction.get(key).updateValueAndValidity();
    });
  }


  formInit() {
    const amountValidation = Validators.min(0);
    let purpose = 1;
    this.selectedPurpose = purpose;
    let status = 1;
    let minBalance = '0';
    let minBalanceUSDT = '0';
    let maxBalance = '0';
    let maxBalanceUSDT = '0';
    let dailyMaxDepositCount = 0;
    let dailyMaxWithdrawalCount = 0;
    let dailyMaxDeposit = '0';
    let dailyMaxDepositUSDT = '0';
    let dailyMaxWithdrawal = '0';
    let dailyMaxWithdrawalUSDT = '0';
    let minDepositPerTransaction = '0';
    let minDepositPerTransactionUSDT = '0';
    let maxDepositPerTransaction = '0';
    let maxDepositPerTransactionUSDT = '0';
    let minWithdrawalPerTransaction = '0';
    let minWithdrawalPerTransactionUSDT = '0';
    let maxWithdrawalPerTransaction = '0';
    let maxWithdrawalPerTransactionUSDT = '0';
    let depositFieldType = 1;
    let shortcutAmount = null;
    let score = 0;
    let position = 99;
    let display_name = '';
    let depositProcessingFeeType = null;
    let withdrawalProcessingFeeType = null;
    let depositAbsorbProcessingFeeType = null;
    let withdrawalAbsorbProcessingFeeType = null;
    let playerDepositAbsorbPercentage = null;
    let companyDepositAbsorbPercentage = null;
    let playerWithdrawalAbsorbPercentage = null;
    let companyWithdrawalAbsorbPercentage = null;
    let depositProcessingFee = '0';
    let depositProcessingFeePercentage = null;
    let withdrawalProcessingFee = '0';
    let withdrawalProcessingFeePercentage = null;
    const methodValidation = this.method === this.METHOD_PAYMENT_GATEWAY;

    if (this.mode === 'edit' && Object.keys(this.detail).length > 0) {
      purpose = this.detail.purpose;
      this.selectedPurpose = purpose;
      status = this.detail.status;
      minBalance = parseFloat(this.detail.min_balance.toString()).toFixed(2);
      minBalanceUSDT = parseFloat(this.detail.min_balance_usdt.toString()).toFixed(2);
      maxBalance = parseFloat(this.detail.max_balance.toString()).toFixed(2);
      maxBalanceUSDT = parseFloat(this.detail.max_balance_usdt.toString()).toFixed(2);
      dailyMaxDepositCount = this.detail.daily_max_deposit_count;
      dailyMaxWithdrawalCount = this.detail.daily_max_withdrawal_count;
      dailyMaxDeposit = parseFloat(this.detail.daily_max_deposit.toString()).toFixed(2);
      dailyMaxDepositUSDT = parseFloat(this.detail.daily_max_deposit_usdt.toString()).toFixed(2);
      dailyMaxWithdrawal = parseFloat(this.detail.daily_max_withdrawal.toString()).toFixed(2);
      dailyMaxWithdrawalUSDT = parseFloat(this.detail.daily_max_withdrawal_usdt.toString()).toFixed(2);
      minDepositPerTransaction = parseFloat(this.detail.min_deposit_per_transaction.toString()).toFixed(2);
      minDepositPerTransactionUSDT = parseFloat(this.detail.min_deposit_per_transaction_usdt.toString()).toFixed(2);
      maxDepositPerTransaction = parseFloat(this.detail.max_deposit_per_transaction.toString()).toFixed(2);
      maxDepositPerTransactionUSDT = parseFloat(this.detail.max_deposit_per_transaction_usdt.toString()).toFixed(2);
      minWithdrawalPerTransaction = parseFloat(this.detail.min_withdrawal_per_transaction.toString()).toFixed(2);
      minWithdrawalPerTransactionUSDT = parseFloat(this.detail.min_withdrawal_per_transaction_usdt.toString()).toFixed(2);
      maxWithdrawalPerTransaction = parseFloat(this.detail.max_withdrawal_per_transaction.toString()).toFixed(2);
      maxWithdrawalPerTransactionUSDT = parseFloat(this.detail.max_withdrawal_per_transaction_usdt.toString()).toFixed(2);
      depositFieldType = this.detail.deposit_field_type;
      shortcutAmount = this.detail.shortcut_amount;
      score = this.detail.score;
      position = this.detail.position;
      display_name = this.detail.display_name;
      depositProcessingFeeType = this.detail.deposit_processing_fee_type;
      withdrawalProcessingFeeType = this.detail.withdrawal_processing_fee_type;
      depositAbsorbProcessingFeeType = this.detail.deposit_absorb_processing_fee_type;
      const name = depositAbsorbProcessingFeeType > 0 ? this.dropdown.absorbProcessingFeeType.find(x => x.id == depositAbsorbProcessingFeeType).name : '';
      this.selectedAbsorbDepositProcessingFeeType = '(' + name + ')';
      withdrawalAbsorbProcessingFeeType = this.detail.withdrawal_absorb_processing_fee_type;
      const nameTwo = withdrawalAbsorbProcessingFeeType > 0 ? this.dropdown.absorbProcessingFeeType.find(x => x.id == withdrawalAbsorbProcessingFeeType).name : '';
      this.selectedAbsorbWithdrawalProcessingFeeType = '(' + nameTwo + ')';
      playerDepositAbsorbPercentage = parseFloat(this.detail.player_deposit_absorb_percentage.toString()).toFixed(2);
      companyDepositAbsorbPercentage = parseFloat(this.detail.company_deposit_absorb_percentage.toString()).toFixed(2);
      playerWithdrawalAbsorbPercentage = parseFloat(this.detail.player_withdrawal_absorb_percentage.toString()).toFixed(2);
      companyWithdrawalAbsorbPercentage = parseFloat(this.detail.company_withdrawal_absorb_percentage.toString()).toFixed(2);
      depositProcessingFee = parseFloat(this.detail.deposit_processing_fee.toString()).toFixed(2);
      depositProcessingFeePercentage = parseFloat(this.detail.deposit_processing_fee_percentage.toString()).toFixed(2);
      withdrawalProcessingFee = parseFloat(this.detail.withdrawal_processing_fee.toString()).toFixed(2);
      withdrawalProcessingFeePercentage = parseFloat(this.detail.withdrawal_processing_fee_percentage.toString()).toFixed(2);
    }
    this.formTransaction = new FormGroup({
      purpose: new FormControl(purpose, [Validators.required]),
      status: new FormControl(status, [Validators.required]),
      min_balance: new FormControl(minBalance, [Validators.required, amountValidation]),
      min_balance_usdt: new FormControl(minBalanceUSDT, this.isMethodCrypto() ? [Validators.required, amountValidation] : []),
      max_balance: new FormControl(maxBalance, [Validators.required, amountValidation]),
      max_balance_usdt: new FormControl(maxBalanceUSDT, this.isMethodCrypto() ? [Validators.required, amountValidation] : []),
      daily_max_deposit_count: new FormControl(dailyMaxDepositCount, Validators.compose(purpose == 2 ? null : [Validators.required, amountValidation])),
      daily_max_withdrawal_count: new FormControl(dailyMaxWithdrawalCount, Validators.compose(purpose == 1 ? null : [Validators.required, amountValidation])),
      daily_max_deposit: new FormControl(dailyMaxDeposit, Validators.compose(purpose == 2 ? null : [Validators.required, amountValidation])),
      daily_max_deposit_usdt: new FormControl(dailyMaxDepositUSDT, Validators.compose((!this.isMethodCrypto() || purpose == 2) ? null : [Validators.required, amountValidation])),
      daily_max_withdrawal: new FormControl(dailyMaxWithdrawal, Validators.compose(purpose == 1 ? null : [Validators.required, amountValidation])),
      daily_max_withdrawal_usdt: new FormControl(dailyMaxWithdrawalUSDT, Validators.compose((!this.isMethodCrypto() || purpose == 1) ? null : [Validators.required, amountValidation])),
      min_deposit_per_transaction: new FormControl(minDepositPerTransaction, Validators.compose(purpose == 2 ? null : [Validators.required, amountValidation])),
      min_deposit_per_transaction_usdt: new FormControl(minDepositPerTransactionUSDT, Validators.compose(!this.isMethodCrypto() || purpose == 2 ? null : [Validators.required, amountValidation])),
      max_deposit_per_transaction: new FormControl(maxDepositPerTransaction, Validators.compose(purpose == 2 ? null : [Validators.required, amountValidation])),
      max_deposit_per_transaction_usdt: new FormControl(maxDepositPerTransactionUSDT, Validators.compose(!this.isMethodCrypto() || purpose == 2 ? null : [Validators.required, amountValidation])),
      min_withdrawal_per_transaction: new FormControl(minWithdrawalPerTransaction, Validators.compose(purpose == 1 ? null : [Validators.required, amountValidation])),
      min_withdrawal_per_transaction_usdt: new FormControl(minWithdrawalPerTransactionUSDT, Validators.compose(!this.isMethodCrypto() || purpose == 1 ? null : [Validators.required, amountValidation])),
      max_withdrawal_per_transaction: new FormControl(maxWithdrawalPerTransaction, Validators.compose(purpose == 1 ? null : [Validators.required, amountValidation])),
      max_withdrawal_per_transaction_usdt: new FormControl(maxWithdrawalPerTransactionUSDT, Validators.compose(!this.isMethodCrypto() || purpose == 1 ? null : [Validators.required, amountValidation])),
      deposit_field_type: new FormControl(depositFieldType),
      shortcut_amount: new FormControl(shortcutAmount),
      score: new FormControl(score, [Validators.required]),
      position: new FormControl(position, [Validators.required]),
      display_name: new FormControl(display_name, Validators.compose(this.method == this.METHOD_PAYMENT_GATEWAY ? [Validators.required] : null)),
      deposit_processing_fee_type: new FormControl(depositProcessingFeeType, Validators.compose(methodValidation && (purpose == 1 || purpose == 5) ? [Validators.required] : null)),
      withdrawal_processing_fee_type: new FormControl(withdrawalProcessingFeeType, Validators.compose(methodValidation && (purpose == 2 || purpose == 5) ? [Validators.required] : null)),
      deposit_absorb_processing_fee_type: new FormControl(depositAbsorbProcessingFeeType, Validators.compose(methodValidation && (purpose == 1 || purpose == 5) ? [Validators.required] : null)),
      withdrawal_absorb_processing_fee_type: new FormControl(withdrawalAbsorbProcessingFeeType, Validators.compose(methodValidation && (purpose == 2 || purpose == 5) ? [Validators.required] : null)),
      player_deposit_absorb_percentage: new FormControl(playerDepositAbsorbPercentage, Validators.compose(methodValidation && (purpose == 1 || purpose == 5) ? [Validators.required, amountValidation] : null)),
      company_deposit_absorb_percentage: new FormControl(companyDepositAbsorbPercentage, Validators.compose(methodValidation && (purpose == 1 || purpose == 5) ? [Validators.required, amountValidation] : null)),
      player_withdrawal_absorb_percentage: new FormControl(playerWithdrawalAbsorbPercentage, Validators.compose(methodValidation && (purpose == 2 || purpose == 5) ? [Validators.required, amountValidation] : null)),
      company_withdrawal_absorb_percentage: new FormControl(companyWithdrawalAbsorbPercentage, Validators.compose(methodValidation && (purpose == 2 || purpose == 5) ? [Validators.required, amountValidation] : null)),
      deposit_processing_fee: new FormControl(depositProcessingFee, Validators.compose(methodValidation && (depositProcessingFeeType == 1 || depositProcessingFeeType == 3) ? [Validators.required, amountValidation] : null)),
      deposit_processing_fee_percentage: new FormControl(depositProcessingFeePercentage, Validators.compose(methodValidation && (depositProcessingFeeType == 2 || depositProcessingFeeType == 3) ? [Validators.required, amountValidation] : null)),
      withdrawal_processing_fee: new FormControl(withdrawalProcessingFee, Validators.compose(methodValidation && (withdrawalProcessingFeeType == 1 || withdrawalProcessingFeeType == 3) ? [Validators.required, amountValidation] : null)),
      withdrawal_processing_fee_percentage: new FormControl(withdrawalProcessingFeePercentage, Validators.compose(methodValidation && (withdrawalProcessingFeeType == 1 || withdrawalProcessingFeeType == 3) ? [Validators.required, amountValidation] : null))
    });

    this.formTransaction.get('purpose').valueChanges.subscribe((data) => {
      this.changeValidators();
    });
  }

  isMethodCrypto() {
    return this.method == this.METHOD_CRYPTO;
  }

}
