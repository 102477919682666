import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Cache } from '@core/models/cache.model';
import { Dropdown } from '@core/models/dropdown.model';


@Injectable()
export class CacheHttpService {

messages$ = new Subject<any[]>();

constructor(private http: HttpClient) { }

getCacheList(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/cache/types`).pipe(
        map(res => res.data.rows)
    )
}

flush(data: any) {
    return this.http.post<ApiResponse>(`/cache/flush`, data).pipe(
        tap((res) => {
            this.messages$.next(res.message);
        }),
    );
}

}