import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppPermissionService } from '@core/services/app-permission.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-section-settings',
  templateUrl: './section-settings.component.html',
  styleUrls: ['./section-settings.component.scss']
})
export class SectionSettingsComponent implements OnInit, OnDestroy {
  // permissions
  canViewFooterSectionList: boolean;
  canViewIconLibraryList: boolean;
  canViewQuickLinkList: boolean;

  private subscriptions = new Subscription();

  constructor(
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit(): void {
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewFooterSectionList = appPermissions.view_footer_section_list;
      this.canViewIconLibraryList = appPermissions.view_icon_library_list;
      this.canViewQuickLinkList = appPermissions.view_quick_link_list;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
