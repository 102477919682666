import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadHttpService } from '@core/services/upload-http.service';
import { DepositDataService } from "@views/pages/apps/general/deposits/services/deposit-data.service";
import { catchError, tap } from "rxjs/operators";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-deposit-upload-receipt',
  templateUrl: './upload-receipt.component.html',
  styleUrls: ['./upload-receipt.component.scss']
})
export class UploadReceiptComponent implements OnInit {

  messages$ = this.depositDataService.messages$;
  form: FormGroup;
  buttonLoading = false;
  file_max_count = 10; // Maximum receipts allowed
  file_max_size = 10; // MB
  file_allow_extensions = ['jpg', 'jpeg', 'png', 'gif'];
  file_allow_mime_types = ['image/jpeg', 'image/png', 'image/gif'];
  fileName: Array<String> = [];
  isFileUploading: Array<boolean> = [];
  isSubmitted = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { deposit: any },
    public dialogRef: MatDialogRef<UploadReceiptComponent>,
    private uploadService: UploadHttpService,
    private depositDataService: DepositDataService
  ) { }

  ngOnInit() {
    this.formInit();
  }

  onSubmit() {
    this.buttonLoading = true;

    const data = {
      id: this.data.deposit.id,
      ...this.form.value,
    };

    Object.keys(data).forEach((key) => (data[key] == null || data[key] === "") && delete data[key]);
    this.depositDataService
      .uploadReceipt(data)
      .pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          this.isSubmitted = true;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      )
      .subscribe();
  }

  onUploadFileMulti(event: any, index) {
    const file: File = event.target.files[0];

    if( this.receipts.length > this.file_max_count ) {
      Swal.fire('System Message', 'Maximum ' + this.file_max_count + ' receipts allowed.', 'error');
      return;
    }

    if( file.size/1024/1024 > this.file_max_size ) {
      Swal.fire('System Message', 'Maximum file size exceed.', 'error');
      return;
    }

    const fileExtension = file.name.split('.').pop().toLowerCase();
    if( this.file_allow_extensions.indexOf(fileExtension) === -1 || this.file_allow_mime_types.indexOf(file.type) === -1 ) {
      Swal.fire('System Message', 'Invalid file type.', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'receipts');
    this.isFileUploading[index] = true;

    this.uploadService.upload(formData).pipe(
      tap(res => {
        (this.form.get("receipt_path") as FormArray).controls[index].patchValue(res[0]);
        this.fileName[index] = event.target.files[0].name;
        this.isFileUploading[index] = false;
      }),
      catchError((error) => {
        this.isFileUploading[index] = false;
        event.target.value = null;
        throw error;
      })
    ).subscribe();
  }

  addReceipt() {
    if( this.receipts.length < this.file_max_count ) {
      const receipt = new FormControl('', [Validators.required]);
      this.receipts.push(receipt);
    }
  }

  removeReceipt(index: number) {
    this.receipts.removeAt(index);
    this.fileName.splice(index, 1);
  }

  get receipts() {
    return this.form.controls["receipt_path"] as FormArray;
  }

  private formInit() {
    this.form = new FormGroup({
      receipt_path: new FormArray([new FormControl('', [Validators.required])], [Validators.required])
    })
  }

  onCloseDialog() {
    this.dialogRef.close(this.isSubmitted);
  }
}
