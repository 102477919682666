import { MerchantBankHttpService } from '../../../../../../../core/services/merchant-bank-http.service';
import { Component, OnInit, Inject, AfterContentInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MerchantBank } from '@core/models/merchant-bank.model';
import { Observable, Subscription } from 'rxjs';
import { GroupDataService } from '../../../../general/groups/services/group-data.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Pagination } from '@core/models/pagination.model';
import { MemberGroupSelectAccount } from '@core/models/member-group-select-account.model';
import { tap, map, catchError } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';

@Component({
  selector: 'kt-merchant-banks',
  templateUrl: './merchant-banks.component.html',
  styleUrls: ['./merchant-banks.component.scss']
})
export class MerchantBanksDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = this.groupData.messages$;
  memberGroup: Observable<MemberGroupSelectAccount[]>;
  private subscription = new Subscription();

  pagination: Pagination;
  pageSize = 15;
  page = 1;
  maxSize = 5;
  params = '';
  patchBanks: any[];
  id: any;
  refreshStatus: boolean;

  banksDropdownList = [];
  banksSelectedItems = [];
  banksDropdownSettings = {};

  dropdown = {
    merchantBankAccounts: this.dropdownHttpService.merchantBankAccounts,
    perPage: this.dropdownHttpService.perPage
  };

  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bank: MerchantBank, mode: string },
    public dialogRef: MatDialogRef<MerchantBanksDialogComponent>,
    private loadingBar: LoadingBarService,
    private groupData: GroupDataService,
    private dropdownHttpService: DropdownHttpService,
    private merchantBankHttpService: MerchantBankHttpService
  ) { }

  ngOnInit() {
    this.formInit();
    this.reload();

    this.banksDropdownSettings = {
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'labelKey',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.id = localStorage.getItem('memberId');
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.memberGroup = this.groupData.getMerchants(`/${this.id}/selectaccounts`).pipe(
      tap(res => {
        this.pagination = this.groupData.pagination;

        this.loadingBar.complete();
      })
    );
  }
  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private reload() {
    this.onViewPageBy();
  }

  onSave() {
    this.buttonLoading = true;
    const data = {
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || key === 'perPage') && delete data[key]);
    this.subscription = this.groupData.updateMerchants(data).pipe(
      tap((res: any) => {
        this.messages$.next([...res.message]);
        this.buttonLoading = false;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {

    this.form = new FormGroup({
      merchant_bank_id: new FormControl(),
      perPage: new FormControl(this.pageSize)
    });

    this.merchantBankHttpService.getMerchantBankAccounts('&purpose=1').pipe(
      map(res => res.map( (elm: any) => {
        elm.labelKey = elm.bank_name + '-' + elm.account_name + '-' + elm.account_number;
        return elm;
      }))
    ).subscribe(res => {
      this.banksDropdownList = res;
      this.memberGroup.subscribe( row => {
        Object.keys(row).forEach((key) => {
          this.banksSelectedItems.push(this.banksDropdownList.find(v => v.account_number === row[key].account_number));
        });
      });
    });
  }

}
