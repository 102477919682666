import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { BotStatus } from '@core/models/bot-status.model';

@Injectable()
export class BotStatusEntityService extends EntityCollectionServiceBase<BotStatus> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('BotStatus', serviceElementsFactory);
    }
}

