import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AllLeads } from '@core/models/all-leads.model';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';

@Injectable()
export class MyLeadsDataService extends DefaultDataService<AllLeads> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('AllLeads', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<AllLeads[]> {
    return this.http.get<ApiResponse>(`/telemarketer/myleads${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => {
        Object.keys(res.data.rows).forEach(key => {
          res.data.rows[key].custom = res.data.rows[key].id + ' - ';
          if (res.data.rows[key].username != null) {
            res.data.rows[key].custom += res.data.rows[key].username.toUpperCase();
          }
          res.data.rows[key].custom += + ' - ';
          if (res.data.rows[key].name != null) {
            res.data.rows[key].custom += res.data.rows[key].name.toUpperCase();
          }
        });
        return res.data.rows;
      })
    );
  }

  exportReport(pageParam: string) {
    return this.http.get<ApiResponse>(`/telemarketer/exportmylead${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  clickToDial(data: any) {
    return this.http.post<ApiResponse>(`/telemarketer/call/clicktodial`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  createMember(data: any) {
    return this.http.post<ApiResponse>(`/telemarketer/creatememberaccount`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

}
