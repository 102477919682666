import { tap, map, concatMap, delay, filter, take, exhaust, takeWhile, share } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { SmsProviderPermissions } from '@core/models/sms-provider-permissions.model';

@Injectable()
export class ProviderPermissionsDataService extends DefaultDataService<SmsProviderPermissions>  {

  messages$ = new Subject<any[]>();
  pagination: Pagination;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('SmsProviderPermissions', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<SmsProviderPermissions[]> {
    return this.http.get<ApiResponse>(`/smsprovider/permissions${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows),
    );
  }

  getById(id: number): Observable<SmsProviderPermissions> {
    return this.http.get<ApiResponse>(`/smsprovider/permissions/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  updateProvider(id: number, smsProvider: SmsProviderPermissions) {
    return this.http.put<ApiResponse>(`/smsprovider/permissions/${id}`, smsProvider).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  updateStatus(provider: any) {
    return this.http.put<ApiResponse>(`/smsprovider/permissions/statusupdate`, provider).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }


  private paginationInit(res: any) {
    if (res) {
      return this.pagination = res.data.paginations;
    }
  }

}
