import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MemberReport } from '@core/models/member-report-model';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, delay, exhaustMap, map, tap } from 'rxjs/operators';
import { MemberDataService } from '../../general/members/services/member-data.service';
import { MemberReportDetailsDialogComponent } from './dialogs/member-report-details.component';
import { MemberReportDataService } from './services/member-report-data.service';
import { MemberReportEntityService } from './services/member-report-entity.service';
import { now } from 'moment';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';
import { DateTime } from 'luxon';

@Component({
  selector: 'kt-member-report',
  templateUrl: './member-report.component.html',
  styleUrls: ['./member-report.component.scss']
})
export class MemberReportComponent implements OnInit, OnDestroy {

  @ViewChildren('filterInput') filterInput: QueryList<ElementRef>;
  form: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  pagination: Pagination;
  memberPagination: Pagination;
  membersPaginated$ = [];
  username = '';
  pageNumber = 1;
  count = 0;
  reloadMember$ = new Subject<any>();
  searchTerm = new BehaviorSubject<string>(null);
  dropdownList: any = [];
  dropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'username',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false
  };
  timezoneDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown clear-all-disabled',
    primaryKey: 'offset',
    labelKey: 'offset',
    noDataLabel: '',
    showCheckbox: false
  };

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  from = this.transactionHttpService.getYesterday().from;
  to = this.transactionHttpService.getToday().to;

  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones')),
    gameProviders: this.dropdownHttpService.gameProviders
  };

  params = `start_date_time=${moment(this.from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date_time=${moment(this.to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}`;
  ranges: any;
  memberReport$: Observable<MemberReport[]>;
  summary: any;
  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  datePickerSubscription: Subscription;

  button_loading = false;
  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;

  messages$ = this.memberReportDataService.messages$;
  access$ = this.authHttpService.getUserAccess(5, 'Member Report');

  // permissions
  canExportMemberReport: boolean;
  canViewMemberBetLogsReport: boolean;

  lastBetLogSyncAt = null;

  constructor(
    public dialog: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private memberReportService: MemberReportEntityService,
    private memberReportDataService: MemberReportDataService,
    private memberDataService: MemberDataService,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private appPermissionService: AppPermissionService,
  ) {

  }

  ngOnInit() {
    this.formInit();
    this.pagination = this.memberReportDataService.pagination;
    this.memberPagination = this.memberDataService.pagination;
    this.dropdownList = this.membersPaginated$;
    this.ranges = this.transactionHttpService.ranges;
    this.timezoneSelectedItems = [this.dropdown.timezones.find(x => x.timezone == this.timezone)];
    this.selectedTimezone = this.timezone;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canExportMemberReport = appPermissions.export_member_report;
      this.canViewMemberBetLogsReport = appPermissions.view_member_bet_logs_report;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.onSearch = false;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.memberReport$ = this.memberReportService.getWithQuery(`?paginate=1&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.onSearch = true;
        this.pagination = this.memberReportDataService.pagination;
        this.loadingBar.complete();
      }),
      catchError((err) => {
        this.loadingBar.complete();
        return throwError(err);
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenSelect() {
    of(null).pipe(
      delay(0), tap(() => this.filterInput.first.nativeElement.focus()
      )).subscribe();
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null ? event.startDate._d : event.startDate,
        end_date_time: event.endDate !== null ? event.endDate._d : event.endDate
      });
    }
  }

  onOpenDialog(report: any) {
    if (!this.canViewMemberBetLogsReport) {
      Swal.fire('Permission Error', 'You do not have permission to view member bet logs', 'error');
      return;
    }

    this.dropdown.gameProviders.subscribe((res) => {
      const data = {
        game_account: report.game_account,
        game_provider_id: (res.find((x) => { return x.code === (report?.game_provider_code || report?.code) }))?.id,
        game_provider_code: report?.game_provider_code,
        username: report?.username,
        member_account_id: report?.member_account_id,
        start_date_time: this.form.value.start_date_time ?? moment('1970-01-01 00:00:00').format('YYYY-MM-DD HH:mm:ss'),
        end_date_time: this.form.value.end_date_time ?? moment(now()).format('YYYY-MM-DD HH:mm:ss'),
        type: 'member-report',
        viewType: 'dialog',
        from: 'member-report',
        timezone: this.form.value.timezone,
        currency_code: report.currency_code,
        game_type: (res.find((x) => { return x.code === report.game_provider_code })).type,
      };
      this.openDialogBy(MemberReportDetailsDialogComponent, data);
    });
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.form.patchValue({
      username: null,
      ga_username: null,
      start_date_time: this.from,
      end_date_time: this.to,
      timezone: this.timezone,
      defaultDate: {
        startDate: this.from,
        endDate: this.to
      }
    });
    this.timezoneSelectedItems = [this.dropdown.timezones.find(x => x.timezone == this.timezone)];
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    this.onSearch = false;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        } else {
          data['start_date_time'] = moment('1970-01-01 00:00:00').tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(now()).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.memberReport$ = this.memberReportService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.page = 1;
            const summary = {
              currency_code: '',
              username: '-',
              access_token: '-',
              total_number_of_transfer_in: 0,
              total_transfer_in_amount: 0.00,
              total_number_of_transfer_out: 0,
              total_transfer_out_amount: 0.00,
              total_number_of_deposit: 0,
              total_deposit: 0.00,
              total_number_of_withdrawal: 0,
              total_withdrawal: 0.00,
              total_bet_count: 0,
              total_bet_amount: 0.00,
              total_valid_bet_amount: 0.00,
              win_lose: 0.00,
              total_jackpot_contribution: 0.00,
              total_jackpot_win: 0.00,
              nett_jackpot: 0.00,
              game_bet_amount: 0.00,
              gross_game_revenue: 0.00,
              total_bonus: 0.00,
              total_cancelled_bonus: 0.00,
              total_rebate: 0.00,
              total_deposit_processing_fee: 0.00,
              total_withdrawal_processing_fee: 0.00,
              nett_game_revenue: 0.00
            };
            res.forEach(row => {
              summary.currency_code = row.currency_code,
                summary.username = row.username !== '-' ? row.username : summary.username,
                summary.access_token = row.access_token,
                summary.total_number_of_transfer_in += +this.sanitizeNumericField(row.total_number_of_transfer_in),
                summary.total_transfer_in_amount += +this.sanitizeNumericField(row.total_transfer_in_amount),
                summary.total_number_of_transfer_out += +this.sanitizeNumericField(row.total_number_of_transfer_out),
                summary.total_transfer_out_amount += +this.sanitizeNumericField(row.total_transfer_out_amount),
                summary.total_number_of_deposit += +this.sanitizeNumericField(row.total_number_of_deposit),
                summary.total_deposit += +this.sanitizeNumericField(row.total_deposit),
                summary.total_number_of_withdrawal += +this.sanitizeNumericField(row.total_number_of_withdrawal),
                summary.total_withdrawal += +this.sanitizeNumericField(row.total_withdrawal),
                summary.total_bet_count += +this.sanitizeNumericField(row.total_bet_count),
                summary.total_bet_amount += +this.sanitizeNumericField(row.total_bet_amount),
                summary.total_valid_bet_amount += +this.sanitizeNumericField(row.total_valid_bet_amount),
                summary.win_lose += +this.sanitizeNumericField(row.win_lose),
                summary.total_jackpot_contribution += +this.sanitizeNumericField(row.total_jackpot_contribution),
                summary.total_jackpot_win += +this.sanitizeNumericField(row.total_jackpot_win),
                summary.nett_jackpot += +this.sanitizeNumericField(row.nett_jackpot),
                summary.game_bet_amount += +this.sanitizeNumericField(row.game_bet_amount),
                summary.gross_game_revenue += +this.sanitizeNumericField(row.gross_game_revenue),
                summary.total_bonus += +this.sanitizeNumericField(row.total_bonus),
                summary.total_cancelled_bonus += +this.sanitizeNumericField(row.total_cancelled_bonus),
                summary.total_rebate += +this.sanitizeNumericField(row.total_rebate),
                summary.total_deposit_processing_fee += +this.sanitizeNumericField(row.total_deposit_processing_fee),
                summary.total_withdrawal_processing_fee += +this.sanitizeNumericField(row.total_withdrawal_processing_fee),
                summary.nett_game_revenue += +this.sanitizeNumericField(row.nett_game_revenue);
            });
            this.summary = summary;
            this.loadingBar.complete();
            this.getLastBetLogSyncAt();
            this.onSearch = true;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
          })
        );
      }),
      catchError((err) => {
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.loadingBar.complete();
        this.getLastBetLogSyncAt();
        return throwError(err);
      })
    ).subscribe();

    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
  }

  onExport() {
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        } else {
          data['start_date_time'] = moment('1970-01-01 00:00:00').tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(now()).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        var filename = (this.form.get('username').value !== null ? this.form.get('username').value + '_' : '') + 'MemberReport_' + moment(this.form.get('start_date_time').value).format('YYYYMMDD') + '-' + moment(this.form.get('end_date_time').value).format('YYYYMMDD') + '.xlsx';
        this.loadingBar.start();
        return this.memberReportDataService.exportReport(`?${parameters}`).pipe(
          tap(res => {
            this.messages$ = this.memberReportDataService.messages$;
            this.loadingBar.complete();
            this.button_loading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.button_loading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  sanitizeNumericField(previousValue: any) {
    const value = String(previousValue).replace(/[,]+/, '');
    return value;
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private openDialogBy(componentRef: any, data?: { game_account: string, game_provider_id: number, game_provider_code: string, username: string, start_date_time: string, end_date_time: string, type: string, currency_code: string, timezone: string, game_type: number, member_account_id: number, from: string }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '90vw',
      height: '90vh',
      data: {
        game_account: data.game_account,
        game_provider_id: data.game_provider_id,
        game_provider_code: data.game_provider_code,
        username: data.username,
        member_account_id: data.member_account_id,
        start_date_time: data.start_date_time,
        end_date_time: data.end_date_time,
        type: data.type,
        currency_code: data.currency_code,
        timezone: data.timezone,
        game_type: data.game_type,
        from: data.from,
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      ga_username: new FormControl(null),
      start_date_time: new FormControl(this.from),
      end_date_time: new FormControl(this.to),
      timezone: new FormControl(this.timezone),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      }) // Do not remove: For Clearing The Range
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  getLastBetLogSyncAt(){
    var userTimeZone = JSON.parse(localStorage.getItem('user_data')).timezone;
    var timeInterved = 5; // 5 minutes 
    const d = new Date();
    let minutes = d.getMinutes();
    const intMinutes =  minutes/timeInterved;
    const roundedMinute = (minutes/timeInterved).toFixed(0);
    var minus;
    if( Number(intMinutes) < Number(roundedMinute) || Number(intMinutes) == Number(roundedMinute)){
      if(Number(roundedMinute) > 0) minus = 1;
      else minus = -1;
    }
    else if( Number(intMinutes) > Number(roundedMinute)){
      minus = 0;
    }
    var  minutesConverted5min = ( Number(roundedMinute) - minus )  * timeInterved;
    var newMinutes;
    if(minutesConverted5min < 10){
      newMinutes = '0'+minutesConverted5min.toString();
    }else{
      newMinutes = minutesConverted5min.toString();
    }

    var timeNow =  DateTime.now().setZone(userTimeZone).toFormat('yyyy-MM-dd HH:'+newMinutes+':00 (ZZ)'); 
    this.lastBetLogSyncAt = timeNow.replace('(', '(GMT ');
  }
    
}
