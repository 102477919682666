import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dialog-preview.component',
  templateUrl: './dialog-preview.component.html',
  styleUrls: ['./dialog-preview.component.scss']
})
export class DialogPreviewComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit() {

    }

    youtubeLink(link) {
        if (!link.includes('?')) {
            link = link + '?autoplay=1&mute=1';
        }

        if(link.includes("youtube") && link.includes("watch?v=")) {
            link = link.replace('watch?v=', 'embed/');
        }

        return link;
    }
}
