import { catchError, tap } from 'rxjs/operators';
import { ProvidersDataService } from './../service/providers-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { SmsProvider } from '@core/models/sms-provider.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';

@Component({
  selector: 'kt-provider-edit-dialog',
  templateUrl: './provider-edit-dialog.component.html',
  styleUrls: ['./provider-edit-dialog.component.scss']
})
export class ProviderEditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  statuses = this.dropdownHttpService.abledDisabled;
  messages$ = this.providersDataService.messages$;
  refreshStatus: boolean;

  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { smsProvider: SmsProvider },
    private dropdownHttpService: DropdownHttpService,
    private providersDataService: ProvidersDataService,
    public dialogRef: MatDialogRef<ProviderEditDialogComponent>
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy(){
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      id: this.data.smsProvider.id,
      ...this.form.value,
      default: this.form.value.default ? 1 : 0,
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    this.providersDataService.updateSmsProvider(data).pipe(
      tap((res: any) => {
        this.messages$.next([...res.message]);
        this.refreshStatus = true;
        this.buttonLoading = false; 
        // To enable "Save" button after get response
        this.form.setErrors(null);  
      }),
      catchError((error) => {
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    this.form = new FormGroup({
      status: new FormControl(this.data.smsProvider.status),
      default: new FormControl(this.data.smsProvider.default),
    });
  }

}
