import { EditorService } from '@core/services/editor.service';
import { FaqEntityService } from './../services/faq-entity.service';
import { Faq } from '@core/models/faq.model';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { FaqDataService } from './../services/faq-data.service';
import { CustomUploadAdapterPlugin } from '@core/ckeditor-upload-adapter/CustomUploadAdapterPlugin';
import { Subscription, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';

@Component({
  selector: 'kt-faq-edit',
  templateUrl: './faq-edit.component.html',
  styleUrls: ['./faq-edit.component.scss']
})
export class FaqEditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  languages = [];

  dropdown = {
    locales$: this.dropdownHttpService.locales,
    sitePlatforms: this.dropdownHttpService.sitePlatforms
  };

  faqSections: any;

  messages$ = this.faqDataService.messages$;
  refreshStatus: boolean;
  buttonLoading = false;

  faqs: any;
  // faqIdRef: number;
  // faqSectionRef: string;
  faqDetails = [];
  //isNewSection = false;
  public editor = Editor;
  editorConfig = this.editorService.config;

  sectionName = null;

  private localesSub = new Subscription();
  private subscription = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { faqs: any; mode: string, locales: any, question_id: number },
    public dialogRef: MatDialogRef<FaqEditDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private faqDataService: FaqDataService,
    private FaqEntityService: FaqEntityService,
    private editorService: EditorService
  ) {
    this.dropdownHttpService.faqSections.subscribe(res => this.faqSections = res);
  }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.localesSub.unsubscribe();
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  setSectionName(event: any) {
    this.sectionName = event.target.value;
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(faq: Faq, mode?: string) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    let data = {
      ...this.form.value,
    };
    Object.keys(data).forEach((key) => ((key === 'question_id' && this.data.mode === 'create') || data[key] == null || data[key] === '') && delete data[key]);
    Object.keys(data).forEach((key) => ((key === 'faq_name' && this.data.mode === 'edit') || data[key] == null || data[key] === '') && delete data[key]);
    Object.keys(data.details).forEach((key) => {
      if (data.details[key].answer === null || data.details[key].question === null || data.details[key].answer === '' || data.details[key].question === '') {
        delete data.details[key]
      }
    });
    switch (mode) {
      case 'edit':
        this.subscription = forkJoin([
          this.faqDataService.updateFaqContent(this.data.question_id, data).pipe(
            tap((res: any) => {
              this.form.setErrors(null);
              this.buttonLoading = false;
              this.messages$.next([...res.message]);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              this.form.enable();
              throw error;
            })
          )
        ]).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.faqDataService.addContent(data).pipe(
            tap((res: any) => {
              this.form.setErrors(null);
              this.buttonLoading = false;
              this.messages$.next([...res.message]);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              this.form.enable();
              throw error;
            })
          )
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onSelectSection(event: any) {
    const section = this.faqSections.find(faq => faq.id === +event.target.value);
    this.form.patchValue({
      faq_section_id: event.target.value,
      faq_name: section?.faq_name,
    });
  }

  /*onNewSectionTick(event: any){
    this.isNewSection = event.target.checked;
    this.form.patchValue({
      new_flag: Number(event.target.checked)
    });
    this.languages.map(element => {
      this.form.patchValue({
        details: {
          [element.id]: {
            section: null
          }
        }
      });
    });
  }*/

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    const validatorOptions = []; // [Validators.required];

    const buildSubDetails = () => {
      let details = {};
      this.data.locales.map((element, index) => {
        const detailsGroup = new FormGroup({
          settings_locale_id: new FormControl(element.id),
          question: new FormControl(null, validatorOptions),
          answer: new FormControl(null),
        });
        details = { ...details, [element.id]: detailsGroup };
      });
      return details;
    }

    this.form = new FormGroup({
      faq_section_id: new FormControl(null, validatorOptions),
      position: new FormControl(null, validatorOptions),
      platform_type_id: new FormControl(null, validatorOptions),
      faq_name: new FormControl({ value: null, disabled: true }),
      question_id: new FormControl(this.data.question_id),
      details: new FormGroup(buildSubDetails())
    });

    if (this.data.mode === 'edit') {
      this.data.locales.map(res => {
        const faq = this.data.faqs.find(faq => faq.settings_locale_id === res.id);
        const editDetails = {
          question: faq ? faq.question : null,
          answer: faq ? faq.answer : null,
          setting_locale_id: faq ? faq.setting_locale_id : null
        };
        this.form.patchValue({ details: { [res.id]: editDetails } });
      });
      this.form.patchValue({
        faq_name: this.data.faqs[0].section_name,
        position: this.data.faqs[0].position,
        platform_type_id: this.data.faqs[0].platform_type_id ?? null
      })
    }
    this.form.markAllAsTouched();
    this.form.markAsDirty();
  }

}
