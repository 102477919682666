import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Marquees } from '@core/models/marquees.model';

@Injectable()
export class MarqueesEntityService extends EntityCollectionServiceBase<Marquees> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('Marquees', serviceElementsFactory);
  }
}
