import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { SubheaderService } from '@core/_base/layout';
import { MatDialog } from '@angular/material/dialog';
import { WhatsappBotEditDialogComponent } from './dialogs/whatsapp-bot-edit/whatsapp-bot-edit.component';
import { WhatsappBotQRDialogComponent } from './dialogs/whatsapp-bot-qr/whatsapp-bot-qr.component';
import { WhatsappBotEntityService } from './services/whatsapp-bot-entity.service';
import { WhatsappBot } from '@core/models/whatsapp-bot.model';
import { Observable, Subscription, interval, of } from 'rxjs';
import { tap, map, exhaustMap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { WhatsappBotDataService } from './services/whatsapp-bot-data.service';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { WhatsappBotStage } from '@core/enums/whatsapp-bot.enum';
import { Status } from '@core/enums/status.enum';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  templateUrl: './whatsapp-bot.component.html',
  styleUrls: ['./whatsapp-bot.component.scss']
})
export class WhatsappBotComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  dropdown = {
    statuses:  this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage
  };
  status = Status;
  stage = WhatsappBotStage;
  whatsappBots$: Observable<WhatsappBot[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';

  locales: any;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  private subscription = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private whatsappBotService: WhatsappBotEntityService,
    private whatsappBotDataService: WhatsappBotDataService,
    private dropdownHttpService: DropdownHttpService
  ) {

    this.dropdownHttpService.locales.subscribe(res => this.locales = res);
   }

  ngOnInit() {
    this.reload();
    this.formInit();
    this.pagination = this.whatsappBotDataService.pagination;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onOpenDialog(type: string, whatsappBotId?: number) {

    if(whatsappBotId) {
      if (type === 'qr') {
        this.openDialogBy(WhatsappBotQRDialogComponent, { botId: whatsappBotId, mode: 'qr' });
      }else{
        const whatsappBot = this.whatsappBotDataService.getById(whatsappBotId);
      
        this.subscription = whatsappBot.pipe(
          tap( (res) => {
            switch (type) {
              case 'edit':
                this.openDialogBy(WhatsappBotEditDialogComponent, { whatsappBot: res, mode: 'edit' });
                break;
            }
          })
        )
        .subscribe();
      }

      
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.whatsappBots$ = this.whatsappBotService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.whatsappBotDataService.pagination;
        this.loading = false;
        this.dataLength = res.length;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event){
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.whatsappBots$ = this.whatsappBotService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.whatsappBotDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { whatsappBot?: any, mode?: any, botId?: any}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        whatsappBot: data.whatsappBot,
        mode: data.mode,
        botId: data.botId,
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result === true){
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      status: new FormControl('all'),
      keywords: new FormControl(null)
    });
  }

  private refreshEvery(seconds: number) {
    const refreshSource = interval(seconds * 1000);
    refreshSource.subscribe(() => {
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
          this.reload();
        }
      });
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key =>  (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

}
