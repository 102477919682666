import { SalesConversionReportDataService } from '../services/sales-conversion-report-data.service';
import { Subscription, Observable,throwError } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { SalesConversionReportDetails } from '@core/models/sales-conversion-report-details.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Pagination } from '@core/models/pagination.model';
import { tap, catchError } from "rxjs/operators";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { MatDialog } from "@angular/material/dialog";
import { AuthHttpService } from '@core/services/auth-http.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'kt-sales-conversion-report-details',
  templateUrl: './sales-conversion-report-details.component.html',
  styleUrls: ['./sales-conversion-report-details.component.scss'],
})
export class SalesConversionReportDetailsDialogComponent implements OnInit, OnDestroy {
  
  form: FormGroup;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  from = null;
  to = null;

  buttonLoading = false;
  loading = false;

  messages$ = this.salesConversionReportDataService.messages$;
  salesConversionReportDetails$: Observable<SalesConversionReportDetails[]>;
  dataLength: number;
  
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
  };

  default_sort_by = 'telemarketer';
  default_sort_order = 'asc';

  sortingConfig = {
    'total_leads_assgined': 'desc',
    'fresh_leads': 'desc',
    'old_leads': 'desc',
    'non_member_leads': 'desc',
    'total_calls': 'desc',
    'avg_calls': 'desc',
    'max_calls': 'desc',
    'min_calls': 'desc',
    'total_deposits': 'desc',
    'fresh_leads_deposits': 'desc',
    'old_leads_deposits': 'desc',
    'non_member_leads_deposits': 'desc',
    'commissionable_depositors': 'desc',
    'comm_conversion_rate': 'desc',
    'deposit_value': 'desc'
  };

  access$ = this.authHttpService.getUserAccess(13, 'Sales Conversion Report');

  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order,
  };

  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      telemarketer_id: any,
      currency_id: number,
      site_id: number,
      month: number;
      year: number;
      mode: string;
      timezone: string,
      dummy: number },
    private salesConversionReportDataService: SalesConversionReportDataService,
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
    public dialogRef: MatDialogRef<SalesConversionReportDetailsDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private authHttpService: AuthHttpService,
  ) {}

  ngOnInit() {
    this.formInit();
    this.onViewPageBy();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];
    this.onViewPageBy(this.page, this.pageSize);
  }

  onViewPageBy(page = 1, pageSize?: number) {
    this.loading = true;
    pageSize = this.pageSize;
    const data = this.filterFormFields(this.form.value);
    const params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = params ? `&${params}` : '';
    return (this.salesConversionReportDetails$ =
      this.salesConversionReportDataService.getDetails(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${parameters}`)
        .pipe(
          tap((res) => {
            this.loading = false;
            this.dataLength = res.length;
            this.pagination = this.salesConversionReportDataService.pagination;
            this.loadingBar.complete();
          })
    ));
  }

  onPerPage(size: Event){
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize);
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private showCommConversionRate(rate:number){
    if (rate == 0){
      return "N/A";
    }else{
      return rate + "%"
    }
  }

  onExport() {
    this.loading = true;
    var pageSize = this.pageSize;
    var page = this.page;
    const data = this.filterFormFields(this.form.value);
    const params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = params ? `&${params}` : '';
    this.salesConversionReportDataService.exportReportDetails(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${parameters}`).pipe(
      tap((res: any) => {
        this.loadingBar.complete();
        this.loading = false;
      }),
      catchError((error) => {
        this.loadingBar.complete();
        this.loading = false;
        return throwError(error);
      })
    ).subscribe();
    
  }
  
  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    let telemarketer_id = this.data.telemarketer_id ?? null;
    let currency_id = this.data.currency_id ?? null;
    let year = this.data.year ?? null;
    let month = this.data.month ?? null;
    let timezone = this.data.timezone ?? null;
    let dummy = this.data.dummy ?? null;

    this.form = new FormGroup({
      telemarketer_id: new FormControl(telemarketer_id),
      currency_id: new FormControl(currency_id),
      year:new FormControl(year),
      month:new FormControl(month),
      timezone: new FormControl(timezone),
      dummy: new FormControl(dummy),
    });
  }
}
