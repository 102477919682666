<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Assign Leads to Account Manager</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <!-- Currency -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">Currency <span class="text-danger">*</span> </label>
          <select formControlName="settings_currency_id" class="col-8 form-control" (change)="onChangeCurrency($event.target.value)" [attr.disabled]="buttonLoading == true ? '' : null">
            <option value="null" disabled hidden> Please Select </option>
            <option [value]="value.id" *ngFor="let value of dropdown.currencies"> {{ value.name }} </option>
          </select>
        </div>
        <!-- Development Stage -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">Development Stage<span class="text-danger">*</span></label>
          <select formControlName="development_stage" class="col-8 form-control" (change)="onChangeDevelopmentStage($event.target.value)" [attr.disabled]="buttonLoading == true ? '' : null">
            <option [value]="null" [disabled]="true">Please Select</option>
            <option [value]="value.id" *ngFor="let value of dropdown.developmentStage">
                {{ value.name }}
            </option>
          </select>
        </div>
        <!-- Account Manager -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">Account Manager<span class="text-danger">*</span></label>
          <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0"
            [form] = 'form'
            [dropdownList] = 'accountManagerDropdownList'
            [dropdownSettings] = 'accountManagerDropdownSettings'
            [formName] = "'account_manager_id'"
            [selectionAttributes] = "'id'"
            [selectedItems] = 'accountManagerSelectedItems'
            (deSelectAll) = "onAccountManagerIdChanges()"
            (closeTriggered) = "onAccountManagerIdChanges()">
          </kt-dropdown-wo-lazyload>
        </div>
        <!-- Member Recovery Type Normal-->
        <div class="col-12 form-group row pr-0" *ngIf="form.value.development_stage == 1">
          <label class="col-4 col-form-label">Member Recovery Type<span class="text-danger">*</span></label>
          <select formControlName="member_recovery_type" class="col-8 form-control" (change)="onChangeMemberRecovery($event.target.value)" [attr.disabled]="buttonLoading == true ? '' : null">
            <option [value]="null" [disabled]="true">Please Select</option>
            <option [value]="value.id" *ngFor="let value of dropdown.normalMemberRecoveryType">
              <span>{{ value.name }}</span>
            </option>
          </select>
        </div>
        <!-- Member Recovery Type Vip-->
        <div class="col-12 form-group row pr-0" *ngIf="form.value.development_stage == 2">
          <label class="col-4 col-form-label">Member Recovery Type<span class="text-danger">*</span></label>
          <select formControlName="member_recovery_type" class="col-8 form-control" (change)="onChangeMemberRecovery($event.target.value)" [attr.disabled]="buttonLoading == true ? '' : null">
            <option [value]="null" [disabled]="true">Please Select</option>
            <option [value]="value.id" *ngFor="let value of dropdown.vipMemberRecoveryType">
              <span>{{ value.name }}</span>
            </option>
          </select>
        </div>
        <!-- No. of Min Inactive Days -->
        <div class="col-12 form-group row pr-0 no-margin-bottom" *ngIf="form.value.development_stage == 1 && form.value.member_recovery_type == 1">
          <label class="col-4 col-form-label"> </label>
          <div class="col-8 input-group no-padding">
            <label class="col-form-label">Inactive Days<span class="text-danger">*</span></label>
            <!-- <span class="input-group-append">
              <span class="input-group-text pointer-calendar inactive-days">Min Inactive Days</span>
            </span>
            <input type="number" (wheel)="false" min="1" formControlName="min_inactive_days" [attr.disabled]="buttonLoading == true ? '' : null" class="form-control" [ngClass]="{'is-invalid': form.controls.min_inactive_days.errors }"> -->
          </div>
          <!-- Min Inactive Days Allowed -->
          <!-- <small id="passwordHelpBlock" class="form-text text-muted col-4" *ngIf="form.controls.min_inactive_days.errors"> </small>
          <small id="passwordHelpBlock" class="form-text text-muted red-hint col-8" *ngIf="form.controls.min_inactive_days.errors">
            <i class="fa fa-exclamation-circle"></i> Min Inactive Days Allowed: {{ min_inactive_days_allowed | number:'1.0-2'}}
          </small> -->
        </div>
        <!-- No. of Max Inactive Days -->
        <div class="col-12 form-group row pr-0" *ngIf="form.value.development_stage == 1 && form.value.member_recovery_type == 1">
          <label class="col-4 col-form-label"> </label>
          <div class="col-2 input-group pl-0">
            <span class="input-group-append">
              <span class="input-group-text pointer-calendar inactive-days">MIN </span>
            </span>
            <input type="number" (wheel)="false" min="1" formControlName="min_inactive_days" [attr.disabled]="buttonLoading == true ? '' : null" class="form-control num-input-group" [ngClass]="{'is-invalid': form.controls.min_inactive_days.errors }">
            <!-- Max Inactive Days Allowed -->
            <small id="passwordHelpBlock" class="form-text text-muted red-hint" *ngIf="form.controls.min_inactive_days.errors || form.controls.max_inactive_days.errors">
              <i class="fa fa-exclamation-circle red-hint"></i> Minimum: {{ min_inactive_days_allowed | number:'1.0-2'}}
            </small>
          </div>
          <div class="col-2 input-group pl-0">
            <span class="input-group-append">
              <span class="input-group-text pointer-calendar inactive-days">MAX </span>
            </span>
            <input type="number" (wheel)="false" min="1" formControlName="max_inactive_days" [attr.disabled]="buttonLoading == true ? '' : null" class="form-control num-input-group" [ngClass]="{'is-invalid': form.controls.max_inactive_days.errors }">
            <!-- Max Inactive Days Allowed -->
            <small id="passwordHelpBlock" class="form-text text-muted red-hint" *ngIf="form.controls.max_inactive_days.errors || form.controls.min_inactive_days.errors">
              <i class="fa fa-exclamation-circle red-hint"></i> Maximum: {{ max_inactive_days_allowed | number:'1.0-2'}}
            </small>
          </div>
          <div class="col-2 text-center no-padding">
            <kt-search-button [loading]="searchBtnLoading" [disabledButton]="buttonLoading || searchBtnDisabled()" (search)="onSearchInactivceAvailableLeads()"></kt-search-button>
          </div>
          <div class="col-2 text-left no-padding clear-btn">
            <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="buttonLoading || searchBtnDisabled()" (clear)="onClear()"></kt-clear-button>
          </div>
        </div>
        <!-- No. of Leads -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label"> </label>
          <div class="col-8 input-group no-padding">
            <span class="input-group-append">
              <span class="input-group-text pointer-calendar left-border-radius">Number of Available Leads</span>
            </span>
            <!-- Normal leads -->
            <input *ngIf="availableLeads == 0 && form.value.development_stage == 1" class="form-control" disabled>
            <input *ngIf="availableLeads > 0 && form.value.development_stage == 1" type="number" (wheel)="false" min="1" formControlName="normal_leads" class="form-control" [attr.disabled]="buttonLoading == true ? '' : null" placeholder="Enter total number of leads for all selected AMs">
            <!-- Vip leads -->
            <input *ngIf="availableLeads == 0 && form.value.development_stage == 2" class="form-control" disabled>
            <input *ngIf="availableLeads > 0 && form.value.development_stage == 2" type="number" (wheel)="false" min="1" formControlName="vip_leads" class="form-control" [attr.disabled]="buttonLoading == true ? '' : null" placeholder="Enter total number of leads for all selected AMs">
          </div>
          <!-- Available leads -->
          <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
          <small id="passwordHelpBlock" class="form-text text-muted col-8">
            Available leads:
            <ng-container *ngIf="getLeadsList">{{ availableLeads | number:'1.0-2'}}</ng-container>
            <ng-container *ngIf="!getLeadsList">-</ng-container>
          </small>
        </div>
        <div class="col-12 form-group row pr-0" *ngIf="leadIndicator != ''" [ngbTooltip]="leadIndicator">
          <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
          <small id="passwordHelpBlock" class="form-text text-muted col-8 leadIndicator">
            {{ leadIndicator }}
          </small>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <button class="btn btn-primary btn-icon-sm mr-2" (click)="onAssignLead()" type="button" [disabled]="buttonLoading || !form.valid || !checkAssignLead() || !canAssignLead"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Submit</button>
      </div>
    </div>
  </form>
</div>
