<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Referral Commission Details: {{ data.username }} | {{ data.currency }}</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <!-- Filter -->
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <div class="col-12 row mb-2 pr-0">
                <!-- Username -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Username:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="username" placeholder="Search" class="form-control">
                </div>
                <!-- Member Group -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Member Group:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="member_group_id" class="form-control">
                    <option value="all">All</option>
                    <option [value]="value.id" *ngFor="let value of dropdown.groups | async">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
                <!-- Currency -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Currency:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="settings_currency_id">
                    <option value="all">All</option>
                    <ng-container *ngFor="let value of dropdown.currencies | async">
                      <option [value]="value.id">{{ value.name }}</option>
                    </ng-container>
                  </select>
                </div>
                <!-- Downline Tier -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Downline Tier:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="tier" class="form-control">
                    <option value="all">All</option>
                    <option [value]="value.id" *ngFor="let value of dropdown.tiers">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 row pr-0">
                <!-- Commission Amount -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Commission Amount:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <div class="input-group date">
                    <input class="form-control" formControlName="commission_amount" type="number" (wheel)="false" />
                  </div>
                </div>
                <!-- Date Time -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date/Time:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <select class="form-control" formControlName="date_type">
                        <option value="created_date">Created Date</option>
                        <option value="updated_date">Updated Date</option>
                      </select>
                    </div>
                    <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                    <span class="input-group-append">
                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 row pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="d-flex flex-wrap status-filter-pt">
                    <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.statuses ; let i = index">
                      <input type="checkbox" [checked]="item.checked" (change)="onSearchStatus($event, item.value, i)" class="custom-control-input" [id]="item.name">
                      <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Action Button -->
      <div class="kt-section col-12 row mb-3 mt-2 d-flex justify-content-between">
        <div>
          <kt-search-button [loading]="searchBtnLoading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" (clear)="onClear()"></kt-clear-button>
        </div>
      </div>

      <!-- Summary -->
      <div class="col-xl-12 summary-wrapper">
        <div class="row">
          <label>Selected Rows Total Referral Commissions: </label>
          <span class="ml-1">{{ selectedTotalReferralCommissionConvert | number : '1.2'}} (USD); </span>
          <span class="ml-1">{{ selectedTotalReferralCommission | number : '1.2'}} ({{ data.currency }})</span>
        </div>
        <div class="row">
          <label>Current Page Total Referral Commission: </label>
          <span class="ml-1">{{ currentPageTotalReferralCommissionConvert }} (USD); </span>
          <span class="ml-1">{{ currentPageTotalReferralCommission | number : '1.2'}} ({{ data.currency }})</span>
        </div>
        <div class="row">
          <label>Total Referral Commission: </label>
          <span class="ml-1">{{ totalReferralCommissionConvert }} (USD); </span>
          <span class="ml-1">{{ totalReferralCommission }} ({{ data.currency }})</span>
        </div>
      </div>

      <!-- Table -->
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-striped table-sortable">
          <thead>
            <tr>
              <th rowspan="2"><input type="checkbox" [(ngModel)]="toggleAll" (change)="checkboxToggleAll()"></th>
              <th rowspan="2" class="sort-enabled pointer" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
              <th rowspan="2" class="sort-enabled pointer" (click)="onSortColumn('downline_username')" [ngClass]="sortingConfig.downline_username" [class.sort-selected]="sortingSelection.sort_by === 'downline_username'">Username</th>
              <th rowspan="2" class="sort-enabled pointer" (click)="onSortColumn('downline_member_group_name')" [ngClass]="sortingConfig.downline_member_group_name" [class.sort-selected]="sortingSelection.sort_by === 'downline_member_group_name'">Member Group</th>
              <th rowspan="2" class="sort-enabled pointer" (click)="onSortColumn('from_currency_code')" [ngClass]="sortingConfig.from_currency_code" [class.sort-selected]="sortingSelection.sort_by === 'from_currency_code'">Currency</th>
              <th rowspan="2" class="sort-enabled pointer" (click)="onSortColumn('exchange_rate')" [ngClass]="sortingConfig.exchange_rate" [class.sort-selected]="sortingSelection.sort_by === 'exchange_rate'">Exchange Rate</th>
              <th rowspan="2" class="sort-enabled pointer" (click)="onSortColumn('deposit_amount')" [ngClass]="sortingConfig.deposit_amount" [class.sort-selected]="sortingSelection.sort_by === 'deposit_amount'">Deposit Amount</th>
              <th class="text-center" colspan="4">Commission</th>
              <th rowspan="2" class="sort-enabled pointer" (click)="onSortColumn('remarks')" [ngClass]="sortingConfig.remarks" [class.sort-selected]="sortingSelection.sort_by === 'remarks'">Remarks</th>
              <th rowspan="2" class="sort-enabled pointer" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
              <th rowspan="2" class="sort-enabled pointer" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
              <th rowspan="2" class="sort-enabled pointer" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
            </tr>
            <tr>
              <!--Commission-->
              <th class="text-center" class="sort-enabled pointer" (click)="onSortColumn('tier')" [ngClass]="sortingConfig.tier" [class.sort-selected]="sortingSelection.sort_by === 'tier'">Downline Tier</th>
              <th class="text-center" class="sort-enabled pointer" (click)="onSortColumn('commission_rate')" [ngClass]="sortingConfig.commission_rate" [class.sort-selected]="sortingSelection.sort_by === 'commission_rate'">Rate (%)</th>
              <th class="text-center" class="sort-enabled pointer" (click)="onSortColumn('commission_amount')" [ngClass]="sortingConfig.commission_amount" [class.sort-selected]="sortingSelection.sort_by === 'commission_amount'">Amount Before Conversion</th>
              <th class="text-center" class="sort-enabled pointer" (click)="onSortColumn('final_amount')" [ngClass]="sortingConfig.final_amount" [class.sort-selected]="sortingSelection.sort_by === 'final_amount'">Release Amount ({{ data.currency }})</th>
            </tr>
          </thead>
          <tbody *ngIf="(referralCommissionDetails$ | async) as rows">
            <tr *ngFor="let row of rows">
              <th [formGroup]="checkboxForm"><input type="checkbox" [formControlName]="row.id"></th>
              <td>{{ row.id }}</td>
              <td>
                <a [ngClass]="this.canViewMemberDialog ? 'text-primary font-weight-bold' : 'no-permission-text'" (click)="(this.canViewMemberDialog) ? onOpenDialog('member-information', row.downline_id) : ''">
                  {{ row.downline_username }}
                </a>
              </td>
              <td class="text-center">{{ row.downline_member_group_name }}</td>
              <td class="text-center">{{ row.from_currency_code }}</td>
              <td class="text-center">{{ row.exchange_rate | number : '1.3' }}</td>
              <td class="text-center">{{ row.deposit_amount | number : '1.2' }}</td>
              <!--Commission-->
              <td class="text-center">{{ row.tier }}</td>
              <td class="text-center">{{ row.commission_rate | number : '1.0' }}</td>
              <td class="text-center">{{ row.commission_amount | number : '1.2' }}</td>
              <td class="text-center">{{ row.final_amount | number : '1.2' }}</td>
              <td class="text-center">{{ row.remarks }}</td>
              <td class="text-center">
                <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status">{{ row.status_name }}</span>
              </td>
              <td>
                {{ row.created_by ? row.created_by : 'System' }}<br />
                <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                <ng-template #createdDateTime>
                  {{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                </ng-template>
              </td>
              <td>
                <ng-container *ngIf="row.updated_by">
                  {{ row.updated_by }}<br />
                  <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                  <ng-template #updatedDateTime>
                    {{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                  </ng-template>
                </ng-container>
              </td>
            </tr>
          </tbody>
          <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
        </table>
      </div>
      <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
        </ngb-pagination>
        <div class="kt-pagination__toolbar">
          <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
          </select>
          <span class="pagination__desc">
            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>