import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Pagination } from '@core/models/pagination.model';
import { TelemarketingPlayerSummaryReport } from '@core/models/telemarketing-player-summary-report.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { TelemarketingPlayerSummaryReportDataService } from './services/telemarketing-player-summary-report-data.service';
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';
import { TranslateService } from '@ngx-translate/core';
import { MemberInformationDialogComponent } from "../../general/members/dialogs/member-information/member-information.component";
import { MemberDataService } from "../../general/members/services/member-data.service";
import { MatDialog } from "@angular/material/dialog";
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'telemarketing-player-summary-report',
  templateUrl: './telemarketing-player-summary-report.component.html',
  styleUrls: ['./telemarketing-player-summary-report.component.scss'],
})
export class TelemarketingPlayerSummaryReportComponent implements OnInit, OnDestroy {
  @ViewChildren('filterInput') filterInput: QueryList<ElementRef>;
  form: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  pagination: Pagination;

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  timezoneDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown clear-all-disabled',
    primaryKey: 'offset',
    labelKey: 'offset',
    noDataLabel: '',
    showCheckbox: false
  };
  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones')),
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage
  };
  buttonLoading = false;
  messages$ = this.telemarketingPlayerSummaryReportDataService.messages$;

  telemarketingPlayerSummaryReport$: Observable<TelemarketingPlayerSummaryReport[]>;
  params = ``;
  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;
  currentContact = '';
  selectedContactType = 'Mobile';
  searchStatus = [0, 2, 3, 4, 6, 7];
  searchTierStatus = [0, 1];

  // permissions
  // telemarketingPlayerSummaryUseNewPermissions: boolean;
  canExportTeleMarketingPlayerSummary: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  datePickerSubscription: Subscription;
  ranges: any;

  default_sort_by = 'lead_assigned_datetime';
  default_sort_order = 'asc';

  sortingConfig = {
    'lead_assigned_datetime': 'desc',
    'registration_date': 'desc',
    'ftd_date': 'desc'
  };

  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order,
  };

  access$ = this.authHttpService.getUserAccess(13, 'Telemarketing Player Summary');
  isAdmin: boolean = JSON.parse(localStorage.getItem('user_data')).is_admin;

  telemarketerDropdownSettings = {};
  telemarketerDropdownList = [];
  telemarketerSelectedItems = [];

  default_currency_id = null;

  constructor(
    private telemarketingPlayerSummaryReportDataService: TelemarketingPlayerSummaryReportDataService,
    private transactionHttpService: TransactionHttpService,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private authHttpService: AuthHttpService,
    private timeZoneDate: TimezoneDatePipe,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private memberDataService: MemberDataService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    if (this.dropdown.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe(res => {
        this.dropdown.currencies = res;
        this.form.patchValue({
          currency_id : this.dropdown.currencies[0]['id']
        });
        this.cdr.detectChanges();
      });
    }else{
      this.form.patchValue({
        currency_id : this.dropdown.currencies[0]['id']
      });
    }
    this.ranges = this.transactionHttpService.ranges;
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.selectedTimezone = this.timezone;

    this.dropdownHttpService.telemarketer.subscribe(res => {
      this.telemarketerDropdownList = res;;
    });

    this.telemarketerDropdownSettings = {
      singleSelection: true,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    // this.telemarketingPlayerSummaryUseNewPermissions = this.appPermissionService.isSectionUsingNewPermissions('telemarketing_player_summary');
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canExportTeleMarketingPlayerSummary = appPermissions.export_telemarketing_player_summary;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null ? event.startDate._d : event.startDate,
        end_date_time: event.endDate !== null ? event.endDate._d : event.endDate,
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.telemarketerSelectedItems = [];

    this.form.patchValue({
      telemarketer_id: null,
      currency_id: this.dropdown.currencies[0]['id'],
      start_date_time: null,
      end_date_time: null,
      date_type: 'assigned_date',
      defaultDate: null,
      timezone: this.timezone,
      only_deposit: null,
    });
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.clearBtnLoading = false;
    // Comment this due to temporary only enable export feature
    // this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        tap((data) => {
          if (data['start_date_time']) {
            data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
            data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          }
          delete (data['timezone']);

          this.params = Object.keys(data)
            .map((key) => key + "=" + data[key])
            .join("&");
          this.sortingConfig[this.sortingSelection.sort_by] = 'desc';
          this.sortingSelection.sort_by = this.default_sort_by;
          this.sortingSelection.sort_order = this.default_sort_order;
          this.sortingConfig[this.default_sort_by] = this.default_sort_order;
          this.onViewPageBy();
        })
      )
      .subscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : "";
    this.loadingBar.start();
    this.searchBtnLoading = false;
    return (this.telemarketingPlayerSummaryReport$ = this.telemarketingPlayerSummaryReportDataService
      .getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`)
      .pipe(
        map(
          (res) => {
            return res.map((report: TelemarketingPlayerSummaryReport) => ({
              ...report
            }));
          }
        ),
        tap((res) => {
          this.dataLength = res.length;
          this.pagination = this.telemarketingPlayerSummaryReportDataService.pagination;
          this.loadingBar.complete();
          this.onSearch = true;
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
        }),
        catchError((err) => {
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.loadingBar.complete();
          return throwError(err);
        })
      ));
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onExport() {
    this.buttonLoading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        data['paginate'] = false;
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        return this.telemarketingPlayerSummaryReportDataService.exportReport(`?${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.buttonLoading = false;
          }),
          catchError((err) => {
            this.buttonLoading = false;
            this.loadingBar.complete();
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private formInit() {
    this.form = new FormGroup({
      telemarketer_id: new FormControl(null),
      currency_id: new FormControl('all'),
      date_type: new FormControl('assigned_date'),
      start_date_time: new FormControl(null),
      end_date_time: new FormControl(null),
      defaultDate: new FormControl(null),
      timezone: new FormControl(this.timezone),
      only_deposit: new FormControl(null),
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach((key) => {
      if (
        formData[key] !== '' &&
        formData[key] !== null &&
        formData[key] !== undefined &&
        key !== 'defaultDate' &&
        formData[key] !== 'all'
      ) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }
  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }

  timezoneDateWithTimezone(date: any, timezone: any, format: any) {
    return this.timeZoneDate.transformWithTimezone(date, timezone, format);
  }

  onClearDate() {
    if (this.form.value.start_date_time !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onOpenDialog(memberId?: number) {
    this.subscription = this.memberDataService.getById(memberId, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}
    &end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&additional_info=1`).pipe(
      tap((res) => {
        if (res) {
          this.openDialogBy(MemberInformationDialogComponent, { member: res });
        }
      })
    ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { member?: any }) {
    data['member']['timezone'] = this.form.value.timezone;
    const dialogRef = this.dialog.open(componentRef, {
      width: '1500px',
      height: '80vh' ,
      data,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

}
