import { Component, OnInit, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import Swal from "sweetalert2";
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { Pagination } from '@core/models/pagination.model';
import { TranslateService } from '@ngx-translate/core';
import { ContactDataService } from './services/contact-data.service';
import { Contact } from '@core/models/contact.model';
import { AppPermissionService } from '@core/services/app-permission.service';
@Component({
  selector: 'contacts',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {

  @HostListener('window:beforeunload', ['$event'])
  confirmLeavingPageBeforeSaving($event) {

    if(this.checkupdate()){
      $event.preventDefault();
      $event.returnValue = true;
      return $event;
    }
  }

  form: FormGroup;
  formContent: FormGroup;
  checkboxForm: FormGroup;
  messages$ = this.contactDataService.messages$;
  loading = false;
  searchBtnLoading = false;
  clearBtnLoading = false;
  dataLength = 0;
  params = '';
  buttonLoading = true;

  mode: any;
  contact: any;
  initContact: any;
  availableContact = [];
  selectedContact = [];
  isEmptyContent = true;

  ranges = this.transactionHttpService.ranges;
  contact$: Observable<Contact[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  displayLocale: any;
  backupPageSize = 30;
  backupPage = 1;
  unsaveStatus = true;

  changedObjects: any;
  toggleAll: boolean = false;
  sortingConfig = {
    'id': 'desc',
    'label': 'desc',
    'position': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    locales: [],
    types: [],
    social_platforms:[],
    platforms: this.dropdownHttpService.sitePlatformsContact,
    perPage: this.dropdownHttpService.perPage,
  };
  
  usableLinks= [
    'Viber'
  ];
  linkValidationMsg: string;

  // permissions
  canCreateNewContact: boolean;
  canViewContactDetails: boolean;
  canEditContact: boolean;
  canUpdateContactStatus: boolean;
  
  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private localesSub = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private contactDataService: ContactDataService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private appPermissionService: AppPermissionService,
  ) { }
  async canDeactivate(): Promise<boolean | Observable<boolean>> {
    // provide component specific logic to decide if component can or can't be deactivated
    if (this.checkupdate()) {
      const result = await Swal.fire({
        title: '<div class="text-center">Unsaved Changes</div>',
        html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Cancel'),
        confirmButtonText: this.translateService.instant('Discard'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          return (true);
        } else if (result.isDenied) {
          return (false);
        }
      });
      return result;
    }
    return true;
  }

  ngOnInit() {
    this.dropdownHttpService.contactTypes.subscribe(res=>{
      this.dropdown.types =res;
    })
    this.dropdownHttpService.contactPlatforms.subscribe(res=>{
      this.dropdown.social_platforms =res;
    })
    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.dropdown.locales = res;
      this.formInit();
      this.onSubmit();
      this.initContact = JSON.stringify({ ...this.formContent.value });
    })).subscribe();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateNewContact = appPermissions.create_new_contact;
      this.canViewContactDetails = appPermissions.view_contact_details;
      this.canEditContact = appPermissions.edit_contact;
      this.canUpdateContactStatus = appPermissions.update_contact_status;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.localesSub.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onClear() {
    this.form.patchValue({
      status: 'all',
      settings_locale_id: 'all',
      label: null,
      contact_type_id: 'all',
      contact_platform_id: 'all',
      platform_type_id: 'all',
    })
  }

  onClearContent() {
    this.formContent.patchValue({
      label: null,
      contact_type_id: null,
      contact_platform_id: null,
      platform_type_id: null,
      position: 99,
      status: 1,
    })

    this.dropdown.locales.forEach((element: any) => {
      this.formContent.patchValue({
        locales: {
          [element.id]: {
            value: null,
            link: null,
          }
        }
      });
    });

    this.initContact = JSON.stringify({ ...this.formContent.value });
  }

  reload(clearSearch?: boolean) {

    this.isEmptyContent = true;
    this.mode = '';
    this.onClearContent();
    this.onSubmit(clearSearch);
    this.buttonLoading = false;
    this.selectedContact = [];
  }

  async onSubmitClick(clearSearch?: boolean) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.isEmptyContent = true;
      this.mode = '';
      this.onClearContent();
      this.onSubmit(clearSearch);
    }
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    if (clearSearch) {
      this.onClear();
    }
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.contact$ = this.contactDataService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.displayLocale = res.length && this.form.value.settings_locale_id != 'all' ? this.dropdown.locales.filter(x => x.id == this.form.value.settings_locale_id)[0] : this.dropdown.locales[0];
            this.page = 1;
            this.pagination = this.contactDataService.pagination;
            this.loadingBar.complete();
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.buttonLoading = false;
            this.toggleAll = false;
            this.dataLength = res.length;
            this.availableContact = res;
            this.checkboxFormInit();
            this.cdr.detectChanges();
          })
        );
      }),
    ).subscribe();
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.formContent.setErrors({ 'invalid': true });
    const data = {
      id: this.contact ? this.contact.id : null,
      ...this.formContent.value,
    };
    Object.keys(data).forEach((key) => {
      if (data[key] == null || data[key] === '' || data[key] === 'Invalid date') {
        delete data[key];
      }
      // else if (key === 'locales') {
      //   Object.keys(data[key]).forEach((sf) => {
      //     if (data[key][sf]['value'] == null && data[key][sf]['link'] == null) {
      //       delete data[key][sf];
      //     }
      //   })
      // }
    });
    switch (this.mode) {
      case 'edit':
        this.subscription = this.contactDataService.updateContact(this.contact.id, data).pipe(
          tap((res: any) => {
            this.messages$.next(res.message);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.form.enable();
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = this.contactDataService.add(data).pipe(
          tap((res: any) => {
            this.messages$.next(res.message);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.formContent.setErrors(null);
            this.formContent.enable();
            throw error;
          })
        ).subscribe();
        break;
    }
  }

  changeLocale(locale: any) {
    this.displayLocale = locale;
  }

  clearContent(locale: any) {
    Swal.fire({
      title: 'Delete Content',
      text: 'Are you sure you want to delete contents for ' + locale.code + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.formContent.patchValue({
          locales: {
            [locale.id]: {
              value: null,
              link: null,
            }
          }
        });
        let contents = this.formContent.value.locales;
        Object.keys(contents).forEach((sf) => {
          if (contents[sf]['value'] == null && contents[sf]['link']) {
            this.isEmptyContent = true;
          }
        });
      }
      this.cdr.detectChanges();
    });
  }

  checkContent(locale: any) {
    let contents = this.formContent.value.locales[locale.id];

    if (!contents){
      return false;
    }

    if ((contents.value == null || contents.value == '') && (contents.link == null || contents.link == '')){
      return false;
    }
    else {
      return true;
    }
  }

  onChangeStatus($event, row: any) {
    const data = {
      id: row.id,
      status: $event.target.checked ? 1 : 0,
    };
    this.subscription = this.contactDataService.updateStatus(data).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.buttonLoading = false;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

  async onPerPage(size: Event) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.page = 1;
      this.pageSize = +(size.target as HTMLSelectElement).value;
      this.backupPageSize = this.pageSize;
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
    else {
      this.pageSize = this.backupPageSize;
    }
  }

  async onViewPageBy(page = 1, pageSize?: number, params?: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.backupPage = page;
      pageSize = this.pageSize;
      params = this.params ? `&${this.params}` : '';
      this.loading = true;
      this.loadingBar.start();
      return this.contact$ = this.contactDataService.getWithQuery(`?${this.params}&page=${page}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
        tap(res => {
          this.pagination = this.contactDataService.pagination;
          this.loading = false;
          this.toggleAll = false;
          this.dataLength = res.length;
          this.availableContact = res;
          this.checkboxFormInit();
          this.loadingBar.complete();
        })
      );
    }
    else {
      this.page = this.backupPage;
    }
  }

  expandRowCreate(mode: any, close?: boolean) {
    let contactInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initContact != contactInput ? true : false;
    if (close != true && this.changedObjects == false) {
      this.contact = null;
      this.changedObjects = false;
      this.initContact = null;
      this.mode = mode;
      this.cdr.detectChanges();
      this.onClearContent();
    } else {
      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.contact = null;
            this.isEmptyContent = true;
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();
            // Check if reopen needed
            this.checkReopen(mode);
          }
        });
      }
      else {
        this.contact = null;
        this.isEmptyContent = true;
        this.mode = '';
        this.unsaveStatus = true;
        this.onClearContent();
      }
    }
    this.cdr.detectChanges();
  }

  expandRowEdit(mode: any, row?: any, close?: boolean) {
    if (close != true) close = this.contact == row ? true : false;
    let contactInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initContact && this.initContact != contactInput ? true : false;
    if (close != true && this.changedObjects == false ) {
      this.contact = row;
      this.changedObjects = false;
      this.initContact = null;
      this.mode = mode;
      this.cdr.detectChanges();
      this.onClearContent();
      this.formContent.patchValue({
        label: this.contact.label,
        contact_type_id: this.contact.contact_type_id,
        contact_platform_id: this.contact.contact_platform_id,
        platform_type_id: this.contact.platform_type_id,
        position: this.contact.position,
        status: this.contact.status,
      });

      // reset all locales to null
      this.dropdown.locales.forEach((element: any) => {
        this.formContent.patchValue({
          locales: {
            [element.id]: {
              value: null,
              link: null
            }
          }
        });
      });
      
      this.contact.locales.forEach((element: any) => {
        this.formContent.patchValue({
          locales: {
            [element.settings_locale_id]: {
              value: element.value,
              link: element.link
            }
          }
        });
      });
      this.initContact = JSON.stringify({ ...this.formContent.value });
    } else {
      this.changedObjects = this.initContact != JSON.stringify({ ...this.formContent.value }) ? true : false;
      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.contact = null;
            this.isEmptyContent = true;
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();
            // Check if reopen needed
            this.checkReopen(mode, row);
          }
        });
      }
      else {
        this.contact = null;
        this.isEmptyContent = true;
        this.mode = '';
        this.unsaveStatus = true;
        this.onClearContent();
      }
    }
  }

  checkReopen(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowEdit(mode, row)
        break;
      case 'create':
        this.expandRowCreate(mode);
        break;
    }
  }

  localeIndex() {
    const index = this.dropdown.locales.findIndex(item => {
      return item.id == this.displayLocale['id'];
    });
    return index;
  }

  checkboxToggleAll() {
    let patchValue = {};
    if (!this.toggleAll) {
      this.availableContact.forEach(item => {
        patchValue = { ...patchValue, [item.id]: true };
      });
    }
    else {
      this.availableContact.forEach(item => {
        patchValue = { ...patchValue, [item.id]: false };
      });
    }
    this.checkboxForm.patchValue(patchValue);
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== false) {
        fields[key] = (formData[key] === true ? 1 : formData[key]);
      }
    });
    return fields;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  async onSortColumn(property: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      // Reset other columns
      for (const key in this.sortingConfig) {
        if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
          if (key == property) {
            this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
          } else {
            this.sortingConfig[key] = 'desc';
          }
        }
      }
      // User selection
      this.sortingSelection.sort_by = property;
      if (this.sortingSelection.sort_by === property) {
        // Same column
        this.sortingSelection.sort_order = this.sortingConfig[property];
      } else {
        // Switch to other column
        this.sortingConfig[property] = 'asc';
        this.sortingSelection.sort_order = 'asc';
      }
      // Load Data
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
  }

  private formInit() {
    const buildContents = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          settings_locale_id: new FormControl(element.id),
          value: new FormControl(null),
          link: new FormControl(null)
        });
        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };
    this.form = new FormGroup({
      // Filter
      status: new FormControl('all'),
      settings_locale_id: new FormControl('all'),
      contact_type_id: new FormControl('all'),
      contact_platform_id: new FormControl('all'),
      platform_type_id: new FormControl('all'),
      label: new FormControl(null),
    });
    this.formContent = new FormGroup({
      // Create dialog
      label: new FormControl(null, [Validators.required]),
      contact_type_id: new FormControl(null, [Validators.required]),
      contact_platform_id: new FormControl(null, [Validators.required]),
      platform_type_id: new FormControl(null, [Validators.required]),
      position: new FormControl(99, [Validators.required]),
      status: new FormControl(1, [Validators.required]),
      locales: new FormGroup(buildContents()),
    });
    this.formContent.valueChanges.subscribe(data => {
      this.isEmptyContent = true;

      for (const key in data['locales']) {
        if (data['locales'][key]['value'] !== null && data['locales'][key]['value'] !=='') {
          this.isEmptyContent = false;
          break;
        }
      }
    })
  }

  private checkboxFormInit() {
    let prepareForm = {};
    this.availableContact.forEach(item => {
      prepareForm = { ...prepareForm, [item.id]: false }
    });
    this.checkboxForm = this.fb.group(prepareForm);
    this.checkboxForm.valueChanges.subscribe(data => {
      this.selectedContact = [];
      Object.keys(data).forEach(key => {
        if (data[key]) {
          this.selectedContact.push(key);
        }
      })
      if (this.availableContact.length != this.selectedContact.length) {
        this.toggleAll = false;
      }
      else {
        this.toggleAll = true;
      }
    })
  }

  checkupdate() {
    return this.initContact !== JSON.stringify({ ...this.formContent.value }) ? true : false;
  }

  displayLocaleValue(row: any) {
    let contact = this.availableContact.filter(x => x.id == row.id)[0]['locales'];
    contact = contact.filter(x => x['locale_name'] == this.displayLocale['code']);
    if (contact.length > 0 && contact[0]['value'] !== null) {
      return contact[0]['value'];
    }
    else {
      return '-';
    }
  }

  displayLocaleLink(row: any) {
    let contact = this.availableContact.filter(x => x.id == row.id)[0]['locales'];
    contact = contact.filter(x => x['locale_name'] == this.displayLocale['code']);
    if (contact.length > 0 && contact[0]['link'] !== null) {
      return contact[0]['link'];
    }
    else {
      return '-';
    }
  }

  private onCheckUnsave() {
    return new Promise<void>((resolve, reject) => {
      let contactInput = JSON.stringify({ ...this.formContent.value });
      this.changedObjects = this.initContact && this.initContact != contactInput  ? true : false;

      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.isEmptyContent = true;
            this.mode = '';
            this.contact = null;
            this.unsaveStatus = true;
            this.onClearContent();
            resolve();
          }
          else {
            this.unsaveStatus = false;
            resolve();
          }
        });
      }
      else {
        resolve();
      }
    });
  }

  getLinkControl(localeId) {
    return (this.formContent.get(`locales.${localeId}`) as FormGroup).get('link');
  }

  linkValidator(control) {
    const link = control.value;
    const socialPlatform = this.dropdown.social_platforms.find(x => x.id == this.formContent.get('contact_platform_id').value);

    // Skip validation if Social Platform is not selected or Link is not filled yet
    if (!link || !socialPlatform) {
      return null;
    }
    
    let regex = /^(https:\/\/|http:\/\/|mailto:)?([\da-z\@.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    this.linkValidationMsg = "Please enter a valid URL or email address. You may optionally include \'http://\', \'https://\', or \'mailto:\' at the beginning.";
    
    if (socialPlatform.name == 'Viber') {
      regex = /^viber:\/\/chat\?number=(%2B)?[0-9]+$/;
      this.linkValidationMsg = "Link should start with \'viber://chat?number=\', followed by mobile number.";
    }

    return regex.test(link) ? null : { pattern: { link } };
  }
  
  updateLinkValidators(localeId?) {
    if (localeId) {
      const linkControl = this.getLinkControl(localeId);
      linkControl.setValidators([this.linkValidator.bind(this)]);
      linkControl.updateValueAndValidity();
    } else {
      this.dropdown.social_platforms.forEach(x => {
        const linkControl = this.getLinkControl(x.id);
        linkControl.setValidators([this.linkValidator.bind(this)]);
        linkControl.updateValueAndValidity();
      });
    }
  }
  
  // addUsableLink(link, localeId) {
  //   let template = '';

  //   switch (link) {
  //     case 'Viber':
  //       template = 'viber://chat?number=';
  //       break;
  //   }

  //   this.formContent.patchValue({
  //     locales: {
  //       [localeId]: {
  //         link: template
  //       }
  //     }
  //   });
  // }
}
