import { Faq } from '@core/models/faq.model';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable()
export class FaqEntityService extends EntityCollectionServiceBase<Faq> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Faq', serviceElementsFactory);
    }
}
