
import { tap, catchError, map, finalize } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { ApplicationRoleEditDialogComponent } from './dialogs/application-role-edit.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment-timezone';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Pagination } from '@core/models/pagination.model';
import { Status } from '@core/enums/status.enum';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ApplicationHttpService } from '@core/services/application-http.service';
import { ApplicationRole } from '@core/models/application-role.model';
import { Subscription, of } from 'rxjs';
import { ApplicationSectionPermission } from '@core/models/application-section-permission.model';
import { ApplicationRoleDetails } from '@core/models/application-role-details.model';
import { AppPermissionService } from '@core/services/app-permission.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';

type AppRoleEditDialogMode = 'create' | 'edit' | 'duplicate';

@Component({
  templateUrl: './application-role.component.html',
  styleUrls: ['./application-role.component.scss']
})
export class ApplicationRoleComponent implements OnInit, OnDestroy {

  form: FormGroup;

  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage,

    parents: [] as ApplicationRole[], // include self
    children: [] as ApplicationRole[],
  };
  status = Status;
  roles: ApplicationRole[] = [];
  descendantsAndSelf: ApplicationRole[] = [];

  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  editBtnLoading = {};
  duplicateBtnLoading = {};
  createBtnLoading = false;

  disabledPermissions = []; 

  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  ranges = this.transactionHttpService.ranges;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  dateTimeFilterType = [
    {
      key: 'created_time',
      label: 'Created Time'
    },
    {
      key: 'updated_time',
      label: 'Updated Time'
    },
  ];

  params = '';

  dataLength: number;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;

  sortingConfig = {
    'id': 'desc',
    'name': 'desc',
    'remarks': 'desc',
    'parent_id': 'desc',
    'status': 'desc',
    'created_by': 'desc',
    'updated_by': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  // permissions
  canCreateApplicationRole: boolean;
  canEditApplicationRole: boolean;
  canDuplicateApplicationRole: boolean;

  private subscriptions = new Subscription();

  constructor(
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private applicationHttpService: ApplicationHttpService,
    private transactionHttpService: TransactionHttpService,
    private cdr: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();

    this.dropdownHttpService.disabledApplicationPermissions.pipe(
      tap(disabledPermissions => {
        this.disabledPermissions = disabledPermissions;
      })
    ).subscribe();

    this.fetchParentChildDropdownItems();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateApplicationRole = appPermissions.app_role_create_role;
      this.canEditApplicationRole = appPermissions.app_role_edit_role;
      this.canDuplicateApplicationRole = appPermissions.app_role_duplicate_role;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onViewPageBy() {
    this.loading = true;
    this.loadingBar.start();
    this.roles = [];

    this.applicationHttpService.getRoles(`page=${this.page}&perPage=${this.pageSize}&${this.generateSortingParam()}${this.params}`).pipe(
      tap(res => {
        this.roles = res;
        this.resetRolesBtnLoading();
        this.loading = false;
        this.loadingBar.complete();
        this.dataLength = res.length;
        this.pagination = this.applicationHttpService.rolePagination;

        this.fetchParentChildDropdownItems();
      }),
      catchError(err => {
        this.loading = false;
        this.loadingBar.complete();
        throw err;
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy();
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy();
  }

  

  onOpenDialog(mode: AppRoleEditDialogMode, id?: number) {
    const setBtnLoading = this.getBtnLoadingFuncByMode(mode, id);

    if ((mode === 'edit' || mode === 'duplicate') && id) {
      setBtnLoading(true);

      this.applicationHttpService.getRoleDetails(id)
        .pipe(
          finalize(() => {
            setBtnLoading(false);
            this.cdr.detectChanges();
          }),
        )
        .subscribe(roleDetails => {
          this.openDialogBy(ApplicationRoleEditDialogComponent, {
            mode,
            sectionPermissions: roleDetails.sections,
            roleDetails: roleDetails,
            disabledPermissions: this.disabledPermissions,
          });
        });

        return;
    }

    // mode = create
    setBtnLoading(true);
    this.applicationHttpService.getSectionPermissions()
      .pipe(
        finalize(() => {
          setBtnLoading(false);
          this.cdr.detectChanges();
        }),
      )
      .subscribe(sectionPermissions => {
        this.openDialogBy(ApplicationRoleEditDialogComponent, {
          mode: 'create',
          sectionPermissions: sectionPermissions,
          disabledPermissions: this.disabledPermissions,
        });
      });
  }

  getBtnLoadingFuncByMode(mode: AppRoleEditDialogMode, id?: number) {
    return (flag: boolean) => {
      if (mode === 'create') {
        this.createBtnLoading = flag;
      } else if (mode === 'edit') {
        this.editBtnLoading[id] = flag;
      } else {
        this.duplicateBtnLoading[id] = flag;
      }
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.sortingSelection = {
      'sort_by': 'id',
      'sort_order': 'desc',
    };

    this.resetForm();
    this.onSubmit();
  }

  resetRolesBtnLoading() {
    this.roles.forEach(role => {
      this.editBtnLoading[role.id] = false;
      this.duplicateBtnLoading[role.id] = false;
    });
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null ? event.startDate._d : event.startDate,
        end_datetime: event.endDate !== null ? event.endDate._d : event.endDate
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onClearDate() {
    this.form.patchValue({ defaultDate: null });
  }

  resetForm() {
    this.form.patchValue({
      name: null,
      status: 'all',
      parent_id: 'all',
      child_id: 'all',
      remarks: null,
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
      datetime_type: this.dateTimeFilterType[0].key
    });
  }

  onSubmit() {
    this.loading = true;
    this.loadingBar.start();
    this.searchBtnLoading = true;
    this.page = 1;
    this.roles = [];

    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap(data => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        if (this.params.length > 0) {
          this.params = '&' + this.params;
        }
        this.applicationHttpService.getRoles(`page=${this.page}&perPage=${this.pageSize}&${this.generateSortingParam()}${this.params}`).pipe(
          tap(res => {
            this.roles = res;
            this.resetRolesBtnLoading();
            this.loading = false;
            this.loadingBar.complete();
            this.searchBtnLoading = false;
            this.clearBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.applicationHttpService.rolePagination;
          }),
          catchError(err => {
            this.loading = false;
            this.loadingBar.complete();
            this.searchBtnLoading = false;
            this.clearBtnLoading = false;
            throw err;
          })
        ).subscribe();
      })
    ).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      status: new FormControl('all'),
      parent_id: new FormControl('all'),
      child_id: new FormControl('all'),
      remarks: new FormControl(null),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null),
      datetime_type: new FormControl(this.dateTimeFilterType[0].key),
    });
  }

  private openDialogBy(componentRef: any, data?: { mode: AppRoleEditDialogMode, sectionPermissions: ApplicationSectionPermission[], roleDetails?: ApplicationRoleDetails, disabledPermissions: any[] }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '85vw',
      data: {
        mode: data.mode,
        sectionPermissions: data.sectionPermissions,
        roleDetails: data.roleDetails,
        disabledPermissions: data.disabledPermissions,
        descendantsAndSelf: this.descendantsAndSelf,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.onViewPageBy();
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate' && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  /**
   * One of the more important uses of this function is to fetch the latest role list of current role,
   * even the newly created one.
   *
   * Hence, this func must be put in onViewPageBy() func to trigger it after edit dialog closed
   */
  private fetchParentChildDropdownItems() {
    const appRoleId = JSON.parse(localStorage.getItem('user_data'))?.application_role_id;

    this.applicationHttpService.getRole(appRoleId).subscribe(appRole => {
      this.descendantsAndSelf = appRole.descendants_and_self;

      this.dropdown.parents = appRole.descendants_and_self;
      this.dropdown.children = appRole.descendants;
    });
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }
}
