// China
export const locale = {
  lang: 'ch',
  data: {
  TRANSLATOR: {
    SELECT: '选择你的语言',
  },
  MENU: {
    NEW: '新',
    ACTIONS: '行动',
    CREATE_POST: '创建新帖子',
    PAGES: 'Pages',
    FEATURES: '特征',
    APPS: '应用',
    DASHBOARD: '仪表板',
  },
  AUTH: {
    GENERAL: {
    OR: '要么',
    SUBMIT_BUTTON: '提交',
    NO_ACCOUNT: '没有账号？',
    SIGNUP_BUTTON: '注册',
    FORGOT_BUTTON: '忘记密码',
    BACK_BUTTON: '背部',
    PRIVACY: '隐私',
    LEGAL: '法律',
    CONTACT: '联系',
    },
    LOGIN: {
    TITLE: '创建帐号',
    BUTTON: '签到',
    },
    FORGOT: {
    TITLE: 'Forgotten Password?',
    DESC: 'Enter your email to reset your password',
    SUCCESS: 'Your account has been successfully reset.'
    },
    REGISTER: {
    TITLE: 'Sign Up',
    DESC: 'Enter your details to create your account',
    SUCCESS: 'Your account has been successfuly registered.'
    },
    INPUT: {
    EMAIL: 'Email',
    FULLNAME: 'Fullname',
    PASSWORD: 'Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    USERNAME: '用戶名'
    },
    VALIDATION: {
    INVALID: '{{name}} is not valid',
    REQUIRED: '{{name}} is required',
    MIN_LENGTH: '{{name}} minimum length is {{min}}',
    AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
    NOT_FOUND: 'The requested {{name}} is not found',
    INVALID_LOGIN: 'The login detail is incorrect',
    REQUIRED_FIELD: 'Required field',
    MIN_LENGTH_FIELD: 'Minimum field length:',
    MAX_LENGTH_FIELD: 'Maximum field length:',
    INVALID_FIELD: 'Field is not valid',
    }
  },
  ECOMMERCE: {
    COMMON: {
    SELECTED_RECORDS_COUNT: 'Selected records count: ',
    ALL: 'All',
    SUSPENDED: 'Suspended',
    ACTIVE: 'Active',
    FILTER: 'Filter',
    BY_STATUS: 'by Status',
    BY_TYPE: 'by Type',
    BUSINESS: 'Business',
    INDIVIDUAL: 'Individual',
    SEARCH: 'Search',
    IN_ALL_FIELDS: 'in all fields'
    },
    ECOMMERCE: 'eCommerce',
    CUSTOMERS: {
    CUSTOMERS: '顾客',
    CUSTOMERS_LIST: '客户名单',
    NEW_CUSTOMER: 'New Customer',
    DELETE_CUSTOMER_SIMPLE: {
      TITLE: 'Customer Delete',
      DESCRIPTION: 'Are you sure to permanently delete this customer?',
      WAIT_DESCRIPTION: 'Customer is deleting...',
      MESSAGE: 'Customer has been deleted'
    },
    DELETE_CUSTOMER_MULTY: {
      TITLE: 'Customers Delete',
      DESCRIPTION: 'Are you sure to permanently delete selected customers?',
      WAIT_DESCRIPTION: 'Customers are deleting...',
      MESSAGE: 'Selected customers have been deleted'
    },
    UPDATE_STATUS: {
      TITLE: 'Status has been updated for selected customers',
      MESSAGE: 'Selected customers status have successfully been updated'
    },
    EDIT: {
      UPDATE_MESSAGE: 'Customer has been updated',
      ADD_MESSAGE: 'Customer has been created'
    }
    }
  }
  }
};
