<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Registration Page</h5>
              <span class="modal-x-button" (click)="onCloseDialog()">
                  <i class="fa fa-times" aria-hidden="true"></i>
              </span>
          </div>
          <div class="modal-body">
              <div class="row">
                  <div class="col-xl-12 col-lg-12 col-sm-12 col-md-12 p-0">
                      <section class="col card border-0">
                          <div class="col-12 p-0 card rounded-0">
                            <div class="card-header text-center">
                              Basic Info
                            </div>
                            <div class="row col-xl-12 col-lg-12 col-sm-12 col-md-12 p-0">
                              <section class="col-xl-6 col-lg-6 col-md-12 col-sm-12 pt-4">
                                  <div class="row col-md-12 form-group">
                                      <label class="col-4 kt-form__label col-form-label"> Campaign <span class="text-danger">*</span></label>
                                      <div class="col-6" style="padding:0">
                                          <kt-dropdown-wo-lazyload
                                            [form] = 'form'
                                            [dropdownList] = 'campaignDropdownList'
                                            [dropdownSettings] = 'campaignDropdownSettings'
                                            [formName] = "'campaign_id'"
                                            [selectionAttributes] = "'id'"
                                            [selectedItems] = 'campaignSelectedItems'>
                                          </kt-dropdown-wo-lazyload>
                                      </div>
                                      <div class="col-2 d-flex align-items-center justify-content-center">
                                          <a class="btn btn-brand" style="height: 38px;" href="/marketeer/campaign" routerLinkActive="active" target="_blank" rel="bookmark"> Code </a>
                                      </div>
                                  </div>
                                  <div class="row col-md-12 form-group">
                                      <label class="col-4 kt-form__label col-form-label"> Name <span class="text-danger">*</span></label>
                                      <input type="text" class="col-8 form-control" formControlName="name">
                                  </div>
                                  <div class="row col-md-12 form-group">
                                      <label class="col-4 kt-form__label col-form-label"> Type <span class="text-danger">*</span></label>
                                      <select class="form-control col-8" formControlName="type_id" (change)="onChangeType($event)">
                                          <option value="null" disable hidden>Please Select</option>
                                          <option [value]="item.id" *ngFor="let item of dropdown.types">{{ item.name | titlecase }}</option>
                                        </select>
                                    </div>
                                  <div class="row col-md-12 form-group">
                                      <label class="col-4 kt-form__label col-form-label"> Platform <span class="text-danger">*</span></label>
                                      <select disable class="form-control col-8" formControlName="platform_id">
                                          <option value="null" disable hidden>Please Select</option>
                                          <option [value]="item.id" *ngFor="let item of dropdown.platform">{{ item.name }}</option>
                                      </select>
                                  </div>
                                  <div class="row col-md-12 form-group">
                                      <label class="col-4 kt-form__label col-form-label"> Locale <span class="text-danger">*</span></label>
                                      <select class="form-control col-8" formControlName="settings_locale_id">
                                          <option value="null" disable hidden>Please Select</option>
                                          <option [value]="item.id" *ngFor="let item of dropdown.locales | async">{{ item.code }}</option>
                                      </select>
                                  </div>
                                  <div class="row col-md-12 form-group">
                                      <label class="col-4 kt-form__label col-form-label"> Start Date Time </label>
                                      <div class="col-8 input-group date">
                                          <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack?.start_date_time" [ngModelOptions]="{standalone: true}">
                                          <input type="text" formControlName="start_date_time" class="form-control">
                                          <span class="input-group-append" [owlDateTimeTrigger]="dateStartRef">
                                              <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                                          </span>
                                          <owl-date-time #dateStartRef></owl-date-time>
                                      </div>
                                  </div>
                                  <div class="row col-md-12 form-group">
                                      <label class="col-4 kt-form__label col-form-label"> End Date Time </label>
                                      <div class="col-8 input-group date">
                                          <input class="hidden" [owlDateTime]="dateEndRef" [ngModel]="dateTimeStack?.end_date_time" [ngModelOptions]="{standalone: true}">
                                          <input type="text" formControlName="end_date_time" class="form-control">
                                          <span class="input-group-append" [owlDateTimeTrigger]="dateEndRef">
                                              <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                                          </span>
                                          <owl-date-time #dateEndRef></owl-date-time>
                                      </div>
                                  </div>
                                  <div class="row col-md-12 form-group">
                                      <label class="col-4 kt-form__label col-form-label"> Status <span class="text-danger">*</span></label>
                                      <select class="form-control col-8" formControlName="status">
                                          <option value="null" disable hidden>Please Select</option>
                                          <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">{{ value }}</option>
                                      </select>
                                  </div>
                              </section>
                              <section class="col-xl-6 col-lg-6 col-md-12 col-sm-12 pt-4" *ngIf="form.value.type_id !== null">
                                  <div class="row col-md-12 form-group">
                                      <label class="col-4 kt-form__label col-form-label"> Layout <span class="text-danger">*</span></label>
                                      <select class="form-control col-8" formControlName="layout_id" disabled>
                                          <option [value]="item.id" *ngFor="let item of dropdown.layout">{{ item.name }}</option>
                                      </select>
                                  </div>
                                  <div class="row col-md-12 form-group">
                                      <div class="col-4"></div>
                                      <div class="col-8 layout-wrapper d-flex justify-content-center">
                                          <img [src]="layoutImg" alt="Layout Image" (click)="onViewLayout(layoutImg, form.value.type_id)" [ngClass]="{'mobile': form.value.type_id == 2}">
                                      </div>
                                  </div>
                              </section>
                            </div>
                          </div>
                      </section>
                  </div>

                  <ng-container *ngIf="form.value.type_id !== null">
                      <!-- Section 1 -->
                      <div class="col-xl-6 col-lg-6 col-sm-12 col-md-6 p-0" >
                          <section class="col card border-0 pt-4">
                              <div class="col-12 p-0 card rounded-0">
                                  <div class="card-header">
                                      Section 1: Registration Form
                                  </div>
                                  <div class="row col-md-12 form-group pt-4">
                                      <label class="col-4 kt-form__label col-form-label"> Section Position <span class="text-danger">*</span></label>
                                      <select *ngIf="form.value.type_id == 1" class="form-control col-8" formControlName="registration_form_position">
                                          <option value="null" disable hidden>Please Select</option>
                                          <option [value]="item.id" *ngFor="let item of dropdown.webSectionPosition">{{ item.name }}</option>
                                      </select>
                                      <input type="number" *ngIf="form.value.type_id == 2" class="col-8 form-control" formControlName="registration_form_position">
                                  </div>
                              </div>
                          </section>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-sm-12 col-md-6 p-0" *ngIf="form.value.type_id == 1"></div>

                      <!-- Section 2: Content -->
                      <ng-container formArrayName="containers">
                          <div class="col-xl-6 col-lg-6 col-sm-12 col-md-6 p-0 pt-4" *ngFor="let container of form['controls'].containers['controls']; let ci= index" formGroupName="{{ci}}">
                              <section class="col card border-0" >
                                  <div class="col-12 p-0 card rounded-0">
                                      <div class="card-header">
                                          <ng-container *ngIf="form.value.type_id == 1; else mobileTemplate">
                                              Section 2 {{ ci == 0 ? '(a): Top' : '(b): Bottom' }} Content Container
                                          </ng-container>
                                          <ng-template #mobileTemplate>
                                              Section 2: Content Container
                                          </ng-template>
                                      </div>
                                      <div class="row col-md-12 form-group pt-4">
                                          <label class="col-4 kt-form__label col-form-label"> Section Type <span class="text-danger">*</span></label>
                                          <select class="form-control col-8" formControlName="section_type_id" (change)="onChangeSectionType($event, ci)">
                                              <option value="null" disable hidden>Please Select</option>
                                              <option [value]="item.id" *ngFor="let item of dropdown.sectionType">{{ item.name }}</option>
                                          </select>
                                      </div>
                                      <div class="row col-md-12 form-group" *ngIf="form.value.type_id == 2" >
                                          <label class="col-4 kt-form__label col-form-label"> Section Position <span class="text-danger">*</span></label>
                                          <input type="number" class="col-8 form-control" formControlName="position">
                                      </div>
                                      <ng-container *ngIf="form.value.containers[ci].section_type_id != null">
                                          <div class="row col-md-12 form-group" *ngIf="form.value.containers[ci].section_type_id == 1">
                                              <label class="col-4 kt-form__label col-form-label">Text Content</label>
                                              <div class="col-8 p-0">
                                                  <ckeditor [config]="editorConfig" [editor]="editor" formControlName="text_content"></ckeditor>
                                              </div>
                                          </div>
                                          <ng-container *ngIf="form.value.containers[ci].section_type_id == 3">
                                              <div class="row col-md-12 form-group" >
                                                  <label class="col-4 kt-form__label col-form-label">Title</label>
                                                  <input type="text" class="col-8 form-control" formControlName="title">
                                              </div>
                                              <div class="row col-md-12 form-group">
                                                  <label class="col-4 kt-form__label col-form-label">Content</label>
                                                  <div class="col-8 p-0">
                                                      <ckeditor [config]="getEditorConfig(ci)" [editor]="editor" formControlName="text_content"></ckeditor>
                                                      <div [id]="'wordcount-container-' + ci"></div>
                                                  </div>
                                              </div>
                                          </ng-container>
                                          <div formArrayName="image_icon">
                                              <div class="border-top" *ngFor="let imageIcon of container.controls.image_icon.controls; let i = index" formGroupName="{{i}}">
                                                  <div class="row col-md-12 pt-4 d-flex justify-content-center" style="margin: 0 auto;">
                                                      {{ form.value.containers[ci].section_type_id == 2 ? 'Image' : 'Icon' }} ({{ i+1 }})
                                                  </div>
                                                  <div class="row col-md-12 form-group pt-4">
                                                      <label class="col-4 kt-form__label col-form-label">{{ form.value.containers[ci].section_type_id == 2 ? 'Image' : 'Icon' }} ({{ i+1 }}) </label>
                                                      <label class="col-4 btn btn-default btn-sm image-button">
                                                          <span>Choose file</span>
                                                          <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'content', ci, i)">
                                                      </label>
                                                      <div class="spinner-wrapper ml-4" *ngIf="contentContainer[ci]?.preview.value[i]?.loading">
                                                          <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                                      </div>
                                                      <div class="col-12">
                                                      <em>* File Size: {{ form.value.type_id == 2 ? '105kB' : '213kB' }}; {{ form.value.containers[ci].section_type_id == 2 ? 'Image' : 'Icon' }} Ratio:
                                                            {{
                                                                (form.value.containers[ci].section_type_id == 2 && form.value.type_id == 2)
                                                                  ? '386 x 173'
                                                                  : (form.value.containers[ci].section_type_id == 2 && form.value.type_id == 1)
                                                                  ? '846 x 373'
                                                                  : '90 x 90'
                                                             }}
                                                      </em>
                                                      </div>
                                                  </div>
                                                  <div class="row col-md-12 form-group pt-4" *ngIf="(contentContainer[ci]?.preview.value[i]?.url) && !contentContainer[ci]?.preview.value[i]?.loading">
                                                      <label class="col-4 kt-form__label col-form-label">Preview ({{ i+1 }}) </label>
                                                      <div class="col-8">
                                                          <div class="image-wrapper">
                                                              <img class="img-fluid preview-image" [src]="contentContainer[ci]?.preview.value[i]?.url" [alt]="'Preview (' + i+1 + ')'"  />
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div *ngIf="form.value.containers[ci].section_type_id == 2">
                                                      <div class="row col-md-12 form-group pt-4">
                                                              <label class="col-4 kt-form__label col-form-label"> Image Position</label>
                                                              <input type="number" class="col-8 form-control" formControlName="image_icon_position">
                                                      </div>
                                                      <div class="row col-md-12 form-group pt-4">
                                                          <label class="col-4 kt-form__label col-form-label"> Redirect Link </label>
                                                          <input type="text" class="col-8 form-control" formControlName="image_link"  placeholder="http://www.website.com">
                                                      </div>
                                                      <div class="row col-md-12 form-group pt-4">
                                                          <label class="col-4 kt-form__label col-form-label"> Image Ratio (%) </label>
                                                          <select class="form-control col-8" formControlName="image_ratio">
                                                              <option value="null" disable hidden>Please Select</option>
                                                              <option [value]="item" *ngFor="let item of dropdown.imageRatio">{{ item | percent }}</option>
                                                          </select>
                                                      </div>
                                                      <div class="row col-md-12 form-group pt-4">
                                                          <label class="col-4 kt-form__label col-form-label"> Alignment (Horizontal) </label>
                                                          <select class="form-control col-8" formControlName="image_alignment">
                                                              <option value="null" disable hidden>Please Select</option>
                                                              <option [value]="item.id" *ngFor="let item of (dropdown.alignment).horizontal">{{ item.name }}</option>
                                                          </select>
                                                      </div>
                                                      <div class="row col-md-12 form-group pt-4">
                                                          <label class="col-4 kt-form__label col-form-label"> Alignment (Vertical) </label>
                                                          <select class="form-control col-8" formControlName="image_vertical_alignment">
                                                              <option value="null" disable hidden>Please Select</option>
                                                              <option [value]="item.id" *ngFor="let item of (dropdown.alignment).vertical">{{ item.name }}</option>
                                                          </select>
                                                      </div>
                                                  </div>
                                                  <div *ngIf="form.value.containers[ci].section_type_id == 3">
                                                      <div class="row col-md-12 form-group pt-4">
                                                              <label class="col-4 kt-form__label col-form-label"> Icon Position</label>
                                                              <input type="number" class="col-8 form-control" formControlName="image_icon_position">
                                                      </div>
                                                      <div class="row col-md-12 form-group pt-4">
                                                          <label class="col-4 kt-form__label col-form-label"> Icon Title </label>
                                                          <input type="text" class="form-control col-8" formControlName="icon_title">
                                                      </div>
                                                      <div class="row col-md-12 form-group pt-4">
                                                          <label class="col-4 kt-form__label col-form-label"> Short Description </label>
                                                          <input class="form-control col-8" formControlName="description">
                                                      </div>
                                                  </div>
                                                  <div class="row col-md-12 form-group pt-4">
                                                      <label class="col-4 kt-form__label col-form-label">Remove </label>
                                                      <button class="btn btn-danger" (click)="deleteImageIcon(ci, i)">Remove This {{ form.value.containers[ci].section_type_id == 3 ? 'Icon' : 'Image' }}</button>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="border-top" *ngIf="form.value.containers[ci].section_type_id != 1">
                                              <div class="row col-md-12 form-group pt-4">
                                                  <label class="col-4 kt-form__label col-form-label">Add On </label>
                                                  <button class="btn btn-brand" (click)="onAddImageIcon(ci)">Add More {{ form.value.containers[ci].section_type_id == 3 ? 'Icon' : 'Image' }}</button>
                                              </div>
                                          </div>
                                  </ng-container>
                                  </div>
                              </section>
                          </div>
                      </ng-container>

                      <!-- Section 3: Banners -->
                      <ng-container formArrayName="banners">
                          <div class="col-xl-6 col-lg-6 col-sm-12 col-md-6 p-0 pt-4" *ngFor="let banner of form['controls'].banners['controls']; let bi = index" formGroupName="{{bi}}">
                              <section class="col card border-0" >
                                  <div class="col-12 p-0 card rounded-0">
                                      <div class="card-header">
                                          Section {{ bi == 0 ? '3: Top' : '4: Bottom' }} Banner
                                      </div>
                                      <div class="row col-md-12 form-group pt-4">
                                          <label class="col-4 kt-form__label col-form-label"> Section Type </label>
                                          <select class="form-control col-8" formControlName="section_type_id" disabled>
                                              <option [value]="item.id" *ngFor="let item of dropdown.bannerType">{{ item.label }}</option>
                                          </select>
                                      </div>
                                      <div formArrayName="images">
                                          <div class="border-top" *ngFor="let image of banner.controls.images.controls; let i = index" formGroupName="{{i}}">
                                              <div class="row col-md-12 pt-4 d-flex justify-content-center" style="margin: 0 auto;">
                                                  Image ({{ i+1 }})
                                              </div>
                                              <div class="row col-md-12 form-group pt-4">
                                                  <label class="col-4 kt-form__label col-form-label">Image </label>
                                                  <label class="col-4 btn btn-default btn-sm image-button">
                                                      <span>Choose file</span>
                                                      <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'banner', bi, i)">
                                                  </label>
                                                  <div class="spinner-wrapper ml-4" *ngIf="bannerForm[bi]?.preview.value[i]?.loading">
                                                      <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                                  </div>
                                                  <div class="col-12">
                                                      <em>* File Size: {{ form.value.type_id == 2 ? '105kB' : '213kB' }}; Banner Ratio:
                                                          {{
                                                              form.value.type_id == 2
                                                                ? '414 x 173'
                                                                : bi == 0
                                                                ? '1920 x 400'
                                                                : '1920 x 470'
                                                          }}
                                                      </em>
                                                  </div>
                                              </div>
                                              <div class="row col-md-12 form-group pt-4" *ngIf="(bannerForm[bi]?.preview.value[i]?.url) && !bannerForm[bi]?.preview.value[i]?.loading">
                                                  <label class="col-4 kt-form__label col-form-label">Preview ({{ i+1 }}) </label>
                                                  <div class="col-8">
                                                      <div class="image-wrapper">
                                                          <img class="img-fluid preview-image" [src]="bannerForm[bi]?.preview.value[i]?.url" [alt]="'Preview (' + i+1 + ')'" />
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="row col-md-12 form-group pt-4">
                                                      <label class="col-4 kt-form__label col-form-label"> Image Position</label>
                                                      <input type="number" class="col-8 form-control" formControlName="position">
                                              </div>
                                              <div class="row col-md-12 form-group pt-4">
                                                  <label class="col-4 kt-form__label col-form-label"> Redirect Link </label>
                                                  <input type="text" class="col-8 form-control" formControlName="image_link"  placeholder="http://www.website.com">
                                              </div>
                                                  <div class="row col-md-12 form-group pt-4">
                                                  <label class="col-4 kt-form__label col-form-label"> Image Ratio (%) <span class="text-danger">*</span></label>
                                                  <select class="form-control col-8" formControlName="image_ratio">
                                                      <option value="null" disable hidden>Please Select</option>
                                                      <option [value]="item" *ngFor="let item of dropdown.imageRatio">{{ item | percent }}</option>
                                                  </select>
                                              </div>
                                              <div class="row col-md-12 form-group pt-4" *ngIf="bi != 0">
                                                  <label class="col-4 kt-form__label col-form-label"> Alignment </label>
                                                  <select class="form-control col-8" formControlName="alignment">
                                                      <option value="null" disable hidden>Please Select</option>
                                                      <option [value]="item.id" *ngFor="let item of (dropdown.alignment).horizontal">{{ item.name }}</option>
                                                  </select>
                                              </div>
                                              <div class="row col-md-12 form-group pt-4">
                                                  <label class="col-4 kt-form__label col-form-label">Remove </label>
                                                  <button class="btn btn-danger" (click)="deleteBannerImage(bi, i)">Remove This Image</button>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="border-top">
                                          <div class="row col-md-12 form-group pt-4">
                                              <label class="col-4 kt-form__label col-form-label">Add On </label>
                                              <button class="btn btn-brand" (click)="onAddBannerImage(bi)">Add More Image</button>
                                          </div>
                                      </div>
                                  </div>
                              </section>
                          </div>
                      </ng-container>
                  </ng-container>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                  <kt-submit-button *ngIf="canEditRegistrationPage" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.mode)"></kt-submit-button>
              </div>
          </div>
      </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true)"></kt-swal-alert>

<!-- to show char limit err msg without closing entire edit dialog -->
<kt-swal-alert [message]="messages2$ | async" [icon]="'error'"></kt-swal-alert>
