import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PromotionApplicationStatus } from '@core/enums/promotion-application.enum';
import { Pagination } from '@core/models/pagination.model';
import { PromotionApplication } from '@core/models/promotion-application.model';
import { PromotionContent } from '@core/models/promotion-content.model';
import { Rewards } from '@core/models/rewards.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MemberInformationDialogComponent } from '@views/pages/apps/general/members/dialogs/member-information/member-information.component';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MemberDataService } from '../../views/pages/apps/general/members/services/member-data.service';
import { PromotionApplicationDataService } from '../../views/pages/apps/general/promotion-application/services/promotion-application-data.service';
import { PromotionApplicationEntityService } from '../../views/pages/apps/general/promotion-application/services/promotion-application-entity.service';
import { PromotionContentDataService } from '../../views/pages/apps/general/promotion-contents/services/promotion-content-data.service';
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';
import { AppPermissionService } from '@core/services/app-permission.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-promotion-application-shared',
  templateUrl: './promotion-application-shared.component.html',
  styleUrls: ['./promotion-application-shared.component.scss']
})
export class PromotionApplicationSharedComponent implements OnInit, OnDestroy {

  form: FormGroup;
  checkboxForm: FormGroup;

  dropdownSettings = {};
  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };

  status = PromotionApplicationStatus;
  promotionApplications$: Observable<PromotionApplication[]>;
  promotionContents$: Observable<PromotionContent[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  ranges: any;

  toggleAll: boolean = false;
  selectedPromotionApplication = [];
  availablePromotionApplication = [];
  action: string;
  text: string;
  promotion_code: string;
  promotionContentDropdownSettings = {};
  promotionContentDropdownList = [];
  promotionContentSelectedItems = [];
  dataLength = 0;

  messages$ = this.promotionApplicationDataService.messages$;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  selectedUsername: string[] = [];
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  @Input()
  promotionContent: PromotionContent;

  @Input()
  isModal: boolean;

  // permissions
  canViewMemberDialog: boolean;
  canApproveRejectPromotionApplication: boolean;
  canPromotionContentApproveRejectPromotionApplication: boolean;

  constructor(
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private memberDataService: MemberDataService,
    private promotionApplicationEntityService: PromotionApplicationEntityService,
    private promotionApplicationDataService: PromotionApplicationDataService,
    private promotionContentDataService: PromotionContentDataService,
    private eventEmitterService: EventEmitterService,
    private fb: FormBuilder,
    private authHttpService: AuthHttpService,
    private timeZoneDate: TimezoneDatePipe,
    private appPermissionService: AppPermissionService,
    private translateService: TranslateService,
  ) { }

  private datePickerSubscription = new Subscription();
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  ngOnInit() {
    this.formInit();
    this.onSubmit(true);
    this.pagination = this.promotionApplicationDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.promotionContentDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'code',
      noDataLabel: '',
      showCheckbox: false
    };

    const paramSuffix = this.isModal ? `&language=${this.promotionContent.language}` : '';

    this.promotionContentDataService.dropdown(`?paginate=false${paramSuffix}`).subscribe(res => {
      res.map(elm => {
        elm['code'] = elm.code;
      });
      this.promotionContentDropdownList = res;
      this.promotionContentDropdownList.unshift({
        id: 'all',
        code: 'All'
      });
      this.promotionContentSelectedItems = [this.promotionContentDropdownList.find(x => x.id === 'all')];
      if (!this.form.touched && this.isModal) {
        this.form.patchValue({
          promotion_content_id: this.promotionContent.id
        });
        this.params += `&promotion_content_id=${this.promotionContent.id}`;
      }
    })

    this.accountTypeDropdownList = this.dropdownHttpService.accountTypesLists;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];

    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.promotion_applications_view_member_dialog;
      this.canApproveRejectPromotionApplication = appPermissions.approve_reject_promotion_application;
      this.canPromotionContentApproveRejectPromotionApplication = appPermissions.promotion_contents_approve_reject_promotion_application;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialog.closeAll();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.promotionApplications$ = this.promotionApplicationEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.promotionApplicationDataService.pagination;
        this.dataLength = res.length;
        this.loading = false;
        this.toggleAll = false;
        this.selectedPromotionApplication = [];
        this.availablePromotionApplication = res;
        this.checkboxFormInit();

        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, id: number) {
    switch (type) {
      case 'member-information':
        if (!this.canViewMemberDialog) {
          Swal.fire('Permission Error', 'You do not have permission to view member information', 'error');
          return;
        }

        const member = this.memberDataService.getById(id, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
        this.subscription = member.pipe(
          tap((res) => {
            if (res) {
              this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' });
            }
          })
        )
          .subscribe();
        break;
    }

  }


  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        exhaustMap((data) => {
          if (data['start_date']) {
            data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
            data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          }
          if(data['account_type']){
            data['account_type'] = this.form.controls['account_type'].value.toString();
          }

          this.params = Object.keys(data).map((key) => key + "=" + data[key]).join("&");
          let parameter = this.params ? "&" + this.params : "";

          if (!this.form.touched && this.isModal) {
            parameter += `&promotion_content_id=${this.promotionContent.id}`;
          }

          this.loadingBar.start();

          return (this.promotionApplications$ = this.promotionApplicationEntityService
            .getWithQuery(`?perPage=${this.pageSize}${parameter}`)
            .pipe(
              tap((res) => {
                this.toggleAll = false;
                this.selectedPromotionApplication = [];
                this.availablePromotionApplication = res;
                this.checkboxFormInit();
                this.page = 1;
                this.pagination = this.promotionApplicationDataService.pagination;
                this.dataLength = res.length;
                this.loading = false;
                this.clearBtnLoading = false;
                this.searchBtnLoading = false;
                this.loadingBar.complete();
              })
            ));
        })
      )
      .subscribe();
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.promotionContentSelectedItems = [this.promotionContentDropdownList.find(x => x.id === 'all')];
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    this.form.patchValue({
      username: null,
      promotion_content_id: this.isModal ? this.form.value.promotion_content_id : 'all',
      status: 'all',
      defaultDate: null,
      account_type_condition: 'Or',
      account_type: [1, 2, 3]
    });
    this.onSubmit(true);
  }

  onReload(e: Event) {
    this.onViewPageBy(this.page);
  }

  checkboxToggleAll() {
    this.selectedUsername = []; // Reset
    let patchValue = {};
    if (this.toggleAll) {
      this.availablePromotionApplication.forEach(item => {
        patchValue = { ...patchValue, [item.id]: true };
        this.selectedUsername.push(item.username);
      });
    } else {
      this.availablePromotionApplication.forEach(item => {
        patchValue = { ...patchValue, [item.id]: false };
      });
    }
    this.checkboxForm.patchValue(patchValue);
  }

  onChangeStatus(id: number[], statusCode: number, username: string) {
    this.action = statusCode == 1 ? 'approve' : 'reject';
    this.text = id.length == 1 ? 'Are you sure you want to ' + this.action + ' ' + this.selectedUsername[0] + '\'s application?' : 'Are you sure you want to ' + this.action + ' ' + id.length + ' application(s)?';
    Swal.fire({
      title: 'Are you sure?',
      text: this.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.subscription = this.promotionApplicationDataService.updateStatus(id, statusCode).subscribe(res => {
          this.messages$ = this.promotionApplicationDataService.messages$
        });
      }
    });
  }

  onCheck(row: PromotionApplication, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      this.selectedUsername.push(row.username);
    } else {
      this.selectedUsername.splice(this.selectedUsername.indexOf(row.username), 1);
    }
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any, isAdmin?: boolean, reward?: Rewards }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'member-information' ? '1500px' : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data,
      autoFocus: data.mode === 'member-information' ? false : true
    });
    if (data.mode !== 'member-information') {
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.onViewPageBy(this.page);
        }
      });
    }
  }

  private formInit() {
    this.promotion_code = this.promotionContent ? this.promotionContent.code : '';

    this.form = new FormGroup({
      username: new FormControl(null),
      promotion_content_id: new FormControl('all'),
      promotion_content_code: new FormControl(this.promotion_code),
      status: new FormControl('all'),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      account_type_condition: new FormControl('Or'),
      account_type: new FormControl([1, 2, 3])
    });
  }

  private checkboxFormInit() {
    let prepareForm = {};
    this.availablePromotionApplication.forEach(item => {
      prepareForm = { ...prepareForm, [item.id]: false }
    });
    this.checkboxForm = this.fb.group(prepareForm);

    this.checkboxForm.valueChanges.subscribe(val => {
      this.selectedPromotionApplication = [];
      Object.keys(val).forEach(key => {
        if (val[key]) {
          this.selectedPromotionApplication.push(key);
        }
      })
    })
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all' && key !== 'promotion_content_code') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  timezoneDate(date: any, format: any) {
    return this.timeZoneDate.transform(date, format);
  }
}
