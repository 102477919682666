<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div  class="kt-portlet kt-portlet--mobile">
      <div  class="kt-portlet__body">
          <div class="kt-form kt-form--label-right">
            <div class="row align-items-center">
                <div class="col-xl-12 order-2 order-xl-1 pr-0">
                    <form class="row align-items-center" [formGroup]="form">
                        <div class="col-12 row mb-2 pr-0 filter-row">
                            <!-- new -->
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>{{ 'ID' | translate }}:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                                <input type="number" formControlName="id" placeholder="{{ 'Search' | translate }}" class="form-control">
                            </div>
                            <div class="col-md-1 kt-form__label col-form-label">
                              <label>{{ 'Name' | translate }}:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                              <input type="text" formControlName="name" placeholder="{{ 'Search' | translate }}" class="form-control">
                            </div>
                            <div class="col-md-1 kt-form__label col-form-label">
                              <label>{{ 'Description' | translate }}:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                              <input type="text" formControlName="description" placeholder="{{ 'Search' | translate }}" class="form-control">
                            </div>
                            <div class="col-md-1 kt-form__label col-form-label">
                              <label>{{ 'Status' | translate }}:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                                <select  class="form-control" formControlName="status">
                                    <option value="all">{{ 'All' | translate }}</option>
                                    <ng-container *ngFor="let value of dropdown.statuses; let i = index;">
                                        <option [value]="i">{{ value | translate }}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 row mb-2 pr-0 filter-row">
                            <div class="col-md-1 kt-form__label col-form-label">
                              <label>{{ 'Currencies' | translate }}:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                              <kt-dropdown-wo-lazyload
                                  [dropdownList] = "currencyDropdownList"
                                  [dropdownSettings] = "currencyDropdownSettings"
                                  [form] = "form"
                                  [selectionAttributes] = "'id'"
                                  [formName] = "'currency_id'"
                                  [selectedItems]="currencySelectedItems"
                                  (selectedItemsChanged)="currencySelectedItems = $event"
                              >
                              </kt-dropdown-wo-lazyload>
                            </div>

                            <div class="col-md-1 kt-form__label col-form-label">
                              <label>{{ 'Promotion' | translate }}:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                              <input type="text" formControlName="promotion" placeholder="{{ 'Search' | translate }}" class="form-control" [ngbTypeahead]="this.searchPromo" [resultFormatter]="this.formatPromo" [inputFormatter]="this.formatPromo">
                            </div>

                            <div class="col-md-1 kt-form__label col-form-label">
                              <label>{{ 'Inactivity Criteria' | translate }}:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                                <select  class="form-control" formControlName="depositor">
                                    <option [value]="null" [disabled]="true">{{ 'Please Select' | translate }}</option>
                                    <ng-container *ngFor="let value of dropdown.depositorStatus; let i = index;">
                                        <option [value]="i">{{ value | translate }}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 row mb-2 pr-0 filter-row">
                          <div class="col-md-1 kt-form__label col-form-label">
                            <label>Date/Time:</label>
                          </div>
                          <div class="col-md-5 kt-form__control">
                            <div class="input-group date">

                              <select formControlName="datetime_type" class="form-control" style="width: 30%;">
                                  <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                              </select>

                              <input type="hidden" formControlName="start_datetime" >
                              <input type="hidden" formControlName="end_datetime" >

                              <div class="input-group date" style="width: 70%;">
                                  <input type="text" class="form-control" placeholder="Search"
                                  (change)="onDateRange($event)" [formControl]="form.controls['defaultDate']"
                                  [timePicker]="true" [timePickerSeconds]="true"
                                  [alwaysShowCalendars]="true" [ranges]="ranges"
                                  [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                  [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()"
                                  ngxDaterangepickerMd
                                  />
                                  <span class="input-group-append">
                                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                  </span>
                              </div>
                            </div>
                          </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
          <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
              <div class="dropdown dropdown-inline">
                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
              </div>
              <button *ngIf="canCreateRecoveryPromotion" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
          </div>
          <div  class="kt-section">
              <div  class="kt-section__content">
                  <div  class="table-responsive">
                      <table  class="table table-bordered table-hover table-striped">
                          <thead>
                            <tr class="text-top">
                                <th rowspan="2" class="text-center">ID</th>
                                <th rowspan="2" class="text-center">Name</th>
                                <th rowspan="2" class="text-center">Description</th>
                                <th rowspan="2" class="text-center">Inactivity Criteria</th>
                                <th rowspan="2" class="text-center">Currencies</th>
                                <th rowspan="2" class="text-center">Promotion</th>
                                <th rowspan="2" class="text-center">Message Template</th>
                                <th rowspan="2" class="text-center">Status</th>
                                <th rowspan="2" class="text-center">Created By</th>
                                <th rowspan="2" class="text-center">Updated By</th>
                                <th rowspan="2" class="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of recoveryPromotions$ | async">
                              <td>{{ row.id }} </td>
                              <td>{{ row.name }}</td>
                              <td>{{ row.description != null ? row.description : '-' }}</td>
                              <td>
                                <span class="fas" [ngClass]="row.depositor > 0 ? 'fa-check kt-font-info' : 'fa-times kt-font-danger'"></span> Depositor<br>
                                <b>Days: </b>{{ row.inactivity }}
                              </td>
                              <td class="text-center">{{ row.currencies }}</td>
                              <td>{{ row.promotion_code != null ? row.promotion_code : '-' }}<br>{{ row.promotion_name ? '('+row.promotion_name+')' : '-'}}</td>
                              <td>
                                <span class="fas" [ngClass]="row.sms_templates != null && row.sms_templates.length > 0 ? 'fa-check kt-font-info' : 'fa-times kt-font-danger'"></span> SMS<br>
                                <span class="fas" [ngClass]="row.inbox_templates != null && row.inbox_templates.length > 0 ? 'fa-check kt-font-info' : 'fa-times kt-font-danger'"></span> Inbox Message
                              </td>
                              <td class="text-center">
                                  <div class="btn-group dropdown">
                                    <button type="button" class="btn btn-pill btn-sm dropdown-base"  [ngClass]="'kt-badge--' + row.status"> {{ dropdown.statuses[row.status] }} </button>
                                    <ng-container *ngIf="(canEditRecoveryPromotion || canUpdateRecoveryPromotionStatus)">
                                        <button type="button" class="btn btn-pill btn-sm dropdown-toggle" [ngClass]="'kt-badge--' + row.status" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-angle-down"></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <ng-container *ngFor="let key of object.keys(dropdown.statuses)">
                                                <button class="dropdown-item" type="button" (click)="onChangeStatus(row, key)" *ngIf="+key >= 0">{{ dropdown.statuses[key] }}</button>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                              </td>
                              <td [matTooltip]="row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss'">
                                {{ row.created_by != null ? row.created_by : '-' }}<br/>
                                {{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                              </td>
                              <td [matTooltip]="row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss'">
                                {{ row.updated_by != null ? row.updated_by : '-' }}<br/>
                                {{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                              </td>
                              <td class="text-center">
                                <button *ngIf="canEditRecoveryPromotion" matTooltip="Recovery Settings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-cog"></i></button>
                                <button *ngIf="canDuplicateRecoveryPromotion" matTooltip="Duplicate" [disabled]="buttonLoading" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onDuplicate(row)"><i class="fas fa-copy"></i></button>
                              </td>
                            </tr>
                            <kt-fallback-row [collection]="recoveryPromotions$"></kt-fallback-row>
                          </tbody>
                          <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                      </table>
                  </div>
                  <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                    <ngb-pagination
                      [pageSize]="pageSize"
                      [(page)]="page"
                      [maxSize]="maxSize"
                      [directionLinks]="true"
                      [boundaryLinks]="true"
                      [rotate]="true"
                      [collectionSize]="pagination.total"
                      (pageChange)="onViewPageBy(page)">
                    </ngb-pagination>
                    <div class="kt-pagination__toolbar">
                        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                        </select>
                        <span class="pagination__desc">
                          Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                        </span>
                    </div>
                  </div>
              </div>
          </div>
          <!-- table ends -->
      </div>
  </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onReload()"></kt-swal-alert>
