import { SampleMessage } from '@core/models/sample-message.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class SampleMessageEntityService extends EntityCollectionServiceBase<SampleMessage> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('SampleMessage', serviceElementsFactory);
  }
}
