import { SubheaderService } from '@core/_base/layout';
import { Component, OnInit } from '@angular/core';
import { Token } from '@core/models/token.model';
import { environment } from '@env/environment';

@Component({
  selector: 'kt-system-error-logs',
  templateUrl: './system-error-logs.component.html',
  styleUrls: ['./system-error-logs.component.scss']
})
export class SystemErrorLogComponent implements OnInit {

  errorLogResource: string;

  constructor(
    private subheaderService: SubheaderService,
  ) { }

  ngOnInit() {
    const {
      access_token,
      plaintext_token
    }: Token = JSON.parse(localStorage.getItem('user_token')) || { access_token: undefined, plaintext_token: undefined };


    // TODO: Might need to store ENV file
    this.errorLogResource = environment.apiBase  + '/report/errorlog/'+`${access_token}`+'/'+ `${plaintext_token}`;
  }

}
