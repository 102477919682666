import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { AccountManagement } from '@core/models/account-management.model';

@Injectable()
export class AccountManagementEntityService extends EntityCollectionServiceBase<AccountManagement> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('AccountManagement', serviceElementsFactory);
    }
}

