<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Create Rewards Settings</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6 form-group">
                <label class="control-label">Name <span class="text-danger">*</span></label>
                <input type="text" formControlName="name" class="col-12 form-control">
            </div>
            <div class="col-md-6 form-group">
                <label class="control-label">Reward Type <span class="text-danger">*</span></label>
                <select class="form-control" formControlName="reward_type_id" (change)="onSelectType($event)">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <ng-container *ngFor="let item of types | keyvalue">
                      <option [value]="item.key" *ngIf="+item.key >= 0">{{ item.value }}</option>
                    </ng-container>
                </select>
            </div>
            <ng-container *ngIf="selectedType === 2">
              <div class="col-md-6 form-group">
                  <label class="control-label">Gift Name <span class="text-danger">*</span></label>
                  <input type="text" formControlName="gift_name" class="col-12 form-control">
              </div>
              <div class="col-md-6 form-group">
                  <label class="control-label">Total Count <span class="text-danger">*</span></label>
                  <input type="number" (wheel)="false" formControlName="gift_total_count" min="1" class="col-12 form-control">
              </div>
            </ng-container>
            <ng-container *ngIf="selectedType === 1">
              <div class="col-md-6 form-group mb-0">
                <label class="control-label">Promotion Code  <span class="text-danger">*</span></label>
                <kt-dropdown-wo-lazyload
                [form] = 'form'
                [dropdownList] = 'promotionCodeDropdownList'
                [dropdownSettings] = 'promotionCodeDropdownSettings'
                [formName] = "'promotion_id'"
                [selectionAttributes] = "'id'"
                [selectedItems] = 'promotionCodeSelectedItems'>
                </kt-dropdown-wo-lazyload>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button [isDisabled]="!form.valid || (!form.value.promotion_id && selectedType === 1) || ((!form.value.gift_name || !form.value.gift_total_count) && selectedType === 2)" [buttonLoading]="false" (confirmed)="onSave()"></kt-submit-button>
        </div>
    </div>
  </form>
</div>
