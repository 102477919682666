<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Parlay Data: {{ data.username }} <span class="p-lr-8"> | </span> {{ data.ticket_id }}</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>

    <div class="modal-body">
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-striped">
          <thead>
            <tr *ngIf="data.info !== null">
              <th class="table-col-max-w" *ngFor="let header of headers">{{ header | titlecase }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="data.info !== null; else noData">
              <tr *ngFor="let row of data.info">
                <ng-container *ngFor="let param of parameter">
                  <td class="table-col-max-w">
                    <span [innerHTML]="onFormatLog(row[param])"></span>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
            <ng-template #noData>
              <td class="text-center">No data available</td>
            </ng-template>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
      </div>
    </div>
  </div>
</div>