import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors  } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthHttpService } from '@core/services/auth-http.service';
import { tap, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { REGEX_PATTERNS } from '@utils/regex.constants';

@Component({
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordDialogComponent implements OnInit {

    @Input()
    username: string;

    form: FormGroup;
    showConfirmPasswordHint = false;
    passwordCheck = {
        contain_numbers: false,
        contain_uppercase: false,
        contain_lowercase: false,
        contain_special_character: false,
        is_valid_character: true,
    };
    loading = false;

    constructor(
        public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
        private fb: FormBuilder,
        private auth: AuthHttpService,
        @Inject(MAT_DIALOG_DATA) public data: { username: string },
    ) { }

    ngOnInit() {
        this.initForm();
    }

    onCheckPasswordFormat(password: any) {
        // Regex check for the guidance
        this.passwordCheck = {
            contain_numbers: REGEX_PATTERNS.contain_numbers.test(password),
            contain_uppercase: REGEX_PATTERNS.contain_uppercase.test(password),
            contain_lowercase: REGEX_PATTERNS.contain_lowercase.test(password),
            contain_special_character: REGEX_PATTERNS.contain_special_character.test(password),
            is_valid_character: REGEX_PATTERNS.is_valid_character.test(password),
        };

        // Matching of confirm_password field
        this.showConfirmPasswordHint = false;
        if( this.form.value.confirm_password !== null && this.form.value.confirm_password !== '' ) {
            if( this.form.value.confirm_password !== this.form.value.password ) {
                this.showConfirmPasswordHint = true;
            }
        }
    }

    onCloseDialog(updated: boolean = false) {
        this.dialogRef.close(updated);
    }

    onSubmit() {
        this.loading = true;
        if( !this.form.valid || this.showConfirmPasswordHint ) {
            this.loading = false;
            return;
        }

        this.form.disable();
        this.auth.resetPassword({
            password: this.form.value.password, 
            password_confirmation: this.form.value.confirm_password, 
            username: this.data.username
        }).pipe(
            tap((res) => {
                Swal.fire({
                    title: 'Reset Password', 
                    text: res.message, 
                    icon: 'success'
                }).then( result => {
                    if (result.isConfirmed) {
                        this.onCloseDialog(true);
                    }
                })
                this.form.enable();
                this.loading = false;
            }),
            catchError((error) => {
                this.form.enable();
                this.loading = false;
                throw error;
            })
        ).subscribe();
    }

    initForm() {
        this.form = this.fb.group({
            password: [
                null,
                Validators.compose([
                    Validators.required,
                    // at least 8 characters long, contain uppercase and lowercase letters, a number, and a special character available on a standard keyboard
                    Validators.pattern(REGEX_PATTERNS.password),
                ],)
            ],
            confirm_password: [
                null,
                [Validators.required]
            ],
        });
    }
}
