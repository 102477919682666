<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div  class="kt-portlet kt-portlet--mobile">
      <div  class="kt-portlet__body">
          <div  class="kt-section">
              <div  class="kt-section__content">
                  <div   class="kt-portlet__body">
                      <div  class="kt-section">
                          <div  class="kt-section__content">
                              <div  class="row col-lg-12">
                                  <div  class="col-lg-5">
                                      <div   class="kt-margin-b-20-tablet-and-mobile">
                                          <div  class="kt-input-icon kt-input-icon&amp;#45;&amp;#45;left">
                                              <form >
                                                  <div >
                                                      <label> Search By Username:
                                                          <input  type="text" class="form-control" style="width: 260px;">
                                                      </label>
                                                      <ul  id="autocomplete-results" class="autocomplete-results" style="display: none;"></ul>
                                                  </div>
                                              </form>
                                          </div>
                                      </div>
                                  </div>

                              </div>
                          </div>
                      </div>
                  </div>
                  <div  class="table-responsive">
                      <div  class="row col-sm-12">
                          <table  class="table col-md-6" style="table-layout: fixed; border-style: groove;">
                              <thead>
                                  <tr>
                                      <th  colspan="2">Account Balance</th>
                                  </tr>
                              </thead>
                              <tbody  class="text-right">
                                  <tr>
                                      <td >Cash Wallet:</td>
                                      <td  class="text-left">0</td>
                                  </tr>
                                  <tr>
                                      <td >Transfer Wallet:</td>
                                      <td  class="text-left">0.00</td>
                                  </tr>
                              </tbody>
                          </table>
                          <table  class="table col-md-6" style="table-layout: fixed; border-style: groove;">
                              <thead>
                                  <tr>
                                      <th  colspan="2">Deposit/Withdrawal</th>
                                  </tr>
                              </thead>
                              <tbody  class="text-right">
                                  <tr>
                                      <td >Total Deposit:</td>
                                      <td  class="text-left">0.00</td>
                                  </tr>
                                  <tr>
                                      <td >Total Withdrawal:</td>
                                      <td  class="text-left">0.00</td>
                                  </tr>
                                  <tr>
                                      <td >Total Bonus:</td>
                                      <td  class="text-left">0.00</td>
                                  </tr>
                                  <tr>
                                      <td >Total Adjustment:</td>
                                      <td  class="text-left">0.00</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <table  class="table table-bordered table-hover">
                          <thead>
                              <tr>
                                  <th >Category</th>
                                  <th  colspan="2" class="text-center">W/L</th>
                                  <th  class="text-center">Commission</th>
                                  <th  class="text-center">Total</th>
                              </tr>
                          </thead>
                          <tbody>




                              <tr  class="text-right" style="background: rgb(219, 219, 219);">
                                  <td  colspan="4">Total</td>
                                  <td  class="kt-font-success"><strong>0.00</strong></td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
