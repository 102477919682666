import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { PromotionMember } from '@core/models/promotion-member.model';

@Injectable()
export class PromotionMemberListDataService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  data$ = new Subject<any[]>();

  constructor(private http: HttpClient) { }

  addMember(member: any):Observable<any>{
    return this.http.post<ApiResponse>(`/promotion/member`, member).pipe(
      tap(res => {
        res.data['custom_promotion_member_swal'] = true;
        this.messages$.next(res.message);

        // For exist records
        this.data$.next(res.data);
      })
    );
  }

  addAffiliate(affiliate: any):Observable<any>{
    return this.http.post<ApiResponse>(`/promotion/affiliate`, affiliate).pipe(
      tap(res => {
        res.data['custom_promotion_member_swal'] = true;
        this.messages$.next(res.message);

        // For exist records
        this.data$.next(res.data);
      })
    );
  }

  deleteMember(promotionMemberId: number) {
    return this.http.delete<ApiResponse>(`/promotion/member/${promotionMemberId}`).pipe(
      tap( res => this.messages$.next(res.message)),
    );
  }

  deleteAffiliate(promotionAffiliateId: number) {
    return this.http.delete<ApiResponse>(`/promotion/affiliate/${promotionAffiliateId}`).pipe(
      tap( res => this.messages$.next(res.message)),
    );
  }

  getAffiliateWithQuery(pageParam: string): Observable<PromotionMember[]>{
    return this.http.get<ApiResponse>(`/promotion/affiliate${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  getWithQuery(pageParam: string): Observable<PromotionMember[]>{
    return this.http.get<ApiResponse>(`/promotion/member${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }

  resetData() {
    this.data$.next(null);
  }
}
