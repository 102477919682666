import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { Dialog } from '@core/models/dialog.model';

@Injectable()
export class DialogDataService extends DefaultDataService<Dialog>{
  pagination: Pagination;
  messages$ = new Subject<any[]>();
  data$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Dialog', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<Dialog[]> {
    return this.http.get<ApiResponse>(`/popups${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => {
        let data = res.data.rows != undefined || res.data.rows != null ? res.data.rows : res.data;
        Object.keys(data).forEach(x => {
          let localeList = [];

          Object.keys(data[x].contents).forEach(y => {
            if (data[x].contents[y].content || data[x].contents[y].title || data[x].contents[y].media_type) {
              localeList.push(data[x].contents[y].locale_name);
            }
          })
          data[x].locale_list = localeList.toString().split(',').join(', ');
        })
        return data
      }
    ));
  }

  addDialog(param: any) {
    return this.http.post<ApiResponse>(`/popups`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  updateDialog(id: number, param: any) {
    return this.http.put<ApiResponse>(`/popups/${id}`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  updateStatus(param: any) {
    return this.http.put<ApiResponse>(`/popups/statusupdate`, param).pipe(
      tap(res => {
        this.messages$.next(res.message);

        // For linkage records
        this.data$.next(res.data);
      })
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}