import { catchError, tap } from 'rxjs/operators';
import { CampaignEntityService } from '@views/pages/apps/marketeer/campaign/services/campaign-entity.service';
import { CampaignDataService } from '../services/campaign-data.service';
import { Subscription, forkJoin } from 'rxjs';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Campaign } from '@core/models/campaign.model';
import { Dropdown } from '@core/models/dropdown.model';
import { MessageTemplateDataService } from '@views/pages/apps/superuser/message-template/services/message-template-data.service';
import { PromotionCodeDataService } from '@views/pages/apps/general/promotion-codes/services/promotion-code-data.service';
import { MatTooltip } from '@angular/material/tooltip';
import { DialogDataService } from '@views/pages/apps/settings/announcements/dialog/services/dialog-data.service';
@Component({
  selector: 'kt-campaign-edit',
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaign-edit.component.scss']
})
export class CampaignEditDialogComponent implements OnInit, OnDestroy {
  @ViewChild('tooltipCode') tooltipCode: MatTooltip;

  form: FormGroup;
  dropdown = {
    statuses:  this.dropdownHttpService.statuses,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    rewardStatus: this.dropdownHttpService.campaignRewardStatus,
    groups: this.dropdownHttpService.groups,
    messagetemplates: this.messageTemplateDataService.getMessageTemplateList(1, 11) // Get message templates with type = 1 (Message) and section = 1 (Member Test)
  };
  buttonLoading = false;
  autoAssignPromo: boolean;
  selectedCurrency: any;
  messages$ = this.campaignDataService.messages$;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  refreshStatus: boolean;

  promotionCodeDropdownSettings = {};
  promotionCodeDropdownList = [];
  promotionCodeSelectedItems = [];
  messageTemplatesSettings = {};
  messageTemplateList = [];
  messageTemplateSelectedItem = [];
  showToggle = false;
  showRewardStatus = false;
  promoType: Number;
  autoAssignPromotionTooltipText: string = '';
  messageTemplate = 0;

  popupDropdownSettings = {};
  popupDropdownList = [];
  popupSelectedItems = [];
  filteredPopupDropdownList = [];
  allPromotionCodeDropdownList = [];
  popupTooltipText: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { campaign: Campaign, mode: string },
    private dropdownHttpService: DropdownHttpService,
    public dialogRef: MatDialogRef<CampaignEditDialogComponent>,
    private campaignDataService: CampaignDataService,
    private campaignEntityService: CampaignEntityService,
    private promotionCodeDataService: PromotionCodeDataService,
    private messageTemplateDataService: MessageTemplateDataService,
    private dialogDataService: DialogDataService,
  ) {}

  ngOnInit() {
    this.setCurrency();

    (document.querySelector('.mat-dialog-container') as HTMLElement).style.overflow = 'visible';

    this.formInit();

    this.promotionCodeDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };

    // Message Templates Dropdown Settings
    this.messageTemplatesSettings = {
      maxHeight: 200,
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'code',
      showCheckbox: false
    };

    this.popupDropdownSettings = {
      autoPosition: false,
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'labelKey',
      noDataLabel: '',
      showCheckbox: false
    };
    
    this.dropdown.messagetemplates.subscribe(res => {
      this.messageTemplateList = res;
      if (this.data.mode === 'edit' && this.data.campaign.message_template_id !== 0) {
        this.messageTemplateSelectedItem = [this.messageTemplateList.find(x => x.id === this.data.campaign.message_template_id) ? this.messageTemplateList.find(x => x.id === this.data.campaign.message_template_id) : []];
      } 
    });

    this.promotionCodeDataService.getWithQuery(`?status=1&paginate=false`).subscribe(res => {
      this.allPromotionCodeDropdownList = res;
      this.promotionCodeDropdownList = res;
      this.promotionCodeDropdownList.map(function (elm) {
        elm['name'] = elm.code + ' - ' + elm.name;
      });

      if(this.data.mode === 'edit'){
        if(this.data.campaign.promotion_id !== 0 ){
          if (this.data.campaign.total_members > 0) {
            this.promotionCodeDropdownSettings['disabled'] = true;
            this.promotionCodeDropdownSettings = Object.assign({}, this.promotionCodeDropdownSettings);
          }
          this.promotionCodeSelectedItems.push({
            id: this.data.campaign.promotion_id,
            name: this.data.campaign.promotion_code + ' - ' + this.data.campaign.promotion_name
          });
          this.showToggle = this.promotionCodeSelectedItems.length > 0 ? true : false;
          this.promoType = this.data.campaign.promo_type;
          // 3 is Free Credit
          if (this.data.campaign.promo_type == 3) {
            if (this.form.get('auto_assign_promo').value) {
              this.setRewardStatusCondition(true);
            } else {
              this.setRewardStatusCondition(false);
            }
          } else {
            this.setRewardStatusCondition(false);
          }
        };
      };
    });

    this.setPopups();
    this.autoAssignPromotionTooltipText = 'Turn on to automatically assign the promotion or reward bound to members upon their registration. \n\nTurn off to disable the automatically assign of the promotion to the members. Promotion or reward bound will need to be manually assigned to the members after their registration if is turned off.'
    this.popupTooltipText = 'The dialog popup to be prompt to member after registration.';
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onSave(campaign: Campaign, mode?: string) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      id: campaign ? campaign.id : null,
      ...this.form.value,
      dialog_popup_list: this.popupSelectedItems
    };
    delete data['dialog_popups'];

    if (data.message_template_id === null) {
      data.message_template_id = 0;
    }

    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.campaignEntityService.update(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.campaignDataService.add(data).pipe(
            tap((res: any) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.campaignDataService.messages$
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  onSelectedPromoChange(item) {
    if(item.length > 0) {
      this.showToggle = true;
      this.promoType = item[0].promo_type;
      this.form.patchValue({
        auto_assign_promo: true
      });
      // Free Credit Type is 3
      if (item[0].promo_type == 3) {
        if (this.form.get('auto_assign_promo').value) {
          this.setRewardStatusCondition(true);
        } else {
          this.setRewardStatusCondition(false);
        }
      } else {
        this.form.patchValue({
          reward_status: 0
        });
        this.setRewardStatusCondition(false);
      }
    } else {
      this.promoType = 0;
      this.form.patchValue({
        auto_assign_promo: false,
        reward_status: 0
      });
      this.showToggle = false;
      this.setRewardStatusCondition(false);
    }
    
  }

  private setCurrency(){
    const selectCurrency = () => {
      if (this.data.mode === 'edit'){
        this.selectedCurrency = [this.dropdown.currencies.find(currency => currency.name === this.data.campaign.currency_code)];
      }
    };

    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
        selectCurrency();
      });
    }else{
      selectCurrency();
    }
  }

  private setRewardStatusCondition(value: boolean) {
    if (value) {
      this.showRewardStatus = true;
      this.form.get('reward_status').setValidators([Validators.required, Validators.pattern('^[1-2]$')]);
      this.form.get('reward_status').updateValueAndValidity();
    } else {
      this.showRewardStatus = false;
      this.form.get('reward_status').clearValidators();
      this.form.get('reward_status').updateValueAndValidity();
    }
  }

  private formInit() {
    let currencyId = null;
    let name = null;
    let status = 1;
    let remarks = null;
    let promotionId = null;
    let memberGroupId = 1;
    this.messageTemplate = null;
    this.autoAssignPromo = false;
    let rewardStatus = 0;
    let dialog_popup = [];
    if (this.data.mode === 'edit'){
      currencyId = this.data.campaign.settings_currency_id;
      name = this.data.campaign.name;
      status = this.data.campaign.status;
      remarks = this.data.campaign.remarks;
      promotionId = this.data.campaign.promotion_id;
      memberGroupId = this.data.campaign.member_group_id;
      this.messageTemplate = this.data.campaign.message_template_id;
      this.autoAssignPromo = this.data.campaign.auto_assign_promo ?? false;
      rewardStatus = this.data.campaign.reward_status
    }
    this.form = new FormGroup({
      currency_id: new FormControl({value: currencyId, disabled: this.data.mode === 'edit' ? true : false}, [Validators.required]),
      promotion_id: new FormControl(promotionId),
      name: new FormControl(name, [Validators.required]),
      status: new FormControl(status, [Validators.required]),
      remarks: new FormControl(remarks),
      auto_assign_promo: new FormControl(this.autoAssignPromo),
      reward_status: new FormControl(rewardStatus),
      member_group_id: new FormControl(memberGroupId),
      message_template_id: new FormControl(this.messageTemplate),
      dialog_popup: new FormControl(dialog_popup)
    });

    this.form.get('auto_assign_promo').valueChanges.subscribe(res => {
      if (res) {
        if (this.promoType === 3) {
          this.setRewardStatusCondition(true);
        } else {
          this.setRewardStatusCondition(false);
        }
      } else {
        this.setRewardStatusCondition(false);
      }
    });
  }

  checkDialogValidityDate() {
    if (this.popupSelectedItems != undefined && this.popupSelectedItems.length > 0) {
      var today = new Date();
      var start_date = new Date(this.popupSelectedItems[0]['start_date']);

      if (this.popupSelectedItems[0]['end_date'] != null) {
        var end_date = new Date(this.popupSelectedItems[0]['end_date']);
        if (today > end_date || today < start_date) {
          return true;
        }
        else {
          return false;
        }
      }
      else if (today < start_date) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }
  
  setPopups() {
    let contents = [];
    this.dialogDataService.getWithQuery(`?paginate=false&status=1`).subscribe(res => {
      res.map(function (elm) {
        elm.contents.forEach(v => contents.push(v));

        let localeTitle = contents.filter(x => x.title != null && x.popup_id == elm.id);
        localeTitle = localeTitle.sort(function(a, b) { 
          return a.locale_id - b.locale_id;
        });

        if (localeTitle.length > 0) {
          let title = (localeTitle[0]['title'].length > 25) ? localeTitle[0]['title'].slice(0, 25 - 1) + ' . . . ' : localeTitle[0]['title'];
          elm['labelKey'] = elm.code + ' (' + title + ')';
        }
        else {
          elm['labelKey'] = elm.code;
        }
      });

      this.popupDropdownList = this.popupDropdownList.concat(res);
      this.popupDropdownList = this.popupDropdownList.map((x) => {
        return {
          id: x.id,
          start_date: x.start_date,
          end_date: x.end_date,
          promotion_id: this.data.mode === 'edit' ? this.data.campaign.id : null,
          labelKey: x.labelKey,
          code: x.code
        }
      });

      this.filteredPopupDropdownList = this.popupDropdownList;

      if (this.data.mode === 'edit') {
        if (this.data.campaign.dialog_popup_list.length > 0) {
          this.popupSelectedItems.push(this.popupDropdownList.filter(x => x.id == this.data.campaign.dialog_popup_list[0].popup_id)[0]);
        }
      }
    })
  }

  onSelectedPopupItems($event: any) {
    if ($event.length == 0) {
      this.popupSelectedItems = [];
    }
    else {
      this.popupSelectedItems = [$event[0]];
    }
  }
}
