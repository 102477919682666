import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { MerchantBank } from '@core/models/merchant-bank.model';

@Injectable()
export class MerchantBankEntityService extends EntityCollectionServiceBase<MerchantBank> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('MerchantBank', serviceElementsFactory);
    }
}
