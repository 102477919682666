<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="kt-form kt-form--label-right mb-2">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <div class="col-12 row mb-2 pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>ID:</label>
                </div>
                <div class="col-md-2 kt-form__control ">
                  <input type="text" formControlName="id" placeholder="Search" class="form-control">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Username:</label>
                </div>
                <div class="col-md-2 kt-form__control ">
                  <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Name:</label>
                </div>
                <div class="col-md-2 kt-form__control ">
                  <input type="text" formControlName="name" placeholder="Search" class="form-control">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Currency:</label>
                </div>
                <div class="col-md-2 kt-form__control ">
                  <select formControlName="currency_id" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 row mb-2 pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date/Time:</label>
                </div>
                <div class="col-md-5 kt-form__control d-flex">
                  <select class="form-control mr-1" style="width: 30%;" formControlName="date_type">
                    <option value="created_by">Created By</option>
                    <option value="updated_by">Updated By</option>
                  </select>
                  <div class="input-group date" style="width: 70%;">
                    <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                    <span class="input-group-append">
                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                    </span>
                  </div>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Game Provider:</label>
                </div>
                <div class="col-md-2 kt-form__control ">
                  <select formControlName="game_provider" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.gameProviders | async">
                      {{ value.code + ' - ' + value.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Remark:</label>
                </div>
                <div class="col-md-2 kt-form__control ">
                  <input type="text" formControlName="remarks" placeholder="Search" class="form-control">
                </div>
              </div>
              <div class="col-12 row mb-2 pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="d-flex flex-wrap status-filter-pt">
                    <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.statuses ; let i = index">
                        <input type="checkbox" [checked]="item.checked" (change)="onSearchStatus($event, item.value, i)" class="custom-control-input" [id]="item.name">
                        <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                    </div>
                </div>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Account Type:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="account_type_condition" class="form-control" >
                    <option value="And">AND Condition</option>
                    <option value="Or">OR Condition</option>
                  </select>
                </div>
                <div class="col-md-3 kt-form__control">
                  <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
                    [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
                    [formName]="'account_type'" [selectionAttributes]="'id'"
                    [selectedItems]='accountTypeSelectedItems'>
                  </kt-dropdown-wo-lazyload>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="kt-section col-12 row d-flex justify-content-between mb-3">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <div class="dropdown dropdown-inline">
          <button class="btn btn-warning btn-icon-sm mr-2" *ngIf="canExportTransfers" (click)="onExport()" type="button" [disabled]="buttonLoading"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
          <button *ngIf="canCreateTransfer" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
        </div>
        <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
        <!-- <button *ngIf="transfersUseNewPermissions ? canCreateTransfer : (isAdmin || createTransfers)" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button> -->
      </div>
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                  <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                  <th class="sort-enabled" (click)="onSortColumn('transfer_from')" [ngClass]="sortingConfig.transfer_from" [class.sort-selected]="sortingSelection.sort_by === 'transfer_from'">Transfer From</th>
                  <th class="sort-enabled" (click)="onSortColumn('transfer_to')" [ngClass]="sortingConfig.transfer_to" [class.sort-selected]="sortingSelection.sort_by === 'transfer_to'">Transfer To</th>
                  <th class="text-center pl-5 pr-5 sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('amount')" [ngClass]="sortingConfig.amount" [class.sort-selected]="sortingSelection.sort_by === 'amount'">Amount</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('bonus')" [ngClass]="sortingConfig.bonus" [class.sort-selected]="sortingSelection.sort_by === 'bonus'">Bonus</th>
                  <th>Result Message</th>
                  <th>Remarks</th>
                  <th class="date-column sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                  <th class="date-column sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                  <th class="action-column text-center">Actions</th>
                </tr>
              </thead>
              <tbody *ngIf="(transfers$ | async) as rows">
                <tr *ngFor="let row of allTransfer; let i = index">
                  <td>{{ row.id }}</td>
                  <td>
                    <a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', row.member_id)">{{ row.username }}</a>
                    <i *ngIf="row.suspicious" class="fa fa-exclamation text-danger"></i>
                  </td>
                  <td>{{ row.transfer_from }}</td>
                  <td>{{ row.transfer_to }}</td>
                  <td class="text-center">
                    <ng-container *ngIf="transferCheckingIndex === i; else elseStatus">
                      <i class="fas fa-circle-notch fa-spin"></i>
                    </ng-container>
                    <ng-template #elseStatus>
                      <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                    </ng-template>
                  </td>
                  <td class="text-center">{{ row.currency }}</td>
                  <td class="text-right">{{ row.amount | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.bonus | number : '1.2-2' }}</td>
                  <td class="break-long-word">
                    <!-- {{resultMessageFormat(row.api_result)}} -->
                    <p class="mb-0 no-break"> {{ row.api_result.ga_username ? 'Username: ' + row.api_result.ga_username : ''}} </p>
                    <p class="mb-0 no-break"> {{ row.api_result.amount ? 'Amount: ' + row.api_result.amount : '' }} </p>
                    <p class="mb-0 no-break"> {{ row.api_result.new_balance ? 'New Balance: ' + row.api_result.new_balance : '' }} </p>
                    <p class="mb-0 no-break"> {{ row.api_result.previous_balance ? 'Previous Balance: ' + row.api_result.previous_balance : '' }} </p>
                    <p class="mb-0 no-break"> {{ row.api_result.status ? 'Status: ' + row.api_result.status : '' }} </p>
                    <p class="mb-0"> {{ row.api_result.message ? 'Message: ' + row.api_result.message : '' }} </p>
                  </td>
                  <td>{{ row.remarks }}</td>
                  <td>
                    {{ row.created_by ? row.created_by : 'System' }}<br>
                    <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #createdDateTime>
                      {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                  <td>
                    {{ row.updated_by ? row.updated_by : 'System' }}<br>
                    <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #updatedDateTime>
                      {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                  <td>
                    <button *ngIf="canApproveTransfers && (+row.status === 0 || +row.status === 4)" matTooltip="Approve" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onUpdateStatus(row, 'approve')"><i class="fas fa-check"></i></button>
                    <button *ngIf="canRejectTransfers && (+row.status === 0 || +row.status === 4)" matTooltip="Reject" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onUpdateStatus(row, 'reject')"><i class="fas fa-times"></i></button>
                    <button *ngIf="canMarkAsFailed && (+row.status === 0)" matTooltip="Mark as Failed" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onMark(row, 'fail')"><i class="fas fa-ban"></i></button>
                    <button *ngIf="canRefundTransfers && (+row.status === 4)" matTooltip="Refund" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onFailedTransfer(row, 'refund')"><i class="fas fa-undo"></i></button>
                    <button *ngIf="canRetryTransfers && (+row.status === 4)" matTooltip="Retry" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onFailedTransfer(row, 'retry')"><i class="fas fa-redo"></i></button>
                    <button *ngIf="canCheckStatus && (+row.status === 0 || +row.status === 4)" matTooltip="Check Status" [disabled]="checkTransferClicked" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onCheckTransfer(row, i)"><i class="fas fa-check-double"></i></button>
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <kt-swal-alert [message]="messages$ | async" (confirmed)="this.reload(false)"></kt-swal-alert>
