<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <!-- Filter Form -->
                        <form class="row align-items-center" [formGroup]="form">
                            <!-- Filter first row -->
                            <div class="col-12 row mb-2 pr-0">
                                <!-- Username -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>
                                <!-- Currency -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="currency_id">
                                        <option value="all" selected>All</option>
                                        <ng-container *ngFor="let value of dropdown.currencies">
                                            <option [value]="value.id">{{ value.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <!-- Filter second row -->
                            <div class="col-12 row mb-2 pr-0">
                                <!-- Source Type -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Source Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="source_type" (change)="onSelectSourceType($event)">
                                        <option [value]="null">Please Select</option>
                                        <ng-container *ngFor="let value of dropdown.sourceTypes">
                                            <option [value]="value.id">{{ value.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <!-- Source Account -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Source Account:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="source_account" placeholder="Search" class="form-control">
                                </div>
                                <!-- Campaign Name -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Campaign Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="campaign_name" placeholder="Search" class="form-control">
                                </div>
                                <!-- Campaign Code -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Campaign Code:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="campaign_code" placeholder="Search" class="form-control">
                                </div>
                            </div>
                            <!-- Filter 3rd row -->
                            <div class="col-12 row mb-2 pr-0">
                                <!-- Timezone -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Timezone:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        #timezone
                                        (click)="onChangeTimezone(timezone.selectedItems)"
                                        [form] = 'form'
                                        [dropdownList] = 'dropdown.timezones'
                                        [dropdownSettings] = 'timezoneDropdownSettings'
                                        [formName] = "'timezone'"
                                        [selectionAttributes] = "'timezone'"
                                        [selectedItems] = 'timezoneSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                                <!-- Registration Date -->
                                <div class="col-md-1 kt-form__label col-form-label col-form-label">
                                    <label>Registration Date:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <div class="input-group date">
                                        <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true"
                                            [ranges]="ranges" [showCustomRangeLabel]="true"
                                            [locale]="dateTimePickerLocale" [timePicker24Hour]="true"
                                            [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar"
                                                (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                                <!-- Registration Site -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Registration Site:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="registration_site" placeholder="Search" class="form-control">
                                </div>
                                <!-- Click ID -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Click ID:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="clickid" placeholder="Search" class="form-control">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Filter Action -->
            <div class="kt-section col-12 row mb-3 mt-2 d-flex justify-content-between">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canExportRegistrationReport" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="button_loading">
                    <i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export
                </button>
            </div>

            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('member_account_id')" [ngClass]="sortingConfig.member_account_id" [class.sort-selected]="sortingSelection.sort_by === 'member_account_id'">Member ID</th>
                                    <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                                    <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency</th>
                                    <th colspan="3" class="text-center">Traffic Source</th>
                                    <th colspan="5" class="text-center">Registration</th>
                                    <th colspan="3" class="text-center">FTD</th>
                                </tr>
                                <tr>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('source_type')" [ngClass]="sortingConfig.source_type" [class.sort-selected]="sortingSelection.sort_by === 'source_type'">Source Type</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('source_account')" [ngClass]="sortingConfig.source_account" [class.sort-selected]="sortingSelection.sort_by === 'source_account'">Source Account</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('campaign')" [ngClass]="sortingConfig.campaign" [class.sort-selected]="sortingSelection.sort_by === 'campaign'">Campaign</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('registration_date')" [ngClass]="sortingConfig.registration_date" [class.sort-selected]="sortingSelection.sort_by === 'registration_date'">Registration Date</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('registration_site')" [ngClass]="sortingConfig.registration_site" [class.sort-selected]="sortingSelection.sort_by === 'registration_site'">Registration Site</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('registration_ip')" [ngClass]="sortingConfig.registration_ip" [class.sort-selected]="sortingSelection.sort_by === 'registration_ip'">Registration IP</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('clickid')" [ngClass]="sortingConfig.clickid" [class.sort-selected]="sortingSelection.sort_by === 'clickid'">Click ID</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('ext_campaign')" [ngClass]="sortingConfig.ext_campaign" [class.sort-selected]="sortingSelection.sort_by === 'ext_campaign'">Ext Campaign</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('ftd_id')" [ngClass]="sortingConfig.ftd_id" [class.sort-selected]="sortingSelection.sort_by === 'ftd_id'">FTD ID</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('ftd_date')" [ngClass]="sortingConfig.ftd_date" [class.sort-selected]="sortingSelection.sort_by === 'ftd_date'">FTD Date</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('ftd_amount')" [ngClass]="sortingConfig.ftd_amount" [class.sort-selected]="sortingSelection.sort_by === 'ftd_amount'">FTD Amount</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(registrationReport$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.member_account_id }}</td>
                                    <td><a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog(row.member_account_id)">{{ row.username }}</a></td>
                                    <td>{{ row.currency_code }}</td>
                                    <td>{{ row.source_type }}</td>
                                    <td>{{ row.source_account }}</td>
                                    <td class="col-max-width">{{ row.campaign }}</td>
                                    <td>{{ timezoneDateWithTimezone(row.registration_date, this.form.value.timezone, 'YYYY-MM-DD HH:mm') }}</td>
                                    <td class="width-250">{{ row.registration_site }}</td>
                                    <td>{{ row.registration_ip ? row.registration_ip : '-' }}</td>
                                    <td>{{ row.clickid ? row.clickid : '-' }}</td>
                                    <td class="width-250">{{ row.ext_campaign ? row.ext_campaign : '-' }}</td>
                                    <td>{{ row.ftd_id ? row.ftd_id : '-' }}</td>
                                    <td>{{ row.ftd_date ? timezoneDateWithTimezone(row.ftd_date, this.form.value.timezone, 'YYYY-MM-DD HH:mm') : '-' }}</td>
                                    <td>{{ row.ftd_amount ? row.ftd_amount : '-' }}</td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                            [directionLinks]="true" [boundaryLinks]="true" [rotate]="true"
                            [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                                class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of
                                {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
