import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TransactionReconciliation } from '@core/models/transaction-reconciliation.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { TransactionReconciliationEntityService } from './services/transaction-reconciliation-entity.service';
@Component({
  selector: 'transaction-reconciliation',
  templateUrl: './transaction-reconciliation.component.html',
  styleUrls: ['./transaction-reconciliation.component.scss'],
})
export class TransactionReconciliationComponent implements OnInit, OnDestroy {
  @ViewChildren('filterInput') filterInput: QueryList<ElementRef>;
  form: FormGroup;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;

  defaultCurrencyCode = 1; // Default
  from = this.transactionHttpService.getLast24Hours().from;
  to = this.transactionHttpService.getLast24Hours().to;

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    merchantBankStatus: this.dropdownHttpService.merchantBankStatus
  };

  bankaccountDropdownSettings = {};
  bankaccountDropdownList = [{
    id: 'all',
    name: 'All',
  }];
  bankaccountDropdownListAll = [];
  bankaccountSelectedItems: any;

  params = `start_date_time=${moment(this.from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date_time=${moment(this.to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&currency_id=${this.defaultCurrencyCode}`;
  ranges: any;
  private subscription = new Subscription();
  private refreshSubcription = new Subscription();
  datePickerSubscription: Subscription;
  dataLength = {
    agent_deposit: undefined,
    agent_withdrawal: undefined,
    deposit: undefined,
    transfer: undefined,
    withdrawal: undefined

  }

  transactionReconciliation$: Observable<TransactionReconciliation[]>;

  constructor(
    private transactionReconciliationEntityService: TransactionReconciliationEntityService,
    private transactionHttpService: TransactionHttpService,
    private merchantBankHttpService: MerchantBankHttpService,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private cdr: ChangeDetectorRef,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit() {
    this.formInit();
    this.ranges = this.transactionHttpService.ranges;
    this.bankaccountDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      // maxHeight: 'auto',
      primaryKey: 'name',
      labelKey: 'name',
      lazyLoading: false,
      noDataLabel: '',
      showCheckbox: false
    };

    this.merchantBankHttpService.getMerchantBanksAccount().subscribe((res) => {
      res.map(function (elm) {
        elm['name'] = elm['bank_code'] + ' - ' + elm['account_name'] + ' - ' + elm['account_number'];
      });
      this.bankaccountDropdownListAll = res;
      this.onSelectCurrency();
    });

    this.setCurrency();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.form.patchValue({
      merchant_bank_id: 'all',
      currency_id: this.dropdown.currencies.find(
        (v) => v.id === this.defaultCurrencyCode
      ).id,
      start_date_time: this.from,
      end_date_time: this.to,
      defaultDate: {
        startDate: this.from,
        endDate: this.to,
      },
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        exhaustMap((data) => {
          if (data['start_date_time']) {
            data['start_date_time'] = moment(data['start_date_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
            data['end_date_time'] = moment(data['end_date_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          }

          this.params = Object.keys(data)
            .map((key) => key + '=' + data[key])
            .join('&');
          const parameters = this.params ? `${this.params}` : '';
          this.loadingBar.start();
          return (this.transactionReconciliation$ = this.transactionReconciliationEntityService
            .getWithQuery(`?${parameters}`)
            .pipe(
              tap((res: any) => {
                this.loading = false;
                this.clearBtnLoading = false;
                this.searchBtnLoading = false;
                this.dataLength = {
                  agent_deposit: res[0].agent_deposit.rows.length,
                  agent_withdrawal: res[0].agent_withdrawal.rows.length,
                  deposit: res[0].deposit.rows.length,
                  transfer: res[0].transfer.rows.length,
                  withdrawal: res[0].withdrawal.rows.length
                };
                this.loadingBar.complete();
              })
            ));
        })
      )
      .subscribe();
  }

  onSelectCurrency(){
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.bankaccountDropdownList = this.bankaccountDropdownListAll.filter(res => +res.currency_id === +this.form.value.currency_id);
    this.bankaccountDropdownList.unshift({id: 'all', name: 'All'});
    this.bankaccountSelectedItems = [{id: 'all', name: 'All' }];
    this.form.patchValue({merchant_bank_id: 'all'});
    this.cdr.detectChanges();
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private setCurrency(){
    const selectCurrency = () => {
      this.form.patchValue({
        currency_id: this.dropdown.currencies.find(
          (v) => v.id === this.defaultCurrencyCode
        ).id,
      });
    };

    if(this.dropdown.currencies.length === 0){
        this.dropdownHttpService.currencies.subscribe( res => {
            this.dropdown.currencies = res;
            selectCurrency();
        });
    }else{
        selectCurrency();
    }
  }

  private formInit() {
    this.form = new FormGroup({
      currency_id: new FormControl(this.defaultCurrencyCode), // Default
      merchant_bank_id: new FormControl('all'),
      start_date_time: new FormControl(this.from),
      end_date_time: new FormControl(this.to),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to,
      }), // Do not remove: For Clearing The Range
      merchant_bank_status: new FormControl(this.getSelectedValues()),
    });
    // this.onSubmit();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach((key) => {
      if (
        formData[key] !== '' &&
        formData[key] !== null &&
        formData[key] !== undefined &&
        key !== 'defaultDate' &&
        formData[key] !== 'all'
      ) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onMerchantBankStatus(event: any, value: string, ind: number) {
    if (event.target.checked) {
      this.dropdown.merchantBankStatus[ind].checked = true;
    } else {
      this.dropdown.merchantBankStatus[ind].checked = false;
    }

    this.form.patchValue({ merchant_bank_status: this.getSelectedValues() });
  }

  getSelectedValues(): number[] {
    return this.dropdown.merchantBankStatus
      .filter(item => item.checked)
      .map(item => item.value);
  }
}
