<form class="kt-form w-webkit-fill-available config-overflow" [formGroup]="formTransaction">
    <div class="row">
        <div class="col-lg-6 form-group">
            <label class="control-label">Purpose<span class="text-danger">*</span></label>
            <select class="form-control" formControlName="purpose" (change)="onPurpose($event)">
                <option [value]="null" [disabled]="true">Please Select</option>
                <option *ngFor="let value of dropdown.bankPurposes | async" [value]="value.id">{{ value.name }}</option>
            </select>
        </div>
        <div class="col-lg-6 form-group">
            <label class="control-label">Status</label>
            <select class="form-control" formControlName="status">
                <ng-container *ngFor="let key of object.keys(status)">
                    <option [value]="key" *ngIf="+key >= 0">{{ status[key] }}</option>
                </ng-container>
            </select>
        </div>
        <!--Transaction Configs-->
        <div class="col-12 mb-3 mt-2">
            <strong>Transaction Configs</strong>
        </div>
        <!-- if purpose != "withdrawal" -->
        <ng-container *ngIf="(selectedPurpose != 2) || (formTransaction.value.purpose != 2)">
            <div class="col-lg-6 form-group input-container">
                <label class="control-label">Daily Max Deposit Count<span class="text-danger">*</span> </label>
                <div class="input-wrapper">
                    <span class="currency-symbol">{{ currency_code }}</span>
                    <input class="form-control config-form" type="number" (wheel)="false" formControlName="daily_max_deposit_count">
                </div>
            </div>
            <div class="col-lg-6 form-group input-container">
                <label class="control-label">Daily Max Deposit Amount<span class="text-danger">*</span> </label>

                <div class="row">
                    <div class="input-wrapper col">
                        <span class="currency-symbol">{{ currency_code }}</span>
                        <input class="form-control config-form" type="number" (wheel)="false" formControlName="daily_max_deposit">
                    </div>
                    <div *ngIf="isMethodCrypto()" class="input-wrapper col">
                        <span class="currency-symbol">USDT</span>
                        <input class="form-control config-form" type="number" (wheel)="false" formControlName="daily_max_deposit_usdt">
                    </div>
                </div>
            </div>
        </ng-container>

         <!-- if purpose != "deposit" -->
         <ng-container *ngIf="(selectedPurpose != 1) || (formTransaction.value.purpose != 1)">
            <div class="col-lg-6 form-group input-container">
                <label class="control-label">Daily Max Withdrawal Count<span class="text-danger">*</span> </label>

                <div class="input-wrapper">
                    <span class="currency-symbol">{{ currency_code }}</span>
                    <input class="form-control config-form" type="number" (wheel)="false" formControlName="daily_max_withdrawal_count">
                </div>
            </div>
            <div class="col-lg-6 form-group input-container">
                <label class="control-label">Daily Max Withdrawal Amount<span class="text-danger">*</span> </label>

                <div class="row">
                    <div class="input-wrapper col">
                        <span class="currency-symbol">{{ currency_code }}</span>
                        <input class="form-control config-form" type="number" (wheel)="false" formControlName="daily_max_withdrawal">
                    </div>
                    <div *ngIf="isMethodCrypto()" class="input-wrapper col">
                        <span class="currency-symbol">USDT</span>
                        <input class="form-control config-form" type="number" (wheel)="false" formControlName="daily_max_withdrawal_usdt">
                    </div>
                </div>

            </div>
        </ng-container>
        <div class="col-lg-6 form-group input-container">
            <label class="control-label">Minimum Balance<span class="text-danger">*</span> </label>

            <div class="row">
                <div class="input-wrapper col">
                    <span class="currency-symbol">{{ currency_code }}</span>
                    <input class="form-control config-form" type="number" (wheel)="false" name="min_balance" formControlName="min_balance">
                </div>
                <div *ngIf="isMethodCrypto()" class="input-wrapper col">
                    <span class="currency-symbol">USDT</span>
                    <input class="form-control config-form" type="number" (wheel)="false" name="min_balance_usdt" formControlName="min_balance_usdt">
                </div>
            </div>

        </div>
        <div class="col-lg-6 form-group input-container">
            <label class="control-label">Maximum Balance<span class="text-danger">*</span> </label>

            <div class="row">
                <div class="input-wrapper col">
                    <span class="currency-symbol">{{ currency_code }}</span>
                    <input class="form-control config-form" type="number" (wheel)="false" formControlName="max_balance">
                </div>
                <div *ngIf="isMethodCrypto()" class="input-wrapper col">
                    <span class="currency-symbol">USDT</span>
                    <input class="form-control config-form" type="number" (wheel)="false" formControlName="max_balance_usdt">
                </div>
            </div>

        </div>
        <div class="col-lg-6 form-group input-container" *ngIf="((mode === 'create' && selectedPurpose !== 2) || (mode === 'edit' && (formTransaction.value.purpose !== 2 && selectedPurpose !== 2)))">
            <label class="control-label">Minimum Deposit per Transaction<span class="text-danger">*</span> </label>

            <div class="row">
                <div class="input-wrapper col">
                    <span class="currency-symbol">{{ currency_code }}</span>
                    <input class="form-control config-form" type="number" (wheel)="false" name="min_deposit_per_transaction" formControlName="min_deposit_per_transaction">
                </div>
                <div *ngIf="isMethodCrypto()" class="input-wrapper col">
                    <span class="currency-symbol">USDT</span>
                    <input class="form-control config-form" type="number" (wheel)="false" name="min_deposit_per_transaction_usdt" formControlName="min_deposit_per_transaction_usdt">
                </div>
            </div>

        </div>
        <div class="col-lg-6 form-group input-container" *ngIf="((mode === 'create' && selectedPurpose !== 2) || (mode === 'edit' && (formTransaction.value.purpose !== 2 && selectedPurpose !== 2)))">
            <label class="control-label">Maximum Deposit per Transaction<span class="text-danger">*</span> </label>

            <div class="row">
                <div class="input-wrapper col">
                    <span class="currency-symbol">{{ currency_code }}</span>
                    <input class="form-control config-form" type="number" (wheel)="false" name="max_deposit_per_transaction" formControlName="max_deposit_per_transaction">
                </div>
                <div *ngIf="isMethodCrypto()" class="input-wrapper col">
                    <span class="currency-symbol">USDT</span>
                    <input class="form-control config-form" type="number" (wheel)="false" name="max_deposit_per_transaction_usdt" formControlName="max_deposit_per_transaction_usdt">
                </div>
            </div>
            <small class="text-muted form-text">
                <i class="fa fa-exclamation-circle"></i>Enter 0 for unlimited
            </small>
        </div>
        <div class="col-lg-6 form-group input-container" *ngIf="((mode === 'create' && selectedPurpose !== 1) || (mode === 'edit' && (formTransaction.value.purpose !== 1 && selectedPurpose !== 1)))">
            <label class="control-label">Minimum Withdrawal per Transaction<span class="text-danger">*</span> </label>

            <div class="row">
                <div class="input-wrapper col">
                    <span class="currency-symbol">{{ currency_code }}</span>
                    <input class="form-control config-form" type="number" (wheel)="false" name="min_withdrawal_per_transaction" formControlName="min_withdrawal_per_transaction">
                </div>
                <div *ngIf="isMethodCrypto()" class="input-wrapper col">
                    <span class="currency-symbol">USDT</span>
                    <input class="form-control config-form" type="number" (wheel)="false" name="min_withdrawal_per_transaction_usdt" formControlName="min_withdrawal_per_transaction_usdt">
                </div>
            </div>
        </div>
        <div class="col-lg-6 form-group input-container" *ngIf="((mode === 'create' && selectedPurpose !== 1) || (mode === 'edit' && (formTransaction.value.purpose !== 1 && selectedPurpose !== 1)))">
            <label class="control-label">Maximum Withdrawal per Transaction<span class="text-danger">*</span> </label>
 
            <div class="row">
                <div class="input-wrapper col">
                    <span class="currency-symbol">{{ currency_code }}</span>
                    <input class="form-control config-form" type="number" (wheel)="false" name="max_withdrawal_per_transaction" formControlName="max_withdrawal_per_transaction">
                </div>
                <div *ngIf="isMethodCrypto()" class="input-wrapper col">
                    <span class="currency-symbol">USDT</span>
                    <input class="form-control config-form" type="number" (wheel)="false" name="max_withdrawal_per_transaction_usdt" formControlName="max_withdrawal_per_transaction_usdt">
                </div>
            </div>
            <small class="text-muted form-text">
                <i class="fa fa-exclamation-circle"></i>Enter 0 for unlimited
            </small>
        </div>
        <div *ngIf="!isMethodCrypto()" class="col-lg-6 form-group">
            <label class="control-label">Deposit Field Type</label>
            <select class="form-control" formControlName="deposit_field_type">
                <option [value]="null" [disabled]="true">Select Deposit Amount</option>
                <option *ngFor="let value of depositFieldTypes" [value]="value.id">{{ value.name }}</option>
            </select>
        </div>
        <div class="col-lg-6 form-group input-container">
            <label class="control-label"> Shortcut Amount </label>
            <div class="input-wrapper">
                <span class="currency-symbol">{{ currency_code }}</span>
                <input class="form-control config-form" type="text" name="shortcut_amount" formControlName="shortcut_amount">
            </div>
            <small class="text-muted form-text">
                <i class="fa fa-exclamation-circle"></i>Separate with comma
            </small>
        </div>
        <div class="col-lg-6 form-group">
            <label class="control-label">Score<span class="text-danger">*</span> </label>
            <input class="form-control" type="number" (wheel)="false" formControlName="score">
        </div>
        <div class="col-lg-6 form-group">
            <label class="control-label">Position<span class="text-danger">*</span> </label>
            <input class="form-control" type="number" (wheel)="false" formControlName="position">
        </div>
        <div class="col-lg-6 form-group input-container" *ngIf="method == 2 || isMethodCrypto()">
            <label class="control-label">Display Name<span *ngIf="!isMethodCrypto()" class="text-danger">*</span> </label>
            <input class="form-control" type="text" formControlName="display_name">
        </div>

        <!--Processing Fee-->
        <ng-container *ngIf="method == 2 || isMethodCrypto()">
            <div class="col-12 mb-3 mt-2">
                <strong>Processing Fee Setting</strong>
            </div>
            <div class="col-12 mb-3 mt-2" *ngIf="(selectedPurpose != 2) || (formTransaction.value.purpose != 2)">
                <strong>Deposit</strong>
            </div>
            <div class="col-lg-6 form-group" *ngIf="(selectedPurpose != 2) || (formTransaction.value.purpose != 2)">
                <label class="control-label">Type<span class="text-danger">*</span></label>
                <select class="form-control" formControlName="deposit_processing_fee_type" (change)="onDepositProcessingFeeType($event)">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <option *ngFor="let value of dropdown.processingFeeType" [value]="value.id">{{ value.name }}</option>
                </select>
            </div>
            <div class="col-lg-6 form-group input-container" *ngIf="((selectedPurpose != 2) || (formTransaction.value.purpose != 2)) && formTransaction.value.deposit_processing_fee_type > 0">
                <label class="control-label">Amount<span class="text-danger">*</span></label>
                <div class="input-wrapper" *ngIf="formTransaction.value.deposit_processing_fee_type == 1">
                    <span class="currency-symbol">{{ currency_code }}</span>
                    <input (wheel)="false" class="form-control config-form" type="number" name="deposit_processing_fee" formControlName="deposit_processing_fee">
                </div>
                <div class="input-wrapper" *ngIf="formTransaction.value.deposit_processing_fee_type == 2">
                    <input (wheel)="false" class="form-control config-form-right" type="number" name="deposit_processing_fee_percentage" formControlName="deposit_processing_fee_percentage">
                    <span class="currency-symbol-right">%</span>
                </div>
                <div class="input-wrapper" *ngIf="formTransaction.value.deposit_processing_fee_type == 3">
                    <span class="currency-symbol">{{ currency_code }}</span>
                    <input (wheel)="false" class="form-control config-form" type="number" name="deposit_processing_fee" formControlName="deposit_processing_fee">
                    +
                    <input (wheel)="false" class="form-control config-form-right" type="number" name="deposit_processing_fee_percentage" formControlName="deposit_processing_fee_percentage">
                    <span class="currency-symbol-right">%</span>
                </div>
            </div>
            <div class="col-lg-6 form-group" *ngIf="(selectedPurpose != 2) || (formTransaction.value.purpose != 2)">
                <label class="control-label">Absorption By<span class="text-danger">*</span></label>
                <select class="form-control" formControlName="deposit_absorb_processing_fee_type" (change)="onAbsorbDepositProcessingFeeType($event)">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <option *ngFor="let value of dropdown.absorbProcessingFeeType" [value]="value.id">{{ value.name }}</option>
                </select>
            </div>
            <div class="col-lg-6 form-group input-container" *ngIf="((selectedPurpose != 2) || (formTransaction.value.purpose != 2)) && formTransaction.value.deposit_absorb_processing_fee_type > 0">
                <label class="control-label">Absorption Percentage {{ selectedAbsorbDepositProcessingFeeType }}<span class="text-danger">*</span></label>
                <div class="input-wrapper">
                    <div class="input-wrapper" *ngIf="formTransaction.value.deposit_absorb_processing_fee_type == 1">
                        <input (wheel)="false" class="form-control config-form-right" type="number" name="company_deposit_absorb_percentage" formControlName="company_deposit_absorb_percentage" disabled>
                        <span class="currency-symbol-right">%</span>
                    </div>
                    <div class="input-wrapper" *ngIf="formTransaction.value.deposit_absorb_processing_fee_type == 2">
                        <input (wheel)="false" class="form-control config-form-right" type="number" name="player_deposit_absorb_percentage" formControlName="player_deposit_absorb_percentage" disabled>
                        <span class="currency-symbol-right">%</span>
                    </div>
                    <div class="input-wrapper" *ngIf="formTransaction.value.deposit_absorb_processing_fee_type == 3">
                        <input (wheel)="false" class="form-control config-form-right" type="number" name="company_deposit_absorb_percentage" formControlName="company_deposit_absorb_percentage">
                        <span class="currency-symbol-right">%</span>
                        +
                        <input (wheel)="false" class="form-control config-form-right" type="number" name="player_deposit_absorb_percentage" formControlName="player_deposit_absorb_percentage">
                        <span class="currency-symbol-right">%</span>
                    </div>
                </div>
                <div class="input-wrapper" *ngIf="formTransaction.value.deposit_absorb_processing_fee_type == 3">
                    <small class="small-word-bottom" >
                        Company
                    </small>
                    <small class="small-word-bottom" >
                        Player
                    </small>
                </div>
            </div>
    
    
            <div class="col-12 mb-3 mt-2" *ngIf="(selectedPurpose != 1) || (formTransaction.value.purpose != 1)">
                <strong>Withdrawal</strong>
            </div>
            <div class="col-lg-6 form-group" *ngIf="(selectedPurpose != 1) || (formTransaction.value.purpose != 1)">
                <label class="control-label">Type<span class="text-danger">*</span></label>
                <select class="form-control" formControlName="withdrawal_processing_fee_type" (change)="onWithdrawalProcessingFeeType($event)">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <option *ngFor="let value of dropdown.processingFeeType" [value]="value.id">{{ value.name }}</option>
                </select>
            </div>
            <div class="col-lg-6 form-group input-container" *ngIf="((selectedPurpose != 1) || (formTransaction.value.purpose != 1)) && formTransaction.value.withdrawal_processing_fee_type > 0">
                <label class="control-label">Amount<span class="text-danger">*</span></label>
                <div class="input-wrapper" *ngIf="formTransaction.value.withdrawal_processing_fee_type == 1">
                    <span class="currency-symbol">{{ currency_code }}</span>
                    <input (wheel)="false" class="form-control config-form" type="number" name="withdrawal_processing_fee" formControlName="withdrawal_processing_fee">
                </div>
                <div class="input-wrapper" *ngIf="formTransaction.value.withdrawal_processing_fee_type == 2">
                    <input (wheel)="false" class="form-control config-form-right" type="number" name="withdrawal_processing_fee_percentage" formControlName="withdrawal_processing_fee_percentage">
                    <span class="currency-symbol-right">%</span>
                </div>
                <div class="input-wrapper" *ngIf="formTransaction.value.withdrawal_processing_fee_type == 3">
                    <span class="currency-symbol">{{ currency_code }}</span>
                    <input (wheel)="false" class="form-control config-form" type="number" name="withdrawal_processing_fee" formControlName="withdrawal_processing_fee">
                    +
                    <input (wheel)="false" class="form-control config-form-right" type="number" name="withdrawal_processing_fee_percentage" formControlName="withdrawal_processing_fee_percentage">
                    <span class="currency-symbol-right">%</span>
                </div>
            </div>
            <div class="col-lg-6 form-group" *ngIf="(selectedPurpose != 1) || (formTransaction.value.purpose != 1)">
                <label class="control-label">Absorption By<span class="text-danger">*</span></label>
                <select class="form-control" formControlName="withdrawal_absorb_processing_fee_type" (change)="onAbsorbWtihdrawalProcessingFeeType($event)">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <option *ngFor="let value of dropdown.absorbProcessingFeeType" [value]="value.id">{{ value.name }}</option>
                </select>
            </div>
            <div class="col-lg-6 form-group input-container" *ngIf="((selectedPurpose != 1) || (formTransaction.value.purpose != 1)) && formTransaction.value.withdrawal_absorb_processing_fee_type > 0">
                <label class="control-label">Absorption Percentage {{ selectedAbsorbWithdrawalProcessingFeeType }}<span class="text-danger">*</span></label>
                <div class="input-wrapper">
                    <div class="input-wrapper" *ngIf="formTransaction.value.withdrawal_absorb_processing_fee_type == 1">
                        <input (wheel)="false" class="form-control config-form-right" type="number" name="company_withdrawal_absorb_percentage" formControlName="company_withdrawal_absorb_percentage" disabled>
                        <span class="currency-symbol-right">%</span>
                    </div>
                    <div class="input-wrapper" *ngIf="formTransaction.value.withdrawal_absorb_processing_fee_type == 2">
                        <input (wheel)="false" class="form-control config-form-right" type="number" name="player_withdrawal_absorb_percentage" formControlName="player_withdrawal_absorb_percentage" disabled>
                        <span class="currency-symbol-right">%</span>
                    </div>
                    <div class="input-wrapper" *ngIf="formTransaction.value.withdrawal_absorb_processing_fee_type == 3">
                        <input (wheel)="false" class="form-control config-form-right" type="number" name="company_withdrawal_absorb_percentage" formControlName="company_withdrawal_absorb_percentage">
                        <span class="currency-symbol-right">%</span>
                        +
                        <input (wheel)="false" class="form-control config-form-right" type="number" name="player_withdrawal_absorb_percentage" formControlName="player_withdrawal_absorb_percentage">
                        <span class="currency-symbol-right">%</span>
                    </div>
                    
                </div>
                <div class="input-wrapper" *ngIf="formTransaction.value.withdrawal_absorb_processing_fee_type == 3">
                    <small class="small-word-bottom" >
                        Company
                    </small>
                    <small class="small-word-bottom" >
                        Player
                    </small>
                </div>
            </div>
        </ng-container>
    </div>
</form>
