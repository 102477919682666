<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Reward</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">

        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Expiry Date <span class="text-danger">*</span> </label>
          <div class="col-8 input-group date">
            <input class="hidden" [owlDateTime]="expiryDateRef" [ngModel]="data.reward.expiry_date" [ngModelOptions]="{standalone: true}">
            <input type="text" formControlName="expiry_datetime" class="form-control"  [ngClass]="{'is-invalid': checkValidation && form.controls.expiry_datetime.errors }">
            <span class="input-group-append" [owlDateTimeTrigger]="expiryDateRef">
                <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
            </span>
            <owl-date-time #expiryDateRef></owl-date-time>
          </div>
        </div>

        <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Status <span class="text-danger">*</span> </label>
            <select formControlName="status" class="col-8 form-control" [ngClass]="{'is-invalid': checkValidation && form.controls.status.errors }">
              <option [value]="null" disabled selected>Please Select</option>
              <ng-container *ngFor="let item of status | keyvalue" >
                <option [value]="item.key" *ngIf="+item.key >= 0">{{ item.value }}</option>
              </ng-container>
            </select>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [buttonLoading]="buttonLoading" (confirmed)="onSave(data.reward, data.mode)"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
