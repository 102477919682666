import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { Role } from '@core/models/role.model';

@Injectable()
export class RoleDataService extends DefaultDataService<Role> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('Role', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<Role[]> {
    return this.http.get<ApiResponse>('/role').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(role: Role): Observable<Role>{
    return this.http.post<ApiResponse>(`/role`, role).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/role/${entity.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  getWithQuery(pageParam: string): Observable<Role[]>{
    return this.http.get<ApiResponse>(`/role${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  getById(id: number): Observable<Role>{
    return this.http.get<ApiResponse>(`/role/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }

}
