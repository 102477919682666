import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { smsProviderCredentials } from '@core/models/sms-provider-credentials.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { ProviderCredentialsDataService } from '../service/provider-credentials-data.service';
import { ProviderCredentialsEditDialogComponent } from '../dialogs/provider-credentials-edit/provider-credentials-edit.component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { Status } from '@core/enums/status.enum';
import { Dropdown } from '@core/models/dropdown.model';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'app-provider-credentials',
  templateUrl: './provider-credentials.component.html',
  styleUrls: ['./provider-credentials.component.scss']
})
export class ProviderCredentialsComponent implements OnInit, OnDestroy {
  
  @Input()
  regions: Dropdown [];

  form: FormGroup;
  credentials$: Observable<smsProviderCredentials[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dropdown = {
    status: this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage
  };
  loading = false;
  searchBtnLoading = false;
  clearBtnLoading = false;
  dataLength: number;
  status = Status;
  isAdmin: boolean = JSON.parse(localStorage.getItem('user_data')).is_admin;

  // permissions
  canEditCredential: boolean;

  private subscriptions = new Subscription();

  constructor(
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
    private providerCredentialsDataService: ProviderCredentialsDataService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.pagination = this.providerCredentialsDataService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditCredential = appPermissions.edit_credential;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.credentials$ = this.providerCredentialsDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.providerCredentialsDataService.pagination;
            this.loadingBar.complete();
          }),
          catchError((error) => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = undefined;
            this.loadingBar.complete();
            throw error;
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.credentials$ = this.providerCredentialsDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.providerCredentialsDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize);
  }

  onOpenDialog(mode: string, row?: any) {
    const params = `smsdetail=${row.id}&code=${row.code}`;
    if (row) {
      this.providerCredentialsDataService.getbyCode(params).subscribe(res => {
        this.openDialogBy(ProviderCredentialsEditDialogComponent, { mode: mode, credentials: res });
      });
    }
  }

  private openDialogBy(componentRef: any, data?: { mode?: string, credentials?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.onViewPageBy(this.page).subscribe();
      }
    })
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null) ? fields[key] = formData[key] : key);
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      code: new FormControl(null),
      name: new FormControl(null),
      region: new FormControl('all'),
      status: new FormControl('all'),
      type: new FormControl('all'),
      super_user: new FormControl(this.isAdmin)
    });
  }

}
