<button type="button" class="btn-close m-0 p-0 border-0" matTooltip="Close (Esc)" (click)="onCloseNotification()">×</button>
<div class="msg-container">
    <div class="msg-header text-uppercase font-weight-bold" *ngIf="data.header">{{ data.header }}</div>
    <div class="msg-body mt-3">
        <div class="msg-title text-capitalize" *ngIf="data.title">{{ data.title }}</div>
        <div class="msg-content" [innerHTML]="sanitize(data.message)"></div>
        <div class="mt-3 d-flex flex-column align-items-center">
            <button class="btn btn-primary w-75" (click)="onNavigate()" *ngIf="data.type">View now</button>
        </div>
    </div>
</div>