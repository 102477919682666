<div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1" id="kt_login">
  <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">

  <!--begin::Content-->
  <div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
    <router-outlet></router-outlet>
  </div>
  <!--end::Content-->
  </div>
</div>
