<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ 'Google Authenticator' }}</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
          </div>
          <div class="modal-body">
            <div class="col-md-12 text-center" *ngIf="twoFaData">
              <div class="col-12 row text-left">
                <h5 class="col-12 scan-title">{{ 'Scan this with Google Authenticator App' }}</h5>
              </div>
              <div class="spinner text-center" *ngIf="loadingQR"></div>
              <img class="qr-img"
                [src]="twoFaData.qrCodeUrl"
                alt="qrcode"
                (load)="onImageLoad()"
              />
              <div class="input-container-code" *ngIf="!loadingQR && !twoFaData.first_verify">
                <label class="control-label">{{ 'Verification Code' }} <span class="text-danger">*</span></label>
                <input type="text" formControlName="code"  class="form-control text-center" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>{{ 'Close' }}</button>
            <kt-submit-button
              [isDisabled]="!form.valid"
              [buttonLoading]="buttonLoading"
              (confirmed)="onVerifyCode()"
              *ngIf="twoFaData && !twoFaData.first_verify"
            >
            </kt-submit-button>
          </div>
      </div>
    </form>
  </div>
  <kt-swal-alert [message]="messages$ | async" [icon]="hasError ? 'error' : 'info'"></kt-swal-alert>