<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row pr-0 mb-2">
                                <!-- Name -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <!-- Currency -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-dropdown-wo-lazyload 
                                        [form]='form' 
                                        [dropdownList]='currenciesDropdownList' 
                                        [dropdownSettings]='currenciesDropdownSettings' 
                                        [formName]="'settings_currency_id'" 
                                        [selectionAttributes]="'id'" 
                                        [selectedItems]='currenciesSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                                <!-- Member Group -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Member Group:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-dropdown-wo-lazyload 
                                        [form]='form' 
                                        [dropdownList]='memberGroupsDropdownList' 
                                        [dropdownSettings]='memberGroupsDropdownSettings' 
                                        [formName]="'member_group_id'" 
                                        [selectionAttributes]="'id'" 
                                        [selectedItems]='memberGroupsSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                                <!-- Promotion -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Promotion:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="promotion" placeholder="{{ 'Search' | translate }}" class="form-control" [ngbTypeahead]="this.searchPromo" [resultFormatter]="this.formatPromo" [inputFormatter]="this.formatPromo">
                                </div>
                            </div>
                            <div class="col-12 row pr-0 mb-2">
                                <!-- Commission Type -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Commission Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="type" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.types">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <!-- Status -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="status" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                                            {{ value }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canCreateReferralCommissionSettings" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'" rowspan="3">ID</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'" rowspan="3">Name</th>
                                    <th class="text-center" rowspan="3">Currencies</th>
                                    <th class="text-center" rowspan="3">Member Group</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('promotion_name')" [ngClass]="sortingConfig.promotion_name" [class.sort-selected]="sortingSelection.sort_by === 'promotion_name'" rowspan="3">Promotion</th>
                                    <th class="text-center" colspan="6">Commission Settings</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'" rowspan="3">Status</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'" rowspan="3">Created By</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'" rowspan="3">Updated By</th>
                                    <th class="text-center" rowspan="3">Actions</th>
                                </tr>
                                <tr>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('type_name')" [ngClass]="sortingConfig.type_name" [class.sort-selected]="sortingSelection.sort_by === 'type_name'" rowspan="2">Type</th>
                                    <th class="text-center" colspan="3">Rate</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('min_referral_commission')" [ngClass]="sortingConfig.min_referral_commission" [class.sort-selected]="sortingSelection.sort_by === 'min_referral_commission'" rowspan="2">Min Limit</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('max_referral_commission')" [ngClass]="sortingConfig.max_referral_commission" [class.sort-selected]="sortingSelection.sort_by === 'max_referral_commission'" rowspan="2">Max Limit</th>
                                </tr>
                                <tr>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('level_commission_rate1')" [ngClass]="sortingConfig.level_commission_rate1" [class.sort-selected]="sortingSelection.sort_by === 'level_commission_rate1'">Level 1</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('level_commission_rate2')" [ngClass]="sortingConfig.level_commission_rate2" [class.sort-selected]="sortingSelection.sort_by === 'level_commission_rate2'">Level 2</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('level_commission_rate3')" [ngClass]="sortingConfig.level_commission_rate3" [class.sort-selected]="sortingSelection.sort_by === 'level_commission_rate3'">Level 3</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(referralCommissionSettings$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.id }}</td>
                                    <td width="150">{{ row.name }}</td>
                                    <td class="text-center">{{ row.currencies_code }}</td>
                                    <td class="text-center">{{ row.member_group_name }}</td>
                                    <td>{{ row.promotion_code }} - {{ row.promotion_name }}</td>
                                    <td class="text-right">{{ row.type_name }}</td>
                                    <td class="text-right">{{ row.level_commission_rates[1] }}%</td>
                                    <td class="text-right">{{ row.level_commission_rates[2] }}%</td>
                                    <td class="text-right">{{ row.level_commission_rates[3] }}%</td>
                                    <td class="text-center">{{ row.min_referral_commission }}</td>
                                    <td class="text-center">{{ row.max_referral_commission }}</td>
                                    <td class="text-center">
                                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status">{{ row.status === 0 ? 'Inactive' : 'Active' }}</span>
                                    </td>
                                    <td>
                                        {{ row.created_by ? row.created_by : 'System' }}<br/>
                                        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="row.update_action">
                                            {{ row.updated_by ? row.updated_by : 'System' }}<br/>
                                            <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                            <ng-template #updatedDateTime>
                                                {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                            </ng-template>
                                        </ng-container>
                                    </td>
                                    <td class="text-center">
                                        <button *ngIf="canEditReferralCommissionSettings" matTooltip="Settings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)"><i class="fas fa-cog"></i></button>
                                        <button *ngIf="canDuplicateReferralCommissionSettings" matTooltip="Duplicate" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('duplicate', row.id)"><i class="fa fa-clone"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>