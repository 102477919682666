import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './member-deduct.component.html',
  styleUrls: ['./member-deduct.component.scss']
})
export class MemberDeductDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MemberDeductDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {
  }

  ngOnInit() {
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

}
