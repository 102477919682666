import { HttpClient } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { AgentReport } from '@core/models/agent-report.model';
import { Pagination } from '@core/models/pagination.model';
import { AgentReportSummary } from '@core/models/agent-report-summary.model';
import { Observable, Subject } from 'rxjs';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { ApiResponse } from '@core/models/api-response.model';
import { map, tap } from 'rxjs/operators';
import { TransactionReport } from '@core/models/transaction-report.model';

@Injectable()
export class AgentReportDataService extends DefaultDataService<AgentReport> {

  pagination: Pagination;
  summary: AgentReportSummary;
  messages$ = new Subject<any[]>();
  date = this.transactionHttpService.getToday();
  from = this.date.from;
  to = this.date.to;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator,
    private transactionHttpService: TransactionHttpService
  ) {
    super('AgentReport', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<AgentReport[]> {
    return this.http.get<ApiResponse>(`/agent/report`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows),
    );
  }

  getWithQuery(pageParam: string): Observable<AgentReport[]>{
    return this.http.get<ApiResponse>(`/agent/report?paginate=1${pageParam}`).pipe(
      tap(res => {
        this.paginationInit(res);
        this.getSummary(res);
      }),
      map(res => res.data.rows)
    )
  }

  getTransaction(pageParam: string): Observable<TransactionReport[]> {
    return this.http.get<ApiResponse>(`/report/transactionreport${pageParam}`).pipe(
      map(res => res.data.rows)
    )
  }

  private paginationInit(res: any) {
    if(res) {
      return this.pagination = res.data.paginations;
    }
  }

  private getSummary(res: any) {
    if(res) {
      return this.summary = res.data.summary;
    }
  }
}
