<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Rebate Settings</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <!-- Filter Form -->
      <form class="row align-items-center kt-form" [formGroup]="filterForm">
        <!-- Game Provider -->
        <div class="col-md-3 kt-margin-b-20-tablet-and-mobile">
          <div class="kt-form__label">
            Game Provider
          </div>
          <div class="kt-form__control">
            <select (change)="onFilter('provider')" formControlName="game_provider_id" class="form-control">
              <option *ngFor="let value of dropdown.providers" [ngValue]="value.id">{{ value.code }}</option>
            </select>
          </div>
        </div>
        <!-- Game Category -->
        <div class="col-md-3 kt-margin-b-20-tablet-and-mobile">
          <div class="kt-form__label">
            Game Category
          </div>
          <div class="kt-form__control">
            <select (change)="onFilter('category')" formControlName="category_id" class="form-control">
              <option *ngFor="let value of dropdown.categories" [ngValue]="value.category_id">{{ value.category_name }}
              </option>
            </select>
          </div>
        </div>
        <!-- Currency -->
        <div class="col-md-3 kt-margin-b-20-tablet-and-mobile">
          <div class="kt-form__label">
            Currency
          </div>
          <div class="kt-form__control">
            <select (change)="onFilter()" formControlName="currency_id" class="form-control">
              <option *ngFor="let value of dropdown.currencies" [ngValue]="value.id">{{value.name}}</option>
            </select>
          </div>
        </div>
        <!-- Loading Icon -->
        <div class="col-md-3 kt-margin-b-20-tablet-and-mobile">
          <div class="kt-form__label">
            <i class="fas fa-circle-notch fa-spin mt-4" *ngIf="isLoading"></i>
          </div>
        </div>
      </form>
      <br>
      <!-- Content / Table -->
      <table class="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <th>Game Provider</th>
            <th>Category</th>
            <th>Currency</th>
            <th>Rebate</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let currency of dropdown.currencies">
            <ng-container
              *ngIf="filterForm.get('currency_id').value === null ? currency.id !== null : filterForm.get('currency_id').value == currency.id">
              <td>{{ provider.code }}</td>
              <td>{{ category.category_name }}</td>
              <td>{{ currency.name }}</td>
              <td>
                <select class="form-control" (change)="updateData(currency.id, $event.target.value)">
                  <option [value]="null">--</option>
                  <ng-container *ngFor="let value of rebateSettings">
                    <option [value]="value.id" [selected]="currency.rebate_setting_id === value.id">{{ value.name }}
                    </option>
                  </ng-container>
                </select>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Action button -->
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
      <kt-submit-button [isDisabled]="!validForm" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
    </div>
  </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>