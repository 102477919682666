import { FormGroup, FormControl, Validators } from '@angular/forms';
import { catchError, tap } from 'rxjs/operators';
import { MemberPromotionDataService } from './../services/member-promotion-data.service';
import { MemberPromotionEntityService } from '@views/pages/apps/general/member-promotions/services/member-promotion-entity.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MemberPromotion } from '@core/models/member-promotion.model';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  templateUrl: './member-promotion-edit.component.html',
  styleUrls: ['./member-promotion-edit.component.scss']
})
export class MemberPromotionEditDialogComponent implements OnInit, OnDestroy {

  messages$ = this.memberPromotionDataService.messages$;
  form: FormGroup;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;

  cancelRemarkText: String = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { memberPromotion: MemberPromotion, mode: string },
    public dialogRef: MatDialogRef<MemberPromotionEditDialogComponent>,
    private memberPromotionEntityService: MemberPromotionEntityService,
    private memberPromotionDataService: MemberPromotionDataService
  ) { }

  ngOnInit() {
    if (this.data.mode === 'cancel') {
      this.calculateBonusWinAmount(this.data.memberPromotion);
    } else
    this.dialogRef.disableClose = true;
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(memberPromotion: MemberPromotion, mode?: string){
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      id: memberPromotion ? memberPromotion.id : null,
      ...this.form.value
    };

    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to ${mode} this member promotion?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        if (mode === 'cancel'){
          this.subscription = this.memberPromotionDataService.cancel(data).pipe(
            tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
            throw error;
          })
          ).subscribe();
        }else{
          this.subscription = this.memberPromotionDataService.unlock(data).pipe(
            tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
            throw error;
          })
          ).subscribe();
        }
      }else{
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
      }
    });
    this.refreshStatus = true;
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  calculateBonusWinAmount(memberPromotion : MemberPromotion) {
    let amount = 0;
    let bonusAmount = 0;
    let winAmount = 0;
    const total_promo_amount = +memberPromotion.amount +  +memberPromotion.bonus_amount;
    if ((+memberPromotion.balance > total_promo_amount && +memberPromotion.accumulated_target_amount > 0) || +memberPromotion.balance < total_promo_amount) {
      amount = +memberPromotion.balance;
    } else {
      amount = total_promo_amount;
    }

    if (amount > 0) {
      if (amount > total_promo_amount) {
        bonusAmount = +memberPromotion.bonus_amount;
        winAmount = amount - total_promo_amount;
      } else if (amount == total_promo_amount) {
        bonusAmount = +memberPromotion.bonus_amount;
        winAmount = 0;
      } else {
        if (amount > +memberPromotion.bonus_amount) {
          bonusAmount = +memberPromotion.bonus_amount;
          winAmount = 0;
        } else {
          bonusAmount = amount;
          winAmount = 0;
        }
      }
    }

    const forfeitedAmount = bonusAmount + winAmount;
    const walletBalance = +memberPromotion.balance - forfeitedAmount;
    this.cancelRemarkText = 'Operator cancelled promotion, Forfeited Amount: ' + memberPromotion.currency_code + ' ' + forfeitedAmount.toFixed(2) + ', Wallet Balance :' + ' ' + memberPromotion.currency_code + ' ' + walletBalance.toFixed(2);
  }

  private formInit() {
    let remark = null;
    if (this.data.mode === 'unlock') {
      remark = this.data.memberPromotion.remarks === '-' ? null : this.data.memberPromotion.remarks;
    } else if (this.data.mode === 'cancel') {
      remark = this.cancelRemarkText;
    }
    this.form = new FormGroup({
      remarks: new FormControl(remark, [Validators.required]),
    });

  }
}
