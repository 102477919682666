import { AuthHttpService } from '@core/services/auth-http.service';
import { MemberInformationDialogComponent } from './../../general/members/dialogs/member-information/member-information.component';
import { MemberDataService } from './../../general/members/services/member-data.service';
import { VipEditDialogComponent } from './dialogs/vip-edit.component';
// Service
import { DropdownHttpService } from "@core/services/dropdown-http.service";
import { EventEmitterService } from "@core/services/event-emitter.service";
import { TransactionHttpService } from "@core/services/transaction-http.service";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { VipMemberVerificationDataService } from "../../general/vip-verification/services/vip-member-verification-data.service";
import { AppPermissionService } from '@core/services/app-permission.service';

// Angular & Core & NGRX
import {
  AfterViewInit,
  ChangeDetectorRef, Component, OnDestroy, OnInit
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { VipVerificationReason } from "@core/enums/vip-verification-reason.enum";
import { VipVerificationStatus } from "@core/enums/vip-verification-status.enum";
import { Dropdown } from "@core/models/dropdown.model";
import { Group } from "@core/models/group.model";
import { Pagination } from "@core/models/pagination.model";
import { VipMemberVerification } from "@core/models/vip-member-verification.model";
// RXJS
import { Observable, of, Subscription, throwError } from "rxjs";
import { map, tap, catchError, exhaustMap } from "rxjs/operators";
// Third Party
import moment from "moment";
import * as moment2 from 'moment-timezone';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: "./all-vips.component.html",
    styleUrls: ["./all-vips.component.scss"],
})
export class AllVipsComponent
  implements OnInit, OnDestroy, AfterViewInit {
  form: FormGroup;
  messages$ = this.vipMemberVerificationDataService.messages$;
  dropdown = {
    groups: [],
    statuses: this.dropdownHttpService.verificationStatuses,
    reason: this.dropdownHttpService.reason,
    perPage: this.dropdownHttpService.perPage,
  };
  dropdownSettings = {};
  vipLevelDropdownSettings = {};
  vipLevelSelectedItems = [];
  vipVerificationStatus = VipVerificationStatus;
  vipVerificationReason = VipVerificationReason;
  members$: Observable<VipMemberVerification[]>;
  searchStatus = [0, 1];

  reasonDropdownSettings = {};
  reasonSelectedItems = [];
  ranges = this.transactionHttpService.ranges;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;

  interactiveInput = {
    disableUsername: false,
    disableName: false,
    disableBankAcc: false,
    disableID: false,
    disableAgent: false,
    disableGA_username: false,
    disableStatus: false,
    disableCurrency: false,
    disableGroup: false,
    disableContact: false,
    disableDate: false,
    disableAffiliate: false,
    disableIp: false,
  }

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = "";

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '13.1';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'level': 'desc',
    'currency_id': 'desc',
    'total_deposits': 'desc',
    'reason': 'desc', // 1:Upgrade / 2: Downgrade
    'remarks': 'desc',
    'status': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc'
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  dateTimeFilterType = [
    {
      key: 'created_at',
      label: 'Created Date'
    },
    {
      key: 'updated_at',
      label: 'Updated Date'
    },
  ]
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  buttonLoading = false;
  dataLength: number;

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private memberGroup: Dropdown[];
  private isAdmin: any;

  canViewMemberDialog: boolean;
  canCreateVip: boolean;
  canEditVip: boolean;
  canApproveVip: boolean;
  canRejectVip: boolean;
  canExportVip: boolean;

  constructor(
    private loadingBar: LoadingBarService,
    private transactionHttpService: TransactionHttpService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private dropdownHttpService: DropdownHttpService,
    private eventEmitterService: EventEmitterService,
    private memberDataService: MemberDataService,
    private vipMemberVerificationDataService: VipMemberVerificationDataService,
    private authHttpService: AuthHttpService,
    private appPermissionService: AppPermissionService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.accountTypeDropdownList = this.dropdownHttpService.accountTypesLists;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    
    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.formInit();

    this.memberGroupsInit();
    this.pagination = this.vipMemberVerificationDataService.pagination;
    this.dropdownSettings = {
      singleSelection: true,
      text: "Please Select",
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: "dropdown",
      primaryKey: "username",
      labelKey: "username",
      lazyLoading: true,
      noDataLabel: "",
      showCheckbox: false
    };

    this.vipLevelDropdownSettings = {
      autoPosition: false,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false,
      lazyLoading: false
    };

    this.reasonDropdownSettings = {
      autoPosition: false,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false,
      lazyLoading: false
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.vip_view_member_dialog;
      this.canCreateVip = appPermissions.create_vip;
      this.canEditVip = appPermissions.edit_vip;
      this.canApproveVip = appPermissions.approve_vip_upgrade;
      this.canRejectVip = appPermissions.reject_vip_upgrade;
      this.canExportVip = appPermissions.export_all_vips_list;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if(key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if(this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : "";
    this.loadingBar.start();
    return (this.members$ = this.vipMemberVerificationDataService
      .getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`)
      .pipe(
        tap((res) => {
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.dataLength = res.length;
          this.pagination = this.vipMemberVerificationDataService.pagination;
          this.loadingBar.complete();
        })
      ));
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  getMemberGroupName(id: number) {
    if (this.memberGroup) {
      return id > 0
        ? (this.memberGroup.find((row) => row.id === id) as Group).name
        : "-";
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.searchStatus = [0, 1];
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    this.dropdown.statuses.forEach((child) => {
      if (child.name !== 'Pending' && child.name !== 'Approved') {
        child.checked = false;
      } else {
        child.checked = true;
      }
    });
    this.onClearDate();
    let username = localStorage.getItem('vip_username') ?? null;
    localStorage.removeItem('vip_username')

    this.form.patchValue({
      username: username,
      member_group_ids: this.dropdown.groups.map(x => x.id),
      status: this.searchStatus,
      reasons: null,
      datetime_type: 'created_at',
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
    });
     
    this.vipLevelSelectedItems = [];
    this.reasonSelectedItems = Object.assign([], this.dropdown.reason);
    this.cdr.detectChanges();
    this.onSubmit(true);
    this.eventEmitterService.onClearMemberSearch();
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        tap((data: any) => {
          if (data['start_datetime']) {
            data['start_datetime'] = moment2(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
            data['end_datetime'] = moment2(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          }
          else {
            delete data['datetime_type'];
          }
          this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
          this.loading = false;
          this.dataLength = data.length;
          if(data['account_type']){
            data['account_type'] = this.form.controls['account_type'].value.toString();
          }
          const statusParams = this.generateStatusParams();
          const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
          this.params = paramsRef.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
          this.onViewPageBy();
        })
      ).subscribe();
  }

  onExport() {
    this.buttonLoading = true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        exhaustMap((data: any) => {
          const statusParams = this.generateStatusParams();
          const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
          this.params = paramsRef.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
          const params = this.params ? `&${this.params}` : "";
          this.loadingBar.start();
          return (this.vipMemberVerificationDataService
            .export(`?perPage=${this.pageSize}&${this.generateSortingParam()}${params}`)
            .pipe(
              tap((res) => {
                this.loadingBar.complete();
                this.buttonLoading = false;
            }),
            catchError(err => {
              this.loadingBar.complete();
              this.buttonLoading = false;
              return throwError(err);
            })
          ));
        })
      ).subscribe();
  }

  onSearchStatus(event: any, id: number, ind: number) {
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }

    this.form.patchValue({ status: this.searchStatus });
  }

  onOpenDialog(type: string, row?: any ) {
    if (type === 'create'){
      this.openDialogBy(VipEditDialogComponent, { mode: type });
    } else if (type === 'member-information') {
      if (!this.canViewMemberDialog) {
        Swal.fire('Permission Error', 'You do not have permission to view member information', 'error');
        return;
      }
    
      const member = this.memberDataService.getById(row, `?start_date=${moment(this.transactionHttpService.getYesterday().from).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
      this.subscription = member.pipe(
        tap((res) => {
          if (res) {
            this.openDialogBy(MemberInformationDialogComponent, { mode: 'member-information', member: res });
          }
        })
      )
        .subscribe();
    } else if (type === 'edit'){
      const editVip = this.vipMemberVerificationDataService.getEditById(row.id);
      this.subscription = editVip.pipe(
        tap( (res) => {
          this.openDialogBy(VipEditDialogComponent, { editVip: res, mode: type, member: row});
        })).subscribe();
    } else {
      const vip = this.vipMemberVerificationDataService.getById(row.id);
      this.subscription = vip.pipe(
        tap( (res) => {
          this.openDialogBy(VipEditDialogComponent, { vip: res, mode: type });
      })).subscribe();
    }
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private openDialogBy(componentRef: any, data?: { vip?: any, mode?: any, member?: any, editVip?: any}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'member-information' ? '1500px' : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data: {
        vip: data.vip,
        mode: data.mode,
        member: data.member,
        editVip: data.editVip,
      },
    });

    if (data.mode !== 'member-information'){
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true){
          this.onViewPageBy(this.page).subscribe();
        }
      });
    }
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private memberGroupsInit() {
    this.dropdownHttpService.groups.pipe(tap(res => {
      this.dropdown.groups = res;
      this.memberGroup = res
      this.reasonSelectedItems = Object.assign([], this.dropdown.reason);
      this.form.patchValue({
        'member_group_ids': this.dropdown.groups.map(x => x.id)
      })
      this.cdr.detectChanges();
      this.onClear();
    })).subscribe();
  }

  private formInit() {
    //vip_username will only be used when it is a redirection from the proceed approval link in 1.1 member vip progress bar
    let username = localStorage.getItem('vip_username') ?? null;
    localStorage.removeItem('vip_username')

    this.form = new FormGroup({
      username: new FormControl(username),
      member_group_ids: new FormControl(null),
      status: new FormControl(this.searchStatus),
      reasons: new FormControl(null),
      datetime_type: new FormControl('created_at'),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null),
      reason: new FormControl("all"),
      account_type_condition: new FormControl('Or'),
      account_type: new FormControl([1,2,3]),
    });
  }

  private generateStatusParams() {
    return {
      newStatusParams: Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&'),
      oldStatusParams: 'status=' + Object.keys(this.searchStatus).map(key => this.searchStatus[key]).join(','),
    };
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach((key) =>
      formData[key] !== "" && formData[key] !== null && key !== 'defaultDate'
        ? (fields[key] = formData[key])
        : key
    );
    return fields;
  }

  onClearDate() {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({ 
        defaultDate: null,
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null ? event.startDate._d : event.startDate,
        end_datetime: event.endDate !== null ? event.endDate._d : event.endDate
      });
    }
  }
}
