import { Pagination } from './../models/pagination.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { WalletEvent } from '@core/models/wallet-event.model';

@Injectable()
export class WalletEventHttpService {

  pagination: Pagination;
  constructor(private http: HttpClient) { }

  getWithQuery(pageParam: string): Observable<WalletEvent[]>{
    return this.http.get<ApiResponse>(`/member/getwalletevents${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows.rows)
    )
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.rows.paginations;
    }
  }
}
