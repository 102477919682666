import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { Agent } from '@core/models/agent.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class AgentDataService extends DefaultDataService<Agent>  {

  pagination: Pagination;
  breadcrumb: any;
  breadcrumbLevel: any[];
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Agent', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<Agent[]> {
    return this.http.get<ApiResponse>('/agent').pipe(
      tap(res => {this.paginationInit(res), this.breadcrumbInit(res)}),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<Agent[]>{
    return this.http.get<ApiResponse>(`/agent${pageParam}`).pipe(
      tap(res => {this.paginationInit(res), this.breadcrumbInit(res)}),
      map(res => res.data.rows)
    )
  }

  // Dropdown API
  getAgentList(pageParam: string): Observable<Agent[]>{
    return this.http.get<ApiResponse>(`/agents${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  getUsernames(pageParam: string): Observable<Agent[]>{
    return this.http.get<ApiResponse>(`/agent${pageParam}`).pipe(
      tap(res => {this.paginationInit(res), this.breadcrumbInit(res)}),
      map(res => {
        Object.keys(res.data.rows).forEach(key => {
          res.data.rows[key].custom = res.data.rows[key].id + ' - ' + res.data.rows[key].username.toUpperCase() + ' - ' + res.data.rows[key].name.toUpperCase();
        })
        return res.data.rows})
    )
  }

  add(agent: Agent): Observable<Agent>{
    return this.http.post<ApiResponse>(`/agent`, agent).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    )
  }

  updateAgent( id: number, agent: Agent): Observable<Agent[]>{
    return this.http.put<ApiResponse>(`/agent/${id}`, agent).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    )
  }

  getById(id: number): Observable<Agent>{
    return this.http.get<ApiResponse>(`/agent/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }

  private breadcrumbInit(res:any) {
    if (res){
      this.breadcrumbLevel = res.data.breadcrumb_level ? res.data.breadcrumb_level : [];
      this.breadcrumb = res.data.breadcrumb;
    }
  }
}
