<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Commission Settings</h5>
                <span class="modal-x-button" (click)="onCloseDialog()"><i class="fa fa-times" aria-hidden="true"></i></span>
            </div>
            <div class="modal-body">
                <div class="col-md-12 form-group row">
                    <label class="col-3 col-form-label">Min Win Amount <span class="text-danger">*</span></label>
                    <input type="number" formControlName="min_win_amount" class="col-5 form-control">
                    <span class="col-4 col-form-label">Minimum Win Amount for affiliate commission</span>
                </div>
                <div class="col-12 form-group row">
                    <label class="col-3 col-form-label">Max Amount <span class="text-danger">*</span></label>
                    <input type="number" formControlName="max_amount" class="col-5 form-control">
                    <span class="col-4 col-form-label">Max Amount for each commission calculation</span>
                </div>
                <div class="col-12 form-group row">
                    <label class="col-3 col-form-label">Commission Rate (%)<span class="text-danger">*</span></label>
                    <input type="number" formControlName="commission_rate" class="col-5 form-control">
                    <span class="col-4 col-form-label">Default commission rate if rate tier settings are not set</span>
                </div>
                <div class="col-12 form-group row">
                    <label class="col-3 col-form-label">Sub Affiliate Commission Rate <span class="text-danger">*</span></label>
                    <input type="number" formControlName="sub_affiliate_commission_rate" class="col-5 form-control">
                    <span class="col-4 col-form-label">Default sub commission rate is 0 if not set</span>
                </div>
                <div class="col-12 form-group row">
                    <label class="col-3 col-form-label">Tier Settings </label>
                    <input type="text" class="col-5 form-control" readonly>
                    <span class="col-4 col-form-label">
                        <button *ngIf="this.data.settings.length !== 0" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog()">Tier Rate Settings</button>
                    </span>
                </div>
                <div class="col-12 form-group row">
                    <label class="col-3 col-form-label">Royalty Rate (%)<span class="text-danger">*</span></label>
                    <input type="number" formControlName="royalty_rate" class="col-5 form-control">
                    <span class="col-4 col-form-label">The royalty rate of total winloss amount</span>
                </div>
                <div class="col-12 form-group row">
                    <label class="col-3 col-form-label">Exclude Adjustment</label>
                    <div class="col-8 col-form-label custom-control custom-checkbox">
                        <input type="checkbox" formControlName="exclude_adjustments" class="custom-control-input" id="excludeAdjustment">
                        <label class="custom-control-label text-nowrap" for="excludeAdjustment">{{ form.value.exclude_adjustments ? 'Yes' : 'No' }}</label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true)"></kt-swal-alert>