<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Sample Message</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body" style="max-width: 80vw;">
                <div class="row">
                    <div class="col-12 form-group">
                        <label class="control-label">Message content <span class="text-danger">*</span></label>
                        <ckeditor #editors [config]="editorConfig" [editor]="editor" formControlName="preset_msg"></ckeditor>
                    </div>
                    <div class="col-12 form-group">
                        <label class="control-label">Position <span class="text-danger">*</span></label>
                        <input type="number" formControlName="position" class="col-12 form-control">
                    </div>
                    <div class="col-12 form-group">
                        <label class="control-label">Status <span class="text-danger">*</span></label>
                        <select *ngIf="dropdown.statuses" class="col-12 form-control" formControlName="status">
                          <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
              <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.sampleMessage, data.mode)"></kt-submit-button>
            </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
