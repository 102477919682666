<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div  class="kt-portlet kt-portlet--mobile">
      <div   class="kt-portlet__body">
          <div  class="kt-section">
              <div  class="kt-section__content">
                  <div  class="row col-lg-12">
                      <div  class="col-lg-5">
                          <div   class="kt-margin-b-20-tablet-and-mobile">
                              <div  class="kt-input-icon kt-input-icon&amp;#45;&amp;#45;left">
                                  <form >
                                      <div >
                                          <label> Search By Username:
                                              <input  type="text" class="form-control" style="width: 260px;">
                                          </label>
                                          <ul  id="autocomplete-results" class="autocomplete-results" style="display: none;"></ul>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                      <div  class="row col-lg-12">
                          <div  class="col-lg-3">
                              <label  for="from">From Date</label>
                              <div  class="input-group">
                                  <input  type="text" placeholder="From" id="from" name="from" class="form-control">
                                  <div  class="input-group-append"><span  class="input-group-text"><i  class="la la-calendar-check-o glyphicon-th"></i></span></div>
                              </div>
                          </div>
                          <div  class="col-lg-3">
                              <label  for="from">To Date</label>
                              <div  class="input-group">
                                  <input  type="text" placeholder="To" id="to" name="to" class="form-control">
                                  <div  class="input-group-append"><span  class="input-group-text"><i  class="la la-calendar-check-o glyphicon-th"></i></span></div>
                              </div>
                          </div>
                          <div  class="col-lg-6">
                              <label>Quick Filter</label>
                              <div  class="btn-group btn-group-sm">
                                  <button  class="btn btn-default">Today</button>
                                  <button  class="btn btn-default">Yesterday</button>
                                  <button  class="btn btn-default">This Week</button>
                                  <button  class="btn btn-default">Last Week</button>
                                  <button  class="btn btn-default">This Month</button>
                                  <button  class="btn btn-default">Last Month</button>
                                  <button  class="btn btn-default">This Year</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div  class="kt-portlet__body">
          <div  class="kt-section">
              <div  class="kt-section__content">
                  <div  class="table-responsive table-wrapper">
                      <table  class="table table-bordered table-hover table-striped">
                          <thead>
                              <tr>
                                  <th  class="first-col text-right bg-gray">Platform</th>
                                  <th  colspan="5" class="text-center bg-blue-madison second-col">MAXBET</th>
                                  <th  colspan="5" class="text-center bg-blue-hoki">AG Gaming</th>
                                  <th  colspan="5" class="text-center bg-purple-plum">AllBet</th>
                                  <th  colspan="5" class="text-center bg-blue-dark">SEXY</th>
                                  <th  colspan="5" class="text-center bg-blue-oleo">SPADE</th>
                                  <th  colspan="5" class="text-center bg-blue-madison">PRAGMATIC PLAY</th>
                                  <th  colspan="5" class="text-center bg-blue-hoki">Joker (Gaming World)</th>
                                  <th  colspan="5" class="text-center bg-purple-plum">ACE333</th>
                                  <th  colspan="5" class="text-center bg-blue-oleo">ABS (4D)</th>
                                  <th  colspan="5" class="text-center bg-blue-dark">FGG</th>
                              </tr>
                              <tr>
                                  <th  class="first-col">Username</th>
                                  <th  class="outline-blue-madison text-right">Tickets</th>
                                  <th  class="outline-blue-madison">Valid Rollover</th>
                                  <th  class="outline-blue-madison">Payout</th>
                                  <th  class="outline-blue-madison">SSR Total</th>
                                  <th  class="outline-blue-madison">Company</th>
                                  <th  class="outline-blue-hoki">Tickets</th>
                                  <th  class="outline-blue-hoki">Valid Rollover</th>
                                  <th  class="outline-blue-hoki">Payout</th>
                                  <th  class="outline-blue-hoki">SSR Total</th>
                                  <th  class="outline-blue-hoki">Company</th>
                                  <th  class="outline-purple-wisteria">Tickets</th>
                                  <th  class="outline-purple-wisteria">Valid Rollover</th>
                                  <th  class="outline-purple-wisteria">Payout</th>
                                  <th  class="outline-purple-wisteria">SSR Total</th>
                                  <th  class="outline-purple-wisteria">Company</th>
                                  <th  class="outline-purple-plum">Tickets</th>
                                  <th  class="outline-purple-plum">Valid Rollover</th>
                                  <th  class="outline-purple-plum">Payout</th>
                                  <th  class="outline-purple-plum">SSR Total</th>
                                  <th  class="outline-purple-plum">Company</th>
                                  <th  class="outline-green-haze">Tickets</th>
                                  <th  class="outline-green-haze">Valid Rollover</th>
                                  <th  class="outline-green-haze">Payout</th>
                                  <th  class="outline-green-haze">SSR Total</th>
                                  <th  class="outline-green-haze">Company</th>
                                  <th  class="outline-green-dark">Tickets</th>
                                  <th  class="outline-green-dark">Valid Rollover</th>
                                  <th  class="outline-green-dark">Payout</th>
                                  <th  class="outline-green-dark">SSR Total</th>
                                  <th  class="outline-green-dark">Company</th>
                                  <th  class="outline-purple-plum">Tickets</th>
                                  <th  class="outline-purple-plum">Valid Rollover</th>
                                  <th  class="outline-purple-plum">Payout</th>
                                  <th  class="outline-purple-plum">SSR Total</th>
                                  <th  class="outline-purple-plum">Company</th>
                                  <th  class="outline-green-haze">Tickets</th>
                                  <th  class="outline-green-haze">Valid Rollover</th>
                                  <th  class="outline-green-haze">Payout</th>
                                  <th  class="outline-green-haze">SSR Total</th>
                                  <th  class="outline-green-haze">Company</th>
                                  <th  class="outline-blue-oleo">Tickets</th>
                                  <th  class="outline-blue-oleo">Valid Rollover</th>
                                  <th  class="outline-blue-oleo">Payout</th>
                                  <th  class="outline-blue-oleo">SSR Total</th>
                                  <th  class="outline-blue-oleo">Company</th>
                                  <th  class="outline-blue-dark">Tickets</th>
                                  <th  class="outline-blue-dark">Valid Rollover</th>
                                  <th  class="outline-blue-dark">Payout</th>
                                  <th  class="outline-blue-dark">SSR Total</th>
                                  <th  class="outline-blue-dark">Company</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td  colspan="31" class="text-center">No records available in this date range</td>
                              </tr>
                          </tbody>
                      </table>

                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
