<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Call Logs</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th class="text-center">Date/Time</th>
              <th class="text-center">Call Duration</th>
              <th class="text-center">Label</th>
              <th class="text-center">Voice</th>
            </tr>
          </thead>
          <tbody *ngIf="callLogData.length > 0">
            <ng-container *ngFor="let row of callLogData">
              <tr>
                <td class="text-center" width="300"> {{ (row.call_datetime | timezoneDate: 'YYYY-MM-DD HH:mm') }} </td>
                <td class="text-center" width="200"> {{ row.callduration }} sec</td>
                <td class="text-center" width="200">
                  <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.answer"> {{ row.answer_name ? row.answer_name : '-' }}</span>
                </td>
                <td class="text-center" width="200">
                  <ng-container *ngIf="(row.voice != null && row.voice != ''); else nullTemplate">
                    <a matTooltip="Voice" href="{{ row.voice }}" target="_blank" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"><i class="fa fa-volume-up"></i></a>
                  </ng-container>
                  <ng-template #nullTemplate>
                      -
                  </ng-template>
                </td>
              </tr>
            </ng-container>
            <kt-fallback-row [collection]="callLogData"></kt-fallback-row>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>
