<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>ID:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="id" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="currency_id" (change)="onCurrencyChange()">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <option value="all">All</option>
                                        <ng-container *ngFor="let value of dropdown.currencies">
                                            <option [value]="value.id">{{ value.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Payment Method:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select  class="form-control" formControlName="payment_method">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <option value="all">All</option>
                                        <ng-container *ngFor="let value of dropdown.paymentMethodsDeposit | async">
                                            <option [value]="value.id">{{ value.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Group:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="affiliate_group">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.groups | async"> {{ value.name }} </option>
                                    </select>
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Merchant Bank:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        [form] = 'form'
                                        [dropdownList] = 'dropdown.merchantBanks'
                                        [dropdownSettings] = 'bankaccountDropdownSettings'
                                        [formName] = "'merchant_bank'"
                                        [selectionAttributes] = "'id'"
                                        [selectedItems] = 'selectedBank'>
                                    </kt-dropdown-wo-lazyload>
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Merchant Account:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                    [form] = 'form'
                                    [dropdownList] = 'bankaccountDropdownList'
                                    [dropdownSettings] = 'bankaccountDropdownSettings'
                                    [formName] = "'merchant_bank_account'"
                                    [selectionAttributes] = "'id'"
                                    [selectedItems] = 'selectedMerchantAccount'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                            </div>

                            <div class="col-12 row pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group date">
                                    <input type="text" class="form-control" placeholder="Search"
                                        (change)="onDateRange($event)" formControlName="defaultDate"
                                        [timePicker]="true" [timePickerSeconds]="true"
                                        [alwaysShowCalendars]="true" [ranges]="ranges"
                                        [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                        [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                    ngxDaterangepickerMd/>
                                    <span class="input-group-append">
                                        <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                    </span>
                                    </div>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="d-flex flex-wrap status-filter-pt">
                                        <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.statuses ; let i = index">
                                            <input type="checkbox" [checked]="item.checked" (change)="onSearchStatus($event, status[item.name], i)" class="custom-control-input" [id]="item.name">
                                            <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row mb-3 mt-3">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Affiliate</th>
                                    <th>Payment Method</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('affiliate_group')" [ngClass]="sortingConfig.affiliate_group" [class.sort-selected]="sortingSelection.sort_by === 'affiliate_group'">Group</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('amount')" [ngClass]="sortingConfig.amount" [class.sort-selected]="sortingSelection.sort_by === 'amount'">Amount</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('processing_fee')" [ngClass]="sortingConfig.processing_fee" [class.sort-selected]="sortingSelection.sort_by === 'processing_fee'">Processing Fee</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('confirmed_amount')" [ngClass]="sortingConfig.confirmed_amount" [class.sort-selected]="sortingSelection.sort_by === 'confirmed_amount'">Confirmed Amount</th>
                                    <th class="min-w-150 sort-enabled" (click)="onSortColumn('merchant_bank_account')" [ngClass]="sortingConfig.merchant_bank_account" [class.sort-selected]="sortingSelection.sort_by === 'merchant_bank_account'">Merchant Bank Account</th>
                                    <th>Remarks</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('processing_time')" [ngClass]="sortingConfig.processing_time" [class.sort-selected]="sortingSelection.sort_by === 'processing_time'">Updated By</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="deposits$.length > 0">
                                <tr *ngFor="let row of deposits$">
                                    <td>{{ row.id }}</td>
                                    <td>
                                        <a class="text-primary font-weight-bold" (click)="onViewInfo('information', row)">
                                            {{ row.username }}
                                        </a>
                                    </td>
                                    <td>{{ row.payment_method }}</td>
                                    <td class="text-center">
                                        <span [class]="'kt-badge kt-badge--inline kt-badge--pill kt-badge--wide kt-badge--' + row.status"> {{ row.status_name }}</span>
                                    </td>
                                    <td class="text-center">{{ row.affiliate_group }}</td>
                                    <td class="text-center">{{ row.currency }}</td>
                                    <td class="text-right">{{ row.amount | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.processing_fee | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.confirmed_amount | number : '1.2-2' }}</td>
                                    <td>{{ row.merchant_bank_account }}</td>
                                    <td>{{ row.remarks }}</td>
                                    <td>
                                        {{ row.created_by ? row.created_by : 'System' }}<br/>
                                        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        {{ row.handler ? row.handler : 'System' }}<br/>
                                        <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.processing_time | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.processing_time  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                          </ng-template>
                                    </td>
                                    <td class="text-center">
                                        <button matTooltip="Edit" (click)="onOpenDialog(row)" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"><i class="fas fa-edit"></i></button>
                                        <button matTooltip="View Receipt" [disabled]="row.receipt_path === '' || row.receipt_path === null || row.receipt_path === undefined" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenReceipt(row)"><i class="fas fa-receipt"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="deposits$.length" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                      <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                      </ngb-pagination>
                      <div class="kt-pagination__toolbar">
                        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                        </select>
                        <span class="pagination__desc">
                          Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                        </span>
                      </div>
                    </div>
              </div>
          </div>
    </div>
  </div>

