import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ApiResponse } from '@core/models/api-response.model';
import { tap } from 'rxjs/operators';


@Injectable()
export class ManualSyncHttpService {

  messages$ = new Subject<any[]>();
  
  constructor(private http: HttpClient) { }

  manualSync(data: any) {
  return this.http.post<ApiResponse>(`/gameprovider/refreshReportData`, data).pipe(
    tap((res) => {
      this.messages$.next(res.message);
    }),
  );
}
}
