import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { GroupHttpService } from '@core/services/group-http.service';
import { Subject, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AllAffiliatesDataService } from '../../services/all-affiliates-data.service';

@Component({
  selector: 'kt-url-test-fire',
  templateUrl: './url-test-fire.component.html',
  styleUrls: ['./url-test-fire.component.scss']
})
export class UrlTestFireDialogComponent implements OnInit {

  form: FormGroup;
  buttonLoading = false;
  messages$ = new Subject<any[]>();
  affiliatePostbackVariables = this.data.affiliatePostbackVariables;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: 'registration' | 'ftd', id: number, postbackMethod: 1 | 2, postbackUrl: string, affiliatePostbackVariables: any },
    public dialogRef: MatDialogRef<UrlTestFireDialogComponent>,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private fb: FormBuilder,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit() {
    const urlParts = this.data.postbackUrl.split(/[?&]/);
    this.formInit(urlParts[0]);
    this.addParamControl(urlParts);
  }

  addParamControl(urlParts): void {
    urlParts.shift();
    const type = this.data.type === 'registration' ? 1 : 2;

    urlParts.forEach(part => {
      const [key, value] = part.split('=');
      if (key && value) {
        const paramsArray = this.form.get('params') as FormArray;
        const matchingParams = this.affiliatePostbackVariables.find(item => item.label == value && (item.type == type || item.type === 'All'));

        paramsArray.push(this.fb.group({
          key: [key, []],
          value: [value, [Validators.required]],
          readonly: [!matchingParams, []],
          isClickid: [value === ':clickid', []]
        }));
      }
    });
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onSubmit() {
    this.buttonLoading = true;

    const baseUrl = this.form.value.postbackUrl;
    const paramsArray = this.form.get('params') as FormArray;

    const queryParams = paramsArray.controls
      .map(control => `${control.get('key').value}=${control.get('value').value}`)
      .join('&');
    const clickidControl = paramsArray.controls.find(control => control.get('isClickid').value == true);

    const data = {
      affiliate_id: this.data.id,
      url: this.form.value.url,
      postback_method: this.data.postbackMethod,
      postback_domain: this.form.value.empty_value ? this.data.postbackUrl : `${baseUrl}?${queryParams}`,
      empty_value: this.form.value.empty_value ? 1 : 0,
      type: this.data.type === 'registration' ? 1 : 2,
      postback_target_type: 1,
      clickid: clickidControl ? clickidControl.get('value').value : null,
    };

    this.allAffiliatesDataService.testPostback(data).pipe(
      tap(res => {
        this.buttonLoading = false;
        this.messages$.next(res);
      }),
      catchError(err => {
        this.buttonLoading = false;
        throw err;
      })
    ).subscribe()
  }

  private formInit(baseurl: string) {
    this.form = new FormGroup({
      url: new FormControl(null, [Validators.required]),
      postbackUrl: new FormControl(baseurl, [Validators.required]),
      empty_value: new FormControl(false),
      params: this.fb.array([]),
    });
  }
}
