<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Upload Receipt ({{ data.deposit.id == undefined ? '-' : data.deposit.id }})</h5>
            <span class="modal-x-button" (click)="onCloseDialog()"><i class="fa fa-times" aria-hidden="true"></i></span>
        </div>
        <div class="modal-body">
          <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">Deposit Receipt <span class="text-danger">*</span></label>
            <div class="col-md-8 form-group required">
              <div class="d-flex mt-2" *ngFor="let receipt of receipts.controls; let i = index">
                <label class="btn btn-default btn-sm image-button">
                  <span>Browse</span>
                  <input class="form-control" type="file" hidden (change)="onUploadFileMulti($event,i)" #myFileInput>
                </label>
                <span class="form-control filename" (click)="myFileInput.click()">
                  <ng-container *ngIf="!isFileUploading[i]; else loadingTemplate">{{ fileName[i] ? fileName[i] : 'Choose Files' }}</ng-container>
                  <ng-template #loadingTemplate>
                    <div class="spinner-wrapper d-flex align-items-center">
                      <mat-progress-spinner style="margin:0 auto;" mode="indeterminate" diameter="25"></mat-progress-spinner>
                    </div>
                  </ng-template>
                </span>
                <button class="btn btn-remove" [disabled]="isFileUploading[i]" (click)="removeReceipt(i)" > <span>x</span></button>
              </div>
              <button *ngIf="receipts.length < file_max_count" class="btn btn-warning mt-4" (click)="addReceipt()"><i class="fa fa-plus" aria-hidden="true"></i> Add </button>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSubmit()"></kt-submit-button>
        </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>