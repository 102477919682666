import { CampaignAdCostResolver } from '@core/resolvers/campaign-ad-cost.resolver';
import { CampaignAdCostDataService } from './campaign-ad-cost/service/campaign-ad-cost-data.service';
import { CampaignAdCostEntityService } from './campaign-ad-cost/service/campaign-ad-cost-entity.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PartialsModule } from '../../../partials/partials.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CampaignResolver } from '@core/resolvers/campaign.resolver';
import { CampaignComponent } from './campaign/campaign.component';
import { CampaignEditDialogComponent } from './campaign/dialogs/campaign-edit.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CampaignEntityService } from './campaign/services/campaign-entity.service';
import { CampaignDataService } from './campaign/services/campaign-data.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { CommonModule, DecimalPipe } from '@angular/common';
import { MarketeerRoutingModule } from './marketeer-routing.module';
import { entityMetadata } from 'app/app.module';
import { EntityDefinitionService, EntityDataService } from '@ngrx/data';
import { NgModule } from '@angular/core';
import { CampaignAdCostComponent } from './campaign-ad-cost/campaign-ad-cost.component';
import { CampaignAdCostDialogComponent } from './campaign-ad-cost/dialogs/campaign-ad-cost-details.component';
import { CampaignAdCostReportDataService } from './campaign-ad-cost-report/services/campaign-ad-cost-report-data.service';
import { CampaignAdCostReportEntityService } from './campaign-ad-cost-report/services/campaign-ad-cost-report-entity.service';
import { CampaignAdCostReportComponent } from './campaign-ad-cost-report/campaign-ad-cost-report.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CampaignMemberListComponent } from './campaign/campaign-member-list/campaign-member-list.component';
import { CampaignReportMemberListComponent } from './campaign-ad-cost-report/campaign-report-member-list/campaign-report-member-list.component';
import { CampaignClicksDialogComponent } from "./campaign-ad-cost-report/campaign-clicks/campaign-clicks.component";

@NgModule({
  declarations: [
    CampaignComponent,
    CampaignAdCostReportComponent,
    CampaignEditDialogComponent,
    CampaignAdCostComponent,
    CampaignAdCostDialogComponent,
    CampaignMemberListComponent,
    CampaignReportMemberListComponent,
    CampaignClicksDialogComponent
  ],
  imports: [
    PartialsModule,
    NgbModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MarketeerRoutingModule,
    NgSelectModule,
    NgxDaterangepickerMd.forRoot()
  ],
  providers: [
    DecimalPipe,
    CampaignDataService,
    CampaignEntityService,
    CampaignResolver,
    CampaignAdCostEntityService,
    CampaignAdCostDataService,
    CampaignAdCostResolver,
    CampaignAdCostReportDataService,
    CampaignAdCostReportEntityService,
  ]
})
export class MarketeerModule {
  constructor(
    private eds: EntityDefinitionService,
    private entityDataService: EntityDataService,
    private campaignDataService: CampaignDataService,
    private campaignAdCostDataService: CampaignAdCostDataService,
    private campaignAdCostReportDataService: CampaignAdCostReportDataService
  ) {
    eds.registerMetadataMap(entityMetadata);
    entityDataService.registerServices({
      CampaignAdCost: campaignAdCostDataService,
      CampaignAdCostReport: campaignAdCostReportDataService,
      Campaign: campaignDataService
    });
  }
 }
