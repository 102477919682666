import { Observable, Subject } from 'rxjs';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { MerchantBank } from '@core/models/merchant-bank.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { map, tap, concatMap } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';

@Injectable()
export class MerchantBankDataService extends DefaultDataService<MerchantBank> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('MerchantBank', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<MerchantBank[]> {
    return this.http.get<ApiResponse>('/merchantbank').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(merchantBank: MerchantBank): Observable<MerchantBank> {
    return this.http.post<ApiResponse>(`/merchantbank`, merchantBank).pipe(
      tap(res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/merchantbank/${entity.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  getWithQuery(pageParam: string): Observable<MerchantBank[]> {
    return this.http.get<ApiResponse>(`/merchantbank${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  getById(id: number): Observable<MerchantBank> {
    return this.http.get<ApiResponse>(`/merchantbank/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  toggleStatus(id: number, status: number, remarks: string, merchantBankTransConfigId: number) {
    return this.http.put<ApiResponse>(`/merchantbank/statusupdate?id=${id}&merchant_bank_transaction_configs_id=${merchantBankTransConfigId}&status=${status}&remarks=${remarks}`, {}).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getRemarksHistory(merchantBankId: number, merchantBankTransConfigId: number) {
    return this.http.get<ApiResponse>(`/merchantbank/remarks?paginate=0&sort_by=created_at&sort_order=DESC&merchant_bank_id=${merchantBankId}&merchant_bank_transaction_configs_id=${merchantBankTransConfigId}`).pipe(
      map(res => res.data.rows)
    );
  }

  // togglePaymentGatewayStatus(id: number, status: number) {
  //   return this.http.put<ApiResponse>(`/merchantbank/paymentgateway/statusupdate?merchant_bank_payment_gateway_id=${id}&status=${status}`, {}).pipe(
  //     tap(res => this.messages$.next(res.message))
  //   );
  // }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

}
