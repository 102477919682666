import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { catchError, tap } from 'rxjs/operators';
import { ApplicationPermissionDetails } from '@core/models/application-permission-details.model';
import { ApplicationHttpService } from '@core/services/application-http.service';
import { Subscription } from 'rxjs';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  templateUrl: './application-permission-edit.component.html',
  styleUrls: ['./application-permission-edit.component.scss']
})

export class ApplicationPermissionEditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;

  buttonLoading = false;

  messages$ = this.applicationHttpService.permissionMessages$;

  // permissions
  canEditPermission: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { permissionDetails: ApplicationPermissionDetails },
    public dialogRef: MatDialogRef<ApplicationPermissionEditDialogComponent>,
    private applicationHttpService: ApplicationHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditPermission = appPermissions.edit_permission;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCloseDialog(success: boolean = false) {
    this.dialogRef.close(success);
  }

  onSave() {
    this.buttonLoading = true;
    try {
      var formDetails = this.form.controls['details'].value, data = [];
      Object.keys(formDetails).forEach(item => {
        data.push(formDetails[item]);
      });
      this.applicationHttpService.updatePermission(this.data.permissionDetails.id, data).pipe(
        tap(() => this.buttonLoading = false),
        catchError((err) => {
          this.buttonLoading = false;
          throw err;
        })
      ).subscribe();
    } catch {
      this.buttonLoading = false;
    }
  }

  private formInit() {
    const buildDetails = () => {
      var details = {};
      this.data.permissionDetails.details.forEach(item => {
        const data = new FormGroup({
          id: new FormControl(item.id, [Validators.required]),
          display_title: new FormControl(item.display_title, [Validators.required]),
          description: new FormControl(item.description, [Validators.required])
        });
        details = { ...details, [item.lang_code]: data }
      });
      return details;
    };
    this.form = new FormGroup({
      details: new FormGroup(buildDetails())
    });
  }
}
