import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { LeadRemarks } from '@core/models/lead-remarks.model';

@Injectable()
export class LeadRemarksEntityService extends EntityCollectionServiceBase<LeadRemarks> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('LeadRemarks', serviceElementsFactory);
    }
}


