import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';

@Injectable()
export class TelemarketerSettingsDataService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(private http: HttpClient) { }

  getWithQuery(pageParam: string): Observable<[]> {
    return this.http.get<ApiResponse>(`/telemarketersetting${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(data: any) {
    return this.http.post<ApiResponse>(`/telemarketersetting`, data).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }

  update(id: number, data: any) {
    return this.http.put<ApiResponse>(`/telemarketersetting/${id}`, data).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }

  toggleStatus(id: number, param: any) {
    return this.http.put<ApiResponse>(`/telemarketersetting/statusupdate?id=${id}&status=${param.status}`, param).pipe(
      tap(res =>  this.messages$.next(res.message))
    );
  }

  getLogById(id: number, param: any) {
    return this.http.get<ApiResponse>(`/telemarketersetting/log/${id}${param}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
