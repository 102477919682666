import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Agent } from '@core/models/agent.model';

@Injectable()
export class AgentEntityService extends EntityCollectionServiceBase<Agent> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('Agent', serviceElementsFactory);
  }
}
