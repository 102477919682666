import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BonusHunterAnalysis } from '@core/models/bonus-hunter-analysis.model';
import { BonusHunterAnalysisHttpService } from '@core/services/bonus-hunter-analysis-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { MemberDataService } from '../../services/member-data.service';
import { MemberInformationDialogComponent } from '../member-information/member-information.component';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, shareReplay, tap, map, exhaustMap } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import moment from 'moment';
import { AppPermissionService } from '@core/services/app-permission.service';
import { DateTime } from 'luxon';
@Component({
  selector: 'kt-bonus-hunter-analysis',
  templateUrl: './bonus-hunter-analysis.component.html',
  styleUrls: ['./bonus-hunter-analysis.component.scss']
})
export class BonusHunterAnalysisComponent implements OnInit, OnDestroy {
  @Input()
  member: any;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  memberInfo$: Observable<BonusHunterAnalysis[]>;
  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  params = '';
  form: FormGroup;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: this.dropdownHttpService.memberStatuses
  };
  memberInfoConfig = {
    loading: false,
    page: 1,
    pagination: null,
  }
  maxSize = 5;
  pageSize = 15;
  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '1.1.1';
  sortingConfig = {
    'username': 'desc',
    'category': 'desc',
    'name': 'desc',
  }
  sortingSelection = {
    'sort_by': 'username',
    'sort_order': 'asc',
  };

  // permissions
  canViewGeneralAnalysis: boolean;
  canViewMemberAnalysis: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    public dialog: MatDialog,
    private bonusHunterAnalysisHttpService: BonusHunterAnalysisHttpService,
    private memberDataService: MemberDataService,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.formInit();
    this.getMemberInfoAnalysis();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewGeneralAnalysis = appPermissions.view_general_analysis;
      this.canViewMemberAnalysis = appPermissions.view_member_analysis;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
    this.timezoneDateWithTimezone();
  }

  timezoneDateWithTimezone() {
    let todayDate = DateTime.now().setZone(this.timezone).toFormat('(ZZ)');
    todayDate = todayDate.replace('(', '(GMT ');
    return todayDate;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getMemberInfoAnalysis(page = 1) {
    this.memberInfoConfig.loading = true;

    this.memberInfo$ = this.bonusHunterAnalysisHttpService.getMemberInfo(`?member_account_id=${this.member.id}&${this.params}&page=${page}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
      shareReplay(),
      tap(res => {
        this.memberInfoConfig.pagination = this.bonusHunterAnalysisHttpService.pagination;
        this.memberInfoConfig.loading = false;
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
      }),
      catchError((error) => {
        this.memberInfoConfig.loading = false;
        return throwError(error);
      })
    )
  }

  getUrl(site: string) {
    let url = new URL(site);
    return url.host;
  }

  urlIsValid(url: string) {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }

  onOpenDialog(row: any) {
    const member = this.memberDataService.getById(row, `?start_date=${moment(this.transactionHttpService.getYesterday().from).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
    this.subscription = member.pipe(
      tap((res) => {
        if (res) {
          this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information', });
        }
      })
    ).subscribe();
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;
    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.getMemberInfoAnalysis();
  }

  onTypes(event: any, filter: string) {
    this.form.patchValue({
      [filter]: event.target.checked ? 1 : 0
    });
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value)
    .pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.params = Object.keys(data)
          .map((key) => key + "=" + data[key])
          .join("&");
      })
    ).subscribe();
    this.getMemberInfoAnalysis();
  }

  onClear() {
    this.formInit();
    this.onSubmit(true);
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== false) {
        fields[key] = key === 'start_datetime' || key === 'end_datetime'
          ? moment(formData[key]).utc().format('YYYY-MM-DD HH:mm:ss')
          : (formData[key] === true ? 1 : formData[key]);
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      status: new FormControl('all'),
      currency_id: new FormControl('all'),
      name: new FormControl(0),
      password: new FormControl(0),
      email: new FormControl(0),
      ip: new FormControl(0),
      fingerprint: new FormControl(0),
    });
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any }) {
    this.dialog.open(componentRef, {
      width: '1500px',
      height: '80vh',
      autoFocus: false,
      data
    });
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

}
