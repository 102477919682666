<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <mat-tab-group animationDuration="0ms">
              <ng-container>
                <mat-tab label="Banking" *ngIf="canViewBankingWithdrawals">
                  <ng-template mat-tab-label>
                    Banking <span *ngIf="bankingCount" class="ml-2 count-number">{{ bankingCount }} </span>
                  </ng-template>
                  <kt-withdrawal withdrawalBankType="banking"></kt-withdrawal>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label *ngIf="canViewCryptoWithdrawals">
                    Crypto <span *ngIf="cryptoCount" class="ml-2 count-number">{{ cryptoCount }} </span>
                  </ng-template>
                  <kt-withdrawal withdrawalBankType="crypto"></kt-withdrawal>
                </mat-tab>
              </ng-container>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
