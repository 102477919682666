
<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"> Edit Deposit</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="container p-0">
            <div class="row">
                <section class="col-lg-6 card border-0">
                    <div class="col-12 p-0 card rounded-0">
                        <div class="card-header">
                            Affiliate Info
                        </div>
                        <section class="col-12 p-4">
                            <div class="row border-top border-left">
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right p-3">
                                    <span class="kt-font-bold">Affiliate ID</span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right p-3">
                                    {{ data.deposit.affiliate_id }}
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right p-3">
                                    <span class="kt-font-bold">Username</span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right p-3">
                                    {{ data.deposit.username }}
                                </div>
                            </div>
                            <div class="row border-top border-left">
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                                    <span class="kt-font-bold">Name</span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                                    {{ data.deposit.name }}
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                                    <span class="kt-font-bold">Group</span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                                    {{ data.deposit.affiliate_group }}
                                </div>
                            </div>
                            <div class="row border-left">
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                                    <span class="kt-font-bold">Remarks</span>
                                </div>
                                <div class="col-lg-9 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                                    {{ data.deposit.affiliate_remarks }}
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section class="col-lg-6 card border-0">
                    <div class="col-12 p-0 card rounded-0">
                        <div class="card-header">
                            Bank Info
                        </div>
                        <div class="col-12 border-top-0 p-4">
                            <section class="row border-top border-left">
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                                    <span class="kt-font-bold">Bank Name</span>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 border-right border-bottom p-3">
                                    {{ data.deposit.merchant_bank }}
                                </div>
                            </section>
                            <section class="row border-left">
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                                    <span class="kt-font-bold">Account Number</span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                                    {{ data.deposit.account_number }}
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                                    <span class="kt-font-bold">Account Name</span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                                    {{ data.deposit.account_name }}
                                </div>
                            </section>
                            <section class="row border-left">
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                                    <span class="kt-font-bold">Select Merchant Bank</span>
                                </div>
                                <div class="col-lg-9 col-md-3 col-sm-6 col-xs-12 d-flex align-items-center border-right border-bottom p-3">
                                    <kt-dropdown-wo-lazyload *ngIf="data.deposit.payment_method === 'Bank/Online Transfer'"
                                        [form] = 'form'
                                        [dropdownList] = 'bankaccountDropdownList'
                                        [dropdownSettings] = 'bankaccountDropdownSettings'
                                        [formName] = "'merchant_bank_details'"
                                        [selectionAttributes] = "'value'"
                                        [selectedItems] = 'bankaccountSelectedItems'
                                        class="w-100">
                                    </kt-dropdown-wo-lazyload>
                                    <span *ngIf="data.deposit.payment_method !== 'Bank/Online Transfer'">-</span>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </div>

            <div class="row mt-2">
                <section class="col-12 card border-0">
                    <div class="col-12 p-0 card rounded-0">
                        <div class="card-header">
                            Deposit Info
                        </div>
                        <section class="col-12 p-4">
                            <div class="row border-top border-left">
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                                    <span class="kt-font-bold">ID</span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                                    {{ data.deposit.id }}
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                                    <span class="kt-font-bold">Created At</span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                                    {{ data.deposit.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                                </div>
                            </div>
                            <div class="row border-left">
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                                    <span class="kt-font-bold">Payment Method</span>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 d-flex align-items-center border-right border-bottom p-3">
                                    {{ data.deposit.payment_method }}
                                </div>
                            </div>
                            <div class="row border-left">
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                                    <span class="kt-font-bold">Status</span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                                    <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + depositStatus"> {{ data.deposit.status_name }}</span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                                    <span class="kt-font-bold">Confirmed Amount ({{data.deposit.currency_code}})</span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                                    {{ data.deposit.confirmed_amount | number : '1.2-2' }}
                                </div>
                            </div>
                            <div class="row border-left">
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                                    <span class="kt-font-bold">Handler</span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                                    {{ currentUsername }}
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                                    <span class="kt-font-bold">Processing Time</span>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                                    {{ data.deposit.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                                </div>
                            </div>
                            <div class="row border-left">
                                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                                    <span class="kt-font-bold">Remarks</span>
                                </div>
                                <div class="input-group col-lg-9 col-md-9 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                                    <input type="text" formControlName="remarks" class="form-control" [ngClass]="{ 'is-invalid': this.form.value.errors }">
                                    <span class="input-group-append" *ngIf="data.deposit.status === 1">
                                        <button type="button" class="btn btn-success" (click)="onUpdateRemarks()">Update</button>
                                    </span>
                                    <label *ngIf="!isValidRemarks" class="col-12 col-form-label">
                                        <mat-error>
                                            <strong>Remarks required to reject</strong>
                                        </mat-error>
                                    </label>
                                </div>
                            </div>
                            <div class="form-control border-0 col-9 border p-1 ml-n3 mt-2" *ngIf="depo.status !== 2">
                                <button (click)="openReceipt()" [disabled]="data.deposit.receipt_path === '' || data.deposit.receipt_path === null || data.deposit.receipt_path === undefined" type="button" class="btn btn-primary mr-2">View Receipt</button>
                            </div>
                            <div class="form-control border-0 col-9 border p-1 ml-n3 mt-2" *ngIf="depo.status === 2">
                                <button (click)="openReceipt()" type="button" [disabled]="data.deposit.receipt_path === '' || data.deposit.receipt_path === null || data.deposit.receipt_path === undefined" class="btn btn-primary mr-2">View Receipt</button>
                            </div>
                        </section>
                    </div>
                </section>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button [type]="depositStatus === 3 ? 'button' : 'submit'" *ngIf="depositStatus === 3 || depositStatus === 0" class="btn btn-danger" [disabled]="!this.form.valid" (click)="onSave(data.deposit, 'reject')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fa fa-ban'"></i>Reject</button>
          <button [type]="depositStatus === 3 ? 'submit' : 'button'" *ngIf="depositStatus === 3" class="btn btn-success" [disabled]="!this.form.valid" (click)="onSave(data.deposit, 'approve')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Approve</button>
        </div>
      </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
