import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GameProvider } from '@core/models/game-provider.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import * as moment from 'moment-timezone';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { forkJoin, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MaintenanceHoursDataService } from '../services/maintenance-hours-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';


@Component({
  selector: 'kt-maintenance-hours-edit',
  templateUrl: './maintenance-hours-edit.component.html',
  styleUrls: ['./maintenance-hours-edit.component.scss']
})
export class MaintenanceHoursEditDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;
  form: FormGroup;

  messages$ = this.maintenanceHoursDataService.messages$;
  refreshStatus: boolean;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    categories: [],
    gameProviders: [],
    remarks: [
      'Scheduled Maintenance',
      'Emergency Maintenance'
    ],
  };


  currencyDropdownSettings = {};
  currencySelectedItems = [];

  dateTimeStack = {
    maintain_from: null,
    maintain_to: null
  };

  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  dateTimeFormat = 'yyyy-MM-dd HH:mm:';

  // permissions
  canCreateGameProviderMaintenanceHour: boolean;
  canEditGameProviderMaintenanceHour: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();

  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { gameProvider: any, mode: string, maintenanceMode?: string },
    public dialogRef: MatDialogRef<MaintenanceHoursEditDialogComponent>,
    private maintenanceHoursDataService: MaintenanceHoursDataService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private dropdownHttpService: DropdownHttpService,
    private gameProviderHttpService: GameProviderHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.dropdownHttpService.gameProviders.subscribe(res => {
      this.dropdown.gameProviders = res;
    });

    this.setSelectedCurrency();

    this.dateTimeStack = {
      maintain_from: new Date(new Date(this.data.gameProvider.maintain_from).getTime() + this.offset - this.clientOffset),
      maintain_to: new Date(new Date(this.data.gameProvider.maintain_to).getTime() + this.offset - this.clientOffset),
    };

    this.formInit();

    this.currencyDropdownSettings = {
      autoPosition: true,
      maxHeight: 150,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: true,
      enableSearchFilter: false,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      disabled: this.data.maintenanceMode !== 'create',
      showCheckbox: false
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateGameProviderMaintenanceHour = appPermissions.create_game_provider_maintenance_hour;
      this.canEditGameProviderMaintenanceHour = appPermissions.edit_game_provider_maintenance_hour;
    });

    this.subscription.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    this.onRefresh();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'maintain_from'),
      this.buildDatePicker(1, 'maintain_to')
    ]).subscribe();
  }

  setSelectedCurrency(){
    const selectCurrency = () => {
      if (this.data.mode === 'maintenance' && this.data.maintenanceMode !== 'create') {
        this.data.gameProvider.currency_id.map( (currencyId: number) => {
          this.currencySelectedItems.push(this.dropdown.currencies.filter(x => +x.id === +currencyId)[0]);
        });
      }
    };
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
        selectCurrency();
      });
    }else{
      selectCurrency();
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(gameProvider: GameProvider) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    // Create variable to hold the value will be pass to API
    const data = {
      ...this.form.getRawValue(),
      maintain_from: this.data.mode === 'maintenance' ? (this.form.value.maintain_from === '0000-00-00 00:00:00' ? '0000-00-00 00:00:00' : moment(this.form.value.maintain_from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')) : null,
      maintain_to: this.data.mode === 'maintenance' ? (this.form.value.maintain_to === '0000-00-00 00:00:00' ? '0000-00-00 00:00:00' : moment(this.form.value.maintain_to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')) : null
    };
    // Remove unused param
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] === 'Invalid date') && delete data[key]);

    // Create game provider maintenance hour
    if (this.data.maintenanceMode === 'create') {
      this.maintenanceHoursDataService.createGameProviderMaintenanceHour(data).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
    // Edit game provider maintenance hour
    else {
      // Remove unused param
      Object.keys(data).forEach((key) => (key === 'id' || key === 'game_provider_code' || key === 'category_code' || key === 'currency_id') && delete data[key]);
      this.maintenanceHoursDataService.updateGameProviderMaintenanceHour(this.data.gameProvider.maintenance_id, data).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onResetMaintenanceForm() {
    // Create game provider maintenance hour
    if (this.data.maintenanceMode === 'create') {
      this.form.patchValue({
        game_provider_code: null,
        category_code: null,
        maintain_from: '0000-00-00 00:00:00',
        maintain_to: '0000-00-00 00:00:00',
        remarks: null
      });
    }
    // Edit game provider maintenance hour
    else {
      this.form.patchValue({
        maintain_from: '0000-00-00 00:00:00',
        maintain_to: '0000-00-00 00:00:00',
        remarks: null
      });
    }
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, index === 0 ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      })
    );
  }

  private formInit() {
    // Declare variable to hold value else set to default value
    const gameProviderId = this.data.gameProvider.game_provider_id ?? null;
    const gameProviderCode = this.data.gameProvider.game_provider_code ?? null;
    const categoryCode = this.data.gameProvider.category_code ?? null;
    const maintainFrom = this.data.gameProvider.maintain_from === '' ? '0000-00-00 00:00:00' : moment(new Date(new Date(this.data.gameProvider.maintain_from).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss');
    const maintainTo = this.data.gameProvider.maintain_to === '' ? '0000-00-00 00:00:00' : moment(new Date(new Date(this.data.gameProvider.maintain_to).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss');
    const remarks = this.data.gameProvider.remarks ?? null;
    const currencyID = this.data.gameProvider.currency_id ?? null;

    // If have game provider id then get and set the game category list
    if (this.data.gameProvider.game_provider_id) {
      this.setGameCategoryList(this.data.gameProvider.game_provider_id);
    }

    // Create form
    this.form = new FormGroup({
      id: new FormControl(gameProviderId),
      game_provider_code: new FormControl({ value: gameProviderCode, disabled: this.data.maintenanceMode === 'edit' }, [Validators.required]),
      category_code: new FormControl({ value: categoryCode, disabled: this.data.maintenanceMode === 'edit' }, [Validators.required]),
      maintain_from: new FormControl(maintainFrom, [Validators.required]),
      maintain_to: new FormControl(maintainTo),
      remarks: new FormControl(remarks),
      currency_id: new FormControl(currencyID, [Validators.required])
      // status: new FormControl(this.data.gameProvider.status), NOT NEEDED FOR NOW SO COMMENTED
      // position: new FormControl(this.data.gameProvider.position), NOT NEEDED FOR NOW SO COMMENTED
    });

    // Declare on change listener for value game_provider_code
    this.form.get('game_provider_code').valueChanges.subscribe((value) => {
      this.dropdown.categories = [];
      if (value !== null) {
        this.form.patchValue({ category_code: null });
        const selectedGameProvider = this.dropdown.gameProviders.find(x => x.code === value);
        this.setGameCategoryList(selectedGameProvider.id);
      }
    });
  }

  private setGameCategoryList(game_provider_id: number) {
    this.gameProviderHttpService.getGameCategory(game_provider_id).subscribe(res => {
      this.dropdown.categories = res;
    })
  }

}
