import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { WebPushMessages } from '@core/models/web-push-messages.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class WebPushMessagesDataService extends DefaultDataService<WebPushMessages> {

  messages$ = new Subject<any[]>();
  pagination: Pagination;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('WebPushMessages', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<WebPushMessages[]> {
    return this.http.get<ApiResponse>(`/webpush${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  export(pageParam: string) {
    return this.http.get<ApiResponse>(`/webpush/export${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getMemberDetails(id: number, pageParam?: string): Observable<WebPushMessages> {
    const newPageParam = pageParam ? pageParam : '';
    return this.http.get<ApiResponse>(`/member/${id}${newPageParam}`).pipe(
      map(res => res.data.rows)
    );
  }

  sendWebpush(data: any): Observable<any> {
    return this.http.post<ApiResponse>(`/webpush/send`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      return this.pagination = res.data.paginations;
    }
  }
}
