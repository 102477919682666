<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Agent Reports</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
            <kt-member-report-list [isAgent]="true" [onSearch]="!onSearch" [data]="agentReport$" [summary]="summary$" [form]="form" [pageSize]="pageSize" [page]="page" [maxSize]="maxSize" [pagination]="pagination" [params]="params" [listType]="'all-member-report'"></kt-member-report-list>
       </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        </div>
    </div>
</div>
