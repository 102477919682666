import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderPermissionsDataService } from '../../service/providers-permissions-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'kt-provider-permissions-edit',
  templateUrl: './provider-permissions-edit.component.html',
  styleUrls: ['./provider-permissions-edit.component.scss']
})
export class ProviderPermissionsEditDialogComponent implements OnInit {

  form: FormGroup;
  messages$ = this.providerPermissionsDataService.messages$;
  dropdown = {
    region: [],
    smsType: [],
    status: this.dropdownHttpService.statuses,
  };
  regionDropdownSettings = {};
  regionSelectedItems = [];
  selectedRegion = [];
  smsTypeDropdownSettings = {};
  smsTypeSelectedItems = [];
  selectedSmsType = [];
  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, id: number, permissions: any },
    public dialogRef: MatDialogRef<ProviderPermissionsEditDialogComponent>,
    private providerPermissionsDataService: ProviderPermissionsDataService,
    private dropdownHttpService: DropdownHttpService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.formInit();
    this.initDropdownSettings();
    this.onGetDropdown();
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onSave() {
    this.buttonLoading = true;
    const data = {
      ...this.form.value
    }
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    if (this.data.mode === 'edit') {
      this.providerPermissionsDataService.updateProvider(this.data.id, data).pipe(
        tap(res => {
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
  }

  onGetDropdown() {
    this.dropdownHttpService.regions.subscribe(res => {
      this.dropdown.region = res;
      if (this.data.permissions) {
        this.selectedRegion = this.dropdown.region.filter(x => this.data.permissions.region.includes(x.country_code));
      }
    });
    this.dropdownHttpService.smsTypes.subscribe(types => {
      this.dropdown.smsType = types;
      if (this.data.permissions) {
        this.selectedSmsType = this.dropdown.smsType.filter(x => this.data.permissions.sms_type.includes(x.id));
      }
    });
  }

  onRemoveItem(item: number, input?: string) {
    switch (input) {
      case 'region': {
        const result = this.selectedRegion.filter((i: any) => i.country_code !== item);
        this.selectedRegion = result;
        this.updateDropdownSelection(this.selectedRegion, 'region');
        break;
      }
      case 'smsType': {
        const result = this.selectedSmsType.filter((i: any) => i.id !== item)
        this.selectedSmsType = result;
        this.updateDropdownSelection(this.selectedSmsType, 'smsType');
        break;
      }
    }
  }

  private updateDropdownSelection(selectedItem: any, input: string) {
    if (input === 'region') {
      this.form.patchValue({
        regions: selectedItem.map(i => i.country_code)
      });
    } else if (input === 'smsType') {
      this.form.patchValue({
        sms_type: selectedItem.map(i => i.id)
      });
    }
    this.cdr.detectChanges();
  }

  private initDropdownSettings() {
    this.regionDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'country_code',
      labelKey: 'country_code',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false,
      maxHeight: 200,
    }
    this.smsTypeDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'name',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false,
      position: 'bottom',
      autoPosition: false,
      maxHeight: 150,
    }
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(this.data.permissions.id),
      code: new FormControl(this.data.mode === 'edit' ? this.data.permissions.code : null),
      name: new FormControl(this.data.mode === 'edit' ? this.data.permissions.name : null, [Validators.required]),
      regions: new FormControl(this.data.mode === 'edit' ? this.data.permissions.region : null, [Validators.required]),
      sms_type: new FormControl(this.data.mode === 'edit' ? this.data.permissions.sms_type : null, [Validators.required]),
      status: new FormControl(this.data.mode === 'edit' ? this.data.permissions.status : null, [Validators.required])
    });
  }
}
