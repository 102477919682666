import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { LayoutConfigService, TranslationService } from '@core/_base/layout';

@Component({
  selector: 'kt-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {

  today: number = Date.now();
  headerLogo: string;

  constructor(
  private el: ElementRef,
  private render: Renderer2,
  private layoutConfigService: LayoutConfigService,
  private translationService: TranslationService) {
  }
  ngOnInit(): void {
    this.translationService.setLanguage(this.translationService.getSelectedLanguage());
    this.headerLogo = this.layoutConfigService.getLogo();
  }

  private loadCSS(styleUrl: string) {
    return new Promise((resolve, reject) => {
      const styleElement = document.createElement('link');
      styleElement.href = styleUrl;
      styleElement.type = 'text/css';
      styleElement.rel = 'stylesheet';
      styleElement.onload = resolve;
      this.render.appendChild(this.el.nativeElement, styleElement);
    });
  }
}
