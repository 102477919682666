import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { SmsProvider } from '@core/models/sms-provider.model';

@Injectable()
export class ProvidersEntityService extends EntityCollectionServiceBase<SmsProvider>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('SmsProvider', serviceElementsFactory)
  }
}
