import { AllAffiliateReport } from '@core/models/all-affiliates-report.model';
import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';

@Injectable()
export class AllAffiliateReportDataService extends DefaultDataService<AllAffiliateReport>  {

    pagination: Pagination;
    messages$ = new Subject<any[]>();

    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
        super('AllAffiliateReport', http, httpUrlGenerator, { root: '' });
    }

    getAll(): Observable<AllAffiliateReport[]> {
        return this.http.get<ApiResponse>('/report/allaffiliatesreport?paginate=true').pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    getWithQuery(pageParam: string): Observable<AllAffiliateReport[]>{
        return this.http.get<ApiResponse>(`/report/allaffiliatesreport?paginate=true${pageParam}`).pipe(
            tap(res => {
                this.paginationInit(res);
            }),
            map(res => res.data.rows)
        );
    }

    exportReport(pageParam: string) {
        return this.http.get<ApiResponse>(`/report/exportallaffiliatesreport${pageParam}`).pipe(
            tap(res => this.messages$.next(res.message))
        );
    }

    private paginationInit(res: any) {
        if (res){
            this.pagination = res.data.paginations;
        }
    }
}
