// Angular
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { MatFormFieldModule } from '@angular/material/form-field';

import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';

import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { AuthEffects } from '@core/store/auth/auth.effects';
import { AuthHttpService } from '@core/services/auth-http.service';
import { AuthGuard } from '@core/guards/auth.guard';
import { ResetPasswordDialogComponent } from './login/dialog/reset-password.component';


const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
      path: '',
      redirectTo: 'auth',
      pathMatch: 'full'
      },
      {
      path: 'auth',
      component: LoginComponent,
      data: {
        title: 'Auth',
        returnUrl: window.location.pathname
      }
      },
    ],
    data: {title: 'Auth'}
  },
  {
    path: '',
    component: ResetPasswordDialogComponent
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    MatFormFieldModule,
    TranslateModule.forChild(),
    EffectsModule.forFeature([AuthEffects])
  ],
  providers: [],
  exports: [AuthComponent],
  declarations: [
    AuthComponent,
    LoginComponent,
    ResetPasswordDialogComponent
  ]
})

export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
  return {
    ngModule: AuthModule,
    providers: [
      AuthHttpService,
      AuthGuard
    ]
  };
  }
}
