import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SystemSetting } from '@core/models/system-setting.model';
import { catchError, tap } from 'rxjs/operators';
import { SystemSettingDataService } from './services/system-setting-data.service';

@Component({
  selector: 'kt-system-setting',
  templateUrl: './system-setting.component.html',
  styleUrls: ['./system-setting.component.scss']
})
export class SystemSettingComponent implements OnInit {

  buttonLoading = false;
  data: SystemSetting[];
  messages$ = this.systemSettingService.messages$;

  constructor(
    private systemSettingService: SystemSettingDataService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getSystemSetting();
  }

  getSystemSetting() {
    this.data = [];
    this.systemSettingService.getWithQuery(`?paginate=false`).subscribe((res)=> {
      this.data = res;
      this.cdr.detectChanges();
    });
  }

  onSectionValueChange(section, event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    let obj = this.data.find(obj => obj.name === section.name);
    for (const key in obj.option) {
      if (Object.keys(obj.option[key]).length > 0) {
        for (const key2 in obj.option[key]) {
          obj.option[key][key2] = +isChecked;
        }
      }else {
        obj.option[key] = +isChecked;
      }
    }
  }

  onValueChange(section, options, option, event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    let obj = this.data.find(obj => obj.name === section.name);
    if (option === undefined) {
      if (Object.keys(options.value).length > 0) {
        for (const key in obj.option[options.key]) {
          obj.option[options.key][key] = +isChecked;
        }
      }else {
        obj.option[options.key] = obj.option[options.key] === 0 ? 1 : 0;
      }
    }else {
      obj.option[options.key][option.key] = obj.option[options.key][option.key] === 0 ? 1 : 0;
    }
  }

  getCheckedStatus(section, options) {
    let isAllChecked = 1;

    if ((Object.keys(options.value).length > 0)) {
      let obj = this.data.find(obj => obj.name === section.name);
      for (const key in obj.option[options.key]) {
        if (obj.option[options.key][key] === 0) {
          isAllChecked = 0;
        }
      }
    } else {
      isAllChecked = options.value;
    }

    return isAllChecked;
  }

  getSectionCheckedStatus(section) {
    let isAllChecked = 1;

    let obj = this.data.find(obj => obj.name === section.name);
    for (const key in obj.option) {
      if (Object.keys(obj.option[key]).length > 0) {
        for (const key2 in obj.option[key]) {
          if(obj.option[key][key2] === 0) {
            isAllChecked = 0;
          }
        }
      }else {
        if (obj.option[key] === 0) {
          isAllChecked = 0;
        }
      }
    }

    return isAllChecked;
  }

  onCloseDialog() {
    this.getSystemSetting();
  }

  onSubmit() {
    this.buttonLoading = true;
    let settings = {};
    let option = {};
    for (const key in this.data) {
      option[this.data[key].id] = this.data[key].option;
    }
    settings['_method'] = 'PUT';
    settings['option'] = option;
    this.systemSettingService.update(settings).pipe(
      tap((res) => {
        this.buttonLoading = false;
        this.messages$.next([...res.message]);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

}