import { CommissionReleases } from '@core/models/commission-releases.model';
import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';

@Injectable()
export class CommissionReleasesDataService extends DefaultDataService<CommissionReleases>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('CommissionReleases', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<CommissionReleases[]> {
    return this.http.get<ApiResponse>('/affiliate/commission/index').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data)
    );
  }

  getWithQuery(pageParam: string): Observable<CommissionReleases[]> {
    return this.http.get<ApiResponse>(`/affiliate/commission/index${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data)
    )
  }

  getById(id: number): Observable<CommissionReleases> {
    return this.http.get<ApiResponse>(`/affiliate/commission/index/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  generateCommission(data: any) {
    return this.http.post<ApiResponse>(`/affiliate/generatecomm`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  release(data: any) {
    return this.http.post<ApiResponse>(`/affiliate/commission/release`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  reject(data: any) {
    return this.http.post<ApiResponse>(`/affiliate/commission/reject`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getConversionRate() {
    return this.http.get<ApiResponse>(`/affiliate/commission/conversionrate`).pipe(
      map(res => res.data)
    );
  }

  export(pageParam: any) {
    return this.http.post<ApiResponse>(`/affiliate/commission/export`, pageParam).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
