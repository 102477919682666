import { FormGroup, FormControl, Validators } from '@angular/forms';
import { tap, catchError } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2'
import { BankReceiptDataService } from '../../services/bank-receipt-data.service';
import { BankReceipt } from '@core/models/bank-receipt.model';
import { Pagination } from '@core/models/pagination.model';
import { BankReceiptStatus } from '@core/enums/bank-receipt.enum';
import { DropdownHttpService } from '@core/services/dropdown-http.service';

@Component({
  templateUrl: './bank-receipt-remarks.component.html',
  styleUrls: ['./bank-receipt-remarks.component.scss']
})
export class BankReceiptRemarksDialogComponent implements OnInit, OnDestroy {

  messages$ = this.bankReceiptDataService.messages$;
  form: FormGroup;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  bankReceipt = this.data.bankReceipt;
  remarks$: Observable<any>;
  pagination: Pagination;
  page = 1;
  pageSize = 30; 
  status = BankReceiptStatus;
  dropdown = {
    perPage: this.dropdownHttpService.perPage
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bankReceipt: any },
    public dialogRef: MatDialogRef<BankReceiptRemarksDialogComponent>,
    private bankReceiptDataService: BankReceiptDataService,
    private dropdownHttpService: DropdownHttpService
  ) { }

  ngOnInit() {
    this.onViewPageBy();
    
  }

  ngOnDestroy() {

  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize);
  }

  onViewPageBy(page = 1, pageSize?: number) {
    pageSize = this.pageSize;
    return this.remarks$ = this.bankReceiptDataService.getRemarks(`?bank_transaction_id=${this.bankReceipt.id}&page=${page}&perPage=${pageSize}`).pipe(
      tap(res => {
        this.pagination = this.bankReceiptDataService.pagination;
      })
    );
  }
}
