import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { AllMemberVerification } from '@core/models/allmember-verification.model';
import { MemberVerification } from '@core/models/member-verification-model';

@Injectable()
export class MemberVerificationDataService extends DefaultDataService<AllMemberVerification>  {

  messages$ = new Subject<any[]>();
  pagination: Pagination;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('MemberVerification', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<any[]>{
    return this.http.get<ApiResponse>(`/member/verification${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  updateVerification(data: any) {
    return this.http.put<ApiResponse>(`/member/verification/update/${data.id}`, data).pipe(
      tap(res => {
        this.messages$.next(res.message)
      })
    );
  }

  getVerifyStatus(username: any): Observable<AllMemberVerification>{
    return this.http.get<ApiResponse>(`/member/verification?username=${username}`).pipe(
      map(res => res.data.rows),
    )
  }

  private paginationInit(res: any) {
    if(res) {
      return this.pagination = res.data.paginations;
    }
  }
}
