import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AllAffiliatesDataService } from '../../services/all-affiliates-data.service';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'kt-adjust-group',
  templateUrl: './adjust-group.component.html',
  styleUrls: ['./adjust-group.component.scss']
})
export class AdjustGroupDialogComponent implements OnInit {

  form: FormGroup;
  messages$ = this.allAffiliatesDataService.messages$;
  dropdown = {
    groups: this.dropdownHttpService.affiliateGroups
  }
  buttonLoading = false;
  hasError: boolean;
  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { affiliate: any },
    public dialogRef: MatDialogRef<AdjustGroupDialogComponent>,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit() {
    this.formInit();
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onSubmit() {
    this.buttonLoading = true;
    this.subscription = this.allAffiliatesDataService.adjustGroup(this.form.value).pipe(
      tap((res) => {
        this.hasError = !!res.success;
        this.messages$.next([...res.message]);
        this.form.setErrors(null);
        this.buttonLoading = false;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(this.data.affiliate.id, [Validators.required]),
      affiliate_group_id: new FormControl(this.data.affiliate.affiliate_group_id, [Validators.required])
    });
  }
}
