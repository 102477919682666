import { AllAgentReportSummary } from '@core/models/all-agent-report-summary.model';
import { Pagination } from '@core/models/pagination.model';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { map, exhaustMap, tap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { SubheaderService } from '@core/_base/layout';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { of, Subscription, Observable } from 'rxjs';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment-timezone';
import { AllAgentReport } from '@core/models/all-agent-report.model';
import { AllAgentReportEntityService } from './services/all-agent--report-entity.service';
import { AllAgentReportDataService } from './services/all-agent-report-data.service';
import { MatDialog } from '@angular/material/dialog';
import { AllAgentReportsDialogComponent } from './dialogs/report-details/all-agent-reports-dialog.component';
import { CurrencyHttpService } from '@core/services/currency-http.service';

@Component({
  selector: 'kt-all-agent-report',
  templateUrl: './all-agent-report.component.html',
  styleUrls: ['./all-agent-report.component.scss']
})
export class AllAgentReportComponent implements OnInit, OnDestroy {

  form: FormGroup;
  agentReport$: Observable<AllAgentReport[]>;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  paginationTotal = 1;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  timezoneDropdownSettings = {
    singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown clear-all-disabled',
      primaryKey: 'offset',
      labelKey: 'offset',
      noDataLabel: '',
      showCheckbox: false
  };
  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones')),
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage
  };
  pagination: Pagination;
  ranges = this.transactionHttpService.ranges;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  dropdownSettings = {};
  summary$: AllAgentReportSummary;
  from = this.transactionHttpService.getLast24Hours().from;
  to = this.transactionHttpService.getLast24Hours().to;
  params = `start_date_time=${moment(this.from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date_time=${moment(this.to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&currency_id=1`;

  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;

  private subscription = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private subheaderService: SubheaderService,
    private loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    public dialog: MatDialog,
    private allAgentEntityService: AllAgentReportEntityService,
    private allAgentDataService: AllAgentReportDataService,
    private currencyHttpService: CurrencyHttpService
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.pagination = this.allAgentDataService.pagination;
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.selectedTimezone = this.timezone;
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      maxHeight: 200,
      noDataLabel: '',
      showCheckbox: false
    };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClear() {
    let default_currency_id: any = null;
    if (this.dropdown.currencies.length > 0) {
      default_currency_id = Object.values(this.dropdown.currencies)[0]['id'];
    }

    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.form.patchValue({
      agent_username: null,
      currency_id: default_currency_id,
      start_date_time: this.from,
      end_date_time: this.to,
      timezone: this.timezone,
      defaultDate: {
        startDate: this.from,
        endDate: this.to
      }
    });
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.onSubmit(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.summary$ = undefined;
    this.loading = true;
    this.loadingBar.start();
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    return this.agentReport$ = this.allAgentEntityService.getWithQuery(`&page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.allAgentDataService.pagination;
        this.summary$ = this.allAgentDataService.summary;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  sanitizeNumericField(previousValue: any) {
    const value = String(previousValue).replace(/[,]+/, '');
    return value;
  }

  private openDialogBy(componentRef: any, data?: { agent_username: string, currency_id: number, start_date_time: string, end_date_time: string, timezone: string }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '1500px',
      data: {
        agent_username: data.agent_username,
        currency_id: data.currency_id,
        start_date_time: this.form.get('start_date_time').value,
        end_date_time: this.form.get('end_date_time').value,
        timezone: data.timezone,
      }
    });
  }

  onOpenDialog(report: any) {
    const data = {
      agent_username: report.agent,
      currency_id: this.form.value.currency_id,
      start_date_time: moment(this.form.get('start_date_time').value).tz(this.form.value.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date_time: moment(this.form.get('end_date_time').value).tz(this.form.value.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      timezone: this.form.value.timezone,
    };
    this.openDialogBy(AllAgentReportsDialogComponent, data);
  }

  onSubmit(clearSearch?: boolean) {
    this.summary$ = undefined;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['currency_id'] === undefined || data['currency_id'] === null || data['currency_id'] === 0) {
          if (this.dropdown.currencies.length > 0) {
            data['currency_id'] = Object.values(this.dropdown.currencies)[0]['id'];
            this.form.patchValue({currency_id : data['currency_id']});
          }
        }

        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        this.loading = true;
        return this.agentReport$ = this.allAgentEntityService.getWithQuery(`&${this.params}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.allAgentDataService.pagination;
            this.summary$ = this.allAgentDataService.summary;
            this.page = 1;
            this.loadingBar.complete();
            this.onSearch = true;
          })
        );
      }),
    ).subscribe();
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      agent_username: new FormControl(null),
      currency_id: new FormControl(null), // Default
      start_date_time: new FormControl(this.from),
      end_date_time: new FormControl(this.to),
      timezone: new FormControl(this.timezone),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      }) // Do not remove: For Clearing The Range
    });
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }
}
