<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Icon</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
            <div class="col-12 form-group row">
                <label class="col-4 col-form-label"> Name <span class="text-danger">*</span> </label>
                <input type="text" min="1" formControlName="name"  class="col-8 form-control">
            </div>
            <div class="col-12 form-group row">
                <label class="col-form-label col-4"> Section Name <span class="text-danger">*</span></label>
                <kt-dropdown-wo-lazyload
                    class="col-8"
                    style="padding:0"
                    [form] = 'form'
                    [dropdownList] = 'sectionsDropdownList'
                    [dropdownSettings] = 'sectionsDropdownSettings'
                    [formName] = "'section_id'"
                    [selectionAttributes] = "'id'"
                    [selectedItems] = 'sectionsSelectedItems'>
                </kt-dropdown-wo-lazyload>
            </div>
            <div class="col-12 form-group row">
                <label class="col-form-label col-4"> Merchant <span class="text-danger">*</span></label>
                <ng-container *ngFor="let value of merchantsSelectedItems">
                    <div class="col-8 p-0">
                        <input type="text" class="form-control" [attr.disabled]="true" [value]="value.name">
                    </div>
                </ng-container>
            </div>
            <div class="col-12 form-group row">
                <label class="col-4 col-form-label"> Image <span class="text-danger">*</span> </label>
                <div class="col-8 m-0 p-0">
                    <div class="row m-0 p-0">
                        <label class="btn btn-default btn-sm">
                            <span>Choose file</span>
                            <input type="file" hidden accept="image/*" (change)="onUploadFile($event)">
                        </label>
                        <div class="spinner-wrapper" *ngIf="loading">
                            <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                        </div>
                    </div>
                    <div class="image-wrapper" *ngIf="data.rows || imagePreview.length > 0">
                        <img class="img-fluid" alt="Image Banner" [src]="imagePreview[0] || data.rows.icon_path" />
                    </div>
                </div>
            </div>
            <div class="col-12 form-group row">
                <label class="col-form-label col-4"> Link With <span class="text-danger">*</span></label>
                <select *ngIf="dropdown.linkwith" (change)="onSelectLinkWith($event, data.mode)" formControlName="link_with" class="col-8 form-control">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <option *ngFor="let value of dropdown.linkwith | async" [value]="value.id">{{ value.name }}</option>
                  </select>
            </div>
            <div class="col-12 form-group row" *ngIf="contact_platform == true || contactExist == true">
                <label class="col-form-label col-4"> Social Platform <span class="text-danger">*</span></label>
                <kt-dropdown-wo-lazyload
                    class="col-8"
                    style="padding:0"
                    [form] = 'form'
                    [dropdownList] = 'contactPlatformsDropdownList'
                    [dropdownSettings] = 'contactPlatformsDropdownSettings'
                    [formName] = "'link_with_contact_id'"
                    [selectionAttributes] = "'id'"
                    [selectedItems] = 'contactPlatformsSelectedItems'>
                </kt-dropdown-wo-lazyload>
            </div>
            <div class="col-12 form-group row" *ngIf="custom == true || customExist == true">
                <label class="col-4 col-form-label"> Internal Link <span class="text-danger">*</span></label>
                <input type="text" formControlName="link_with_internal_link"  class="col-8 form-control" placeholder="/promotion/code=123">
            </div>
            <div class="col-12 form-group row">
                <label class="col-4 col-form-label"> Status <span class="text-danger">*</span> </label>
                <select *ngIf="dropdown.statuses" formControlName="status" class="col-8 form-control">
                    <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
                </select>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.rows, data.mode)"></kt-submit-button>
        </div>
      </div>
    </form>
  </div>
  
  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
  