import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { AffiliateDeposits } from '@core/models/affiliate-deposits.model';
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Subject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class AffiliateDepositsDataService extends DefaultDataService<AffiliateDeposits>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('AffiliateDeposits', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<AffiliateDeposits[]> {
    return this.http.get<ApiResponse>(`/affiliate/deposit`).pipe(
      tap(res => { this.paginationInit(res) }),
      map(res => res.data.rows)
    )
  }

  getWithQuery(pageParam: string): Observable<AffiliateDeposits[]> {
    return this.http.get<ApiResponse>(`/affiliate/deposit${pageParam}`).pipe(
      tap(res => { this.paginationInit(res) }),
      map(res => res.data.rows)
    )
  }

  getById(id: number): Observable<AffiliateDeposits>{
    return this.http.get<ApiResponse>(`/affiliate/deposit/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  updateCurrentDeposit(deposit: any) {
    return this.http.put<ApiResponse>(`/affiliate/deposit/${deposit.id}`, deposit).pipe(
      tap( res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  updateToNewHolder(deposit: any) {
    return this.http.put<ApiResponse>(`/affiliate/deposit/${deposit.id}`, deposit);
  }

  updateRemarks(deposit: any) {
    return this.http.put<ApiResponse>(`/affiliate/deposit/remarks/${deposit.id}`, deposit).pipe(
      tap( res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}