// Angular
import {
  ElementRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

// Service
import { catchError, map, tap } from "rxjs/operators";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { DepositDataService } from "../../services/deposit-data.service";

@Component({
  selector: "app-deposit-view-receipt",
  templateUrl: "./view-receipt.component.html",
  styleUrls: ["./view-receipt.component.scss"],
  providers: [NgbCarouselConfig],
})
export class ViewReceiptComponent implements OnInit, OnDestroy {
  @ViewChildren("focusfield") focusfield: QueryList<ElementRef>;

  private subscription = new Subscription();

  loading = false;
  remarkLength = 0;
  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { deposit: any },
    public dialogRef: MatDialogRef<ViewReceiptComponent>,
    public depositDataService: DepositDataService,
    config: NgbCarouselConfig,
    public dialog: MatDialog
  ) {
    config.interval = 0;
    config.keyboard = true;
    config.pauseOnHover = true;
    config.showNavigationIndicators = false;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  checkMedia(url: string) {
    const imageExtensions = [".gif", ".jpg", ".jpeg", ".png"]; // you can add more extensions
    const pdfExtensions = [".pdf"]; // you can add more extensions

    const inc = (ext: string) => url.includes(ext);

    if (imageExtensions.some(inc)) {
      return 'image';
    }

    if (pdfExtensions.some(inc)) {
      return 'pdf';
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }
}
