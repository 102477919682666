import { Component, OnDestroy, OnInit } from '@angular/core';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { ProvidersDataService } from './service/providers-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss'],
})
export class ProvidersComponent implements OnInit, OnDestroy {
  loading = false;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    region: [],
  };

  icon: 'info' | 'error' = 'info'; // info || error

  messages$ = this.providersDataService.messages$;
  isAdmin: boolean = JSON.parse(localStorage.getItem('user_data')).is_admin;

  // permissions
  canViewSMSProviders: boolean;
  canViewCredentials: boolean;
  canViewSMSProviderPermissions: boolean;

  private subscriptions = new Subscription();

  constructor(
    private providersDataService: ProvidersDataService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if (this.dropdown.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe(res => {
        this.dropdown.currencies = res;
      });
    }
    this.dropdownHttpService.regions.subscribe(res => {
      this.dropdown.region = res;
    });

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewSMSProviders = appPermissions.view_sms_providers;
      this.canViewCredentials = appPermissions.view_credentials;
      this.canViewSMSProviderPermissions = appPermissions.view_sms_provider_permissions;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSelectTab() {}

  onCloseDialog() {}
}

