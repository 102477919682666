import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { Rewards } from '@core/models/rewards.model';
import { Observable, Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { PlayerReward } from '@core/models/player-reward.model';

@Injectable()
export class RewardsHttpService {

    pagination: Pagination;
    messages$ = new Subject<any[]>();
    data$ = new Subject<any[]>();

    constructor(private http: HttpClient) { }

    getPlayerRewards(param: string): Observable<PlayerReward[]> {
        return this.http.get<ApiResponse>(`/reward${param}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        )
    }

    export(pageParam: string) {
        return this.http.get<ApiResponse>(`/reward/export${pageParam}`).pipe(
          tap(res => this.messages$.next(res.message))
        );
      }

    getRewardsList(pageParam: string): Observable<Rewards[]> {
        return this.http.get<ApiResponse>(`/promotion${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        )
    }

    assign(rewards: { member_account_id: number; promotion_id: number }) {
        return this.http.post<ApiResponse>(`/promotion/assign`, rewards).pipe(     
            tap((res) => {
                this.messages$.next(res.message);
                if (res.data) {
                    this.data$.next(res.data);
                }
            }),
        );
    }

    batchAssign(data: any) {
        return this.http.post<ApiResponse>(`/promotion/batchassign`, data).pipe(     
            tap((res) => {
                this.messages$.next(res.message);
                if (res.data) {
                    this.data$.next(res.data);
                }
            }),
        );
    }

    private paginationInit(res: any) {
        if (res) {
            this.pagination = res.data.paginations;
        }
    }

}
