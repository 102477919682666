import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { RemarkDialogComponent } from '../../dialogs/remark/remark.component';
import { MatDialog } from '@angular/material/dialog';
import { AppPermissionService } from '@core/services/app-permission.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-all-leads-call-log',
  templateUrl: './all-leads-call-log.component.html',
  styleUrls: ['./all-leads-call-log.component.scss']
})
export class AllLeadsCallLogComponent implements OnInit, OnDestroy {

  callLogData: [];

  // permissions
  canEditRemark: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { callLog: any, status: any },
    public dialogRef: MatDialogRef<AllLeadsCallLogComponent>,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.callLogData = this.data.callLog;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditRemark = appPermissions.add_remark;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onOpenDialog(type: string, id?: any, data?: any) {
    if (type === 'remarks') {
      this.openDialogBy(RemarkDialogComponent, { mode: type, leadHistoryID: id, leadID: data, status: this.data.status});
    } 
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, leadHistoryID?: any, leadID?: any, status?: any}) {
    const dialogRef = this.dialog.open(componentRef, {
      data: {
        leadHistoryID: data.leadHistoryID,
        leadID: data.leadID,
        status: data.status
      },
      autoFocus: false
    });
  }
}