<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <!-- Filter -->
            <form class="row align-items-center" [formGroup]="form">
              <!-- First Fow -->
              <div class="col-12 row mb-2 pr-0">
                <!-- Currency -->
                <div class="col-md-1 kt-form__label col-form-label">
                    <label>Available Currency:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="currency_id" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                        {{ value.name }}
                    </option>
                  </select>
                </div>
                <!-- Type -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Verification Type:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="type" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="value.value" *ngFor="let value of dropdown.type">
                        {{ value.name }}
                    </option>
                  </select>
                </div>
                <!-- Bank Type -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Bank Type:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="bank_type" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.bankType">
                        {{ value.name }}
                    </option>
                  </select>
                </div>
                <!-- Status -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="status" class="form-control">
                    <option value="all"> All </option>
                    <ng-container *ngFor="let value of dropdown.status; let i = index;">
                      <option [value]="i">{{ value | translate }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <button *ngIf="canCreateVerificationSettings" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
      </div>

      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="toggle-column-area" (click)="toggleExpandableRows(); onExpandCollpseAll()">
                    <div id="accordion">
                        <span data-toggle="collapse" [attr.data-target]="'#collapse-0'" [attr.aria-expanded]="isRowsExpanded ? true : false"></span>
                    </div>
                  </th>
                  <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">{{ 'ID' | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('type')" [ngClass]="sortingConfig.type" [class.sort-selected]="sortingSelection.sort_by === 'type'">{{ 'Verification Type' | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('currency_code')" [ngClass]="sortingConfig.currency_code" [class.sort-selected]="sortingSelection.sort_by === 'currency_code'">{{ 'Available Currency' | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('bank_type')" [ngClass]="sortingConfig.bank_type" [class.sort-selected]="sortingSelection.sort_by === 'bank_type'">{{ 'Bank Type' | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">{{ 'Status' | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">{{ 'Created By' | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">{{ 'Updated By' | translate }}</th>
                  <th class="text-center">{{ 'Actions' | translate }}</th>
                </tr>
              </thead>
              <tbody *ngIf="(verificationSettings$ | async) as rows">
                <ng-container *ngFor="let row of rows; let i = index">
                  <tr>
                    <td class="c-p" (click)="onExpandCollpse(row.id, i)"> {{ rowIcon[i] }} </td>
                    <td class="c-p" (click)="onExpandCollpse(row.id, i)">{{ row.id }}</td>
                    <td class="c-p" (click)="onExpandCollpse(row.id, i)">{{ row.type_name }}</td>
                    <td>
                      <ng-container *ngIf="row.currency_code.length <= currencyColumnItem">
                        <span *ngFor="let currency_codeD of row.currency_code; let i = index">
                          {{ currency_codeD }}<span *ngIf="i < row.currency_code.length - 1"><br></span>
                        </span>
                      </ng-container>
                    
                      <ng-container *ngIf="row.currency_code.length > currencyColumnItem">
                        <ng-container *ngIf="!showFullContent">
                          <span *ngFor="let currency_codeD of row.currency_code.slice(0, currencyColumnItem); let i = index">
                            {{ currency_codeD }}<span *ngIf="i <= currencyColumnItem - 1"><br></span>
                          </span>
                          <span class="content-span" (click)="toggleContent($event)">[Read more]</span>
                        </ng-container>
                        <ng-container *ngIf="showFullContent">
                          <span *ngFor="let currency_codeD of row.currency_code; let i = index">
                            {{ currency_codeD }} <span *ngIf="i <= row.currency_code.length - 1"><br></span>
                          </span>
                          <span class="content-span" (click)="toggleContent($event)">[Read less]</span>
                        </ng-container>
                      </ng-container>
                    </td>
                    <td class="c-p" (click)="onExpandCollpse(row.id, i)">{{ row.bank_type_name }}</td>
                    <td class="c-p" (click)="onExpandCollpse(row.id, i)"><span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status_name }}</span></td>
                    <td class="c-p" (click)="onExpandCollpse(row.id, i)">{{ row.created_by }} <br *ngIf="row.created_by !== '' && row.created_by !== null"> {{ isValidDate(row.created_at) ? (row.created_at | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }}</td>
                    <td class="c-p" (click)="onExpandCollpse(row.id, i)">{{ row.updated_by }} <br *ngIf="row.updated_by !== '' && row.updated_by !== null"> {{ isValidDate(row.updated_at) ? (row.updated_at | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }}</td>
                    <td class="text-center">
                      <button matTooltip="{{ 'Settings' | translate }}" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)" *ngIf="canEditVerificationSettings"><i class="fas fa-cog"></i></button>
                      <button matTooltip="{{ 'Duplicate' | translate }}" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('duplicate', row.id)" *ngIf="canDuplicateVerificationSettings"><i class="fa fa-clone"></i></button>
                  </td>
                  </tr>
                  <tr class="detail" [id]="'detail-' + row.id">
                    <td colspan="12">
                      <div class="title">Currency</div>
                      <table class="table table-bordered">
                        <thead>
                          <th>Currency</th>
                          <th>Member Group</th>
                          <th>Title</th>
                          <th>Content</th>
                          <th>Field Details</th>
                          <th>Status</th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let info of row.more_info">
                            <td> {{ info.settings_currency_code }} </td>
                            <td class="w-20">
                              <ng-container *ngIf="info.member_group_name != null; else emptyTemplate">
                                <div class="content-element" [@panelState]="getUniqueIdentifier(info) == expandedElement || stripHTMLTags(info.member_group_name).length < 30 ? 'open' : 'closed'" [innerHTML]="onContent(info.member_group_name)"></div>
                                <span class="content-span" *ngIf="stripHTMLTags(info.member_group_name).length >= 30" (click)="toggleReadMore(info)">
                                    {{ getUniqueIdentifier(info) == expandedElement ? '[Read Less]' : '[Read More...]' }}
                                </span>
                              </ng-container>
                              <ng-template #emptyTemplate>
                                -
                              </ng-template>
                            </td>
                            <td>
                              <span *ngIf="info.content && info.content.length > 0">
                                {{ info.content[0].title }}
                              </span>
                            </td>
                            <td class="w-50">
                              <span *ngIf="info.content && info.content.length > 0">
                                <div class="content-element" [@panelState]="getUniqueIdentifier(info) == expandedElement || stripHTMLTags(info.content[0].content).length < 30 ? 'open' : 'closed'" [innerHTML]="onContent(info.content[0].content)"></div>
                                <span class="content-span" *ngIf="stripHTMLTags(info.content[0].content).length >= 30" (click)="toggleReadMore(info)">
                                    {{ getUniqueIdentifier(info) == expandedElement ? '[Read Less]' : '[Read More...]' }}
                                </span>
                              </span>
                            </td>
                            <td class="w-20">
                              <ng-container *ngIf="info.field && info.field.length > 0">
                                <div class="content-element">
                                  <ng-container *ngFor="let fieldD of getDisplayFields(info); let last = last">
                                    <span *ngIf="fieldD.field_details && fieldD.field_details.length > 0">
                                      {{ fieldD.field_details[0].name }} - {{ fieldD.type_name }} - (Position: {{ fieldD.field_details[0].position }})
                                    </span>
                                    <span *ngIf="!last"><br></span>
                                  </ng-container>
                                </div>
                                <span *ngIf="info.field.length > maxNumberofCollapsedField" class="content-span" (click)="toggleReadMore(info)">
                                  {{ getUniqueIdentifier(info) == expandedElement ? '[Read Less]' : '[Read More...]' }}
                                </span>
                              </ng-container>
                            </td>
                            <td class="text-center">
                              <div class="btn-group dropdown">
                                <button type="button" class="btn btn-pill btn-sm dropdown-base"  [ngClass]="'kt-badge--' + info.status"> {{ this.dropdown.status[info.status] }} </button>
                                <ng-container *ngIf="(canUpdateVerificationSettingStatus || canEditVerificationSettings)">
                                  <button type="button" class="btn btn-pill btn-sm dropdown-toggle" [ngClass]="'kt-badge--' + info.status" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <i class="fa fa-angle-down"></i>
                                  </button>
                                  <div class="dropdown-menu">
                                      <ng-container *ngFor="let value of dropdown.status; let i = index">
                                          <button class="dropdown-item" type="button" (click)="onChangeStatus(i, row.id, info.settings_currency_id)" *ngIf="+i >= 0">{{ value }}</button>
                                      </ng-container>
                                  </div>
                                </ng-container>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                {{ 'Showing' | translate }} {{pagination.from}} {{ 'to' | translate }} {{pagination.to}} {{ 'of' | translate }} {{ pagination.total | number : '1.0' }} {{ 'records' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>