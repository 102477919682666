// Angular
import { Component, Input, OnInit } from '@angular/core';
import { WebsocketService } from '@core/services/websocket.service';

@Component({
  selector: 'kt-online-operator',
  templateUrl: './online-operator.component.html',
  styleUrls: ['online-operator.component.scss']
})
export class OnlineOperatorComponent implements OnInit {
  onlineUsers = [];
  searchTerm: string = '';
  userConnectionCounts = new Map<string, number>();

  constructor(private websocketService: WebsocketService) {}

  ngOnInit() {
    this.websocketService.getPresenceChannel('online-users.bo').subscribe(channel => {
      channel.here((users: any[]) => {
        // Initialize the list of online users
        this.onlineUsers = this.deduplicateUsers(users).sort(this.sortUsersByName);
  
        // Initialize connection counts for all users
        users.forEach(user => {
          this.userConnectionCounts.set(user.id, (this.userConnectionCounts.get(user.id) || 0) + 1);
        });
      }).joining((user: any) => {
        // Increment connection count for the joining user
        const currentCount = this.userConnectionCounts.get(user.id) || 0;
        this.userConnectionCounts.set(user.id, currentCount + 1);
  
        // Add the user to the list if it's their first connection
        if (currentCount === 0) {
          this.onlineUsers.push(user);
          this.onlineUsers.sort(this.sortUsersByName);
        }
      }).leaving((user: any) => {
        // Decrement connection count for the leaving user
        const currentCount = this.userConnectionCounts.get(user.id) || 0;
  
        if (currentCount > 1) {
          // More connections are still active; just decrement the count
          this.userConnectionCounts.set(user.id, currentCount - 1);
        } else {
          // Last connection is closing; remove the user
          this.userConnectionCounts.delete(user.id);
          this.onlineUsers = this.onlineUsers.filter(u => u.id !== user.id);
          this.onlineUsers.sort(this.sortUsersByName);
        }
      }).error((error) => {
        console.error('Error accessing channel:', error);
      });
    });

  }

  private deduplicateUsers(users: any[]): any[] {
    const uniqueUsers: any[] = [];
    const seenUserIds = new Set();
  
    for (const user of users) {
      if (!seenUserIds.has(user.id)) {
        uniqueUsers.push(user);
        seenUserIds.add(user.id);
      }
    }
  
    return uniqueUsers;
  }

  getColorByInitial(initial: string): string {
    const firstLetter = initial.charAt(0).toUpperCase();
    const secondLetter = initial.charAt(1)?.toUpperCase() || ''; // Handle case where there's no second letter
  
    const colors = {
      'A': '#f94144', 'B': '#f3722c', 'C': '#f8961e', 'D': '#f9844a',
      'E': '#f9c74f', 'F': '#90be6d', 'G': '#43aa8b', 'H': '#577590',
      'I': '#277da1', 'J': '#4d908e', 'K': '#43aa8b', 'L': '#90be6d',
      'M': '#f9c74f', 'N': '#f3722c', 'O': '#f94144', 'P': '#577590',
      'Q': '#4d908e', 'R': '#43aa8b', 'S': '#90be6d', 'T': '#f8961e',
      'U': '#f3722c', 'V': '#f9844a', 'W': '#277da1', 'X': '#4d908e',
      'Y': '#43aa8b', 'Z': '#f94144',
    };
  
    if (secondLetter) {
      const combinedKey = firstLetter + secondLetter;
      const combinedColor = colors[combinedKey];
      if (combinedColor) {
        return combinedColor;
      }
    }
  
    return colors[firstLetter] || '#007bff';
  }

  private sortUsersByName(a: any, b: any): number {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    return nameA.localeCompare(nameB);
  }
}
