import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { MenuBar } from '@core/models/menu-bar.model';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class MenuBarDataService extends DefaultDataService<MenuBar> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('MenuBar', http, httpUrlGenerator, { root: '' });
  }

  add(menubar?: MenuBar): Observable<MenuBar>{
    return this.http.post<ApiResponse>(`/menubar`, menubar).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<MenuBar[]> {
    return this.http.get<ApiResponse>(`/menubar${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => {
        Object.keys(res.data.rows).forEach(x => {
          let localeList = [];

          Object.keys(res.data.rows[x].contents).forEach(y => {
            if (res.data.rows[x].contents[y].name) {
              localeList.push(res.data.rows[x].contents[y].locale_name);
            }
          })
          res.data.rows[x].locale_list = localeList.toString().split(',').join(', ');

          if (res.data.rows[x].sub_menu && res.data.rows[x].sub_menu.length > 0) {

            res.data.rows[x].sub_menu.forEach(y => {
              let subMenuLocaleList = [];
              Object.keys(y.contents).forEach(z => {
                if (y.contents[z].name) {
                  subMenuLocaleList.push(y.contents[z].locale_name);
                }
              })
              y.locale_list = subMenuLocaleList.toString().split(',').join(', ');
            })

          }
        })
        return res.data.rows
      }
    ));
  }

  updateMenuBar(id: number, param: any) {
    return this.http.put<ApiResponse>(`/menubar/${id}`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  updateStatus(param: any) {
    return this.http.put<ApiResponse>(`/menubar/statusupdate`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  getSVGIcon(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>('/svg').pipe(
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }


}
