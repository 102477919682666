import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from "@angular/material/dialog";
import { CampaignAdCostReport } from '@core/models/campaign-ad-cost-report.model';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { CampaignAdCostReportDataService } from './services/campaign-ad-cost-report-data.service';
import { CampaignAdCostReportEntityService } from './services/campaign-ad-cost-report-entity.service';
import { AppPermissionService } from "@core/services/app-permission.service";
import { CampaignReportMemberListComponent } from './campaign-report-member-list/campaign-report-member-list.component';
import { CampaignClicksDialogComponent } from "./campaign-clicks/campaign-clicks.component";

@Component({
  templateUrl: './campaign-ad-cost-report.component.html',
  styleUrls: ['./campaign-ad-cost-report.component.scss']
})

export class CampaignAdCostReportComponent implements OnInit, OnDestroy {

  form: FormGroup;
  params = '';
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  campaignAdCostReport$: Observable<CampaignAdCostReport[]>;
  perPageDropdown = this.dropdownHttpService.perPage;
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  timezoneDropdownSettings = {
    singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown clear-all-disabled',
      primaryKey: 'offset',
      labelKey: 'offset',
      noDataLabel: '',
      showCheckbox: false
  };
  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones'))
  };
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;

  button_loading = false;
  messages$ = this.campaignAdCostReportDataService.messages$;
  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();

  // permissions
  canExportAdCost: boolean;

  constructor(
    private transactionHttpService: TransactionHttpService,
    private campaignAdCostReportService: CampaignAdCostReportEntityService,
    private campaignAdCostReportDataService: CampaignAdCostReportDataService,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.formInit();
    this.pagination = this.campaignAdCostReportDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.selectedTimezone = this.timezone;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canExportAdCost = appPermissions.export_campaign_ad_reports;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.campaignAdCostReport$ = this.campaignAdCostReportService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.campaignAdCostReportDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClearDate() {
    if (this.form.value.start_date !== null){
      this.form.patchValue({defaultDate: null});
    }
  }

  onDateRange(event: any) {
    if (event){
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      campaign_name: null,
      country: null,
      start_date_time: null,
      end_date_time: null,
      timezone: this.timezone,
      defaultDate: null,
      dummy: 1
    });
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.campaignAdCostReport$ = this.campaignAdCostReportService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.campaignAdCostReportDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onExport() {
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `?${this.params}` : '';
        this.loadingBar.start();
        return this.campaignAdCostReportDataService.exportReport(`${parameters}`).pipe(
          tap(res => {
            this.messages$ = this.campaignAdCostReportDataService.messages$;
            this.loadingBar.complete();
            this.button_loading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.button_loading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private formInit() {
    this.form = new FormGroup({
      campaign_name: new FormControl(null),
      country: new FormControl(null),
      start_date_time: new FormControl(null),
      end_date_time: new FormControl(null),
      timezone: new FormControl(this.timezone),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      dummy: new FormControl(1)
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all' && key !== 'defaultDate' ) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }

  onOpenDialog(type: string, campaignId?: number, campaignName?: any) {
    if (campaignId) {
      if (type === 'campaign-members') {
        this.openDialogBy(CampaignReportMemberListComponent, { mode: type, campaignID: campaignId, form: this.form.value});
      } else if (type === 'campaign-click') {
        this.openDialogBy(CampaignClicksDialogComponent, { mode: type, campaignID: campaignId, form: this.form.value, campaignName: campaignName });

      }
    }
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, campaignID?: number, form?: any, campaignName?: string }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'campaign-members' || data.mode === 'campaign-click' ? '1500px' : '800px',
      data: (data?.mode === 'campaign-members') ?
        {
          mode: data.mode,
          campaignID: data.campaignID,
          form: data.form,
        } : 
        {
          mode: data.mode,
          campaignID: data.campaignID,
          campaignName: data.campaignName,
          form: data.form,
        } 
        
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  onChangeDummy($event) {
    let isChecked = $event.target.checked ? 1 : 0;
    this.form.patchValue({
      dummy: isChecked
    });
  }
}
