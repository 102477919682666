import { EventEmitterService } from '@core/services/event-emitter.service';
import { Component, OnInit, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup ,  FormArray } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { MessageTemplateDataService } from '@views/pages/apps/superuser/message-template/services/message-template-data.service';

declare var $: any;
@Component({
  selector: 'kt-dropdown-wo-lazyload-footer',
  templateUrl: './dropdown-wo-lazyload-footer.component.html',
  styleUrls: ['./dropdown-wo-lazyload-footer.component.scss']
})
export class DropdownWoLazyloadFooterComponent implements OnInit {

  data: any = [];

  @Input()
  isMessageTemplate: any;

  @Input()
  isSMSMessageTemplate: any;

  @Input()
  selectedItems: any;

  @Input()
  form: FormGroup;

  @Input()
  dropdownList: any;

  @Input()
  formName: any;

  @Input()
  Name: any;

  @Input()
  Index: any;

  @Input()
  selectionAttributes: any;

  @Input()
  dropdownSettings: any;

  @Input()
  dataLoading: boolean = false;

  @Output()
  selectedItemsChanged = new EventEmitter();

  @Output()
  openTriggered = new EventEmitter<boolean>();

  pageNumber = 1;
  pageSize = 30;
  loading = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private eventEmitterService: EventEmitterService,
    private msessageTemplateDataService: MessageTemplateDataService
  ) {}

  ngOnInit(){
    this.eventEmitterService.merchantAccountSubsVar = this.eventEmitterService.invokeClearMerchantAccountSearch.subscribe(() => {
      this.selectedItems = [];
    });
  }

  fetchMore(item: any) {
    if (this.loading === false && item.endIndex === this.dropdownList.length - 1 && this.dropdownList.length % this.pageSize === 0  && this.dropdownList.length !== 0) {
      this.pageNumber++;
      if(this.isMessageTemplate){
        this.setMessageTemplateDropdown();
      }
      if(this.isSMSMessageTemplate){
        this.setSMSMessageTemplateDropdown();
      }
    }
  }

  onOpen(items: any){
    // start for send sms campaign template dropdown
    var smsCampaignTemplate = document.getElementById("campaign-dropdown-template");
    if (smsCampaignTemplate !== null){
      smsCampaignTemplate.getElementsByClassName('dropdown-list')[0]['classList'].add('dropdown-list-top');
      smsCampaignTemplate.getElementsByClassName('arrow-2')[0]['classList'].remove('arrow-up');
      smsCampaignTemplate.getElementsByClassName('arrow-2')[0]['classList'].add('arrow-down');
      smsCampaignTemplate.getElementsByClassName('dropdown-list')[0].getElementsByClassName('arrow-up')[0]['classList'].add('arrow-down')
    }
    // end for send sms campaign template dropdown

    if(document.querySelectorAll('#selectAll')){
      document.querySelectorAll('#selectAll').forEach(ele => ele.removeAttribute('id'));
    }
    this.openTriggered.emit(true);
  }

  onSelectAll(items: any) {
    this.data = [];
    if (this.dropdownSettings.singleSelection === true){
      this.onItemSelectedChange();
      // this.form.controls[this.formName].patchValue(items[this.selectionAttributes]);
      this.form.get(this.Name+'.'+this.Index+'.'+this.formName).patchValue(items[this.selectionAttributes]);
    }
    if (this.dropdownSettings.singleSelection !== true && this.selectedItems !== null){
      Object.keys(this.selectedItems).forEach((key) =>
      this.data.push(this.selectedItems[key][this.selectionAttributes]));
      this.onItemSelectedChange();
      // this.form.controls[this.formName].patchValue(this.data);
      this.form.get(this.Name+'.'+this.Index+'.'+this.formName).patchValue(this.data);
    }
  }

  onDeSelectAll(items: any) {
    this.data = [];
    if (this.dropdownSettings.singleSelection === true){
      this.onItemSelectedChange();
        // this.form.controls[this.formName].patchValue(null);
        this.form.get(this.Name+'.'+this.Index+'.'+this.formName).patchValue(null);
    }else{
      this.onItemSelectedChange();
      // this.form.controls[this.formName].patchValue(this.data);
      this.form.get(this.Name+'.'+this.Index+'.'+this.formName).patchValue(this.data);
    }
  }

  OnItemDeSelect(item: any){
    this.data = [];
    if (this.dropdownSettings.singleSelection === true){
      if (this.selectedItems.length > 0){
        this.onItemSelectedChange();
        // this.form.controls[this.formName].patchValue(item[this.selectionAttributes]);
        this.form.get(this.Name+'.'+this.Index+'.'+this.formName).patchValue(item[this.selectionAttributes]);
      }else{
        this.onItemSelectedChange();
        // this.form.controls[this.formName].patchValue(null);
        this.form.get(this.Name+'.'+this.Index+'.'+this.formName).patchValue(null);
      }
    }
    if (this.dropdownSettings.singleSelection !== true){
      Object.keys(this.selectedItems).forEach((key) =>
      this.data.push(this.selectedItems[key][this.selectionAttributes]));
      this.onItemSelectedChange();
      // this.form.controls[this.formName].patchValue(this.data);
      this.form.get(this.Name+'.'+this.Index+'.'+this.formName).patchValue(this.data);
    }
  }

  onItemSelect(item: any) {
    this.data = [];
    if (this.dropdownSettings.singleSelection === true){
      this.onItemSelectedChange();
      // this.form.controls[this.formName].patchValue(item[this.selectionAttributes]);
      this.form.get(this.Name+'.'+this.Index+'.'+this.formName).patchValue(item[this.selectionAttributes]);
    }
    if (this.dropdownSettings.singleSelection !== true && this.selectedItems !== null){
      Object.keys(this.selectedItems).forEach((key) =>
      this.data.push(this.selectedItems[key][this.selectionAttributes]));
      this.onItemSelectedChange();
      // this.form.controls[this.formName].patchValue(this.data);
      this.form.get(this.Name+'.'+this.Index+'.'+this.formName).patchValue(this.data);
    }
  }

  onItemSelectedChange(){
    this.selectedItemsChanged.emit(this.selectedItems);
  };

  onClose(item: any){
    this.data = [];
    if (this.dropdownSettings.singleSelection !== true && (this.selectedItems !== null || this.selectedItems !== undefined)){

      if(!!this.selectedItems) {
        Object.keys(this.selectedItems).forEach((key) =>
          this.data.push(this.selectedItems[key][this.selectionAttributes])
        );
        // this.form.controls[this.formName].patchValue(this.data);
        this.form.get(this.Name+'.'+this.Index+'.'+this.formName).patchValue(this.data);
      }

    }
  }

  private setMessageTemplateDropdown() {
    this.loading = true;
    this.msessageTemplateDataService.getWithQuery(`?type[]=1&page=${this.pageNumber}&perPage=${this.pageSize}`).subscribe(res => {
      this.dropdownList = this.dropdownList.concat(res);
      this.loading = false;
      this.cdr.detectChanges();
    })
  }

  private setSMSMessageTemplateDropdown() {
    this.loading = true;
    this.msessageTemplateDataService.getWithQuery(`?type[]=2&page=${this.pageNumber}&perPage=${this.pageSize}`).subscribe(res => {
      this.dropdownList = this.dropdownList.concat(res);
      this.loading = false;
      this.cdr.detectChanges();
    })
  }

}
