import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { QuickLink } from '@core/models/quick-link.model';

@Injectable()
export class QuickLinkEntityService extends EntityCollectionServiceBase<QuickLink> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('QuickLink', serviceElementsFactory);
  }
}
