<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Promotion Currency</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div class="col-12">
                <div class="kt-section col-12 row d-flex flex-row-reverse mb-3 mt-1">
                    <button *ngIf="canAddPromotionCurrency" class="btn btn-brand btn-icon-sm float-right" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Add</button>
                </div>
                <div class="kt-section mb-0">
                    <div class="kt-section__content">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover table-striped">
                                <thead>
                                        <tr>
                                            <ng-container *ngIf="promotionType === 3 && (data.promoSubType === 2 || data.promoSubType === 4); else elseTemplate">
                                                <th class="text-center">Currency</th>
                                                <th class="text-center">Min Reward</th>
                                                <th class="text-center">Max Reward</th>
                                            </ng-container>
                                            <ng-template #elseTemplate>
                                                <th class="text-center">Currency</th>
                                                <th class="text-center">Max Total Applications</th>
                                                <th class="text-center">{{ (promotionType === 3 && data.promoSubType === 3) ? 'Threshold' : 'Max Total Amount' }}</th>
                                                <ng-container *ngIf="promotionType === 4">
                                                    <th class="text-center">Coins</th>
                                                    <th class="text-center">Amount Per Line</th>
                                                    <th class="text-center">Lines</th>
                                                    <th class="text-center">Total Rounds</th>
                                                </ng-container>
                                                <th class="text-center" *ngIf="(promotionType === 2 || promotionType === 1 || promotionType === 4)">Min Transfer</th>
                                                <th class="text-center" *ngIf="promotionType === 2 || promotionType === 1">Max Bonus</th>
                                                <th class="text-center" *ngIf="promotionType === 3 && data.promoSubType !== 3">Bonus Amount</th>
                                            </ng-template>
                                            <th class="text-center" *ngIf="promotionType === 3">Max Balance Claim</th>
                                            <th class="text-center">Status</th>
                                            <th class="text-center">Current Players</th>
                                            <th class="text-center">Used Budget</th>
                                            <th class="text-center">Actions</th>
                                        </tr>
                                </thead>
                                <tbody *ngIf="promotionCurrency$.length > 0">
                                    <tr *ngFor="let row of promotionCurrency$">
                                        <ng-container *ngIf="promotionType === 3 && (data.promoSubType === 2 || data.promoSubType === 4); else elseTemplate">
                                            <td class="text-center align-middle">{{ row.currency }}</td>
                                            <td class="text-right align-middle">{{ row.min_bonus | number : '1.2-2' }}</td>
                                            <td class="text-right align-middle">{{ row.max_bonus | number : '1.2-2'}}</td>
                                        </ng-container>
                                        <ng-template #elseTemplate>
                                            <td class="text-center align-middle">{{ row.currency }}</td>
                                            <td class="text-right align-middle">{{ row.max_total_applications | number : '1.0-0' }}</td>
                                            <td class="text-right align-middle">{{ (promotionType === 3 && data.promoSubType === 3) ? (row.threshold | number: '1.2-2'): (row.max_total_bonus | number : '1.2-2')}}</td>
                                            <ng-container *ngIf="promotionType === 4">
                                                <td class="text-right align-middle">{{ row.coins | number : '1.0-0' }}</td>
                                                <td class="text-right align-middle">{{ row.amount_per_line | number : '1.2-2'  }}</td>
                                                <td class="text-right align-middle">{{ row.lines | number : '1.0-0'  }}</td>
                                                <td class="text-right align-middle">{{ row.rounds | number : '1.2-2'  }}</td>
                                            </ng-container>
                                            <td *ngIf="(promotionType === 2 || promotionType === 1 || promotionType === 4)" class="text-right align-middle">{{ row.min_transfer | number : '1.2-2'}}</td>
                                            <td *ngIf="promotionType === 2 || promotionType === 1" class="text-right align-middle">{{ row.max_bonus | number : '1.2-2'}}</td>
                                            <td *ngIf="promotionType === 3 && data.promoSubType !== 3" class="text-right align-middle">{{ row.free_credit_amount | number : '1.2-2'}}</td>
                                        </ng-template>
                                        <td *ngIf="promotionType === 3" class="text-right align-middle">{{ row.max_balance_claim > 0 ? (row.max_balance_claim | number : '1.2-2') : 'Unlimited' }}</td>
                                        <td class="text-center align-middle">
                                            <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                                        </td>
                                        <td class="text-right align-middle">{{ row.current_players }}</td>
                                        <td class="text-right align-middle">{{ row.used_budget | number : '1.2-2'}}</td>
                                        <td class="text-center actions-column">
                                            <button *ngIf="canEditPromotionCurrency" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
                                        </td>
                                    </tr>
                                    <kt-fallback-row [collection]="promotionCurrency$"></kt-fallback-row>
                                </tbody>
                            </table>
                        </div>
                        <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                            <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
</div>
</div>
