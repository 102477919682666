<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="kt-form kt-form--label-right  mb-1">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <div class="col-12 row mb-2 pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Username:</label>
                </div>
                <div class="col-md-2 kt-form__control ">
                  <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Name:</label>
                </div>
                <div class="col-md-2 kt-form__control ">
                  <input type="text" formControlName="name" placeholder="Search" class="form-control">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Role:</label>
                </div>
                <div class="col-md-2 kt-form__control ">
                  <select formControlName="role_id" class="form-control">
                    <option value="all"> All </option>
                    <option *ngFor="let value of dropdown.roles | async" [value]="value.id">{{ value.name }}</option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>IP Address:</label>
                </div>
                <div class="col-md-2 kt-form__control ">
                  <input type="text" formControlName="ip" placeholder="Search" class="form-control">
                </div>
              </div>
              <div class="col-12 row mb-2 pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Request URL:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="actions" placeholder="Search" class="form-control">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Referrer:</label>
                </div>
                <div class="col-md-2 kt-form__control ">
                  <input type="text" formControlName="referer" placeholder="Search" class="form-control">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date/Time:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="input-group date">
                    <input type="text" class="form-control" placeholder="Search"
                      [formControl]="form.controls['defaultDate']"
                      (change)="onDateChange($event)"
                      (opened)="onDateOpened()"
                      [timePicker]="true" [timePickerSeconds]="true"
                      [alwaysShowCalendars]="true" [ranges]="ranges" [maxDate]="maxDate"
                      [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                      [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()"
                      ngxDaterangepickerMd/>
                    <span class="input-group-append">
                      <span class="input-group-text pointer-calendar" (click)="onDateChange()">X</span>
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="kt-section col-12 row mb-3 mt-1">
        <kt-search-button [loading]="loading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
        <kt-clear-button [loading]="loading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
      </div>
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Operator's Name</th>
                  <th>IP Address</th>
                  <th>Request URL</th>
                  <th>Referrer</th>
                  <th>Input</th>
                  <th class="date-column">Created By</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of operatorLogs">
                  <td>{{ row.id }}</td>
                  <td>{{ row.name }}</td>
                  <td>{{ row.ip }}</td>
                  <td class="width-250">{{ row.request_url }}</td>
                  <td>{{ row.referer }}</td>
                  <td class="width-250">{{ row.input }}</td>
                  <td>
                    System<br/>
                    <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #createdDateTime>
                      {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="loading"  style="text-align: center;">Loading more...</div>
            <div *ngIf="!loading"  style="text-align: center;">No more data available</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
