import { SmsLog } from '@core/models/sms-log.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class LogsEntityService extends EntityCollectionServiceBase<SmsLog>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('SmsLog', serviceElementsFactory)
  }
}
