import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AffiliateGroups } from '@core/models/affiliate-groups.model';
import { AffiliateGroupsDataService } from '../../services/affiliate-groups-data.service';
import { TierRateSettingsDialogComponent } from '../tier-rate-settings/tier-rate-settings.component';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'kt-commission-settings',
  templateUrl: './commission-settings.component.html',
  styleUrls: ['./commission-settings.component.scss']
})
export class CommissionSettingsDialogComponent implements OnInit {

  form: FormGroup;
  buttonLoading = false;
  messages$ = this.affiliateGroupsDataService.messages$;

  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, groups: AffiliateGroups, settings: any },
    public dialogRef: MatDialogRef<CommissionSettingsDialogComponent>,
    public dialog: MatDialog,
    private affiliateGroupsDataService: AffiliateGroupsDataService,
  ) { }

  ngOnInit() {
    this.formInit();
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    const data = {
      ...this.form.value,
      exclude_adjustments: this.form.value.exclude_adjustments ? 1 : 0
    }
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    if (this.data.settings.length === 0) {
      this.subscription = this.affiliateGroupsDataService.addCommissionSettings(data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else {
      this.subscription = this.affiliateGroupsDataService.updateCommissionSettings(this.data.settings[0].id, data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
  }

  onOpenDialog() {
    this.affiliateGroupsDataService.getTierSettings(this.data.settings[0].id).subscribe(res => {
      this.dialog.open(TierRateSettingsDialogComponent, {
        data: {
          commissionSettingId: this.data.settings[0].id,
          tier: res
        }
      }).afterClosed().subscribe();
    });
  }

  private formInit() {
    let minWinAmount = null;
    let maxAmount = null;
    let commissionrate = null;
    let subAffiliateCommissionRate = null;
    let royaltyFee = null;
    let excludeAdjustments = 0;

    if (this.data.settings.length !== 0) {
      minWinAmount = this.data.settings[0].min_win_amount;
      maxAmount = this.data.settings[0].max_amount;
      commissionrate = this.data.settings[0].commission_rate;
      subAffiliateCommissionRate = this.data.settings[0].sub_affiliate_commission_rate;
      royaltyFee = this.data.settings[0].royalty_rate;
      excludeAdjustments = this.data.settings[0].exclude_adjustments;
    }

    this.form = new FormGroup({
      affiliate_group_id: new FormControl(this.data.groups),
      min_win_amount: new FormControl(minWinAmount, Validators.required),
      max_amount: new FormControl(maxAmount, Validators.required),
      commission_rate: new FormControl(commissionrate, Validators.required),
      sub_affiliate_commission_rate: new FormControl(subAffiliateCommissionRate, Validators.required),
      royalty_rate: new FormControl(royaltyFee, Validators.required),
      exclude_adjustments: new FormControl(excludeAdjustments)
    });
  }

}
