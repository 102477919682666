import { MessageTemplate } from '@core/models/message-template.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class MessageTemplateEntityService extends EntityCollectionServiceBase<MessageTemplate> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('MessageTemplate', serviceElementsFactory);
  }
}
