<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Reupload Document({{ data.bankAccount.id }})</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
          <!-- <div class="col-12 form-group row pr-0" formArrayName="document">
            <label class="col-4 col-form-label">Upload Document <span class="text-danger">*</span> </label>
            <div class="col-8 input-wrapper pl-0 pr-0" >
              <div class="d-flex mt-2" *ngFor="let document of document.controls; let i = index">
                <label class="btn btn-default btn-sm image-button">
                  <span>Browse</span>
                  <input class="form-control" type="file" hidden accept="image/*,video/*,.pdf" (change)="onUploadFile($event,i)">
                </label>
                <span class="form-control filename" style="cursor: pointer;" (click)="onOpenDialog('show-document', { value: document.value })">{{ fileName[i] ? fileName[i] : 'Choose Files' }}</span>
                <button class="btn btn-remove" *ngIf="!isFileUploading[i]" (click)="removeDocument(i)" > <span>x</span></button>
                <div class="spinner-wrapper d-flex align-items-center" *ngIf="isFileUploading[i]">
                  <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                </div>
              </div>
              <button class="btn btn-warning mt-4" (click)="addDocument()"><i class="fa fa-plus" aria-hidden="true"></i> Add </button>
            </div>
          </div> -->

        <ng-container *ngFor="let field of fields; let i = index">
          <div class="col-12 form-group row pr-0" [formArrayName]="'fields'">
            <ng-container [formGroupName]="i">
              
              <!-- Check for type 1 (Textbox) -->
              <ng-container *ngIf="field.type === 1">
                <label class="col-4 col-form-label">
                  {{ getFieldName(field.field_details) }} <span class="text-danger">*</span>
                </label>
                <div class="col-8 input-wrapper pl-0 pr-0">
                  <input class="form-control" type="text" formControlName="textbox">
                </div>
              </ng-container>
              
              <!-- Check for type 2 (Document Upload) -->
              <ng-container *ngIf="field.type === 2">
                <label class="col-4 col-form-label">
                  {{ getFieldName(field.field_details) }} <span class="text-danger">*</span>
                </label>
                <div class="col-8 input-wrapper pl-0 pr-0" formArrayName="document">
                  <div class="d-flex mt-2" *ngFor="let documentCtrl of form.get(['fields', i, 'document']).controls; let j = index">
                    <label class="btn btn-default btn-sm image-button">
                      <span>Browse</span>
                      <input class="form-control" type="file" hidden accept="image/*,video/*,.pdf" (change)="onUploadFile($event, i, j)">
                    </label>
                    <span class="form-control filename" style="cursor: pointer;" (click)="onOpenDialog('show-document', { value: documentCtrl.value })">
                      {{ getFileName(i, j) }}
                    </span>
                    <button class="btn btn-remove" *ngIf="!isFileUploading[i][j]" (click)="removeDocument(i, j)">
                      <span>x</span>
                    </button>
                    <div class="spinner-wrapper d-flex align-items-center" *ngIf="isFileUploading[i][j]">
                      <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                    </div>
                  </div>
                  <button class="btn btn-warning mt-4" (click)="addDocument(i)" *ngIf="form.get(['fields', i, 'document']).controls.length < maxFileUpload">
                    <i class="fa fa-plus" aria-hidden="true"></i> Add
                  </button>
                </div>
              </ng-container>
              
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i
            class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading"
          (confirmed)="onSubmit()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
