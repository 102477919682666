import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { MemberDataService } from '../../services/member-data.service';

@Component({
  selector: 'kt-remarks-history',
  templateUrl: './member-remarks-history.component.html',
  styleUrls: ['./member-remarks-history.component.scss']
})
export class MemberRemarksHistoryComponent implements OnInit {

  remarksHistory$: Observable<[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { memberId: number },
    public dialogRef: MatDialogRef<MemberRemarksHistoryComponent>,
    private memberDataService: MemberDataService
  ) { }

  ngOnInit() {
    this.remarksHistory$ = this.memberDataService.getRemarksHistory(this.data.memberId);
  }


  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

}
