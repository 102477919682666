<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Remark History</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
            <section class="col-lg-12 card border-0 p-0 pb-3 mb-4">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Message Template Info
                </div>
                <div class="row m-3 p-0">
                    <div class="col-3 card-header border p-3">
                      <span class="kt-font-bold">Affiliate ID</span>
                    </div>
                    <div class="col-3 border-bottom border p-3">
                      <span class="text-right">{{ data.row.id }} </span>
                    </div>
                    <div class="col-3  card-header border p-3">
                      <span class="kt-font-bold">Affiliate Username</span>
                    </div>
                    <div class="col-3  border-bottom border p-3">
                      <span class="text-right">{{ data.row.created_by }}</span>
                    </div>
                    <div class="col-3 card-header border p-3">
                      <span class="kt-font-bold">Code</span>
                    </div>
                    <div class="col-3 border-bottom border p-3">
                      <span class="text-right">{{ data.row.code | uppercase }}</span>
                    </div>
                    <div class="col-3 card-header border p-3">
                      <span class="kt-font-bold">Locale</span>
                    </div>
                    <div class="col-3 border-bottom border p-3">
                      <span class="text-right">{{ data.row.locales }}</span>
                    </div>
                </div>
              </div>
            </section>
            <section class="col-lg-12 card border-0 p-0 pb-3">
                <div class="col-12 p-0 card rounded-0" *ngIf="remarks as rows">
                  <div class="card-header">
                    Timeline
                  </div>
                  <div class="row ml-1" *ngFor="let row of rows; let i = index">
                    <div class="col-2 p-3">
                      {{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}
                    </div>
                    <div class="col-10 p-3">
                      <!-- The Timeline -->
                      <div class="timeline">
                        <div class="preview-status" [matTooltip]="textBystatusId[row.status]">&nbsp;</div>
                        <!-- Item 1 -->
                        <div>
                          <div class="direction-r">
                            <div class="flag-wrapper">
                              <span class="flag" [ngClass]="'border-color--' + row.status">
                                <section class="col-12 p-3 inner-box-timeline">
                                  <div class="row border-top border-left border-bottom">
                                    <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                      <span class="kt-font-bold">Action</span>
                                    </div>
                                    <div class="col-md-9 border-right p-3">
                                      <span class="text-right" [innerHTML]="actionText(row.status,i)">
                                      </span>
                                    </div>
                                  </div>
                                  <div class="row border-bottom border-left" *ngIf="row.remark">
                                    <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                      <span class="kt-font-bold">Remark</span>
                                    </div>
                                    <div class="col-md-9 border-right p-3">
                                      <span>{{ row.remark }}</span>
                                    </div>
                                  </div>
                                  <div class="row border-bottom border-left" *ngIf="row.reason">
                                    <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                      <span class="kt-font-bold">Reason</span>
                                    </div>
                                    <div class="col-md-9 border-right p-3">
                                      <span>{{ row.reason }}</span>
                                    </div>
                                  </div>
                                  <div class="row border-top border-left border-bottom">
                                    <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                      <span class="kt-font-bold">Created By</span>
                                    </div>
                                    <div class="col-md-9 border-right p-3">
                                      <span class="text-right">{{ row.created_by || row.created_by !== null ? row.created_by : data.row.created_by }}</span>
                                    </div>
                                  </div>
                                </section>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog($event)"><i class="fas fa-ban"></i>Close</button>
        </div>
      </div>
  </div>