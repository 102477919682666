import { AuthHttpService } from '@core/services/auth-http.service';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OperatorLog } from '@core/models/operator-log.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import * as moment from 'moment-timezone';
import { OperatorLogsEntityService } from './services/operator-logs-entity.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'kt-operator-logs',
  templateUrl: './operator-logs.component.html',
  styleUrls: ['./operator-logs.component.scss']
})
export class OperatorLogsComponent implements OnInit, OnDestroy {
  form: FormGroup;
  ranges: any;
  maxDate: any;
  dateTimePickerLocale: any;

  operatorLogs: OperatorLog[] = [];
  page = 1;
  pageSize = 30;
  params = '';
  loading = false;
  loadmore = true;
  dropdown = {
    roles: this.dropdownHttpService.roles,
  };
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  private subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private authHttpService: AuthHttpService,
    private dropdownHttpService: DropdownHttpService,
    private operatorLogsService: OperatorLogsEntityService,
    private transactionHttpService: TransactionHttpService,
  ) { }

  ngOnInit(): void {
    this.dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
    this.ranges = this.transactionHttpService.ranges;

    this.formInit();
    this.onSubmit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    const documentHeight = Math.max(
      document.body.scrollHeight || 0,
      document.body.offsetHeight || 0,
      document.documentElement.scrollHeight || 0,
      document.documentElement.offsetHeight || 0
    );
    const scrollbottom = (window.innerHeight + window.scrollY) * 1.1 >= documentHeight;

    if (this.loadmore && !this.loading && scrollbottom) {
      this.onSubmit(true);
    }
  }

  onClear() {
    this.formInit();
    this.onSubmit();
  }

  onSubmit(scrollload?: boolean) {
    this.loading = true;
    this.page++;

    if (!scrollload) {
      this.page = 1;
      this.params = this.getSearchParams();
      this.loadmore = true;
      this.operatorLogs = [];
    }

    this.operatorLogsService.getWithQuery(`?page=${this.page}&perPage=${this.pageSize}&${this.params}`)
    .subscribe((res: OperatorLog[]) => {
      this.loading = false;
      if (res) {
        this.operatorLogs = [...this.operatorLogs, ...res];
        this.cdr.detectChanges();

        if (res.length >= this.pageSize) {
          return;
        }
      }

      this.loadmore = false;
    });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  onDateOpened() {
    this.maxDate = moment();

    const service = this.transactionHttpService;

    const todayservice = service.getToday();
    this.ranges.Today = this.patchDatetime(todayservice.from, todayservice.to);

    const last24hourservice = service.getLast24Hours();
    this.ranges['Last 24 Hours'] = this.patchDatetime(last24hourservice.from, last24hourservice.to);

    const yesterdayservice = service.getYesterday();
    this.ranges.Yesterday = this.patchDatetime(yesterdayservice.from, yesterdayservice.to);

    const thisweekservice = service.getThisWeek();
    this.ranges['This Week'] = this.patchDatetime(thisweekservice.from, thisweekservice.to);

    const thismonthservice = service.getThisMonth();
    this.ranges['This Month'] = this.patchDatetime(thismonthservice.from, thismonthservice.to);
  }

  onDateChange(event: any = null) {
    const patchdatetime = this.patchDatetime(event?.startDate, event?.endDate);

    this.maxDate = moment();

    this.form.patchValue({
      start_date: patchdatetime.startdate,
      end_date: patchdatetime.enddate,
      defaultDate: {
        startDate: patchdatetime.startdate,
        endDate: patchdatetime.enddate,
      }
    });
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private formInit() {
    const patchdatetime = this.patchDatetime();

    this.maxDate = moment();

    this.form = new FormGroup({
      username: new FormControl(null),
      name: new FormControl(null),
      actions: new FormControl(null),
      role_id: new FormControl('all'),
      ip: new FormControl(null),
      referer: new FormControl(null),
      start_date: new FormControl(patchdatetime.startdate),
      end_date: new FormControl(patchdatetime.enddate),
      defaultDate: new FormControl({
        startDate: patchdatetime.startdate,
        endDate: patchdatetime.enddate,
      }), // Do not remove: For Clearing The Range
    });
  }

  private getSearchParams(): string {
    const formValues = this.form.value;
    const patchdatetime = this.patchDatetime(formValues.start_date, formValues.end_date);

    const newFormValues = {
      ...formValues,
      start_date: patchdatetime.startdate.tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_date: patchdatetime.enddate.tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
    };

    const filteredFormValues = this.filterFormFields(newFormValues);
    const searchParams = new URLSearchParams(filteredFormValues);

    return searchParams.toString();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] && formData[key] !== 'all' && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private patchDatetime(startDate: any = null, endDate:any = null) {
    if (endDate) endDate = moment(endDate);
    if (startDate) startDate = moment(startDate);

    let enddate = endDate && !endDate.isAfter(moment()) ? endDate : moment().subtract(1, 'minute');
    let startdate = startDate && !startDate.isAfter(moment()) ? startDate : enddate.clone().subtract(1, 'hour');

    enddate = enddate.clone();
    startdate = startdate.clone();

    return {startdate, enddate};
  }
}
