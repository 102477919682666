<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header" style="display: inline">
            <h5 class="modal-title" style="display: inline">Label and Remark - {{ data.member.username }} ({{ data.member.id }})</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>  
        <div class="modal-body">
            <div class="col-12">
                <form class="row align-items-center" [formGroup]="form">
                    <div class="col-12 row mb-2 pr-0">
                        <div class="col-md-1 kt-form__label col-form-label">
                            <label>Label:</label>
                        </div>
                        <div class="col-md-2 kt-form__control">
                            <select formControlName="label" class="form-control">
                                <option value="all"> All </option>
                                <option [value]="item.name" *ngFor="let item of dropdown.label | async">{{ item.name }}</option>
                                <option value="none"> None </option>
                            </select>
                        </div>
                        <div class="col-md-1 kt-form__label col-form-label">
                            <label>Status:</label>
                        </div>
                        <div class="col-md-2 kt-form__control">
                            <select formControlName="status" class="form-control">
                                <option value="all"> All </option>
                                <ng-container *ngFor="let item of dropdown.statuses | keyvalue">
                                    <option [value]="item.key" *ngIf="+item.key >= 0">{{ item.value }}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                </form>
                <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                    <div class="dropdown dropdown-inline">
                        <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                        <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                    </div>
                    <button class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
                </div>
                <div class="kt-section mb-0">
                    <div class="kt-section__content">
                        <div class="table-responsive">
                            <table class="table parent-table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>ID</th>
                                        <th>Label</th>
                                        <th>Remarks</th>
                                        <th class="text-center">Status</th> 
                                        <th class="date-column">Created By</th>
                                        <th class="date-column">Updated By</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="(labels$ | async) as rows">
                                    <ng-container *ngFor="let row of rows; let parentIndex = index">
                                        <tr>
                                            <td class="arrow-right" data-toggle="collapse" [attr.data-target]="'#collapse'+ row.id" aria-expanded="false" [attr.aria-controls]="'collapse'+ row.id"><i class="fas fa-angle-right arrow-right"></i></td>
                                            <td>{{ row.id }}</td>
                                            <td>{{ row.label }}</td>
                                            <td class="text-ellipsis">{{ row.default_remark }}</td>
                                            <td class="text-center">
                                                <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                                            </td>
                                            <td>
                                                {{ row.created_by === null ? '-' : row.created_by }}<br>
                                                {{ row.created_at != '0000-00-00 00:00:00' ? (row.created_at | date:'yyyy-MM-dd HH:mm') : '' }}
                                            </td>
                                            <td>
                                                {{ row.updated_by === null ? '-' : row.updated_by }}<br>
                                                {{ row.updated_at | date:'yyyy-MM-dd HH:mm' }}
                                            </td>
                                            <td class="text-center">
                                                <button title="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id, row.default_remark)"><i class="fas fa-edit"></i></button>
                                            </td>
                                        </tr>
                                        <td colspan="8" class="details">
                                            <div class="collapse" [id]="'collapse'+ row.id">
                                                <table class="child-data-table">
                                                    <thead>
                                                        <tr>
                                                            <th style="width:3%"></th>
                                                            <th>Remark History</th>
                                                            <th>Edit Purpose</th>
                                                            <th>Created By</th>
                                                            <th class="text-center">Default</th>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container *ngIf="row.remarks.length >= 1">
                                                            <tr *ngFor="let remark of row.remarks; let childIndex = index">
                                                                <td></td>
                                                                <td style="max-width:400px;word-break:break-word;">{{ remark.default_remark ?  remark.default_remark : remark.remarks }}</td>
                                                                <td>{{ remark.edit_purpose === null ? '-' : remark.edit_purpose }}</td>
                                                                <td>
                                                                    {{ remark.created_by === null ? '-' : remark.created_by }}<br>
                                                                    {{ remark.created_at != '0000-00-00 00:00:00' ? (remark.created_at | date:'yyyy-MM-dd HH:mm') : '' }}
                                                                </td>
                                                                <td class="text-center">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" [id]="'checkbox-'+ row.id + '-' + childIndex" (change)="onSelectDefault($event, row, remark.id, childIndex)" [checked]="remark.default == 1 ? true : false">
                                                                        <label class="custom-control-label text-nowrap" [for]="'checkbox-'+ row.id + '-' + childIndex"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-container>    
                                                        <ng-container *ngIf="row.remarks.length === 0">
                                                            <tr class="d-flex no-data-text">No Data Available</tr>
                                                        </ng-container>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </ng-container>
                                </tbody>
                                <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
      
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
  