import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { SMSRecoveryReportDetail } from '@core/models/sms-recovery-report-detail.model';

@Injectable()
export class SMSRecoveryReportDetailEntityService extends EntityCollectionServiceBase<SMSRecoveryReportDetail> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('SMSRecoveryReportDetail', serviceElementsFactory);
  }
}
