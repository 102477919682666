import { Injectable } from '@angular/core';
import { BankSetting } from '@core/models/bank-settings.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable()
export class BankSettingsEntityService extends EntityCollectionServiceBase<BankSetting> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('BankSetting', serviceElementsFactory);
    }
}
