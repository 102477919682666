import {  Component, Inject, OnDestroy, OnInit} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AfffiliateMessageTemplateDataService } from '../../services/message-template-affiliate-data.service';
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { Subscription } from 'rxjs'; 
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';

import { DropdownHttpService } from '@core/services/dropdown-http.service';
@Component({
  //selector: 'app-lead-remark',
  templateUrl: './show-affiliate-remark.component.html',
  styleUrls: ['./show-affiliate-remark.component.scss'],
})
export class ShowAffiliateRemarkComponent implements OnInit, OnDestroy {


  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  remarks: any;
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
  };

  textBystatus = [
    "Bo user changed to inactive",
    "Approved",
    "Rejected",
    "Affiliate submitted changes",
    "Affiliate submitted request",
    "Rejected Remark"
  ];

  textBystatusId = [
    "Inactive",
    "Active",
    "Rejected",
    "Pending Change",
    "Pending Approval",
    "Active"
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { row: any},
    public dialogRef: MatDialogRef<ShowAffiliateRemarkComponent>,
    public dialog: MatDialog,
    private dropdownHttpService: DropdownHttpService,
    private messageTemplateDataService: AfffiliateMessageTemplateDataService,
  ) { }

  ngOnInit() {

     this.onViewPageBy();
  }

  ngOnDestroy() {
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onRefresh() {
    this.dialogRef.close(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {

    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';

    this.messageTemplateDataService.getRemarkById(this.data.row.id,`?paginate=false`).subscribe(res => {
      this.remarks = res;
      this.page = 1;
      this.pagination = this.messageTemplateDataService.pagination;
    });

  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  actionText(status,currentIndex){
    var lastRemarkStatus = null;
    if(this.remarks){
      lastRemarkStatus = this.remarks[currentIndex + 1] ? this.remarks[currentIndex + 1]['status'] : null;
    }
    if(status == 0){
      return 'Operator Inactive Message Template';
    }else if(status == 1 && lastRemarkStatus == 4){
      return 'Operator Approve Message Template';
    }else if(status == 1 && lastRemarkStatus == 3){
      return 'Operator Approve Message Template <br><b>(Applied new message template)</b>';
    }else if(status == 1 && lastRemarkStatus == 0){
      return 'Operator Active Message Template';
    }else if(status == 2 ){
      return 'Operator Reject Message Template';
    }else if(status == 3 ){
      return 'Affiliate Submit Changes';
    }else if(status == 4 && lastRemarkStatus !== 2){
      return 'Affiliate Submit Message Template';
    }else if(status == 4 && lastRemarkStatus == 2){
      return 'Affiliate Resubmit Message Template';
    }else if(status == 5){
      return 'Operator Reject Changes on Message Template <br><b>(Revert back to previous message template)</b>';
    }
  }

}
