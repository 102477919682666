<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row pr-0 mb-3">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>ID:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="operator_id" placeholder="Search" class="form-control">
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div> 

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="status" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                                            {{ value }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Role Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        [form] = 'form'
                                        [dropdownList] = '(dropdown.applicationRoles | async) || []'
                                        [dropdownSettings] = 'appRolesDropdownSettings'
                                        [formName] = "'app_role_ids'"
                                        [selectionAttributes] = "'id'"
                                        [selectedItems] = 'appRolesSelectedItems'
                                    ></kt-dropdown-wo-lazyload>
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Currencies' | translate }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        [dropdownList] = "currencyDropdownList"
                                        [dropdownSettings] = "currencyDropdownSettings"
                                        [form] = "form"
                                        [selectionAttributes] = "'id'"
                                        [formName] = "'currency_id'"
                                        [selectedItems]="currencySelectedItems"
                                        (selectedItemsChanged)="currencySelectedItems = $event"
                                    >
                                    </kt-dropdown-wo-lazyload>
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                    </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group date">

                                        <select formControlName="datetime_type" class="form-control" style="width: 30%;">
                                            <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                                        </select>

                                        <input type="hidden" formControlName="start_datetime" >
                                        <input type="hidden" formControlName="end_datetime" >

                                        <div class="input-group date" style="width: 70%;">
                                            <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" [formControl]="form.controls['defaultDate']"
                                            [timePicker]="true" [timePickerSeconds]="true"
                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                            [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()"
                                            ngxDaterangepickerMd
                                            />
                                            <span class="input-group-append">
                                                <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canCreateOperator" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                                    <th class="sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                                    <th class="text-center">Currencies</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('application_role_name')" [ngClass]="sortingConfig.application_role_name" [ngClass]="sortingConfig.application_role_name" [class.sort-selected]="sortingSelection.sort_by === 'application_role_name'">Role Name</th>
                                    <th *ngIf="canViewOperator2FA" class="text-center sort-enabled" (click)="onSortColumn('two_factor_authentication_status')" [ngClass]="sortingConfig.two_factor_authentication_status" [ngClass]="sortingConfig.two_factor_authentication_status" [class.sort-selected]="sortingSelection.sort_by === 'two_factor_authentication_status'">2FA</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('view_masked_mobile')" [ngClass]="sortingConfig.view_masked_mobile" [ngClass]="sortingConfig.view_masked_mobile" [class.sort-selected]="sortingSelection.sort_by === 'view_masked_mobile'">Access</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('last_login')" [ngClass]="sortingConfig.last_login" [class.sort-selected]="sortingSelection.sort_by === 'last_login'">Last Login Details</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(operators$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.id }}</td>
                                    <td>{{ row.username }}</td>
                                    <td>{{ row.name }}</td>
                                    <td class="text-center">{{ row.currency }}</td>
                                    <td class="text-center">
                                        <span
                                            class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide role" [ngClass]="'kt-badge--' + row.application_role_id"> {{ row.application_role_name }}
                                        </span>
                                    </td>
                                    <td *ngIf="canViewOperator2FA" class="text-center">
                                        <ng-container *ngIf="row.two_factor_authentication; else twoFADisabled">
                                            <!-- Inactive -->
                                            <span *ngIf="row.two_factor_authentication_status == 0" matTooltip="Enabled and Not Setup" class="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><defs><polygon points="0 0 24 0 24 24 0 24"/></defs><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><mask fill="white"><use xlink:href="#path-1"/></mask><g/><path d="M15.6274517,4.55882251 L14.4693753,6.2959371 C13.9280401,5.51296885 13.0239252,5 12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L14,10 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C13.4280904,3 14.7163444,3.59871093 15.6274517,4.55882251 Z" fill="#b4b5b9"/></g></svg></span>
                                            <!-- Active -->
                                            <span *ngIf="row.two_factor_authentication_status == 1" matTooltip="Enabled and Setup" class="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><defs><polygon points="0 0 24 0 24 24 0 24"/></defs><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><mask fill="white"><use xlink:href="#path-1"/></mask><g/><path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#24d366"/></g></svg></span>
                                            <!-- Pending for reset -->
                                            <span *ngIf="row.two_factor_authentication_status == 2" matTooltip="Pending Reset" (click)="onReset(row.id)" style="cursor:pointer" class="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><defs/><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><path d="M7.38979581,2.8349582 C8.65216735,2.29743306 10.0413491,2 11.5,2 C17.2989899,2 22,6.70101013 22,12.5 C22,18.2989899 17.2989899,23 11.5,23 C5.70101013,23 1,18.2989899 1,12.5 C1,11.5151324 1.13559454,10.5619345 1.38913364,9.65805651 L3.31481075,10.1982117 C3.10672013,10.940064 3,11.7119264 3,12.5 C3,17.1944204 6.80557963,21 11.5,21 C16.1944204,21 20,17.1944204 20,12.5 C20,7.80557963 16.1944204,4 11.5,4 C10.54876,4 9.62236069,4.15592757 8.74872191,4.45446326 L9.93948308,5.87355717 C10.0088058,5.95617272 10.0495583,6.05898805 10.05566,6.16666224 C10.0712834,6.4423623 9.86044965,6.67852665 9.5847496,6.69415008 L4.71777931,6.96995273 C4.66931162,6.97269931 4.62070229,6.96837279 4.57348157,6.95710938 C4.30487471,6.89303938 4.13906482,6.62335149 4.20313482,6.35474463 L5.33163823,1.62361064 C5.35654118,1.51920756 5.41437908,1.4255891 5.49660017,1.35659741 C5.7081375,1.17909652 6.0235153,1.2066885 6.2010162,1.41822583 L7.38979581,2.8349582 Z" fill="#000000" opacity="0.3"/><path d="M14.5,11 C15.0522847,11 15.5,11.4477153 15.5,12 L15.5,15 C15.5,15.5522847 15.0522847,16 14.5,16 L9.5,16 C8.94771525,16 8.5,15.5522847 8.5,15 L8.5,12 C8.5,11.4477153 8.94771525,11 9.5,11 L9.5,10.5 C9.5,9.11928813 10.6192881,8 12,8 C13.3807119,8 14.5,9.11928813 14.5,10.5 L14.5,11 Z M12,9 C11.1715729,9 10.5,9.67157288 10.5,10.5 L10.5,11 L13.5,11 L13.5,10.5 C13.5,9.67157288 12.8284271,9 12,9 Z" fill="#fb1330"/></g></svg></span>
                                        </ng-container>
                                        <ng-template #twoFADisabled>
                                            <span matTooltip="Disabled">-</span>
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        <span class="svg-icon svg-icon-primary svg-icon-2x">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 0 24 24" fill="none">
                                                <path [attr.fill]="row.view_masked_mobile ? '#24d366' : '#b4b5b9'" d="M6.62 10.79a15.05 15.05 0 006.59 6.59l2.2-2.2a1 1 0 011.11-.21c1.12.45 2.33.7 3.6.7a1 1 0 011 1v3.5a1 1 0 01-1 1A16.92 16.92 0 013 5a1 1 0 011-1H7.5a1 1 0 011 1c0 1.27.25 2.48.7 3.6a1 1 0 01-.21 1.11l-2.2 2.2z"/>
                                            </svg>
                                        </span>
                                        <span class="svg-icon svg-icon-primary svg-icon-2x ml-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 0 24 24" fill="none">
                                                <path [attr.fill]="row.view_masked_email ? '#24d366' : '#b4b5b9'" d="M20 4H4a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
                                            </svg>
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                                    </td>
                                    <td>
                                        IP : {{ row.last_login_location }}
                                        <br />
                                        {{ row.last_login | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                                    </td>
                                    <td>
                                        {{ row.created_by != null ? row.created_by : 'System' }}<br/>
                                        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        {{ row.updated_by != null ? row.updated_by : 'System' }}<br/>
                                        <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        <button *ngIf="canEditOperator" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
                                        <!-- <button *ngIf="canResetOperatorPassword" matTooltip="Reset Password" [disabled]="row.support === 1 ? false : true" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('reset', row)"><i class="fas fa-key"></i></button> -->
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>
