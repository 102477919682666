import { EventEmitterService } from '@core/services/event-emitter.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromotionCodeEntityService } from './../../../promotion-codes/services/promotion-code-entity.service';
import { RewardSettingsType } from '@core/enums/reward-settings-type.enum';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { PromotionCodeDataService } from '../../../promotion-codes/services/promotion-code-data.service';

@Component({
  selector: 'kt-rewards-settings-edit',
  templateUrl: './rewards-settings-edit.component.html',
  styleUrls: ['./rewards-settings-edit.component.scss']
})
export class RewardsSettingsDialogComponent implements OnInit {
  form: FormGroup;
  selectedType: number;
  types = RewardSettingsType;
  dropdown = {
    promotionCode : this.promotionCodeEntityService.getWithQuery(`?paginate=false`)
  };

  promotionCodeDropdownSettings = {};
  promotionCodeDropdownList = [];
  promotionCodeSelectedItems = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, rewardSetting: any, index: number},
    private promotionCodeEntityService: PromotionCodeEntityService,
    private eventEmitterService: EventEmitterService,
    public dialogRef: MatDialogRef<RewardsSettingsDialogComponent>,
    private promotionCodeDataService: PromotionCodeDataService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.promotionCodeDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      autoPosition: false,
      showCheckbox: false
    };
    this.promotionCodeDataService.getWithQuery(`?&paginate=false`).subscribe(res => {
      this.promotionCodeDropdownList = res;
      this.promotionCodeDropdownList.map(function (elm) {
        elm['name'] = elm.code + ' - ' + elm.name;
      });

      if(this.data.mode === 'edit'){
        if(this.data.rewardSetting.promotion_id ){
          this.promotionCodeSelectedItems.push({
            id: this.data.rewardSetting.promotion_id,
            name: this.data.rewardSetting.promotion_code + ' - ' + this.data.rewardSetting.promotion_name
          });
        };
      };
    });
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSelectType(event: any){
    this.selectedType = +(event.target as HTMLInputElement).value;
    this.form.patchValue({
      reward_type_name: event.target.options[event.target.options.selectedIndex].text
    });
    if (this.selectedType === 1){
      this.form.patchValue({
        gift_name: null,
        gift_total_count: null
      });
    } else {
      this.form.patchValue({
        promotion_code: null,
        promotion_id: null
      });
    }
  }

  onSelectPromo(event: any){
    this.form.patchValue({
      promotion_code: event.target.options[event.target.options.selectedIndex].text
    });
  }

  onSave(){
    const data = {
      index: this.data.index,
      actionType: this.data.mode,
      ...this.form.value
    };
    this.eventEmitterService.onUpdateRewardSettings(data);
    this.dialogRef.close();
  }

  private formInit() {
    let name = null;
    let rewardType = null;
    let promotionId = null;
    let giftName = null;
    let giftTotalCount = null;
    let rewardTypeName = null;
    let promotionCode = null;

    if (this.data.mode === 'edit'){
      this.selectedType = +this.data.rewardSetting.reward_type_id;
      name = this.data.rewardSetting.name;
      rewardType = this.data.rewardSetting.reward_type_id;
      promotionId = this.data.rewardSetting.promotion_id;
      giftName = this.data.rewardSetting.gift_name;
      giftTotalCount = this.data.rewardSetting.gift_total_count;
      rewardTypeName = this.data.rewardSetting.reward_type_name;
      promotionCode = this.data.rewardSetting.promotion_code;
    }
    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      reward_type_id: new FormControl(rewardType, [Validators.required]),
      promotion_id: new FormControl(promotionId),
      gift_name: new FormControl(giftName),
      gift_total_count: new FormControl(giftTotalCount),
      reward_type_name: new FormControl(rewardTypeName),
      promotion_code: new FormControl(promotionCode)
    });
  }

}
