<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form" [formGroup]="form" (submit)="onSave()">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Create Adjustment</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 form-group">
                        <label class="control-label">Affiliate <span class="text-danger">*</span></label>
                        <kt-member-dropdown class="dropdown-maxheight" [form]="form" [isAffiliate]="true"
                            [dropdownSettings]='dropdownSettings' [formName]="'affiliate_id'" (selectedItemsChanged)="onSelectAffiliate($event)"
                            [selectionAttributes]="'id'"></kt-member-dropdown>
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Wallet <span class="text-danger">*</span></label>
                        <select class="form-control" formControlName="type_id" (change)="onSelectWallet($event)">
                            <option [value]="null" [disabled]="true">Please Select</option>
                            <option [value]="i" *ngFor="let item of dropdown.wallet_type; let i = index">{{ item }}</option>
                        </select>
                    </div>
                    <div class="col-md-12 form-group" *ngIf="form.get('affiliate_id').valid && form.get('type_id').valid">
                        <label class="control-label">Balance: {{ walletBalance }}</label>
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Amount <span class="text-danger">*</span></label>
                        <input type="number" formControlName="amount" class="col-12 form-control">
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Remarks </label>
                        <input type="text" formControlName="remarks" class="col-12 form-control">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
              <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
