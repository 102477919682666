<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <!-- Header -->
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? ('Edit' | translate) : data.mode === 'duplicate' ? ('Duplicate' | translate) : 'Create' | translate }} {{ 'Verification Settings' | translate }}</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 form-group">
            <label class="control-label">{{ 'Verification Type' | translate }} <span class="text-danger">* </span></label>
            <select class="form-control" formControlName="type" (change)="onUpdateValidator()">
              <option [value]="null" [disabled]="true">Please Select</option>
              <ng-container *ngFor="let value of dropdown.type">
                <option [value]="value.value">{{ value.name }}</option>
              </ng-container>
            </select>
          </div>
          <div class="col-md-6 form-group" *ngIf="form.getRawValue().type == 1">
            <label class="control-label">{{ 'Bank Type' | translate }} <span class="text-danger">* </span></label>
            <select class="form-control" formControlName="bank_type">
              <option [value]="null" [disabled]="true">Please Select</option>
              <ng-container *ngFor="let value of dropdown.bankType">
                <option [value]="value.id">{{ value.name }}</option>
              </ng-container>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 form-group d-flex align-items-end">
            <div class="col-md-6 form-group">
              <label class="control-label">{{ 'Linked Message Template (Approve)' | translate }} <span class="text-danger">* </span></label>
              <kt-dropdown-wo-lazyload
                #approveSelect
                [form] = 'form'
                [dropdownList] = 'dropdown.messageTemplateDropdownList'
                [dropdownSettings] = 'sendMessageTemplateDropdownSettings'
                [formName] = "'approve_message_template_id'"
                [selectionAttributes] = "'id'"
                [selectedItems]="selectedApproveMessageTemplate"
                [isMessageTemplate]="true"
                [dataLoading]="loading"
                [formInvalid]="form.controls['approve_message_template_id'].invalid && form.controls['approve_message_template_id'].touched"
                (click)="onTemplateSelect(approveSelect.selectedItems, 'approve')">
              </kt-dropdown-wo-lazyload>
            </div>

            <div class="col-md-6 form-group">
              <label class="control-label">{{ 'Linked Message Template (Reject)' | translate }} <span class="text-danger">* </span></label>
              <kt-dropdown-wo-lazyload
                #rejectSelect
                [form] = 'form'
                [dropdownList] = 'dropdown.messageTemplateDropdownList'
                [dropdownSettings] = 'sendMessageTemplateDropdownSettings'
                [formName] = "'reject_message_template_id'"
                [selectionAttributes] = "'id'"
                [selectedItems]="selectedRejectMessageTemplate"
                [isMessageTemplate]="true"
                [dataLoading]="loading"
                [formInvalid]="form.controls['reject_message_template_id'].invalid && form.controls['reject_message_template_id'].touched"
                (selectedItemsChanged)="onTemplateSelect(rejectSelect.selectedItems, 'reject')">
              </kt-dropdown-wo-lazyload>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="col">
          <div style="display: contents;">
            <i class="fas fa-circle warn-icon" [hidden]="isCurrencyAvailable()"></i>
            <button type="button" class="btn btn-brand" [disabled]="form.get('bank_type').value == null" (click)="onOpenDialog('currency', data.mode)"><i [class]="currencyBtnLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-plus'"></i>Currency</button>
          </div>
          <button type="button" class="btn btn-brand ml-2" (click)="onOpenDialog('create message template', 'create')"><i [class]="messageTemplateBtnLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-plus'"></i>Message Template</button>
        </div>

        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>{{ 'Close' | translate }}</button>
        <kt-submit-button [isDisabled]="!form.valid || !isCurrencyAvailable()" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event, true)"></kt-swal-alert>
