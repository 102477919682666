// Angular / Core
import { Component, ComponentRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';
// Component
import { RegistrationPageEditComponent } from './dialogs/registration-page-edit.component';
// Model
import { Pagination } from '@core/models/pagination.model';
import { RegistrationPage } from '@core/models/registration-page.model';
// Services
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { environment } from '@env/environment';
import { RegistrationPageDataService } from './services/registration-page-data.service';
//Enums 
import { Status } from '@core/enums/status.enum';
// Third Party
import { Subscription, of } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import moment from 'moment';
import { LoadingBarService } from '@ngx-loading-bar/core';
import Swal from 'sweetalert2';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss']
})
export class RegistrationPageComponent implements OnInit, OnDestroy {
  @ViewChild('tooltipCode') tooltipCode: MatTooltip;

  form: FormGroup;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  registrationPage$ = [];
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = undefined;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    types: this.dropdownHttpService.displayTypes,
    platform: [
      { id: 'all', name: 'All' },
      // ...this.dropdownHttpService.sitePlatforms,
      { id: '1', name: 'User Portal' }
    ],
    locales: this.dropdownHttpService.locales,
    perPage: this.dropdownHttpService.perPage
  };
  signUpUrl = environment.signUpUrl;
  mobileSignUpUrl = environment.mobileSignUpUrl;
  status = Status;
  messages$ = this.registrationPageDataService.messages$;

  // permissions
  canCreateRegistrationPage: boolean;
  canViewRegistrationPageDetails: boolean;
  canDuplicateRegistrationPage: boolean;
  canUpdateRegistrationPageStatus: boolean;

  private subscriptions = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private registrationPageDataService: RegistrationPageDataService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.pagination = this.registrationPageDataService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateRegistrationPage = appPermissions.create_registration_page;
      this.canViewRegistrationPageDetails = appPermissions.view_registration_page_details;
      this.canDuplicateRegistrationPage = appPermissions.duplicate_registration_page;
      this.canUpdateRegistrationPageStatus = appPermissions.update_registration_page_status;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.registrationPage$ = [];
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.registrationPageDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.page = 1;
            this.registrationPage$ = res;
            this.dataLength = res.length;
            this.pagination = this.registrationPageDataService.pagination;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.registrationPage$ = [];
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.registrationPageDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.registrationPage$ = res;
        this.dataLength = res.length;
        this.pagination = this.registrationPageDataService.pagination;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onChangeStatus(selectedStatus: number, row: RegistrationPage) {
    const text = selectedStatus == 0 ? 'This action will restore the registration page to its default design.' : 'This action will update the registration page to use the current design.';
    if (selectedStatus !== row.status) {
      Swal.fire({
        title: 'System Alert',
        html: '<div class="text-center">Do you want to save the changes? <br> <div class="my-3 text-red">' + text + '<div></div>',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Yes',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          const data = {
            id: row.id,
            status: selectedStatus
          }
          this.registrationPageDataService.updateStatus(data).pipe(
            tap(res => {
              this.onViewPageBy(this.page);
            })
          ).subscribe();
        }
      });
    }
  }

  onOpenDialog(mode: string, id?: any) {
    switch (mode) {
      case 'create':
        this.openDialogBy(RegistrationPageEditComponent, { mode: mode });
        break;
      case 'edit':
      case 'duplicate':
        this.registrationPageDataService.getById(id).subscribe(res => {
          this.openDialogBy(RegistrationPageEditComponent, { mode: mode, registrationPage: res });
        });
        break;
    }
  }

  private openDialogBy(componentRef: any, data?: { mode: any, registrationPage?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'duplicate' ? '800px' : '1200px',
      data: {
        mode: data.mode,
        registrationPage: data.registrationPage
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page);
      }
    })
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') {
        fields[key] = key === 'start_time' || key === 'end_time' ? moment(formData[key]).utc().format('YYYY-MM-DD HH:mm:ss') : formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      type_id: new FormControl('all'),
      platform_id: new FormControl('all'),
      layout_id: new FormControl('all'),
      code: new FormControl(null),
      settings_locale_id: new FormControl('all'),
      status: new FormControl('all')
    });
  }

}
