import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { map } from 'rxjs/operators';

@Injectable()
export class VersionHttpService {

  constructor(private http: HttpClient) { }

  getVersion() {
    return this.http.get<ApiResponse>('/version').pipe(
      map(res => res.data.rows)
    );
  }

}
