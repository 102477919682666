import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Imports } from '@core/models/imports.model';
import { Pagination } from '@core/models/pagination.model';

@Injectable()

export class ImportHistoryDataService {

  constructor(private http: HttpClient) { }
  pagination: Pagination;

  getWithQuery(params?: string): Observable<Imports[]> {
    return this.http.get<ApiResponse>(`/imports?${params}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }
  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}