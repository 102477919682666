import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class RoleHttpService {

  constructor(private http: HttpClient) { }

  getRoles(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/role?paginate=false`).pipe(
      map(res => res.data.rows)
    );
  }

  getPermissions(){
    return this.http.get<ApiResponse>(`/role/permission`).pipe(
      map(res => res.data.rows)
    );
  }

  getTelemarketer(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/telemarketerlist`).pipe(
      map(res => res.data.rows)
    );
  }

  getAccountManager(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/accountManagerList`).pipe(
      map(res => res.data.rows)
    );
  }

  getNormalAccountManager(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/normalaccountManagerList`).pipe(
      map(res => res.data.rows)
    );
  }

  getVipAccountManager(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/vipaccountManagerList`).pipe(
      map(res => res.data.rows)
    );
  }
}
