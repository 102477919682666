<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} System Maintenance</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <!-- Maintain From -->
        <div class="col-12 form-group row">
          <label class="col-4 control-label">From <span class="text-danger">*</span></label>
          <div class="col-8 input-group date">
            <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack?.maintain_from" [ngModelOptions]="{standalone: true}">
            <input type="text" formControlName="maintain_from" class="form-control">
            <span class="input-group-append" [owlDateTimeTrigger]="dateStartRef">
              <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
            </span>
            <owl-date-time #dateStartRef></owl-date-time>
          </div>
        </div>
        <!-- Maintain To -->
        <div class="col-12 form-group row">
          <label class="col-4 control-label">To <span class="text-danger">*</span></label>
          <div class="col-8 input-group date">
            <input class="hidden" [owlDateTime]="dateEndRef" [ngModel]="dateTimeStack?.maintain_to" [ngModelOptions]="{standalone: true}">
            <input type="text" formControlName="maintain_to" class="form-control">
            <span class="input-group-append" [owlDateTimeTrigger]="dateEndRef">
              <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
            </span>
            <owl-date-time #dateEndRef></owl-date-time>
          </div>
        </div>
        <!-- Status -->
        <div class="col-12 form-group row" *ngIf="data.mode === 'edit'">
          <label class="col-4 control-label">Status <span class="text-danger">*</span></label>
          <select formControlName="status" class="col-8 form-control">
            <option value="null" disabled hidden> Please Select </option>
            <option value=1> Enabled </option>
            <option value=0> Disabled </option>
          </select>
        </div>
        <!-- Remarks -->
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Remarks</label>
          <input type="text" formControlName="remarks" class="col-8 form-control">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button *ngIf="data.mode === 'edit' ? canEditSystemMaintenance : canCreateSystemMaintenance" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
