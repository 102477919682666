import { DatePipe } from '@angular/common';
import { push } from 'object-path';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { AllEvents } from '@core/models/all-events.model';
import { AllEventsDataService } from '../../services/all-events-data.service';
import { catchError, map, tap } from 'rxjs/operators';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { Observable, Subscription, forkJoin } from 'rxjs';
import * as moment from 'moment-timezone';


@Component({
  selector: 'kt-update-result',
  templateUrl: './update-result.component.html',
  styleUrls: ['./update-result.component.scss']
})
export class UpdateResultDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  form: FormGroup;
  @ViewChild(OwlDateTimeInputDirective) datePicker: OwlDateTimeInputDirective<any>;

  messages$ = this.allEventsDataService.messages$;
  event_info$: Observable<any>;
  selectedTeam = [];
  predictionType = null;
  event_name = null;
  private datePickerSubscription = new Subscription();
  dateTimeStack = null;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;

  dropdown = {
    teams: null
  }

  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<UpdateResultDialogComponent>,
    private allEventsDataService: AllEventsDataService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.formInit();
    this.event_info$ = this.allEventsDataService.showResult(this.data.row.id).pipe(
      tap((res)=> {
        this.dropdown.teams = res.teams.sort((a,b) => (a.team_name.toLowerCase() < b.team_name.toLowerCase() ? -1 : (a.team_name.toLowerCase() > b.team_name.toLowerCase()) ? 1 : 0));
        this.predictionType = res.prediction_type;
        this.event_name = res.name;
        this.selectedTeam = Array(res.number_of_teams).fill(0);
        this.form.patchValue({
          event_id: res.id,
          total_prize_pool: res.total_prize_pool
        });
        for(let index = 0; index < res.number_of_teams; index++) {
          this.team.push(new FormGroup({
            id: new FormControl(null, [1, 3].includes(res.prediction_type) ? Validators.required : null),
            score: new FormControl(null, [2, 3].includes(res.prediction_type) ? Validators.required : null)
          }))
        }
      })
    )
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'release_date'),
    ]).subscribe();
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
  }

  onSave() {
    this.buttonLoading = true;

    const data = {
      ...this.form.value,
      release_date: this.isValidDate(this.form.value.release_date) ? moment(this.form.value.release_date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') : null,
    };

    Object.keys(data).forEach((key) => (data[key] == null) && delete data[key]);

    this.allEventsDataService.setResult(data).pipe(
      tap((res: any) => {
        this.messages$.next([...res.message]);
        this.buttonLoading = false;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

  onCloseDialog() {
    this.dialogRef.close(true);
  }

  onSelectedTeamOption(from, team_id) {
    this.selectedTeam[from] = +team_id;
  }

  get team() {
    return this.form.controls['team'] as FormArray;
  }

  private formInit() {
    this.form = new FormGroup({
      team: new FormArray([]),
      event_id: new FormControl(null, Validators.required),
      total_prize_pool: new FormControl(null, Validators.required),
      total_winner: new FormControl(null, Validators.required),
      release_date: new FormControl(null, Validators.required)
    });
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.valueChange.pipe(
      map(res => this.datePipe.transform(res, 'yyyy-MM-dd HH:mm:ss')),
      tap(date => {
        this.form.patchValue({ [formKey] : date });
      }),
    );
  }

  private isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

}
