import { ApiResponse } from '@core/models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { AffiliateWithdrawals } from '@core/models/affiliate-withdrawals.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { tap, map, concatMap } from 'rxjs/operators';

@Injectable()
export class AffiliateWithdrawalDataService extends DefaultDataService<AffiliateWithdrawals>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('AffiliateWithdrawals', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<AffiliateWithdrawals[]> {
    return this.http.get<ApiResponse>('/affiliate/withdraw').pipe(
      tap(res => {this.paginationInit(res)}),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<AffiliateWithdrawals[]>{
    return this.http.get<ApiResponse>(`/affiliate/withdraw${pageParam}`).pipe(
      tap(res => {this.paginationInit(res)}),
      map(res => res.data.rows)
    )
  }

  getById(id: number): Observable<AffiliateWithdrawals>{
    return this.http.get<ApiResponse>(`/affiliate/withdraw/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  updateCurrentWithdraw(withdraw: any) {
    return this.http.put<ApiResponse>(`/affiliate/withdraw/${withdraw.id}`, withdraw).pipe(
      tap( res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  updateToNewHolder(withdraw: any) {
    return this.http.put<ApiResponse>(`/affiliate/withdraw/${withdraw.id}`, withdraw);
  }

  updateRemarks(withdraw: any) {
    return this.http.put<ApiResponse>(`/affiliate/withdraw/remarks/${withdraw.id}`, withdraw).pipe(
      tap( res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }
}