import { Component, OnInit, Inject } from '@angular/core';
import { of } from 'rxjs';
import { VerificationSettingBankDataService } from './services/verification-settings-bank-data.service';
import { Pagination } from '@core/models/pagination.model';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { tap, catchError, map, exhaustMap } from 'rxjs/operators';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { VerificationSettingsBankDetailComponent } from './dialogs/verification-settings-bank-detail.component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'kt-verification-settings-bank',
  templateUrl: './verification-settings-bank.component.html',
  styleUrls: ['./verification-settings-bank.component.scss']
})
export class VerificationSettingsBankComponent implements OnInit {

  verificationSettingBank$ = localStorage.getItem('createVerificationSettingsBank') === null ? [] : JSON.parse(localStorage.getItem('createVerificationSettingsBank')).filter(item => item.settings_currency_id === this.data.currencyID);
  data$ = this.verificationSettingBankDataService.data$;
  messages$ = this.verificationSettingBankDataService.messages$;

  pagination: Pagination;
  form: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dataLength: number;
  perPageDropdown = this.dropdownHttpService.perPage;
  clearBtnLoading = false;
  searchBtnLoading = false;
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, verificationSettingsID: number, currencyID: number, bankTypeID: number, new_currency: boolean },
    private loadingBar: LoadingBarService,
    private verificationSettingBankDataService: VerificationSettingBankDataService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VerificationSettingsBankComponent>,
  ) { }

  ngOnInit() {
    this.formInit();
    if (this.data.mode === 'edit' && !this.data.new_currency){
      this.onSubmit();
      this.pagination = this.verificationSettingBankDataService.pagination;
    }
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    this.loading = true;
    return this.verificationSettingBankDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.verificationSettingBank$ = res;
        this.pagination = this.verificationSettingBankDataService.pagination;
        this.dataLength = res.length;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  onPerPage(size: Event){
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog() {
    this.openDialogBy(VerificationSettingsBankDetailComponent);
  }

  private reload() {
    this.onViewPageBy(this.page);
  }

  private openDialogBy(componentRef: any) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        mode: this.data.mode,
        verificationSettingsID: this.data.mode == 'edit' ? this.data.verificationSettingsID : null,
        currencyID: this.data.currencyID,
        bankTypeID: this.data.bankTypeID,
        new_currency: this.data.new_currency
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== undefined) {
        if (this.data.mode == 'edit' && !this.data.new_currency) {
          this.onViewPageBy(this.page);
        } else {
          setTimeout(() => {
            this.verificationSettingBank$ = localStorage.getItem('createVerificationSettingsBank') === null ? [] : JSON.parse(localStorage.getItem('createVerificationSettingsBank')).filter(item => item.settings_currency_id === this.data.currencyID);
          }, 300);
        }
      }
    });
  }

  onUpdateBank(row?: any){
    const rowid = row?.id;

    if (this.data.mode === 'edit' && !this.data.new_currency) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to delete this bank?',
        icon: 'info',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((resp) => {
        if (resp.value) {
          this.verificationSettingBankDataService.deleteBank(rowid).pipe(
            tap((res: any) => {
              localStorage.setItem('updateVerificationSettingsBank', '1');
            }),
            catchError((error) => {
              throw error;
            })
          ).subscribe();
          setTimeout(() => {
            this.reload();
          }, 1000);
        }
      });
    } else {
      var bankArray = JSON.parse(localStorage.getItem("createVerificationSettingsBank"));
      if (bankArray != null) {
        bankArray.filter((res, index) => {
          if (res.id == rowid) {
            bankArray.splice(index, 1)
          }
        });
      }
  
      localStorage.setItem("createVerificationSettingsBank", JSON.stringify(bankArray));
      this.verificationSettingBank$ = localStorage.getItem('createVerificationSettingsBank') === null ? [] : JSON.parse(localStorage.getItem('createVerificationSettingsBank')).filter(item => item.settings_currency_id === this.data.currencyID);
    }
  }

  resetData() {
    this.loadingBar.complete();
    this.verificationSettingBankDataService.resetData();
  }

  onSubmit(clearSearch?: boolean) {
    this.loading = true;
    this.searchBtnLoading = clearSearch ? false : true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = `${Object.keys(data).map(key => key + '=' + data[key]).join('&')}`
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();

        return this.verificationSettingBankDataService.getWithQuery(`?&page=${this.page}&perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.page = 1;
            this.pagination = this.verificationSettingBankDataService.pagination;
            this.verificationSettingBank$ = res;
            this.dataLength = res.length;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private formInit() {
    let verificationSettingsID = this.data.mode == 'edit' ? this.data.verificationSettingsID : null;
    let currencyID = this.data.mode == 'edit' ? this.data.currencyID : null;

    this.form = new FormGroup({
      bank_name: new FormControl(null),
      verification_setting_id: new FormControl(verificationSettingsID),
      settings_currency_id: new FormControl(currencyID),
    });
  }
}
