import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, observable } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { PromotionStatistic } from '@core/models/promotion-statistic.model';
import { Pagination } from '@core/models/pagination.model';

@Injectable()
export class PromotionStatisticDataService extends DefaultDataService<PromotionStatistic> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  promoStat= [];
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('PromotionStatistic', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<PromotionStatistic[]>{
    return this.http.get<ApiResponse>(`/promotion/report${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => this.setPromo(res))
    )
  }

  export(pageParam: string) {
    return this.http.get<ApiResponse>(`/promotion/report/export${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private setPromo(res: any) {
    this.promoStat = [];
    if(res && res.success){
      Object.keys(res.data.rows).forEach(key =>  {
          this.promoStat.push(res.data.rows[key]);
      });
    }
    return this.promoStat;
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }

}
