<form class="kt-form w-webkit-fill-available config-overflow" [formGroup]="formContent">
  <div class="row">
    <div class="col-lg-6 form-group">
      <label class="control-label">Member Group<span class="text-danger">*</span></label>
      <kt-dropdown-wo-lazyload
          [form] = "formContent"
          [dropdownList] = "memberGroupDropdownList"
          [dropdownSettings] = "memberGroupDropdownSettings"
          [selectionAttributes] = "'id'"
          [formName]="'member_group'"
          [selectedItems]="memberGroupSelectedItems"
      >
      </kt-dropdown-wo-lazyload>
    </div>
    <div class="col-lg-6 form-group">
      <label class="control-label">Status<span class="text-danger">*</span></label>
      <select class="form-control" formControlName="status">
          <option [value]="null" [disabled]="true">Please Select</option>
          <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 form-group">
        <label class="control-label">Bank/E-Wallet List<span class="text-danger">*</span></label>
        <div>
            <button type="button" class="btn btn-brand mt-2" (click)="onOpenDialog('bank-list')"><i class="fa fa-plus" aria-hidden="true"></i>Bank List</button>
        </div>
    </div>
  </div>

  <mat-tab-group animationDuration="0ms" class="col-12 form-group">
    <ng-container *ngIf="dropdown.locales.length > 0">
      <mat-tab *ngFor="let language of dropdown.locales; let i = index">
        <ng-container formGroupName="content">
          <ng-container [formGroupName]="language.id">
            <ng-template mat-tab-label>
              {{language.code}}
            </ng-template>
            <input type="hidden" formControlName="settings_locale_id" [value]="language.id" />
            <!-- <input type="hidden" formControlName="id" [value]="data.promotionContent.content.id" *ngIf="mode === 'edit'" /> -->
            <div class="col-12 form-group">
              <label>Title <span class="text-danger" *ngIf="language.lang_code == 'EN'">*</span></label>
              <input type="text" formControlName="title" class="col-12 form-control">
            </div>

            <div class="col-12 form-group">
              <label>Content <span class="text-danger" *ngIf="language.lang_code == 'EN'">*</span></label>
              <ckeditor [config]="editorConfig" [editor]="editor" formControlName="content"></ckeditor>
            </div>
          </ng-container>
        </ng-container>

        <div formArrayName="field" class="mt-4">
          <div class="col-12 form-group">
            <table class="table fieldTable">
              <thead>
                <tr>
                  <th></th>
                  <th>Field Name</th>
                  <th>Field Type</th>
                  <th style="width: 140px">Position</th>
                  <th class="text-center" style="width: 130px">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let field of formContent.get('field').controls; let i = index" [formGroupName]="i">
                  <td class="text-center">
                    <div class="pointer-cursor" (click)="addField()"><i class="fas fa-plus"></i></div>
                  </td>
                  <td>
                    <ng-container formGroupName="field_details">
                      <ng-container [formGroupName]="language.id">
                        <input formControlName="name" placeholder="Enter field name" class="form-control">
                      </ng-container>
                    </ng-container>
                  </td>
                  <td>
                    <select formControlName="type" class="form-control" (change)="onChangeType($event, i)" [attr.disabled]="isFromDetailField(i) && mode === 'edit' ? true : null">
                      <option value="null" disabled>Please Select</option>
                      <option value="1">Textbox</option>
                      <option value="2">Upload File</option>
                    </select>
                  </td>
                  <td>
                    <ng-container formGroupName="field_details">
                      <ng-container [formGroupName]="language.id">
                        <input formControlName="position" placeholder="Enter position" class="form-control" type="number">
                      </ng-container>
                    </ng-container>
                  </td>
                  <td class="text-center">
                    <ng-container *ngIf="!isFromDetailField(i) || mode == 'create' else statusTemplate">
                      <div class="pointer-cursor" (click)="removeField(i)"><i class="fas fa-trash"></i></div>
                    </ng-container>

                    <ng-template #statusTemplate>
                      <select class="form-control" formControlName="status">
                        <option [value]="null" [disabled]="true">Please Select</option>
                        <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
                      </select>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</form>