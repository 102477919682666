import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiResponse } from '@core/models/api-response.model';
import { TwoFADataService, TwoFAVerifyRequest } from './services/twofa-data.service';
import { TwoFA } from '@core/models/twofa.model';
@Component({
  templateUrl: './account-twofa.component.html',
  styleUrls: ['./account-twofa.component.scss']
})
export class AccountTwoFADialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  messages$ = new Subject<any[]>();
  hasError: boolean;
  subscription = new Subscription();
  buttonLoading = false;
  loadingQR: boolean = true;
  twoFaData: TwoFA = null;
  constructor(
    public dialogRef: MatDialogRef<AccountTwoFADialogComponent>,
    private twoFADataService: TwoFADataService
  ) { }
  ngOnInit() {
    this.formInit();
    this.onGetQRCode();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }
  onVerifyCode() {
    const data: TwoFAVerifyRequest = {
      code: this.form.value.code,
    }
    this.buttonLoading = true;
    this.twoFADataService.verifyTwoFACode(data).pipe(
      tap((res) => {
        this.buttonLoading = false;
        this.hasError = false;
        this.messages$.next([...res.message]);
        this.twoFaData.first_verify = true;
      }),
      catchError((error) => {
        this.hasError = true;
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }
  onGetQRCode() {
    this.twoFADataService.getQRCode().pipe(
      tap((res) => {
        this.twoFaData = res;
      })
    ).subscribe();
  }
  private formInit() {
    this.form = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    });
  }
  onImageLoad() {
    this.loadingQR = false;
  }
  
}