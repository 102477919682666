<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-center">
        <h5 class="modal-title">Check Transfer - {{ data.username }} ({{ data.id }})</h5>
        <span class="modal-x-button" style="right: -19%;position: relative;" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body row">
        <!-- Created At -->
        <div class="col-12 form-group row d-flex justify-content-center m-0 p-0">
          <label class="col-3 offset-2 col-form-label">Created At</label>
          <div class="col-5 col-form-label">: {{ data.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</div>
        </div>
        <!-- From -->
        <div class="col-12 form-group row d-flex justify-content-center m-0 p-0">
          <label class="col-3 offset-2 col-form-label">From</label>
          <div class="col-5 col-form-label">: {{ data.from }}</div>
        </div>
        <!-- To -->
        <div class="col-12 form-group row d-flex justify-content-center m-0 p-0">
          <label class="col-3 offset-2 col-form-label">To</label>
          <div class="col-5 col-form-label">: {{ data.to }}</div>
        </div>
        <!-- Currency -->
        <div class="col-12 form-group row d-flex justify-content-center m-0 p-0">
          <label class="col-3 offset-2 col-form-label">Currency</label>
          <div class="col-5 col-form-label">: {{ data.currency }}</div>
        </div>
        <!-- Amount -->
        <div class="col-12 form-group row d-flex justify-content-center m-0 p-0">
          <label class="col-3 offset-2 col-form-label">Amount</label>
          <div class="col-5 col-form-label">: {{ data.amount | number:'1.2-2' }}</div>
        </div>
        <!-- Status -->
        <div class="col-12 form-group row d-flex justify-content-center m-0 p-0">
          <label class="col-3 offset-2 col-form-label">Status</label>
          <div class="col-5 col-form-label">: {{ data.gsys_status_name | titlecase }}</div>
        </div>
      </div>
      <!-- Action Button -->
      <div class="col-12 form-group row d-flex justify-content-center mt-2 mb-5">
        <button *ngIf="canMarkAsFailed" class="btn btn-danger btn-real-danger" (click)="onMarkAs('Failed')" [disabled]="data.cms_status === 4">Mark as Failed</button>
        <button *ngIf="canRejectTransfers" class="btn btn-danger mx-3" (click)="onMarkAs('Rejected')">Rejected</button>
        <button *ngIf="canApproveTransfers" class="btn btn-success" (click)="onMarkAs('Approved')">Approved</button>
      </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog()"></kt-swal-alert>
