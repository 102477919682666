import { GameProviderReport } from '@core/models/game-provider-report.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { CompanyReport } from '@core/models/company-report.model';

@Injectable()
export class CompanyReportEntityService extends EntityCollectionServiceBase<CompanyReport> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('CompanyReport', serviceElementsFactory);
  }
}