import { RebateReleaseEntityService } from './rebate-releases/services/rebate-release-entity.service';
import { RebateReleaseDataService } from './rebate-releases/services/rebate-release-data.service';
import { RebateDataService } from './rebates/services/rebate-data.service';
import { RebateEntityService } from './rebates/services/rebate-entity.service';
import { RebatesRoutingModule } from './rebates-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { entityMetadata } from '../../../../app.module';
import { EntityDefinitionService, EntityDataService } from '@ngrx/data';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DecimalPipe } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { RebatesComponent } from './rebates/rebates.component';
import { RebateEditDialogComponent } from './rebates/dialogs/rebate-edit.component';
import { RebateGenerateDialogComponent } from './rebate-releases/dialogs/rebate-generate.component';
import { RebateReleasesComponent } from './rebate-releases/rebate-releases.component';
import { RebateHistoryComponent } from './rebate-history/rebate-history.component';
import { RebateHistoryDataService } from './rebate-history/services/rebate-history-data.service';
import { RebateHistoryEntityService } from './rebate-history/services/rebate-history-entity.service';
import { PartialsModule } from '@views/partials/partials.module';
import { NewRebateReleaseEntityService } from './new-rebate-releases/services/new-rebate-release-entity.service';
import { NewRebateReleaseDataService } from './new-rebate-releases/services/new-rebate-release-data.service';
import { NewRebateReleasesComponent } from './new-rebate-releases/new-rebate-releases.component';
import { NewRebateGenerateDialogComponent } from './new-rebate-releases/dialogs/rebate-generate/new-rebate-generate.component';
import { RebateUpdateSettingDialogComponent } from './new-rebate-releases/dialogs/rebate-update-setting/rebate-update-setting.component';
import { RebateDetailsDialogComponent } from './new-rebate-releases/dialogs/rebate-details/rebate-details.component';
import { RebateSettingDetailsDialogComponent } from './new-rebate-releases/dialogs/rebate-setting-details/rebate-setting-details.component';
import { PromoTurnoverDetailsDialogComponent } from './rebate-releases/dialogs/promo-turnover-details.component';
import { TargetAmountDetailsDialogComponent } from './rebate-releases/dialogs/target-amount-details.component';
import { RebateListDialogComponent } from './new-rebate-releases/dialogs/rebate-list/rebate-list.component';
@NgModule({
  declarations: [
    RebatesComponent, 
    RebateEditDialogComponent, 
    RebateReleasesComponent, 
    RebateHistoryComponent, 
    RebateGenerateDialogComponent,
    PromoTurnoverDetailsDialogComponent,
    TargetAmountDetailsDialogComponent,
    NewRebateReleasesComponent,
    NewRebateGenerateDialogComponent,
    RebateUpdateSettingDialogComponent,
    RebateDetailsDialogComponent,
    RebateSettingDetailsDialogComponent,
    RebateListDialogComponent
  ],
  imports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AngularMultiSelectModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RebatesRoutingModule,
    SharedModule,
    PartialsModule,
    NgbModule,
    NgSelectModule,
    SharedModule,
    NgxDaterangepickerMd.forRoot()
  ],
  providers: [
    DecimalPipe,
    RebateEntityService,
    RebateDataService,
    RebateReleaseDataService,
    RebateReleaseEntityService,
    RebateHistoryDataService,
    RebateHistoryEntityService,
    NewRebateReleaseDataService,
    NewRebateReleaseEntityService
  ]
})
export class RebatesModule {
  constructor(
    private eds: EntityDefinitionService,
    private entityDataService: EntityDataService,
    private rebateDataService: RebateDataService,
    private rebateReleaseDataService: RebateReleaseDataService,
    private rebateHistoryDataService: RebateHistoryDataService,
    private newRebateReleaseDataService: NewRebateReleaseDataService
  ) {
    eds.registerMetadataMap(entityMetadata);
    entityDataService.registerServices({
      RebateRelease: rebateReleaseDataService,
      RebateHistory: rebateHistoryDataService,
      Rebate: rebateDataService,
      NewRebateRelease: newRebateReleaseDataService,
    });
  }
 }
