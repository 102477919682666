<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right mb-2">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Game Provider:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <select formControlName="code" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.code" *ngFor="let value of gameProviders">
                                            {{ value.code }} - {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Category:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <select formControlName="category_code" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.code" *ngFor="let value of dropdown.categories | async">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <select formControlName="status" class="form-control">
                                        <option value="all"> All </option>
                                        <ng-container *ngFor="let key of object.keys(maintenanceHoursStatus)">
                                            <option [value]="maintenanceHoursStatus[key]" *ngIf="+key >= 0">{{ maintenanceHoursStatus[key] }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control ">
                                    <div class="input-group date">
                                        <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true"
                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                            [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                        ngxDaterangepickerMd/>
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <div class="dropdown dropdown-inline">
                    <button *ngIf="canCreateGameProviderMaintenanceHour" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')">
                        <i class="fas fa-plus"></i>Create
                    </button>
                </div>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <th  class="text-center">Game Provider</th>
                                <th class="text-center">Category</th>
                                <th class="text-center">Currency</th>
                                <th class="text-center date-column">Start</th>
                                <th class="text-center date-column">End</th>
                                <th class="text-center">Status</th>
                                <th class="text-center">Created By</th>
                                <th class="text-center">Updated By</th>
                                <th class="text-center">Actions</th>
                            </thead>
                            <tbody *ngIf="(maintenanceHours$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td class="text-center">{{ row.name }}</td>
                                    <td class="text-center">{{ row.category }}</td>
                                    <td class="text-center">{{ row.currency }}</td>
                                    <td class="text-center">{{ row.maintain_from | timezoneDate: 'YYYY-MM-DD HH:mm' }}</td>
                                    <td class="text-center">{{ row.maintain_to | timezoneDate: 'YYYY-MM-DD HH:mm' }}</td>
                                    <td class="text-center">
                                        <span [class]="'kt-badge kt-badge--inline kt-badge--pill kt-badge--wide kt-badge--'+maintenanceHoursStatus[row.status]">
                                            {{ row.status }}
                                        </span>
                                    </td>
                                    <td>
                                        {{ row.created_by ? row.created_by : 'System' }} <br />
                                        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        {{ row.updated_by ? row.updated_by : 'System' }} <br />
                                        <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                  </td>
                                    <td class="text-center text-middle">
                                        <button *ngIf="canEditGameProviderMaintenanceHour" matTooltip="Edit Maintenance"
                                            class="col-6 btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"
                                            (click)="onOpenDialog('edit', row)"><i
                                                class="fas fa-edit"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
