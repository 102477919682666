import { Operator } from '@core/models/operator.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { ReferralCommissionSetting } from '@core/models/referral-commission-setting.model';
@Injectable()
export class ReferralCommissionSettingsEntityService extends EntityCollectionServiceBase<ReferralCommissionSetting>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ReferralCommissionSettings', serviceElementsFactory);
  }
}
