import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppPermissionService } from '@core/services/app-permission.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-raffle-events',
  templateUrl: './raffle-events.component.html',
  styleUrls: ['./raffle-events.component.scss']
})
export class RaffleEventsComponent implements OnInit, OnDestroy {
  // permissions


  private subscriptions = new Subscription();

  constructor(
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit(): void {
    // const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {

    // });

    // this.subscriptions.add(apSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
