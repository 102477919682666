<ng-container *ngIf="data.member as item">
  <div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Check Promotion Eligible - {{ data.member.username }} ({{ data.member.id }})</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="row col-md-12" style="padding-right: 20px;">
            <div class="col-6 form-group pr-0 mb-0 promotion-search-row">
              <label class="col-form-label">Promotion:</label>
              <div class="promotion-dropdown" style="padding:0">
                <kt-dropdown-wo-lazyload 
                  id='promotionCodeDropDown' 
                  [form]='form' 
                  [dropdownList]='promotionCodeDropdownList' 
                  [dropdownSettings]='promotionCodeDropdownSettings' 
                  [formName]="'name'" 
                  [selectionAttributes]="'name'" 
                  [selectedItems]='promotionCodeSelectedItems' 
                  [dataLoading]='promotionSpinnerLoading'>
                </kt-dropdown-wo-lazyload>
              </div>
            </div>
            <div class="col-6 form-group pr-0 mb-0 promotion-search-row">
              <label class="col-form-label">Game Provider:</label>
              <div class="promotion-dropdown" style="padding:0">
                <kt-dropdown-wo-lazyload 
                  id='gameProviderDropDown' 
                  [form]='form' 
                  [dropdownList]='gameProviderDropdownList' 
                  [dropdownSettings]='gameProviderDropdownSettings' 
                  [formName]="'game_provider_code'" 
                  [selectionAttributes]="'code'" 
                  [selectedItems]='gameProviderSelectedItems' 
                  [dataLoading]='gameProviderSpinnerLoading'>
                </kt-dropdown-wo-lazyload>
              </div>
            </div>
          </div>

          <ng-container *ngIf="eligibleCondition.length > 0; else successMsg">
            <div *ngIf="message" class="mb-4 mt-4">
              <mat-accordion>
                <mat-expansion-panel [expanded]="true" class="eligible-details" #eligibleDetails>
                  <!-- Title -->
                  <mat-expansion-panel-header>
                    <mat-panel-title class="error-msg-color"> <i _ngcontent-uyi-c32="" aria-hidden="true" class="fa fa-times"></i> {{ message }} </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="mt-0" />
                  <h5 class="h5-text">Eligible Details</h5>
                  <div class="row" style="align-items: center;">
                    <ul class="reason-container">
                      <li *ngFor="let ec of eligibleCondition">
                        <span class="reason-head">{{ ec['type'] }}:</span>{{ replaceLineBreaks(ec['message']) }}
                      </li>
                    </ul>
                  </div>
                  <ng-container *ngIf="preRequisites.length > 0">
                    <hr class="mt-3" />
                    <h5 class="h5-text">Pre-Requisites</h5>
                    <div class="row" style="align-items: center;">
                      <ul class="reason-container">
                        <li *ngFor="let pr of preRequisites">
                          <span class="reason-head">{{ pr['type'] }}:</span>{{ replaceLineBreaks(pr['message']) }}
                        </li>
                      </ul>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="importantNote == true && buttonLoading == false">
                    <hr class="mt-3" />
                    <h5 class="h5-text">IMPORTANT: <span class="important-note">Certain promotion eligibility criteria tied to game provider may not be captured. Please select your preferred game provider for more detailed information.</span></h5>
                  </ng-container>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </ng-container>

          <ng-template #successMsg> 
            <ng-container *ngIf="message && preRequisites.length > 0 else noPreRequisites">
              <div class="mb-4 mt-4">
                <mat-accordion>
                  <mat-expansion-panel [expanded]="true" class="eligible-details" #eligibleDetails>
                    <!-- Title -->
                    <mat-expansion-panel-header style="margin: 20px 0;">
                      <mat-panel-title class="eligible-message-container-2 success-msg-color" style="background: #EBF5F0;"> <i _ngcontent-uyi-c32="" aria-hidden="true" class="fa fa-check"></i> {{ message }} </mat-panel-title>
                    </mat-expansion-panel-header>
                    <hr class="mt-0" />
                    <h5 class="h5-text">Pre-Requisites</h5>
                    <div class="row" style="align-items: center;">
                      <ul class="reason-container">
                        <li *ngFor="let pr of preRequisites">
                          <span class="reason-head">{{ pr['type'] }}:</span>{{ replaceLineBreaks(pr['message']) }}
                        </li>
                      </ul>
                    </div>
                    <ng-container *ngIf="importantNote == true && buttonLoading == false">
                      <hr class="mt-3" />
                      <h5 class="h5-text">IMPORTANT: <span class="important-note">Certain promotion eligibility criteria tied to game provider may not be captured. Please select your preferred game provider for more detailed information.</span></h5>
                    </ng-container>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </ng-container>
            <ng-template #noPreRequisites>
              <div *ngIf="message" class="eligible-message-container mb-4 mt-4" style="background: #EBF5F0;">
                <span class="success-msg-color"> <i _ngcontent-uyi-c32="" aria-hidden="true" class="fa fa-check"></i>{{ message }} </span>
              </div>
              <ng-container *ngIf="importantNote == true && buttonLoading == false">
                <hr class="mt-3" />
                <h5 class="h5-text">IMPORTANT: <span class="important-note">Certain promotion eligibility criteria tied to game provider may not be captured. Please select your preferred game provider for more detailed information.</span></h5>
              </ng-container>
            </ng-template>
          </ng-template>

          <!-- Eligible Details -->
          <mat-accordion *ngIf="eligibleCondition.length > 0">
            <mat-expansion-panel [expanded]="false" class="eligible-details" #eligibleDetails>
              <!-- Title -->
              <mat-expansion-panel-header>
                <mat-panel-title> Member Info </mat-panel-title>
              </mat-expansion-panel-header>
              <hr class="mt-0" />
              <!-- Content -->
              <mat-grid-list cols="4" rowHeight="7rem">
                <mat-grid-tile>
                  <!-- Username -->
                  <div id="username" data-desc="Username" data-section="eligibleDetails" data-subtab="Eligible Details" class="info alert-secondary" role="alert">
                    Username
                    <br />
                    <label class="label my-2">{{ eligibleDetail[0]['username'] }}</label>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile>
                  <!-- Member Group -->
                  <div id="member_group" data-desc="Member Group" data-section="eligibleDetails" data-subtab="Eligible Details" class="info alert-secondary" role="alert">
                    Member Group
                    <br />
                    <label class="label my-2">{{ eligibleDetail[0]['member_group'] }}</label>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile>
                  <!-- Last Deposit Amount-->
                  <div id="deposit_amount" data-desc="Deposit Amount" data-section="eligibleDetails" data-subtab="Eligible Details" class="info alert-secondary" role="alert">
                    Last Deposit Amount
                    <br />
                    <label class="label my-2">{{ eligibleDetail[0]['deposit_amount'] != '' || eligibleDetail[0]['deposit_amount'] != null ? (eligibleDetail[0]['deposit_amount'] | number : '1.2-2') : '-' }}</label>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile>
                  <!-- Last Deposited Bank -->
                  <div id="last_deposited_bank" data-desc="Last Deposited Bank" data-section="eligibleDetails" data-subtab="Eligible Details" class="info alert-secondary" role="alert">
                    Last Deposited Bank
                    <br />
                    <label class="label my-2">{{ eligibleDetail[0]['last_deposited_bank'] != '' ? eligibleDetail[0]['last_deposited_bank'] : '-' }}</label>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile>
                  <!-- KYC -->
                  <div id="kyc" data-desc="KYC" data-section="eligibleDetails" data-subtab="Eligible Details" class="info alert-secondary" role="alert">
                    KYC
                    <br />
                    <label class="label my-2">
                      <div>
                        <i class="fa fa-shield-alt" [matTooltip]="eligibleDetail[0]['kyc']['kyc_progression']" [ngClass]="{'basic-unverified': eligibleDetail[0]['kyc']['kyc_progression'] === 'Basic','advance-user': eligibleDetail[0]['kyc']['kyc_progression'] === 'Advanced', 'verified-pro': eligibleDetail[0]['kyc']['kyc_progression'] === 'Pro'}"></i>
                        <i class="fa fa-envelope ml-2" [matTooltip]="eligibleDetail[0]['kyc']['email_status'] === 1 ? ('Verified' | translate) : ('Unverified' | translate) + ' ' + ('Email' | translate)" [ngClass]="eligibleDetail[0]['kyc']['email_status'] === 0 ? 'basic-unverified' : 'verified-pro'"></i>
                        <i class="fa fa-mobile-alt ml-2" [matTooltip]="eligibleDetail[0]['kyc']['mobile_status'] === 1 ? ('Verified' | translate) : ('Unverified' | translate) + ' ' + ('Mobile Number' | translate)" [ngClass]="eligibleDetail[0]['kyc']['mobile_status'] === 0 ? 'basic-unverified' : 'verified-pro'"></i>
                      </div>
                    </label>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile>
                  <!-- Ongoing Promotion -->
                  <div id="ongoing_promotion" data-desc="Ongoing Promotion" data-section="eligibleDetails" data-subtab="Eligible Details" class="info alert-secondary" role="alert">
                    Ongoing Promotion
                    <br />
                    <label class="label my-2">{{ eligibleDetail[0]['ongoing_promotion'] != '' ? eligibleDetail[0]['ongoing_promotion'] : '-' }}</label>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile>
                  <!-- Deposit Status -->
                  <div id="deposit_status" data-desc="Deposit Status" data-section="eligibleDetails" data-subtab="Eligible Details" class="info alert-secondary" role="alert">
                    Deposit Status
                    <br />
                    <label class="label my-2">{{ eligibleDetail[0]['deposit_status'] != '' ? eligibleDetail[0]['deposit_status'] : '-' }}</label>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile>
                  <!-- Fingerprint -->
                  <div id="fingerprint" data-desc="Fingerprint" data-section="eligibleDetails" data-subtab="Eligible Details" class="info alert-secondary" role="alert">
                    Fingerprint
                    <br />
                    <label class="label my-2">{{ eligibleDetail[0]['fingerprint'] != '' ? replaceLineBreaks(eligibleDetail[0]['fingerprint']) : '-' }}</label>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <!-- <button class="btn btn-brand btn-icon-sm btn-search" [ngClass]="!form.valid || buttonLoading ? 'disabled' : ''" (click)="onCheckEligible()"><i [class]="buttonLoading ? 'fas fa-circle-notch fa-spin p-0' : 'fas fa-search'"></i></button> -->
          <kt-search-button [loading]="buttonLoading" [disabledButton]="!form.valid" (click)="onCheckEligible()"></kt-search-button>
        </div>
      </div>
    </form>
  </div>
  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)" [icon]="!!hasError ? 'info' : 'error'"></kt-swal-alert>
</ng-container>