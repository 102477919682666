<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create'}} KPI Settings</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="row mt-2">
          <section class="col-lg-12 card border-0">
            <div class="col-12 p-0 card rounded-0">
              <div class="card-header">
                Member Development Stage
              </div>
              <section class="col-12 p-4">
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label class="control-label">Development Stage<span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-8 form-group">
                    <select class="form-control" formControlName="development_stage" (change)="getKpiSettings()">
                      <option [value]="null" [disabled]="true">Please Select</option>
                      <ng-container *ngFor="let value of dropdown.developmentStage">
                        <option [value]="value.id">{{ value.name }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label class="control-label">Lead Stage<span class="text-danger">*</span></label>
                  </div>
                  <!-- Member Recovery Type (Normal Development Stage)-->
                  <div class="col-md-8 form-group" *ngIf="form.value.development_stage == 1">
                    <select class="form-control" formControlName="lead_stage" (change)="getKpiSettings()">
                      <option [value]="null" [disabled]="true">Please Select</option>
                      <ng-container *ngFor="let value of dropdown.normalLeadStage">
                        <option [value]="value.id">{{ value.name }}</option>
                      </ng-container>
                    </select>
                  </div>
                  <!-- Member Recovery Type (Vip Development Stage)-->
                  <div class="col-md-8 form-group" *ngIf="form.value.development_stage == 2">
                    <select class="form-control" formControlName="lead_stage" (change)="getKpiSettings()">
                      <option [value]="null" [disabled]="true">Please Select</option>
                      <ng-container *ngFor="let value of dropdown.vipLeadStage">
                        <option [value]="value.id">{{ value.name }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
                <div>
                  <span class="updated-by">Updated By: {{ updatedBy }} {{ updatedAt ? (updatedAt | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</span>
                </div>
              </section>
            </div>
          </section>
        </div>

        <div class="row mt-5" *ngIf="form.value.development_stage == 1 && form.value.lead_stage == 1">
          <section class="col-lg-12 card border-0">
            <div class="col-12 p-0 card rounded-0">
              <div class="card-header">
                Assignment Settings
              </div>
              <section class="col-12 p-4">
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label class="control-label">Minimum Inactive Days<span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input type="number" (wheel)="false" formControlName="min_inactive_days" class="col-12 form-control">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label class="control-label">Maximim Inactive Days<span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input type="number" (wheel)="false" formControlName="max_inactive_days" class="col-12 form-control">
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>

        <div class="row mt-5">
          <section class="col-lg-12 card border-0">
            <div class="col-12 p-0 card rounded-0">
              <div class="card-header">
                Settings
              </div>
              <section class="col-12 p-4">
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label class="control-label">Task Durations<span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input type="number" (wheel)="false" formControlName="task_durations" class="col-12 form-control">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label class="control-label">Average Deposit Days<span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input type="number" (wheel)="false" formControlName="average_deposit_days" class="col-12 form-control">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label class="control-label">Increase Deposit Count %<span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input type="number" (wheel)="false" formControlName="increase_deposit_count_percentage" class="col-12 form-control" placeholder="Set 0 to by pass the condition">
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label class="control-label">Increase Deposit Amount %<span class="text-danger">*</span></label>
                  </div>
                  <div class="col-md-8 form-group">
                    <input type="number" (wheel)="false" formControlName="increase_deposit_amount_percentage" class="col-12 form-control" placeholder="Set 0 to by pass the condition">
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid || !canEditKPISettings" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>