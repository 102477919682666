<!-- begin:: Content Head -->
<!-- <div class="kt-subheader kt-grid__item" [ngClass]="{'kt-container--clear': clear}" [hidden]="subheaderService.disabled$ | async" id="kt_subheader">
  <div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}"> -->
    <div class="kt-subheader__main">
      <h5 class="kt-subheader__title pageTitle">
        {{title}}
      </h5>
    </div>
  <!-- </div>
</div> -->
<!-- end:: Content Head -->
