import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MemberDataService } from '../../../services/member-data.service';
import { Subscription } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'kt-member-game-account',
  templateUrl: './member-game-account.component.html',
  styleUrls: ['./member-game-account.component.scss']
})
export class MemberGameAccountDialogComponent implements OnInit {

  form: FormGroup;
  dropdown = {
    gameProviders: []
  }
  refreshStatus: boolean;
  button_loading = false;
  messages$ = this.memberDataService.messages$;
  private messageSubscription = new Subscription()

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { member: any, existing_game_account: any },
    public dialogRef: MatDialogRef<MemberGameAccountDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private memberDataService: MemberDataService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.dropdownHttpService.gameProviders.pipe(
      map((res) => {
        return res.filter((item) => {
          return !this.data.existing_game_account.includes(item.code);
        })
      })
    ).subscribe(res => this.dropdown.gameProviders = res);
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave() {
    this.button_loading = true;
    this.memberDataService.createGameAccount(this.form.value).pipe(
        tap((res: any) => {
          this.memberDataService.messages$.next(res.message);
          this.button_loading = false;
        }),
        catchError((error) => {
          this.button_loading = false;
          this.form.setErrors(null);
          throw error;
        })
    ).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      member_account_id: new FormControl(this.data.member.id),
      game_provider_id: new FormControl(null, [Validators.required])
    });
  }

}
