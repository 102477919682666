<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ 'Import Member Rewards' }}</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="col-md-12">
            <div class="row col-12 form-group row">
              <label class="col-4 kt-form__label col-form-label">{{ 'Currency' }} <span class="text-danger">*</span></label>
              <div class="col-8 kt-form__control px-0">
                <select formControlName="currency_id" class="form-control" (change)="onChangeCurrency($event.target.value)">
                  <option [disabled]="true" value="all">{{ 'Please Select' }}</option>
                  <option [value]="value.id" *ngFor="let value of dropdown.currencies | async">
                      {{ value.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-12 form-group row">
              <label class="col-4 kt-form__label col-form-label">{{ 'Campaign' }}</label>
              <div class="col-8 kt-form__control px-0">            
                <select class="form-control" formControlName="campaign_id" (change)="onSelectedCampaignChanged($event)">
                  <option [value]="null">{{ 'Please Select' }}</option>
                  <ng-container *ngFor="let value of campaignDropdownList">
                      <option [value]="+value.id">{{ value.name }}</option>
                  </ng-container>
                </select>
              </div>
            </div>   

              <div class="col-12 form-group row" *ngIf="!showReadOnlyReward else readonlyRewardTemplate">
                <label class="col-4 kt-form__label col-form-label">{{ 'Rewards' }} <span class="text-danger">*</span></label>
                <div class="col-8 kt-form__control px-0">
                  <kt-dropdown-wo-lazyload [form]='form' [dropdownList]='filterRewardsDropdown' [dropdownSettings]='rewardsDropdownSettings' [formName]="'promotion_id'" [selectionAttributes]="'id'" [selectedItems]="selectedRewards" (selectedItemsChanged)="onSelectedItemChange($event)" [ngClass]="{'is-invalid': checkValidation && form.controls.promotion_id.errors }">
                  </kt-dropdown-wo-lazyload>
                </div>
              </div>
              <ng-template #readonlyRewardTemplate>
                <div class="col-12 form-group row">
                  <label class="col-4 kt-form__label col-form-label">{{ 'Rewards' }} </label>
                  <div class="col-8 kt-form__control px-0">
                    <select formControlName="promotion_id" class="form-control" disabled="true">
                      <ng-container *ngFor="let item of rewardsDropdown; let first = first">
                        <option [value]="+item?.id"  [selected]="first">
                            {{ item?.name }}
                        </option>
                      </ng-container>
                  </select>
                  </div>
                </div>
              </ng-template>
  
                   
    
            <div class="col-12 form-group row">
                <label class="col-4 kt-form__label col-form-label">{{ 'Reward Status' }} <span class="text-danger">*</span> </label>
                <select formControlName="status" class="col-8 form-control" [ngClass]="{'is-invalid': checkValidation && form.controls.status.errors }">
                  <option [value]="null" disabled selected>{{ 'Please Select' }}</option>
                  <ng-container *ngFor="let item of status | keyvalue" >
                    <option [value]="item.key" *ngIf="+item.key == 0 || +item.key == 4">{{ item.value }}</option>
                  </ng-container>
                </select>
            </div>
            
            <div class="col-12 row">
              <label class="col-4 "></label>
              <div class="col-8 pl-0"><i class="fa fa-file-csv"></i><a href="assets/ExampleImportRewards.csv" target="_blank" download="ExampleImportRewards.csv"> {{ 'Download Template' }}</a></div>
            </div>
  
            <div class="col-12 form-group row">
              <label class="col-4 kt-form__label col-form-label">{{ 'Members' }} <span class="text-danger">*</span> </label>
              <label class="col-2 btn btn-default btn-sm kt-form__control" [ngClass]="buttonLoading ? 'disabled' : ''">
                <ng-container>
                  <span>{{ 'Import File' }}</span>
                  <input type="file" hidden id="file" accept=".csv" (change)="onFileUpload($event)" [disabled]="buttonLoading" #myFile>
                </ng-container>
              </label>
              <label class="col-6 kt-form__label col-form-label" *ngIf="file">{{ file.name }}</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col spinner-wrapper" *ngIf="buttonLoading">
            <mat-spinner [diameter]="15" class="mr-2"></mat-spinner>
            <p>{{ 'Importing... Please Wait' }}</p>
          </div>
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()" ><i class="fas fa-ban"></i>{{ 'Close' }}</button>
          <kt-submit-button [buttonLoading]="buttonLoading" (confirmed)="onSave($event)"></kt-submit-button>
        </div>
      </div>
    </form>
  </div>
  
  <!--swal-->
  <div style="display:none;" #importMessages>
      <p>{{ messages$ | async }}</p><br>
      <div style="text-align: left;" class="mb-3" *ngIf="showTextarea">
        <label>{{ 'Members failed to assign rewards' }}: <button id="btn-copy" style="all:unset;"><i matTooltip="Copy to clipboard" class="fas fa-copy"></i></button></label><br>
        <textarea style="width: 100%;" rows="5" [value]="textareaList" #NotFound readonly></textarea>
      </div>
  </div>
  