import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit, OnDestroy, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { tap, catchError, map } from 'rxjs/operators';
import { ApiKeySetting } from '@core/models/api-key-setting.model';
import { ApiKeySettingDataService } from '../services/api-key-setting-data.service';
import * as moment from 'moment-timezone';
import { forkJoin, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-api-key-setting-edit',
  templateUrl: './api-key-setting-edit.component.html',
  styleUrls: ['./api-key-setting-edit.component.scss']
})
export class ApiKeySettingEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;

  form: FormGroup;
  messages$ = this.apiKeySettingDataService.messages$;
  refreshStatus: boolean;

  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  dateTimeFormat = 'yyyy-MM-dd HH:mm:';

  key = null;
  dateTimeStack = {
    expiry_date: null
  };
  buttonLoading = false;

  permissions = [
    {id: 1, read: 0, write: 0, name: "Member" },
    {id: 2, read: 0, write: 0, name: "Withdrawal" },
    {id: 3, read: 0, write: 0, name: "Promotion" },
    {id: 6, read: 0, write: 0, name: "Rewards" },
    {id: 4, read: 0, write: 0, name: "Messages" },
    {id: 5, read: 0, write: 0, name: "Deposit" },
  ];

  private datePickerSubscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  canCreateApiKey: boolean;
  canEditApiKey: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, apiKeySetting?: ApiKeySetting },
    public dialogRef: MatDialogRef<ApiKeySettingEditComponent>,
    private datePipe: DatePipe,
    private apiKeySettingDataService: ApiKeySettingDataService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit(): void {
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateApiKey = appPermissions.create_api_key;
      this.canEditApiKey = appPermissions.edit_api_key;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'expiry_date'),
    ]).subscribe();
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.onRefresh();
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const apiKeySetting = <ApiKeySetting>this.filterFormFields(this.form.value);

    if (this.data.mode === 'create') {
      this.apiKeySettingDataService.add(apiKeySetting).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else {
      apiKeySetting.id = this.data.apiKeySetting.id;
      this.apiKeySettingDataService.update(apiKeySetting).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
    this.refreshStatus = true;
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onClearDate() {
    this.form.patchValue({ expiry_date: null });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    const permissionss: any = [];
    Object.keys(formData).forEach(key => {
      fields[key] = formData[key];
    });

    fields['permissions'] = this.permissions;
    fields['expiry_date'] = moment(fields['expiry_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
    return fields;
  }

  generateKey(regenerate: boolean = true) {
    const keyLength = 128;
    const randomValue = new Uint8Array(keyLength/2);
    crypto.getRandomValues(randomValue);
    this.key = Array.from(randomValue, byte => byte.toString(16).padStart(2, '0')).join('');

    if (regenerate) {
      this.form.patchValue({
        key: this.key,
      });
      Swal.fire({
        title: 'Success',
        text: 'Successfully Generated a New API Key',
        icon: 'info',
        confirmButtonText: 'OK'
      });
    } else {
      return this.key;
    }
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, formKey.includes('start') ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      })
    );
  }

  private isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  private formInit() {
    let application = this.data.mode === 'edit' ? this.data.apiKeySetting.application : null;
    this.key = this.data.mode === 'edit' ? this.data.apiKeySetting.key : this.generateKey(false);
    let expiry_date = this.data.mode == 'edit' ? this.isValidDate(this.data.apiKeySetting.expiry_date) ? moment(new Date(new Date(this.data.apiKeySetting.expiry_date).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null : null;
    let status =  this.data.mode === 'edit' ? this.data.apiKeySetting.status : 1;

    if (this.data.mode === 'edit') {
      const permissionsFromEndpoint = this.data.apiKeySetting.permissions;

      Object.keys(permissionsFromEndpoint).forEach(permissionId => {
        const permission = this.permissions.find(p => p.id === parseInt(permissionId));
        if (permission) {
          permission.read = permissionsFromEndpoint[permissionId].read;
          permission.write = permissionsFromEndpoint[permissionId].write;
        }
      });
    }

    this.form = new FormGroup({
      application: new FormControl(application, [Validators.required]),
      key: new FormControl(this.key),
      expiry_date: new FormControl(expiry_date),
      status: new FormControl(status, [Validators.required])
    });
  }
}
