import { BankSettingsDialogComponent } from './bank-settings/dialogs/bank-settings-edit.component';
import { BankSettingsComponent } from './bank-settings/bank-settings.component';
import { FaqEditDialogComponent } from './faq-management/dialogs/faq-edit.component';
import { FaqManagementComponent } from './faq-management/faq-management.component';
import { BotStatusComponent } from './bot-status/bot-status.component';
import { BankReceiptsComponent } from './bank-receipts/bank-receipts.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MerchantBankComponent } from './merchant-banks/merchant-bank.component';
import { SettingsMaintainanceComponent } from './subcomponents/settings-maintainance/settings-maintainance.component';
import { ContactComponent } from './contacts/contact.component';
import { AnnouncementComponent } from './announcements/announcement.component';
import { AnnouncementEditDialogComponent } from './announcements/dialogs/announcement-edit/announcement-edit.component';
import { MerchantBankEditDialogComponent } from './merchant-banks/dialogs/merchant-bank-edit/merchant-bank-edit.component';
import { MerchantBankNewEditComponent } from './merchant-banks/dialogs/merchant-bank-new-edit/merchant-bank-new-edit.component';
import { BannerComponent } from './banners/banner.component';
import { BannerEditDialogComponent } from './banners/dialogs/banner-edit/banner-edit.component';
import { BankReceiptBrowserDialogComponent } from './bank-receipts/dialogs/bank-receipt-browser/bank-receipt-browser.component';
import { BankReceiptVoidDialogComponent } from './bank-receipts/dialogs/bank-receipt-void/bank-receipt-void.component';
import { BankReceiptRemarksDialogComponent } from './bank-receipts/dialogs/bank-receipt-remarks/bank-receipt-remarks.component';
import { BankTransactionFormDialogComponent } from './bank-transaction/dialogs/bank-transaction-form/bank-transaction-form.component';
import { BankTransactionComponent } from './bank-transaction/bank-transaction.component';
import { TransactionReconciliationComponent } from './transaction-reconciliation/transaction-reconciliation.component';
import { TermsConditionsEditDialogComponent } from './terms-conditions/dialogs/terms-conditions-edit.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { FaqSectionsComponent } from './faq-sections/faq-sections.component';
import { FaqSectionsEditDialogComponent } from './faq-sections/dialogs/faq-sections-edit.component';
import { LiveChatScriptComponent } from './live-chat-script/live-chat-script.component';
import { LiveChatScriptEditDialogComponent } from './live-chat-script/dialogs/live-chat-script-edit.component';
import { WhatsappNumberComponent } from './whatsapp-number/whatsapp-number.component';
import { WhatsappNumberEditDialogComponent } from './whatsapp-number/dialogs/whatsapp-number-edit/whatsapp-number-edit.component';
import { WhatsappBotComponent } from './whatsapp-bot/whatsapp-bot.component';
import { WhatsappBotEditDialogComponent } from './whatsapp-bot/dialogs/whatsapp-bot-edit/whatsapp-bot-edit.component';
import { WhatsappBotQRDialogComponent } from './whatsapp-bot/dialogs/whatsapp-bot-qr/whatsapp-bot-qr.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { RegistrationPageEditComponent } from './registration-page/dialogs/registration-page-edit.component';
import { SectionSettingsComponent } from './footer-settings/section-settings/section-settings.component';
import { WebDisplayComponent } from './footer-settings/web-display/web-display.component';
import { WebDisplayEditDialogComponent } from './footer-settings/web-display/dialogs/web-display-edit.component';
import { MobileDisplayComponent } from './footer-settings/mobile-display/mobile-display.component';
import { FooterSectionEditDialogComponent } from './footer-settings/section-settings/footer-section/dialogs/footer-section-edit.component';
import { IconLibraryEditDialogComponent } from './footer-settings/section-settings/icon-library/dialogs/icon-library-edit.component';
import { QuickLinkEditDialogComponent } from './footer-settings/section-settings/quick-link/dialogs/quick-link-edit.component';
import { SeoSettingsComponent } from './seo-settings/seo-settings.component';
import { SeoSettingsEditComponent } from './seo-settings/dialogs/seo-settings-edit/seo-settings-edit.component';
import { RouteSettingsComponent } from './seo-settings/dialogs/route-settings/route-settings.component';
import { MetaTagSettingsComponent } from './seo-settings/dialogs/meta-tag-settings/meta-tag-settings.component';
import { MetaTagSettingsDuplicateComponent } from './seo-settings/dialogs/meta-tag-settings-duplicate/meta-tag-settings-duplicate.component';
import { BankMaintenanceHourComponent } from './bank-settings/dialogs/bank-maintenance-hour/bank-maintenance-hour.component';
import { MarqueesComponent } from './announcements/marquees/marquees.component';
import { DialogComponent } from './announcements/dialog/dialog.component';
import { UnSaveChangesGuard } from '@core/guards/unsavechanges.guard';
import { AboutUsComponent } from './about-us/about-us.component';
import { ResponsibleGamingComponent } from './responsible-gaming/responsible-gaming.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

import { DialogPreviewComponent } from './announcements/dialog/dialogs/dialog-preview.component';
import { BankReceiptEditComponent } from './bank-receipts/dialogs/bank-receipt-edit/bank-receipt-edit.component';
import { ReferralContentComponent } from './referral-content/referral-content.component';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { MerchantBankWrapperComponent } from './merchant-banks/merchant-bank-wrapper/merchant-bank-wrapper.component';

const routes: Routes = [
  {
    path: 'announcements',
    component: AnnouncementComponent
  },
  {
    path: 'banners',
    component: BannerComponent,
    canDeactivate: [UnSaveChangesGuard]
  },
  {
    path: 'contacts',
    component: ContactComponent,
    canDeactivate: [UnSaveChangesGuard]
  },
  {
    path: 'terms-and-conditions',
    component: TermsConditionsComponent
  },
  {
    path: 'faq-management',
    component: FaqManagementComponent,
    canDeactivate: [UnSaveChangesGuard]
  },
  {
    path: 'faq-sections',
    component: FaqSectionsComponent,
  },
  {
    path: 'merchant-banks',
    component: MerchantBankWrapperComponent,
  },
  {
    path: 'bank-transactions',
    component: BankTransactionComponent
  },
  {
    path: 'bank-receipts',
    component: BankReceiptsComponent
  },
  {
    path: 'bot-status',
    component: BotStatusComponent
  },
  {
    path: 'transaction-reconciliation',
    component: TransactionReconciliationComponent
  },
  {
    path: 'maintainance',
    component: SettingsMaintainanceComponent
  },
  {
    path: 'bank-settings',
    component: BankSettingsComponent
  },
  {
    path: 'whatsapp-number',
    component: WhatsappNumberComponent
  },
  {
    path: 'whatsapp-bot',
    component: WhatsappBotComponent
  },
  {
    path: 'live-chat-script',
    component: LiveChatScriptComponent,
    canDeactivate: [UnSaveChangesGuard]
  },
  {
    path: 'registration-page',
    component: RegistrationPageComponent
  },
  {
    path: 'section-settings',
    component: SectionSettingsComponent
  },
  {
    path:'website-display',
    component: WebDisplayComponent
  },
  {
    path:'mobile-display',
    component: MobileDisplayComponent
  },
  {
    path: 'seo',
    component: SeoSettingsComponent
  },
  {
    path:'marquee',
    component: MarqueesComponent,
    canDeactivate: [UnSaveChangesGuard]
  },
  {
    path:'dialog',
    component: DialogComponent,
    canDeactivate: [UnSaveChangesGuard]
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    canDeactivate: [UnSaveChangesGuard]
  },
  {
    path: 'responsible-gaming',
    component: ResponsibleGamingComponent,
    canDeactivate: [UnSaveChangesGuard]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    canDeactivate: [UnSaveChangesGuard]
  },
  {
    path:'referral-content',
    component: ReferralContentComponent,
    canDeactivate: [UnSaveChangesGuard]
  },
  {
    path:'menu-bar',
    component: MenuBarComponent,
    canDeactivate: [UnSaveChangesGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  entryComponents: [
    AnnouncementEditDialogComponent,
    MerchantBankEditDialogComponent,
    MerchantBankNewEditComponent,
    BannerEditDialogComponent,
    BankReceiptVoidDialogComponent,
    BankReceiptBrowserDialogComponent,
    BankReceiptRemarksDialogComponent,
    BankTransactionFormDialogComponent,
    FaqEditDialogComponent,
    TermsConditionsEditDialogComponent,
    FaqSectionsEditDialogComponent,
    LiveChatScriptEditDialogComponent,
    BankSettingsDialogComponent,
    WhatsappNumberEditDialogComponent,
    WhatsappBotEditDialogComponent,
    WhatsappBotQRDialogComponent,
    RegistrationPageEditComponent,
    FooterSectionEditDialogComponent,
    WebDisplayEditDialogComponent,
    IconLibraryEditDialogComponent,
    QuickLinkEditDialogComponent,
    SeoSettingsEditComponent,
    RouteSettingsComponent,
    MetaTagSettingsComponent,
    MetaTagSettingsDuplicateComponent,
    BankMaintenanceHourComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    ResponsibleGamingComponent,
    DialogPreviewComponent,
    BankReceiptEditComponent,
  ]
})
export class SettingsRoutingModule { }
