export class MerchantBankTransactionConfig {
    id: number;
    merchant_bank_id: number;
    payment_gateway_id: number;
    type: number;
    purpose: number;
    status: number;
    balance: number;
    min_balance: number;
    min_balance_usdt: number;
    max_balance: number;
    max_balance_usdt: number;
    daily_max_deposit_count: number;
    daily_max_withdrawal_count: number;
    daily_max_deposit: number;
    daily_max_deposit_usdt: number;
    daily_max_withdrawal: number;
    daily_max_withdrawal_usdt: number;
    min_deposit_per_transaction: number;
    min_deposit_per_transaction_usdt: number;
    max_deposit_per_transaction: number;
    max_deposit_per_transaction_usdt: number;
    min_withdrawal_per_transaction: number;
    min_withdrawal_per_transaction_usdt: number;
    max_withdrawal_per_transaction: number;
    max_withdrawal_per_transaction_usdt: number;
    deposit_field_type: number;
    shortcut_amount: any;
    score: number;
    position: number;
    display_name: string;
    deposit_processing_fee_type: number;
    withdrawal_processing_fee_type: number;
    deposit_absorb_processing_fee_type: number;
    withdrawal_absorb_processing_fee_type: number;
    player_deposit_absorb_percentage: number;
    company_deposit_absorb_percentage: number;
    player_withdrawal_absorb_percentage: number;
    company_withdrawal_absorb_percentage: number;
    deposit_processing_fee: number;
    deposit_processing_fee_percentage: number;
    withdrawal_processing_fee: number;
    withdrawal_processing_fee_percentage: number;
    payment_gateway_name: string;
}