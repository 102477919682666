import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  OnDestroy,
  ViewRef
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import * as objectPath from 'object-path';
// Layout
import { LayoutConfigService, MenuAsideService, MenuOptions, OffcanvasOptions } from '@core/_base/layout';
import { HtmlClassService } from '../html-class.service';
import { Subscription } from 'rxjs';
import KTOffcanvas from 'assets/js/global/components/base/offcanvas';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { navigations } from '@core/store/auth/auth.selectors';
import { AppPermissionService } from '@core/services/app-permission.service';

declare const KTUtil: any;

@Component({
  selector: 'kt-aside-left',
  templateUrl: './aside-left.component.html',
  styleUrls: ['./aside-left.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsideLeftComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('asideMenuRef', {static: true}) asideMenu: ElementRef;
  version = localStorage.getItem('version');
  // navigations = JSON.parse(localStorage.getItem('navigations'))
  navigations: any;

  currentRouteUrl = '';
  insideTm: any;
  outsideTm: any;

  menuCanvasOptions: OffcanvasOptions = {
  baseClass: 'kt-aside',
  overlay: true,
  closeBy: 'kt_aside_close_btn',
  toggleBy: {
    target: 'kt_aside_toggler',
    // state: 'kt-header-mobile__toolbar-toggler--active'
  }
  };

  menuOptions: MenuOptions = {
    // vertical scroll
    scroll: null,

    submenu: {
      desktop: {
        default: 'dropdown',
      },
      tablet: 'accordion',
      mobile: 'accordion'
    },

    accordion: {
      expandAll: false
    }
  };

  // permissions
  canViewGraph: boolean;
  canViewStats: boolean;
  canViewGameProviderMaintenance: boolean;
  canEditGameProviderMaintenance: boolean;

  private routeSubscription = new Subscription();
  private subscriptions = new Subscription();

  ktOffCanvas: any;

  constructor(
    public htmlClassService: HtmlClassService,
    public menuAsideService: MenuAsideService,
    public layoutConfigService: LayoutConfigService,
    private router: Router,
    private render: Renderer2,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
    private appPermissionService: AppPermissionService,
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.cdr && !(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    });
  }

  ngOnInit() {
    this.ktOffCanvas = new KTOffcanvas('kt_aside', this.menuCanvasOptions);
    this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

    this.routeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
      this.cdr.markForCheck();
    });

    const config = this.layoutConfigService.getConfig();

    if (objectPath.get(config, 'aside.menu.dropdown')) {
      this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-dropdown', '1');
      this.render.setAttribute(
        this.asideMenu.nativeElement,
        'data-ktmenu-dropdown-timeout',
        objectPath.get(config, 'aside.menu.submenu.dropdown.hover-timeout')
      );
    }

    const navSub = this.store.pipe(select(navigations)).subscribe(navigations => {
      this.navigations = navigations;
      this.cdr.detectChanges();
    });

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewGraph = appPermissions.view_graph;
      this.canViewStats = appPermissions.view_statistic_summary;
      this.canViewGameProviderMaintenance = appPermissions.dashboard_view_game_provider_maintenance_hour;
      this.canEditGameProviderMaintenance = appPermissions.dashboard_create_game_provider_maintenance_hour;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(navSub);
    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  // check whether if at least one of the dashboard permissions is granted
  checkDashboardPermissions() {
    const userData = JSON.parse(localStorage.getItem('user_data'));

    if (
      userData?.authorizedRole === 'role' ||
      (userData?.authorizedRole === 'mixed' && !userData?.application_role_id)
    ) {
      return true;
    }

    return this.canViewGraph || this.canViewStats || this.canViewGameProviderMaintenance || this.canEditGameProviderMaintenance;
  }

  mouseEnter(e: Event) {

    if (document.body.classList.contains('kt-aside--fixed')) {
      if (this.outsideTm) {
      clearTimeout(this.outsideTm);
      this.outsideTm = null;
      }

      this.insideTm = setTimeout(() => {
      // if the left aside menu is minimized
      if (document.body.classList.contains('kt-aside--minimize') && KTUtil.isInResponsiveRange('desktop')) {
        // show the left aside menu
        this.render.removeClass(document.body, 'kt-aside--minimize');
        this.render.addClass(document.body, 'kt-aside--minimize-hover');
      }
      }, 50);
    }
  }

  mouseLeave(e: Event) {
    if (document.body.classList.contains('kt-aside--fixed')) {
      if (this.insideTm) {
      clearTimeout(this.insideTm);
      this.insideTm = null;
      }

      this.outsideTm = setTimeout(() => {
      // if the left aside menu is expand
      if (document.body.classList.contains('kt-aside--minimize-hover') && KTUtil.isInResponsiveRange('desktop')) {
        // hide back the left aside menu
        this.render.removeClass(document.body, 'kt-aside--minimize-hover');
        this.render.addClass(document.body, 'kt-aside--minimize');
      }
      }, 100);
    }
  }

  checkRouterLink(item) {
    const links = [];
    // Get all links of the section
    if (item.sub_menu && item.sub_menu.length > 0) {
      item.sub_menu.forEach(subItem => {
        if (subItem.sub_menu && subItem.sub_menu.length > 0) {
          subItem.sub_menu.forEach(subItem2 => {
            links.push('/' + subItem2.link);
          });
        }
        if (subItem.link !== undefined && subItem.link !== null) links.push('/' + subItem.link);
      });
    }
    if (item.link !== undefined && item.link !== null) links.push('/' + item.link);
    // Check if any link matches the current route
    if (links.length > 0) {
      for (let i = 0; i < links.length; i++) {
        if (links[i] == this.currentRouteUrl) return true;
      }
    }
    return false;
  }

  returnZero() {
    return 0
  }
}
