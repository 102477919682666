import { tap, map, concatMap, delay, filter, take, exhaust, takeWhile, share } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { SmsProvider } from '@core/models/sms-provider.model';

@Injectable()
export class ProvidersDataService extends DefaultDataService<SmsProvider>  {

  messages$ = new Subject<any[]>();
  pagination: Pagination;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('SmsProvider', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<SmsProvider[]>{
    return this.http.get<ApiResponse>(`/smsprovider${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows),
      share()
    );
  }

  getProviders(pageParam: string): Observable<SmsProvider[]>{
    return this.http.get<ApiResponse>(`/smsprovider/providers${pageParam}`).pipe(
      map(res => res.data.rows),
      share()
    );
  }

  getProviderBalanceBy(code: string):Observable<number> {
    return this.http.get<ApiResponse>(`/smsprovider/balance?code=${code}`).pipe(
      map(res => res.data)
    );
  }

  updateSmsProvider(smsProvider: any) {
    return this.http.put<ApiResponse>(`/smsprovider/${smsProvider.id}`, smsProvider).pipe(
      tap( res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      return this.pagination = res.data.paginations;
    }
  }

  getSettings() {
    return this.http.get<ApiResponse>(`/smsprovider/setting`).pipe(
      map(res => res.data.rows)
    );
  }

  getSettingsWithQuery(pageParam: string) {
    return this.http.get<ApiResponse>(`/smsprovider/setting${pageParam}`).pipe(
      map(res => res.data.rows)
    );
  }

  updateSettings(id: number, data: any) {
    return this.http.put<ApiResponse>(`/smsprovider/setting/update/${id}`, {...data}).pipe(
      tap(res => this.messages$.next(res.message)),
    )
  }

  getSMSProviderSettingWithQuery(code: string, pageParam: string) {
    return this.http.get<ApiResponse>(`/smsprovider/providersetting/${code}${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows),
      share()
    );
  }

  updateSmsProviderSetting(id: number, data: any) {
    return this.http.post<ApiResponse>(`/smsprovider/providersetting/update/${id}`, {...data}).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  getSMSProviderSettingById(id: number) {
    return this.http.get<ApiResponse>(`/smsprovider/providersetting/edit/${id}`).pipe(
      map(res => res.data.rows),
    );
  }

  getSMSEdgeRoute(code: string, transactional: any = 1) {
    return this.http.get<ApiResponse>(`/smsprovider/providersetting/route/edge?country_code=${code}&transactional=${transactional}`).pipe(
      map(res => res.data.rows),
    );
  }
}
