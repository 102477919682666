<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-content" class="modal-title">{{ data.gameProvider.game_provider_code + ' - ' + data.gameProvider.category_name + ' - ' + data.gameProvider.currency }}</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-md-6">
                <div class="col-12 mb-0 form-group row">
                  <label class="col-4 col-form-label">Games</label>
                  <input class="col-8 form-control" type="text" placeholder="Search keywords..." [disabled]="loading" (keyup)="onSearch($event)">
                </div>
              </div>
              <div class="col-md-6">
                <div class="col-12 mb-0 form-group row">
                  <label class="col-5 col-form-label">Selected Games</label>
                  <span class="col-3 m-auto text-right">{{ selectedCount }} / {{ dropdown.allGameList ? dropdown.allGameList.length : 0 }}</span>
                </div>
              </div>
              <div class="col-md-12 loading-icon-container">
                <tbody shared-table-handler [loading]="loading" [dataLength]="1" class="text-gray-600 fw-bold loading-icon">
                </tbody>
              </div>
              <div *ngIf="!loading">
                <div class="col-md-12 scroll-container" *ngIf="dropdown.gameList.length > 0; else noGameData">
                  <div class="card-body text-dark-50 font-size-lg pt-0 pb-0 card-body-border-top scrollable-y">
                    <div class="row">
                      <div class="col-2 pb-20 games" *ngFor="let game of dropdown.gameList" [ngClass]="game.selected ? 'selected-game' : ''">
                        <div class="games-container" (click)="selectGame(game)">
                          <div class="img-container">
                            <img draggable="false" class="img-fluid prevent-select" [src]="game.image_path2 ? game.image_path2 : game.image_path" />
                          </div>
                          <span class="game-name prevent-select">{{ game.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-template #noGameData>
                  <span class="empty-game">All games added to the category / no games available</span>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="checkbox-button">
            <label><input (click)="selectAllGame()" type="checkbox" formControlName="select_all"> Select All</label>
          </div>
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button [isDisabled]="loading || selectedCount == 0" (confirmed)="onSave()"></kt-submit-button>
        </div>
      </div>
    </form>
  </div>