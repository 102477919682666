<div ngbDropdown placement="bottom-right" *ngIf="user$ | async as _user" class="kt-header__topbar-item kt-header__topbar-item--user">
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
  <div class="kt-header__topbar-user" [ngClass]="{'kt-header__topbar-icon': icon}">
    <span class="kt-header__topbar-welcome kt-hidden-mobile" *ngIf="greeting">Hi,</span>
    <span class="kt-header__topbar-username kt-hidden-mobile text-align" *ngIf="greeting">{{ projectCode.toUpperCase() }}<br>{{_user.username}}</span>
    <i *ngIf="icon" class="flaticon2-user-outline-symbol"></i>
    <img *ngIf="avatar" alt="Pic" [attr.src]="photoDefault">
    <span *ngIf="badge" class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">{{_user.username|firstLetter}}</span>
  </div>
  </div>
  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
  <!--begin: Head -->
  <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x" style="background-image: url(./assets/media/misc/bg-1.jpg)">
    <div class="kt-user-card__avatar">
    <img alt="Pic" [attr.src]="photoDefault"/>
    <span [hidden]="true" class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
      {{_user.username|firstLetter}}
    </span>
    </div>
    <div class="kt-user-card__name">
    {{ projectCode.toUpperCase() }}<br>
    {{_user.username}}
    </div>
  </div>
  <!--end: Head -->

  <!--begin: Navigation -->
  <div class="kt-notification">

    <!-- <a ngbDropdownItem routerLink="/account/settings" class="kt-notification__item">
      <div class="kt-notification__item-icon">
        <i class="flaticon2-calendar-3 kt-font-success"></i>
      </div>
      <div class="kt-notification__item-details">
        <div class="kt-notification__item-title kt-font-bold">
          <a routerLink="/account/settings">My Profile</a>
        </div>
        <div class="kt-notification__item-time">Account settings and more</div>
      </div>
    </a> -->

    <a *ngIf="twoFaAccess" ngbDropdownItem class="kt-notification__item" (click)="onOpenTwoFA()">
      <div class="kt-notification__item-icon">
        <!-- <i class="flaticon2-calendar-3 kt-font-success"></i> -->
        <span class="kt-font-success">{{ '2FA' }}</span>
      </div>
      <div class="kt-notification__item-details">
        <div class="kt-notification__item-title kt-font-bold">
          <a>{{ 'Google Authenticator' }}</a>
        </div>
        <div class="kt-notification__item-time">{{ 'Generates Two-Factor Authentication' }}</div>
      </div>
    </a>

    <a ngbDropdownItem class="kt-notification__item" (click)="onChangePassword()">
      <div class="kt-notification__item-icon">
        <i class="flaticon2-calendar-3 kt-font-success"></i>
      </div>
      <div class="kt-notification__item-details">
        <div class="kt-notification__item-title kt-font-bold">
          <a>Change Password</a>
        </div>
        <div class="kt-notification__item-time">Account settings password</div>
      </div>
    </a>

    <a ngbDropdownItem class="kt-notification__item" (click)="onChangeTimezone()">
      <div class="kt-notification__item-icon">
        <i class="flaticon2-calendar-3 kt-font-success"></i>
      </div>
      <div class="kt-notification__item-details">
        <div class="kt-notification__item-title kt-font-bold">
          <a>Change Timezone</a>
        </div>
        <div class="kt-notification__item-time">Account settings timezone</div>
      </div>
    </a>

    <div *ngIf="canGetSetPanicMode" class="kt-notification__item panicMode">
      <div class="kt-notification__item-icon">
        <i class="flaticon2-exclamation kt-font-danger"></i>
      </div>
      <div class="kt-notification__item-details">
        <div class="kt-notification__item-title kt-font-bold">
          <p>Panic Mode</p>
        </div>
        <div class="kt-notification__item-time">
          <label class="switch">
            <input type="checkbox" [checked]="panicModeStatus" (change)="onPanicModeOn($event)">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>

    <div class="kt-notification__custom">
    <a (click)="onLogout()" class="btn btn-outline-brand btn-upper btn-sm btn-bold">Sign Out</a>
    </div>
  </div>
  <!--end: Navigation -->

  <!--begin: Navigation(alternative) -->
  <ul class="kt-nav kt-margin-b-10 kt-hidden">
    <li class="kt-nav__item">
    <a ngbDropdownItem routerLink="/account/settings" class="kt-nav__link">
      <span class="kt-nav__link-icon"><i class="flaticon2-calendar-3"></i></span>
      <span class="kt-nav__link-text">My Profile</span>
    </a>
    </li>
    <li class="kt-nav__item">
    <a ngbDropdownItem routerLink="profile" class="kt-nav__link">
      <span class="kt-nav__link-icon"><i class="flaticon2-browser-2"></i></span>
      <span class="kt-nav__link-text">My Tasks</span>
    </a>
    </li>
    <li class="kt-nav__item">
    <a ngbDropdownItem routerLink="profile" class="kt-nav__link">
      <span class="kt-nav__link-icon"><i class="flaticon2-mail"></i></span>
      <span class="kt-nav__link-text">Messages</span>
    </a>
    </li>
    <li class="kt-nav__item">
    <a ngbDropdownItem routerLink="profile" class="kt-nav__link">
      <span class="kt-nav__link-icon"><i class="flaticon2-gear"></i></span>
      <span class="kt-nav__link-text">Settings</span>
    </a>
    </li>
    <li class="kt-nav__item kt-nav__item--custom kt-margin-t-15">
    <a (click)="onLogout()" class="btn btn-outline-brand btn-upper btn-sm btn-bold">Sign Out</a>
    </li>
  </ul>
  <!--end: Navigation(alternative) -->
  </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>