<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <!-- Header -->
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode | titlecase }}</h5>
        <span class="modal-x-button" (click)="onCloseDialog()"><i class="fa fa-times" aria-hidden="true"></i></span>
      </div>
      <div class="modal-body">
        <!-- Merchant -->
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Merchant <span class="text-danger">*</span></label>
          <select formControlName="merchant" class="col-8 form-control">
            <option [value]="null" disabled hidden>Please Select</option>
            <option *ngFor="let value of dropdown.merchant | async" [value]="value.id">{{ value.name }}</option>
          </select>
        </div>
        <!-- Region -->
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Region <span class="text-danger">*</span></label>
          <select formControlName="country_code" class="col-8 form-control">
            <option [value]="null" disabled hidden>Please Select</option>
            <option *ngFor="let value of data.regions" [value]="value.country_code">{{ value.country }}</option>
          </select>
        </div>
        <!-- Domain -->
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Domain <span class="text-danger">*</span></label>
          <textarea rows="4" placeholder="New line for multiple domain" class="col-8 form-control" formControlName="domain"></textarea>
        </div>
        <!-- Google Tracking ID -->
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Google Tracking ID</label>
          <input type="text" class="col-8 form-control" formControlName="gtag" />
        </div>
        <!-- Status -->
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Status <span class="text-danger">*</span></label>
          <select class="col-8 form-control" formControlName="status">
            <option *ngFor="let value of dropdown.status; let i = index" [value]="i">{{ value }}</option>
          </select>
        </div>
        <!-- Remarks -->
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Remarks </label>
          <textarea rows="4" class="col-8 form-control" formControlName="remarks"></textarea>
        </div>
      </div>
      <!-- Action Button -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true)"></kt-swal-alert>
