import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Message } from '@core/models/message.model';

@Injectable()
export class MessageEntityService extends EntityCollectionServiceBase<Message> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Message', serviceElementsFactory);
    }
}

