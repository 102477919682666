<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <!-- Filter -->
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <!-- Filter Form -->
            <form class="row align-items-center" [formGroup]="form">
              <!-- Filter first row -->
              <div class="col-12 row mb-2 pr-0">
                <!-- Title -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Section:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="section" placeholder="Search" class="form-control">
                </div>
                <!-- Status -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="status">
                    <option value="all">All</option>
                    <ng-container *ngFor="let status of statuses; let i = index">
                      <option [value]="status.id">{{ status.name }}</option>
                    </ng-container>
                  </select>
                </div>
                <!-- Date/Time -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date/Time:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="input-group date">
                    <input type="text" class="form-control" placeholder="{{ 'Search' }}" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                    <span class="input-group-append">
                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Filter Action -->
      <div class="kt-section col-12 row mb-3 mt-2">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
      </div>
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="sort-enabled pointer" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'" >ID</th>
                  <th class="text-center">Section</th>
                  <th class="text-center">Filename</th>
                  <th class="text-center">Parameters</th>
                  <th class="text-center">Successful Import Count</th>
                  <th class="text-center">Total Import Count</th>
                  <th class="text-center">Status</th>
                  <th class="text-center sort-enabled pointer" (click)="onSortColumn('remark')" [ngClass]="sortingConfig.remark" [class.sort-selected]="sortingSelection.sort_by === 'remark'">Remarks</th>
                  <th class="text-center sort-enabled pointer" (click)="onSortColumn('created_by')" [ngClass]="sortingConfig.created_by" [class.sort-selected]="sortingSelection.sort_by === 'created_by'">Created By</th>
                  <th class="text-center pr-4">Actions</th>
                </tr>
              </thead>
              <tbody *ngIf="rows.length > 0">
                <tr *ngFor="let row of rows">
                  <td class="text-center">{{ row.id }}</td>
                  <td class="text-center">{{ row.function_name }}</td>
                  <td class="text-center">{{ row.file_name }}</td>
                  <td class="text-break w-25 parameters">{{ row.params }}</td>
                  <td class="text-center">{{ row.success_data }}</td>
                  <td class="text-center">{{ row.total_data }}</td>
                  <td class="text-center">
                    <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'report-kt-badge--' + row.status"> {{ row.status_name }}</span>
                  </td>
                  <td class="text-center">{{ row.remark ? row.remark : '-'}}</td>
                  <td class="text-center">{{ row.created_by }} <br> {{ row.created_at !== null ? (row.created_at | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }}</td>
                  <td class="text-center">
                    <button matTooltip="Download" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onDownload(row.link)"><i class="fa fa-download"></i></button>
                    <button *ngIf="row.output_link_content && row.output_link" matTooltip="Download" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onDownloadTxt(row.output_link_content,row.output_link )" ><i class="fa fa-file"></i></button>
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="rows.length" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)"> </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc"> Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total | number : "1.0" }} records </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>