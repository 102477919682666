<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Generate Referral Commission</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <form class="row align-items-center mb-2" [formGroup]="form">
                    <div class="col-md-6 form-group">
                        <label>Commission Setting <span class="text-danger">*</span><i *ngIf="dropdownLoading" class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                        <select class="form-control" formControlName="referral_commission_setting_id" [ngClass]="{'is-invalid': checkValidation && form.controls.currency_id.errors }" (change)="onReferralCommissionSettingChange()" [attr.disabled]="dropdownLoading ? 'disabled' : null">
                            <option value="null" [disabled]="true">Please Select</option>
                            <ng-container *ngFor="let value of referralCommissionSettings">
                                <option [value]="value.id">{{ value.name }} ({{ value.type_name }})</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-md-6 form-group">
                        <label>Currencies <span class="text-danger">*</span><i *ngIf="dropdownLoading" class="fas fa-circle-notch fa-spin p-0 mr-2"></i></label>
                        <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'currenciesDropdownList'
                            [dropdownSettings] = 'currencyDropdownSettings'
                            [formName] = "'currency_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] = 'currenciesSelectedItems'
                            (selectedItemsChanged)="onSelectCurrency($event)">
                        </kt-dropdown-wo-lazyload>
                    </div>
                    <div class="col-md-6 form-group">
                        <label>Commission Generate Date <span class="text-danger">*</span></label>
                        <div class="input-group date">
                            <input type="text" class="form-control" (change)="onDateRange($event)"
                                formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true"
                                [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true"
                                [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [opens]="'right'"
                                [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd [ngClass]="{'is-invalid': checkValidation && form.controls.start_datetime.errors }" />
                            <span class="input-group-append">
                                <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6 form-group">
                        <label>Members </label>
                        <kt-member-dropdown class="dropdown-maxheight"
                            [form]="form"
                            [dropdownSettings]='memberAccountDropdownSettings'
                            [formName]="'member_account_id'"
                            [isFilterByCurrency]="true"
                            [currencyId]="currencyId"
                            [isFilterByMembergroupId]="true"
                            [membergroupId]="membergroupId"
                            [isReferral]="true"
                            [selectionAttributes]="'referrer_id'">
                        </kt-member-dropdown>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [buttonLoading]="buttonLoading" (confirmed)="onGenerate()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
