import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, map, exhaustMap } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { Pagination } from '@core/models/pagination.model';
import { SubheaderService } from '@core/_base/layout/services/subheader.service';
import { GameTagsEntityService } from './services/game-tags-entity.service';
import { GameTagsDataService } from './services/game-tags-data.service';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { environment } from '@env/environment';
import { GameTags } from '@core/models/game-tags.model';
import { GameTagsEditDialogComponent } from './dialogs/game-tags-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-game-tags',
  templateUrl: './game-tags.component.html',
  styleUrls: ['./game-tags.component.scss']
})
export class GameTagsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  pagination: Pagination;
  pageSize = 15;
  page = 1;
  maxSize = 5;
  params = '';
  messages$ = this.gameTagsDataService.messages$;
  gameTags$: any;
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
    statuses:  this.dropdownHttpService.statuses,
  };

  recentParams = JSON.parse(sessionStorage.getItem('game_tags_params'));

  dataLength = 0;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  newurl: string;

  sortingConfig = {
    'name': 'desc',
    'position': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
  };

  sortingSelection = {
    'sort_by': 'created_at',
    'sort_order': 'asc',
  };

  // permissions
  canCreateGameTag: boolean;
  canEditGameTag: boolean;
  canViewGameTagDetails: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private gameTagsDataService: GameTagsDataService,
    private gameTagsEntityService: GameTagsEntityService,
    private subheaderService: SubheaderService,
    private dropdownHttpService: DropdownHttpService,
    private router: Router,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
  ) {}

  ngOnInit() {
    this.formInit();
    this.reload();
    this.pagination = this.gameTagsDataService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateGameTag = appPermissions.create_game_tag;
      this.canEditGameTag = appPermissions.edit_game_tag;
      this.canViewGameTagDetails = appPermissions.view_game_tag_details;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
        if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
          if (key == property) {
            this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
          } else {
            this.sortingConfig[key] = 'desc';
          }
        }
      }
  
      // User selection
      this.sortingSelection.sort_by = property;
  
      if (this.sortingSelection.sort_by === property) {
        // Same column
        this.sortingSelection.sort_order = this.sortingConfig[property];
      } else {
        // Switch to other column
        this.sortingConfig[property] = 'asc';
        this.sortingSelection.sort_order = 'asc';
      }
      // Load Data
      this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    sessionStorage.setItem('recent_page', null);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.gameTags$ = this.gameTagsDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.dataLength = res.length;
        this.loading = false;
        this.pagination = this.gameTagsDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      status: 'all',
      name: null,
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.gameTags$ = this.gameTagsDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.dataLength = res.length;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.page = 1;
            this.pagination = this.gameTagsDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (key == 'name') {
        formData[key] !== '' && formData[key] !== null ? fields[key] = formData[key] : key;
      }
      else {
        formData[key] !== '' && formData[key] !== null && formData[key] !== 'all'? fields[key] = formData[key] : key;
      }
    });
    return fields;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private formInit() {
    this.form = new FormGroup({
      status: new FormControl('all'),
      name: new FormControl(null),
    });
  }

  onOpenDialog(type: string, gameTagID?: number) {
    if (gameTagID) {
      const gameTag = this.gameTagsDataService.getGameTag(gameTagID);
      this.subscription = gameTag.pipe(
        tap((res) => {
          switch (type) {
            case 'edit':
              this.openDialogBy(GameTagsEditDialogComponent, { gameTag: res, mode: 'edit' });
              break;
          }
        })
      )
        .subscribe();
    } else {
      this.openDialogBy(GameTagsEditDialogComponent, { mode: 'create' });
    }
  }

  private openDialogBy(componentRef: any, data?: { gameTag?: any; mode?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        gameTag: data.gameTag,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      //this.disabledEdit$.next(false);
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private reload() {
    this.onSubmit(false);
  }

  onSetLink(id) {
    return `/game/assign-games/${id}`;
  }

  onSetSession(id) {
    sessionStorage.setItem('assign_games', id);
    this.newurl = window.location.pathname + `/?assigngame=${id}`;
    window.history.pushState({ path: this.newurl }, '', this.newurl);
  }
}
