import { GameProviderReport } from '@core/models/game-provider-report.model';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Pagination } from '@core/models/pagination.model';

@Injectable()
export class GameProviderReportDataService extends DefaultDataService<GameProviderReport> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  date = this.transactionHttpService.getToday();
  from = this.date.from;
  to = this.date.to;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator,
    private transactionHttpService: TransactionHttpService
  ) {
    super('GameProviderReport', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<GameProviderReport[]> {
    return this.http.get<ApiResponse>(`/report/gameprovider?currency_id=1&game_provider_code=KISS&start_date_time=${this.from}&end_date_time=${this.to}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<GameProviderReport[]>{
    return this.http.get<ApiResponse>(`/report/gameprovider${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  private paginationInit(res: any) {
    if(res) {
      return this.pagination = res.data.paginations;
    }
  }

  exportReport(pageParam: string) {
    return this.http.get<ApiResponse>(`/report/exportgameprovider${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }
}
