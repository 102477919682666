import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Referral } from '@core/models/referral.model';

@Injectable()
export class ReferralEntityService extends EntityCollectionServiceBase<Referral> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Referral', serviceElementsFactory);
    }
}

