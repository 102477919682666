import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'merchant-bank-wrapper',
  templateUrl: './merchant-bank-wrapper.component.html',
  styleUrls: ['./merchant-bank-wrapper.component.scss'],
})
export class MerchantBankWrapperComponent implements OnInit {

  // permissions
  canViewBankingMerchantAccounts: boolean;
  canViewCryptoMerchantAccounts: boolean;

  private subscription: Subscription[] = [];


  constructor(
    private eventEmitter: EventEmitterService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewBankingMerchantAccounts = appPermissions.view_bank_payment_gateway_list;
      this.canViewCryptoMerchantAccounts = appPermissions.view_crypto_merchant_account_list;

      this.cdr.detectChanges();
    });

    this.subscription.push(apSub);
  }

  ngOnDestory() {
    this.subscription.map(sub => sub.unsubscribe);
  }
}