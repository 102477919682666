<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="kt-form w-webkit-fill-available">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Game Provider Blacklist</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div>
            <div class="apply-currency" *ngIf="!dataLoading && formReady">
              <span class="cursor-curr" (click)="onCurrencyDialog()">Apply to other currencies <i class="fas fa-caret-down"></i></span>
              <div class="currency-dropdown-container" *ngIf="showCurrencyDialog">
                <div class="currency-all-checkbox">
                  <input type="checkbox" (change)="toggleAll($event)" [checked]="checkAllCurrencyChecked()"> All Currencies
                </div>
                <div *ngFor="let currency of getCurrencyDialogList()" class="currency-other-checkbox">
                  <input type="checkbox" [checked]="selectedAppliedCurrencies.includes(currency.id)" (change)="toggleCurrency(currency.id)"> {{ currency.name }}
                </div>
                <div class="apply-currency-button-container">
                  <button class="btn btn-brand btn-icon-sm"
                  [disabled]="selectedAppliedCurrencies.length == 0"
                  (click)="applyToOtherCurrencies()"
                  *ngIf="beforeApplied"
                  >
                    Apply
                  </button>
                  <button class="btn btn-success btn-icon-sm"
                  *ngIf="afterApplied" disabled
                  >
                    Applied 
                    <i class="fas fa-check"></i>
                  </button>
                  <i class="fas fa-exclamation-circle"
                    matTooltip="Further changes made to the blacklist will not be automatically applied to other currencies. Please repeat this step if you wish to apply the changes to them."
                    matTooltipClass="custom-tooltip"
                    matTooltipHideDelay="0"
                  >
                  </i>
                </div>
              </div>
            </div>
            <mat-tab-group animationDuration="0ms" class="col-12 form-group" (selectedTabChange)="setCurrency($event)" [selectedIndex]="selectedIndex" #tabGroup>
              <mat-tab *ngFor="let currency of dropdown.currencies; let i = index">
                <ng-template mat-tab-label>
                  {{currency.name}}
                </ng-template>
                <div class="row col-12 pr-0 pl-0 ml-0">
                  <div class="col-md-2 pl-0 pr-20 border-right">
                    <div class="col-12 pl-0 pr-0 mb-3 input-group">
                      <div class="col-md-10 pl-0 pr-0 form-outline">
                        <input
                          type="search"
                          id="form1"
                          class="form-control search-input"
                          placeholder="Search provider..."
                          (keyup)="onSearch($event)"
                          (search)="resetKeyword($event)"
                        />
                      </div>
                      <button
                        type="button"
                        class="col-md-2 btn btn-primary search-icon mr-0"
                        (click)="onSearch()"
                      >
                        <i class="fas fa-search"></i>
                      </button>
                      <div
                        class="col-12 pt-22 form-control border-none pl-16"
                        *ngIf="providersByCurrency[selectedIndex].length == 0"
                      >
                        No provider found
                      </div>
                    </div>
                    <div class="col-12 pl-0 pr-0 list-group">
                      <div class="modal-content col-md-12 border-none" id="list-example">
                        <div class="pl-0 pr-0 form-outline" *ngFor="let provider of providersByCurrency[selectedIndex]">
                          <div [ngClass]="selectedGameProvider.length > 0 && selectedGameProvider[selectedIndex] && selectedGameProvider[selectedIndex].id == provider.id
                            ? 'selected-game-div'
                            : ''"
                          >
                            <span class="list-group-item list-group-item-action border-none"
                              (click)="setProvider(provider.id)"
                              [ngClass]="selectedGameProvider.length > 0 && selectedGameProvider[selectedIndex] && selectedGameProvider[selectedIndex].id == provider.id
                                ? 'selected-game-provider'
                                : ''"
                            >
                              {{ provider.code + ' - ' + provider.name }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-10 pr-0">
                    <div class="col-12">
                      Note: Adding (tick) a category to the blacklist will also include all its sub categories in the blacklist.
                    </div>
                    <div class="col-12 pr-0">
                      <div *ngIf="dataLoading && !formReady" class="spinner-blacklist-container">
                        <div class="spinner-blacklist"></div>
                      </div>
                      <form *ngIf="!dataLoading && formReady" class="kt-form w-webkit-fill-available" [formGroup]="form">
                        <div formArrayName="categories">
                          <div *ngFor="let group of filteredForm; let i = index" [formGroupName]="i" class="category-wrapper">
                            <div class="category">
                              <div class="category-header" (click)="toggleDropdown(group.value.game_provider_id, group.value.category_code)">
                                <label class="category-main-text">
                                  <input
                                    type="checkbox"
                                    [checked]="checkMainCategoryChecked(group.value.game_provider_id, group.value.category_code)"
                                    (change)="changeMainCategoryStatus($event, group.value.game_provider_id, group.value.category_code)"
                                    (click)="$event.stopPropagation();"
                                  >
                                  {{ group.value.category_name }}
                                </label>
                                <div class="expand-icon">
                                  <i class="fa fa-chevron-down" *ngIf="!group.value.expanded"></i>
                                  <i class="fa fa-chevron-up" *ngIf="group.value.expanded"></i>
                                </div>
                              </div>
                              <ng-container *ngIf="group.value.expanded">
                                <div  formArrayName="sub_categories" class="sub-categories" [@expandCollapse]="group.value.expanded ? 'expanded' : 'collapsed'">
                                  <div *ngFor="let subCategory of group.get('sub_categories').controls; let j = index" class="sub-category">
                                    <label>
                                      <!-- [disabled]="checkMainCategoryChecked(group.value.game_provider_id, group.value.category_code)" -->
                                      <input
                                        type="checkbox"
                                        [checked]="subCategory.value.status == 1"
                                        (change)="changeSubCategoryStatus($event, group.value.game_provider_id, group.value.category_code, subCategory.value.name)"
                                      >
                                        {{ subCategory.value.name }}
                                    </label>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Cancel</button>
          <kt-submit-button *ngIf="canEditGameProviderBlacklist" [isDisabled]="false" [buttonLoading]="false" (confirmed)="checkFormValid()"></kt-submit-button>
        </div>
      </div>
    </div>
  </div>
  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>