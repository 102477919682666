import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MemberLabel } from '@core/models/member-label.model';
import { Member } from '@core/models/member.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MemberLabelHttpService } from '@core/services/member-label-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MemberLabelEditComponent } from './member-label-edit/member-label-edit.component';
import { Status } from '@core/enums/status.enum';
import Swal from 'sweetalert2';
declare const $: any;

@Component({
  selector: 'kt-member-label',
  templateUrl: './member-label.component.html',
  styleUrls: ['./member-label.component.scss']
})
export class MemberLabelComponent implements OnInit {

  form: FormGroup;
  checkboxForm: FormGroup;
  dropdown = {
    label: this.dropdownHttpService.memberLabels,
    statuses: this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage
  }
  labels$: Observable<MemberLabel[]>;
  status = Status;
  params = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;
  messages$ = this.memberLabelHttpService.messages$;
  isCheck = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { member: Member },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MemberLabelComponent>,
    private memberLabelHttpService: MemberLabelHttpService,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.onSubmit();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        return this.labels$ = this.memberLabelHttpService.getWithQuery(`?${parameters}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onOpenDialog(mode: string, id?: number, defaultRemark?: any) {
    switch (mode) {
      case 'create':
        this.openDialogBy(MemberLabelEditComponent, { mode: mode, member: this.data.member });
        break;
      case 'edit':
        this.memberLabelHttpService.getById(id).subscribe((res: any) => {
          this.openDialogBy(MemberLabelEditComponent, { mode: mode, member: this.data.member, label: res, defaultRemark: defaultRemark });
        });
        break;
    }
  }

  onSelectDefault(event: any, label: any, remarksId: number, selectedIndex: number) {
    const input = (event.target as HTMLInputElement);
    const checkboxes = document.querySelectorAll('[id^="checkbox-' + label.id + '"]') as NodeListOf<HTMLInputElement>;
    if(checkboxes.length > 1) {
      for(let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
      checkboxes[selectedIndex].checked = true;
    }
    if (input.checked) {
      const text = 'This action will change the default remark to the selected remark.';
      Swal.fire({
        title: 'System Alert',
        html: '<div class="text-center">Do you want to save the changes? <br> <div class="my-3 text-red">' + text + '<div></div>',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Yes',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          const data = {
            remark_id: remarksId,
          }
          this.memberLabelHttpService.updateDefaultRemark(label.id, data).pipe(
            tap((res: any) => {
              this.messages$.next([...res.message]);
              this.onSubmit(false);
            }),
            catchError((error) => {
              checkboxes[selectedIndex].checked = false;
              throw error;
            })
          ).subscribe();
        } else {
          // unchecked checkbox & reset to default value if click on 'Cancel'
          checkboxes[selectedIndex].checked = false;
          label.remarks.forEach((item, index) => {
            checkboxes[index].checked = item.default == 1 ? true : false
          });
        }
      });
    }
  }

  private openDialogBy(componentRef: any, data?: { mode: any, member: any, label?: any, defaultRemark?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onSubmit(false);
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      member_account_id: new FormControl(this.data.member.id),
      label: new FormControl('all'),
      status: new FormControl('all')
    });
  }

}
