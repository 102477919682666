import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Member } from '@core/models/member.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { tap, catchError, finalize } from 'rxjs/operators';
import { MemberHttpService } from '@core/services/member-http.service';
import { TranslateService } from '@ngx-translate/core';
import { PromotionCodeEntityService } from '../../../promotion-codes/services/promotion-code-entity.service';
import { PromotionCodeDataService } from '../../../promotion-codes/services/promotion-code-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
@Component({
  templateUrl: './member-promotion-eligible.component.html',
  styleUrls: ['./member-promotion-eligible.component.scss']
})
export class MemberPromotionEligibleDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = new Subject<any[]>();
  hasError: boolean;
  subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  showDetails = false;

  promotionCodeDropdownSettings = {
    singleSelection: true,
    text: this.translateService.instant('Please Select'),
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 200, //'auto',
    primaryKey: 'id',
    labelKey: 'name',
    noDataLabel: '',
    showCheckbox: false
  };

  promotionCodeDropdownList = [];
  promotionCodeSelectedItems = [];
  promotionSpinnerLoading = false;

  gameProviderDropdownSettings = {
    singleSelection: true,
    text: this.translateService.instant('Please Select'),
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 200, //'auto',
    primaryKey: 'code',
    labelKey: 'name',
    noDataLabel: '',
    showCheckbox: false
  };

  gameProviderDropdownList = [];
  gameProviderSelectedItems = [];
  gameProviderSpinnerLoading = false;

  message = null;
  eligibleCondition = [];
  eligibleDetail = [];
  preRequisites = [];
  importantNote = false;

  constructor(
    public dialogRef: MatDialogRef<MemberPromotionEligibleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { member: Member},
    private translateService: TranslateService,
    private memberService: MemberHttpService,
    private promotionCodeEntityService: PromotionCodeEntityService,
    private promotionCodeDataService: PromotionCodeDataService,
    private cdr: ChangeDetectorRef,
    public sanitizer: DomSanitizer,    
    private dropdownHttpService: DropdownHttpService,

  ) { }

  ngOnInit() {
    this.formInit();
    this.onGetPromotions();
    this.onGetGameProviders();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      promotion_id: new FormControl(null, [Validators.required]),
      game_provider_code: new FormControl(null)
    });

    this.form.get('name').valueChanges.subscribe(
      res =>
        this.onGetRewardPromotion(res),
    );

    this.form.get('promotion_id').valueChanges.subscribe(res =>
      this.importantNote = false,
    );

    this.form.get('game_provider_code').valueChanges.subscribe((value) => {
      this.importantNote = false,
      this.message = null,
      this.eligibleCondition = [],
      this.eligibleDetail = [],
      this.preRequisites = [],
      this.cdr.detectChanges()
    });
  }

  onGetPromotions() {
    this.promotionSpinnerLoading = true;
    this.form.patchValue({ promotion_id: null });

    this.promotionCodeEntityService.getWithQuery(`?paginate=0`).pipe(
      finalize(() => this.promotionSpinnerLoading = false),
      tap(res => {
        this.promotionCodeDropdownList = [];
        Object.keys(res).map(key => {
          this.promotionCodeDropdownList.push(res[key]);
        });
        
        this.promotionCodeDropdownList = this.promotionCodeDropdownList.map((item) => ({
          id: item.id,
          name: item.code + ' - ' + item.name
        }))
      })
    ).subscribe();
  }

  onGetRewardPromotion(promotionName: string) {
    this.message = null;
    const promotionReward = this.promotionCodeDropdownList.find(l => l.name === promotionName);
    if (promotionReward) {
      if (promotionReward.id !== this.form.value.promotion_id) {
        this.form.patchValue({
          promotion_id: promotionReward.id,
        });

        this.message = null;
        this.eligibleCondition = [];
        this.eligibleDetail = [];
        this.preRequisites = [];
        this.cdr.detectChanges();
      }
    } else {
      this.form.patchValue({
        promotion_id: null,
      });

      this.message = null;
      this.eligibleCondition = [];
      this.eligibleDetail = [];
      this.preRequisites = [];
      this.cdr.detectChanges();
    }
  }

  onCheckEligible() {
    this.buttonLoading = true;

    var game_provider_code = this.form.value.game_provider_code != null ? this.form.value.game_provider_code : '';
    this.form.value.game_provider_code == null ? this.importantNote = true : this.importantNote = false;

    this.promotionCodeDataService.checkEligibility(this.form.value.promotion_id, this.data.member.id, 0, game_provider_code).subscribe(res => {
      this.message = res.message;
      this.eligibleCondition = res.data.rows['types'];
      this.eligibleDetail = res.data.rows['details'];
      this.preRequisites = res.data.rows['pre_requisites'];
      this.buttonLoading = false;
      this.cdr.detectChanges();
    });
  }

  replaceLineBreaks(data: string) {
    return data.replace('<br>', '\n');
  }

  expandDetails() {
    this.showDetails = this.showDetails == false ? true : false;
  }

  onGetGameProviders() {
    this.gameProviderSpinnerLoading = true;
    this.form.patchValue({ game_provider_code: null });

    this.dropdownHttpService.gameProviders.pipe(
      finalize(() => this.gameProviderSpinnerLoading = false),
      tap(res => {
        this.gameProviderDropdownList = [];
        Object.keys(res).map(key => {
          this.gameProviderDropdownList.push(res[key]);
        });
      })
    ).subscribe();
  }
}
