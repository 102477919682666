import { AuthHttpService } from '@core/services/auth-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { AllMemberReportSummary } from '@core/models/all-member-report-summary.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportDetailsComponent } from './dialogs/report-details/report-details.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, Observable, of, throwError } from 'rxjs';
import { map, tap, exhaustMap, catchError } from 'rxjs/operators';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Pagination } from '@core/models/pagination.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AllMemberReportEntityService } from './services/all-member-report-entity.service';
import { AllMemberReport } from '@core/models/allmember-report.model';
import { AllMemberReportDataService } from './services/all-member-report-data.service';
import * as moment from 'moment-timezone';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { DateTime } from 'luxon';

@Component({
  templateUrl: './all-member-reports.component.html',
  styleUrls: ['./all-member-reports.component.scss']
})
export class AllMemberReportsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  paginationTotal = 1;
  from = this.transactionHttpService.getYesterday().from;
  to = this.transactionHttpService.getToday().to;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  timezoneDropdownSettings = {
    singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown clear-all-disabled',
      primaryKey: 'offset',
      labelKey: 'offset',
      noDataLabel: '',
      showCheckbox: false
  };
  params = ``;
  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones')),
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage
  };

  memberReport$: Observable<AllMemberReport[]>;

  pagination: Pagination;
  summary$: AllMemberReportSummary;
  messages$ = this.memberReportDataService.messages$;
  ranges: any;
  access$ = this.authHttpService.getUserAccess(5, 'All Member Report');
  private datePickerSubscription = new Subscription();
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  button_loading = false;
  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;

  // permissions
  canExportAllMemberReport: boolean;

  lastBetLogSyncAt = null;
  
  constructor(
    public dialog: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private memberReportDataService: AllMemberReportDataService,
    private memberReportEntityService: AllMemberReportEntityService,
    private loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.ranges = this.transactionHttpService.ranges;
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.selectedTimezone = this.timezone;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canExportAllMemberReport = appPermissions.export_all_member_report;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.summary$ = undefined;
    this.onSearch = false;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.memberReport$ = this.memberReportEntityService.getWithQuery(`&page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.onSearch = true;
        this.pagination = this.memberReportDataService.pagination;
        this.summary$ = this.memberReportDataService.summary;
        this.loadingBar.complete();
      }),
      catchError((err) => {
        this.loadingBar.complete();
        return throwError(err);
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    let default_currency_id: any = null;
    if (this.dropdown.currencies.length > 0) {
      default_currency_id = Object.values(this.dropdown.currencies)[0]['id'];
    }

    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.form.patchValue({
      currency_id: default_currency_id,
      start_date_time: this.from,
      end_date_time: this.to,
      timezone: this.timezone,
      defaultDate: {
        startDate: this.from,
        endDate: this.to
      }
    });
    this.timezoneSelectedItems = [this.dropdown.timezones.find(x => x.timezone == this.timezone)];
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.summary$ = undefined;
    this.onSearch = false;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        delete (data['timezone']);

        if (data['currency_id'] === undefined || data['currency_id'] === null || data['currency_id'] === 0) {
          if (this.dropdown.currencies.length > 0) {
            data['currency_id'] = Object.values(this.dropdown.currencies)[0]['id'];
            this.form.patchValue({ currency_id: data['currency_id'] });
          }
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.memberReport$ = this.memberReportEntityService.getWithQuery(`&${this.params}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.page = 1;
            this.summary$ = this.memberReportDataService.summary;
            this.pagination = this.memberReportDataService.pagination;
            this.loadingBar.complete();
            this.onSearch = true;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.getLastBetLogSyncAt();
          }),
          catchError((err) => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.loadingBar.complete();
            this.getLastBetLogSyncAt();
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  onExport() {
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        var filename = 'AllMemberReport_' + moment(this.form.get('start_date_time').value).format('YYYYMMDD') + '-' + moment(this.form.get('end_date_time').value).format('YYYYMMDD') + '.xlsx';
        return this.memberReportDataService.exportReport(`?${parameters}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.button_loading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.button_loading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  sanitizeNumericField(previousValue: any) {
    const value = String(previousValue).replace(/[,]+/, '');
    return value;
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onOpenDialog(memberReportRow: any) {
    this.openDialogBy(ReportDetailsComponent, { memberReport: memberReportRow, form: this.form.value });
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private openDialogBy(componentRef: any, data?: { memberReport?: any, form?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800',
      data: {
        memberReport: data.memberReport,
        form: data.form
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      currency_id: new FormControl(null), // Default
      start_date_time: new FormControl(this.from),
      end_date_time: new FormControl(this.to),
      timezone: new FormControl(this.timezone),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      }) // Do not remove: For Clearing The Range
    });
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }

  getLastBetLogSyncAt(){
    var userTimeZone = JSON.parse(localStorage.getItem('user_data')).timezone;
    var timeInterved = 5; // 5 minutes 
    const d = new Date();
    let minutes = d.getMinutes();
    const intMinutes =  minutes/timeInterved;
    const roundedMinute = (minutes/timeInterved).toFixed(0);
    var minus;
    if( Number(intMinutes) < Number(roundedMinute) || Number(intMinutes) == Number(roundedMinute)){
      if(Number(roundedMinute) > 0) minus = 1;
      else minus = -1;
    }
    else if( Number(intMinutes) > Number(roundedMinute)){
      minus = 0;
    }

    var  minutesConverted5min = ( Number(roundedMinute) - minus )  * timeInterved;
    var newMinutes;
    if(minutesConverted5min < 10){
      newMinutes = '0'+minutesConverted5min.toString();
    }else{
      newMinutes = minutesConverted5min.toString();
    }

    var timeNow =  DateTime.now().setZone(userTimeZone).toFormat('yyyy-MM-dd HH:'+newMinutes+':00 (ZZ)'); 
    this.lastBetLogSyncAt = timeNow.replace('(', '(GMT ');
  }
}
