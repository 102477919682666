import { AllSubAffiliateReportDataService } from '@views/pages/apps/reports/all-sub-affiliate-report/services/all-sub-affiliate-report-data.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import moment from 'moment';
import { Subscription, forkJoin } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Pagination } from '@core/models/pagination.model';

@Component({
  selector: 'kt-sub-affiliate',
  templateUrl: './sub-affiliate.component.html',
  styleUrls: ['./sub-affiliate.component.scss']
})
export class SubAffiliateComponent implements OnInit {
  pageSize = 30;
  page = 1;
  params = `parent_username=${this.data.affiliate.username}`;
  perPageDropdown = this.dropdownHttpService.perPage;
  pagination: Pagination;
  maxSize = 5;
  totalSubAffiliate = 0;
  form: FormGroup;
  button_loading = false;
  searchBtnLoading = false;
  subAffiliate$: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { affiliate: any },
    private AllSubAffiliateReportDataService: AllSubAffiliateReportDataService,
    public dialogRef: MatDialogRef<SubAffiliateComponent>,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit() {
    this.getSubAffiliateData();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  private getSubAffiliateData(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.button_loading = true;
    this.subAffiliate$ = [];

    this.AllSubAffiliateReportDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.AllSubAffiliateReportDataService.pagination;
        this.totalSubAffiliate = this.pagination.total;
        this.subAffiliate$ = res;
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.getSubAffiliateData(this.page, this.pageSize, this.params);
  }
}
