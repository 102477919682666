import { LogsEntityService } from './service/logs-entity.service';
import { LogsDataService } from './service/logs-data.service';
import { SmsRoutingModule } from './sms-routing.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { entityMetadata } from 'app/app.module';
import { EntityDefinitionService, EntityDataService } from '@ngrx/data';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PartialsModule } from '@views/partials/partials.module';
import { SharedModule } from '@shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LogsComponent } from './logs/logs.component';
import { ProvidersDataService } from './providers/service/providers-data.service';
import { ProvidersEntityService } from './providers/service/providers-entity.service';
import { ProvidersComponent } from './providers/providers.component';
import { SendComponent } from './send/send.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ProviderEditDialogComponent } from './providers/dialogs/provider-edit-dialog.component';
import { AllSmsMessagesComponent } from './all-sms-messages/all-sms-messages.component';
import { AllSmsMessagesDialogComponent } from './all-sms-messages/dialogs/all-sms-messages-dialog.component';
import { AllSmsMessagesDataService } from './all-sms-messages/service/all-sms-messages-data.service';
import { AllSmsMessagesEntityService } from './all-sms-messages/service/all-sms-messages-entity.service';
import { WebPushMessagesComponent } from './web-push-messages/web-push-messages.component';
import { WebPushMessagesEntityService } from './web-push-messages/service/web-push-messages-entity.service';
import { WebPushMessagesDataService } from './web-push-messages/service/web-push-messages-data.service';
import { ProviderSettingsComponent } from './providers/provider-settings/provider-settings.component';
import { ProviderSettingsEditDialogComponent } from './providers/dialogs/provider-settings-edit/provider-settings-edit.component';
import { ProviderPermissionsComponent } from './providers/provider-permissions/provider-permissions.component';
import { ProviderPermissionsEditDialogComponent } from './providers/dialogs/provider-permissions-edit/provider-permissions-edit.component';
import { ProviderPermissionsDataService } from './providers/service/providers-permissions-data.service';
import { ProviderCredentialsComponent } from './providers/provider-credentials/provider-credentials.component';
import { ProviderCredentialsEditDialogComponent } from './providers/dialogs/provider-credentials-edit/provider-credentials-edit.component';
import { ProviderCredentialsDataService } from './providers/service/provider-credentials-data.service';
import { ProviderCredentialSettingsDialogComponent } from './providers/dialogs/provider-credential-settings/provider-credential-settings.component';
import { ProviderCredentialsSettingsEditDialogComponent } from './providers/dialogs/provider-credential-settings-edit/provider-credentials-settings-edit.component';

const dialogs = [
  AllSmsMessagesDialogComponent,
  ProviderEditDialogComponent,
  ProviderSettingsEditDialogComponent,
  ProviderPermissionsEditDialogComponent,
  ProviderCredentialsEditDialogComponent,
  ProviderCredentialSettingsDialogComponent,
  ProviderCredentialsSettingsEditDialogComponent
];

@NgModule({
  entryComponents: [
    ProviderSettingsEditDialogComponent,
  ],
  declarations: [
    ...dialogs,
    LogsComponent,
    ProvidersComponent,
    SendComponent,
    AllSmsMessagesComponent,
    WebPushMessagesComponent,
    ProviderSettingsComponent,
    ProviderPermissionsComponent,
    ProviderCredentialsComponent,
  ],
  imports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CommonModule,
    PartialsModule,
    SmsRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgbModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  providers: [
    LogsDataService,
    LogsEntityService,
    ProvidersDataService,
    ProvidersEntityService,
    AllSmsMessagesDataService,
    AllSmsMessagesEntityService,
    WebPushMessagesDataService,
    WebPushMessagesEntityService,
    ProviderCredentialsDataService,
    ProviderPermissionsDataService,
  ],
})
export class SmsModule {
  constructor(
    private eds: EntityDefinitionService,
    private entityDataService: EntityDataService,
    private logsDataService: LogsDataService,
    private providersDataService: ProvidersDataService,
    private allSmsMessagesDataService: AllSmsMessagesDataService,
    private webPushMessagesDataService: WebPushMessagesDataService
  ) {
    eds.registerMetadataMap(entityMetadata);
    entityDataService.registerServices({
      SmsLog: logsDataService,
      SmsProvider: providersDataService,
      AllSmsMessages: allSmsMessagesDataService,
      WebPushMessagesDataService: webPushMessagesDataService,
    });
  }
}
