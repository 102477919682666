import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ApiResponse } from '@core/models/api-response.model';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { accessSections } from '@core/store/auth/auth.selectors';
import { map } from 'rxjs/operators';


@Injectable()
export class AuthHttpService {
    navigations$ = new Subject<any[]>();

    constructor(
      private http: HttpClient,
      private store: Store<AppState>,
    ) {}

    login(merchant_code: string, username: string, password: string) {
      return this.http.post<ApiResponse>('/login', {merchant_code, username, password});
    }

    logout() {
      return this.http.get('/logout');
    }

    getNavigations() {
      return this.http.get('/navigation');
    }

    changePassword(password: { password: string, password_confirmation: string }) {
      return this.http.post<ApiResponse>('/changepassword', password );
    }

    resetPassword(password: { password: string, password_confirmation: string, username: string }) {
      return this.http.post<ApiResponse>('/resetpassword', password );
    }

    getUserAccess(index: number, keywordName: any): Observable<any> {
      // const userAccess = JSON.parse(localStorage.getItem('user_access'));
      // let access = null;
      // Object.keys(userAccess[index]).forEach(key => {
      //   if(userAccess[index][key].name === keywordName){
      //     access = userAccess[index][key];
      //   }
      // });
      // return access;

      return this.store.pipe(
        select(accessSections),
        map(userAccess => {
          let access = null;
          if (userAccess && userAccess[index]) {
            Object.keys(userAccess[index]).forEach(key => {
              if (userAccess[index][key].name === keywordName) {
                access = userAccess[index][key];
              }
            });
          }
          return access;
        })
      );
    }

    forceLowerCaseInputControl(formGroup: FormGroup, formControlName: string, event: Event) {
      formGroup.get(formControlName).setValue((event.target as HTMLInputElement).value.toLowerCase());
    }

    changeTimezone(timezone: {timezone: string}) {
      return this.http.put<ApiResponse>('/timezoneupdate', timezone);
    }

}
