import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { SubheaderService } from '@core/_base/layout/services/subheader.service';
import { tap, map, exhaustMap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { RebateEntityService } from './services/rebate-entity.service';
import { RebateDataService } from './services/rebate-data.service';
import { Pagination } from '@core/models/pagination.model';
import { RebateEditDialogComponent } from './dialogs/rebate-edit.component';
import { Observable, of, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Rebate } from '@core/models/rebate.model';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-rebates',
  templateUrl: './rebates.component.html',
  styleUrls: ['./rebates.component.scss']
})
export class RebatesComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage
  };
  rebates$: Observable<Rebate[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  canCreateRebate: boolean;
  canViewRebate: boolean;

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private rebateDataService: RebateDataService,
    private rebateEntityService: RebateEntityService,
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    public dialog: MatDialog,
    private gameProviderHttpService: GameProviderHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateRebate = appPermissions.create_rebate_settings;
      this.canViewRebate = appPermissions.view_rebate_settings;
    });

    this.subscriptions.add(apSub);

    this.formInit();
    this.reload();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onOpenDialog(type: string, rebateId?: number) {
    this.gameProviderHttpService.selectionMode = 'fresh';
    if (rebateId) {
      const operator = this.rebateEntityService.getByKey(rebateId);
      this.subscription = operator.pipe(
        tap((res) => {
          this.openDialogBy(RebateEditDialogComponent, { rebate: res, mode: type });
        })
      )
        .subscribe();
    } else {
      this.openDialogBy(RebateEditDialogComponent, { mode: type });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.rebates$ = this.rebateEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.rebateDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.rebates$ = this.rebateEntityService.getWithQuery(`?${this.params}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.rebateDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null) ? fields[key] = formData[key] : key);
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { rebate?: any, mode?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        rebate: data.rebate,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      status: new FormControl('all')
    });
  }

}
