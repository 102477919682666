import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { BankTransaction } from '@core/models/bank-transaction.model';

@Injectable()
export class BankTransactionEntityService extends EntityCollectionServiceBase<BankTransaction> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('BankTransaction', serviceElementsFactory);
  }
}
