export const environment = {
    production: false,
    apiBase: "https://api.porsche68.com/api/bo",
    socketHost: "https://api.porsche68.com",
    signUpUrl: "https://porsche68.com/signup/",
    mobileSignUpUrl: "https://m.porsche68.com/signup/",
    liveChatUrl: "",
    sitePrefix: "bp77",
    sqsEnable: true,
    projectCode: "QP1"
};