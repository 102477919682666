
<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Send Message</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
          <div>
              <div class="col-12 form-group row">
                  <label class="col-3 col-form-label">Member</label>
                  <div class="col-9">
                    <kt-member-dropdown
                      class="dropdown-maxheight"
                      [form]="form"
                      [dropdownSettings]='dropdownSettings'
                      [formName]="'member_account_id'"
                      [selectionAttributes]="'id'"
                      [selectedItems]="selectedMembers"
                      (selectedItemsChanged)="onMemberChanged($event)"
                    >
                    </kt-member-dropdown>
                    <p class="selected-member-wrapper">
                      <!-- User-selection -->
                      <small *ngFor="let item of selectedMembers" class="selected-member bg-info">
                        {{ item.username }}
                        <span class="bg-danger remove" (click)="onRemoveMember(item.id)">x</span>
                      </small>
                    </p>
                  </div>
              </div>
              <div class="col-12 form-group row">
                  <label class="col-3 col-form-label">Subject <span class="text-danger">*</span></label>
                  <div class="col-9 pl-2 pr-2">
                    <input id="contact" formControlName="title" class="form-control">
                  </div>
              </div>
              <div class="col-12 form-group row">
                <label class="col-3 col-form-label">Content <span class="text-danger">*</span></label>
                <div class="col-9">
                  <ckeditor [config]="editorConfig" [editor]="editor" formControlName="content"></ckeditor>
                </div>
              </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="false" (confirmed)="onSubmit()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
