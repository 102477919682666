import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadHttpService } from '@core/services/upload-http.service';
import { AccountManagementDataService } from '../../services/account-management-data.service';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ViewAttachmentComponent } from '../view-attachment/view-attachment.component';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';

@Component({
  selector: 'kt-account-management-remark',
  templateUrl: './account-management-remark.component.html',
  styleUrls: ['./account-management-remark.component.scss']
})
export class AccountManagementRemarkComponent implements OnInit {
  @ViewChild('attachmentLightbox') attachmentLightbox: TemplateRef<any>;

  pagination: Pagination;
  imagePreview = [];
  form: FormGroup;
  isPDF: boolean = false;
  buttonLoading = false;
  refreshStatus: boolean;
  messages$ = this.AccountManagementDataService.messages$;
  loading = false;
  remarksHistory = [];
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
  };
  maxSize = 5;
  pageSize = 30;
  page = 1;

  private subscription = new Subscription();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {mode: string, member},
    public dialogRef: MatDialogRef<AccountManagementRemarkComponent>,
    private uploadService: UploadHttpService,
    private sanitizer: DomSanitizer,
    private AccountManagementDataService: AccountManagementDataService,
    public loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit() {
    this.formInit();
    if (this.data.mode == 'show-remarks') {
      this.getTimeline();
      this.pagination = this.AccountManagementDataService.pagination;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onUploadFile(event: any) {
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'receipts');
    this.uploadService.upload(formData).subscribe(res => {
      this.imagePreview = res;
      if(res[0].indexOf('.pdf') >= 0) {
        this.isPDF = true;
        this.imagePreview[0] = this.sanitizer.bypassSecurityTrustResourceUrl(res[0]);
      } else {
        this.isPDF = false;
      }
      // this.imagePreview.push({
      //   path: res[0].indexOf('.pdf') >= 0 ? this.sanitizer.bypassSecurityTrustResourceUrl(res[0]) : res,
      //   isPdf: res[0].indexOf('.pdf') >= 0 ? true : false,
      // });
      this.form.patchValue({
        attachment: this.isPDF? this.imagePreview[0].changingThisBreaksApplicationSecurity : this.imagePreview[0]
      });
      // this.form.get('attachment').markAsDirty();
      // this.form.markAllAsTouched();
    });
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    const data = {
      ...this.form.value,
    };
    Object.keys(data).forEach((key) => (data[key] == null) && delete data[key]);
    this.subscription = this.AccountManagementDataService.updateRemarks(data, this.data.member.id).pipe(
      tap((res: any) => {
        this.messages$.next([...res.message]);
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }
  
  private formInit() {
    this.form = new FormGroup({
      development_stage: new FormControl(this.data.member.development_stage == 'Normal' ? 1: 2, [Validators.required]),
      lead_stage: new FormControl(this.data.member.lead_stage, [Validators.required]),
      status: new FormControl(this.data.member.status, [Validators.required]),
      attachment: new FormControl(null),
      remarks: new FormControl(null, [Validators.required]),
    });
  }

  private getTimeline(page = 1, pageSize?: number) {
    const id = this.data.member.id;
    this.loading = true;
    this.loadingBar.useRef().start();
    pageSize = this.pageSize;

    this.AccountManagementDataService.getRemarks(`/${id}?page=${page}&perPage=${pageSize}`).subscribe(res => {
      this.pagination = this.AccountManagementDataService.pagination;
      this.remarksHistory = res.rows;
      this.loadingBar.useRef().complete();
      this.loading = false;
    });
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.getTimeline(this.page, this.pageSize);
  }

  openAttachment(URLdata) {
    this.openDialogBy(ViewAttachmentComponent, {data: URLdata})
  }

  private openDialogBy(componentRef: any, data) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '740px',
      data: data
    });
  }
}
