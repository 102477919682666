import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Pagination } from '@core/models/pagination.model';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { ShowDocumentComponent } from '../show-document/show-document.component';
import { MemberBankAccountVerificationDataService } from '../../services/member-bank-account-verification-data.service';

import { MemberBankAccountVerificationRemark } from '@core/models/member-bank-account-verification-remark-model';

@Component({
  selector: 'kt-member-bank-account-verification-remark',
  templateUrl: './member-bank-account-verification-remark.component.html',
  styleUrls: ['./member-bank-account-verification-remark.component.scss']
})
export class MemberBankAccountVerificationRemarkComponent implements OnInit {
  @ViewChild('attachmentLightbox') attachmentLightbox: TemplateRef<any>;

  pagination: Pagination;
  form: FormGroup;
  buttonLoading = false;
  refreshStatus: boolean;
  messages$ = this.MemberBankAccountVerificationDataService.messages$;
  loading = false;
  remarksHistory = [];
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
  };
  maxSize = 5;
  pageSize = 30;
  page = 1;

  private subscription = new Subscription();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {mode: string, memberVerification: MemberBankAccountVerificationRemark},
    public dialogRef: MatDialogRef<MemberBankAccountVerificationRemarkComponent>,
    public dialog: MatDialog,
    public loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private MemberBankAccountVerificationDataService: MemberBankAccountVerificationDataService,
  ) { }

  ngOnInit() {
    this.getTimeline();
    this.pagination = this.MemberBankAccountVerificationDataService.pagination;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  private getTimeline(page = 1, pageSize?: number) {
    const id = this.data.memberVerification.id;
    this.loading = true;
    this.loadingBar.useRef().start();
    pageSize = this.pageSize;

    this.MemberBankAccountVerificationDataService.getRemarks(`/${id}?page=${page}&perPage=${pageSize}`).subscribe(res => {
      this.pagination = this.MemberBankAccountVerificationDataService.pagination;
      this.remarksHistory = res;
      this.loadingBar.useRef().complete();
      this.loading = false;
    });
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.getTimeline(this.page, this.pageSize);
  }

  openDocument(documents) {
    this.openDialogBy(ShowDocumentComponent, documents)
  }

  private openDialogBy(componentRef: any, data) {
    this.dialog.open(componentRef, {
      width: '800px',
      data: {documents: data}
    });
  }
}
