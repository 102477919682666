<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{ +data.formData.eligible_types === 2 ? 'Affiliate' : 'Member'}} List</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center col-12" [formGroup]="form" *ngIf="data.promotionCodeMode === 'edit'">
                            <div class="col-12 row mb-2 pr-0" >
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="kt-section mb-0">
                    <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                        <div class="dropdown dropdown-inline">
                            <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()" *ngIf="data.promotionCodeMode === 'edit'"></kt-search-button>
                            <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()" *ngIf="data.promotionCodeMode === 'edit'"></kt-clear-button>
                        </div>
                        <div>
                            <input type="file" hidden id="file" accept=".csv" (change)="onMemberUpload($event)" #myFileInput>
                            <label *ngIf="importedMemberFileName !== '' && importedMemberFileName !== null && data.promotionCodeMode === 'create'" class="mr-3">
                                <span class="modal-x-button mr-2" (click)="onRemoveFile()" matTooltip="Remove File">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </span>
                                {{ importedMemberFileName }}
                            </label>
                            <button [disabled]="uploading && data.promotionCodeMode === 'edit' ? true : false" (click)="myFileInput.click()" class="btn btn-import btn-icon-sm mr-2" *ngIf="+data.formData.eligible_types !== 2">
                                <i [ngClass]="uploading? 'spinner-border spinner-border-sm align-middle' : 'fas fa-file-import'"></i>Import
                            </button>
                            <button class="btn btn-brand btn-icon-sm float-right" (click)="onUpdatePerson()"><i class="fas fa-plus"></i>Add</button>
                        </div>
                    </div>
                    <div class="col-12 row mx-0 px-0 my-2">
                        <div class="col-12 p-0 text-right">
                            <i class="fa fa-file-csv"></i>
                            <a href="assets/ExampleImportMemberList.csv" target="_blank" download="ExampleImportMemberList.csv"> {{ 'Download Template' }}</a>
                        </div>
                    </div>
                    <div class="kt-section__content">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover table-striped">
                                <thead>
                                        <tr>
                                            <ng-container>
                                                <th class="text-center">Username</th>
                                                <th class="text-center">{{ +data.formData.eligible_types === 2 ? 'Affiliate' : 'Member'}} Group</th>
                                            </ng-container>
                                            <th class="text-center">Actions</th>
                                        </tr>
                                </thead>
                                <ng-container *ngIf="+data.formData.eligible_types !== 2; else elseAffiliate">
                                    <tbody>
                                        <ng-container *ngIf="promotionMember$.length > 0; else elseNoData1">
                                            <tr *ngFor="let row of promotionMember$;index as indexOfelement">
                                                <ng-container>
                                                    <td class="text-center align-middle">{{ row.username }}</td>
                                                    <td class="text-center align-middle">{{ row.group }}</td>
                                                </ng-container>
                                                <td class="text-center actions-column">
                                                    <button matTooltip="Delete" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onUpdatePerson(row)"><i class="fas fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-template #elseNoData1>
                                            <kt-fallback-row [collection]="promotionMember$"></kt-fallback-row>
                                        </ng-template>
                                    </tbody>
                                </ng-container>
                                <ng-template #elseAffiliate>
                                    <tbody>
                                        <ng-container *ngIf="promotionAffiliate$.length > 0; else elseNoData2">
                                            <tr *ngFor="let row of promotionAffiliate$;index as indexOfelement">
                                                <ng-container>
                                                    <td class="text-center align-middle">{{ row.username }}</td>
                                                    <td class="text-center align-middle">{{ row.group }}</td>
                                                </ng-container>
                                                <td class="text-center actions-column">
                                                    <button matTooltip="Delete" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onUpdatePerson(row)"><i class="fas fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-template #elseNoData2>
                                            <kt-fallback-row [collection]="promotionAffiliate$"></kt-fallback-row>
                                        </ng-template>
                                    </tbody>
                                </ng-template>
                            </table>
                        </div>
                        <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                            <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
</div>
</div>
<kt-swal-alert [message]="messages$ | async" [data]="data$ | async" (confirmed)="resetData()"></kt-swal-alert>