<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
        <div class="row col-12 modal-body">
          <div class="col-4 stepwizard">
            <div class="stepwizard-row">
              <div class="stepwizard-step">
                  <button type="button" class="btn btn-circle" [ngClass]="currentStep == 1 ? 'btn-primary' : 'btn-default'" disabled="disabled">{{ currentStep > 1 ? '✔' : '1' }}</button>
                  <div class="wizard-text">
                    <p [ngClass]="currentStep == 1 ? 'current-step-text' : currentStep > 1 ? 'step-hint' : 'step-text'">Step 1</p><br>
                    <p class="mt-0 step-hint">Select Telemarketer</p>
                  </div>
              </div>
              <div class="stepwizard-step">
                  <button type="button" class="btn btn-circle" [ngClass]="currentStep == 2 ? 'btn-primary' : 'btn-default'" disabled="disabled">2</button>
                  <div class="wizard-text mb-0">
                    <p class="mb-0" [ngClass]="currentStep == 2 ? 'current-step-text' : 'step-text'">Step 2</p><br>
                    <p class="mt-0 step-hint">Select Leads</p>
                  </div>
              </div> 
            </div>
          </div>
  
          <ng-container>
            <div class="col-8 form-group pr-0 wizard-input step-wizard-2" [hidden]="currentStep !== 1">
              <label class="col-12 col-form-label pl-0">From Telemarketer <span class="text-danger">*</span></label>
              <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0"
                [form] = 'form'
                [dropdownList] = 'fromTelemarketerDropdownList'
                [dropdownSettings] = 'fromTelemarketerDropdownSettings'
                [formName] = "'telemarketer_id'"
                [selectionAttributes] = "'id'"
                [selectedItems] = 'fromTelemarketerSelectedItems'>
              </kt-dropdown-wo-lazyload>
  
              <label class="col-12 col-form-label pl-0 mt-3">Reassign to Telemarketer <span class="text-danger">*</span></label>
              <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0"
                [form] = 'form'
                [dropdownList] = 'toTelemarketerDropdownList'
                [dropdownSettings] = 'toTelemarketerDropdownSettings'
                [formName] = "'to_telemarketer_id'"
                [selectionAttributes] = "'id'"
                [selectedItems] = 'toTelemarketerSelectedItems'>
              </kt-dropdown-wo-lazyload>
            </div>
          </ng-container>
  
          <ng-container>
            <div class="col-8 form-group pr-0 wizard-input step-wizard-2" [hidden]="currentStep !== 2">
              <label class="col-4 col-form-label">Leads <span class="text-danger">*</span></label>
              <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0"
                [form] = 'form'
                [dropdownList] = 'leadsDropdownList'
                [dropdownSettings] = 'leadsDropdownSettings'
                [formName] = "'lead_ids'"
                [selectionAttributes] = "'id'"
                [selectedItems] = 'leadsSelectedItems'>
              </kt-dropdown-wo-lazyload>
            </div>
          </ng-container>
          
          <div class="kt-section col-12 row d-flex justify-content-between mb-0 wizard-footer-button">
            <div class="col-4"></div>
            <div class="col-4 dropdown dropdown-inline">
              <button *ngIf="currentStep !== 1" class="btn btn-back" (click)="onBack()">Back</button>
            </div>
            <ng-container *ngIf="currentStep !== 2; else stepSubmit">
              <div class="col-4 dropdown dropdown-inline text-right pr-0">
                <button class="btn btn-success" (click)="onNext()" [disabled]="isDisabled()">Next</button>
              </div>
            </ng-container>
            <ng-template #stepSubmit>
              <div class="col-4 dropdown dropdown-inline text-right pr-0">
                <button class="btn btn-success" (click)="onSubmit()" [disabled]="isDisabled()">Submit</button>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </form>
  </div>
  