<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <div class="col-12 row mb-2 pr-0">
                <!-- Telemarketer -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Telemarketer:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0" [form]='form'
                    [dropdownList]='telemarketerDropdownList' [dropdownSettings]='telemarketerDropdownSettings'
                    [formName]="'telemarketer_id'" [selectionAttributes]="'id'"
                    [selectedItems]='telemarketerSelectedItems'>
                  </kt-dropdown-wo-lazyload>
                </div>
                <!-- Date -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date: </label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <select class="form-control" formControlName="date_type">
                        <option value="assigned_date">Assigned Date</option>
                        <option value="registration_date">Registration Date</option>
                        <option value="FTD_date">FTD Date</option>
                      </select>
                    </div>
                    <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)"
                      formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true"
                      [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true"
                      [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                      ngxDaterangepickerMd />
                    <span class="input-group-append">
                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                    </span>
                  </div>
                </div>
                <!-- Only deposit -->
                <div class="col-md-3 kt-form__control">
                  <div class="d-flex flex-wrap status-filter-pt">
                      <div class="custom-control custom-checkbox mr-3">
                          <input type="checkbox" class="custom-control-input" formControlName="only_deposit" id="only_deposit">
                          <label class="custom-control-label text-nowrap" for="only_deposit">Only deposit</label>
                      </div>
                  </div>
                </div>
              </div>
              <div class="col-12 row pr-0">
                <!-- Currency -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Currency:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="currency_id" class="form-control">
                    <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Timezone' }}:</label>
                  </div>
                  <div class="col-md-5 kt-form__control">
                    <kt-dropdown-wo-lazyload #timezone (click)="onChangeTimezone(timezone.selectedItems)" [form]='form'
                      [dropdownList]='dropdown.timezones' [dropdownSettings]='timezoneDropdownSettings'
                      [formName]="'timezone'" [selectionAttributes]="'timezone'" [selectedItems]='timezoneSelectedItems'>
                    </kt-dropdown-wo-lazyload>
                  </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
        <div>
          <!-- Temporary only enable export function -->
          <!-- <kt-search-button [loading]="searchBtnLoading" [disabledButton]="true"
            (search)="onSubmit()"></kt-search-button> -->
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <button *ngIf="canExportTeleMarketingPlayerSummary" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button"
          [disabled]="buttonLoading"><i
            [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
      </div>
      <!-- Temporary only enable export function -->
      <!-- Table -->
      <!-- <div class="kt-section ">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th rowspan="2" class="text-center" >Member ID</th>
                  <th rowspan="2" class="text-center" >Username</th>
                  <th rowspan="2" class="text-center" >Telemarketer</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('lead_assigned_datetime')"
                    [ngClass]="sortingConfig.lead_assigned_datetime" [class.sort-selected]="sortingSelection.sort_by === 'lead_assigned_datetime'">
                    Assigned Date</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('registration_date')"
                    [ngClass]="sortingConfig.registration_date" [class.sort-selected]="sortingSelection.sort_by === 'registration_date'">
                    Registration Date</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('ftd_date')"
                    [ngClass]="sortingConfig.ftd_date" [class.sort-selected]="sortingSelection.sort_by === 'ftd_date'">
                    FTD Date</th>
                  <th rowspan="2" class="text-center" >Currency</th>
                  <th colspan="2" class="text-center" >Days Since FTD</th>
                  <th rowspan="2" class="text-center" >Total Deposit Count</th>
                  <th rowspan="2" class="text-center" >Total Deposit Amount</th>
                  <th rowspan="2" class="text-center" >Total Withdrawal Count</th>
                  <th rowspan="2" class="text-center" >Total Withdrawal Amount</th>
                  <th rowspan="2" class="text-center" >Total Bet Count</th>
                  <th rowspan="2" class="text-center" >Total Bet Amount</th>
                  <th rowspan="2" class="text-center" >Total Valid Turnover Amount</th>
                  <th rowspan="2" class="text-center" >Total Win Amount</th>
                  <th rowspan="2" class="text-center" >GGR</th>
                  <th rowspan="2" class="text-center" >Total Deposit Bonus Amount</th>
                  <th rowspan="2" class="text-center" >Total Free Credit Amount</th>
                  <th rowspan="2" class="text-center" >Total Credit Rebate Amount</th>
                  <th rowspan="2" class="text-center" >Total Processing Fee</th>
                  <th rowspan="2" class="text-center" >NGR</th>
                </tr>
                <tr>
                  <th class="text-center">Current</th>
                  <th class="text-center">Overall</th>
                </tr>
              </thead>
              <tbody *ngIf="(telemarketingPlayerSummaryReport$ | async) as rows">
                <ng-container *ngFor="let row of rows">
                  <tr>
                    <td rowspan="3" class="text-center align-middle">{{ row.member_id }}</td>
                    <td rowspan="3" class="text-center align-middle"><a class="text-primary font-weight-bold" (click)="onOpenDialog(row.member_id)">{{row.member_username}}</a></td>
                    <td rowspan="3" class="text-center align-middle">{{ row.telemarketer_username }}</td>
                    <td rowspan="3" class="text-center align-middle">{{ timezoneDateWithTimezone(row.lead_assigned_datetime, this.form.value.timezone, 'YYYY-MM-DD HH:mm') }}</td>
                    <td rowspan="3" class="text-center align-middle">{{ timezoneDateWithTimezone(row.registration_date, this.form.value.timezone, 'YYYY-MM-DD HH:mm') }}</td>
                    <td rowspan="3" class="text-center align-middle">{{ row.ftd_date ? timezoneDateWithTimezone(row.ftd_date, this.form.value.timezone, 'YYYY-MM-DD HH:mm')  : '-' }}</td>
                    <td rowspan="3" class="text-center align-middle">{{ row.currency_code }}</td>
                    <td rowspan="3" class="text-center align-middle">{{ row.ftd_status }}</td>
                    <td class="text-center">Ongoing</td>
                    <td class="text-center">{{ row.deposit_total_ongoing ? row.deposit_total_ongoing : '0' }}</td>
                    <td class="text-center">{{ row.deposit_total_amount_ongoing ? (row.deposit_total_amount_ongoing | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.withdraw_total_ongoing ? row.withdraw_total_ongoing: '0' }}</td>
                    <td class="text-center">{{ row.withdraw_total_amount_ongoing ? (row.withdraw_total_amount_ongoing | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_bet_count_ongoing ? row.total_bet_count_ongoing  : '0' }}</td>
                    <td class="text-center">{{ row.total_bet_amount_ongoing ? (row.total_bet_amount_ongoing | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_valid_bet_amount_ongoing ? (row.total_valid_bet_amount_ongoing | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_player_win_lose_ongoing ? (row.total_player_win_lose_ongoing | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.gross_game_revenue_ongoing ? (row.gross_game_revenue_ongoing | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_deposit_bonus_ongoing ? (row.total_deposit_bonus_ongoing | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_freecredit_bonus_ongoing ? (row.total_freecredit_bonus_ongoing | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_rebate_ongoing ? (row.total_rebate_ongoing | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_processing_fee_ongoing ? (row.total_processing_fee_ongoing | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.net_game_revenue_ongoing ? (row.net_game_revenue_ongoing | number:'1.2-2') : '0.00' }}</td>
                  </tr>
                  <tr>
                    <td class="text-center">30 days</td>
                    <td class="text-center">{{ row.deposit_total_30days ? row.deposit_total_30days : '0' }}</td>
                    <td class="text-center">{{ row.deposit_total_amount_30days ? (row.deposit_total_amount_30days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.withdraw_total_30days ? row.withdraw_total_30days : '0' }}</td>
                    <td class="text-center">{{ row.withdraw_total_amount_30days ? (row.withdraw_total_amount_30days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_bet_count_30days ? row.total_bet_count_30days : '0' }}</td>
                    <td class="text-center">{{ row.total_bet_amount_30days ? (row.total_bet_amount_30days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_valid_bet_amount_30days ? (row.total_valid_bet_amount_30days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_player_win_lose_30days ? (row.total_player_win_lose_30days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.gross_game_revenue_30daysg ? (row.gross_game_revenue_30days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_deposit_bonus_30days ? (row.total_deposit_bonus_30days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_freecredit_bonus_30days ? (row.total_freecredit_bonus_30days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_rebate_30days ? (row.total_rebate_30days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_processing_fee_30days ? (row.total_processing_fee_30days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.net_game_revenue_30days ? (row.net_game_revenue_30days | number:'1.2-2') : '0.00' }}</td>
                  </tr>
                  <tr>
                    <td class="text-center">60 days</td>
                    <td class="text-center">{{ row.deposit_total_60days ? row.deposit_total_60days: '0' }}</td>
                    <td class="text-center">{{ row.deposit_total_amount_60days ? (row.deposit_total_amount_60days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.withdraw_total_60days ? row.withdraw_total_60days : '0' }}</td>
                    <td class="text-center">{{ row.withdraw_total_amount_60days ? (row.withdraw_total_amount_60days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_bet_count_60days ? row.total_bet_count_60days : '0' }}</td>
                    <td class="text-center">{{ row.total_bet_amount_60days ? (row.total_bet_amount_60days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_valid_bet_amount_60days ? (row.total_valid_bet_amount_60days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_player_win_lose_60days ? (row.total_player_win_lose_60days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.gross_game_revenue_60days ? (row.gross_game_revenue_60days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_deposit_bonus_60days ? (row.total_deposit_bonus_60days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_freecredit_bonus_60days ? (row.total_freecredit_bonus_60days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_rebate_60days ? (row.total_rebate_60days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.total_processing_fee_60days ? (row.total_processing_fee_60days | number:'1.2-2') : '0.00' }}</td>
                    <td class="text-center">{{ row.net_game_revenue_60days ? (row.net_game_revenue_60days | number:'1.2-2') : '0.00' }}</td>
                  </tr>
                </ng-container>

              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold">
              </tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true"
              [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total"
              (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                Showing {{pagination.from}} to {{pagination.to}} of
                {{ pagination.total | number : '1.0' }} records
              </span>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
