import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { PromotionCurrency } from '@core/models/promotion-currency.model';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { PromotionCurrencyEntityService } from './services/promotion-currency-entity.service';
import { PromotionCurrencyDataService } from './services/promotion-currency-data.service';
import { Pagination } from '@core/models/pagination.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Status } from '@core/enums/status.enum';
import { PromotionCurrencyDetailsComponent } from './dialogs/promotion-currency-details.component';
import { PromotionCodeDataService } from '../promotion-codes/services/promotion-code-data.service';
import { PromotionCodeDetailsDialogComponent } from '../promotion-codes/dialogs/promotion-code-details/promotion-code-details';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PromotionCode } from '@core/models/promotion-code.model';
import { AppPermissionService } from '@core/services/app-permission.service';
@Component({
  selector: 'kt-promotion-currency',
  templateUrl: './promotion-currency.component.html',
  styleUrls: ['./promotion-currency.component.scss']
})
export class PromotionCurrencyComponent implements OnInit, OnDestroy {

  @ViewChild(PromotionCodeDetailsDialogComponent) child;
  status = Status;
  promotionCurrency$ = localStorage.getItem('createPromoCodeCurrencies') === null ? [] : JSON.parse(localStorage.getItem('createPromoCodeCurrencies'));
  private subscription = new Subscription();

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = this.data.promotionCodeMode === 'edit' ? `promotion_id=${this.data.promotionCode.id}` : '';
  catchPromotionId: any;
  perPageDropdown = this.dropdownHttpService.perPage;
  promotionType = +this.data.promotionType;

  // permissions
  canAddPromotionCurrency: boolean;
  canEditPromotionCurrency: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { promotionCurrency: PromotionCurrency, promotionCode: PromotionCode, promotionType?: any, promoSubType: number, promotionCodeMode: string },
    private loadingBar: LoadingBarService,
    private promotionCurrencyEntityService: PromotionCurrencyEntityService,
    private promotionCurrencyDataService: PromotionCurrencyDataService,
    private promotionCodeDataService: PromotionCodeDataService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PromotionCurrencyComponent>,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if (this.data.promotionCodeMode === 'edit'){
      this.reload();
      this.pagination = this.promotionCurrencyDataService.pagination;
    }

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canAddPromotionCurrency = appPermissions.add_promotion_currency;
      this.canEditPromotionCurrency = appPermissions.edit_promotion_currency;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.promotionCurrencyEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.promotionCurrencyDataService.pagination;
        this.promotionCurrency$ = res;
        this.loadingBar.complete();
      })
    );
  }
  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, promotionCurrencyRow?: any) {
    if (this.data.promotionCodeMode === 'edit'){
      if(promotionCurrencyRow) {
        const promotionCurrency = this.promotionCurrencyEntityService.getByKey(promotionCurrencyRow.id);
        this.subscription = promotionCurrency.pipe(
          tap( (res) => {
          switch (type) {
            case 'edit':
              this.openDialogBy(PromotionCurrencyDetailsComponent, { promotionCurrency: res, mode: 'edit', promotionId: res.promotion_id });
              break;
          }
        })
        )
        .subscribe();
      } else {
        this.openDialogBy(PromotionCurrencyDetailsComponent, { promotionCurrency: null, mode: 'create', promotionId: this.data.promotionCode.id });
      }
    }else{
      if (promotionCurrencyRow){
        this.openDialogBy(PromotionCurrencyDetailsComponent, { promotionCurrency: localStorage.getItem('createPromoCodeCurrencies') === null ? [] : JSON.parse(localStorage.getItem('createPromoCodeCurrencies')), mode: 'edit', promotionId: null, row: promotionCurrencyRow });
      }else{
        this.openDialogBy(PromotionCurrencyDetailsComponent, { promotionCurrency: localStorage.getItem('createPromoCodeCurrencies') === null ? [] : JSON.parse(localStorage.getItem('createPromoCodeCurrencies')), mode: 'create', promotionId: null });
      }
    }
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { promotionCurrency?: any, mode?: any, promotionId?: number, row?: any}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        promotionCurrency: data.promotionCurrency,
        mode: data.mode,
        promotionId: data.promotionId,
        promotionType: this.promotionType,
        promoSubType: this.data.promoSubType,
        promotionCodeMode: this.data.promotionCodeMode === 'create' || this.data.promotionCodeMode === 'duplicate' ? 'create' : this.data.promotionCodeMode,
        promotionCurrencyRow: data.row
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (this.data.promotionCodeMode === 'edit'){
        if(result === true){
          this.onViewPageBy(this.page).subscribe();
        }
      }else{
        if(result !== undefined){
          setTimeout(() => {
            this.promotionCurrency$ = localStorage.getItem('createPromoCodeCurrencies') === null ? [] : JSON.parse(localStorage.getItem('createPromoCodeCurrencies'));
          }, 300);
        }
      }
    });
  }

}
