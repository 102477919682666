import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { WhatsappNumber } from '@core/models/whatsapp-number.model';

@Injectable()
export class WhatsappNumberEntityService extends EntityCollectionServiceBase<WhatsappNumber> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('WhatsappNumber', serviceElementsFactory);
    }
}

