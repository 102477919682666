import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class CryptoTokenHttpService {

  constructor(private http: HttpClient) { }

  getTokens(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/cryptotokens`).pipe(
      map(res => res.data.rows)
    );
  }

  getTokensByCurrency(currency_id): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/cryptotokenbycurrency?currency_id=${currency_id}`).pipe(
      map(res => res.data.rows)
    );
  }

  getTokenExchangeRate(data) {
    return this.http.get<ApiResponse>(`/c2pexchangerate?${data}`).pipe(
      map(res => res.data.rows)
    )
  }
}

