import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { PromotionStatistic } from '@core/models/promotion-statistic.model';

@Injectable()
export class PromotionStatisticEntityService extends EntityCollectionServiceBase<PromotionStatistic> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('PromotionStatistic', serviceElementsFactory);
  }
}
