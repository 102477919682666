import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { AllPredictions } from '@core/models/all-predictions.model';

@Injectable()
export class AllPredictionsEntityService extends EntityCollectionServiceBase<AllPredictions>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('AllPredictions', serviceElementsFactory)  }
}
