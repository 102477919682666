// Angular / Core
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// Model
import { ApiResponse } from "@core/models/api-response.model";
import { Pagination } from "@core/models/pagination.model";
// Third Party
import { Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class SeoSettingsDomainsDataService {

    constructor(private http: HttpClient) { }

    getDomains(pageParam: string): Observable<Dropdown[]> {
      return this.http.get<ApiResponse>(`/seo/getSettingDomains${pageParam}`).pipe(
        map(res => res.data.rows)
      );
    }
}
