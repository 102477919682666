import { RecoveryPromotionEditDialogComponent } from './dialogs/recovery-promotion-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, catchError, debounceTime, distinctUntilChanged, map, exhaustMap } from 'rxjs/operators';
import { RecoveryPromotionEntityService } from './services/recovery-promotion-entity.service';
import { RecoveryPromotionDataService } from './services/recovery-promotion-data.service';
import { Pagination } from '@core/models/pagination.model';
import { RecoveryPromotion } from '@core/models/recovery-promotion.model';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { SubheaderService } from '@core/_base/layout';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { AppPermissionService } from '@core/services/app-permission.service';
import { PromotionCodeDataService } from '../promotion-codes/services/promotion-code-data.service';
import { FormControl, FormGroup } from '@angular/forms';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { Promotion } from '@core/models/promotion.model';
import { PromotionSettingHttpService } from '@core/services/promotion-setting-http.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'kt-recovery-promotion',
  templateUrl: './recovery-promotion.component.html',
  styleUrls: ['./recovery-promotion.component.scss']
})
export class RecoveryPromotionComponent implements OnInit, OnDestroy {

  recoveryPromotions$: Observable<RecoveryPromotion[]>;

  object = Object;
  currencyDropdownList = [];
  currencySelectedItems = [];
  currencyDropdownSettings = {
    singleSelection: false,
    labelKey: 'name',
    enableSearchFilter: true,
    
  }

  dateTimeFilterType = [
    {
      key: 'created_time',
      label: 'Created Date'
    },
    {
      key: 'updated_time',
      label: 'Updated Date'
    }
  ]
  ranges = this.transactionHttpService.ranges;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  form: FormGroup;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    depositorStatus: this.dropdownHttpService.depositorStatuses,
    perPage: this.dropdownHttpService.perPage
  };
  buttonLoading = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  promoTypeahead = [];
  dataLength: number;

  // permissions
  canCreateRecoveryPromotion: boolean;
  canEditRecoveryPromotion: boolean;
  canDuplicateRecoveryPromotion: boolean;
  canUpdateRecoveryPromotionStatus: boolean;

  promotionsLoading = true;
  promotions = [];

  messages$ = this.recoveryPromotionDataService.messages$;
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    private recoveryPromotionDataService: RecoveryPromotionDataService,
    private recoveryPromotionEntityService: RecoveryPromotionEntityService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
    private promotionCodeDataService: PromotionCodeDataService,
    private transactionHttpService: TransactionHttpService,
    private promotionSettingHttpService: PromotionSettingHttpService,
  ) {
    this.promotionCodeDataService.getWithQuery(`?paginate=false`).subscribe(promotions => {
      promotions.map(function (elm) {
        elm['name'] = elm.code + ' - ' + elm.name;
      });
      this.promotionsLoading = false;
      this.promotions = promotions;
    });
  }

  ngOnInit() {
    this.onReload();
    this.formInit();
    this.promotionSettingHttpService.getAllPromoSettings().subscribe((res: any) => {
      this.promoTypeahead = res.promotions;
    });
    this.dropdownHttpService.currencies.subscribe(res => {
      this.currencyDropdownList = res;
    });
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateRecoveryPromotion = appPermissions.create_recovery_promotion;
      this.canEditRecoveryPromotion = appPermissions.edit_recovery_promotion;
      this.canDuplicateRecoveryPromotion = appPermissions.duplicate_recovery_promotion;
      this.canUpdateRecoveryPromotionStatus = appPermissions.update_recovery_promotion_status;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.recoveryPromotions$ = this.recoveryPromotionEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.recoveryPromotionDataService.pagination;
        this.dataLength = res.length;
        this.loadingBar.complete();
      })
    );
  }

  onSubmit(clearSearch? : boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    const formValue = this.form.value;
    if (formValue.promotion) {
      if (formValue.promotion.code != undefined && formValue.promotion.name != undefined) {
        formValue.code = formValue.promotion.code;
        formValue.promotion_name = formValue.promotion.name;
        delete formValue.promotion;
      }
    }
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_datetime']) {
          data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.recoveryPromotions$ = this.recoveryPromotionEntityService.getWithQuery(`?${this.params}&page=${this.page}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.pagination = this.recoveryPromotionDataService.pagination;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.currencySelectedItems = [];
    this.onClearDate();
    this.formInit();
    this.onSubmit(true);
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== false) {
        fields[key] = formData[key] === true ? 1 : formData[key];
      }
    });
    return fields;
  }

  onDuplicate(data: any) {
    this.onOpenDialog('duplicate', data)
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(mode: 'create' | 'edit' | 'duplicate', row?: RecoveryPromotion) {
    this.loadingBar.start();
    this.buttonLoading = true;

    let interval = setInterval(() => {
      if (!this.promotionsLoading) {
        if (mode === 'edit' || mode === 'duplicate') {
          this.recoveryPromotionDataService.getById(row.id).subscribe(res => {
            this.loadingBar.complete();
            this.buttonLoading = false;
            this.openDialogBy(RecoveryPromotionEditDialogComponent, { mode: mode, row: res, promotions: this.promotions });
          });
        } else {
          this.loadingBar.complete();
          this.buttonLoading = false;
          this.openDialogBy(RecoveryPromotionEditDialogComponent, { mode: mode, promotions: this.promotions });
        }
        clearInterval(interval);
      }
    }, 500);

  }

  onChangeStatus(row: any, key) {
    if (!this.canEditRecoveryPromotion && !this.canUpdateRecoveryPromotionStatus) {
      Swal.fire('Permission Denied', 'You are not allowed to update recovery promotion status', 'error');
      return;
    }

    const data = {
      id: row.id,
      status: key
    };
    this.subscription = this.recoveryPromotionDataService.toggleStatus(row.id, data).subscribe(() =>
      this.messages$ = this.recoveryPromotionDataService.messages$
    );
  }

  onReload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, row?: any, promotions?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '80vw',
      data: {
        mode: data.mode,
        row: data.row,
        promotions: data.promotions
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null),
      description: new FormControl(null),
      currency_id: new FormControl(this.currencySelectedItems),
      promotion: new FormControl({value: null, disabled: false}),
      depositor: new FormControl(null),
      status: new FormControl('all'),
      datetime_type: new FormControl(this.dateTimeFilterType[0].key),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null),
    });
  }

  filterPromo(term: string): Promotion[] {
    if (this.promoTypeahead) {
      return this.promoTypeahead.filter(
        promo => promo.code.toLowerCase().includes(term.toLowerCase()) || promo.name.toLowerCase().includes(term.toLowerCase())
      );
    } else {
      return [];
    }
  }

  searchPromo = (search$: Observable<string>): Observable<Promotion[]> =>{
    return search$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(term => term.trim() === '' ? [] : this.filterPromo(term))
    )
  }

  formatPromo(promotion: Promotion): string {
    return `${promotion.code} - ${promotion.name}`;
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null ? event.startDate._d : event.startDate,
        end_datetime: event.endDate !== null ? event.endDate._d : event.endDate
      });
    }
  }
}
