<div class="kt-form kt-form--label-right  mb-2">
  <div class="row align-items-center">
    <div class="col-xl-12 order-2 order-xl-1 pr-0">
      <form class="row align-items-center" [formGroup]="form">
        <div class="col-12 row mb-2 pr-0">
          <div class="col-md-1 kt-form__label col-form-label">
            <label>Code:</label>
          </div>
          <div class="col-md-2 kt-form__control">
            <input type="text" formControlName="code" placeholder="Search" class="form-control">
          </div>
          <div class="col-md-1 kt-form__label col-form-label">
            <label>Name:</label>
          </div>
          <div class="col-md-2 kt-form__control">
            <input type="text" formControlName="name" placeholder="Search" class="form-control">
          </div>
          <div class="col-md-1 kt-form__label col-form-label">
            <label>Region:</label>
          </div>
          <div class="col-md-2 kt-form__control">
            <select class="form-control" formControlName="region">
              <option value="all"> All </option>
              <option [value]="item.country_code" *ngFor="let item of regions">{{ item.country_code }}
              </option>
            </select>
          </div>
          <div class="col-md-1 kt-form__label col-form-label">
            <label>Status:</label>
          </div>
          <div class="col-md-2 kt-form__control">
            <select class="form-control" formControlName="status">
              <option value="all"> All </option>
              <option [value]="i" *ngFor="let value of dropdown.status; let i = index">{{ value }}</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
  <div class="dropdown dropdown-inline">
    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
  </div>
</div>
<div class="kt-section">
  <div class="kt-section__content">
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped table-sortable">
        <thead>
          <tr>
            <th>Code</th>
            <th>Vendor Name</th>
            <th>Supported Region</th>
            <th>SMS Type</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody *ngIf="(permissions$ | async) as rows">
          <tr *ngFor="let row of rows">
            <td>{{ row.code }}</td>
            <td>{{ row.name }}</td>
            <td>{{ row.region === null ? '-' : row.region }}</td>
            <td>{{ row.sms_type }}</td>
            <td class="text-center">
              <div class="btn-group dropdown">
                <button type="button" class="btn btn-pill btn-sm dropdown-base" [ngClass]="'kt-badge--' + row.status">{{ status[row.status] }}</button>
                <ng-container *ngIf="canEditVendor || canUpdateSMSProviderStatus">
                  <button type="button" class="btn btn-pill btn-sm dropdown-toggle" [ngClass]="'kt-badge--' + row.status" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fa fa-angle-down"></i>
                  </button>
                  <div class="dropdown-menu">
                    <ng-container *ngFor="let value of dropdown.status; let i = index">
                      <button class="dropdown-item" type="button" (click)="onChangeStatus(i, row)" *ngIf="+i >= 0">{{ value }}</button>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </td>
            <td>
              <button *ngIf="canEditVendor" title="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)"><i class="fas fa-edit"></i></button>
            </td>
          </tr>
        </tbody>
        <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
      </table>
    </div>
    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
      <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
      </ngb-pagination>
      <div class="kt-pagination__toolbar">
        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
          <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
        </select>
        <span class="pagination__desc">
          Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
        </span>
      </div>
    </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>