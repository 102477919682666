<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog d-block">
  <form class="kt-form" [formGroup]="form">
    <div class="modal-content">
      <!-- Header -->
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'create' ? 'Create' : 'Edit'}} Events</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <!-- Body -->
      <div class="modal-body">
        <div class="row">
          <!-- Basic Info -->
          <section class="col-md-12 card border-0 form-group">
            <div class="col-12 card rounded-0 p-0">
              <div class="card-header">
                Basic Info
              </div>
              <section class="col-12 px-4 pt-4">
                <!-- Name -->
                <div class="form-group">
                  <label>Name: <span class="text-danger">*</span></label>
                  <input type="text" formControlName="name" class="col-12 form-control">
                </div>
                <!-- Code -->
                <div class="form-group">
                  <label>Code: <span class="text-danger">*</span></label>
                  <input
                    type="text"
                    formControlName="code"
                    class="col-12 form-control"
                    [ngClass]="{ 'border-danger': form.controls['code'].hasError('pattern') }"
                  >
                  <mat-error *ngIf="form.controls['code'].hasError('pattern')">
                    <strong>Code must not contain any spaces</strong>
                  </mat-error>
                </div>
                <!-- Prediction Type -->
                <div class="form-group">
                  <label>Prediction Type: <span class="text-danger">*</span></label>
                  <select class="form-control" *ngIf="dropdown.predictionType" formControlName="prediction_type" (change)="onChangePredictionType($event.target.value)">
                    <option *ngFor="let value of dropdown.predictionType | async" [value]="value.id">{{ value.name }}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Group Type: <span class="text-danger">*</span></label>
                  <select class="form-control" *ngIf="dropdown.eventGroupTypes" formControlName="group_type" (change)="onChangeGroupType($event.target.value)">
                    <option *ngFor="let value of dropdown.eventGroupTypes | async" [value]="value.id">{{ value.name }}</option>
                  </select>
                </div>
                <div class="form-group" *ngIf="!predictionTypeIs1">
                  <label>Winning Score: <span class="text-danger">*</span></label>
                  <input type="number" formControlName="winning_score" class="col-12 form-control">
                </div>
                <!-- Prediction Type -->
                <div class="form-group" *ngIf="predictionTypeIs1">
                  <label>Number of Teams: <span class="text-danger">*</span></label>
                  <input type="number" formControlName="number_of_teams" class="col-12 form-control">
                </div>
                <!-- Status -->
                <div class="form-group">
                  <label>Status: <span class="text-danger">*</span></label>
                  <select class="form-control" *ngIf="dropdown.statuses" formControlName="status">
                    <option *ngFor="let value of dropdown.statuses; let i = index" [value]="i">{{ value }}</option>
                  </select>
                </div>
              </section>
            </div>
          </section>
          <!-- Publish Date -->
          <section class="col-md-12 card border-0 form-group">
            <div class="col-12 card rounded-0 p-0">
              <div class="card-header">
                Publish Date
              </div>
              <section class="col-12 px-4 pt-4">
                <div class="row">
                  <!-- Start Date -->
                  <div class="col-6 form-group">
                    <label>Start: <span class="text-danger">*</span></label>
                    <div class="input-group date">
                      <input class="hidden" [owlDateTime]="publishDateStartRef" [ngModel]="dateTimeStack?.publishDateStart" [ngModelOptions]="{standalone: true}">
                      <input type="text" placeholder="Search" class="form-control" formControlName="publish_date_start">
                      <span class="input-group-append" [owlDateTimeTrigger]="publishDateStartRef">
                        <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                      </span>
                      <owl-date-time #publishDateStartRef></owl-date-time>
                    </div>
                  </div>
                  <!-- End Date -->
                  <div class="col-6 form-group">
                    <label>End: <span class="text-danger">*</span></label>
                    <div class="input-group date">
                      <input class="hidden" [owlDateTime]="publishDateEndRef" [ngModel]="dateTimeStack?.publishDateEnd" [ngModelOptions]="{standalone: true}">
                      <input type="text" placeholder="Search" class="form-control" formControlName="publish_date_end">
                      <span class="input-group-append" [owlDateTimeTrigger]="publishDateEndRef">
                        <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                      </span>
                      <owl-date-time #publishDateEndRef></owl-date-time>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          <!-- Prediction Date -->
          <section class="col-md-12 card border-0 form-group">
            <div class="col-12 card rounded-0 p-0">
              <div class="card-header">
                Prediction Date
              </div>
              <section class="col-12 px-4 pt-4">
                <div class="row">
                  <!-- Start Date -->
                  <div class="col-6 form-group">
                    <label>Start: <span class="text-danger">*</span></label>
                    <div class="input-group date">
                      <input class="hidden" [owlDateTime]="predictionDateStartRef" [ngModel]="dateTimeStack?.predictionDateStart" [ngModelOptions]="{standalone: true}">
                      <input type="text" placeholder="Search" class="form-control" formControlName="prediction_date_start">
                      <span class="input-group-append" [owlDateTimeTrigger]="predictionDateStartRef">
                        <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                      </span>
                      <owl-date-time #predictionDateStartRef></owl-date-time>
                    </div>
                  </div>
                  <!-- End Date -->
                  <div class="col-6 form-group">
                    <label>End: <span class="text-danger">*</span></label>
                    <div class="input-group date">
                      <input class="hidden" [owlDateTime]="predictionDateEndRef" [ngModel]="dateTimeStack?.predictionDateEnd" [ngModelOptions]="{standalone: true}">
                      <input type="text" placeholder="Search" class="form-control" formControlName="prediction_date_end">
                      <span class="input-group-append" [owlDateTimeTrigger]="predictionDateEndRef">
                        <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                      </span>
                      <owl-date-time #predictionDateEndRef></owl-date-time>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          <!-- Grand Final Date -->
          <section class="col-md-12 card border-0 form-group">
            <div class="col-12 card rounded-0 p-0">
              <div class="card-header">
                Grand Final Date
              </div>
              <section class="col-12 px-4 pt-4">
                <div class="row">
                  <div class="col-6 form-group">
                    <label>Date: <span class="text-danger">*</span></label>
                    <div class="input-group date">
                      <input class="hidden" [owlDateTime]="grandFinalDateRef" [ngModel]="dateTimeStack?.grandFinalDate" [ngModelOptions]="{standalone: true}">
                      <input type="text" placeholder="Search" class="form-control" formControlName="grand_final">
                      <span class="input-group-append">
                        <span class="input-group-text pointer-calendar"  [owlDateTimeTrigger]="grandFinalDateRef"><i class="far fa-calendar-alt"></i></span>
                      </span>
                      <owl-date-time #grandFinalDateRef ></owl-date-time>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          <!-- Ticket Amount -->
          <section class="col-md-12 card border-0 form-group">
            <div class="col-12 card rounded-0 p-0">
              <div class="card-header">
                Ticket Amount
              </div>
              <section class="col-12 px-4 pt-4" formGroupName="event_setting_ticket_amount">
                <div class="row">
                  <div class="col-4 form-group" *ngFor="let currency of dropdown.currencies; let i = index" [formGroupName]="i">
                    <label>{{ currency.name }}: <span class="text-danger">*</span></label>
                    <div class="input-group date">
                      <input type="number" (wheel)="false" formControlName="amount_per_ticket" class="form-control" min="0" step="0.01" [ngClass]="{'is-invalid': form.get('event_setting_ticket_amount.'+i+'.amount_per_ticket').errors?.pattern}">
                      <ng-container *ngIf="form.get('event_setting_ticket_amount.'+i+'.amount_per_ticket').errors?.pattern">
                        <div class="col-12 text-danger">only allowed max 2 decimal places</div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          <!-- Prize Pool -->
          <section class="col-md-12 card border-0 form-group">
            <div class="col-12 card rounded-0 p-0">
              <div class="card-header">
                Prize Pool
              </div>
              <section class="col-12 px-4 pt-4">
                <div class="row">
                  <div class="col-6 form-group">
                    <label>Seed Amount: <span class="text-danger">*</span></label>
                    <div class="input-group date">
                      <input type="number" (wheel)="false" formControlName="seed_amount" class="form-control">
                    </div>
                  </div>
                  <div class="col-6 form-group">
                    <label>Amount Contribution Per Prediction: <span class="text-danger">*</span></label>
                    <div class="input-group date">
                      <input type="number" (wheel)="false" formControlName="amount_contribution" class="form-control">
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          <!-- Message Template -->
          <section class="col-md-12 card border-0 form-group">
            <div class="col-12 card rounded-0 p-0">
              <div class="card-header">
                Message
              </div>
              <section class="col-12 px-4 pt-4">
                <div class="table-responsive mb-2">
                  <table class="table table-bordered thead-dark">
                    <thead class="table-header-bg">
                      <tr>
                        <th class="text-center">Eligible for Prediction</th>
                        <th class="text-center">Not Eligible for Prediction</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="p-4">
                          <label>SMS: </label>
                          <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'smsTemplateList'
                            [dropdownSettings] = 'messageTemplatesSettings'
                            [formName] = "'message_template_sms_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] ='smsTemplateSelectedItem'>
                          </kt-dropdown-wo-lazyload>
                          <label class="mt-4">Message: </label>
                          <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'messageTemplateList'
                            [dropdownSettings] = 'messageTemplatesSettings'
                            [formName] = "'message_template_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] ='messageTemplateSelectedItem'>
                          </kt-dropdown-wo-lazyload>
                        </td>
                        <td class="p-4">
                          <label>SMS: </label>
                          <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'smsTemplateList'
                            [dropdownSettings] = 'messageTemplatesSettings'
                            [formName] = "'non_eligible_message_template_sms_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] ='nonEligibleSmsTemplateSelectedItem'>
                          </kt-dropdown-wo-lazyload>
                          <label class="mt-4">Message: </label>
                          <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'messageTemplateList'
                            [dropdownSettings] = 'messageTemplatesSettings'
                            [formName] = "'non_eligible_message_template_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] ='nonEligibleMessageTemplateSelectedItem'>
                          </kt-dropdown-wo-lazyload>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
      <!-- Footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button *ngIf="canEditEvent" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<!-- Swal Alert -->
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog()"></kt-swal-alert>
