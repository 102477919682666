import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kt-promotion-application-wrapper',
  templateUrl: './promotion-application-wrapper.component.html',
  styleUrls: ['./promotion-application-wrapper.component.scss'],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: { } }
  ]
})
export class PromotionApplicationWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
