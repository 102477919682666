import { EventEmitterService } from '@core/services/event-emitter.service';
import { LuckyDrawSettingsDataService } from './../services/lucky-draw-settings-data.service';
import { RewardsSettingsDialogComponent } from './rewards-settings-edit/rewards-settings-edit.component';
import { map, tap, catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { Subscription, forkJoin } from 'rxjs';
import { LuckyDrawStatus } from '@core/enums/lucky-draw-status.enum';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, AfterViewInit, ChangeDetectorRef, QueryList, ViewChildren, OnDestroy, Inject } from '@angular/core';
import { LuckyDrawSettings } from '@core/models/lucky-draw-settings.model';
import * as moment from 'moment-timezone';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-lucky-draw-edit',
  templateUrl: './lucky-draw-edit.component.html',
  styleUrls: ['./lucky-draw-edit.component.scss']
})
export class LuckyDrawDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;
  form: FormGroup;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies'))
  };
  dateTimeStack = {
    start_datetime: null,
    end_datetime: null,
    publish_start: null,
    publish_end: null
  };
  messages$ = this.luckyDrawSettingsDataService.messages$;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  dateTimeFormat = 'yyyy-MM-dd HH:mm:';
  status = LuckyDrawStatus;
  refreshStatus: boolean;
  buttonLoading = false;
  rewardSettings = [];

  // permissions
  canCreateLuckyDrawSettings: boolean;
  canEditLuckyDrawSettings: boolean;
  canCreateRewardsSettings: boolean;
  canEditRewardsSettings: boolean;
  canRemoveRewardsSettings: boolean;


  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, luckyDrawSetting: any},
    private datePipe: DatePipe,
    private dropdownHttpService: DropdownHttpService,
    public dialogRef: MatDialogRef<LuckyDrawDialogComponent>,
    private cdr: ChangeDetectorRef,
    private luckyDrawSettingsDataService: LuckyDrawSettingsDataService,
    private eventEmitterService: EventEmitterService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
      });
    }
    this.formInit();

    this.eventEmitterService.updateRewardSettingsVar = this.eventEmitterService.updateRewardSettings.subscribe((res: any) => {
      const data = {
        ...res
      };
      Object.keys(data).forEach((key) => (data[key] == null || key === 'actionType' || key === 'index') && delete data[key]);
      if (res.actionType === 'create'){
        this.rewardSettings.push(data);
      } else {
        this.rewardSettings.splice(res.index, 1, data);
      }
    });

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateLuckyDrawSettings = appPermissions.create_lucky_draw_settings;
      this.canEditLuckyDrawSettings = appPermissions.edit_lucky_draw_settings;
      this.canCreateRewardsSettings = appPermissions.create_rewards_settings;
      this.canEditRewardsSettings = appPermissions.edit_rewards_settings;
      this.canRemoveRewardsSettings = appPermissions.remove_rewards_settings;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.onRefresh();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'start_datetime'),
      this.buildDatePicker(1, 'end_datetime'),
      this.buildDatePicker(2, 'publish_start'),
      this.buildDatePicker(3, 'publish_end')
    ]).subscribe();
  }

  onRemoveSetting(index: number){
    this.rewardSettings.splice(index, 1);
  }

  onSave(luckyDrawSetting: LuckyDrawSettings, mode?: string) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    const data = {
      id: luckyDrawSetting ? luckyDrawSetting.id : null,
      ...this.form.value,
      start_datetime: moment(this.form.value.start_datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_datetime: moment(this.form.value.end_datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      publish_start: moment(this.form.value.publish_start).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      publish_end: moment(this.form.value.publish_end).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')
    };
    Object.keys(data).forEach((key) => (data[key] == null) && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.luckyDrawSettingsDataService.updateLuckyDraw(data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.luckyDrawSettingsDataService.add(data).pipe(
            tap((res: any) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              this.messages$.next([...res.message]);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              throw error;
            })
          )
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onOpenDialog(mode: string, rewards?: any, ind?: number) {
    if (mode === 'create')  {
      this.openDialogBy(RewardsSettingsDialogComponent, { mode: 'create' });
    } else if (mode === 'edit'){
       this.openDialogBy(RewardsSettingsDialogComponent, { mode: 'edit', rewardSetting: rewards, index: ind });
    }
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, rewardSetting?: any, index?: number}) {
    this.dialog.open(componentRef, {
      width: '800px',
      data: {
        mode: data.mode,
        rewardSetting: data.rewardSetting,
        index: data.index
      }
    });
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, index === 0 || index === 2 ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      })
    );
  }

  private formInit() {
    let status = 0;
    let name = null;
    let currencyId = null;
    let entryAmount = null;
    let maxEntry = null;
    let startDate = null;
    let endDate = null;
    let publishStart = null;
    let publishEnd = null;
    if (this.data.mode === 'edit'){
      this.dateTimeStack = {
        start_datetime: new Date(new Date(this.data.luckyDrawSetting.start_datetime).getTime() + this.offset - this.clientOffset),
        end_datetime: new Date(new Date(this.data.luckyDrawSetting.end_datetime).getTime() + this.offset - this.clientOffset),
        publish_start: new Date(new Date(this.data.luckyDrawSetting.publish_start).getTime() + this.offset - this.clientOffset),
        publish_end: new Date(new Date(this.data.luckyDrawSetting.publish_end).getTime() + this.offset - this.clientOffset),
      };
      status = this.data.luckyDrawSetting.status;
      name = this.data.luckyDrawSetting.name;
      currencyId = this.data.luckyDrawSetting.currency_id;
      entryAmount = this.data.luckyDrawSetting.entry_amount;
      maxEntry = this.data.luckyDrawSetting.max_amount_per_entry;
      startDate = moment(new Date(new Date(this.data.luckyDrawSetting.start_datetime).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss');
      endDate = moment(new Date(new Date(this.data.luckyDrawSetting.end_datetime).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss');
      publishStart = moment(new Date(new Date(this.data.luckyDrawSetting.publish_start).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss');
      publishEnd = moment(new Date(new Date(this.data.luckyDrawSetting.publish_end).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss');
      this.rewardSettings = this.data.luckyDrawSetting.rewards;
    }
    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      currency_id: new FormControl({value: currencyId, disabled: this.data.mode === 'edit' ? true : false}, [Validators.required]),
      entry_amount: new FormControl(entryAmount, [Validators.required]),
      max_amount_per_entry: new FormControl(maxEntry, [Validators.required]),
      start_datetime: new FormControl(startDate, [Validators.required]),
      end_datetime: new FormControl(endDate, [Validators.required]),
      publish_start: new FormControl(publishStart, [Validators.required]),
      publish_end: new FormControl(publishEnd, [Validators.required]),
      status: new FormControl(status, [Validators.required]),
      reward_settings: new FormControl(this.rewardSettings)
    });
  }

}
