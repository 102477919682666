import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

/**
 * Returns only first letter of string
 */
@Pipe({
  name: 'timezoneDate'
})
export class TimezoneDatePipe implements PipeTransform {
  transform(value: any, format?: any): any {
    const timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    const clientOffset = moment().utcOffset() * 60 * 1000;
    const offset = moment.tz(timezone).utcOffset() * 60 * 1000;

    return value ? moment(new Date(new Date(value).getTime() + offset - clientOffset)).format(format ? format : 'YYYY-MM-DD HH:mm:ss') : null;
  }

  transformWithTimezone(value: any, timzone: any, format?: any): any {
    const timezone = timzone;
    const clientOffset = moment().utcOffset() * 60 * 1000;
    const offset = moment.tz(timezone).utcOffset() * 60 * 1000;

    return value ? moment(new Date(new Date(value).getTime() + offset - clientOffset)).format(format ? format : 'YYYY-MM-DD HH:mm:ss') : null;
  }
}