import { MemberTrace } from '@core/models/member-trace.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class MemberTraceEntityService extends EntityCollectionServiceBase<MemberTrace> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('MemberTrace', serviceElementsFactory);
  }
}
