import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MerchantBankStatus } from '@core/enums/merchant-bank.enum';
import { Group } from '@core/models/group.model';
import { MerchantBank } from '@core/models/merchant-bank.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { UploadHttpService } from "@core/services/upload-http.service";
import { Lightbox } from "ngx-lightbox";
import { forkJoin, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MerchantBankDataService } from '../../services/merchant-bank-data.service';
import { MerchantBankEntityService } from '../../services/merchant-bank-entity.service';

@Component({
  templateUrl: "./merchant-bank-edit.component.html",
  styleUrls: ["./merchant-bank-edit.component.scss"],
})
export class MerchantBankEditDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    merchantBanks: [],
    bankPurposes: this.dropdownHttpService.bankPurposes,
    groups: this.dropdownHttpService.groups,
    types: this.dropdownHttpService.merchantBanksType,
    methods: [{ id: 1, name: 'Bank Transfer' }, { id: 2, name: 'Payment Gateways' }, { id: 3, name: 'Ewallet' }],
    paymentGateway: []
  };
  messages$ = this.merchantBankDataService.messages$;
  isSelectedCurrency = false;
  errors: any = [];
  minMaxValid = true;
  buttonLoading = false;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  refreshStatus: boolean;

  selectedCurrencyCode = "";
  selectedBankCode = "";
  selectedBankType: number;
  selectedPurpose: number;

  memberGroupDropdownList = [];
  memberGroupDropdownSettings = {
    text: "Please Select",
    enableSearchFilter: true,
    singleSelection: false,
    classes: "dropdown",
    primaryKey: "id",
    labelKey: "name",
    noDataLabel: "",
    showCheckbox: false,
  };
  paymentGatewaysDropdownSettings = {
    text: "Please Select",
    enableSearchFilter: true,
    singleSelection: false,
    classes: "dropdown",
    primaryKey: "id",
    labelKey: "name",
    noDataLabel: "",
    showCheckbox: false,
    disabled: false
  };
  memberGroupSelectedItems = [];
  paymentGatewaysSelectedItems = [];
  object = Object;
  status = MerchantBankStatus;

  imagePreview = [];
  isImageUploading = false;

  showProcessingFeeDep = false;
  showProcessingFeeWith = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bank: MerchantBank; mode: string },
    public dialogRef: MatDialogRef<MerchantBankEditDialogComponent>,
    private merchantBankService: MerchantBankEntityService,
    private merchantBankDataService: MerchantBankDataService,
    private merchantBankHttpService: MerchantBankHttpService,
    private dropdownHttpService: DropdownHttpService,
    private uploadService: UploadHttpService,
    private lightbox: Lightbox,
    private authHttpService: AuthHttpService,
  ) { }

  ngOnInit() {
    if (this.dropdown.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe(res => {
        this.dropdown.currencies = res;
      });
    }

    this.formInit();
    if (this.data.mode === "edit") {
      this.onSelectCurrency(this.data.bank.settings_currency_id);
      this.selectedBankType = this.data.bank.bank_type;
    }
    this.dropdown.groups.subscribe((res) => {
      const data = [];
      res.forEach((row) => {
        data.push({
          id: row.id,
          code: row.code,
          name: row.name,
        });
      });
      this.memberGroupDropdownList = data;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(merchantBank: MerchantBank, mode?: string) {
    this.buttonLoading = true;
    const data = {
      id: merchantBank ? merchantBank.id : null,
      ...this.form.value,
      sync: this.form.value.sync ? 1 : 0,
      business: this.form.value.business ? 1 : 0,
      member_groups:
        this.form.value.member_groups !== null &&
          this.form.value.member_groups[0]?.id !== undefined
          ? this.form.value.member_groups.map((row: Group) => row.id)
          : this.form.value.member_groups,
      no_bonus: this.form.value.no_bonus === 0 || this.form.value.no_bonus === false || this.form.value.no_bonus === null ? 0 : 1,
    };
    Object.keys(data).forEach(
      (key) => (data[key] == null || data[key] === "" || data[key].length === 0) && delete data[key]
    );
    const alertMessage = `<p>Please verify the information before continuing as you are not allowed to update these information (Currency, Bank, Account Number) after.</p>`;
    const alertConfig: any = {
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      reverseButtons: true,
    };

    switch (mode) {
      case "edit":
        this.subscription = this.merchantBankService
          .update(data)
          .pipe(
            tap((res: any) => {
              this.messages$.next([...res.message]);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          )
          .subscribe();
        break;
      case "create":
        Swal.fire({
          title: "New Merchant Bank Account",
          html:
            `<p>${this.selectedCurrencyCode} - ${this.selectedBankCode} - ${this.form.value.account_name} ${this.selectedBankType == 1 ? ' - ' + this.form.value.account_number : ""} </p>` +
            alertMessage,
          ...alertConfig,
        }).then((result) => {
          if (result.value === true) {
            this.subscription = forkJoin([
              this.merchantBankDataService.add(data).pipe(
                tap((res: any) => {
                  this.form.setErrors(null);
                  this.buttonLoading = false;
                }),
                catchError((error) => {
                  this.buttonLoading = false;
                  this.form.setErrors(null);
                  throw error;
                })
              ),
              this.merchantBankDataService.messages$,
            ]).subscribe();
          } else {
            this.buttonLoading = false;
          }
        });

        break;
    }
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onSelectCurrency(id: number) {
    const currenyId = id;
    if (this.data.mode === "create") {
      this.selectedCurrencyCode = this.dropdown.currencies.filter(
        (row) => row.id === +id
      )[0].name;
      this.form.patchValue({ bank_id: null, bank_type: null });
    } else {
      this.form.patchValue({
        bank_id:
          Number(currenyId) === this.data.bank.settings_currency_id
            ? this.data.bank.bank.id
            : null,
      });
    }
    this.setBankOrPaymentGateWay();
    this.isSelectedCurrency = true;
  }

  onSelectBank() {
    let bank = this.dropdown.merchantBanks.filter(
      (row) => row.id === +this.form.value.bank_id
    )[0];
    this.selectedBankCode = bank.code;
    this.selectedBankType = bank.bank_type;
    this.form.patchValue({
      bank_type: this.selectedBankType,
    });
  }

  onSelectMethod() {
    this.form.patchValue({
      payment_gateways: [],
      member_groups: [],
      bank_id: null,
      bank_type: null
    });
    this.memberGroupSelectedItems = [];
    this.paymentGatewaysSelectedItems = [];
    this.paymentGatewaysDropdownSettings = {
      ...this.paymentGatewaysDropdownSettings,
      disabled: +this.form.value.method !== 2
    };
    this.selectedBankType = this.form.value.method;
    this.updateValidatorPaymentGateWay();
    this.form.get('payment_gateways').updateValueAndValidity();
    this.setBankOrPaymentGateWay();
  }

  onUploadFile(event: any) {
    this.isImageUploading = true;
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append("files", file, file.name);
    formData.append("type", "bank");
    this.uploadService.upload(formData).subscribe((res: any) => {
      this.imagePreview = res;
      this.form.patchValue({
        qr_image: this.imagePreview[0],
      });
      this.form.get("qr_image").markAsDirty();
      this.form.markAllAsTouched();
      this.isImageUploading = false;
    });
  }

  openImage(label: string, path: string) {
    let imageList = [];
    imageList.push({
      src: path,
      caption: label,
      thumb: path,
    });

    this.lightbox.open(imageList, 0, {
      centerVertically: true,
      disableScrolling: false,
      fitImageInViewPort: true,
      fadeDuration: 0.25,
      resizeDuration: 0.25,
    });
  }

  onPurpose(event: Event) {
    this.selectedPurpose = +(event.target as HTMLSelectElement).value;
    this.form.patchValue({
      daily_max_deposit: null,
      daily_max_deposit_count: null,
      daily_max_withdrawal: null,
      daily_max_withdrawal_count: null,
    });
    // if (this.form.value.purpose === "1") {
    //   this.form.patchValue({
    //     sync: 1,
    //   });
    // } else {
    //   this.form.patchValue({
    //     sync: 0,
    //   });
    // }
    this.showProcessingFeeWith = this.selectedPurpose !== 1 ? true : false;
    this.showProcessingFeeDep = this.selectedPurpose !== 2 ? true : false;
    this.form.patchValue({
      processing_fee_percent_dep: null,
      processing_fee_percent_with: null,
    });
  }

  onCheckBalance() {
    this.minMaxValid =
      this.form.value.min_balance < this.form.value.max_balance ? true : false;
  }

  changeBankTypeValidators() {
    this.form.patchValue({
      qr_image: null,
      username: null,
      password: null,
      business: null,
      sync: null
    });

    if (+this.form.value.method === 2) {
      // this.form.controls.qr_image.clearValidators();
      this.form.controls.account_number.setValidators(Validators.required);
      this.form.controls.username.clearValidators();
      this.form.controls.password.clearValidators();
      // this.form.get('qr_image').updateValueAndValidity();
      this.form.get('account_number').updateValueAndValidity();
      this.form.get('username').updateValueAndValidity();
      this.form.get('password').updateValueAndValidity();
    } else {
      if (this.form.get("bank_type").value == 3) {
        // this.form.controls.qr_image.setValidators(Validators.required);
        this.form.controls.account_number.setValidators(Validators.required);
        this.form.get('account_number').updateValueAndValidity();
        this.form.controls.username.clearValidators();
        this.form.controls.password.clearValidators();
      } else {
        // this.form.controls.qr_image.clearValidators();
        this.form.controls.account_number.setValidators(Validators.required);
        this.form.controls.username.setValidators(Validators.required);
        this.form.controls.password.setValidators(
          Validators.compose(
            this.data.mode === "create" ? [Validators.required] : null
          )
        );
      }
      this.updateFormValidity();
    }
  }

  changeValidators() {
    this.memberGroupSelectedItems = [];
    this.form.patchValue({
      member_groups: []
    });
    //deposit
    if (this.form.get("purpose").value == "1") {
      this.form.controls.daily_max_deposit.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.form.controls.daily_max_deposit_count.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.form.controls.min_deposit_per_transaction.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.form.controls.max_deposit_per_transaction.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.form.controls.daily_max_withdrawal.clearValidators();
      this.form.controls.daily_max_withdrawal_count.clearValidators();
      this.form.controls.min_withdrawal_per_transaction.clearValidators();
      this.form.controls.max_withdrawal_per_transaction.clearValidators();
    }
    //withdrawal
    else if (this.form.get("purpose").value == "2") {
      this.form.controls.daily_max_deposit.clearValidators();
      this.form.controls.daily_max_deposit_count.clearValidators();
      this.form.controls.min_deposit_per_transaction.clearValidators();
      this.form.controls.max_deposit_per_transaction.clearValidators();
      this.form.controls.daily_max_withdrawal.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.form.controls.daily_max_withdrawal_count.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.form.controls.min_withdrawal_per_transaction.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.form.controls.max_withdrawal_per_transaction.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
    }
    //deposit and withdrawal
    else if (this.form.get("purpose").value == "5") {
      this.form.controls.daily_max_deposit.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.form.controls.daily_max_deposit_count.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.form.controls.daily_max_withdrawal.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.form.controls.daily_max_withdrawal_count.setValidators([
        Validators.required,
        Validators.min(0),
      ]);
    }
    // other purposes
    else {
      this.form.controls.daily_max_deposit.clearValidators();
      this.form.controls.daily_max_deposit_count.clearValidators();
      this.form.controls.daily_max_withdrawal.clearValidators();
      this.form.controls.daily_max_withdrawal_count.clearValidators();
    }

    if (+this.form.value.purpose === 1 || +this.form.value.purpose === 5) {
      this.form.controls.member_groups.setValidators([Validators.required]);
    } else {
      this.form.controls.member_groups.clearValidators();
    }
    this.form.get('member_groups').updateValueAndValidity();
    this.updateFormValidity();
  }

  private updateValidatorPaymentGateWay() {
    if (+this.form.value.method === 2) {
      this.form.controls.payment_gateways.setValidators([
        Validators.required
      ]);
    } else {
      this.form.controls.payment_gateways.clearValidators();
    }
    this.form.get('payment_gateways').updateValueAndValidity();
  }

  private formInit() {
    const amountValidation = Validators.min(0);

    let currencyId = null;
    let bankType = null;
    let accountName = null;
    let accountNumber = null;
    let qrImage = null;
    let maxBalance = null;
    let minBalance = null;
    let purpose = null;
    let position = 99;
    let username = null;
    let password = null;
    let status = 1;
    let sync = 0;
    let score = null;
    let dailyMaxDeposit = null;
    let dailyMaxDepositCount = null;
    let dailyMaxTransaction = null;
    let dailyMaxWithdrawal = null;
    let dailyMaxWithdrawalCount = null;
    let minDepositPerTransaction = null;
    let maxDepositPerTransaction = null;
    let minWithdrawalPerTransaction = null;
    let maxWithdrawalPerTransaction = null;
    let business = 0;
    let memberGroups = this.memberGroupDropdownList;
    let type = null;
    let noBonus = null;
    let method = null;
    let paymentGateways = [];
    let depositProcessingFee = null;
    let withdrawalProcessingFee = null;

    if (this.data.mode === "edit") {
      currencyId = this.data.bank.settings_currency_id;
      bankType = this.data.bank.bank_type;
      accountName = this.data.bank.account_name;
      accountNumber = this.data.bank.account_number;
      qrImage = this.data.bank.qr_image;
      maxBalance = this.data.bank.max_balance;
      minBalance = this.data.bank.min_balance;
      position = this.data.bank.position;
      purpose = this.data.bank.purpose;
      username = this.data.bank.username;
      password = this.data.bank.password;
      sync = this.data.bank.sync;
      business = this.data.bank.business > 0 ? 1 : 0;
      status = this.data.bank.status;
      score = this.data.bank.score;
      dailyMaxDeposit = this.data.bank.daily_max_deposit;
      dailyMaxDepositCount = this.data.bank.daily_max_deposit_count;
      dailyMaxTransaction = this.data.bank.daily_max_transaction;
      dailyMaxWithdrawal = this.data.bank.daily_max_withdrawal;
      dailyMaxWithdrawalCount = this.data.bank.daily_max_withdrawal_count;
      minDepositPerTransaction = this.data.bank.min_deposit_per_transaction;
      maxDepositPerTransaction = this.data.bank.max_deposit_per_transaction
      minWithdrawalPerTransaction = this.data.bank.min_withdrawal_per_transaction
      maxWithdrawalPerTransaction = this.data.bank.max_withdrawal_per_transaction
      type = this.data.bank.type;
      noBonus = this.data.bank.no_bonus;
      method = this.data.bank.method;
      depositProcessingFee = this.data.bank.processing_fee_percent_dep;
      withdrawalProcessingFee = this.data.bank.processing_fee_percent_with;

      if (this.data.bank.payment_gateways.length > 0) {
        this.data.bank.payment_gateways.map((res: any) => {
          this.paymentGatewaysSelectedItems.push(res);
          paymentGateways.push(res.id)
        });
      }

      this.memberGroupSelectedItems =
        this.data.bank.member_groups.length >= 1
          ? [...this.memberGroupSelectedItems, ...this.data.bank.member_groups]
          : null;
      memberGroups = this.memberGroupSelectedItems;

      this.showProcessingFeeWith = +(this.data.bank.purpose) !== 1 ? true : false;
      this.showProcessingFeeDep = +(this.data.bank.purpose) !== 2 ? true : false;

    }
    this.form = new FormGroup({
      currency_id: new FormControl(currencyId, [Validators.required]),
      bank_id: new FormControl(null, [Validators.required]),
      bank_type: new FormControl(bankType, [Validators.required]),
      account_name: new FormControl(accountName, [Validators.required]),
      account_number: new FormControl(
        accountNumber,
        Validators.compose(bankType === 1 ? [Validators.required] : null)
      ),
      qr_image: new FormControl(qrImage, []),
      max_balance: new FormControl(maxBalance, [
        Validators.required,
        amountValidation,
      ]),
      min_balance: new FormControl(minBalance, [
        Validators.required,
        amountValidation,
      ]),
      position: new FormControl(position, [Validators.required]),
      purpose: new FormControl(purpose, [Validators.required]),
      username: new FormControl(
        username,
        Validators.compose(bankType === 1 ? [Validators.required] : null)
      ),
      status: new FormControl(status),
      daily_max_deposit: new FormControl(dailyMaxDeposit),
      daily_max_deposit_count: new FormControl(dailyMaxDepositCount),
      //daily_max_transaction: new FormControl(dailyMaxTransaction, [Validators.required, amountValidation]),
      daily_max_withdrawal: new FormControl(dailyMaxWithdrawal),
      daily_max_withdrawal_count: new FormControl(dailyMaxWithdrawalCount),
      min_deposit_per_transaction: new FormControl(
        minDepositPerTransaction,
        Validators.compose(purpose == 2 ? null : [Validators.required, amountValidation])
      ),
      max_deposit_per_transaction: new FormControl(
        maxDepositPerTransaction,
        Validators.compose(purpose == 2 ? null : [Validators.required, amountValidation])
      ),
      min_withdrawal_per_transaction: new FormControl(
        minWithdrawalPerTransaction,
        Validators.compose(purpose == 1 ? null : [Validators.required, amountValidation])
      ),
      max_withdrawal_per_transaction: new FormControl(
        maxWithdrawalPerTransaction,
        Validators.compose(purpose == 1 ? null : [Validators.required, amountValidation])
      ),
      password: new FormControl(
        password,
        Validators.compose(
          this.data.mode === "create" ? [Validators.required] : null
        )
      ),
      score: new FormControl(score, [Validators.required, amountValidation]),
      sync: new FormControl(sync),
      business: new FormControl(business),
      member_groups: new FormControl(memberGroups),
      type: new FormControl(type),
      no_bonus: new FormControl(noBonus),
      method: new FormControl(method),
      payment_gateways: new FormControl(paymentGateways),
      processing_fee_percent_dep: new FormControl(depositProcessingFee),
      processing_fee_percent_with: new FormControl(withdrawalProcessingFee)
    });

    this.form.get("bank_type").valueChanges.subscribe((data) => {
      this.changeBankTypeValidators();
    });

    this.form.get("purpose").valueChanges.subscribe((data) => {
      this.changeValidators();
    });

    this.form.get("payment_gateways").valueChanges.subscribe((data) => {
      this.updateValidatorPaymentGateWay();
    });

    this.paymentGatewaysDropdownSettings = {
      ...this.paymentGatewaysDropdownSettings,
      disabled: +this.form.value.method !== 2
    };
  }

  private updateFormValidity() {
    Object.keys(this.form.controls).forEach((key) => {
      if (key != "bank_type" && key != "purpose")
        this.form.get(key).updateValueAndValidity();
    });
  }

  private setBankOrPaymentGateWay() {
    if (this.form.value.currency_id !== null && this.form.value.method !== null) {
      this.merchantBankHttpService.getMerchantBanks(this.form.value.currency_id, this.form.value.method).subscribe(res => {
        this.dropdown.merchantBanks = res;
        if (this.data.mode === 'edit') {
          this.form.patchValue({ bank_id: this.data.bank.bank_id });
        }
      });
    }
    if (+this.form.value.method === 2) {
      this.merchantBankHttpService.getMemberMerchantPaymentGateway(this.form.value.currency_id).subscribe(res => {
        this.dropdown.paymentGateway = res;
      });
    }
  }
}
