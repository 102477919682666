import { Component, OnInit, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import Swal from "sweetalert2";
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { Pagination } from '@core/models/pagination.model';
import { TranslateService } from '@ngx-translate/core';
import { MenuBarDataService } from './services/menu-bar-data.service';
import { MenuBar } from '@core/models/menu-bar.model';
import { DomSanitizer } from '@angular/platform-browser';
import { AppPermissionService } from '@core/services/app-permission.service';
@Component({
  selector: 'menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit, OnDestroy {

  @HostListener('window:beforeunload', ['$event'])
  confirmLeavingPageBeforeSaving($event) {

    if(this.checkupdate()){
      $event.preventDefault();
      $event.returnValue = true;
      return $event;
    }
  }

  form: FormGroup;
  formContent: FormGroup;

  messages$ = this.menuBarDataService.messages$;
  selectedPlatform = 1;
  loading = false;
  searchBtnLoading = false;
  clearBtnLoading = false;
  dataLength = 0;
  params = '';
  buttonLoading = true;

  mode: any;
  menuBar: any;
  initMenuBar: any;
  availableMenuBar = [];

  isEmptyContent = true;

  ranges = this.transactionHttpService.ranges;
  menuBar$: Observable<MenuBar[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  displayLocale: any;
  backupPageSize = 30;
  backupPage = 1;
  unsaveStatus = true;

  selectedSVG = null;

  viewMode = false;
  viewMenu = true;

  showSub = false ;
  subMenu;

  usableLinks = [
    'casino',
    'slots',
    'fishing',
    'promotion',
    'vip',
    'about us',
    'language',
    'affiliate'
  ];

  usableLinksMemberinfo = [
    ':member_id',
    ':member_username',
    ':currency_code'
  ];

  changedObjects: any;
  sortingConfig = {
    'id': 'desc',
    'name': 'desc',
    'position': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    svgIcons: [],
    locales: [],
    perPage: this.dropdownHttpService.perPage,
    linkTypes: this.dropdownHttpService.linkType,
  };

  // permissions
  canCreateMenuBar: boolean;
  canViewMenuBarDetails: boolean;
  canEditMenuBar: boolean;
  canUpdateMenuBarStatus: boolean;
  showUsableLink = true;
  variable = null;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();
  private localesSub = new Subscription();
  constructor(
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private menuBarDataService: MenuBarDataService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private appPermissionService: AppPermissionService,
  ) { }
  async canDeactivate(): Promise<boolean | Observable<boolean>> {
    // provide component specific logic to decide if component can or can't be deactivated
    if (this.checkupdate()) {
      const result = await Swal.fire({
        title: '<div class="text-center">Unsaved Changes</div>',
        html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Cancel'),
        confirmButtonText: this.translateService.instant('Discard'),
        icon: 'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          return (true);
        } else if (result.isDenied) {
          return (false);
        }
      });
      return result;
    }
    return true;
  }

  ngOnInit() {
    this.dropdownHttpService.svgIcons.pipe(
      tap(res=>this.dropdown.svgIcons = res)
    ).subscribe();

    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.dropdown.locales = res;
      this.formInit();
      this.onSubmit();
      this.initMenuBar = JSON.stringify({ ...this.formContent.value });
    })).subscribe();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateMenuBar = appPermissions.create_menu_bar;
      this.canViewMenuBarDetails = appPermissions.view_menu_bar_details;
      this.canEditMenuBar = appPermissions.edit_menu_bar;
      this.canUpdateMenuBarStatus = appPermissions.update_menu_bar_status;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.localesSub.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onClear() {
    this.selectedPlatform = 1;
    this.viewMenu = null;
    this.form.patchValue({
      platform: 1,
      status: 'all',
      settings_locale_id: 'all',
      name: null
    })
  }

  onClearContent() {
    this.showUsableLink = true;
    this.formContent.patchValue({
      main_menu_id: null,
      platform: this.selectedPlatform,
      link_type: 1,
      link: '/',
      svg_icon_id: null,
      after_login: 0,
      new: 0,
      sub: 0,
    })

    this.dropdown.locales.forEach((element: any) => {
      this.formContent.patchValue({
        contents: {
          [element.id]: {
            name: null,
            position: null,
          }
        }
      });
    });

    this.initMenuBar = JSON.stringify({ ...this.formContent.value });
  }

  reload(clearSearch?: boolean) {
    this.isEmptyContent = true;
    this.mode = '';
    this.showSub = false;
    this.subMenu = null;
    this.onClearContent();
    this.onSubmit(clearSearch);
    this.buttonLoading = false;
  }

  async onSubmitClick(clearSearch?: boolean) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.isEmptyContent = true;
      this.mode = '';
      this.onClearContent();
      this.showSub = false;
      this.subMenu = null;
      this.onSubmit(clearSearch);
    }
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    if (clearSearch) {
      this.onClear();
    }
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.displayLocale = this.displayLocale ? this.displayLocale : this.form.value.settings_locale_id != 'all' ? this.dropdown.locales.filter(x => x.id == this.form.value.settings_locale_id)[0] : this.dropdown.locales[0];
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.menuBar$ = this.menuBarDataService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}&sortByLocale=${this.displayLocale.id}`).pipe(
          tap(res => {
            this.page = 1;
            this.pagination = this.menuBarDataService.pagination;
            this.loadingBar.complete();
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.buttonLoading = false;
            this.dataLength = res.length;
            this.availableMenuBar = res;
            this.cdr.detectChanges();
          })
        );
      }),
    ).subscribe();
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.formContent.setErrors({ 'invalid': true });

    if (this.mode == 'createSub') {
      this.formContent.patchValue({
        main_menu_id: this.menuBar.id,
        sub: 1,
      });
    }

    const data = {
      id: this.menuBar ? this.menuBar.id : null,
      ...this.formContent.value,
    };
    Object.keys(data).forEach((key) => {
      if (data[key] == null || data[key] === '' || data[key] === 'Invalid date') {
        delete data[key];
      }
    });
    switch (this.mode) {
      case 'edit':
      case 'editSub':
        this.subscription = this.menuBarDataService.updateMenuBar(this.menuBar.id, data).pipe(
          tap((res: any) => {
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.formContent.setErrors(null);
            this.formContent.enable();
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
      case 'createSub':
        this.subscription = this.menuBarDataService.add(data).pipe(
          tap((res: any) => {
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.formContent.setErrors(null);
            this.formContent.enable();
            throw error;
          })
        ).subscribe();
        break;
    }
  }

  changeLocale(locale: any) {
    this.displayLocale = locale;
  }

  addUsableLink(linkType: any) {
    let link = null
    switch (linkType) {
      case 'language' :
        link = '#/popup/language';
        break;
      case 'external':
        link = '/external?link=';
        break;
      case 'promotion':
        link = '/promotion?code=';
        break;
      case 'about us':
        link = '/about-us';
        break;        
      case ':member_id':
        if(!this.formContent.value.link.includes('member_id=:member_id')){
          link = this.formContent.value.link+'member_id=:member_id';
        }else{
          link = this.formContent.value.link;
        }
        break;
      case ':member_username':
        if(!this.formContent.value.link.includes('member_username=:member_username')){
          link = this.formContent.value.link+'member_username=:member_username';
        }else{
          link = this.formContent.value.link;
        }
        break;
      case ':currency_code':
        if(!this.formContent.value.link.includes('currency_code=:currency_code')){
          link = this.formContent.value.link+'currency_code=:currency_code';
        }else{
          link = this.formContent.value.link;
        }
        break;
      default:
        link = '/'+ linkType;
        break;
    }

    this.formContent.patchValue({
      link: link
    });
  }

  clearContent(locale: any) {
    Swal.fire({
      title: 'Delete Content',
      text: 'Are you sure you want to delete contents for ' + locale.code + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((response) => {
      if (response.isConfirmed) {
        this.formContent.patchValue({
          contents: {
            [locale.id]: {
              name: null,
              position: null,
            }
          }
        });
      }
      this.cdr.detectChanges();
    });
  }

  async onSelectPlatform(id: any) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      if (this.selectedPlatform !== id) {
        this.selectedPlatform = id;
        this.showSub = false;
        this.subMenu = null;
        this.form.patchValue({
          platform: this.selectedPlatform,
        });
        this.formContent.patchValue({
          platform: this.selectedPlatform,
        });

        this.isEmptyContent = true;
        this.mode = '';
        this.onClearContent();
        this.onSubmit();
      }
    }
  }

  checkContent(locale: any) {
    let contents = this.formContent.value.contents[locale.id];

    if (!contents){
      return false;
    }

    if ((contents.name == null || contents.name == '') && (contents.position == null || contents.position == '')){
      return false;
    }
    else {
      return true;
    }
  }

  onChangeStatus($event, row: any) {
    const data = {
      id: row.id,
      status: $event.target.checked ? 1 : 0,
    };
    this.subscription = this.menuBarDataService.updateStatus(data).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.buttonLoading = false;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

  async onPerPage(size: Event) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.page = 1;
      this.pageSize = +(size.target as HTMLSelectElement).value;
      this.backupPageSize = this.pageSize;
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
    else {
      this.pageSize = this.backupPageSize;
    }
  }

  async onViewPageBy(page = 1, pageSize?: number, params?: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      this.backupPage = page;
      pageSize = this.pageSize;
      params = this.params ? `&${this.params}` : '';
      this.loading = true;
      this.loadingBar.start();
      return this.menuBar$ = this.menuBarDataService.getWithQuery(`?${this.params}&page=${page}&perPage=${this.pageSize}&${this.generateSortingParam()}&sortByLocale=${this.displayLocale.id}`).pipe(
        tap(res => {
          this.pagination = this.menuBarDataService.pagination;
          this.loading = false;
          this.dataLength = res.length;
          this.availableMenuBar = res;
          this.loadingBar.complete();
        })
      );
    }
    else {
      this.page = this.backupPage;
    }
  }

  updateFormContentValidator() {
    if( (this.selectedPlatform == 2) && this.mode !='createSub' && this.mode !='editSub'){
      this.formContent.get('svg_icon_id').setValidators(Validators.required);
      this.formContent.get('svg_icon_id').updateValueAndValidity();
      this.formContent.get('link').setValidators(Validators.pattern("^(\\/|#\\/popup\\/).*"));
      this.formContent.get('link').updateValueAndValidity();
    }else{
      this.formContent.get('svg_icon_id').setValidators(null);
      this.formContent.get('svg_icon_id').updateValueAndValidity();
      this.formContent.get('link').setValidators([Validators.pattern("^(\\/|#\\/popup\\/).*"),Validators.required]);
      this.formContent.get('link').updateValueAndValidity();
    }
  }

  expandRowCreate(mode: any, close?: boolean , row?:any) {
    let menuBarInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initMenuBar != menuBarInput ? true : false;
    if (close != true && this.changedObjects == false) {
      this.menuBar = null;
      this.changedObjects = false;
      this.initMenuBar = null;
      this.mode = mode;
      this.onClearContent();

      this.updateFormContentValidator();

      if (mode == 'createSub') {
        this.menuBar = row;
      }

      this.cdr.detectChanges();
    } else {
      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.menuBar = null;
            this.isEmptyContent = true;
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();
            // Check if reopen needed
            this.checkReopen(mode);
          }
        });
      }
      else {
        this.menuBar = null;
        this.isEmptyContent = true;
        this.mode = '';
        this.unsaveStatus = true;
        this.onClearContent();
      }
    }
    this.cdr.detectChanges();
  }

  expandRowEdit(mode: any, row?: any, close?: boolean) {
    if (close != true) close = this.menuBar == row ? true : false;
    let menuBarInput = JSON.stringify({ ...this.formContent.value });
    this.changedObjects = this.initMenuBar && this.initMenuBar != menuBarInput ? true : false;
    if (close != true && this.changedObjects == false ) {
      this.menuBar = row;
      this.changedObjects = false;
      this.initMenuBar = null;
      if (row.sub == 1) {
        this.mode = 'editSub'
      } else {
        this.mode = mode;
      }
      this.cdr.detectChanges();
      this.onClearContent();

      this.updateFormContentValidator();
      this.selectedSVG = row.svg_icon_id

      if (this.menuBar.link.includes('external')) {
        this.showUsableLink = false;
        this.formContent.patchValue({
          link_type: 2,
        });
      } else {
        this.showUsableLink = true;
        this.formContent.patchValue({
          link_type: 1,
        });
      }

      this.formContent.patchValue({
        main_menu_id: this.menuBar.main_menu_id,
        platform: this.menuBar.platform,
        link: this.menuBar.link,
        svg_icon_id: this.menuBar.svg_icon_id,
        after_login: this.menuBar.after_login,
        new: this.menuBar.new,
        sub: this.menuBar.sub,
      });
      this.menuBar.contents.forEach((element: any) => {
        this.formContent.patchValue({
          contents: {
            [element.settings_locale_id]: {
              name: element.name,
              position: element.position
            }
          }
        });
      });
      this.initMenuBar = JSON.stringify({ ...this.formContent.value });
    } else {
      this.changedObjects = this.initMenuBar != JSON.stringify({ ...this.formContent.value }) ? true : false;
      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.menuBar = null;
            this.isEmptyContent = true;
            this.mode = '';
            this.unsaveStatus = true;
            this.onClearContent();
            // Check if reopen needed
            this.checkReopen(mode, row);
          }
        });
      }
      else {
        this.menuBar = null;
        this.isEmptyContent = true;
        this.mode = '';
        this.unsaveStatus = true;
        this.onClearContent();
      }
    }
  }

  expandRowView(row) {
    if (this.subMenu == row) {
      this.showSub = false
      this.subMenu = null
    } else {
      this.showSub = true
      this.subMenu = row;
    }
  }

  checkReopen(mode: any, row?: any) {
    switch (mode) {
      case 'edit':
        this.expandRowEdit(mode, row)
        break;
      case 'create':
        this.expandRowCreate(mode);
        break;
    }
  }

  localeIndex() {
    const index = this.dropdown.locales.findIndex(item => {
      return item.id == this.displayLocale['id'];
    });
    return index;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== false) {
        fields[key] = (formData[key] === true ? 1 : formData[key]);
      }
    });
    return fields;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  async onSortColumn(property: string) {
    await this.onCheckUnsave();
    if (this.unsaveStatus == true) {
      // Reset other columns
      for (const key in this.sortingConfig) {
        if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
          if (key == property) {
            this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
          } else {
            this.sortingConfig[key] = 'desc';
          }
        }
      }
      // User selection
      this.sortingSelection.sort_by = property;
      if (this.sortingSelection.sort_by === property) {
        // Same column
        this.sortingSelection.sort_order = this.sortingConfig[property];
      } else {
        // Switch to other column
        this.sortingConfig[property] = 'asc';
        this.sortingSelection.sort_order = 'asc';
      }
      // Load Data
      this.onViewPageBy(this.page, this.pageSize, this.params);
    }
  }

  private formInit() {
    const buildContents = () => {
      let fields = {};
      this.dropdown.locales.forEach((element: any) => {
        const subFields = new FormGroup({
          settings_locale_id: new FormControl(element.id),
          name: new FormControl(null),
          position: new FormControl(null)
        });

        let isUpdating = false;

        subFields.get('name').valueChanges.subscribe(name => {
          if (!isUpdating) {
            isUpdating = true;
            const positionControl = subFields.get('position');

            if (name !== null && name !== '') {
              // If name is set, require position
              positionControl.setValidators([Validators.required]);
            } else {
              // If name is not set, remove position validation
              positionControl.clearValidators();
            }

            // Update the validity of the position control
            positionControl.updateValueAndValidity();
            isUpdating = false;
          }
        });

        subFields.get('position').valueChanges.subscribe(position => {
          if (!isUpdating) {
            isUpdating = true;
            const nameControl = subFields.get('name');

            if (position !== null && position !== '') {
              // If position is set, require name
              nameControl.setValidators([Validators.required]);
            } else {
              // If position is not set, remove name validation
              nameControl.clearValidators();
            }

            // Update the validity of the name control
            nameControl.updateValueAndValidity();
            isUpdating = false;
          }
        });

        fields = { ...fields, [element.id]: subFields };
      });
      return fields;
    };

    this.form = new FormGroup({
      // Filter
      status: new FormControl('all'),
      settings_locale_id: new FormControl('all'),
      platform: new FormControl(this.selectedPlatform),
      name: new FormControl(null),
    });
    this.formContent = new FormGroup({
      // Create dialog
      link_type: new FormControl(1),
      main_menu_id: new FormControl(null),
      platform: new FormControl(this.selectedPlatform),
      link: new FormControl('/'),
      svg_icon_id: new FormControl(null),
      after_login: new FormControl(0),
      new: new FormControl(0),
      sub: new FormControl(0),
      contents: new FormGroup(buildContents()),
    });
    this.formContent.valueChanges.subscribe(data => {
      this.isEmptyContent = true;

      for (const key in data['contents']) {
        if (data['contents'][key]['name'] !== null && data['contents'][key]['name'] !==''  &&  data['contents'][key]['position'] !== null && data['contents'][key]['position'] !=='') {
          this.isEmptyContent = false;
          break;
        }
      }
    })
  }

  checkLinkValidity() {
    return ( this.formContent.value.link  && (this.formContent.value.link).includes("/member") ) && this.formContent.value.after_login != 1 ? false : true;
  }

  checkupdate() {
    return this.initMenuBar !== JSON.stringify({ ...this.formContent.value }) ? true : false;
  }

  displayLocaleName(row: any) {
    let menuBar = row['contents'].filter(x => x['locale_name'] == this.displayLocale['code']);
    if (menuBar.length > 0 && menuBar[0]['name'] !== null) {
      return menuBar[0]['name'];
    }
    else {
      return '-';
    }
  }

  displayLocalePosition(row: any) {
    let menuBar = row['contents'].filter(x => x['locale_name'] == this.displayLocale['code']);

    if (menuBar.length > 0 && menuBar[0]['position'] !== null) {
      return menuBar[0]['position'];
    }
    else {
      return '-';
    }
  }

  displaySVG(selectedSVG: number) {
    let svg = this.dropdown.svgIcons.find(svg => svg.id == selectedSVG).svg_icon
    return this.onSanitize(svg);
  }

  onSanitize(data: string) {
    return this.sanitizer.bypassSecurityTrustHtml(data.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>'))
  }

  private onCheckUnsave() {
    return new Promise<void>((resolve, reject) => {
      let menuBarInput = JSON.stringify({ ...this.formContent.value });
      this.changedObjects = this.initMenuBar && this.initMenuBar != menuBarInput  ? true : false;

      if (this.changedObjects == true) {
        Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Are you sure you want to discard the changes?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Cancel'),
          confirmButtonText: this.translateService.instant('Discard'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.isEmptyContent = true;
            this.mode = '';
            this.menuBar = null;
            this.unsaveStatus = true;
            this.onClearContent();
            resolve();
          }
          else {
            this.unsaveStatus = false;
            resolve();
          }
        });
      }
      else {
        resolve();
      }
    });
  }

  onChangeLinkFormat(event: any) {
    if (+event.srcElement.value === 1) {
      this.variable =  '/'
      this.showUsableLink = true;
    } else {
      this.showUsableLink = false;
      this.variable = '/external?link=';
    }
    this.formContent.patchValue({
      link: this.variable
    });  
  }
}
