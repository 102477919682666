import { SmsLog } from '@core/models/sms-log.model';
import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class LogsDataService extends DefaultDataService<SmsLog>  {

  messages$ = new Subject<any[]>();
  pagination: Pagination;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('SmsLog', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<SmsLog[]> {
    return this.http.get<ApiResponse>('/smsprovider/log').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<SmsLog[]>{
    return this.http.get<ApiResponse>(`/smsprovider/log${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  export(pageParam: string) {
    return this.http.get<ApiResponse>(`/smsprovider/log/export${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if(res) {
      return this.pagination = res.data.paginations;
    }
  }
}
