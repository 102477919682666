<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <span class="modal-x-button close" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
            <div class="pt-0 pb-0">
                <div id="kt_table_users_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <ngb-carousel *ngIf="data.evidences.length > 0">
                    <ng-template ngbSlide *ngFor="let row of data.evidences; let i = index">
                      <div class="picsum-img-wrapper">
                        <object *ngIf="checkMedia(row.url) !== 'video'" [data]="row.url | safe:'resourceUrl'" width="100%" [ngStyle]="{'height': (checkMedia(row.url) == 'pdf')? '100%' : 'auto'}"></object>
                        <video *ngIf="checkMedia(row.url) == 'video'" height="100%"  controls>
                          <source [src]="row.url | safe:'resourceUrl'" type="video/mp4">
                          <source [src]="row.url | safe:'resourceUrl'" type="video/ogg">
                          <source [src]="row.url | safe:'resourceUrl'" type="video/webm">
                          Your browser does not support HTML video.
                        </video> 
                      </div>
                    </ng-template>
                  </ngb-carousel>
                </div>
            </div>
        </div>
      </div>
  </div>