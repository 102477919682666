import { DatePipe } from '@angular/common';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { tap, catchError, map } from 'rxjs/operators';
import { AffiliateGroupsDataService } from './../../services/affiliate-groups-data.service';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AffiliateGroups } from '@core/models/affiliate-groups.model';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

import moment from 'moment';
@Component({
  selector: 'kt-currency-settings',
  templateUrl: './currency-settings.component.html',
  styleUrls: ['./currency-settings.component.scss']
})
export class CurrencySettingsDialogComponent implements OnInit {

  form: FormGroup;
  buttonLoading = false;
  currentYear = moment(this.transactionHttpService.getYesterday().from).format('YYYY');
  currentMonth = moment(this.transactionHttpService.getYesterday().from).format('M');
  messages$ = this.affiliateGroupsDataService.messages$;
  dropdown = {
    month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    year: [this.currentYear]
  };
  currencies = JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies'));
  rates;

  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, groups: AffiliateGroups, settings: any },
    public dialogRef: MatDialogRef<CurrencySettingsDialogComponent>,
    public dialog: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private affiliateGroupsDataService: AffiliateGroupsDataService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    // sort by currency id ascending
    this.currencies = this.currencies.sort((a, b) => a.id - b.id);
    this.formInit();
    this.setYearDropdown();
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    let data = {
      ...this.form.value
    }
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    this.subscription = this.affiliateGroupsDataService.updateCurrencySettings(data).pipe(
      tap((res: any) => {
        this.messages$.next([...res.message]);
        this.buttonLoading = false;
        this.form.setErrors(null);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
  }

  onSelectYM() {
    const yearMonth = {
      month: this.form.value.month,
      year: this.form.value.year
    };
    this.affiliateGroupsDataService.getCurrencySettings(this.data.groups.id, yearMonth).subscribe(res => {
      this.resetData(res);
    });
  }

  onAllYearMonth() {
    if (this.form.value.all_year_and_month) {
      this.form.controls.year.disable();
      this.form.controls.month.disable();
      this.form.patchValue({
        year: this.data.settings[0].year,
        month: this.data.settings[0].month,
      });
      this.resetData(this.data.settings);
    }else{
      this.form.controls.year.enable();
      this.form.controls.month.enable();
    }
  }

  private resetData(list: any) {
    this.currencies.map((res: any, index: number) => {
      const targetObj = list.find(entry => entry.currency_id === res.id);
      this.form.patchValue({
        rates: {
          [index]: {
            monthly_rate: targetObj && targetObj.monthly_rate ? targetObj.monthly_rate : 0,
          }
        }
      });
    });
  }

  private setYearDropdown(){
    for (let i = 1; i < 5; i++){
      this.dropdown.year.push(this.datePipe.transform(moment().subtract(i, 'years'), 'yyyy'));
    }
  }

  private formInit() {
    this.form = new FormGroup({
      affiliate_group_id: new FormControl(this.data.groups.id),
      all_year_and_month: new FormControl(true),
      year: new FormControl({ value: this.currentYear, disabled: true }),
      month: new FormControl({ value: this.currentMonth, disabled: true }),
      rates: new FormGroup(this.buildrates(this.data.settings)),
    });

    this.rates = Object.values(this.form.value.rates);
  }

  private buildrates(settings: any) {
    let rates = {};

    this.currencies.map((res: any, index: number) => {
      const targetObj = settings.find(entry => entry.currency_id === res.id);
      const tempGroup = new FormGroup({
        settings_currency_id: new FormControl(res.id),
        monthly_rate: new FormControl(targetObj && targetObj.monthly_rate ? targetObj.monthly_rate : 0),
        currency: new FormControl(res.name),
      });
      rates = { ...rates, [index]: tempGroup };
    });
    
    return rates;
  }
}
