import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { isLoggedIn } from '../store/auth/auth.selectors';
import { tap } from 'rxjs/operators';
import { AppState } from '@store/reducers';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private store: Store<AppState>, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      return this.store.pipe(
        select(isLoggedIn),
        tap(loggedIn => {
          const twoFaVerified = localStorage.getItem("twofaverified") ? localStorage.getItem("twofaverified") : '0';

          const twoFaAccess = localStorage.getItem('twofa_access') == 'true' ? true : false;

          const twoFaFirstSetUp = localStorage.getItem('twoFaFirstSetUp') == 'true' ? true : false;

          const twoFaStatus = JSON.parse(localStorage.getItem('user_data'))?.two_factor_authentication_status;

          if (twoFaStatus == 2 && twoFaAccess && loggedIn) { // Pending for reset
            this.router.navigate(['/twofapending']);
          }

          if (!twoFaFirstSetUp && twoFaAccess && loggedIn) {
            this.router.navigate(['/twofafirstsetup']);
          }

          if (!loggedIn) {
            this.router.navigateByUrl("/login");
          } else if (loggedIn && twoFaAccess && twoFaFirstSetUp && twoFaVerified == '0' && state.url !== "/twofa") {
            this.router.navigateByUrl("/twofa");
          }
        })
      );
    }

}
