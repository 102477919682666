import { AffiliateFirstDepositorReport } from '@core/models/affiliate-first-depositor-report.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class AffiliateFirstDepositorReportEntityService extends EntityCollectionServiceBase<AffiliateFirstDepositorReport> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('AffiliateFirstDepositorReport', serviceElementsFactory);
  }
}
