import { Operator } from '@core/models/operator.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Rebate } from '@core/models/rebate.model';

@Injectable()
export class RebateEntityService extends EntityCollectionServiceBase<Rebate>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Rebate', serviceElementsFactory);
  }
}
