<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control ">
                                    <div class="input-group date">
                                        <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true"
                                            [ranges]="ranges" [showCustomRangeLabel]="true"
                                            [locale]="{format: 'YYYY-MM-DD HH:mm:ss'}" [timePicker24Hour]="true"
                                            [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                    </div>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Timezone' }}:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        #timezone
                                        (click)="onChangeTimezone(timezone.selectedItems)"
                                        [form] = 'form'
                                        [dropdownList] = 'dropdown.timezones'
                                        [dropdownSettings] = 'timezoneDropdownSettings'
                                        [formName] = "'timezone'"
                                        [selectionAttributes] = "'timezone'"
                                        [selectedItems] = 'timezoneSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <select formControlName="currency_id" class="form-control">
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Number of Days:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <input type="number" formControlName="days" placeholder="Search"
                                        class="form-control">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Action Button -->
            <div class="kt-section col-12 row mb-3 mt-2 d-flex justify-content-between">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canExportMarketingWMReport" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="button_loading">
                    <i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export
                </button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th class="date-column text-center">Start Date</th>
                                    <th class="date-column text-center">End Date</th>
                                    <th class="text-center">Total W/L</th>
                                    <th class="text-center">Number of Active Players in {{ this.form.get('days').value }}</th>
                                    <th class="text-center">Number of Active Players</th>
                                    <th class="text-center">Number of New Depositors</th>
                                    <th class="text-center">Number of Dropped Players</th>
                                    <th class="text-center">Number of New Leads</th>
                                    <th class="text-center">Player Value</th>
                                    <th class="text-center">Number of New Players</th>
                                    <th class="text-center">Churn Rate %</th>
                                </tr>
                            </thead>

                            <tbody *ngIf="(marketingWeekly$ | async) as row">
                                <tr>
                                    <td class="text-center">{{ timezoneDateWithTimezone(row.start_date_time, form.value.timezone, 'YYYY-MM-DD HH:mm') }}</td>
                                    <td class="text-center">{{ timezoneDateWithTimezone(row.end_date_time, form.value.timezone, 'YYYY-MM-DD HH:mm') }}</td>
                                    <td class="text-right">{{ row.total_win_loss | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.total_active_players_in_days | number : '1.0-0' }}</td>
                                    <td class="text-right">{{ row.total_active_players | number : '1.0-0' }}</td>
                                    <td class="text-right">{{ row.total_new_depositors | number : '1.0-0' }}</td>
                                    <td class="text-right">{{ row.total_dropped_players | number : '1.0-0' }}</td>
                                    <td class="text-right">{{ row.total_new_leads | number : '1.0-0'}}</td>
                                    <td class="text-right">{{ row.player_value | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.total_new_players | number : '1.0-0' }}</td>
                                    <td class="text-right">{{ row.churn_rate }}</td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
