<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ data.mode === 'create' ? 'Create' : 'Update' }} Bank Setting</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 form-group">
              <label class="col-form-label"> Code <span class="text-danger">*</span> </label>
              <input type="text" formControlName="code" class="form-control">
            </div>
            <div class="col-lg-6 form-group">
              <label class="col-form-label"> Name <span class="text-danger">*</span> </label>
              <input type="text" formControlName="name" class="form-control">
            </div>
            <div class="col-lg-6 form-group">
              <label class="col-form-label"> Currencies <span class="text-danger">*</span></label>
                <div class="p-0 col-12" *ngIf="dropdown.currencies as currency">
                  <kt-dropdown-wo-lazyload
                      [form] = 'form'
                      [dropdownList] = 'currency'
                      [dropdownSettings] = 'currencyDropdownSettings'
                      [formName] = "'currency'"
                      [selectionAttributes] = "'id'"
                      [selectedItems] = 'currencySelectedItems'
                      (selectedItemsChanged) = 'onSelectedCurrencyChanged($event)'
                      >
                  </kt-dropdown-wo-lazyload>
                </div>
            </div>
            <div class="col-lg-6 form-group">
                <label class="col-form-label">Bank Type <span class="text-danger">*</span></label>
                <select formControlName="bank_type" class="form-control" (change)="onSelectBankType()">
                  <option [value]="null" [disabled]="true">Please Select</option>
                  <option *ngFor="let value of dropdown.bankTypes | async" [value]="value.id">{{ value.name }}</option>
                </select>
            </div>
            <div class="col-lg-6 form-group" *ngIf="selectedBankType == 5">
                <label class="col-form-label"> Token <span class="text-danger">*</span></label>
                <div class="p-0 col-12" *ngIf="dropdown.cryptoTokens | async as token">
                  <kt-dropdown-wo-lazyload
                      [form] = 'form'
                      [dropdownList] = 'token'
                      [dropdownSettings] = 'currencyDropdownSettings'
                      [formName] = "'token'"
                      [selectionAttributes] = "'id'"
                      [selectedItems] = 'selectedCryptoToken'
                      (selectedItemsChanged) = 'onSelectedCryptoTokenChanged($event)'
                      >
                  </kt-dropdown-wo-lazyload>
                </div>
            </div>
            <div class="col-lg-6 form-group" *ngIf="selectedBankType == 6">
              <label class="col-form-label"> Network</label>
              <input type="text" formControlName="crypto_network" class="form-control">
            </div>
            <div class="col-lg-6 form-group" *ngIf="selectedBankType == 6">
              <label class="col-form-label"> Position</label>
              <input type="text" formControlName="crypto_position" class="form-control">
            </div>
            <div class="col-lg-6 form-group">
                <label class="col-form-label">Type <span class="text-danger">*</span></label>
                <select formControlName="type" class="form-control">
                  <option [value]="null" [disabled]="true">Please Select</option>
                  <option *ngFor="let value of dropdown.types" [value]="value.id">{{ value.name }}</option>
                </select>
            </div> 
            <div class="col-lg-6 form-group">
              <label class="col-form-label"> Status <span class="text-danger">*</span> </label>
              <select formControlName="status" class="form-control">
                <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <!-- only show require receipt upload if bank type isn't Payment Gateway -->
            <div *ngIf="displayRequiredReceipt === true" class="col-12 form-group">
              <label class="col-form-label">Require Receipt Upload</label>
              <div class="d-flex">
                <div *ngFor="let item of selectedRequireReceipts" class="mr-3">
                  <input type="checkbox" class="mr-2" [checked]="item.require_receipt" (change)="onRequireReceiptChanged($event, item.settings_currency_id)" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>

            <!-- only show require receipt upload if bank type = ewallet || bank -->
            <div *ngIf="displayRequiredVerification === true && selectedRequireVerifications.length >0" class="col-12 form-group">
              <label class="col-form-label">Require Verification</label>
              <div class="d-flex">
                <div *ngFor="let item of selectedRequireVerifications" class="mr-3">
                  <input type="checkbox" class="mr-2" [checked]="item.status" (change)="onRequireVerificationChanged($event, item.settings_currency_id)" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- Desktop -->
            <div class="col-lg-6 form-group">
              <section class="card">
                <div class="card-header">Desktop Media</div>
                <section class="col-12 p-3">
                  <div class="border row p-0 m-0">
                    <div class="col-4 card-header border-right d-flex align-items-center">
                      <label class="col-form-label">Default</label>
                    </div>
                    <div class="col-8 p-3">
                      <label class="btn btn-default btn-sm float-left">
                        <span>Choose file</span>
                        <input type="file" hidden accept="image/*" (change)="onUploadFile($event, 'desktop')" [disabled]="(mobileLoading || desktopLoading)">
                      </label>
                      <div class="spinner-wrapper row ml-5" *ngIf="desktopLoading">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <div class="image-wrapper" *ngIf="data.bankSetting?.desktop_image || desktopImagePreview.length > 0">
                        <img class="img-fluid w-100" alt="Image Banner" [src]="desktopImagePreview[0] || data.bankSetting.desktop_image" />
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </div>
            <!-- Mobile -->
            <div class="col-lg-6 form-group">
              <section class="card">
                <div class="card-header">Mobile Media</div>
                <section class="col-12 p-3">
                  <div class="border row p-0 m-0">
                    <div class="col-4 card-header border-right d-flex align-items-center">
                      <label class="col-form-label">Default</label>
                    </div>
                    <div class="col-8 p-3">
                      <label class="btn btn-default btn-sm float-left">
                        <span>Choose file</span>
                        <input type="file" hidden accept="image/*" (change)="onUploadFile($event, 'mobile')" [disabled]="(mobileLoading || desktopLoading)">
                      </label>
                      <div class="spinner-wrapper row ml-5" *ngIf="mobileLoading">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                      </div>
                      <div class="image-wrapper" *ngIf="data.bankSetting?.mobile_image || mobileImagePreview.length > 0">
                        <img class="img-fluid w-100" alt="Image Banner" [src]="mobileImagePreview[0] || data.bankSetting.mobile_image" />
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button *ngIf="data.mode == 'create' ? canCreateBankSettings : canUpdateBankSettings" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()" #focusfield></kt-submit-button>
        </div>
      </div>
    </form>
  </div>

  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
