import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { PromotionCurrency } from '@core/models/promotion-currency.model';

@Injectable()
export class VerificationSettingBankDataService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  data$ = new Subject<any[]>();

  constructor(private http: HttpClient) { }

  getWithQuery(pageParam: string): Observable<[]>{
    return this.http.get<ApiResponse>(`/verificationsetting/bank${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  addBank(member: any):Observable<any>{
    return this.http.post<ApiResponse>(`/verificationsetting/bank`, member).pipe(
      tap(res => {
        res.data['custom_verification_setting_bank_swal'] = true;
        this.messages$.next(res.message);

        // For exist records
        this.data$.next(res.data);
      })
    );
  }

  deleteBank(id: number) {
    return this.http.delete<ApiResponse>(`/verificationsetting/bank/${id}`).pipe(
      tap( res => this.messages$.next(res.message)),
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }

  resetData() {
    this.data$.next(null);
  }
}
