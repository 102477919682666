<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Affiliates</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-3 col-form-label">
            <label>Affiliates <span class="text-danger">*</span></label>
          </div>
          <div class="col-9">
            <kt-member-dropdown 
              class="dropdown-maxheight" 
              [form]="form" 
              [isAffiliate]="true" 
              [formName]="'usernames'"
              [dropdownSettings]='dropdownSettings' 
              [selectionAttributes]="'username'" 
              [selectedItems]="selectedMembers"
              (selectedItemsChanged)="onSelectionChanged($event)">
            </kt-member-dropdown>
            <p class="selected-member-wrapper">
              <small *ngFor="let item of selectedMembers" class="selected-member bg-info">
                {{ item.username }}
                <span class="bg-danger remove" (click)="onRemoveSelection(item.id)">x</span>
              </small>
            </p>
            <div class="py-5">OR</div>

            <textarea #textArea formControlName="affiliate_codes" class="form-control" (keydown)="autoGrow()"
              placeholder="{{ 'Enter affiliate code here.\nNewline for multiple affiliate code' }}">
            </textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary btn-icon-sm mr-2" (click)="onSave()" type="button" [disabled]="loading || !form.valid">
          <i [class]="loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>
          Submit
        </button>
      </div>
    </div>
  </form>
</div>