
<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">{{ data.promotionContent.title }} - Total Applicants: {{ data.promotionContent.applications | number : '1.0-0' }} </h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
    </div>
    <div class="modal-body pb-0">
        <kt-promotion-application-shared [promotionContent]="data.promotionContent" [isModal]="data.isModal"></kt-promotion-application-shared>
    </div>
  </div>
</div>
