<ng-container *ngIf="data.member as item">
    <div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
      <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Reset Password</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
              <div class="col-md-12">
                  <div class="row col-12">
                      <label class="col-3 col-form-label control-label">New Password <span class="text-danger">*</span></label>
                      <input type="text" formControlName="password"  class="col-9 form-control" />
                  </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
              <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onResetPassword(item)"></kt-submit-button>
            </div>
        </div>
      </form>
    </div>
    <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)" [icon]="!!hasError ? 'info' : 'error'"></kt-swal-alert>
  </ng-container>
