import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MerchantBankDataService } from '../../services/merchant-bank-data.service';

@Component({
  selector: 'kt-remarks-history',
  templateUrl: './remarks-history.component.html',
  styleUrls: ['./remarks-history.component.scss']
})
export class RemarksHistoryComponent implements OnInit {

  remarksHistory$: Observable<[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { merchantBankId: number, merchantBankTransConfigId: number },
    public dialogRef: MatDialogRef<RemarksHistoryComponent>,
    private merchantBankDataService: MerchantBankDataService
  ) { }

  ngOnInit() {
    this.remarksHistory$ = this.merchantBankDataService.getRemarksHistory(this.data.merchantBankId, this.data.merchantBankTransConfigId);
  }


  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

}
