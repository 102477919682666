import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { TwoFAVerifyRequest } from '@views/pages/apps/account/dialogs/account-twofa/services/twofa-data.service';
import { TwoFADataService } from '@views/pages/apps/account/dialogs/account-twofa/services/twofa-data.service';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { TwoFA } from '@core/models/twofa.model';
@Component({
  selector: 'twofafirstloginsetup',
  templateUrl: './twofa-login-firstsetup.component.html',
  styleUrls: ['./twofa-login-firstsetup.component.scss'],
})
export class TwofaLoginFirstsetupComponent implements OnInit {
  form: FormGroup;
  buttonLoading: boolean = false;
  messages$ = new Subject<any[]>();
  subscription = new Subscription();
  loadingQR: boolean = true;
  twoFaData: TwoFA = null;
  errorText: boolean = false;
  constructor(
    private twoFADataService: TwoFADataService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { }
  ngOnInit(): void {
    this.formInit();
    this.onGetQRCode();
  }
  private formInit() {
    this.form = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    });
    this.form.get('code').valueChanges.subscribe((value) => {
      if (this.errorText) {
        this.errorText = false;
        this.cdr.detectChanges();
      }
    });
  }
  onSubmit() {
    const data: TwoFAVerifyRequest = {
      code: this.form.value.code,
    }
    this.buttonLoading = true;
    this.twoFADataService.verifyTwoFACode(data).pipe(
      tap((res) => {
        this.buttonLoading = false;
        this.messages$.next([...res.message]);
        this.twoFaData.first_verify = true;
        localStorage.setItem('twoFaFirstSetUp', this.twoFaData.first_verify.toString());
        this.router.navigate(['/login']);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.errorText = true;
        this.cdr.detectChanges();
        throw error;
      })
    ).subscribe();
  }
  onGetQRCode() {
    this.twoFADataService.getQRCode().pipe(
      tap((res) => {
        this.twoFaData = res;
        this.cdr.detectChanges();
      })
    ).subscribe();
  }
  onImageLoad() {
    this.loadingQR = false;
  }
}