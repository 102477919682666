import { Injectable } from '@angular/core';
import { AffiliateTransfers } from '@core/models/affiliate-transfers.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable()
export class AffiliateTransfersEntityService extends EntityCollectionServiceBase<AffiliateTransfers> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('AffiliateTransfers', serviceElementsFactory);
  }
}

