import { Component, OnInit, Inject, OnDestroy, ViewChildren, QueryList, AfterViewInit, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AnnouncementEntityService } from '../../services/announcement-entity.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { Subscription, forkJoin, of } from 'rxjs';
import { tap, map, catchError, delay } from 'rxjs/operators';
import { Announcement } from '@core/models/announcement.model';
import { DatePipe } from '@angular/common';
import { AnnouncementDataService } from '../../services/announcement-data.service';
import * as moment from 'moment-timezone';

@Component({
  templateUrl: './announcement-edit.component.html',
  styleUrls: ['./announcement-edit.component.scss']
})
export class AnnouncementEditDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;
  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    locales: this.dropdownHttpService.locales,
    announcementTypes: this.dropdownHttpService.announcementTypes,
    locations: this.dropdownHttpService.locations,
  };
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  dateTimeFormat = 'yyyy-MM-dd HH:mm:';
  messages$ = this.announcementDataService.messages$;
  refreshStatus: boolean;
  buttonLoading = false;
  dateTimeStack = {
    start_datetime: null,
    end_datetime: null
  };
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  private datePickerSubscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { announcement: Announcement, mode: string },
    public dialogRef: MatDialogRef<AnnouncementEditDialogComponent>,
    private announcementService: AnnouncementEntityService,
    private announcementDataService: AnnouncementDataService,
    private dropdownHttpService: DropdownHttpService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    this.onRefresh();
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'start_datetime'),
      this.buildDatePicker(1, 'end_datetime')
    ]).subscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onFocusField() {
    of(null).pipe(
      delay(0), tap(() => this.focusfield.first.nativeElement.focus()
      )).subscribe();
  }

  onSave(announcement: Announcement, mode?: string) {
    this.buttonLoading = true;
    const data = {
      id: announcement ? announcement.id : null,
      ...this.form.value,
      start_datetime: moment(this.form.value.start_datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
      end_datetime: this.form.value.end_datetime ? moment(this.form.value.end_datetime).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') : ''
    };
    Object.keys(data).forEach((key) => (data[key] == null || (data[key] === '' && key !== 'end_datetime')) && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.announcementService.update(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.announcementDataService.add(data).pipe(
            tap((res: any) => {
              this.form.setErrors(null);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.announcementDataService.messages$
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, index === 0 ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
        this.onFocusField();
      })
    );
  }

  private formInit() {
    const dateFormat = 'yyyy-MM-dd HH:mm:ss';
    let content = null;
    let settingLocaleId = null;
    let position = 99;
    let method = 1;
    let location = null;
    let status = 1;
    let startDate: any = this.datePipe.transform(new Date(), dateFormat);
    let endDate: any = this.datePipe.transform(new Date(), dateFormat);
    if (this.data.mode === 'edit') {
      this.dateTimeStack = {
        start_datetime: new Date(new Date(this.data.announcement.start_datetime).getTime() + this.offset - this.clientOffset),
        end_datetime: this.data.announcement.end_datetime !== null ? new Date(new Date(this.data.announcement.end_datetime).getTime() + this.offset - this.clientOffset) : null
      };
      content = this.data.announcement.content;
      settingLocaleId = this.data.announcement.settings_locale_id;
      position = this.data.announcement.position;
      method = this.data.announcement.method;
      location = this.data.announcement.location;
      status = this.data.announcement.status;
      startDate = moment(new Date(new Date(this.data.announcement.start_datetime).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss');
      endDate = moment(new Date(new Date(this.data.announcement.end_datetime).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss');
    }
    this.form = new FormGroup({
      content: new FormControl(content, [Validators.required]),
      settings_locale_id: new FormControl(settingLocaleId, [Validators.required]),
      position: new FormControl(position, [Validators.required, Validators.pattern('[0-9]+')]),
      method: new FormControl(method),
      location: new FormControl(location),
      status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false }),
      start_datetime: new FormControl(startDate, [Validators.required]),
      end_datetime: new FormControl(endDate),
    });

  }

}
