<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <!-- Header -->
        <div class="modal-header">
            <h5 class="modal-title">Meta Tag Settings <span *ngIf="data.seo.domain.length > 0">({{ data.seo.domain[0] }})</span></h5>
            <span class="modal-x-button" (click)="onCloseDialog()"><i class="fa fa-times" aria-hidden="true"></i></span>
        </div>
        <div class="modal-body">
            <div class="d-flex align-items-center device-tabs">
                <ul *ngFor="let item of dropdown.deviceType">
                  <li (click)="onSelectDevice(item)" [ngClass]="selectedDevice.id === item.id ? 'active-tab' : ''">
                    <a><i [class]="item.icon"></i></a>
                  </li>
                </ul>
                <div class="ml-auto">
                    <button *ngIf="canDuplicateMetaTagSettings" class="btn btn-warning btn-icon-sm mr-2 mb-2" (click)="onOpenDuplicateDialog()" [disabled]="isSavingAll || processingRoutes.length > 0 || metatagsLoading || routesLoading"><i class="fas fa-clone" aria-hidden="true"></i>Duplicate From</button>
                </div>
            </div>
            <div class="tab-container">
                <div class="row">
                    <div class="col-4">
                        <div class="col-12 form-group row mb-3 mx-0 px-0">
                            <input class="form-control" type="text" placeholder="Search route" [(ngModel)]="routeName" name="routeName" (keyup)="onSearch()">
                        </div>
                        <div>
                            <div class="spinner-wrapper d-flex justify-content-center mb-4" *ngIf="routesLoading">
                                <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                            </div>  
                            <ng-container *ngIf="routes.length > 0; else noRoute">
                                <ng-container *ngIf="!routesLoading">
                                    <ng-container *ngFor="let route of routes">
                                        <div class="col-12 form-group row route-list" (click)="onSelectRoute(route)" [ngClass]="{'selected': route.id === selectedRoute?.id }">
                                            <a [matTooltip]="route.route" matTooltipPosition="after">{{ route.name }}</a>
                                            <div class="col-2 ml-auto text-right">
                                                <i class="fas fa-circle-notch fa-spin p-0" *ngIf="processingRoutes.includes(route.id)"></i>
                                                <button matTooltip="Save" class="btn btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onSave(2, route.id)" [disabled]="processingRoutes.includes(route.id) || isSavingAll" *ngIf="shouldShowButton(route.id)"><i class="fas fa-save fa-lg"></i></button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <ng-template #noRoute>
                                <label *ngIf="!routesLoading" class="w-100 text-center" style="color: #646c9a;">No data available</label>
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-8">
                        <ng-container *ngIf="(data.locales.length > 0); else elseLoading">
                            <form [formGroup]="form">
                                <mat-tab-group animationDuration="0ms" [selectedIndex]="defaultLocaleTab" animationDuration="0ms" [disableRipple]="true" formGroupName="details" [style]="routes.length > 0 ? 'min-height: 85vh' : 'min-height: 25vh'">
                                    <mat-tab *ngFor="let locale of data.locales; let i = index" [formGroupName]="locale.id">
                                        <ng-template mat-tab-label>
                                            <span>{{ locale.code }}</span>
                                        </ng-template>  
                                        <div *ngIf="(selectedRoute !== undefined && (!metatagsLoading)); else formLoading">
                                            <!-- Meta Section -->
                                            <div class="col-12 form-group row">
                                                <label class="col-4 col-form-label">Meta Title </label>
                                                <input type="text" class="col-8 form-control" formControlName="meta_title" name="meta_title" placeholder="Suggested about 60 characters" #field_meta_title (input)="onInputChange(locale.code, field_meta_title.name, field_meta_title.value)">
                                                <label class="col-12 text-right p-0 mb-0 count-label">CC: {{ form.value.details[locale.id].meta_title ? form.value.details[locale.id].meta_title.length : 0 }}</label>
                                            </div>
                                            <div class="col-12 form-group row">
                                                <label class="col-4 col-form-label">Meta Description </label>
                                                <textarea rows="4" class="col-8 form-control" formControlName="meta_desc" name="meta_desc" placeholder="Suggested about 160 characters" #field_meta_desc (input)="onInputChange(locale.code, field_meta_desc.name, field_meta_desc.value)"></textarea>
                                                <label class="col-12 text-right p-0 mb-0 count-label">CC: {{ form.value.details[locale.id].meta_desc ? form.value.details[locale.id].meta_desc.length : 0 }}</label>
                                            </div>
                                            <div class="col-12 form-group row">
                                                <label class="col-4 col-form-label">Meta Keywords </label>
                                                <input type="text" class="col-8 form-control" formControlName="meta_keyword" name="meta_keyword" placeholder="Suggested no more than 10 keyword phrases" (keydown)="onCountWords()" #keyword (input)="onInputChange(locale.code, keyword.name, keyword.value)">
                                                <label class="col-12 text-right p-0 mb-0 count-label">WC: {{ words ? words : 0 }}</label>
                                            </div>
                                            <div class="col-12 form-group row">
                                                <label class="col-4 col-form-label">Meta Robots</label>
                                                <input type="text" class="col-8 form-control" formControlName="meta_robot" name="meta_robot" placeholder="Eg: 'index,follow' or 'noindex,nofollow'" #field_meta_robot (input)="onInputChange(locale.code, field_meta_robot.name, field_meta_robot.value)">
                                            </div>
                                            <hr class="dashed">
                                            <!-- Open Graph Section -->
                                            <div class="col-12 form-group row">
                                                <label class="col-4 col-form-label">Open Graph Title</label>
                                                <input type="text" class="col-8 form-control" formControlName="open_graph_title" name="open_graph_title" placeholder="Title to show on social media" #field_open_graph_title (input)="onInputChange(locale.code, field_open_graph_title.name, field_open_graph_title.value)">
                                                <label class="col-12 text-right p-0 mb-0 count-label">CC: {{ form.value.details[locale.id].open_graph_title ? form.value.details[locale.id].open_graph_title.length : 0 }}</label>
                                            </div>
                                            <div class="col-12 form-group row">
                                                <label class="col-4 col-form-label">Open Graph Description</label>
                                                <textarea rows="4" class="col-8 form-control" formControlName="open_graph_desc" name="open_graph_desc" placeholder="Description to show on social media" #field_open_graph_desc (input)="onInputChange(locale.code, field_open_graph_desc.name, field_open_graph_desc.value)"></textarea>
                                                <label class="col-12 text-right p-0 mb-0 count-label">CC: {{ form.value.details[locale.id].open_graph_desc ? form.value.details[locale.id].open_graph_desc.length : 0 }}</label>
                                            </div>
                                            <div class="col-12 form-group row">
                                                <label class="col-4 col-form-label">Open Graph URL</label>
                                                <input type="text" class="col-8 form-control" formControlName="open_graph_url" name="open_graph_url" placeholder="Eg: www.google.com" #field_open_graph_url (input)="onInputChange(locale.code, field_open_graph_url.name, field_open_graph_url.value)">
                                            </div>
                                            <div class="col-12 form-group row">
                                                <label class="col-4 col-form-label">Open Graph Type</label>
                                                <select class="col-8 form-control" formControlName="open_graph_type" name="open_graph_type" #field_open_graph_type (change)="onInputChange(locale.code, field_open_graph_type.name, field_open_graph_type.value)">
                                                    <option value="null" hidden disabled>Please Select</option>
                                                    <option [value]="item.id" *ngFor="let item of dropdown.openGraphType">{{ item.name }}</option>
                                                </select>
                                            </div>
                                            <div class="col-12 form-group row">
                                                <label class="col-4 col-form-label">Open Graph Image</label>
                                                <div class="col-8 p-0">
                                                    <div class="d-flex align-items-center mb-3">
                                                        <label class="col-2 btn btn-default btn-sm image-button mb-0">
                                                            <span>Choose file</span>
                                                            <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, locale)">
                                                        </label>
                                                        <div class="spinner-wrapper ml-3" *ngIf="isImageUploading">
                                                            <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                                        </div>        
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="image-wrapper" *ngIf="form.value.details[locale.id].img_preview != '' && form.value.details[locale.id].img_preview != null">
                                                            <img style="max-width:50%" class="img-fluid" alt="Open Graph Image" [src]="form.value.details[locale.id].img_preview" />
                                                        </div>
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>   
                                        <ng-template #formLoading>
                                            <div class="spinner-wrapper d-flex justify-content-center mb-4" *ngIf="(routesLoading || metatagsLoading); else noData">
                                                <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                            </div>  
                                        </ng-template>            
                                        <ng-template #noData>
                                            <label class="w-100 text-center" style="color: #646c9a;">No data available</label>
                                        </ng-template>           
                                    </mat-tab>
                                </mat-tab-group>
                            </form>
                        </ng-container>
                        <ng-template #elseLoading>
                            <div class="row justify-content-center">
                                <i class="fas fa-circle-notch fa-spin p-0 mr-2"></i>
                            </div>
                        </ng-template>      
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
            <button *ngIf="canEditMetaTagSettings" class="btn btn-brand" (click)="onSave(1)" [disabled]="!hasChangesToSave() || isSavingAll || processingRoutes.length > 0"><i [class]="isSavingAll ? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Save All</button>
        </div>
    </div>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>