import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationHttpService {

  apiBase = environment.apiBase;

  constructor(
    private handler: HttpBackend,
    private httpClient: HttpClient,
  ) {
    this.httpClient = new HttpClient(this.handler);
    this.apiBase = this.apiBase.substring(0, this.apiBase.lastIndexOf('/'))
  }

  getIpAddress() {
    return this.httpClient.get<ApiResponse>(`${this.apiBase}/getip`).pipe(
      map(res => res.data.rows)
    );
  }

}
