import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AllAffiliatesDataService } from '../../services/all-affiliates-data.service';
import { Subscription } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'kt-affiliate-remark',
  templateUrl: './affiliate-remark.component.html',
  styleUrls: ['./affiliate-remark.component.scss']
})
export class AffiliateRemarkComponent implements OnInit {
  refreshStatus: boolean;
  loading = false;
  remarksHistory = [];

  private subscription = new Subscription();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { affiliate: any },
    public dialogRef: MatDialogRef<AffiliateRemarkComponent>,
    private AllAffiliatesDataService: AllAffiliatesDataService,
    public loadingBar: LoadingBarService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getTimeline();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private getTimeline() {
    const id = this.data.affiliate.id;
    this.loading = true;
    this.loadingBar.useRef().start();

    this.AllAffiliatesDataService.getRemarks(`${id}?paginate=false`).subscribe(res => {
      this.remarksHistory = res.rows;
      this.loadingBar.useRef().complete();
      this.loading = false;
    });
  }
}
