// Angular / Core
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// Model
import { ApiResponse } from "@core/models/api-response.model";
import { Pagination } from "@core/models/pagination.model";
import { RegistrationPage } from "@core/models/registration-page.model";
// Third Party
import { Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { DefaultDataService, HttpUrlGenerator } from "@ngrx/data";

@Injectable()
export class RegistrationPageDataService {
    pagination: Pagination;
    messages$ = new Subject<any[]>();

    constructor(private http: HttpClient) { }

    getWithQuery(pageParam: string): Observable<RegistrationPage[]> {
        return this.http.get<ApiResponse>(`/registrationpage/settings${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map((res) => {
                return res.data.rows;
            })
        );
    }

    getById(id: number): Observable<RegistrationPage> {
        return this.http.get<ApiResponse>(`/registrationpage/settings/${id}`).pipe(
            tap(res => this.paginationInit(res)),
            map((res) => {
                return res.data.rows;
            })
        );
    }

    addSettings(settings) {
        return this.http.post<ApiResponse>(`/registrationpage/settings`, settings).pipe(
            tap(res => this.messages$.next(res.message)),
            map(res => res.data.rows)
        );
    }

    update(id: number, settings: RegistrationPage) {
        return this.http.put<ApiResponse>(`/registrationpage/settings/${id}`, settings).pipe(
            tap(res => this.messages$.next(res.message)),
        );
    }

    updateStatus(settings: any) {
        return this.http.put<ApiResponse>(`/registrationpage/settings/statusupdate`, settings).pipe(
            tap(res => this.messages$.next(res.message)),
        );
    }

    private paginationInit(res: any) {
        if (res) {
            this.pagination = res.data.paginations;
        }
    }
}
