<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Create Game Account</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body m-b-25">
        <div class="col-12 form-group row pr-0">
          <label class="col-4 kt-form__label col-form-label">Username</label>
          <label class="col-8 col-form-label"> {{ data.member.username }} </label>
        </div>

        <div class="col-12 form-group row pr-0">
          <label class="col-4 kt-form__label col-form-label">Currency</label>
          <label class="col-8 col-form-label"> {{ data.member.currency_code }} </label>
        </div>

        <div class="col-12 form-group row pr-0">
          <label class="col-4 kt-form__label col-form-label">Game Provider</label>
          <select class="col-8 form-control" formControlName="game_provider_id">
            <option value="null"> Please Select </option>
            <option [value]="value.id" *ngFor="let value of dropdown.gameProviders"> {{ value.code + '-' + value.name }}
            </option>
          </select>
          <i *ngIf="dropdown.gameProviders.length === 0" class="fas fa-circle-notch fa-spin p-0 mr-2 dropdown-loading"></i>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="button_loading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>