<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row pr-0 mb-2">
                                <!-- ID -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>ID:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="id" placeholder="Search" class="form-control"/>
                                </div>
                                <!-- Username -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" placeholder="Search" class="form-control"
                                    (input)="toLowerCaseInput('username', $event)"/>
                                </div>
                                <!-- Member Group -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Member Group:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                <select formControlName="member_group_id" class="form-control">
                                    <option value="all"> All </option>
                                    <option [value]="value.id" *ngFor="let value of dropdown.groups | async">
                                    {{ value.name }}
                                    </option>
                                </select>
                                </div>
                                <!-- Currency -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="settings_currency_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of currenciesDropdownList">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row pr-0 mb-2">
                                <!-- Commission Name -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Commission Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="commission_name" placeholder="{{ 'Search' | translate }}" class="form-control" [ngbTypeahead]="this.searchSetting" [resultFormatter]="this.formatSetting" [inputFormatter]="this.formatSetting">
                                </div>
                                <!-- Commisison Type -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Commission Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="type" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.types">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <!-- Commission Amount -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Commission Amount:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="number" formControlName="total_release_amount" class="form-control">
                                </div>
                            </div>
                            <div class="col-12 row pr-0 mb-2">
                                <!-- Date Time -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <select class="form-control" formControlName="date_type">
                                                <option value="calculation_date" selected>Calculation Date</option>
                                                <option value="created_date">Created Date</option>
                                                <option value="updated_date">Updated Date</option>
                                            </select>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true"
                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                            [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                        ngxDaterangepickerMd/>
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                                <!-- Status -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="d-flex flex-wrap status-filter-pt">
                                        <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.statuses ; let i = index">
                                            <input type="checkbox" [checked]="item.checked" (change)="onSearchStatus($event, item.value, i)" class="custom-control-input" [id]="item.name">
                                            <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
                <div>
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <div>
                    <button *ngIf="canGenerateReferralCommission" class="btn btn-brand btn-icon-sm mr-2" (click)="onOpenDialog('generate')"><i class="fas fa-plus"></i>Generate Referral Commission</button>
                    <button *ngIf="canReleaseReferralCommission" class="btn btn-success btn-icon-sm mr-2" (click)="referralCommissionAction('release', 'selected')" [disabled]="!(selectedCommission.length > 0)"><i class="fas fa-check"></i>Release Selected</button>
                    <button *ngIf="canReleaseReferralCommission" class="btn btn-success btn-icon-sm mr-2" (click)="referralCommissionAction('release', 'all')" [disabled]="selectedCommission.length > 0"><i class="fas fa-check"></i>Release All</button>
                    <button *ngIf="canRejectReferralCommission" class="btn btn-danger btn-icon-sm mr-2" (click)="referralCommissionAction('reject', 'selected')" [disabled]="!(selectedCommission.length > 0)"><i class="fas fa-ban"></i>Reject Selected</button>
                    <button *ngIf="canRejectReferralCommission" class="btn btn-danger btn-icon-sm mr-2" (click)="referralCommissionAction('reject', 'all')" [disabled]="selectedCommission.length > 0"><i class="fas fa-ban"></i>Reject All</button>
                  </div>
            </div>

            <!-- Summary -->
            <div class="kt-section">
                <div class="col-xl-12 summary-wrapper">
                    <div class="row">
                        <div class="col-md-6 pl-0 pr-0">
                            <label>Selected Rows Total Refferal Commission(USD): </label><span class="ml-1">{{ selectedTotalCommissions | number : '1.2' }}</span>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-md-6 pl-0 pr-0">
                        <label>Current Page Total Refferal Commission(USD): </label><span class="ml-1">{{ currentPageTotalCommissions | number : '1.2' }}</span>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-6 pl-0 pr-0">
                        <label>Total Refferal Commission(USD): </label><span class="ml-1">{{ totalCommissions | number : '1.2' }}</span>
                    </div>
                    </div>
                </div>
            </div>

            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr class="text-bottom">
                                    <th rowspan="3" ><input type="checkbox" [(ngModel)]="toggleAll" (change)="checkboxToggleAll()"></th>
                                    <th rowspan="3" class="sort-enabled pointer" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th rowspan="3" class="text-center sort-enabled pointer" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                                    <th rowspan="3" class="text-center sort-enabled pointer" (click)="onSortColumn('start_datetime')" [ngClass]="sortingConfig.start_datetime" [class.sort-selected]="sortingSelection.sort_by === 'start_datetime'">Commission Calculation Period</th>
                                    <th rowspan="3" class="text-center sort-enabled pointer" (click)="onSortColumn('commission_name')" [ngClass]="sortingConfig.commission_name" [class.sort-selected]="sortingSelection.sort_by === 'commission_name'">Commission Settings</th>
                                    <th class="text-center" colspan="6">Member</th>
                                    <th rowspan="3" class="text-center sort-enabled pointer" (click)="onSortColumn('total_release_amount')" [ngClass]="sortingConfig.total_release_amount" [class.sort-selected]="sortingSelection.sort_by === 'total_release_amount'">Total Release Amount</th>
                                    <th rowspan="3" class="text-center sort-enabled pointer" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                                    <th rowspan="3" class="text-center sort-enabled pointer" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                                    <th rowspan="3" class="text-center sort-enabled pointer" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                                </tr>
                                <tr>
                                    <th rowspan="2" class="text-center sort-enabled pointer" (click)="onSortColumn('member_group_name')" [ngClass]="sortingConfig.member_group_name" [class.sort-selected]="sortingSelection.sort_by === 'member_group_name'">Member Group</th>
                                    <th rowspan="2" class="text-center sort-enabled pointer" (click)="onSortColumn('currency_code')" [ngClass]="sortingConfig.currency_code" [class.sort-selected]="sortingSelection.sort_by === 'currency_code'">Currency</th>
                                    <th rowspan="2" class="text-center sort-enabled pointer" (click)="onSortColumn('exchange_rate')" [ngClass]="sortingConfig.exchange_rate" [class.sort-selected]="sortingSelection.sort_by === 'exchange_rate'">Currency Rate (per USD)</th>
                                    <th colspan="3" class="text-center">Active Downline</th>
                                </tr>
                                <tr>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('downline_tier_1')" [ngClass]="sortingConfig.downline_tier_1" [class.sort-selected]="sortingSelection.sort_by === 'downline_tier_1'">Level 1</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('downline_tier_2')" [ngClass]="sortingConfig.downline_tier_2" [class.sort-selected]="sortingSelection.sort_by === 'downline_tier_2'">Level 2</th>
                                    <th class="text-center sort-enabled pointer" (click)="onSortColumn('downline_tier_3')" [ngClass]="sortingConfig.downline_tier_3" [class.sort-selected]="sortingSelection.sort_by === 'downline_tier_3'">Level 3</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(referralCommission$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <th [formGroup]="checkboxForm"><input type="checkbox" [formControlName]="row.id"></th>
                                    <td class="text-center">{{ row.id }}</td>
                                    <td>
                                        <a [ngClass]="canViewMemberDialog ? 'text-primary font-weight-bold' : 'no-permission-text'" (click)="canViewMemberDialog ? onOpenDialog('member-information', row.member_account_id) : ''">
                                            {{ row.username }}
                                        </a>
                                    </td>
                                    <td>
                                        {{ (row.start_datetime | timezoneDate:'YYYY-MM-DD HH:mm:ss (ddd)':true) }} - <br/> 
                                        {{ (row.end_datetime | timezoneDate: 'YYYY-MM-DD HH:mm:ss (ddd)':true) }}
                                    </td>
                                    <td class="text-center">{{ row.referral_commission_setting_name }}<br> ({{ row.type_name }})</td>
                                    <td class="text-center">{{ row.member_group_name }}</td>
                                    <td class="text-center">{{ row.currency_code }}</td>
                                    <td class="text-center">{{ row.exchange_rate | number : '1.3-3' }}</td>
                                    <td class="text-center">
                                        <a [ngClass]="row.downline_tier_1 > 0 && canViewReferralCommissionDetails ? 'text-primary font-weight-bold' : 'no-permission-text'" (click)="(row.downline_tier_1 > 0 && canViewReferralCommissionDetails) ? onOpenDialog('referral-commission-details', null, row, 1) : ''">
                                            {{ row.downline_tier_1 }}
                                        </a>
                                    </td>
                                    <td class="text-center">
                                        <a [ngClass]="row.downline_tier_2 > 0 && canViewReferralCommissionDetails ? 'text-primary font-weight-bold' : 'no-permission-text'" (click)="(row.downline_tier_2 > 0 && canViewReferralCommissionDetails) ? onOpenDialog('referral-commission-details', null, row, 2) : ''">
                                            {{ row.downline_tier_2 }}
                                        </a>
                                    </td>
                                    <td class="text-center">
                                        <a [ngClass]="row.downline_tier_3 > 0 && canViewReferralCommissionDetails ? 'text-primary font-weight-bold' : 'no-permission-text'" (click)="(row.downline_tier_3 > 0 && canViewReferralCommissionDetails) ? onOpenDialog('referral-commission-details', null, row, 3) : ''">
                                            {{ row.downline_tier_3 }}
                                        </a>
                                    </td>
                                    <td class="text-right">{{ row.total_release_amount | number: '1.2-2' }}</td>
                                    <td class="text-center">
                                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status">{{ row.status_name }}</span>
                                        <ng-container *ngIf="row.additional_info.related_referral_commission.length >= 1">
                                            <br>
                                            <ng-container *ngFor="let item of row.additional_info.related_referral_commission">
                                              {{ item.id }}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td>
                                        {{ row.created_by ? row.created_by : 'System' }}<br/>
                                        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="row.updated_by">
                                            {{ row.updated_by }}<br/>
                                            <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                            <ng-template #updatedDateTime>
                                                {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                            </ng-template>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog()"></kt-swal-alert>
        </div>
    </div>
</div>