<div class="google-authenticator-container">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <h4 class="twofa-title">
            Google Authenticator
        </h4>
        <h5 class="scan-title">Scan this with Google Authenticator App</h5>
        <div class="text-center" *ngIf="twoFaData">
            <div class="spinner text-center" *ngIf="loadingQR"></div>
            <img class="qr-img"
                [src]="twoFaData.qrCodeUrl"
                alt="qrcode"
                (load)="onImageLoad()"
            />
        </div>
        
        <div class="input-container" *ngIf="!loadingQR">
            <input type="text" class="form-control" placeholder="Verification Code" formControlName="code" />
            <button
                type="button"
                class="btn btn-primary next-button"
                (click)="onSubmit()"
                [disabled]="!form.valid || buttonLoading"
                >
                {{ 'Next' }}
            </button>
            <span class="error-text" *ngIf="errorText">
                {{ form.value.code }} is not valid
            </span>
        </div>
    </form>
</div>