import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { ReferralSetting } from '@core/models/referral-setting.model';

@Injectable()
export class ReferralSettingsEntityService extends EntityCollectionServiceBase<ReferralSetting> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('ReferralSetting', serviceElementsFactory);
  }
}
