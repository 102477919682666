import { tap, catchError } from 'rxjs/operators';
import { Subscription, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { RaffleEventsDataService } from '../../../services/raffle-events-data.service';
import { RaffleEvents } from '@core/models/raffle-events.model';

@Component({
  selector: 'kt-raffle-event-name-dialog',
  templateUrl: './raffle-event-name-dialog.component.html',
  styleUrls: ['./raffle-event-name-dialog.component.scss']
})
export class RaffleEventNameDialogComponent implements OnInit, OnDestroy {

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;
  form: FormGroup;

  messages$ = this.raffleEventsDataService.messages$;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    locales$: this.raffleEventsDataService.locales$,
  };
 
  availableLocales = [];

  private subscription = new Subscription();

  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { event_name: RaffleEvents, mode: string },
    public dialogRef: MatDialogRef<RaffleEventNameDialogComponent>,
    private raffleEventsDataService: RaffleEventsDataService,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit() {
    console.log('data after dialog');
    console.log(this.data.event_name);

    this.formInit();
    this.setLocales();
  }

  ngOnDestroy() {
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(mode?: string) {
    this.buttonLoading = true;

    if (this.form.valid) {
      this.buttonLoading = true;
      this.form.setErrors({ 'invalid': true });

      this.dialogRef.close(this.form.value.event_name_locales);
    }
  }

  private formInit() {
    this.form = new FormGroup({});
  }

  setLocales() {
    this.dropdown.locales$.subscribe(locales => {
      if (locales.length > 0){
        this.availableLocales = locales;
        this.generateLocale();

        Object.values(this.data.event_name).forEach((res)=> {
          const detailsGroup = {
            settings_locale_id: res.settings_locale_id,
            name: res.name
          }
          this.form.patchValue({
            event_name_locales : {[res.settings_locale_id]: detailsGroup} 
          });
        });
      }
    });
  }

  private generateLocale() {
    const buildLocales = () => {
      let fields = {};
      if (this.availableLocales.length > 0) {
        this.availableLocales.map((element: any) => {
          const subFields = new FormGroup({
            setting_locale_id: new FormControl(element.id),
            name: new FormControl(null),
          });
          fields = { ...fields, [element.id]: subFields };
        });
      }
        return fields;
    };
    this.form.addControl('event_name_locales', new FormGroup(buildLocales()));

    console.log(this.form.value);

  }
}
    

  

  

