<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
        <div class="swal2-header swal2-popup pt-4">
          <span class="swal2-icon swal2-info swal2-icon-show">
            <div class="swal2-icon-content justify">i</div>
          </span>
          <h6 class="swal2-title" id="swal2-title" style="display: flex;">{{ title }}</h6>
        </div>
        <div class="swal2-content">
          <div class="col-12 p-4">
            <section class="row border-top border-left">
              <div class="col-lg-4 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                <label class="control-label">Remark<span class="text-danger">*</span></label>
              </div>
              <div class="col-lg-8 col-md-9 col-sm-6 col-xs-12 border-right border-bottom p-3">
                <input type="text" formControlName="remarks" class="form-control"  id="remark-input">
              </div>
            </section>
            <section class="row border-left">
              <div class="col-lg-4 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                <label class="control-label">Notification</label>
              </div>
              <div class="col-lg-8 col-md-9 col-sm-6 col-xs-12 border-right border-bottom p-3 display">
                <div>
                  <input type="checkbox" formControlName="sms_notification" class="input" (change)="onCheckbox($event, 'sms_notification')">
                  <label class="control-label ml-2 mb-0">SMS</label>
                </div>
                <div>
                  <input type="checkbox" formControlName="email_notification" class="input" (change)="onCheckbox($event, 'email_notification')">
                  <label class="control-label ml-2 mb-0">Email</label>
                </div>
              </div>
            </section>
            <div id="custom-validation-message" style="color: red; text-align: left; margin-top: 10px;"></div>
          </div>
          <p>{{ message }}</p>
        </div>

        <div class="swal2-actions mb-4">
          <button type="button" class="swal2-cancel swal2-styled" (click)="onCloseDialog()">No</button>
          <button type="button" class="swal2-confirm swal2-styled" (click)="onSave(data.affiliate, data.mode)" [disabled]="buttonLoading">
            <span *ngIf="!buttonLoading">Yes</span>
            <span *ngIf="buttonLoading" class="spinner-border spinner-border-sm align-middle"></span>
          </button>
        </div>
      </div>
    </form>
  </div>

  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
