<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="row align-items-center">
                <div class="col-xl-12 order-2 order-xl-1">
                    <mat-tab-group animationDuration="0ms">
                        <ng-container>
                            <mat-tab label="Raffle Events Details">
                                <kt-raffle-event-detail></kt-raffle-event-detail>
                            </mat-tab>
                            <!-- <mat-tab  label="Raffle Event Tickets Details">
                                <kt-raffle-event-detail></kt-raffle-event-detail>
                            </mat-tab> -->
                        </ng-container>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>