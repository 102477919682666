import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionDataService } from './../../../transactions/services/transaction-data.service';
import { TransactionEntityService } from '@views/pages/apps/general/transactions/services/transaction-entity.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Pagination } from '@core/models/pagination.model';
import { map, tap, exhaustMap } from 'rxjs/operators';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { Subscription, Observable, of } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import * as moment from 'moment-timezone';
import { Transaction } from '@core/models/transaction.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
@Component({
  selector: 'kt-member-wallet-event',
  templateUrl: './member-wallet-event.component.html',
  styleUrls: ['./member-wallet-event.component.scss']
})
export class MemberWalletEventComponent implements OnInit, OnDestroy {

  @Input() member: any;
  @Input() totalGameBalance: string;

  form: FormGroup;
  walletEvents$: Observable<Transaction[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = ``;
  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };
  ranges: any;
  transactionTypes = [];
  selectedTransaction = [];
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  private subscription = new Subscription();
  private datePickerSubscription = new Subscription();
  constructor(
    private loadingBar: LoadingBarService,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private transactionEntityService: TransactionEntityService,
    private transactionDataService: TransactionDataService,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit() {
    this.formInit();
    this.dropdownHttpService.transactionTypes.subscribe(res => {
      res.forEach(item => {
        item.checked = true;
      })
      this.transactionTypes = res;
      this.form.patchValue({
        transaction_type_id: this.getTransactionTypes()
      })
      this.onSubmit(true);
      this.ranges = this.transactionHttpService.ranges;
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    params += this.generateTransactionParams().transactionParams !== '' ? `&${this.generateTransactionParams().transactionParams}` : '';
    return this.walletEvents$ = this.transactionEntityService.getWithQuery(`?member_account_id=${this.member.id}&page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.transactionDataService.pagination;
      })
    );
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    this.loadingBar.start();
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        const transactionParams = this.generateTransactionParams().transactionParams !== '' ? `&${this.generateTransactionParams().transactionParams}` : '';
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        return this.transactionEntityService.getWithQuery(`?perPage=${this.pageSize}${parameters}${transactionParams}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.transactionDataService.pagination;
            this.walletEvents$ = of(res);
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.transactionTypes.forEach(item => {
      item.checked = true;
    })
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.form.patchValue({
      transaction_type_id: this.getTransactionTypes(),
      start_date: null,
      end_date: null,
      defaultDate: null
    });
    this.onSubmit(true);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private generateTransactionParams() {
    return {
      transactionParams: Object.keys(this.form.value.transaction_type_id).map(key => `transaction_type_id[${key}]=${this.form.value.transaction_type_id[key]}`).join('&')
    };
  }

  private formInit() {
    this.form = new FormGroup({
      member_account_id: new FormControl(this.member.id),
      transaction_type_id: new FormControl([]),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null) // Do not remove: For Clearing The Range
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all' && key !== 'defaultDate' && key !== 'transaction_type_id') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  onChangeType(event: any, id: string) {
    this.transactionTypes.find(x => x.id === id).checked = event.target.checked;
    this.form.patchValue({
      transaction_type_id: this.getTransactionTypes()
    })
  }

  private getTransactionTypes() {
    let transaction_type_ids = [];
    this.transactionTypes.forEach(item => {
      if (item.checked) {
        transaction_type_ids.push(item.id);
      }
    });
    return transaction_type_ids;
  }

}
