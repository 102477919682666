import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Rebate, RebateBase } from '@core/models/rebate.model';

@Injectable()
export class RebateDataService extends DefaultDataService<Rebate>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Rebate', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<Rebate[]> {
    return this.http.get<ApiResponse>('/rebate/settings').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<Rebate[]>{
    return this.http.get<ApiResponse>(`/rebate/settings${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(rebate: Rebate): Observable<Rebate>{
    return this.http.post<ApiResponse>(`/rebate/settings`, rebate).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/rebate/settings/${entity.data.rows.setting.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  updateRebate(rebate: Rebate){
    return this.http.put<ApiResponse>(`/rebate/settings/${rebate.id}`, rebate).pipe(
      tap( res => this.messages$.next(res.message))
    );
  }

  getById(id: number): Observable<Rebate>{
    return this.http.get<ApiResponse>(`/rebate/settings/${id}`).pipe(
      map(res => {
        return {
          id: res.data.rows.setting.id,
          ...res.data.rows
        }
      })
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

  getRebateIdsByGameProvider(game_provider_id: number): Observable<any[]>{
    return this.http.get<ApiResponse>(`/rebate/settings/getRebateIdsByGameProvider`, {
      params: { game_provider_id: game_provider_id.toString() }
    }).pipe(
      map(res => res.data.rows)
    )
  }

  getRebatesName(name: string): Observable<any[]>{
    return this.http.get<ApiResponse>(`/rebate/settings/getRebatesName`, {
      params: { name: name}
    }).pipe(
      map(res => res.data.rows)
    )
  }

  updateRebatesByGameProvider(rebateDetails: any){
    return this.http.post<ApiResponse>(`/rebate/settings/updateRebatesByGameProvider`, rebateDetails).pipe(
      tap( res => this.messages$.next(res.message))
    );
  }
}
