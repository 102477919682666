<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Marketing Report Details</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-striped">
          <thead>
            <tr class="text-top">
              <th colspan="2" class="text-center">Params</th>
              <th rowspan="2" class="text-center">Currency</th>
              <th colspan="3" class="text-center">Members</th>
              <th colspan="6" class="text-center">Deposit/Withdrawal</th>
              <th colspan="3" class="text-center">Bet</th>
            </tr>
            <tr>
              <th>Keys</th>
              <th>Values</th>
              <th class="text-center">New <br />Registrations</th>
              <th class="text-center">Clicks</th>
              <th class="text-center">Registrations<br />w/ deposit</th>
              <th class="text-center">Unique<br />Deposit</th>
              <th class="text-center">Total Deposit<br />Count</th>
              <th class="text-center">Total Deposit<br />Amount</th>
              <th class="text-center">Unique<br />Withdrawals</th>
              <th class="text-center">Total Withdrawal<br />Count</th>
              <th class="text-center">Total Withdrawal<br />Amount</th>
              <th class="text-center">Total Bonus</th>
              <th class="text-center">Total Bonus<br />Cancelled</th>
              <th class="text-center">Total NGR</th>
            </tr>
          </thead>

          <tbody *ngIf="(marketingReportDetails$ | async) as details">
            <ng-container *ngFor="let items of details | keyvalue">
              <tr *ngFor="let item of items.value; let i = index">
                <td class="text-left" *ngIf="i===0">{{ items.key }}</td>
                <td class="text-left" *ngIf="i!==0"></td>
                <td class="text-left">{{ item.parameters }}</td>
                <td class="text-center">{{ item.currency_code }}</td>
                <td class="text-right">{{ item.total_registrations_count | number : '1.0-0' }}</td>
                <td class="text-right">{{ item.total_clicks_count | number : '1.0-0'}}</td>
                <td class="text-right">{{ item.registration_w_deposit_count | number : '1.0-0'}}</td>
                <td class="text-right">{{ item.deposit_total_unique_count | number : '1.0-0'}}</td>
                <td class="text-right">{{ item.deposit_total_count | number : '1.0-0'}}</td>
                <td class="text-right">{{ sanitizeNumericField(item.deposit_total_amount) | number : '1.2-2' }}</td>
                <td class="text-right">{{ item.withdraw_total_unique_count | number : '1.0-0'}}</td>
                <td class="text-right">{{ item.withdraw_total_count | number : '1.0-0'}}</td>
                <td class="text-right">{{ sanitizeNumericField(item.withdraw_total_amount) | number : '1.2-2' }}
                </td>
                <td class="text-right">{{ sanitizeNumericField(item.total_bonus) | number : '1.2-2' }}</td>
                <td class="text-right">{{ sanitizeNumericField(item.total_cancelled_bonus) | number : '1.2-2' }}</td>
                <td class="text-right">{{ sanitizeNumericField(item.total_ngr) | number : '1.2-2' }}</td>
            </ng-container>
            <kt-fallback-row [collection]="details"></kt-fallback-row>
          </tbody>
        </table>
      </div>
       <!-- Pagination -->
       <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true"
          [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
        </ngb-pagination>
        <div class="kt-pagination__toolbar">
          <span class="pagination__desc">
            Showing {{pagination.from}} to {{pagination.to}} of
            {{ pagination.total | number : '1.0' }} records
          </span>
        </div>
      </div>


    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
  </div>
</div>