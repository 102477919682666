import { AllAffiliateReportComponent } from './all-affiliate-report/all-affiliate-report.component';
import { AllSubAffiliateReportComponent } from './all-sub-affiliate-report/all-sub-affiliate-report.component';
import { AffiliateFirstDepositorReportComponent } from './affiliate-first-depositor-report/affiliate-first-depositor-report.component';
import { GameResultComponent } from './member-report/dialogs/game-result/game-result.component';
import { MemberReportDetailsDialogComponent } from './member-report/dialogs/member-report-details.component';
import { MemberReportComponent } from './member-report/member-report.component';
import { Routes, RouterModule} from '@angular/router';
import { NgModule } from '@angular/core';
import { AllMemberReportsComponent } from './all-member-reports/all-member-reports.component';
import { ReportDetailsComponent } from './all-member-reports/dialogs/report-details/report-details.component';
import { GameProviderReportComponent } from './game-provider-report/game-provider-report.component';
import { GameProviderReportDetailsComponent } from './game-provider-report/dialogs/game-provider-report-details.component';
import { StatisticsReportComponent } from './statistics-report/statistics-report.component';
import { MarketingReportDetailsComponent } from './marketing-report/dialogs/report-details/marketing-report-details.component';
import { MarketingReportComponent } from './marketing-report/marketing-report.component';
import { AgentReportsComponent } from './agent-report/agent-reports.component';
import { AllAgentReportComponent } from './all-agent-report/all-agent-report.component';
import { AllAgentReportsDialogComponent } from './all-agent-report/dialogs/report-details/all-agent-reports-dialog.component';
import { SMSRecoveryReportComponent } from './sms-recovery-report/sms-recovery-report.component';
import { SMSRecoveryReportDetailsComponent } from './sms-recovery-report/dialogs/sms-recovery-details/report-details.component';
import { DailyNewDepositReportComponent } from './daily-new-deposit-report/daily-new-deposit-report.component';
import { TicketInfoDialogComponent } from './member-report/dialogs/ticket-info/ticket-info.component';
import { CompanyReportComponent } from './company-report/company-report.component';
import { RegistrationReportComponent } from './registration-report/registration-report.component';
import { MarketingWeeklyReportComponent } from './marketing-weekly-report/marketing-weekly-report.component';
import { GameTypeReportComponent } from './gametype-report/gametype-report.component';
import { TelemarketerReportComponent } from './telemarketer-report/telemarketer-report.component';
import { ParlayInfoDialogComponent } from './member-report/dialogs/parlay-info/parlay-info.component';
import { SalesConversionReportComponent } from './sales-conversion-report/sales-conversion-report.component';
import { SalesConversionReportDetailsDialogComponent } from './sales-conversion-report/sales-conversion-report-details/sales-conversion-report-details.component';
import { TelemarketingPlayerSummaryReportComponent } from './telemarketing-player-summary-report/telemarketing-player-summary-report.component';

const routes: Routes = [
  {
    path: 'member-reports',
    component: MemberReportComponent
  },
  {
    path: 'all-member-reports',
    component: AllMemberReportsComponent
  },
  {
    path: 'providers',
    component: GameProviderReportComponent
  },
  {
    path: 'statistics-report',
    component: StatisticsReportComponent
  },
  {
    path: 'marketing-report',
    component: MarketingReportComponent
  },
  {
    path: 'daily-new-deposit',
    component: DailyNewDepositReportComponent
  },
  {
    path: 'company-reports',
    component: CompanyReportComponent
  },
  {
    path: 'registration-reports',
    component: RegistrationReportComponent
  },
  {
    path: 'sms-recovery-reports',
    component: SMSRecoveryReportComponent
  },
  {
    path: 'marketing-weekly-reports',
    component: MarketingWeeklyReportComponent
  },
  {
    path: 'game-type-reports',
    component: GameTypeReportComponent
  },
  {
    path: 'telemarketer-reports',
    component: TelemarketerReportComponent
  },
  {
    path: 'agent-reports',
    component: AgentReportsComponent
  },
  {
    path: 'all-agent-reports',
    component: AllAgentReportComponent
  },
  {
    path: 'all-affiliates-report',
    component: AllAffiliateReportComponent
  },
  {
    path: 'all-sub-affiliates-report',
    component: AllSubAffiliateReportComponent
  },
  {
    path: 'affiliate-first-time-depositor',
    component: AffiliateFirstDepositorReportComponent
  },
  {
    path: 'sales-conversion-report',
    component: SalesConversionReportComponent
  },
  {
    path:'telemarketer-player-summary',
    component: TelemarketingPlayerSummaryReportComponent,
    data: { title: '13.6 Telemarketing Player Summary' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  entryComponents: [
    ReportDetailsComponent,
    MarketingReportDetailsComponent,
    MemberReportDetailsDialogComponent,
    GameResultComponent,
    GameProviderReportDetailsComponent,
    AllAgentReportsDialogComponent,
    SMSRecoveryReportDetailsComponent,
    TicketInfoDialogComponent,
    ParlayInfoDialogComponent,
    SalesConversionReportDetailsDialogComponent
  ]
})

export class ReportsRoutingModule { }
