<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> {{game.game_provider_code}} Game Logs: {{member_account_username}} | {{game.ga_username}} </h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <div class="row">
            <div class="col-md-4 form-group required">
              <label class="control-label">Date <span class="text-danger">*</span></label>
              <div class="input-group date">
                <!-- // calendar only -->
                <input class="form-control" formControlName="date" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1">
                <span class="input-group-append" [owlDateTimeTrigger]="dt1">
                  <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                </span>
                <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
              </div>
            </div>
            <div class="col-md-2 form-group">
              <label class="control-label">Start Time <span class="text-danger"></span></label>
              <div class="input-group date">
                <!-- // calendar only -->
                <input class="form-control" formControlName="start_time" [owlDateTimeTrigger]="st1" [owlDateTime]="st1" placeholder="00:00">
                <span class="input-group-append" [owlDateTimeTrigger]="st1">
                  <span class="input-group-text pointer-calendar"><i class="far fa-clock"></i></span>
                </span>
                <owl-date-time [pickerType]="'timer'" #st1></owl-date-time>
              </div>
            </div>
            <div class="col-md-2 form-group ">
              <label class="control-label">End Time <span class="text-danger"></span></label>
              <div class="input-group date">
                <!-- // calendar only -->
                <input class="form-control" formControlName="end_time" [owlDateTimeTrigger]="et1" [owlDateTime]="et1" placeholder="23:59">
                <span class="input-group-append" [owlDateTimeTrigger]="et1">
                  <span class="input-group-text pointer-calendar"><i class="far fa-clock"></i></span>
                </span>
                <owl-date-time [pickerType]="'timer'" #et1></owl-date-time>
              </div>
            </div>
            <ng-container *ngIf="game.game_provider_code == 'MEGA' || game.game_provider_code == 'MEGAB'">
              <div class="col-md-2 form-group required">
                <label class="control-label">Type: <span class="text-danger"></span></label>
                <select class="form-control" formControlName="type">
                  <option [value]="null" [disabled]="true">Please Select</option>
                  <option [value]="value" *ngFor="let value of dropdown.memberGameLogType"> {{ value }}
                  </option>
                </select>
              </div>
            </ng-container>
            <div class="col-md-2 form-group required">
              <label class="control-label">Page: <span class="text-danger">*</span></label>
              <div class="input-group">
                <input class="form-control" formControlName="page" >
                <owl-date-time [pickerType]="'timer'" #et1></owl-date-time>
              </div>
            </div>
            <small id="passwordHelpBlock" class="form-text text-muted w-100 pl-3">The date and time will be based on UTC+8 timezone.</small>
            <small *ngIf="game.game_provider_code == '918KISS' || game.game_provider_code == '918BOT'" class="form-text text-muted w-100 pl-3"><span class="text-danger">*</span>Known issue where the DateTime provided by 918KISS is delayed by 15 minute from the actual transaction date and time.</small>

          </div>
          <ng-container *ngIf="loading">
                <div class="spinner-wrapper">
                  <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                </div>
          </ng-container>
          <div class="table-responsive" id="contentTable" [innerHtml]="tableContent" AppMotationObserver (innerHtmlRendered)="htmlRender()" >
          </div>
          <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
            <div class="dropdown dropdown-inline">
              <button type="button" class="btn btn-secondary mr-2" (click)="onChangePage('previous')" ><i class="fas fa-angle-double-left"></i>Previous</button>
              <button type="button" class="btn btn-secondary mr-2" (click)="onChangePage('next')" >Next <i class="fas fa-angle-double-right"></i></button>
            </div>
            <div class="dropdown dropdown-inline">
              <button type="button" class="btn btn-secondary mr-2" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
              <kt-submit-button [buttonLoading]="buttonLoading" (confirmed)="onSubmit()"></kt-submit-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
