<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} IP Whitelisting</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
            <div class="col-12 form-group row">
              <label class="col-4 control-label">Merchant<span class="text-danger">*</span></label>
              <select formControlName="merchant_id" class="col-8 form-control">
                <option value="null" disabled hidden> Please Select </option>
                <option [value]="value.id" *ngFor="let value of dropdown.merchant | async"> {{ value.name }} </option>
              </select>
            </div>
            <div class="col-12 form-group row">
              <label class="col-4 control-label">IP<span class="text-danger">*</span></label>
              <input type="text" formControlName="ip" class="col-8 form-control">
            </div>
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Type<span class="text-danger">*</span></label>
            <select formControlName="type" class="col-8 form-control">
              <option value="null" disabled hidden> Please Select </option>
              <option [value]="item.id" *ngFor="let item of dropdown.type">{{ item.name }}</option>
            </select>
          </div>

          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Expiry Date</label>
            <div class="col-8 input-group date" [owlDateTimeTrigger]="dateStartRef" >
              <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack" [ngModelOptions]="{standalone: true}">
              <input type="text" formControlName="expiry_date" class="form-control">
              <span class="input-group-append">
                  <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
              </span>
              <owl-date-time #dateStartRef></owl-date-time>
            </div>
          </div>

          <div class="col-12 form-group row" *ngIf="data.mode === 'edit'">
            <label class="col-4 control-label">Status<span class="text-danger">*</span></label>
            <select formControlName="status" class="col-8 form-control">
              <option value="null" disabled hidden> Please Select </option>
              <option value=1> Enabled </option>
              <option value=0> Disabled </option>
              <option value=2> Expired </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button *ngIf="data.mode === 'edit' ? canEditIpWhitelisting : canCreateIpWhitelisting" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
        </div>
      </div>
    </form>
  </div>
  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true)"></kt-swal-alert>