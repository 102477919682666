<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
        <div class="dropdown dropdown-inline">
        </div>
        <div class="dropdown dropdown-inline">
          <button *ngIf="canViewAffiliateSettings" class="btn btn-setting btn-icon-sm mr-2" [disabled]="loading" (click)="onOpenDialog('affiliateSettings')"><i class="fas fa-users-cog"></i>Affiliate Settings</button>
        </div>
      </div>
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="sort-enabled" (click)="onSortColumn('settings_currency_id')" [ngClass]="sortingConfig.settings_currency_id" [class.sort-selected]="sortingSelection.sort_by === 'settings_currency_id'">{{ 'Currency' | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('validity_day')" [ngClass]="sortingConfig.validity_day" [class.sort-selected]="sortingSelection.sort_by === 'validity_day'">{{ 'Initial Lead Validity Period (Days)' | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('follow_up_day')" [ngClass]="sortingConfig.follow_up_day" [class.sort-selected]="sortingSelection.sort_by === 'follow_up_day'">{{ 'Follow Up Extension Period (Days)' | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('ftd_target_day')" [ngClass]="sortingConfig.ftd_target_day" [class.sort-selected]="sortingSelection.sort_by === 'ftd_target_day'">{{ 'FTD Target Extension Period (Days)' | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('inactive_period')" [ngClass]="sortingConfig.inactive_period" [class.sort-selected]="sortingSelection.sort_by === 'inactive_period'">{{ 'Inactive Period (Hours)' | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('target_deposit_amount')" [ngClass]="sortingConfig.target_deposit_amount" [class.sort-selected]="sortingSelection.sort_by === 'target_deposit_amount'">{{ 'Target Deposit Amount' | translate }}</th>
                  <th class="text-center">{{ 'Tier Progression Threshold' | translate }}</th>
                  <th class="sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">{{ 'Updated By' | translate }}</th>
                  <th class="text-center">{{ 'Actions' | translate }}</th>
                </tr>
              </thead>
              <tbody *ngIf="(telemarketerSettings$ | async) as rows">
                <tr *ngFor="let row of rows">
                  <td>{{ row.currency_code }}</td>
                  <td class="text-right">{{ row.validity_day }}</td>
                  <td class="text-right">{{ row.follow_up_day }}</td>
                  <td class="text-right">{{ row.ftd_target_day }}</td>
                  <td class="text-right">{{ row.inactive_period }}</td>
                  <td class="text-right">{{ row.target_deposit_amount | number : '1.2-2' }}</td>
                  <td>{{ row.tier0 }}<br>{{ row.tier1 }}<br>{{ row.tier2 }}<br>{{ row.tier3 }}</td>
                  <td>
                    {{ row.updated_by ? row.updated_by : 'System' }} <br />
                    <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ isValidDate(row.updated_at) ? (row.updated_at | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }}</span>
                    <ng-template #updatedDateTime>
                      <ng-container *ngIf="isValidDate(row.updated_at)">
                        {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                      </ng-container>
                    </ng-template>
                  </td>
                  <td class="text-center">
                    <button *ngIf="canEditTelemarketerSetting" matTooltip="{{ 'Edit' | translate }}" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
                    <button *ngIf="canViewTelemarketerSettingLog" matTooltip="{{ 'Show Setting History' | translate }}" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('show-history', row)"><i class="fas fa-history"></i></button>
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                {{ 'Showing' | translate }} {{pagination.from}} {{ 'to' | translate }} {{pagination.to}} {{ 'of' | translate }} {{ pagination.total | number : '1.0' }} {{ 'records' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>