<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Rebates Details: <span class="p-l-0">{{ data.rebateRelease.username }}</span> | <span>{{ data.rebateRelease.start_datetime | timezoneDate: 'YYYY-MM-DD HH:mm': currencyTimezone[data.rebateRelease.currency] }} - {{ data.rebateRelease.end_datetime | timezoneDate: 'YYYY-MM-DD HH:mm': currencyTimezone[data.rebateRelease.currency] }}</span>
                  </h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="col-12 form-group">
                  <div class="table-responsive mb-2">
                    <table class="table table-bordered table-hover table-striped">
                      <thead>
                          <tr>
                              <th class="text-center">Game Username</th>
                              <th class="text-center">Member Group</th>
                              <th class="text-center">Game Provider</th>
                              <th class="text-center">Category</th>
                              <td class="text-center">Sub Category</td>
                              <th class="text-center">Currency</th>
                              <th class="text-center date-column">Date</th>
                              <th class="text-center">Valid Turnover</th>
                              <th class="text-center">Promo Turnover</th>
                              <th class="text-center">Rate</th>
                              <th class="text-center">W/L</th>
                              <th class="text-center">Rebate Amount</th>
                              <th class="text-center">Eligible Amount</th>
                          </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let row of data.details">
                            <td>   
                                <ng-container *ngIf="canViewMemberBetLogDialog; else elseBlock">
                                    <a class="text-primary font-weight-bold" (click)="onOpenDialog(row)">
                                        {{ row.game_account }}
                                    </a>
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{ row.game_account }}
                                </ng-template>
                            </td>
                            <td>{{ row.member_group_name }}</td>
                            <td>{{ row.game_provider_name }}</td>
                            <td>{{ row.category_name }}</td>
                            <td>{{ row.game_provider_sub_categories_name }}</td>
                            <td>{{ row.currency }}</td>
                            <td>
                                {{ row.start_datetime | timezoneDate: 'YYYY-MM-DD HH:mm': currencyTimezone[row.currency] }} - {{ row.end_datetime | timezoneDate: 'YYYY-MM-DD HH:mm': currencyTimezone[row.currency] }}
                            </td>
                            <td class="text-right">
                                {{ row.valid_turnover | number : '1.2' }}
                            </td>
                            <td class="text-right">{{ row.promo_turnover | number : '1.2' }}</td>
                            <td>{{ row.rate | number : '1.0' }}</td>
                            <td class="text-right">{{ row.winloss | number : '1.2' }}</td>
                            <td class="text-right">{{ row.rebate_amount | number : '1.2' }}</td>
                            <td class="text-right">{{ row.eligible_amount | number : '1.2' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
              </div>
        </div>
</div>

