<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">CCID Response</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <div class="col-12">
        <div class="kt-section mb-0">
            <div class="kt-section__content">
                <div *ngIf="!loading" class="table-responsive">
                    <table class="table table-bordered table-hover table-striped">
                      <tbody>
                          <div [innerHTML]="ccidHtml"></div>
                      </tbody>
                    </table>
                </div>
                <div *ngIf="loading" class="spinner-wrapper">
                  <mat-progress-spinner mode="indeterminate" class="spinner" diameter="30"></mat-progress-spinner>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
  </div>
</div>
