<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Clear Cache</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="kt-checkbox label-cache">
                        <input type="checkbox" (change)="onSelectAll($event)" id="checkbox-parent-clear" /> Clear All <span ></span>
                        <input type="hidden" formControlName="cache_tag">
                    </label>
                </div>
                <div class="form-group" *ngFor="let value of dropdown.cache | async | keyvalue; let parentIndex = index">
                    <label class="kt-checkbox label-cache-title">
                        <input type="checkbox" (change)="onSelectSection(parentIndex, $event)" [id]="'checkbox-parent-'+ parentIndex" [attr.data-length]="(value.value).length" />
                        {{ value.key }} <span ></span>
                    </label>
                    <div class="row ml-4">
                        <div class="col-sm-6 col-xs-12" *ngFor="let item of value.value">
                            <label class="kt-checkbox label-cache">
                                <input type="checkbox" [id]="'checkbox-item-'+ parentIndex + '-' + item.id" [value]="+item.id" (change)="onSelected($event, parentIndex)"> {{ item.name }} <span></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [isDisabled]="selectedItems.length <= 0" [buttonLoading]="buttonLoading" (confirmed)="onClearCache()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>

