import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { SampleMessageDataService } from './services/sample-message-data.service';
import { tap, map, exhaustMap, catchError } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { SampleMessageEntityService } from './services/sample-message-entity.service';
import { SampleMessage } from '@core/models/sample-message.model';
import { Observable, Subscription, interval, of, forkJoin } from 'rxjs';
import { SampleMessageEditDialogComponent } from './dialogs/sample-message-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { SubheaderService } from '@core/_base/layout';
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { Status } from '@core/enums/status.enum';
import { LoadingBarService } from '@ngx-loading-bar/core';
import Swal from 'sweetalert2';

@Component({
  templateUrl: './sample-message.component.html',
  styleUrls: ['./sample-message.component.scss']
})
export class SampleMessageComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    roles: this.dropdownHttpService.roles,
    perPage: this.dropdownHttpService.perPage
  };
  status = Status;
  sampleMessages$: Observable<SampleMessage[]>;
  messages$ = this.sampleMessageDataService.messages$;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '1.2';
  sortingConfig = {
    'id': 'desc',
    'status': 'desc',
    'position': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  
  buttonLoading = false;
  refreshStatus: boolean;

  private subscription = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private sampleMessageEntityService: SampleMessageEntityService,
    private sampleMessageDataService: SampleMessageDataService,
    private dropdownHttpService: DropdownHttpService
  ) { }

  ngOnInit() {

    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));

    this.reload();
    this.formInit();
    this.pagination = this.sampleMessageDataService.pagination;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.refreshSubcription.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if(key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if(this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.sampleMessages$ = this.sampleMessageEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.pagination = this.sampleMessageDataService.pagination;
        this.loading = false;
        this.dataLength = res.length;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event){
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, sampleMessageId?: number, id?: number) {
    if (sampleMessageId) {
      if (type === 'edit'){
        const sampleMessage = this.sampleMessageDataService.getById(sampleMessageId);
        this.subscription = sampleMessage.pipe(
          tap( (res) => {
            this.openDialogBy(SampleMessageEditDialogComponent, { sampleMessage: res, mode: 'edit' });
        })
        )
        .subscribe();
      }
      else{
          Swal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete this sample message?`,
            icon: 'info',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
          }).then((resp) => {
            if (resp.value) {
              this.subscription = forkJoin([
                this.sampleMessageDataService.deleteSampleMessage(sampleMessageId).pipe(
                  tap((res: any) => {
                    Swal.fire({
                      title: 'System Message',
                      text: res.message[0],
                      icon: 'info',
                    });
                    this.form.setErrors(null);
                    this.messages$.next([...res.message]);
                    this.reload();                    
                  }),
                  catchError((error) => {
                    this.form.setErrors(null);
                    throw error;
                  })
                )
              ]).subscribe();
            }
          });
      }
    } else {
      this.openDialogBy(SampleMessageEditDialogComponent, { mode: 'create' });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.sampleMessages$ = this.sampleMessageEntityService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.sampleMessageDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }


  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private openDialogBy(componentRef: any, data?: { sampleMessage?: any, mode?: any, sampleMessageId?: any}) {
    const dialogRef = this.dialog.open(componentRef, {
      // width: '80vw',
      data: {
        sampleMessage: data.sampleMessage,
        mode: data.mode,
        sampleMessageId: data.sampleMessageId
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true){
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      preset_msg: new FormControl(null),
      position: new FormControl(null),
      status: new FormControl('all')
    });
  }

  private refreshEvery(seconds: number) {
    const refreshSource = interval(seconds * 1000);
    refreshSource.subscribe(() => {
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
          this.reload();
        }
      });
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key =>  (formData[key] !== '' && formData[key] !== null) ? fields[key] = formData[key] : key);
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

}
