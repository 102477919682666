import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BankReceiptStatus } from '@core/enums/bank-receipt.enum';
import { BankReceipt } from '@core/models/bank-receipt.model';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { SubheaderService } from '@core/_base/layout/services/subheader.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from 'rxjs';
import { delay, exhaustMap, map, tap } from 'rxjs/operators';
import { BankReceiptVoidDialogComponent } from './dialogs/bank-receipt-void/bank-receipt-void.component';
import { BankReceiptDataService } from './services/bank-receipt-data.service';
import { BankReceiptEntityService } from './services/bank-receipt-entity.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { BankReceiptEditComponent } from './dialogs/bank-receipt-edit/bank-receipt-edit.component';
import { BankReceiptRemarksDialogComponent } from './dialogs/bank-receipt-remarks/bank-receipt-remarks.component';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-bank-receipts',
  templateUrl: './bank-receipts.component.html',
  styleUrls: ['./bank-receipts.component.scss']
})
export class BankReceiptsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    merchantBankAccounts: this.merchantBankHttpService.getMerchantBanksAccount(),
    bankReceiptStatuses: this.dropdownHttpService.bankReceiptStatuses,
    bankReceiptTypes: this.dropdownHttpService.bankReceiptTypes,
    bankReceiptTransactionTypes: this.dropdownHttpService.bankReceiptTransactionTypes,
    bankReceiptDateTimeTypes: this.dropdownHttpService.bankReceiptDateTimeTypes,
    perPage: this.dropdownHttpService.perPage
  };

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  bankaccountDropdownSettings = {};
  bankaccountDropdownList = [];
  bankaccountSelectedItems: any;

  status = BankReceiptStatus;
  bankReceipts$: Observable<BankReceipt[]>;
  defaultStart = this.transactionHttpService.getToday().from;
  defaultEnd = this.transactionHttpService.getToday().to;
  pagination: Pagination;
  pageSize = 100; // Only for BankReceipts
  page = 1;
  maxSize = 5;
  params = `start_date=${moment(this.defaultStart).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.defaultEnd).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}`;
  ranges: any;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;

  sortingConfig = {
    'id': 'desc',             // Column : ID
    'bank_account': 'desc',   // Column : Bank Account
    'receipt_type': 'desc',   // Column : Receipt Type
    'desc': 'desc',           // Column : Description
    'date_time': 'desc',      // Column : Transaction Timestamp
    'currency': 'desc',       // Column : Currency
    'amount': 'desc',         // Column : Amount
    'status': 'desc',         // Column : Status
    'remark': 'desc',         // Column : Remarks
    'created_at': 'desc',     // Column : Created By
    'updated_at': 'desc'      // Column : Updated By
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  // permissions
  canCreateBankReceipt: boolean;
  canEditBankReceipt: boolean;
  canMarkReceiptAsUsedReadOnly: boolean;
  canRejectReceipts: boolean;

  private subscriptions = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private dropdownHttpService: DropdownHttpService,
    private bankReceiptDataService: BankReceiptDataService,
    private bankReceiptEntityService: BankReceiptEntityService,
    private merchantBankHttpService: MerchantBankHttpService,
    private transactionHttpService: TransactionHttpService,
    private eventEmitterService: EventEmitterService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.pagination = this.bankReceiptDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;

    this.bankaccountDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      // maxHeight: 'auto',
      primaryKey: 'name',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };

    this.merchantBankHttpService.getMerchantBanksAccount('&bank_type=1').subscribe(
      res => {
        res.map(function (elm) {
          elm['name'] = elm['bank_code'] + ' - ' + elm['account_name'] + ' - ' + elm['account_number'];
        });
        this.bankaccountDropdownList = res;
        this.bankaccountDropdownList.unshift({
          id: 'all',
          name: 'All'
        });
        this.bankaccountSelectedItems = [this.bankaccountDropdownList.find(v => v.id === 'all')];
      }
    );

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateBankReceipt = appPermissions.create_bank_receipt;
      this.canEditBankReceipt = appPermissions.edit_bank_receipt;
      this.canMarkReceiptAsUsedReadOnly = appPermissions.mark_receipt_as_used_read_only;
      this.canRejectReceipts = appPermissions.reject_receipts;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onOpenDialog(type: string, bankReceiptData?: any) {
    if (type === 'create' || type === 'edit') {
      this.openDialogBy(BankReceiptEditComponent, { mode: type, bankReceipt: bankReceiptData });
    } else {
      this.openDialogBy(BankReceiptVoidDialogComponent, { bankReceipt: bankReceiptData, mode: type });
    }
  }

  onOpenRemarks(bankReceiptData: any) {
    this.openDialogBy(BankReceiptRemarksDialogComponent, {bankReceipt: bankReceiptData});
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.bankReceipts$ = this.bankReceiptEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.bankReceiptDataService.pagination;
        this.loading = false;
        this.dataLength = res.length;
        this.loadingBar.complete();
      })
    );
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.sortingSelection = {
      'sort_by': 'id',
      'sort_order': 'desc',
    };
    this.form.patchValue({
      'sort_by': this.sortingSelection.sort_by,
      'sort_order': this.sortingSelection.sort_order,
    });
    this.form.patchValue({
      id: null,
      keyword: null,
      currency_id: 'all',
      status: 'all',
      type: 'all',
      transaction_type: 'all',
      merchant_bank_id: 'all',
      date_time_type: 1,
      start_date: this.defaultStart,
      end_date: this.defaultEnd,
      defaultDate: {
        startDate: this.defaultStart,
        endDate: this.defaultEnd
      }
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.bankReceipts$ = this.bankReceiptEntityService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.bankReceiptDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onSortColumn(column: string) {
    if (this.sortingSelection.sort_by == column) {
      this.sortingSelection.sort_order = this.sortingSelection.sort_order == 'desc' ? 'asc' : 'desc';
    } else {
      this.sortingSelection.sort_by = column;
      this.sortingSelection.sort_order = 'desc';
    }
    this.form.patchValue({
      'sort_by': this.sortingSelection.sort_by,
      'sort_order': this.sortingSelection.sort_order,
    });
    this.onSubmit();
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private openDialogBy(componentRef: any, data?: { bankReceipt?: any, mode?: any, member?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        bankReceipt: data.bankReceipt,
        mode: data.mode
      },
      autoFocus: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      keyword: new FormControl(null),
      currency_id: new FormControl('all'),
      status: new FormControl('all'),
      type: new FormControl('all'),
      transaction_type: new FormControl('all'),
      merchant_bank_id: new FormControl('all'), // AKA Bank Account
      date_time_type: new FormControl(1),
      sort_by: new FormControl(this.sortingSelection.sort_by),
      sort_order: new FormControl(this.sortingSelection.sort_order),
      start_date: new FormControl(this.defaultStart),
      end_date: new FormControl(this.defaultEnd),
      defaultDate: new FormControl({
        startDate: this.defaultStart,
        endDate: this.defaultEnd
      }) // Do not remove: For Clearing The Range
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all' && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

}
