<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right  kt-margin-b-10">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                          <div class="col-12 row pr-0">
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Status:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                              <select  class="form-control" formControlName="status">
                                <option [value]="null" [disabled]="true">Please Select</option>
                                <option value="all">All</option>
                                <ng-container *ngFor="let value of dropdown.statuses; let i = index;">
                                  <option [value]="i">{{ value }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
                <div class="dropdown dropdown-inline">
                  <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                  <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table  class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                  <th >ID</th>
                                  <th>Content</th>
                                  <th>Locale</th>
                                  <th>Type</th>
                                  <th>Location</th>
                                  <th>Position</th>
                                  <th class="text-center">Status</th>
                                  <th class="date-column">Start Date</th>
                                  <th class="date-column">End Date</th>
                                  <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(announcements$ | async) as rows">
                              <tr *ngFor="let row of rows">
                                <td> {{ row.id }}  </td>
                                <td>
                                  <p>
                                    {{ row.content }}
                                  </p>
                                </td>
                                <td> {{ row.locale.name }}  </td>
                                <td> {{ announcementType[row.method] }}  </td>
                                <td> {{ row.location_name | titlecase }} </td>
                                <td> {{ row.position }} </td>
                                <td class="text-center">
                                  <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                                </td>
                                <td> {{ row.start_datetime | timezoneDate: 'YYYY-MM-DD HH:mm' }}  </td>
                                <td> {{ row.end_datetime | timezoneDate: 'YYYY-MM-DD HH:mm' }}  </td>
                                <td class="text-center">
                                  <div class="btn-toolbar">
                                    <div class="btn-group">
                                      <button  data-target="#AnnouncementEditModal" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)"><i  class="fas fa-edit"></i></button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                      <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                      </ngb-pagination>
                      <div class="kt-pagination__toolbar">
                        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                        </select>
                        <span class="pagination__desc">
                          Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                        </span>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
