
<form class="row align-items-center" [formGroup]="form">
  <!-- First Row -->
  <div class="col-12 row mb-2 pr-0">
    <div class="col-md-1 kt-form__label col-form-label">
      <label>ID:</label>
    </div>
    <div class="col-md-2 kt-form__control">
      <input type="text" formControlName="id" placeholder="Search" class="form-control">
    </div>
    <div class="col-md-1 kt-form__label col-form-label">
      <label>Username:</label>
    </div>
    <div class="col-md-2 kt-form__control">
      <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
    </div>
    <div class="col-md-1 kt-form__label col-form-label">
      <label>Name:</label>
    </div>
    <div class="col-md-2 kt-form__control">
      <input type="text" formControlName="name" placeholder="Search" class="form-control">
    </div>
    <div class="col-md-1 kt-form__label col-form-label">
      <label>Currency:</label>
    </div>
    <div class="col-md-2 kt-form__control">
      <select class="form-control" formControlName="currency_id" (change)="onCurrencyChange()">
        <option [value]="null" [disabled]="true">Please Select</option>
        <option value="all">All</option>
        <ng-container *ngFor="let value of dropdown.currencies">
          <option [value]="value.id">{{ value.name }}</option>
        </ng-container>
      </select>
    </div>
  </div>
  <!-- Second Row -->
  <div class="col-12 row mb-2 pr-0">
    <ng-container *ngIf="withdrawalBankType == 'banking'">
      <div class="col-md-1 kt-form__label col-form-label">
        <label>Date/Time:</label>
      </div>
      <div class="col-md-5 kt-form__control">
        <div class="input-group date">
          <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
          <span class="input-group-append">
            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
          </span>
        </div>
      </div>
      <div class=" col-md-1 kt-form__label col-form-label">
        <label class="mb-0">Payment Method:</label>
      </div>
      <div class="col-md-2 kt-form__control">
        <select class="form-control" formControlName="payment_method">
          <option [value]="null" [disabled]="true">Please Select</option>
          <option value="all">All</option>
          <ng-container *ngFor="let value of dropdown.paymentMethods | async">
            <option [value]="value.id">{{ value.name }}</option>
          </ng-container>
        </select>
      </div>
    </ng-container>
    <div class="col-md-1 kt-form__label col-form-label">
      <label class="mb-0">Member Group:</label>
    </div>
    <div class="col-md-2 kt-form__control">
      <select class="form-control" formControlName="member_group">
        <option [value]="null" [disabled]="true">Please Select</option>
        <option value="all">All</option>
        <ng-container *ngFor="let value of dropdown.groups | async">
          <option [value]="value.id">{{ value.name }}</option>
        </ng-container>
      </select>
    </div>
    <ng-container *ngIf="withdrawalBankType == 'crypto'">
      <div class="col-md-1 kt-form__label col-form-label">
        <label>Token:</label>
      </div>
      <div class="col-md-2 kt-form__control">
        <kt-dropdown-wo-lazyload [form]='form' [dropdownList]='tokensDropdownList' [dropdownSettings]='tokensDropdownSettings' [formName]="'crypto_token_id'" [selectionAttributes]="'id'" [selectedItems]='tokensSelectedItems'>
        </kt-dropdown-wo-lazyload>
      </div>
      <div class="col-md-1 kt-form__label col-form-label">
        <label>Amount:</label>
      </div>
      <div class="col-md-2 kt-form__control col-form-label">
        <select formControlName="currency_type" class="form-control" style="padding: 5px;">
          <option [value]="item.key" *ngFor="let item of dropdown.currency_type">{{ item.label }}</option>
        </select>
      </div>
      <div class="col-md-3 kt-form__control d-flex">
        <input type="text" formControlName="amount" placeholder="Search" class="form-control">
      </div>
    </ng-container>
  </div>
  <!-- Third Row -->
  <div class="col-12 row pr-0">
    <ng-container *ngIf="withdrawalBankType == 'banking'">
      <div class="col-md-1 kt-form__label col-form-label">
        <label>Merchant Bank:</label>
      </div>
      <div class="col-md-2 kt-form__control">
        <kt-dropdown-wo-lazyload [form]='form' [dropdownList]='dropdown.merchantBanks' [dropdownSettings]='bankaccountDropdownSettings' [formName]="'merchant_bank'" [selectionAttributes]="'id'" [selectedItems]='bankSelectedItems'>
        </kt-dropdown-wo-lazyload>
      </div>
      <div class="col-md-1 kt-form__label col-form-label">
        <label>Merchant Account:</label>
      </div>
      <div class="col-md-2 kt-form__control">
        <kt-dropdown-wo-lazyload [form]='form' [dropdownList]='bankaccountDropdownList' [dropdownSettings]='bankaccountDropdownSettings' [formName]="'merchant_bank_account'" [selectionAttributes]="'id'" [selectedItems]='bankaccountSelectedItems'>
        </kt-dropdown-wo-lazyload>
      </div>
    </ng-container>
    <ng-container *ngIf="withdrawalBankType == 'crypto'">
      <div class="col-md-1 kt-form__label col-form-label">
        <label>Date/Time:</label>
      </div>
      <div class="col-md-2 kt-form__control">
        <select formControlName="datetime_type" class="form-control">
          <option [value]="item.key" *ngFor="let item of dropdown.dateTimeFilterType">{{ item.label }}</option>
        </select>
      </div>
      <div class="col-md-3 kt-form__control">
          <div class="input-group date">
              <input type="hidden" formControlName="start_date" >
              <input type="hidden" formControlName="end_date" >
              <div class="input-group date">
                  <input type="text" class="form-control" placeholder="Search"
                  (change)="onDateRange($event)" formControlName="defaultDate"
                  [timePicker]="true" [timePickerSeconds]="true"
                  [alwaysShowCalendars]="true" [ranges]="ranges"
                  [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                  [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()"
                  ngxDaterangepickerMd/>
                  <span class="input-group-append">
                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                  </span>
              </div>
          </div>
      </div>
    </ng-container>
    <div class="col-md-1 kt-form__label col-form-label mt-2">
        <label>Unusual Callback:</label>
    </div>
    <div class="col-md-2 kt-form__control align-items-center d-flex">
        <!-- <input type="text" formControlName="id" placeholder="Search" class="form-control"> -->
        <label class="switch">
            <input type="checkbox" formControlName="unusual_callback">
            <div class="slider round">
              <span class="on">ON</span>
              <span class="off">OFF</span>
            </div>
          </label>
    </div>
  </div>
  <!-- Forth Row -->
  <div class="col-12 row pr-0">
    <div class="col-md-1 kt-form__label col-form-label">
      <label>Status:</label>
    </div>
    <div class="col-md-5 kt-form__control">
      <div class="d-flex flex-wrap status-filter-pt">
        <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.statuses ; let i = index">
          <input type="checkbox" [checked]="item.checked" (change)="onSearchStatus($event, item.value, i)" class="custom-control-input" [id]="item.name">
          <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 row mb-2 pr-0">
    <div class="col-md-1 kt-form__label col-form-label">
      <label>Account Type:</label>
    </div>
    <div class="col-md-2 kt-form__control">
      <select formControlName="account_type_condition" class="form-control" >
        <option value="And">AND Condition</option>
        <option value="Or">OR Condition</option>
      </select>
    </div>
    <div class="col-md-3 kt-form__control">
      <kt-dropdown-wo-lazyload class="dropdown-maxheight col-10" style="padding:0" [form]='form'
        [dropdownList]='accountTypeDropdownList' [dropdownSettings]='accountTypeDropdownSettings'
        [formName]="'account_type'" [selectionAttributes]="'id'"
        [selectedItems]='accountTypeSelectedItems'>
      </kt-dropdown-wo-lazyload>
    </div>
  </div>
</form>
<div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
  <div class="dropdown dropdown-inline">
    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
  </div>

  <div class="dropdown dropdown-inline">
    <button *ngIf="canExportWithdrawal" class="btn btn-warning btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="button_loading">
      <i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>
      Export
    </button>
    <button *ngIf="canCreateWithdrawal" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
  </div>
</div>
<div class="kt-section">
  <div class="kt-section__content">
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped table-sortable">
        <thead>
          <ng-container *ngIf="withdrawalBankType == 'banking'">
            <tr>
              <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
              <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
              <th class="text-center pl-5 pr-5 sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
              <th class="sort-enabled" (click)="onSortColumn('member_group')" [ngClass]="sortingConfig.member_group" [class.sort-selected]="sortingSelection.sort_by === 'member_group'">Member Group</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('amount')" [ngClass]="sortingConfig.amount" [class.sort-selected]="sortingSelection.sort_by === 'amount'">Amount</th>
              <th class="text-center sort-enabled min-w-150" (click)="onSortColumn('processing_fee')" [ngClass]="sortingConfig.processing_fee" [class.sort-selected]="sortingSelection.sort_by === 'processing_fee'">Processing Fee</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('confirmed_amount')" [ngClass]="sortingConfig.confirmed_amount" [class.sort-selected]="sortingSelection.sort_by === 'confirmed_amount'">Confirmed Amount</th>
              <th class="sort-enabled" (click)="onSortColumn('member_bank_account')" [ngClass]="sortingConfig.member_bank_account" [class.sort-selected]="sortingSelection.sort_by === 'member_bank_account'">Member Bank Account</th>
              <th>Remarks</th>
              <th class="date-column sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
              <th class="date-column" rowspan="2">Approved By</th>
              <th class="date-column sort-enabled" rowspan="2" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
              <th class="text-center">Actions</th>
            </tr>
          </ng-container>
          <ng-container *ngIf="withdrawalBankType == 'crypto'">
            <tr>
              <th class="sort-enabled" rowspan="2" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
              <th class="sort-enabled" rowspan="2" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
              <th class="text-center pl-5 pr-5 sort-enabled" rowspan="2" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
              <th class="sort-enabled" rowspan="2" (click)="onSortColumn('member_group')" [ngClass]="sortingConfig.member_group" [class.sort-selected]="sortingSelection.sort_by === 'member_group'">Member Group</th>
              <th class="text-center" colspan="3">Local Currency</th>
              <th class="text-center" colspan="3">Intermediary Currency</th>
              <th class="sort-enabled" rowspan="2" (click)="onSortColumn('cr_exchange_rate')" [ngClass]="sortingConfig.cr_exchange_rate" [class.sort-selected]="sortingSelection.sort_by === 'cr_exchange_rate'">Exchange Rate</th>
              <th class="sort-enabled" rowspan="2" (click)="onSortColumn('member_bank_account')" [ngClass]="sortingConfig.member_bank_account" [class.sort-selected]="sortingSelection.sort_by === 'member_bank_account'">Member Crypto Account</th>
              <th rowspan="2">Remarks</th>
              <th class="date-column sort-enabled" rowspan="2" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
              <th class="date-column" rowspan="2">Approved By</th>
              <th class="date-column sort-enabled" rowspan="2" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
              <th class="text-center" rowspan="2">Actions</th>
            </tr>
            <tr>
              <th class="text-center sort-enabled" (click)="onSortColumn('amount')" [ngClass]="sortingConfig.amount" [class.sort-selected]="sortingSelection.sort_by === 'amount'">Amount</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('processing_fee')" [ngClass]="sortingConfig.processing_fee" [class.sort-selected]="sortingSelection.sort_by === 'processing_fee'">Processing Fee</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('confirmed_amount')" [ngClass]="sortingConfig.confirmed_amount" [class.sort-selected]="sortingSelection.sort_by === 'confirmed_amount'">Confirmed Amount</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('cr_amount')" [ngClass]="sortingConfig.cr_amount" [class.sort-selected]="sortingSelection.sort_by === 'cr_amount'">Amount</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('cr_processing_fee')" [ngClass]="sortingConfig.cr_processing_fee" [class.sort-selected]="sortingSelection.sort_by === 'cr_processing_fee'">Processing Fee</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('cr_confirmed_amount')" [ngClass]="sortingConfig.cr_confirmed_amount" [class.sort-selected]="sortingSelection.sort_by === 'cr_confirmed_amount'">Confirmed Amount</th>
            </tr>
          </ng-container>
        </thead>
        <tbody *ngIf="(withdrawals$ | async) as rows">
          <tr *ngFor="let row of rows" [ngClass]="{'unusual-callback': row.unusual_callback == 1}">
            <td>{{ row.id }}</td>
            <td>
              <a *ngIf="canViewMemberDialog" [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', row.member_id)">
                {{ row.username }}
              </a>
              <ng-container [ngClass]="row.dummy == 1 ? 'text-dummy-unclickable' : 'text-primary'" *ngIf="!canViewMemberDialog">{{ row.username }}</ng-container>
              <i *ngIf="row.suspicious" class="fa fa-exclamation text-danger"></i>
            </td>
            <td class="text-center username-col">
              <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="row.status === 4 ? 'kt-badge--10' : 'kt-badge--' + row.status"> {{ row.status_name }}</span>
              <div class="suspicious-ribbon" matTooltip="Unusual Callback" *ngIf="row.unusual_callback == 1">
                <i  class="fa fa-exclamation"></i>
            </div>
            </td>
            <td>{{ row.member_group }}</td>
            <td *ngIf="withdrawalBankType == 'banking'" class="text-center">{{ row.currency }}</td>
            <td class="text-right">
              <!-- {{ (!isAdmin && createRightsOnly && row.status === 0 && row.handler === null) ? '****' : (row.amount !== '***' ? (row.amount | number : '1.2-2') : row.amount) }} -->
                {{ canShowAmount(row.amount, '1.2-2') }}
            </td>
            <td>
              <div class="processing-fee-row">
                <span class="processing-fee-label"><b>Player</b>: </span>
                <!-- <span class="processing-fee-amount"><b>{{ row.member_processing_fee | number : '1.2-2' }}</b></span> -->
                <span class="processing-fee-amount"><b>{{ canShowAmount(row.member_processing_fee, '1.2-2') }}</b></span>
              </div>
              <div class="processing-fee-row">
                <span class="processing-fee-label">Company: </span>
                <!-- <span class="processing-fee-amount">{{ row.processing_fee | number : '1.2-2' }}</span> -->
                <span class="processing-fee-amount">{{ canShowAmount(row.processing_fee, '1.2-2') }}</span>
              </div>
              <div class="processing-fee-row">
                <span class="processing-fee-label">Total: </span>
                <!-- <span class="processing-fee-amount">{{ +row.member_processing_fee + +row.processing_fee | number : '1.2-2' }}</span> -->
                <span class="processing-fee-amount">{{ canShowAmount(+row.member_processing_fee + +row.processing_fee, '1.2-2') }}</span>
              </div>
            </td>
            <td class="text-right">
              <!-- {{ (!isAdmin && createRightsOnly && row.status === 0 && row.handler === null) ? '****' : (row.confirmed_amount !== '***' ? (row.confirmed_amount | number : '1.2-2') : row.confirmed_amount) }} -->
              {{ canShowAmount(row.confirmed_amount, '1.2-2') }}
            </td>
            <td *ngIf="withdrawalBankType == 'banking'">{{ row.member_bank_account }}</td>
            <td *ngIf="withdrawalBankType == 'crypto'" class="text-right">
              <!-- {{ (!isAdmin && createRightsOnly && row.status === 0 && row.handler === null) ? '****' : (row.cr_amount !== '***' ? (row.cr_amount | number : '1.2-6') : row.cr_amount) }} -->
              {{ canShowAmount(row.cr_amount, '1.6-6') }}
            </td>
            <td *ngIf="withdrawalBankType == 'crypto'">
              <div class="processing-fee-row">
                <span class="processing-fee-label"><b>Player</b>: </span>
                <!-- <span class="processing-fee-amount"><b>{{ row.cr_member_processing_fee | number : '1.2-6' }}</b></span> -->
                <span class="processing-fee-amount"><b>{{ canShowAmount(row.cr_member_processing_fee, '1.6-6') }}</b></span>
              </div>
              <div class="processing-fee-row">
                <span class="processing-fee-label">Company: </span>
                <!-- <span class="processing-fee-amount">{{ row.cr_processing_fee | number : '1.2-6' }}</span> -->
                <span class="processing-fee-amount">{{ canShowAmount(row.cr_processing_fee, '1.6-6') }}</span>
              </div>
              <div class="processing-fee-row">
                <span class="processing-fee-label">Total: </span>
                <!-- <span class="processing-fee-amount">{{ +row.cr_member_processing_fee + +row.cr_processing_fee | number : '1.2-6' }}</span> -->
                <span class="processing-fee-amount">{{ canShowAmount((+row.cr_member_processing_fee + +row.cr_processing_fee), '1.6-6') }}</span>
              </div>
            </td>
            <td *ngIf="withdrawalBankType == 'crypto'" class="text-right">
              <!-- {{ (!isAdmin && createRightsOnly && row.status === 0 && row.handler === null) ? '****' : (row.cr_confirmed_amount !== '***' ? (row.cr_confirmed_amount | number : '1.2-6') : row.cr_confirmed_amount) }} -->
              {{ canShowAmount(row.cr_confirmed_amount, '1.6-6') }}
            </td>
            <td *ngIf="withdrawalBankType == 'crypto'"> {{ (row.cr_exchange_rate | number : '1.3-3') || '-' }} </td>
            <td *ngIf="withdrawalBankType == 'crypto'">{{ row.token }} - {{ row.network }} - {{ row.crypto_wallet_address }}</td>
            <td [ngClass]="row.remarks ? 'text-left' : 'text-center'">
                <ng-container *ngIf="(row.remarks); else nullTemplate">
                    <a class="text-primary font-weight-bold" (click)="onOpenDialog('show-remarks', row.id)">
                        {{ row.remarks }}
                    </a>
                </ng-container>
                <ng-template #nullTemplate>
                    -
                </ng-template>
            </td>
            <td>
              {{ row.created_username ? row.created_username : 'System' }}<br/>
              <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #createdDateTime>
                {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
            </td>
            <td>
              <ng-container *ngIf="row.approved_rejected_name  else noData">
                {{ row.approved_rejected_name ? row.approved_rejected_name : 'System' }}<br/>
                <ng-container *ngIf="row.approved_at != null && row.rejected_at == null else rejected">
                  <span placement="bottom" [ngbTooltip]="approvedDateTime" container="body" class="text-ellipsis">{{ row.approved_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                  <ng-template #approvedDateTime>
                    {{ row.approved_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                  </ng-template>
                </ng-container>
                <ng-template #rejected>
                  <span placement="bottom" [ngbTooltip]="rejectedDateTime" container="body" class="text-ellipsis">{{ row.rejected_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                  <ng-template #rejectedDateTime>
                    {{ row.rejected_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                  </ng-template>
                </ng-template>
              </ng-container>
              <ng-template #noData>
                <ng-container *ngIf="row.status == 1 || row.status == 2 else displayNone">
                  {{ row.approved_rejected_name ? row.approved_rejected_name : 'System' }}<br/>
                  <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                  <ng-template #updatedDateTime>
                    {{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                  </ng-template>
                </ng-container>
                <ng-template #displayNone>
                  -
                </ng-template>
              </ng-template>
            </td>
            <td>
              {{ row.handler ? row.handler : 'System' }}<br/>
              <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #updatedDateTime>
                {{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
            </td>
            <td class="text-center">
              <button *ngIf="canViewWithdrawalDetails" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" [disabled]="disabledEditButton" (click)="onOpenDialog('edit', row.id)"><i class="fas fa-eye"></i></button>
            </td>
          </tr>
        </tbody>
        <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
      </table>
    </div>
    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
      <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
      </ngb-pagination>
      <div class="kt-pagination__toolbar">
        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
          <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
        </select>
        <span class="pagination__desc">
          Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
        </span>
      </div>
    </div>
  </div>
</div>
