import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { ManualSyncHttpService } from '@core/services/manual-sync-http-service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'kt-manual-sync',
  templateUrl: './manual-sync.component.html',
  styleUrls: ['./manual-sync.component.scss']
})
export class ManualSyncDialogComponent implements OnInit {

  form: FormGroup;
  subscription = new Subscription();
  messages$ = this.manualSyncHttpService.messages$;
  dropdown = {
    gameProviders: [],
  }

  sentRequest = false;

  constructor(
    public dialogRef: MatDialogRef<ManualSyncDialogComponent>,
    private manualSyncHttpService: ManualSyncHttpService,
    private dropdownHttpService: DropdownHttpService,

  ) { }

  ngOnInit() {
    this.formInit();
    this.dropdownHttpService.gameProviders.subscribe(res => {
      this.dropdown.gameProviders = res;
    });
  }

  onSync() {
    this.sentRequest = true;
    this.manualSyncHttpService.manualSync(this.form.value).subscribe((res) => {
      this.manualSyncHttpService.messages$.next(res.message);
      this.sentRequest = false;
    });
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  private formInit() {
    this.form = new FormGroup({
      game_provider_code: new FormControl(null, [Validators.required])
    });
  }

}
