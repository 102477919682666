import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IpWhitelisting } from '@core/models/ip-whitelisting.model';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { IpWhitelistingEditComponent } from './dialog/ip-whitelisting-edit.component';
import { IpWhitelistingDataService } from './services/ip-whitelisting-data.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Subscription, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-ip-whitelisting',
  templateUrl: './ip-whitelisting.component.html',
  styleUrls: ['./ip-whitelisting.component.scss']
})
export class IpWhitelistingComponent implements OnInit, OnDestroy {

  form: FormGroup;
  ip$ = [];
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dropdown = {
    merchant: this.dropdownHttpService.merchantSites,
    type: this.dropdownHttpService.ipTypes,
    perPage: this.dropdownHttpService.perPage,
  }
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';

  // permissions
  canCreateIpWhitelisting: boolean;
  canEditIpWhitelisting: boolean;

  private subscriptions = new Subscription();

  constructor(
    private ipWhitelistingDataService: IpWhitelistingDataService,
    private loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.onSubmit(true);

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateIpWhitelisting = appPermissions.create_ip_whitelist;
      this.canEditIpWhitelisting = appPermissions.edit_ip_whitelist;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.ip$ = [];
    this.loading = true;
    this.loadingBar.start();
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        tap((data: any) => {
          this.params = Object.keys(data).length > 0 ? '&' + Object.keys(data).map(key => key + '=' + data[key]).join('&') : '';
          this.ipWhitelistingDataService.getWithQuery(`page=${this.page}&perPage=${this.pageSize}${this.params}`).pipe(
            tap(res => {
              this.ip$ = res;
              this.loading = false;
              this.clearBtnLoading = false;
              this.searchBtnLoading = false;  
              this.page = 1;
              this.pagination = this.ipWhitelistingDataService.pagination;
              this.loadingBar.complete();
            })).subscribe();
        })
      ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      merchant_id: 'all',
      ip: null,
      type: 'all',
      status: 'all'
    });
    this.onSubmit(true);
  }

  onOpenDialog(mode: string, id?: number) {
    if (mode === 'create') {
      this.openDialogBy(IpWhitelistingEditComponent, { mode: mode });
    }
    else {
      this.ipWhitelistingDataService.getById(id).subscribe(res => {
        this.openDialogBy(IpWhitelistingEditComponent, {
          mode: mode,
          ipWhitelisting: res
        });
      });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.ip$ = [];
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.ipWhitelistingDataService.getWithQuery(`page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.ip$ = res;
        this.loading = false;
        this.pagination = this.ipWhitelistingDataService.pagination;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private openDialogBy(componentRef: any, data: { mode: string, ipWhitelisting?: IpWhitelisting }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      height: 'auto',
      data: {
        mode: data.mode,
        ip: data.ipWhitelisting
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy();
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      merchant_id: new FormControl('all'),
      ip: new FormControl(null),
      type: new FormControl('all'),
      status: new FormControl('all')
    });
  }

}
