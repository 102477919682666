import { tap, catchError } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CacheHttpService } from '@core/services/cache-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Subscription, Observable } from 'rxjs';
import Swal from 'sweetalert2';


@Component({
  selector: 'kt-clear-cache',
  templateUrl: './clear-cache.component.html',
  styleUrls: ['./clear-cache.component.scss']
})
export class ClearCacheDialogComponent implements OnInit {

  form: FormGroup;
  subscription = new Subscription();
  messages$ = this.cacheHttpService.messages$;
  cache$: Observable<Cache[]>;
  dropdown = {
    cache: this.dropdownHttpService.cache,
  }
  buttonLoading = false;

  selectedItems = [];

  checkboxes: any[]

  constructor(
    public dialogRef: MatDialogRef<ClearCacheDialogComponent>,
    private cacheHttpService: CacheHttpService,
    private dropdownHttpService: DropdownHttpService,

  ) { }

  ngOnInit() {
    this.formInit();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onClearCache() {
    Swal.fire({
      text: 'Are you sure you want to clear these caches?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.buttonLoading = !this.buttonLoading;
        this.cacheHttpService.flush(this.form.value).pipe(
          tap((res: any) => {
            this.cacheHttpService.messages$.next(res.message);
            this.buttonLoading = !this.buttonLoading;
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = !this.buttonLoading;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
      }
    });
  }

  onSelectAll(event: Event) {
    const input = (event.target as HTMLInputElement);
    const isChecked = input.checked;
    let checkboxes = document.querySelectorAll('[id^="checkbox"]') as NodeListOf<HTMLInputElement>;
    if (isChecked) {
      checkboxes.forEach(item => {
        item.checked = true;
        if (!this.selectedItems.includes(+item.value)) {
          this.selectedItems.push(+item.value);

        }
      });
    }else{
      checkboxes.forEach(item => {
        item.checked = false;
        this.selectedItems.splice(this.selectedItems.indexOf(+item.value), 1);
      });
    }
    this.form.patchValue({
      cache_tag: this.selectedItems.filter(i => i >= 1)
    })
  }

  onSelected(event: Event, parentIndex: number) {
    const input = (event.target as HTMLInputElement);
    const isChecked = input.checked;
    if(isChecked) {
      this.selectedItems.push(+input.value);
    } else {
      this.selectedItems.splice(this.selectedItems.indexOf(+input.value), 1);
    }

    // Set section as checked (when necessary)
    const parent = (document.querySelectorAll('[id^="checkbox-parent-'+ parentIndex + '"]') as NodeListOf<HTMLInputElement>)[0];
    const children = document.querySelectorAll('[id^="checkbox-item-'+ parentIndex + '"]') as NodeListOf<HTMLInputElement>;
    let countSelected = 0;

    // Count selections on current section
    children.forEach(i => {
      if(i.checked) {
        countSelected++
      }
    });

    // Compare selections against Section length
    if(+parent.dataset[`length`] === countSelected) {
      parent.checked = true;
    }

    this.form.patchValue({
      cache_tag: this.selectedItems
    })
  }

  onSelectSection(parentIndex: number, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const checkboxes = document.querySelectorAll('[id^="checkbox-item-'+ parentIndex + '"]') as NodeListOf<HTMLInputElement>;

    if(isChecked) { // Selected
      checkboxes.forEach(i => {
        i.checked = true;
        this.selectedItems.push(+i.value);
      });
    } else { // Unselected
      checkboxes.forEach(i => {
        i.checked = false;
        this.selectedItems.splice(this.selectedItems.indexOf(+i.value), 1);
      });
    }

    // Form Selection
    this.form.patchValue({
      cache_tag: this.selectedItems.filter(i => i >= 1)
    });
  }

  private formInit() {
    let id = null;
    this.form = new FormGroup({
      cache_tag: new FormControl(null, [Validators.required]),
    });
  }

}
