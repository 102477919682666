<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="align-items-center" [formGroup]="form">
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>ID:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="id" placeholder="Search" class="form-control">
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Parent:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="parent" placeholder="Search" class="form-control">
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies"> {{ value.name }} </option>
                                    </select>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Affiliate Group:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="affiliate_group_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.groups | async"> {{ value.name }} </option>
                                    </select>
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Keyword:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="keyword" placeholder="Search" class="form-control">
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <select class="form-control" formControlName="date_type">
                                                <option value="registration_date" selected>Registration Date</option>
                                                <option value="created_date">Created Date</option>
                                                <option value="updated_date">Updated Date</option>
                                            </select>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate"
                                        [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true"
                                        [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>  
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="d-flex flex-wrap status-filter-pt">
                                        <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.statuses; let i = index">
                                            <input type="checkbox" [checked]="item.checked" (change)="onSearchStatus($event, item.value, i)" class="custom-control-input" [id]="item.name">
                                            <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!--Action Button-->
            <div class="kt-section col-12 row mb-3 mt-1 d-flex justify-content-between">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <div>
                    <button *ngIf="canApproveAffiliate" class="btn btn-success btn-icon-sm mr-2" (click)="onOpenDialog('approve_selected')" [disabled]="!(selectedAffiliates.length > 0)"><i class="fas fa-check"></i>Approve Selected</button>
                    <button *ngIf="canRejectAffiliate" class="btn btn-danger btn-icon-sm mr-2" (click)="onOpenDialog('reject_selected')" [disabled]="!(selectedAffiliates.length > 0)"><i class="fas fa-ban"></i>Reject Selected</button>
                </div>
            </div>

            <!--Listing-->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th><input type="checkbox" [(ngModel)]="toggleAll" (change)="checkboxToggleAll()"></th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('parent_username')" [ngClass]="sortingConfig.parent_username" [class.sort-selected]="sortingSelection.sort_by === 'parent_username'">Parent</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('affiliate_group')" [ngClass]="sortingConfig.affiliate_group" [class.sort-selected]="sortingSelection.sort_by === 'affiliate_group'">Affiliate Group</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Application Details</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('register_date')" [ngClass]="sortingConfig.register_date" [class.sort-selected]="sortingSelection.sort_by === 'register_date'">Registration Info</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('remarks')" [ngClass]="sortingConfig.remarks" [class.sort-selected]="sortingSelection.sort_by === 'remarks'">Remarks</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                                    <th class="text-center">Actions</th>
                                </tr>

                            </thead>
                            <tbody *ngIf="affiliateApproval$.length > 0">
                                <tr *ngFor="let row of affiliateApproval$">
                                    <th [formGroup]="checkboxForm"><input type="checkbox" [formControlName]="row.id"></th>
                                    <td class="text-center">{{ row.id }}</td>
                                    <td class="text-center">{{ row.parent_username === null ? '-' : row.parent_username }}</td>
                                    <td>
                                        <a class="text-primary font-weight-bold" (click)="onOpenDialog('information', row)">
                                            {{ row.username }}
                                        </a>
                                        <br>
                                        <span class="bonus-hunter">
                                            <i class="fas fa-sms" matTooltip="Send SMS Notification" *ngIf="row.sms_notification === 1"></i>
                                            <i class="fas fa-envelope" matTooltip="Send Email Notification" *ngIf="row.email_notification === 1"></i>
                                        </span>
                                    </td>
                                    <td>{{ row.currency }}</td>
                                    <td>{{ row.affiliate_group }}</td>
                                    <td>
                                        <label class="font-weight-bold">{{ 'Name : ' }}</label>
                                        <span class="ml-2">{{ row.name }}</span>
                                        <br>
                                        <label class="font-weight-bold">{{ 'Mobile : ' }}</label>
                                        <span class="ml-2">{{ row.mobile }}</span>
                                        <br>
                                        <label class="font-weight-bold">{{ 'Email : ' }}</label>
                                        <span class="ml-2">{{ row.email }}</span>
                                    </td>
                                    <td class="width-170">        
                                        <span class="no-wrap">{{ (row.register_date | timezoneDate: 'YYYY-MM-DD HH:mm:ss')}}</span><br>
                                        <span placement="top" [ngbTooltip]="row.registration_ip" container="body" class="text-ellipsis"> {{ row.registration_ip }}</span>
                                        <ng-container *ngIf="urlIsValid(row.registration_site); else invalidRegistrationSite">
                                            <a [href]="row.registration_site" target="_blank"> {{ getUrl(row.registration_site) }}</a><br>
                                        </ng-container>
                                        <ng-template #invalidRegistrationSite>
                                            <a> {{ row.registration_site }}</a><br>
                                        </ng-template>
                                        {{ row.registration_locale_code }}     
                                    </td>
                                    <td class="text-center">
                                        <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status_name }}</span>
                                    </td>
                                    <td [ngClass]="row.remarks ? 'text-left' : 'text-center'">{{ row.remarks === '' ? '-' : row.remarks }}</td>
                                    <td>
                                        {{ row.created_by }}<br>
                                        <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="row.update_action == 1 || row.updated_by != null">
                                            {{ row.updated_by ? row.updated_by : 'System' }}<br>
                                            <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                            <ng-template #updatedDateTime>
                                                {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                            </ng-template>
                                        </ng-container>
                                    </td>
                                    <td class="text-center actions-column">
                                        <button matTooltip="Approve" *ngIf="row.status === 0 || row.status == 3" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('approve_single', row)"><i class="fas fa-check"></i></button>
                                        <button matTooltip="Reject" *ngIf="row.status === 0" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('reject_single', row)"><i class="fas fa-ban"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                            [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
