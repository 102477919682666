import { ApiResponse } from '@core/models/api-response.model';
import { tap, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';
import { SystemMaintenance } from '@core/models/system-maintenance.model';

@Injectable()
export class SystemMaintenanceDataService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(private http: HttpClient) { }

  getWithQuery(pageParam: string): Observable<SystemMaintenance[]> {
    return this.http.get<ApiResponse>(`/settingsystem${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getById(id: number): Observable<SystemMaintenance> {
    return this.http.get<ApiResponse>(`/settingsystem/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  add(data: SystemMaintenance): Observable<SystemMaintenance> {
    return this.http.post<ApiResponse>(`/settingsystem`, data).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  update(systemMaintenance: SystemMaintenance) {
    return this.http.put<ApiResponse>(`/settingsystem/${systemMaintenance.id}`, systemMaintenance).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
