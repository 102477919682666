import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MemberPromotionDataService } from '../../../member-promotions/services/member-promotion-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { PromotionTransferStatus } from '@core/enums/member-promotion-transfer-status.enum';

@Component({
  selector: 'member-promotion-history',
  templateUrl: './member-promotion-history.component.html',
  styleUrls: ['./member-promotion-history.component.scss']
})
export class MemberPromotionHistoryComponent implements OnInit {

  promotion_id: number;
  memberPromotionHistory$: Observable<any>;
  promotionTransferStatus = PromotionTransferStatus;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  loading = false;

  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '3.1';
  sortingConfig = {
    'id': 'desc',
    'status': 'desc',
    'amount': 'desc',
    'updated_at': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { promotion_id: number },
    public dialogRef: MatDialogRef<MemberPromotionHistoryComponent>,
    private loadingBar: LoadingBarService,
    private memberPromotionDataService: MemberPromotionDataService,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit(): void {
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.promotion_id = this.data.promotion_id;
    this.onViewPageBy();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.memberPromotionHistory$ = this.memberPromotionDataService.getPromotionHistory(`?page=${this.page}&perPage=${this.pageSize}&${this.generateSortingParam()}${params}`, this.data.promotion_id).pipe(
      tap(res => {
        this.loading = false;
        this.pagination = this.memberPromotionDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }


  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

}