import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { SeoSettingsDataService } from './services/seo-settings-data.service';
import { Pagination } from '@core/models/pagination.model';
import { Status } from '@core/enums/status.enum';
import { SeoSettingsEditComponent } from './dialogs/seo-settings-edit/seo-settings-edit.component';
import { RouteSettingsComponent } from './dialogs/route-settings/route-settings.component';
import { MetaTagSettingsComponent } from './dialogs/meta-tag-settings/meta-tag-settings.component';
import { Observable, Subscription, of } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-seo-settings',
  templateUrl: './seo-settings.component.html',
  styleUrls: ['./seo-settings.component.scss']
})
export class SeoSettingsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    merchant: this.dropdownHttpService.merchantSites,
    domain: this.dropdownHttpService.seoDomains,
    status: this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage,
  }
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  messages$ = this.seoSettingsDataService.messages$;
  seo$: Observable<[]>;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;
  status = Status;
  regions: any;

  // permissions
  canCreateSEOSettings: boolean;
  canEditSEOSettings: boolean;
  canViewRouteSettings: boolean;
  canViewMetaTagSettings: boolean;

  private subscriptions = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private seoSettingsDataService: SeoSettingsDataService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.pagination = this.seoSettingsDataService.pagination;
    this.dropdownHttpService.regions.subscribe(res => this.regions = res);

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateSEOSettings = appPermissions.create_seo_settings;
      this.canEditSEOSettings = appPermissions.edit_seo_settings;
      this.canViewRouteSettings = appPermissions.view_route_settings;
      this.canViewMetaTagSettings = appPermissions.view_meta_tag_settings;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.seo$ = this.seoSettingsDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          map(res => {
            res.forEach((item: any) => {
              item['country_name'] = this.regions.find(x => x.country_code == item.country_code)?.country;
            });
            return res;
          }),
          tap(res => {
            this.page = 1;
            this.dataLength = res.length;
            this.pagination = this.seoSettingsDataService.pagination;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.seo$ = this.seoSettingsDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      map(res => {
      res.forEach((item: any) => {
        item['country_name'] = this.regions.find(x => x.country_code == item.country_code)?.country;
      });
      return res;
    }),
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.seoSettingsDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(mode: string, seoSettings?: any) {
    switch (mode) {
      case 'create':
        this.openDialogBy(SeoSettingsEditComponent, { mode: mode, regions: this.regions });
        break;
      case 'route':
        this.openDialogBy(RouteSettingsComponent, { mode: mode });
        break;
      case 'edit':
        this.openDialogBy(SeoSettingsEditComponent, { mode: mode, seo: seoSettings, regions: this.regions });
        break;
      case 'metatag':
        this.dropdownHttpService.locales.pipe(
          map(res => {
            res = res.filter(x => x.country_code == seoSettings.country_code);
            return res;
          })
        ).subscribe(locales => {
          this.openDialogBy(MetaTagSettingsComponent, { mode: mode, seo: seoSettings, locales: locales }, true);
        });
        break;
    }
  }

  private openDialogBy(componentRef: any, data?: { mode: string, seo?: any, locales?: any, regions?: any }, disableClose?: boolean) {
    let attributes = {
      width: data.mode === 'metatag' ? '1800px' : data.mode === 'route' ? '1200px' : '800px',
      data,
    };
    if (disableClose) {
      attributes['disableClose'] = true;
    }
    const dialogRef = this.dialog.open(componentRef, attributes);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onSubmit();
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      merchant: new FormControl(1),
      domain: new FormControl(null),
      status: new FormControl('all'),
      country_code: new FormControl('all')
    });
  }

}
