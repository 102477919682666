import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthHttpService } from '@core/services/auth-http.service';
import { Subscription, Subject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiResponse } from '@core/models/api-response.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';

@Component({
  templateUrl: './account-timezone.component.html',
  styleUrls: ['./account-timezone.component.scss']
})
export class AccountTimezoneDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = new Subject<any[]>();
  hasError: boolean;
  subscription = new Subscription();
  buttonLoading = false;
  submitClicked = false;
  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones'))
  };
  timezonesDropdownSettings = {};
  timezonesSelectedItems = [];
  originalTimezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  constructor(
    public dialogRef: MatDialogRef<AccountTimezoneDialogComponent>,
    private authHttpService: AuthHttpService,
    private dropdownHttpService: DropdownHttpService,
    private eventEmitterService: EventEmitterService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.timezonesSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.originalTimezone);

    this.timezonesDropdownSettings = {
        singleSelection: true,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 150,
        primaryKey: 'timezone',
        labelKey: 'offset',
        noDataLabel: '',
        showCheckbox: false
    };
    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
    if (this.submitClicked) {
      window.location.reload();
    }
  }

  onChangeTimezone(newTimezone: string) {
    this.buttonLoading = true;
    this.submitClicked = true;
    this.subscription = this.authHttpService.changeTimezone({timezone: newTimezone}).pipe(
      tap((res) => {
        let userData = JSON.parse(localStorage.getItem('user_data'));
        userData = { ...userData, timezone: newTimezone}
        localStorage.setItem('user_data', JSON.stringify(userData));
        this.eventEmitterService.onUpdateTimezone();
        this.hasError = !!res.success;
        this.buttonLoading = false;
        this.messages$.next([...res.message]);
        this.form.setErrors(null);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
    })
    ).subscribe();
  }

  private formInit() {
    let timezone = this.originalTimezone;
    this.form = new FormGroup({
        timezone: new FormControl(timezone, [Validators.required])
    });
  }

}