import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { MenuBar } from '@core/models/menu-bar.model';

@Injectable()
export class MenuBarEntityService extends EntityCollectionServiceBase<MenuBar> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('MenuBar', serviceElementsFactory);
    }
}

