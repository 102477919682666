export enum DepositStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
  InProgress = 3,
  Risky = 4,
  PendingCallback = 5,
  Failed = 6,
  PendingReceipt = 7,
}
