import { MemberRemarksListComponent } from '../../../../shared/member-remarks-list/member-remarks-list.component';
import { AllAffiliateMemberDialogComponent } from './../../../../shared/all-affiliate-member-dialog/all-affiliate-member-dialog.component';
import { PdfDialogComponent } from './../../../../shared/pdf-dialog/pdf-dialog.component';
import { AffiliateAdjustmentDialogComponent } from './affiliate-adjustments/dialogs/affiliate-adjustments-edit.component';
import { AffiliateAdjustmentEntityService } from './affiliate-adjustments/services/affiliate-adjustment-entity.service';
import { AffiliateAdjustmentDataService } from './affiliate-adjustments/services/affiliate-adjustment-data.service';
import { AdjustGroupDialogComponent } from './all-affiliates/dialogs/adjust-group/adjust-group.component';
import { AffiliateApprovalComponent } from './affiliate-approval/affiliate-approval.component';
import { AffiliateApprovalDataService } from './affiliate-approval/services/affiliate-approval-data.service';
import { AffiliateApprovalEditDialogComponent } from './affiliate-approval/dialog/affiliate-approval-edit.component';
import { AffiliateApprovalEntityService } from './affiliate-approval/services/affiliate-approval-entity.service';
import { AffiliateDepositDetailsComponent } from './affiliate-deposits/dialog/deposit-details.component';
import { AffiliateDepositsComponent } from './affiliate-deposits/affiliate-deposits.component';
import { AffiliateDepositsDataService } from './affiliate-deposits/services/affiliate-deposits-data.service';
import { AffiliateDepositsEntityService } from './affiliate-deposits/services/affiliate-deposits-entity.service';
import { AffiliateInformationComponent } from './all-affiliates/dialogs/affiliate-information/affiliate-information.component';
import { AffiliateMemberComponent } from './all-affiliates/dialogs/affiliate-member/affiliate-member.component';
import { AffiliateWithdrawalDataService } from './affiliate-withdrawals/services/affiliate-withdrawal-data.service';
import { AffiliateWithdrawalEntityService } from './affiliate-withdrawals/services/affiliate-withdrawal-entity.service';
import { AffiliateWithdrawalsComponent } from './affiliate-withdrawals/affiliate-withdrawals.component';
import { AffiliateWithdrawEditDialogComponent } from './affiliate-withdrawals/dialog/affiliate-withdraw-edit.component';
import { AffiliateRemarkComponent } from './all-affiliates/dialogs/affiliate-remark/affiliate-remark.component';
import { AgentComponent } from './agent/agent.component';
import { AgentDataService } from './agent/services/agent-data.service';
import { AgentEditDialogComponent } from './agent/dialogs/agent-edit.component';
import { AgentEntityService } from './agent/services/agent-entity.service';
import { AgentTreeViewComponent } from './agent-tree-view/agent-tree-view.component';
import { AgentTreeViewDataService } from './agent-tree-view/services/agent-tree-view-data.service';
import { AgentTreeViewEntityService } from './agent-tree-view/services/agent-tree-view-entity.service';
import { AllAffiliatesComponent } from './all-affiliates/all-affiliates.component';
import { AllAffiliatesDataService } from './all-affiliates/services/all-affiliates-data.service';
import { AllAffiliatesEditDialogComponent } from './all-affiliates/dialogs/all-affiliates-edit/all-affiliates-edit.component';
import { AllAffiliatesPasswordDialogComponent } from './all-affiliates/dialogs/all-affiliates-password/all-affiliates-password.component';
import { AllEventsComponent } from './all-events/all-events.component';
import { AllEventsDataService } from './all-events/services/all-events-data.service';
import { AllEventsEditDialogComponent } from './all-events/dialogs/all-events-edit/all-events-edit.component';
import { AllEventsEntityService } from './all-events/services/all-events-entity.service';
import { AllLeadsAssignDialogComponent } from './all-leads/dialogs/all-leads-assign/all-leads-assign.component';
import { AffiliateSettingsDialogComponent } from './all-leads/dialogs/affiliate-settings/affiliate-settings.component';
import { AffiliateSettingsAddDialogComponent } from './all-leads/dialogs/affiliate-settings-add/affiliate-settings-add.component';
import { AllLeadsImportDialogComponent } from './all-leads/dialogs/all-leads-import/all-leads-import.component';
import { AllLeadsComponent } from './all-leads/all-leads.component';
import { AllLeadsDataService } from './all-leads/services/all-leads-data.service';
import { AllLeadsEntityService } from './all-leads/services/all-leads-entity.service';
import { AllPredictionsComponent } from './all-predictions/all-predictions.component';
import { AllPredictionsDataService } from './all-predictions/services/all-predictions-data.service';
import { AllPredictionsEntityService } from './all-predictions/services/all-predictions-entity.service';
import { AllTicketsComponent } from './all-tickets/all-tickets.component';
import { AllTicketsDataService } from './all-tickets/services/all-tickets-data.service';
import { AllTicketsEntityService } from './all-tickets/services/all-tickets-entity.service';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { BankTransactionDataService } from '../settings/bank-transaction/service/bank-transaction-data.service';
import { BankTransactionEntityService } from '../settings/bank-transaction/service/bank-transaction-entity.service';
import { BankTransactionResolver } from '@core/resolvers/bank-transaction.resolver';
import { BetLimitDialogComponent } from './groups/dialogs/bet-limit/bet-limit.component';
import { CallLogDialogComponent } from './my-leads/dialogs/call-logs/call-log.component';
import { CCIDLookupDialogComponent } from './ccid-lookup/ccid-lookup.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { CommissionReleasesComponent } from './commission-releases/commission-releases.component';
import { CommissionReleasesDataService } from './commission-releases/services/commission-releases-data.service';
import { CommissionReleasesEntityService } from './commission-releases/services/commission-releases-entity.service';
import { CommonModule, DecimalPipe } from '@angular/common';
import { CreditLimitHttpService } from '@core/services/credit-limit-http.service';
import { CryptoTokenHttpService } from '@core/services/crypto-token-http.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { DepositComponent } from './deposits/deposit.component';
import { DepositDataService } from './deposits/services/deposit-data.service';
import { DepositDetailsComponent } from './deposits/dialogs/deposit-details/deposit-details.component';
import { DepositEntityService } from './deposits/services/deposit-entity.service';
import { DepositLimitDialogComponent } from './groups/dialogs/deposit-limit/deposit-limit.component';
import { DepositResolver } from '@core/resolvers/deposit.resolver';
import { EntityDefinitionService, EntityDataService } from '@ngrx/data';
import { entityMetadata } from '../../../../app.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GameProviderBlacklistComponent } from './promotion-codes/dialogs/game-provider-blacklist/game-provider-blacklist.component';
import { GameProviderEditDialogComponent } from '../games/game-providers/dialogs/game-provider-edit.component';
import { GeneralRoutingModule } from './general-routing.module';
import { GenerateCommissionComponent } from './commission-releases/dialogs/generate-commission/generate-commission.component';
import { GroupComponent } from './groups/group.component';
import { GroupDataService } from './groups/services/group-data.service';
import { GroupEditDialogComponent } from './groups/dialogs/group-edit.component';
import { GroupEntityService } from './groups/services/group-entity.service';
import { GroupResolver } from '@core/resolvers/group.resolver';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LuckyDrawDialogComponent } from './lucky-draw-settings/dialogs/lucky-draw-edit.component';
import { LuckyDrawSettingsComponent } from './lucky-draw-settings/lucky-draw-settings.component';
import { LuckyDrawSettingsDataService } from './lucky-draw-settings/services/lucky-draw-settings-data.service';
import { LuckyDrawSettingsEntityService } from './lucky-draw-settings/services/lucky-draw-settings-entity.service';
import { MaterialModule } from '@views/pages/material/material.module';
import { MemberBankAccountComponent } from './members/dialogs/member-bank-account/member-bank-account.component';
import { MemberBankAccountEditDialogComponent } from './members/dialogs/member-bank-account/member-bank-account-edit/member-bank-account-edit.component';
import { MemberBankAccountVerificationComponent } from './member-bank-account-verification/member-bank-account-verification.component';
import { MemberBankAccountVerificationDataService } from './member-bank-account-verification/services/member-bank-account-verification-data.service';
import { MemberBankAccountVerificationDetailsComponent } from './member-bank-account-verification/dialogs/member-bank-account-verification-details/member-bank-account-verification-details.component';
import { MemberBankAccountVerificationRemarkComponent } from './member-bank-account-verification/dialogs/member-bank-account-verification-remark/member-bank-account-verification-remark.component';
import { MemberBankAccountVerificationEntityService } from './member-bank-account-verification/services/member-bank-account-verification-entity.service';
import { MemberBlacklistDialogComponent } from './members/dialogs/member-blacklist/member-blacklist.component';
import { MemberComponent } from './members/member.component';
import { MemberDataService } from './members/services/member-data.service';
import { MemberDeductDialogComponent } from './members/dialogs/member-deduct/member-deduct.component';
import { MemberEntityService } from './members/services/member-entity.service';
import { MemberGameAccountDialogComponent } from './members/dialogs/member-information/member-game-account/member-game-account.component';
import { MemberGameLogDialogComponent } from './members/dialogs/member-game-log/member-game-log.component';
import { MemberGameLogDetailsDialogComponent } from './members/dialogs/member-game-log-details/member-game-log-details.component';
import { MemberHttpService } from '@core/services/member-http.service';
import { MemberInformationDialogComponent } from './members/dialogs/member-information/member-information.component';
import { MemberMessageHistoryComponent } from './members/dialogs/member-history-messages/member-message-history.component';
import { MemberOnlineComponent } from './member-online/member-online.component';
import { MemberOnlineDataService } from './member-online/services/member-online-data.service';
import { MemberPasswordDialogComponent } from './members/dialogs/member-password/member-password.component';
import { MemberProfileDialogComponent } from './members/dialogs/member-profile/member-profile.component';
import { MemberPromotionComponent } from './member-promotions/member-promotion.component';
import { MemberPromotionDataService } from './member-promotions/services/member-promotion-data.service';
import { MemberPromotionEditDialogComponent } from './member-promotions/dialogs/member-promotion-edit.component';
import { MemberPromotionEntityService } from './member-promotions/services/member-promotion-entity.service';
import { MemberPromotionResolver } from '@core/resolvers/member-promotion.resolver';
import { MemberRemarksHistoryComponent } from './members/dialogs/member-remarks-history/member-remarks-history.component';
import { MemberResolver } from '@core/resolvers/member.resolver';
import { MemberRewardsDialogComponent } from './members/dialogs/member-rewards/member-rewards.component';
import { MemberTopupDialogComponent } from './members/dialogs/member-topup/member-topup.component';
import { MemberTraceComponent } from './member-trace/member-trace.component';
import { MemberTraceDataService } from './member-trace/services/member-trace-data.service';
import { MemberTraceEntityService } from './member-trace/services/member-trace-entity.service';
import { MemberVerificationComponent } from './member-verification/member-verification.component';
import { MemberVerificationDataService } from './member-verification/services/member-verification-data.service';
import { MemberVerificationDetailsComponent } from './member-verification/dialogs/member-verification-details/member-verification-details.component';
import { MemberVerificationEntityService } from './member-verification/services/member-verification-entity.service';
import { MemberWalletEventComponent } from './members/dialogs/member-wallet-event/member-wallet-event.component';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { MerchantBanksDialogComponent } from '../settings/merchant-banks/dialogs/merchant-banks/merchant-banks.component';
import { MessageDataService } from './members/dialogs/member-history-messages/services/message-data.service';
import { MessageEntityService } from './members/dialogs/member-history-messages/services/message-entity.service';
import { MyLeadsComponent } from './my-leads/my-leads.component';
import { MyLeadsDataService } from './my-leads/services/my-leads-data.service';
import { MyLeadsEntityService } from './my-leads/services/my-leads-entity.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PartialsModule } from '@views/partials/partials.module';
import { PromotionApplicationComponent } from './promotion-application/dialogs/promotion-application.component';
import { PromotionApplicationDataService } from './promotion-application/services/promotion-application-data.service';
import { PromotionApplicationEntityService } from './promotion-application/services/promotion-application-entity.service';
import { PromotionApplicationWrapperComponent } from './promotion-application/promotion-application-wrapper.component';
import { PromotionCategoryComponent } from './promotion-content-category/promotion-category.component';
import { PromotionCategoryM7Component } from './promotion-content-category-m7/promotion-category-m7.component';
import { PromotionCategoryDataService } from './promotion-content-category/services/promotion-category-data.service';
import { PromotionCategoryM7DataService } from './promotion-content-category-m7/services/promotion-category-m7-data.service';
import { PromotionCategoryEditDialogComponent } from './promotion-content-category/dialogs/promotion-category-edit.component';
import { PromotionCategoryM7EditDialogComponent } from './promotion-content-category-m7/dialogs/promotion-category-m7-edit.component';
import { PromotionCategoryEntityService } from './promotion-content-category/services/promotion-category-entity.service';
import { PromotionCategoryM7EntityService } from './promotion-content-category-m7/services/promotion-category-m7-entity.service';
import { PromotionCodeComponent } from './promotion-codes/promotion-code.component';
import { PromotionCodeDataService } from './promotion-codes/services/promotion-code-data.service';
import { PromotionCodeDetailsDialogComponent } from './promotion-codes/dialogs/promotion-code-details/promotion-code-details';
import { PromotionCodeEntityService } from './promotion-codes/services/promotion-code-entity.service';
import { PromotionCodeResolver } from '@core/resolvers/promotion-code.resolver';
import { PromotionContentComponent } from './promotion-contents/promotion-content.component';
import { PromotionContentDataService } from './promotion-contents/services/promotion-content-data.service';
import { PromotionContentEditDialogComponent } from './promotion-contents/dialogs/promotion-content-edit.component';
import { PromotionContentEntityService } from './promotion-contents/services/promotion-content-entity.service';
import { PromotionContentResolver } from '@core/resolvers/promotion-content.resolver';
import { PromotionCurrencyComponent } from './promotion-currency/promotion-currency.component';
import { PromotionCurrencyDataService } from './promotion-currency/services/promotion-currency-data.service';
import { PromotionCurrencyDetailsComponent } from './promotion-currency/dialogs/promotion-currency-details.component';
import { PromotionCurrencyEntityService } from './promotion-currency/services/promotion-currency-entity.service';
import { PromotionMemberListComponent } from './promotion-member-list/promotion-member-list.component';
import { PromotionMemberListDataService } from './promotion-member-list/services/promotion-member-list-data.service';
import { PromotionMemberDetailsComponent } from './promotion-member-list/dialogs/promotion-member-list-details.component';
import { PromotionHistoryComponent } from './members/dialogs/promotion-history/promotion-history.component';
import { PromotionMessageDataService } from './promotion-codes/dialogs/promotion-messages/services/promotion-message-data.service';
import { PromotionMessageDetailsComponent } from './promotion-codes/dialogs/promotion-message-details/promotion-message-details.component';
import { PromotionMessageEntityService } from './promotion-codes/dialogs/promotion-messages/services/promotion-message-entity.service';
import { PromotionMessagesComponent } from './promotion-codes/dialogs/promotion-messages/promotion-messages.component';
import { PromotionStatisticComponent } from './promotion-statistic/promotion-statistic.component';
import { PromotionStatisticDataService } from './promotion-statistic/services/promotion-statistic-data.service';
import { PromotionStatisticEntityService } from './promotion-statistic/services/promotion-statistic-entity.service';
import { RebateSettingsDialogComponent } from './groups/dialogs/rebate-settings/rebate-settings.component';
import { RecoveryPromotionComponent } from './recovery-promotion/recovery-promotion.component';
import { RecoveryPromotionDataService } from './recovery-promotion/services/recovery-promotion-data.service';
import { RecoveryPromotionEditDialogComponent } from './recovery-promotion/dialogs/recovery-promotion-edit.component';
import { RecoveryPromotionEntityService } from './recovery-promotion/services/recovery-promotion-entity.service';
import { ReferralDataService } from './referrals/services/referral-data.service';
import { ReferralEntityService } from './referrals/services/referral-entity.service';
import { ReferralsComponent } from './referrals/referrals.component';
import { ReferralSettingsComponent } from './referral-settings/referral-settings.component';
import { ReferralSettingsDataService } from './referral-settings/services/referral-settings-data.service';
import { ReferralSettingsEditDialogComponent } from './referral-settings/dialogs/referral-settings-edit.component';
import { ReferralSettingsEntityService } from './referral-settings/services/referral-settings-entity.service';
import { ReleaseCommissionsDialogComponent } from './commission-releases/dialogs/release-commissions/release-commissions.component';
import { RemarksHistoryComponent } from '../settings/merchant-banks/dialogs/remarks-history/remarks-history.component';
import { ReuploadDocumentDialogComponent } from './members/dialogs/member-bank-account/reupload-document/reupload-document.component';
import { RewardDetailsDialogComponent } from './rewards/dialogs/reward-details/reward-details.component';
import { RewardsComponent } from './rewards/rewards.component';
import { RewardsDataService } from './rewards/services/rewards-data.service';
import { RewardsEntityService } from './rewards/services/rewards-entity.service';
import { RewardsSettingsDialogComponent } from './lucky-draw-settings/dialogs/rewards-settings-edit/rewards-settings-edit.component';
import { SharedModule } from '@shared/shared.module';
import { StatusRemarksComponent } from './../settings/merchant-banks/dialogs/status-remarks/status-remarks.component';
import { TelemarketerRewardsComponent } from './telemarketer-rewards/telemarketer-rewards.component';
import { TelemarketerRewardsDataService } from './telemarketer-rewards/services/telemarketer-rewards-data.service';
import { TelemarketerRewardsEntityService } from './telemarketer-rewards/services/telemarketer-rewards-entity.service';
import { TelemarketerRewardDetailsDialogComponent } from './telemarketer-rewards/dialogs/telemarketer-reward-details/telemarketer-reward-details.component';
import { TelemarketerSettingsComponent } from './telemarketer-settings/telemarketer-settings.component';
import { TelemarketerSettingsEditDialogComponent } from './telemarketer-settings/dialogs/telemarketer-settings-edit/telemarketer-settings-edit.component';
import { TelemarketerSettingsLogComponent } from './telemarketer-settings/dialogs/telemarketer-settings-log/telemarketer-settings-log.component';
import { TelemarketerSettingsDataService } from './telemarketer-settings/services/telemarketer-settings-data.service';
import { TransactionComponent } from './transactions/transaction.component';
import { TransactionDataService } from './transactions/services/transaction-data.service';
import { TransactionEntityService } from './transactions/services/transaction-entity.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { TransactionWalletDialogComponent } from './transactions/dialogs/transaction-wallet/transaction-wallet.component';
import { TransferComponent } from './transfers/transfer.component';
import { TransferDataService } from './transfers/services/transfer-data.service';
import { TransferEditDialogComponent } from './transfers/dialogs/transfer-edit.component';
import { TransferEntityService } from './transfers/services/transfer-entity.service';
import { TransferResolver } from '@core/resolvers/transfer.resolver';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { TreeviewModule } from 'ngx-treeview';
import { UpdateResultDialogComponent } from './all-events/dialogs/update-result/update-result.component';
import { VipMemberVerificationDataService } from './vip-verification/services/vip-member-verification-data.service';
import { VipVerificationComponent } from './vip-verification/vip-verification.component';
import { WithdrawalComponent } from './withdrawals/withdrawal.component';
import { WithdrawalEditDialogComponent } from './withdrawals/dialogs/withdrawal-single/withdrawal-edit.component';
import { WithdrawalLimitDialogComponent } from './groups/dialogs/withdrawal-limit/withdrawal-limit.component';
import { WithdrawalMultiDialogComponent } from './withdrawals/dialogs/withdrawal-multi/withdrawal-multi.component';
import { WithdrawDataService } from './withdrawals/services/withdraw-data.service';
import { WithdrawEntityService } from '@views/pages/apps/general/withdrawals/services/withdraw-entity.service';
import { WithdrawResolver } from '@core/resolvers/withdraw.resolver';
import { AffiliateTransfersComponent } from './affiliate-transfers/affiliate-transfers.component';
import { AffiliateTransfersDataService } from './affiliate-transfers/services/affiliate-transfers-data.service';
import { AffiliateTransfersEntityService } from './affiliate-transfers/services/affiliate-transfers-entity.service';
import { AffiliateAdjustmentsComponent } from './affiliate-adjustments/affiliate-adjustments.component';
import { WhatsappCurrencyHttpService } from '@core/services/whatsapp-currency-http.service';
import { AffiliateGroupsComponent } from './affiliate-groups/affiliate-groups.component';
import { AffiliateGroupsEditDialogComponent } from './affiliate-groups/dialogs/affiliate-groups-edit/affiliate-groups-edit.component';
import { AffiliateGroupsDataService } from './affiliate-groups/services/affiliate-groups-data.service';
import { CommissionSettingsDialogComponent } from './affiliate-groups/dialogs/commission-settings/commission-settings.component';
import { TierRateSettingsDialogComponent } from './affiliate-groups/dialogs/tier-rate-settings/tier-rate-settings.component';
import { CurrencySettingsDialogComponent } from './affiliate-groups/dialogs/currency-settings/currency-settings.component';
import { AllLeadsCallLogComponent } from './all-leads/dialogs/all-leads-call-log/all-leads-call-log.component';
import { LiveChatComponent } from './live-chat/live-chat.component';
import { LiveChatDataService } from './live-chat/services/live-chat-data.service';
import { SampleMessageComponent } from './sample-messages/sample-message.component';
import { SampleMessageDataService } from './sample-messages/services/sample-message-data.service';
import { SampleMessageEditDialogComponent } from './sample-messages/dialogs/sample-message-edit.component';
import { SampleMessageEntityService } from './sample-messages/services/sample-message-entity.service';
import { SettlementSettingsComponent } from './affiliate-groups/dialogs/settlement-settings/settlement-settings.component';
import { RemarkDialogComponent } from './all-leads/dialogs/remark/remark.component';
import { ShowRemarkComponent } from './all-leads/dialogs/show-remark/show-remark.component';
import { SubAffiliateComponent } from './all-affiliates/dialogs/sub-affiliate/sub-affiliate.component';
import { EventTeamsDialogComponent } from './all-events/dialogs/event-teams/event-teams.component';
import { EventTeamsEditDialogComponent } from './all-events/dialogs/event-teams-edit/event-teams-edit.component';
import { BonusHunterAnalysisComponent } from './members/dialogs/bonus-hunter-analysis/bonus-hunter-analysis.component';
import { MemberLabelComponent } from './members/dialogs/member-label/member-label.component';
import { MemberLabelEditComponent } from './members/dialogs/member-label/member-label-edit/member-label-edit.component';
import { MemberLabelHttpService } from '@core/services/member-label-http.service';
import { CoreModule } from '@core/core.module';
import { TimezoneHttpService } from '@core/services/timezone-http.service';
import { ImportRewardsDialogComponent } from './rewards/dialogs/import-rewards/import-rewards.component';
import { LeadRemarksComponent } from './lead-remarks/lead-remarks.component';
import { LeadRemarksDataService } from './lead-remarks/services/lead-remarks-data.service';
import { LeadRemarksEntityService } from './lead-remarks/services/lead-remarks-entity.service';
import { ShowEvidenceComponent } from './my-leads/dialogs/show-evidence/show-evidence.component';
import { RequestJustificationDialogComponent } from './all-leads/dialogs/request-justification/request-justification.component';
import { RequestReceiptDialogComponent } from '@shared/request-receipt-dialog/request-receipt-dialog.component';
import { UrlTestFireDialogComponent } from './all-affiliates/dialogs/url-test-fire/url-test-fire.component';
import { ShowRemarkHistoryComponent } from './deposits/dialogs/show-remark-history/show-remark-history.component';
import { ViewReceiptComponent } from './deposits/dialogs/view-receipt/view-receipt.component';
import { StatusCheckDialogComponent } from './transfers/dialogs/status-check/status-check.component';
import { CreateMemberDialogComponent } from './my-leads/dialogs/create-member/create-member.component';
import { UploadReceiptComponent } from './deposits/dialogs/upload-receipt/upload-receipt.component';
import { GameProviderNewBlacklistComponent } from './promotion-codes/dialogs/game-provider-new-blacklist/game-provider-new-blacklist.component';
import { MemberPromotionHistoryComponent } from './members/dialogs/member-promotion-history/member-promotion-history.component';
import { TestFirePostbackLogsComponent } from './all-affiliates/dialogs/test-fire-postback-logs/test-fire-postback-logs.component';
import { MemberCryptoAccountEditDialogComponent } from './members/dialogs/member-bank-account/member-crypto-account-edit/member-crypto-account-edit.component';
import { MemberCryptoWalletHttpService } from '@core/services/member-crypto-wallet-http.service';
import { MemberPromotionEligibleDialogComponent } from './members/dialogs/member-promotion-eligible/member-promotion-eligible.component';
import { PromotionCodeMemberEligibleDialogComponent } from './promotion-codes/dialogs/promotion-code-member-eligible/promotion-code-member-eligible.component';
import { PromotionContentMemberEligibleDialogComponent } from './promotion-contents/dialogs/promotion-content-member-eligible/promotion-content-member-eligible.component';
import { RewardMemberEligibleDialogComponent } from './rewards/dialogs/reward-member-eligible/reward-member-eligible.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BasicInfoComponent } from './members/dialogs/member-information/tabs/basic-info/basic-info.component';
import { AccountManagementComponent } from './account-management/account-management.component';
import { AccountManagementDataService } from './account-management/services/account-management-data.service';
import { AccountManagementEntityService } from './account-management/services/account-management-entity.service';
import { AccountManagementLogDialogComponent } from './account-management/dialogs/account-managment-log/account-management-log-dialog.component';
import { AccountManagementKpiSettingsDialogComponent } from './account-management/dialogs/kpi-settings/kpi-settings-dialog.component';
import { AssignLeadDialogComponent } from './account-management/dialogs/assign-lead/assign-lead-dialog.component';
import { AccountManagementRemarkComponent } from './account-management/dialogs/account-management-remark/account-management-remark.component';
import { ViewAttachmentComponent } from './account-management/dialogs/view-attachment/view-attachment.component';
import { WithdrawalWrapperComponent } from './withdrawals/withdrawal-wrapper/withdrawal-wrapper.component';
import { DepositWrapperComponent } from './deposits/deposit-wrapper/deposit-wrapper.component';
import { UndoLeadsDialogComponent } from './all-leads/dialogs/undo-lead/undo-lead.component';
import { ReassignLeadsDialogComponent } from './all-leads/dialogs/reassign-lead/reassign-lead.component';
import { AffiliateGroupsDialogBulkAddComponent } from './affiliate-groups/dialogs/affiliate-groups-edit/dialogs/affiliate-groups-dialog-bulk-add.component';
import { AffiliateGroupsPromotionBulkAddComponent } from './affiliate-groups/dialogs/affiliate-groups-edit/promotions/affiliate-groups-promotion-bulk-add.component';
import { AffiliateGroupsInboxBulkAddComponent } from './affiliate-groups/dialogs/affiliate-groups-edit/inboxes/affiliate-groups-inbox-bulk-add.component';
import { PromotionAffiliateComponent } from './promotion-codes/dialogs/promotion-affiliate/promotion-affiliate.component';
import { MemberVerificationWrapperComponent } from './member-verification/member-verification-wrapper/member-verification-wrapper.component';
import { ShowDocumentComponent } from './member-bank-account-verification/dialogs/show-document/show-document.component';
import { LeadHistoryComponent } from './all-leads/dialogs/lead-history/lead-history.component';
import { RaffleEventsComponent } from './raffle-events/raffle-events.component';
import { RaffleEventsDataService } from './raffle-events/services/raffle-events-data.service';
import { RaffleEventsEntityService } from './raffle-events/services/raffle-events-entity.service';
import { RaffleEventDetailComponent } from './raffle-events/raffle-event-details/raffle-event-detail.component';
import { RaffleEventsEditDialogComponent } from './raffle-events/raffle-event-details/dialogs/raffle-event-detail-edit/raffle-event-edit.component';
import { RaffleEventNameDialogComponent } from './raffle-events/raffle-event-details/dialogs/raffle-event-name-dialog/raffle-event-name-dialog.component';
import { VerificationSettingsComponent } from './verification-settings/verification-settings.component';
import { VerificationSettingsEditDialogComponent } from './verification-settings/dialogs/verification-settings-edit/verification-settings-edit.component';
import { VerificationSettingsCurrencyDialogComponent } from './verification-settings/dialogs/verification-settings-currency/verification-settings-currency.component';
import { VerificationSettingsCurrencyContentComponent } from './verification-settings/dialogs/verification-settings-currency-content/verification-settings-currency-content.component';
import { VerificationSettingsDataService } from './verification-settings/services/verification-settings-data.service';
import { VerificationSettingsBankComponent } from './verification-settings/dialogs/verification-settings-bank/verification-settings-bank.component';
import { VerificationSettingsBankDetailComponent } from './verification-settings/dialogs/verification-settings-bank/dialogs/verification-settings-bank-detail.component';
import { VerificationSettingBankDataService } from './verification-settings/dialogs/verification-settings-bank/services/verification-settings-bank-data.service';import { DepositLogComponent } from './deposits/dialogs/deposit-log/deposit-log.component';
import { DepositLogWrapperComponent } from './deposits/dialogs/deposit-log-wrapper/deposit-log-wrapper.component';

@NgModule({
  declarations: [
    AffiliateAdjustmentsComponent,
    AdjustGroupDialogComponent,
    AffiliateApprovalComponent,
    AffiliateApprovalEditDialogComponent,
    AffiliateDepositDetailsComponent,
    AffiliateDepositsComponent,
    AffiliateInformationComponent,
    AffiliateMemberComponent,
    AffiliateRemarkComponent,
    AffiliateTransfersComponent,
    AffiliateWithdrawalsComponent,
    AffiliateWithdrawEditDialogComponent,
    AgentComponent,
    AffiliateAdjustmentDialogComponent,
    AgentEditDialogComponent,
    AgentTreeViewComponent,
    AllAffiliatesComponent,
    AllAffiliatesEditDialogComponent,
    AllAffiliatesPasswordDialogComponent,
    AllEventsComponent,
    AllEventsEditDialogComponent,
    AllLeadsAssignDialogComponent,
    AffiliateSettingsDialogComponent,
    AffiliateSettingsAddDialogComponent,
    AllLeadsImportDialogComponent,
    AllLeadsComponent,
    AccountManagementComponent,
    AllPredictionsComponent,
    AllTicketsComponent,
    BetLimitDialogComponent,
    CallLogDialogComponent,
    CCIDLookupDialogComponent,
    CommissionReleasesComponent,
    DepositComponent,
    DepositDetailsComponent,
    DepositLimitDialogComponent,
    GameProviderBlacklistComponent,
    GameProviderEditDialogComponent,
    GenerateCommissionComponent,
    GroupComponent,
    GroupEditDialogComponent,
    LuckyDrawDialogComponent,
    LuckyDrawSettingsComponent,
    LiveChatComponent,
    MemberBankAccountComponent,
    MemberBankAccountEditDialogComponent,
    MemberBankAccountVerificationComponent,
    MemberBankAccountVerificationDetailsComponent,
    MemberBankAccountVerificationRemarkComponent,
    MemberCryptoAccountEditDialogComponent,
    MemberBlacklistDialogComponent,
    MemberComponent,
    MemberDeductDialogComponent,
    MemberGameAccountDialogComponent,
    MemberGameLogDialogComponent,
    MemberGameLogDetailsDialogComponent,
    MemberInformationDialogComponent,
    MemberMessageHistoryComponent,
    MemberOnlineComponent,
    MemberPasswordDialogComponent,
    MemberProfileDialogComponent,
    MemberPromotionComponent,
    MemberPromotionEditDialogComponent,
    MemberRemarksHistoryComponent,
    MemberRewardsDialogComponent,
    MemberRewardsDialogComponent,
    MemberTopupDialogComponent,
    MemberTraceComponent,
    MemberVerificationComponent,
    MemberVerificationDetailsComponent,
    MemberWalletEventComponent,
    MerchantBanksDialogComponent,
    MyLeadsComponent,
    PromotionApplicationComponent,
    PromotionApplicationWrapperComponent,
    PromotionCategoryComponent,
    PromotionCategoryM7Component,
    PromotionCategoryEditDialogComponent,
    PromotionCategoryM7EditDialogComponent,
    PromotionCodeComponent,
    PromotionCodeDetailsDialogComponent,
    PromotionContentComponent,
    PromotionContentEditDialogComponent,
    PromotionCurrencyComponent,
    PromotionCurrencyDetailsComponent,
    PromotionMemberListComponent,
    PromotionMemberDetailsComponent,
    PromotionHistoryComponent,
    PromotionMessageDetailsComponent,
    PromotionMessagesComponent,
    PromotionStatisticComponent,
    RebateSettingsDialogComponent,
    RecoveryPromotionComponent,
    RecoveryPromotionEditDialogComponent,
    ReferralsComponent,
    ReferralSettingsComponent,
    ReferralSettingsEditDialogComponent,
    ReleaseCommissionsDialogComponent,
    RemarkDialogComponent,
    AccountManagementLogDialogComponent,
    AccountManagementKpiSettingsDialogComponent,
    AssignLeadDialogComponent,
    AccountManagementRemarkComponent,
    RequestJustificationDialogComponent,
    RemarksHistoryComponent,
    ReuploadDocumentDialogComponent,
    RewardDetailsDialogComponent,
    RewardsComponent,
    RewardsSettingsDialogComponent,
    StatusRemarksComponent,
    ShowRemarkComponent,
    ShowRemarkHistoryComponent,
    TransactionComponent,
    TransactionWalletDialogComponent,
    TransferComponent,
    TransferEditDialogComponent,
    UpdateResultDialogComponent,
    VipVerificationComponent,
    WithdrawalComponent,
    WithdrawalEditDialogComponent,
    WithdrawalLimitDialogComponent,
    WithdrawalMultiDialogComponent,
    PdfDialogComponent,
    MemberRemarksListComponent,
    AllAffiliateMemberDialogComponent,
    AffiliateGroupsComponent,
    AffiliateGroupsEditDialogComponent,
    CommissionSettingsDialogComponent,
    TierRateSettingsDialogComponent,
    CurrencySettingsDialogComponent,
    RequestReceiptDialogComponent,
    AllLeadsCallLogComponent,
    SampleMessageComponent,
    SampleMessageEditDialogComponent,
    SettlementSettingsComponent,
    SubAffiliateComponent,
    EventTeamsDialogComponent,
    EventTeamsEditDialogComponent,
    BonusHunterAnalysisComponent,
    MemberLabelComponent,
    MemberLabelEditComponent,
    ImportRewardsDialogComponent,
    LeadRemarksComponent,
    ShowEvidenceComponent,
    UrlTestFireDialogComponent,
    ViewReceiptComponent,
    StatusCheckDialogComponent,
    CreateMemberDialogComponent,
    UploadReceiptComponent,
    GameProviderNewBlacklistComponent,
    MemberPromotionHistoryComponent,
    TestFirePostbackLogsComponent,
    MemberPromotionEligibleDialogComponent,
    PromotionCodeMemberEligibleDialogComponent,
    PromotionContentMemberEligibleDialogComponent,
    RewardMemberEligibleDialogComponent,
    TelemarketerRewardsComponent,
    TelemarketerRewardDetailsDialogComponent,
    BasicInfoComponent,
    ViewAttachmentComponent,
    WithdrawalWrapperComponent,
    DepositWrapperComponent,
    UndoLeadsDialogComponent,
    ReassignLeadsDialogComponent,
    TelemarketerSettingsComponent,
    TelemarketerSettingsEditDialogComponent,
    TelemarketerSettingsLogComponent,
    AffiliateGroupsDialogBulkAddComponent,
    AffiliateGroupsPromotionBulkAddComponent,
    AffiliateGroupsInboxBulkAddComponent,
    PromotionAffiliateComponent,
    MemberVerificationWrapperComponent,
    ShowDocumentComponent,
    LeadHistoryComponent,
    RaffleEventsComponent,
    RaffleEventDetailComponent,
    RaffleEventsEditDialogComponent,
    RaffleEventNameDialogComponent,
    VerificationSettingsComponent,
    VerificationSettingsEditDialogComponent,
    VerificationSettingsCurrencyDialogComponent,
    VerificationSettingsCurrencyContentComponent,
    VerificationSettingsBankComponent,
    VerificationSettingsBankDetailComponent,
    DepositLogComponent,
    DepositLogWrapperComponent,
  ],
  imports: [
    AngularMultiSelectModule,
    ColorPickerModule,
    CommonModule,
    FormsModule,
    GeneralRoutingModule,
    LoadingBarModule,
    MaterialModule,
    NgbModule,
    NgSelectModule,
    NgxDaterangepickerMd.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PartialsModule,
    ReactiveFormsModule,
    SharedModule,
    TreeViewModule,
    TreeviewModule.forRoot(),
    CoreModule,
    MatExpansionModule,
    MatGridListModule,
    MatSlideToggleModule,
  ],
  providers: [
    AffiliateAdjustmentDataService,
    AffiliateAdjustmentEntityService,
    AffiliateApprovalDataService,
    AffiliateApprovalEntityService,
    AffiliateDepositsDataService,
    AffiliateDepositsEntityService,
    AffiliateTransfersDataService,
    AffiliateTransfersEntityService,
    AffiliateWithdrawalDataService,
    AffiliateWithdrawalEntityService,
    AgentDataService,
    AgentEntityService,
    AgentTreeViewDataService,
    AgentTreeViewEntityService,
    AllAffiliatesDataService,
    AllEventsDataService,
    AllEventsEntityService,
    AllLeadsDataService,
    AllLeadsEntityService,
    AccountManagementDataService,
    AccountManagementEntityService,
    AllPredictionsDataService,
    AllPredictionsEntityService,
    AllTicketsDataService,
    AllTicketsEntityService,
    BankTransactionDataService,
    BankTransactionEntityService,
    BankTransactionResolver,
    CommissionReleasesDataService,
    CommissionReleasesEntityService,
    CreditLimitHttpService,
    CryptoTokenHttpService,
    CurrencyHttpService,
    DecimalPipe,
    DepositDataService,
    DepositEntityService,
    DepositResolver,
    GroupDataService,
    GroupEntityService,
    GroupResolver,
    LuckyDrawSettingsDataService,
    LuckyDrawSettingsEntityService,
    MemberDataService,
    MemberEntityService,
    MemberHttpService,
    MemberOnlineDataService,
    MemberPromotionDataService,
    MemberPromotionEntityService,
    MemberPromotionResolver,
    MemberResolver,
    MemberTraceDataService,
    MemberTraceEntityService,
    MemberVerificationDataService,
    MemberVerificationEntityService,
    MemberBankAccountVerificationDataService,
    MemberBankAccountVerificationEntityService,
    MerchantBankHttpService,
    MessageDataService,
    MessageEntityService,
    MyLeadsDataService,
    MyLeadsEntityService,
    PromotionApplicationDataService,
    PromotionApplicationEntityService,
    PromotionCategoryDataService,
    PromotionCategoryEntityService,
    PromotionCategoryM7DataService,
    PromotionCategoryM7EntityService,
    PromotionCodeDataService,
    PromotionCodeEntityService,
    PromotionCodeResolver,
    PromotionContentDataService,
    PromotionContentEntityService,
    PromotionContentResolver,
    PromotionCurrencyDataService,
    PromotionCurrencyEntityService,
    PromotionMemberListDataService,
    PromotionMessageDataService,
    PromotionMessageEntityService,
    PromotionStatisticDataService,
    PromotionStatisticEntityService,
    RecoveryPromotionDataService,
    RecoveryPromotionEntityService,
    ReferralDataService,
    ReferralEntityService,
    ReferralSettingsDataService,
    ReferralSettingsEntityService,
    RewardsDataService,
    RewardsEntityService,
    TransactionDataService,
    TransactionEntityService,
    TransactionHttpService,
    TransferDataService,
    TransferEntityService,
    TransferResolver,
    VipMemberVerificationDataService,
    WhatsappCurrencyHttpService,
    WithdrawDataService,
    WithdrawEntityService,
    WithdrawResolver,
    AffiliateGroupsDataService,
    LiveChatDataService,
    SampleMessageDataService,
    SampleMessageEntityService,
    TimezoneHttpService,
    MemberLabelHttpService,
    LeadRemarksDataService,
    LeadRemarksEntityService,
    MemberCryptoWalletHttpService,
    TelemarketerRewardsDataService,
    TelemarketerRewardsEntityService,
    TelemarketerSettingsDataService,
    RaffleEventsEntityService,
    RaffleEventsDataService,
    VerificationSettingsDataService,
    VerificationSettingBankDataService
  ]

})
export class GeneralModule {
  constructor(
    private affiliateAdjustmentDataService: AffiliateAdjustmentDataService,
    private affiliateApprovalDataService: AffiliateApprovalDataService,
    private affiliateDepositsDataService: AffiliateDepositsDataService,
    private affiliateGroupsDataService: AffiliateGroupsDataService,
    private affiliateTransfersDataService: AffiliateTransfersDataService,
    private affiliateWithdrawalDataService: AffiliateWithdrawalDataService,
    private agentDataService: AgentDataService,
    private agentTreeViewDataService: AgentTreeViewDataService,
    private allEventsDataService: AllEventsDataService,
    private allLeadsDataService: AllLeadsDataService,
    private accountManagementDataService: AccountManagementDataService,
    private allPredictionsDataService: AllPredictionsDataService,
    private allTicketsDataService: AllTicketsDataService,
    private depositDataService: DepositDataService,
    private eds: EntityDefinitionService,
    private entityDataService: EntityDataService,
    private groupDataService: GroupDataService,
    private luckyDrawSettingsDataService: LuckyDrawSettingsDataService,
    private memberDataService: MemberDataService,
    private memberOnlineDataService: MemberOnlineDataService,
    private memberPromotionDataService: MemberPromotionDataService,
    private memberTraceDataService: MemberTraceDataService,
    private memberVerificationDataService: MemberVerificationDataService,
    private memberBankAccountVerificationDataService: MemberBankAccountVerificationDataService,
    private messageDataService: MessageDataService,
    private myLeadsDataService: MyLeadsDataService,
    private promotionApplicationDataService: PromotionApplicationDataService,
    private promotionCategoryDataService: PromotionCategoryDataService,
    private promotionCategoryM7DataService: PromotionCategoryM7DataService,
    private promotionCodeDataService: PromotionCodeDataService,
    private promotionContentDataService: PromotionContentDataService,
    private promotionCurrencyDataService: PromotionCurrencyDataService,
    private promotionMemberDataService: PromotionMemberListDataService,
    private promotionMessageDataService: PromotionMessageDataService,
    private promotionStatisticDataService: PromotionStatisticDataService,
    private recoveryPromotionDataService: RecoveryPromotionDataService,
    private referralDataService: ReferralDataService,
    private referralSettingsDataService: ReferralSettingsDataService,
    private rewardsDataService: RewardsDataService,
    private transactionDataService: TransactionDataService,
    private transferDataService: TransferDataService,
    private vipMemberVerificationDataService: VipMemberVerificationDataService,
    private withdrawDataService: WithdrawDataService,
    private liveChatDataService: LiveChatDataService,
    private sampleMessageDataService: SampleMessageDataService,
    private leadRemarksDataService: LeadRemarksDataService,
    private telemarketerRewardsDataService: TelemarketerRewardsDataService,
    private raffleEventsDataService:RaffleEventsDataService,
  ) {
    eds.registerMetadataMap(entityMetadata);
    entityDataService.registerServices({
      AffiliateAdjustment: affiliateApprovalDataService,
      AffiliateApproval: affiliateApprovalDataService,
      AffiliateDeposits: affiliateDepositsDataService,
      AffiliateGroups: affiliateGroupsDataService,
      AffiliateTransfer: affiliateTransfersDataService,
      AffiliateWithdrawals: affiliateWithdrawalDataService,
      Agent: agentDataService,
      AgentTreeView: agentTreeViewDataService,
      AllEvents: allEventsDataService,
      AllLeads: allLeadsDataService,
      AccountManagement: accountManagementDataService,
      AllPredictions: allPredictionsDataService,
      AllTickets: allTicketsDataService,
      Deposit: depositDataService,
      LiveChat: liveChatDataService,
      SampleMessage: sampleMessageDataService,
      LuckyDrawSettings: luckyDrawSettingsDataService,
      Member: memberDataService,
      MemberGroup: groupDataService,
      MemberOnlineDataService: memberOnlineDataService,
      MemberPromotion: memberPromotionDataService,
      MemberTrace: memberTraceDataService,
      MemberVerification: memberVerificationDataService,
      MemberBankAccountVerification: memberBankAccountVerificationDataService,
      Message: messageDataService,
      MyLeads: myLeadsDataService,
      Promotion: promotionCodeDataService,
      PromotionApplication: promotionApplicationDataService,
      PromotionCategory: promotionCategoryDataService,
      PromotionCategoryM7: promotionCategoryM7DataService,
      PromotionContent: promotionContentDataService,
      PromotionCurrency: promotionCurrencyDataService,
      PromotionMessages: promotionMessageDataService,
      PromotionStatistic: promotionStatisticDataService,
      RecoveryPromotion: recoveryPromotionDataService,
      Referral: referralDataService,
      ReferralSetting: referralSettingsDataService,
      Reward: rewardsDataService,
      Transaction: transactionDataService,
      Transfer: transferDataService,
      vipMemberVerification: vipMemberVerificationDataService,
      Withdraw: withdrawDataService,
      LeadRemarks: leadRemarksDataService,
      TelemarketerRewards: telemarketerRewardsDataService,
      RaffleEvents: raffleEventsDataService
    });
  }
}
