
import { Subscription, Observable, interval, of } from 'rxjs';
import { tap, map, exhaustMap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { RoleEditDialogComponent } from './dialogs/role-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { SubheaderService } from '@core/_base/layout/services/subheader.service';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit, ViewRef } from '@angular/core';
import { Role } from '@core/models/role.model';
import { Pagination } from '@core/models/pagination.model';
import { RoleEntityService } from './services/role-entity.service';
import { RoleDataService } from './services/role-data.service';
import { Status } from '@core/enums/status.enum';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { RoleHttpService } from '@core/services/role-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage
  };
  status = Status;
  roles$: Observable<Role[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  // permissions
  canCreateRole: boolean;
  canEditRole: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private roleService: RoleEntityService,
    private roleDataService: RoleDataService,
    private dropdownHttpService: DropdownHttpService,
    private roleHttpService: RoleHttpService,
    private appPermissionService: AppPermissionService,

  ) { }

  ngOnInit() {
    this.reload();
    this.formInit();
    this.pagination = this.roleDataService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateRole = appPermissions.create_role;
      this.canEditRole = appPermissions.edit_role;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onOpenDialog(type: string, roleId?: number) {
    if (roleId) {
      const contact = this.roleService.getByKey(roleId);
      this.subscription = contact.pipe(
        tap((res) => {
          switch (type) {
            case 'edit':
              this.openDialogBy(RoleEditDialogComponent, { role: res, mode: 'edit' });
              break;
          }
        })
      )
        .subscribe();
    } else {
      this.openDialogBy(RoleEditDialogComponent, { mode: 'create' });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.roles$ = this.roleService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.roleDataService.pagination;
        this.loading = false;
        this.dataLength = res.length;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.roles$ = this.roleService.getWithQuery(`?${this.params}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.roleDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { role?: any, mode?: any, collection?: any }) {
    this.dropdownHttpService.functionSections.subscribe(sections => {
      this.roleHttpService.getPermissions().subscribe(permissions => {
        const dialogRef = this.dialog.open(componentRef, {
          width: '850px',
          autoFocus: false,
          data: {
            role: data.role,
            mode: data.mode,
            collection: sections,
            permissions: permissions
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            this.onViewPageBy(this.page).subscribe();
          }
        });
      });
    });
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      status: new FormControl('all')
    });
  }

  private refreshEvery(seconds: number) {
    const refreshSource = interval(seconds * 1000);
    refreshSource.subscribe(() => {
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
          this.reload();
        }
      });
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null) ? fields[key] = formData[key] : key);
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

}
