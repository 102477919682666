import { TransactionHttpService } from '@core/services/transaction-http.service';
import { ApiResponse } from './../models/api-response.model';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

@Injectable()
export class DashboardHttpService {
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  dateToday = this.datePipe.transform(moment(), 'yyyy-MM-dd');
  dateFrom = moment(this.transactionHttpService.getToday().from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
  dateEnd = moment(this.transactionHttpService.getToday().to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private transactionHttpService: TransactionHttpService
  ) { }

  getStatistics(dateFrom: string, dateEnd: string, currencyId?: number) {
    let params = `?start_date=${dateFrom}&end_date=${dateEnd}`;

    if(currencyId) {
      params += `&currency_id=${currencyId}`;
    }

    return this.http.get<ApiResponse>(`/statistic${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  getNetGameRevenue(dateFrom: string, dateEnd: string, timezone: string, currencyId?: number) {
    let params = `?start_date=${dateFrom}&end_date=${dateEnd}&timezone=${timezone}`;

    if(currencyId) {
      params += `&currency_id=${currencyId}`;
    }

    return this.http.get<ApiResponse>(`/statistic/netgamerevenue${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  getTransaction(dateFrom: string, dateEnd: string, currencyId?: number) {
    let params = `?start_date=${dateFrom}&end_date=${dateEnd}`;

    if(currencyId) {
      params += `&currency_id=${currencyId}`;
    }

    return this.http.get<ApiResponse>(`/statistic/transactions${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  getAgentSummary() {
    return this.http.get<ApiResponse>(`/agent/summary`).pipe(
      map(res => res.data)
    );
  }

  getOnlineUser() {
    return this.http.get<ApiResponse>(`/statistic/checkonlineusers`).pipe(
      map(res => res.data.rows.total_online_users)
    );
  }

  getMaitenanceProviders() {
    return this.http.get<ApiResponse>(`/gameprovider/dashboard`).pipe(
      map(res => res.data.rows)
    );
  }

  getReport(startDate?: string, endDate?: string, currencyId?: number) {
    let param = '';
    if (startDate && endDate){
      param += 'start_date=' + startDate + '&end_date=' + endDate;
    }
    if ( currencyId){
      if (param !== '') {
        param += '&';
        param += 'currency_id=' + currencyId;
      }
    }
    return this.http.get<ApiResponse>(`/statistic/report?${param}`).pipe(
      map(res => res.data.rows)
    );
  }

  getTransactionPeakBy(type: string, params?: string) {
    let newParams = `?type=${type}`;
    if (params) {
      newParams += `&${params}`;
    }
    return this.http.get<ApiResponse>(`/statistic/insight${newParams}`).pipe(
      map(res => res.data)
    );
  }

  getAllStatisticsGSYS(params?: string) {
    return this.http.get<ApiResponse>(`/statistic/allstatsgsys?${params}`).pipe(
      map(res => res.data)
    );
  }

  getAllStatisticsCMS(params?: string) {
    return this.http.get<ApiResponse>(`/statistic/allstatscms?${params}`).pipe(
      map(res => res.data)
    );
  }

  getMemberConversion(params?: string) {
    return this.http.get<ApiResponse>(`/statistic/memberconversion?${params}`).pipe(
      map(res => res.data)
    );
  }

  getMemberRetentionRate(params?: string) {
    return this.http.get<ApiResponse>(`/statistic/memberretention?${params}`).pipe(
      map(res => res.data)
    );
  }

  getDeviceType(params?: string) {
    return this.http.get<ApiResponse>(`/statistic/devicetypes?${params}`).pipe(
      map(res => res.data)
    );
  }

  getAccordiaAccount() {
    return this.http.get<ApiResponse>(`/telemarketer/dashboard`).pipe(
      map(res => res.data)
    );
  }
}
