import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormControl} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LiveChatScriptDataService } from '../services/live-chat-script-data.service';
import { Subscription, forkJoin } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Component({
    selector: 'kt-live-chat-script-edit',
    templateUrl: './live-chat-script-edit.component.html',
    styleUrls: ['./live-chat-script-edit.component.scss']
})
export class LiveChatScriptEditDialogComponent implements OnInit {

    form: FormGroup;
    messages$ = this.liveChatScriptDataService.messages$;
    buttonLoading = false;
    refreshStatus: boolean;
    private subscription = new Subscription();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { rows: any; mode: string},
        public dialogRef: MatDialogRef<LiveChatScriptEditDialogComponent>,
        private liveChatScriptDataService: LiveChatScriptDataService,
    ) { }

    ngOnInit() {
        this.formInit();
    }

    onCloseDialog(event?: Event) {
        this.dialogRef.close();
    }

    onSave(mode: string) {
        // To set "Save" button to disable (To prevent call API in multiple times when double click)
        this.form.setErrors({ 'invalid': true });
        const data = {
            ...this.form.value
        };
        Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
        switch (mode) {
            case 'edit':
                this.subscription = forkJoin([
                    this.liveChatScriptDataService.storeScript(data).pipe(
                        tap((res: any) => {
                            this.form.setErrors(null);
                            this.buttonLoading = false;
                        }),
                        catchError((error) => {
                            this.buttonLoading = false;
                            this.form.setErrors(null);
                            this.form.enable();
                            throw error;
                        })
                    ),
                    this.liveChatScriptDataService.messages$
                ]).subscribe();
                break;
        }
        this.refreshStatus = true;
    }

    private formInit() {
        this.form = new FormGroup({
            website: new FormControl(this.data.mode === 'edit' ? this.data.rows.website : null),
            chat_page: new FormControl(this.data.mode === 'edit' ? this.data.rows.chat_page : null)
        });
        this.form.markAllAsTouched();
        this.form.markAsDirty();
    }

}


