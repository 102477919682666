<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Merchant Bank</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <!--Basic info-->
                <div class="row">
                    <div class="col-12 mb-3">
                        <strong>Basic info</strong>
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Currency <span class="text-danger">*</span></label>
                        <fieldset [disabled]="data.mode === 'edit' ? true : false">
                            <select (change)="onSelectCurrency(form.value.currency_id)" formControlName="currency_id" class="form-control">
                                <option [value]="null" [disabled]="true">Please Select</option>
                                <option *ngFor="let value of dropdown.currencies" [value]="value.id">{{ value.name }}</option>
                            </select>
                        </fieldset>
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Payment Method <span class="text-danger">*</span> </label>
                        <fieldset [disabled]="data.mode === 'edit' ? true : false">
                            <select formControlName="method" class="form-control" (change)="onSelectMethod()">
                                <option [value]="null" [disabled]="true">Please Select</option>
                                <ng-container *ngFor="let value of dropdown.methods">
                                    <option [value]="value.id">
                                        {{ value.name }}
                                    </option>
                                </ng-container>
                            </select>
                        </fieldset>
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> {{selectedBankType == 3 ? 'Ewallet' : 'Bank' }} <span class="text-danger">*</span> </label>
                        <fieldset [disabled]="data.mode === 'edit' ? true : false">
                            <select (change)="onSelectBank()" *ngIf="isSelectedCurrency && form.value.method !== null" formControlName="bank_id" class="form-control">
                                <option [value]="null" [disabled]="true">Please Select</option>
                                <ng-container *ngFor="let value of dropdown.merchantBanks">
                                    <option *ngIf="value.id !== 0" [value]="value.id">
                                        {{ value.name }}
                                    </option>
                                </ng-container>
                            </select>
                            <select disabled *ngIf="!isSelectedCurrency || form.value.method === null" class="form-control">
                                <option></option>
                            </select>
                        </fieldset>
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Payment Gateway <span class="text-danger" *ngIf="+form.value.method === 2">*</span> </label>
                        <kt-dropdown-wo-lazyload
                        [form]="form"
                        [dropdownList]="dropdown.paymentGateway"
                        [selectedItems]="paymentGatewaysSelectedItems"
                        [selectionAttributes]="'id'"
                        [formName]="'payment_gateways'"
                        [dropdownSettings]="paymentGatewaysDropdownSettings"
                        ></kt-dropdown-wo-lazyload>
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Account Name <span class="text-danger">*</span> </label>
                        <input class="form-control" type="text" formControlName="account_name">
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> {{selectedBankType == 3 ? 'Ewallet Number' : 'Account Number' }} <span class="text-danger">*</span> </label>
                        <fieldset [disabled]="data.mode === 'edit' ? true : false">
                            <input class="form-control" type="text" formControlName="account_number">
                        </fieldset>
                    </div>
                    <ng-container *ngIf="selectedBankType == 3">
                        <div class="col-lg-6 form-group">
                            <label class="control-label"> QR Image </label>
                            <div class="col-12">
                                <div class="row m-0 p-0">
                                    <label class="btn btn-default btn-sm float-left">
                                        <span>Choose file</span>
                                        <input type="file" hidden accept="image/*" (change)="onUploadFile($event)" />
                                    </label>
                                    <div class="spinner-wrapper" *ngIf="isImageUploading">
                                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 form-group" *ngIf="form.get('qr_image')?.value != null">
                            <label class="control-label">Preview</label>
                            <div class="col-12">
                                <div class="image-wrapper">
                                <img
                                    style="max-width:50%"
                                    class="img-fluid" alt="QR Image"
                                    [src]="form.get('qr_image').value" (click)="openImage(account_name, form.get('qr_image').value)"/>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-lg-6 form-group">
                        <label class="control-label">Type</label>
                        <select class="form-control" formControlName="type">
                            <option [value]="null" hidden>Please Select</option>
                            <ng-container *ngFor="let value of dropdown.types">
                                <option [value]="value.id">{{ value.name }}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Purpose <span class="text-danger">*</span></label>
                        <select class="form-control" formControlName="purpose" (change)="onPurpose($event)">
                            <option [value]="null" [disabled]="true">Please Select</option>
                            <option *ngFor="let value of dropdown.bankPurposes | async" [value]="value.id">{{ value.name }}</option>
                        </select>
                    </div>
                    <div class="col-lg-6 form-group" *ngIf="data.mode === 'edit'">
                        <label class="control-label"> Status</label>
                        <select class="form-control" formControlName="status">
                            <ng-container *ngFor="let key of object.keys(status)">
                                <option [value]="key" *ngIf="+key >= 0">{{ status[key] }}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-lg-12 form-group" [ngClass]="(form.value.purpose == 1 || form.value.purpose == 5) ? '' : 'd-none'">
                        <label class="control-label"> Member Groups <span class="text-danger">*</span></label>
                        <div class="pl-0">
                            <kt-dropdown-wo-lazyload
                            [form]="form"
                            [dropdownList]="memberGroupDropdownList"
                            [selectedItems]="memberGroupSelectedItems"
                            [selectionAttributes]="'id'"
                            [formName]="'member_groups'"
                            [dropdownSettings]="memberGroupDropdownSettings"
                            ></kt-dropdown-wo-lazyload>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!--Transaction Configs-->
                    <div class="col-12 mb-3 mt-2">
                        <strong>Transaction Configs</strong>
                    </div>
                    <!-- if purpose = "deposit" or "deposit & withdrawal"-->
                    <ng-container *ngIf="(selectedPurpose === 1 || selectedPurpose === 5) || (form.value.purpose == 1 || form.value.purpose == 5)">
                        <div class="col-lg-6 form-group">
                            <label class="control-label"> Daily Max Deposit Count <span class="text-danger">*</span> </label>
                            <input class="form-control" type="number" (wheel)="false" formControlName="daily_max_deposit_count">
                        </div>
                        <div class="col-lg-6 form-group">
                            <label class="control-label"> Daily Max Deposit Amount <span class="text-danger">*</span> </label>
                            <input class="form-control" type="number" (wheel)="false" formControlName="daily_max_deposit">
                        </div>
                    </ng-container>

                    <!-- if purpose = "withdrawal" or "deposit & withdrawal"-->
                    <ng-container *ngIf="(selectedPurpose === 2 || selectedPurpose === 5) || (form.value.purpose == 2 || form.value.purpose == 5)">
                        <div class="col-lg-6 form-group">
                            <label class="control-label"> Daily Max Withdrawal Count <span class="text-danger">*</span> </label>
                            <input class="form-control" type="number" (wheel)="false" formControlName="daily_max_withdrawal_count">
                        </div>
                        <div class="col-lg-6 form-group">
                            <label class="control-label"> Daily Max Withdrawal Amount <span class="text-danger">*</span> </label>
                            <input class="form-control" type="number" (wheel)="false" formControlName="daily_max_withdrawal">
                        </div>
                    </ng-container>

                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Maximum Balance <span class="text-danger">*</span> </label>
                        <input class="form-control" type="number" (wheel)="false" formControlName="max_balance">
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Minimum Balance <span class="text-danger">*</span> </label>
                        <input class="form-control" type="number" (wheel)="false" name="min_balance" formControlName="min_balance">
                    </div>
                    <div class="col-lg-6 form-group" *ngIf="((data.mode === 'create' && selectedPurpose !== 2) || (data.mode === 'edit' && (form.value.purpose !== 2 && selectedPurpose !== 2)))">
                        <label class="control-label"> Maximum Deposit per Transaction <span class="text-danger">*</span> </label>
                        <input class="form-control" type="number" (wheel)="false" name="max_deposit_per_transaction" formControlName="max_deposit_per_transaction">
                        <small class="text-muted form-text">
                            <i class="fa fa-exclamation-circle"></i> Enter 0 for unlimited
                        </small>
                    </div>
                    <div class="col-lg-6 form-group" *ngIf="((data.mode === 'create' && selectedPurpose !== 2) || (data.mode === 'edit' && (form.value.purpose !== 2 && selectedPurpose !== 2)))">
                        <label class="control-label"> Minimum Deposit per Transaction <span class="text-danger">*</span> </label>
                        <input class="form-control" type="number" (wheel)="false" name="min_deposit_per_transaction" formControlName="min_deposit_per_transaction">
                    </div>
                    <div class="col-lg-6 form-group" *ngIf="((data.mode === 'create' && selectedPurpose !== 1) || (data.mode === 'edit' && (form.value.purpose !== 1 && selectedPurpose !== 1)))">
                        <label class="control-label"> Maximum Withdrawal per Transaction <span class="text-danger">*</span> </label>
                        <input class="form-control" type="number" (wheel)="false" name="max_withdrawal_per_transaction" formControlName="max_withdrawal_per_transaction">
                        <small class="text-muted form-text">
                            <i class="fa fa-exclamation-circle"></i> Enter 0 for unlimited
                        </small>
                    </div>
                    <div class="col-lg-6 form-group" *ngIf="((data.mode === 'create' && selectedPurpose !== 1) || (data.mode === 'edit' && (form.value.purpose !== 1 && selectedPurpose !== 1)))">
                        <label class="control-label"> Minimum Withdrawal per Transaction <span class="text-danger">*</span> </label>
                        <input class="form-control" type="number" (wheel)="false" name="min_withdrawal_per_transaction" formControlName="min_withdrawal_per_transaction">
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Score <span class="text-danger">*</span> </label>
                        <input class="form-control" type="number" (wheel)="false" formControlName="score">
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Position <span class="text-danger">*</span> </label>
                        <input class="form-control" type="number" (wheel)="false" formControlName="position">
                    </div>
                    <ng-container *ngIf="form.value.purpose !== null">
                        <div class="col-lg-6 form-group" *ngIf="showProcessingFeeDep">
                            <label class="control-label"> Processing Fee % (Deposit)</label>
                            <input class="form-control" type="number" (wheel)="false" formControlName="processing_fee_percent_dep">
                        </div>
                        <div class="col-lg-6 form-group" *ngIf="showProcessingFeeWith">
                            <label class="control-label"> Processing Fee % (Withdrawal) </label>
                            <input class="form-control" type="number" (wheel)="false" formControlName="processing_fee_percent_with">
                        </div>
                    </ng-container>
                </div>

                <ng-container *ngIf="selectedBankType === 1">
                <div class="row">
                    <!--Bot Configs-->
                    <div class="col-12 mb-3 mt-2">
                        <strong>Bot Configs</strong>
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Username <span class="text-danger">*</span> </label>
                        <input class="form-control" type="text" formControlName="username">
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Password <span class="text-danger" *ngIf="data.mode === 'create'">*</span> </label>
                        <input class="form-control" type="text" formControlName="password">
                    </div>
                    <div class="col-md-2 col-6 form-group">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" formControlName="business" class="custom-control-input" id="businessCheck">
                            <label class="custom-control-label" for="businessCheck"> Business </label>
                        </div>
                    </div>
                    <div class="col-md-2 col-6 form-group">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" formControlName="sync" class="custom-control-input" id="syncCheck">
                            <label class="custom-control-label" for="syncCheck">Sync</label>
                        </div>
                    </div>
                </div>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [isDisabled]="!form.valid || !minMaxValid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.bank, data.mode)"></kt-submit-button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
