// Service
import { ImportHistoryDataService } from "./services/import-history-data.service";
// Angular / Core
import { FormGroup, FormControl } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { LoadingBarService } from "@ngx-loading-bar/core";
// Third Party
import { DownloadHttpService } from "@core/services/download-http.service";
import { TransactionHttpService } from '@core/services/transaction-http.service';
import * as moment from 'moment-timezone';
import { Pagination } from '@core/models/pagination.model';
import { Observable } from 'rxjs';
import { Imports } from '@core/models/imports.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: "kt-import-history",
  templateUrl: "./import-history.component.html",
  styleUrls: ["./import-history.component.scss"],
})
export class ImportHistoryComponent implements OnInit {
  form: FormGroup;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  statuses = [
    { id: 1, name: 'Success' },
    { id: 0, name: 'Failed' }
  ];
  rows = [];
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  perPage = this.dropdownHttpService.perPage;

  sortingConfig = {
    'id': 'desc',
    'remark': 'desc',
    'created_by': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  imports$: Observable<Imports[]>;
  
  constructor(
    private loadingBar: LoadingBarService,
    private importHistoryDataService: ImportHistoryDataService,
    private downloadHttpService: DownloadHttpService,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnInit() {
    this.downloadHttpService.updateImportCount().subscribe();
    this.pagination = this.importHistoryDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.formInit();
    this.onSubmit(true);
  }
  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.rows = [];
    this.loading = true;
    this.loadingBar.start();

    const data = {
      ...this.form.value,
    };

    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] === 'all' || key == 'defaultDate' ) && delete data[key]);
    
    if (data['start_datetime']) {
      data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
      data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
    }

    this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = this.params ? `&${this.params}` : '';

    this.importHistoryDataService.getWithQuery(`page=${this.page}&perPage=${this.pageSize}${parameters}`).subscribe(res => {
      this.rows = res;
      this.pagination = this.importHistoryDataService.pagination;
      this.loading = false;
      this.clearBtnLoading = false;
      this.searchBtnLoading = false;  
      this.loadingBar.complete();
    });

  }
  
  onDownload(url: string) {
    if (url !== undefined && url !== null && url !== '') {
      this.downloadHttpService.downloadFileByUrl(url);
    }
  }

  onDownloadTxt(content: string,rowfileName : string) {
    if (content !== undefined && content !== null && content !== '') {
        const blob = new Blob([content], { type: "application/text" });
        var url = window.URL.createObjectURL(blob);
        var fileName = rowfileName.split('/');
        let   link = document.createElement("a");
        link.setAttribute('type', 'hidden');
        link.href = url;
        link.download = fileName[fileName.length - 1];
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      section: null,
      status: 'all',
      defaultDate: null,
      start_datetime: null,
      end_datetime: null
    });
    this.onSubmit(true);
  }
  private formInit() {
    this.form = new FormGroup({
      section: new FormControl(null),
      status: new FormControl('all'),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null)
    });
  }
  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }
  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }
  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }
  onClearDate() {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }
  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.searchBtnLoading =  true;
    this.clearBtnLoading = true;
    this.loadingBar.start();

    this.importHistoryDataService.getWithQuery(`page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).subscribe(res => {
        this.rows = res;
        this.pagination = this.importHistoryDataService.pagination;
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;  
        this.loadingBar.complete();
    });
  }
  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }
  
  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }
}