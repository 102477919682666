import { FormGroup, FormControl } from '@angular/forms';
import { PromotionCategoryM7EditDialogComponent } from './dialogs/promotion-category-m7-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, map, exhaustMap } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { SubheaderService } from '@core/_base/layout';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PromotionCategoryM7DataService } from './services/promotion-category-m7-data.service';
import { PromotionCategoryM7EntityService } from './services/promotion-category-m7-entity.service';
import { PromotionCategory } from '@core/models/promotion-content-category.model';

@Component({
  selector: 'kt-promotion-category-m7',
  templateUrl: './promotion-category-m7.component.html',
  styleUrls: ['./promotion-category-m7.component.scss']
})
export class PromotionCategoryM7Component implements OnInit, OnDestroy {

  form: FormGroup;
  promotionCategory$: Observable<PromotionCategory[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = 'status=1';
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
    statuses: this.dropdownHttpService.statuses
  };
  buttonLoading = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  messages$ = this.promotionCategoryDataService.messages$;
  private subscription = new Subscription();
  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    private promotionCategoryDataService: PromotionCategoryM7DataService,
    private promotionCategoryEntityService: PromotionCategoryM7EntityService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.formInit();
    this.onSubmit(true);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.promotionCategory$ = this.promotionCategoryEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.promotionCategoryDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(mode: string, row?: PromotionCategory) {
    if (mode === 'edit') {
      this.openDialogBy(PromotionCategoryM7EditDialogComponent, { mode: 'edit', row: row });
    } else {
      this.openDialogBy(PromotionCategoryM7EditDialogComponent, { mode: 'create' });
    }
  }

  onReload() {
    this.onViewPageBy(this.page).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.promotionCategory$ = this.promotionCategoryEntityService.getWithQuery(`?${this.params}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.promotionCategoryDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null) ? fields[key] = formData[key] : key);
    return fields;
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, row?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        mode: data.mode,
        row: data.row
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      status: new FormControl(1)
    });
  }
}
