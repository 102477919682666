import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AffiliateGroupsDataService } from '../../services/affiliate-groups-data.service';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'kt-tier-rate-settings',
  templateUrl: './tier-rate-settings.component.html',
  styleUrls: ['./tier-rate-settings.component.scss']
})
export class TierRateSettingsDialogComponent implements OnInit {

  buttonLoading = false;
  messages$ = this.affiliateGroupsDataService.messages$;
  newArr: any = [];
  newSettings: any;

  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { commissionSettingId: number, tier?: any, },
    public dialogRef: MatDialogRef<TierRateSettingsDialogComponent>,
    public dialog: MatDialog,
    private affiliateGroupsDataService: AffiliateGroupsDataService,
  ) { }

  ngOnInit() {
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  addRow() {
    this.newSettings = {
      max_amount: null,
      active_members: null,
      commission_rate: null
    };
    this.newArr.push(this.newSettings);
  }

  onGenerate(formName: string, event, index: number, mode: string) {
    if (mode === 'edit') {
      switch (formName) {
        case 'amount':
          this.data.tier.rows[index].max_amount = +event.target.value;
          break;
        case 'members':
          this.data.tier.rows[index].active_members = +event.target.value;
          break;
        case 'rate':
          this.data.tier.rows[index].commission_rate = +event.target.value;
          break;
      }
    } else {
      switch (formName) {
        case 'amount':
          this.newArr[index].max_amount = +event.target.value;
          break;
        case 'members':
          this.newArr[index].active_members = +event.target.value;
          break;
        case 'rate':
          this.newArr[index].commission_rate = +event.target.value;
          break;
      }
    }
  }

  onSave() {
    this.buttonLoading = true;
    if (this.newArr.length > 0) {
      const value = {
        affiliate_group_commission_setting_id: this.data.commissionSettingId,
        tiers: [
          ...this.newArr
        ]
      }
      Object.keys(value.tiers).forEach((key) => (value.tiers[key] == null || value.tiers[key] === '') && delete value[key]);
      this.subscription = this.affiliateGroupsDataService.addTierSettings(value).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          throw error;
        })
      ).subscribe();
    }
    if (this.data.tier.rows.length > 0) {
      if (this.data.tier.rows.length === 1) {
        const value = {
          ...this.data.tier.rows
        }
        this.onUpdateTier(value, +this.data.tier.rows[0].id);
      } else if (this.data.tier.rows.length > 1) {
        const value = {
          tiers: [
            ...this.data.tier.rows
          ]
        }
        this.onUpdateTier(value);
      }
    }
  }

  onUpdateTier(data: any, tierId?: number) {
    if (tierId) {
      this.subscription = this.affiliateGroupsDataService.updateTierSettings(tierId, data[0]).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          throw error;
        })
      ).subscribe();
    } else {
      this.subscription = this.affiliateGroupsDataService.bulkUpdateTier(data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          throw error;
        })
      ).subscribe();
    }
  }

  onDelete(tierId: number, index?: number) {
    if (tierId) {
      this.affiliateGroupsDataService.deleteTier(tierId).subscribe();
    } else {
      this.newArr.splice(index, 1);
    }
  }

  onReload() {
    this.affiliateGroupsDataService.getTierSettings(this.data.commissionSettingId).subscribe(res => {
      this.data.tier = res;
    });
    this.newArr = [];
  }

}
