import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Contact } from '@core/models/contact.model';
import { Pagination } from '@core/models/pagination.model';

@Injectable()
export class ContactDataService extends DefaultDataService<Contact> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('Contact', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<Contact[]> {
    return this.http.get<ApiResponse>('/contact').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(contact?: Contact): Observable<Contact>{
    return this.http.post<ApiResponse>(`/contact`, contact).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/contact/${entity.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  getWithQuery(pageParam: string): Observable<Contact[]> {
    return this.http.get<ApiResponse>(`/contact${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => {
        Object.keys(res.data.rows).forEach(x => {
          let localeList = [];
          Object.keys(res.data.rows[x].locales).forEach(y => {
            localeList.push(res.data.rows[x].locales[y].locale_name);
          })
          res.data.rows[x].locale_list = localeList.toString().split(',').join(', ');
        })
        return res.data.rows
      }
    ));
  }

  getById(id: number): Observable<Contact>{
    return this.http.get<ApiResponse>(`/contact/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  updateContact(id: number, param: any) {
    return this.http.put<ApiResponse>(`/contact/${id}`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  updateStatus(param: any) {
    return this.http.put<ApiResponse>(`/contact/statusupdate`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }

}
