import { Pagination } from '../models/pagination.model';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { MemberBankAccount } from '@core/models/member-bank-account.model';
import { MemberCryptoWallet } from '@core/models/member-crypto-wallet.mode';

@Injectable()
export class MemberCryptoWalletHttpService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(private http: HttpClient) { }

  getWithQuery(pageParam: string): Observable<MemberCryptoWallet[]>{
    return this.http.get<ApiResponse>(`/cryptowallet${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  getById(id: number): Observable<MemberCryptoWallet>{
    return this.http.get<ApiResponse>(`/cryptowallet/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  add(data: any): Observable<MemberBankAccount>{
    return this.http.post<ApiResponse>(`/cryptowallet`, data).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    )
  }

  update(memberCryptoAccount: any) {
    return this.http.put<ApiResponse>(`/cryptowallet/${memberCryptoAccount.id}`, memberCryptoAccount);
  }

  validateWalletAddress(wallet_information: string)  {
    return this.http.get<ApiResponse>(`/cryptowallet/validate${wallet_information}`).pipe(
      map(res => res.data)
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }
}
