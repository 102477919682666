import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VerificationSettingsDataService } from '../../services/verification-settings-data.service';
import { catchError, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import Swal from 'sweetalert2';
import { VerificationSettingsCurrencyContentComponent } from '../verification-settings-currency-content/verification-settings-currency-content.component';

@Component({
    templateUrl: './verification-settings-currency.component.html',
    styleUrls: ['./verification-settings-currency.component.scss']
})
export class VerificationSettingsCurrencyDialogComponent implements OnInit {
    form: FormGroup;

    messages$ = this.verificationSettingsDataService.messages$;
    loading = false;
    buttonLoading = false;
    messageTemplateBtnLoading = false;

    dropdown = {
      currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    };
    filteredCurrencies = [];

    formControlMapping = {
      "member_group": "Member Group",
      "status": "Status",
      "bank": "Bank",
      "content": "Content"
    };

    tabs: any[] = [];
    selectedIndex: number = 0;
    isAddingTab = false;
    @ViewChild('selectElement') selectElement!: MatSelect;
    @ViewChildren(VerificationSettingsCurrencyContentComponent) subComponents: QueryList<VerificationSettingsCurrencyContentComponent>;

    private localesSub = new Subscription();

    constructor(
      @Inject(MAT_DIALOG_DATA) public data: { verificationSettingsCurrency: any, verificationSettingsID: number, mode: string, bank_type: number },
      public dialogRef: MatDialogRef<VerificationSettingsCurrencyDialogComponent>,
      private verificationSettingsDataService: VerificationSettingsDataService,
      private dropdownHttpService: DropdownHttpService,
      public dialog: MatDialog,
    ) { }

    ngOnInit() {
      this.filteredCurrencies = this.dropdown.currencies;
      this.formInit();
    }

    ngOnDestroy() {
      this.localesSub.unsubscribe();
    }

    onCloseDialog(event?: Event, refresh = false) {
      this.dialogRef.close(refresh);
    }

    onSave() {
      this.buttonLoading = true;
      // To set "Save" button to disable (To prevent call API in multiple times when double click)
      this.form.setErrors({ 'invalid': true });

      let missingDataFields = false;
      let missingDataTabs = [];
      let formValid = true;
      const errorMessages = [];
      let data = {
        ...this.form.value,
      };

      this.subComponents.forEach((component, index) => {
        const tabName = this.tabs[index].label;
        const tabCode = this.tabs[index].code;
        const tabID = this.tabs[index].id;

        data[tabID] = component.formContent.value;
        // Remove all the field that not selected type
        data[tabID].field = data[tabID].field.filter(item => item.type !== null);

        // If the form is invalid
        // We check what causes the form invalid and we push the error message
        // Then make the formValid = false
        for (const controlName in component.formContent.controls) {
          const control = component.formContent.controls[controlName];
          const displayControlName = this.formControlMapping[controlName] || controlName;
          if (control.errors?.required) {
              errorMessages.push(`${displayControlName} in ${tabName} is required.`);
          }

          if (controlName == 'content') {
            let contents = component.formContent.controls[controlName].value;
            let firstENData = null;
            for (const [key, value] of Object.entries(contents)) {
              // Only the first tab (EN) is required.
              if (value['lang_code'] === 'EN' && index == 0) {
                firstENData = value; // Store EN data for later use
                
                // Check for required fields in EN
                if (!value['title']) {
                  errorMessages.push(`Title in ${value['settings_locale_code']} is required.`);
                }
            
                if (!value['content']) {
                  errorMessages.push(`Content in ${value['settings_locale_code']} is required.`);
                }
              }
            }
            
            // Check for missing data in other languages
            for (const [key, value] of Object.entries(contents)) {
              if ((value['lang_code'] !== 'EN' || index != 0) && firstENData) {
                if (!value['title'] || !value['content']) {
                  missingDataFields = true;
                  missingDataTabs.push(tabCode);
                  break; 
                }
              }
            }
          }

          if (controlName == 'field') {
            let fields = component.formContent.controls[controlName].value;
            const hasType = fields.some(field => field.type !== null);
            const hasActiveStatus = fields.some(field => field.status == "1");
          
            if (!hasType) {
              errorMessages.push(`At least one field in ${tabName} is required.`);
            } else if (!hasActiveStatus) {
              // If Currency status is Active, require at least one Active field
              errorMessages.push(`At least one field with Active status in ${tabName} is required.`);
            } else {
              fields.forEach((field, index) => {
                if (field.type !== null) {
                  let enFieldDetails = null;
                  const fieldNumber = index + 1;
          
                  // First pass: check and store EN data
                  for (const [key, value] of Object.entries(field.field_details)) {
                    if (value['lang_code'] == 'EN') {
                      enFieldDetails = value; // Store EN data for fallback
          
                      if (!value['name']) {
                        errorMessages.push(`Field ${fieldNumber} name in ${value['settings_locale_code']} is required.`);
                      }
          
                      if (!value['position']) {
                        errorMessages.push(`Field ${fieldNumber} position in ${value['settings_locale_code']} is required.`);
                      }
                    }
                  }
          
                  // Check for missing data in other languages
                  for (const [key, value] of Object.entries(field.field_details)) {
                    if (value['lang_code'] !== 'EN' && enFieldDetails) {
                      if (!value['name'] || !value['position']) {
                        missingDataFields = true;
                        missingDataTabs.push(tabCode);
                        break; 
                      }
                    }
                  }
                }
              });
            }
          }
        }
        formValid = errorMessages.length > 0 ? false : true;
      });
        
      if (formValid) {
        if (missingDataFields) {
          missingDataTabs = [...new Set(missingDataTabs)];
          const missingDataTabsText = missingDataTabs.join(', ');

          Swal.fire({
            title: 'Incomplete Data Detected',
            html: `Some content or fields are missing in non-English languages for the following currencies: ${missingDataTabsText}<br>Would you like to proceed by filling these missing values with English data?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              // Proceed by filling missing data with English data
              let firstENData = null;
              this.subComponents.forEach((component, index) => {
                let enFieldDetails = null;
                
                if (component.formContent.controls['content']) {
                  let contents = component.formContent.controls['content'].value;
                  for (const [key, value] of Object.entries(contents)) {
                    if (value['lang_code'] === 'EN' && index == 0) {
                      firstENData = value;
                    }
                  }
                  // Only the first tab (EN) is required. If titles or content in other languages or tabs are empty, they will be replaced with those from the first tab (EN).
                  for (const [key, value] of Object.entries(contents)) {
                    if ((value['lang_code'] !== 'EN' || index != 0) && firstENData) {
                      if (!value['title']) {
                        value['title'] = firstENData['title'];
                      }
                      if (!value['content']) {
                        value['content'] = firstENData['content'];
                      }
                    }
                  }
                }
        
                if (component.formContent.controls['field']) {
                  let fields = component.formContent.controls['field'].value;
                  fields.forEach((field) => {
                    if (field.type !== null) {
                      for (const [key, value] of Object.entries(field.field_details)) {
                        if (value['lang_code'] == 'EN') {
                          enFieldDetails = value;
                        }
                      }

                      for (const [key, value] of Object.entries(field.field_details)) {
                        if (value['lang_code'] !== 'EN' && enFieldDetails) {
                          if (!value['name']) {
                            value['name'] = enFieldDetails['name'];
                          }
                          if (!value['position']) {
                            value['position'] = enFieldDetails['position'];
                          }
                        }
                      }
                    }
                  });
                }
              });
        
              this.submitForm(data);
            } else {
              this.buttonLoading = false;
              this.form.setErrors(null);
            }
          });
        } else {
          this.submitForm(data);
        }
      } else {
        // If form not valid for transaction config, swal the error message
        if (errorMessages.length > 0) {
          const message = '<ul>' + errorMessages.map(msg => `<li>${msg}</li>`).join('') + '</ul>';
          Swal.fire({
            icon: 'error',
            title: 'Validation Error',
            html: message,
            width: '650px'
          })
        }
        this.buttonLoading = false;
        this.form.setErrors(null);
      }
    }

    submitForm(data) {
      Object.keys(data).forEach(
        (key) => (data[key] == null || data[key] === "" || data[key].length === 0) && delete data[key]
      );
    
      if (this.data.mode == 'create') {
        localStorage.setItem('createVerificationSettingsCurrency', JSON.stringify(data));
        this.dialogRef.close(1);
      } else {
        data = {
          currency: data
        };
    
        this.verificationSettingsDataService.update(this.data.verificationSettingsID, data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.dialogRef.close(1);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
      }
    }

    addTab(data?: any, ableDelete: boolean = true) {
      let selectedTab = '';
      let currencyCode = '';
      let currencyID = '';
      if (data) {
        let selectedCur = this.dropdown.currencies.find(item => item.id === data.settings_currency_id);
        selectedTab = selectedCur.name;
        currencyCode = selectedCur.name;
        currencyID = selectedCur.id;
      } else {
        let selectedCur = this.dropdown.currencies.find(item => item.id === this.form.get('selectedTab').value);
        selectedTab = selectedCur.name;
        currencyCode = selectedCur.name;
        currencyID = selectedCur.id;
      }
      const newTab = {
          id: currencyID,
          label: selectedTab,
          code: currencyCode,
          detail: data ?? {},
          ableDelete: ableDelete
      };
      this.tabs.push(newTab);

      // Find the index of the newly added tab
      const newIndex = this.tabs.length - 1;
  
      this.form.get('selectedTab').setValue(null);
      this.isAddingTab = false;

      if (this.data.mode == 'create') {
        // Set the selected index to the index of the newly added tab
        setTimeout(() => {
          this.selectedIndex = newIndex;
        });
      }

      this.refreshCurrencyDropdown();
    }

    removeTab(index: number) {
      Swal.fire({
        title: 'Are you sure you want to delete',
        text: this.tabs[index].label,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.tabs.splice(index, 1);
          const newIndex = this.tabs.length - 1;
  
          // Set the selected index to the index of the newly added tab
          setTimeout(() => {
            this.selectedIndex = newIndex;
          });

          this.refreshCurrencyDropdown();
        }
      });
    }

    refreshCurrencyDropdown() {
      // Filter out currencies that exist in tabs.
      const filterIDs = this.tabs.map(item => item.id);
      this.filteredCurrencies = this.dropdown.currencies.filter(currency => !filterIDs.includes(currency.id));
    }

    showDropdown(index: number) {
      this.tabs[index].showDropdown = true;
    }
    
    hideDropdown(index: number) {
      this.tabs[index].showDropdown = false;
    }
    
    toggleAddingTab() {
      this.isAddingTab = true;
    }
    
    openSelect() {
      this.selectElement.open();
    }

    private formInit() {
      this.form = new FormGroup({
          selectedTab: new FormControl(null)
      });

      if (this.data.verificationSettingsCurrency.length > 0) {
        let deleteFlag = this.data.mode == 'create' ? true : false;
        Object.entries(this.data.verificationSettingsCurrency).forEach(([key, value]) => {
          this.addTab(value, deleteFlag);
        });
      }
    }
}
