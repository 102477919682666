import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'kt-member-remarks-list',
  templateUrl: './member-remarks-list.component.html',
  styleUrls: ['./member-remarks-list.component.scss']
})
export class MemberRemarksListComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { remarks: any },
    public dialogRef: MatDialogRef<MemberRemarksListComponent>
  ) { }

  ngOnInit(): void {
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

}
