import {createFeatureSelector, createSelector} from '@ngrx/store';
import { AuthState } from './auth.reducers';

export const selectAuthState = createFeatureSelector<AuthState>('auth');
export const isLoggedIn = createSelector(selectAuthState, auth => !!auth.user);
export const isLoggedOut = createSelector(isLoggedIn, loggedIn => !loggedIn);
export const loggedUser = createSelector(selectAuthState, auth => auth.user.user);
export const accessSections = createSelector(selectAuthState, auth => auth.user.access_sections);
export const navigations = createSelector(selectAuthState, auth => auth.user.navigations);
export const appPermissions = createSelector(selectAuthState, auth => auth.user.app_permissions);
export const specialPermissions = createSelector(selectAuthState, auth => auth.user.permissions);
export const loggedUserWithPermissions = createSelector(
  loggedUser,
  appPermissions,
  (loggedUser, appPermissions) => ({
    loggedUser,
    appPermissions,
  })
);
