<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">{{ data.mode === 'create' ? 'Create' : 'Edit' }} FAQ</h5>
              <span class="modal-x-button" (click)="onCloseDialog()">
                  <i class="fa fa-times" aria-hidden="true"></i>
              </span>
          </div>
          <div class="modal-body">
                <div class="form-group">
                  <label>Section <span class="text-danger">*</span></label>
                  <select *ngIf="data.mode === 'create'" class="form-control" formControlName="faq_section_id" (change)="onSelectSection($event)">
                      <option [value]="null" [disabled]="true">Please Select</option>
                      <option *ngFor="let value of faqSections" [value]="value.id">{{ value.faq_name }}</option>
                  </select>
                  <input *ngIf="data.mode === 'edit'" type="text" class="form-control" formControlName="faq_name">

                  <label class="mt-3">Position <span class="text-danger">*</span></label>
                  <input type="number" class="form-control" formControlName="position">
                  
                  <label class="mt-3">Platform <span class="text-danger">*</span></label>
                  <select formControlName="platform_type_id" class="form-control">
                    <option value="null" hidden>Please Select</option>
                    <option *ngFor="let item of dropdown.sitePlatforms" [value]="item.id">{{ item.name }}</option>
                  </select>
                </div>

              <ng-container>
                  <ng-container *ngIf="(data.locales.length > 0); else elseLoading">
                      <mat-tab-group animationDuration="0ms" class="form-group" formGroupName="details">
                          <mat-tab *ngFor="let language of data.locales; let i = index" [formGroupName]="language.id">
                              <ng-template mat-tab-label>
                                  <span>{{language.code}}</span>
                              </ng-template>
                              <input type="hidden" class="form-control" [value]="language.id" formControlName="settings_locale_id">

                              <div class="form-group">
                                  <label>Question <span class="text-danger">*</span></label>
                                  <ckeditor [config]="editorConfig" [editor]="editor" formControlName="question"></ckeditor>
                              </div>

                              <div class="form-group">
                                  <label>Answer <span class="text-danger">*</span></label>
                                  <ckeditor [config]="editorConfig" [editor]="editor" formControlName="answer"></ckeditor>
                              </div>
                          </mat-tab>
                      </mat-tab-group>
                  </ng-container>
                  <ng-template #elseLoading>
                      <div class="row justify-content-center">
                          <i class="fas fa-circle-notch fa-spin p-0 mr-2"></i>
                      </div>
                  </ng-template>
              </ng-container>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
              <kt-submit-button [isDisabled]="false" [buttonLoading]="buttonLoading" (confirmed)="onSave(faqs, data.mode)"></kt-submit-button>
          </div>
      </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>