import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { map, switchMap } from "rxjs/operators";
import Echo from "laravel-echo";
import { environment } from '@env/environment';
import { Store } from "@ngrx/store";
import { AppState } from "@store/reducers";
import { Observable } from "rxjs";
import { loggedUserToken } from "@core/store/auth/auth.selectors";
import { Token } from "@core/models/token.model";

// shared socket information
const SocketIoClient = require("socket.io-client");
const socketHost: string = environment.socketHost;

@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  /**
   * Echo instances
   * 
   * As of this writing, public and private Echo instances are kept separate.
   *
   * Although the public Echo can use private Echo’s auth headers, it requires some
   * refactoring and QA to ensure proper functionality.
   *
   */
  static echo: Echo;
  static privateEcho: Echo; // with auth headers

  userToken$: Observable<Token>;

  // snackbar
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  // all available CTAs
  ctaMap = {
    deposit: '/member/wallet;tab=deposit',
    view: '/member/reward',
    reward: '/member/reward',
    profile: '/member/profile'
  };

  constructor(
    private store: Store<AppState>
  ) {
    this.userToken$ = this.store.select(loggedUserToken);
  }

  initializeEcho() {
    if (WebsocketService.echo == undefined) {
      try {
        WebsocketService.echo = new Echo({
          broadcaster: "socket.io",
          client: SocketIoClient,
          host: socketHost,
        });
      } catch (e) {
        console.warn(e);
      }
    }
  }

  initializePrivateEcho(): Observable<Echo> {
    return this.userToken$.pipe(
      map(userToken => {
        if (WebsocketService.privateEcho) {
          return WebsocketService.privateEcho;
        }

        if (!userToken) {
          throw new Error('token not found');
        }

        // Initialize Echo if it's not already initialized
        if (!WebsocketService.privateEcho) {
          WebsocketService.privateEcho = new Echo({
            broadcaster: "socket.io",
            client: SocketIoClient,
            host: socketHost,
            auth: {
              headers: {
                'access-token': userToken.access_token,
                'token-selector': userToken.plaintext_token,
                'X-User-Model': 'bo'
              }
            }
          });
        }

        return WebsocketService.privateEcho;
      })
    );
  }

  getEcho() {
    return WebsocketService.echo;
  }

  getChannel(channel: string) {
    this.initializeEcho();
    return WebsocketService.echo.channel(channel);
  }

  /**
   * Available for client if need to access channel directly
   *
   * @param channel 
   * @returns 
   */
  getPrivateChannel(channel: string): Observable<any> {
    return this.initializePrivateEcho().pipe(
      map(echo => echo.private(channel))
    );
  }

  getPresenceChannel(channel: string): Observable<any> {
    return this.initializePrivateEcho().pipe(
      map(echo => echo.join(channel))
    );
  }

  listenToPrivateEvent(channelName: string, event: string): Observable<any> {
    return this.getPrivateChannel(channelName).pipe(
      switchMap(channel => {
        return new Observable((observer) => {
          channel.listen(event, (data: any) => {
            observer.next(data);
          });
        });
      })
    );
  }

  getPrivateEcho() {
    return WebsocketService.privateEcho;
  }

  static disconnectEcho() {
    if (WebsocketService.echo) {
      WebsocketService.echo.disconnect();
      WebsocketService.echo = null;
    }
  }

  static disconnectPrivateEcho() {
    if (WebsocketService.privateEcho) {
      WebsocketService.privateEcho.disconnect();
      WebsocketService.privateEcho = null;
    }
  }
}