// Angular
import { ChangeDetectorRef,SimpleChanges, Component, Inject, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-show-document',
  templateUrl: './show-document.component.html',
  styleUrls: ['./show-document.component.scss'],
  providers: [NgbCarouselConfig],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowDocumentComponent implements OnInit {

  loading = false;
  remarkLength = 0;

  images = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/900/500`);
  Documents$ = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { documents: any},
    public dialogRef: MatDialogRef<ShowDocumentComponent>,
    private cdr: ChangeDetectorRef,
    config: NgbCarouselConfig,
    private domSanitizer:DomSanitizer,
  ) { 
    config.interval = 5000;
    config.keyboard = true;
    config.pauseOnHover = true;
    config.showNavigationIndicators = false;
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    
  }

  onCloseDialog(confirm = false) {
    this.dialogRef.close(confirm);
  }

  checkMedia(url){
    const imageExtensions = ['.gif', '.jpg', '.jpeg', '.png'] // you can add more extensions
    const pdfExtensions   = ['.pdf'] // you can add more extensions
    
    var isImage = false;
    var isVideo = false;
    var isPdf = false;

    imageExtensions.map((e) => {
      isImage = url.includes(e);
    })
    if(isImage) return 'image';

    pdfExtensions.map((e) => {
      isPdf = url.includes(e);
    })
    if(isPdf) return 'pdf';
  }
}
