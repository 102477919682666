
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CampaignAdCost } from '@core/models/campaign-ad-cost.model';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { CampaignHttpService } from '@core/services/campaign-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from 'rxjs';
import { exhaustMap, map, tap, catchError } from 'rxjs/operators';
import { CampaignAdCostDialogComponent } from './dialogs/campaign-ad-cost-details.component';
import { CampaignAdCostDataService } from './service/campaign-ad-cost-data.service';
import { CampaignAdCostEntityService } from './service/campaign-ad-cost-entity.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from "@core/services/app-permission.service";

@Component({
  selector: 'kt-campaign-ad-cost',
  templateUrl: './campaign-ad-cost.component.html',
  styleUrls: ['./campaign-ad-cost.component.scss']
})
export class CampaignAdCostComponent implements OnInit, OnDestroy {

  @ViewChild('myFileInput') myFileInput;
  campaignAdCost$: Observable<CampaignAdCost[]>;
  form: FormGroup;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage
  };
  ranges: any;
  file: File;
  messages$ = this.campaignAdCostHttpService.messages$;
  checkValidation = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  importBtnLoading = false;
  dataLength: number;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();

  // permissions
  canCreateAdCost: boolean;
  canEditAdCost: boolean;
  canImportAdCost: boolean;

  constructor(
    private loadingBar: LoadingBarService,
    private campaignAdCostEntityService: CampaignAdCostEntityService,
    private campaignAdCostDataService: CampaignAdCostDataService,
    private campaignAdCostHttpService: CampaignHttpService,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private currencyHttpService: CurrencyHttpService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.pagination = this.campaignAdCostDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateAdCost = appPermissions.create_ad_cost;
      this.canEditAdCost = appPermissions.edit_ad_cost;
      this.canImportAdCost = appPermissions.import_ad_cost;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : window.history.state.campaignId === undefined ? '' : `&campaign_id=${window.history.state.campaignId}`;
    this.loadingBar.start();
    return this.campaignAdCost$ = this.campaignAdCostEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.campaignAdCostDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, campaignId?: number) {
    if (campaignId) {
      const campaignAdCost = this.campaignAdCostEntityService.getByKey(campaignId);
      this.subscription = campaignAdCost.pipe(
        tap((res) => {
          this.openDialogBy(CampaignAdCostDialogComponent, { mode: type, adCost: res });
        })).subscribe();
    } else {
      this.openDialogBy(CampaignAdCostDialogComponent, { mode: type });
    }
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      campaign: window.history.state.campaignName === undefined ? null : window.history.state.campaignName,
      currency_id: 'all',
      country: null,
      start_date: null,
      end_date: null,
      defaultDate: null
    });
    this.onSubmit(true);
  }

  onUploadFile(event: any) {
    this.file = event.target.files[0];
    this.checkValidation = true;
    const data = new FormData();
    if (this.file !== undefined) {
      this.loading = true;
      this.importBtnLoading = true;
      data.append('attached_file', this.file);
      this.loadingBar.start();
      this.subscription = this.campaignAdCostHttpService.importCampaignAdCost(data).pipe(
        tap((res: any) => {
          this.messages$.next([res.message]);
          this.checkValidation = false;
          this.file = null;
          this.myFileInput.nativeElement.value = "";
          this.page = 1
          this.loading = false;
          this.importBtnLoading = false;
          this.onViewPageBy(this.page).subscribe();
          this.loadingBar.complete();
        }),
        catchError((error) => {
          this.checkValidation = false;
          this.file = null;
          this.myFileInput.nativeElement.value = "";
          this.loading = false;
          this.importBtnLoading = false;
          this.loadingBar.complete();
          throw error;
        })
      ).subscribe();
    }
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.campaignAdCost$ = this.campaignAdCostEntityService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.page = 1
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;;
            this.pagination = this.campaignAdCostDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, adCost?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        mode: data.mode,
        adCost: data.adCost
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      campaign: new FormControl(window.history.state.campaignName === undefined ? null : window.history.state.campaignName),
      currency_id: new FormControl('all'),
      country: new FormControl(null),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null) // Do not remove: For Clearing The Range
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all' && key !== 'defaultDate') {
        fields[key] = formData[key];
      };
    });
    return fields;
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

}
