import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { MemberBankAccountVerification } from '@core/models/member-bank-account-verification-model';

@Injectable()
export class MemberBankAccountVerificationEntityService extends EntityCollectionServiceBase<MemberBankAccountVerification>  {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('MemberBankAccountVerification', serviceElementsFactory)  
  }
}
