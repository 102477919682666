<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode == 'show-remarks' ? 'Remark History' : 'New Remark'}}</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="data.mode == 'show-remarks'; else newRemark">
                    <section class="col-lg-12 card border-0">
                        <div class="col-12 p-0 card rounded-0" *ngIf="remarksHistory.length > 0">
                          <div class="card-header">
                            Timeline
                          </div>
                          <div class="row ml-1" *ngFor="let row of remarksHistory">
                            <div class="col-1 p-3">
                              {{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }}
                            </div>
                            <div class="col-11 p-3">
                              <!-- The Timeline -->
                              <div class="timeline">
                                <!-- Item 1 -->
                                <div>
                                  <div class="direction-r">
                                    <div class="flag-wrapper">
                                      <span class="flag" [ngClass]="'border-color--' + row.status">
                                        <section class="col-12 p-3 inner-box-timeline">
                                          <div class="row border-top border-left">
                                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                              <span class="kt-font-bold">Remark</span>
                                            </div>
                                            <div class="col-md-9 border-right p-3">
                                              <span>{{ row.value }}</span>
                                            </div>
                                          </div>
                              
                                          <div class="row border-top border-left">
                                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                              <span class="kt-font-bold">Development Stage</span>
                                            </div>
                                            <div class="col-md-3 border-right p-3">
                                              <span>{{ row.development_stage == '1' ? 'Normal' : 'VIP' }}</span>
                                            </div>
                                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                              <span class="kt-font-bold">Lead Stage</span>
                                            </div>
                                            <div class="col-md-3 border-right p-3">
                                              <span>{{ row.lead_stage }}</span>
                                            </div>
                                          </div>
            
                                          <div class="row border-top border-left border-bottom">
                                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                              <span class="kt-font-bold">Attachment</span>
                                            </div>
                                            <div class="col-md-3 border-right p-3 attachmentIcon">
                                              <span class="text-right"><i class="fa fa-file" (click)="openAttachment(row.url)" *ngIf="row.url !== null"></i></span>
                                            </div>
  
                                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                              <span class="kt-font-bold">Created By</span>
                                            </div>
                                            <div class="col-md-3 border-right p-3">
                                              <span class="text-right">{{ row.username ? row.username : '-' }}<br>{{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }} </span>
                                            </div>
                                          </div>
                                        </section>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="kt-pagination kt-pagination--brand mt-2 p-3" *ngIf="pagination !== undefined">
                            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="getTimeline(page)">
                            </ngb-pagination>
                            <div class="kt-pagination__toolbar">
                              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                              </select>
                              <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0'}} records
                              </span>
                            </div>
                          </div>
                        </div>
                      </section>
                </ng-container>
                <ng-template #newRemark>
                    <!-- Remarks -->
                    <div class="col-12 form-group row pr-0">
                        <label class="col-4 col-form-label">Remark <span class="text-danger">*</span> </label>
                        <input type="text" formControlName="remarks" class="col-8 form-control">
                    </div>
                    <div class="col-12 form-group row">
                        <label class="col-4 col-form-label"> Upload Attachment </label>
                        <label class="col-2 btn btn-default btn-sm float-left">
                        <span>Choose file</span>
                        <input type="file" hidden accept="image/*,.pdf" (change)="onUploadFile($event)">
                        </label>
                    </div>
                    <div class="col-12 form-group row" *ngIf="imagePreview.length > 0">
                        <label class="col-4 col-form-label">Preview Image </label>
                        <div class="col-8 image-wrapper">
                            <ng-container *ngIf="!isPDF; else elsePdf">
                                <img class="img-fluid" [src]="imagePreview[0]" alt="{{ 'Image Preview' | translate }}" />
                            </ng-container>
                            <ng-template #elsePdf>
                                <iframe width="100%" height="100%" [src]="imagePreview[0]" frameborder="0" style="border:0" allowfullscreen></iframe>
                              </ng-template>
                            <!-- <div class="trash-img-div d-flex justify-content-end align-items-start">
                                <img src="/assets/images/topnav/trash-red.png" width="25" (click)="onRemoveFileMulti(i)" />
                            </div> -->
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="modal-footer" *ngIf="data.mode == 'remarks'">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>