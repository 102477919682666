import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { MemberDataService } from '../../services/member-data.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, tap, exhaustMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MemberGameLogDetailsDialogComponent } from '../member-game-log-details/member-game-log-details.component';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';

declare var $:any;

@Component({
  selector: 'kt-game-log',
  templateUrl: './member-game-log.component.html',
  styleUrls: ['./member-game-log.component.scss']
})
export class MemberGameLogDialogComponent implements OnInit {
  form: FormGroup;
  member_account_id: number;
  member_account_username: string;
  game: any;
  page = 1;
  tableContent: any;
  loading = false;
  buttonLoading = false;
  from = this.transactionHttpService.getToday().from;
  to = this.transactionHttpService.getToday().to;
  params = `date=${moment(this.from).utc().format('YYYY-MM-DD')}&start_time=${moment(this.from).utc().format('HH:mm:ss')}&end_time=${moment(this.to).utc().format('HH:mm:ss')}`;
  type = 'Normal';


  dropdown = {
    memberGameLogType: this.dropdownHttpService.memberGameLogType,
  }


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { member: any, existing_game_account: any },
    public dialogRef: MatDialogRef<MemberGameLogDialogComponent>,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    public loadingBar: LoadingBarService,
    private memberDataService: MemberDataService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.member_account_id = this.data.member.id;
    this.member_account_username = this.data.member.username;
    this.game = this.data.existing_game_account;
    this.formInit();
    
  }

  htmlRender(){
    if($('#link_view_game_sessions_popup').length){
      $('#contentTable').off('click');
      $( "#contentTable" ).on( "click", "tr",(events)=> {
        this.onOpenDialog($(events.currentTarget).attr('data-link'), this.data.member, this.game);
      });
    }else{
      $('#link_view_game_sessions').css("color", "#5867dd");
      $('#link_view_game_sessions').off('click');
      $( "#link_view_game_sessions" ).click((events)=> {
        this.onOpenDialog(String($(events.currentTarget).attr('data-link')), this.data.member, this.game);
      });
    }
    
  }

  onOpenDialog(dataLink: String, member: any, game?: any) {
      this.openDialogBy(MemberGameLogDetailsDialogComponent, { dataLink: dataLink, member: member, existing_game_account: game})
  }

  private openDialogBy(componentRef: any, data?: { dataLink?: String, member?:any, existing_game_account?:any}) {
      this.dialog.open(componentRef, {
        width: '800px',
        data: {
          dataLink: data.dataLink,
          member: data.member,
          existing_game_account : data.existing_game_account
        }
      });

    
  }

  onSubmit() {
    this.loading = true;
    this.buttonLoading = true;
    this.tableContent = `<div class="spinner-wrapper">
    <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
  </div>`;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        return this.memberDataService.getMemberGameLog(`?${this.params}&username=${this.member_account_username}&member_account_id=${this.member_account_id}&game_provider_code=${this.game.game_provider_code}&game_account=${this.game.ga_username}&currency_code=${this.data.member.currency_code}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.loading = false;
            this.buttonLoading = false;
            this.tableContent = this.sanitizer.bypassSecurityTrustHtml(res);
          })
        );
      }),
    ).subscribe();
  }

  onChangePage(mode:any) {
    if(mode === 'next'){
      this.page = this.page + 1;
    }else{
      this.page = this.page - 1;
    }
    this.form = new FormGroup({
      date: new FormControl(this.form.get('date').value, [Validators.required]),
      start_time: new FormControl(this.form.get('start_time').value),
      end_time: new FormControl(this.form.get('end_time').value),
      page: new FormControl(this.page, [Validators.required]),
      type: new FormControl(this.form.get('type').value)
    });
    this.onSubmit();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate') {
        fields[key] = key === 'start_time' ? moment(formData[key]).utc().format('YYYY-MM-DD HH:mm:00') : key === 'date' ? moment(formData[key]).utc().format('YYYY-MM-DD HH:mm:ss')  : key === 'end_time' ? moment(formData[key]).utc().format('YYYY-MM-DD HH:mm:59') : formData[key];
      }
    });
    return fields;
  }

  private formInit(){
    this.form = new FormGroup({
      date: new FormControl(moment(this.to).format('YYYY-MM-DD'),[Validators.required]),
      start_time: new FormControl(moment(this.from).format('YYYY-MM-DD HH:mm:ss')),
      end_time: new FormControl(moment(this.to).format('YYYY-MM-DD HH:mm:ss')),
      page: new FormControl(this.page, [Validators.required]),
      type: new FormControl(this.type)
    });
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

}
