<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <!-- Filter -->
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <div class="col-12 row pr-0 mb-2">
                <!-- Status -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="status" class="form-control">
                    <option value=""> ALL </option>
                    <option value=5> Ongoing </option>
                    <option value=1> Finished </option>
                    <option value=3> Upcoming </option>
                  </select>
                </div>
                <!-- DateTime -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date/Time:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="input-group date">
                    <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                    <span class="input-group-append">
                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
        <!-- Filter Action -->
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <!-- Other Action -->
        <div class="dropdown dropdown-inline">
          <!-- Create -->
          <button *ngIf="canCreateSystemMaintenance" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
        </div>
      </div>
      <!-- Table -->
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped">
              <thead>
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th class="text-center">Status</th>
                  <th>Remarks</th>
                  <th>Created By</th>
                  <th>Updated By</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody *ngIf="systemMaintenances as rows">
                <tr *ngFor="let row of rows">
                  <td>{{ row.maintain_from | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}</td>
                  <td>{{ row.maintain_to | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}</td>
                  <td class="text-center">
                      <span [class]="'kt-badge kt-badge--inline kt-badge--pill kt-badge--wide kt-badge--'+row.status">
                      {{ row.status_name }}
                    </span>
                  </td>
                  <td>{{ row.remarks }}</td>
                  <td>
                    {{ row.created_by }}<br />
                    <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #createdDateTime>
                      {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                  <td>
                    <ng-container *ngIf="row.updated_by !== null; else elseBlock">
                      {{ row.updated_by }}<br />
                      <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                      <ng-template #updatedDateTime>
                        {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                      </ng-template>
                    </ng-container>
                    <ng-template #elseBlock>
                      -
                    </ng-template>
                  </td>
                  <td class="text-center">
                    <button *ngIf="canEditSystemMaintenance" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)"><i class="fas fa-edit"></i></button>
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="systemMaintenances.length" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                Showing {{pagination.from}} to {{pagination.to}} of
                {{ pagination.total | number : '1.0' }} records
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
