<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode | titlecase }} VIP</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="data.mode === 'reject'; else elseTemplate">
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Remarks <span class="text-danger">*</span></label>
            <input type="text" formControlName="remarks" class="col-8 form-control">
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Member <span class="text-danger" *ngIf="data.mode === 'create'">*</span></label>
            <label class="col-8 col-form-label p-l-0" *ngIf="data.mode !== 'create'">{{ data.mode === 'edit' ? data.editVip.username : data.vip.username }}</label>
            <kt-member-dropdown *ngIf="data.mode === 'create'" class="dropdown-maxheight col-8" style="padding:0" [form]="form" [dropdownSettings]='dropdownSettings'
            [formName]="'member_account_id'" [selectionAttributes]="'id'" (selectedItemsChanged)="onSelectedMemberChanged($event)"></kt-member-dropdown>
          </div>
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Current Level <span class="text-danger">*</span></label>
            <select formControlName="member_group_id" class="col-8 form-control">
              <option [value]="null" [disabled]="true">Please Select</option>
              <option [value]="value.id" *ngFor="let value of dropdown.groups">
                  {{ value.name }}
              </option>
            </select>
          </div>

          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Next Level <span class="text-danger">*</span></label>
            <label class="col-8 col-form-label p-l-0" *ngIf="data.mode === 'approve'">{{ data.vip.next_member_group }}</label>
            <select *ngIf="data.mode !== 'approve'" formControlName="next_member_group_id" class="col-8 form-control" (change)="onSelectNextLevel($event)">
              <option [value]="null" [disabled]="true">Please Select</option>
              <option [value]="value.id" *ngFor="let value of dropdown.groups">
                  {{ value.name }}
              </option>
            </select>
          </div>

          <div class="col-12 form-group row" *ngIf="data.mode !== 'edit'">
            <label class="col-4 col-form-label">VIP Upgrade Bonus</label>
              <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0" *ngIf="data.mode !== 'edit' && promotionCodeDropdownList.length > 0"
              [form] = 'form'
              [dropdownList] = 'promotionCodeDropdownList'
              [dropdownSettings] = 'promotionCodeDropdownSettings'
              [formName] = "'upgrade_promotion_id'"
              [selectionAttributes] = "'id'"
              [selectedItems] = 'upgradeBonusSelectedItems'>
            </kt-dropdown-wo-lazyload>
          </div>

          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">VIP Birthday Bonus</label>
            <label class="col-8 col-form-label p-l-0" *ngIf="memberDOB !== null" [ngClass]="isMemberDOBPass ? 'green' : 'red'">
              {{ memberDOB }} - {{ isMemberDOBPass ? 'Applicable' : 'Not Applicable' }}
            </label>

            <div class="col-8 input-group date" [owlDateTimeTrigger]="dateStartRef" *ngIf="memberDOB === null && data.mode !== 'create'">
              <input class="hidden" [owlDateTime]="dateStartRef">
              <input type="text" formControlName="date_of_birth" class="form-control">
              <span class="input-group-append">
                  <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
              </span>
              <owl-date-time #dateStartRef></owl-date-time>
            </div>
          </div>

          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Send SMS</label>
            <div class="d-flex flex-wrap status-filter-pt">
              <div class="custom-control custom-checkbox mr-3">
                  <input type="checkbox" formControlName="send_sms" class="custom-control-input" id="sendSMS">
                  <label class="custom-control-label text-nowrap" for="sendSMS">Yes</label>
              </div>
            </div>
          </div>

          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Send Member Message </label>
            <div class="d-flex flex-wrap status-filter-pt">
              <div class="custom-control custom-checkbox mr-3">
                  <input type="checkbox" formControlName="send_message" class="custom-control-input" id="sendMessage">
                  <label class="custom-control-label text-nowrap" for="sendMessage">Yes</label>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.mode)"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
