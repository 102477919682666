<div ngbDropdown placement="bottom" autoClose="outside" [open]="isOpen" class="kt-header__topbar-item" style="margin-right: 0 !important;" (openChange)="openChange($event)">
  <button ngbDropdownToggle hidden #notificationTrigger class="notificationTrigger"></button>
  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg mt-4">

    <div class="kt-head kt-head--skin-{{skin}} kt-head--fit-x">
      <h3 class="kt-head__title">Notification</h3>
    </div>

    <div class="kt-notification custom-scroll" (scroll)="onScrollDown($event)">
      <ng-container *ngIf="(notificationsList).length >= 1">
        <a class="kt-notification__item" *ngFor="let row of notificationsList" [ngClass]="{ 'unread': +row.read === 0 }" (click)="onRedirect(row.type)">
          <div class="kt-notification__item-icon">
            <i ngClass="{{ getJsonResult(row.type).icon }}"></i>
          </div>
          <div class="kt-notification__item-details">
            <div class="kt-notification__item-title d-flex justify-content-between" [matTooltip]="row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm'"><span>{{ row.title }}</span> <span>{{ onGetLapsedTime(row.created_at) }}</span></div>
            <div class="kt-notification__item-time">{{ row.message }}</div>
          </div>
        </a>
      </ng-container>
      <a *ngIf="showLoading" class="kt-notification__item d-flex justify-content-center">
        <mat-spinner [diameter]="30" class="loading"></mat-spinner>
      </a>
    </div>
  </div>
</div>
