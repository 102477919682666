import { FormGroup, Validators, FormControl } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { Role } from '@core/models/role.model';
import { RoleEntityService } from '../services/role-entity.service';
import { tap, catchError } from 'rxjs/operators';
import { RoleDataService } from '../services/role-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';


@Component({
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss']
})
export class RoleEditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses
  };
  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  messages$ = this.roleDataService.messages$;
  functionAccess = ['view', 'create', 'edit', 'export'];
  refreshStatus: boolean;
  functions = [];
  access = [];
  sections = [];
  buttonLoading = false;

  // permissions
  canCreateRole: boolean;
  canEditRole: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { role: Role, mode: string, collection: any, permissions: any },
    public dialogRef: MatDialogRef<RoleEditDialogComponent>,
    private roleService: RoleEntityService,
    private roleDataService: RoleDataService,
    private dropdownHttpService: DropdownHttpService,
    private cdr: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateRole = appPermissions.create_role;
      this.canEditRole = appPermissions.edit_role;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(role: Role, mode?: string) {
    this.buttonLoading = true;
    const data = {
      id: role ? role.id : null,
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.roleService.update(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        this.refreshStatus = true;
        break;
      case 'create':
        this.subscription = this.roleService.add(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        this.refreshStatus = true;
        break;
    }
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onSelectSectionRow(sectionId: number, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const section = this.data.collection.find(section => section.id === sectionId);
    let access = {};
    for (const fn of section.functions) {
      if (isChecked) {
        access = { ...access, [fn.id]: this.getAccessFunctions(true) }
      } else {
        access = { ...access, [fn.id]: this.getAccessFunctions(false) }
      }
    }
    this.form.patchValue({
      access_sections: {
        [section.id]: access
      }
    });
    this.touchForm();
    this.onAccessSectionsChanged({ ...this.form.get("access_sections").value });
  }

  onSelectAccessRow(sectionId: number, functionId: number, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.form.patchValue({
      access_sections: {
        [sectionId]: {
          [functionId]: isChecked ? this.getAccessFunctions(true) : this.getAccessFunctions(false)
        }
      }
    });

    this.touchForm();
    this.onAccessSectionsChanged({ ...this.form.get("access_sections").value });
  }

  onSelectColumn(permission: string, sectionId: number, event: Event) {
    if (sectionId) {
      const isChecked = (event.target as HTMLInputElement).checked;
      const sections = this.data.collection;
      let access = {};
      sections.map((sn: any) => {
        if (sn.id === sn.id) {
          let fnObject = {};
          sn.functions.map((fn: any) => {
            fnObject = {
              ...fnObject, [fn.id]: {
                [permission]: isChecked ? true : false
              }
            }
          })
          access = { ...access, ...fnObject };
        }
      });
      this.form.patchValue({
        access_sections: {
          [sectionId]: access
        }
      });
    }
    this.touchForm();
    this.valueChanges();
  }

  onSelectPermissions(tag: string, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;

    this.form.get('permissions').patchValue({
      [tag]: isChecked ? true : false
    });
    this.touchForm();
  }

  private getAccessFunctions(controlValue: boolean, isFormControlInstance = false) {
    let access: {};
    this.functionAccess.map(item => access = { ...access, [item]: controlValue });
    if (isFormControlInstance) {
      this.functionAccess.map(item => {
        access = { ...access, [item]: new FormControl(controlValue) };

        if (Object.keys(controlValue)) {
          const controls: any = controlValue;
          for (const c in controls) {
            if (controls.hasOwnProperty(c)) {
              access = { ...access, [c]: new FormControl(controls[c]) }
            }
          }
        }
      });
    }
    return access;
  }

  private touchForm() {
    this.form.markAsDirty();
    this.form.markAsTouched();
  }



  private formInit() {
    let name = null;
    let status = 1;
    let remarks = null;

    const buildAccessSections = () => {
      let collection = this.data.collection;
      let section = {};

      for (const sn of collection) {
        let subSection = {};
        for (const fn of sn.functions) {
          subSection = { ...subSection, [fn.id]: new FormGroup(this.getAccessFunctions(false, true)) };
        }
        section = { ...section, [sn.id]: new FormGroup(subSection) };
      }

      if (this.data.mode === 'edit') {
        collection = this.data.role.access_sections;
        Object.keys(collection).forEach(sn => {
          Object.keys(collection[sn]).forEach(fn => {
            Object.keys(collection[sn][fn]).forEach(efs => {
              if (section[sn].controls[fn] !== undefined) {
                section[sn].controls[fn].patchValue({
                  [efs]: collection[sn][fn][efs]
                })
              }
            })
          })
        })
      }

      return section;
    }

    const buildPermissions = () => {
      let permissionList = this.data.permissions;
      let permissions = {};
      for (const p of permissionList) {
        permissions = { ...permissions, [p.tag]: new FormControl(this.data.mode === 'edit' ? this.data.role.permissions[p.tag] : false) };
      }

      return permissions;
    }

    if (this.data.mode === 'edit') {
      name = this.data.role.name;
      status = this.data.role.status;
      remarks = this.data.role.remarks;
    }
    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false }),
      remarks: new FormControl(remarks),
      access_sections: new FormGroup(buildAccessSections()),
      permissions: new FormGroup(buildPermissions())
    });
    this.onAccessSectionsChanged({ ...this.form.get("access_sections").value });
  }

  valueChanges() {
    //Checking value changes to fire off the change to onAccessSectionsChanged
    this.form.get("access_sections").valueChanges.subscribe(newForm => {
      this.onAccessSectionsChanged(newForm);
    })
  }

  private onAccessSectionsChanged(newForm?: any) {
    //Traversing through sections
    for (let i = 1; i <= Object.keys(newForm).length; i++) {
      let isAllFunctionsChecked = true;
      let isAllAccessChecked = true;
      let viewColumn = true;
      let createColumn = true;
      let editColumn = true;
      let exportColumn = true;

      //Traversing through functions (rows within sections)
      Object.keys(newForm[i]).map(k => {
        //Row logic - setting function (leftmost) checkbox of selected section to "checked"
        if (
          newForm[i][k].view &&
          newForm[i][k].create &&
          newForm[i][k].edit &&
          newForm[i][k].export
        ) {
          //If in edit mode, the function rows will be null, so functions object value must be set
          (document.getElementById('function_' + (i) + '_' + (k)) as HTMLInputElement) !== null ?
            (document.getElementById('function_' + (i) + '_' + (+k)) as HTMLInputElement).checked = true
            : this.functions = { ...this.functions, ['function_' + (i) + '_' + (k)]: true };
        } else {
          (document.getElementById('function_' + (i) + '_' + (k)) as HTMLInputElement) !== null ?
            (document.getElementById('function_' + (i) + '_' + (+k)) as HTMLInputElement).checked = false
            : this.functions = { ...this.functions, ['function_' + (i) + '_' + (k)]: false };
          //Set !isAllFunctionsChecked (if one is found unchecked) to be used by section checkbox
          isAllFunctionsChecked = false;
        }

        //Column logic - checking if view, create, edit, export column is all true for all functions
        if (!newForm[i][k].view) {
          viewColumn = false;
        }
        if (!newForm[i][k].create) {
          createColumn = false;
        }
        if (!newForm[i][k].edit) {
          editColumn = false;
        }
        if (!newForm[i][k].export) {
          exportColumn = false;
        }
      });
      //Column logic - setting access (topmost) checkbox of selected section to "checked" (if nothing is found unchecked)
      (document.getElementById('action_' + (i) + '_' + 0) as HTMLInputElement) !== null ?
        (document.getElementById('action_' + (i) + '_' + 0) as HTMLInputElement).checked = viewColumn
        : this.access = { ...this.access, ['action_' + (i) + '_' + 0]: viewColumn };
      (document.getElementById('action_' + (i) + '_' + 1) as HTMLInputElement) !== null ?
        (document.getElementById('action_' + (i) + '_' + 1) as HTMLInputElement).checked = createColumn
        : this.access = { ...this.access, ['action_' + (i) + '_' + 1]: createColumn };
      (document.getElementById('action_' + (i) + '_' + 2) as HTMLInputElement) !== null ?
        (document.getElementById('action_' + (i) + '_' + 2) as HTMLInputElement).checked = editColumn
        : this.access = { ...this.access, ['action_' + (i) + '_' + 2]: editColumn };
      (document.getElementById('action_' + (i) + '_' + 3) as HTMLInputElement) !== null ?
        (document.getElementById('action_' + (i) + '_' + 3) as HTMLInputElement).checked = exportColumn
        : this.access = { ...this.access, ['action_' + (i) + '_' + 3]: exportColumn };

      //Set !isAllAccessChecked (if one is found unchecked) to be used by section checkbox
      if (!viewColumn || !createColumn || !editColumn || !exportColumn) {
        isAllAccessChecked = false;
      }

      //Section logic - setting section checkbox (left-hand corner) of selected section to "checked"
      (document.getElementById('section_' + i) as HTMLInputElement) !== null ?
        (document.getElementById('section_' + i) as HTMLInputElement).checked = (isAllFunctionsChecked && isAllAccessChecked)
        : this.sections = { ...this.sections, ['section_' + (i)]: (isAllFunctionsChecked && isAllAccessChecked) };
    }
  }

  customTitleCase(value: string): string {
    if (!value) return '';
    return value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
}
