<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Whatsapp Bot QR</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div  class="table-responsive">
          <table  class="table table-bordered table-hover table-striped">
            <thead>
              <tr>
                <th class="text-center">{{ headerText }}</th>
              </tr>
            </thead>
            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            <ng-container *ngIf="loading == false">
              <ng-container *ngIf="qrLink != ''; else noData">
                <tr>
                    <td class="text-center">
                      <img alt="QR Login" class="img-fluid" src="{{ qrLink }}">
                    </td>
                </tr>
              </ng-container>
              <ng-template #noData>
                <td class="text-center">No data available</td>
              </ng-template>
            </ng-container>
            
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <input type="hidden" id="bot_id" formControlName="bot_id" class="col-8 form-control">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
