import { tap, map, exhaustMap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ReferralCommissionSettingsDataService } from './services/referral-commission-settings-data.service';
import { ReferralCommissionSettingsEntityService } from './services/referral-commission-settings-entity.service';
import { Pagination } from '@core/models/pagination.model';
import { ReferralCommissionSettingsEditDialogComponent } from './dialogs/referral-commission-settings-edit.component';
import { Observable, of, Subscription, forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ReferralCommissionSetting } from '@core/models/referral-commission-setting.model';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TranslateService } from '@ngx-translate/core';
import { AppPermissionService } from '@core/services/app-permission.service';
import { Promotion } from '@core/models/promotion.model';
import { PromotionSettingHttpService } from '@core/services/promotion-setting-http.service';
import { GroupEntityService } from '../../general/groups/services/group-entity.service';
@Component({
  selector: 'kt-referral-commission-settings',
  templateUrl: './referral-commission-settings.component.html',
  styleUrls: ['./referral-commission-settings.component.scss']
})
export class ReferralCommissionSettingsComponent implements OnInit, OnDestroy, AfterViewInit {
  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage,
    types : this.dropdownHttpService.referralCommSettingTypes,
  };
  referralCommissionSettings$: Observable<ReferralCommissionSetting[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  memberGroupsDropdownList = [];
  memberGroupsSelectedItems = [];
  memberGroupsDropdownSettings = {};

  currenciesDropdownList = [];
  currenciesSelectedItems = [];
  currenciesDropdownSettings = {};
  
  promoTypeahead = [];
  promotionsDropdownSettings = {};

  sortingConfig = {
    'id': 'desc',
    'name': 'desc',
    'currencies': 'desc',
    'member_group_name': 'desc',
    'promotion_name': 'desc',
    'type_name': 'desc',
    'min_referral_commission': 'desc',
    'max_referral_commission': 'desc',
    'status': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
    'level_commission_rate1': 'desc',
    'level_commission_rate2': 'desc',
    'level_commission_rate3': 'desc',
  };

  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '17.3';

  // permissions
  canCreateReferralCommissionSettings: boolean;
  canViewReferralCommissionSettings: boolean;
  canDuplicateReferralCommissionSettings: boolean;
  canEditReferralCommissionSettings: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private referralCommissionSettingsDataService: ReferralCommissionSettingsDataService,
    private referralCommissionSettingsEntityService: ReferralCommissionSettingsEntityService,
    private loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private eventEmitterService: EventEmitterService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private appPermissionService: AppPermissionService,
    private promotionSettingHttpService: PromotionSettingHttpService,
    private groupService: GroupEntityService,
  ) { }

  async ngOnInit() {
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));

    this.memberGroupsDropdownSettings = {
      text: this.translateService.instant('Please Select'),
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      singleSelection: false,
      classes: "dropdown",
      primaryKey: "id",
      labelKey: "name",
      noDataLabel: "",
      showCheckbox: false,
    };

    this.currenciesDropdownList = JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies'));
    this.currenciesDropdownSettings = {
      text: this.translateService.instant('Please Select'),
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      singleSelection: false,
      classes: "dropdown",
      primaryKey: "id",
      labelKey: "name",
      noDataLabel: "",
      showCheckbox: false,
    };

    this.promotionSettingHttpService.getAllPromoSettings().subscribe((res: any) => {
      this.promoTypeahead = res.promotions;
    });

    this.promotionsDropdownSettings = {
      singleSelection: true,
      text: 'All',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 150,
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };

    this.formInit();
    await this.setMemberGroup();
    this.reload();

    const apSub = this.appPermissionService.getAppPermissions().subscribe((appPermissions) => {
      this.canCreateReferralCommissionSettings = appPermissions.create_referral_commission_settings;
      this.canViewReferralCommissionSettings = appPermissions.view_referral_commission_settings;
      this.canEditReferralCommissionSettings = appPermissions.edit_referral_commission_settings;
      this.canDuplicateReferralCommissionSettings = appPermissions.duplicate_referral_commission_settings;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onOpenDialog(type: string, referralCommissionSettingId?: number) {
    if (referralCommissionSettingId) {
      const operator = this.referralCommissionSettingsDataService.getById(referralCommissionSettingId);
      this.subscription = operator.pipe(
        tap((res) => {
          this.openDialogBy(ReferralCommissionSettingsEditDialogComponent, { referralCommissionSetting: res, mode: type });
        })
      )
        .subscribe();
    } else {
      this.openDialogBy(ReferralCommissionSettingsEditDialogComponent, { mode: type });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.referralCommissionSettings$ = this.referralCommissionSettingsDataService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.referralCommissionSettingsDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.memberGroupsSelectedItems = [];
    this.currenciesSelectedItems = [];
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    if (this.form.value.promotion) {
      this.form.value.promotion = this.form.value.promotion.name;
    }
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        this.referralCommissionSettings$ = this.referralCommissionSettingsDataService.getWithQuery(`?${this.params}&perPage=${this.pageSize}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.referralCommissionSettingsDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page);
  }

  private openDialogBy(componentRef: any, data?: { referralCommissionSetting?: any, mode?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '1800px',
      data: {
        referralCommissionSetting: data.referralCommissionSetting,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page);
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      settings_currency_id: new FormControl(null),
      member_group_id: new FormControl(null),
      promotion: new FormControl(null),
      status: new FormControl('all'),
      type: new FormControl('all'),
    });
  }

  filterPromo(term: string): Promotion[] {
    if (this.promoTypeahead) {
      return this.promoTypeahead.filter(
        promo => promo.code.toLowerCase().includes(term.toLowerCase()) || promo.name.toLowerCase().includes(term.toLowerCase())
      );
    } else {
      return [];
    }
  }

  searchPromo = (search$: Observable<string>): Observable<Promotion[]> =>{
    return search$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(term => term.trim() === '' ? [] : this.filterPromo(term))
    )
  }

  formatPromo(promotion: Promotion): string {
    return `${promotion.code} - ${promotion.name}`;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private setMemberGroup():Promise<void> {
    return new Promise((resolve, reject) => {
        this.groupService.getWithQuery(`?status=1&paginate=false`).subscribe(res => {
            this.memberGroupsDropdownList = res;  
            resolve();
        });
    });
}

}
