import { Injectable } from "@angular/core";
import { CustomUploadAdapterPlugin } from '@core/ckeditor-upload-adapter/CustomUploadAdapterPlugin';
import { environment } from '@env/environment';

@Injectable()
export class EditorService {

  config = {
    extraPlugins: [CustomUploadAdapterPlugin],
    removePlugins: ["MediaEmbedToolbar"],
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'fontColor',
        '|',
        'insertTable',
        'bulletedList',
        'numberedList',
        'fontSize',
        'fontFamily',
        '|',
        'sourceEditing',
        '|',
        'alignment',
        'outdent',
        'indent',
        'codeBlock',
        'fontBackgroundColor',
        'highlight',
        '|',
        'link',
        'imageUpload',
        'mediaEmbed',
        '|',
        'blockQuote',
        'code',
        'horizontalLine',
        'strikethrough',
        'subscript',
        'superscript',
        '|',
        'undo',
        'redo',
        'removeFormat',
      ]
    },
    language: 'en',
    image: {
      toolbar: [
        'imageTextAlternative',
        'linkImage'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties'
      ]
    },
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true
        }
      ]
    },
    apiBaseUrl: environment.apiBase
  };

  config2 = {
    extraPlugins: [CustomUploadAdapterPlugin],
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'fontColor',
        '|',
        'insertTable',
        'bulletedList',
        'numberedList',
        'fontSize',
        'fontFamily',
      ]
    },
    language: 'en',
    image: {
      toolbar: [
        'imageTextAlternative',
        'linkImage'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties'
      ]
    },
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true
        }
      ]
    },
    // link: {
    //   defaultProtocol: 'http://'
    // }
    link: {
      decorators: {
        addTargetToExternalLinks: {
          mode: 'automatic',
          callback: url => /^(https?:)?\/\//.test(url),
          attributes: {
            target: '_blank',
          }
        }
      }
    },
    removePlugins: ["MediaEmbedToolbar"]
  };


  constructor() { }
}

// Available item that can enable
// items: [
//   'heading',
//   'bold',
//   'italic',
//   'underline',
//   'fontColor',
//   'fontBackgroundColor',
//   'highlight',
//   'insertTable',
//   'bulletedList',
//   'numberedList',
//   'todoList',
//   'fontSize',
//   'fontFamily',
//   'alignment',
//   'undo',
//   'redo',
//   'sourceEditing',
//   'link',
//   'blockQuote',
//   'code',
//   'horizontalLine',
//   'htmlEmbed',
//   'indent',
//   'outdent',
//   'removeFormat',
//   'strikethrough',
//   'subscript',
//   'superscript',
//   'imageUpload',
//   'codeBlock',
//   'imageInsert'
// ]