<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/glightbox/dist/css/glightbox.min.css">
<script src="https://cdn.jsdelivr.net/gh/mcstudios/glightbox/dist/js/glightbox.min.js"></script>

<!-- USING A CDN -->

<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/glightbox/dist/css/glightbox.min.css" />
<script src="https://cdn.jsdelivr.net/gh/mcstudios/glightbox/dist/js/glightbox.min.js"></script>

<script type="text/javascript">
  const lightbox = GLightbox({ ...options });
</script>

<div class="kt-form w-webkit-fill-available" *ngIf="dropdown.locales.length > 0">
    <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">

      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
          <form [formGroup]="form">
            <div class="row align-items-center">
              <div class="col-xl-12 order-2 order-xl-1">
                <div class="row mb-3">
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Date' }}:</label>
                  </div>
                  <div class="col-md-5 kt-form__control">
                    <div class="input-group date">
                      <input type="hidden" formControlName="start_date">
                      <input type="hidden" formControlName="end_date">
                      <input type="text" class="form-control" placeholder="{{ 'Search' }}" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" ngxDaterangepickerMd />
                      <span class="input-group-append">
                        <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Code' | translate }}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <input type="text" formControlName="code" placeholder="{{ 'Search' | translate }}" class="form-control">
                  </div>
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Location' }}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <select formControlName="location" class="form-control">
                      <option value="all"> {{'All Locations' }} </option>
                      <option [value]="value.id" *ngFor="let value of dropdown.locations">
                        {{ value.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 order-2 order-xl-1">
                <div class="row mb-3">
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Status' }}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <select formControlName="status" class="form-control">
                      <option value="all"> {{ 'All' }} </option>
                      <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
                        {{ value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Locale' }}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <select formControlName="locale_id" class="form-control">
                      <option value="all"> {{ 'All' }} </option>
                      <option [value]="value.id" *ngFor="let value of dropdown.locales">
                        {{ value.code }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Platform' }}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <select formControlName="platform" class="form-control">
                      <option value="all">{{ 'All Platform' }}</option>
                      <option [value]="value.id" *ngFor="let value of dropdown.platforms; let i = index">
                        {{ value.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Session'}}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <select formControlName="session" class="form-control">
                      <option value="all">{{ 'All Session' }}</option>
                      <option [value]="value.id" *ngFor="let value of dropdown.sessions; let i = index">
                        {{ value.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 order-2 order-xl-1">
                <div class="row mb-3">
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Affiliates Visibility' }}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control toggle_button">
                    <label class="switch">
                      <input type="checkbox" [checked]="form.value.affiliates_visibility == 1" (change)="onFilterAffVisibility($event)">
                      <div class="slider round">
                        <span class="on">ON</span>
                        <span class="off">OFF</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="kt-section col-12 row d-flex justify-content-between mb-0 mt-1">
            <div class="dropdown dropdown-inline">
              <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmitClick()"></kt-search-button>
              <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onSubmitClick(true)"></kt-clear-button>
            </div>
          </div>
        </div>
      </div>
      <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__body pt-12 pb-0">
            <div class="kt-section">
              <div class="kt-section__content">
                <div class="table-responsive">
                  <table class="table table-bordered table-hover table-striped table-sortable">
                    <thead>
                      <tr>
                        <th width="5" class="sort-enabled pointer" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">{{ 'Id' | uppercase }}</th>
                        <th width="5" class="pr-0">{{ 'Locale' | uppercase }}</th>
                        <th width="5" class="pl-1 pr-3">
                          <div class="dropdown selected-category-container ml-1" *ngIf="displayLocale != null">
                            <button class="dropdown-toggle d-flex justify-content-center dropdown-box" type="button" data-toggle="dropdown" style="margin: 0 auto">
                              <p class="mr-1">{{ displayLocale['code'] | uppercase }} </p>
                              <i class="fa fa-sort-down"></i>
                            </button>
                            <ul class="dropdown-menu dropdown-box-open">
                              <li *ngFor="let value of dropdown.locales" class="selected-category-container2 ml-0" (click)="changeLocale(value)">
                                {{ value.code | uppercase }}
                              </li>
                            </ul>
                          </div>
                        </th>
                        <th>{{ 'Code' | uppercase }}</th>
                        <th>{{ 'Desktop' | uppercase }}</th>
                        <th>{{ 'Mobile' | uppercase }}</th>
                        <th>{{ 'Platform' | uppercase }}</th>
                        <th>{{ 'Title' | uppercase }}</th>
                        <th>{{ 'Session' | uppercase }}</th>
                        <th class="sort-enabled pointer" (click)="onSortColumn('position')" [ngClass]="sortingConfig.position" [class.sort-selected]="sortingSelection.sort_by === 'position'">{{ 'Position' | uppercase }}</th>
                        <th class="sort-enabled pointer" (click)="onSortColumn('start_date')" [ngClass]="sortingConfig.start_date" [class.sort-selected]="sortingSelection.sort_by === 'start_date'">{{ 'Start Date' | uppercase }}</th>
                        <th class="sort-enabled pointer" (click)="onSortColumn('end_date')" [ngClass]="sortingConfig.end_date" [class.sort-selected]="sortingSelection.sort_by === 'end_date'">{{ 'End Date' | uppercase }}</th>
                        <th>{{ 'Status' | uppercase }}</th>
                        <th class="text-center pr-13">{{ 'Actions' | uppercase }}</th>
                      </tr>
                      <tr *ngIf="canCreateNewDialog && (!mode || mode != 'create')">
                        <th colspan="15" class="pr-0 pl-0">
                          <div class="content-container text-center" (click)="expandRowCreate('create')">
                            <span><i class="fas fa-plus mr-2"></i>Create New Content</span>
                          </div>
                        </th>
                      </tr>
                      <tr *ngIf="mode == 'create'">
                        <th colspan="15" class="pr-0 pl-0">
                          <ng-container *ngIf="!mode else contents"></ng-container>
                        </th>
                      </tr>
                    </thead>
                    <tbody *ngIf="(dialog$ | async) as rows">
                      <ng-container *ngFor="let row of rows">
                        <tr>
                          <td [style]="mode == 'edit' && dialog.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.id }}</td>
                          <td [style]="mode == 'edit' && dialog.id == row.id ? 'border-bottom: 0 !important;' : ''" colspan="2" width="1" class="pr-5">
                            <div style="width: 100px;">
                              {{ row.locale_list }}
                            </div>
                          </td>
                          <td [style]="mode == 'edit' && dialog.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.code }}</td>
                          <td [style]="mode == 'edit' && dialog.id == row.id ? 'border-bottom: 0 !important;' : ''">
                            <ng-container *ngIf="displayLocaleImageVideo(row, 'desktop') != '' else NoDesktopVideoImage">
                              <i  class="play-text row"  *ngIf="displayLocaleImageVideoType(row, 'desktop')  == 'youtube' " (click)="openVideo(displayLocaleImageVideo(row, 'desktop'))" > <i class="fas fa-play play-icon"></i> <p class="m-0 pl-2">{{'Play Video' | uppercase }}</p></i>
                              <img *ngIf="displayLocaleImageVideoType(row, 'desktop')  == 'image'" [src]=" displayLocaleImageVideo(row, 'desktop')" alt="desktop image" style="max-width: 150px; cursor: pointer; max-height: 100px;" (click)="openImage(displayLocaleImageVideo(row, 'desktop'))">
                            </ng-container>
                            <ng-template #NoDesktopVideoImage>
                              -
                            </ng-template>
                          </td>
                          <td [style]="mode == 'edit' && dialog.id == row.id ? 'border-bottom: 0 !important;' : ''" >
                            <ng-container *ngIf="displayLocaleImageVideo(row, 'mobile') != '' else NoMobileVideoImage">
                              <i  class="play-text row"  *ngIf="displayLocaleImageVideoType(row, 'mobile')  == 'youtube' " (click)="openVideo(displayLocaleImageVideo(row, 'mobile'))" > <i class="fas fa-play play-icon"></i><p class="m-0 pl-2">{{'Play Video' | uppercase }}</p></i>
                              <img *ngIf="displayLocaleImageVideoType(row, 'mobile')  == 'image'" [src]=" displayLocaleImageVideo(row, 'mobile')" alt="mobile image" style="max-width: 150px; cursor: pointer; max-height: 100px;" (click)="openImage(displayLocaleImageVideo(row, 'mobile'))">
                            </ng-container>
                            <ng-template #NoMobileVideoImage>
                              -
                            </ng-template>
                          </td>
                          <td [style]="mode == 'edit' && dialog.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.platform_name }}</td>
                          <td [style]="mode == 'edit' && dialog.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ displayLocaleContent(row, 'title') }}</td>
                          <td [style]="mode == 'edit' && dialog.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.session_name }}</td>
                          <td [style]="mode == 'edit' && dialog.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.position }}</td>
                          <td [style]="mode == 'edit' && dialog.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.start_date | timezoneDate:'YYYY-MM-DD HH:mm' }}</td>
                          <td [style]="mode == 'edit' && dialog.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.end_date !== null ? (row.end_date | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }}</td>
                          <td [style]="mode == 'edit' && dialog.id == row.id ? 'border-bottom: 0 !important;' : ''">
                            <label class="switch">
                              <input [disabled]="!canEditDialog && !canUpdateDialogStatus" type="checkbox" id="togBtn" [checked]="row.status == 1" (change)="onChangeStatus($event, row, false)">
                              <div class="slider round">
                                <span class="on">ON</span>
                                <span class="off">OFF</span>
                              </div>
                            </label>
                          </td>
                          <td [style]="mode == 'edit' && dialog.id == row.id ? 'border-bottom: 0 !important;' : ''" class="text-center actions-column">
                            <button *ngIf="canPreviewDialog && displayPreviewIcon(row)" matTooltip="Preview" class="btn btn-elevate btn-sm btn-icon btn-icon-sm" (click)="preview(row)"><i class="fas fa-eye"></i></button>
                            <button *ngIf="canViewDialogDetails" matTooltip="{{ 'View' }}" class="btn btn-elevate btn-sm btn-icon btn-icon-sm" (click)="expandRowEdit('edit', row)"><i class="fas fa-cog"></i></button>
                          </td>
                        </tr>
                        <tr *ngIf="mode == 'edit' && dialog.id == row.id">
                          <td colspan="15" class="pr-0 pl-0 pt-0 pb-0" style="border-bottom: 0 !important;">
                            <ng-container *ngIf="!mode else contents"></ng-container>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                    <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold table-handler"></tbody>
                  </table>
                </div>
                <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                  <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                  </ngb-pagination>
                  <div class="kt-pagination__toolbar">
                    <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                      <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                    </select>
                    <span class="pagination__desc">
                      Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <ng-template #contents>
    <form [formGroup]="formContent">
      <div class="create-content-container" *ngIf="dropdown.locales.length > 0">
        <div class="row col-12 p-0 m-0">
          <div class="col-12 mb-2" *ngIf="mode == 'create'">
            <span class="header">Create New Dialog</span>
          </div>
          <div class="form-group col-3 mb-3">
            <label>{{ 'Platform' }}: <span class="text-danger">*</span></label>
            <select formControlName="platform" class="form-control">
              <option [value]="value.id" *ngFor="let value of dropdown.platforms; let i = index">
                {{ value.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-3 mb-3">
            <label>{{ 'Location' }}: <span class="text-danger">*</span></label>
            <select formControlName="location" class="form-control">
              <option [value]="value.id" *ngFor="let value of dropdown.locations; let i = index">
                {{ value.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-3 mb-3">
            <label>{{ 'Start Date' }}: <span class="text-danger">*</span></label>
            <div class="input-group date">
              <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack?.start_date" [ngModelOptions]="{standalone: true}">
              <input type="text" formControlName="start_date" class="form-control">
              <span class="input-group-append" [owlDateTimeTrigger]="dateStartRef">
                <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
              </span>
              <owl-date-time #dateStartRef></owl-date-time>
            </div>
          </div>
          <div class="form-group col-3 mb-3">
            <label>{{ 'End Date' }}: <span class="text-danger"></span></label>
            <div class="input-group date">
              <input class="hidden" [owlDateTime]="dateEndRef" [ngModel]="dateTimeStack?.end_date" [ngModelOptions]="{standalone: true}">
              <input type="text" formControlName="end_date" class="form-control">
              <span class="input-group-append" [owlDateTimeTrigger]="dateEndRef">
                <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
              </span>
              <owl-date-time #dateEndRef></owl-date-time>
            </div>
          </div>
        </div>
        <div class="row col-12 p-0 m-0">
          <div class="form-group col-3 mb-3">
            <label>{{ 'Position' }}: <span class="text-danger">*</span></label>
            <input type="number" min="1" (wheel)="false" step="1" formControlName="position" class="form-control">
          </div>
          <div class="form-group col-3 mb-3" *ngIf="mode == 'create'">
            <label>{{ 'Status' }}: <span class="text-danger">*</span></label>
            <select formControlName="status" class="form-control">
              <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
                {{ value }}
              </option>
            </select>
          </div>
          <div class="form-group col-3 mb-0">
            <label>{{ 'Session' }}: <span class="text-danger">*</span></label>
            <select formControlName="session" class="form-control">
              <option [value]="value.id" *ngFor="let value of dropdown.sessions; let i = index">
                {{ value.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-3 mb-0">
            <label>{{ 'Always Pop' }}: <span class="text-danger">*</span><i class="fa fa-info-circle ml-1" placement="bottom" [ngbTooltip]="linkToolTip" tooltipClass="tooltip-class"></i></label>
            <div class="col-md-2 kt-form__control toggle_button p-0">
              <label class="switch" style="margin: 5px 0 !important;">
                <input type="checkbox" [checked]="formContent.value.always_pop == 1" (change)="onChangeAlwaysPop($event)">
                <div class="slider round">
                  <span class="on">ON</span>
                  <span class="off">OFF</span>
                </div>
              </label>
            </div>
            <ng-template #linkToolTip>
              <div class="text-left">
                - <b>Toggle ON</b> to always display the dialog popup whenever member access the page.<br><br>
                - <b>Toggle OFF</b> to display the dialog box based on default conditions or specific triggers. 
              </div>
            </ng-template>
          </div>
          <div class="form-group col-3 mb-0">
            <label>{{ 'Affiliates Visibility' }}: <span class="text-danger">*</span></label>
            <div class="col-md-2 kt-form__control toggle_button p-0">
              <label class="switch" style="margin: 5px 0 !important;">
                <input type="checkbox" [checked]="formContent.value.affiliates_visibility == 1" (change)="onChangeAffVisibility($event)">
                <div class="slider round">
                  <span class="on">ON</span>
                  <span class="off">OFF</span>
                </div>
              </label>
            </div>
          </div>
        </div>
        <hr>
        <mat-spinner [diameter]="30" class="loading m-auto" *ngIf="!checkrender"></mat-spinner>
        <ng-container *ngIf="checkrender">
        <mat-tab-group animationDuration="0ms" class="form-group mb-0 mat-tab-header-pagination-controls-enabled" formGroupName="contents" [selectedIndex]="localeIndex()">
          <ng-container *ngIf="dropdown.locales.length > 0">
            <mat-tab *ngFor="let value of dropdown.locales; let i = index" [formGroupName]="value.id">
              <ng-template mat-tab-label>
                <span class="mat-tab">
                  {{ value.code }}
                  <div *ngIf="checkContent(value)" class="icon" (click)="clearContent(value)">
                    <i class="fas fa-times"></i>
                  </div>
                </span>
              </ng-template>
              <div class="row p-0">
                <div class="form-group col-3 mb-3">
                  <label>{{ 'Media Type' }}:</label>
                  <div class="col-9 p-0">
                      <label class="kt-radio mr-3" *ngFor="let item of mediaTypes ; let i = index">
                        <input type="radio" (change)="onMediaTypes($event, item.name, value.id)" class="custom-control-input" [id]="item.id" [checked]="this.formContent.get('contents.'+value.id+'.media_type').value == item.id" value="item.id" > {{ item.name | uppercase  }} <span class="reset-preq-radio"></span>
                      </label>
                  </div>
                </div>
              </div>
              <div class="row p-0">
                <div class="form-group col-3 mb-3" *ngIf=" (this.formContent.get('platform').value == 1  || this.formContent.get('platform').value == 3 ) &&  this.formContent.get('contents.'+value.id+'.media_type').value == 1">
                  <label>{{ 'Video URL (Desktop)' }}:</label>
                  <input type="text"  formControlName="video_desktop_link" class="form-control" >
                </div>
                <div class="form-group col-3 mb-3" *ngIf="(this.formContent.get('platform').value == 1  || this.formContent.get('platform').value == 2 ) && this.formContent.get('contents.'+value.id+'.media_type').value == 1">
                  <label>{{ 'Video URL (Mobile)' }}:</label>
                  <input type="text" formControlName="video_mobile_link" class="form-control" >
                </div>

                <div class="form-group col-3 mb-3" *ngIf="(this.formContent.get('platform').value == 1  || this.formContent.get('platform').value == 3) && this.formContent.get('contents.'+value.id+'.media_type').value == 2">
                  <label>{{ 'Image (Desktop)' }}:</label>

                  <label class="btn btn-default btn-sm btn-upload upload-icon" *ngIf="getMediaSource(value.id , 'desktop_link') == null && !isImageUploading[0]">
                    <img src="assets/img/upload-icon.png">
                    <span>{{ 'Upload' }}</span>
                    <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'desktop_link', value.id, 0)" [disabled]="(mobileLoading || desktopLoading)"/>
                  </label>

                  <div class="spinner-wrapper" *ngIf="isImageUploading[0]">
                      <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                  </div>

                  <div class="image-row" *ngIf="getMediaSource(value.id , 'desktop_link') && !isImageUploading[0]">
                      <div class="image-upload">
                          <div class="image-wrapper">
                            <img class="fas fa-trash position-absolute m-2 trash-icon" src="assets/img/trash-icon.png" (click)="removeImage($event,'desktop_link',value.id)">
                            <img class="img-fluid preview-image" alt="Image Desktop" [src]="getMediaSource(value.id, 'desktop_link')" />
                          </div>
                      </div>
                  </div>
                </div>

                <div class="form-group col-3 mb-3" *ngIf="(this.formContent.get('platform').value == 1  || this.formContent.get('platform').value == 2) && this.formContent.get('contents.'+value.id+'.media_type').value == 2">
                  <label>{{ 'Image (Mobile)' }}:</label>

                  <label class="btn btn-default btn-sm btn-upload upload-icon" *ngIf="getMediaSource(value.id , 'mobile_link') == null && !isImageUploading[1]">
                    <img src="assets/img/upload-icon.png">
                    <span>{{ 'Upload' }}</span>
                    <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event, 'mobile_link', value.id, 1)" [disabled]="(mobileLoading || desktopLoading)" />
                  </label>

                  <div class="spinner-wrapper" *ngIf="isImageUploading[1]">
                      <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                  </div>

                  <div class="image-row" *ngIf="getMediaSource(value.id , 'mobile_link') && !isImageUploading[1]">
                      <div class="image-upload">
                        <div class="image-wrapper">
                          <img class="fas fa-trash position-absolute m-2 trash-icon" src="assets/img/trash-icon.png" (click)="removeImage($event,'mobile_link',value.id)">
                          <img class="img-fluid preview-image" alt="Image Mobile" [src]="getMediaSource(value.id, 'mobile_link')" />
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="row col-12 m-0 p-0">
                <div class="form-group col-3 mb-3 p-0">
                  <label>{{ 'CTA Button Type' }}:</label>
                  <div class="col-9 p-0">
                    <label class="kt-radio mr-3" *ngFor="let item of ctaButtonTypes ; let i = index">
                      <input type="radio" (change)="onCTAButtonTypes($event, item.name, value.id)" class="custom-control-input" [id]="item.id" [checked]="this.formContent.get('contents.'+value.id+'.cta_button_type').value == item.id" value="item.id"> {{ item.name | translate | uppercase }} <span class="reset-preq-radio"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row col-12 m-0 p-0">
                <div class="form-group col-3 mb-3 pl-0" *ngIf="this.formContent.get('contents.'+value.id+'.cta_button_type').value == 1 || this.formContent.get('contents.'+value.id+'.cta_button_type').value == 2">
                  <label>{{ 'CTA Button Text (Left)' }}</label>
                  <input type="text" formControlName="cta_button_text_1" class="form-control">
                </div>
                <div class="form-group col-3 mb-3" *ngIf="this.formContent.get('contents.'+value.id+'.cta_button_type').value == 1 || this.formContent.get('contents.'+value.id+'.cta_button_type').value == 2">
                  <label>{{ 'CTA Button Link (Left)' }}<i class="fa fa-info-circle ml-1" placement="bottom" [ngbTooltip]="linkToolTip" tooltipClass="tooltip-class"></i></label>
                  <input type="text" formControlName="cta_button_link_1" class="form-control">
                  <span class="header-hint">
                    <div *ngFor="let CTAVariable of CTAVariables; let last = last" class="p-inline">
                      <p class="p-inline" (click)="addVariableCTA(CTAVariable, value.id, 1)">{{ CTAVariable }}</p>
                      <div *ngIf="!last" class="p-inline">
                        <p class="p-inline p-display">, </p>
                      </div>
                    </div>
                  </span>
                </div>
                <ng-template #linkToolTip>
                  <div class="text-left">
                    Internal Link: A hyperlink that points to the other pages on your website. <br>
                    Examples:<br>
                    /promotion<br>
                    /referral<br>
                    <br><br>
                    External Link: A hyperlink that points to the target page on other websites.<br>
                    Examples:<br>
                    /external?link=https://example.com/mobileapp.apk<br>
                    /external?link=https://google.com<br>
                  </div>
                </ng-template>
                <div class="form-group col-3 mb-3" *ngIf="this.formContent.get('contents.'+value.id+'.cta_button_type').value == 2">
                  <label>{{ 'CTA Button Text (Right)' }}</label>
                  <input type="text" formControlName="cta_button_text_2" class="form-control">
                </div>
                <div class="form-group col-3 mb-3 pr-0" *ngIf="this.formContent.get('contents.'+value.id+'.cta_button_type').value == 2">
                  <label>{{ 'CTA Button Link (Right)' }}<i class="fa fa-info-circle ml-1" placement="bottom" [ngbTooltip]="linkToolTip" tooltipClass="tooltip-class"></i></label>
                  <input type="text" formControlName="cta_button_link_2" class="form-control">
                  <span class="header-hint">
                    <div *ngFor="let CTAVariable of CTAVariables; let last = last" class="p-inline">
                      <p class="p-inline" (click)="addVariableCTA(CTAVariable, value.id, 2)">{{ CTAVariable }}</p>
                      <div *ngIf="!last" class="p-inline">
                        <p class="p-inline p-display">, </p>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="row col-12 p-0 m-0">
                <div class="form-group col-12 mb-0 p-0">
                  <label>{{ 'Title' }}: </label>
                  <input type="text" formControlName="title" class="form-control">
                </div>
              </div>
              <div class="col-12 p-0 mb-2">
                <span class="header-hint">Usable variables:
                  <div *ngFor="let usableVariable of usableVariables; let last = last" class="p-inline">
                    <p class="p-inline" (click)="addVariableTitle(usableVariable, value.id)">{{ usableVariable }}</p>
                    <div *ngIf="!last" class="p-inline">
                      <p class="p-inline p-display">, </p>
                    </div>
                  </div>
                </span>
              </div>
              <div class="row col-12 p-0 m-0 mt-2">
                <div class="form-group col-12 mb-0 p-0">
                  <label>{{ 'Content' }}: </label>
                  <ckeditor #editors [config]="editorConfig" [editor]="editor" formControlName="content"></ckeditor>
                </div>
              </div>
              <div class="col-12 p-0 mb-2">
                <span class="header-hint">Usable variables:
                  <div *ngFor="let usableVariable of usableVariables; let last = last" class="p-inline">
                    <p class="p-inline" (click)="addVariable(usableVariable, value.id)">{{ usableVariable }}</p>
                    <div *ngIf="!last" class="p-inline">
                      <p class="p-inline p-display">, </p>
                    </div>
                  </div>
                </span>
              </div>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </ng-container>
        <div class="modal-footer">
          <table class="info mr-3" *ngIf="mode == 'edit'">
            <tr>
              <td class="header-hint">
                Updated by: {{ dialog.updated_by ? dialog.updated_by + '&nbsp;' : 'System&nbsp;' }}
              </td>
              <td class="header-hint">
                <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="tooltip-class">{{ dialog.updated_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #updatedDateTime>
                {{ dialog.updated_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
              </td>
            </tr>
            <tr>
              <td class="header-hint">
                Created by: {{ dialog.created_by ? dialog.created_by + '&nbsp;' : 'System&nbsp;' }}
              </td>
              <td class="header-hint">
                <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="tooltip-class">{{ dialog.created_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #createdDateTime>
                {{ dialog.created_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
              </td>
            </tr>
          </table>
          <button type="button" class="btn btn-danger m-0 mr-2" (click)="mode == 'create' ? expandRowCreate('', true) : expandRowEdit('', null, true)">{{ 'Cancel' }}</button>
          <button *ngIf="(mode == 'create' && canCreateNewDialog) || (mode == 'edit' && canEditDialog)" type="button" class="btn btn-success m-0" [disabled]="isEmptyContent || !formContent.valid || isImageUploading[0] || isImageUploading[1]" (click)="onSave()">{{ mode != null && mode == 'create' ? 'Create' : 'Update' }}</button>
        </div>
      </div>
    </form>
  </ng-template>

  <kt-swal-alert [message]="messages$ | async" [data]="data$ | async" (confirmed)="reload()" (confirmed2)="onChangeStatusConfirm()" (cancelled)="resetPopup()"></kt-swal-alert>
