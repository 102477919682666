// Angular
import {
  ChangeDetectorRef,
  ElementRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
// Service
import { catchError, map, tap } from "rxjs/operators";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { DepositLog } from "@core/models/deposit-log.model";
import { DepositDataService } from "../../services/deposit-data.service";
import { TransactionStatus } from "@core/enums/transaction-status.enum";
import { BankTransactionStatus } from "@core/enums/bank-transaction-status.enum";
import { WithdrawDataService } from "../../../withdrawals/services/withdraw-data.service";
import { BankTransactionDataService } from "@views/pages/apps/settings/bank-transaction/service/bank-transaction-data.service";
import { DepositStatus } from "@core/enums/deposit-status.enum";

type RemarkType = 'deposit' | 'withdraw' | 'bank-transaction';

@Component({
  selector: "app-deposit-show-remark-history",
  templateUrl: "./show-remark-history.component.html",
  styleUrls: ["./show-remark-history.component.scss"],
})
export class ShowRemarkHistoryComponent implements OnInit, OnDestroy {
  @ViewChildren("focusfield") focusfield: QueryList<ElementRef>;

  private subscription = new Subscription();

  messages$ = this.depositDataService.messages$;

  remarks: DepositLog[];
  loading = false;
  remarkLength = 0;
  status = {};

  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: any, remarkType: RemarkType },
    public dialogRef: MatDialogRef<ShowRemarkHistoryComponent>,
    public depositDataService: DepositDataService,
    public withdrawDataService: WithdrawDataService,
    public bankTransactionDataService: BankTransactionDataService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.status = this.data.remarkType === 'bank-transaction' ? BankTransactionStatus : TransactionStatus;

    const dataServices = {
      'deposit': this.depositDataService,
      'withdraw': this.withdrawDataService,
      'bank-transaction': this.bankTransactionDataService
    };

    this.fetchRemarksHistory(dataServices[this.data.remarkType]);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  /**
   * Normalize status between different remark type
   *
   * For example bank transaction's Rejected status has different status number with deposit/withdraw Rejected.
   * This function is meant to get correct status number for badge styling
   *
   * We will use deposit (transaction type) as base reference,
   *
   * e.g.
   * bank transaction's Rejected status number shall change to deposit's Rejected to get correct badge style
   *
   * @param status Status (number) of remark type
   * @returns
   */
  normalizeStatus(status) {
    const remarkType = this.data.remarkType;

    if (remarkType === 'bank-transaction') {
      const statusMap = {
        [BankTransactionStatus.Rejected]: TransactionStatus.Rejected,
        [BankTransactionStatus.Approved]: TransactionStatus.Approved,
        [BankTransactionStatus.Pending]: TransactionStatus.Pending,
      };

      return statusMap[status];
    } else if (remarkType === 'deposit') {
      const statusMap = {
        [DepositStatus.Pending]: TransactionStatus.Pending,
        [DepositStatus.Approved]: TransactionStatus.Approved,
        [DepositStatus.Rejected]: TransactionStatus.Rejected,
        [DepositStatus.InProgress]: TransactionStatus['In Progress'],
        [DepositStatus.Risky]: TransactionStatus.Risky,
        [DepositStatus.PendingCallback]: TransactionStatus['Pending Callback'],
        [DepositStatus.Failed]: TransactionStatus.Failed,
        [DepositStatus.PendingReceipt]: TransactionStatus['Pending Callback'],
      };

      return statusMap[status];
    }

    return status;
  }

  private fetchRemarksHistory(dataService: WithdrawDataService | DepositDataService | BankTransactionDataService) {
    dataService
      .getRemarksHistory(this.data.id)
      .pipe(
        tap((res) => {
          this.loading = false;
          this.remarks = res.data;
          this.remarkLength = res.data.length;
        }),
        catchError((error) => {
          this.loading = false;
          this.remarkLength = 0;
          // This detect changes is needed to update the table handler
          this.cdr.detectChanges();
          throw error;
        })
      )
      .subscribe();
  }
}
