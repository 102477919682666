<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <!-- Header -->
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create'}} VIP Settings</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>

      <div class="modal-body">
        <div class="col-12 kt-mb-10">
          <strong>General</strong>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Name <span class="text-danger">*</span></label>
          <input type="text" formControlName="name" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Currency <span class="text-danger">*</span></label>
          <select class="col-8 form-control" formControlName="currency_id" (change)="checkMemberAvailableAdjustment($event.target.value)">
            <option [value]="null" [disabled]="true">Please Select</option>
            <ng-container *ngFor="let value of dropdown.currencies">
              <option [value]="value.id">{{ value.name }}</option>
            </ng-container>
          </select>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Member Group <span class="text-danger">*</span></label>
          <kt-dropdown-wo-lazyload [form]="form" class="col-8 p-0" 
            [dropdownList]="memberGroupDropdownList" 
            [selectedItems]="memberGroupSelectedItems" 
            [selectionAttributes]="'id'" [formName]="'member_group_id'" 
            [dropdownSettings]="memberGroupDropdownSettings" 
            (selectedItemsChanged)="onChangeMemberGroup($event)">
          </kt-dropdown-wo-lazyload>
        </div>
        <!-- <div class="col-12 form-group row" *ngIf="form.value.upgrade_requirement === null || form.value.upgrade_requirement === '' || (data.mode === 'edit' && form.value.ftd)">
          <label class="col-4 col-form-label">First Time Deposit <span class="text-danger">*</span></label>
          <div class="custom-control custom-checkbox col-8 form-control border-0">
            <input type="checkbox" formControlName="ftd" class="custom-control-input" id="ftd" (change)="onFTDTargetAmount('ftd')">
            <label class="custom-control-label" for="ftd"></label>
          </div>
        </div> -->
        <!-- <div class="col-12 form-group row" *ngIf="!form.value.ftd">
          <label class="col-4 col-form-label">Target Amount <span *ngIf="!memberGroupSelectedItems[0]?.trial" class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="upgrade_requirement" min="0" class="col-8 form-control" (keyup)="onFTDTargetAmount('target-amount')">
        </div> -->
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Upgrade Requirement 
            <span *ngIf="!memberGroupSelectedItems[0]?.trial" class="text-danger">*</span>&nbsp;
            <i class="fa fa-info-circle" placement="bottom" [ngbTooltip]="linkToolTip" tooltipClass="tooltip-class"></i>

            <ng-template #linkToolTip>
              <div class="text-left">
                <b>Invited</b> - Members are eligible for this upgrade if manually given by the operator.<br>
                <b>FTD</b> - Members are eligible for this upgrade if their first-time deposit meets the value indicated (0 would indicate any amount would work).<br>
                <b>Lifetime Deposit</b> - Members are eligible for this upgrade if their lifetime deposit meets the requirement.<br>
                <b>Annual Deposit</b> - Members are eligible for this upgrade if the sum of their past 365 days of deposits meets the requirement.
              </div>
            </ng-template>
          </label>
          <select class="col-4 form-control" formControlName="upgrade_requirement_type_id" (change)="onChangeUpgradeRequirementType($event.target.value)">
            <option [value]="null" [disabled]="true">Please Select</option>
            <ng-container *ngFor="let value of dropdown.upgradeRequirementType">
              <option [value]="value.id">{{ value.name }}</option>
            </ng-container>
          </select>
          <ng-container *ngIf="form.value.upgrade_requirement_type_id > 1"> 
            <input type="text" [ngClass]="{'is-invalid': form.value.upgrade_requirement < 1 }" (wheel)="false" formControlName="upgrade_requirement" class="col-4 form-control special-width" (keyup)="onFTDTargetAmount('target-amount')">
          </ng-container>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label d-block">Upgrade Bonus
            <ng-container *ngIf="!checkValidity(upgradeBonusSelectedItems, 1)">
              <i class="fa fa-exclamation-circle validity-icon" placement="bottom" [ngbTooltip]="linkToolTip3" tooltipClass="tooltip-class-1"></i>
              <ng-template #linkToolTip3>
                <div class="text-left">
                  Invalid Action. The selected option is invalid/expired.
                </div>
              </ng-template>
            </ng-container>
          </label>
          <div class="col-5" style="padding:0" [ngClass]="!checkValidity(upgradeBonusSelectedItems, 1) ? 'vip-validity' : ''">
            <kt-dropdown-wo-lazyload
              [form] = 'form'
              [dropdownList] = 'promotionCodeDropdownList'
              [dropdownSettings] = 'promotionCodeDropdownSettings'
              [formName] = "'upgrade_promotion_id'"
              [selectionAttributes] = "'id'"
              [selectedItems] = 'upgradeBonusSelectedItems'
              (selectedItemsChanged)="upgradeBonusSelectedItems = $event">
            </kt-dropdown-wo-lazyload>
          </div>
          <a *ngIf="canViewPromotionCodeList" class="btn btn-brand promotion-btn promocode-button" href="/general/promotion-codes" routerLinkActive="active" target="_blank" rel="bookmark">3.2 Promotion Codes</a>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label d-block">Birthday Bonus
            <ng-container *ngIf="!checkValidity(birthdayBonusSelectedItems, 1)">
              <i class="fa fa-exclamation-circle validity-icon" placement="bottom" [ngbTooltip]="linkToolTip4" tooltipClass="tooltip-class-1"></i>
              <ng-template #linkToolTip4>
                <div class="text-left">
                  Invalid Action. The selected option is invalid/expired.
                </div>
              </ng-template>
            </ng-container>
          </label>
          <div class="col-5" style="padding:0" [ngClass]="!checkValidity(birthdayBonusSelectedItems, 1) ? 'vip-validity' : ''">
            <kt-dropdown-wo-lazyload
              [form] = 'form'
              [dropdownList] = 'promotionCodeDropdownList'
              [dropdownSettings] = 'promotionCodeDropdownSettings'
              [formName] = "'birthday_promotion_id'"
              [selectionAttributes] = "'id'"
              [selectedItems] = 'birthdayBonusSelectedItems'
              (selectedItemsChanged)="birthdayBonusSelectedItems = $event">
            </kt-dropdown-wo-lazyload>
          </div>
            <a *ngIf="canViewPromotionCodeList" class="btn btn-brand promotion-btn promocode-button" href="/general/promotion-codes" routerLinkActive="active" target="_blank" rel="bookmark">3.2 Promotion Codes</a>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label d-block">Next VIP Upgrade</label>
          <kt-dropdown-wo-lazyload [form]="form" class="col-8 p-0" 
            [dropdownList]="nextMemberGroupDropdownList" 
            [selectedItems]="nextMemberGroupSelectedItems" 
            [selectionAttributes]="'id'" 
            [formName]="'next_vip_upgrade_id'" 
            [dropdownSettings]="memberGroupDropdownSettings">
          </kt-dropdown-wo-lazyload>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">SMS Template&nbsp;
            <ng-container *ngIf="!checkValidity(smsTemplateSelectedItem, 2)">
              <i class="fa fa-exclamation-circle validity-icon" placement="bottom" [ngbTooltip]="linkToolTip5" tooltipClass="tooltip-class-1"></i>
              <ng-template #linkToolTip5>
                <div class="text-left">
                  Invalid Action. The selected option is invalid.
                </div>
              </ng-template>
            </ng-container>
          </label>
          <div class="col-8" style="padding:0" [ngClass]="!checkValidity(smsTemplateSelectedItem, 2) ? 'vip-validity' : ''">
            <kt-dropdown-wo-lazyload class="col-8 p-0"
              [form] = 'form'
              [dropdownList] = 'smsTemplateList'
              [dropdownSettings] = 'messageTemplatesSettings'
              [formName] = "'sms_template_id'"
              [selectionAttributes] = "'id'"
              [selectedItems] ='smsTemplateSelectedItem'
              (selectedItemsChanged)="smsTemplateSelectedItem = $event">
            </kt-dropdown-wo-lazyload>
          </div>
        </div>
        <div class="col-12 form-group row" >
          <label class="col-4 col-form-label">Message Template&nbsp;
            <ng-container *ngIf="!checkValidity(messageTemplateSelectedItem, 2)">
              <i class="fa fa-exclamation-circle validity-icon" placement="bottom" [ngbTooltip]="linkToolTip6" tooltipClass="tooltip-class-1"></i>
              <ng-template #linkToolTip6>
                <div class="text-left">
                  Invalid Action. The selected option is invalid.
                </div>
              </ng-template>
            </ng-container>
          </label>
          <div class="col-8" style="padding:0" [ngClass]="!checkValidity(messageTemplateSelectedItem, 2) ? 'vip-validity' : ''">
            <kt-dropdown-wo-lazyload class="col-8 p-0"
              [form] = 'form'
              [dropdownList] = 'messageTemplateList'
              [dropdownSettings] = 'messageTemplatesSettings'
              [formName] = "'message_template_id'"
              [selectionAttributes] = "'id'"
              [selectedItems] ='messageTemplateSelectedItem'
              (selectedItemsChanged)="messageTemplateSelectedItem = $event">
            </kt-dropdown-wo-lazyload>
          </div>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Dialog Popup&nbsp;
            <ng-container *ngIf="!checkValidity(popupSelectedItems, 3)">
              <i class="fa fa-exclamation-circle validity-icon" placement="bottom" [ngbTooltip]="linkToolTip5" tooltipClass="tooltip-class-1"></i>
              <ng-template #linkToolTip5>
                <div class="text-left">
                  Invalid Action. The selected option is invalid.
                </div>
              </ng-template>
            </ng-container>
          </label>
          <div class="col-5" style="padding: 0" [ngClass]="!checkValidity(popupSelectedItems, 3) ? 'vip-validity' : ''">
            <kt-dropdown-wo-lazyload class="dropdown-maxheight"
              [form]= 'form' 
              [dropdownList]= 'popupDropdownListArray'
              [dropdownSettings]= 'popupDropdownSettings' 
              [formName]="'popup_id'" 
              [selectionAttributes]="'id'" 
              [selectedItems]= 'popupSelectedItems'
              (selectedItemsChanged)="popupSelectedItems = $event"> 
            </kt-dropdown-wo-lazyload>
          </div>
          <a *ngIf="canViewDialogList" class="btn btn-brand promotion-btn promocode-button" href="/settings/dialog" routerLinkActive="active" target="_blank" rel="bookmark">14.1.2 Dialog</a>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label d-block">Auto Approve&nbsp;
            <i class="fa fa-info-circle" placement="bottom" [ngbTooltip]="linkToolTip2" tooltipClass="tooltip-class-1"></i>
            <ng-template #linkToolTip2>
              <div class="text-left">
                Dictates if VIP upgrades INTO this VIP tier will be auto approved by the system IF the upgrade is made by the system.<br>
                <b>Auto approve by operator is solely dictated by operators.</b>
              </div>
            </ng-template>
          </label>
          <div class="custom-control custom-checkbox col-8 form-control border-0">
            <input type="checkbox" formControlName="auto_approve" class="custom-control-input" id="auto_approve">
            <label class="custom-control-label" for="auto_approve"></label>
          </div>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Status <span class="text-danger">*</span></label>
          <select class="col-8 form-control" formControlName="status">
            <option [value]="null" [disabled]="true">Please Select</option>
            <ng-container *ngFor="let item of dropdown.statuses | keyvalue">
              <ng-container *ngIf="item.value <= 1">
                <option [value]="item.value" *ngIf="!(+item.key >= 0)">{{ item.key }}</option>
              </ng-container>
            </ng-container>
          </select>
        </div>

        <div class="col-12 kt-mb-10">
          <strong>CMS</strong>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Days <span class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="days" min="0" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Service Priority <span class="text-danger">*</span></label>
          <input type="text" formControlName="service_priority" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Upgrade Rebates <span class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="reward_bonus" min="0" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Birthday Rebates <span class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="birth_rebate" min="0" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Live Rebates <span class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="lc_rebate" min="0" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Slots Rebates <span class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="sl_rebate" min="0" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Sports Rebates <span class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="sp_rebate" min="0" class="col-8 form-control">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid || !checkAllValidity()" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event, true)"></kt-swal-alert>
