import { Subscription, of } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { Pagination } from '@core/models/pagination.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { MatDialog } from "@angular/material/dialog";
import * as moment from 'moment-timezone';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { CampaignAdCostReportDataService } from '../services/campaign-ad-cost-report-data.service';

@Component({
  selector: 'kt-campaign-clicks',
  templateUrl: './campaign-clicks.component.html',
  styleUrls: ['./campaign-clicks.component.scss'],
})
export class CampaignClicksDialogComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  form: FormGroup;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;

  loading = false;
  campaignClicks$: any;

  params = "";
  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };
  ranges: any;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;
  button_loading = false;

  default_sort_by = 'created_at';
  default_sort_order = 'desc';

  sortingConfig = {
    'unique_id': 'desc',
    'ip_address': 'desc',
    'user_agent': 'desc',
    'locale_code': 'desc',
    'created_at': 'desc'
  };

  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order,
  };
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, campaignID: number, form: any, campaignName: string },
    private campaignAdCostReportDataService: CampaignAdCostReportDataService,
    public dialogRef: MatDialogRef<CampaignClicksDialogComponent>,
    public dialog: MatDialog,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private loadingBar: LoadingBarService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.pagination = this.campaignAdCostReportDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.onSubmit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.campaignClicks$ = this.campaignAdCostReportDataService.getCampaignClicks(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.dataLength = res.length;
        this.pagination = this.campaignAdCostReportDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClearDate() {
    if (this.form.value.start_date !== null){
      this.form.patchValue({defaultDate: null});
    }
  }

  onDateRange(event: any) {
    if (event){
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      unique_id: null,
      ip_address: null,
      start_date_time: null,
      end_date_time: null,
      defaultDate: null
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        data['campaign_id'] = this.data.campaignID;

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.campaignClicks$ = this.campaignAdCostReportDataService.getCampaignClicks(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.campaignAdCostReportDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private formInit() {
    this.form = new FormGroup({
      unique_id: new FormControl(null),
      ip_address: new FormControl(null),
      start_date_time: new FormControl(this.data.form.start_date_time ? this.data.form.start_date_time : null),
      end_date_time: new FormControl(this.data.form.end_date_time ? this.data.form.end_date_time : null),
      defaultDate: new FormControl(this.data.form.defaultDate !== null ? this.data.form.defaultDate : null), // Do not remove: For Clearing The Range
    });

    this.data.form = null;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all' && key !== 'defaultDate' ) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }
}
