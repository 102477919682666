import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Pagination } from '@core/models/pagination.model';
import { AllMemberReportSummary } from '@core/models/all-member-report-summary.model';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { TransactionReport } from '@core/models/transaction-report.model';
import { MarketingReport } from '@core/models/marketing-report.model';
import { MarketingReportDetails } from '@core/models/marketing-report-details.model';

@Injectable()
export class MarketingReportDataService extends DefaultDataService<MarketingReport> {

  pagination: Pagination;
  summary: AllMemberReportSummary;
  messages$ = new Subject<any[]>();
  date = this.transactionHttpService.getToday();
  from = this.date.from;
  to = this.date.to;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private transactionHttpService: TransactionHttpService
  ) {
    super('MarketingReport', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<MarketingReport[]> {
    return this.http.get<ApiResponse>(`/report/marketingreport`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows),
    );
  }

  getWithQuery(pageParam: string): Observable<MarketingReport[]>{
    return this.http.get<ApiResponse>(`/report/marketingreport?paginate=1${pageParam}`).pipe(
      tap(res => {
        this.paginationInit(res);
        this.getSummary(res);
      }),
      map(res => res.data.rows)
    );
  }

  getTransaction(pageParam: string): Observable<TransactionReport[]> {
    return this.http.get<ApiResponse>(`/report/transactionreport${pageParam}`).pipe(
      map(res => res.data.rows)
    );
  }

  getDetails(pageParam: string): Observable<MarketingReportDetails>{
    return this.http.get<ApiResponse>(`/report/marketingreport?paginate=1${pageParam}`).pipe(
      tap(res => {
        this.paginationInit(res);
        this.getSummary(res);
      }),
      map(res => res.data.rows[0])
    );
  }

  private paginationInit(res: any) {
    if (res) {
      return this.pagination = res.data.paginations;
    }
  }

  private getSummary(res: any) {
    if(res) {
      return this.summary = res.data.summary;
    }
  }

  exportReport(pageParam: string) {
    return this.http.get<ApiResponse>(`/report/exportmarketingreport${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }
}
