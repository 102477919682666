import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Observable, Subscription, of } from 'rxjs';
import { tap, map, exhaustMap, shareReplay } from 'rxjs/operators';
import { ApiKeySettingDataService } from './services/api-key-setting-data.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Pagination } from '@core/models/pagination.model';
import { ApiKeySettingEditComponent } from './dialog/api-key-setting-edit.component';
import { ApiKeySetting } from '@core/models/api-key-setting.model';
import { MatDialog } from '@angular/material/dialog';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-api-key-setting',
  templateUrl: './api-key-setting.component.html',
  styleUrls: ['./api-key-setting.component.scss']
})
export class ApiKeySettingComponent implements OnInit, OnDestroy {

  form: FormGroup;

  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };
  dataLength: number;

  pagination: Pagination;
  pageSize = 30;
  page = 1;

  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  params = '';
  apiKeySetting$: Observable<any[]>

  private subscriptions = new Subscription();

  // permissions
  canCreateApiKey: boolean;
  canEditApiKey: boolean;

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private loadingBar: LoadingBarService,
    private apiKeySettingDataService: ApiKeySettingDataService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit(): void {
    this.formInit();
    this.onSubmit(true);

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateApiKey = appPermissions.create_api_key;
      this.canEditApiKey = appPermissions.edit_api_key;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).length > 0 ? '&' + Object.keys(data).map(key => key + '=' + data[key]).join('&') : '';
        this.loadingBar.start();

        return this.apiKeySetting$ = this.apiKeySettingDataService.getWithQuery(`/?paginate=1&perPage=${this.pageSize}${this.params}`).pipe(
          shareReplay(),
          tap(res => {
            this.page = 1;
            this.pagination = this.apiKeySettingDataService.pagination;
            this.loadingBar.complete();
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
          })
        );
      }),
    ).subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      application: "",
      status: "all",
    });
    this.onSubmit(true);
  }

  onOpenDialog(mode: string, id?: number) {
    if (mode === 'create') {
      this.openDialogBy(ApiKeySettingEditComponent, { mode: mode });
    }
    else {
      this.apiKeySettingDataService.getById(id).subscribe(res => {
        this.openDialogBy(ApiKeySettingEditComponent, { mode: mode, apiKeySetting: res });
      });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    var params = this.params + `&page=${page}`;
    this.loadingBar.start();
    return this.apiKeySetting$ = this.apiKeySettingDataService.getWithQuery(`?paginate=1&perPage=${this.pageSize}${params}`).pipe(
      tap((res) => {
        this.pagination = this.apiKeySettingDataService.pagination;
        this.dataLength = res.length;
        this.loading = false;
        this.loadingBar.complete();
      })
    );
  }

  private openDialogBy(componentRef: any, data: { mode: string, apiKeySetting?: ApiKeySetting }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      height: 'auto',
      data
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page);
      }
    });
  }

  private formInit() {
    this.form = new FormGroup({
      application: new FormControl(""),
      status: new FormControl("all"),
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

}
