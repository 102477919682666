import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MerchantBankDataService } from '../../services/merchant-bank-data.service';

@Component({
  selector: 'kt-status-remarks',
  templateUrl: './status-remarks.component.html',
  styleUrls: ['./status-remarks.component.scss']
})
export class StatusRemarksComponent implements OnInit, OnDestroy {

  form: FormGroup;
  refreshStatus: boolean;
  buttonLoading = false;

  messages$ = this.merchantBankDataService.messages$;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { merchantBank: any },
    public dialogRef: MatDialogRef<StatusRemarksComponent>,
    private merchantBankDataService: MerchantBankDataService
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave() {
    const data = {
      ...this.form.value
    };
    this.buttonLoading = true;
    this.subscription = this.merchantBankDataService.toggleStatus(data.id, data.status, data.remarks, data.merchant_bank_transaction_configs_id).subscribe(() => {
      this.messages$ = this.merchantBankDataService.messages$;
    });
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private formInit(){
    this.form = new FormGroup({
      id: new FormControl(this.data.merchantBank.merchant_bank_id ? this.data.merchantBank.merchant_bank_id : this.data.merchantBank.id),
      merchant_bank_transaction_configs_id: new FormControl(this.data.merchantBank.merchant_bank_id ? this.data.merchantBank.id : 0),
      status: new FormControl(this.data.merchantBank.status),
      remarks: new FormControl(null, [Validators.required])
    });
  }

}
