<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="row align-items-center">
                <div class="col-xl-12 order-2 order-xl-1">
                    <mat-tab-group animationDuration="0ms">
                        <ng-container>
                            <mat-tab *ngIf="canViewFooterSectionList" label="Footer Section">
                                <kt-footer-section></kt-footer-section>
                            </mat-tab>
                            <mat-tab *ngIf="canViewIconLibraryList" label="Icon Library">
                                <kt-icon-library></kt-icon-library>
                            </mat-tab>
                            <mat-tab *ngIf="canViewQuickLinkList" label="Quick Link">
                                <kt-quick-link></kt-quick-link>
                            </mat-tab>
                        </ng-container>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>