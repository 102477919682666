
<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form" *ngIf="data.mode === 'edit'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> Edit Deposit</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="p-0">
          <div class="row">
            <section [class.col-lg-6]="data.deposit.is_crypto !== 1" [class.col-lg-12]="data.deposit.is_crypto === 1" class="card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Member Info
                </div>
                <div class="col-12 border-top-0 p-4">
                  <section class="row border-top border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Member ID</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.deposit.member_id }}
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Username</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.deposit.username }}
                    </div>
                  </section>
                  <section class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Name</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.deposit.name }}
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Group</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.deposit.member_group }}
                    </div>
                  </section>
                  <section class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                      <span class="kt-font-bold">Remarks</span>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 border-right border-bottom p-3 d-flex justify-content-between">
                      <span>{{ data.deposit.member_remarks ? data.deposit.member_remarks : "-" }}</span>
                      <i (click)="onViewMoreRemarks(data.deposit)" *ngIf="data.deposit.remarks_count > 1" class="fas fa-stream d-flex align-items-center pointer" matTooltip="More"></i>
                    </div>
                  </section>
                </div>
              </div>
            </section>
            <section class="col-lg-6 card border-0" *ngIf="data.deposit.is_crypto !== 1">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Bank Info
                </div>
                <div class="col-12 p-4">
                  <section class="row border-top border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                      <span class="kt-font-bold">Bank Name</span>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.deposit.payment_method_id === 4 || data.deposit.payment_method_id === 21 ? merchanBank.bank_name : '-' }}
                    </div>
                  </section>
                  <section class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Account Number</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 d-flex align-items-center border-right border-bottom p-3">
                      {{ data.deposit.payment_method_id === 4 || data.deposit.payment_method_id === 21 ? merchanBank.account_number : '-' }}
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Account Name</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 d-flex align-items-center border-right border-bottom p-3">
                      {{ data.deposit.payment_method_id === 4 || data.deposit.payment_method_id === 21 ? merchanBank.account_name : '-' }}
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Select Merchant Bank</span>
                    </div>
                    <div class="col-lg-9 col-md-3 col-sm-6 col-xs-12 d-flex align-items-center border-right border-bottom p-3">
                      <kt-dropdown-wo-lazyload *ngIf="data.deposit.payment_method_id === 4 || data.deposit.payment_method_id === 21"
                      [form] = 'form'
                      [dropdownList] = 'bankaccountDropdownList'
                      [dropdownSettings] = 'bankaccountDropdownSettings'
                      [formName] = "'merchant_bank_details'"
                      [selectionAttributes] = "'value'"
                      [selectedItems] = 'bankaccountSelectedItems'
                      class="w-100">
                    </kt-dropdown-wo-lazyload>
                    <span *ngIf="data.deposit.payment_method_id !== 4 || data.deposit.payment_method_id !== 21">-</span>
                    <label *ngIf="!isValidMerchantBank" class="col-12 col-form-label">
                      <mat-error>
                        <strong>Merchant Bank required to approve</strong>
                      </mat-error>
                    </label>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
          <div class="row mt-2">
            <section class="col-12 card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Transaction Info
                </div>
                <section class="col-12 p-4" *ngIf="data.deposit.is_crypto === 1">
                  <div class="row border-top border-left">
                    <!-- ID -->
                    <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">ID</span>
                    </div>
                    <div class="col-lg-4 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.deposit.id }}
                    </div>
                    <!-- Created At -->
                    <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Created At</span>
                    </div>
                    <div class="col-lg-4 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ timezoneDateWithTimezone(data.deposit.created_at, timezone, 'YYYY-MM-DD HH:mm') }}
                    </div>
                  </div>
                  <div class="row border-left">
                    <!-- Status -->
                    <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Status</span>
                    </div>
                    <div class="col-lg-4 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + (depositStatus == 7 ? 5 : depositStatus)"> {{ status[depositStatus] }}</span>
                    </div>
                    <!-- Payment Method -->
                    <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Payment Method</span>
                    </div>
                    <div class="col-lg-4 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.deposit.payment_method }}
                    </div>
                  </div>
                  <div class="row border-left">
                    <!-- Token -->
                    <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Token</span>
                    </div>
                    <div class="col-lg-4 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.deposit.cr_token }}
                    </div>
                    <!-- Wallet Address -->
                    <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Wallet Address</span>
                    </div>
                    <div class="col-lg-4 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.deposit.cr_wallet_address }}
                    </div>
                  </div>
                  <div class="row border-left">
                    <!-- Processing Fee -->
                    <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Processing Fee <span class="text-danger">*</span></span>
                    </div>
                    <div class="col-lg-4 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      <div class="row mb-4">
                        <div class="col-12">
                          <span>Total :<i
                            class="fa fa-info-circle promotion-code-detail-tooltip" placement="top"
                            matTooltip="{{ 'Total Amount = Company Amount + Player Amount' }}"></i></span>
                        </div>
                        <div class="input-wrap">
                          <div class="col-6">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><label>{{ data.deposit.currency }}</label></span>
                              </div>
                              <input type="number" (wheel)="false" formControlName="total_processing_fee" class="col-xs-12 form-control" #focusfield [ngClass]="{'is-invalid': checkValidation && form.controls.processing_fee.errors }">
                            </div>
                            <small class="text-muted form-text red-hint" *ngIf="totalProcessingFeeErrorMessage !== ''">
                              <i class="fa fa-exclamation-circle"></i> {{ totalProcessingFeeErrorMessage }}
                            </small>
                          </div>
                          <div class="col-6">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><label>{{ data.deposit.cr_token }}</label></span>
                              </div>
                              <input type="number" (wheel)="false" formControlName="cr_total_processing_fee" class="col-xs-12 form-control" #focusfield [ngClass]="{'is-invalid': checkValidation && form.controls.processing_fee.errors }">
                            </div>
                            <small class="text-muted form-text red-hint" *ngIf="cr_totalProcessingFeeErrorMessage !== ''">
                              <i class="fa fa-exclamation-circle"></i> {{ cr_totalProcessingFeeErrorMessage }}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <div class="col-12">
                          <span>Company :</span>
                        </div>
                        <div class="input-wrap">
                          <div class="col-6">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><label>{{ data.deposit.currency }}</label></span>
                              </div>
                              <input type="number" (wheel)="false" formControlName="processing_fee" class="col-xs-12 form-control" #focusfield [ngClass]="{'is-invalid': checkValidation && form.controls.processing_fee.errors }">
                            </div>
                            <small class="text-muted form-text red-hint" *ngIf="processingFeeErrorMessage !== ''">
                              <i class="fa fa-exclamation-circle"></i> {{ processingFeeErrorMessage }}
                            </small>
                          </div>
                          <div class="col-6">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><label>{{ data.deposit.cr_token }}</label></span>
                              </div>
                              <input type="number" (wheel)="false" formControlName="cr_processing_fee" class="col-xs-12 form-control" #focusfield [ngClass]="{'is-invalid': checkValidation && form.controls.processing_fee.errors }">
                            </div>
                            <small class="text-muted form-text red-hint" *ngIf="cr_processingFeeErrorMessage !== ''">
                              <i class="fa fa-exclamation-circle"></i> {{ cr_processingFeeErrorMessage }}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <span>Player :</span>
                        </div>
                        <div class="input-wrap">
                          <div class="col-6">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><label>{{ data.deposit.currency }}</label></span>
                              </div>
                              <input type="number" (wheel)="false" formControlName="member_processing_fee" class="col-xs-12 form-control" #focusfield [ngClass]="{'is-invalid': checkValidation && form.controls.processing_fee.errors }">
                            </div>
                            <small class="text-muted form-text red-hint" *ngIf="processingFeeErrorMessage !== ''">
                              <i class="fa fa-exclamation-circle"></i> {{ processingFeeErrorMessage }}
                            </small>
                          </div>
                          <div class="col-6">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><label>{{ data.deposit.cr_token }}</label></span>
                              </div>
                              <input type="number" (wheel)="false" formControlName="cr_member_processing_fee" class="col-xs-12 form-control" #focusfield [ngClass]="{'is-invalid': checkValidation && form.controls.processing_fee.errors }">
                            </div>
                            <small class="text-muted form-text red-hint" *ngIf="cr_processingFeeErrorMessage !== ''">
                              <i class="fa fa-exclamation-circle"></i> {{ cr_processingFeeErrorMessage }}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Confirmed Amount -->
                    <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Confirmed Amount</span>
                    </div>
                    <div class="col-lg-4 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      <div class="mb-4">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><label>{{ data.deposit.currency }}</label></span>
                          </div>
                          <input type="number" (wheel)="false" formControlName="confirmed_amount" class="col-xs-12 form-control">
                        </div>
                      </div>
                      <div class="mb-1">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><label>{{ data.deposit.cr_token }}</label></span>
                          </div>
                          <input type="number" (wheel)="false" formControlName="cr_confirmed_amount" class="col-xs-12 form-control">
                        </div>
                      </div>
                      <div>
                        <i class="fa fas fa-info-circle exchange-rate-icon"></i> {{ processingFeeErrorMessage }} Exchange Rate: {{ data.deposit.cr_token }} 1 = {{ data.deposit.currency }} {{ data.deposit.cr_exchange_rate }}
                      </div>
                    </div>
                  </div>
                  <div class="row border-left">
                    <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Handler</span>
                    </div>
                    <div class="col-lg-4 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.newHandler ? data.newHandler : data.deposit.handler }}
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Processing Time</span>
                    </div>
                    <div class="col-lg-4 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ timezoneDateWithTimezone(data.deposit.updated_at, timezone, 'YYYY-MM-DD HH:mm') }}
                    </div>
                  </div>
                  <div class="row border-left">
                    <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Latest Remarks</span>
                    </div>
                    <div class="input-group col-lg-10 col-md-9 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      <ng-container *ngIf="data.deposit.status === 1; else span">
                        <input type="text" formControlName="remarks" class="form-control" [ngClass]="{ 'is-invalid': form.controls.remarks.errors }">
                        <span class="input-group-append" *ngIf="data.deposit.status === 1 && canEditDeposits">
                          <button type="button" class="btn btn-success" (click)="onUpdateRemarks()">Update</button>
                        </span>
                      </ng-container>
                      <ng-template #span>
                        {{ data.deposit.remarks }}
                      </ng-template>
                    </div>
                  </div>
                  <div class="form-control border-0 col-9 border p-1 ml-n3 mt-2">
                    <button *ngIf="canViewReceipts" (click)="openReceipt()" [disabled]="depositReceipts.length <= 0" type="button" class="btn btn-primary mr-2">View Receipt</button>
                    <ng-container *ngIf="depo.status !== 2 && depo.status !==1;">
                      <button
                        *ngIf="canRequestReceipts && ((data.deposit.bank_type === 1 || data.deposit.bank_type === 3) && (data.deposit.status === 0 || data.deposit.status === 3 || data.deposit.status === 5))"
                        (click)="requestReceipt()"
                        type="button"
                        class="btn btn-warning mr-2"
                      >
                        Request Receipt
                      </button>
                      <button *ngIf="canUploadReceipts && (data.deposit.can_upload_receipt === true)" (click)="openUploadReceipt()" type="button" class="btn btn-dark mr-2">Upload Receipt</button>
                      <ng-container *ngIf="isBP77 || (!isBP77 && systemSettingOption == 1); else nullTemplate">
                        <button *ngIf="canBrowseReceipts && !data.deposit.bank_receipt_id" type="button" [disabled]="onDisableBrowse(data.deposit.payment_method) || !isSelectedMerchantBank" class="btn btn-secondary btn-gray" (click)="onShowBankReceiptBrowser()">Browse Receipts</button>
                      </ng-container>
                      <ng-template #nullTemplate></ng-template>
                    </ng-container>
                  </div>
                </section>
                <section class="col-12 p-4" *ngIf="data.deposit.is_crypto !== 1">
                  <div class="row border-top border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">ID</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.deposit.id }}
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Created At</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ timezoneDateWithTimezone(data.deposit.created_at, timezone, 'YYYY-MM-DD HH:mm') }}
                    </div>
                  </div>
                  <div class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Status</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + (depositStatus == 7 ? 5 : depositStatus)"> {{ status[depositStatus] }}</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Confirmed Amount ({{data.deposit.currency}})</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.deposit.confirmed_amount  | number : '1.2-2'}}
                    </div>
                  </div>
                  <div class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Payment Method</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.deposit.payment_method }}
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Processing Fee <span class="text-danger">*</span></span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      <div class="col-12">
                        <span>Total :<i
                          class="fa fa-info-circle promotion-code-detail-tooltip" placement="top"
                          matTooltip="{{ 'Total Amount = Company Amount + Player Amount' }}"></i></span>
                        <input type="number" (wheel)="false" formControlName="total_processing_fee" class="col-xs-12 form-control" #focusfield [ngClass]="{'is-invalid': checkValidation && form.controls.processing_fee.errors }">
                        <small class="text-muted form-text red-hint" *ngIf="totalProcessingFeeErrorMessage !== ''">
                          <i class="fa fa-exclamation-circle"></i> {{ totalProcessingFeeErrorMessage }}
                        </small>
                      </div>
                      <br>
                      <div class="processing-fee-wrap">
                        <div class="input-wrap">
                          <div class="col-6">
                            <span>Company :</span>
                            <input type="number" (wheel)="false" formControlName="processing_fee" class="col-xs-12 form-control" #focusfield [ngClass]="{'is-invalid': checkValidation && form.controls.processing_fee.errors }">
                          </div>
                          <div class="col-6">
                            <span>Player :</span>
                            <input type="number" (wheel)="false" formControlName="member_processing_fee" class="col-xs-12 form-control" #focusfield [ngClass]="{'is-invalid': checkValidation && form.controls.processing_fee.errors }">
                          </div>
                        </div>
                        <small class="text-muted form-text red-hint" *ngIf="processingFeeErrorMessage !== ''">
                          <i class="fa fa-exclamation-circle"></i> {{ processingFeeErrorMessage }}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Handler</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.newHandler ? data.newHandler : data.deposit.handler }}
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Processing Time</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ timezoneDateWithTimezone(data.deposit.updated_at, timezone, 'YYYY-MM-DD HH:mm') }}
                    </div>
                  </div>
                  <div class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">References</span>
                    </div>
                    <div class="input-group col-lg-9 col-md-9 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      <input type="text" formControlName="pg_reference_id" class="form-control">
                    </div>
                  </div>
                  <div class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Latest Remarks</span>
                    </div>
                    <div class="input-group col-lg-9 col-md-9 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      <ng-container *ngIf="data.deposit.status === 1; else span">
                        <input type="text" formControlName="remarks" class="form-control" [ngClass]="{ 'is-invalid': form.controls.remarks.errors }">
                        <span class="input-group-append" *ngIf="data.deposit.status === 1">
                          <button type="button" class="btn btn-success" (click)="onUpdateRemarks()">Update</button>
                        </span>
                      </ng-container>
                      <ng-template #span>
                        {{ data.deposit.remarks }}
                      </ng-template>
                    </div>
                  </div>
                  <div class="form-control border-0 col-9 border p-1 ml-n3 mt-2">
                    <button *ngIf="canViewReceipts" (click)="openReceipt()" [disabled]="depositReceipts.length <= 0" type="button" class="btn btn-primary mr-2">View Receipt</button>
                    <ng-container *ngIf="depo.status !== 2 && depo.status !==1;">
                      <button
                        *ngIf="canRequestReceipts && ((data.deposit.bank_type === 1 || data.deposit.bank_type === 3) && (data.deposit.status === 0 || data.deposit.status === 3 || data.deposit.status === 5))"
                        (click)="requestReceipt()"
                        type="button"
                        class="btn btn-warning mr-2"
                      >
                        Request Receipt
                      </button>
                      <button *ngIf="canUploadReceipts && (data.deposit.can_upload_receipt === true)" (click)="openUploadReceipt()" type="button" class="btn btn-dark mr-2">Upload Receipt</button>
                      <button *ngIf="canBrowseReceipts && !data.deposit.bank_receipt_id" type="button" [disabled]="onDisableBrowse(data.deposit.payment_method) || !isSelectedMerchantBank" class="btn btn-secondary btn-gray" (click)="onShowBankReceiptBrowser()">Browse Receipts</button>
                    </ng-container>
                  </div>
                </section>
              </div>
            </section>
          </div>

          <div class="row mt-2" *ngIf="transactionCallbackLogs.length > 0">
            <section class="col-lg-12 card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Payment Gateway Callback Log
                </div>
                <section class="col-12 p-4">
                  <div class="row">

                    <table class="table table-bordered table-hover table-striped table-layout-fixed">
                      <thead>
                        <tr>
                          <th class="text-center" style="width: 10%;">Callback ID</th>
                          <th class="text-center" style="width: 20%;">Transaction ID</th>
                          <th class="text-center" style="width: 10%;">Status</th>
                          <th class="text-center" style="width: 40%;">Callback Details</th>
                          <th class="text-center" style="width: 20%;">Callback Date/Time</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="transactionCallbackLogs as rows">
                          <tr *ngFor="let row of rows">
                              <td class="text-center">{{row.id}}</td>
                              <td class="text-center">{{row.reference}}</td>
                              <td class="text-center">
                                <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status">{{ statusCallback[+row.status] }}</span>
                              </td>
                              <td class="text-center">{{row.details}}</td>
                              <td class="text-center">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</td>
                          </tr>
                          <kt-fallback-row [collection]="rows"></kt-fallback-row>
                      </tbody>
                    </table>
                    <div class="kt-pagination kt-pagination--brand mt-2 col-12" *ngIf="transactionCallbackLogsPagination !== undefined">
                      <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="transactionCallbackLogsPagination.total"
                        (pageChange)="onGetTransactionCallbackLogs(page)">
                      </ngb-pagination>
                      <div class="kt-pagination__toolbar">
                          <span class="pagination__desc">
                            Showing {{transactionCallbackLogsPagination.from}} to {{transactionCallbackLogsPagination.to}} of {{ transactionCallbackLogsPagination.total | number : '1.0' }} records
                          </span>
                      </div>
                    </div>

                  </div>
                </section>
              </div>
           </section>
          </div>

          <ng-container *ngIf="selectedReceipt$ | async as rows; else selectReceiptNote">
            <div class="row mt-2" *ngIf="rows.length > 0">
              <section class="col-lg-12 card border-0">
                <table class="table table-bordered table-hover table-striped">
                  <thead>
                      <tr>
                        <th colspan="8">Selected Bank Receipt</th>
                      </tr>
                      <tr>
                        <th>Bank Account</th>
                        <th>Description</th>
                        <th class="info-column">Bank Time</th>
                        <th>Currency</th>
                        <th>Amount</th>
                        <th class="text-center">Status</th>
                        <th>Remarks</th>
                        <th class="info-column">Scanned Time</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of rows">
                      <td>{{ row.bank_account }}</td>
                      <td>{{ row.desc }}</td>
                      <td>{{ timezoneDateWithTimezone(row.bank_time, timezone, 'YYYY-MM-DD HH:mm') }}</td>
                      <td>{{ row.currency }}</td>
                      <td class="text-right">
                        {{ +row.credit === 0 ? '' : row.credit | number : '1.2-2' }}
                        {{ +row.debit === 0 ? '' : '-' + row.debit | number : '1.2-2' }}
                      </td>
                      <td class="text-center">
                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ statusReceipt[row.status] }}</span>
                      </td>
                      <td>{{ row.remarks }}</td>
                      <td>{{ timezoneDateWithTimezone(row.created_at, timezone, 'YYYY-MM-DD HH:mm') }}</td>
                    </tr>
                    <kt-fallback-row [collection]="rows"></kt-fallback-row>
                  </tbody>
                </table>
              </section>
            </div>
          </ng-container>
          <ng-template #selectReceiptNote>
            <div class="row mt-2" *ngIf="isSelectedMerchantBank && selectReceiptAgain">
              <section class="col-lg-12 card border-0">
                <table class="table table-bordered table-hover table-striped">
                  <thead>
                      <tr>
                        <th colspan="8">Selected Bank Receipt</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <label class="col-form-label">
                        <mat-error>
                          <p style="padding-left: 0.75rem">Please select a receipt again</p>
                        </mat-error>
                      </label>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
          </ng-template>

        </div>
      </div>
      <div *ngIf="canEditDeposits" class="modal-footer justify-content-between">
        <!-- Reject -->
        <button
          *ngIf="depositStatus === DepositStatusEnum.Pending || depositStatus === DepositStatusEnum.InProgress || depositStatus === DepositStatusEnum.PendingCallback || depositStatus === DepositStatusEnum.PendingReceipt"
          [type]="depositStatus === 3 ? 'button' : 'submit'"
          class="btn btn-danger"
          [disabled]="buttonLoading"
          (click)="onSave(data.deposit, 'reject')"
        >
          <i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fa fa-ban'"></i>Reject
        </button>

        <!-- Void -->
        <button
          *ngIf="depositStatus === DepositStatusEnum.Approved && ((this.isBankTransfer() && this.canVoidDepositsBankTransaction) || (!this.isBankTransfer() && this.canVoidDepositsPaymentGateway))"
          type="submit"
          class="btn btn-danger"
          [disabled]="buttonLoading"
          (click)="onVoid(data.deposit)"
        >
          <i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fa fa-ban'"></i>Void
        </button>

        <!-- Revert -->
        <button
          *ngIf="canRevertDeposits && ((depositStatus === DepositStatusEnum.Rejected && !isBankTransfer()) || depositStatus === DepositStatusEnum.Failed)"
          type="submit" 
          class="btn btn-warning"
          style="color: black;"
          [disabled]="buttonLoading"
          (click)="onSave(data.deposit, 'revert')"
        >
          <i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fa fa-undo-alt'"></i>Revert
        </button>

        <!-- LEFT  -->
        <!-- ========================================================================================================== -->
        <!-- RIGHT -->

        <!-- Mark as Resolve -->
        <button *ngIf="canResolveUnusualCallback && data.deposit.unusual_callback && (depositStatus === DepositStatusEnum.Approved || (depositStatus === DepositStatusEnum.Rejected || depositStatus === DepositStatusEnum.Failed) && !isBankTransfer())" type="submit"  class="btn btn-primary" [disabled]="buttonLoading" (click)="onSave(data.deposit, 'mark-resolve')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fa fa-check'"></i>Mark as Resolve</button>

        <!-- Approve -->
        <button *ngIf="depositStatus === DepositStatusEnum.InProgress || depositStatus === DepositStatusEnum.PendingReceipt" [type]="depositStatus === 3 ? 'submit' : 'button'" class="btn btn-success" [disabled]="buttonLoading || !form.valid || processingFeeErrorMessage !== '' || totalProcessingFeeErrorMessage !== '' || cr_processingFeeErrorMessage !== '' || cr_totalProcessingFeeErrorMessage !== ''" (click)="onSave(data.deposit, 'approve')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Approve</button>
      </div>
    </div>
  </form>

  <form class="kt-form w-webkit-fill-available" [formGroup]="form" *ngIf="data.mode === 'create'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Create Deposit</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Member <span class="text-danger">*</span></label>
          <kt-member-dropdown class="dropdown-maxheight col-8" style="padding:0" [form]="form" [dropdownSettings]='dropdownSettings'
          [formName]="'member_account_id'" [selectionAttributes]="'id'" (selectedItemsChanged)="onSelectedMemberChanged($event)" [ngClass]="{'is-invalid': checkValidation && form.controls.member_account_id.errors }"></kt-member-dropdown>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Merchant Bank Account <span class="text-danger">*</span></label>
          <kt-dropdown-wo-lazyload
          class="col-8"
          style="padding:0"
          [form] = 'form'
          [dropdownList] = 'bankaccountDropdownList'
          [dropdownSettings] = 'bankaccountDropdownSettings'
          [formName] = "'merchant_bank_id'"
          [selectionAttributes] = "'id'"
          [selectedItems] = '[]'
          [dataLoading]="bankAccountLoading"
          (selectedItemsChanged)="onSelectedMerchantBankChanged($event)"
          [ngClass]="{'is-invalid': checkValidation && form.controls.merchant_bank_id.errors }">
        </kt-dropdown-wo-lazyload>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Amount <span class="text-danger">*</span></label>
          <input type="number" (wheel)="false" formControlName="amount" class="col-8 form-control" [ngClass]="{'is-invalid': checkValidation && form.controls.amount.errors }">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Date Time <span class="text-danger">*</span></label>
          <div class="col-8 pl-0 pr-0">
            <div class="input-group date" [owlDateTimeTrigger]="dateStartRef">
              <input class="hidden" [owlDateTime]="dateStartRef">
              <input type="text" formControlName="datetime" placeholder="Search" class="form-control" [ngClass]="{'is-invalid': checkValidation && form.controls.datetime.errors }">
              <span class="input-group-append">
                  <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
              </span>
              <owl-date-time #dateStartRef></owl-date-time>
            </div>
          </div>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label"> Upload Receipt <span class="text-danger">*</span> </label>
          <label class="col-2 btn btn-default btn-sm float-left" [ngClass]="{'is-invalid': checkValidation && form.controls.receipt_path.errors }">
            <span>Choose file</span>
            <input type="file" hidden accept="image/*" (change)="onUploadFile($event)">
          </label>
        </div>
        <div class="col-12 form-group row" *ngIf="data.deposit || imagePreview.length > 0">
          <label class="col-4 col-form-label">Preview Image </label>
          <div class="col-8 image-wrapper">
              <img class="img-fluid" alt="Image Banner" [src]="imagePreview[0] || data.deposit.receipt_path" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [buttonLoading]="buttonLoading" (confirmed)="onAdd()" #focusfield></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
