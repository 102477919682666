<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode | titlecase }} VIP</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="data.mode === 'reject'; else elseTemplate">
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Remarks <span class="text-danger">*</span></label>
            <input type="text" formControlName="remarks" class="col-8 form-control">
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Member <span class="text-danger" *ngIf="data.mode === 'create'">*</span></label>
            <label class="col-8 col-form-label p-l-0" *ngIf="data.mode !== 'create'">{{ data.mode === 'edit' ? data.editVip.username : data.vip.username }}</label>
            <kt-member-dropdown *ngIf="data.mode === 'create'" class="dropdown-maxheight col-8" style="padding:0" [form]="form" [dropdownSettings]='dropdownSettings'
            [formName]="'member_account_id'" [selectionAttributes]="'id'" (selectedItemsChanged)="onSelectedMemberChanged($event)"></kt-member-dropdown>
          </div>
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Current Level <span class="text-danger">*</span></label>
            <select formControlName="member_group_id" class="col-8 form-control">
              <option [value]="null" [disabled]="true">Please Select</option>
              <option [value]="value.id" *ngFor="let value of dropdown.groups">
                  {{ value.name }}
              </option>
            </select>
          </div>

          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">New Level <span class="text-danger">*</span></label>
            <select formControlName="reason" class="form-control col-form-label mr-3" style="width: 20%" (change)="changeReasonType($event.target.value)" [attr.disabled]="form.value.member_account_id == null ? '' : null">
              <option [value]="null" [disabled]="true">Please Select</option>
              <option [value]="item.id" *ngFor="let item of dropdown.reasonTypes">{{ item.name }}</option>
            </select>
            <!-- <label class="col-form-label p-l-0" style="width: 44.88%;" *ngIf="data.mode === 'approve'">{{ data.vip.next_member_group }}</label> -->
            <select formControlName="next_member_group_id" class="form-control" style="width: 44.87%;" (change)="onSelectNextLevel($event.target.value)" [attr.disabled]="form.value.reason == null ? '' : null">
              <option [value]="null" [disabled]="true">Please Select</option>
              <option [value]="value.id" *ngFor="let value of filteredMemberGroups">
                  {{ value.name }}
              </option>
            </select>
          </div>

          <div class="col-12 form-group row" *ngIf="data.mode !== 'edit' && form.value.reason != 2">
            <label class="col-4 col-form-label">VIP Upgrade Bonus&nbsp;
              <ng-container *ngIf="!checkValidity(upgradeBonusSelectedItems, 1)">
                <i class="fa fa-exclamation-circle validity-icon" placement="bottom" [ngbTooltip]="linkToolTip3" tooltipClass="tooltip-class"></i>
                <ng-template #linkToolTip3>
                  <div class="text-left">
                    Invalid Action. The selected option is invalid/expired.
                  </div>
                </ng-template>
              </ng-container>
            </label>
            <ng-container *ngIf="form.value.next_member_group_id != null && filteredMemberGroups.length > 0">
              <div class="col-8" style="padding:0" [ngClass]="!checkValidity(upgradeBonusSelectedItems, 1) ? 'vip-validity' : ''">
                <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0" *ngIf="data.mode !== 'edit' && promotionCodeDropdownList.length > 0"
                  [form] = 'form'
                  [dropdownList] = 'promotionCodeDropdownList'
                  [dropdownSettings] = 'promotionCodeDropdownSettings'
                  [formName] = "'upgrade_promotion_id'"
                  [selectionAttributes] = "'id'"
                  [selectedItems] = 'upgradeBonusSelectedItems'
                  (selectedItemsChanged)="upgradeBonusSelectedItems = $event">
                </kt-dropdown-wo-lazyload>
              </div>
            </ng-container>
          </div>

          <div class="col-12 form-group row" *ngIf="data.mode !== 'edit' && form.value.reason != 2">
            <label class="col-4 col-form-label">VIP Birthday Bonus&nbsp;
              <ng-container *ngIf="!checkValidity(birthdayPromotionSelectedItems, 1)">
                <i class="fa fa-exclamation-circle validity-icon" placement="bottom" [ngbTooltip]="linkToolTip3" tooltipClass="tooltip-class"></i>
                <ng-template #linkToolTip3>
                  <div class="text-left">
                    Invalid Action. The selected option is invalid/expired.
                  </div>
                </ng-template>
              </ng-container>
            </label>
            <ng-container *ngIf="form.value.next_member_group_id != null && filteredMemberGroups.length > 0">
              <div class="col-8" style="padding:0" [ngClass]="!checkValidity(birthdayPromotionSelectedItems, 1) ? 'vip-validity' : ''">
                <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0" *ngIf="data.mode !== 'edit' && promotionCodeDropdownList.length > 0"
                  [form] = 'form'
                  [dropdownList] = 'promotionCodeDropdownList'
                  [dropdownSettings] = 'birthdayCodeDropdownSettings'
                  [formName] = "'birthday_promotion_id'"
                  [selectionAttributes] = "'id'"
                  [selectedItems] = 'birthdayPromotionSelectedItems'
                  (selectedItemsChanged)="birthdayPromotionSelectedItems = $event">
                </kt-dropdown-wo-lazyload>
              </div>
            </ng-container>
          </div>

          <div class="col-12 form-group row" *ngIf="form.value.reason != 2">
            <label class="col-4 col-form-label">VIP Birthday Bonus Eligibility</label>
            <ng-container *ngIf="currentVipSetting != null && birthdayPromotionId != 0 && birthdayPromotionId != null; else nullBirthdayBonus">
              <label class="col-8 col-form-label p-l-0" [ngClass]="isMemberDOBPass ? 'green' : 'red'">
                {{ memberDOB != null && memberDOB != '0000-00-00' ? memberDOB : '' }} 
                {{ memberDOB != null && memberDOB != '0000-00-00' ? ' - ': 'Null - ' }} 
                {{ isMemberDOBPass ? 'Applicable' : 'Not Applicable' }}
                {{ birthdayPromotionSelectedItems != undefined && birthdayPromotionSelectedItems.length > 0 && memberDOB != null && memberDOB != '0000-00-00' ? isMemberDOBPassReason : 
                   memberDOB == null || memberDOB == '0000-00-00' ? ' - Missing Birthday Information' : ''}}

              </label>
            </ng-container>
            <ng-template #nullBirthdayBonus>
              <label class="col-8 col-form-label p-l-0 red">
                <ng-container *ngIf="currentVipSetting != null && form.value.next_member_group_id != null && form.value.next_member_group_id != 0">
                  {{ memberDOB != null && memberDOB != '0000-00-00' ? memberDOB : '' }} {{ memberDOB != null && memberDOB != '0000-00-00' ? ' - ': 'Null - ' }} 
                  {{ 'Not Applicable - No Birthday Bonus Available' }} 
                </ng-container>
              </label>
            </ng-template>

            <!-- <div class="col-8 input-group date" [owlDateTimeTrigger]="dateStartRef" *ngIf="memberDOB === null && data.mode !== 'create'">
              <input class="hidden" [owlDateTime]="dateStartRef">
              <input type="text" formControlName="date_of_birth" class="form-control">
              <span class="input-group-append">
                  <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
              </span>
              <owl-date-time #dateStartRef></owl-date-time>
            </div> -->
          </div>

          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Send SMS&nbsp;
              <ng-container *ngIf="!checkValidity(smsTemplateSelectedItem, 2)">
                <i class="fa fa-exclamation-circle validity-icon" placement="bottom" [ngbTooltip]="linkToolTip6" tooltipClass="tooltip-class"></i>
                <ng-template #linkToolTip6>
                  <div class="text-left">
                    Invalid Action. The selected option is invalid.
                  </div>
                </ng-template>
              </ng-container>
            </label>
            <div class="col-8" style="padding:0" [ngClass]="!checkValidity(smsTemplateSelectedItem, 2) ? 'vip-validity' : ''">
              <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0" *ngIf="data.mode !== 'edit'"
                [form] = 'form'
                [dropdownList] = 'smsTemplateList'
                [dropdownSettings] = 'messageTemplatesSettings'
                [formName] = "'message_template_sms_id'"
                [selectionAttributes] = "'id'"
                [selectedItems] ='smsTemplateSelectedItem'
                (selectedItemsChanged)="smsTemplateSelectedItem = $event">
              </kt-dropdown-wo-lazyload>
            </div>
          </div>

          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Send Inbox Message&nbsp;
              <ng-container *ngIf="!checkValidity(messageTemplateSelectedItem, 2)">
                <i class="fa fa-exclamation-circle validity-icon" placement="bottom" [ngbTooltip]="linkToolTip6" tooltipClass="tooltip-class"></i>
                <ng-template #linkToolTip6>
                  <div class="text-left">
                    Invalid Action. The selected option is invalid.
                  </div>
                </ng-template>
              </ng-container>
            </label>
            <div class="col-8" style="padding:0" [ngClass]="!checkValidity(messageTemplateSelectedItem, 2) ? 'vip-validity' : ''">
              <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0" *ngIf="data.mode !== 'edit'"
                [form] = 'form'
                [dropdownList] = 'messageTemplateList'
                [dropdownSettings] = 'messageTemplatesSettings'
                [formName] = "'message_template_id'"
                [selectionAttributes] = "'id'"
                [selectedItems] ='messageTemplateSelectedItem'
                (selectedItemsChanged)="messageTemplateSelectedItem = $event">
              </kt-dropdown-wo-lazyload>
            </div>
          </div>

          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Dialog Popup&nbsp;
              <ng-container *ngIf="!checkValidity(popupSelectedItems, 3)">
                <i class="fa fa-exclamation-circle validity-icon" placement="bottom" [ngbTooltip]="linkToolTip5" tooltipClass="tooltip-class-1"></i>
                <ng-template #linkToolTip5>
                  <div class="text-left">
                    Invalid Action. The selected option is invalid.
                  </div>
                </ng-template>
              </ng-container>
            </label>
            <div class="col-8" style="padding: 0" [ngClass]="!checkValidity(popupSelectedItems, 3) ? 'vip-validity' : ''">
              <kt-dropdown-wo-lazyload class="dropdown-maxheight"
                [form]= 'form' 
                [dropdownList]= 'popupDropdownListArray'
                [dropdownSettings]= 'popupDropdownSettings' 
                [formName]="'popup_id'" 
                [selectionAttributes]="'id'" 
                [selectedItems]= 'popupSelectedItems'
                (selectedItemsChanged)="popupSelectedItems = $event"> 
              </kt-dropdown-wo-lazyload>
            </div>
          </div>

          <ng-container *ngIf="data.mode !== 'reject'">
            <div class="col-12 form-group row">
              <label class="col-4 col-form-label">Status </label>
              <select class="col-8 form-control" formControlName="status" [attr.disabled]="(!isAdminUser && !permission?.approve_vip_upgrade) || form.value.next_member_group_id == null || data.mode == 'approve' ? '' : null">
                <option [value]="null" [disabled]="true">Please Select</option>
                <option [value]="0">{{ 'Pending' }}</option>
                <option [value]="1">{{ 'Approved' }}</option>
              </select>
            </div>
          </ng-container>

          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Remark </label>
            <input type="text" formControlName="remarks" class="col-8 form-control">
          </div>
        </ng-template>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <ng-container *ngIf="data.mode == 'create' && (form.value.status == 0 || form.value.status == null)">
          <kt-submit-button [isDisabled]="!form.valid || !checkAllValidity()" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.mode)"></kt-submit-button>
        </ng-container>
        <ng-container *ngIf="(data.mode == 'create' && form.value.status == 1) || data.mode == 'approve'">
          <button [type]="'button'" class="btn btn-success" [disabled]="!form.valid || !checkAllValidity()" (click)="onSave(data.mode)"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-check'"></i>Approve</button>
        </ng-container>
        <ng-container *ngIf="data.mode == 'reject'">
          <button [type]="'button'" class="btn btn-danger" [disabled]="!form.valid" (click)="onSave(data.mode)"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-times'"></i>Reject</button>
        </ng-container>
        </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
