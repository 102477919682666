import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DashboardHttpService } from '@core/services/dashboard-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Subscription } from 'rxjs';

import moment from 'moment';
import { CurrencyHttpService } from '@core/services/currency-http.service';

@Component({
  templateUrl: './statistics-report.component.html',
  styleUrls: ['./statistics-report.component.scss'],
})
export class StatisticsReportComponent implements OnInit, OnDestroy {
  form: FormGroup;
  from = this.transactionHttpService.getYesterday().from;
  to = this.transactionHttpService.getToday().to;
  currencyCode = 1; // Default

  selectedCurrency: number;

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
  };

  ranges: any;
  private datePickerSubscription = new Subscription();
  private subscription = new Subscription();

  statisticsReport: any;

  constructor(
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private dashboardHttpService: DashboardHttpService,
    private loadingBar: LoadingBarService,
    private currencyHttpService: CurrencyHttpService
  ) {}

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.onSubmit();
    this.ranges = this.transactionHttpService.ranges;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onClear() {
    this.form.patchValue({
      username: null,
      currency_id: this.currencyCode,
      start_date_time: this.from,
      end_date_time: this.to,
      defaultDate: {
        startDate: this.from,
        endDate: this.to,
      },
    });
    this.onSubmit();
  }

  onSubmit() {
    this.selectedCurrency = this.form.value.currency_id;
    this.getStatisticsReport();
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time:
          event.startDate !== null ? event.startDate._d : event.startDate,
        end_date_time:
          event.endDate !== null ? event.endDate._d : event.endDate,
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private formInit() {
    this.form = new FormGroup({
      currency_id: new FormControl(this.currencyCode), // Default
      start_date_time: new FormControl(this.from),
      end_date_time: new FormControl(this.to),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to,
      }), // Do not remove: For Clearing The Range
    });
    this.selectedCurrency = this.currencyCode;
  }

  private getStatisticsReport() {
    this.loadingBar.start();
    this.dashboardHttpService
      .getReport(
        moment(this.form.value.start_date_time)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        moment(this.form.value.end_date_time)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        this.form.value.currency_id
      )
      .subscribe((res) => {
        this.statisticsReport = res;
        this.loadingBar.complete();
      });
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }
}
