import { MemberTrace } from '@core/models/member-trace.model';
import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { Agent } from '@core/models/agent.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class MemberTraceDataService extends DefaultDataService<MemberTrace>  {

  pagination: Pagination;
  breadcrumb: any;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('MemberTrace', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<MemberTrace[]> {
    return this.http.get<ApiResponse>('/member/trace').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): any{
    return this.http.get<ApiResponse>(`/member/trace${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res)
    );
  }

  export(pageParam: string) {
    return this.http.get<ApiResponse>(`/member/trace/export${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
