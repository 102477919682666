import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { tap, catchError } from 'rxjs/operators';
import { MemberBankAccount } from '@core/models/member-bank-account.model';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MemberBankAccountHttpService } from '@core/services/member-bank-account-http.service';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl, ValidationErrors } from '@angular/forms';
import { Member } from '@core/models/member.model';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { UploadHttpService } from '@core/services/upload-http.service';
import { ShowDocumentComponent } from '../../../../member-bank-account-verification/dialogs/show-document/show-document.component';

@Component({
  selector: 'kt-member-bank-account-edit',
  templateUrl: './member-bank-account-edit.component.html',
  styleUrls: ['./member-bank-account-edit.component.scss']
})
export class MemberBankAccountEditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = this.memberBankAccountHttpService.messages$;
  private subscription = new Subscription();
  private messageSubscription = new Subscription();

  dropdown = {
    bankType: this.dropdownHttpService.bankType
  };
  bankType = [1, 3];
  filteredBankDropdownList = [];
  bankDropdownSettings = {};
  bankDropdownList = [];
  bankSelectedItems: any;
  buttonLoading = false;
  selectedBank = '';
  selectedBankType = 'Bank';
  payNowData = {
    mobile_number: null,
    nric_fin: null,
    unique_entity_name: null,
    virtual_payment_address: null
  };
  refreshStatus: boolean;
  isPaynowDataValid = false;

  fileName: Array<Array<string>> = [];
  isFileUploading: Array<Array<boolean>> = [];
  maxFileUpload = 10;

  verificationSetting: any;
  fields: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { member: Member, bankAccount: MemberBankAccount, mode: string },
    public dialogRef: MatDialogRef<MemberBankAccountEditDialogComponent>,
    private memberBankAccountHttpService: MemberBankAccountHttpService,
    private merchantBankHttpService: MerchantBankHttpService,
    private uploadService: UploadHttpService,
    public dialog: MatDialog,
    private dropdownHttpService: DropdownHttpService
  ) { }

  async ngOnInit() {
    this.formInit();
    this.checkPayNowData();
    this.dropdownDynamicWidth();
    this.merchantBankHttpService.getMemberMerchantBanks(`&currency_id=${this.data.member.settings_currency_id}&bank_type=${this.bankType}&paginate=0`).subscribe(
      res => {
        this.bankDropdownList = res.map((elm: any) => {
          elm.name = elm.name.toUpperCase();
          return elm;
        });
        if (this.data.mode === 'edit'){
          let filteredDropdownList = this.bankDropdownList.filter(x => x.bank_type == this.data.bankAccount.bank_type);
          this.filteredBankDropdownList = Object.assign([], filteredDropdownList);
          
          if(this.data.bankAccount.bank_type == '3') {
            this.selectedBankType = 'Ewallet';
          }
          else{            
            this.filteredBankDropdownList.push({ id: 0, code: "others", name: "Others" });
          }

          this.bankSelectedItems = [this.filteredBankDropdownList.find(v => v.id === this.data.bankAccount.bank_id)];
          this.selectedBank = this.bankSelectedItems[0].name.toLowerCase();
        }
      }
    );
    this.bankDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'name',
      labelKey: 'name',
      lazyLoading: false,
      noDataLabel: '',
      showCheckbox: false,
      autoPosition: false
    };

    await this.onVerificationSetting();
    if (this.data.mode === 'edit') {
      this.bankVerification(this.data.bankAccount.bank_id);
    }


    // Commented this part to allow editing & updating bank field //
    /*if (this.data.mode === 'edit')
    {
      this.bankDropdownSettings['disabled'] = true;
    }*/
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave() {
    this.buttonLoading = true;
    const data = {
      id: this.data.bankAccount ? this.data.bankAccount.id : null,
      ...this.form.value,
      mobile_number: this.selectedBank === 'paynow' ? (this.form.value.mobile_number ? this.form.value.mobile_number.toString() : null) : null
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);

    switch (this.data.mode) {
      case 'edit':
        this.subscription = this.memberBankAccountHttpService.update(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = this.memberBankAccountHttpService.add(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private onVerificationSetting():Promise<void>{
    return new Promise((resolve, reject) => {
      this.memberBankAccountHttpService.getVerificationSetting().subscribe(res => {
        this.verificationSetting = res;
        resolve();
      });
    });
  }
  
  onBankChanged(bank: any[]) {
    if (bank.length > 0) {
      this.selectedBank = bank[0].name.toLowerCase();
      if (this.selectedBank === 'paynow') {
        this.resetForm('paynow');
      }
      else if (this.selectedBank === 'others') {
        this.resetForm('others');
      }
      else {
        this.resetForm(null);
      }

      this.bankVerification(bank[0].id);
    } else {
      this.selectedBank = '';
    }
  }

  bankVerification(bank_id: number) {
    const fields = this.isBankRequireVerification(bank_id, this.data.member.settings_currency_id, this.data.member.member_group_id);
    this.addFieldsToForm(fields);
    this.fields = fields;
  }

  isBankRequireVerification(bank_id: number, currency_id: number, member_group_id: number): any[] {
    for (const item of this.verificationSetting) {
      const moreInfo = item.more_info.find(info =>
        info.settings_currency_id == currency_id && 
        info.status == 1 && 
        info.bank_id.includes(bank_id) && 
        info.member_group_id.includes(member_group_id)
      );
  
      if (moreInfo) {
        const filteredFields = moreInfo.field.filter(field => field.status === 1);

        const sortedFields = filteredFields.map(field => {
          const position = this.getFieldPosition(field.field_details); // Use getFieldPosition function
  
          // If a valid position exists, return the field with position
          if (position !== null) {
            return {
              ...field,
              position // Add position for sorting later
            };
          }
        }).filter(field => field !== undefined) // Filter out undefined results
          .sort((a, b) => a.position - b.position); // Sort by position in ascending order
  
        return sortedFields;
      }
    }
    return [];
  }

  getFieldName(field_details: any[]){
    const defaultLangCode = 'EN';
    const result = field_details.find(item => item.lang_code === defaultLangCode);

    // If found, return the name
    if (result) {
      return result.name;
    }
  }

  getFieldPosition(field_details: any[]){
    const defaultLangCode = 'EN';
    const result = field_details.find(item => item.lang_code === defaultLangCode);

    return result ? result.position : null;
  }

  private addFieldsToForm(fields: any[]) {
    const fieldsArray = this.form.get('fields') as FormArray;
    fieldsArray.clear();

    fields.forEach(field => {
      const fieldGroup = new FormGroup({
        field_id: new FormControl(field.id || null) // Add field_id here
      });

      if (field.type === 1) {
        fieldGroup.addControl('textbox', new FormControl('', Validators.required));
      } else if (field.type === 2) {
        const documentsArray = new FormArray([new FormControl('', Validators.required)], this.requiredFileArrayValidator);
        fieldGroup.addControl('document', documentsArray);
      } 
  
      fieldsArray.push(fieldGroup);
    });

    this.isFileUploading = Array.from({ length: fields.length }, () => []);
  }

  private requiredFileArrayValidator(control: AbstractControl): ValidationErrors | null {
    const array = control as FormArray;
    return array.controls.length === 0 ? { required: true } : null;
  }

  onValidatePayNowData() {
    if ((this.form.value.mobile_number === null || this.form.value.mobile_number === '') && (this.form.value.nric_fin === null || this.form.value.nric_fin === '') && (this.form.value.unique_entity_name === null || this.form.value.unique_entity_name === '') && (this.form.value.virtual_payment_address === null || this.form.value.virtual_payment_address === '')) {
      this.isPaynowDataValid = false;
    } else {
      this.isPaynowDataValid = true;
    }
  }

  numberOnly(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  private checkPayNowData() {
    if (this.data.mode === 'edit') {
      this.selectedBank = this.data.bankAccount.bank_name.toLowerCase();
      if (this.selectedBank === 'paynow') {
        this.isPaynowDataValid = true;
        this.payNowData = {
          mobile_number: this.data.bankAccount.mobile_number,
          nric_fin: this.data.bankAccount.nric_fin,
          unique_entity_name: this.data.bankAccount.unique_entity_name,
          virtual_payment_address: this.data.bankAccount.virtual_payment_address
        };
        this.form.get('account_number').clearValidators();
        this.form.get('account_number').updateValueAndValidity();
        this.form.get('account_name').clearValidators();
        this.form.get('account_name').updateValueAndValidity();
      } else {
        this.isPaynowDataValid = true;
      }
    }
  }

  private formInit() {
    let bankId = null;
    let bankType = null;
    let accountName = null;
    let accountNumber = null;
    let bank_name = null;
    let mobile_number = null;
    let nric_fin = null;
    let unique_entity_name = null;
    let virtual_payment_address = null;
    if (this.data.mode === 'edit') {
      bankId = this.data.bankAccount.bank_id;
      bankType = this.data.bankAccount.bank_type;
      accountNumber = this.data.bankAccount.account_number;
      accountName = this.data.bankAccount.account_name;
      bank_name = this.data.bankAccount.bank_name;
      mobile_number = this.data.bankAccount.mobile_number;
      nric_fin = this.data.bankAccount.nric_fin;
      unique_entity_name = this.data.bankAccount.unique_entity_name;
      virtual_payment_address = this.data.bankAccount.virtual_payment_address;
    }
    this.form = new FormGroup({
      member_account_id: new FormControl(this.data.member.id),
      bank_id: new FormControl(bankId, [Validators.required]),
      bank_type: new FormControl(bankType, [Validators.required]),
      account_name: new FormControl(accountName, [Validators.required]),
      account_number: new FormControl(accountNumber, [Validators.required]),
      bank_name: new FormControl(bank_name, this?.data?.bankAccount?.bank_id == 0 ? [Validators.required] : []),
      mobile_number: new FormControl(mobile_number),
      nric_fin: new FormControl(nric_fin),
      unique_entity_name: new FormControl(unique_entity_name),
      virtual_payment_address: new FormControl(virtual_payment_address),
      fields: new FormArray([])  // Dynamic fields
    });
  }

  private resetForm(bankCode: string) {
    console.log(this.form.value);
    console.log('before clear');

    if (bankCode == 'paynow') {
      this.form.get('account_number').clearValidators();
      this.form.get('account_number').updateValueAndValidity();
      this.form.get('account_name').clearValidators();
      this.form.get('account_name').updateValueAndValidity();
      this.form.get('bank_name').clearValidators();
      this.form.get('bank_name').updateValueAndValidity();
      this.form.patchValue({
        account_number: null,
        account_name: null,
        bank_name: null,
        mobile_number: this.payNowData.mobile_number,
        nric_fin: this.payNowData.nric_fin,
        unique_entity_name: this.payNowData.unique_entity_name,
        virtual_payment_address: this.payNowData.virtual_payment_address
      });
      this.onValidatePayNowData();
    }
    else if (bankCode == 'others') {
      this.isPaynowDataValid = true;
      this.form.get('account_number').setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9\s]+$/)]);
      this.form.get('account_number').updateValueAndValidity();
      this.form.get('account_name').setValidators([Validators.required]);
      this.form.get('account_name').updateValueAndValidity();
      this.form.get('bank_name').setValidators([Validators.required]);
      this.form.get('bank_name').updateValueAndValidity();
      this.form.patchValue({
        account_name: this.data.mode === 'edit' ? this.data.bankAccount.account_name : null,
        account_number: this.data.mode === 'edit' ? this.data.bankAccount.account_number : null,
        bank_name: this.data.mode === 'edit' ? this.data.bankAccount.bank_name : null,
        mobile_number: null,
        nric_fin: null,
        unique_entity_name: null,
        virtual_payment_address: null,
      });
    } else {
      this.isPaynowDataValid = true;
      this.form.get('account_number').setValidators([Validators.required, Validators.pattern(/^[a-zA-Z0-9\s]+$/)]);
      this.form.get('account_number').updateValueAndValidity();
      this.form.get('account_name').setValidators([Validators.required]);
      this.form.get('account_name').updateValueAndValidity();
      this.form.get('bank_name').clearValidators();
      this.form.get('bank_name').updateValueAndValidity();
      this.form.patchValue({
        account_name: this.data.mode === 'edit' ? this.data.bankAccount.account_name : null,
        account_number: this.data.mode === 'edit' ? this.data.bankAccount.account_number : null,
        bank_name: null,
        mobile_number: null,
        nric_fin: null,
        unique_entity_name: null,
        virtual_payment_address: null,
      });
    }

    
    // Clear fields value
    const fieldsArray = this.form.get('fields') as FormArray;
    fieldsArray.controls.forEach((fieldControl) => {
      const documentArray = fieldControl.get('document') as FormArray;
      if (documentArray) {
        documentArray.clear(); // Clears all controls in the 'document' FormArray
      }
    });

    this.fileName = [];
    this.isFileUploading = [];

    console.log(this.form.value);
  }

  private dropdownDynamicWidth() {
    let interval = setInterval(() => {
      let dropdownList = document.querySelector('#bankDropdown .dropdown-list') as HTMLElement;
      if (dropdownList) {
        dropdownList.style.width = `${document.getElementById('bankDropdown').offsetWidth}px`;
        clearInterval(interval);
      }
    });
  }

  onBankType(event: any) {
    let filteredDropdownList = this.bankDropdownList.filter(x => x.bank_type == event.target.value);
    this.filteredBankDropdownList = Object.assign([], filteredDropdownList);

    if(event.target.value == '1') {
      this.selectedBankType = 'Bank';
      this.filteredBankDropdownList.push({ id: 0, code: "others", name: "Others" });
    }
    else if(event.target.value == '3') {
      this.selectedBankType = 'Ewallet';
    }

    this.bankSelectedItems = [];
    this.form.patchValue({
      account_name: null,
      account_number: null
    });
  }

  onOpenDialog(mode: string, data?: any) {
    if (mode == 'show-document') {
      if (data.value != '') {
        const documentData = {
          value: data.value
        };
        const documentArray = [documentData];

        this.openDialogBy(ShowDocumentComponent, { mode: mode, documents: documentArray });
      }
    }
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, documents?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        documents: data.documents,
        mode: data.mode
      },
      autoFocus: false
    });
  }

  getFileName(fieldIndex: number, docIndex: number): string {
    return this.fileName[fieldIndex] && this.fileName[fieldIndex][docIndex] ? this.fileName[fieldIndex][docIndex] : 'Choose Files';
  }

  addDocument(fieldIndex: number) {
    const fieldsArray = this.form.get('fields') as FormArray;
    const documentArray = fieldsArray.at(fieldIndex).get('document') as FormArray;
    const newDocumentControl = new FormControl('', [Validators.required]);
    if (documentArray.length < this.maxFileUpload) {
      documentArray.push(newDocumentControl);
    }
  }

  removeDocument(fieldIndex: number, docIndex: number) {
    const fieldsArray = this.form.get('fields') as FormArray;
    const documentArray = fieldsArray.at(fieldIndex).get('document') as FormArray;
    documentArray.removeAt(docIndex);
    if (this.fileName[fieldIndex]) {
      this.fileName[fieldIndex].splice(docIndex, 1);
    }
  }

  onUploadFile(event: any, fieldIndex: number, docIndex: number) {
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'uploads');
  
    // Ensure the file uploading state is tracked per field and document index
    if (!this.isFileUploading[fieldIndex]) {
      this.isFileUploading[fieldIndex] = [];
    }
    this.isFileUploading[fieldIndex][docIndex] = true;
  
    this.uploadService.upload(formData).pipe(
      tap(res => {
        const fieldsArray = this.form.get('fields') as FormArray;
        const documentArray = fieldsArray.at(fieldIndex).get('document') as FormArray;
        documentArray.at(docIndex).patchValue(res[0]);
        
        if (!this.fileName[fieldIndex]) {
          this.fileName[fieldIndex] = [];
        }
        this.fileName[fieldIndex][docIndex] = event.target.files[0].name;
        this.isFileUploading[fieldIndex][docIndex] = false;
      }),
      catchError((error) => {
        this.isFileUploading[fieldIndex][docIndex] = false;
        throw error;
      })
    ).subscribe();
  }
}
