<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Member Group</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 form-group">
                        <label class="control-label">Code <span class="text-danger">*</span></label>
                        <input type="text" formControlName="code" class="col-12 form-control">
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Name <span class="text-danger">*</span></label>
                        <input type="text" formControlName="name" class="col-12 form-control">
                    </div>
                    <div class="col-12 form-group">
                        <label class="control-label">Status <span class="text-danger">*</span></label>
                        <select *ngIf="dropdown.statuses" class="col-12 form-control" formControlName="status">
                          <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
                        </select>
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Default</label>
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" formControlName="default" class="custom-control-input" id="defaultCheck">
                          <label class="custom-control-label" for="defaultCheck">{{ form.value.default ? 'Yes' : 'No' }}</label>
                        </div>
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">VIP</label>
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" formControlName="vip" class="custom-control-input" id="vipCheck">
                          <label class="custom-control-label" for="vipCheck">{{ form.value.vip ? 'Yes' : 'No' }}</label>
                        </div>
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Trial</label>
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" formControlName="trial" class="custom-control-input" id="trialCheck">
                          <label class="custom-control-label" for="trialCheck">{{ form.value.trial ? 'Yes' : 'No' }}</label>
                        </div>
                    </div>
                    <div class="col-md-12 form-group" *ngIf="data.mode === 'edit'">
                        <label class="control-label">Color</label>
                        <input [(colorPicker)]="form.value.color" [value]="form.value.color" [cpPosition]="'top-right'" formControlName="color" class="col-12 form-control">
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Remarks </label>
                        <input type="text" formControlName="remarks" class="col-12 form-control">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
              <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.group, data.mode)"></kt-submit-button>
            </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
