<div class="online-operator-container" ngbDropdown placement="bottom-left">
  <!-- Dropdown Toggle -->
  <div ngbDropdownToggle class="dropdown-toggle">
    <span class="online-count">Current Online Operators 
      <span class="fas fa-users"></span> {{ onlineUsers.length }}
    </span>
  </div>

  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-anim">
    <div class="search-container">
      <input type="text" [(ngModel)]="searchTerm" placeholder="Search by name" class="form-control search-input" />
    </div>
    <div class="online-users">
      <div class="user-details" *ngFor="let user of onlineUsers | filterOnlineOperator: searchTerm">
        <div class="user-initials-container">
          <div
            class="user-initials"
            [ngStyle]="{ 'background-color': getColorByInitial(user.name|firstAndSecondLetter) }"
          >
            {{user.name|firstAndSecondLetter}}
          </div>
          <span class="status-badge"></span>
        </div>
        <div class="user-name">{{ user.name }}</div>
      </div>
    </div>
  </div>
</div>