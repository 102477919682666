import { GameProviderDataService } from '../game-providers/services/game-provider-data.service';
import { MatDialog } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { tap, catchError, takeUntil } from 'rxjs/operators';
import { Subscription, Subject, Observable, of } from 'rxjs';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, ViewChildren, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameProviderEntityService } from '../game-providers/services/game-provider-entity.service';
import { MatTabGroup } from '@angular/material/tabs';
import Swal from 'sweetalert2';
import { DragRef, CdkDropList, CdkDropListGroup, CdkDragEnter, moveItemInArray } from "@angular/cdk/drag-drop";
import { AssignGamesEditDialogComponent } from './dialogs/assign-games-edit.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CanComponentDeactivate } from '@core/interfaces/can-deactivate.interface';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-mini-games',
  templateUrl: './assign-games.component.html',
  styleUrls: ['./assign-games.component.scss']
})
export class AssignGamesComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChildren(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;

  @HostListener('window:beforeunload', ['$event'])
  confirmLeavingPageBeforeSaving($event) {
    if(this.checkupdate()){
      $event.preventDefault();
      $event.returnValue = true;
      return $event;
    }
  }

  private target: CdkDropList = null;
  private targetIndex: number;
  private source: CdkDropList = null;
  private sourceIndex: number;
  private dragRef: DragRef = null;

  boxWidth = '160px';
  boxHeight = '160px';

  messages$ = this.gameProviderDataService.messages$;
  removeGames = [];
  games = {
    LC: [],
    SL: [],
    FS: [],
    LT: [],
    CG: [],
    TB: [],
    AR: [],
  }
  placeholder: any;
  placeholders = [];

  form: FormGroup;
  gameLoaded = false;
  submitLoading = false;
  resetLoading = false;
  buttonLoading = false;

  dropdown = {
    gameTags: [],
    currencies: [],
    gameProviders: [],
  }

  gamePositionUpdate = [];
  gameTagList = [];
  gameGameTagList = [];
  categoryList = [];
  gameTagDropdown = [];
  gameProviderDropdown = [];
  subCategoryDropdown = [];
  selectedGameTag: any;
  selectedGameTagName: any;
  selectedCurrency: any;
  selectedGameProvider: any;
  selectedExpandedCategoryCode: Array<{code: string, expand: number}> = [];
  selectedGameProviderCurrencyList: any;
  isFirstProvider = true;
  isMerchantHasProvider = false;
  keyword: any;
  resetExpand = true;

  isInit = true;
  loading = false;
  gameTagId = this.route.snapshot.paramMap.get('gameTagId');
  destroyed$ = new Subject();

  init_games = {
    LC: [],
    SL: [],
    FS: [],
    LT: [],
    CG: [],
    TB: [],
    AR: [],
  }

  gameProviderExcludeLC = ['BG', 'DG', 'SA', 'WE'];

  init_selectedGameTag:any;
  selectedIndex = 0 ;

  // permissions
  canEditGameTagSettings: boolean;
  canAddGames: boolean;
  canRemoveGames: boolean;
  canResetGames: boolean;
  canSyncGames: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private route: ActivatedRoute,
    private dropdownHttpService: DropdownHttpService,
    private dialog: MatDialog,
    private gameProviderDataService: GameProviderDataService,
    private cdr: ChangeDetectorRef,
    private gameproviderEntity: GameProviderEntityService,
    private router: Router,
    private translateService: TranslateService,
    private appPermissionService: AppPermissionService,
  ) {
    this.target = null;
    this.source = null;
  }

  async canDeactivate(): Promise<boolean | Observable<boolean>> {
    // provide component specific logic to decide if component can or can't be deactivated
    if(this.checkupdate()){
      const result =  await Swal.fire({
        title: '<div class="text-center">This page contains unsaved changes</div>',
        html: '<div class="text-center">' + this.translateService.instant('If you leave this page, changes you made may not be saved') +'</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Go Back'),
        confirmButtonText: this.translateService.instant('Continue'),
        icon:'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          return(true);
        } else if (result.isDenied) {
          return(false);
        }
      });

      return result;
    }
    return true;
  }

  ngOnInit() {
    this.loading = true;
    this.loadingBar.start();
    this.dropdownHttpService.assignGameTags.subscribe(res => {
      if (res.length) {
        this.gameTagDropdown = res;
        this.gameTagDropdown = this.gameTagDropdown.map((x) => {
          return {
            ...x,
          };
        }).sort((a, b) => a.name.localeCompare(b.name));

        this.setGameTag(false, true);
        this.getCurrencies();

        this.gameproviderEntity.getWithQuery(`?paginate=false`).pipe(
          tap(res => {
            this.gameProviderDropdown = res.filter(x => x['categories'].some(y => (['LC', 'SL', 'FS', 'LT', 'CG', 'TB', 'AR'].includes(y['code'])) && y['type'] !== 'APP'));
            this.dropdown.gameProviders = this.gameProviderDropdown.filter(x => x['currency_code'].includes(this.selectedCurrency['name']));
            if (this.dropdown.gameProviders.length) {
              this.isMerchantHasProvider = true;
              this.selectedGameProvider = this.dropdown.gameProviders[0];
              this.selectedGameProviderCurrencyList = this.dropdown.gameProviders[0].currency_code;
              this.getCategories(true);
            }

            this.loading = false;
            this.loadingBar.complete();
          })
        ).subscribe();
      }
    });

    this.formInit();

    if (sessionStorage.getItem('assign_games') === null) {
      window.location.href = '/game/game-tags';
    } else {
      this.setSession();
    }

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditGameTagSettings = appPermissions.edit_game_tag_settings;
      this.canAddGames = appPermissions.add_games;
      this.canRemoveGames = appPermissions.remove_games;
      this.canResetGames = appPermissions.reset_games;
      this.canSyncGames = appPermissions.sync_games;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    sessionStorage.removeItem('assign_games');
  }

  getGameGameTag() {
    this.gameGameTagList = [];
    let categoriesLength = this.categoryList.length;

    if (categoriesLength === 0) {
      // If categoryList empty, resolve immediately
      return Promise.resolve();
    }

    const params = {
      settings_currency_id: this.selectedCurrency['id'],
      game_tag_id: this.selectedGameTag,
      game_category_code: '',
      game_provider_code: this.selectedGameProvider['code'],
    }

    if (this.resetExpand == true) {
      this.selectedExpandedCategoryCode = [];
      this.categoryList.forEach((item) => {
        this.selectedExpandedCategoryCode.push({ code: item['code'], expand: this.selectedExpandedCategoryCode.length > 0 ? 0 : 1 });
      });
    }

    return new Promise<void>((resolve, reject) => {
      this.categoryList.forEach((item) => {
        params['game_category_code'] = item['code'];

        this.gameProviderDataService.getGameGameTag(params).pipe(
          takeUntil(this.destroyed$),
          tap(res => {
            if (params['game_provider_code'] == this.selectedGameProvider['code']) {
              if (item['code'] == 'LC') {
                this.games.LC = res.filter(x => x['status'] == 1);
                this.games.LC = this.games.LC.sort((a, b) => {
                  if (a.position !== b.position) {
                    return a.position - b.position; // Sort by position in ascending order
                  }
                  return a.id - b.id; // If positions are the same, sort by id in ascending order
                });
              }
              else if (item['code'] == 'SL') {
                this.games.SL = res.filter(x => x['status'] == 1);
                this.games.SL = this.games.SL.sort((a, b) => {
                  if (a.position !== b.position) {
                    return a.position - b.position; // Sort by position in ascending order
                  }
                  return a.id - b.id; // If positions are the same, sort by id in ascending order
                });
              }
              else if (item['code'] == 'FS') {
                this.games.FS = res.filter(x => x['status'] == 1);
                this.games.FS = this.games.FS.sort((a, b) => {
                  if (a.position !== b.position) {
                    return a.position - b.position; // Sort by position in ascending order
                  }
                  return a.id - b.id; // If positions are the same, sort by id in ascending order
                });
              }
              else if (item['code'] == 'LT') {
                this.games.LT = res.filter(x => x['status'] == 1);
                this.games.LT = this.games.LT.sort((a, b) => {
                  if (a.position !== b.position) {
                    return a.position - b.position; // Sort by position in ascending order
                  }
                  return a.id - b.id; // If positions are the same, sort by id in ascending order
                });
              }
              else if (item['code'] == 'CG') {
                this.games.CG = res.filter(x => x['status'] == 1);
                this.games.CG = this.games.CG.sort((a, b) => {
                  if (a.position !== b.position) {
                    return a.position - b.position; // Sort by position in ascending order
                  }
                  return a.id - b.id; // If positions are the same, sort by id in ascending order
                });
              }
              else if (item['code'] == 'TB') {
                this.games.TB = res.filter(x => x['status'] == 1);
                this.games.TB = this.games.TB.sort((a, b) => {
                  if (a.position !== b.position) {
                    return a.position - b.position; // Sort by position in ascending order
                  }
                  return a.id - b.id; // If positions are the same, sort by id in ascending order
                });
              }
              else if (item['code'] == 'AR') {
                this.games.AR = res.filter(x => x['status'] == 1);
                this.games.AR = this.games.AR.sort((a, b) => {
                  if (a.position !== b.position) {
                    return a.position - b.position; // Sort by position in ascending order
                  }
                  return a.id - b.id; // If positions are the same, sort by id in ascending order
                });
              }

              if (this.gameGameTagList.length == 0) {
                this.gameGameTagList = res;
              }
              else {
                this.gameGameTagList = [...this.gameGameTagList, ...res];
              }
              this.init_games.FS =this.gameGameTagList.filter(x => x['game_category_code'] == 'FS' && x['status'] == 1);
              this.init_games.LC =this.gameGameTagList.filter(x => x['game_category_code'] == 'LC' && x['status'] == 1);
              this.init_games.SL =this.gameGameTagList.filter(x => x['game_category_code'] == 'SL' && x['status'] == 1);
              this.init_games.LT =this.gameGameTagList.filter(x => x['game_category_code'] == 'LT' && x['status'] == 1);
              this.init_games.CG =this.gameGameTagList.filter(x => x['game_category_code'] == 'CG' && x['status'] == 1);
              this.init_games.TB =this.gameGameTagList.filter(x => x['game_category_code'] == 'TB' && x['status'] == 1);
              this.init_games.AR =this.gameGameTagList.filter(x => x['game_category_code'] == 'AR' && x['status'] == 1);
            }
          })
        ).subscribe(res => {
          categoriesLength--;
          if (categoriesLength == 0) {
            this.removeGames = this.gameGameTagList.filter(x => x['status'] == 0);
            resolve();
          }
        });
      })
    })
  }

  getCurrencies() {
    return this.dropdownHttpService.currencies.pipe(
      tap(res => {
        this.dropdown.currencies = res;
        this.selectedCurrency = res[0];
      })
    ).toPromise();
  }

  setCurrency(event) {
    var changedObjects = false;
    if (JSON.stringify(this.init_games) !== JSON.stringify(this.games) && this.categoryList.length > 0) {
      changedObjects = true;
    }
    if( changedObjects ==  true && this.selectedIndex != event.index){
      Swal.fire({
        title: '<div class="text-center">This page contains unsaved changes</div>',
        html: '<div class="text-center">' + this.translateService.instant('If you leave this page, changes you made may not be saved') +'</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Go Back'),
        confirmButtonText: this.translateService.instant('Continue'),
        icon:'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          this.selectedIndex = event.index;
          this.gameLoaded = false;
          this.resetList();
          this.categoryList = [];
          this.selectedCurrency = this.dropdown.currencies[event.index];
          this.getProviders(this.selectedCurrency['name']);
        } else if (result.isDenied) {
          this.tabGroup.selectedIndex = this.selectedIndex;
        }
      });
    }else{

      if(this.selectedIndex != event.index){
        this.selectedIndex = event.index;
        this.gameLoaded = false;
        this.resetList();
        this.categoryList = [];
        this.selectedCurrency = this.dropdown.currencies[event.index];
        this.getProviders(this.selectedCurrency['name']);
      }
    }
  }

  setSession() {
    sessionStorage.setItem('recent_page', 'game-tags');
  }

  setGameTag(refresh: boolean, init = false) {
    var changedObjects = false;
    if (JSON.stringify(this.init_games) !== JSON.stringify(this.games) && this.categoryList.length > 0) {
      changedObjects = true;
    }
    if (changedObjects ==  true && init == false && refresh == true) {
      Swal.fire({
        title: '<div class="text-center">This page contains unsaved changes</div>',
        html: '<div class="text-center">' + this.translateService.instant('If you leave this page, changes you made may not be saved') +'</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Go Back'),
        confirmButtonText: this.translateService.instant('Continue'),
        icon:'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          this.gameLoaded = false;
          this.resetList();
          this.dropdown.gameTags = this.gameTagDropdown;
          this.selectedGameTag = init ? this.dropdown.gameTags.filter(x => x['id'] == this.gameTagId)[0]['id'] : this.dropdown.gameTags[0].id;
          this.init_selectedGameTag = this.selectedGameTag;
          this.selectedGameTagName = init ? this.dropdown.gameTags.filter(x => x['id'] == this.gameTagId)[0]['name'].toLowerCase() : this.dropdown.gameTags[0].name.toLowerCase();
          refresh ? this.getProviders(this.selectedCurrency['name']) : null;
        }
      });
    }else{
        this.gameLoaded = false;
        this.resetList();
        this.dropdown.gameTags = this.gameTagDropdown;;
        this.selectedGameTag = init ? this.dropdown.gameTags.filter(x => x['id'] == this.gameTagId)[0]['id'] : this.dropdown.gameTags[0].id;
        this.init_selectedGameTag = this.selectedGameTag;
        this.selectedGameTagName = init ? this.dropdown.gameTags.filter(x => x['id'] == this.gameTagId)[0]['name'].toLowerCase() : this.dropdown.gameTags[0].name.toLowerCase();
        refresh ? this.getProviders(this.selectedCurrency['name']) : null;
    }
  }

  changeGameTag() {
    var changedObjects = false;
    if (JSON.stringify(this.init_games) !== JSON.stringify(this.games) && this.categoryList.length > 0) {
      changedObjects = true;
    }
    if( changedObjects ==  true ){
      Swal.fire({
        title: '<div class="text-center">This page contains unsaved changes</div>',
        html: '<div class="text-center">' + this.translateService.instant('If you leave this page, changes you made may not be saved') +'</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Go Back'),
        confirmButtonText: this.translateService.instant('Continue'),
        icon:'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          this.init_selectedGameTag = this.selectedGameTag;
          this.gameLoaded = false;
          this.categoryList = [];
          this.selectedGameTagName = this.dropdown.gameTags.filter(x => x['id'] == this.selectedGameTag)[0]['name'].toLowerCase();
          this.isFirstProvider = false;
          this.getCategories();
        } else if (result.isDenied) {
          this.selectedGameTag =this.init_selectedGameTag;
        }
      });
    }else{
      this.init_selectedGameTag = this.selectedGameTag;
      this.gameLoaded = false;
      this.categoryList = [];
      this.selectedGameTagName = this.dropdown.gameTags.filter(x => x['id'] == this.selectedGameTag)[0]['name'].toLowerCase();
      this.isFirstProvider = false;
      this.getCategories();
    }
  }

  onOpenDialog(type: string, category: any) {
    this.selectedExpandedCategoryCode.forEach(function (item) {
      if (item.code == category['code']) {
        item.expand = 1;
      }
    });
    if (type == 'add-game') {
      const data = {
        category_code: category['code'],
        category_name: category['category'],
        game_provider_id: this.selectedGameProvider['id'],
        game_provider_code: this.selectedGameProvider['code'],
        currency: this.selectedCurrency['name'],
        games: this.getList(category['code']),
      };

      this.openDialogBy(AssignGamesEditDialogComponent, category, { gameProvider: data });
    }
    else if (type == 'reset-game') {
      Swal.fire({
        title: this.selectedGameTagName == 'all' ? 'Reset Game List' : 'Remove Game List',
        text: this.selectedGameTagName == 'all' ? 'Are you sure you want to reset the position to default value?' : 'Are you sure you want to remove all the assigned games from this list?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((response) => {
        if (response.isConfirmed) {
          if (this.selectedGameTagName == 'all') {
            this.loadingBar.start();
            const params = {
              settings_currency_id: this.selectedCurrency['id'],
              game_tag_id: this.selectedGameTag,
              game_provider_code: this.selectedGameProvider['code'],
              game_category_code: category['code'],
            };

            this.gameProviderDataService.resetGame(params).pipe(
              tap((res: any) => {
                this.loadingBar.complete();
              }),
              catchError((error) => {
                this.loadingBar.complete();
                this.form.setErrors(null);
                throw error;
              })
            ).subscribe();
          } else {
            if (category['code'] == 'LC') {
              this.games.LC.forEach(item => {
                let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
                if (existGame.length) {
                  this.removeGames.push(existGame[0]);
                }
              });
              this.games.LC = [];
            }
            else if (category['code'] == 'SL') {
              this.games.SL.forEach(item => {
                let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
                if (existGame.length) {
                  this.removeGames.push(existGame[0]);
                }
              });
              this.games.SL = [];
            }
            else if (category['code'] == 'FS') {
              this.games.FS.forEach(item => {
                let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
                if (existGame.length) {
                  this.removeGames.push(existGame[0]);
                }
              });
              this.games.FS = [];
            }
            else if (category['code'] == 'LT') {
              this.games.LT.forEach(item => {
                let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
                if (existGame.length) {
                  this.removeGames.push(existGame[0]);
                }
              });
              this.games.LT = [];
            }
            else if (category['code'] == 'CG') {
              this.games.CG.forEach(item => {
                let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
                if (existGame.length) {
                  this.removeGames.push(existGame[0]);
                }
              });
              this.games.CG = [];
            }
            else if (category['code'] == 'TB') {
              this.games.TB.forEach(item => {
                let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
                if (existGame.length) {
                  this.removeGames.push(existGame[0]);
                }
              });
              this.games.TB = [];
            }
            else if (category['code'] == 'AR') {
              this.games.AR.forEach(item => {
                let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
                if (existGame.length) {
                  this.removeGames.push(existGame[0]);
                }
              });
              this.games.AR = [];
            }
          }

        }

        this.cdr.detectChanges();
      });
    }
  }

  gameStoreAndUpdate() {
    this.gamePositionUpdate = [];

    return new Promise<void>((resolve, reject) => {
      this.games.LC.forEach((item, index) => {
        let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
        if (existGame.length) {
          this.gamePositionUpdate.push({
            id: existGame[0].id,
            game_id: existGame[0].game_id,
            position: index + 1,
            status: 1,
          });
        }
        else {
          this.gamePositionUpdate.push({
            id: 0,
            game_id: item['game_id'],
            position: index + 1,
            status: 1,
          });
        }
      });

      this.games.SL.forEach((item, index) => {
        let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
        if (existGame.length) {
          this.gamePositionUpdate.push({
            id: existGame[0].id,
            game_id: existGame[0].game_id,
            position: index + 1,
            status: 1,
          });
        }
        else {
          this.gamePositionUpdate.push({
            id: 0,
            game_id: item['game_id'],
            position: index + 1,
            status: 1,
          });
        }
      });

      this.games.FS.forEach((item, index) => {
        let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
        if (existGame.length) {
          this.gamePositionUpdate.push({
            id: existGame[0].id,
            game_id: existGame[0].game_id,
            position: index + 1,
            status: 1,
          });
        }
        else {
          this.gamePositionUpdate.push({
            id: 0,
            game_id: item['game_id'],
            position: index + 1,
            status: 1,
          });
        }
      });

      this.games.LT.forEach((item, index) => {
        let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
        if (existGame.length) {
          this.gamePositionUpdate.push({
            id: existGame[0].id,
            game_id: existGame[0].game_id,
            position: index + 1,
            status: 1,
          });
        }
        else {
          this.gamePositionUpdate.push({
            id: 0,
            game_id: item['game_id'],
            position: index + 1,
            status: 1,
          });
        }
      });

      this.games.CG.forEach((item, index) => {
        let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
        if (existGame.length) {
          this.gamePositionUpdate.push({
            id: existGame[0].id,
            game_id: existGame[0].game_id,
            position: index + 1,
            status: 1,
          });
        }
        else {
          this.gamePositionUpdate.push({
            id: 0,
            game_id: item['game_id'],
            position: index + 1,
            status: 1,
          });
        }
      });

      this.games.TB.forEach((item, index) => {
        let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
        if (existGame.length) {
          this.gamePositionUpdate.push({
            id: existGame[0].id,
            game_id: existGame[0].game_id,
            position: index + 1,
            status: 1,
          });
        }
        else {
          this.gamePositionUpdate.push({
            id: 0,
            game_id: item['game_id'],
            position: index + 1,
            status: 1,
          });
        }
      });

      this.games.AR.forEach((item, index) => {
        let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
        if (existGame.length) {
          this.gamePositionUpdate.push({
            id: existGame[0].id,
            game_id: existGame[0].game_id,
            position: index + 1,
            status: 1,
          });
        }
        else {
          this.gamePositionUpdate.push({
            id: 0,
            game_id: item['game_id'],
            position: index + 1,
            status: 1,
          });
        }
      });

      this.removeGames.forEach((item, index) => {
        let existGame = this.gameGameTagList.filter(x => x['game_id'] == item['game_id']);
        if (existGame.length) {
          this.gamePositionUpdate.push({
            id: existGame[0].id,
            game_id: existGame[0].game_id,
            position: 99,
            status: 0,
          });
        }
      });

      resolve();
    });
  }

  async onSave() {
    this.loadingBar.start();
    this.buttonLoading = true;

    await this.gameStoreAndUpdate();
    const params = {
      settings_currency_id: this.selectedCurrency['id'],
      game_tag_id: this.selectedGameTag,
      game_game_tag: this.gamePositionUpdate,
    };

    this.gameProviderDataService.updateGameGameTag(params).pipe(
      tap((res: any) => {
        this.loadingBar.complete();
        this.buttonLoading = false;
      }),
      catchError((error) => {
        this.loadingBar.complete();
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
  }

  private openDialogBy(componentRef: any, category: any, data?: { gameProvider?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        gameProvider: data.gameProvider,
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      result.forEach(item => {
        this.removeGames = this.removeGames.filter(x => x['game_id'] !== item['game_id']);
      });

      if (result.length) {
        if (category['code'] == 'LC') {
          this.games.LC = [...this.games.LC, ...result]
        }
        else if (category['code'] == 'SL') {
          this.games.SL = [...this.games.SL, ...result]
        }
        else if (category['code'] == 'FS') {
          this.games.FS = [...this.games.FS, ...result]
        }
        else if (category['code'] == 'LT') {
          this.games.LT = [...this.games.LT, ...result]
        }
        else if (category['code'] == 'CG') {
          this.games.CG = [...this.games.CG, ...result]
        }
        else if (category['code'] == 'TB') {
          this.games.TB = [...this.games.TB, ...result]
        }
        else if (category['code'] == 'AR') {
          this.games.AR = [...this.games.AR, ...result]
        }
      }
      this.cdr.detectChanges();
    });
  }

  private formInit() {
    this.form = new FormGroup({
      game_tag: new FormControl(null),
    });
  }

  getProviders(currencyName: any) {
    this.dropdown.gameProviders = this.gameProviderDropdown.filter(x => x['currency_code'].includes(currencyName));

    if (this.dropdown.gameProviders.length) {
      let previousProvider = this.selectedGameProvider ? this.dropdown.gameProviders.filter(x => x['id'] == this.selectedGameProvider['id']) : null;
      if (!this.isFirstProvider && previousProvider !== null) {
        this.selectedGameProvider = previousProvider.length ? this.selectedGameProvider : this.dropdown.gameProviders[0];
        this.selectedGameProviderCurrencyList = previousProvider.length ? this.selectedGameProviderCurrencyList : this.dropdown.gameProviders[0].currency_code;
        this.isFirstProvider = previousProvider.length ? false : true;
      }
      else {
        this.selectedGameProvider = this.dropdown.gameProviders[0];
        this.selectedGameProviderCurrencyList = this.dropdown.gameProviders[0].currency_code;
      }

      if (this.selectedGameProvider['categories'].length) {
        if (this.gameProviderExcludeLC.includes(this.selectedGameProvider.code)) {
          // Hardcoded hide Live Casino game for those game provider due to game provider only can launch by lobby
          this.categoryList = this.selectedGameProvider['categories'].filter(x => ['SL', 'FS', 'LT', 'CG', 'TB', 'AR'].includes(x['code']));
        } else {
          this.categoryList = this.selectedGameProvider['categories'].filter(x => ['LC', 'SL', 'FS', 'LT', 'CG', 'TB', 'AR'].includes(x['code']));
        }
      } else {
        this.categoryList = [];
      }

      setTimeout(() => {
        this.getCategories();
      }, 1500);
    }
    else {
      this.selectedGameProvider = null;
      this.gameLoaded = true;
      this.cdr.detectChanges();
    }
  }

  setProvider(id: any, refresh = false, catgegoryCode = null) {
    if (this.selectedGameProvider['id'] !== id || refresh == true) {
      var changedObjects = false;
      if (JSON.stringify(this.init_games) !== JSON.stringify(this.games) && this.categoryList.length > 0) {
        changedObjects = true;
      }
      if( changedObjects ==  true && refresh == false){
        Swal.fire({
          title: '<div class="text-center">This page contains unsaved changes</div>',
          html: '<div class="text-center">' + this.translateService.instant('If you leave this page, changes you made may not be saved') +'</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('Go Back'),
          confirmButtonText: this.translateService.instant('Continue'),
          icon:'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {

            this.gameLoaded = false;
            this.categoryList = [];
            this.isFirstProvider = false;
            this.selectedGameProvider = this.dropdown.gameProviders.filter(x => x['id'] == id)[0];
            this.getCategories(false, catgegoryCode);

          } else if (result.isDenied) {
          }
        });
      }else{
        this.gameLoaded = false;
        this.categoryList = [];
        this.isFirstProvider = false;
        this.selectedGameProvider = this.dropdown.gameProviders.filter(x => x['id'] == id)[0];
        this.getCategories(false, catgegoryCode);
      }
    }
  }

  searchProvider(keyword: any) {
    this.isFirstProvider = false;
    this.dropdown.gameProviders = this.gameProviderDropdown.filter(x => (x['code'].toUpperCase().includes(keyword.toUpperCase()) || x['name'].toUpperCase().includes(keyword.toUpperCase())) && x['currency_code'].includes(this.selectedCurrency['name']));
  }

  onSearch(event?) {
    this.keyword = event ? event.target.value : this.keyword;
    if (event && event.key === "Enter" && this.keyword) {
      this.searchProvider(this.keyword);
    }
    else if (!event && this.keyword) {
      this.searchProvider(this.keyword);
    }
  }

  resetKeyword(event) {
    if (!event.target.value) {
      this.keyword = '';
      this.searchProvider('');
    }
  }

  async getCategories(init?: boolean, categoryCode = null) {
    this.destroyed$.next();
    this.gameLoaded = false;
    this.resetList();
    let timeout = init ? 100 : 5;
    let category = this.dropdown.gameProviders.filter(x => x['id'] == this.selectedGameProvider['id']);

    if (category.length && this.categoryList.length == 0) {
      if (this.gameProviderExcludeLC.includes(category[0].code)) {
        // Hardcoded hide Live Casino game for those game provider due to game provider only can launch by lobby
        category = category[0]['categories'].filter(x => ['SL', 'FS', 'LT', 'CG', 'TB', 'AR'].includes(x['code']));
      } else {
        category = category[0]['categories'].filter(x => ['LC', 'SL', 'FS', 'LT', 'CG', 'TB', 'AR'].includes(x['code']));
      }
      this.categoryList = category;
      await this.getGameGameTag();
    }
    else if (this.categoryList.length) {
      await this.getGameGameTag();
    }
    else {
      this.categoryList = [];
    }

    setTimeout(() => {
      let index = 0;
      let isFirst = true;
      this.placeholders = [];

      this.listGroup['_results'].forEach(listGroup => {
        listGroup['_items'].forEach(result => {
          if (isFirst) {
            let item = result;
            item['category_code'] = this.categoryList[index]['code'];
            this.placeholders.push(item);

            let phElement = result.element.nativeElement;
            phElement.style.display = 'none';
            phElement.parentElement !== null ? phElement.parentElement.removeChild(phElement) : null;
            isFirst = false;
          }
        });

        index++;
        isFirst = true;
      });

      this.resetExpand = true;
      this.gameLoaded = true;
      this.cdr.detectChanges();
    }, timeout);
  }

  setCategory(category: any) {
    this.selectedExpandedCategoryCode.forEach(function (item) {
      if (item.code == category['code']) {
        item.expand = item.expand == 1 ? 0 : 1;
      }
    });
  }

  scrollTop(categoryCode: any) {
    let element = null;
    if (categoryCode == 'LC') {
      element = document.getElementById('cat_point_LC');
    }
    else if (categoryCode == 'SL') {
      element = document.getElementById('cat_point_SL');
    }
    else if (categoryCode == 'FS') {
      element = document.getElementById('cat_point_FS');
    }
    else if (categoryCode == 'LT') {
      element = document.getElementById('cat_point_LT');
    }
    else if (categoryCode == 'CG') {
      element = document.getElementById('cat_point_CG');
    }
    else if (categoryCode == 'TB') {
      element = document.getElementById('cat_point_TB');
    }
    else if (categoryCode == 'AR') {
      element = document.getElementById('cat_point_AR');
    }
     
    element.scrollTop = 0;
  }

  getList(code: any) {
    let list = [];
    if (code == 'SL') list = this.games.SL;
    else if (code == 'LC') list = this.games.LC;
    else if (code == 'FS') list = this.games.FS;
    else if (code == 'LT') list = this.games.LT;
    else if (code == 'CG') list = this.games.CG;
    else if (code == 'TB') list = this.games.TB;
    else if (code == 'AR') list = this.games.AR;
    return list;
  }

  removeGame(gameId: any, category: any) {
    let isShowDeleteGameConfirmation = sessionStorage.getItem('isShowDeleteGameConfirmation');
    if (isShowDeleteGameConfirmation == null || isShowDeleteGameConfirmation == 'true') {
      Swal.fire({
        title: 'Delete Game',
        text: 'Are you sure you want to delete?',
        icon: 'warning',
        input: 'checkbox',
        inputPlaceholder: 'Do not show it again',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((response) => {
        if (response.value && response.isConfirmed) {
          sessionStorage.setItem('isShowDeleteGameConfirmation', 'false');
          this.updateRemoveGames(gameId, category);
        }
        else if (!response.value && response.isConfirmed) {
          this.updateRemoveGames(gameId, category);
        }
      });
    } else {
      this.updateRemoveGames(gameId, category);
    }
  }

  updateRemoveGames(gameId: any, category: any) {
    let selectedCategory = this.getList(category['code']);
    let gameToRemove = selectedCategory.filter(x => x['game_id'] == gameId);

    if (this.gameGameTagList.filter(x => x['game_id'] == gameId).length) {
      if (this.removeGames.length) {
        this.removeGames.filter(x => x['game_id'] == gameId).length == 0 ? this.removeGames.push(gameToRemove[0]) : null;
      } else {
        this.removeGames.push(gameToRemove[0]);
      }
    }

    if (category['code']  == 'LC') {
      this.games.LC = this.games.LC.filter(x => x['game_id'] != gameId);
    }
    else if (category['code']  == 'SL') {
      this.games.SL = this.games.SL.filter(x => x['game_id'] != gameId);
    }
    else if (category['code']  == 'FS') {
      this.games.FS = this.games.FS.filter(x => x['game_id'] != gameId);
    }
    else if (category['code']  == 'LT') {
      this.games.LT = this.games.LT.filter(x => x['game_id'] != gameId);
    }
    else if (category['code']  == 'CG') {
      this.games.CG = this.games.CG.filter(x => x['game_id'] != gameId);
    }
    else if (category['code']  == 'TB') {
      this.games.TB = this.games.TB.filter(x => x['game_id'] != gameId);
    }
    else if (category['code']  == 'AR') {
      this.games.AR = this.games.AR.filter(x => x['game_id'] != gameId);
    }

    this.cdr.detectChanges()
  }

  onCloseDialog(event?: Event) {
    this.resetExpand = false;
    this.setProvider(this.selectedGameProvider['id'], true);  }

  onDropListDropped(categoryCode: any) {
    if (!this.target) {
      return;
    }

    const placeholderElement: HTMLElement = this.placeholder.element.nativeElement;
    const placeholderParentElement: HTMLElement = placeholderElement.parentElement;

    placeholderElement.style.display = 'none';

    placeholderParentElement.removeChild(placeholderElement);
    placeholderParentElement.appendChild(placeholderElement);
    placeholderParentElement.insertBefore(
      this.source.element.nativeElement,
      placeholderParentElement.children[this.sourceIndex]
    );

    if (this.placeholder._dropListRef.isDragging()) {
      this.placeholder._dropListRef.exit(this.dragRef);
    }

    this.target = null;
    this.source = null;
    this.dragRef = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.getList(categoryCode), this.sourceIndex, this.targetIndex);
    }
  }

  onDropListEntered({ item, container }: CdkDragEnter, categoryCode: any) {
    this.placeholder = [];
    if (categoryCode == 'SL') this.placeholder = this.placeholders.filter(x => x['category_code'] == 'SL')[0];
    else if (categoryCode == 'LC') this.placeholder = this.placeholders.filter(x => x['category_code'] == 'LC')[0];
    else if (categoryCode == 'FS') this.placeholder = this.placeholders.filter(x => x['category_code'] == 'FS')[0];
    else if (categoryCode == 'LT') this.placeholder = this.placeholders.filter(x => x['category_code'] == 'LT')[0];
    else if (categoryCode == 'CG') this.placeholder = this.placeholders.filter(x => x['category_code'] == 'CG')[0];
    else if (categoryCode == 'TB') this.placeholder = this.placeholders.filter(x => x['category_code'] == 'TB')[0];
    else if (categoryCode == 'AR') this.placeholder = this.placeholders.filter(x => x['category_code'] == 'AR')[0];
    if (container == this.placeholder) {
      return;
    }

    const placeholderElement: HTMLElement = this.placeholder.element.nativeElement;
    const sourceElement: HTMLElement = item.dropContainer.element.nativeElement;
    const dropElement: HTMLElement = container.element.nativeElement;
    const dragIndex: number = Array.prototype.indexOf.call(
      dropElement.parentElement.children,
      this.source ? placeholderElement : sourceElement
    );
    const dropIndex: number = Array.prototype.indexOf.call(
      dropElement.parentElement.children,
      dropElement
    );

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = item.dropContainer;

      placeholderElement.style.width = this.boxWidth + 'px';
      placeholderElement.style.height = this.boxHeight + 40 + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = container;
    this.dragRef = item._dragRef;

    placeholderElement.style.display = '';

    dropElement.parentElement.insertBefore(
      placeholderElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement
    );

    this.placeholder._dropListRef.enter(
      item._dragRef,
      item.element.nativeElement.offsetLeft,
      item.element.nativeElement.offsetTop
    );
  }

  backToGameTagPage(){
    var changedObjects = false;
    if (JSON.stringify(this.init_games) !== JSON.stringify(this.games) && this.categoryList.length > 0) {
      changedObjects = true;
    }
    if( changedObjects ==  true ){
      Swal.fire({
        title: '<div class="text-center">This page contains unsaved changes</div>',
        html: '<div class="text-center">' + this.translateService.instant('If you leave this page, changes you made may not be saved') +'</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        reverseButtons: true,
        denyButtonText: this.translateService.instant('Go Back'),
        confirmButtonText: this.translateService.instant('Continue'),
        icon:'warning',
        customClass: {
          denyButton: 'deny-button',
          confirmButton: 'confirm-button',
        }
      }).then(result => {
        if (result.isConfirmed) {
          this.router.navigate(['/game/game-tags']);
        } else if (result.isDenied) {
        }
      });
    }else{
      this.router.navigate(['/game/game-tags']);
    }
  }

  checkupdate(){
    return JSON.stringify(this.init_games) !== JSON.stringify(this.games) ? true : false ;
  }

  resetList() {
    this.games.LC = [];
    this.games.SL = [];
    this.games.FS = [];
    this.games.LT = [];
    this.games.CG = [];
    this.games.TB = [];
    this.games.AR = [];
  }
}
