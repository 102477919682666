import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { smsProviderCredentials, smsProviderCredentialsCurrencySettings } from '@core/models/sms-provider-credentials.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service'
import { ProvidersDataService } from '../../service/providers-data.service';
import { catchError, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Status } from '@core/enums/status.enum';
import { ProviderCredentialsSettingsEditDialogComponent } from '../provider-credential-settings-edit/provider-credentials-settings-edit.component';
import { Observable, Subscription } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Pagination } from '@core/models/pagination.model';
import { AppPermissionService } from '@core/services/app-permission.service';
@Component({
  selector: 'kt-provider-credential-settings',
  templateUrl: './provider-credential-settings.component.html',
  styleUrls: ['./provider-credential-settings.component.scss']
})
export class ProviderCredentialSettingsDialogComponent implements OnInit {
  pagination: Pagination;
	pageSize = 30;
	page = 1;
	maxSize = 5;
	params = "";
	dropdown = {
		status: this.dropdownHttpService.statuses,
		perPage: this.dropdownHttpService.perPage,
	};
	loading = false;
	searchBtnLoading = false;
	clearBtnLoading = false;
	dataLength: number;
	settings$: [];
	code = this.data.credentials.code;
	messages$ = this.providerDataService.messages$;
	status = Status;
	switchOffSmartRoute = false;
	switchOffTransactional = false;
	switchOnTransactional = false;
	// permissions
	canEditProviderCredentialSettings: boolean;

	private subscriptions = new Subscription();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { mode: string; credentials },
		public dialogRef: MatDialogRef<ProviderCredentialsSettingsEditDialogComponent>,
		private providerDataService: ProvidersDataService,
		private dropdownHttpService: DropdownHttpService,
		public dialog: MatDialog,
		private loadingBar: LoadingBarService,
		private appPermissionService: AppPermissionService,
	) {}

	ngOnInit() {
		this.reload();
		
		const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
			this.canEditProviderCredentialSettings = appPermissions.edit_provider_settings;
		  });
	  
		  this.subscriptions.add(apSub);
	}

	onViewPageBy(page = 1, pageSize?: number, params?: string) {
		this.loading = true;
		this.settings$ = [];
		this.loadingBar.start();
		pageSize = this.pageSize;
		params = this.params ? `&${this.params}` : "";
		return this.providerDataService.getSMSProviderSettingWithQuery(this.code, `?page=${page}&perPage=${pageSize}${params}`).pipe(
			tap((res) => {
				this.loading = false;
				this.pagination = this.providerDataService.pagination;
				this.settings$ = res;
				this.dataLength = res.length;
				this.loadingBar.complete();
				this.loading = false;
			})
		);
	}

	onPerPage(size: Event) {
		this.page = 1;
		this.pageSize = +(size.target as HTMLSelectElement).value;
		this.onViewPageBy(this.page, this.pageSize, this.params).subscribe();
	}

	private reload() {
		this.onViewPageBy(this.page).subscribe();
	}

	onChangeStatus(row: any, status: number) {
		this.loading = true;
		let data = {
			id: row.id,
			status: status,
		};
		Object.keys(data).forEach((key) => (data[key] == null || data[key] === "") && delete data[key]);
		this.subscriptions = this.providerDataService
			.updateSmsProviderSetting(row.id, data)
			.pipe(
				tap((res: any) => {
					this.loading = false;
					this.messages$.next([...res.message]);
					this.reload();
				}),
				catchError((error) => {
					throw error;
				})
			)
			.subscribe();
	}

	onToggleSmartRoute(row: any, evt) {
		let smart_route = evt.target.checked;
		if (row.route_id == null && smart_route == 0) {
			Swal.fire("System Message", "Smart Route has been successfully disabled. Please configure the preferred route to restore the SMS Provider functionality", "warning").then((resp) => {
				if (resp.value) {
					this.switchOffSmartRoute = true;
					this.onOpenDialog(this.data.mode, row);
				} else {
					this.switchOffSmartRoute = false;
				}
			});
		} else {
			const data = {
				smart_route: evt.target.checked,
			};
			this.subscriptions = this.providerDataService.updateSmsProviderSetting(row.id, data).subscribe(() => {
        this.messages$ = this.providerDataService.messages$;
        this.reload();
      });
		}
	}

	onToggleShortenURL(row: any, evt) {
		const data = {
			shorten_url: row.shorten_url === 0 ? 1 : 0,
		};
		this.subscriptions = this.providerDataService.updateSmsProviderSetting(row.id, data).subscribe(() => {
      this.messages$ = this.providerDataService.messages$;
      this.reload();
    });
	}

  onToggleTransactional(row: any, evt) {
	let transactional = evt.target.checked;
	if (row.smart_route == 0) {
		row.route_id = null;
		Swal.fire("System Message", "Please configure the preferred route to restore the SMS Provider functionality", "warning").then((resp) => {
			if (resp.value) {
				this.switchOffTransactional = transactional == 0;
				this.switchOnTransactional = transactional == 1;
				this.onOpenDialog(this.data.mode, row);
			} else {
				this.switchOffTransactional = false;
				this.switchOnTransactional = false;
			}
		});
	} else {
		const data = {
			transactional: row.transactional === 0 ? 1 : 0,
		};
		this.subscriptions = this.providerDataService.updateSmsProviderSetting(row.id, data).subscribe(() => 
		{
			this.messages$ = this.providerDataService.messages$;
			this.reload();
		});
	}
	}

	private openDialogBy(componentRef: any, data?: { mode?: string; credentials?: any; setting?: any; routeJustSwitchedOff?: any; transactionalJustSwitchedOff?: any; transactionalJustSwitchedOn?: any}) {
		const dialogRef = this.dialog.open(componentRef, {
			width: "800px",
			data,
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result == true) {
				this.switchOffSmartRoute = false;
				this.switchOffTransactional = false;
				this.switchOnTransactional = false;
				this.onViewPageBy(this.page).subscribe();
			}
		});
	}

	onCloseDialog(value = false) {
		this.dialogRef.close(value);
	}

	onOpenDialog(mode: string, row?: any) {
		if (row) {
			this.providerDataService.getSMSProviderSettingById(row.id).subscribe((res) => {
				if(this.switchOffSmartRoute == true) {
					res = { ...res[0], smart_route: 0 };
				} else if (this.switchOffTransactional == true) {
					res = { ...res[0], transactional: 0, route_id: null};
				} else if (this.switchOnTransactional == true) {
					res = { ...res[0], transactional: 1, route_id: null};
				} else {
					res = res[0];
				}
				this.openDialogBy(ProviderCredentialsSettingsEditDialogComponent, { mode: mode, credentials: this.data.credentials, setting: res, routeJustSwitchedOff: this.switchOffSmartRoute, transactionalJustSwitchedOff: this.switchOffTransactional, transactionalJustSwitchedOn: this.switchOnTransactional});
			});
		}
	}
}
