<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : (data.mode === 'duplicate' ? 'Duplicate': 'Create') }} Referral Commission Settings</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="row">
                    <section class="col card border-0">
                        <div class="col-12 p-0 card rounded-0">
                            <div class="card-header">Basic Information</div>
                            <section class="col-12 p-4">
                                <div class="row border-top border-left">
                                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                        <span class="kt-font-bold">Name <span class="text-danger">*</span> </span>
                                    </div>
                                    <div class="col-md-8 border-right p-3">
                                        <input formControlName="name" type="text" class="form-control">
                                    </div>
                                </div>

                                <div class="row border-top border-left">
                                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                        <span class="kt-font-bold">Currencies <span class="text-danger">*</span></span>
                                    </div>
                                    <div class="col-md-8 border-right p-3">
                                        <kt-dropdown-wo-lazyload class="col-8" 
                                            [form]='form' 
                                            [dropdownList]='currenciesDropdownList' 
                                            [dropdownSettings]='dropdownSettings' 
                                            [formName]="'currencies'" 
                                            [selectionAttributes]="'id'" 
                                            [selectedItems]='currenciesSelectedItems' 
                                            (selectedItemsChanged)="setCurrencyMemberGroup('currency', $event)">
                                        </kt-dropdown-wo-lazyload>
                                    </div>
                                </div>

                                <div class="row border-top border-left">
                                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                        <span class="kt-font-bold">Member Group <span class="text-danger">*</span></span>
                                    </div>
                                    <div class="col-md-8 border-right p-3">
                                        <kt-dropdown-wo-lazyload class="col-8"
                                            [form]='form' 
                                            [dropdownList]='memberGroupsDropdownList' 
                                            [dropdownSettings]='memberGroupDropdownSettings' 
                                            [formName]="'member_groups'" 
                                            [selectionAttributes]="'id'" 
                                            [selectedItems]='memberGroupsSelectedItems' 
                                            (selectedItemsChanged)="setCurrencyMemberGroup('memberGroup', $event)">
                                        </kt-dropdown-wo-lazyload>
                                    </div>
                                </div>

                                <div class="row border-top border-left">
                                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                        <span class="kt-font-bold">Promotion <span class="text-danger">*</span></span>
                                    </div>
                                    <div class="referral-dropdown-container col-md-8 border-right p-3">
                                        <kt-dropdown-wo-lazyload 
                                            [form]='form' 
                                            [dropdownList]='promotionCodeDropdownList' 
                                            [dropdownSettings]='promotionCodeDropdownSettings' 
                                            [formName]="'promotion_id'" 
                                            [selectionAttributes]="'id'" 
                                            [selectedItems]='promotionCodeSelectedItems'>
                                        </kt-dropdown-wo-lazyload>
                                        <div>
                                            <a class="btn btn-brand promotion-btn" href="/general/promotion-codes" routerLinkActive="active" target="_blank" rel="bookmark">3.2 Promotion Codes</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="row border-top border-left border-bottom">
                                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                        <span class="kt-font-bold">Status <span class="text-danger">*</span></span>
                                    </div>
                                    <div class="col-md-8 border-right p-3">
                                        <select formControlName="status" class="form-control" [disabled]="true">
                                            <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                                                {{ value }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                            </section>
                        </div>
                    </section>

                    <section class="col card border-0">
                        <div class="col-12 p-0 card rounded-0">

                            <div class="card-header">Commission Settings</div>
                            <section class="col-12 p-4">

                                <div class="row border-top border-left">
                                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                        <span class="kt-font-bold">Type <span class="text-danger">*</span></span>
                                    </div>
                                    <div class="col-md-8 border-right p-3">
                                        <select formControlName="type" class="form-control">
                                            <option [value]="value.id" *ngFor="let value of dropdown.types">{{ value.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row border-top border-left">
                                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                        <span class="kt-font-bold">Commission Rate <span class="text-danger">*</span> </span>
                                    </div>
                                    <div class="col-md-8 border-right p-3">
                                        <div class="row" formArrayName="level_commission_rates">
                                            <ng-container *ngFor="let row of levelForm.controls; let i = index">
                                                <div class="col">
                                                    Level {{ i + 1 }}
                                                    <input [formControlName]="i" type="number" (wheel)="false" min="1" max="100" class="form-control">
                                                </div>
                                            </ng-container>
                                        </div>
                                        <span>Eg : 1 = 1%</span>
                                    </div>
                                </div>

                                <div class="row border-top border-left">
                                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                        <span class="kt-font-bold">Minimum Limit <span class="text-danger">*</span></span>
                                    </div>
                                    <div class="col-md-8 border-right p-3">
                                        <input formControlName="min_referral_commission" min="0" type="number" (wheel)="false" class="form-control">
                                    </div>
                                </div>

                                <div class="row border-top border-left">
                                    <div class="col-lg-4 col-md-4 card-header border-right p-3">
                                        <span class="kt-font-bold">Maximum Limit <span class="text-danger">*</span></span>
                                    </div>
                                    <div class="col-md-8 border-right p-3">
                                        <input formControlName="max_referral_commission" min="0" type="number" (wheel)="false" class="form-control">
                                    </div>
                                </div>

                            </section>
                        </div>
                    </section>

                </div>
            </div>
            <div class="modal-footer">
                <div class="pl-0 mr-0 col">
                </div>
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>{{ 'Close' }}</button>
                <kt-submit-button *ngIf="(data.mode == 'create' && canCreateReferralCommissionSettings) || (data.mode == 'edit' && canEditReferralCommissionSettings) || (data.mode == 'duplicate' && canDuplicateReferralCommissionSettings)" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.referralCommissionSetting, data.mode)" [text]="data.mode == 'duplicate' ? 'Duplicate': 'Submit'"></kt-submit-button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>