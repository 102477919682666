import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {CanComponentDeactivate} from '../interfaces/can-deactivate.interface';
import {Observable, of} from 'rxjs';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class UnSaveChangesGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor() {
  }
  
  canDeactivate(component: CanComponentDeactivate): any {
    return component.canDeactivate ? component.canDeactivate() : true;
  }

}