import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { Announcement } from '@core/models/announcement.model';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';

@Injectable()
export class AnnouncementDataService extends DefaultDataService<Announcement> {

    pagination: Pagination;
    messages$ = new Subject<any[]>();

    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
        super('Announcement', http, httpUrlGenerator, { root: '' });
    }

    getAll(): Observable<Announcement[]> {
      return this.http.get<ApiResponse>('/announcement').pipe(
        tap(res => this.paginationInit(res)),
        map(res => res.data.rows)
      );
    }

    add(announcement: Announcement): Observable<Announcement>{
      return this.http.post<ApiResponse>(`/announcement`, announcement).pipe(
        tap( res => this.messages$.next(res.message)),
        concatMap((entity) => this.http.get(`/announcement/${entity.data.rows.id}`).pipe(
          map((row: ApiResponse) => row.data.rows)
        ))
      );
    }

    getWithQuery(pageParam: string): Observable<Announcement[]>{
      return this.http.get<ApiResponse>(`/announcement${pageParam}`).pipe(
        tap(res => this.paginationInit(res)),
        map(res => res.data.rows)
      )
    }

    getById(id: number): Observable<Announcement>{
      return this.http.get<ApiResponse>(`/announcement/${id}`).pipe(
        map(res => res.data.rows)
      );
    }

    getAnnouncementLocations(){
      return this.http.get<ApiResponse>(`/announcement/locations`).pipe(
        map(res => res.data.rows)
      )
    }

    private paginationInit(res: any) {
      if(res) {
        this.pagination = res.data.paginations;
      }
    }

}
