import { Component, OnInit, Inject, ViewChildren, QueryList, ElementRef, ChangeDetectorRef} from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IconLibraryDataService } from '../services/icon-library-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { UploadHttpService } from '@core/services/upload-http.service';
import { Subscription, forkJoin } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { IconLibrary } from '@core/models/icon-library.model';

@Component({
  selector: 'kt-icon-library-edit',
  templateUrl: './icon-library-edit.component.html',
  styleUrls: ['./icon-library-edit.component.scss']
})
export class IconLibraryEditDialogComponent implements OnInit {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;

  form: FormGroup;
  dropdown = {
      statuses: this.dropdownHttpService.statuses,
      linkwith: this.dropdownHttpService.linkWith,
      activecontactplatform: this.dropdownHttpService.activeContactPlatforms
  };
  messages$ = this.iconLibraryDataService.messages$;
  loading = false;
  buttonLoading = false;
  refreshStatus: boolean;
  sectionsDropdownSettings = {};
  merchantsDropdownSettings = {};
  contactPlatformsDropdownSettings = {};
  sectionsDropdownList = [];
  merchantsDropdownList = [];
  contactPlatformsDropdownList = [];
  sectionsSelectedItems: any;
  merchantsSelectedItems = [];
  contactPlatformsSelectedItems: any;
  imagePreview = [];
  contact_platform: Boolean;
  custom: Boolean;
  contactExist: Boolean;
  customExist: Boolean;

  private subscription = new Subscription();
  private unsubscribe: Subscription[] = [];

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: { rows: any; mode: string},
      public dialogRef: MatDialogRef<IconLibraryEditDialogComponent>,
      private iconLibraryDataService: IconLibraryDataService,
      private dropdownHttpService: DropdownHttpService,
      private uploadService: UploadHttpService,
      private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.formInit();
    this.dropdownHttpService.footerSection.subscribe(
      res => {
        this.sectionsDropdownList = res.filter(x => x.section_type_code === 'iconQuickLink' );
        if (this.data.rows){
          this.sectionsSelectedItems = res.filter(x => x.id === this.data.rows.section_id );
        }
      }
    );

    this.dropdownHttpService.merchantSites.subscribe(
      res => {
        this.merchantsDropdownList = res;
        this.merchantsSelectedItems = res.filter(x =>  x.id === 1);

      }
    );

    this.dropdownHttpService.activeContactPlatforms.subscribe(
      res => {
        this.contactPlatformsDropdownList = res;
        if (this.data.rows){
          this.contactPlatformsSelectedItems = res.filter(x => x.id === this.data.rows.link_with_contact_id );
        }
      }
    );

    this.sectionsDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      position: 'bottom',
      maxHeight: 150,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.merchantsDropdownSettings = {
      text: 'Please Select',
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      position: 'bottom',
      maxHeight: 150,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.contactPlatformsDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      maxHeight: 150,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(footerIconId: IconLibrary, mode: string) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    if (this.form.value.link_with == 1) {

    this.form.patchValue({
      link_with_contact_id: this.form.value.link_with_contact_id,
      link_with_internal_link: null
    });
    } else if (this.form.value.link_with == 2) {

      this.form.patchValue({
        link_with_contact_id: null,
        link_with_internal_link: this.form.value.link_with_internal_link
      });
    } else if (this.form.value.link_with == 0) {

      this.form.patchValue({
        link_with_contact_id: null,
        link_with_internal_link: null
      });
    }

    const data = {
      id: (footerIconId)? footerIconId.id: null,
      status: this.form.get('status').value,
      ...this.form.value
    };

    switch (mode) {
      case 'edit':
        this.unsubscribe.push(this.iconLibraryDataService.updateFooterIcon(data, data.id).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe());
        this.refreshStatus = true;
      break;
      case 'create':
        this.unsubscribe.push(forkJoin([
          this.iconLibraryDataService.storeFooterIcon(data).pipe(
            tap((res: any) => {
              this.iconLibraryDataService.messages$.next(res.message);
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              throw error;
            })
          ),
        ]).subscribe());
        this.refreshStatus = true;
      break;
    }
  }

  onUploadFile(event: any) {
    this.loading = true;
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'uploads');
    this.uploadService.upload(formData).subscribe(res => {
      this.imagePreview = res;
      this.form.patchValue({
        icon_path: this.imagePreview[0]
      });
      this.form.get('icon_path').markAsDirty();
      this.form.markAllAsTouched();
      this.loading = false;
    });
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  onSelectLinkWith(value, mode) {
    this.contact_platform = false;
    this.custom = false;
    const linkWith = this.form.value.link_with;

    if (linkWith == 1) {
      this.form.controls['link_with_contact_id'].setValidators([Validators.required]);
      this.form.controls['link_with_contact_id'].updateValueAndValidity();
      this.form.controls['link_with_internal_link'].setValidators(null);
      this.form.controls['link_with_internal_link'].updateValueAndValidity();
      this.contact_platform = true;
      this.customExist = false;

    } else if (linkWith == 2) {
      this.form.controls['link_with_contact_id'].setValidators(null);
      this.form.controls['link_with_contact_id'].updateValueAndValidity();
      this.form.controls['link_with_internal_link'].setValidators([Validators.required]);
      this.form.controls['link_with_internal_link'].updateValueAndValidity();
      this.custom = true;
      this.contactExist = false;

    } else if (linkWith == 0) {
      this.form.controls['link_with_contact_id'].setValidators(null);
      this.form.controls['link_with_contact_id'].updateValueAndValidity();
      this.form.controls['link_with_internal_link'].setValidators(null);
      this.form.controls['link_with_internal_link'].updateValueAndValidity();
      this.contact_platform = false;
      this.custom = false;
      this.contactExist = false;
      this.customExist = false;
    }
  }

  private formInit() {
    let name = null;
    let section_id = null;
    let merchant_id = [1];
    let icon_path = this.imagePreview[0];
    let link_with = 0;
    let status = 1;
    let link_with_contact_id = null;
    let link_with_internal_link = null;


    if (this.data.mode === 'edit'){
      name =  this.data.rows.name;
      section_id = this.data.rows.section_id;
      merchant_id = this.data.rows.merchant_id;
      icon_path = icon_path ? icon_path : this.data.rows.icon_path;
      if (this.data.rows.link_with_contact_id) {
        link_with =  1;
        this.contactExist = true;
      } else if (this.data.rows.link_with_internal_link) {
        link_with =  2;
        this.customExist = true;
      } else {
        link_with = link_with;
      }
      link_with_contact_id = link_with_contact_id ? link_with_contact_id : this.data.rows.link_with_contact_id;
      link_with_internal_link = link_with_internal_link ? link_with_internal_link : this.data.rows.link_with_internal_link;
      status = this.data.rows.status;
    }

    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      section_id: new FormControl(section_id, [Validators.required]),
      merchant_id: new FormControl(merchant_id, [Validators.required]),
      icon_path: new FormControl(icon_path, [Validators.required]),
      link_with: new FormControl(link_with, [Validators.required]),
      link_with_contact_id: new FormControl(link_with_contact_id),
      link_with_internal_link: new FormControl(link_with_internal_link),
      status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false }),
    });

    this.form.markAllAsTouched();
    this.form.markAsDirty();
  }
}


