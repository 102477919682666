import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { MaintenanceHours } from '@core/models/maintenance-hours.model';

@Injectable()
export class MaintenanceHoursEntityService extends EntityCollectionServiceBase<MaintenanceHours> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('MaintenanceHours', serviceElementsFactory);
  }
}
