import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AllLeadsDataService } from '../../services/all-leads-data.service';
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';
import Swal from 'sweetalert2';
import { AffiliateSettingsAddDialogComponent } from '../affiliate-settings-add/affiliate-settings-add.component';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  templateUrl: './affiliate-settings.component.html',
  styleUrls: ['./affiliate-settings.component.scss']
})
export class AffiliateSettingsDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  form: FormGroup;

  buttonLoading = false;
  sameSettings = true;

  dropdownList = {
    action: [
      { 'id': 1, name: 'Allow' },
      { 'id': 2, name: 'Block' }
    ],
    affiliates: [
      { 'id': 1, name: 'All Affiliates' },
      { 'id': 2, name: 'Select Affiliates' }
    ],
  }
  allAffiliateList = [];
  selectedAffiliateList = [];
  showHint = false;
  hintText: any;
  leadSettings: any;

  // permissions
  canEditAffiliateSettings: boolean;
  canAddAffiliates: boolean;
  canDeleteAffiliates: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AffiliateSettingsDialogComponent>,
    private allLeadsDataService: AllLeadsDataService,
    private timeZoneDate: TimezoneDatePipe,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();

    this.allLeadsDataService.getLeadSetting().subscribe(res => {
      this.leadSettings = res;
      this.form.patchValue({
        action: res['action'],
        affiliates: res['affiliates'],
      });

      if (res['affiliates'] == 2) {
        this.showHint = true;
        this.onChangeSetting();
      }
    });

    this.refresh();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditAffiliateSettings = appPermissions.edit_affiliate_settings;
      this.canAddAffiliates = appPermissions.add_affiliates;
      this.canDeleteAffiliates = appPermissions.delete_affiliates;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  refresh() {
    this.buttonLoading = true;
    this.allLeadsDataService.getAffiliateLeadSetting().subscribe(res => {
      this.allAffiliateList = res;
      this.selectedAffiliateList = res.map(obj => ({ ...obj }));
      this.buttonLoading = false;
    });
  }

  onChangeSetting() {
    if (this.form.value.action == 1 && this.form.value.affiliates == 2) {
      this.showHint = true;
      this.hintText = 'Only affiliate from the list below will be included in telemarketer leads calculation.';
    }
    else if (this.form.value.action == 2 && this.form.value.affiliates == 2) {
      this.showHint = true;
      this.hintText = 'Only affiliate from the list below will be excluded from telemarketer leads calculation.';
    }
    else {
      this.showHint = false;
      this.hintText = '';
    }

    if (this.form.value.action == this.leadSettings.action && this.form.value.affiliates == this.leadSettings.affiliates) {
      this.sameSettings = true;
    }
    else {
      this.sameSettings = false;
    }
  }

  onSave() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });

    const data = {
      ...this.form.value
    };

    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] < 1) && delete data[key]);
   
    this.subscription = this.allLeadsDataService.createLeadSetting(data).pipe(
      tap(() => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        this.dialogRef.close(true);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
  }

  onSearch(event?) {
    let keyword = event && event.target.value ? event.target.value : null;
    let cloneAllAffiliateList = this.allAffiliateList.map(obj => ({ ...obj }));

    if (keyword !== null) {
      this.selectedAffiliateList = cloneAllAffiliateList.filter(x => x.name.toLowerCase().includes(keyword.toLowerCase()) || x.username.toLowerCase().includes(keyword.toLowerCase()) || x.affiliate_code.toLowerCase().includes(keyword.toLowerCase()));
    }
    else {
      this.selectedAffiliateList = cloneAllAffiliateList;
    }
  }

  onDeleteAll() {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to delete all saved affiliate settings`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.deleteAll();
      }
      this.buttonLoading = false;
    });
  }

  onDelete(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to delete this affiliate setting`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.delete(id);
      }
      this.buttonLoading = false;
    });
  }

  delete(id) {
    this.allLeadsDataService.clearAffiliateLeadSetting(id).pipe(
      tap(() => {
        this.buttonLoading = false;
        this.refresh();
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

  deleteAll() {
    const data = {
      lead_setting_id: this.leadSettings.id
    };

    this.allLeadsDataService.clearAllAffiliateLeadSetting(data).pipe(
      tap(() => {
        this.buttonLoading = false;
        this.refresh();
      }),
      catchError((error) => {
        this.buttonLoading = false;
        throw error;
      })
    ).subscribe();
  }

  onOpenDialog() {
    this.openDialogBy(AffiliateSettingsAddDialogComponent);
  }

  private openDialogBy(componentRef: any) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      height: 'auto',
      data: {
        lead_setting: this.leadSettings
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(res => {
      this.refresh();
    });
  }

  timezoneDate(date: any, format: any) {
    return this.timeZoneDate.transform(date, format);
  }

  private formInit() {
    this.form = new FormGroup({
      action: new FormControl(null),
      affiliates: new FormControl(null),
    });
  }

}
