import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, Subscription, of } from "rxjs";
import { exhaustMap, map, shareReplay, tap } from "rxjs/operators";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { Pagination } from "@core/models/pagination.model";
import { MatDialog } from "@angular/material/dialog";
import { SmsSetting } from "@core/models/sms-setting.model";
import { ProviderSettingsEditDialogComponent } from "../dialogs/provider-settings-edit/provider-settings-edit.component";
import { DropdownHttpService } from "@core/services/dropdown-http.service";
import { Dropdown } from "@core/models/dropdown.model";
import { ProvidersDataService } from "../service/providers-data.service";
import { AppPermissionService } from "@core/services/app-permission.service";

@Component({
  selector: "app-provider-settings",
  templateUrl: "./provider-settings.component.html",
  styleUrls: ["./provider-settings.component.scss"],
})
export class ProviderSettingsComponent implements OnInit, OnDestroy {
  @Input()
  currencies: Dropdown[];

  dropdown = {
    perPage: this.dropdownHttpService.perPage,
  };

  selectedCurrency: "all";
  settings$: Observable<any[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  form: FormGroup;
  loading = false;
  buttonLoading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  params = "";

  // permissions
  canEditSMSProviders: boolean;

  private subscriptions = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private providersDataService: ProvidersDataService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
  ) {}

  ngOnInit(): void {
    this.formInit();
    this.onSubmit(true);

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditSMSProviders = appPermissions.edit_sms_providers;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      currency_id: new FormControl("all"),
    });
  }

  private filterFormFields(formData: any): any {
    const fields = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== "" && formData[key] !== null) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onOpenDialog(type: string, row?: SmsSetting) {
    if (row) {
      this.openDialogBy(ProviderSettingsEditDialogComponent, {
        row,
        mode: type,
        currencies: this.currencies,
      });
    }
  }

  private openDialogBy(
    componentRef: any,
    data?: { row: any; mode?: any; currencies?: any; currencyID?: any }
  ) {
    const dialogRef = this.dialog.open(componentRef, {
      width: "800px",
      data: {
        row: data.row,
        mode: data.mode,
        currencies: data.currencies,
        currencyID: data.row.currency_id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  onViewPageBy(page = 1) {
    this.loading = true;
    const pageSize = this.pageSize;
    const params = this.params ? `&${this.params}` : "";
    this.loadingBar.start();
    return (this.settings$ = this.providersDataService
      .getSettingsWithQuery(`?${params}&perPage=${pageSize}`)
      .pipe(
        shareReplay(), // prevent multiple requests
        tap((res) => {
          this.loading = false;
          this.dataLength = res.length;
          this.pagination = this.providersDataService.pagination;
          this.loadingBar.complete();
        })
      ));
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      currency_id: "all",
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        exhaustMap((data) => {
          const paramsRef = Object.keys(data)
            .map((key) => key + "=" + data[key])
            .join("&");
          this.params = paramsRef;
          this.loadingBar.start();
          return (this.settings$ = this.providersDataService
            .getSettingsWithQuery(`?${this.params}&perPage=${this.pageSize}`)
            .pipe(
              shareReplay(), // prevent multiple requests
              tap((res) => {
                this.loading = false;
                this.clearBtnLoading = false;
                this.searchBtnLoading = false;
                this.dataLength = res.length;
                this.page = 1;
                this.pagination = this.providersDataService.pagination;
                this.loadingBar.complete();
              })
            ));
        })
      )
      .subscribe();
  } 
}
