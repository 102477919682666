import { EventEmitterService } from '@core/services/event-emitter.service';
import { Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdjustWallet } from '@core/models/adjust-wallet.model';
import { Member } from '@core/models/member.model';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MemberHttpService } from '@core/services/member-http.service';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { MemberDataService } from '../../../members/services/member-data.service';
import { MemberEntityService } from '../../../members/services/member-entity.service';

@Component({
  templateUrl: './transaction-wallet.component.html',
  styleUrls: ['./transaction-wallet.component.scss']
})
export class TransactionWalletDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('filterInput') filterInput: QueryList<ElementRef>;
  members$ = this.data.member ? this.data.member : this.memberHttService.getAllDropdown();
  memberDropdown = [];
  member$: Observable<Member>;
  membersSub = new Subscription();
  pagination: Pagination;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  messages$ = this.memberDataService.messages$;
  form: FormGroup;
  dropdown = {
    transactionType: this.dropdownHttpService.transactionType
  };
  dropdownSettings = {};
  selectedItems = [];
  username = '';
  currentWallet = 0;
  pageNumber = 1;
  params = '';
  lastPage = null;
  refreshStatus: boolean;

  buttonLoading = false;
  checkValidation = false;
  bankaccountDropdownSettings = {};
  bankaccountDropdownList = [];
  bankaccountSelectedItems: any;
  bankaccountLoading = false;

  constructor(
    public dialogRef: MatDialogRef<TransactionWalletDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { member?: Member },
    private memberHttService: MemberHttpService,
    private memberDataService: MemberDataService,
    private memberService: MemberEntityService,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private merchantBankHttpService: MerchantBankHttpService,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit() {
    this.formInit();
    if (this.data.member) {
      this.currentWallet = this.data.member.balance;
    }

    if (this.data.member) {
      const member = this.data.member;
      this.selectedItems.push({
        ...member,
        labelKey: member.id + ' - ' + member.username.toUpperCase() + ' - ' + member.name.toUpperCase()
      });
      this.setBankDropdown(this.data.member.settings_currency_id);
    }

    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'labelKey',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      disabled: this.data.member ? true : false,
      showCheckbox: false
    };

    this.bankaccountDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'name',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(memberId: number, data: AdjustWallet) {
    this.checkValidation = true;
    if (this.form.valid) {
      // this.buttonLoading = true;
      // To set "Save" button to disable (To prevent call API in multiple times when double click)
      this.form.setErrors({ 'invalid': true });
      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
      if (memberId) {
        this.subscription = this.transactionHttpService.adjustWallet(memberId, data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
            this.checkValidation = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.checkValidation = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
      }
    }
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onSelectMember(event?: any) {
    this.currentWallet = event.length > 0 ? event[0].balance : 0;
    if (event.length > 0) {
      this.setBankDropdown(event[0].currency_id);
    } else {
      this.bankaccountDropdownList = [];
    }
  }

  private setBankDropdown(currencyId: number) {
    this.bankaccountLoading = true;
    this.merchantBankHttpService.getMerchantBanksAccount(`&status=1&currency_id=${currencyId}`).subscribe(
      res => {
        res.map(elm => {
          elm.name = elm.bank_code + ' - ' + elm.account_name + (elm.account_number == null ? '' : ' - ' + elm.account_number);
        });
        this.bankaccountDropdownList = res;
        this.bankaccountLoading = false;
      }
    );
  }

  private formInit() {
    const memberID = this.data.member ? this.data.member.id : null;
    this.form = new FormGroup({
      id: new FormControl(memberID, [Validators.required]),
      amount: new FormControl(null, [Validators.required, Validators.pattern('^-?(?=.*[1-9])[0-9]+(\.[0-9]+)?$'), Validators.min(0)]),
      transaction_type_id: new FormControl(null, [Validators.required]),
      remarks: new FormControl(null, [Validators.required]),
      merchant_bank: new FormControl(null)
    });

    this.form.get('transaction_type_id').valueChanges.subscribe((data) => {
      this.onUpdateBank(data);
    });
  }

  private onUpdateBank(value: any){
    if (+value === 9 || +value === 12){ //bonus and rebate
      this.form.patchValue({
        merchant_bank: null
      });
      this.eventEmitterService.onClearMerchantAccountSearch();
    }

    if (+value === 9 || +value === 12 || +value === 11){ // bonus and rebate and normal
      this.form.get('amount').setValidators([Validators.required, Validators.pattern('^-?(?=.*[1-9])[0-9]+(\.[0-9]+)?$')]);
      this.form.get('amount').updateValueAndValidity();
    }else{
      this.form.get('amount').setValidators([Validators.required, Validators.pattern('^-?(?=.*[1-9])[0-9]+(\.[0-9]+)?$'), Validators.min(0)]);
      this.form.get('amount').updateValueAndValidity();
    }
  }
}
