import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GameCategory } from '@core/models/game-category.model';
import { Rebate } from '@core/models/rebate.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, debounceTime, tap } from 'rxjs/operators';
import { RebateDataService } from '../../../rebates/services/rebate-data.service';

@Component({
  selector: 'kt-rebate-setting-details',
  templateUrl: './rebate-setting-details.component.html',
  styleUrls: ['./rebate-setting-details.component.scss']
})
export class RebateSettingDetailsDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  form: FormGroup;
  refreshStatus: boolean;
  messages$ = this.rebateDataService.messages$;
  private subscription = new Subscription();

  buttonLoading = false;

  dropdown = {
    memberGroups: this.dropdownHttpService.groups,
    gameProviders: this.gameProviderHttpService.getGameProviders((this.data.rebate.member_groups).map(res => res.category_id)),
    gameCategories: this.dropdownHttpService.gameCategories,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
  }

  memberGroupsDropdownList = [];
  memberGroupsSelectedItems = [];

  gameProvidersDropdownList = [];
  gameProvidersDropdownLoading = false;
  gameProvidersSelectedItems = [];

  categoriesDropdownList  = [];
  categoriesSelectedItems = [];

  currenciesSelectedItems = [];

  dropdownSettings = {
    singleSelection: false,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 'auto',
    primaryKey: 'id',
    labelKey: 'name',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    disabled: true
  };

  gameProviderDropdownSettings = {
    singleSelection: false,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 'auto',
    primaryKey: 'id',
    labelKey: 'labelKey',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    disabled: true
  };

  blacklistedGameProvidersData = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { rebate: Rebate ,mode: string},
    private rebateDataService: RebateDataService,
    public dialogRef: MatDialogRef<RebateSettingDetailsDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private gameProviderHttpService: GameProviderHttpService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
      });
    }
    this.formInit();
  }

  ngAfterViewInit() {
    forkJoin(this.dropdown).subscribe((res: any) => {
      this.memberGroupsDropdownList = res.memberGroups;
      this.gameProvidersDropdownList = res.gameProviders.map((res: any) => {
        return {
          id: res.id,
          code: res.code,
          name: res.name,
          labelKey: res.code + " - " + res.name
        }
      });
      this.categoriesDropdownList = res.gameCategories;

      // Pre-selected currencies
      this.dropdown.currencies.forEach(item => { this.data.rebate.member_groups.forEach(r => { if(+r.settings_currency_id === +item.id) { this.currenciesSelectedItems.push(item); }}); });
      this.currenciesSelectedItems = Array.from(new Set(this.currenciesSelectedItems));

      // Pre-selected categories
      this.categoriesDropdownList.forEach(item => { this.data.rebate.member_groups.forEach(r => { if(+r.category_id === +item.id) { this.categoriesSelectedItems.push(item); }}); });
      this.categoriesSelectedItems = Array.from(new Set(this.categoriesSelectedItems));

      // Pre-selected gameProviders
      this.gameProvidersDropdownList.forEach(item => { this.data.rebate.member_groups.forEach(r => { if(+r.game_provider_id === +item.id) { this.gameProvidersSelectedItems.push(item); }}); });
      this.gameProvidersSelectedItems = Array.from(new Set(this.gameProvidersSelectedItems));
      this.onBlacklistedGameProviders();

      // Pre-selected memberGroups
      this.memberGroupsDropdownList.forEach(item => { this.data.rebate.member_groups.forEach(r => { if(+r.member_group_id === +item.id) { this.memberGroupsSelectedItems.push(item); }}); });
      this.memberGroupsSelectedItems = Array.from(new Set(this.memberGroupsSelectedItems));

      this.form.patchValue({
        currencies: this.currenciesSelectedItems.map(item => item.id),
        categories: this.categoriesSelectedItems.map(item => item.id),
        member_groups: this.memberGroupsSelectedItems.map(item => item.id),
        game_providers: this.gameProvidersSelectedItems.map(item => item.id)
      });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  getSelectedCategories(selections: GameCategory[]) {

    // Reset
    this.gameProvidersDropdownList = [];
    this.gameProvidersDropdownLoading = true;
    this.subscription.unsubscribe();

    if(selections && selections.length <= 0) {
      this.gameProvidersSelectedItems = [];
      this.form.patchValue({
        game_providers: null
      })
    }

    if(selections !== null && selections.length >= 1) {
      this.categoriesSelectedItems = selections;
      const categoriesParam = selections.map(item => item.id);
      if(categoriesParam && categoriesParam.length >= 1) {
        setTimeout(() => {
          this.subscription = this.gameProviderHttpService.getGameProviders(categoriesParam)
          .pipe(
            debounceTime(2000)
          )
          .subscribe((res: any) => {
            this.gameProvidersDropdownList = res.map((item: any) => {
              return {
                id: item.id,
                code: item.code,
                name: item.name,
                labelKey: item.code + " - " + item.name
              };
            });
            this.gameProvidersDropdownLoading = false;
          });
        }, 1500);
      }
    }
  }

  onBlacklistedGameProviders() {
    let data = [];
    this.gameProvidersSelectedItems.map( res => {
      let list;
      let gameProviders;
      let params;
      if (this.data.rebate.blacklist_sub_categories.find( x => x['game_provider_id'] === res.id) !== undefined){
        list = this.data.rebate.blacklist_sub_categories.find( x => x['game_provider_id'] === res.id);
        gameProviders = this.gameProvidersDropdownList.find( x => x['code'] === list.game_provider_code);
        params = {
          gameProviders_name: gameProviders.name,
          sub_category_name: list['sub_category_name'].toString()
        };
        data.push(params)
      }
    });
    this.blacklistedGameProvidersData = data;
  }

  private formInit() {
    let name = null;
    let min = null;
    let max = null;
    let percentage = null;
    let stat = 1;
    let memberGroups = null;
    let gameProviders = null;
    let categories = null;
    let currencies = null;

    name = this.data.rebate.setting.name;
    min = this.data.rebate.setting.min_rebate_limit;
    max = this.data.rebate.setting.max_rebate_limit;
    percentage = this.data.rebate.setting.percentage;
    stat = this.data.rebate.setting.status;

    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      min_rebate_limit: new FormControl(min, [Validators.required]),
      max_rebate_limit: new FormControl(max, [Validators.required]),
      percentage: new FormControl(percentage, [Validators.required]),
      status: new FormControl(stat),
      member_groups: new FormControl(memberGroups),
      game_providers: new FormControl(gameProviders),
      categories: new FormControl(categories),
      currencies: new FormControl(currencies),
    });
  }

}
