<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ this.data.leadJustification ? 'Review Justification' : 'Remark History'}}
          </h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div *ngIf="(remark$ | async) as remark">
            <!-- Lead Info -->
            <ng-container *ngIf="this.data.leadJustification != undefined">
              <section class="col-lg-12 card border-0 p-0 pb-3">
                <div class="col-12 p-0 card rounded-0">
                    <div class="card-header">
                        Lead Info
                    </div>
                    <section class="col-12 p-4">
                        <div class="row border-top border-left">
                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                <span class="kt-font-bold">ID</span>
                            </div>
                            <div class="col-md-3 border-right p-3">
                                <span>{{ remark.info.id }}</span>
                            </div>
                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                <span class="kt-font-bold">Created At</span>
                            </div>
                            <div class="col-md-3 border-right p-3">
                                <span>{{ remark.info.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }} </span>
                            </div>
                        </div>
                        <div class="row border-top border-left">
                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                <span class="kt-font-bold">Initial Lead Type</span>
                            </div>
                            <div class="col-md-3 border-right p-3">
                                <span>{{ remark.info.initial_lead_type }}</span>
                            </div>
                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                <span class="kt-font-bold">Current Status</span>
                            </div>
                            <div class="col-md-3 border-right p-3">
                                <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + remark.info.status"> {{ remark.info.status_name ? remark.info.status_name : '-' }}</span>
                            </div>
                        </div>
                        <div class="row border-top border-left">
                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                <span class="kt-font-bold">Name</span>
                            </div>
                            <div class="col-md-3 border-right p-3">
                                <span>{{ remark.info.name }}</span>
                            </div>
                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                <span class="kt-font-bold">Username</span>
                            </div>
                            <div class="col-md-3 border-right p-3">
                                <span>{{ remark.info.username ? remark.info.username : '-' }} </span>
                            </div>
                        </div>
                        <div class="row border-top border-left border-bottom">
                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                <span class="kt-font-bold">Currency</span>
                            </div>
                            <div class="col-md-3 border-right p-3">
                                <span>{{ remark.info.currency_code }}</span>
                            </div>
                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                <span class="kt-font-bold">Source Type</span>
                            </div>
                            <div class="col-md-3 border-right p-3">
                                <span>{{ remark.info.source_type }} </span>
                            </div>
                        </div>   
                    </section>
                </div>
              </section>
            </ng-container>
            
            <!-- Justification -->
            <div class="col-12 p-0 card rounded-0 mb-4" *ngIf="canEditLeadJustification && this.data.leadJustification">
              <form class="kt-form w-webkit-fill-available" [formGroup]="form">
                  <div class="card-header">
                  Justification
                  </div>
                  <section class="col-12 p-4">
                      <div class="row border-left">
                          <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-top border-right border-bottom p-3">
                              <span class="kt-font-bold">Status <span class="text-danger">*</span></span>
                          </div>
                          <div class="input-group col-lg-9 col-md-9 col-sm-6 col-xs-12 align-items-center border-top border-right border-bottom p-3">
                              <select formControlName="status" class="form-control">
                                <option [value]="null" [disabled]="true">Please Select</option>
                                <option [value]="value.id" *ngFor="let value of statusDropdownList">
                                    {{ value.name }}
                                </option>
                            </select>
                          </div>
                      </div>
                      <div class="row border-left">
                        <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-top border-right border-bottom p-3">
                            <span class="kt-font-bold">Remark <span class="text-danger">*</span></span>
                        </div>
                        <div class="input-group col-lg-9 col-md-9 col-sm-6 col-xs-12 align-items-center border-top border-right border-bottom p-3">
                            <input type="text" formControlName="remarks" class="form-control">
                        </div>
                    </div>
                  </section>
              </form>
            </div>

            <!-- Remark History -->
            <section class="col-lg-12 card border-0 p-0 pb-3">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  {{ this.data.leadJustification ? 'Remark History Timeline' : 'Timeline' }}
                </div>
                <div class="row ml-1" *ngFor="let row of remark.rows">
                  <div class="col-1 p-3">
                    {{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }}
                  </div>
                  <div class="col-11 p-3">
                    <!-- The Timeline -->
                    <div class="timeline">
                      <div class="preview-status" [matTooltip]="row.status_name">&nbsp;</div>
                      <!-- Item 1 -->
                      <div>
                        <div class="direction-r">
                          <div class="flag-wrapper">
                            <span class="flag" [ngClass]="'border-color--' + row.status">
                              <section class="col-12 p-3 inner-box-timeline">
                                <div class="row border-top border-left">
                                  <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                    <span class="kt-font-bold">Remark</span>
                                  </div>
                                  <div class="col-md-9 border-right p-3">
                                    <span>{{ row.value }}</span>
                                  </div>
                                </div>

                                <div class="row border-top border-left border-bottom">
                                  <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                    <span class="kt-font-bold">Attachment</span>
                                  </div>
                                  <div class="col-md-3 border-right p-3 attachmentIcon">
                                    <span class="text-right"><i class="fa fa-file" (click)="onOpenDialog(row.remark_asset)" *ngIf="row.remark_asset.length > 0"></i></span>
                                  </div>

                                  <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                    <span class="kt-font-bold">Created By</span>
                                  </div>
                                  <div class="col-md-3 border-right p-3">
                                    <span class="text-right">{{ row.created_by ? row.created_by : '-' }}<br>{{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }} </span>
                                  </div>
                                </div>
                              </section>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog($event)"><i class="fas fa-ban"></i>Close</button>
          <button *ngIf="canEditLeadJustification && this.data.leadJustification" class="btn btn-primary btn-icon-sm mr-2" (click)="onSave(data.leadID)" type="button" [disabled]="buttonLoading || !form.valid "><i [class]="buttonLoading ? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Submit</button>
        </div>
      </div>
  </div>