import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { TelemarketerReport } from '@core/models/telemarketer-report.model';

@Injectable()
export class TelemarketerReportEntityService extends EntityCollectionServiceBase<TelemarketerReport> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('TelemarketerReport', serviceElementsFactory);
    }
}
