import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Announcement } from '@core/models/announcement.model';

@Injectable()
export class AnnouncementEntityService extends EntityCollectionServiceBase<Announcement> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Announcement', serviceElementsFactory);
    }
}

