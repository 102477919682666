import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Member } from '@core/models/member.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class MemberHttpService {

  constructor(private http: HttpClient) { }

  resetPassword(member: Member, form: FormGroup) {
    return this.http.post<ApiResponse>(`/member/${member.id}/password`, form.value);
  }

  getAllDropdown(): Observable<Member[]> {
    return this.http.get<ApiResponse>(`/member`).pipe(
      map(res => {
        Object.keys(res.data.rows).forEach(key => {
          res.data.rows[key].custom = res.data.rows[key].id + ' - ' + res.data.rows[key].username.toUpperCase() + ' - ' + res.data.rows[key].name.toUpperCase();
        })
        return res.data.rows}
    ));
  }

}
