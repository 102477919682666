// Service
import { DownloadAreaDataService } from "./services/download-area-data.service";
// Angular / Core
import { FormGroup, FormControl } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { LoadingBarService } from "@ngx-loading-bar/core";
// Model
import { Pagination } from "@core/models/pagination.model";
// Service
import { DownloadHttpService } from "@core/services/download-http.service";
import { DropdownHttpService } from "@core/services/dropdown-http.service";
// Third Party
import { tap, map } from "rxjs/operators";
import { of } from "rxjs";


@Component({
  selector: "kt-registration-report",
  templateUrl: "./download-area.component.html",
  styleUrls: ["./download-area.component.scss"],
})
export class DownloadAreaComponent implements OnInit {
  form: FormGroup;

  statuses = [
    "Pending",
    "Ready",
    "Running",
    "Failed"
  ];

  reports = [];
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };

  constructor(
    private loadingBar: LoadingBarService,
    private downloadAreaDataService: DownloadAreaDataService,
    private downloadHttpService: DownloadHttpService,
    private dropdownHttpService: DropdownHttpService
  ) { }

  ngOnInit() {
    this.formInit();
    this.onSubmit(true);
    this.updateDownloadCount();
    this.pagination = this.downloadAreaDataService.pagination;
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.reports = [];
    this.loading = true;
    this.loadingBar.start();
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        tap((data: any) => {
          this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
          const parameters = this.params ? `&${this.params}` : '';
  
          this.downloadAreaDataService.getDownload(`perPage=${this.pageSize}${parameters}`).pipe(
            tap(res => {
              this.reports = res;
              this.loading = false;
              this.clearBtnLoading = false;
              this.searchBtnLoading = false;
              this.page = 1;
              this.pagination = this.downloadAreaDataService.pagination;
              this.loadingBar.complete();
            })).subscribe();
        })
      )
      .subscribe();
  }

  onDownload(url: string) {
    if (url !== undefined && url !== null && url !== '') {
      this.downloadHttpService.downloadFileByUrl(url);
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      title: "",
      status: null
    });
    this.onSubmit(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loading = true;
    this.reports = [];
    this.loadingBar.start();
    this.downloadAreaDataService.getDownload(`page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.reports = res;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.pagination = this.downloadAreaDataService.pagination;
        this.loading = false;
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private formInit() {
    this.form = new FormGroup({
      title: new FormControl(null),
      status: new FormControl()
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && formData[key] !== 'null') {
          fields[key] = formData[key];
      }
    });
    return fields;
  }

  updateDownloadCount() {
      this.downloadHttpService.updateDownloadCount().subscribe();
  }

}
