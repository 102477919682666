import { Component, OnInit, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { MarketingReportDataService } from '../../services/marketing-report-data.service';
import { MarketingReportDetails } from '@core/models/marketing-report-details.model';
import { MarketingReport } from '@core/models/marketing-report.model';
import { Pagination } from '@core/models/pagination.model';
@Component({
  selector: 'kt-report-details',
  templateUrl: './marketing-report-details.component.html',
  styleUrls: ['./marketing-report-details.component.scss']
})
export class MarketingReportDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

  marketingReportDetails$: Observable<MarketingReportDetails>;
  datetime = {
    start: moment(this.data.form.start_date_time).tz(this.data.form.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
    end: moment(this.data.form.end_date_time).tz(this.data.form.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')
  };
  params = `currency_id=${this.data.form.currency_id}&start_date_time=${this.datetime.start}&end_date_time=${this.datetime.end}&campaign_code=${this.data.marketingReport.campaign_code}`;

  pageSize = 15;
  page = 1;
  maxSize = 5;
  pagination: Pagination;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {marketingReport: MarketingReport, form: any },
    public dialogRef: MatDialogRef<MarketingReportDetailsComponent>,
    public marketingReportDataService: MarketingReportDataService,
    private loadingBar: LoadingBarService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.onViewPageBy();
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  sanitizeNumericField(previousValue: any) {
    const value = String(previousValue).replace(/[,]+/, '');
    return value;
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.marketingReportDetails$ = this.marketingReportDataService.getDetails(`&page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.marketingReportDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }
}
