export enum PromotionTransferStatus {
  Pending = 0,
  Active,
  Cancelled,
  Completed,
  Error,
  Expired,
  Fatal,
  Rejected,
  "Low Balance",
  "Low Balance 2",
}