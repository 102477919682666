import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { WhatsappBot } from '@core/models/whatsapp-bot.model';

@Injectable()
export class WhatsappBotEntityService extends EntityCollectionServiceBase<WhatsappBot> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('WhatsappBot', serviceElementsFactory);
    }
}

