import { AuthHttpService } from '@core/services/auth-http.service';
import { exhaustMap } from 'rxjs/operators';
// Service
import { DropdownHttpService } from "@core/services/dropdown-http.service";
import { EventEmitterService } from "@core/services/event-emitter.service";
import { TransactionHttpService } from "@core/services/transaction-http.service";
import { RegistrationReportDataService } from "./services/registration-report-data.service";
// Model
import { Pagination } from "@core/models/pagination.model";
import { RegistrationReport } from "@core/models/registration-report.model";
// Angular / Core
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { LoadingBarService } from "@ngx-loading-bar/core";
// Third Party
import { MatDialog } from "@angular/material/dialog";
import * as moment from 'moment-timezone';
import { Observable, of, Subscription, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { MemberInformationDialogComponent } from "../../general/members/dialogs/member-information/member-information.component";
import { MemberDataService } from "../../general/members/services/member-data.service";
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';
@Component({
  selector: "kt-registration-report",
  templateUrl: "./registration-report.component.html",
  styleUrls: ["./registration-report.component.scss"],
})
export class RegistrationReportComponent implements OnInit, OnDestroy {
  form: FormGroup;

  pageSize = 30;
  page = 1;
  maxSize = 5;
  pagination: Pagination;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  from = this.transactionHttpService.getThisMonth().from;
  to = this.transactionHttpService.getThisMonth().to;
  timezoneDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown clear-all-disabled',
    primaryKey: 'offset',
    labelKey: 'offset',
    noDataLabel: '',
    showCheckbox: false
  };
  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones')),
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    sourceTypes: this.dropdownHttpService.sourceTypes,
    perPage: this.dropdownHttpService.perPage,
  };
  agentNameDropdownSettings = {};
  agentUsernameDropdownSettings = {};

  registrationReport$: Observable<RegistrationReport[]>;
  membersPaginated$ = [];
  ranges: any;
  params = ``;

  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;

  button_loading = false;
  messages$ = this.registrationReportDataService.messages$;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  default_sort_by = 'registration_date';
  default_sort_order = 'desc';

  sortingConfig = {
    'member_account_id': 'desc',
    'username': 'desc',
    'currency': 'desc',
    'source_type': 'desc',
    'source_account': 'desc',
    'campaign': 'desc',
    'registration_date': 'desc',
    'registration_site': 'desc',
    'registration_ip': 'desc',
    'clickid': 'desc',
    'ext_campaign': 'desc',
    'ftd_id': 'desc',
    'ftd_date': 'desc',
    'ftd_amount': 'desc'
  };
  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order,
  };
  dataLength: number;
  access$ = this.authHttpService.getUserAccess(5, 'Registration Report');

  // permissions
  canViewMemberDialog: boolean;
  canExportRegistrationReport: boolean;

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private registrationReportDataService: RegistrationReportDataService,
    private loadingBar: LoadingBarService,
    private transactionHttpService: TransactionHttpService,
    private memberDataService: MemberDataService,
    public dialog: MatDialog,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private timeZoneDate: TimezoneDatePipe,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.pagination = this.registrationReportDataService.pagination;
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.selectedTimezone = this.timezone;

    this.agentNameDropdownSettings = {
      singleSelection: true,
      text: "Please Select",
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: "dropdown",
      primaryKey: "name",
      labelKey: "name",
      lazyLoading: true,
      noDataLabel: "",
      showCheckbox: false
    };
    this.agentUsernameDropdownSettings = {
      singleSelection: true,
      text: "Please Select",
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: "dropdown",
      primaryKey: "username",
      labelKey: "username",
      lazyLoading: true,
      noDataLabel: "",
      showCheckbox: false
    };
    this.ranges = this.transactionHttpService.ranges;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.registration_report_view_member_dialog;
      this.canExportRegistrationReport = appPermissions.export_registration_report;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];

    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : "";
    this.loadingBar.start();
    return (this.registrationReport$ = this.registrationReportDataService
      .getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`)
      .pipe(
        tap((res) => {
          this.dataLength = res.length;
          this.pagination = this.registrationReportDataService.pagination;
          this.loadingBar.complete();
          this.onSearch = true;
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
        }),
        catchError((err) => {
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.loadingBar.complete();
          return throwError(err);
        })
      ));
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClearDate() {
    if (this.form.value.start_date_time !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        tap((data) => {
          if (data['start_date_time']) {
            data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
            data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          }
          delete (data['timezone']);

          this.params = Object.keys(data)
            .map((key) => key + "=" + data[key])
            .join("&");
          this.sortingConfig[this.sortingSelection.sort_by] = 'desc';
          this.sortingSelection.sort_by = this.default_sort_by;
          this.sortingSelection.sort_order = this.default_sort_order;
          this.sortingConfig[this.default_sort_by] = this.default_sort_order;
          this.onViewPageBy();
        })
      )
      .subscribe();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch(); // Cannot clear selected Agent without this
    this.form.patchValue({
      username: null,
      currency_id: 'all',
      source_type: null,
      source_account: null,
      campaign_name: null,
      campaign_code: null,
      timezone: this.timezone,
      start_date_time: this.from,
      end_date_time: this.to,
      defaultDate: {
        startDate: this.from,
        endDate: this.to
      }, // Do not remove: For Clearing The Range
      registration_site: null,
      clickid: null,
    });
    this.form.controls.source_account.disable();
    this.page = 1;
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.onSubmit(true);
  }

  onExport() {
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        data['paginate'] = false;

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        this.sortingConfig[this.sortingSelection.sort_by] = 'desc';
        this.sortingSelection.sort_by = this.default_sort_by;
        this.sortingSelection.sort_order = this.default_sort_order;
        this.sortingConfig[this.default_sort_by] = this.default_sort_order;
        return this.registrationReportDataService.exportReport(`?${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.messages$ = this.registrationReportDataService.messages$;
            this.loadingBar.complete();
            this.button_loading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.button_loading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      currency_id: new FormControl('all'),
      source_type: new FormControl(null),
      source_account: new FormControl({value: null, disabled: true}),
      campaign_name: new FormControl(null),
      campaign_code: new FormControl(null),
      timezone: new FormControl(this.timezone),
      start_date_time: new FormControl(this.from),
      end_date_time: new FormControl(this.to),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      }), // Do not remove: For Clearing The Range
      registration_site: new FormControl(null),
      clickid: new FormControl(null),
    });
  }

  sanitizeNumericField(previousValue: any) {
    const value = String(previousValue).replace(/[,]+/, "");
    return value;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach((key) => {
      if (
        formData[key] !== "" &&
        formData[key] !== null &&
        formData[key] !== undefined &&
        key !== "defaultDate"
      ) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onOpenDialog(memberId?: number) {
    if (!this.canViewMemberDialog) {
      Swal.fire({
        icon: 'error',
        title: 'Permission Error',
        text: 'You do not have permission to view member information',
      });
      return;
    }

    const member = this.memberDataService.getById(memberId, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.form.value.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.form.value.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
    this.subscription = member.pipe(
      tap((res) => {
        if (res) {
          this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' });
        }
      })
    ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any, isAdmin?: boolean }) {
    data['member']['timezone'] = this.form.value.timezone;
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'member-information' ? '1500px' : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data,
      autoFocus: data.mode === 'member-information' ? false : true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }

  timezoneDateWithTimezone(date: any, timezone: any, format: any) {
    return this.timeZoneDate.transformWithTimezone(date, timezone, format);
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  onSelectSourceType(event: Event) {
    let selectedSourceType = (event.target as HTMLInputElement).value;
    if (selectedSourceType !== null && selectedSourceType !== 'null' && +selectedSourceType != 3) {
      this.form.controls.source_account.enable();
    } else {
      this.form.patchValue({source_account: null});
      this.form.controls.source_account.disable();
    }
  }
}
