import { Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AllLeadsDataService } from '../../services/all-leads-data.service';

@Component({
  templateUrl: './remark.component.html',
  styleUrls: ['./remark.component.scss']
})
export class RemarkDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  form: FormGroup;
  private subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  messages$ = this.AllLeadsDataService.messages$;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { leadID: number, leadHistoryID: number, status},
    public dialogRef: MatDialogRef<RemarkDialogComponent>,
    private AllLeadsDataService: AllLeadsDataService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog() {
    this.dialogRef.close(true);
  }

  onSubmit() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });

    const formData = {
      ...this.form.value
    };
    Object.keys(formData).forEach((key) => (formData[key] == null || formData[key] === '') && delete formData[key]);
    this.subscription = this.AllLeadsDataService.updateRemark(formData, this.data.leadID).pipe(
      tap((res) => {
        localStorage.setItem('latest_remark_update', JSON.stringify(res));
        this.buttonLoading = false;
        this.form.setErrors(null);
        this.dialogRef.close(true);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    if (this.data.leadHistoryID != undefined) {
      this.form = new FormGroup({
        remarks: new FormControl(null, [Validators.required]),
        lead_call_history_id: new FormControl(this.data.leadHistoryID),
        status: new FormControl(this.data.status),
      });
    } else {
      this.form = new FormGroup({
        remarks: new FormControl(null, [Validators.required]),
        status: new FormControl(this.data.status),
      });
    }
  }

}
