<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog d-block">
    <form class="kt-form" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode | titlecase }} Recovery Promotion</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body d-flex align-items-start justify-content-center w-100">
                <!-- Basic Information -->
                <section class="card w-50 mr-2">
                    <div class="card-header">Basic Information</div>
                    <div class="card-body">
                        <!-- Name -->
                        <div class="row border">
                            <div class="col-4 card-header p-3 border-bottom-0 border-right">
                                <span class="kt-font-bold">Name <span class="text-danger">*</span></span>
                            </div>
                            <div class="col-8 p-3">
                                <input type="text" class="col-lg-12 form-control" formControlName="name" #focusfield />
                            </div>
                        </div>
                        <!-- Description -->
                        <div class="row border border-top-0">
                            <div class="col-4 card-header p-3 border-bottom-0 border-right">
                                <span class="kt-font-bold">Description </span>
                            </div>
                            <div class="col-8 p-3">
                                <input type="text" class="col-lg-12 form-control" formControlName="description" />
                            </div>
                        </div>
                        <!-- Inactivity Criteria -->
                        <div class="row border border-top-0">
                            <div class="col-4 card-header p-3 border-bottom-0 border-right">
                                <span class="kt-font-bold">Inactivity Criteria <span class="text-danger">*</span></span>
                            </div>
                            <div class="col-8 p-3">
                                <select formControlName="depositor" class="form-control mb-3">
                                    <option [value]="null" [disabled]="true">Please Select</option>
                                    <option value=0>Non-Depositor</option>
                                    <option value=1>Depositor</option>
                                </select>

                                <div class="input-group-custom">
                                    <div class="group">
                                        <input type="number" formControlName="inactivity" class="form-control left" min="0" onkeypress="return event.charCode >= 48">
                                        <span class="label right">Days</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Status -->
                        <div class="row border border-top-0">
                            <div class="col-4 card-header p-3 border-bottom-0 border-right">
                                <span class="kt-font-bold">Status <span class="text-danger">*</span></span>
                            </div>
                            <div class="col-8 p-3">
                                <select class="form-control" formControlName="status">
                                    <ng-container *ngFor="let value of dropdown.statuses; let i = index;">
                                        <option [value]="i">{{ value }}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                    </div>
                </section>

                <!-- Recovery Settings -->
                <section class="card w-50 ml-2">
                    <div class="card-header">Recovery Settings</div>
                    <div class="card-body">
                        <!-- Currencies -->
                        <div class="row border">
                            <div class="col-4 card-header p-3 border-bottom-0 border-right">
                                <span class="kt-font-bold">Currencies <span class="text-danger">*</span></span>
                            </div>
                            <div class="col-8 p-3">
                                <kt-dropdown-wo-lazyload #currencySelect [form]="form" [dropdownList]="dropdown.currencies" [dropdownSettings]="currencyDropdownSettings" [formName]="'currency'" [selectionAttributes]="'id'" [selectedItems]="currencySelectedItems" (click)="onChangeCurrency(currencySelect.selectedItems)" (deSelectAll)="onChangeCurrency([])"> </kt-dropdown-wo-lazyload>
                            </div>
                        </div>
                        <!-- Promotion -->
                        <div class="row border border-top-0">
                            <div class="col-4 card-header p-3 border-bottom-0 border-right">
                                <span class="kt-font-bold">Promotion <span class="text-danger" *ngIf="promotionRequired">*</span></span>
                            </div>
                            <div class="col-4 py-3 pl-3 pr-0">
                                <div class="referral-dropdown">
                                    <kt-dropdown-wo-lazyload #promotionSelect [form]='form' [dropdownList]='dropdown.filteredPromotionCodeDropdownList' [dropdownSettings]='promotionCodeDropdownSettings' [formName]="'promotion_id'" [selectionAttributes]="'id'" [selectedItems]='promotionCodeSelectedItems' (selectedItemsChanged)="onSelectedPromotionItems(promotionSelect.selectedItems)"> </kt-dropdown-wo-lazyload>
                                </div>
                            </div>
                            <div class="col-4 py-3 pl-0 pr-3">
                                <a *ngIf="canViewPromotionCodeList" class="btn btn-brand promotion-btn w-100" href="/general/promotion-codes" routerLinkActive="active" target="_blank" rel="bookmark">3.2 Promotion Codes</a>
                            </div>
                        </div>
                        <!-- SMS -->
                        <div class="row border border-top-0" formGroupName="sms_message_templates">
                            <div class="col-4 card-header p-3 border-bottom-0 border-right">
                                <span class="kt-font-bold">SMS <span class="text-danger" *ngIf="smsRequired">*</span></span>
                            </div>
                            <div class="col-8 p-3">
                                <div class="input-group-custom">
                                    <div class="group" *ngFor="let currency of currencySelectedItems">
                                        <span class="label left d-flex align-items-center justify-content-center" style="width: 5rem;">{{ currency.name }}</span>
                                        <kt-dropdown-wo-lazyload class="w-100 right" #smsSelect [form]="form.get('sms_message_templates')" [formName]="currency.id" [dropdownList]="dropdown.smsTemplates[currency.name]" [dropdownSettings]="smsDropdownSettings" [selectionAttributes]="'id'" [selectedItems]="smsSelectedItems[currency.name]" (click)="onChangeSms(smsSelect.selectedItems, currency.name)" (deSelectAll)="onChangeSms([], currency.name)"> </kt-dropdown-wo-lazyload>
                                        <!-- <select [formControlName]="currency.id" class="form-control right">
                                            <option [value]="null">Please Select</option>
                                            <option *ngFor="let smsTemplate of dropdown.smsTemplates[currency.name]" [value]="smsTemplate.id">
                                                {{ smsTemplate.code }}
                                            </option>
                                        </select> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Inbox Message -->
                        <div class="row border border-top-0" formGroupName="message_templates">
                            <div class="col-4 card-header p-3 border-bottom-0 border-right">
                                <span class="kt-font-bold">Inbox Message <span class="text-danger" *ngIf="messageRequired">*</span></span>
                            </div>
                            <div class="col-8 p-3">
                                <div class="input-group-custom">
                                    <div class="group" *ngFor="let currency of currencySelectedItems">
                                        <span class="label left d-flex align-items-center justify-content-center" style="width: 5rem;">{{ currency.name }}</span>
                                        <kt-dropdown-wo-lazyload class="w-100 right" #messageSelect [form]="form.get('message_templates')" [formName]="currency.id" [dropdownList]="dropdown.messageTemplates[currency.name]" [dropdownSettings]="messageDropdownSettings" [selectionAttributes]="'id'" [selectedItems]="messageSelectedItems[currency.name]" (click)="onChangeMessage(messageSelect.selectedItems, currency.name)" (deSelectAll)="onChangeMessage([], currency.name)"> </kt-dropdown-wo-lazyload>
                                        <!-- <select [formControlName]="currency.id" class="form-control right">
                                            <option [value]="null">Please Select</option>
                                            <option *ngFor="let messageTemplate of dropdown.messageTemplates[currency.name]" [value]="messageTemplate.id">
                                                {{ messageTemplate.code }}
                                            </option>
                                        </select> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Information Note -->
                        <div class="mt-3" *ngIf="informationNote != 0">
                            <i class="fa fa-exclamation-circle position-relative text-danger mr-2" style="color: #c92424 !important"></i>
                            <span style="color: #c92424 !important" *ngIf="informationNote == 1">No message template has been selected.</span>
                            <span style="color: #c92424 !important" *ngIf="informationNote == 2">Applying message template from recovery settings.</span>
                            <span style="color: #c92424 !important" *ngIf="informationNote == 3">Applying message template from promotion code.</span>
                        </div>
                    </div>
                </section>
            </div>
            <div class="modal-footer">
                <div class="col">
                </div>
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [isDisabled]="!form.valid || (smsRequired && !smsIsValid) || (messageRequired && !messageIsValid)" [buttonLoading]="buttonLoading" (confirmed)="onSave()" [text]="data.mode == 'duplicate' ? 'Duplicate' : 'Submit'"></kt-submit-button>
            </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog()"></kt-swal-alert>