import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { TelemarketingPlayerSummaryReport } from '@core/models/telemarketing-player-summary-report.model';

@Injectable()
export class TelemarketingPlayerSummaryReportEntityService extends EntityCollectionServiceBase<TelemarketingPlayerSummaryReport> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('TelemarketingPlayerSummaryReport', serviceElementsFactory);
    }
}
