import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IpWhitelisting } from '@core/models/ip-whitelisting.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { IpWhitelistingDataService } from '../services/ip-whitelisting-data.service';
import { catchError, map, tap } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';
import { AppPermissionService } from '@core/services/app-permission.service';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

@Component({
  selector: 'kt-ip-whitelisting-edit',
  templateUrl: './ip-whitelisting-edit.component.html',
  styleUrls: ['./ip-whitelisting-edit.component.scss']
})
export class IpWhitelistingEditComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  buttonLoading = false;
  messages$ = this.ipWhitelistingDataService.messages$;
  dropdown = {
    merchant: this.dropdownHttpService.merchantSites,
    type: this.dropdownHttpService.ipTypes
  }

  // Datetime
  dateTimeStack = null;
  @ViewChild(OwlDateTimeInputDirective) datePicker: OwlDateTimeInputDirective<any>;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;

  // permissions
  canCreateIpWhitelisting: boolean;
  canEditIpWhitelisting: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, ip: IpWhitelisting },
    public dialogRef: MatDialogRef<IpWhitelistingEditComponent>,
    private dropdownHttpService: DropdownHttpService,
    private ipWhitelistingDataService: IpWhitelistingDataService,
    private appPermissionService: AppPermissionService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateIpWhitelisting = appPermissions.create_ip_whitelist;
      this.canEditIpWhitelisting = appPermissions.edit_ip_whitelist;
    });

    this.subscriptions.push(apSub);
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      forkJoin([
        this.buildDatePicker(0, 'expiry_date'),
      ]).subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    const data = {
      ...this.form.value,
      merchant_id: +this.form.value.merchant_id,
      type: +this.form.value.type,
      expiry_date: (this.form.value.expiry_date != null && this.form.value.expiry_date != '') ? moment(this.form.value.expiry_date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') : null,
    }

    // console.log(this.form.value);
    // console.log(this.dateTimeStack);
    // console.log(data);
    // return;
    Object.keys(data).forEach((key) => key != 'expiry_date' && (data[key] == null || data[key] === '') && delete data[key]);
    if (this.data.mode === 'create') {
      this.ipWhitelistingDataService.add(data).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else if (this.data.mode === 'edit') {
      this.ipWhitelistingDataService.update(this.data.ip.id, data).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.valueChange.pipe(
      map(res => this.datePipe.transform(res, 'yyyy-MM-dd HH:mm:00')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      }),
    );
  }

  private dateMinimum(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }
      const controlDate = moment(control.value, 'YYYY-MM-DD HH:mm:ss');

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment();
      return controlDate.isAfter(validationDate) ? null : {
        'date-minimum': {
          'date-minimum': validationDate.format('YYYY-MM-DD HH:mm:ss'),
          'actual': controlDate.format('YYYY-MM-DD HH:mm:ss')
        }
      };
    };
  }

  private formInit() {
    let merchant = this.data.mode === 'edit' ? this.data.ip.site_id : null;
    let ip = this.data.mode === 'edit' ? this.data.ip.ip_address : null;
    let type = this.data.mode === 'edit' ? this.data.ip.type : null;
    let status = this.data.mode === 'edit' ? this.data.ip.status : null;
    let expiry_date = this.data.mode === 'edit' ? (this.data.ip.expiry_date) : null;

    if (this.data.mode === 'create') {
      this.form = new FormGroup({
        merchant_id: new FormControl(merchant, [Validators.required]),
        ip: new FormControl(ip, [Validators.required]),
        type: new FormControl(type, [Validators.required]),
        expiry_date: new FormControl(expiry_date, this.dateMinimum()),
      });
    } else {
      this.dateTimeStack = this.data.ip.expiry_date ? new Date(this.data.ip.expiry_date) : null;
      this.form = new FormGroup({
        merchant_id: new FormControl(merchant, [Validators.required]),
        ip: new FormControl(ip, [Validators.required]),
        type: new FormControl(type, [Validators.required]),
        status: new FormControl(status, [Validators.required]),
        expiry_date: new FormControl(expiry_date, this.dateMinimum()),
      });
    }
  }
}