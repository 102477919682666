import { AllAffiliatesDataService } from './../../services/all-affiliates-data.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment-timezone';
import { Subscription, forkJoin } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'kt-affiliate-information',
  templateUrl: './affiliate-information.component.html',
  styleUrls: ['./affiliate-information.component.scss']
})
export class AffiliateInformationComponent implements OnInit {

  infoForm: FormGroup;
  commInfoForm: FormGroup;

  info$: any;
  commInfo$: any;
  providerSummary$: any;

  searchBtnLoading = false;
  searchBtnLoading2 = false;

  commInfoFrom: string;
  commInfoTo: string;

  infoFrom = this.transactionHttpService.getYesterday().from;
  infoTo = this.transactionHttpService.getLast24Hours().to;
  ranges = this.transactionHttpService.ranges;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;

  currentMonthData = [
    {label: 'Company W/L', param: 'company_win_lose'},
    {label: 'Total Active Members', param: 'total_active_members'},
    {label: 'Bet Count', param: 'bet_count'},
    {label: 'Game Bet Amount', param: 'turnover'}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { affiliate: any },
    private transactionHttpService: TransactionHttpService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    public dialogRef: MatDialogRef<AffiliateInformationComponent>
  ) { }

  ngOnInit() {
    this.searchBtnLoading = true;
    this.searchBtnLoading2 = true;

    this.commInfoFrom = moment(this.transactionHttpService.getLastMonth().from).format('YYYY-MM');
    this.commInfoTo = moment(this.transactionHttpService.getLastMonth().to).format('YYYY-MM');

    this.infoFormInit();
    this.commInfoFormInit();
    this.getAffiliateData();
    this.getCommissionData();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  private getAffiliateData(searchDate?: any) {
    this.info$ = null;
    this.providerSummary$ = null;

    const parameters = `?username=${this.data.affiliate.username}&` + (searchDate !== undefined ? `start_datetime=${searchDate.start_datetime}&end_datetime=${searchDate.end_datetime}` : `start_datetime=${moment(this.infoFrom).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_datetime=${moment(this.infoTo).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}`);

    forkJoin([
      this.allAffiliatesDataService.getProviderSummary(parameters),
      this.allAffiliatesDataService.getInfo(parameters),
    ]).subscribe(([provider, info]) => {
      this.info$ = info;
      this.providerSummary$ = provider;

      this.searchBtnLoading = false;
    });
  }

  private getCommissionData(search?: any) {
    this.commInfo$ = null;

    let params = {
      username: this.data.affiliate.username,
    };

    if ((search?.start_yearmonth ?? '') !== '') {
      params['start_yearmonth'] = search?.start_yearmonth;
    }
    if ((search?.end_yearmonth ?? '') !== '') {
      params['end_yearmonth'] = search?.end_yearmonth;
    }

    let query = new URLSearchParams(params);

    forkJoin([
      this.allAffiliatesDataService.getCommInfo(`?${query.toString()}`),
    ]).subscribe(([comminfo]) => {
      this.commInfo$ = comminfo;

      this.commInfoFrom = comminfo['min_yearmonth'] ?? this.commInfoFrom;
      this.commInfoTo = comminfo['max_yearmonth'] ?? this.commInfoTo;

      if ((search?.end_yearmonth ?? '') === '') {
        // Update the form values
        this.commInfoForm.patchValue({
          start_yearmonth: this.commInfoTo,
          end_yearmonth: this.commInfoTo,
        });
      }

      this.searchBtnLoading2 = false;
    });
  }

  onInfoSubmit() {
    this.searchBtnLoading = true;

    delete this.infoForm.value.defaultDate;

    const data = this.filterFormFields(this.infoForm.value);

    if (data['start_datetime']) {
      data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
      data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
    }

    this.getAffiliateData(data);
  }

  onCommInfoSubmit() {
    this.searchBtnLoading2 = true;

    // Extract start and end year-month values from the form
    let startYearMonth = this.commInfoForm?.value?.start_yearmonth; // Form returned format YYYY-MM
    let endYearMonth = this.commInfoForm?.value?.end_yearmonth; // Form returned format YYYY-MM

    // Parse and set the start and end of the month
    let startYearMonthMoment = moment(startYearMonth, 'YYYY-MM');
    let endYearMonthMoment = moment(endYearMonth, 'YYYY-MM');

    // Validate if end year month is greater than start year month
    if (startYearMonthMoment.isAfter(endYearMonthMoment)) {
      // Handle validation error
      Swal.fire('Commission Filter Error', 'Start Year/Month cannot greater than End Year/Month', 'error');
      this.searchBtnLoading2 = false;
      return;
    }

    this.getCommissionData(this.commInfoForm?.value);
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach((key) => {
      if (
        formData[key] !== '' &&
        formData[key] !== null &&
        formData[key] !== undefined &&
        key !== 'defaultDate' &&
        formData[key] !== 'all'
      ) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onDateRange(event: any) {
    if (event) {
      this.infoForm.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private infoFormInit() {
    this.infoForm = new FormGroup({
      start_datetime: new FormControl(this.infoFrom),
      end_datetime: new FormControl(this.infoTo),
      defaultDate: new FormControl({
        startDate: this.infoFrom,
        endDate: this.infoTo,
      }),
    });
  }

  private commInfoFormInit() {
    this.commInfoForm = new FormGroup({
      start_yearmonth: new FormControl(this.commInfoFrom),
      end_yearmonth: new FormControl(this.commInfoTo),
    });
  }
}
