// Angular
import { ChangeDetectorRef,SimpleChanges, Component, Inject, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-show-evidence',
  templateUrl: './show-evidence.component.html',
  styleUrls: ['./show-evidence.component.scss'],
  providers: [NgbCarouselConfig],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowEvidenceComponent implements OnInit {

  loading = false;
  remarkLength = 0;

  images = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/900/500`);
  Evidences$ = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { evidences: any},
    public dialogRef: MatDialogRef<ShowEvidenceComponent>,
    private cdr: ChangeDetectorRef,
    config: NgbCarouselConfig,
    private domSanitizer:DomSanitizer,
  ) { 
    config.interval = 5000;
    config.keyboard = true;
    config.pauseOnHover = true;
    config.showNavigationIndicators = false;
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    
  }

  onCloseDialog(confirm = false) {
    this.dialogRef.close(confirm);
  }

  checkMedia(url){
    const videoExtensions = ['.mpg', '.mp2', '.mpeg', '.mpe', '.mpv', '.mp4'] //you can add more extensions
    const imageExtensions = ['.gif', '.jpg', '.jpeg', '.png'] // you can add more extensions
    const pdfExtensions   = ['.pdf'] // you can add more extensions
    
    var isImage = false;
    var isVideo = false;
    var isPdf = false;

    imageExtensions.map((e) => {
      isImage = url.includes(e);
    })
    if(isImage) return 'image';

    videoExtensions.map((e) => {
      isVideo = url.includes(e);
    })
    if(isVideo) return 'video';
    pdfExtensions.map((e) => {
      isPdf = url.includes(e);
    })
    if(isPdf) return 'pdf';

  }

}
