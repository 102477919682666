// Services
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { TelemarketerSettingsDataService } from './services/telemarketer-settings-data.service';
// Angular
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
// Model & Enum
import { Pagination } from '@core/models/pagination.model';
// RXJS & Other
import { tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import moment from 'moment';
import { TelemarketerSettingsEditDialogComponent } from './dialogs/telemarketer-settings-edit/telemarketer-settings-edit.component';
import { TelemarketerSettingsLogComponent } from './dialogs/telemarketer-settings-log/telemarketer-settings-log.component';
import { AffiliateSettingsDialogComponent } from '../all-leads/dialogs/affiliate-settings/affiliate-settings.component';
@Component({
  templateUrl: './telemarketer-settings.component.html',
  styleUrls: ['./telemarketer-settings.component.scss']
})
export class TelemarketerSettingsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  telemarketerSettings$: Observable<[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';

  dropdown = {
    perPage: this.dropdownHttpService.perPage,
  };

  sortingConfig = {
    'settings_currency_id': 'desc',
    'validity_day': 'desc',
    'follow_up_day': 'desc',
    'ftd_target_day': 'desc',
    'target_deposit_amount': 'desc',
    'inactive_period': 'desc',
    'updated_at': 'desc'
  }
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'asc',
  };

  loading = false;
  dataLength = 0;
  messages$ = this.telemarketerSettingsDataService.messages$;

  canViewTelemarketerSetting: boolean;
  canEditTelemarketerSetting: boolean;
  canViewTelemarketerSettingLog: boolean;
  canViewAffiliateSettings: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private telemarketerSettingsDataService: TelemarketerSettingsDataService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService
  ) { }

  ngOnInit() {
    this.onSubmit();
    this.pagination = this.telemarketerSettingsDataService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewTelemarketerSetting = appPermissions.view_telemarketer_setting_list;
      this.canEditTelemarketerSetting = appPermissions.edit_telemarketer_setting;
      this.canViewTelemarketerSettingLog = appPermissions.view_telemarketer_setting_log;
      this.canViewAffiliateSettings = appPermissions.view_affiliate_settings;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    this.loadingBar.start();
    pageSize = this.pageSize;
    params = this.params ? `${this.params}` : '';
    return this.telemarketerSettings$ = this.telemarketerSettingsDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.telemarketerSettingsDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, item?: any) {
    if (type === 'edit') {
      this.openDialogBy(TelemarketerSettingsEditDialogComponent, { mode: type, telemarketerSettings: item });
    } else if (type === 'show-history') {
      this.openDialogBy(TelemarketerSettingsLogComponent, { mode: '', telemarketerSettings: item });
    } else if ( type === 'affiliateSettings') {
      this.openDialogBy(AffiliateSettingsDialogComponent, { mode: type });
    }
  }

  onClear() {
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    const data = {};

    this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    this.params = this.params ? `&${this.params}` : '';
    this.page = 1;
    this.onViewPageBy();
  }

  private openDialogBy(componentRef: any, data?: { mode: string, telemarketerSettings?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'affiliateSettings' ? '800px' : '1200px',
      data: {
        telemarketerSettings: data.telemarketerSettings,
        mode: data.mode,
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onSubmit();
      }
    });
  }

  reload() {
    this.onViewPageBy(this.page);
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }
}
