import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { smsProviderCredentials, smsProviderCredentialsCurrencySettings } from '@core/models/sms-provider-credentials.model';
import { Observable, of, Subject } from 'rxjs';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { tap, map, share } from 'rxjs/operators';

@Injectable()
export class ProviderCredentialsDataService extends DefaultDataService<smsProviderCredentials>  {

  messages$ = new Subject<any[]>();
  pagination: Pagination;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('smsProviderCredentials', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<smsProviderCredentials[]>{
    return this.http.get<ApiResponse>(`/smsprovider/credential${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows),
      share()
    );
  }

  getbyCode(params: string):Observable<smsProviderCredentials[]> {
    return this.http.get<ApiResponse>(`/smsprovider/credential/credentials?${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  updateCredentials(smsProvider: any) {
    return this.http.put<ApiResponse>(`/smsprovider/credential/updatecredentials`, smsProvider).pipe(
      tap( res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      return this.pagination = res.data.paginations;
    }
  }
}
