import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns the first letter of the first word and the first letter of the second word
 * if both exist, otherwise returns only the first letter of the first word.
 */
@Pipe({
  name: 'firstAndSecondLetter'
})
export class FirstAndSecondLetterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const words = value.split(' ');
    
    // Check if there is more than one word, otherwise return only the first letter of the first word
    if (words.length > 1) {
      return words[0][0] + words[1][0].toUpperCase();  // First letter of both words
    } else {
      return words[0][0].toUpperCase();  // Only first letter of the first word
    }
  }
}