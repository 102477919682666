import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TwoFA } from '@core/models/twofa.model';
export interface TwoFAVerifyRequest {
  code: string;
}
@Injectable()
export class TwoFADataService {
  messages$ = new Subject<any[]>();
  constructor(private http: HttpClient) { }
  
  getQRCode(): Observable<TwoFA>{
    return this.http.get<ApiResponse>('/twofa/getqr').pipe(
      map(res => res.data)
    );
  }
  verifyTwoFACode(twoFAVerifyRequest: TwoFAVerifyRequest){
    return this.http.post<ApiResponse>(`/twofa/verifycode`, twoFAVerifyRequest);
  }

  verifyTwoFACodeLogin(twoFAVerifyRequest: TwoFAVerifyRequest){
    return this.http.post<ApiResponse>(`/twofa/verifycodelogin`, twoFAVerifyRequest);
  }

  requestResetTwoFA(){
    return this.http.post<ApiResponse>(`/twofa/requestreset`, null);
  }

  resetTwoFA(user_id: any){
    let data = {id: user_id};
    return this.http.post<ApiResponse>(`/twofa/reset`, data).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }
}