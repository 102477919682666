<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Remarks</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>Created At</th>
              <th class="text-center">Operator</th>
              <th>Remark</th>
            </tr>
          </thead>
          <tbody *ngIf="(remarksHistory$ | async) as rows">
            <ng-container *ngFor="let row of rows">
              <tr>
                <td width="150"> {{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }} </td>
                <td class="text-center" width="150"> {{ row.handler }} </td>
                <td width="400"> {{ row.remarks }} </td>
              </tr>
            </ng-container>
            <kt-fallback-row [collection]="rows"></kt-fallback-row>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>
