import { catchError, debounceTime, tap, map } from 'rxjs/operators';
import { ReferralCommissionSettingsEntityService } from './../services/referral-commission-settings-entity.service';
import { ReferralCommissionSettingsDataService } from './../services/referral-commission-settings-data.service';
import { Subscription, forkJoin, of } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ReferralCommissionSetting } from '@core/models/referral-commission-setting.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { PromotionCodeDataService } from '../../../general/promotion-codes/services/promotion-code-data.service';
import { TranslateService } from '@ngx-translate/core';
import { AppPermissionService } from '@core/services/app-permission.service';
import { GroupEntityService } from '../../../general/groups/services/group-entity.service';
@Component({
    selector: 'kt-referral-commission-settings-edit',
    templateUrl: './referral-commission-settings-edit.component.html',
    styleUrls: ['./referral-commission-settings-edit.component.scss']
})
export class ReferralCommissionSettingsEditDialogComponent implements OnInit, AfterViewInit, OnDestroy {

    form: FormGroup;
    refreshStatus: boolean;
    messages$ = this.referralCommissionSettingsDataService.messages$;
    private subscription = new Subscription();
    private subscriptions = new Subscription();

    buttonLoading = false;

    dropdown = {
        currencies: this.dropdownHttpService.currencies,
        statuses: this.dropdownHttpService.statuses,
        types: this.dropdownHttpService.referralCommSettingTypes
    };

    memberGroupsDropdownList = [];
    memberGroupsSelectedItems = [];

    currenciesDropdownList = [];
    currenciesSelectedItems = [];
    promotionCodeDropdownList = [];
    promotionCodeSelectedItems = [];
    levelForm: any;

    dropdownSettings = {
        autoPosition: true,
        maxHeight: 150,
        singleSelection: false,
        text: this.translateService.instant('Please Select'),
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        primaryKey: 'id',
        labelKey: 'name',
        lazyLoading: true,
        noDataLabel: '',
        showCheckbox: false
    };

    memberGroupDropdownSettings = {
        autoPosition: true,
        maxHeight: 150,
        singleSelection: false,
        text: this.translateService.instant('Please Select'),
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        primaryKey: 'id',
        labelKey: 'name',
        noDataLabel: '',
        showCheckbox: false,
        disabled: this.data.mode === 'edit' || this.data.mode === 'duplicate' ? false : true,
    };

    promotionCodeDropdownSettings = {
        singleSelection: true,
        text: this.translateService.instant('Please Select'),
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'name',
        noDataLabel: '',
        showCheckbox: false,
        disabled: this.data.mode === 'edit' || this.data.mode === 'duplicate' ? false : true,
    };

    // permissions
    canCreateReferralCommissionSettings: boolean;
    canEditReferralCommissionSettings: boolean;
    canDuplicateReferralCommissionSettings: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { referralCommissionSetting: ReferralCommissionSetting, mode: string },
        private referralCommissionSettingsDataService: ReferralCommissionSettingsDataService,
        public dialogRef: MatDialogRef<ReferralCommissionSettingsEditDialogComponent>,
        private dropdownHttpService: DropdownHttpService,
        private promotionCodeDataService: PromotionCodeDataService,
        public dialog: MatDialog,
        private translateService: TranslateService,
        private appPermissionService: AppPermissionService,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private groupService: GroupEntityService,
    ) { }

    async ngOnInit() {
        this.formInit();
        await this.setMemberGroup();

        const apSub = this.appPermissionService.getAppPermissions().subscribe((appPermissions) => {
            this.canCreateReferralCommissionSettings = appPermissions.create_referral_commission_settings;
            this.canEditReferralCommissionSettings = appPermissions.edit_referral_commission_settings;
            this.canDuplicateReferralCommissionSettings = appPermissions.duplicate_referral_commission_settings;
        });

        this.subscriptions.add(apSub);
    }

    ngAfterViewInit() {
        forkJoin(this.dropdown).subscribe((res: any) => {
            this.currenciesDropdownList = res.currencies;

            if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
                // Pre-selected currencies
                this.currenciesDropdownList.forEach(item => { this.data.referralCommissionSetting.member_groups.forEach(r => { if (+r.settings_currency_id === +item.id) { this.currenciesSelectedItems.push(item); } }); });
                this.currenciesSelectedItems = Array.from(new Set(this.currenciesSelectedItems));

                // // Pre-selected memberGroups
                this.memberGroupsDropdownList.forEach(item => { this.data.referralCommissionSetting.member_groups.forEach(r => { if (+r.member_group_id === +item.id) { this.memberGroupsSelectedItems.push(item); } }); });
                this.memberGroupsSelectedItems = Array.from(new Set(this.memberGroupsSelectedItems));

                this.form.patchValue({
                    currencies: this.currenciesSelectedItems.map(item => item.id),
                    member_groups: this.memberGroupsSelectedItems.map(item => item.id)
                });

                this.filterPromotionCodes(true);
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.subscriptions.unsubscribe();
        this.onRefresh();
    }

    onCloseDialog(event?: Event) {
        this.dialogRef.close();
    }

    onSave(referralCommissionSetting: ReferralCommissionSetting, mode?: string) {
        this.buttonLoading = true;
        // To set "Save" button to disable (To prevent call API in multiple times when double click)
        this.form.setErrors({ 'invalid': true });

        const data = {
            id: referralCommissionSetting ? referralCommissionSetting.setting.id : null,
            ...this.form.getRawValue(),
        };

        Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
        switch (mode) {
            case 'edit':
                this.subscription = this.referralCommissionSettingsDataService.updateReferralCommissionSetting(data).pipe(
                    tap((res: any) => {
                        this.messages$.next([...res.message]);
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                    }),
                    catchError((error) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                        throw error;
                    })
                ).subscribe();
                this.refreshStatus = true;
                break;
            case 'create':
            case 'duplicate':
                this.subscription = forkJoin([
                    this.referralCommissionSettingsDataService.add(data).pipe(
                        tap((res: any) => {
                            this.buttonLoading = false;
                            // To enable "Save" button after get response
                            this.form.setErrors(null);
                        }),
                        catchError((error) => {
                            this.buttonLoading = false;
                            // To enable "Save" button after get response
                            this.form.setErrors(null);
                            throw error;
                        })
                    ),
                    this.referralCommissionSettingsDataService.messages$,
                ]).subscribe();
                this.refreshStatus = true;
                break;
        }
    }

    onRefresh() {
        if (this.refreshStatus) {
            this.dialogRef.close(true);
        }
    }

    private formInit() {
        let name = null;
        let min = null;
        let max = null;
        let status = 1;
        let memberGroups = null;
        let currencies = null;
        let promotion_id = null;
        let type = null;
        let levelCommRates = [1, 2, 3];

        if (this.data.mode === 'edit' || this.data.mode === 'duplicate') {
            name = this.data.mode === 'edit' ? this.data.referralCommissionSetting.setting.name : null;
            min = this.data.referralCommissionSetting.setting.min_referral_commission;
            max = this.data.referralCommissionSetting.setting.max_referral_commission;
            status = this.data.referralCommissionSetting.setting.status;
            type = this.data.referralCommissionSetting.setting.type;
            levelCommRates = Object.values(this.data.referralCommissionSetting.setting.level_commission_rates);
        }

        this.form = new FormGroup({
            name: new FormControl(name, [Validators.required]),
            min_referral_commission: new FormControl(min, [Validators.required, Validators.min(0)]),
            max_referral_commission: new FormControl(max, [Validators.required, Validators.min(0)]),
            promotion_id: new FormControl(promotion_id, [Validators.required]),
            status: new FormControl({ value: status, disabled: this.data.mode === 'create' }),
            member_groups: new FormControl(memberGroups),
            currencies: new FormControl(currencies),
            type: new FormControl(type, [Validators.required]),
            level_commission_rates: new FormArray([]),
        });

        this.levelForm = this.form.controls['level_commission_rates'] as FormArray;
        const commRate = levelCommRates;
        let rate: any;
        commRate.forEach((item) => {
            rate = new FormControl(this.data.mode == 'edit' || this.data.mode === 'duplicate' ? item : null)
            this.levelForm.push(rate);
        });

        this.form.controls['currencies'].valueChanges.subscribe((val) => {
            var disabled = val ? false : true;

            this.memberGroupDropdownSettings = {
                ...this.memberGroupDropdownSettings,
                disabled: disabled,
            };
            this.cdr.detectChanges();
        });

        this.form.controls['member_groups'].valueChanges.subscribe((val) => {
            var disabled = val ? false : true;

            this.promotionCodeDropdownSettings = {
                ...this.promotionCodeDropdownSettings,
                disabled: disabled,
            };
            this.cdr.detectChanges();
        });
    }

    setCurrencyMemberGroup(type: string, value: any) {
        if (value != null && value.length > 0) {
            if (type === 'currency') {
                this.form.patchValue({
                    currencies: value.map(item => item.id) 
                });
            } else {
                this.form.patchValue({
                    member_groups: value.map(item => item.id)
                });
            }
    
            this.filterPromotionCodes(false);
        } else {
            this.promotionCodeSelectedItems = [];
            if (type == 'currency') {
                this.form.patchValue({
                    currencies: null
                });
            } else {
                this.form.patchValue({
                    member_groups: null
                });
            }
        }
    }

    filterPromotionCodes(editDuplicateAction: boolean) {
        this.promotionCodeSelectedItems = [];
        this.form.patchValue({
            promotion_id: null
        });
        const selectedCurrency = this.form.get('currencies').value;
        const selectedMemberGroup = this.form.get('member_groups').value;

        if (selectedCurrency && selectedMemberGroup) {
            this.promotionCodeDataService.getWithQuery(`?paginate=false&status=1&member_group_id=${selectedMemberGroup}&currency_id=${selectedCurrency}&eligibility_type=1&referral_commission_setting=true`).subscribe(res => {
                this.promotionCodeDropdownList = res;
                this.promotionCodeDropdownList.map(function (elm) {
                    elm['name'] = elm.code + ' - ' + elm.name;
                });

                if ((this.data.mode === 'edit' || this.data.mode === 'duplicate') && editDuplicateAction && this.data.referralCommissionSetting.promotion_id != 0) {
                    this.promotionCodeSelectedItems.push({
                        id: this.data.referralCommissionSetting.setting.promotion_id,
                        name: this.data.referralCommissionSetting.setting.promotion_name + '-' + this.data.referralCommissionSetting.setting.promotion_code
                    });
                    
                    this.form.patchValue({
                        promotion_id: this.data.referralCommissionSetting.setting.promotion_id
                    });
                };
            });
        }
    }

    private setMemberGroup():Promise<void> {
        return new Promise((resolve, reject) => {
            this.groupService.getWithQuery(`?status=1&paginate=false`).subscribe(res => {
                this.memberGroupsDropdownList = res;  
                resolve();
            });
        });
    }
}
