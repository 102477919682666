<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Affiliate Settings</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="col-12 p-0 card rounded-0 mb-4">
          <div class="card-header">
            General
          </div>
          <div class="col-12 row pl-4 pt-4 pb-0">
            <label class="col-4 col-form-label">Action <span class="text-danger">*</span> </label>
            <select [attr.disabled]="buttonLoading ? 'disabled' : null" formControlName="action" class="col-8 form-control" (change)="onChangeSetting()">
              <option [value]="value.id" *ngFor="let value of dropdownList.action"> {{ value.name }} </option>
            </select>
          </div>
          <div class="col-12 row pl-4 pt-4 pb-4">
            <label class="col-4 col-form-label">Affiliates <span class="text-danger">*</span> </label>
            <select [attr.disabled]="buttonLoading ? 'disabled' : null" formControlName="affiliates" class="col-8 form-control" (change)="onChangeSetting()">
              <option [value]="value.id" *ngFor="let value of dropdownList.affiliates"> {{ value.name }} </option>
            </select>
          </div>
          <div class="col-12 pl-4 pr-4 pb-4" *ngIf="leadSettings">
            <table class="col-12">
              <tr>
                <td>
                  Updated by: {{ leadSettings.updated_by ? leadSettings.updated_by + '&nbsp;' : '-&nbsp;' }}{{ leadSettings.updated_at | timezoneDate:'YYYY-MM-DD HH:mm'}}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-12 p-0 card rounded-0 mt-0" *ngIf="showHint">
          <div class="card-header">
            Affiliates
          </div>
          <td class="text-left p-0">
            <span class="kt-badge kt-badge--inline kt-badge--wide">{{ hintText }}</span>
          </td>
          <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
            <div class="col-6 dropdown dropdown-inline search pl-0">
              <label class="col-2 col-form-label mr-3 pl-0">Search:</label>
              <input type="text" class="form-control" (keyup)="onSearch($event)">
            </div>
            <div class="col-6 dropdown dropdown-inline pr-0 text-right">
              <button *ngIf="canDeleteAffiliates" class="btn btn-danger btn-icon-sm mr-2" [disabled]="buttonLoading || selectedAffiliateList < 1" (click)="onDeleteAll()"><i class="fas fa-trash-alt"></i>Delete All</button>
              <button *ngIf="canAddAffiliates" class="btn btn-brand btn-icon-sm" [disabled]="buttonLoading" (click)="onOpenDialog()"><i class="fas fa-plus"></i>Add</button>
            </div>
          </div>
          <div class="col-12 affiliate-listing">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th class="text-center">Code</th>
                    <th class="text-center">Username</th>
                    <th class="text-center">Name</th>
                    <th class="text-center">Currency</th>
                    <th class="text-center">TimeStamp</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody *ngIf="!buttonLoading">
                  <ng-container *ngIf="selectedAffiliateList.length > 0; else noData">
                    <tr *ngFor="let row of selectedAffiliateList">
                      <td class="text-center">{{ row.affiliate_code ? row.affiliate_code : '-' }}</td>
                      <td>{{ row.username }}</td>
                      <td>{{ row.name ? row.name : '-' }}</td>
                      <td class="text-center">{{ row.currency_code }}</td>
                      <td><span>{{ row.updated_by ? row.updated_by : '-' }}</span><br>{{ timezoneDate(row.created_at, 'YYYY-MM-DD HH:mm')}}</td>
                      <td class="text-center actions-column">
                        <button *ngIf="canDeleteAffiliates" title="Delete" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onDelete(row.id)"><i class="fas fa-trash-alt"></i></button>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
                <tbody *ngIf="buttonLoading" class="pre-search-table">
                  <tr>
                    <td colspan="30">
                      <div class="spinner-wrapper">
                        <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <ng-template #noData>
                  <tr>
                    <td colspan="30" class="text-center">
                      No affiliate found
                    </td>
                  </tr>
                </ng-template>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <button *ngIf="canEditAffiliateSettings" class="btn btn-primary btn-icon-sm mr-2" (click)="onSave()" type="button" [disabled]="sameSettings || buttonLoading || !form.valid"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Submit</button>
      </div>
    </div>
  </form>
</div>