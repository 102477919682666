<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Campaign Clicks - {{data.campaignName}}</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <!-- Unique ID -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Unique ID:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="unique_id" placeholder="Search" class="form-control">
                                </div>
                                <!-- IP -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>IP:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="ip_address" placeholder="Search" class="form-control">
                                </div>
                                <!-- Date Time -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group date">
                                        <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" [opens]="'left'" (click)="updateDateRange()" ngxDaterangepickerMd />
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- Action Button -->
            <div class="kt-section col-12 row mb-3 mt-2 d-flex justify-content-between">
                <div>
                    <kt-search-button [loading]="searchBtnLoading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" (clear)="onClear()"></kt-clear-button>
                </div>
            </div>

            <!-- Content -->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <!-- Table -->
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('unique_id')" [ngClass]="sortingConfig.unique_id" [class.sort-selected]="sortingSelection.sort_by === 'unique_id'">{{ 'Unique ID' }}</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('ip_address')" [ngClass]="sortingConfig.ip_address" [class.sort-selected]="sortingSelection.sort_by === 'ip_address'">{{ 'IP Address' }}</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('user_agent')" [ngClass]="sortingConfig.user_agent" [class.sort-selected]="sortingSelection.sort_by === 'user_agent'">{{ 'User Agent' }}</th>
                                    <th>{{ 'URL' }}</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('locale_code')" [ngClass]="sortingConfig.locale_code" [class.sort-selected]="sortingSelection.sort_by === 'locale_code'">{{ 'Locale' }}</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">{{ 'Date' }}</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(campaignClicks$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.unique_id ? row.unique_id : '-' }}</td>
                                    <td>{{ row.ip_address ? row.ip_address : '-' }}</td>
                                    <td>{{ row.user_agent ? row.user_agent : '-' }}</td>
                                    <td>{{ row.url ? row.url : '-' }}</td>
                                    <td>{{ row.locale_code ? row.locale_code : '-' }}</td>
                                    <td>{{ row.created_at | date:'yyyy-MM-dd HH:mm' }}</td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                {{ 'Showing' }} {{pagination.from}} {{ 'to' }} {{pagination.to}} {{ 'of' }} {{ pagination.total | number : '1.0' }} {{ 'records' }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>{{ 'Close' }}</button>
        </div>
    </div>
</div>