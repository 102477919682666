import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { AllSmsMessages } from '@core/models/all-sms-messages.model';

@Injectable()
export class AllSmsMessagesEntityService extends EntityCollectionServiceBase<AllSmsMessages>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('AllSmsMessages', serviceElementsFactory)
  }
}
