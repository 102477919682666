import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { map } from 'rxjs/operators';

@Injectable()
export class SeoHttpService {

    constructor(private http: HttpClient) { }

    getSeoDomains() {
        return this.http.get<ApiResponse>(`/seodomain`).pipe(
            map(res => res.data.rows)
        );
    }

    getSeoRoutes() {
        return this.http.get<ApiResponse>(`/seoroute`).pipe(
            map(res => res.data.rows)
        );
    }
}
