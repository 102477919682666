import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { SMSRecoveryReport } from '@core/models/sms-recovery-report.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { of, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { SMSRecoveryReportDetailsComponent } from './dialogs/sms-recovery-details/report-details.component';
import { SMSRecoveryReportDataService } from './services/sms-recovery-report-data.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';

@Component({
  templateUrl: './sms-recovery-report.component.html',
  styleUrls: ['./sms-recovery-report.component.scss']
})
export class SMSRecoveryReportComponent implements OnInit, OnDestroy {

  form: FormGroup;
  smsRecovery$: SMSRecoveryReport[];
  pageSize = 30;
  page = 1;
  maxSize = 5;
  paginationTotal = 1;
  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones')),
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage
  };
  pagination: Pagination;
  ranges = this.transactionHttpService.ranges;
  from = this.transactionHttpService.getLast24Hours().from;
  to = this.transactionHttpService.getLast24Hours().to;
  params = `start_date_time=${moment(this.from).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date_time=${moment(this.to).utc().format('YYYY-MM-DD HH:mm:ss')}&currency_id=1`;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  timezoneDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown clear-all-disabled',
    primaryKey: 'offset',
    labelKey: 'offset',
    noDataLabel: '',
    showCheckbox: false
  };
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;

  default_sort_by = 'campaign_id';
  default_sort_order = 'asc';

  sortingConfig = {
    'campaign_id': 'asc',
    'campaign_name': 'desc',
    'total_deposit': 'desc',
    'deposit': 'desc',
    'depositor': 'desc',
    'avg_deposit': 'desc',
    'player_assigned': 'desc',
    'last_player_assigned_time': 'desc',
    'reward_name': 'desc',
    'redeemed': 'desc',
    'last_redeemed_time': 'desc',
    'redeem': 'desc',
    'ad_cost': 'desc',
    'ad_cost_per_depositor': 'desc',
    'total_ngr': 'desc'
  };
  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order,
  };
  button_loading = false;
  messages$ = this.smsRecoveryReportDataService.messages$;
  access$ = this.authHttpService.getUserAccess(5, 'SMS Recovery Report');

  // permissions
  canViewSMSRecoveryReportDetails: boolean;
  canExportSMSRecoveryReport: boolean;

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    public dialog: MatDialog,
    private smsRecoveryReportDataService: SMSRecoveryReportDataService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.pagination = this.smsRecoveryReportDataService.pagination;
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.selectedTimezone = this.timezone;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewSMSRecoveryReportDetails = appPermissions.view_sms_recovery_report_details;
      this.canExportSMSRecoveryReport = appPermissions.export_sms_recovery_report;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];

    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.form.patchValue({
      campaign_name: null,
      currency_id: 0,
      start_date_time: this.from,
      end_date_time: this.to,
      timezone: this.timezone,
      defaultDate: {
        startDate: this.from,
        endDate: this.to
      }
    });
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);
    this.onSubmit(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    this.loadingBar.start();
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.smsRecovery$ = [];
    return this.smsRecoveryReportDataService.getWithQuery(`&page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.loading = false;
        this.pagination = this.smsRecoveryReportDataService.pagination;
        this.loadingBar.complete();
      }),
      catchError((err) => {
        this.loadingBar.complete();
        return throwError(err);
      })
    ).subscribe(res => this.smsRecovery$ = res)
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  sanitizeNumericField(previousValue: any) {
    const value = String(previousValue).replace(/[,]+/, '');
    return value;
  }

  onExport() {
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        data['paginate'] = false;

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        return this.smsRecoveryReportDataService.exportReport(`?${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.messages$ = this.smsRecoveryReportDataService.messages$;
            this.loadingBar.complete();
            this.button_loading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.button_loading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  onOpenDialog(id: number) {
    if (!this.canViewSMSRecoveryReportDetails) {
      Swal.fire({
        icon: 'error',
        title: 'Permission Error',
        text: 'You do not have permission to view SMS recovery report details',
      });
      return;
    }

    this.openDialogBy(SMSRecoveryReportDetailsComponent, {
      id: id, form: this.form.value
    });
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.smsRecovery$ = [];
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        this.loading = true;
        this.sortingConfig[this.sortingSelection.sort_by] = 'desc';
        this.sortingSelection.sort_by = this.default_sort_by;
        this.sortingSelection.sort_order = this.default_sort_order;
        this.sortingConfig[this.default_sort_by] = this.default_sort_order;
        return this.smsRecoveryReportDataService.getWithQuery(`&${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.pagination = this.smsRecoveryReportDataService.pagination;
            this.page = 1;
            this.loadingBar.complete();
            this.onSearch = true;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
          }),
          catchError((err) => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.loadingBar.complete();
            return throwError(err);
          })
        );
      }),
    ).subscribe(res => this.smsRecovery$ = res);
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private openDialogBy(componentRef: any, data?: { id: number, form?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '1500px',
      data: {
        id: data.id,
        form: data.form
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      campaign_name: new FormControl(null),
      currency_id: new FormControl(0), // Default
      start_date_time: new FormControl(this.from),
      end_date_time: new FormControl(this.to),
      timezone: new FormControl(this.timezone),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      }) // Do not remove: For Clearing The Range
    });
  }

  private reload() {
    this.onViewPageBy(this.page);//.subscribe();
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }
}
