<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">History of Assigned Account Manager</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="row">
          <section class="col-lg-12 card border-0">
            <div class="col-12 p-0 card rounded-0">
              <div class="card-header">
                Member Info
              </div>
              <section class="col-12 p-4">
                <div class="row border-top border-left">
                  <div class="col-lg-3 col-md-3 card-header border-right p-3">
                    <span class="kt-font-bold">Member ID</span>
                  </div>
                  <div class="col-md-3 border-right p-3">
                    <span>{{ data.accountManagement.member_account_id }}</span>
                  </div>
                  <div class="col-lg-3 col-md-3 card-header border-right p-3">
                    <span class="kt-font-bold">Username</span>
                  </div>
                  <div class="col-md-3 border-right p-3">
                    <span>{{ data.accountManagement.username }}</span>
                  </div>
                </div>

                <div class="row border-top border-left">
                  <div class="col-lg-3 col-md-3 card-header border-right p-3">
                    <span class="kt-font-bold">Name</span>
                  </div>
                  <div class="col-md-3 border-right p-3">
                    <span>{{ data.accountManagement.name }}</span>
                  </div>
                  <div class="col-lg-3 col-md-3 card-header border-right p-3">
                    <span class="kt-font-bold">Group</span>
                  </div>
                  <div class="col-md-3 border-right p-3">
                    <span>{{ data.accountManagement.member_group }}</span>
                  </div>
                </div>

                <div class="row border-top border-left border-bottom">
                  <div class="col-lg-3 col-md-3 card-header border-right p-3">
                    <span class="kt-font-bold">Currency</span>
                  </div>
                  <div class="col-md-3 border-right p-3">
                    <span>{{ data.accountManagement.currency }}</span>
                  </div>
                  <div class="col-lg-3 col-md-3 card-header border-right p-3">
                    <span class="kt-font-bold">Remarks</span>
                  </div>
                  <div class="col-md-3 border-right p-3">
                    <span>{{ data.accountManagement.value }}</span>
                  </div>
                </div>

              </section>
            </div>
          </section>
        </div>

        <div class="row mt-5">
          <section class="col-lg-12 card border-0">
            <div class="col-12 p-0 card rounded-0" *ngIf="(AccountManagementLog$ | async) as rows">
              <div class="card-header">
                Timeline
              </div>
              <div class="row ml-1" *ngFor="let row of rows">
                <div class="col-1 p-3">
                  {{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }}
                </div>
                <div class="col-11 p-3 timelinediv">
                  <!-- The Timeline -->
                  <div class="timeline">
                    <!-- Item 1 -->
                    <div>
                      <div class="direction-r">
                        <div class="flag-wrapper">
                          <span class="flag" [ngClass]="'border-color--' + row.status">
                            <section class="col-12 p-3">
                              <div class="row border-top border-left">
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                  <span class="kt-font-bold">Account Manager</span>
                                </div>
                                <div class="col-md-9 border-right p-3">
                                  <span>{{ row.account_manager }}</span>
                                </div>
                              </div>

                              <div class="row border-top border-left">
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                  <span class="kt-font-bold">Development Stage</span>
                                </div>
                                <div class="col-md-3 border-right p-3">
                                  <span>{{ row.development_stage_name }}</span>
                                </div>
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                  <span class="kt-font-bold">Lead Stage</span>
                                </div>
                                <div class="col-md-3 border-right p-3">
                                  <span>{{ row.lead_stage }}</span>
                                </div>
                              </div>

                              <div class="row border-top border-left">
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                  <span class="kt-font-bold">Average Deposit Count / Amount (LCC)</span>
                                </div>
                                <div class="col-md-3 border-right p-3">
                                  <span class="text-right">#: {{ row.average_deposit_count ? row.average_deposit_count : '-' }} <br> $: {{ row.average_deposit_amount ? row.average_deposit_amount : '-' }}</span>
                                </div>
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                  <span class="kt-font-bold">KPI (Count / Amount)</span>
                                </div>
                                <div class="col-md-3 border-right p-3">
                                  <span class="text-right">#: {{ row.kpi_deposit_count ? row.kpi_deposit_count : '-' }} <br> $: {{ row.kpi_deposit_amount ? row.kpi_deposit_amount : '-' }}</span>
                                </div>
                              </div>

                              <div class="row border-top border-left border-bottom">
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                  <span class="kt-font-bold">Lead Validity</span>
                                </div>
                                <div class="col-md-3 border-right p-3">
                                  <span>{{ row.lead_validity_datetime ? (row.lead_validity_datetime | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }}</span>
                                </div>
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                  <span class="kt-font-bold">Progress (Count / Amount)</span>
                                </div>
                                <div class="col-md-3 border-right p-3">
                                  <span class="text-right">#: {{ row.progress_deposit_count ? row.progress_deposit_count : '-' }} <br> $: {{ row.progress_deposit_amount ? row.progress_deposit_amount : '-' }}</span>
                                </div>
                              </div>
                            </section>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kt-pagination kt-pagination--brand mt-2 p-3" *ngIf="pagination !== undefined">
                <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="getTimeline(page)">
                </ngb-pagination>
                <div class="kt-pagination__toolbar">
                  <select (change)="onPerPage($event)" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}" class="form-control kt-font-brand page-size">
                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                  </select>
                  <span class="pagination__desc">
                    Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0'}} records
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>