import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { FooterSection } from '@core/models/footer-section.model';

@Injectable()
export class FooterSectionEntityService extends EntityCollectionServiceBase<FooterSection> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('FooterSection', serviceElementsFactory);
  }
}
