<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">  
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{ 'Remark History | ' }} {{ data.affiliate.username }} ({{ data.affiliate.id }})</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">    
            <div class="col-12 p-0 card rounded-0" *ngIf="remarksHistory.length > 0">
                <div class="card-header">
                    Timeline
                </div>
                <div class="row ml-1" *ngFor="let row of remarksHistory">
                    <div class="col-1 p-3">
                        {{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }}
                    </div>
                    <div class="col-11 p-3">
                        <!-- The Timeline -->
                        <div class="timeline">
                            <div class="preview-status" [matTooltip]="row.status_name">&nbsp;</div>
                        <!-- Item 1 -->
                            <div>
                                <div class="direction-r">
                                    <div class="flag-wrapper">
                                        <span class="flag" [ngClass]="'border-color--' + row.status">
                                            <section class="col-12 p-3 inner-box-timeline">
                                                <div class="row border-top border-left">
                                                    <div class="col-lg-3 col-md-3 card-header border-right p-3">     
                                                        <span class="kt-font-bold">Remark</span>
                                                    </div>
                                                    <div class="col-md-9 border-right p-3">
                                                        <span>{{ row.value }}</span>
                                                    </div>
                                                </div>
                                                <div class="row border-top border-left border-bottom">
                                                    <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                                        <span class="kt-font-bold">Created By</span>
                                                    </div>
                                                    <div class="col-md-9 border-right p-3">
                                                        <span class="text-right">{{ row.handler ? row.handler : '-' }}<br>{{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }} </span>
                                                    </div>
                                                </div>
                                            </section>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog($event)"><i class="fas fa-ban"></i>Close</button>
        </div>
    </div>
</div>