<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Bet Limits</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
          <form class="row align-items-center kt-form" [formGroup]="filterForm">
            <div class="col-md-3 kt-margin-b-20-tablet-and-mobile">
              <div class="kt-form__label">
                Currency
              </div>
              <div class="kt-form__control">
                <select formControlName="currency" class="form-control">
                  <option value="null" [disabled]="true">Please Select</option>
                  <option *ngFor="let value of dropdown.currencies" [ngValue]="value">{{value.name}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3 kt-margin-b-20-tablet-and-mobile">
              <div class="kt-form__label">
                Game Provider
              </div>
              <div class="kt-form__control">
                <select formControlName="game_provider" class="form-control">
                  <option value="null" [disabled]="true">Please Select</option>
                  <option *ngFor="let value of dropdown.providers | async" [ngValue]="value">{{value.code}}</option>
                </select>
              </div>
            </div>
          </form>
          <div class="col-12 pr-0 mt-3 pl-0">
            <div class="row align-items-center bet-limit-table col-12 m-0" >
              <div class="col-md-4 kt-margin-b-20-tablet-and-mobile">
                <div class="kt-form__label text-center">
                  Min Bet
                </div>
              </div>
              <div class="col-md-4 kt-margin-b-20-tablet-and-mobile">
                <div class="kt-form__label text-center">
                  Max Bet
                </div>
              </div>
              <div class="col-md-4 kt-margin-b-20-tablet-and-mobile">
                <div class="kt-form__label text-center">
                  Max Amount Per Match
                </div>
              </div>
            </div>
            <div class="row align-items-center bet-limit-field col-12 p-0 m-0">
              <div class="col-md-4 kt-margin-b-20-tablet-and-mobile pl-0">
                <div class="kt-form__control">
                  <input type="number" (wheel)="false" formControlName="min_bet_limit" class="form-control">
                </div>
              </div>
              <div class="col-md-4 kt-margin-b-20-tablet-and-mobile p-0">
                <div class="kt-form__control">
                  <input type="number" (wheel)="false" formControlName="max_bet_limit" class="form-control">
                </div>
              </div>
              <div class="col-md-4 kt-margin-b-20-tablet-and-mobile pr-0">
                <div class="kt-form__control">
                  <input type="number" (wheel)="false" formControlName="max_amount_per_match" class="form-control">
                </div>
              </div>
            </div>
            <div class="row align-items-center bet-limit-info col-12 p-0 m-0">
              <div class="col-md-4 kt-margin-b-20-tablet-and-mobile pl-0">
                <small class="form-text">Min: {{providerMinBetLimit}}</small>
              </div>
              <div class="col-md-4 kt-margin-b-20-tablet-and-mobile pl-0">
                <small class="form-text">Max: {{providerMaxBetLimit}}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()" ><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button *ngIf="canEditBetLimit" [isDisabled]="!(form.valid && filterForm.valid)" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
        </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
