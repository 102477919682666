import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { TermsConditions } from '@core/models/terms-conditions.model';

@Injectable()
export class TermsConditionsDataService extends DefaultDataService<TermsConditions>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('TermsAndConditions', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<TermsConditions[]> {
    return this.http.get<ApiResponse>('/termsandconditions').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(promotionContent: TermsConditions): Observable<any>{
    return this.http.post<ApiResponse>(`/termsandconditions`, promotionContent).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getWithQuery(pageParam: string): Observable<TermsConditions[]>{
    return this.http.get<ApiResponse>(`/termsandconditions${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data)
    )
  }

  getById(id: number): Observable<TermsConditions>{
    return this.http.get<ApiResponse>(`/termsandconditions/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  updateTermsConditionsContent(id: number, termsConditions: any) {
    return this.http.put<ApiResponse>(`/termsandconditions/${id}`, termsConditions).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  updateStatus(param: any) {
    return this.http.put<ApiResponse>(`/termsandconditions/statusupdate`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.contents.paginations;
    }
  }
}
