<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Referral Settings</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 form-group">
                        <label class="control-label">Currency <span class="text-danger">*</span></label>
                        <select *ngIf="dropdown.currencies" class="col-12 form-control" formControlName="currency_id">
                          <option *ngFor="let value of dropdown.currencies" [value]="value.id">{{ value.name }}</option>
                        </select>
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Name <span class="text-danger">*</span></label>
                        <input type="text" formControlName="name" class="col-12 form-control">
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Target Deposit Amount <span class="text-danger">*</span></label>
                        <input type="number" formControlName="target_deposit_amount" class="col-12 form-control">
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Target Referral Count <span class="text-danger">*</span></label>
                        <input type="number" formControlName="target_referral_count" class="col-12 form-control">
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Validity</label>
                        <input type="number" formControlName="validity" class="col-12 form-control">
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label label">Referral Bonus</label>
                        <div class="referral-dropdown">
                            <kt-dropdown-wo-lazyload
                                [form] = 'form'
                                [dropdownList] = 'promotionCodeDropdownList'
                                [dropdownSettings] = 'promotionCodeDropdownSettings'
                                [formName] = "'referral_bonus'"
                                [selectionAttributes] = "'id'"
                                [selectedItems] = 'referralBonusSelectedItems'>
                            </kt-dropdown-wo-lazyload>
                        </div>
                        <a *ngIf="canViewPromotionCodeList" class="btn btn-brand promotion-btn" href="/general/promotion-codes" routerLinkActive="active" target="_blank" rel="bookmark">3.2 Promotion Codes</a>
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Invitation Bonus</label>
                        <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'promotionCodeDropdownList'
                            [dropdownSettings] = 'promotionCodeDropdownSettings'
                            [formName] = "'invitation_bonus'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] = 'invitationBonusSelectedItems'>
                        </kt-dropdown-wo-lazyload>
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="col-form-label">Status</label>
                        <select class="form-control col-12" formControlName="status">
                            <option [value]="null" [disabled]="true">Please Select</option>
                            <ng-container *ngFor="let value of dropdown.statuses; let i = index;">
                                <option [value]="i">
                                    {{ value }}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
              <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.mode)"></kt-submit-button>
            </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
