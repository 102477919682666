import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromotionMessage } from '@core/models/promotion-message.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LocaleHttpService } from '@core/services/locale-http.service';
import { forkJoin, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PromotionMessageDataService } from '../promotion-messages/services/promotion-message-data.service';

@Component({
  selector: 'kt-promotion-message-details',
  templateUrl: './promotion-message-details.component.html',
  styleUrls: ['./promotion-message-details.component.scss']
})
export class PromotionMessageDetailsComponent implements OnInit {

  form: FormGroup;
  messages$ = this.promotionMessageDataservice.messages$;

  currency_id = this.data.mode === 'edit' ? this.data.promotionMessage.currency_id : 0;
  settings_locale_id = this.data.mode === 'edit' ? this.data.promotionMessage.settings_locale_id : 0;

  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    locales: this.LocaleHttpService.getLocales(this.currency_id),
  };

  MessageTemplatesSettings = {};
  smsTemplateList = [];
  smsTemplateSelectedItem = [];
  messageTemplateList = [];
  messageTemplateSelectedItem = [];

  buttonLoading = false;
  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { promotionMessage: PromotionMessage, mode: string, promotionId: number },
    public dialogRef: MatDialogRef<PromotionMessageDetailsComponent>,
    private dropdownHttpService: DropdownHttpService,
    private promotionMessageDataservice: PromotionMessageDataService,
    private LocaleHttpService: LocaleHttpService
  ) { }

  ngOnInit() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
      });
    }
    this.formInit();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(promotionMessage: PromotionMessage, mode: string) {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });
    const data = {
      id: promotionMessage ? promotionMessage.promotion_name_id : null,
      promotion_id: this.data.promotionId,
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || (data[key] === '')) && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.promotionMessageDataservice.updatePromotionNames(data).pipe(
          tap((res: any) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.onCloseDialog();
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.promotionMessageDataservice.add(data).pipe(
            tap((res: any) => {
              this.form.setErrors(null);
              this.buttonLoading = false;
              this.onCloseDialog();
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.promotionMessageDataservice.messages$
        ]).subscribe();
        break;
    }
  }

  private formInit() {
    let currency = null;
    let locale = null;
    let promotion_name = null;
    let rewards_name = null;

    if (this.data.mode === 'edit') {
      currency = this.data.promotionMessage.currency_id;
      locale = this.data.promotionMessage.settings_locale_id;
      promotion_name = this.data.promotionMessage.promotion_name;
      rewards_name = this.data.promotionMessage.rewards_name;
    }

    this.form = new FormGroup({
      currency_id: new FormControl(currency, [Validators.required]),
      settings_locale_id: new FormControl(locale, [Validators.required]),
      promotion_name: new FormControl(promotion_name, [Validators.required]),
      rewards_name: new FormControl(rewards_name)
    });
  }

  // Change locale list based on currency selected if the currency is changed
  onCurrencyChange() {
    if (this.form.value.currency_id !== undefined) {
      this.dropdown.locales = this.LocaleHttpService.getLocales(this.form.value.currency_id);
    }
  }

  // Change dropdown list based on locale selected and reset the selected SMS and message if the locale is changed
  // onLocaleChange() {
  //   if (this.form.value.settings_locale_id !== undefined) {
  //     this.dropdown.smstemplates = this.messageTemplateDataService.getMessageTemplateList(2, this.form.value.settings_locale_id, 8), // Get sms templates with type = 2 (SMS) and section = 8 (Promotions)
  //     this.dropdown.messagetemplates = this.messageTemplateDataService.getMessageTemplateList(1, this.form.value.settings_locale_id, 8) // Get message templates with type = 1 (Message) and section = 8 (Promotions)

  //     // Dropdown list for SMS templates
  //     this.dropdown.smstemplates.subscribe(res => {
  //       this.smsTemplateList = res;
  //       this.smsTemplateSelectedItem = [];
  //       this.form.patchValue({ sms_template_id: null });
  //     })

  //     // Dropdown list for Message templates
      // this.dropdown.messagetemplates.subscribe(res => {
      //   this.messageTemplateList = res;
      //   this.messageTemplateSelectedItem = [];
      //   this.form.patchValue({ message_template_id: null });
      // })
  //   }
  // }
}
