import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';
import { SMSRecoveryReportDetail } from '@core/models/sms-recovery-report-detail.model';

@Injectable()
export class SMSRecoveryReportDetailDataService extends DefaultDataService<SMSRecoveryReportDetail>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('SMSRecoveryReportDetail', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(params: string): Observable<SMSRecoveryReportDetail[]>{
    return this.http.get<ApiResponse>(`/smsprovider/report/details/${params}`).pipe(
      tap(res => {
        this.paginationInit(res);
      }),
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if (res){
      this.pagination = res.data.paginations;
    }
  }

}
