import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Member } from '@core/models/member.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MemberVerificationDataService } from '../../services/member-verification-data.service';

@Component({
  selector: 'kt-member-verification-details',
  templateUrl: './member-verification-details.component.html',
  styleUrls: ['./member-verification-details.component.scss']
})
export class MemberVerificationDetailsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = this.memberVerificationDataService.messages$;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  dropdown = {
    statuses:  this.dropdownHttpService.statuses,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { memberVerification: Member },
    public dialogRef: MatDialogRef<MemberVerificationDetailsComponent>,
    private memberVerificationDataService: MemberVerificationDataService,
    private dropdownHttpService: DropdownHttpService,
    private authHttpService: AuthHttpService
  ) { }

  ngOnInit() {
    this.formInit();

      const username = this.data.memberVerification.username;
      return this.memberVerificationDataService.getVerifyStatus(username)
      .subscribe()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onVerify(memberVerification: Member) {
    this.buttonLoading = !this.buttonLoading;
    const data = {
      ...this.form.value,
      sms_verified: this.form.value.sms_verified === 'Yes' ? 1 : 0,
      id: memberVerification.id,
      status: 1,
      member_account_id: this.data.memberVerification.member_account_id,
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    this.subscription = this.memberVerificationDataService.updateVerification(data).pipe(
      tap((res: any) => {
        this.messages$.next([...res.message]);
        this.buttonLoading = !this.buttonLoading;
      }),
      catchError((error) => {
        this.buttonLoading = !this.buttonLoading;
          this.form.setErrors(null);
          throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private formInit() {
    let username = null;
    let name = null;
    let smsVerified = null;
    let statusName = null
    let remarks = null;

    username =  this.data.memberVerification.username;
    name =  this.data.memberVerification.name;
    smsVerified = (+this.data.memberVerification.sms_verified === 1) ? 'Yes' : 'No';
    statusName = this.data.memberVerification.status_name;
    remarks = this.data.memberVerification.remarks;

    this.form = new FormGroup ({
      username: new FormControl(username),
      name: new FormControl(name),
      sms_verified: new FormControl(smsVerified),
      status_name: new FormControl(statusName),
      remarks: new FormControl(null)
    })
  }

}
