import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { VerificationSettingBankDataService } from '../services/verification-settings-bank-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, catchError } from 'rxjs/operators';
import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';

@Component({
  selector: 'kt-verification-settings-bank-detail',
  templateUrl: './verification-settings-bank-detail.component.html',
  styleUrls: ['./verification-settings-bank-detail.component.scss']
})
export class VerificationSettingsBankDetailComponent implements OnInit {

  form: FormGroup;
  dropdown = {
    bankType: [],
    merchantBanks: [],
  };
  selectedBank = [];

  merchantBanksDropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    searchBy: ["name"],
    classes: 'dropdown',
    primaryKey: 'id',
    labelKey: 'name',
    noDataLabel: '',
    showCheckbox: false,
    autoPosition: false,
    position: 'bottom',
  };
  buttonLoading = false;
  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, verificationSettingsID: number, currencyID: number, bankTypeID: number, new_currency: boolean },
    private dropdownHttpService: DropdownHttpService,
    public dialogRef: MatDialogRef<VerificationSettingsBankDetailComponent>,
    private merchantBankHttpService: MerchantBankHttpService,
    private verificationSettingBankDataService: VerificationSettingBankDataService,
  ) { }

  ngOnInit() {
    this.dropdownHttpService.merchantBankTypes.subscribe(res => {
      this.dropdown.bankType = res.filter(item => item.id === 1 || item.id === 3);
    });

    this.formInit();
  }

  onSelectBankType() {
    let bankTypeID = this.form.value.bank_type;

    this.merchantBankHttpService.getMemberMerchantBanks(`&currency_id=${this.data.currencyID}&bank_type=${bankTypeID}&paginate=0`).subscribe(res => {
      this.dropdown.merchantBanks = res;
    });
  }

  private formInit() {
    let bank_type = this.data.bankTypeID ?? null;

    this.form = new FormGroup({
      bank_type: new FormControl(bank_type, [Validators.required]),
      bank: new FormControl(null, [Validators.required]),
    });

    if (this.data.bankTypeID != null) this.onSelectBankType();
  }

  onSave() {
    this.buttonLoading = true;

    let data = {
      ...this.form.value,
    };

    if (this.data.mode == 'create' || (this.data.mode == 'edit' && this.data.new_currency)) {
      this.setBankLocalStorage();
      this.dialogRef.close(true);
    } else if (this.data.mode == 'edit') {
      data = {
        ...data,
        verification_setting_id: this.data.verificationSettingsID,
        settings_currency_id: this.data.currencyID,
      }

      this.subscription = this.verificationSettingBankDataService.addBank(data).pipe(
        tap(() => {
          localStorage.setItem('updateVerificationSettingsBank', '1');
          this.dialogRef.close(false);
        }),
        catchError((error) => {
          throw error;
        })
      ).subscribe();
    }
  }

  setBankLocalStorage() {
    let data = {
      ...this.form.value,
    };
    let updatedData = [];
    const currencyID = this.data.currencyID;
    const verificationSettingsBank = JSON.parse(localStorage.getItem('createVerificationSettingsBank')) || [];

    this.selectedBank.forEach((result, index) => {
      const itemExists = verificationSettingsBank.some(res => +res.id === +result.id);

      if (!itemExists) {
        // Create a new data object if the item doesn't exist
        data = {
          id: result.id,
          bank_type_name: result.bank_type_name,
          name: result.name,
          settings_currency_id: currencyID
        };
        // Push the new data into updatedData array
        updatedData.push(data);
      }
    });

    updatedData.push(...verificationSettingsBank);

    localStorage.setItem('createVerificationSettingsBank', JSON.stringify(updatedData));
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onSelectedBankChanged(value) {
    this.selectedBank = value;
  }
}
