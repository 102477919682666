<ng-container *ngIf="loading; else notLoading">
  <div class="col-12 mt-5 d-flex justify-content-center">
    <mat-spinner [diameter]="35"></mat-spinner>
  </div>
</ng-container>

<ng-template #notLoading>
  <ng-container *ngIf="lc_token != null && lc_token != 'null' && lc_token != undefined; else noData">
    <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
          <div class="kt-form kt-form--label-right">
            <div class="row align-items-center">
              <div class="col-xl-12 order-2 order-xl-1 pr-0">
                <div class="col-12 row pr-0">
                  <iframe class="live-chat-iframe" [src]="safeUrl | safe:'resourceUrl'" height="600" width="100%">
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-container>
  <ng-template #noData>
    <div class="col-12 mt-5 d-flex justify-content-center">
      <label class="control-label">
        <h5> No Data Available</h5>
      </label>
    </div>
  </ng-template>
</ng-template>
