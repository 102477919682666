<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="align-items-center" [formGroup]="form">
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>ID:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="id" placeholder="Search" class="form-control">
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Parent:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="parent" (input)="toLowerCaseInput('parent', $event)" placeholder="Search" class="form-control">
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies"> {{ value.name }} </option>
                                    </select>
                                </div>
                                
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Partner Network: </label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="partner_network" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.partnerNetwork"> {{ value.name }} </option>
                                    </select>
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Group:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="affiliate_group_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.groups | async"> {{ value.name }} </option>
                                    </select>
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Code:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="code" placeholder="Search" class="form-control">
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Contact: </label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <select class="form-control" formControlName="contact_type" (change)="onContactType($event.target.value)">
                                                <option value="Mobile">Mobile</option>
                                                <option value="Email">Email</option>
                                            </select>
                                        </div>
                                        <input type="text" (change)="onContact($event)" placeholder="Search" class="form-control" [disabled]="(selectedContactType === '' && selectedContactType === null)" #contactRef id="contactRefValue">
                                        <input type="hidden" formControlName="mobile" />
                                        <input type="hidden" formControlName="email" />
                                    </div>
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Remarks:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="remarks" placeholder="Search" class="form-control">
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="status" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.statuses"> {{ value.name }} </option>
                                    </select>
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Dummy Account:<i class="fa fa-info-circle ml-1" placement="bottom" [ngbTooltip]="dummyToolTip" tooltipClass="tooltip-class"></i></label>
                                </div>  
                                <div class="col-md-2 kt-form__control">
                                    <label class="switch mr-3" style="align-self: center;">
                                        <input type="checkbox" formControlName="dummy" [checked]="form.value.dummy == 1" (change)="onChangeDummy($event)">
                                        <div class="slider round">
                                            <span class="on">INC</span>
                                            <span class="off">EXC</span>
                                        </div>
                                    </label>
                                </div> 
                                <ng-template #dummyToolTip>
                                    <div class="text-left">
                                        INC (Include): Include dummy accounts.<br>EXC (Exclude): Exclude dummy accounts.
                                    </div>
                                </ng-template>
                            </div>

                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <select class="form-control" formControlName="date_type">
                                                <option value="registration_date" selected>Registration Date</option>
                                                <option value="updated_date">Updated Date</option>
                                            </select>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate"
                                        [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true"
                                        [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>  
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

            <!--Action Button-->
            <div class="kt-section col-12 row mb-3 mt-1 justify-content-between">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <div class="dropdown dropdown-inline">
                    <button class="btn btn-warning btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="buttonLoading">
                      <i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export
                    </button>
                    <button class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')">
                        <i class="fas fa-plus"></i>Create
                    </button>
                </div>
            </div>

            <!--Listing-->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('parent_username')" [ngClass]="sortingConfig.parent_username" [class.sort-selected]="sortingSelection.sort_by === 'parent_username'">Parent</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                                    <th class="text-center" colspan="5">Affiliate Info</th>
                                    <th class="text-center" colspan="3">Affiliate Count</th>
                                    <th class="text-center" colspan="2">Wallet Balance</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('register_date')" [ngClass]="sortingConfig.register_date" [class.sort-selected]="sortingSelection.sort_by === 'register_date'">Registration Info</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('remarks')" [ngClass]="sortingConfig.remarks" [class.sort-selected]="sortingSelection.sort_by === 'remarks'">Remarks</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                                    <th rowspan="2" class="text-center">Actions</th>
                                </tr>
                                <tr>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('currency_code')" [ngClass]="sortingConfig.currency_code" [class.sort-selected]="sortingSelection.sort_by === 'currency_code'">Currency</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('code')" [ngClass]="sortingConfig.code" [class.sort-selected]="sortingSelection.sort_by === 'code'">Code</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('partner_network_name')" [ngClass]="sortingConfig.partner_network_name" [class.sort-selected]="sortingSelection.sort_by === 'partner_network_name'">Partner Network</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('affiliate_group')" [ngClass]="sortingConfig.affiliate_group" [class.sort-selected]="sortingSelection.sort_by === 'affiliate_group'">Group</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('total_sub_affiliates')" [ngClass]="sortingConfig.total_sub_affiliates" [class.sort-selected]="sortingSelection.sort_by === 'total_sub_affiliates'">Sub-Affiliate</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('total_members')" [ngClass]="sortingConfig.total_members" [class.sort-selected]="sortingSelection.sort_by === 'total_members'">Member</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('total_clicks')" [ngClass]="sortingConfig.total_clicks" [class.sort-selected]="sortingSelection.sort_by === 'total_clicks'">Clicks</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('main_wallet')" [ngClass]="sortingConfig.main_wallet" [class.sort-selected]="sortingSelection.sort_by === 'main_wallet'">Main</th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('deposit_wallet')" [ngClass]="sortingConfig.deposit_wallet" [class.sort-selected]="sortingSelection.sort_by === 'deposit_wallet'">Deposit</th>
                                </tr>

                            </thead>
                            <tbody *ngIf="(allAffiliates$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td class="text-center">{{ row.id }}</td>
                                    <td class="text-center">{{ row.parent_username === null ? '-' : row.parent_username }}</td>
                                    <td>
                                        <a class="text-primary font-weight-bold" (click)="onOpenDialog('information', row)">
                                            {{ row.username }}
                                        </a>
                                    </td>
                                    <td>{{ row.name }}</td>
                                    <td class="text-center">{{ row.currency_code }}</td>
                                    <td class="text-center">{{ row.code === '' ? '-' : row.code }}</td>
                                    <td class="text-center">{{ row.partner_network_name === '' ? '-' : row.partner_network_name }}</td>
                                    <td>{{ row.affiliate_group }}</td>
                                    <td class="text-center">
                                        <a class="text-primary font-weight-bold" (click)="onOpenDialog('subAffiliates', row)">
                                            {{ row.total_sub_affiliates | number : '1.0-0' }}
                                        </a>
                                    </td>
                                    <td class="text-center">
                                        <a class="text-primary font-weight-bold" (click)="onOpenDialog('affiliateMembers', row)">
                                            {{ row.total_members | number : '1.0-0' }}
                                        </a>
                                    </td>
                                    <td class="text-center">{{ row.total_clicks | number : '1.0-0' }}</td>
                                    <td class="text-right">{{ row.main_wallet | number : '1.2-2' }}</td>
                                    <td class="text-right">{{ row.deposit_wallet | number : '1.2-2' }}</td>
                                    <td class="width-170">        
                                        <span class="no-wrap">{{ (row.register_date | timezoneDate: 'YYYY-MM-DD HH:mm:ss')}}</span><br>
                                        <span placement="top" [ngbTooltip]="row.registration_ip" container="body" class="text-ellipsis"> {{ row.registration_ip }}</span>
                                        <ng-container *ngIf="urlIsValid(row.registration_site); else invalidRegistrationSite">
                                            <a [href]="row.registration_site" target="_blank"> {{ getUrl(row.registration_site) }}</a><br>
                                        </ng-container>
                                        <ng-template #invalidRegistrationSite>
                                            <a> {{ row.registration_site }}</a><br>
                                        </ng-template>
                                        {{ row.registration_locale_code }}     
                                    </td>
                                    <td class="text-center">
                                        <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status_name }}</span>
                                    </td>
                                    <td [ngClass]="row.remarks ? 'text-left' : 'text-center'">
                                        <ng-container *ngIf="(row.remarks); else nullTemplate">
                                            <ng-container *ngIf="canViewRemarkHistory; else noViewRemarkHistory">
                                                <a class="text-primary font-weight-bold" (click)="onOpenDialog('showRemark', row)">
                                                    {{ row.remarks }}
                                                </a>
                                            </ng-container>
                                            <ng-template #noViewRemarkHistory>
                                                {{ row.remarks }}
                                            </ng-template>
                                        </ng-container>
                                        <ng-template #nullTemplate>
                                            -
                                        </ng-template>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="row.update_action == 1 || row.updated_by != null">
                                            {{ row.updated_by ? row.updated_by : 'System' }}<br/>
                                            <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                            <ng-template #updatedDateTime>
                                                {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                            </ng-template>
                                        </ng-container>
                                    </td>
                                    <td class="text-center actions-column">
                                      <div class="button-container">
                                        <button matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
                                        <button matTooltip="Suspend" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onSuspend(row.id, row.username)"><i class="fas fa-user-slash"></i></button>
                                        <button matTooltip="Change Password" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('resetPassword', row)"><i class="fas fa-key"></i></button>
                                        <button matTooltip="Adjust Group" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('adjustGroup', row)"><i class="fas fa-sliders-h"></i></button>
                                        <button
                                            matTooltip="Shadow Login"
                                            class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"
                                            (click)="onShadowLogin(row)"
                                            *ngIf="(userPermissions$ | async).shadow_login || isAdminUser"
                                        >
                                            <i class="fas fa-sign-in-alt"></i>
                                        </button>
                                        <button matTooltip="History" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('testFirePostbackLogs', row)"><i class="fas fa-history"></i></button>
                                      </div>
                                    </td>
                                </tr>
                                <!-- <kt-fallback-row [collection]="rows"></kt-fallback-row> -->
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                            [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onSubmit()"></kt-swal-alert>
