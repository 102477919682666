import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { FaqSections } from '@core/models/faq-sections.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FaqSectionsDataService } from './services/faq-sections-data.service';
import { FaqSectionsEditDialogComponent } from './dialogs/faq-sections-edit.component';
import { Subscription, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
@Component({
  selector: 'kt-faq-sections',
  templateUrl: './faq-sections.component.html',
  styleUrls: ['./faq-sections.component.scss']
})
export class FaqSectionsComponent implements OnInit {

  pagination: Pagination;
  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };
  sortingSelection = {
    'sort_by': 'position'
  }
  messages$ = this.faqSectionsDataService.messages$;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  faqSections$: Observable<FaqSections[]>;
  private subscription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private faqSectionsDataService: FaqSectionsDataService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.onViewPageBy(this.page, this.pageSize, this.params);
    this.pagination = this.faqSectionsDataService.pagination;
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    //params = this.params ? `&${this.params}` : '';
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    this.loadingBar.start();
    return this.faqSections$ = this.faqSectionsDataService.getWithQuery(`?page=${page}&perPage=${pageSize}&${sortingParams}`).pipe(
      tap(res => {
        this.pagination = this.faqSectionsDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onReload() {
    this.onViewPageBy(this.page);
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, faqSectionsId?: any) {
    if (faqSectionsId) {
      this.faqSectionsDataService.getById(faqSectionsId).subscribe(
        res => {
          switch (type) {
            case 'edit':
              this.openDialogBy(FaqSectionsEditDialogComponent, { mode: 'edit', faqSections: res });
              break;
          }
        }
      )
    } else {
      this.openDialogBy(FaqSectionsEditDialogComponent, { mode: 'create' });
    }
  }

  private openDialogBy(componentRef: any, data?: { mode?: string, faqSections?: any }) {
    this.dropdownHttpService.locales.subscribe(locales => {
      const dialogRef = this.dialog.open(componentRef, {
        width: '800px',
        data: {
          sections: data.faqSections,
          mode: data.mode,
          locales: locales
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
          this.onReload();
      });
    })

  }

}
