<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form" [formGroup]="form">
        <div class="modal-content">
            <!-- Header -->
            <div class="modal-header">
                <h5 class="modal-title">{{ data.maintenanceMode === 'create' ? 'Create' : 'Edit' }} Game Provider Maintenance Hour</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <!-- Body -->
            <div class="modal-body">
                <!-- First Row -->
                <div class="row">
                    <!-- Game Provider -->
                    <div class="col-md-6 form-group required">
                        <label>Game Provider <span class="text-danger">*</span></label>
                        <select formControlName="game_provider_code" class="form-control">
                            <option value="null" disabled hidden> Please Select </option>
                            <option [value]="value.code" *ngFor="let value of dropdown.gameProviders">
                                {{ value.code +' - '+ value.name }}
                            </option>
                        </select>
                        <i *ngIf="dropdown.gameProviders.length === 0"
                            class="fas fa-circle-notch fa-spin p-0 mr-2 dropdown-loading"></i>
                    </div>
                    <!-- Category -->
                    <div class="col-md-6 form-group required">
                        <label>Category <span class="text-danger">*</span></label>
                        <select formControlName="category_code" class="form-control">
                            <option value="null" disabled hidden> Please Select </option>
                            <option *ngIf="dropdown.categories.length > 1" value="all"> All </option>
                            <option *ngFor="let value of dropdown.categories" [value]="value.category_code">{{
                                value.category_name }}
                            </option>
                        </select>
                        <i *ngIf="dropdown.categories.length === 0 && form.value.game_provider_code !== null"
                            class="fas fa-circle-notch fa-spin p-0 mr-2 dropdown-loading"></i>
                    </div>
                    <!-- Maintenance Hour -->
                    <div class="col-md-12">
                        <p class="control-label">Maintenance Hour</p>
                    </div>
                    <!-- From -->
                    <div class="col-md-6 form-group required">
                        <label class="control-label">From <span class="text-danger">*</span></label>
                        <div class="input-group date">
                            <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack?.maintain_from" [ngModelOptions]="{standalone: true}">
                            <input type="text" formControlName="maintain_from" class="form-control">
                            <span class="input-group-append" [owlDateTimeTrigger]="dateStartRef">
                                <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                            </span>
                            <owl-date-time #dateStartRef></owl-date-time>
                        </div>
                    </div>
                    <!-- To -->
                    <div class="col-md-6 form-group">
                        <label class="control-label">To</label>
                        <div class="input-group date">
                            <input class="hidden" [owlDateTime]="dateEndRef" [ngModel]="dateTimeStack?.maintain_to" [ngModelOptions]="{standalone: true}">
                            <input type="text" formControlName="maintain_to" class="form-control">
                            <span class="input-group-append" [owlDateTimeTrigger]="dateEndRef">
                                <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                            </span>
                            <owl-date-time #dateEndRef></owl-date-time>
                        </div>
                    </div>
                    <!-- Currency -->
                    <div class="col-6 form-group">
                        <label>Currency <span class="text-danger">*</span></label>
                        <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'dropdown.currencies'
                            [dropdownSettings] = 'currencyDropdownSettings'
                            [formName] = "'currency_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] = 'currencySelectedItems'>
                            </kt-dropdown-wo-lazyload>
                    </div>
                    <!-- Remark -->
                    <div class="col-6 form-group">
                        <label>Remarks</label>
                        <select class="form-control" formControlName="remarks">
                            <option value="null" disabled hidden> Please Select </option>
                            <option *ngFor="let value of dropdown.remarks" [value]="value">{{ value }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <!-- Footer -->
            <div class="modal-footer d-flex justify-content-between">
                <button type="button" class="btn btn-success" (click)="onResetMaintenanceForm()"><i
                        class="fas fa-undo"></i>Reset</button>
                <div>
                    <button type="button" class="btn btn-secondary mr-2" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                    <kt-submit-button *ngIf="data.maintenanceMode === 'create' ? canCreateGameProviderMaintenanceHour : canEditGameProviderMaintenanceHour" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.gameProvider)"></kt-submit-button>
                </div>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>