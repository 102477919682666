import { NgSelectModule } from '@ng-select/ng-select';
import { entityMetadata } from '../../../../app.module';
import { EntityDefinitionService, EntityDataService } from '@ngrx/data';
import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DecimalPipe } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PartialsModule } from '@views/partials/partials.module';
import { ReferralCommissionSettingsComponent } from './referral-commission-settings/referral-commission-settings.component';
import { ReferralCommissionSettingsEditDialogComponent } from './referral-commission-settings/dialogs/referral-commission-settings-edit.component';
import { ReferralCommissionSettingsDataService } from './referral-commission-settings/services/referral-commission-settings-data.service';
import { ReferralCommissionSettingsEntityService } from './referral-commission-settings/services/referral-commission-settings-entity.service';
import { ReferralCommissionRoutingModule } from './referral-commission-routing.module';
import { ReferralCommissionHistoryComponent } from './referral-commission-history/referral-commission-history.component';
import { ReferralCommissionHistoryDataService } from './referral-commission-history/services/referral-commission-history-data.service';
import { ReferralCommissionComponent } from './referral-commission/referral-commission.component';
import { ReferralCommissionDataService } from './referral-commission/services/referral-commission-data.service';
import { ReferralCommissionDialogComponent } from './referral-commission/dialogs/referral-commission-releases/referral-commission-generate.component';
import { ReferralCommissionDetailsComponent } from './referral-commission/dialogs/referral-commission-details.component';


@NgModule({
  declarations: [
    ReferralCommissionSettingsComponent, 
    ReferralCommissionSettingsEditDialogComponent, 
    ReferralCommissionHistoryComponent,
    ReferralCommissionComponent,
    ReferralCommissionDialogComponent,
    ReferralCommissionDetailsComponent
  ],
  imports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AngularMultiSelectModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ReferralCommissionRoutingModule,
    SharedModule,
    PartialsModule,
    NgbModule,
    NgSelectModule,
    SharedModule,
    NgxDaterangepickerMd.forRoot()
  ],
  providers: [
    DecimalPipe,
    ReferralCommissionSettingsDataService,
    ReferralCommissionSettingsEntityService,
    ReferralCommissionHistoryDataService,
    ReferralCommissionDataService
  ]
})
export class ReferralCommissionModule {
  constructor(
    private eds: EntityDefinitionService,
    private entityDataService: EntityDataService,
    private referralCommissionSettingsDataService: ReferralCommissionSettingsDataService,
  ) {
    eds.registerMetadataMap(entityMetadata);
    entityDataService.registerServices({
      ReferralCommissionSetting: referralCommissionSettingsDataService,
    });
  }
 }
