import { RebateRelease } from '@core/models/rebate-release-model';
import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class RebateReleaseDataService extends DefaultDataService<RebateRelease>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  currentPageTotal = 0;
  totalAmount = 0;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('RebateRelease', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<RebateRelease[]> {
    return this.http.get<ApiResponse>('/rebate').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<RebateRelease[]> {
    return this.http.get<ApiResponse>(`/rebate${pageParam}`).pipe(
      tap(res => {
        this.paginationInit(res);
        this.currentPageTotal = res.data.current_page_total;
        this.totalAmount = res.data.total_amount;
      }),
      map(res => res.data.rows)
    );
  }

  getById(id: number): Observable<RebateRelease> {
    return this.http.get<ApiResponse>(`/rebate/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  generateRebate(data: any) {
    return this.http.post<ApiResponse>(`/rebate/generate`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  release(data: any) {
    return this.http.post<ApiResponse>(`/rebate/release`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  reject(data: any) {
    return this.http.post<ApiResponse>(`/rebate/reject`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getmemberpromotionlist(data: any): Observable<any[]> {
    return this.http.get<ApiResponse>(`/rebate/memberpromotionlist?${data}`).pipe(
      map(res => res.data.rows)
    );
  }

  gettargetamountdetail(data: any): Observable<any[]> {
    return this.http.get<ApiResponse>(`/rebate/targetamountdetail?${data}`).pipe(
      tap(res => {
      }),map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
