import { Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AccountManagementDataService } from '../../services/account-management-data.service';
import { AccountManagement } from '@core/models/account-management.model';
import { AccountManagementLog } from '@core/models/account-management-log.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';

@Component({
  templateUrl: './account-management-log-dialog.component.html',
  styleUrls: ['./account-management-log-dialog.component.scss']
})
export class AccountManagementLogDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  pagination: Pagination;
  private subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  messages$ = this.AccountManagementDataService.messages$;
  loading = false;
  data$ = this.AccountManagementDataService.data$;
  AccountManagementLog$: Observable<AccountManagementLog[]>;
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
  };
  maxSize = 5;
  pageSize = 30;
  page = 1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { accountManagement: AccountManagement, leadHistoryID: number },
    public dialogRef: MatDialogRef<AccountManagementLogDialogComponent>,
    private AccountManagementDataService: AccountManagementDataService,
    public dialog: MatDialog,
    public loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit() {
    this.getTimeline();
    this.pagination = this.AccountManagementDataService.pagination;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  private getTimeline(page = 1, pageSize?: number) {
      this.loading = true;
      this.loadingBar.useRef().start();
      pageSize = this.pageSize;
      return (this.AccountManagementLog$ = this.AccountManagementDataService
        .getAccountManagementLog(`?page=${page}&perPage=${pageSize}&member_account_id=${this.data.accountManagement.member_account_id}`)
        .pipe(
          tap(res => {
            this.pagination = this.AccountManagementDataService.pagination;
            this.loadingBar.useRef().complete();
            this.loading = false;
          }),
          catchError((err) => {
            this.loading = false;
            this.loadingBar.useRef().complete();
            return throwError(err);
          })
        ));
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.getTimeline(this.page, this.pageSize);
  }

}
