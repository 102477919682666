import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountRoutingModule } from './account-routing.module';
import { SharedModule } from '@shared/shared.module';
import { PartialsModule } from '@views/partials/partials.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountPasswordDialogComponent } from './dialogs/account-password/account-password.component';
import { AccountSettingsDialogComponent } from './dialogs/account-settings/account-settings.component';
import { AccountTimezoneDialogComponent } from './dialogs/account-timezone/account-timezone.component';
import { AccountTwoFADialogComponent } from './dialogs/account-twofa/account-twofa.component';

@NgModule({
  declarations: [AccountSettingsDialogComponent, AccountPasswordDialogComponent, AccountTimezoneDialogComponent, AccountTwoFADialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AccountRoutingModule,
    SharedModule,
    PartialsModule
  ]
})
export class AccountModule { }
