import { CampaignAdCost } from '@core/models/campaign-ad-cost.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class CampaignAdCostEntityService extends EntityCollectionServiceBase<CampaignAdCost> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('CampaignAdCost', serviceElementsFactory);
  }
}
