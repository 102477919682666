import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { BankReceipt } from '@core/models/bank-receipt.model';

@Injectable()
export class BankReceiptDataService extends DefaultDataService<BankReceipt>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  data$ = new Subject<any[]>();
  selectedReceipt$ = new BehaviorSubject<BankReceipt[]>(null);

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('BankReceipt', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<BankReceipt[]> {
    return this.http.get<ApiResponse>('/bankreceipt').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<BankReceipt[]>{
    return this.http.get<ApiResponse>(`/bankreceipt${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  voidReceipt(bankReceipt: BankReceipt) {
    return this.http.put<ApiResponse>(`/bankreceipt/${bankReceipt.id}`, bankReceipt);
  }

  getReceipt(id: number): Observable<BankReceipt[]>{
    return this.http.get<ApiResponse>(`/bankreceipt?id=${id}`).pipe(
      map(res => res.data.rows)
    )
  }

  checkDuplicateReceipt(params: any[]) {
    return this.http.post<ApiResponse>('/bankreceipt/duplicate', params).pipe(
      tap(res => {
        if (res.data?.duplicate_transactions.length != 0) {
          res.data['custom_bank_receipt_check_duplicate_swal'] = true;
          this.messages$.next(res.message);

          // For duplicated receipts
          this.data$.next(res.data);
        }
        
      }),
      map(res => res.data),
    );
  }

  createManualReceipt(params: any[]) {
    return this.http.post<ApiResponse>('/bankreceipt', params);
  }

  updateReceipt(id, params) {
    return this.http.put<ApiResponse>(`/bankreceipt/${id}`, params);
  }

  getRemarks(pageParam: string): Observable<any[]> {
    return this.http.get<ApiResponse>(`/bankreceipt/remarks${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.pagination;
      if (res.data.pagination == null) 
      this.pagination = res.data.paginations;
    }
  }
}
