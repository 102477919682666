import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ReferralCommission } from '@core/models/referral-commission.model';
import { Pagination } from '@core/models/pagination.model';

@Injectable()

export class ReferralCommissionDataService {

  constructor(private http: HttpClient) { }
  pagination: Pagination;
  messages$ = new Subject<any[]>();
  currentPageTotal = 0;
  totalAmount = 0;
  currentPageTotalUSD = 0;
  totalAmountUSD = 0;

  getWithQuery(params?: string): Observable<ReferralCommission[]> {
    return this.http.get<ApiResponse>(`/referralcommission${params}`).pipe(
      tap(res => {
        this.paginationInit(res);
        this.currentPageTotal = res.data.current_page_total;
        this.totalAmount = res.data.total_amount;
      }),
      map(res => res.data.rows)
    );
  }

  getDetails(params?: string) {
    return this.http.get<ApiResponse>(`/referralcommission/show${params}`).pipe(
      tap(res => {
        this.paginationInit(res);
        this.currentPageTotal = res.data.current_page_total;
        this.totalAmount = res.data.total_amount;
        this.currentPageTotalUSD = res.data.current_page_total_convert;
        this.totalAmountUSD = res.data.total_amount_convert;
      }),
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

  generateRebate(data: any) {
    return this.http.post<ApiResponse>(`/referralcommission/generate`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  release(data: any) {
    return this.http.post<ApiResponse>(`/referralcommission/release`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  reject(data: any) {
    return this.http.post<ApiResponse>(`/referralcommission/reject`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }
}