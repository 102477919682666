import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class AffiliateCampaignHttpService {
  messages$ = new Subject<any[]>();
  
  constructor(private http: HttpClient) { }

  getAffiliateCampaignsDropdown(params?): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/affiliatecampaignsdropdown?${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  getAffiliateCampaignByCurrency(currencyId): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/affiliatecampaign?status=1&currency_id=${currencyId}`).pipe(
      map(res => res.data.rows)
    );
  }
}
