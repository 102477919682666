<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <!-- Header -->
        <div class="modal-header">
            <h5 class="modal-title">Route Settings</h5>
            <span class="modal-x-button" (click)="onCloseDialog()"><i class="fa fa-times" aria-hidden="true"></i></span>
        </div>
        <div class="modal-body">
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
                <div class="dropdown dropdown-inline">
                    <form [formGroup]="searchForm">    
                        <div class="d-flex search-filter">
                            <div class="col-md-3 kt-form__label col-form-label">
                                <label>Keyword:</label>
                            </div>
                            <div class="col-md-9 kt-form__control">
                                <div class="input-group-append">
                                    <input type="text" class="form-control" placeholder="Search" formControlName="keyword">
                                    <button class="btn btn-brand btn-icon-sm btn-search" (click)="onSubmit()"><i class="fas fa-search"></i></button>
                                </div>
                            </div>    
                        </div>
                    </form>
                </div>
                <div class="dropdown dropdown-inline">
                    <button *ngIf="canAddRoute" class="btn btn-brand btn-icon-sm" (click)="onAddRow()" [disabled]="isOnAddClicked"><i class="fas fa-plus"></i>Add</button>
                </div>
            </div>
            <div class="kt-section mb-0">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <form [formGroup]="form">
                            <table class="table table-bordered table-hover table-striped table-sortable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Route</th>
                                        <th>Custom Canonical</th>
                                        <th>Type</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container formArrayName="route">
                                        <ng-container *ngIf="routesArr.controls.length >= 1">
                                            <ng-container *ngFor="let row of routesArr.controls; index as i" [formGroupName]="i"> 
                                                <tr>
                                                    <td><input class="form-control" type="text" formControlName="name" [ngClass]="{'is-invalid': checkValidation && row.controls.name.errors }"></td>
                                                    <td><input class="form-control" type="text" formControlName="route" [ngClass]="{'is-invalid': checkValidation && row.controls.route.errors }"></td>
                                                    <td><input class="form-control" type="text" formControlName="custom_canonical"></td>
                                                    <td>
                                                        <input type="checkbox" formControlName="desktop"[id]="'desktop-'+i"><label [for]="'desktop-'+i" class="pl-1 mb-0"> Desktop</label>
                                                        <br>
                                                        <input type="checkbox" formControlName="mobile" [id]="'mobile-'+i"><label [for]="'mobile-'+i" class="pl-1 mb-0">Mobile</label>
                                                    </td>
                                                    <td class="text-center">
                                                        <button *ngIf="canEditRoute && row.value.mode === 'edit'" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="enableEdit(i)" [disabled]="!row.disabled"><i class="fas fa-edit"></i></button>
                                                        <button *ngIf="canEditRoute" matTooltip="Save" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onSave(row.value, i)" [disabled]="row.disabled"><i class="fas fa-save"></i></button>
                                                        <button *ngIf="canDeleteRoute && row.enabled" matTooltip="Dismiss" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onDismissRow(row.value.mode, i)"><i class="fas fa-times"></i></button>
                                                        <button *ngIf="canDeleteRoute && row.value.mode === 'edit' && row.disabled" matTooltip="Delete" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onDelete(row.value.id)"><i class="fas fa-trash-alt"></i></button>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </tbody>
                                <tbody shared-table-handler [loading]="loading" [dataLength]="route$.length" class="text-gray-600 fw-bold"></tbody>
                            </table>
                        </form>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onSubmit(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                          <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                          </select>
                          <span class="pagination__desc">
                            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                          </span>
                        </div>
                    </div>            
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        </div>
    </div>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onSubmit(page)"></kt-swal-alert>
