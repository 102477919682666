<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Member Report Details: {{ data.memberReport.username }} | {{ timezoneDateWithTimezone(unconvertedFrom, data.formValue.timezone, 'YYYY-MM-DD HH:mm') }} - {{ timezoneDateWithTimezone(unconvertedTo, data.formValue.timezone, 'YYYY-MM-DD HH:mm') }}</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <div class="kt-section col-12 row d-flex mb-0">
        Last Bet Log Sync At <span class="font-weight-bold">{{ lastBetLogSyncAt ? lastBetLogSyncAt : '-'}}</span>
      </div>
      <kt-member-report-list [data]="memberReport$" [betLogsDetails]="true" [loading]="loading" [onSearch]="true" [summary]="summary" [form]="form" [params]="params" [isModal]="true" (submodal)="onOpenDialog($event)" [listType]="'member-report'"></kt-member-report-list>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
  </div>
</div>
