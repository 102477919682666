<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">{{ data.mode === 'edit'? 'Edit' : 'Create'}} Game Tag</h5>
              <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
              </span>
          </div>
          <div class="modal-body">
            <div class="col-12 form-group">
              <label>Tag Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" [attr.disabled]="gameTagName == 'all' ? '' : null" formControlName="name" [ngClass]="{'is-invalid': checkValidation && form.controls.name.errors }">
            </div>
            <div class="col-12 form-group">
                <label>Position <span class="text-danger">*</span></label>
                <input type="number" (wheel)="false" class="form-control" formControlName="position" [ngClass]="{'is-invalid': checkValidation && form.controls.position.errors }">
            </div>
            <div class="col-12 form-group" *ngIf="data.mode == 'edit'">
              <label>Status <span class="text-danger">*</span></label>
              <select class="form-control" *ngIf="dropdown.statuses" formControlName="status" [ngClass]="{'is-invalid': checkValidation && form.controls.status.errors }" [attr.disabled]="gameTagName == 'all' ? '' : null">
                <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
              </select>
            </div>

            <!-- Locales -->
            <div class="col-12" *ngIf="hideLocale == false">
              <table class="table table-bordered" *ngIf="availableLocales as rows">
                <thead bgcolor="#e9ecef">
                  <th class="text-left col-2">Locale</th>
                  <th class="text-center col-10">Display Name</th>
                </thead>
                <tbody formGroupName="game_tag_locales">
                  <tr *ngFor="let row of rows" [formGroupName]="row.id">
                    <td class="text-left">{{ row.code }}
                      <input type="hidden" class="form-control" formControlName="setting_locale_id" [value]="row.id"/>
                    </td>
                    <td class="text-left">
                      <input type="text" class="form-control" formControlName="display_name">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
            <kt-submit-button *ngIf="data.mode === 'edit' ? canEditGameTag : canCreateGameTag" [buttonLoading]="buttonLoading" [isDisabled]="form.invalid" (confirmed)="onSave(data.gameTag, data.mode)" #focusfield></kt-submit-button>
          </div>
      </div>
    </form>
  </div>
  <kt-swal-alert [message]="messages$ | async"></kt-swal-alert>