<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <!-- Header -->
      <div class="modal-header">
        <h5 class="modal-title">Edit Permission</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <!-- Body -->
      <div class="modal-body">
        <!-- Section -->
        <div class="col-12 form-group row m-0 pb-4">
          <label class="col-3 m-auto">Section </label>
          <input type="text" class="form-control col-9" [value]="data.permissionDetails.sections[0].name" disabled />
        </div>
        <!-- Sub Section Level One -->
        <div class="col-12 form-group row m-0 pb-4" *ngIf="data.permissionDetails.sections.length > 1">
          <label class="col-3 m-auto">Sub Section <span class="text-danger"></span></label>
          <input type="text" class="form-control col-9" [value]="data.permissionDetails.sections[1].name" disabled />
        </div>
        <!-- Sub Section Level Two -->
        <div class="col-12 form-group row m-0 pb-4" *ngIf="data.permissionDetails.sections.length > 2">
          <label class="col-3 m-auto">Second Sub Section <span class="text-danger"></span></label>
          <input type="text" class="form-control col-9" [value]="data.permissionDetails.sections[2].name" disabled />
        </div>
        <!-- Action -->
        <div class="col-12 form-group row m-0 pb-4">
          <label class="col-3 m-auto">Action <span class="text-danger"></span></label>
          <input type="text" class="form-control col-9" [value]="data.permissionDetails.action_title" disabled />
        </div>
        <!-- Details -->
        <mat-tab-group class="col-12 form-group" formGroupName="details">
          <ng-container *ngIf="data.permissionDetails.details.length > 0">
            <mat-tab *ngFor="let item of data.permissionDetails.details" [formGroupName]="item.lang_code">
              <ng-template mat-tab-label>
                {{ item.lang_code }}
                <mat-icon *ngIf="data.mode === 'edit' && data.promotionContent.details[item.lang_code] !== undefined" class="btn-outline-hover-info btn-icon btn-delete" (click)="onDeleteLocale(data.promotionContent.details[item.lang_code], $event)"><i class="fas fa-trash-alt"></i></mat-icon>
              </ng-template>
              <!-- Display Title -->
              <div class="col-12 form-group">
                <label>Display Title <span class="text-danger">*</span></label>
                <input type="text" formControlName="display_title" class="col-12 form-control" />
              </div>
              <!-- Description -->
              <div class="col-12 form-group">
                <label>Description <span class="text-danger">*</span></label>
                <textarea formControlName="description" rows="5" class="col-12 form-control"></textarea>
              </div>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </div>
      <!-- Footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button *ngIf="canEditPermission" [buttonLoading]="buttonLoading" [isDisabled]="form.invalid" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true)"></kt-swal-alert>
