<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Bank List</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 form-group">
              <label class="control-label">Bank Type <span class="text-danger">*</span></label>
              <select class="form-control" formControlName="bank_type" placeholder="Please Select" (change)="onSelectBankType()" disabled>
                <option [value]="null" [disabled]="true">Please Select</option>
                <ng-container *ngFor="let value of dropdown.bankType">
                  <option [value]="value.id">{{ value.name }}</option>
                </ng-container>
              </select>
            </div>

            <div class="col-md-12 form-group">
              <label class="control-label" *ngIf="this.form.get('bank_type').value == 1">Bank <span class="text-danger">*</span></label>
              <label class="control-label" *ngIf="this.form.get('bank_type').value == 3">E-Wallet <span class="text-danger">*</span></label>
              <kt-dropdown-wo-lazyload
                [form] = 'form'
                [dropdownList] = 'dropdown.merchantBanks'
                [dropdownSettings] = 'merchantBanksDropdownSettings'
                [formName] = "'bank'"
                [selectionAttributes] = "'id'"
                (selectedItemsChanged)="onSelectedBankChanged($event)">
              </kt-dropdown-wo-lazyload> 
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button [isDisabled]="false" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
        </div>
    </div>
  </form>
</div>
<kt-swal-alert (confirmed)="onCloseDialog($event)"></kt-swal-alert>