<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">All Affiliate Member</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <div class="col-12 form-group">
        <div class="table-responsive mb-2">
          <table class="table table-bordered table-hover table-striped">
            <thead>
                <tr class="text-top">
                    <th rowspan="2" class="text-center">ID</th>
                    <th rowspan="2" class="text-center">Username</th>
                    <th rowspan="2" class="text-center">Currency</th>
                    <th rowspan="2" class="text-center">Group</th>
                    <th rowspan="2" class="text-center">Game Account</th>
                    <th colspan="8" class="text-center">Deposit/Withdrawal/Transfer</th>
                    <th colspan="9" class="text-center">Bets</th>
                    <th colspan="3" class="text-center">Promotion</th>
                    <th colspan="2" class="text-center">Company Processing Fee</th>
                    <th rowspan="2" class="text-center">Net Game Revenue (NGR)</th>
                </tr>
                <tr>
                    <th class="text-center">Total Deposit Count</th>
                    <th class="text-center">Total Deposit Amount</th>
                    <th class="text-center">Total Withdrawal Count</th>
                    <th class="text-center">Total Withdrawal Amount</th>
                    <th class="text-center">Total Transfer In Count</th>
                    <th class="text-center">Total Transfer In Amount</th>
                    <th class="text-center">Total Transfer Out Count</th>
                    <th class="text-center">Total Transfer Out Amount</th>
                    <th class="text-center">Total Bet Count</th>
                    <th class="text-center">Total Bet Amount</th>
                    <th class="text-center">Total Valid Bet</th>
                    <th class="text-center">Player W/L</th>
                    <th class="text-center">Total Jackpot Contribution</th>
                    <th class="text-center">Total Jackpot Win</th>
                    <th class="text-center">Nett <br />Jackpot</th>
                    <th class="text-center">Game Bet <br />Amount</th>
                    <th class="text-center">Gross Game Revenue <br />(GGR)</th>
                    <th class="text-center">Total Bonus</th>
                    <th class="text-center">Total Cancelled Bonus</th>
                    <th class="text-center">Total Rebate</th>
                    <th class="text-center">Total Deposit Processing Fee</th>
                    <th class="text-center">Total Withdrawal Processing Fee</th>
                </tr>
            </thead>
            <tbody *ngIf="(allAffMember$ | async) as rows">
              <tr *ngFor="let row of rows">
                  <td>{{ row.id }}</td>
                  <td>{{ row.username }}</td>
                  <td class="text-center">{{ row.currency_code }}</td>
                  <td>{{ row.member_group }}</td>
                  <td>{{ row.game_account }}</td>
                  <td class="text-right">{{ row.deposit_total }}</td>
                  <td class="text-right">{{ row.deposit_total_amount | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.withdraw_total }}</td>
                  <td class="text-right">{{ row.withdraw_total_amount | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.transfer_in_total }}</td>
                  <td class="text-right">{{ row.transfer_in_total_amount | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.transfer_out_total }}</td>
                  <td class="text-right">{{ row.transfer_out_total_amount | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.total_bet_count }}</td>
                  <td class="text-right">{{ row.total_bet_amount | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.total_valid_bet_amount | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.player_win_lose | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.total_jackpot_contribution | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.total_jackpot_win | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.nett_jackpot | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.game_bet_amount | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.gross_game_revenue | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.total_bonus | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.total_cancelled_bonus | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.total_rebate | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.total_deposit_processing_fee | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.total_withdrawal_processing_fee | number : '1.2-2' }}</td>
                  <td class="text-right">{{ row.nett_game_revenue | number : '1.2-2' }}</td>
                </tr>
                <kt-fallback-row [collection]="rows"></kt-fallback-row>
            </tbody>
          </table>
        </div>
        <div class="kt-pagination kt-pagination--brand mt-2 mb-4" *ngIf="pagination !== undefined">
          <ngb-pagination
            [pageSize]="pageSize"
            [(page)]="page"
            [maxSize]="maxSize"
            [directionLinks]="true"
            [boundaryLinks]="true"
            [rotate]="true"
            [collectionSize]="pagination.total"
            (pageChange)="onViewPageBy(page)">
          </ngb-pagination>
          <div class="kt-pagination__toolbar">
            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
            </select>
            <span class="pagination__desc">
              Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
  </div>
</div>
