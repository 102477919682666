import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { TransactionReport } from '@core/models/transaction-report.model';

@Injectable()
export class TransactionReportEntityService extends EntityCollectionServiceBase<TransactionReport>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('TransactionReport', serviceElementsFactory)  }
}
