import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SubheaderService } from '@core/_base/layout';
import { Breadcrumb } from '@core/_base/layout/services/subheader.service';

@Component({
  selector: 'kt-mobile-title',
  templateUrl: './mobile-title.component.html',
  styleUrls: ['./mobile-title.component.scss']
})
export class MobileTitleComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() fluid: boolean;
  @Input() clear: boolean;

  today: number = Date.now();
  title = '';
  desc = '';
  breadcrumbs: Breadcrumb[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    public subheaderService: SubheaderService,
  ) { }

  ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  ngAfterViewInit() {

    const title = this.subheaderService.title$.subscribe(bt => {
      if (bt) {
        Promise.resolve(null).then(() => {
          this.title = bt.title;
          this.desc = bt.desc;
        });
      }
    });
    const breadcrumbs = this.subheaderService.breadcrumbs$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        this.breadcrumbs = bc;
      });
    });

    this.subscriptions.push(
      title,
      breadcrumbs
    );
  }
}
