import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MemberLabel } from '@core/models/member-label.model';
import { Member } from '@core/models/member.model';
import { MemberLabelComponent } from '../member-label.component';
import { MemberLabelHttpService } from '@core/services/member-label-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'kt-member-label-edit',
  templateUrl: './member-label-edit.component.html',
  styleUrls: ['./member-label-edit.component.scss']
})
export class MemberLabelEditComponent implements OnInit {

  form: FormGroup;
  buttonLoading = false;
  messages$ = this.memberLabelHttpService.messages$;
  dropdown = {
    merchant: this.dropdownHttpService.merchantSites,
    label: [],
    statuses: this.dropdownHttpService.statuses,
  }
  dropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'id',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
    disabled: true,
  };
  selectedMember = [];
  linkedMembersdropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'id',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false,
  };
  selectedLinkedMembers = [];
  selectedLabel = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, member: Member, label: MemberLabel, defaultRemark: any },
    public dialogRef: MatDialogRef<MemberLabelComponent>,
    private memberLabelHttpService: MemberLabelHttpService,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.dropdownHttpService.memberLabels.subscribe(label => {
      this.dropdown.label = label;
      this.selectedLabel = this.dropdown.label.filter(x => x.code === this.data.label.code);
    });
    if (this.data.member) {
      this.selectedMember.push({
        id: this.data.member.id,
        username: this.data.member.username
      });
    }
    if (this.data.mode === 'edit') {
      if (this.data.label.links.length > 0) {
        this.selectedLinkedMembers = this.data.label.links;
        this.updateMemberSelection(this.selectedLinkedMembers);
      }
    }
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      ...this.form.value,
      status: this.data.mode === 'create' ? 1 : this.form.value.status,
      default: this.form.value.default == true ? 1 : 0,
      label_code: this.form.value.label_code != 'null' ? this.form.value.label_code : null,
      links: this.selectedLabel.length > 0 ? this.form.value.links : []
    };

    switch (this.data.mode) {
      case 'create':
        this.memberLabelHttpService.add(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
            this.selectedLabel = [];
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.selectedLabel = [];
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'edit':
        this.memberLabelHttpService.update(this.data.label.id, data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
            this.selectedLabel = [];
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.selectedLabel = [];
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
    }
  }

  onSetLabel(event: any) {
    const item = event.target.value;
    this.selectedLabel = this.dropdown.label.filter(x => x.code === item);
    this.form.patchValue({
      label: this.selectedLabel[0]?.name ?? '-'
    });
  }

  onRemoveLinkedMember(id: number) {
    const result = this.selectedLinkedMembers.filter((i: Member) => i.id !== id);
    this.selectedLinkedMembers = result;
    this.updateMemberSelection(this.selectedLinkedMembers);
  }

  private updateMemberSelection(selectedLinkedMembers: Member[]) {
    this.form.patchValue({
      links: selectedLinkedMembers.map(i => i.id)
    });
  }

  private formInit() {
    this.form = new FormGroup({
      merchant: new FormControl(1),
      member_account_id: new FormControl(this.data.mode === 'create' ? this.data.member.id : null),
      links: new FormControl(this.selectedLinkedMembers),
      label: new FormControl(this.data.mode === 'edit' ? this.data.label.label : '-'),
      label_code: new FormControl(this.data.mode === 'edit' ? this.data.label.code : 'null'),
      remarks: new FormControl(this.data.mode === 'edit' ? this.data.defaultRemark : null, [Validators.required]),
      default: new FormControl(this.data.mode === 'edit' ? true : null),
      edit_purpose: new FormControl(this.data.mode === 'edit' ? this.data.label.edit_purpose : null, this.data.mode === 'edit' ? [Validators.required] : null),
      status: new FormControl({ value: this.data.mode === 'edit' ? this.data.label.status : 1, disabled: this.data.mode === 'edit' ? false : true }, this.data.mode === 'edit' ? [Validators.required] : null)
    });
  }

}
