import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { WhatsappNumberEntityService } from '../../services/whatsapp-number-entity.service';
import { WhatsappNumber } from '@core/models/whatsapp-number.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Subscription, forkJoin, of } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { WhatsappNumberDataService } from '../../services/whatsapp-number-data.service';

@Component({
  templateUrl: './whatsapp-number-edit.component.html',
  styleUrls: ['./whatsapp-number-edit.component.scss']
})
export class WhatsappNumberEditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    statuses:  this.dropdownHttpService.statuses,
    whatsappCurrencies: this.dropdownHttpService.whatsappCurrencies
  };
  buttonLoading = false;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  messages$ = this.whatsappNumberDataService.messages$;
  refreshStatus: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { whatsappNumber: WhatsappNumber, mode: string },
    public dialogRef: MatDialogRef<WhatsappNumberEditDialogComponent>,
    private whatsappNumberService: WhatsappNumberEntityService,
    private whatsappNumberDataService: WhatsappNumberDataService,
    private dropdownHttpService: DropdownHttpService
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onSave(whatsappNumber: WhatsappNumber, mode?: string) {
    this.buttonLoading = true;
    const data = {
      id: whatsappNumber ? whatsappNumber.id : null,
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.whatsappNumberService.update(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.whatsappNumberDataService.add(data).pipe(
            tap((res: any) => {
              this.form.setErrors(null);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.whatsappNumberDataService.messages$
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    let name = null,
    phone_number = null,
    settings_currency_id =  null,
    score = null,
    remark =  null,
    status = 1;

    if(this.data.mode === 'edit') {
      settings_currency_id = this.data.whatsappNumber.settings_currency_id;
      name = this.data.whatsappNumber.name;
      phone_number = this.data.whatsappNumber.phone_number;
      score = this.data.whatsappNumber.score;
      remark = this.data.whatsappNumber.remark;
      status = this.data.whatsappNumber.status;
    }
    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      phone_number: new FormControl(phone_number, [Validators.pattern(/^[0-9\s]+$/), Validators.required]),
      settings_currency_id: new FormControl(settings_currency_id,[Validators.required]),
      score : new FormControl(score),
      remark : new FormControl(remark),
      status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false })
    });

  }

}
