import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DepositDataService } from "@views/pages/apps/general/deposits/services/deposit-data.service";
import { catchError, tap } from "rxjs/operators";

@Component({
  selector: "request-receipt-dialog",
  templateUrl: "./request-receipt-dialog.component.html",
  styleUrls: ["./request-receipt-dialog.component.scss"],
})
export class RequestReceiptDialogComponent implements OnInit {
  form: FormGroup;
  buttonLoading = false;
  messages$ = this.depositDataService.messages$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { deposit: any },
    public dialogRef: MatDialogRef<RequestReceiptDialogComponent>,
    private depositDataService: DepositDataService
  ) {}

  ngOnInit() {
    this.formInit();
  }

  onSubmit() {
    this.buttonLoading = true;

    const data = {
      id: this.data.deposit.id,
      ...this.form.value,
    };

    Object.keys(data).forEach((key) => (data[key] == null || data[key] === "") && delete data[key]);
    this.depositDataService
      .requestReceipt(data)
      .pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      )
      .subscribe();
  }

  formInit() {
    this.form = new FormGroup({
      remark: new FormControl("Receipt Requested", [Validators.required]),
      reason: new FormControl(""),
    });
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close(event);
  }
}
