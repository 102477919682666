// Angular or Core
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
// Model
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { MemberOnline } from '@core/models/member-online.model';
// Third Party
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class MemberOnlineDataService extends DefaultDataService<MemberOnline> {

  pagination: Pagination;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super('MemberOnline', http, httpUrlGenerator, { root: '' });
  }

  getMemberOnline(param?: string): Observable<MemberOnline> {
    return this.http.get<ApiResponse>(`/member/online${param}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows),
    );
  }

  private paginationInit(res: any) {
    if (res) {
      return this.pagination = res.data.paginations;
    }
  }

}
