import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { PromotionMessage } from '@core/models/promotion-message.model';

@Injectable()
export class PromotionMessageDataService extends DefaultDataService<PromotionMessage> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('PromotionMessages', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<PromotionMessage[]> {
    return this.http.get<ApiResponse>('/promotionname').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(promotionMessage?: PromotionMessage): Observable<PromotionMessage>{
    return this.http.post<ApiResponse>(`/promotionname`, promotionMessage).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/promotionname/${entity.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  updatePromotionNames(data: PromotionMessage): Observable<PromotionMessage>{
    return this.http.put<ApiResponse>(`/promotionname/${data.id}`, data).pipe(
      tap( res => this.messages$.next(res.message)),
      map((row: ApiResponse) => row.data.rows)
    );
  }

  getById(id: number): Observable<PromotionMessage>{
    return this.http.get<ApiResponse>(`/promotionname/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<PromotionMessage[]>{
    return this.http.get<ApiResponse>(`/promotionname${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }

}
