import { MerchantBankHttpService } from '@core/services/merchant-bank-http.service';
import { tap, catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MemberBankAccount } from '@core/models/member-bank-account.model';
import { Subscription } from 'rxjs';
import { MemberCryptoWalletHttpService } from '@core/services/member-crypto-wallet-http.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Member } from '@core/models/member.model';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CryptoTokenHttpService } from '@core/services/crypto-token-http.service';
import { MemberCryptoWallet } from '@core/models/member-crypto-wallet.mode';
import { of } from 'rxjs';

@Component({
  selector: 'kt-member-crypto-account-edit',
  templateUrl: './member-crypto-account-edit.component.html',
  styleUrls: ['./member-crypto-account-edit.component.scss']
})
export class MemberCryptoAccountEditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = this.memberCryptoAccountHttpService.messages$;
  private subscription = new Subscription();
  private messageSubscription = new Subscription();


  filteredTokenDropdownList = [];
  tokenDropdownSettings = {};
  tokenDropdownList = [];
  tokenSelectedItems: any;
  buttonLoading = false;
  selectedNetwork = '';
  refreshStatus: boolean;
  isPaynowDataValid = false;
  validated = false;
  showError = false;
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { member: Member, cryptoAccount: MemberCryptoWallet, mode: string },
    public dialogRef: MatDialogRef<MemberCryptoAccountEditDialogComponent>,
    private memberCryptoAccountHttpService: MemberCryptoWalletHttpService,
    private cryptoTokenHttpService: CryptoTokenHttpService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.dropdownDynamicWidth();
    this.cryptoTokenHttpService.getTokensByCurrency(this.data.member.settings_currency_id).subscribe(
      res => {
        this.tokenDropdownList = res.map((elm: any) => {
          elm.token_network = elm.token_name;
          return elm;
        });
        this.filteredTokenDropdownList = this.tokenDropdownList;
        if (this.data.mode === 'edit'){
          this.tokenSelectedItems = [this.filteredTokenDropdownList.find(v => v.token_id === this.data.cryptoAccount.crypto_token_id)];
          this.selectedNetwork = this.data.cryptoAccount.network;
        }
      }
    );
    this.tokenDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'token_network',
      lazyLoading: false,
      noDataLabel: '',
      showCheckbox: false,
      autoPosition: false,
      disabled: this.data.mode === 'edit'
    };

    // Commented this part to allow editing & updating bank field //
    /*if (this.data.mode === 'edit')
    {
      this.bankDropdownSettings['disabled'] = true;
    }*/
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave() {
    this.buttonLoading = true;
    const data = {
      id: this.data.cryptoAccount ? this.data.cryptoAccount.id : null,
      member_account_id: this.data.member.id,
      ...this.form.value,
    }; 
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);

    switch (this.data.mode) {
      case 'edit':
        this.subscription = this.memberCryptoAccountHttpService.update(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = this.memberCryptoAccountHttpService.add(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onTokenChanged(token: any[]) {
    let selectedTokenId = '';
    this.selectedNetwork = '';
    if (token.length > 0) {
      selectedTokenId = token[0].token_id;
      this.selectedNetwork = token[0].network;
    }
    this.form.patchValue({
      crypto_token_id: selectedTokenId,
    });
    this.form.get('crypto_token_id').updateValueAndValidity();
    this.resetForm();
  }

  numberOnly(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  private formInit() {
    let tokenId = null;
    let walletAddress = null;
    let walletNickname = null;
    if (this.data.mode === 'edit') {
      tokenId = this.data.cryptoAccount.crypto_token_id;
      walletAddress = this.data.cryptoAccount.wallet_address;
      walletNickname = this.data.cryptoAccount.wallet_nickname;
    }
    this.form = new FormGroup({
      member_account_id: new FormControl(this.data.member.id),
      crypto_token_id: new FormControl(tokenId, [Validators.required]),
      wallet_address: new FormControl({value: walletAddress, disabled: this.data.mode === 'edit'}, [Validators.required]),
      wallet_nickname: new FormControl(walletNickname),
    });
    this.setupDebouncing('wallet_address');
  }

  setupDebouncing(controlName: string) {
    const wallet_address_control = this.form.get(controlName);
    this.form.get(controlName).valueChanges.subscribe(query => { wallet_address_control.setErrors({ invalidWallet: true });})
    this.form.get(controlName).valueChanges.pipe(
      debounceTime(400), // Adjust the debounce time as needed
      distinctUntilChanged(),
      switchMap(query => {
        const wallet_address = this.form.get(controlName)?.value;
        if (wallet_address) {
          this.validated = false;
          this.isLoading = true;
          const network = this.selectedNetwork ?? '';
          const queryString = `?wallet_address=${wallet_address}&network=${network}`;
          return this.memberCryptoAccountHttpService.validateWalletAddress(queryString)
            .pipe(
              catchError(err => {
                this.isLoading = false;
                this.showError = true;
                this.validated = false;
                return of(false);
              })
            );
        } else {
          return of(null);
        }
      }),
    ).subscribe(res => {
      if (res == true) {
        wallet_address_control.setErrors(null);
        this.validated = true;
        this.showError = false;
      } else if (res == false) {
        this.showError = true;
        this.validated = false;
        wallet_address_control.setErrors({ invalidWallet: true });
      }
      this.isLoading = false;
    });
  }

  private resetForm() {
    this.form.get('wallet_address').setValidators([]);
    this.form.get('wallet_address').updateValueAndValidity();
    this.form.get('wallet_nickname').setValidators([]);
    this.form.get('wallet_nickname').updateValueAndValidity();
    this.form.patchValue({
      wallet_nickname: this.data.mode === 'edit' ? this.data.cryptoAccount.wallet_nickname : null,
      wallet_address: this.data.mode === 'edit' ? this.data.cryptoAccount.wallet_address : null,
    });
  }

  private dropdownDynamicWidth() {
    let interval = setInterval(() => {
      let dropdownList = document.querySelector('#tokenDropdown .dropdown-list') as HTMLElement;
      if (dropdownList) {
        dropdownList.style.width = `${document.getElementById('tokenDropdown').offsetWidth}px`;
        clearInterval(interval);
      }
    });
  }

  onBankType(event: any) {
    let filteredDropdownList = this.tokenDropdownList.filter(x => x.bank_type == event.target.value);
    this.filteredTokenDropdownList = Object.assign([], filteredDropdownList);

    this.tokenSelectedItems = [];
    this.form.patchValue({
      account_name: null,
      account_number: null
    });
  }

}
