import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PartialsModule } from '@views/partials/partials.module';
import { CoreModule } from '@core/core.module';

import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

const material = [
  // material modules
  MatFormFieldModule,
  MatIconModule,
  MatTabsModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatDialogModule,
  MatFormFieldModule,
];

const components = [];

const routes: Routes = [];

@NgModule({
  imports: [
  CoreModule,
  CommonModule,
  ...material,
  PartialsModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule,
    ...material
  ],
  entryComponents: [],
  providers: [
  { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },

  ],
  declarations: [
    ...components
  ]
})
export class MaterialModule {}
