import { Observable, Subject } from 'rxjs';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { TelemarketingPlayerSummaryReport } from '@core/models/telemarketing-player-summary-report.model';

@Injectable()
export class TelemarketingPlayerSummaryReportDataService extends DefaultDataService<TelemarketingPlayerSummaryReport> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('TelemarketingPlayerSummaryReport', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<TelemarketingPlayerSummaryReport[]>{
    return this.http.get<ApiResponse>(`/report/telemarketerplayersummary${pageParam}`).pipe(
      tap(res => { this.paginationInit(res) }),
      map(res => res.data.rows)
    )
  }

  exportReport(pageParam: string) {
    return this.http.get<ApiResponse>(`/report/exporttelemarketerplayersummary${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }
  
  private paginationInit(res: any) {
    if (res) {
      return (this.pagination = res.data.paginations);
    }
  }

}
