<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">{{ data.mode | titlecase }} Vendor</h5>
              <span class="modal-x-button" (click)="onCloseDialog()">
                  <i class="fa fa-times" aria-hidden="true"></i>
              </span>
          </div>
          <div class="modal-body">
            <div class="col-md-12 form-group row">
                <label class="col-4 col-form-label control-label">Code </label>
                <input class="col-8 form-control" type="text" formControlName="code" disabled />
            </div>
            <div class="col-md-12 form-group row">
                <label class="col-4 col-form-label control-label">Vendor Name <span class="text-danger"> * </span> </label>
                <input class="col-8 form-control" type="text" formControlName="name" />
            </div>
            <div class="col-md-12 form-group row">
                <label class="col-4 col-form-label control-label">Supported Region <span class="text-danger"> * </span> </label>
                <div class="col-8 p-0">
                    <kt-dropdown-wo-lazyload
                        [form] = 'form'
                        [dropdownList] = 'dropdown.region'
                        [dropdownSettings] = 'regionDropdownSettings'
                        [formName] = "'regions'"
                        [selectionAttributes] = "'country_code'"
                        [selectedItems] = 'selectedRegion'>
                    </kt-dropdown-wo-lazyload>
                    <p class="selected-item-wrapper">
                        <small *ngFor="let item of selectedRegion" class="selected-item bg-info">
                          {{ item.country_code }}
                          <span class="bg-danger remove" (click)="onRemoveItem(item.country_code, 'region')">x</span>
                        </small>
                    </p>
                </div>
            </div>
            <div class="col-md-12 form-group row">
                <label class="col-4 col-form-label control-label">SMS Type <span class="text-danger"> * </span> </label>
                <div class="col-8 p-0">
                    <kt-dropdown-wo-lazyload
                        [form] = 'form'
                        [dropdownList] = 'dropdown.smsType'
                        [dropdownSettings] = 'smsTypeDropdownSettings'
                        [formName] = "'sms_type'"
                        [selectionAttributes] = "'id'"
                        [selectedItems] = 'selectedSmsType'>
                    </kt-dropdown-wo-lazyload>
                    <p class="selected-item-wrapper">
                        <small *ngFor="let item of selectedSmsType" class="selected-item bg-info">
                          {{ item.name }}
                          <span class="bg-danger remove" (click)="onRemoveItem(item.id, 'smsType')">x</span>
                        </small>
                    </p>
                </div>
            </div>
            <div class="col-md-12 form-group row">
                <label class="col-4 col-form-label control-label">Status</label>
                <select class="col-8 form-control" formControlName="status">
                    <option *ngFor="let value of dropdown.status; let i = index;" [value]="i">{{ value }}</option>
                </select>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
            <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
          </div>
      </div>
    </form>
  </div>
  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true)"></kt-swal-alert>