import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';

@Injectable()
export class VerificationSettingsDataService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  locales$ = new BehaviorSubject([]);

  constructor(private http: HttpClient) { }

  getWithQuery(pageParam: string): Observable<[]> {
    return this.http.get<ApiResponse>(`/verificationsetting${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getById(id: number): Observable<any> {
    return this.http.get<ApiResponse>(`/verificationsetting/${id}`).pipe(
        map(res => res.data.rows)
    );
  }

  add(data: any) {
    return this.http.post<ApiResponse>(`/verificationsetting`, data).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }

  update(id: number, data: any) {
    return this.http.put<ApiResponse>(`/verificationsetting/${id}`, data).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }

  updateStatus(data: any) {
    return this.http.put<ApiResponse>(`/verificationsetting/statusupdate`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
