<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form" [formGroup]="form">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Withdrawal Limits</h5>
              <span class="modal-x-button" (click)="onCloseDialog()">
                  <i class="fa fa-times" aria-hidden="true"></i>
              </span>
          </div>
          <div class="modal-body">
              <div class="form-group p-0" formGroupName="withdraw_limits">
                  <div class="row d-flex justify-content-between" *ngFor="let withdraw_limits of data.withdrawLimit; let i = index" [formGroupName]="i">
                      <div class="col-12 form-group row">
                          <label class="col-4 col-form-label kt-font-bold">{{  withdraw_limits.currency_code }} Withdrawal Limitation:</label>
                      </div>
                      <div class="col-md-3 col-lg-3 col-sm-6 col-xs-12 form-group pr-0" *ngFor="let item of withdrawLimitFields; let ind = index">
                          <div class="col-12 row pr-0">
                              <label class="col-md-5 col-lg-5 col-sm-12 col-xs-12 col-form-label pl-0">{{ ind === 0 ? 'Minimum' : ind === 1 ? 'Maximum' : ind === 2 ? 'Daily Count' : 'Daily Maximum'}}</label>
                              <input type="number" (wheel)="false" [formControlName]="item" class="col-md-7 col-lg-7 col-sm-12 col-xs-12 form-control">
                              <small class="text-muted input-msg" *ngIf="ind !== 0">
                                <i class="fa fa-exclamation-circle"></i> Enter 0 for unlimited
                              </small>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button *ngIf="canEditWithdrawalLimits" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
          </div>
      </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
