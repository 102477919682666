<!-- begin:: Footer -->
<div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
  <div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
  <div class="kt-footer__copyright">
    &nbsp;&copy;&nbsp; {{today | date:'yyyy'}}
  </div>
  <div class="kt-footer__menu"></div>
  </div>
</div>
<!-- end:: Footer -->
