import { GroupDataService } from './../services/group-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, catchError } from 'rxjs/operators';
import { GroupEntityService } from './../services/group-entity.service';
import { Subscription, forkJoin } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Group } from '@core/models/group.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'kt-member-group-form',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss']
})
export class GroupEditDialogComponent implements OnInit, OnDestroy  {

  form: FormGroup;
  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  messages$ = this.groupDataService.messages$;
  refreshStatus: boolean;

  dropdown = {
    statuses: this.dropdownHttpService.statuses
  };
  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { group: Group, mode: string},
    public dialogRef: MatDialogRef<GroupEditDialogComponent>,
    private groupService: GroupEntityService,
    private groupDataService: GroupDataService,
    private dropdownHttpService: DropdownHttpService
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(group: Group, mode?: string) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      id: group ? group.id : null,
      ...this.form.value,
      default: this.form.value.default ? 1 : 0,
      vip: this.form.value.vip ? 1 : 0,
      trial: this.form.value.trial ? 1 : 0
    };
    Object.keys(data).forEach((key) => (data[key] == null) && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.groupService.update(data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.groupDataService.add(data).pipe(
            tap((res: any) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              this.messages$.next([...res.message]);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              throw error;
            })
          )
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    let status = 1;
    let code = null;
    let name = null;
    let isDefault = null;
    let remarks = null;
    let vip = null;
    let trial = null;
    let color = null;

    if (this.data.mode === 'edit'){
      status =  this.data.group.status;
      code =  this.data.group.code;
      name = this.data.group.name;
      isDefault = this.data.group.default;
      vip = this.data.group.vip;
      trial = this.data.group.trial;
      remarks =  this.data.group.remarks;
      color =  this.data.group.color;
    }
    this.form = new FormGroup({
      code: new FormControl(code, [Validators.required]),
      name: new FormControl(name, [Validators.required]),
      status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false }),
      default: new FormControl(isDefault),
      vip: new FormControl(vip),
      trial: new FormControl(trial),
      remarks: new FormControl(remarks),
      color: new FormControl(color)
    });
  }

}
