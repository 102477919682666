import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'kt-clear-button',
  templateUrl: './clear-button.component.html',
  styleUrls: ['./clear-button.component.scss']
})
export class ClearButtonComponent implements OnInit {

  @Output()
  clear = new EventEmitter();

  @Input()
  loading = false;

  @Input()
  disabledButton = false;

  clicked = false;

  constructor(
  ) { }

  ngOnInit() {
    if (!this.loading || !this.disabledButton) {
      this.clicked = true;
    }
  }

  onClear() {
    this.clicked = true;
    this.clear.emit();
  }

}
