<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <!-- Filter -->
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <div class="col-12 row pr-0 mb-2">
                  <!-- Username -->
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>Action By:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <input type="text" formControlName="username" placeholder="Search" class="form-control"
                      (input)="toLowerCaseInput('username', $event)"/>
                  </div>
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>Session ID:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <input type="text" formControlName="unique_id" placeholder="Search" class="form-control"/>
                  </div>
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>Date/Time:</label>
                  </div>
                  <div class="col-md-5 kt-form__control">
                    <div class="input-group date">
                      <input type="text" class="form-control" placeholder="Search"
                        [formControl]="form.controls['defaultDate']"
                        (change)="onDateChange($event)"
                        (opened)="onDateOpened()"
                        [timePicker]="true" [timePickerSeconds]="true"
                        [alwaysShowCalendars]="true" [ranges]="ranges" [maxDate]="maxDate"
                        [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                        [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true"
                        ngxDaterangepickerMd/>
                      <span class="input-group-append">
                        <span class="input-group-text pointer-calendar" (click)="onDateChange()">X</span>
                      </span>
                    </div>
                  </div>
              </div>
              <div class="col-12 row pr-0 mb-2">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>IP Address:</label>
                </div>
                <div class="col-md-3 kt-form__control">
                  <input type="text" formControlName="ip_address" placeholder="Search" class="form-control"/>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>URL:</label>
                </div>
                <div class="col-md-3 kt-form__control">
                  <input type="text" formControlName="url" placeholder="Search" class="form-control"/>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Audit Type:</label>
                </div>
                <div class="col-md-3 kt-form__control">
                  <select formControlName="event" class="form-control">
                    <option value="all"> All </option>
                    <option value="created"> Created </option>
                    <option value="updated"> Updated </option>
                    <option value="deleted"> Deleted </option>
                  </select>
                </div>
              </div>
              <div class="col-12 row pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Modal name:</label>
                </div>
                <div class="col-md-3 kt-form__control">
                  <input type="text" formControlName="modal_name" placeholder="Search" class="form-control"/>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Modal ID:</label>
                </div>
                <div class="col-md-3 kt-form__control">
                  <input type="text" formControlName="modal_id" placeholder="Search" class="form-control"/>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Keyword:</label>
                </div>
                <div class="col-md-3 kt-form__control">
                  <input type="text" formControlName="keyword" placeholder="Search" class="form-control"/>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Filter Action -->
      <div class="kt-section col-12 row mb-3 mt-3">
          <div class="dropdown dropdown-inline">
            <kt-search-button [loading]="loading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
            <kt-clear-button [loading]="loading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
          </div>
      </div>
      <!-- Table -->
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped">
              <thead>
                <tr>
                  <th class="text-center">ID</th>
                  <th class="text-center">Audit ID</th>
                  <th class="text-center">Session ID</th>
                  <th class="text-center">IP Address</th>
                  <th class="text-center">URL</th>
                  <th class="text-center">Event</th>
                  <th class="text-center">Modal name</th>
                  <th class="text-center">Modal ID</th>
                  <th class="text-center">Old Values</th>
                  <th class="text-center">New Values</th>
                  <th class="text-center date-column">Created By</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of audits">
                  <td>{{ row.id }}</td>
                  <td>{{ row.audit_id }}</td>
                  <td>{{ row.unique_id }}</td>
                  <td>{{ row.ip_address }}</td>
                  <td class="text-break mw-150">{{ row.url }}</td>
                  <td>{{ row.event }}</td>
                  <td class="text-break mw-150">{{ row.auditable_type }}</td>
                  <td>{{ row.auditable_id }}</td>
                  <td class="text-break mw-250">{{ row.old_values | json }}</td>
                  <td class="text-break mw-250">{{ row.new_values | json }}</td>
                  <td>
                    {{ row.audit_username ? row.audit_username : 'System' }}
                    <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                    <ng-template #createdDateTime>
                      {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="loading" style="text-align: center;">Loading more...</div>
            <div *ngIf="!loading" style="text-align: center;">No more data available</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
