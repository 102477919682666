import { Observable, Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from './core/_base/layout';
// language list
import { Title } from '@angular/platform-browser';
import { SitePrefix } from '@core/enums/site-prefix.enum';
import { environment } from '@env/environment';
import { VersionHttpService } from '@core/services/version-http.service';
import { SubheaderService } from '@core/_base/layout/services/subheader.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { login } from './core/store/auth/auth.actions';
import { isLoggedIn, isLoggedOut } from './core/store/auth/auth.selectors';
import { locale as chLang } from './core/_config/i18n/ch';
import { locale as deLang } from './core/_config/i18n/de';
import { locale as enLang } from './core/_config/i18n/en';
import { locale as esLang } from './core/_config/i18n/es';
import { locale as frLang } from './core/_config/i18n/fr';
import { locale as jpLang } from './core/_config/i18n/jp';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  sitePrefix: string = environment.sitePrefix;
  siteName = this.sitePrefix.toUpperCase() + ' BO';
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  pageTitle = '';
  loader: boolean;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  loading = true;
  isLoggedIn$: Observable<boolean>;
  isLoggedOut$: Observable<boolean>;
  subheader = '';

  constructor(
    private router: Router,
    private translationService: TranslationService,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    private store: Store<AppState>,
    private titleService: Title,
    private versionService: VersionHttpService,
    private subheaderService: SubheaderService,
  ) {
    this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
  }

  ngOnInit() {
    if (!localStorage.getItem('twofaverified')) {
      localStorage.setItem('twofaverified', '0');
    }
    this.favIcon.href = './assets/media/logos/favicon-quantum-play.ico';

    this.checkVersion();

    const userData = localStorage.getItem('user_data');
    const userToken = localStorage.getItem('user_token');
    const userAccess = localStorage.getItem('user_access');
    const navigations = localStorage.getItem('navigations');
    const permissions = localStorage.getItem('user_permissions');
    const appPermissions = localStorage.getItem('user_app_permissions');
    const appSections = localStorage.getItem('user_app_sections');

    if (userData && userToken && userAccess && appPermissions) {
      this.store.dispatch(login({
        user: JSON.parse(userData),
        token: JSON.parse(userToken),
        access_sections: JSON.parse(userAccess),
        navigations: JSON.parse(navigations),
        permissions: JSON.parse(permissions),
        app_permissions: JSON.parse(appPermissions),
        app_sections: JSON.parse(appSections),
      }));
    }

    this.router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationStart: {
          // sessionStorage.removeItem('currencies');
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd: {
          this.setSubheader();
          break;
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });

    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    this.isLoggedOut$ = this.store.pipe(select(isLoggedOut));
    this.loader = this.layoutConfigService.getConfig('loader.enabled');

    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.splashScreenService.hide();
        window.scrollTo(0, 0);
        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  private getRouteTitle(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getRouteTitle(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

  private checkVersion() {
    const currentVersion = localStorage.getItem('version');
    this.versionService.getVersion().subscribe(res => {
      if ((currentVersion != null && res.version != currentVersion) || currentVersion == null) {
        var notificationSound = localStorage.getItem('notificationSound') !== null ? localStorage.getItem('notificationSound') : 'on';
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem('version', res.version);
        localStorage.setItem('notificationSound', notificationSound);
        if (window.location.pathname !== '/login/auth') {
          window.location.href = '/login/auth';
        }
      }
    });
  }

  private setSubheader() {
    const navigations = JSON.parse(localStorage.getItem('navigations'));
    const pathname = window.location.pathname.replace(/^\//, "");

    if (pathname !== 'dashboard' && !pathname.includes('game/category')) {
      for (const category in navigations) {
        if (navigations.hasOwnProperty(category)) {
          if (navigations[category].link != null && navigations[category].link === pathname) {
            this.subheader = category;
          } else {
            navigations[category].sub_menu.forEach(item => {
              if (item.link === pathname) {
                this.subheader = item.name;
              }
            })
          }
        }
      }
      this.setPageTitle(this.subheader);
    }

    if (pathname.includes('game/category')) {
      let path = pathname.split('/');
      let gameProviderName = path[path.length - 1].toUpperCase();
      let gameCategory = '';
      switch (path[2]) {
        case 'SP':
          gameCategory = 'Sport';
          break;
        case 'LC':
          gameCategory = 'Live Casino';
          break;
        case 'SL':
          gameCategory = 'Slots';
          break;
        case 'ES':
          gameCategory = 'E-Sport';
          break;
        case 'FS':
          gameCategory = 'Fishing';
          break;
        case 'LT':
          gameCategory = 'Lottery';
          break;
        case 'CG':
          gameCategory = 'Crash Game';
          break;
        case 'TB':
          gameCategory = 'Table';
          break;
        case 'CK':
          gameCategory = 'Cricket';
          break;  
      }
      this.setPageTitle('15.1.1 All Games - ' + gameProviderName + ' - ' + gameCategory)
    }

    if (pathname.includes('game/assign-games')) {
      this.setPageTitle('15.3 Game Tags');
    }

    if (pathname === 'dashboard') {
      this.setPageTitle('Dashboard');
    }

    if (pathname === 'download-area') {
      this.setPageTitle('Download Area');
    }

    if (pathname === 'import-history') {
      this.setPageTitle('Import History Log'); 
    }

    if (pathname.includes('login/auth')) {
      this.setPageTitle('Auth');
    }

    if (pathname.includes('settings/marquee')){
      this.setPageTitle('14.1.1 Marquee');
    }

    if (pathname.includes('settings/dialog')){
      this.setPageTitle('14.1.2 Dialog');
    }

    if (pathname.includes('settings/live-chat-script')){
      this.setPageTitle('14.3.1 Livechat Script');
    }

    if (pathname.includes('settings/contacts')){
      this.setPageTitle('14.3.2 Contacts');
    }

    if (pathname.includes('settings/terms-and-conditions')) {
      this.setPageTitle('14.4.1 T&C');
    }

    if (pathname.includes('settings/faq-management')){
      this.setPageTitle('14.4.2 FAQ');
    }

    if (pathname.includes('settings/about-us')){
      this.setPageTitle('14.4.3 About Us');
    }

    if (pathname.includes('settings/privacy-policy')){
      this.setPageTitle('14.4.4 Privacy Policy');
    }

    if (pathname.includes('settings/responsible-gaming')){
      this.setPageTitle('14.4.5 Responsible Gaming');
    }
    
    if (pathname.includes('settings/website-display')){
      this.setPageTitle('14.7.1 Website Display');
    }

    if (pathname.includes('settings/mobile-display')){
      this.setPageTitle('14.7.2 Mobile Display');
    }

    if (pathname.includes('settings/section-settings')){
      this.setPageTitle('14.7.3 Section Settings');
    }

    if (pathname.includes('general/member-verification')){
      this.setPageTitle('1.3.1 Verification List');
    }

    if (pathname.includes('general/verification-settings')){
      this.setPageTitle('1.3.2 Verification Settings');
    }
  }

  private setPageTitle(title: string) {
    this.subheaderService.setTitle(title);
    this.titleService.setTitle(`${environment.projectCode.toUpperCase()} | ${title}`)
  }
}
