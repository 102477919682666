import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SeoSettingsDataService } from '../../services/seo-settings-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'kt-seo-settings-edit',
  templateUrl: './seo-settings-edit.component.html',
  styleUrls: ['./seo-settings-edit.component.scss']
})
export class SeoSettingsEditComponent implements OnInit {

  form: FormGroup;
  dropdown = {
    merchant: this.dropdownHttpService.merchantSites,
    status: this.dropdownHttpService.statuses,
  };
  messages$ = this.seoSettingsDataService.messages$;
  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      mode: 'create' | 'edit',
      regions: any,
      seo: {
        id: number,
        site_id: number,
        country_code: string,
        domain: string[],
        gtag: string,
        status: number,
        remarks: string
      }
    },
    public dialogRef: MatDialogRef<SeoSettingsEditComponent>,
    private dropdownHttpService: DropdownHttpService,
    private seoSettingsDataService: SeoSettingsDataService,
  ) { }

  ngOnInit() {
    this.formInit();
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    const data = {
      ...this.form.value,
      domain: (this.form.value.domain).trim().split('\n')
    }
    switch (this.data.mode) {
      case 'create':
        this.seoSettingsDataService.add(data).pipe(
          tap((res: any) => {
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'edit':
        this.seoSettingsDataService.update(this.data.seo.id, data).pipe(
          tap((res: any) => {
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
    }
  }

  private formInit() {
    this.form = new FormGroup({
      merchant: new FormControl(this.data.mode === 'edit' ? this.data.seo.site_id : null, [Validators.required]),
      country_code: new FormControl(this.data.mode === 'edit' ? this.data.seo.country_code : null, [Validators.required]),
      domain: new FormControl(this.data.mode === 'edit' ? (this.data.seo.domain).join('\n') : null, [Validators.required]),
      gtag: new FormControl(this.data.mode === 'edit' ? this.data.seo.gtag : null),
      status: new FormControl(this.data.mode === 'edit' ? this.data.seo.status : 1, [Validators.required]),
      remarks: new FormControl(this.data.mode === 'edit' ? this.data.seo.remarks : null),
    });
  }

}
