import { EditorService } from './../../../../../../core/services/editor.service';
import { CustomUploadAdapterPlugin } from '@core/ckeditor-upload-adapter/CustomUploadAdapterPlugin';
import { map, catchError, tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, forkJoin } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MessageTemplateEntityService } from './../services/message-template-entity.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Component, OnInit, Inject, ViewChildren, QueryList } from '@angular/core';
import { MessageTemplateDataService } from '../services/message-template-data.service';
import { MessageTemplate } from '@core/models/message-template.model';
import * as Editor from '@core/../../assets/js/global/integration/plugins/ckeditor';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { AppPermissionService } from '@core/services/app-permission.service';
declare var $: any;

@Component({
  selector: 'kt-message-template-edit',
  templateUrl: './message-template-edit.component.html',
  styleUrls: ['./message-template-edit.component.scss']
})
export class MessageTemplateEditComponent implements OnInit {
  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    locales: this.dropdownHttpService.locales,
    perPage: this.dropdownHttpService.perPage,
    type: this.dropdownHttpService.messageTemplateType,
    section: [],
    locales$: this.messageTemplateDataService.locales$
  };
  @ViewChildren('editors') editorComponent:QueryList<CKEditorComponent>;
  usableVariables: any;
  messages$ = this.messageTemplateDataService.messages$;
  formFields = [];
  formFieldsSelectedItems = [];
  details: any;
  refreshStatus: boolean;
  buttonLoading = false;
  section: string = '';
  type: string = '';
  name: string = '';
  locale: string = '';
  code: string = '';
  public editor = Editor;
  editorConfig = this.editorService.config;

  editable: boolean = true;

  // permissions
  canCreateMessageTemplate: boolean;
  canEditMessageTemplate: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  private localesSub = new Subscription();

  languages: { id: number; code: string; name: string }[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { messageTemplate: any, mode: 'create' | 'edit' },
    private dropdownHttpService: DropdownHttpService,
    private messageTemplateDataService: MessageTemplateDataService,
    private messageTemplateEntityService: MessageTemplateEntityService,
    public loadingBar: LoadingBarService,
    public dialogRef: MatDialogRef<MessageTemplateEditComponent>,
    private editorService: EditorService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.dropdownHttpService.fillInForms.subscribe(res => {
      Object.keys(res).forEach(key => {
        this.formFields.push({
          name: res[key],
          id: key
        });
      });
    });

    this.localesSub = this.dropdown.locales$.pipe(tap(res => this.languages = res)).subscribe(res => {
      res.forEach(item => {
        if (this.data.mode === 'edit') {
          let language_id = item.id;
          let details = null;
          if (this.data.messageTemplate.message_details[language_id]) {
            Object.keys(this.data.messageTemplate.message_details[language_id]).forEach((key) => {
              details = {
                ...details,
                [key]:
                  this.data.messageTemplate.message_details[language_id][key]
              };
            });
          }
          this.details = {
            ...this.details,
            [language_id]: {
              ...details
            }
          };
        }
      })
    });

    this.formInit();
    this.dropdownHttpService.messageTemplateSection(this.data.mode).subscribe(res => {
      this.dropdown.section = res;
      this.updateForm();
    });

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateMessageTemplate = appPermissions.create_message_template;
      this.canEditMessageTemplate = appPermissions.edit_message_template;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.localesSub.unsubscribe();
    this.onRefresh();
  }

  onSectionChange(sectionID: number, name: string) {
    this.messageTemplateDataService.getMessageTemplatVariables(sectionID, name).subscribe(res => {
      this.usableVariables = res['usable_variables'];
    });
  }

  onSelectUsableVariable(usableVariable: String, i){
    let editorInstance = this.editorComponent.toArray()[i].editorInstance;
    const selection = editorInstance.model.document.selection;
    const range = selection.getFirstRange();
    editorInstance.editing.view.focus();
    editorInstance.model.change ( writer => {
        writer.insert( usableVariable + ' ', range.start );
        const rangeAfterAppend = selection.getFirstRange();
        writer.setSelection( writer.createPositionAt( rangeAfterAppend.start ) );
    } );
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(messageTemplate: MessageTemplate, mode?: string) {
    this.buttonLoading = true;

    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    const data = {
      id: messageTemplate ? messageTemplate['message_template'].id : null,
      ...this.form.value,
      details: this.form.value.details
    };

    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.messageTemplateEntityService.update(data).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
            this.buttonLoading = false;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.messageTemplateDataService.add(data).pipe(
            tap((res: any) => {
              this.form.setErrors(null);
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.messageTemplateDataService.messages$
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onGenerate(param: string) {
    if (param === 'section') {
      this.section = this.form.value.section !== '' && this.form.value.section !== null ? $('.sectionSelect option:selected').text() + '.' : '';
      this.onSectionChange(this.form.value.section, this.form.value.name);
    }
    if (param === 'type') {
      this.type = this.form.value.type !== '' && this.form.value.type !== null ? $('.typeSelect option:selected').text() + '.' : '';
    }
    if (param === 'name') {
      this.name = this.form.value.name !== '' && this.form.value.name !== null ? this.form.value.name + '' : '';
    }

    this.code = (this.section + this.type + this.name).replace(/\s/g, "");
  }

  private formInit() {
    if (this.data.mode === 'edit') {
      this.code = this.data.messageTemplate.message_template.code;
      this.section = this.data.messageTemplate.message_template.section_name + '.';
      this.type = this.data.messageTemplate.message_template.type_name + '.';
      this.name = this.data.messageTemplate.message_template.name + '.';
    }
    let name = this.data.mode === 'create' ? null : this.data.messageTemplate.message_template.name;
    let section = this.data.mode === 'create' ? null : this.data.messageTemplate.message_template.section;
    let type = this.data.mode === 'create' ? null : this.data.messageTemplate.message_template.type;
    let status = this.data.mode === 'create' ? 1 : this.data.messageTemplate.message_template.status;

    const validatorOptions = [];

    const buildSubDetails = () => {
      let details = {};
      const isEditMode = () => {
        let edit = {};
        if (this.data.mode === 'edit') {
          edit = { ...edit, id: new FormControl(null) };
        }
        return edit;
      };

      this.languages.map(element => {
        const detailsGroup = new FormGroup({
          settings_locale_id: new FormControl(element.id, validatorOptions),
          subject: new FormControl(null, validatorOptions),
          message: new FormControl(null),
          ...isEditMode()
        });
        details = { ...details, [element.id]: detailsGroup };
      });
      return details;
    };

    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      section: new FormControl(section, [Validators.required]),
      type: new FormControl(type, [Validators.required]),
      status: new FormControl(status),
      details: new FormGroup(buildSubDetails()),
    });
    if (this.data.mode === 'edit') {
      const content = this.data.messageTemplate.message_template;
      const details = this.details;
      this.form.patchValue({ ...content, details});
    }
  }

  private updateForm() {
    if (this.data.mode === 'edit') {
      this.editable = this.dropdown.section.find(x => x.id == this.form.get('section').value).edit === 'false' ? false : true;
      this.messageTemplateDataService.getMessageTemplatVariables(this.form.get('section').value, this.form.get('name').value).subscribe(res => {
        this.usableVariables = res['usable_variables'];
      });
      if (!this.editable) {
        this.form.controls.name.disable();
        this.form.controls.section.disable();
        this.form.controls.status.disable();
        this.form.controls.type.disable();
      }
    }
  }
}
