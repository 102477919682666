<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Manual Sync</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="kt-form__label">
                    <label>Game Provider:</label>
                </div>
                <div class="kt-form__control">
                    <select formControlName="game_provider_code" class="form-control">
                        <option [value]="null" disabled>Please Select</option>
                        <option [value]="value.code" *ngFor="let value of dropdown.gameProviders">
                            {{ value.code }} - {{ value.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <button type="button" [attr.disabled]="!form.valid  || sentRequest ? true : null" class="btn btn-primary" (click)="onSync()"><i class="fas fa-sync"></i>Sync</button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>



<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>

