// Angular
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EntityDataService, EntityDefinitionService } from '@ngrx/data';

// Thirt Party
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

// Module
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime/date-time';
import { VipRoutingModule } from './vip-routing.module';
import { entityMetadata } from 'app/app.module';
import { PartialsModule } from '@views/partials/partials.module';
import { SharedModule } from '@shared/shared.module';
import { MaterialModule } from '@views/pages/material/material.module';

// Component
import { AllVipsComponent } from './all-vips/all-vips.component';
import { VipSettingsComponent } from './settings/vip-settings.component';
import { VipSettingsEditDialogComponent } from './settings/dialogs/vip-settings-edit.component';
import { VipMemberLogsComponent } from './vipmemberlogs/vip-member-logs.component';

// Service
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { VipSettingsDataService } from './settings/services/vip-settings-data.service';
import { VipMemberVerificationDataService } from './all-vips/services/vip-member-verification-data.service';
import { VipEditDialogComponent } from './all-vips/dialogs/vip-edit.component';

@NgModule({
  declarations: [
    AllVipsComponent,
    VipSettingsComponent,
    VipSettingsEditDialogComponent,
    VipEditDialogComponent,
    VipMemberLogsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    VipRoutingModule,
    SharedModule,
    PartialsModule,
    NgSelectModule,
    NgbModule,
    LoadingBarModule,
    NgxDaterangepickerMd.forRoot(),
    ColorPickerModule,
    AngularMultiSelectModule,
    MaterialModule,
    MaterialModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  providers: [
    DecimalPipe,
    CurrencyHttpService,
    VipMemberVerificationDataService,
    VipSettingsDataService
  ]
})

export class VipModule {
  constructor(
    private eds: EntityDefinitionService,
    private entityDataService: EntityDataService,
    private vipMemberVerificationDataService: VipMemberVerificationDataService
  ) {
    eds.registerMetadataMap(entityMetadata);
    entityDataService.registerServices({
      vipMemberVerification: vipMemberVerificationDataService
    });
  }
}