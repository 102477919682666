<div class="kt-form w-webkit-fill-available" *ngIf="dropdown.locales.length > 0">
    <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">

      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
          <form [formGroup]="form">
            <div class="row align-items-center">
              <div class="col-xl-12 order-2 order-xl-1">
                <div class="row mb-3">
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Date' }}:</label>
                  </div>
                  <div class="col-md-5 kt-form__control">
                    <div class="input-group date">
                      <input type="hidden" formControlName="start_datetime">
                      <input type="hidden" formControlName="end_datetime">
                      <input type="text" class="form-control" placeholder="{{ 'Search' }}" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" ngxDaterangepickerMd />
                      <span class="input-group-append">
                        <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Platform Type'}}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <select formControlName="platform_type_id" class="form-control">
                      <option value="all">{{ 'All' }}</option>
                      <option [value]="value.id" *ngFor="let value of dropdown.platforms; let i = index">
                        {{ value.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 order-2 order-xl-1">
                <div class="row mb-3">
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Status' }}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <select formControlName="status" class="form-control">
                      <option value="all"> {{'All' }} </option>
                      <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
                        {{ value }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Locale' }}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <select formControlName="settings_locale_id" class="form-control">
                      <option value="all"> {{'All' }} </option>
                      <option [value]="value.id" *ngFor="let value of dropdown.locales">
                        {{ value.code }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Label' }}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <input type="text" formControlName="label" placeholder="{{ 'Search' }}" class="form-control">
                  </div>
                  <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Session'}}:</label>
                  </div>
                  <div class="col-md-2 kt-form__control">
                    <select formControlName="session" class="form-control">
                      <option value="all">{{ 'All Session' }}</option>
                      <option [value]="value.id" *ngFor="let value of dropdown.sessions; let i = index">
                        {{ value.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="kt-section col-12 row d-flex justify-content-between mb-0 mt-1">
            <div class="dropdown dropdown-inline">
              <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmitClick()"></kt-search-button>
              <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onSubmitClick(true)"></kt-clear-button>
            </div>
          </div>
        </div>
      </div>
      <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__body pt-12 pb-0">
            <div class="kt-section">
              <div class="kt-section__content">
                <div class="table-responsive">
                  <table class="table table-bordered table-hover table-striped table-sortable">
                    <thead>
                      <tr>
                        <th width="5" class="sort-enabled pointer" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">{{ 'Id' | uppercase }}</th>
                        <th width="5" class="pr-0">{{ 'Locale' | uppercase }}</th>
                        <th width="5" class="pl-1 pr-5">
                          <div class="dropdown selected-category-container ml-1" *ngIf="displayLocale != null">
                            <button class="dropdown-toggle d-flex justify-content-center dropdown-box" type="button" data-toggle="dropdown" style="margin: 0 auto">
                              <p class="mr-1">{{ displayLocale['code'] | uppercase }} </p>
                              <i class="fa fa-sort-down"></i>
                            </button>
                            <ul class="dropdown-menu dropdown-box-open">
                              <li *ngFor="let value of dropdown.locales" class="selected-category-container2 ml-0" (click)="changeLocale(value)">
                                {{ value.code | uppercase }}
                              </li>
                            </ul>
                          </div>
                        </th>
                        <th width="250">{{ 'Desktop' | uppercase }}</th>
                        <th width="250">{{ 'Mobile' | uppercase }}</th>
                        <th class="sort-enabled pointer" (click)="onSortColumn('label')" [ngClass]="sortingConfig.label" [class.sort-selected]="sortingSelection.sort_by === 'label'">{{ 'Label' | uppercase }}</th>
                        <th>{{ 'Session' | uppercase }}</th>
                        <th>{{ 'Platform Type' | uppercase }}</th>
                        <th class="sort-enabled pointer" (click)="onSortColumn('position')" [ngClass]="sortingConfig.position" [class.sort-selected]="sortingSelection.sort_by === 'position'">{{ 'Position' | uppercase }}</th>
                        <th class="sort-enabled pointer" (click)="onSortColumn('start_datetime')" [ngClass]="sortingConfig.start_datetime" [class.sort-selected]="sortingSelection.sort_by === 'start_datetime'">{{ 'Start Date' | uppercase }}</th>
                        <th class="sort-enabled pointer" (click)="onSortColumn('end_datetime')" [ngClass]="sortingConfig.end_datetime" [class.sort-selected]="sortingSelection.sort_by === 'end_datetime'">{{ 'End Date' | uppercase }}</th>
                        <th>{{ 'Status' | uppercase }}</th>
                        <th class="text-center pr-13">{{ 'Actions' | uppercase}}</th>
                      </tr>
                      <tr *ngIf="canCreateNewBanner && (!mode || mode != 'create')">
                        <th colspan="100%" class="pr-0 pl-0">
                          <div class="content-container text-center" (click)="expandRowCreate('create')">
                            <span><i class="fas fa-plus mr-2"></i>Create New Content</span>
                          </div>
                        </th>
                      </tr>
                      <tr *ngIf="mode == 'create'">
                        <th colspan="100%" class="pr-0 pl-0">
                          <ng-container *ngIf="!mode else contents"></ng-container>
                        </th>
                      </tr>
                    </thead>
                    <tbody *ngIf="(banner$ | async) as rows">
                      <ng-container *ngFor="let row of rows">
                        <tr>
                          <td [style]="mode == 'edit' && banner.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.id }}</td>
                          <td [style]="mode == 'edit' && banner.id == row.id ? 'border-bottom: 0 !important;' : ''" colspan="2" width="1" class="pr-5">
                            <div style="width: 100px;">
                              {{ row.locale_list }}
                            </div>
                          </td>
                          <td [style]="mode == 'edit' && banner.id == row.id ? 'border-bottom: 0 !important;' : ''">
                            <ng-container *ngIf="displayImage(row, 'desktop') != '' else NoImage">
                              <img [src]="displayImage(row, 'desktop')" class="image-preview pointer" (click)="openImage(displayImage(row, 'desktop'))">
                            </ng-container>
                            <ng-template #NoImage>
                              -
                            </ng-template>
                          </td>
                          <td [style]="mode == 'edit' && banner.id == row.id ? 'border-bottom: 0 !important;' : ''">
                            <ng-container *ngIf="displayImage(row, 'mobile') != '' else NoImage">
                              <img [src]="displayImage(row, 'mobile')" class="image-preview pointer" (click)="openImage(displayImage(row, 'mobile'))">
                            </ng-container>
                            <ng-template #NoImage>
                              -
                            </ng-template>
                          </td>
                          <td [style]="mode == 'edit' && banner.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.label }}</td>
                          <td [style]="mode == 'edit' && banner.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.session_name }}</td>
                          <td [style]="mode == 'edit' && banner.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.platform_type }}</td>
                          <td [style]="mode == 'edit' && banner.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.position }}</td>
                          <td [style]="mode == 'edit' && banner.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.start_datetime | timezoneDate:'YYYY-MM-DD HH:mm' }}</td>
                          <td [style]="mode == 'edit' && banner.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.end_datetime !== null ? (row.end_datetime | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }}</td>
                          <td [style]="mode == 'edit' && banner.id == row.id ? 'border-bottom: 0 !important;' : ''">
                            <label class="switch">
                              <input [disabled]="!canEditBanner && !canUpdateBannerStatus" type="checkbox" id="togBtn" [checked]="row.status == 1" (change)="onChangeStatus($event, row)">
                              <div class="slider round">
                                <span class="on">ON</span>
                                <span class="off">OFF</span>
                              </div>
                            </label>
                          </td>
                          <td [style]="mode == 'edit' && banner.id == row.id ? 'border-bottom: 0 !important;' : ''" class="text-center actions-column">
                            <button *ngIf="canViewBannerDetails" matTooltip="{{ 'View' }}" class="btn btn-elevate btn-sm btn-icon btn-icon-sm" (click)="expandRowEdit('edit', row)"><i class="fas fa-cog"></i></button>
                          </td>
                        </tr>
                        <tr *ngIf="mode == 'edit' && banner.id == row.id">
                          <td colspan="100%" class="pr-0 pl-0 pt-0 pb-0" style="border-bottom: 0 !important;">
                            <ng-container *ngIf="!mode else contents"></ng-container>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                    <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold table-handler"></tbody>
                  </table>
                </div>
                <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                  <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                  </ngb-pagination>
                  <div class="kt-pagination__toolbar">
                    <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                      <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                    </select>
                    <span class="pagination__desc">
                      Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <ng-template #contents>
    <form [formGroup]="formContent">
      <div class="create-content-container" *ngIf="dropdown.locales.length > 0">
        <div class="row col-12 p-0 m-0">
          <div class="col-12 mb-2" *ngIf="mode == 'create'">
            <span class="header">Create New Banner</span>
          </div>
          <div class="form-group col-3 mb-3">
            <label>{{ 'Label' }}: <span class="text-danger">*</span></label>
            <input type="text" formControlName="label" class="form-control">
          </div>
          <div class="form-group col-3 mb-3">
            <label>{{ 'Link' }}: <i class="fa fa-info-circle ml-1" placement="right" [ngbTooltip]="linkToolTip" tooltipClass="tooltip-class" container="body"></i></label>
            <input type="text" formControlName="link" class="form-control">
            <span class="header-hint">
              <div *ngFor="let usableLink of usableLinks; let last = last" class="p-inline">
                <p class="p-inline" (click)="addUsableLink(usableLink)">{{ usableLink }}</p>
                <div *ngIf="!last" class="p-inline">
                  <p class="p-inline p-display">&nbsp;&nbsp;&nbsp;&nbsp;</p>
                </div>
              </div>
            </span>
          </div>
          <ng-template #linkToolTip>
            <div class="text-left">
              Internal Link: A hyperlink that points to the other pages on your website. <br>
              Examples:<br>
              /promotion?code=CODE0001<br>
              /referral<br>
              <br><br>
              External Link: A hyperlink that points to the target page on other websites.<br>
              Examples:<br>
              /external?link=https://example.com/mobileapp.apk<br>
              /external?link=https://google.com<br>
              <br><br>
              Launch Game Link: A hyperlink that points to the target game lobby / subcategory lobby /  game or table.<br>
              Examples:<br>
              Launch|PP<br>
              Launch|EZ|roulette<br>
              Launch|EZ|roulette|481000<br>
            </div>
          </ng-template>
          <div class="form-group col-3 mb-3">
            <label>{{ 'Start Date' }}: <span class="text-danger">*</span></label>
            <div class="input-group date">
              <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack?.start_datetime" [ngModelOptions]="{standalone: true}">
              <input type="text" formControlName="start_datetime" class="form-control">
              <span class="input-group-append" [owlDateTimeTrigger]="dateStartRef">
                <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
              </span>
              <owl-date-time #dateStartRef></owl-date-time>
            </div>
          </div>
          <div class="form-group col-3 mb-3">
            <label>{{ 'End Date' }}: <span class="text-danger"></span></label>
            <div class="input-group date">
              <input class="hidden" [owlDateTime]="dateEndRef" [ngModel]="dateTimeStack?.end_datetime" [ngModelOptions]="{standalone: true}">
              <input type="text" formControlName="end_datetime" class="form-control">
              <span class="input-group-append" [owlDateTimeTrigger]="dateEndRef">
                <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
              </span>
              <owl-date-time #dateEndRef></owl-date-time>
            </div>
          </div>
        </div>
        <div class="row col-12 p-0 m-0">
          <div class="form-group col-3 mb-3">
            <label>{{ 'Position' }}: <span class="text-danger">*</span></label>
            <input type="number" min="1" (wheel)="false" step="1" formControlName="position" class="form-control">
          </div>
          <div class="form-group col-3 mb-3" *ngIf="mode == 'create'">
            <label>{{ 'Status' }}: <span class="text-danger">*</span></label>
            <select formControlName="status" class="form-control">
              <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
                {{ value }}
              </option>
            </select>
          </div>
          <div class="form-group col-3 mb-0">
            <label>{{ 'Session' }}: <span class="text-danger">*</span></label>
            <select formControlName="session" class="form-control">
              <option [value]="value.id" *ngFor="let value of dropdown.sessions; let i = index">
                {{ value.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-3 mb-0">
            <label>{{ 'Platform Type' }}: <span class="text-danger">*</span></label>
            <select formControlName="platform_type_id" class="form-control">
              <option value="null" disabled hidden>{{ 'Please Select' }}</option>
              <option [value]="value.id" *ngFor="let value of dropdown.platforms; let i = index">
                {{ value.name }}
              </option>
            </select>
          </div>
        </div>
        <hr>
        <mat-tab-group animationDuration="0ms" class="form-group mb-0 mat-tab-header-pagination-controls-enabled" formGroupName="images" [selectedIndex]="localeIndex()">          <ng-container *ngIf="dropdown.locales.length > 0">
            <mat-tab *ngFor="let value of dropdown.locales; let i = index" [formGroupName]="value.id">
              <ng-template mat-tab-label>
                <span class="mat-tab">
                  {{ value.code }}
                  <div *ngIf="checkContent(value)" class="icon" (click)="clearContent(value)">
                    <i class="fas fa-times"></i>
                  </div>
                </span>
              </ng-template>

              <div class="row col-12 p-0 m-0">
                <div class="form-group col-3 mb-3 pl-0 pr-4">
                  <label>{{ 'Image (Desktop)' }}: </label>
                    <label class="btn btn-default btn-sm btn-upload upload-icon" *ngIf="getMediaSource(value.id, 'image_desktop') == null && !isImageUploading[0]">
                      <img src="assets/img/upload-icon.png">
                      <span>{{ 'Upload' }}</span>
                      <input class="form-control" type="file" hidden accept="image/*"
                      (change)="onUploadFile($event, value.id, 0,'image_desktop')" [disabled]="(mobileLoading || desktopLoading)" (keyup)="onChangeImageValidate($event, value.id, 'image_desktop')">
                    </label>
                    <div class="spinner-wrapper" *ngIf="isImageUploading[0]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                    </div>

                    <div *ngIf="getMediaSource(value.id, 'image_desktop') != null && !isImageUploading[0]">
                      <div class="image-upload mt-2">
                        <div class="image-wrapper">
                          <img class="fas fa-trash position-absolute m-2 trash-icon" src="assets/img/trash-icon.png" (click)="onRemoveFile(value.id, 0,'image_desktop')">
                          <img class="img-fluid preview-image" alt="Desktop Image"
                            [src]="getMediaSource(value.id, 'image_desktop')" />
                        </div>
                      </div>
                    </div>
                </div>

                <div class="form-group col-3 mb-3 pl-0 pr-4">
                  <label>{{ 'Image (Mobile)' }}: </label>
                    <label class="btn btn-default btn-sm btn-upload upload-icon" *ngIf="getMediaSource(value.id, 'image_mobile') == null && !isImageUploading[1]">
                      <img src="assets/img/upload-icon.png">
                      <span>{{ 'Upload' }}</span>
                      <input class="form-control" type="file" hidden accept="image/*"
                      (change)="onUploadFile($event, value.id, 1,'image_mobile')" [disabled]="(mobileLoading || desktopLoading)" (keyup)="onChangeImageValidate($event, value.id, 'image_mobile')">
                    </label>
                    <div class="spinner-wrapper" *ngIf="isImageUploading[1]">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                    </div>

                    <div *ngIf="getMediaSource(value.id, 'image_mobile') != null && !isImageUploading[1]">
                      <div class="image-upload mt-2">
                        <div class="image-wrapper">
                          <img class="fas fa-trash position-absolute m-2 trash-icon" src="assets/img/trash-icon.png" (click)="onRemoveFile(value.id, 1,'image_mobile')">
                          <img class="img-fluid preview-image" alt="Mobile Image"
                            [src]="getMediaSource(value.id, 'image_mobile')" />
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
        <div class="modal-footer">
          <table class="info mr-3" *ngIf="mode == 'edit'">
            <tr>
              <td class="header-hint">
                Updated by: {{ banner.updated_by ? banner.updated_by + '&nbsp;' : 'System&nbsp;' }}
              </td>
              <td class="header-hint">
                <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="tooltip-class">{{ banner.updated_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
                <ng-template #updatedDateTime>
                  {{ banner.updated_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
                </ng-template>
              </td>
            </tr>
            <tr>
              <td class="header-hint">
                Created by: {{ banner.created_by ? banner.created_by + '&nbsp;' : 'System&nbsp;' }}
              </td>
              <td class="header-hint">
                <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="tooltip-class">{{ banner.created_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
                <ng-template #createdDateTime>
                  {{ banner.created_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
                </ng-template>
              </td>
            </tr>
          </table>
          <button type="button" class="btn btn-danger m-0 mr-2" (click)="mode == 'create' ? expandRowCreate('', true) : expandRowEdit('', null, true)">{{ 'Cancel' }}</button>
          <button *ngIf="(mode == 'create' && canCreateNewBanner) || (mode == 'edit' && canEditBanner)" type="button" class="btn btn-success m-0" [disabled]="isEmptyContent || !formContent.valid || isImageUploading[0] || isImageUploading[1]" (click)="onSave()">{{ mode != null && mode == 'create' ? 'Create' : 'Update' }}</button>
        </div>
      </div>
    </form>
  </ng-template>

  <kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>
