import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-superuser-reports-winlose-full',
  templateUrl: './superuser-reports-winlose-full.component.html',
  styleUrls: ['./superuser-reports-winlose-full.component.scss']
})
export class SuperuserReportsWinloseFullComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
