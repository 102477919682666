import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { GameProviderDataService } from '../../game-providers/services/game-provider-data.service';
import { FormGroup, FormControl } from '@angular/forms';
@Component({
  selector: 'kt-assign-games-edit',
  templateUrl: './assign-games-edit.component.html',
  styleUrls: ['./assign-games-edit.component.scss']
})
export class AssignGamesEditDialogComponent implements OnInit, OnDestroy {

  refreshStatus: boolean;

  dropdown = {
    gameList: [],
    allGameList: [],
  }

  selectedCount = 0;
  loading = false;
  selectAll = false;
  form: FormGroup;

  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { gameProvider: any },
    public dialogRef: MatDialogRef<AssignGamesEditDialogComponent>,
    private gameProviderDataService: GameProviderDataService,
  ) { }

  ngOnInit() {
    this.loading = true;
    const params = {
      paginate: false,
      status: 1,
      game_provider_id: this.data.gameProvider.game_provider_id,
      game_provider_code: this.data.gameProvider.game_provider_code,
      category_code: this.data.gameProvider.category_code,
    }

    this.gameProviderDataService.getSelfGameList(params).subscribe(res => {
      if (res !== undefined && res !== null) {
        let games = res;

        games.forEach(item => {
          item['selected'] = false;
          item['added'] = false;

          let checkSelected = this.data.gameProvider.games.find(x => x.game_id === item.id);

          if (checkSelected !== undefined) {
            item['added'] = true;
          }

          item['game_id'] = item['id'];
          item['id'] = null;
        });

        games = games.filter(x => x.added == false);

        this.dropdown.allGameList = games;
        this.dropdown.gameList = games.map(obj => ({ ...obj }));
      }

      this.loading = false;
      this.form.get('select_all').enable();
    });

    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close([]);
  }

  onSave() {
    this.refreshStatus = true;
    this.onRefresh();
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      let selecteGameList = this.dropdown.gameList.filter(x => x.selected === true);
      this.dialogRef.close(selecteGameList);
    }
  }

  selectGame(game: any) {
    this.dropdown.gameList.map(x => x.selected == false && x.game_id == game.game_id ? x.selected = true : x.selected == true && x.game_id == game.game_id ? x.selected = false : x.selected = x.selected);
    this.dropdown.allGameList.map(x => x.selected == false && x.game_id == game.game_id ? x.selected = true : x.selected == true && x.game_id == game.game_id ? x.selected = false : x.selected = x.selected);
    this.selectedCount = this.dropdown.allGameList.filter(x => x.selected === true).length;

    if (this.selectedCount !== this.dropdown.allGameList.length) {
      this.selectAll = false;
      this.form.patchValue({
        select_all: false
      });
    }
    else if (this.dropdown.allGameList.length > 0 && this.selectedCount == this.dropdown.allGameList.length) {
      this.selectAll = true;
      this.form.patchValue({
        select_all: true
      });
    }
  }


  onSearch(event?) {
    let keyword = event && event.target.value ? event.target.value : null;
    let cloneAllGameList = this.dropdown.allGameList.map(obj => ({ ...obj }));

    if (keyword !== null) {
      // this.dropdown.gameList = cloneAllGameList.filter(x => x.name.toLowerCase().includes(keyword.toLowerCase()));
      this.dropdown.gameList = cloneAllGameList.filter(x => x.selected === true || x.name.toLowerCase().includes(keyword.toLowerCase()))
    }
    else {
      this.dropdown.gameList = cloneAllGameList;
    }
  }

  selectAllGame() {
    if (this.selectAll) {
      this.selectAll = false;
      this.dropdown.gameList.map(x => x.selected = false);
      this.dropdown.allGameList.map(x => x.selected = false);
      this.selectedCount = this.dropdown.allGameList.filter(x => x.selected === true).length;
    }
    else {
      this.selectAll = true;
      this.dropdown.gameList.map(x => x.selected = true);
      this.dropdown.allGameList.map(x => x.selected = true);
      this.selectedCount = this.dropdown.allGameList.filter(x => x.selected === true).length;
    }
  }

  private formInit() {
    this.form = new FormGroup({
      select_all: new FormControl({ value: false, disabled: true }),
    });
  }
}
