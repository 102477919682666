import { Component, Inject, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { UploadHttpService } from '@core/services/upload-http.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import * as moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { PromotionCodeDataService } from '../../../../general/promotion-codes/services/promotion-code-data.service';
import { PromotionCodeEntityService } from '../../../../general/promotion-codes/services/promotion-code-entity.service';
import { RebateEntityService } from '../../../../rebates/rebates/services/rebate-entity.service';
import { RebateDataService } from '../../../../rebates/rebates/services/rebate-data.service';
import { Promotion } from '@core/models/promotion.model';
import { Rebate } from '@core/models/rebate.model';
import { Observable, Subscription, interval, forkJoin, of } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { tap, exhaustMap, map, debounceTime, distinctUntilChanged, catchError, switchMap } from 'rxjs/operators';
import { Status } from '@core/enums/status.enum'
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'kt-quick-link-settings',
    templateUrl: './quick-link-settings.component.html',
    styleUrls: ['./quick-link-settings.component.scss']
})

export class QuickLinkSettingsComponent implements OnInit {

    /*****************************************************************/
    /*                     Variables Initialization                  */
    /*****************************************************************/

    /* Shared variables */
    tableMaxHeight: number = 400; // Default max-height
    pageSize = 30;
    selectedPromo: string | null = null;
    PromoLoading = false;
    TableLoading = false;
    FilterLoading = false;
    buttonLoading = false;
    memberGroupDropDownList = [];
    currencyDropdownSettings = {};
    currencyDropdownList = [];
    categoryDropdownSettings = {};
    categoryDropdownList = [];
    currencyIdToName = {};
    categoryIdToName = {};

    /* Promotion codes variables */
    promoCode_page = 1;
    promoCode_status = Status;
    promoCode_checkboxForm: FormGroup;
    promoCode_form: FormGroup;
    promoCode_targetTypeForm: FormGroup;
    promoCode_loading = false;
    promoCode_tableLoading = false;
    promoCode_buttonLoading = false;
    promoCode_loadmore = true;
    promoCode_clearBtnLoading = false;
    promoCode_searchBtnLoading = false;
    promoCode_checkboxLoaded = false;
    promoCode_toggleAll: boolean = false;
    promoCode_FilterHide = false;
    promoCode_loadedCurrency = false;
    promoCode_loadedCategory = false;
    promoCode_isFirstTimeLoad = true;
    promoCode_isUpdated = false;
    promoCode_params = '';
    promoCode_sortedColumn: string = '';
    promoCode_sortDirection: 'asc' | 'desc' = 'asc';
    promoCode_messages$ = this.promotionCodeDataService.messages$;
    promoCode_currencySelectedItems = [];
    promoCode_categorySelectedItems = [];
    promoCode_changes = [];
    promoCode_targetType_changes = [];
    promoCode_targetTypeForm_subscription;
    promoCode_initialCheckboxState = {};
    promoCode_targetType_initialState = {};

    promotion$: Observable<Promotion[]>;
    promotion = [];
    promotionDetails: any;
    AssignedPromotion = [];
    existedPromotion = [];
    loadedExistedPromotion = [];

    private promo_update_subscription = new Subscription();
    private promotion_with_gp_subscription = new Subscription();


    /* Rebates setting variables */
    rebates_page = 1;
    rebates_status = Status;
    rebates_checkboxForm: FormGroup;
    rebates_form: FormGroup;
    rebates_loading = false;
    rebates_tableLoading = false;
    rebates_buttonLoading = false;
    rebates_loadmore = true;
    rebates_clearBtnLoading = false;
    rebates_searchBtnLoading = false;
    rebates_checkboxLoaded = false;
    rebates_toggleAll: boolean = false;
    rebates_FilterHide = false;
    rebates_loadedCurrency = false;
    rebates_loadedCategory = false;
    rebates_isFirstTimeLoad = true;
    rebates_isUpdated = false;
    rebates_params = '';
    rebates_sortedColumn: string = '';
    rebates_sortDirection: 'asc' | 'desc' = 'asc';
    rebates_messages$ = this.promotionCodeDataService.messages$;
    rebates_currencySelectedItems = [];
    rebates_categorySelectedItems = [];
    rebates_changes = [];

    rebates$: Observable<Rebate[]>;
    rebates = [];
    rebates_blacklistedGameProviders$ = this.gameProviderHttpService.blacklistedGameProviders$;
    rebates_blacklistedGameProvidersData = null;
    rebatesDetails: any;
    AssignedRebate = [];
    existedRebate = [];
    loadedExistedRebate = [];

    private rebate_update_subscription = new Subscription();
    private rebate_with_gp_subscription = new Subscription();

    /* Constructor */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { gameProvider: any },
        public dialogRef: MatDialogRef<QuickLinkSettingsComponent>,
        private dropdownHttpService: DropdownHttpService,
        private uploadService: UploadHttpService,
        private promotionCodeEntityService: PromotionCodeEntityService,
        private promotionCodeDataService: PromotionCodeDataService,
        private rebateEntityService: RebateEntityService,
        private rebateDataService: RebateDataService,
        private gameProviderHttpService: GameProviderHttpService,
        private loadingBar: LoadingBarService,
        private translateService: TranslateService,
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private cdr: ChangeDetectorRef,
    ) { 
        this.promoCode_targetTypeForm = this.formBuilder.group({});
    }

    /* Dropdown services */
    dropdown = {
        currencies: this.dropdownHttpService.currencies,
        categories: this.dropdownHttpService.gameCategories,
        promoTypes: this.dropdownHttpService.promoTypes,
        memberGroups: this.dropdownHttpService.groups,
        supported_target_type: [
            { id: 1, name: 'Turnover' },
            { id: 2, name: 'Winover' },
        ],
        statuses: this.dropdownHttpService.statuses,
    };

    /*****************************************************************/
    /*                 End of Variables Initialization               */
    /*****************************************************************/

    /*****************************************************************/
    /*                      Main Flow Functions                      */
    /*****************************************************************/

    /* Initialize forms and dropdowns */
    ngOnInit() {
      // prevent user to exit modal by clicking outside of the modal
      // this is to prevent user misclick and all the changes will be gone
      this.dialogRef.disableClose = true;
      this.PromoLoading = true;
      this.TableLoading = true;
      this.isSelectedPromo('promotions');
      this.selectedPromo = 'promotions';
      this.dropdown.memberGroups.subscribe(res => {
        this.memberGroupDropDownList = res;
      })
      this.setCurrencyDropdown().then(() => {
        this.setCategoryDropdown().then(() => {
            this.PromoformInit();
            this.RebateformInit();
            this.getExistedPromotion();
            this.onSubmit('promotions', false);
        });
      });
    }

    ngOnDestroy() {
      this.promo_update_subscription.unsubscribe();
      this.promotion_with_gp_subscription.unsubscribe();
      this.rebate_update_subscription.unsubscribe();
      this.rebate_with_gp_subscription.unsubscribe();
    }

    private PromoformInit() {
      let currencyID = this.data.gameProvider.currency ?? null;
      let categoryID = this.data.gameProvider.catogory ?? null;
      let target_type: number | string;
      let disable_target_type: boolean;
      if(this.data.gameProvider.supported_target_type === 1){
        target_type = 'all';
        disable_target_type = false;
      }
      else if(this.data.gameProvider.supported_target_type === 2){
        target_type = 1;
        disable_target_type = true;
      }
      else if(this.data.gameProvider.supported_target_type === 3){
        target_type = 2;
        disable_target_type = true;
      }
      this.promoCode_form = new FormGroup({
        name: new FormControl(null),
        status: new FormControl('1'),
        currency_id: new FormControl(currencyID, [Validators.required]),
        category_id: new FormControl(categoryID, [Validators.required]),
        target_type: new FormControl({value: target_type, disabled: disable_target_type}),
        promo_type: new FormControl('all'),
      });
    }

    private RebateformInit() {
      let currencyID = this.data.gameProvider.currency ?? null;
      let categoryID = this.data.gameProvider.catogory ?? null;
      this.rebates_form = new FormGroup({
        name: new FormControl(null),
        currency_id: new FormControl(currencyID, [Validators.required]),
        category_id: new FormControl(categoryID, [Validators.required]),
      });
    }

    private setCurrencyDropdown(isOnClear?: boolean): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        this.dropdown.currencies.subscribe(res => {
          this.currencyDropdownList = res;
          // Create mappings from code to name for currencies
          this.currencyIdToName = this.currencyDropdownList.reduce((acc, item) => {
            acc[item.id] = item.name;  // Map currency code to name
            return acc;
          }, {});
          this.currencyDropdownList = res.filter(x => this.data.gameProvider.currency_code.includes(x.name));
          if (this.data.gameProvider.currency_code !== null) {
            if(this.selectedPromo === 'promotions'){
              if(!this.promoCode_loadedCurrency || isOnClear){
                this.promoCode_currencySelectedItems = res.filter(x => this.data.gameProvider.currency_code.includes(x.name));
                this.promoCode_loadedCurrency = true;
              }
            }
            else{
              if(!this.rebates_loadedCurrency || isOnClear){
                this.rebates_currencySelectedItems = res.filter(x => this.data.gameProvider.currency_code.includes(x.name));
                this.rebates_loadedCurrency = true;
              }
            }
          }
          resolve();
        }, error => {
          reject(error);
        });
    
        this.currencyDropdownSettings = {
          autoPosition: true,
          maxHeight: 150,
          singleSelection: false,
          text: this.translateService.instant('Please Select'),
          enableFilterSelectAll: true,
          enableSearchFilter: false,
          classes: 'dropdown',
          primaryKey: 'id',
          labelKey: 'name',
          lazyLoading: true,
          noDataLabel: '',
          showCheckbox: false
        };
      });
    }

    private setCategoryDropdown(isOnClear?: boolean): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        this.dropdown.categories.subscribe(res => {
        this.categoryDropdownList = res;
        // Create mappings from ID to name for categories
        this.categoryIdToName = this.categoryDropdownList.reduce((acc, item) => {
          acc[item.id] = item.name;  // Map category ID to name
          return acc;
        }, {});
        this.categoryDropdownList = res.filter(x => this.data.gameProvider.categories.some(e => e.code === x.code));
        if (this.data.gameProvider.categories !== null) {
          if(this.selectedPromo === 'promotions'){
            if(!this.promoCode_loadedCategory || isOnClear){
              this.promoCode_categorySelectedItems = res.filter(x => this.data.gameProvider.categories.some(e => e.code === x.code));
              this.promoCode_loadedCategory = true;
            }
          }
          else{
            if(!this.rebates_loadedCategory || isOnClear){
              this.rebates_categorySelectedItems = res.filter(x => this.data.gameProvider.categories.some(e => e.code === x.code));
              this.rebates_loadedCategory = true;
            }
          }
        }
        resolve();
        }, error => {
          reject(error);
        })
    
        this.categoryDropdownSettings = {
          autoPosition: true,
          maxHeight: 150,
          singleSelection: false,
          text: this.translateService.instant('Please Select'),
          enableFilterSelectAll: true,
          enableSearchFilter: false,
          classes: 'dropdown',
          primaryKey: 'id',
          labelKey: 'name',
          lazyLoading: true,
          noDataLabel: '',
          showCheckbox: false,
        };
      });
    }

    private getExistedPromotion(){
      this.promotion_with_gp_subscription = this.promotionCodeDataService.getPromotionDetailsByGameProvider(this.data.gameProvider.id).pipe(
        tap(res => {
          this.existedPromotion = res;
        })
      ).subscribe();
      this.rebate_with_gp_subscription = this.rebateDataService.getRebateIdsByGameProvider(this.data.gameProvider.id).pipe(
        tap(res => {
          this.existedRebate = res;
        })
      ).subscribe();
    }
    /* End of initialize forms and dropdowns */

    /* Checkbox related functions */
    private async shouldCheck(){
      if(this.selectedPromo === 'promotions'){
        // Only verify check / unchecked for loaded content
        this.loadedExistedPromotion = this.existedPromotion.map(item => item.promotion_id);
        this.loadedExistedPromotion = this.loadedExistedPromotion
        .filter(promotion_id   => this.promotion.some(promo => promo.id === promotion_id )) //filter by loaded content
        .filter(promotion_id  => !this.promoCode_changes.some(change => change.promotion_id === promotion_id && change.action === 'delete')); //filter by unchecked row by user
        let promoCode_prepareForm = {};
        if(!this.promoCode_checkboxLoaded){
          this.AssignedPromotion = this.loadedExistedPromotion;
        }
        this.AssignedPromotion = [...new Set([
          ...(this.AssignedPromotion || []),
          ...this.loadedExistedPromotion
        ])];
        this.promotion.forEach(item => {
          let check = this.AssignedPromotion.filter(x => x === item.id)
          let exist = check.length > 0 ? true : false
          promoCode_prepareForm = { ...promoCode_prepareForm, [item.id]: exist };
        });
        this.promoCode_checkboxForm = this.formBuilder.group(promoCode_prepareForm);
        this.promotion = this.promotion.sort((a, b) => {
          let aChecked = this.AssignedPromotion.includes(a.id);
          let bChecked = this.AssignedPromotion.includes(b.id);
          if (aChecked !== bChecked) {
            return aChecked ? -1 : 1;
          }

          let aSelectable = this.isSelectableTargetType(a.target_type);
          let bSelectable = this.isSelectableTargetType(b.target_type);
        
          // Only check isSelectable if both are checked (box checked by user manually will not be sorted)
          // if (aChecked && bChecked) {
          //   if (a.isSelectable !== b.isSelectable) {
          //     return a.isSelectable ? -1 : 1;
          //   }
          // }

          // Only check isSelectable if both are checked (box checked by user manually will be sorted)
          if (aChecked && bChecked) {
            if (aSelectable !== bSelectable) {
              return aSelectable ? -1 : 1;
            }
          }

          const updatedAtComparison = this.compare(a.updated_at, b.updated_at, 'desc');
          if (updatedAtComparison !== 0) {
            return updatedAtComparison;
          }

          // sort by id in descending order as a tie-breaker
           return b.id - a.id;
        });
        if (this.promotion.length != this.AssignedPromotion.length) {
          this.promoCode_toggleAll = false;
        }
        else {
          this.promoCode_toggleAll = true;
        }
        if(this.promoCode_isUpdated) {
          this.promoCode_initialCheckboxState = {};
          this.promoCode_targetType_initialState = {};
          this.promoCode_isUpdated = false;
        }
        this.promotion.forEach(row => {
          if(!this.promoCode_initialCheckboxState.hasOwnProperty(row.id)) {
            this.promoCode_initialCheckboxState[row.id] = this.promoCode_checkboxForm.controls[row.id].value;
          }
          if (!this.promoCode_targetType_initialState.hasOwnProperty(row.id)) {
              this.promoCode_targetType_initialState[row.id] = this.promoCode_targetTypeForm.controls[row.id].value;
          }
        });

        this.promoCode_targetTypeForm_subscription = this.promoCode_targetTypeForm.valueChanges.subscribe(data => {
            Object.keys(data).forEach(key => {
            if (this.promoCode_targetType_initialState.hasOwnProperty(key)) {
              const promotion = this.promotion.find(promo => promo.id === +key);
              const target_type_id = promotion ? promotion.target_type_id : null;
              const initialTargetType = this.promoCode_targetType_initialState[key];
              let currentTargetType = data[key];
              const existingIndex = this.promoCode_targetType_changes.findIndex(item => item.promotion_id === +key);
  
              if(currentTargetType !== initialTargetType){
                // If 'Turnover' option is selected, manually assign 'Turnover incl. Transfer Amount' or 'Turnover excl. Transfer Amount'
                // based on which target type that the promotion offered
                if(currentTargetType === 1){
                  if(target_type_id === 5 || target_type_id === 6) {
                    currentTargetType = 1;
                  }
                  else if(target_type_id === 7 || target_type_id === 8) {
                    currentTargetType = 3;
                  }
                }
                // If 'Winover' option is selected, manually assign 'Winover incl. Transfer Amount' or 'Winover excl. Transfer Amount'
                // based on which target type that the promotion offered
                else if(currentTargetType === 2) {
                  if(target_type_id === 5 || target_type_id === 7) {
                    currentTargetType = 2;
                  }
                  else if(target_type_id === 6 || target_type_id === 8) {
                    currentTargetType = 4;
                  }
                }
                // Update the target type if it already existed
                if (existingIndex !== -1) {
                  this.promoCode_targetType_changes[existingIndex].target_type = currentTargetType;
                } else {
                    this.promoCode_targetType_changes.push({
                      promotion_id: +key,
                      category: promotion.category,
                      target_type: currentTargetType
                  });
                }
              }
            }
          });
          this.promoCode_targetType_changes.forEach(promoChange => {
            let index = this.promoCode_changes.findIndex(promo => promo.promotion_id == promoChange.promotion_id);
        
            // If a match is found, update the target_type
            if (index !== -1) {
                this.promoCode_changes[index].target_type = promoChange.target_type;
            }
            else{
              this.promoCode_changes.push({
                promotion_id: promoChange.promotion_id,
                promotion_categories: promoChange.category,
                target_type: promoChange.target_type,
                action: 'add'
            });
            }
          });
        });

        this.promoCode_checkboxForm.valueChanges.subscribe(data => {
          const selectedRows = Object.keys(data)
          .filter(key => data[key])  // Only keep keys with a true value
          .map(key => {
            return this.promotion.find(row => row.id == key);
          });
          Object.keys(data).forEach(key => {
            const index = this.promotion.findIndex(r => r.id == key);
            if(index !== -1){
              const row = { ...this.promotion[index] };
              const control = this.promoCode_targetTypeForm.get(`${row.id}`);
              const gameProviders = row.game_provider ? row.game_provider.split(', ') : [];
              const initialState = this.promoCode_initialCheckboxState[key];
              const currentState = data[key];
  
              // Find any existing entry for this promotion ID
              const existingChangeIndex = this.promoCode_changes.findIndex(change => change.promotion_id === row.id);
              const existingTargetChangeIndex = this.promoCode_targetType_changes.findIndex(change => change.promotion_id == row.id);
  
              if (currentState === true) {
                if(this.isSelectableTargetType(row.target_type) && this.data.gameProvider.supported_target_type === 1){
                    control.enable();
                }
                if (!this.AssignedPromotion.includes(Number(key))) {
                  this.AssignedPromotion.push(Number(key));
                }
                if (!gameProviders.includes(this.data.gameProvider.code)) {
                  gameProviders.push(this.data.gameProvider.code);
                }
                if (initialState !== true) {
                  if (existingChangeIndex !== -1) {
                    // Update the existing entry
                    this.promoCode_changes[existingChangeIndex] = {
                        ...this.promoCode_changes[existingChangeIndex],
                        action: 'add'
                    };
                  } else {
                    this.promoCode_changes.push({
                      promotion_id: row.id,
                      promotion_categories: row.category,
                      action: 'add'
                    });
                  }
                }
                // Only remove the target type changes for those unchecked rows, so all the checked rows will have their target type changes remain in the array
                // This is needed as it is subscribe for FormGroup value changes, so it will loop through every formcontrol of this formgroup instead of specific row
                else if(existingChangeIndex !== -1 && initialState === currentState && !this.promoCode_changes[existingChangeIndex].target_type){
                  this.promoCode_changes.splice(existingChangeIndex, 1);
                }
              }
              else{
                // Checkbox unchecked: remove the promotion ID if present
                if(this.isSelectableTargetType(row.target_type)){
                    control.disable();
                    if(control.value && this.data.gameProvider.supported_target_type === 1){
                      const matchedRecord = this.existedPromotion.find(promo => promo.promotion_id === row.id);
                      control.setValue(matchedRecord ? matchedRecord.target_type : null);
                    }
                }
                const AssignedPromotionindex = this.AssignedPromotion.indexOf(Number(key));
                if(AssignedPromotionindex !== -1){
                  this.AssignedPromotion.splice(AssignedPromotionindex, 1);
                }
  
                const providerIndex = gameProviders.indexOf(this.data.gameProvider.code);
                if (providerIndex !== -1) {
                  gameProviders.splice(providerIndex, 1);
                }

                if(existingTargetChangeIndex !== -1){
                  this.promoCode_targetType_changes = this.promoCode_targetType_changes.filter(change => change.promotion_id !== row.id);
                }
  
                if (initialState !== false) {
                  if (existingChangeIndex !== -1) {
                    // Update the existing entry
                    this.promoCode_changes[existingChangeIndex] = {
                        promotion_id: row.id,
                        promotion_categories: row.category,
                        action: 'delete',
                        target_type: null
                    };
                  } else {
                    this.promoCode_changes.push({
                      promotion_id: row.id,
                      promotion_categories: row.category,
                      action: 'delete'
                  });
                  }
                }
                else if(existingChangeIndex !== -1 && initialState === currentState){
                  this.promoCode_changes.splice(existingChangeIndex, 1);
                }
              }
            }
          })
    
          if (this.promotion.length != this.AssignedPromotion.length) {
            this.promoCode_toggleAll = false;
          }
          else {
            this.promoCode_toggleAll = true;
          }
        })
        this.PromoLoading = false;
        this.TableLoading = false;
        this.promoCode_checkboxLoaded = true;
      }
      else{
        this.loadedExistedRebate = this.existedRebate.filter(id => this.rebates.map(rebate => rebate.id).includes(id));
        let rebates_prepareForm = {};
        if(!this.rebates_checkboxLoaded){
          this.AssignedRebate = this.loadedExistedRebate;
        }
        this.AssignedRebate = [...new Set([
          ...(this.AssignedRebate || []),
          ...this.loadedExistedRebate
        ])];
        this.rebates.forEach(item => {
          let check = this.AssignedRebate.filter(x => x === item.id)
          let exist = check.length > 0 ? true : false
          rebates_prepareForm = { ...rebates_prepareForm, [item.id]: exist };
        });
        this.rebates_checkboxForm = this.formBuilder.group(rebates_prepareForm);
        this.rebates = this.rebates.sort((a, b) => {
          let aChecked = this.AssignedRebate.includes(a.id);
          let bChecked = this.AssignedRebate.includes(b.id);
          if (aChecked !== bChecked) {
            return aChecked ? -1 : 1;
          }

          // If both are checked or both are unchecked, sort by 'updated_at'
          const updatedAtComparison = this.compare(a.updated_at, b.updated_at, 'desc');
          if (updatedAtComparison !== 0) {
            return updatedAtComparison;
          }

          // If 'updated_at' is the same, sort by id in descending order as a tie-breaker
          return b.id - a.id;
        });
        if (this.rebates.length != this.AssignedRebate.length) {
          this.rebates_toggleAll = false;
        }
        else {
          this.rebates_toggleAll = true;
        }
        const initialCheckboxState = {};
        this.rebates.forEach(row => {
            initialCheckboxState[row.id] = this.rebates_checkboxForm.controls[row.id].value;
        });

        this.rebates_checkboxForm.valueChanges.subscribe(data => {
          const selectedRows = Object.keys(data)
          .filter(key => data[key])  // Only keep keys with a true value
          .map(key => {
            return this.rebates.find(row => row.id == key);
          });
          Object.keys(data).forEach(key => {
            const index = this.rebates.findIndex(r => r.id == key);
            if(index !== -1){
              const row = { ...this.rebates[index] };
              const initialState = initialCheckboxState[key];
              const currentState = data[key];
  
              // Remove any existing entry for this rebate setting ID
              this.rebates_changes = this.rebates_changes.filter(change => change.rebate_setting_id !== row.id);
  
              if (currentState === true) {
                if (!this.AssignedRebate.includes(Number(key))) {
                  this.AssignedRebate.push(Number(key));
                }
                if (initialState !== true) {
                  const distinctMemberGroupIds = [...new Set(row.member_groups.map(group => group.member_group_id))];
                  this.rebates_changes.push({
                    member_group_id: distinctMemberGroupIds,
                    currency_id: row.currency_id,
                    game_provider_id: this.data.gameProvider.id,
                    category_id: row.category_id,
                    rebate_setting_id: row.id,
                    status: row.status,
                    action: 'add'
                  });
                }
              }
              else{
                // Checkbox unchecked: remove the rebate setting ID if present
                const AssignedRebateindex = this.AssignedRebate.indexOf(Number(key));
                if(AssignedRebateindex !== -1){
                  this.AssignedRebate.splice(AssignedRebateindex, 1);
                }
  
                if (initialState !== false) {
                  const distinctMemberGroupIds = [...new Set(row.member_groups.map(group => group.member_group_id))];
                  this.rebates_changes.push({
                    member_group_id: distinctMemberGroupIds,
                    currency_id: row.currency_id,
                    game_provider_id: this.data.gameProvider.id,
                    category_id: row.category_id,
                    rebate_setting_id: row.id,
                    status: row.status,
                    action: 'delete'
                  });
                }
              }
            }
          })
    
          if (this.rebates.length != this.AssignedRebate.length) {
            this.rebates_toggleAll = false;
          }
          else {
            this.rebates_toggleAll = true;
          }
        })
        this.PromoLoading = false;
        this.TableLoading = false;
        this.rebates_checkboxLoaded = true;
      }
    }

    checkboxToggleAll() {
      let patchValue = {};
      if(this.selectedPromo === 'promotions'){
        if (!this.promoCode_toggleAll) {
          this.promotion.forEach(item => {
            patchValue = { ...patchValue, [item.id]: true };
          });
    
          this.AssignedPromotion = this.promotion.map(x => { 
            return x.id;
          });
        }
        else {
          this.promotion.forEach(item => {
            patchValue = { ...patchValue, [item.id]: false };
          });
    
          this.AssignedPromotion = [];
        }
        this.promoCode_checkboxForm.patchValue(patchValue);
      }
      else{
        if (!this.rebates_toggleAll) {
          this.rebates.forEach(item => {
            patchValue = { ...patchValue, [item.id]: true };
          });
    
          this.AssignedRebate = this.rebates.map(x => { 
            return x.id;
          });
        }
        else {
          this.rebates.forEach(item => {
            patchValue = { ...patchValue, [item.id]: false };
          });
    
          this.AssignedRebate = [];
        }
        this.rebates_checkboxForm.patchValue(patchValue);
      }
    }
    /* End of checkbox related functions */

    /* Search and clear filter option functions */
    async onSubmit(promotype?:any, scrollload?: boolean, isSearch?: boolean) {
      if(this.promoCode_targetTypeForm_subscription){
        this.promoCode_targetTypeForm_subscription.unsubscribe();
      }
      this.selectedPromo = promotype;
      let proceed_search = true;
      if(!scrollload && (!this.promoCode_isFirstTimeLoad || !this.rebates_isFirstTimeLoad)){
        proceed_search = await this.checkPendingChanges(promotype);
      }
      if(proceed_search){
        if (!scrollload) {
          if(promotype === 'promotions'){
            if(!this.promoCode_checkboxLoaded || isSearch || this.promoCode_isFirstTimeLoad){
              this.promoCode_page = 1;
              this.promoCode_loadmore = true;
              this.promotion = [];
              this.AssignedPromotion = [];
              this.promoCode_searchBtnLoading = true;
              this.promoCode_clearBtnLoading = true;
              this.promoCode_tableLoading = true;
            }
          }
          else{
            if(!this.rebates_checkboxLoaded || isSearch || this.rebates_isFirstTimeLoad){
              this.rebates_page = 1;
              this.rebates_loadmore = true;
              this.rebates = [];
              this.AssignedRebate = [];
              this.rebates_searchBtnLoading = true;
              this.rebates_clearBtnLoading = true;
              this.rebates_tableLoading = true;
            }
          }
        }
        else{
          if(promotype === 'promotions'){
            this.promoCode_page++;
            this.promoCode_searchBtnLoading = true;
            this.promoCode_clearBtnLoading = true;
            this.promoCode_loading = true;
          }
          else{
            this.rebates_page++;
            this.rebates_searchBtnLoading = true;
            this.rebates_clearBtnLoading = true;
            this.rebates_loading = true;
          }
        }
        if(scrollload || this.promoCode_isFirstTimeLoad || this.rebates_isFirstTimeLoad || isSearch){
          of(promotype === 'promotions' ? this.promoCode_form.value : this.rebates_form.value).pipe(
            map(formValue => {
              const selectedCurrencies = promotype === 'promotions' ? this.promoCode_currencySelectedItems : this.rebates_currencySelectedItems;
              const selectedCategories = promotype === 'promotions' ? this.promoCode_categorySelectedItems : this.rebates_categorySelectedItems;
              return this.processForm(formValue, selectedCurrencies, selectedCategories);
            }),
            map(this.filterFormFields),
            exhaustMap((data) => {
              if(!scrollload){
                if(promotype === 'promotions'){
                  this.promoCode_params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
                }
                else{
                  this.rebates_params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
                }
              }
              this.loadingBar.start();
              if(promotype === 'promotions'){
                let target_type: number;
                let parameters;
                if(this.data.gameProvider.supported_target_type === 2) {
                  target_type = 1;
                }
                else if(this.data.gameProvider.supported_target_type === 3) {
                  target_type = 2;
                }
                if(target_type === 1 || target_type === 2) {
                  parameters = this.promoCode_params ? `page=${this.promoCode_page}&perPage=${this.pageSize}&target_type=${target_type}&${this.promoCode_params}` : `page=${this.promoCode_page}&perPage=${this.pageSize}&target_type=${target_type}`;
                }
                else{
                  parameters = this.promoCode_params ? `page=${this.promoCode_page}&perPage=${this.pageSize}&${this.promoCode_params}` : `page=${this.promoCode_page}&perPage=${this.pageSize}`;
                }
                return this.promotionCodeEntityService.getWithQuery(`?${parameters}`).pipe(
                  tap(res => {
                    this.promoCode_loading = false;
                    this.promoCode_clearBtnLoading = false;
                    this.promoCode_searchBtnLoading = false;
                    this.FilterLoading = false;
                    this.promoCode_tableLoading = false;
                    this.promotion = this.deepClone([...this.promotion, ...res]);
                    if(this.promoCode_isFirstTimeLoad || isSearch) {
                      this.promoCode_targetTypeForm.reset();
                      this.promoCode_targetTypeForm = this.formBuilder.group({});
                    }
                    this.promoCode_isFirstTimeLoad = false;
                    // Filter out rows that do not have target type to prevent form control adding error
                    // Please note that target type is a must for a promotion, so without it is considered as abnormal behavior
                    this.promotion = this.promotion.filter((row) => {
                      const promotionSupportedTargetType = this.getSupportedTargetType(row.target_type);
                      return promotionSupportedTargetType !== null;
                    });
                    this.promotion.forEach((row) => {
                      const isSelectable = this.isSelectableTargetType(row.target_type, row.id);
                      const promotionSupportedTargetType = this.getSupportedTargetType(row.target_type);
                      let selectedTargetType;
                      const matchedPromotion = this.existedPromotion.find(item => item.promotion_id === row.id);
                      selectedTargetType = matchedPromotion ? 
                      (matchedPromotion.target_type == 1 || matchedPromotion.target_type == 3) ? 1
                      : (matchedPromotion.target_type == 2 || matchedPromotion.target_type == 4) ? 2
                      : null
                      : null;
                      if(this.data.gameProvider.supported_target_type === 1) {
                        this.promoCode_targetTypeForm.addControl(`${row.id}`, new FormControl({
                          value: selectedTargetType ? selectedTargetType : promotionSupportedTargetType.select,
                          disabled: !isSelectable
                        }));

                        row.target_type_id = promotionSupportedTargetType.target_type_id;
                      }
                      else {
                        let target_type_id;
                        let select;
                        if(promotionSupportedTargetType.target_type_id > 4 && promotionSupportedTargetType.target_type_id < 9) {
                          if(this.data.gameProvider.supported_target_type === 2) {
                            if(promotionSupportedTargetType.target_type_id === 5 || promotionSupportedTargetType.target_type_id === 6) {
                              target_type_id = 1;
                              select = 1;
                            }
                            else if(promotionSupportedTargetType.target_type_id === 7 || promotionSupportedTargetType.target_type_id === 8) {
                              target_type_id = 3;
                              select = 1;
                            }
                          }
                          else if(this.data.gameProvider.supported_target_type === 3) {
                            if(promotionSupportedTargetType.target_type_id === 5 || promotionSupportedTargetType.target_type_id === 7) {
                              target_type_id = 2;
                              select = 2;
                            }
                            else if(promotionSupportedTargetType.target_type_id === 6 || promotionSupportedTargetType.target_type_id === 8) {
                              target_type_id = 4;
                              select = 2;
                            }
                          }
                          this.promoCode_targetTypeForm.addControl(`${row.id}`, new FormControl({
                            value: select,
                            disabled: true
                          }));
  
                          row.target_type_id = target_type_id;
                        }
                        else{
                          this.promoCode_targetTypeForm.addControl(`${row.id}`, new FormControl({
                            value: promotionSupportedTargetType.select,
                            disabled: true
                          }));
  
                          row.target_type_id = promotionSupportedTargetType.target_type_id;
                        }
                      }
                      row.isSelectable = isSelectable;
                    });
                    this.shouldCheck();
                    this.loadingBar.complete();
                    this.cdr.detectChanges();
                    if (res.length >= this.pageSize) {
                      return;
                    }
                    this.promoCode_loadmore = false;
                  })
                );
              }
              else{
                const parameters = this.rebates_params ? `page=${this.rebates_page}&perPage=${this.pageSize}&${this.rebates_params}` : `page=${this.rebates_page}&perPage=${this.pageSize}`;
                return this.rebateEntityService.getWithQuery(`?${parameters}`).pipe(
                  tap(res => {
                    this.rebates_loading = false;
                    this.rebates_clearBtnLoading = false;
                    this.rebates_searchBtnLoading = false;
                    this.FilterLoading = false;
                    this.rebates_tableLoading = false;
                    this.rebates_isFirstTimeLoad = false;
                    this.rebates = this.deepClone([...this.rebates, ...res]);
                    // Loop through the rebates array
                    this.rebates.forEach(rebate => {
                        const uniqueMemberGroup = new Set<string>();
                        this.memberGroupDropDownList.forEach(item => {
                            rebate.member_groups.forEach(r => {
                                if(r.member_group_id && +r.member_group_id === +item.id) {
                                    uniqueMemberGroup.add(item.name);
                                }
                            });
                        });
                        const uniqueCurrencyIds = new Set<number>();
                        this.currencyDropdownList.forEach(item => {
                            rebate.member_groups.forEach(r => {
                            if (r.settings_currency_id && +r.settings_currency_id === +item.id) {
                                uniqueCurrencyIds.add(+r.settings_currency_id);
                            }
                            });
                        });
        
                        const uniqueCategoryIds = new Set<number>();
                        this.categoryDropdownList.forEach(item => {
                            rebate.member_groups.forEach(r => {
                            if (r.category_id && +r.category_id === +item.id) {
                                uniqueCategoryIds.add(+r.category_id);
                            }
                            });
                        });
                        
                        rebate.member_group_name = Array.from(uniqueMemberGroup).join(",");
                        rebate.currency_id = Array.from(uniqueCurrencyIds);
                        rebate.currency_names = rebate.currency_id.length > 0 ? Array.from(uniqueCurrencyIds).map(id => this.currencyIdToName[id]) : 'Not available';
                        rebate.category_id = Array.from(uniqueCategoryIds);
                        rebate.category_names = rebate.category_id.length > 0 ? Array.from(uniqueCategoryIds).map(id => this.categoryIdToName[id]) : 'Not available';
                    });
                    this.shouldCheck();
                    this.loadingBar.complete();
                    this.cdr.detectChanges();
                    if (res.length >= this.pageSize) {
                      return;
                    }
                    this.rebates_loadmore = false;
                  })
                );
              }
            }),
          ).subscribe();
        }
      }
    }

    async onClear() {
      let proceed_clear = await this.checkPendingChanges(this.selectedPromo);
      if(proceed_clear){
        this.FilterLoading = true;
        if(this.selectedPromo === 'promotions'){
          this.promoCode_tableLoading = true;
          this.promoCode_clearBtnLoading = true;
          this.promoCode_searchBtnLoading = true;
          this.PromoformInit();
        }
        else{
          this.rebates_tableLoading = true;
          this.rebates_clearBtnLoading = true;
          this.rebates_searchBtnLoading = true;
          this.RebateformInit();
        }
        this.setCategoryDropdown(true).then(() => {
          this.setCurrencyDropdown(true).then(() => {
            this.onSubmit(this.selectedPromo, false, true);
          })
        })
      }
    }
    /* End of search and clear filter option functions */

    /* Save changes functions */
    async onSave(isSaveAll: boolean, promotype?: string) {
      this.dialogRef.disableClose = true;
      if(isSaveAll){
        this.savePromoChanges(true).then(() => {
          this.saveRebatesChanges(true).then(() => {
            Swal.fire({
              text: this.translateService.instant('Updated Successfully'),
              icon: 'info',
            }).then((result) => {
              if (result) {
                this.dialogRef.disableClose = false;
                this.onCloseDialog();
              }
            });
          })
        })
      }
      else{
        if(this.selectedPromo === 'rebates' && promotype === 'promotions' || this.selectedPromo === 'promotions' && promotype === 'rebates') {
          this.onSelectPromotion(promotype);
        }
        if(this.selectedPromo === 'promotions'){
          this.savePromoChanges(false);
        }
        else{
          this.saveRebatesChanges(false);
        }
      }
    }

    private fetchPromotionDetails(promotionId: number): Observable<any> {
      return this.promotionCodeEntityService.getByKey(promotionId).pipe(
        tap((res) => {
          this.promotionDetails = this.deepClone(res);
        })
      );
    }

    private fetchRebateDetails(rebateId: number): Observable<any> {
      return this.rebateEntityService.getByKey(rebateId).pipe(
        map((res) => {
          let blacklistSubCategory;
          const blacklistSubCategories = res.blacklist_sub_categories || [];
          blacklistSubCategory = blacklistSubCategories.find(x => x['game_provider_code'] === this.data.gameProvider.code);
          let data: {[k: number]: any[]} = {};
          if (blacklistSubCategory) {
            data = {
              [blacklistSubCategory.game_provider_code]: blacklistSubCategory['sub_category_name']
            };
          }
          return {
            ...res,
            blacklist_sub_categories: data
          };
        })
      );
    }

    private savePromoChanges(isSaveAll: boolean): Promise<void> {
      return new Promise((resolve, reject) => {
        if(this.promoCode_changes.length <= 0){
          resolve();
          return;
        }
        this.checkPendingTargetTypeSelection().then(hasPending => {
          if (hasPending) {
            Swal.fire({
              text: this.translateService.instant('Please ensure all checked rows already have selected target type.'),
              icon: 'warning',
            });
            this.dialogRef.disableClose = false;
            reject();
            return;
          }
          else{
            this.buttonLoading = true;
            this.promoCode_buttonLoading = true;
            // To set "Save" button to disable (To prevent call API in multiple times when double click)
            this.promoCode_form.setErrors({ 'invalid': true });
    
            const requests = this.promoCode_changes.map(change => {
              return this.fetchPromotionDetails(change.promotion_id).pipe(
                map(res => {
                  const targetType = change.target_type ? change.target_type: res.target[0].type;
    
                  return {
                    promotion_id: change.promotion_id,
                    target_type: targetType,
                    target_multiplier: res.target[0].multiplier,
                    category_codes: this.data.gameProvider.categories.map(category => category.code).join(','),
                    promotion_categories: change.promotion_categories,
                    action: change.action,
                    game_provider_id: this.data.gameProvider.id
                  };
                })
              );
            });
    
            forkJoin(requests).subscribe(
              results => {
                this.promo_update_subscription = this.promotionCodeDataService.updatePromotionByGameProvider(results).pipe(
                tap((res: any) => {
                  this.promoCode_messages$.next([...res.message]);
                  this.buttonLoading = false;
                  this.promoCode_buttonLoading = false;
                  this.promoCode_isUpdated = true;
                  this.promoCode_changes = [];
                  if(!isSaveAll){
                    Swal.fire({
                      text: this.translateService.instant('Updated Successfully'),
                      icon: 'info',
                    }).then((result) => {
                      if (result && this.rebates_buttonLoading === false) {
                        this.FilterLoading = true;
                        this.promoCode_tableLoading = true;
                        this.promoCode_clearBtnLoading = true;
                        this.promoCode_searchBtnLoading = true;
                        this.promoCode_isFirstTimeLoad = true;
                        this.dialogRef.disableClose = false;
                        this.PromoformInit();
                        this.getExistedPromotion();
                        this.onSelectPromotion('promotions');
                      }
                      resolve();
                    });
                  }
                  else{
                    resolve();
                  }
                  // To enable "Save" button after get response
                  this.promoCode_form.setErrors(null);
                }),
                catchError((error) => {
                  this.buttonLoading = false;
                  this.promoCode_buttonLoading = false;
                  this.promoCode_form.setErrors(null);
                  reject(error);
                  throw error;
                })
                ).subscribe();
              },
              error => {
                console.error('Error fetching promotion details:', error);
                reject(error);
              },
            );
          }
        }).catch(error => {
          console.error("Error checking target type selections:", error);
          reject(error);
        });
      });
    }

    private saveRebatesChanges(isSaveAll: boolean): Promise<void> {
      return new Promise((resolve, reject) => {
        if(this.rebates_changes.length <= 0){
          resolve();
          return;
        }
        this.buttonLoading = true;
        this.rebates_buttonLoading = true;
        // To set "Save" button to disable (To prevent call API in multiple times when double click)
        this.rebates_form.setErrors({ 'invalid': true });

        const requests = this.rebates_changes.map(change => {
          return this.fetchRebateDetails(change.rebate_setting_id).pipe(
            map(res => ({
              member_group_id: change.member_group_id,
              currency_id: change.currency_id,
              game_provider_id: this.data.gameProvider.id,
              category_id: change.category_id,
              rebate_setting_id: change.rebate_setting_id,
              status: change.status,
              action: change.action,
              blacklist_sub_categories: res.blacklist_sub_categories
            }))
          );
        });

        forkJoin(requests).subscribe(
          results => {
            this.rebate_update_subscription = this.rebateDataService.updateRebatesByGameProvider(results).pipe(
            tap((res: any) => {
              this.rebates_messages$.next([...res.message]);
              this.buttonLoading = false;
              this.rebates_buttonLoading = false;
              this.rebates_isUpdated = true;
              this.rebates_changes = [];
              if(!isSaveAll){
                Swal.fire({
                  text: this.translateService.instant('Updated Successfully'),
                  icon: 'info',
                }).then((result) => {
                  if (result && this.promoCode_buttonLoading === false) {
                    this.FilterLoading = true;
                    this.rebates_tableLoading = true;
                    this.rebates_clearBtnLoading = true;
                    this.rebates_searchBtnLoading = true;
                    this.rebates_isFirstTimeLoad = true;
                    this.dialogRef.disableClose = false;
                    this.RebateformInit();
                    this.getExistedPromotion();
                    this.onSelectPromotion('rebates');
                  }
                  resolve();
                });
              }
              else{
                resolve();
              }
              // To enable "Save" button after get response
              this.rebates_form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.rebates_buttonLoading = false;
              this.rebates_form.setErrors(null);
              reject(error);
              throw error;
            })
            ).subscribe();
          },
          error => {
            console.error('Error fetching rebates details:', error);
            reject(error);
          },
        );
      });
    }
    /* End of save changes functions */

    /*****************************************************************/
    /*                   End of Main Flow Functions                  */
    /*****************************************************************/

    /*****************************************************************/
    /*                         Side Functions                        */
    /*****************************************************************/

    /* Sidebar related functions */
    onSelectPromotion(promotype: any) {
      this.selectedPromo = promotype;
      let shouldProceed = false;
      if(promotype === 'promotions' && this.promoCode_isFirstTimeLoad){
        this.TableLoading = true;
        shouldProceed = true;
      }
      else if(promotype === 'rebates' && this.rebates_isFirstTimeLoad){
        this.TableLoading = true;
        shouldProceed = true;
      }

      /* Enable these when require to clear 'name' field when user switching tab from promotion codes / rebate setting */
      // if(this.promoCode_form.value.name){
      //   if(typeof this.promoCode_form.value.name !== 'object'){
      //     this.promoCode_form.patchValue({
      //       name: null
      //     })
      //   }
      // }
      // if(this.rebates_form.value.name){
      //   if(typeof this.rebates_form.value.name !== 'object'){
      //     this.rebates_form.patchValue({
      //       name: null
      //     })
      //   }
      // }

      if(shouldProceed){
        this.setCurrencyDropdown().then(() => {
          this.setCategoryDropdown().then(() => {
              this.onSubmit(promotype, false);
          })
        })
      }
      else{
        return;
      }
    }

    isSelectedPromo(promo_type: string): boolean {
        return this.selectedPromo === promo_type;
    }
    /* End of sidebar related functions */

    /* Filter form related functions */
    searchPromotions = (text$: Observable<string>): Observable<Promotion[]> =>
      text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(term => term.trim() === '' ? of([]) :  this.promotionCodeEntityService.getWithQuery(`?code=${term}&name=${term}&typeahead_search=${1}`)),
        catchError(() => of([]))
    );

    searchRebates = (text$: Observable<string>): Observable<Rebate[]> =>
      text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(term => term.trim() === '' ? of([]) :  this.rebateDataService.getRebatesName(term)),
        catchError(() => of([]))
    );
    
    formatPromotion(promotion: any): string {
      if (typeof promotion === 'string') {
        // Return the input string if it's an incomplete character
        return promotion;
      } else if (typeof promotion === 'object' && promotion !== null) {
        // Return the formatted string if it's an object
        return `${promotion.code} - ${promotion.name}`;
      } else {
        // Return an empty string or handle other types if needed
        return '';
      }
    }

    formatRebates(rebate: any): string {
      if (typeof rebate === 'string') {
        // Return the input string if it's an incomplete character
        return rebate;
      } else if (typeof rebate === 'object' && rebate !== null) {
        // Return the formatted string if it's an object
        return `${rebate.name}`;
      } else {
        // Return an empty string or handle other types if needed
        return '';
      }
    }

    // Ensure the form control is set with the selected object
    onSelectItem(selectedItem) {
      this.promoCode_form.get('name').setValue(selectedItem);
    }

    private filterFormFields(formData: any) {
      const fields = {};
      Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
      return fields;
    }

    private processForm(formValue, selectedCurrencies, selectedCategories) {
      if(selectedCurrencies.length <= 0){
        formValue.currency_id = this.currencyDropdownList.map(item => item.id);
      }
      else{
        formValue.currency_id = selectedCurrencies.map(item => item.id);
      }
      if(selectedCategories.length <= 0){
        formValue.category_id = this.categoryDropdownList.map(item => item.id);
      }
      else{
        formValue.category_id = selectedCategories.map(item => item.id);
      }
      if (formValue.name) {
        if (typeof formValue.name === 'object') {
          formValue.code = formValue.name.code ? formValue.name.code : null;
          formValue.name = formValue.name.name ? formValue.name.name : null;
        } else {
          formValue.code = formValue.name;
          formValue.name = formValue.name;
          formValue.typeahead_search = 1;
        }
      }
      return formValue;
    }

    onChangeCurrency(selected) {
      if(this.selectedPromo === 'promotions'){
        this.promoCode_currencySelectedItems = selected;
      }
      else{
        this.rebates_currencySelectedItems = selected;
      }
    }

    onChangeCategories(selected) {
      if(this.selectedPromo === 'promotions'){
        this.promoCode_categorySelectedItems = selected;
      }
      else{
        this.rebates_categorySelectedItems = selected;
      }
    }

    onHideFilter() {
      // Always set the FilterHide value to opposite value
      if(this.selectedPromo === 'promotions'){
        this.promoCode_FilterHide = !this.promoCode_FilterHide;
      }
      else{
        this.rebates_FilterHide = !this.rebates_FilterHide;
      }
      this.adjustTableHeight();
    }
    /* End of filter form related functions */

    /* Table related functions */
    onScroll(event: Event): void {
      const target = event.target as HTMLElement;
      const scrollHeight = target.scrollHeight;
      const scrollTop = target.scrollTop;
      const clientHeight = target.clientHeight;
      const scrollBottom = (scrollTop + clientHeight) * 1.1 >= scrollHeight;
      if(this.selectedPromo === 'promotions'){
        if (this.promoCode_loadmore && !this.promoCode_loading && !this.promoCode_tableLoading && scrollBottom) {
          this.onSubmit(this.selectedPromo, true);
        }
      }
      else{
        if (this.rebates_loadmore && !this.rebates_loading && !this.rebates_tableLoading && scrollBottom) {
          this.onSubmit(this.selectedPromo, true);
        }
      }
    }

    onsortColumn(column: string) {
      if (this.selectedPromo === 'promotions') {
          this.sortPromotions(column);
      } else {
          this.sortRebates(column);
      }
    }

    private sortPromotions(column: string) {
      if (this.promoCode_sortedColumn === column) {
          this.promoCode_sortDirection = this.promoCode_sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
          this.promoCode_sortedColumn = column;
          this.promoCode_sortDirection = 'asc';
      }
  
      this.promotion.sort((a, b) => this.compare(a[column], b[column], this.promoCode_sortDirection));
    }
  
    private sortRebates(column: string) {
        if (this.rebates_sortedColumn === column) {
            this.rebates_sortDirection = this.rebates_sortDirection === 'asc' ? 'desc' : 'asc';
        } else {
            this.rebates_sortedColumn = column;
            this.rebates_sortDirection = 'asc';
        }
    
        this.rebates.sort((a, b) => this.compare(a[column], b[column], this.rebates_sortDirection));
    }
  
    private compare(valueA: any, valueB: any, direction: 'asc' | 'desc') {
        if (valueA < valueB) {
            return direction === 'asc' ? -1 : 1;
        } else if (valueA > valueB) {
            return direction === 'asc' ? 1 : -1;
        } else {
            return 0;
        }
    }

    getSortClass(column: string): string {
      if(this.selectedPromo === 'promotions'){
        if (this.promoCode_sortedColumn === column) {
          return this.promoCode_sortDirection === 'asc' ? 'sort-asc' : 'sort-desc';
        }
      }
      else{
        if (this.rebates_sortedColumn === column) {
          return this.rebates_sortDirection === 'asc' ? 'sort-asc' : 'sort-desc';
        }
      }
      return '';
    }
    /* End of table related functions */

    /* Other support functions */
    async onCloseDialog() {
      if (this.promoCode_changes.length !== 0 || this.rebates_changes.length !== 0) {
        const resp = await Swal.fire({
          title: 'You have unsaved changes',
          text: 'Are you sure you want to close without saving the changes?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#dd3333',
          denyButtonColor: '#0abb87',
          confirmButtonText: 'Close Without Saving',
          cancelButtonText: 'Back',
          denyButtonText: 'Save All',
          reverseButtons: true,
        });
  
        if (resp.isDenied) {
          await this.onSave(true);
        }
        else if (resp.isConfirmed) {
          this.dialogRef.close(true);
        }
        else if (resp.isDismissed) {
          // do nothing
        }
        else {
          // do nothing
        }
      } else {
        this.dialogRef.close(true);
      }
    }

    private deepClone = (obj: any): any => {
      if (obj === null || typeof obj !== 'object') {
        return obj;
      }
    
      if (Array.isArray(obj)) {
        return obj.map(this.deepClone);
      }
    
      const clonedObj = { ...obj };
      for (const key in clonedObj) {
        if (Object.prototype.hasOwnProperty.call(clonedObj, key)) {
          clonedObj[key] = this.deepClone(clonedObj[key]);
        }
      }
    
      return clonedObj;
    };

    private async checkPendingChanges(promotype: string): Promise<boolean> {
      if((promotype === 'promotions' && this.promoCode_changes.length !== 0) || (promotype == 'rebates' && this.rebates_changes.length !== 0)){
        const resp = await Swal.fire({
          title: 'You have unsaved changes',
          text: 'Proceed searching or clearing the filter option will dismiss the changes, do you want to save it right now?',
          icon: 'warning',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#dd3333',
          denyButtonColor: '#0abb87',
          confirmButtonText: 'Continue Without Saving',
          cancelButtonText: 'Back',
          denyButtonText: 'Save',
          reverseButtons: true,
        });
  
        if (resp.isDenied) {
          if(promotype === 'promotions') {
            this.promoCode_searchBtnLoading = true;
            this.promoCode_clearBtnLoading = true;
            this.promoCode_tableLoading = true;
            await this.savePromoChanges(true);
            await Swal.fire({
              text: this.translateService.instant('Updated Successfully'),
              icon: 'info',
            });
            this.getExistedPromotion();
          }
          else{
            this.rebates_searchBtnLoading = true;
            this.rebates_clearBtnLoading = true;
            this.rebates_tableLoading = true;
            await this.saveRebatesChanges(true);
            await Swal.fire({
              text: this.translateService.instant('Updated Successfully'),
              icon: 'info',
            });
            this.getExistedPromotion();
          }
          return true;
        }
        else if (resp.isConfirmed) {
          if(promotype === 'promotions') {
            this.promoCode_changes = [];
            this.promoCode_targetType_changes = [];
          }
          else{
            this.rebates_changes = [];
          }
          return true;
        }
        else if (resp.isDismissed) {
          return false;
        }
      }
      else{
        return true;
      }
    }

    private isSelectableTargetType(result: string, promotion_id?: number): boolean {
      if(!result){
          return true;
      }
      if(promotion_id){
        if(this.existedPromotion.some(item => item.promotion_id === promotion_id)){
          return result.includes('Turnover') && result.includes('Winloss');
        }
        else{
          return false;
        }
      }
      return result.includes('Turnover') && result.includes('Winloss');
    }
    
    private getSupportedTargetType(result: string): {select: number, target_type_id: number} | null {
      const resultMapping = [
        { keywords: ['Turnover incl. Transfer Amount'], select: 1, target_type_id: 1 },
        { keywords: ['Winloss incl. Transfer Amount'], select: 2, target_type_id: 2 },
        { keywords: ['Turnover excl. Transfer Amount'], select: 1, target_type_id: 3 },
        { keywords: ['Winloss excl. Transfer Amount'], select: 2, target_type_id: 4 },
        { keywords: ['Turnover incl. Transfer Amount', 'Winloss incl. Transfer Amount'], select: null, target_type_id: 5 },
        { keywords: ['Turnover incl. Transfer Amount', 'Winloss excl. Transfer Amount'], select: null, target_type_id: 6 },
        { keywords: ['Turnover excl. Transfer Amount', 'Winloss incl. Transfer Amount'], select: null, target_type_id: 7 },
        { keywords: ['Turnover excl. Transfer Amount', 'Winloss excl. Transfer Amount'], select: null, target_type_id: 8 },
      ];

      const normalizedResult = result.split(',').map(item => item.trim()).sort().join(', ');

      for (const mapping of resultMapping) {
        const normalizedKeywords = mapping.keywords.slice().sort().join(', ');
        if (normalizedKeywords === normalizedResult) {
          return { select: mapping.select, target_type_id: mapping.target_type_id };
        }
      }
      return null;
    }

    private checkPendingTargetTypeSelection(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        try {
          const formControls = this.promoCode_targetTypeForm.controls;
          for (const key in formControls) {
            if (formControls.hasOwnProperty(key)) {
              const control = formControls[key];
              // Check if the row is not disabled (checked) and if the control value is null
              if (!control.disabled && control.value === null) {
                resolve(true); // There is a pending selection in a checked row
                return;
              }
            }
          }
          resolve(false); // All selections are made in checked rows
        } catch (error) {
          reject(error); // In case of any error
        }
      });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
      this.adjustTableHeight();
    }

    private adjustTableHeight(): void {
      const filterElement = document.querySelector('.filter-section') as HTMLElement;
      const filterHeight = filterElement ? filterElement.clientHeight : 0;
      const modalElement = document.querySelector('.modal-content') as HTMLElement;
      const modalHeight = modalElement ? modalElement.clientHeight : 0;
  
      this.tableMaxHeight = modalHeight - filterHeight - 20; // 20 for padding/margin, can adjust
    }


    /* End of other support functions */

    /*****************************************************************/
    /*                     End of Side Functions                     */
    /*****************************************************************/
}