<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="col-12 table-responsive form-group pr-0 pl-0">
        <section class="col-12 heading">
          <div class="col-9">
            <label>Name</label>
          </div>
          <div class="col-3 text-center" >
            <label>Option</label>
          </div>
        </section>
        <ng-container *ngFor="let section of data; let i = index">
          <section class="parent">
            <div class="col-9">
              <input type="checkbox" [checked]="getSectionCheckedStatus(section)" (click)="onSectionValueChange(section, $event)">
              <label>{{ section.name }}</label>
            </div>
            <div class="col-3 text-center">
              <input type="checkbox" [checked]="getSectionCheckedStatus(section)" (click)="onSectionValueChange(section, $event)">
            </div>
          </section>

          <ng-container *ngIf="section.option">
            <section class="child" *ngFor="let options of section.option | keyvalue; let k = index">

              <div class="col-9">
                <input type="checkbox" [checked]="getCheckedStatus(section, options)" (click)="onValueChange(section, options, option, $event )">
                <label>{{ options.key }}</label>
              </div>
              <div class="col-3 text-center">
                <input type="checkbox" [checked]="getCheckedStatus(section, options)" (click)="onValueChange(section, options, option, $event )">
              </div>

                <section *ngFor="let option of options.value | keyvalue">
                  <div class="col-9">
                    <input class="sub-option" type="checkbox" [checked]="option.value" (click)="onValueChange(section, options, option, $event )">
                    <label>{{ option.key }}</label>
                  </div>
                  <div class="col-3 text-center">
                    <input type="checkbox" [checked]="option.value" (click)="onValueChange(section, options, option, $event )">
                  </div>
                </section>
            </section>
          </ng-container>
        </ng-container>
      </div>
      <div>
        <kt-submit-button class="btn-submit" [buttonLoading]="buttonLoading" (confirmed)="onSubmit()"></kt-submit-button>
      </div>
    </div>
  </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog()"></kt-swal-alert>