
<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div  class="kt-portlet kt-portlet--mobile">
      <div  class="kt-portlet__body">
        <div class="kt-form kt-form--label-right">
            <div class="row align-items-center">
                <div class="col-xl-12 order-2 order-xl-1 pr-0">
                    <form class="row align-items-center" [formGroup]="form">
                        <div class="col-12 row mb-2 pr-0">
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Username:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                                <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                            </div>
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Agent:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                              <kt-member-dropdown class="dropdown-maxheight" [form]="form"
                                  [isAgent]="true" [dropdownSettings]='dropdownSettings' [formName]="'agent_username'"
                                  [selectionAttributes]="'username'"></kt-member-dropdown>
                            </div>
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Currency:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                                <select class="form-control" formControlName="currency_id">
                                    <option [value]="null" [disabled]="true">Please Select</option>
                                    <option value="all">All</option>
                                    <ng-container *ngFor="let value of dropdown.currencies">
                                        <option [value]="value.id">{{ value.name }}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 row mb-2 pr-0">
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Deposit Date/Time:</label>
                            </div>
                            <div class="col-md-5 kt-form__control">
                                <div class="input-group date">
                                <input type="text" class="form-control" placeholder="Search"
                                    (change)="onDateRange($event, 'deposit')" formControlName="defaultDateDeposit"
                                    [timePicker]="true" [timePickerSeconds]="true"
                                    [alwaysShowCalendars]="true" [ranges]="ranges"
                                    [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                    [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                ngxDaterangepickerMd/>
                                <span class="input-group-append">
                                    <span class="input-group-text pointer-calendar" (click)="onClearDate('deposit')">X</span>
                                </span>
                                </div>
                            </div>
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Registration Date/Time:</label>
                            </div>
                            <div class="col-md-5 kt-form__control">
                                <div class="input-group date">
                                <input type="text" class="form-control" placeholder="Search"
                                    (change)="onDateRange($event, 'registration')" formControlName="defaultDateRegistration"
                                    [timePicker]="true" [timePickerSeconds]="true"
                                    [alwaysShowCalendars]="true" [ranges]="ranges"
                                    [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                    [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()"
                                ngxDaterangepickerMd/>
                                <span class="input-group-append">
                                    <span class="input-group-text pointer-calendar" (click)="onClearDate('registration')">X</span>
                                </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 row mb-2 pr-0">
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>{{ 'Timezone' }}:</label>
                            </div>
                            <div class="col-md-5 kt-form__control">
                                <kt-dropdown-wo-lazyload
                                    #timezone
                                    (click)="onChangeTimezone(timezone.selectedItems)"
                                    [form] = 'form'
                                    [dropdownList] = 'dropdown.timezones'
                                    [dropdownSettings] = 'timezoneDropdownSettings'
                                    [formName] = "'timezone'"
                                    [selectionAttributes] = "'timezone'"
                                    [selectedItems] = 'timezoneSelectedItems'>
                                </kt-dropdown-wo-lazyload>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="kt-section col-12 row mb-3 mt-2 d-flex justify-content-between">
            <div class="dropdown dropdown-inline">
                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
            </div>
            <button *ngIf="canExportDailyNewDepositReport" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="button_loading">
                <i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export
            </button>
        </div>

        <div  class="kt-section">
            <div  class="kt-section__content">
                <div  class="table-responsive">
                    <table  class="table table-bordered table-hover table-striped table-sortable">
                        <thead>
                            <tr>
                                <th class="date-column sort-enabled" (click)="onSortColumn('deposit_at')" [ngClass]="sortingConfig.deposit_at" [class.sort-selected]="sortingSelection.sort_by === 'deposit_at'">Date</th>
                                <th class="text-center sort-enabled" (click)="onSortColumn('currency_code')" [ngClass]="sortingConfig.currency_code" [class.sort-selected]="sortingSelection.sort_by === 'currency_code'">Currency</th>
                                <th class="text-center sort-enabled" (click)="onSortColumn('agent_username')" [ngClass]="sortingConfig.agent_username" [class.sort-selected]="sortingSelection.sort_by === 'agent_username'">Agent</th>
                                <th class="text-center sort-enabled" (click)="onSortColumn('amount')" [ngClass]="sortingConfig.amount" [class.sort-selected]="sortingSelection.sort_by === 'amount'">Amount</th>
                                <th class="date-column sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                                <th class="date-column sort-enabled" (click)="onSortColumn('member_name')" [ngClass]="sortingConfig.member_name" [class.sort-selected]="sortingSelection.sort_by === 'member_name'">Member Name</th>
                                <th class="text-center sort-enabled" (click)="onSortColumn('registration_site')" [ngClass]="sortingConfig.registration_site" [class.sort-selected]="sortingSelection.sort_by === 'registration_site'">Registration Info</th>
                                <th class="date-column sort-enabled" (click)="onSortColumn('register_at')" [ngClass]="sortingConfig.register_at" [class.sort-selected]="sortingSelection.sort_by === 'register_at'">Register At</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="(dailyNewDepositReport$ | async) as rows">
                            <tr *ngFor="let row of rows">
                                <td>{{ timezoneDateWithTimezone(row.deposit_at, form.value.timezone, 'YYYY-MM-DD HH:mm') }}</td>
                                <td class="text-center">{{ row.currency_code }}</td>
                                <td>{{ row.agent_username }}</td>
                                <td class="text-right">{{ row.amount | number : '1.2-2' }}</td>
                                <td><a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog(row.id)">{{ row.username }}</a></td>
                                <td>{{ row.member_name }}</td>
                                <td class="registration-info-column">{{ row.registration_site }}</td>
                                <td>{{ timezoneDateWithTimezone(row.register_at, form.value.timezone, 'YYYY-MM-DD HH:mm') }}</td>
                            </tr>
                        </tbody>
                        <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                      </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                          [pageSize]="pageSize"
                          [(page)]="page"
                          [maxSize]="maxSize"
                          [directionLinks]="true"
                          [boundaryLinks]="true"
                          [rotate]="true"
                          [collectionSize]="pagination.total"
                          (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                              Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
              </div>
          </div>
      </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
