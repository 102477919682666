import { AffiliateAdjustmentDataService } from './../services/affiliate-adjustment-data.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'kt-affiliate-adjustments-edit',
  templateUrl: './affiliate-adjustments-edit.component.html',
  styleUrls: ['./affiliate-adjustments-edit.component.scss']
})
export class AffiliateAdjustmentDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    wallet_type: ['Main Wallet', 'Deposit Wallet'],
    affiliates: []
  };
  buttonLoading = false;
  messages$ = this.affiliateAdjustmentDataService.messages$;
  refreshStatus: boolean;
  selectedAffiliate: any;
  walletBalance: number;
  selectedWallet: number;
  dropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'username',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false
  };
  private subscription = new Subscription();
  constructor(
    public dialogRef: MatDialogRef<AffiliateAdjustmentDialogComponent>,
    private affiliateAdjustmentDataService: AffiliateAdjustmentDataService
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSelectAffiliate(value: any){
    this.selectedAffiliate =  value[0];
    if (this.selectedWallet >= 0){
      if (this.selectedWallet === 0){
        this.walletBalance = this.selectedAffiliate.main_wallet;
      }else{
        this.walletBalance = this.selectedAffiliate.deposit_wallet;
      }
    }
  }

  onSelectWallet(event: Event){
    this.selectedWallet = +(event.target as HTMLInputElement).value;
    if (this.selectedAffiliate) {
      if (+(event.target as HTMLInputElement).value === 0){
        this.walletBalance = this.selectedAffiliate.main_wallet;
      }else{
        this.walletBalance = this.selectedAffiliate.deposit_wallet;
      }
    }
  }

  onSave() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });

    const data = {
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    this.subscription = forkJoin([
      this.affiliateAdjustmentDataService.create(data).pipe(
        tap(() => {
          this.buttonLoading = false;
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ),
      this.affiliateAdjustmentDataService.messages$
    ]).subscribe();
    this.refreshStatus = true;
  }

  private formInit() {
    this.form = new FormGroup({
      affiliate_id: new FormControl(null, Validators.required),
      type_id: new FormControl(null, Validators.required),
      amount: new FormControl(null, Validators.required),
      remarks: new FormControl(null)
    });
  }

}
