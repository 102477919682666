import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ReferralCommissionHistory } from '@core/models/referral-commission-history.model';
import { Pagination } from '@core/models/pagination.model';

@Injectable()

export class ReferralCommissionHistoryDataService {

  constructor(private http: HttpClient) { }
  pagination: Pagination;

  getWithQuery(params?: string): Observable<ReferralCommissionHistory[]> {
    return this.http.get<ApiResponse>(`/referralcommission/batches${params}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }
  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}