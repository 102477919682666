<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Affiliate List</h5>
                <span class="modal-x-button" (click)="onCloseDialog($event, false)">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="col-12 kt-form kt-form--label-right" *ngIf="pagination !== undefined">
                    <div class="row align-items-center">
                        <div class="col-xl-12 order-2 order-xl-1">
                            <form class="align-items-center" [formGroup]="searchForm">
                                <div class="row mb-3">
                                    <div class="col-md-2 kt-form__label col-form-label pl-0">
                                        <label>Affiliate Group:</label>
                                    </div>
                                    <div class="col-md-3 kt-form__control pr-0">
                                        <kt-dropdown-wo-lazyload
                                            [dropdownList] = "affGroupDropdownListAll"
                                            [dropdownSettings] = "affGroupDropdownSettings"
                                            [form] = "searchForm"
                                            [selectionAttributes] = "'id'"
                                            [formName]="'affiliate_group_id'"
                                            [selectedItems]="affGroupSearchSelectedItems"
                                            (selectedItemsChanged)="affGroupSearchSelectedItems = $event">
                                        </kt-dropdown-wo-lazyload>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!--Action Button-->
                <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                    <div class="dropdown dropdown-inline" *ngIf="pagination !== undefined">
                        <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="getAffGroupVisibility()"></kt-search-button>
                        <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                    </div>
                    <div [ngClass]="pagination !== undefined ? '' : 'mr-0 ml-auto'" class="dropdown dropdown-inline">
                        <ng-container>
                            <button class="btn btn-brand btn-icon-sm" (click)="onAffGroupAddRow(true)" [disabled]="buttonLoading || loading || isOnAddClicked || validationAffGroupList()"><i class="fas fa-plus"></i>Add</button>
                        </ng-container>
                    </div>
                </div>
                <div class="kt-section col-12 mb-0">
                    <div class="kt-section__content">
                        <div class="table-responsive">
                            <form [formGroup]="affGroupVisibilityArrayForm">
                                <table class="table table-bordered table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th class="text-center" style="width: 33%">Affliate Group</th>
                                            <th class="text-center" colspan="2">Visibility</th>
                                            <th class="text-center" style="width: 10%">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody class="promotionContainer">
                                        <ng-container formArrayName="affGroupVisibility">
                                            <ng-container *ngIf="!loading && affGroupVisibilityArr.controls.length > 0">
                                                <ng-container *ngFor="let row of affGroupVisibilityArr.controls; index as i" [formGroupName]="i"> 
                                                    <tr [ngClass]="row.enabled ? 'edit-active-row': ''">
                                                        <td style="width: 33%">
                                                            <div style="width: 31.3%" class="p-0 position-absolute" [ngClass]="{'div-disabled': row.disabled }">
                                                                <kt-dropdown-wo-lazyload
                                                                    [form]='affGroupVisibilityArr.controls[i]'
                                                                    [dropdownList]='filteredAffGroupDropdownList[i]'
                                                                    [dropdownSettings]='affGroupDropdownSettings'
                                                                    [formName]="'affiliate_group_id'"
                                                                    [selectionAttributes]="'id'"
                                                                    [selectedItems]="affGroupSelectedItems[i]"
                                                                    (selectedItemsChanged)="onSelectedAffGroupListItems($event, i)">
                                                                </kt-dropdown-wo-lazyload>
                                                            </div>
                                                        </td>
                                                        <td class="border-top-0 border-right-0 border-left-0 pr-0" style="width: 14%">
                                                            <select class="form-control" formControlName="visibility" (change)="onChangeAffGroupVisbility($event, i)" [ngClass]="row.controls.affiliate_group_id.value == null ? 'disabled-select' : ''">
                                                                <option value="null" disabled>Please Select</option>
                                                                <option value="1">All</option>
                                                                <option value="2">Include Selected</option>
                                                                <option value="3">Exclude Selected</option>
                                                            </select>
                                                        </td>
                                                        <td class="border-top-0 border-right-0 border-left-0">
                                                            <ng-container *ngIf="row.controls.visibility.value > 1">
                                                                <div class="pr-0" [ngClass]="{'is-invalid': checkValidationAffiliatePromotionList(i) && row.controls.visibility.value != null }">
                                                                    <div style="width: 41.3%" class="p-0 position-absolute affiliate-dropdown" [ngClass]="{'div-disabled': row.disabled || row.controls.visibility.value == null }">
                                                                        <kt-dropdown-wo-lazyload
                                                                            [form]='affGroupVisibilityArr.controls[i]'
                                                                            [dropdownList]='affiliateListDropdownList[i]'
                                                                            [dropdownSettings]='affiliateDropdownListSettings'
                                                                            [formName]="'affiliate_id'"
                                                                            [selectionAttributes]="'id'"
                                                                            [selectedItems]="affiliateListSelectedItems[i]"
                                                                            (selectedItemsChanged)="onSelectedAffiliatePromotionListItems($event, i)">
                                                                        </kt-dropdown-wo-lazyload>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </td>
                                                        <td class="text-center" style="width: 10%">
                                                            <button *ngIf="row.value.mode === 'edit'" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="enableAffGroupEdit(i)" [disabled]="!row.disabled || validationAffGroupList()"><i class="fas fa-edit"></i></button>
                                                            <button matTooltip="Save" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onAffGroupSave(row.value, i)" [disabled]="row.disabled || (!row.disabled && validationAffGroupList(i))"><i class="fas fa-save"></i></button>
                                                            <button *ngIf="row.enabled" matTooltip="Dismiss" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onDismissAffGroupRow(row.value.mode, i)"><i class="fas fa-times"></i></button>
                                                            <button *ngIf="row.value.mode === 'edit' && row.disabled" matTooltip="Delete" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-disabled-opacity" (click)="onAffGroupDelete(row.value)" [disabled]="validationAffGroupList()"><i class="fas fa-trash-alt"></i></button>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </tbody>
                                    <tbody shared-table-handler [loading]="loading" [dataLength]="affGroupVisibility$.length || affGroupVisibilityArr.controls.length >= 1" class="text-gray-600 fw-bold"></tbody>
                                </table>
                            </form>
                        </div>
                        <div class="kt-pagination kt-pagination--brand mt-2 mb-2" *ngIf="pagination !== undefined">
                            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page, pageSize)">
                            </ngb-pagination>
                            <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                            </div>
                        </div>            
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog($event, false)"><i class="fas fa-ban"></i>Close</button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>