import { Component, OnInit, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MemberReportDetailsDialogComponent } from '../../../member-report/dialogs/member-report-details.component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MemberReport } from '@core/models/member-report-model';
import { MemberReportEntityService } from '../../../member-report/services/member-report-entity.service';
import * as moment from 'moment-timezone';
import { DropdownHttpService } from "@core/services/dropdown-http.service";
import { FormBuilder, FormGroup } from '@angular/forms';
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';
import { DateTime } from 'luxon';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'kt-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss']
})
export class ReportDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

  memberReport$: Observable<MemberReport[]>;
  gameProviders = this.dropdownHttpService.gameProviders;
  summary: any;
  username: any;
  datetime = {
    start: moment(this.data.formValue.start_date_time).tz(this.data.formValue.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
    end: moment(this.data.formValue.end_date_time).tz(this.data.formValue.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
  };
  unconvertedFrom = moment(this.data.formValue.start_date_time).tz(this.data.formValue.timezone, true).utc() // For display only
  unconvertedTo = moment(this.data.formValue.end_date_time).tz(this.data.formValue.timezone, true).utc() // For display only
  pageSize = 30;
  params = `start_date_time=${this.datetime.start}&end_date_time=${this.datetime.end}&paginate=1&perPage=${this.pageSize}&username=${this.data.memberReport.username}`

  loading = false;

  form = this.formBuilder.group(this.data.formValue);

  // permissions
  canViewMemberBetLogsReport: boolean;

  private subscriptions = new Subscription();

  lastBetLogSyncAt = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { memberReport: MemberReport, formValue: any, from: 'member-report' | 'all-member-report' },
    public dialogRef: MatDialogRef<ReportDetailsComponent>,
    private memberReportService: MemberReportEntityService,
    private loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private dropdownHttpService: DropdownHttpService,
    private formBuilder: FormBuilder,
    private timeZoneDate: TimezoneDatePipe,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.datetime.start = 
    this.datetime.end = 
    
    this.reload();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      if (this.data.from === 'all-member-report') {
        this.canViewMemberBetLogsReport = appPermissions.all_member_report_view_member_bet_logs_report;
      } else if (this.data.from === 'member-report') {
        this.canViewMemberBetLogsReport = appPermissions.view_member_bet_logs_report;
      }
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  sanitizeNumericField(previousValue: any) {
    const value = String(previousValue).replace(/[,]+/, '');
    return value;
  }

  private openDialogBy(componentRef: any, data?: { game_account: string, game_provider_id:number, game_provider_code: string, username: string, start_date_time: string, end_date_time: string, agent_username?: string, type: string, currency_code: string, timezone: string, game_type: number, member_account_id: number, from: string, }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '90vw',
      height: '90vh',
      data: {
        game_account: data.game_account,
        game_provider_id: data.game_provider_id,
        game_provider_code: data.game_provider_code,
        username: data.username,        
        member_account_id: data.member_account_id,
        start_date_time: data.start_date_time,
        end_date_time: data.end_date_time,
        agent_username: data.agent_username,
        type: data.type,
        currency_code: data.currency_code,
        timezone: data.timezone,
        game_type: data.game_type,
        from: data.from,
      }
    });
  }

  onOpenDialog(report: any) {
    this.gameProviders.subscribe((res) => {
      const data = {
        game_account: report.game_account,
        game_provider_id: (res.find((x) => { return x.code === report.game_provider_code })).id,
        game_provider_code: report.game_provider_code,
        username: report.username,
        member_account_id: report?.member_account_id,
        start_date_time: this.data.formValue.start_date_time,
        end_date_time: this.data.formValue.end_date_time,
        agent_username: report.agent,
        type: 'report-details',
        currency_code: report.currency_code,
        timezone: this.data.formValue.timezone,
        game_type: (res.find((x) => { return x.code === report.game_provider_code })).type,
        from: this.data.from,
      };
      if (report.game_provider_code !== '-' && report.game_provider_code !== null) {
        if (!this.canViewMemberBetLogsReport) {
          Swal.fire({
            icon: 'error',
            title: 'Permission Error',
            text: 'You do not have permission to view member bet logs report',
          });
          return;
        }

        this.openDialogBy(MemberReportDetailsDialogComponent, data);
      }
    });
  }

  onViewPageBy(params?: string) {
    params = this.data.memberReport.agent ? `${this.params}&agent_username=${this.data.memberReport.agent}` : this.params;
    this.loadingBar.start();
    this.loading = true;
    return this.memberReport$ = this.memberReportService.getWithQuery(`?${params}`).pipe(
      tap(res => {
        const summary = {
          currency_code: '',
          username: '-',
          total_number_of_transfer_in: 0,
          total_transfer_in_amount: 0.00,
          total_number_of_transfer_out: 0,
          total_transfer_out_amount: 0.00,
          total_number_of_deposit: 0,
          total_deposit: 0.00,
          total_number_of_withdrawal: 0,
          total_withdrawal: 0.00,
          total_bet_count: 0,
          total_bet_amount: 0.00,
          total_valid_bet_amount: 0.00,
          win_lose: 0.00,
          total_jackpot_contribution: 0.00,
          total_jackpot_win: 0.00,
          nett_jackpot: 0.00,
          game_bet_amount: 0.00,
          gross_game_revenue: 0.00,
          total_bonus: 0.00,
          total_cancelled_bonus: 0.00,
          nett_game_revenue: 0.00,
          total_rebate: 0.00,
          total_deposit_processing_fee: 0.00,
          total_withdrawal_processing_fee: 0.00
        };

        res.forEach(row => {
          summary.currency_code = row.currency_code,
            summary.username = row.username !== '-' ? row.username : summary.username,
            summary.total_number_of_transfer_in += +this.sanitizeNumericField(row.total_number_of_transfer_in),
            summary.total_transfer_in_amount += +this.sanitizeNumericField(row.total_transfer_in_amount),
            summary.total_number_of_transfer_out += +this.sanitizeNumericField(row.total_number_of_transfer_out),
            summary.total_transfer_out_amount += +this.sanitizeNumericField(row.total_transfer_out_amount),
            summary.total_number_of_deposit += +this.sanitizeNumericField(row.total_number_of_deposit),
            summary.total_deposit += +this.sanitizeNumericField(row.total_deposit),
            summary.total_number_of_withdrawal += +this.sanitizeNumericField(row.total_number_of_withdrawal),
            summary.total_withdrawal += +this.sanitizeNumericField(row.total_withdrawal),
            summary.total_bet_count += +this.sanitizeNumericField(row.total_bet_count),
            summary.total_bet_amount += +this.sanitizeNumericField(row.total_bet_amount),
            summary.total_valid_bet_amount += +this.sanitizeNumericField(row.total_valid_bet_amount),
            summary.win_lose += +this.sanitizeNumericField(row.win_lose),
            summary.total_jackpot_contribution += +this.sanitizeNumericField(row.total_jackpot_contribution),
            summary.total_jackpot_win += +this.sanitizeNumericField(row.total_jackpot_win),
            summary.nett_jackpot += +this.sanitizeNumericField(row.nett_jackpot),
            summary.game_bet_amount += +this.sanitizeNumericField(row.game_bet_amount),
            summary.gross_game_revenue += +this.sanitizeNumericField(row.gross_game_revenue),
            summary.total_bonus += +this.sanitizeNumericField(row.total_bonus),
            summary.total_cancelled_bonus += +this.sanitizeNumericField(row.total_cancelled_bonus),
            summary.nett_game_revenue += +this.sanitizeNumericField(row.nett_game_revenue);
            summary.total_rebate += +this.sanitizeNumericField(row.total_rebate);
            summary.total_deposit_processing_fee += +this.sanitizeNumericField(row.total_deposit_processing_fee);
            summary.total_withdrawal_processing_fee += +this.sanitizeNumericField(row.total_withdrawal_processing_fee);
        });
        this.summary = summary;
        this.loadingBar.complete();
        this.loading = false;
        this.getLastBetLogSyncAt();
      })
    );
  }

  onPerPage(size: Event) {
    this.onViewPageBy(this.params);
  }

  private reload() {
    this.onViewPageBy().subscribe();
  }

  timezoneDateWithTimezone(date: any, timezone: any, format: any) {
    let returnDate =  this.timeZoneDate.transformWithTimezone(date, timezone, format);
    let todayDate = DateTime.now().setZone(timezone).toFormat('(ZZ)');
    todayDate = todayDate.replace('(', '(GMT ');
    returnDate = returnDate + ' ' + todayDate;
    return returnDate;
  }

  getLastBetLogSyncAt(){
    var userTimeZone = JSON.parse(localStorage.getItem('user_data')).timezone;
    var timeInterved = 5; // 5 minutes 
    const d = new Date();
    let minutes = d.getMinutes();
    const intMinutes =  minutes/timeInterved;
    const roundedMinute = (minutes/timeInterved).toFixed(0);
    var minus;
    if( Number(intMinutes) < Number(roundedMinute) || Number(intMinutes) == Number(roundedMinute)){
      if(Number(roundedMinute) > 0) minus = 1;
      else minus = -1;
    }
    else if( Number(intMinutes) > Number(roundedMinute)){
      minus = 0;
    }
    var  minutesConverted5min = ( Number(roundedMinute) - minus )  * timeInterved;
    var newMinutes;
    if(minutesConverted5min < 10){
      newMinutes = '0'+minutesConverted5min.toString();
    }else{
      newMinutes = minutesConverted5min.toString();
    }

    var timeNow =  DateTime.now().setZone(userTimeZone).toFormat('yyyy-MM-dd HH:'+newMinutes+':00 (ZZ)'); 
    this.lastBetLogSyncAt = timeNow.replace('(', '(GMT ');
  }
}
