import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs/operators';
import { CommissionReleasesDataService } from '../../services/commission-releases-data.service';

@Component({
  selector: 'kt-release-commissions',
  templateUrl: './release-commissions.component.html',
  styleUrls: ['./release-commissions.component.scss']
})
export class ReleaseCommissionsDialogComponent implements OnInit {

  form: FormGroup;
  conversionRate$: any;
  messages$ = this.commissionReleasesDataService.messages$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReleaseCommissionsDialogComponent>,
    private commissionReleasesDataService: CommissionReleasesDataService,
  ) { }

  ngOnInit() {
    this.formInit();
    // hidden for backup due different affiliate have different conversion rate
    // this.conversionRate$ = this.commissionReleasesDataService.getConversionRate();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onRelease() {
    let value = {};
    switch (this.data.mode) {
      case 'all':
        value = {
          ...this.form.value,
        }
        break;
      case 'selected':
        value = {
          ...this.form.value,
          affiliate_commission_id: this.data.selected_commisions
        }
        break;
    }
    this.commissionReleasesDataService.release(value).pipe(
      tap((res: any) => {
        this.commissionReleasesDataService.messages$.next(res.message);
          this.form.setErrors(null);
      }),
      catchError((error) => {
          this.form.setErrors(null);
          throw error;
      })
    ).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      remark: new FormControl(null),
    });
  }
}
