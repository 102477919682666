import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, catchError, delay } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, forkJoin, of } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { PromotionCategoryM7DataService } from '../services/promotion-category-m7-data.service';
import { PromotionCategory } from '@core/models/promotion-content-category.model';

@Component({
  selector: 'kt-promotion-category-m7-edit',
  templateUrl: './promotion-category-m7-edit.component.html',
  styleUrls: ['./promotion-category-m7-edit.component.scss']
})
export class PromotionCategoryM7EditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = this.promotionCategoryDataService.messages$;
  refreshStatus: boolean;
  buttonLoading = false;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    locales$: this.promotionCategoryDataService.locales$
  };
  languages = [];
  position = this.data.mode === 'edit' ? this.data.row.position : null;
  code = this.data.mode === 'edit' ? this.data.row.code : null;
  label = this.data.mode === 'edit' ? this.data.row.name : null;
  private localesSub = new Subscription();
  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string; row?: PromotionCategory },
    private promotionCategoryDataService: PromotionCategoryM7DataService,
    private dropdownHttpService: DropdownHttpService,
    public dialogRef: MatDialogRef<PromotionCategoryM7EditDialogComponent>,
  ) { }

  ngOnInit(){
    this.formInit();
    this.addContentForm();
  }

  ngOnDestroy() {
    this.localesSub.unsubscribe();
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onType(name: string, event: any){
    this.form.patchValue({
      [name]: event.target.value
    });
  }

  onSave() {
    this.buttonLoading = true;

    this.form.setErrors({ 'invalid': true });
    const data = {
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    Object.keys(data.contents).forEach((key) => {
      if (data.contents[key].name === null || data.contents[key].name === ''){
        delete data.contents[key]
      }
    });
    switch (this.data.mode) {
      case 'edit':
        this.subscription = forkJoin([
          this.promotionCategoryDataService.updateCategory(this.data.row.id, data).pipe(
            tap(() => {
              this.buttonLoading = false;
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.promotionCategoryDataService.messages$
        ]).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.promotionCategoryDataService.add(data).pipe(
            tap(() => {
              this.buttonLoading = false;
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.promotionCategoryDataService.messages$
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private addContentForm(){
    this.localesSub = this.dropdownHttpService.locales.pipe(tap(res => {
      this.languages = res;
      const buildSubDetails = () => {
        let contents = {};
        res.map((element, index) => {
          const contentsGroup = new FormGroup({
            settings_locale_id: new FormControl(element.id),
            name: new FormControl(null),
          });
          contents = { ...contents, [element.id]: contentsGroup };
        });
        return contents;
      };
      this.form.addControl('contents', new FormGroup(buildSubDetails()));

      if (this.data.mode === 'edit') {
        this.data.row.contents.map((res, index)=> {
          const detailsGroup = {
            settings_locale_id: res.settings_locale_id,
            name: res.name
          }
          this.form.patchValue({
            position: this.data.row.position,
            code: this.data.row.code,
            label: this.data.row.name,
            contents : {[res.settings_locale_id]: detailsGroup} 
          });
        });
      }
    })).subscribe();
  }

  private formInit() {
    let status = this.data.mode === 'edit' ? this.data.row.status : 1;
    this.form = new FormGroup({
      position: new FormControl(null, [Validators.required]),
      code: new FormControl(null, [Validators.required]),
      label: new FormControl(null, [Validators.required]),
      status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false })
    });
    this.form.markAllAsTouched();
    this.form.markAsDirty();
  }

}
