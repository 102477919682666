import { Component, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, noop, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { AuthHttpService } from '@core/services/auth-http.service';
import * as fromAuthActions from '@core/store/auth/auth.actions';
import { environment } from '@env/environment';
import { AppPermissions } from '@core/models/app-permissions.model';
import { ResetPasswordDialogComponent } from './dialog/reset-password.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  loading = false;
  isLoggedIn$: Observable<boolean>;
  errors: any = [];
  code: string;

  sitePrefix: string = environment.sitePrefix;

  private unsubscribe: Subscription[] = [];

  twoFaAccess: boolean = false;

  constructor(
    private router: Router,
    private auth: AuthHttpService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.code = this.sitePrefix;
    this.initLoginForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
    this.loading = false;
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.auth.forceLowerCaseInputControl(this.loginForm, controlName, event);
  }

  initLoginForm() {
    this.loginForm = this.fb.group({
      username: [
        null,
        Validators.compose([
          Validators.minLength(3),
          Validators.maxLength(320)
        ])
      ],
      merchant_code: [
        this.code,
        Validators.compose([
          Validators.minLength(2),
          Validators.maxLength(320)
        ])
      ],
      password: [
        null,
        Validators.compose([
          Validators.minLength(6),
          Validators.maxLength(100)
        ])
      ]
    });
  }

  submit() {
    this.loginForm.disable();
    const { merchant_code, username, password } = this.loginForm.value;
    this.auth.login(merchant_code, username, password).pipe(
      tap((res) => {
        
        if(!res.success) {
          this.loginForm.enable();
          this.cdr.detectChanges();

          // Password reset action required
          if( res.data && res.data.action_reset_password && res.data.action_reset_password === true ) {
            let title = res.message ? (typeof res.message !== 'string' ? res.message[0] : res.message) : 'Please update your password';
            Swal.fire({ 
              title: title, 
              text: 'For your security, you need to reset your password before you can continue', 
              icon: 'info', 
              confirmButtonText: 'Reset Password'
            }).then(result => {
              if(result.isConfirmed) {

                const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
                  width: '400px',
                  data: {
                    username: this.loginForm.get('username').value
                  }
                });

                // Reset password field if password updated
                dialogRef.afterClosed().subscribe(res => {
                  if( res ) {
                    this.loginForm.patchValue({
                      password: null
                    });
                  }
                });
              }
            });
            return;
          } 

          // Other errors
          const buildMessage = (stack: any[] | string) => {
            let result = '';
            if (stack !== 'The given data was invalid.') {
              if (stack.length > 0 && typeof stack !== 'string') {
                stack.map((m: any) => result += `<li>${m}</li>`);
              } else {
                result += `<li>${stack}</li>`;
              }
            }
            return result;
          };

          let message = '<ul>';
          message += buildMessage(res.message);
          message += '</ul>';

          Swal.fire('System Message', message, 'error');
          return;
        }
        
        // Login success
        const navigations = res.data.navigation;
        const appPermissions: AppPermissions = res.data.app_permissions;

        this.store.dispatch(fromAuthActions.login({
          user: res.data.user,
          token: res.data.token,
          access_sections: res.data.access_sections,
          navigations: navigations,
          permissions: res.data.permissions,
          app_permissions: appPermissions,
          app_sections: res.data.app_sections,
        }));

        // for dashboard, need to manually check permissions because dashboard section is not included in res.data.navigation
        if (
          appPermissions.view_graph ||
          appPermissions.view_statistic_summary ||
          appPermissions.dashboard_view_game_provider_maintenance_hour ||
          appPermissions.dashboard_create_game_provider_maintenance_hour
        ) {
          this.router.navigate(['/dashboard']);
        } else {
          for (const key of Object.keys(navigations)) {
            const link = navigations[key]?.sub_menu?.[0]?.link;

            if (link) {
              this.router.navigate([link]);
              break;
            }
          }
        }

        if (localStorage.getItem('twofa_access')) {
          this.twoFaAccess = localStorage.getItem('twofa_access') == 'true' ? true : false;
        } else {
          let twofa_access = false;

          if (res.data.user.two_factor_authentication == 1) {
            twofa_access = true;
          } else {
            twofa_access = false;
          }

          localStorage.setItem('twofa_access', twofa_access.toString());
          this.twoFaAccess = twofa_access;
        }

        localStorage.setItem('twoFaFirstSetUp', res.data.user.twofa_first_verify.toString());

        if (!res.data.user.twofa_first_verify && this.twoFaAccess) {
          this.router.navigate(['/twofafirstsetup']);
        } else if (res.data.user.twofa_first_verify && this.twoFaAccess) {
          this.router.navigate(['/twofa']);
        } else {
          this.router.navigate(['/dashboard']);
        }
      }),
      catchError(err => {
        this.loginForm.enable();
        this.cdr.detectChanges();
        throw err;
      })
    ).subscribe(noop);
    setTimeout(() => {
      this.loginForm.enable();
    }, 1000);
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.loginForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

}
