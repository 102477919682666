import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { RebateHistory } from '@core/models/rebate-history.model';

@Injectable()
export class RebateHistoryEntityService extends EntityCollectionServiceBase<RebateHistory>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('RebateHistory', serviceElementsFactory);
  }
}
