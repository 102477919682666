import { MatDialogModule } from '@angular/material/dialog';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MiniGameDataService } from './mini-games/service/mini-game-data.service';
import { GameProviderResolver } from '@core/resolvers/game-provider.resolver';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { PlatformRoutingModule } from './platform-routing.module';
import { PlatformGamesComponent } from './subcomponents/platform-games/platform-games.component';
import { SharedModule } from '@shared/shared.module';
import { GameProvidersComponent } from './game-providers/game-providers.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GameProviderEntityService } from './game-providers/services/game-provider-entity.service';
import { GameProviderDataService } from './game-providers/services/game-provider-data.service';
import { EntityDefinitionService, EntityDataService } from '@ngrx/data';
import { MiniGamesComponent } from './mini-games/mini-games.component';
import { MiniGameResolver } from '@core/resolvers/mini-game.resolver';
import { MiniGameEntityService } from './mini-games/service/mini-game-entity.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { entityMetadata } from 'app/app.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MaintenanceHoursComponent } from './maintenance-hours/maintenance-hours.component';
import { MaintenanceHoursEntityService } from './maintenance-hours/services/maintenance-hours-entity.service';
import { MaintenanceHoursDataService } from './maintenance-hours/services/maintenance-hours-data.service';
import { MaintenanceHoursEditDialogComponent } from './maintenance-hours/dialogs/maintenance-hours-edit.component';
import { MiniGamesEditDialogComponent } from './mini-games/dialogs/mini-games-edit.component';
import { CoreModule } from '@core/core.module';
import { GameProviderPositionEntityService } from './game-provider-position/services/game-provider-position-entity.service';
import { GameProviderPositionDataService } from './game-provider-position/services/game-provider-position-data.service';
import { GameProviderPositionComponent } from './game-provider-position/game-provider-position.component';
import { GameProviderPositionEditDialogComponent } from './game-provider-position/dialogs/game-provider-position-edit.component';
import { MaterialModule } from '@views/pages/material/material.module';
import { GameTagsComponent } from './game-tags/game-tags.component';
import { GameTagsEditDialogComponent } from './game-tags/dialogs/game-tags-edit.component';
import { GameTagsEntityService } from './game-tags/services/game-tags-entity.service';
import { GameTagsDataService } from './game-tags/services/game-tags-data.service';
import { AssignGamesComponent } from './assign-games/assign-games.component';
import { AssignGamesEditDialogComponent } from './assign-games/dialogs/assign-games-edit.component';
import { AssignGameDataService } from './assign-games/services/assign-game-data.service';
import { AssignGameEntityService } from './assign-games/services/assign-game-entity.service';
import { MatTabsModule } from '@angular/material/tabs';
import { QuickLinkSettingsComponent } from './game-providers/dialogs/quick-link-settings/quick-link-settings.component';

@NgModule({
  declarations: [
    PlatformGamesComponent,
    GameProvidersComponent,
    MaintenanceHoursComponent,
    MiniGamesComponent,
    MaintenanceHoursEditDialogComponent,
    MiniGamesEditDialogComponent,
    GameProviderPositionComponent,
    GameProviderPositionEditDialogComponent,
    GameTagsComponent,
    GameTagsEditDialogComponent,
    AssignGamesComponent,
    AssignGamesEditDialogComponent,
    QuickLinkSettingsComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    PlatformRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatDialogModule,
    NgxDaterangepickerMd.forRoot(),
    MatProgressSpinnerModule,
    CoreModule,
    MaterialModule,
    MatTabsModule,
    DragDropModule,
  ],
  providers: [
    GameProviderEntityService,
    GameProviderDataService,
    MaintenanceHoursEntityService,
    MaintenanceHoursDataService,
    GameProviderDataService,
    GameProviderResolver,
    MiniGameResolver,
    MiniGameEntityService,
    MiniGameDataService,
    GameProviderPositionEntityService,
    GameProviderPositionDataService,
    GameTagsEntityService,
    GameTagsDataService,
    AssignGameDataService,
    AssignGameEntityService,
  ]
})
export class PlatformModule {
  constructor(
    private eds: EntityDefinitionService,
    private entityDataService: EntityDataService,
    private gameproviderDataService: GameProviderDataService,
    private miniGameDataService: MiniGameDataService,
    private maintenanceHoursDataService: MaintenanceHoursDataService,
    private gameproviderPositionDataService: GameProviderPositionDataService,
    private gameTagsDataService: GameTagsDataService
  ) {
    eds.registerMetadataMap(entityMetadata);
    entityDataService.registerServices({
      GameProviders: gameproviderDataService,
      MiniGame: miniGameDataService,
      MaintenanceHours: maintenanceHoursDataService,
      GameProviderPosition: gameproviderPositionDataService,
      GameTags: gameTagsDataService
    });
  }
}
