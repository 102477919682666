import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { Operator } from '@core/models/operator.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class OperatorDataService extends DefaultDataService<Operator>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Operator', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<Operator[]> {
    return this.http.get<ApiResponse>('/operator').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(operator: Operator): Observable<Operator>{
    return this.http.post<ApiResponse>(`/operator`, operator).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/operator/${entity.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.operator)
      ))
    );
  }

  getWithQuery(pageParam: string): Observable<Operator[]>{
    return this.http.get<ApiResponse>(`/operator${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getById(id: number): Observable<Operator>{
    return this.http.get<ApiResponse>(`/operator/${id}`).pipe(
      map(res => res.data.operator)
    );
  }

  resetPassword(data: any){
    return this.http.put<ApiResponse>(`/operator/changeSupportPassword`, data).pipe(
      tap( res => {
        res.message.push(`New Password: ${res.data.rows.new_password}`);
        this.messages$.next(res.message);
      }),
      map(res => res.data.rows)
    );
  }

  twofaStatus(id: any){
    return this.http.get<ApiResponse>(`/operator/twofastatus/${id}`).pipe(
      map(res => res.data)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
