<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Deposit Event History (ID: {{ data.deposit.id }})</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <kt-deposit-log [deposit]="data.deposit"></kt-deposit-log>
        </div>
        <div class="modal-footer">
          <ng-container *ngIf="data.deposit.status !== 2 && data.deposit.status !==1">
            <button *ngIf="canRequestReceipts && ((data.deposit.bank_type === 1 || data.deposit.bank_type === 3) && (data.deposit.status === 0 || data.deposit.status === 3 || data.deposit.status === 5))"(click)="requestReceipt()" type="button" class="btn btn-warning mr-2">Request Receipt</button>
            <button *ngIf="canUploadReceipts && (data.deposit.can_upload_receipt === true)" (click)="openUploadReceipt()" type="button" class="btn btn-dark mr-2">Upload Receipt</button>
          </ng-container>
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        </div>
      </div>
    </form>
  </div>
  
  <kt-swal-alert [message]="messages$ | async"></kt-swal-alert>