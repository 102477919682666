import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { AllMemberVerification } from '@core/models/allmember-verification.model';

@Injectable()
export class MemberVerificationEntityService extends EntityCollectionServiceBase<AllMemberVerification>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('MemberVerification', serviceElementsFactory)  }
}
