import { AffiliateAdjustment } from '@core/models/affiiliate-adjustment.model';
import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';

@Injectable()
export class AffiliateAdjustmentDataService extends DefaultDataService<AffiliateAdjustment>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('AffiliateAdjustment', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<AffiliateAdjustment[]> {
    return this.http.get<ApiResponse>('/affiliate/adjustment').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<AffiliateAdjustment[]>{
    return this.http.get<ApiResponse>(`/affiliate/adjustment${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  create(adjustment: AffiliateAdjustment) {
    return this.http.post<ApiResponse>(`/affiliate/adjustment`, adjustment).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }


  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }
}
