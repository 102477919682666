import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
} from "@ngrx/data";
import { Injectable } from "@angular/core";
import { GameTypeReport } from "@core/models/gametype-report.model";

@Injectable()
export class GameTypeReportEntityService extends EntityCollectionServiceBase<GameTypeReport> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super("GameTypeReport", serviceElementsFactory);
  }
}
