import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { AffiliateMessageTemplate } from '@core/models/message-template-affiliate.model';

@Injectable()
export class AfffiliateMessageTemplateEntityService extends EntityCollectionServiceBase<AffiliateMessageTemplate> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('AffiliateMessageTemplate', serviceElementsFactory);
  }
}
