<div #container>
    <angular2-multiselect [data]="dropdownList"
      [loading] = "loading"
      [settings]="dropdownSettings"
      [(ngModel)]="selectedItems"
      (onOpen)="onOpen($event)"
      (onSelect)="onItemSelect($event)"
      (onDeSelect)="OnItemDeSelect($event)"
      (onSelectAll)="onSelectAll($event)"
      (onDeSelectAll)="onDeSelectAll($event)"
      (onClose)="onClose($event)"
      (onScrollToEnd)="fetchMore($event)" >
      <c-item>
        <ng-template let-item="item">
            <label>{{item[dropdownSettings.labelKey]}}</label>
        </ng-template>
      </c-item>
    </angular2-multiselect>
  
    <div *ngIf="(selectedItems == undefined || selectedItems.length == 0) && dataLoading" [ngStyle]="{'position': 'absolute', 'bottom': '0', 'width': container.offsetWidth + 'px', 'height': container.offsetHeight + 'px', 'padding-right': '30px'}" class="text-right">
      <i class='spinner-border spinner-border-sm align-middle' [ngStyle]="{'margin-top': (container.offsetHeight / 3) + 'px'}"></i>
    </div>
  </div>