import { BetLimit } from '@core/models/bet-limit.model';
import { tap, map, catchError } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Observable } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { GroupDataService } from '../../services/group-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { stringify } from 'querystring';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-bet-limit',
  templateUrl: './bet-limit.component.html',
  styleUrls: ['./bet-limit.component.scss']
})
export class BetLimitDialogComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();

  filterForm: FormGroup;
  form: FormGroup;

  messages$ = this.groupDataService.messages$;
  memberGroupId = this.data.groupId;
  refreshStatus: boolean;
  dropdown = {
    providers: this.gameProviderHttpService.getGameProvidersCategory('SP'),
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies'))
  }
  providerMinBetLimit = null;
  providerMaxBetLimit = null;

  buttonLoading = false;

  // permissions
  canEditBetLimit: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, betLimit: BetLimit, groupId: number },
    public dialogRef: MatDialogRef<BetLimitDialogComponent>,
    private groupDataService: GroupDataService,
    private dropdownHttpService: DropdownHttpService,
    private gameProviderHttpService: GameProviderHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
      });
    }
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditBetLimit = appPermissions.edit_bet_limit;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy(){
    this.formSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(){
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const betLimit = this.formatData();
    const data = {
      member_group_id: this.memberGroupId,
      bet_limits: betLimit
    };
    this.subscription = this.groupDataService.updateBetLimit(data).pipe(
      tap((res: any) => {
        this.messages$.next([...res.message]);
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  private formatData(){
    return {
      0: {
        currency_id: this.filterForm.get('currency').value.id,
        game_provider_id: this.filterForm.get('game_provider').value.id,
        min_bet_limit: Number(this.form.get('min_bet_limit').value),
        max_bet_limit: Number(this.form.get('max_bet_limit').value),
        max_amount_per_match: Number(this.form.get('max_amount_per_match').value)
      }
    }
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private formInit() {
    this.filterForm = new FormGroup({
      game_provider: new FormControl(null, [Validators.required]),
      currency: new FormControl(null, [Validators.required])
    });
    this.form = new FormGroup({
      min_bet_limit: new FormControl(0.00, Validators.compose([Validators.required, Validators.min(0.00)])),
      max_bet_limit: new FormControl(0.00, Validators.compose([Validators.required, Validators.min(0.00)])),
      max_amount_per_match: new FormControl(0.00, Validators.compose([Validators.required, Validators.min(0.00)]))
    });
    this.valueChange();
  }

  private valueChange(){
    this.filterForm.valueChanges.forEach(res => {
      if(res.game_provider && res.currency){
        this.checkExistData(res.game_provider.code, res.currency.name)
      }
    })
  }

  checkExistData(gameProvider: string, currency: string){
    const betLimits = this.data.betLimit;
    let min_bet_limit = 0.00;
    let max_bet_limit = 0.00;
    let max_amount_per_match = 0.00;

    this.gameProviderHttpService.getGameProviderBetLimit(gameProvider, currency).subscribe( res => {
      if (res['status'] == 1) {
        this.providerMinBetLimit = (res['data']['settings_limit'][0]['min_bet']) ?? '-';
        this.providerMaxBetLimit = (res['data']['settings_limit'][0]['max_bet']) ?? '-';
      } else {
        this.providerMinBetLimit = '-';
        this.providerMaxBetLimit = '-';
      }
    });

    for (const row in betLimits){
      if(row){
        if(betLimits[row].currency_code == currency && betLimits[row].game_provider_code == gameProvider){
          min_bet_limit = betLimits[row].min_bet_limit;
          max_bet_limit = betLimits[row].max_bet_limit;
          max_amount_per_match = betLimits[row].max_amount_per_match;
        }
      }
    }

    this.form.patchValue({
      min_bet_limit: min_bet_limit,
      max_bet_limit: max_bet_limit,
      max_amount_per_match: max_amount_per_match
    })
  }
}
