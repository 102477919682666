<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <!-- Header -->
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? ('Edit' | translate) : 'Create' | translate }} {{ 'Telemarketer Settings' | translate }} {{ ' - ' }} {{ data.telemarketerSettings.currency_code }}</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 form-group">
            <label class="control-label">{{ 'Initial Lead Validity Period (Days)' | translate }} <span class="text-danger">* </span>
              <i class="fas fa-exclamation-circle" 
                matTooltip="The number of days that determine the lead's initial expiry date when the lead is assigned to a telemarketer." 
                matTooltipClass="custom-tooltip" 
                matTooltipHideDelay="0"
                matTooltipPosition="right"></i>
            </label>
            <input type="number" (wheel)="false" formControlName="validity_day" min="0" class="form-control">
          </div>
          <div class="col-md-6 form-group">
            <label class="control-label">{{ 'Follow Up Extension Period (Days)' | translate }} <span class="text-danger">* </span>
              <i class="fas fa-exclamation-circle" 
                 matTooltip="The number of days extended on the lead expiry date when a telemarketer follows up on the lead by making calls or adding remarks." 
                 matTooltipClass="custom-tooltip" 
                 matTooltipHideDelay="0"
                 matTooltipPosition="right"></i>
            </label>
            <input type="number" (wheel)="false" formControlName="follow_up_day" min="0" class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 form-group">
            <label class="control-label">{{ 'FTD Target Extension Period (Days)' | translate }} <span class="text-danger">* </span>
              <i class="fas fa-exclamation-circle" 
                matTooltip="The number of days added to the FTD Date to form a new lead expiry date for hitting the target deposit amount." 
                matTooltipClass="custom-tooltip" 
                matTooltipHideDelay="0"
                matTooltipPosition="right"></i>
            </label>
            <input type="number" (wheel)="false" formControlName="ftd_target_day" min="0" class="form-control">
          </div>
          <div class="col-md-6 form-group">
            <label class="control-label">{{ 'Inactive Period (Hours)' | translate }} <span class="text-danger">* </span>
              <i class="fas fa-exclamation-circle" 
                matTooltip="The duration a member must be inactive after registration before being eligible for telemarketer assignment. Only values in 15-minute increments are permitted (Example: 0.25, 1.50, 3.75)" 
                matTooltipClass="custom-tooltip" 
                matTooltipHideDelay="0"
                matTooltipPosition="right"></i>
            </label>
            <input type="number" (wheel)="false" formControlName="inactive_period" min="0" class="form-control" step="0.25">
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 form-group">
            <label class="control-label">{{ 'Target Deposit Amount' | translate }} <span class="text-danger">* </span>
              <i class="fas fa-exclamation-circle" 
                matTooltip="The total accumulated deposit amount that a lead needs to deposit within the given time frame." 
                matTooltipClass="custom-tooltip" 
                matTooltipHideDelay="0"
                matTooltipPosition="right"></i>
            </label>
            <input type="number" (wheel)="false" formControlName="target_deposit_amount" min="0" class="form-control">
          </div>
          <div class="col-md-6 form-group"></div>
        </div>

        <div class="row">
          <div class="col-md-6 form-group">
            <label class="control-label">{{ 'Tier Progression Amount' | translate }} <span class="text-danger">* </span>
              <i class="fas fa-exclamation-circle" 
                matTooltip="The specific deposit amounts required for a lead to move through different tiers." 
                matTooltipClass="custom-tooltip" 
                matTooltipHideDelay="0"
                matTooltipPosition="right"></i>
            </label>
            <div class="row tier-margin">
              <div class="col-md-3 align-tier">
                <label class="control-label">{{ 'Tier 0' | translate }}</label> 
              </div>
              <div class="col-md-3 align-tier">
                <span>>=</span>
              </div>
              <div class="col-md-6">
                <input type="number" (wheel)="false" formControlName="tier0_amount" min="0" class="form-control" readonly>
              </div>
            </div>
            <div class="row tier-margin">
              <div class="col-md-3 align-tier">
                <label class="control-label">{{ 'Tier 1' | translate }}</label> 
              </div>
              <div class="col-md-3 align-tier">
                <span>>=</span>
              </div>
              <div class="col-md-6">
                <input type="number" (wheel)="false" formControlName="tier1_amount" min="0" class="form-control">
              </div>
            </div>
            <div class="row tier-margin">
              <div class="col-md-3 align-tier">
                <label class="control-label">{{ 'Tier 2' | translate }}</label> 
              </div>
              <div class="col-md-3 align-tier">
                <span>>=</span>
              </div>
              <div class="col-md-6">
                <input type="number" (wheel)="false" formControlName="tier2_amount" min="0" class="form-control">
                <small class="text-muted input-msg">
                  <i class="fa fa-exclamation-circle"></i> Tier 2 setting will not be applied if leave empty
                </small>
              </div>
            </div>
            <div class="row tier-margin">
              <div class="col-md-3 align-tier">
                <label class="control-label">{{ 'Tier 3' | translate }}</label> 
              </div>
              <div class="col-md-3 align-tier">
                <span>>=</span>
              </div>
              <div class="col-md-6">
                <input type="number" (wheel)="false" formControlName="tier3_amount" min="0" class="form-control">
                <small class="text-muted input-msg">
                  <i class="fa fa-exclamation-circle"></i> Tier 3 setting will not be applied if leave empty
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>{{ 'Close' | translate }}</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event, true)"></kt-swal-alert>
