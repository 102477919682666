import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { GameProviderPosition } from '@core/models/game-provider-position.model';

@Injectable()
export class GameProviderPositionDataService extends DefaultDataService<GameProviderPosition> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('GameProviderPosition', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<GameProviderPosition[]> {
    return this.http.get<ApiResponse>('/gameproviderposition').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<GameProviderPosition[]>{
    return this.http.get<ApiResponse>(`/gameproviderposition${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  updateGameProviderPosition(gameProviderId: number, gameProvider: any) {
    return this.http.put<ApiResponse>(`/gameproviderposition/${gameProviderId}`, gameProvider).pipe(
      tap( res => this.messages$.next(res.message)),
    );
  }

  getGameProvider(gameProviderID: any): Observable<GameProviderPosition[]>{
    return this.http.get<ApiResponse>(`/gameproviderposition/${gameProviderID}`).pipe(
      map(res => res.data)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

}
