<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
      <div class="modal-header">
          <h5 class="modal-title">Remark History</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
      </div>
      <div class="modal-body">
        <section class="col-lg-12 card border-0">
          <div class="col-12 p-0 card rounded-0" *ngIf="remarksHistory.length > 0">
            <div class="card-header">
              Timeline
            </div>
            <div class="row ml-1" *ngFor="let row of remarksHistory">
              <div class="col-2 p-3">
                {{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }}
              </div>
              <div class="col-10 p-3">
                <!-- The Timeline -->
                <div class="timeline">
                  <!-- Item 1 -->
                  <div>
                    <div class="direction-r">
                      <div class="flag-wrapper">
                        <span class="flag" [ngClass]="'border-color--' + row.action_type">
                          <section class="col-12 p-3 inner-box-timeline">
                            <div class="row border-top border-left">
                              <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                <span class="kt-font-bold">Remark</span>
                              </div>
                              <div class="col-md-9 border-right p-3">
                                <span>{{ row.remarks ? row.remarks : '-' }}</span>
                              </div>
                            </div>

                            <div class="row border-top border-left">
                              <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                <span class="kt-font-bold">Reason</span>
                              </div>
                              <div class="col-md-9 border-right p-3">
                                <span>{{ row.reason ? row.reason : '-' }}</span>
                              </div>
                            </div>

                            <div class="row border-top border-left border-bottom" *ngFor="let field of row.fields">
                              <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                <span class="kt-font-bold">{{ field.field_name }}</span>
                              </div>
                              <ng-container *ngIf="field.type == 1">
                                <div class="col-md-9 border-right p-3">
                                  <span>{{ field.verification_text != '' && field.verification_text != null ? field.verification_text : '-' }}</span>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="field.type == 2 || field.type == ''">
                                <ng-container *ngIf="field.documents.length > 0; else emptyDocument">
                                  <div class="col-md-9 border-right p-3 attachmentIcon">
                                    <span class="text-right"><i class="fa fa-file" (click)="openDocument(field.documents)"></i></span>
                                  </div>
                                </ng-container>
                                <ng-template #emptyDocument>
                                  <div class="col-md-9 border-right p-3">
                                    <span>-</span>
                                  </div>
                                </ng-template>
                              </ng-container>
                            </div>
                            <div class="row border-top border-left border-bottom">
                              <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                <span class="kt-font-bold">Created By</span>
                              </div>
                              <div class="col-md-9 border-right p-3">
                                <span class="text-right">{{ row.created_by ? row.created_by : '-' }}<br>{{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }} </span>
                              </div>
                            </div>
                          </section>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="kt-pagination kt-pagination--brand mt-2 p-3" *ngIf="pagination !== undefined">
              <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="getTimeline(page)">
              </ngb-pagination>
              <div class="kt-pagination__toolbar">
                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}" class="form-control kt-font-brand page-size">
                  <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                </select>
                <span class="pagination__desc">
                  Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0'}} records
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="modal-footer" *ngIf="data.mode == 'remarks'">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
      </div>
  </div>
</div>
