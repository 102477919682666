import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';
import { SMSRecoveryReport } from '@core/models/sms-recovery-report.model';

@Injectable()
export class SMSRecoveryReportDataService extends DefaultDataService<SMSRecoveryReport>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('SMSRecoveryReport', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<SMSRecoveryReport[]> {
    return this.http.get<ApiResponse>('/smsprovider/report?paginate=true').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<SMSRecoveryReport[]>{
    return this.http.get<ApiResponse>(`/smsprovider/report?paginate=true${pageParam}`).pipe(
      tap(res => {
        this.paginationInit(res);
      }),
      map(res => res.data.rows)
    );
  }

  exportReport(pageParam: string) {
    return this.http.get<ApiResponse>(`/smsprovider/report/export${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res){
      this.pagination = res.data.paginations;
    }
  }

}
