import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VipMemberLogsDataService {

  messages$ = new Subject<any[]>();

  constructor(
    private http: HttpClient
  ) { }

  getWithQuery(params: string): Observable<[]> {
    return this.http.get<ApiResponse>(`/vipmemberlogs?${params}`).pipe(
      map(res => res.data.data)
    );
  }

  export(pageParam: string) {
    return this.http.get<ApiResponse>(`/vipmemberlogs/export?${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }
}
