// Angular
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { AuthActions } from '@core/store/auth/auth-action.types';
import { loggedUser, specialPermissions } from '@core/store/auth/auth.selectors';
import { MatDialog } from '@angular/material/dialog';
import { AccountPasswordDialogComponent } from '@views/pages/apps/account/dialogs/account-password/account-password.component';
import { AccountTimezoneDialogComponent } from '@views/pages/apps/account/dialogs/account-timezone/account-timezone.component';
import { AccountTwoFADialogComponent } from '@views/pages/apps/account/dialogs/account-twofa/account-twofa.component';
import Swal from 'sweetalert2';
import { PanicModeHttpService } from '@core/services/panic-mode-http.service';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  user$: Observable<any>;
  photoDefault = '/assets/media/users/default.jpg';
  messages$ = this.panicHttpService.messages$;
  panicModePermission = false;
  panicModeStatus = null;
  userPermissions$ = this.store.select(specialPermissions);

  panicModeStatusSub: Subscription;

  projectCode: string = environment.projectCode;

  two_factor_authentication = JSON.parse(localStorage.getItem('user_data')).two_factor_authentication == 1 ? true : false;
  twoFaAccess: boolean = false;

  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;

  private subscriptions = new Subscription();

  // permissions
  canGetSetPanicMode: boolean;

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    private panicHttpService: PanicModeHttpService,
    private cdr: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
  ) {
    this.user$ = this.store.select(loggedUser);
  }

  ngOnInit() {
    if (localStorage.getItem('twofa_access')) {
      this.twoFaAccess = localStorage.getItem('twofa_access') == 'true' ? true : false;
    } else {
      localStorage.setItem('twofa_access', this.two_factor_authentication.toString());
      this.twoFaAccess = this.two_factor_authentication;
    }

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canGetSetPanicMode = appPermissions.panic_mode;

      if (this.canGetSetPanicMode) {
        this.panicHttpService.panicModeWebsocket();
        this.panicModeStatusSub = this.panicHttpService.panicModeStatus$.subscribe(status => {
          this.panicModeStatus = status;
          this.cdr.detectChanges();
        });

        if (this.panicModeStatus === null) {
          this.panicHttpService.getPanicMode().subscribe();
        }
      }
    });

    this.subscriptions.add(apSub);
  }

  onChangePassword() {
    this.dialog.open(AccountPasswordDialogComponent, {
      width: '800px'
    });
  }
  
  onChangeTimezone() {
    this.dialog.open(AccountTimezoneDialogComponent, {
      width: '800px'
    });
  }

  onPanicModeOn(event: Event) {
    this.panicModeStatus = (<HTMLInputElement>event.target).checked;

    let displaytext;
    let status = this.panicModeStatus ? 1 : 0;
    if (this.panicModeStatus) {
      displaytext = `Do you want to turn on panic mode?`;
    } else {
      displaytext = `Do you want to turn off panic mode?`;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: displaytext,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.panicHttpService.panicMode(status).pipe(
          tap((res: any) => {
            this.messages$.next([...res.message]);
          }),
          catchError((error) => {
            throw error;
          })
        ).subscribe();
      } else {
        this.panicModeStatus = !(<HTMLInputElement>event.target).checked;
        this.cdr.detectChanges();
      }
    });
  }

  onLogout() {
    this.store.dispatch(AuthActions.logout());
  }

  onOpenTwoFA() {
    this.dialog.open(AccountTwoFADialogComponent, {
      width: '500px'
    })
  }
}
