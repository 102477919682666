<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add {{+data.promoCodeFormData.eligible_types !== 2 ? 'Member' : 'Affiliate'}}</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 form-group">
              <label class="control-label">{{+data.promoCodeFormData.eligible_types !== 2 ? 'Member' : 'Affiliate'}} <span class="text-danger">*</span></label>
              <kt-member-dropdown class="col-8 pl-0 p-0 dropdown-maxheight"
              [form]="form"
              [memberType]="+data.promoCodeFormData.eligible_types === 3 || +data.promoCodeFormData.eligible_types === 4 ? +data.promoCodeFormData.eligible_types : undefined"
              [isAffiliate]="+data.promoCodeFormData.eligible_types === 2"
              [dropdownSettings]='dropdown'
              [formName]="+data.promoCodeFormData.eligible_types !== 2 ? 'member_ids' : 'affiliate_ids'"
              [telemarketer_ids]="data.promoCodeFormData.telemarketer_ids"
              [normal_account_manager_ids]="data.promoCodeFormData.normal_account_manager_ids"
              [vip_account_manager_ids]="data.promoCodeFormData.vip_account_manager_ids"
              [selectionAttributes]="'id'"
              (selectedItemsChanged)="onSelectedPersonChanged($event)">
            </kt-member-dropdown>
            </div>

          </div>
        </div>


        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button [isDisabled]="false || selectedPerson.length == 0" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.promotionPerson, data.mode)"></kt-submit-button>
        </div>
    </div>
  </form>
</div>
<kt-swal-alert (confirmed)="onCloseDialog($event)"></kt-swal-alert>