<div  class="modal-content">
  <div  class="modal-header">
      <h5 class="modal-title">Blacklist</h5>
  </div>
  <div  class="modal-body">
      <div  class="form-group row">
          <label  class="col-lg-3 control-label">User:</label> <span  class="col-lg-9">phool sing yadav (yadav1207)</span></div>
      <div  class="form-group row">
          <label  class="col-lg-3 control-label">Mobile Number:</label> <span  class="col-lg-9">01164121207</span></div>
      <div  class="form-group row">
          <label  class="col-lg-3 control-label">Email:</label> <span  class="col-lg-9">phoolsingyadav@gmail.com</span></div>
      <div  class="form-group row">
          <label  class="col-lg-3 col-form-label">Blacklist Type:</label>
          <div  class="col-lg-9">
              <div >
                  <label  class="kt-checkbox">
                      <input  type="checkbox" value="1"> Bonus Hunter <span ></span></label>
                  <br >
                  <label  class="kt-checkbox">
                      <input  type="checkbox" value="1"> Suspect Bonus Hunter <span ></span></label>
                  <br >
                  <label  class="kt-checkbox">
                      <input  type="checkbox" value="1"> Cheater<span ></span></label>
                  <br >
                  <label  class="kt-checkbox">
                      <input  type="checkbox" value="1"> Cheater Bank Account <span ></span></label>
                  <br >
                  <label  class="kt-checkbox">
                      <input  type="checkbox" value="1"> Side Bet <span ></span></label>
                  <br >
                  <label  class="kt-checkbox">
                      <input  type="checkbox" value="1"> Suspect Side Bet <span ></span></label>
                  <br >
                  <label  class="kt-checkbox">
                      <input  type="checkbox" value="1"> Multipler ID Register <span ></span></label>
                  <br >
                  <label  class="kt-checkbox">
                      <input  type="checkbox" value="1"> Abnormal Bet <span ></span></label>
                  <br >
                  <label  class="kt-checkbox">
                      <input  type="checkbox" value="1"> Others<span ></span></label>
              </div>
          </div>
      </div>
      <div  class="form-group row">
          <label  for="remark" class="col-lg-3 col-form-label">Remarks</label>
          <div  class="col-lg-9">
              <input  id="remark" class="form-control">
          </div>
      </div>
  </div>
  <div  class="modal-footer">
      <button  id="closeModal" type="button" data-dismiss="modal" class="btn btn-secondary" (click)="onCloseDialog()">Close </button>
      <button  type="button" class="btn btn-primary float-left">Save Changes</button>
  </div>
</div>
