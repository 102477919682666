<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Remark History ({{bankReceipt.id}})</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <div class="kt-section">
        <div class="kt-section__content">
          <!-- Content -->
        <div class="table-responsive">
          <!-- Table -->
          <table class="table table-bordered table-hover table-striped">
              <!-- Header -->
              <thead>
                  <tr class="text-top">
                      <th class="text-center">ID</th>
                      <th>Remark</th>
                      <th>Status</th>
                      <th class="text-center">Created By</th>
                      
                  </tr>
              </thead>

              <!-- Body -->
              <tbody *ngIf="(remarks$ | async) as rows">
                  <!-- Member report -->
                      <tr *ngFor="let row of rows">
                        <td class="text-center">{{ row.id }}</td>
                        <td>{{ row.remarks }}</td>
                        <td class="text-center">
                          <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                        </td>
                        <td class="text-center">{{ row.created_by != null ? row.created_by : '-' }}<br>{{row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</td>
                      </tr>
                  <!-- If no record -->
                  <kt-fallback-row [collection]="rows"></kt-fallback-row>
              </tbody>
          </table>
      </div>

      <!-- Pagination -->
      <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
          <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
          </ngb-pagination>
          <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                  <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                  Showing {{pagination.from}} to {{pagination.to}} of
                  {{ pagination.total | number : '1.0' }} records
              </span>
          </div>
      </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
  </div>
</div>

