import Swal from 'sweetalert2';
import * as moment from 'moment-timezone';
import { AppState } from '@store/reducers';
import { select, Store } from '@ngrx/store';
import { AuthData } from '@core/models/auth-data';
import { Observable, of, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Pagination } from '@core/models/pagination.model';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';

import { MemberBankAccountVerificationDetailsComponent } from './dialogs/member-bank-account-verification-details/member-bank-account-verification-details.component';
import { MemberBankAccountVerificationRemarkComponent } from './dialogs/member-bank-account-verification-remark/member-bank-account-verification-remark.component';
import { MemberBankAccountVerificationDataService } from './services/member-bank-account-verification-data.service';
import { MemberBankAccountVerificationEntityService } from './services/member-bank-account-verification-entity.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { MemberBankAccountVerification } from '@core/models/member-bank-account-verification-model';
import { ShowDocumentComponent } from './dialogs/show-document/show-document.component';
import { MemberInformationDialogComponent } from '@views/pages/apps/general/members/dialogs/member-information/member-information.component';
import { MemberDataService } from '../members/services/member-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kt-member-bank-account-verification',
  templateUrl: './member-bank-account-verification.component.html',
  styleUrls: ['./member-bank-account-verification.component.scss']
})
export class MemberBankAccountVerificationComponent implements OnInit, OnDestroy {

  form: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  pagination: Pagination;
  ranges: any;

  dropdownSettings = {};
  dropdown = {
    groups: this.dropdownHttpService.groups,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: this.dropdownHttpService.memberBankAccountVerificationStatuses,
    perPage: this.dropdownHttpService.perPage
  };
  searchStatus = [0, 3];
  memberBankAccountVerification$: Observable<MemberBankAccountVerification[]>;
  params = ``;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '1.3';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'currency': 'desc',
    'member_group': 'desc',
    'status': 'desc',
    'remarks': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  currentUserId: number;
  currentOperatorUsername: string;
  loggedUser$: Observable<AuthData>;

  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  // permissions
  canEditMemberBankAccountVerification: boolean;
  canViewVerificationRemarkHistory: boolean;
  canViewMemberDialog: boolean;

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private memberBankAccountVerificationService: MemberBankAccountVerificationEntityService,
    private memberBankAccountVerificationDataService: MemberBankAccountVerificationDataService,
    private loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
    private memberDataService: MemberDataService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    const loggedUserStoreSub = this.store.pipe(select(loggedUser)).subscribe((res) => {
      this.currentUserId = res.id;
      this.currentOperatorUsername = res.username;
    });
    this.pagination = this.memberBankAccountVerificationDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.onSubmit(true);

    this.accountTypeDropdownList = this.dropdownHttpService.accountTypesLists;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];

    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditMemberBankAccountVerification = appPermissions.edit_member_verification;
      this.canViewVerificationRemarkHistory = appPermissions.view_verification_remark_history;
      this.canViewMemberDialog = appPermissions.member_verification_view_member_dialog;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
    this.subscriptions.add(loggedUserStoreSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }

    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.memberBankAccountVerification$ = this.memberBankAccountVerificationService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.memberBankAccountVerificationDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onCheckBox(item: any) {
    return this.searchStatus.indexOf(+item) >= 0 ? true : false;
  }

  onSearchStatus(event: any, value: number) {
    const position = this.searchStatus.indexOf(+value);
    if (event.target.checked) {
      this.searchStatus.push(+value);
    } else {
      this.searchStatus.splice(position, 1);
    }
    this.form.patchValue({ status: this.searchStatus });
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.searchStatus = [0, 3];
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    this.form.patchValue({
      date_type: 'created_at',
      start_date: null,
      end_date: null,
      defaultDate: null,
      username: null,
      keyword: null,
      group_id: 'all',
      currency_id: 'all',
      status: this.searchStatus,
      account_type_condition: 'Or',
      account_type: [1, 2, 3]
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        const statusParams = this.generateStatusParams();

        if(data['account_type']){
          data['account_type'] = this.form.controls['account_type'].value.toString();
        }

        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        this.loadingBar.start();
        return this.memberBankAccountVerification$ = this.memberBankAccountVerificationService.getWithQuery(`?${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.memberBankAccountVerificationDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private generateStatusParams() {
    return {
      newStatusParams: Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&'),
      oldStatusParams: 'status=' + Object.keys(this.searchStatus).map(key => this.searchStatus[key]).join(','),
    };
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      group_id: new FormControl('all'),
      currency_id: new FormControl('all'),
      status: new FormControl(this.searchStatus),
      keyword: new FormControl(null),
      date_type: new FormControl('created_at'),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null),
      account_type_condition: new FormControl('Or'),
      account_type: new FormControl([1,2,3])
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, memberVerificationDetails?: any, documents?: any, memberVerification?: any, member?: any, newHandler?: string, refreshAfterCloseDialog?: boolean }, dimension?: string) {
    const dialogData = { mode: data?.mode };
    if (data?.mode === 'edit') {
        dialogData['memberVerification'] = data.memberVerificationDetails;
        if (data?.newHandler) {
          dialogData['newHandler'] = data.newHandler;
        }
    } else if (data?.mode === 'show-document') {
      dialogData['documents'] = data.documents;
    } else if (data?.mode === 'show-remarks') {
      dialogData['memberVerification'] = data.memberVerification;
    } else if (data?.mode === 'member-information') {
      dialogData['member'] = data.member;
    }

    const dialogRef = this.dialog.open(componentRef, {
      width: dimension ? dimension : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true || data.refreshAfterCloseDialog) {
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  onOpenDialog(mode: any, memberVerificationRow: any) {
    if (mode == 'edit') {
      this.subscription = this.memberBankAccountVerificationDataService.getById(memberVerificationRow.id).pipe(
        tap((res) => {
          if (res) {
            const showDialog = (takeover = false) => {
              let refresh = memberVerificationRow.status != res.status;
              if (takeover) {
                const data = {
                  id: res.id,
                  takeover: takeover,
                  triggerMessage: false
                };

                this.memberBankAccountVerificationDataService.updateVerification(data.id, data).subscribe();
                this.openDialogBy(MemberBankAccountVerificationDetailsComponent, { mode: mode, memberVerificationDetails: res, newHandler: this.currentOperatorUsername, refreshAfterCloseDialog: refresh }, '1200px');
              } else {
                this.openDialogBy(MemberBankAccountVerificationDetailsComponent, { mode: mode, memberVerificationDetails: res, refreshAfterCloseDialog: refresh }, '1200px');
              }
            };

            if (res.bo_user_id > 0 && (res.bo_user_id !== this.currentUserId) && (res.status === 3)) {
              Swal.fire({
                title: 'Someone is on the same verification row!',
                text: 'Do you want to take over?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                reverseButtons: true,
              }).then((resp) => {
                if (resp.value) {
                  return showDialog(true);
                }
              });
            } else {
              return showDialog();
            }
          }
        })
      ).subscribe();
    } else if (mode == 'show-document') {
      this.openDialogBy(ShowDocumentComponent, { mode: mode, documents: memberVerificationRow.documents });
    } else if (mode == 'show-remarks') {
      this.openDialogBy(MemberBankAccountVerificationRemarkComponent, { mode: mode, memberVerification: memberVerificationRow }, '1200px');
    } else if (mode == 'member-information') {
      const member = this.memberDataService.getById(memberVerificationRow.member_id, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
        this.subscription = member.pipe(
          tap((res) => {
            if (res) {
              this.openDialogBy(MemberInformationDialogComponent, { mode: mode, member: res }, '1500px');
            }
          })
        ).subscribe();
    } 
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }
}
