import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs/operators';

import { SiteDomain } from '@core/models/site-domain.model';
import { SiteDomainDataService } from '../services/site-domain-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-site-domain-edit',
  templateUrl: './site-domain-edit.component.html',
  styleUrls: ['./site-domain-edit.component.scss']
})
export class SiteDomainEditComponent implements OnInit, OnDestroy {

  form: FormGroup;
  buttonLoading = false;
  messages$ = this.siteDomainDataService.messages$;

  dropdown = {
    type: this.dropdownHttpService.siteDomainTypes,
    deviceType: this.dropdownHttpService.deviceTypes,
    statuses: this.dropdownHttpService.statuses,
  }

  private subscriptions = new Subscription();

  // permissions
  canCreateSiteDomain: boolean;
  canEditSiteDomain: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, siteDomain: SiteDomain },
    public dialogRef: MatDialogRef<SiteDomainEditComponent>,
    private siteDomainDataService: SiteDomainDataService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateSiteDomain = appPermissions.create_site_domain;
      this.canEditSiteDomain = appPermissions.edit_site_domain;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    const data = {
      ...this.form.getRawValue()
    }
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    if (this.data.mode === 'create') {
      this.siteDomainDataService.add(data).pipe(
        tap((_) => {
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else if (this.data.mode === 'edit') {
      this.siteDomainDataService.update(this.data.siteDomain.id, data).pipe(
        tap((_) => {
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
  }

  private formInit() {
    this.form = new FormGroup({
      domain: new FormControl(this.data.mode === 'edit' ? this.data.siteDomain.domain : null, [Validators.required]),
      type: new FormControl(this.data.mode === 'edit' ? this.data.siteDomain.type : null, [Validators.required]),
      device_type: new FormControl(this.data.mode === 'edit' ? this.data.siteDomain.device_type : null, [Validators.required]),
      status: new FormControl(this.data.mode === 'edit' ? this.data.siteDomain.status : null, [Validators.required]),
    });
  }
}
