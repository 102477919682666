<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Banner</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
          <div class="col-12 form-group row">
              <label class="col-4 col-form-label"> Name <span class="text-danger">*</span> </label>
              <input type="text" min="1" formControlName="name"  class="col-8 form-control">
          </div>
          <div class="col-12 form-group row">
              <label class="col-form-label col-4"> Type <span class="text-danger">*</span></label>
              <select *ngIf="dropdown.bannerTypes" formControlName="banner_type_id" class="col-8 form-control">
                <option [value]="null" [disabled]="true">Please Select</option>
                <option *ngFor="let value of dropdown.bannerTypes | async" [value]="value.id">{{ value.name }}</option>
              </select>
          </div>
          <div class="col-12 form-group row">
              <label class="col-form-label col-4"> Locale <span class="text-danger">*</span></label>
              <select *ngIf="dropdown.locales" formControlName="settings_locale_id" class="col-8 form-control">
                <option [value]="null" [disabled]="true">Please Select</option>
              <option *ngFor="let value of dropdown.locales | async" [value]="value.id">{{ value.code }}</option>
              </select>
          </div>
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label"> Status <span class="text-danger">*</span> </label>
            <select *ngIf="dropdown.statuses" formControlName="status" class="col-8 form-control">
              <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
            </select>
          </div>
          <div class="col-12 form-group row">
              <label class="col-4 col-form-label"> Position <span class="text-danger">*</span> </label>
              <input type="number" (wheel)="false" min="1" formControlName="position"  class="col-8 form-control">
          </div>
          <div class="col-12 form-group row">
              <label class="col-4 col-form-label"> Platform <span class="text-danger">*</span> </label>
              <select formControlName="platform_type_id" class="col-8 form-control">
                <option [value]="1">User Portal</option>
                <option [value]="2">Affiliate Portal</option>
              </select>
          </div>
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label"> Image <span class="text-danger">*</span> </label>
            <div class="col-8 m-0 p-0">
              <div class="row m-0 p-0">
                <label class="btn btn-default btn-sm float-left">
                  <span>Choose file</span>
                  <input type="file" hidden accept="image/*" (change)="onUploadFile($event)">
                </label>
                <div class="spinner-wrapper" *ngIf="loading">
                  <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                </div>
              </div>
              <div class="image-wrapper" *ngIf="data.banner || imagePreview.length > 0">
                <img class="img-fluid" alt="Image Banner" [src]="imagePreview[0] || data.banner.image_path" />
              </div>
            </div>
          </div>
          <div class="col-12 form-group row">
              <label class="col-4 col-form-label"> Link
                <i class="fa fa-info-circle ml-1" placement="right" [ngbTooltip]="linkToolTip" tooltipClass="tooltip-class"></i>
              </label>
              <input type="text" formControlName="link"  class="col-8 form-control" placeholder="/promotion?code=code001">
          </div>

          <ng-template #linkToolTip>
            <div class="text-left">
              Internal Link: A hyperlink that points to the other pages on your website. <br>
              Examples:<br>
              /promotion?code=CODE0001<br>
              /referral<br>
              <br><br>
              External Link: A hyperlink that points to the target page on other websites.<br>
              Examples:<br>
              /external?link=https://example.com/mobileapp.apk<br>
              /external?link=https://google.com<br>
            </div>
          </ng-template>

          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Start Date Time <span class="text-danger">*</span></label>
            <div class="col-8 input-group date">
              <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack?.start_datetime" [ngModelOptions]="{standalone: true}">
              <input type="text" formControlName="start_datetime" class="form-control">
              <span class="input-group-append" [owlDateTimeTrigger]="dateStartRef">
                  <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
              </span>
              <owl-date-time #dateStartRef></owl-date-time>
            </div>
          </div>

          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">End Date Time</label>
            <div class="col-8 input-group date">
              <input class="hidden" [owlDateTime]="dateEndRef" [ngModel]="dateTimeStack?.end_datetime" [ngModelOptions]="{standalone: true}">
              <input type="text" formControlName="end_datetime" class="form-control">
              <span class="input-group-append" [owlDateTimeTrigger]="dateEndRef">
                  <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
              </span>
              <owl-date-time #dateEndRef></owl-date-time>
            </div>
          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid || loading" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.banner, data.mode)" #focusfield></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
