import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { smsProviderCredentials } from '@core/models/sms-provider-credentials.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service'
import { ProvidersDataService } from '../../service/providers-data.service';
import { catchError, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { ProviderCredentialSettingsDialogComponent } from '../provider-credential-settings/provider-credential-settings.component';
@Component({
  selector: 'kt-provider-credentials-settings',
  templateUrl: './provider-credentials-settings-edit.component.html',
  styleUrls: ['./provider-credentials-settings-edit.component.scss']
})
export class ProviderCredentialsSettingsEditDialogComponent implements OnInit {

  form: FormGroup;
	dropdown = {
		status: this.dropdownHttpService.statuses,
		routeList: [],
	};
	loading = false;
	buttonLoading = false;
	messages$ = this.providerDataService.messages$;
	showRouteIdField = false;
  routeFieldClicked = false;
	private subscriptions = new Subscription();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { mode: string; credentials; setting; routeJustSwitchedOff?; transactionalJustSwitchedOff?; transactionalJustSwitchedOn},
		public dialogRef: MatDialogRef<ProviderCredentialsSettingsEditDialogComponent>,
		private dropdownHttpService: DropdownHttpService,
		public dialog: MatDialog,
		private providerDataService: ProvidersDataService
	) {}

	ngOnInit() {
		this.formInit();
		let region = this.data.setting.region;
    let transactional = this.form.value.transactional;
		this.providerDataService.getSMSEdgeRoute(region, transactional).subscribe((res) => {
			this.dropdown.routeList = res;
		});

		if (this.data.setting.smart_route == 0) {
			this.showRouteIdField = true;
		}
	}

	onCloseDialog(value = false) {
		this.dialogRef.close(value);
	}

  markAsClicked() {
    this.routeFieldClicked = true;
  }

	onToggleSmartRoute(evt) {
		let checkSmartRoute = evt.target.checked;
		if (checkSmartRoute == true) {
			this.showRouteIdField = false;
			this.form.patchValue({
				route_id: null,
				route_name: null,
				smart_route: 1,
			});
		} else {
			this.showRouteIdField = true;
			this.form.patchValue({
				smart_route: 0,
			});
		}
	}

	onToggleShortenURL(evt) {
		let checkShortenURL = evt.target.checked;
		if (checkShortenURL == true) {
			this.form.patchValue({
				shorten_url: 1,
			});
		} else {
			this.form.patchValue({
				shorten_url: 0,
			});
		}
	}

  onToggleTransactional(evt) {
		let checkTransactional = evt.target.checked;
    	let region = this.data.setting.region;
		this.dropdown.routeList = [];
		if (checkTransactional == true) {
			this.form.patchValue({
				route_id: null,
				route_name: null,
				transactional: 1,
			});
		} else {
			this.form.patchValue({
				route_id: null,
				route_name: null,
				transactional: 0,
			});
		}
    this.providerDataService.getSMSEdgeRoute(region, checkTransactional ? 1 : 0).subscribe((res) => {
      this.dropdown.routeList = res;
    });
	}

	onSave() {
		this.buttonLoading = true;
		if (this.form.valid) {
			if (this.form.value.route_id !== null) {
				const selectedRoute = this.dropdown.routeList.find((route) => route.id === this.form.value.route_id);
				this.form.patchValue({ route_name: selectedRoute ? selectedRoute.name : "" });
			}
			const data = {
				...this.form.getRawValue(),
			};

			this.subscriptions = this.providerDataService
				.updateSmsProviderSetting(this.data.setting.id, data)
				.pipe(
					tap((res: any) => {
						this.buttonLoading = false;
						this.messages$.next([...res.message]);
					}),
					catchError((error) => {
						this.buttonLoading = false;
						this.form.setErrors(null);
						throw error;
					})
				)
				.subscribe();
		}
	}

	private formInit() {
		this.form = new FormGroup(
			{
				region: new FormControl({ value: this.data.setting.region, disabled: true }),
				smart_route: new FormControl(this.data.setting.smart_route),
				shorten_url: new FormControl(this.data.setting.shorten_url),
				transactional: new FormControl(this.data.setting.transactional),
				status: new FormControl(this.data.setting.status),
				route_id: new FormControl(this.data.setting.route_id),
				route_name: new FormControl(this.data.setting.route_name),
			},
			{ validators: this.routeIdValidator }
		);
		this.form.get("smart_route").valueChanges.subscribe((value) => {
			this.form.get("route_id").updateValueAndValidity();
		});
	}

	routeIdValidator(control: AbstractControl): { [key: string]: any } | null {
		const smartRoute = control.get("smart_route").value;
		const routeId = control.get("route_id").value;

		if (!smartRoute && !routeId) {
			return { routeIdRequired: true };
		}
		return null;
	}
}
