<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right  kt-margin-b-10">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <mat-tab-group animationDuration="0ms">
                            <ng-container>
                                <mat-tab label="Bank / Payment Gateway" *ngIf="canViewBankingMerchantAccounts">
                                    <app-merchant-bank type="bank_payment_gateway"></app-merchant-bank>
                                </mat-tab>
                                <mat-tab label="Crypto" *ngIf="canViewCryptoMerchantAccounts"> 
                                    <app-merchant-bank type="crypto"></app-merchant-bank>
                                </mat-tab>
                            </ng-container>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
