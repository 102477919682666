<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-100" [formGroup]="form">
    <div class="modal-content">

      <!-- Edit Game Provider Position -->
      <div class="modal-header">
        <h5 class="modal-content" class="modal-title">Edit Position</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>

      <div class="modal-body">
        <div class="row">
          <!-- Game Provider -->
          <div class="col-md-6 form-group">
              <label>Game Provider</label>
              <input type="text" formControlName="game_provider_name" class="form-control" disabled>
          </div>
        </div>

        <section formGroupName="category_list">
          <div class="row">
            <ng-container *ngFor="let categories of data.gameProviderPosition.category_list; let i = index">
              <section class="card border-0" style="padding-bottom: 20px" [ngClass]="{'col': data.gameProviderPosition.category_list.length === 1, 'col-6': data.gameProviderPosition.category_list.length % 2 === 0, 'col-4': data.gameProviderPosition.category_list.length % 2 !== 0 && data.gameProviderPosition.category_list.length !== 1}" [formGroupName]="i">
                <div class="col-12 p-0 card rounded-0">

                  <!-- Category -->
                  <div class="card-header">
                    {{ categories.category_name }}
                  </div>

                  <section class="col-12 p-4">
                    <!-- Title -->
                    <div class="row border-top border-bottom">
                      <div class="col-lg-6 col-md-6 card-header card-header-color2 p-3 border-right border-left">
                        <span class="kt-font-bold">Currency</span>
                      </div>
                      <div class="col-lg-6 col-md-6 card-header card-header-color2 p-3 text-center border-right">
                        <span class="kt-font-bold">Position</span>
                      </div>
                    </div>

                    <!-- Currency and Position -->
                    <div formGroupName="position_list">
                      <div class="row border-bottom" *ngFor="let positions of categories.position; let x = index" [formGroupName]="x">
                        <div class="col-lg-6 col-md-6 p-3 border-right border-left">
                          <label> {{ positions.currency_code }} </label>
                        </div>
                        <div class="col-lg-6 col-md-6 p-3 border-right">
                          <input type="number" class="form-control" formControlName="position">
                        </div>
                      </div>
                    </div>
                  </section>

                </div>
              </section>
            </ng-container>
          </div>
        </section>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i
            class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading"
          (confirmed)="onSave()"></kt-submit-button>
      </div>

    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>