import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class CurrencyHttpService {

  constructor(private http: HttpClient) { }

  getCurrency(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/currency`).pipe(
      map(res => res.data.rows)
    );
  }

  setCurrency(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/currency`).pipe(
      map(res => res.data.rows),
      tap(res => sessionStorage.setItem('currencies', JSON.stringify(res))),
    );
  }
}

