import { AuthHttpService } from '@core/services/auth-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { AllMemberReportSummary } from '@core/models/all-member-report-summary.model';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription, Observable, of, throwError } from 'rxjs';
import { map, tap, exhaustMap, catchError } from 'rxjs/operators';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { Pagination } from '@core/models/pagination.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { MarketingReportEntityService } from './services/marketing-report-entity.service';
import { MarketingReportDataService } from './services/marketing-report-data.service';
import { MarketingReport } from '@core/models/marketing-report.model';
import { MarketingReportDetailsComponent } from './dialogs/report-details/marketing-report-details.component';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';

@Component({
  templateUrl: './marketing-report.component.html',
  styleUrls: ['./marketing-report.component.scss']
})
export class MarketingReportComponent implements OnInit, OnDestroy {

  form: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  from = this.transactionHttpService.getYesterday().from;
  to = this.transactionHttpService.getToday().to;

  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  timezoneDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown clear-all-disabled',
    primaryKey: 'offset',
    labelKey: 'offset',
    noDataLabel: '',
    showCheckbox: false,
  };
  params = ``;
  dropdown = {
    timezones: JSON.parse(localStorage.getItem('timezones')),
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage
  };

  marketingReport$: Observable<MarketingReport[]>;

  pagination: Pagination;
  summary$: AllMemberReportSummary;
  ranges: any;
  private datePickerSubscription = new Subscription();
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  button_loading = false;
  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;

  messages$ = this.marketingReportDataService.messages$;

  @ViewChild('registrationKeyInput') registrationKeyInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  default_sort_by = 'campaign_name';
  default_sort_order = 'asc';

  sortingConfig = {
    'campaign_name': 'asc',
    'total_registrations_count': 'desc',
    'total_clicks_count': 'desc',
    'registration_w_deposit_count': 'desc',
    'total_ad_cost_local': 'desc',
    'cost_per_registration_local': 'desc',
    'cost_per_unique_deposit_local': 'desc',
    'deposit_total_unique_count': 'desc',
    'deposit_total_count': 'desc',
    'deposit_total_amount': 'desc',
    'withdraw_total_unique_count': 'desc',
    'withdraw_total_count': 'desc',
    'withdraw_total_amount': 'desc',
    'total_bonus': 'desc',
    'total_bonus_cancelled': 'desc',
    'total_ngr': 'desc'
  };
  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order,
  };
  access$ = this.authHttpService.getUserAccess(5, 'Marketing Report');

  // permissions
  canViewMarketingReportDetails: boolean;
  canExportMarketingReport: boolean;

  constructor(
    public dialog: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private marketingReportDataService: MarketingReportDataService,
    private marketingReportEntityService: MarketingReportEntityService,
    private loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.ranges = this.transactionHttpService.ranges;
    this.timezoneSelectedItems = [this.dropdown.timezones.find(x => x.timezone == this.timezone)];
    this.selectedTimezone = this.timezone;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMarketingReportDetails = appPermissions.view_marketing_report_details;
      this.canExportMarketingReport = appPermissions.export_marketing_report;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.marketingReport$ = this.marketingReportEntityService.getWithQuery(`&page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.marketingReportDataService.pagination;
        this.summary$ = this.marketingReportDataService.summary;
        this.loadingBar.complete();
      }),
      catchError((err) => {
        this.loadingBar.complete();
        return throwError(err);
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    let default_currency_id: any = null;
    if (this.dropdown.currencies.length > 0) {
      default_currency_id = Object.values(this.dropdown.currencies)[0]['id'];
    }

    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.form.patchValue({
      currency_id: default_currency_id,
      start_date_time: this.from,
      end_date_time: this.to,
      timezone: this.timezone,
      defaultDate: {
        startDate: this.from,
        endDate: this.to
      }
    });
    this.timezoneSelectedItems = [this.dropdown.timezones.find(x => x.timezone == this.timezone)];
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['currency_id'] === undefined || data['currency_id'] === null || data['currency_id'] === 0) {
          if (this.dropdown.currencies.length > 0) {
            data['currency_id'] = Object.values(this.dropdown.currencies)[0]['id'];
            this.form.patchValue({currency_id : data['currency_id']});
          }
        }
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.loadingBar.start();
        this.sortingConfig[this.sortingSelection.sort_by] = 'desc';
        this.sortingSelection.sort_by = this.default_sort_by;
        this.sortingSelection.sort_order = this.default_sort_order;
        this.sortingConfig[this.default_sort_by] = this.default_sort_order;
        return this.marketingReport$ = this.marketingReportEntityService.getWithQuery(`&${this.params}&perPage=${this.pageSize}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.page = 1;
            this.dataLength = res.length;
            this.summary$ = this.marketingReportDataService.summary;
            this.pagination = this.marketingReportDataService.pagination;
            this.loadingBar.complete();
            this.onSearch = true;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
          }),
          catchError((err) => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.loadingBar.complete();
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  onExport() {
    this.button_loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['currency_id'] === undefined || data['currency_id'] === null || data['currency_id'] === 0) {
          if (this.dropdown.currencies.length > 0) {
            data['currency_id'] = Object.values(this.dropdown.currencies)[0]['id'];
            this.form.patchValue({currency_id : data['currency_id']});
          }
        }
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        delete (data['timezone']);

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start(); var filename = 'MarketingReport_' + moment(this.form.get('start_date_time').value).format('YYYYMMDD') + '-' + moment(this.form.get('end_date_time').value).format('YYYYMMDD') + '.xlsx';
        return this.marketingReportDataService.exportReport(`?${parameters}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.button_loading = false;
          }),
          catchError((err) => {
            this.button_loading = false;
            this.loadingBar.complete();
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  sanitizeNumericField(previousValue: any) {
    const value = String(previousValue).replace(/[,]+/, '');
    return value;
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onOpenDialog(memberReportRow: any) {
    if (!this.canViewMarketingReportDetails) {
      Swal.fire({
        icon: 'error',
        title: 'Permission Error',
        text: 'You do not have permission to view marketing report details',
      });
      return;
    }

    this.openDialogBy(MarketingReportDetailsComponent, {
      marketingReport: memberReportRow, form: this.form.value
    });
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private openDialogBy(componentRef: any, data?: { marketingReport?: any, form?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800',
      data: {
        marketingReport: data.marketingReport,
        form: data.form
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && key !== 'key' && key !== 'key_search') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      currency_id: new FormControl(null), // Default
      start_date_time: new FormControl(this.from),
      end_date_time: new FormControl(this.to),
      timezone: new FormControl(this.timezone),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      }), // Do not remove: For Clearing The Range
    });
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }
}
