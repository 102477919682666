// Angular or Core 
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
// Service
import { LiveChatDataService } from "./services/live-chat-data.service";
import { LoadingBarService } from "@ngx-loading-bar/core";
// Third Party
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from '@env/environment';
// Component
@Component({
  selector: "kt-live-chat",
  templateUrl: "./live-chat.component.html",
  styleUrls: ["./live-chat.component.scss"],
})
export class LiveChatComponent implements OnInit {
  @ViewChild("iframe") iframe: ElementRef;
  liveChatBaseUrl: string = environment.liveChatUrl; //Live chat url
  loading: boolean = true;
  safeUrl: any = null;
  lc_token: string = null;
  lc_username: string = null;
  lc_id: string = null;
  private subscription = new Subscription();

  constructor(
    public sanitizer: DomSanitizer,
    private loadingBar: LoadingBarService,
    private liveChatDataService: LiveChatDataService,
  ) {
    this.sanitizer = sanitizer;
  }

  ngOnInit() {
    this.loadingBar.start();
    //if has token in local storage, retrive from local storage
    if (localStorage.getItem("lc_token") != null) {
      let lc_token = localStorage.getItem("lc_token");
      let lc_username = localStorage.getItem("lc_username");
      let lc_id = localStorage.getItem("lc_id");

      this.lc_token = lc_token;
      this.lc_username = lc_username;
      this.lc_id = lc_id;

      this.safeUrl = this.liveChatBaseUrl + `?lc_token=${this.lc_token}&lc_username=${this.lc_username}&lc_id=${this.lc_id}&lc_usertype=operator`;
      this.loading = false;
      this.loadingBar.complete();

    }
    else {
      // retrieve the token from api
      const livechat = this.liveChatDataService.getLiveChatToken();
      this.subscription = livechat
        .pipe(
          tap((res) => {
            localStorage.setItem("lc_token", res.token);
            localStorage.setItem("lc_username", res.username);
            localStorage.setItem("lc_id", res.id.toString());
          })
        )
        .subscribe((res) => {
          this.lc_token = localStorage.getItem("lc_token");
          this.lc_username = localStorage.getItem("lc_username");
          this.lc_id = localStorage.getItem("lc_id");

          this.safeUrl = this.liveChatBaseUrl + `?lc_token=${this.lc_token}&lc_username=${this.lc_username}&lc_id=${this.lc_id}&lc_usertype=operator`;

          this.loading = false;
          this.loadingBar.complete();
        });
    }
  }
}
