import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class ContactHttpService {

  constructor(private http: HttpClient) { }

  getContactTypes(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/contact/contactTypes`).pipe(
      map(res => res.data.contact_types)
    );
  }

  getPlatforms(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/contact/platforms`).pipe(
      map(res => res.data.contact_platforms)
    );
  }

  getActivePlatforms(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/contact/active-platforms`).pipe(
      map(res => res.data.contact_platforms)
    );
  }

  getContact(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/contactdropdown`).pipe(
      map(res => res.data.rows)
    )
  }

}

