import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Promotion } from '@core/models/promotion.model';

@Injectable()
export class PromotionCodeEntityService extends EntityCollectionServiceBase<Promotion> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('Promotion', serviceElementsFactory);
  }
}
