<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <!-- Filfer -->
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <div class="col-12 row mb-1 pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Currency:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="currency_id">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <ng-container *ngFor="let value of dropdown.currencies">
                      <option [value]="value.id">{{ value.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="col-12 row mb-1 pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date/Time:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Timezone' }}:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <kt-dropdown-wo-lazyload
                      #timezone
                      (click)="onChangeTimezone(timezone.selectedItems)"
                      [form] = 'form'
                      [dropdownList] = 'dropdown.timezones'
                      [dropdownSettings] = 'timezoneDropdownSettings'
                      [formName] = "'timezone'"
                      [selectionAttributes] = "'timezone'"
                      [selectedItems] = 'timezoneSelectedItems'>
                  </kt-dropdown-wo-lazyload>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Action Button -->
      <div class="kt-section col-12 row d-flex justify-content-between mb-3">
        <div>
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <button *ngIf="canExportMarketingReport" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="button_loading"><i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
      </div>

      <!-- Summary -->
      <ng-container *ngIf="summary$ as summary">
        <h5 class="summary">Summary</h5>
        <div class="table-responsive summary-table">
          <table class="table table-bordered table-hover table-striped">
            <!-- Header -->
            <thead>
              <tr class="text-top">
                <th rowspan="2" class="text-center">Currency</th>
                <th colspan="3" class="text-center">Members</th>
                <th colspan="6" class="text-center">Deposit/Withdrawal</th>
                <th colspan="4" class="text-center">Bets</th>
              </tr>
              <tr>
                <th class="text-center">New <br />Registrations</th>
                <th class="text-center">Clicks</th>
                <th class="text-center">Registrations <br />w/ Deposits</th>
                <th class="text-center">Unique <br />Deposits</th>
                <th class="text-center">FTD <br />Count</th>
                <th class="text-center">FTD <br />Amount</th>
                <th class="text-center">Total <br />Deposit Count</th>
                <th class="text-center">Total <br />Deposit Amount</th>
                <th class="text-center">Unique <br />Withdrawals</th>
                <th class="text-center">Total <br />Withdrawal Count</th>
                <th class="text-center">Total <br />Withdrawal Amount</th>
                <th class="text-center">Total <br />Bonus</th>
                <th class="text-center">Total <br />Bonus Cancelled</th>
                <th class="text-center">Total <br />NGR</th>
              </tr>
            </thead>
            <!-- Body -->
            <tbody>
              <tr>
                <td class="text-center">{{ summary.currency_code }}</td>
                <td class="text-right">{{ sanitizeNumericField(summary.total_registrations_count) | number : '1.0-0' }}</td>
                <td class="text-right">{{ sanitizeNumericField(summary.total_clicks_count)| number : '1.0-0' }}</td>
                <td class="text-right">{{ sanitizeNumericField(summary.registration_w_deposit_count) | number : '1.0-0'}}</td>
                <td class="text-right">{{ sanitizeNumericField(summary.deposit_total_unique_count) | number : '1.0-0'}}</td>
                <td class="text-right">{{ sanitizeNumericField(summary.ftd_count) | number : '1.0-0' }}</td>
                <td class="text-right">{{ sanitizeNumericField(summary.ftd_amount) | number : '1.2-2' }}</td>
                <td class="text-right">{{ sanitizeNumericField(summary.deposit_total_count) | number : '1.0-0' }}</td>
                <td class="text-right">{{ sanitizeNumericField(summary.deposit_total_amount) | number : '1.2-2' }}</td>
                <td class="text-right">{{ sanitizeNumericField(summary.withdraw_total_unique_count) | number : '1.0-0'}}</td>
                <td class="text-right">{{ sanitizeNumericField(summary.withdraw_total_count) | number : '1.0-0' }}</td>
                <td class="text-right">{{ sanitizeNumericField(summary.withdraw_total_amount) | number : '1.2-2' }}</td>
                <td class="text-right">{{ sanitizeNumericField(summary.total_bonus) | number : '1.2-2' }}</td>
                <td class="text-right">{{ sanitizeNumericField(summary.total_cancelled_bonus) | number : '1.2-2' }}</td>
                <td class="text-right">{{ sanitizeNumericField(summary.total_ngr) | number : '1.2-2' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

      <!-- Content -->
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-striped table-sortable">
          <!-- Header -->
          <thead>
            <tr class="text-top">
              <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('campaign_name')" [ngClass]="sortingConfig.campaign_name" [class.sort-selected]="sortingSelection.sort_by === 'campaign_name'">Campaign</th>
              <th rowspan="2" class="text-center">Currency</th>
              <th colspan="6" class="text-center">Members</th>
              <th colspan="6" class="text-center">Deposit/Withdrawal</th>
              <th colspan="6" class="text-center">Bets</th>
            </tr>
            <tr>
              <th class="text-center sort-enabled" (click)="onSortColumn('total_registrations_count')" [ngClass]="sortingConfig.total_registrations_count" [class.sort-selected]="sortingSelection.sort_by === 'total_registrations_count'">New <br />Registrations</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('total_clicks_count')" [ngClass]="sortingConfig.total_clicks_count" [class.sort-selected]="sortingSelection.sort_by === 'total_clicks_count'">Clicks</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('registration_w_deposit_count')" [ngClass]="sortingConfig.registration_w_deposit_count" [class.sort-selected]="sortingSelection.sort_by === 'registration_w_deposit_count'">Registrations <br />w/ Deposit</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('total_ad_cost_local')" [ngClass]="sortingConfig.total_ad_cost_local" [class.sort-selected]="sortingSelection.sort_by === 'total_ad_cost_local'">Ad Cost</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('cost_per_registration_local')" [ngClass]="sortingConfig.cost_per_registration_local" [class.sort-selected]="sortingSelection.sort_by === 'cost_per_registration_local'">Cost Per <br />Registration</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('cost_per_unique_deposit_local')" [ngClass]="sortingConfig.cost_per_unique_deposit_local" [class.sort-selected]="sortingSelection.sort_by === 'cost_per_unique_deposit_local'">Cost Per <br />Unique Deposit</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('deposit_total_unique_count')" [ngClass]="sortingConfig.deposit_total_unique_count" [class.sort-selected]="sortingSelection.sort_by === 'deposit_total_unique_count'">Unique <br />Deposits</th>
              <th class="text-center sort-enabled">FTD <br />Count</th>
              <th class="text-center sort-enabled">FTD <br />Amount</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('deposit_total_count')" [ngClass]="sortingConfig.deposit_total_count" [class.sort-selected]="sortingSelection.sort_by === 'deposit_total_count'">Total <br />Deposit Count</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('deposit_total_amount')" [ngClass]="sortingConfig.deposit_total_amount" [class.sort-selected]="sortingSelection.sort_by === 'deposit_total_amount'">Total <br />Deposit Amount</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('withdraw_total_unique_count')" [ngClass]="sortingConfig.withdraw_total_unique_count" [class.sort-selected]="sortingSelection.sort_by === 'withdraw_total_unique_count'">Unique <br />Withdrawals</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('withdraw_total_count')" [ngClass]="sortingConfig.withdraw_total_count" [class.sort-selected]="sortingSelection.sort_by === 'withdraw_total_count'">Total <br />Withdrawal Count</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('withdraw_total_amount')" [ngClass]="sortingConfig.withdraw_total_amount" [class.sort-selected]="sortingSelection.sort_by === 'withdraw_total_amount'">Total <br />Withdrawal Amount</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('total_bonus')" [ngClass]="sortingConfig.total_bonus" [class.sort-selected]="sortingSelection.sort_by === 'total_bonus'">Total <br />Bonus</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('total_bonus_cancelled')" [ngClass]="sortingConfig.total_bonus_cancelled" [class.sort-selected]="sortingSelection.sort_by === 'total_bonus_cancelled'">Total <br />Bonus Cancelled</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('total_ngr')" [ngClass]="sortingConfig.total_ngr" [class.sort-selected]="sortingSelection.sort_by === 'total_ngr'">Total <br />NGR</th>
            </tr>
          </thead>
          <!-- Body -->
          <tbody *ngIf="(marketingReport$ | async) as rows">
            <tr *ngFor="let row of rows">
              <td>{{row.campaign_name}} <a class="text-primary" (click)="onOpenDialog(row,'marketing-report-details')">({{row.campaign_code}})</a></td>
              <td class="text-center">{{ row.currency_code }}</td>
              <td class="text-right">{{ row.total_registrations_count | number : '1.0-0'}}</td>
              <td class="text-right">{{ row.total_clicks_count| number : '1.0-0' }}</td>
              <td class="text-right">{{ row.registration_w_deposit_count | number : '1.0-0'}}</td>
              <td class="text-right no-break">{{ row.currency_code + ' ' + (sanitizeNumericField(row.total_ad_cost_local) | number : '1.2-2') }} <br> {{ 'USD ' + (sanitizeNumericField(row.total_ad_cost_usd) | number : '1.2-2') }}</td>
              <td class="text-right">{{ row.currency_code + ' ' + (sanitizeNumericField(row.cost_per_registration_local) | number : '1.2-2') }} <br> {{ 'USD ' + (sanitizeNumericField(row.cost_per_registration_usd) | number : '1.2-2') }}</td>
              <td class="text-right no-break">{{ row.currency_code + ' ' + (sanitizeNumericField(row.cost_per_unique_deposit_local) | number : '1.2-2') }} <br> {{ 'USD ' + (sanitizeNumericField(row.cost_per_unique_deposit_usd) | number : '1.2-2') }}</td>
              <td class="text-right">{{ row.deposit_total_unique_count | number : '1.0-0'}}</td>
              <td class="text-right">{{ row.ftd_count | number : '1.0-0' }}</td>
              <td class="text-right">{{ sanitizeNumericField(row.ftd_amount) | number : '1.2-2' }}</td>
              <td class="text-right">{{ sanitizeNumericField(row.deposit_total_count) | number : '1.0-0'  }}</td>
              <td class="text-right">{{ sanitizeNumericField(row.deposit_total_amount) | number : '1.2-2' }}</td>
              <td class="text-right">{{ row.withdraw_total_unique_count | number : '1.0-0' }}</td>
              <td class="text-right">{{ sanitizeNumericField(row.withdraw_total_count) | number : '1.0-0' }}</td>
              <td class="text-right">{{ sanitizeNumericField(row.withdraw_total_amount) | number : '1.2-2'}}</td>
              <td class="text-right">{{ sanitizeNumericField(row.total_bonus) | number : '1.2-2' }}</td>
              <td class="text-right">{{ sanitizeNumericField(row.total_cancelled_bonus) | number : '1.2-2' }}</td>
              <td class="text-right">{{ sanitizeNumericField(row.total_ngr) | number : '1.2-2' }}</td>
            </tr>
          </tbody>
          <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
        </table>
      </div>

      <!-- Pagination -->
      <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
        </ngb-pagination>
        <div class="kt-pagination__toolbar">
          <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
          </select>
          <span class="pagination__desc">
            Showing {{pagination.from}} to {{pagination.to}} of
            {{ pagination.total | number : '1.0' }} records
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
