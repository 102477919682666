
<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> {{ data.mode === 'edit' ? 'Edit' : 'Create' }} API Key</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Application <span class="text-danger">*</span></label>
          <input type="text" formControlName="application" class="col-8 form-control">
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Key</label>
          <h4
              (click)="tooltip.show();"
              [cdkCopyToClipboard]="key"
              (mouseleave) = "tooltip.hide(2000)"
              style="margin-right: 10px; margin-top: 5px;">
              <i class="fa fa-paste"
                  mat-raised-button #tooltip="matTooltip"
                  (mouseenter)="$event.stopImmediatePropagation()"
                  (mouseleave)="$event.stopImmediatePropagation()"
                  matTooltip="Copied!"
                  matTooltipPosition="above"></i>
          </h4>
          <button class="ml-2 btn btn-success" (click)="generateKey()"><i class="fas fa-key"></i>Regenerate</button>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Expiry Date</label>
          <div class="col-8 pl-0 pr-0">
            <div class="input-group date">
              <input class="hidden" [owlDateTime]="dateStartRef">
              <input type="text" [owlDateTimeTrigger]="dateStartRef"  formControlName="expiry_date" placeholder="Search" class="form-control">
              <owl-date-time #dateStartRef></owl-date-time>
              <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
            </div>
          </div>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Status <span class="text-danger">*</span></label>
          <select formControlName="status" class="col-8 form-control">
            <option value=1> Active </option>
            <option value=0> Inactive </option>
          </select>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Permissions</label>
          <div class="col-6 row">
            <div class="col-6 form-group" *ngFor="let item of permissions | keyvalue">
              <h5 class="modal-title"> {{ item.value.name }}</h5>
              <ul style="list-style-type: circle;">
                <li>
                  <label><input type="checkbox" [(ngModel)]="item.value.read" [ngModelOptions]="{standalone: true}"> Read</label>
                </li>
                <li>
                  <label><input type="checkbox" [(ngModel)]="item.value.write" [ngModelOptions]="{standalone: true}"> Write</label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button *ngIf="data.mode === 'edit' ? canEditApiKey : canCreateApiKey" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()" #focusfield></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
