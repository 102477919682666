import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Sites } from '@core/models/sites.model';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Dropdown } from '@core/models/dropdown.model';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { LiveChat } from '@core/models/live-chat.model';

@Injectable()
export class LiveChatScriptDataService extends DefaultDataService<LiveChat>{

    pagination: Pagination;
    messages$ = new Subject<any[]>();

    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
        super('LiveChat', http, httpUrlGenerator, { root: '' });
    }

    getWithQuery(pageParam: string): Observable<LiveChat[]> {
        return this.http.get<ApiResponse>(`/livechat${pageParam}`).pipe(
          tap(res => this.paginationInit(res)),
          map(res => {
            Object.keys(res.data.rows).forEach(x => {
              let localeList = [];
    
              Object.keys(res.data.rows[x].locales).forEach(y => {
                localeList.push(res.data.rows[x].locales[y].locale_name);
              })
              res.data.rows[x].locale_list = localeList.toString().split(',').join(', ');
            })
            return res.data.rows
          }
        ));
      }
    
    addLiveChat(param: any) {
        return this.http.post<ApiResponse>(`/livechat`, param).pipe(
            tap(res => this.messages$.next(res.message)),
        );
    }

    updateLiveChat(id: number, param: any) {
        return this.http.put<ApiResponse>(`/livechat/${id}`, param).pipe(
            tap(res => this.messages$.next(res.message)),
        );
    }

    updateStatus(param: any) {
        return this.http.put<ApiResponse>(`/livechat/statusupdate`, param).pipe(
            tap(res => this.messages$.next(res.message)),
        );
    }

    // getWithQuery(): Observable<Sites[]> {
    //     return this.httpClient.get<ApiResponse>(`${this.apiBase}/member/livechat/getscript`).pipe(
    //         map(res => res.data.rows)
    //     );
    // }

    storeScript(data: Sites) {
      return this.http.post<ApiResponse>(`/livechat/storescript/`, data).pipe(
            tap(res => this.messages$.next(res.message)),
        );
    }

    private paginationInit(res: any) {
        if(res) {
            this.pagination = res.data.paginations;
        }
    }
}