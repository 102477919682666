import { BetLimit } from '@core/models/bet-limit.model';
import { WithdrawLimit } from '@core/models/withdraw-limit.model';
import { DepositLimit } from '@core/models/deposit-limit.model';
import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { Group } from '@core/models/group.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { MerchantBank } from '@core/models/merchant-bank.model';
import { MemberGroupSelectAccount } from '@core/models/member-group-select-account.model';
import { ReferralSetting } from '@core/models/referral-setting.model';

@Injectable()
export class ReferralSettingsDataService extends DefaultDataService<ReferralSetting>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  id: any;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('ReferralSetting', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<ReferralSetting[]> {
    return this.http.get<ApiResponse>('/referralsetting').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<ReferralSetting[]>{
    return this.http.get<ApiResponse>(`/referralsetting${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(referralSetting: ReferralSetting): Observable<ReferralSetting>{
    return this.http.post<ApiResponse>(`/referralsetting`, referralSetting).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    )
  }

  updateReferral(id: number, referralSetting: ReferralSetting) {
    return this.http.put<ApiResponse>(`/referralsetting/${id}`, referralSetting).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
