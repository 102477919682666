<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Adjust Group</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="col-md-12 form-group row">
                    <label class="col-4 col-form-label">Username</label>
                    <label class="col-8 col-form-label p-l-0"> {{ data.affiliate.username }}</label>
                </div>
                <div class="col-md-12 form-group row">
                    <label class="col-4 col-form-label">Group<span class="text-danger">*</span></label>
                    <select formControlName="affiliate_group_id" class="col-8 form-control">
                        <option [value]="null" [disabled]="true">Please Select</option>
                        <option *ngFor="let value of dropdown.groups | async" [value]="value.id">{{ value.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSubmit()"></kt-submit-button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true)"></kt-swal-alert>