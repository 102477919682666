import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { DailyNewDepositReport } from '@core/models/daily-new-deposit-report.model';

@Injectable()
export class DailyNewDepositReportEntityService extends EntityCollectionServiceBase<DailyNewDepositReport> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('DailyNewDepositReport', serviceElementsFactory);
  }
}
