import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AllEvents } from '@core/models/all-events.model';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';

@Injectable()
export class AllEventsDataService extends DefaultDataService<AllEvents>  {

    pagination: Pagination;
    messages$ = new Subject<any[]>();

    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
        super('AllEvents', http, httpUrlGenerator, { root: '' });
    }

    getAll(): Observable<AllEvents[]> {
        return this.http.get<ApiResponse>('/event').pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    getWithQuery(pageParam: string): Observable<AllEvents[]> {
        return this.http.get<ApiResponse>(`/event${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    getPredictionTypes() {
        return this.http.get<ApiResponse>(`/eventpredictiontypes`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    getGroupTypes() {
        return this.http.get<ApiResponse>(`/eventgrouptypes`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    addEvent(data: any): Observable<AllEvents> {
        return this.http.post<ApiResponse>(`/event`, data).pipe(
            tap(res => this.messages$.next(res.message)),
            map(res => res.data.rows)
        )
    }

    updateEvent(id: number, data: any) {
        return this.http.put<ApiResponse>(`/event/${id}`, data).pipe(
            tap(res => this.messages$.next(res.message))
        )
    }

    showResult(id: number) {
        return this.http.get<ApiResponse>(`/event/showresult/${id}?paginate=false`).pipe(
            map(res => res.data.rows)
        );
    }

    setResult(data: any): Observable<any> {
        return this.http.post<ApiResponse>(`/event/setwinners`, data).pipe(
            tap(res => this.messages$.next(res.message))
        )
    }

    getTeams(pageParam: string) {
        return this.http.get<ApiResponse>(`/event/teams${pageParam}`).pipe(
            tap(res => this.paginationInit(res)),
            map(res => res.data.rows)
        );
    }

    addTeam(data: any): Observable<AllEvents> {
        return this.http.post<ApiResponse>(`/event/teams`, data).pipe(
            tap(res => this.messages$.next(res.message)),
            map(res => res.data.rows)
        )
    }

    updateTeam(teamId: number, data: any) {
        return this.http.put<ApiResponse>(`/event/teams/${teamId}`, data).pipe(
            tap(res => this.messages$.next(res.message))
        )
    }

    deleteTeam(teamId: number) {
        return this.http.delete<ApiResponse>(`/event/teams/${teamId}`).pipe(
            map((row: ApiResponse) => row.message)
        )
    }

    private paginationInit(res: any) {
        if (res) {
            this.pagination = res.data.paginations;
        }
    }
}
