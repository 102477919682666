import { RoleEntityService } from '@views/pages/apps/superuser/roles/services/role-entity.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, tap, take } from 'rxjs/operators';

@Injectable()
export class RoleResolver implements Resolve<boolean> {

    constructor(private roleEntityService: RoleEntityService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.roleEntityService.loaded$.pipe(
          tap(loaded =>  {
            if(!loaded) {
              this.roleEntityService.getAll().pipe(
                take(1)
              );
            }
          }),
          filter(loaded => !!loaded),
          first()
        );
    }
}
