import { AllAffiliateReportEntityService } from './all-affiliate-report/services/all-affiliate-reports-data.service';
import { AllAffiliateReportDataService } from './all-affiliate-report/services/all-affiliate-reports-entity.service';
import { AllSubAffiliateReportEntityService } from './all-sub-affiliate-report/services/all-sub-affiliate-report-entity.service';
import { AllSubAffiliateReportDataService } from './all-sub-affiliate-report/services/all-sub-affiliate-report-data.service';
import { AffiliateFirstDepositorReportEntityService } from './affiliate-first-depositor-report/services/affiliate-first-depositor-report-entity.service';
import { AffiliateFirstDepositorReportDataService } from './affiliate-first-depositor-report/services/affiliate-first-depositor-report-data.service';
import { GameResultComponent } from './member-report/dialogs/game-result/game-result.component';
import { DailyNewDepositReportDataService } from './daily-new-deposit-report/services/daily-new-deposit-report-data.service';
import { DailyNewDepositReportEntityService } from './daily-new-deposit-report/services/daily-new-deposit-report-entity.service';
import { GameProviderReportEntityService } from './game-provider-report/services/game-provider-report-entity.service';
import { GameProviderReportDataService } from './game-provider-report/services/game-provider-report-data.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { entityMetadata } from './../../../../app.module';
import { EntityDefinitionService, EntityDataService } from '@ngrx/data';
import { GameProviderDailyReportResolver } from '@core/resolvers/game-provider-daily-report.resolver';
import { GameProviderDailyReportDataService } from './game-provider-report/services/game-provider-daily-report-data.service';
import { GameProviderDailyReportEntityService } from './game-provider-report/services/game-provider-daily-report-entity.service';
import { NgModule } from '@angular/core';
import { AllMemberReportsComponent } from './all-member-reports/all-member-reports.component';
import { ReportDetailsComponent } from './all-member-reports/dialogs/report-details/report-details.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportsRoutingModule } from './reports-routing.module';
import { CommonModule, DecimalPipe } from '@angular/common';
import { GameProviderReportDetailsComponent } from './game-provider-report/dialogs/game-provider-report-details.component';
import { SharedModule } from '@shared/shared.module';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { GameProviderReportComponent } from './game-provider-report/game-provider-report.component';
import { MemberReportComponent } from './member-report/member-report.component';
import { MemberReportDetailsDialogComponent } from './member-report/dialogs/member-report-details.component';
import { TransactionReportDataService } from './member-report/dialogs/services/transaction-report-data.service';
import { TransactionReportEntityService } from './member-report/dialogs/services/transaction-report-entity.service';
import { MemberReportEntityService } from './member-report/services/member-report-entity.service';
import { MemberReportDataService } from './member-report/services/member-report-data.service';
import { AllMemberReportDataService } from './all-member-reports/services/all-member-report-data.service';
import { AllMemberReportEntityService } from './all-member-reports/services/all-member-report-entity.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { StatisticsReportComponent } from './statistics-report/statistics-report.component';
import { MarketingReportDataService } from './marketing-report/services/marketing-report-data.service';
import { MarketingReportEntityService } from './marketing-report/services/marketing-report-entity.service';
import { MarketingReportDetailsComponent } from './marketing-report/dialogs/report-details/marketing-report-details.component';
import { MarketingReportComponent } from './marketing-report/marketing-report.component';
import { AgentReportsComponent } from './agent-report/agent-reports.component';
import { AllAgentReportComponent } from './all-agent-report/all-agent-report.component';
import { AllAgentReportDataService } from './all-agent-report/services/all-agent-report-data.service';
import { AllAgentReportEntityService } from './all-agent-report/services/all-agent--report-entity.service';
import { AgentReportDataService } from './agent-report/services/agent-report-data.service';
import { AgentReportEntityService } from './agent-report/services/agent-report-entity.service';
import { AllAgentReportsDialogComponent } from './all-agent-report/dialogs/report-details/all-agent-reports-dialog.component';
import { SMSRecoveryReportComponent } from './sms-recovery-report/sms-recovery-report.component';
import { SMSRecoveryReportDataService } from './sms-recovery-report/services/sms-recovery-report-data.service';
import { SMSRecoveryReportEntityService } from './sms-recovery-report/services/sms-recovery-report-entity.service';
import { SMSRecoveryReportDetailsComponent } from './sms-recovery-report/dialogs/sms-recovery-details/report-details.component';
import { SMSRecoveryReportDetailEntityService } from './sms-recovery-report/dialogs/sms-recovery-details/services/sms-recovery-report-detail-entity.service';
import { SMSRecoveryReportDetailDataService } from './sms-recovery-report/dialogs/sms-recovery-details/services/sms-recovery-report-detail-data.service';
import { DailyNewDepositReportComponent } from './daily-new-deposit-report/daily-new-deposit-report.component';
import { TicketInfoDialogComponent } from './member-report/dialogs/ticket-info/ticket-info.component';
import { MaterialModule } from '@views/pages/material/material.module';
import { CompanyReportComponent } from './company-report/company-report.component';
import { CompanyReportEntityService } from './company-report/services/company-report-entity.service';
import { CompanyReportDataService } from './company-report/services/company-report-data.service';
import { RegistrationReportComponent } from './registration-report/registration-report.component';
import { RegistrationReportDataService } from './registration-report/services/registration-report-data.service';
import { RegistrationReportEntityService } from './registration-report/services/registration-report-entity.service';
import { SafePipe } from '@core/_base/layout';
import { CoreModule } from '@core/core.module';
import { MarketingWeeklyReportComponent } from './marketing-weekly-report/marketing-weekly-report.component';
import { MarketingWeeklyReportEntityService } from './marketing-weekly-report/services/marketing-weekly-report-entity.service';
import { MarketingWeeklyReportDataService } from './marketing-weekly-report/services/marketing-weekly-report-data.service';
import { GameTypeReportComponent } from './gametype-report/gametype-report.component';
import { GameTypeReportDataService } from './gametype-report/services/gametype-report-data.service';
import { GameTypeReportEntityService } from './gametype-report/services/gametype-report-entity.service';
import { TelemarketerReportEntityService } from './telemarketer-report/services/telemarketer-report-entity.service';
import { TelemarketerReportDataService } from './telemarketer-report/services/telemarketer-report-data.service';
import { TelemarketerReportComponent } from './telemarketer-report/telemarketer-report.component';
import { ParlayInfoDialogComponent } from './member-report/dialogs/parlay-info/parlay-info.component';
import { AllAffiliateReportComponent } from './all-affiliate-report/all-affiliate-report.component';
import { AllSubAffiliateReportComponent } from './all-sub-affiliate-report/all-sub-affiliate-report.component';
import { AffiliateFirstDepositorReportComponent } from './affiliate-first-depositor-report/affiliate-first-depositor-report.component';
import { SalesConversionReportComponent } from './sales-conversion-report/sales-conversion-report.component';
import { SalesConversionReportDataService } from './sales-conversion-report/services/sales-conversion-report-data.service';
import { SalesConversionReportEntityService } from './sales-conversion-report/services/sales-conversion-report-entity.service';
import { SalesConversionReportDetailsDialogComponent } from './sales-conversion-report/sales-conversion-report-details/sales-conversion-report-details.component';
import { TelemarketingPlayerSummaryReportEntityService } from './telemarketing-player-summary-report/services/telemarketing-player-summary-report-entity.service';
import { TelemarketingPlayerSummaryReportDataService } from './telemarketing-player-summary-report/services/telemarketing-player-summary-report-data.service';
import { TelemarketingPlayerSummaryReportComponent } from './telemarketing-player-summary-report/telemarketing-player-summary-report.component';

@NgModule({
  declarations: [
    AllMemberReportsComponent,
    ReportDetailsComponent,
    GameProviderReportDetailsComponent,
    GameProviderReportComponent,
    MemberReportComponent,
    MemberReportDetailsDialogComponent,
    GameResultComponent,
    GameProviderReportDetailsComponent,
    StatisticsReportComponent,
    MarketingReportComponent,
    MarketingReportDetailsComponent,
    AgentReportsComponent,
    AllAgentReportComponent,
    AllAgentReportsDialogComponent,
    SMSRecoveryReportComponent,
    MarketingWeeklyReportComponent,
    SMSRecoveryReportDetailsComponent,
    DailyNewDepositReportComponent,
    TicketInfoDialogComponent,
    CompanyReportComponent,
    RegistrationReportComponent,
    GameTypeReportComponent,
    TelemarketerReportComponent,
    ParlayInfoDialogComponent,
    AllAffiliateReportComponent,
    AllSubAffiliateReportComponent,
    AffiliateFirstDepositorReportComponent,
    SalesConversionReportComponent,
    SalesConversionReportDetailsDialogComponent,
    TelemarketingPlayerSummaryReportComponent
  ],
  imports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AngularMultiSelectModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ReportsRoutingModule,
    SharedModule,
    NgbModule,
    NgSelectModule,
    SharedModule,
    MaterialModule,
    NgxDaterangepickerMd.forRoot(),
    CoreModule,
  ],
  providers: [
    DecimalPipe,
    GameProviderDailyReportEntityService,
    GameProviderDailyReportDataService,
    GameProviderDailyReportResolver,
    GameProviderReportDataService,
    GameProviderReportEntityService,
    MemberReportEntityService,
    MemberReportDataService,
    TelemarketerReportEntityService,
    TelemarketerReportDataService,
    ReportDetailsComponent,
    AllMemberReportDataService,
    AllMemberReportEntityService,
    MarketingReportDataService,
    MarketingReportEntityService,
    AllAgentReportDataService,
    AllAgentReportEntityService,
    AgentReportDataService,
    AgentReportEntityService,
    SMSRecoveryReportEntityService,
    SMSRecoveryReportDataService,
    SMSRecoveryReportDetailEntityService,
    SMSRecoveryReportDetailDataService,
    DailyNewDepositReportEntityService,
    DailyNewDepositReportDataService,
    CompanyReportDataService,
    CompanyReportEntityService,
    RegistrationReportDataService,
    RegistrationReportEntityService,
    MarketingWeeklyReportEntityService,
    MarketingWeeklyReportDataService,
    GameTypeReportEntityService,
    GameTypeReportDataService,
    TransactionReportEntityService,
    TransactionReportDataService,
    AllAffiliateReportEntityService,
    AllAffiliateReportDataService,
    AllSubAffiliateReportDataService,
    AllSubAffiliateReportEntityService,
    AffiliateFirstDepositorReportDataService,
    AffiliateFirstDepositorReportEntityService,
    SalesConversionReportDataService,
    SalesConversionReportEntityService,
    TelemarketingPlayerSummaryReportDataService,
    TelemarketingPlayerSummaryReportEntityService
  ]
})
export class ReportsModule {
  constructor(
    private eds: EntityDefinitionService,
    private entityDataService: EntityDataService,
    private gameProviderDailyReportDataService: GameProviderDailyReportDataService,
    private gameProviderReportDataService: GameProviderReportDataService,
    private memberReportDataService: MemberReportDataService,
    private transactionReportDataService: TransactionReportDataService,
    private allmemberReportDataService: AllMemberReportDataService,
    private marketingReportDataService: MarketingReportDataService,
    private allAgentReportDataService: AllAgentReportDataService,
    private allAffiliateReportDataService: AllAffiliateReportDataService,
    private agentReportDataService: AgentReportDataService,
    private smsRecoveryReportDataService: SMSRecoveryReportDataService,
    private smsRecoveryReportDetailDataService: SMSRecoveryReportDetailDataService,
    private dailyNewDepositReportDataService: DailyNewDepositReportDataService,
    private companyReportDataService: CompanyReportDataService,
    private registrationReportDataService: RegistrationReportDataService,
    private marketingWeeklyReportDataService: MarketingWeeklyReportDataService,
    private gameTypeReportDataService: GameTypeReportDataService,
    private telemarketerReportDataService: TelemarketerReportDataService,
    private allSubAffiliateReport: AllSubAffiliateReportDataService,
    private affiliateFirstDepositorReportDataService: AffiliateFirstDepositorReportDataService,
    private salesConversionReportDataService: SalesConversionReportDataService,
    private telemarketingPlayerSummaryReportDataService: TelemarketingPlayerSummaryReportDataService
  ) {
    eds.registerMetadataMap(entityMetadata);
    entityDataService.registerServices({
      GameProviderDailyReport: gameProviderDailyReportDataService,
      GameProviderReport: gameProviderReportDataService,
      MemberReport: memberReportDataService,
      TransactionReport: transactionReportDataService,
      AllMemberReport: allmemberReportDataService,
      MarketingReport: marketingReportDataService,
      AllAgentReport: allAgentReportDataService,
      AllAffiliateReport: allAffiliateReportDataService,
      AgentReport: agentReportDataService,
      SMSRecoveryReport: smsRecoveryReportDataService,
      SMSRecoveryReportDetail: smsRecoveryReportDetailDataService,
      DailyNewDepositReport: dailyNewDepositReportDataService,
      CompanyReport: companyReportDataService,
      RegistrationReportDataService: registrationReportDataService,
      WeeklyMonthlyReport: marketingWeeklyReportDataService,
      GameTypeReport: gameTypeReportDataService,
      TelemarketerReport: telemarketerReportDataService,
      AllSubAffiliateReport: allSubAffiliateReport,
      AffiliateFirstDepositorReport: affiliateFirstDepositorReportDataService,
      SalesConversionReport: salesConversionReportDataService,
      TelemarketingPlayerSummaryReport: telemarketingPlayerSummaryReportDataService
    });
  }
 }
