import { CampaignAdCostDialogComponent } from './campaign-ad-cost/dialogs/campaign-ad-cost-details.component';
import { CampaignAdCostComponent } from './campaign-ad-cost/campaign-ad-cost.component';
import { CampaignEditDialogComponent } from './campaign/dialogs/campaign-edit.component';
import { CampaignComponent } from './campaign/campaign.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CampaignAdCostReportComponent } from './campaign-ad-cost-report/campaign-ad-cost-report.component';
import { CampaignMemberListComponent } from './campaign/campaign-member-list/campaign-member-list.component';
import { CampaignReportMemberListComponent } from './campaign-ad-cost-report/campaign-report-member-list/campaign-report-member-list.component';
import { CampaignClicksDialogComponent } from "./campaign-ad-cost-report/campaign-clicks/campaign-clicks.component";

const routes: Routes = [
  {
    path: 'campaign',
    component: CampaignComponent
  },
  {
    path: 'ad-cost',
    pathMatch: 'full',
    component: CampaignAdCostComponent
  },
  {
    path: 'ad-reports',
    pathMatch: 'full',
    component: CampaignAdCostReportComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  entryComponents: [
    CampaignEditDialogComponent,
    CampaignAdCostDialogComponent,
    CampaignMemberListComponent,
    CampaignReportMemberListComponent,
    CampaignClicksDialogComponent
  ]
})
export class MarketeerRoutingModule { }
