<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div  class="kt-portlet kt-portlet--mobile">
      <div   class="kt-portlet__body">
          <div  class="kt-section">
              <div  class="kt-section__content">
                  <div  class="row col-lg-12">
                      <div  class="col-lg-5">
                          <div   class="kt-margin-b-20-tablet-and-mobile">
                              <div  class="kt-input-icon kt-input-icon&amp;#45;&amp;#45;left">
                                  <form >
                                      <div >
                                          <label> Search By Username:
                                              <input  type="text" class="form-control" style="width: 260px;">
                                          </label>
                                          <ul  id="autocomplete-results" class="autocomplete-results" style="display: none;"></ul>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                      <div  class="row col-lg-12">
                          <div  class="col-lg-3">
                              <label  for="from">From Date</label>
                              <div  class="input-group">
                                  <input  type="text" placeholder="From" id="from" name="from" class="form-control">
                                  <div  class="input-group-append"><span  class="input-group-text"><i  class="la la-calendar-check-o glyphicon-th"></i></span></div>
                              </div>
                          </div>
                          <div  class="col-lg-3">
                              <label  for="from">To Date</label>
                              <div  class="input-group">
                                  <input  type="text" placeholder="To" id="to" name="to" class="form-control">
                                  <div  class="input-group-append"><span  class="input-group-text"><i  class="la la-calendar-check-o glyphicon-th"></i></span></div>
                              </div>
                          </div>
                          <div  class="col-lg-6">
                              <label>Quick Filter</label>
                              <div  class="btn-group btn-group-sm">
                                  <button  class="btn btn-default">Today</button>
                                  <button  class="btn btn-default">Yesterday</button>
                                  <button  class="btn btn-default">This Week</button>
                                  <button  class="btn btn-default">Last Week</button>
                                  <button  class="btn btn-default">This Month</button>
                                  <button  class="btn btn-default">Last Month</button>
                                  <button  class="btn btn-default">This Year</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div  class="kt-portlet__body">
          <div  class="kt-section">
              <div  class="kt-section__content">
                  <div  class="table-responsive">
                      <table  class="table table-bordered table-hover table-striped">
                          <thead>
                              <tr>
                                  <th >User</th>
                                  <th  class="text-center">Tickets</th>
                                  <th  class="text-center">Valid Rollover</th>
                                  <th  class="text-center">Payout</th>
                                  <th  class="text-center">SSR Total</th>
                                  <th  class="text-center">Company</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td  colspan="8" class="text-center">No records available in this date range</td>
                              </tr>
                          </tbody>
                      </table>

                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
