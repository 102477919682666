import { DatePipe } from '@angular/common';
import { PromotionCodeEntityService } from './../../../general/promotion-codes/services/promotion-code-entity.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, catchError, map } from 'rxjs/operators';
import { Subscription, forkJoin } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { VipMemberVerificationDataService } from '@views/pages/apps/general/vip-verification/services/vip-member-verification-data.service';
import Swal from "sweetalert2";
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { PromotionCodeDataService } from '@views/pages/apps/general/promotion-codes/services/promotion-code-data.service';
@Component({
  selector: 'kt-vip-edit',
  templateUrl: './vip-edit.component.html',
  styleUrls: ['./vip-edit.component.scss']
})
export class VipEditDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(OwlDateTimeInputDirective) datePicker: OwlDateTimeInputDirective<any>;
  form: FormGroup;
  messages$ = this.vipMemberVerificationDataService.messages$;
  refreshStatus: boolean;
  buttonLoading = false;
  dropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'labelKey',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false
  };
  dropdown = {
    groups: [],
    //promotionCode: this.data.mode === 'approve' ? this.promotionCodeEntityService.getWithQuery(`?paginate=false&status=1&member_group_id=${this.data.vip.next_member_group_id}&currency_id=${this.data.vip.currency_id}`) : null
  };
  memberDOB = null;
  isMemberDOBPass: boolean;
  memberGroupId = null;
  nextMemberGroupId = null;
  currencyId = null;

  promotionCodeDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    maxHeight: 200, //'auto',
    primaryKey: 'id',
    labelKey: 'name',
    noDataLabel: '',
    showCheckbox: false
  };
  promotionCodeDropdownList = [];
  upgradeBonusSelectedItems = [];

  private subscription = new Subscription();
  private datePickerSubscription = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { vip: any, mode: string, editVip: any, member:any},
    private vipMemberVerificationDataService: VipMemberVerificationDataService,
    private promotionCodeEntityService: PromotionCodeEntityService,
    private dropdownHttpService: DropdownHttpService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<VipEditDialogComponent>,
    private promotionCodeDataService: PromotionCodeDataService,
  ) { }

  ngOnInit() {
    this.dropdownHttpService.groups.subscribe(res => {
      this.dropdown.groups = res;
    })
    this.formInit();
    if (this.data.mode === 'approve') {
      this.promotionCodeDataService.getWithQuery(`?paginate=false&status=1&member_group_id=${this.data.vip.next_member_group_id}&currency_id=${this.data.vip.currency_id}`).subscribe(res => {
        this.promotionCodeDropdownList = res;
        this.promotionCodeDropdownList.map(function (elm) {
            elm['name'] = elm.code + ' - ' + elm.name;
        });
      });
      if( this.data.vip.upgrade_promotion_id > 0){
        this.upgradeBonusSelectedItems.push({
          id: this.data.vip.upgrade_promotion_id,
          name: this.data.vip.upgrade_promotion_code + ' - ' + this.data.vip.upgrade_promotion_name
        });
      }
    }
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  ngAfterViewInit() {
    if (this.data.mode === 'approve' && this.data.vip.date_of_birth === '0000-00-00') {
      this.datePickerSubscription = forkJoin([
        this.buildDatePicker(0, 'date_of_birth'),
      ]).subscribe();
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(mode?: string) {
    this.buttonLoading = true;
    const data = {
      id: mode === 'edit' ? this.data.member.id : mode !== 'create' ? this.data.vip.id : null,
      status: mode === 'approve' ? 1 : mode === 'reject' ? 2 : null,
      ...this.form.value,
      send_message: Number(this.form.value.send_message),
      send_sms: Number(this.form.value.send_sms),
      member_group_id: this.memberGroupId,
      next_member_group_id: this.nextMemberGroupId
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    if (mode === 'edit') {
      this.updateVIP(data);
    } else if (mode === 'create') {
      this.subscription = this.vipMemberVerificationDataService.addVIp(data).pipe(
        tap((res: any) => {
          this.refreshStatus = true;
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else {
      this.onUpdate(mode, data);
    }
  }

  onUpdate(action: string, data: any) {
    Swal.fire({
      title: "Are you sure?",
      text:
        "Do you want to " + action + "  " + this.data.vip.username + " ?",
      icon: "info",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((resp) => {
      if (resp.value) {
        if (action === "approve") {
          this.updateVIP(data);
        } else {
          this.updateVIP(data);
        }
      } else {
        this.buttonLoading = false;
      }
    });
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onSelectNextLevel(event: any) {
    this.nextMemberGroupId = event.srcElement.value;
    this.form.patchValue({ upgrade_promotion_id: null });
    this.upgradeBonusSelectedItems = [];
    if (this.data.mode === 'create') {
      this.setPromotion(this.nextMemberGroupId, this.currencyId);
      // Get VIP setting based on the member's member group id, setting currency id and upgrade type (type = 1)
      this.subscription = this.vipMemberVerificationDataService.getVIPSetting(event.srcElement.value, this.currencyId, 1).pipe(
        tap((res) => {
        // Assign the upgrade_promotion_id
          if (res[0].upgrade_promotion_id != undefined  && +res[0].upgrade_promotion_id > 0) {
            this.form.patchValue({ upgrade_promotion_id: res[0].upgrade_promotion_id });
            this.upgradeBonusSelectedItems.push({
              id: res[0].upgrade_promotion_id,
              name: res[0].upgrade_promotion_code + ' - ' + res[0].upgrade_promotion_name
            });
          }
        })).subscribe();
    }
  }

  onSelectedMemberChanged(value) {
    this.setPromotion(this.nextMemberGroupId, this.currencyId);
    this.form.patchValue({ upgrade_promotion_id: null });
    this.upgradeBonusSelectedItems = [];
    this.currencyId = value[0].currency_id;
    this.checkValidDateOfBirth(value[0]);
    this.form.patchValue({
      member_group_id: value[0].member_group_id,
      date_of_birth: value[0].date_of_birth
    });
    this.memberGroupId = value[0].member_group_id;
  }

  private setPromotion(groupId: number, currencyId: number) {
    if (this.nextMemberGroupId !== null && this.currencyId !== null) {
      const param = `member_group_id=${groupId}&currency_id=${currencyId}`;
      this.promotionCodeDataService.getWithQuery(`?paginate=false&status=1&${param}`).subscribe(res => {
        this.promotionCodeDropdownList = res;
        this.promotionCodeDropdownList.map(function (elm) {
            elm['name'] = elm.code + ' - ' + elm.name;
        });
      });
      this.form.controls['upgrade_promotion_id'].enable();
    }
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.valueChange.pipe(
      map(res => this.datePipe.transform(res, 'yyyy-MM-dd')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      }),
    );
  }

  private updateVIP(data: any) {
    this.subscription = this.vipMemberVerificationDataService.updateVIp(data).pipe(
      tap((res: any) => {
        this.buttonLoading = false;
        this.refreshStatus = true;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
  }

  private checkValidDateOfBirth(vip: any) {
    const nowDateTime = new Date();

    if (vip.vip_birthday_bonus_status == true) {
      this.isMemberDOBPass = true;
    } else {
      this.isMemberDOBPass = false;
    }

    this.memberDOB = vip.date_of_birth;
  }

  private formInit() {
    let memberAccountId = null;
    let upgradePromotionId = null;
    let sendSms = 1;
    let sendMessage = 1;
    let remarks = null;
    let dob = null;
    if (this.data.mode !== 'create') {
      if (this.data.mode === 'edit') {
        memberAccountId = this.data.editVip.id;
        this.nextMemberGroupId = null;
        this.currencyId = this.data.editVip.currency_id;
        dob = this.data.editVip.date_of_birth;
        this.memberGroupId = this.data.editVip.current_group_id;
        if (this.data.editVip.date_of_birth !== '0000-00-00') {
          this.checkValidDateOfBirth(this.data.editVip);
          this.currencyId = this.data.editVip.currency_id;
        }
      } else {
        memberAccountId = this.data.vip.member_account_id;
        this.nextMemberGroupId = this.data.vip.next_member_group_id;
        this.currencyId = this.data.vip.currency_id;
        upgradePromotionId = this.data.vip.upgrade_promotion_id > 0 ? this.data.vip.upgrade_promotion_id : null;
        remarks = this.data.vip.remarks;
        dob = this.data.vip.date_of_birth;
        this.memberGroupId = this.data.vip.member_group_id;
        if (this.data.vip.date_of_birth !== '0000-00-00') {
          this.checkValidDateOfBirth(this.data.vip);
          this.currencyId = this.data.vip.currency_id;
        }
      }
    }
    this.form = new FormGroup({
      member_account_id: new FormControl(memberAccountId),
      member_group_id: new FormControl({ value: this.memberGroupId, disabled: true }),
      next_member_group_id: new FormControl({ value: this.nextMemberGroupId, disabled: this.data.mode === 'approve' ? true : false }),
      upgrade_promotion_id: new FormControl({ value: upgradePromotionId, disabled: this.data.mode === 'edit' || (this.nextMemberGroupId === null && this.currencyId === null) ? true : false }),
      send_sms: new FormControl(sendSms),
      send_message: new FormControl(sendMessage),
      date_of_birth: new FormControl(dob),
      remarks: new FormControl(remarks, this.data.mode === 'reject' ? [Validators.required] : null)
    });

  }

}
