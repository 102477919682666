<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Bank Receipt</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Currency <span class="text-danger">*</span></label>
                        <fieldset [disabled]="data.mode === 'edit' ? true : false">
                            <select (change)="onSelectCurrency(form.value.currency_id)" formControlName="currency_id" class="form-control">
                                <option [value]="null" [disabled]="true">Please Select</option>
                                <option *ngFor="let value of dropdown.currencies" [value]="value.id">{{ value.name }}</option>
                            </select>
                        </fieldset>
                    </div>
                    <div class="col-lg-6 form-group" #container>
                        <label class="control-label"> Bank Account <span class="text-danger">*</span> </label>
                        <!-- Note: this bank account field will be disabled in Edit mode. The "disabled" option is set within bankAccountDropdownSettings, not here -->
                        <fieldset>
                            <kt-dropdown-wo-lazyload
                                [form] = 'form'
                                [dropdownList] = 'filteredBankAccounts'
                                [dropdownSettings] = 'bankAccountDropdownSettings'
                                [formName] = "'merchant_bank_id'"
                                [selectionAttributes] = "'banksys_id'"
                                [selectedItems] = 'bankAccountSelectedItems'>
                            </kt-dropdown-wo-lazyload>
                        </fieldset>
                    </div>

                    <div class="col-lg-3 form-group">
                        <label class="control-label"> Type <span class="text-danger">*</span> </label>
                        <fieldset [disabled]="true">
                            <select formControlName="type" class="form-control">
                                <option [value]="null" [disabled]="true">Please Select</option>
                                <ng-container *ngFor="let value of dropdown.bankReceiptTypes">
                                    <option *ngIf="value.id !== 0" [value]="value.id">
                                        {{ value.name }}
                                    </option>
                                </ng-container>
                            </select>
                        </fieldset>
                    </div>
                    <div class="col-lg-3 form-group">
                        <label class="control-label"> Transaction Type <span class="text-danger">*</span> </label>
                        <fieldset [disabled]="data.mode === 'edit' ? true : false">
                            <select formControlName="transaction_type" class="form-control">
                                <option [value]="null" [disabled]="true">Please Select</option>
                                <ng-container *ngFor="let item of dropdown.bankReceiptTransactionTypes">
                                    <option *ngIf="item.value !== 'all'" [value]="item.value">
                                        {{ item.name }}
                                    </option>
                                </ng-container>
                            </select>
                        </fieldset>
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Transaction Timestamp <span class="text-danger">*</span> </label>
                        <div class="pl-0 pr-0">
                            <div class="input-group date" [owlDateTimeTrigger]="dateStartRef">
                                <input class="hidden" [owlDateTime]="dateStartRef" [max]="currentDate">
                                <input type="text" formControlName="transaction_timestamp" placeholder="Search" class="form-control" [ngClass]="{'is-invalid': checkValidation && form.controls.transaction_timestamp.errors }">
                                <span class="input-group-append">
                                    <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                                </span>
                                <owl-date-time #dateStartRef></owl-date-time>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Description <span class="text-danger">*</span> </label>
                        <fieldset>
                            <input class="form-control" type="text" formControlName="desc">
                        </fieldset>
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label">Amount <span class="text-danger">*</span></label>
                        <fieldset>
                            <input class="form-control" type="number" formControlName="amount" min="0" />
                        </fieldset>
                    </div>

                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Remarks <span *ngIf="data.mode === 'edit'" class="text-danger">*</span></label>
                        <fieldset>
                            <input class="form-control" type="text" formControlName="remarks">
                        </fieldset>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.mode)"></kt-submit-button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" [data]="data$ | async" (confirmed)="onConfirm($event)" (cancelled)="onCancelUpdate($event)"></kt-swal-alert>
<kt-swal-alert [message]="messages2$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
