import { Injectable } from '@angular/core';
import { WebPushMessages } from '@core/models/web-push-messages.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable()
export class WebPushMessagesEntityService extends EntityCollectionServiceBase<WebPushMessages> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('WebPushMessages', serviceElementsFactory)
  }
}
