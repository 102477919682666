import { ApiResponse } from '@core/models/api-response.model';
import { tap, map, concatMap } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Dropdown } from '@core/models/dropdown.model';
import { AffiliateMessageTemplate } from '@core/models/message-template-affiliate.model';

@Injectable()
export class AfffiliateMessageTemplateDataService extends DefaultDataService<AffiliateMessageTemplate>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  locales$ = new BehaviorSubject([]);

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('MessageTemplate', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<AffiliateMessageTemplate[]> {
    return this.http.get<ApiResponse>('/messagetemplate/affiliate/index').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getPendingCount(): Observable<any> {
    return this.http.get<ApiResponse>('/messagetemplate/affiliate/pendingcount').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data)
    );
  }

  getWithQuery(pageParam: string): Observable<AffiliateMessageTemplate[]>{
    return this.http.get<ApiResponse>(`/messagetemplate/affiliate/index${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getMessageTemplateSection(action: 'create' | 'edit' = 'edit') {
    return this.http.get<ApiResponse>(`/messagetemplatesections/affiliate?action=${action}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(messageTemplate: AffiliateMessageTemplate): Observable<AffiliateMessageTemplate>{
    return this.http.post<ApiResponse>(`messagetemplate/affiliate/`, messageTemplate).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((res) => this.http.get(`/messagetemplate/affiliate/${res.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  getById(id: number): Observable<AffiliateMessageTemplate>{
    return this.http.get<ApiResponse>(`/messagetemplate/affiliate/${id}`).pipe(
      map(res => res.data)
    );
  }

  getMessageTemplateList(type: number, section: number): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/messagetemplates/affiliate?type=` + type + `&section=` + section).pipe(
      map(res => res.data.rows)
    );
  }

  getMessageTemplatVariables(sectionID: number, name: string): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/messagetemplatevariables/affiliate?section_id=${sectionID}&name=${name}`).pipe(
      map(res => res.data.rows)
    );
  }

  getGenericVariables(): Observable<AffiliateMessageTemplate> {
    return this.http.get<ApiResponse>(`/messagetemplate/affiliate/generic`).pipe(
      map(res => res.data)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

  getRemarkById(id: number,pageParam?: string) {
    return this.http.get<ApiResponse>(`/messagetemplate/affiliate/remark/${id}${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  statusUpdate(params: any) {
    return this.http.post<ApiResponse>(`/messagetemplate/affiliate/statusupdate`, params).pipe(
      tap((res) => {
        this.messages$.next(res.message);
      }),
    );;
  }
  
}
