import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: '[shared-table-handler]',
  templateUrl: './table-handler.component.html',
  styleUrls: ['./table-handler.component.scss']
})
export class TableHandlerComponent implements OnInit {

  @Input()
  loading = false;

  @Input()
  dataLength = 0;

  constructor() { }

  ngOnInit() {
  }

}
