<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Lead History</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div *ngIf="(history$ | async) as data">
                <!-- Lead Info Section -->
                <section class="col-lg-12 card border-0 p-0 pb-3">
                    <div class="col-12 p-0 card rounded-0">
                        <div class="card-header">
                            Lead Info
                        </div>
                        <section class="col-12 p-4">
                            <div class="row border-top border-left">
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                    <span class="kt-font-bold">ID</span>
                                </div>
                                <div class="col-md-3 border-right p-3">
                                    <span>{{ data.info.id }}</span>
                                </div>
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                    <span class="kt-font-bold">Created At</span>
                                </div>
                                <div class="col-md-3 border-right p-3">
                                    <span>{{ data.info.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }} </span>
                                </div>
                            </div>
                            <div class="row border-top border-left">
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                    <span class="kt-font-bold">Initial Lead Type</span>
                                </div>
                                <div class="col-md-3 border-right p-3">
                                    <span>{{ data.info.initial_lead_type }}</span>
                                </div>
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                    <span class="kt-font-bold">Status</span>
                                </div>
                                <div class="col-md-3 border-right p-3">
                                    <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + data.info.status"> {{ data.info.status_name ? data.info.status_name : '-' }}</span>
                                </div>
                            </div>
                            <div class="row border-top border-left">
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                    <span class="kt-font-bold">Name</span>
                                </div>
                                <div class="col-md-3 border-right p-3">
                                    <span>{{ data.info.name }}</span>
                                </div>
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                    <span class="kt-font-bold">Username</span>
                                </div>
                                <div class="col-md-3 border-right p-3">
                                    <span>{{ data.info.username ? data.info.username : '-' }} </span>
                                </div>
                            </div>
                            <div class="row border-top border-left">
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                    <span class="kt-font-bold">Currency</span>
                                </div>
                                <div class="col-md-3 border-right p-3">
                                    <span>{{ data.info.currency_code }}</span>
                                </div>
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                    <span class="kt-font-bold">Source Type</span>
                                </div>
                                <div class="col-md-3 border-right p-3">
                                    <span>{{ data.info.source_type }} </span>
                                </div>
                            </div>
                            <div class="row border-top border-left border-bottom">
                                <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                    <span class="kt-font-bold">Latest Remark</span>
                                </div>
                                <div class="col-md-9 border-right p-3">
                                    <span>{{ data.info.remarks }}</span>
                                </div>
                            </div>    
                        </section>
                    </div>
                </section>
                <!-- Lead Timeline Section -->
                <section class="col-lg-12 card border-0 p-0 pb-3">
                    <div class="col-12 p-0 card rounded-0">
                        <div class="card-header">
                            Timeline
                        </div>
                        <div>
                            <div class="row ml-1" *ngFor="let row of data.rows">
                                <div class="col-1 p-3">
                                    {{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }}
                                </div>
                                <div class="col-11 p-3">
                                    <!-- The Timeline -->
                                    <div class="timeline">
                                        <div class="preview-status" [matTooltip]="row.status_name">&nbsp;</div>
                                        <div class="direction-r">
                                            <div class="flag-wrapper">
                                                <span class="flag" [ngClass]="'border-color--' + row.status">
                                                    <section class="col-12 p-3 inner-box-timeline">
                                                        <div class="row border-top border-left">
                                                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                                                <span class="kt-font-bold">Assigned Telemarketer</span>
                                                            </div>
                                                            <div class="col-md-3 border-right p-3">
                                                                <span>{{ row.telemarketer ? row.telemarketer : '-' }}</span>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                                                <span class="kt-font-bold">Assigned Date</span>
                                                            </div>
                                                            <div class="col-md-3 border-right p-3">
                                                                <span class="text-right">{{ row.lead_assigned_datetime ? (row.lead_assigned_datetime | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }} </span>
                                                            </div>
                                                        </div>
                                                        <div class="row border-top border-left">
                                                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                                                <span class="kt-font-bold">Progress</span>
                                                            </div>
                                                            <div class="col-md-3 border-right p-3">
                                                                <div class="text-nowrap text-left">
                                                                    <i class="fa fa-check-circle" aria-hidden="true" [ngClass]="'status-color--' + row.is_register"></i> 
                                                                    Registered
                                                                </div>
                                                                <div class="text-nowrap text-left">
                                                                    <i class="fa fa-check-circle" aria-hidden="true" [ngClass]="'status-color--' + row.is_ftd"></i> 
                                                                    FTD
                                                                </div>
                                                                <div class="text-nowrap text-left">
                                                                    <i class="fa fa-check-circle" aria-hidden="true" [ngClass]="'status-color--' + row.target_amount_status"></i> 
                                                                    $: {{ row.total_deposit ? row.total_deposit : '-' }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                                                <span class="kt-font-bold">Lead Expiry Date</span>
                                                            </div>
                                                            <div class="col-md-3 border-right p-3">
                                                                <span class="text-right">{{ row.lead_expired_datetime ? (row.lead_expired_datetime | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }} </span>
                                                            </div>
                                                        </div>
                                                        <div class="row border-top border-left">
                                                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                                                <span class="kt-font-bold">Remark</span>
                                                            </div>
                                                            <div class="col-md-9 border-right p-3">
                                                                <span>{{ row.value ? row.value : '-' }}</span>
                                                            </div>
                                                        </div>    
                                                        <div class="row border-top border-left border-bottom">
                                                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                                                <span class="kt-font-bold">Attachment</span>
                                                            </div>
                                                            <div class="col-md-3 border-right p-3 attachmentIcon">
                                                                <span class="text-right"><i class="fa fa-file" (click)="onOpenDialog(row.remark_assets)" *ngIf="row.remark_assets.length > 0"></i></span>
                                                                <span *ngIf="row.remark_assets.length == 0">{{ '-' }}</span>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3 card-header border-right p-3">
                                                                <span class="kt-font-bold">Handled By</span>
                                                            </div>
                                                            <div class="col-md-3 border-right p-3">
                                                                <span class="text-right">{{ row.handler ? row.handler : '-' }}<br>{{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }} </span>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog($event)"><i class="fas fa-ban"></i>Close</button>
        </div>
    </div>
</div>