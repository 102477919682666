<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Announcement</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">

          <div class="col-12 form-group row">
              <label class="col-4 col-form-label"> Content <span class="text-danger">*</span> </label>
              <textarea rows="5"  placeholder="Please enter announcement detail in one sentence..." class="col-8 form-control" formControlName="content"></textarea>
          </div>
        <div class="col-12 form-group row">
              <label class="col-4 col-form-label"> Locale <span class="text-danger">*</span></label>
              <select *ngIf="dropdown.locales" formControlName="settings_locale_id" class="col-8 form-control" (change)="onFocusField()">
                <option [value]="null" [disabled]="true">Please Select</option>
                <option *ngFor="let value of dropdown.locales | async" [value]="value.id">{{ value.code }}</option>
              </select>
          </div>
          <div class="col-12 form-group row">
              <label class="col-4 col-form-label">Position <span class="text-danger">*</span> </label>
              <input type="text" class="col-8 form-control" formControlName="position">
          </div>
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Type: </label>
            <select *ngIf="dropdown.announcementTypes" class="col-8 form-control" formControlName="method" (change)="onFocusField()">
              <option [value]="null" [disabled]="true">Please Select</option>
              <option *ngFor="let value of dropdown.announcementTypes" [value]="value.id">{{ value.name }}</option>
            </select>
          </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Location: </label>
          <select *ngIf="dropdown.locations" class="col-8 form-control" formControlName="location" (change)="onFocusField()">
            <option [value]="null" [disabled]="true">Please Select</option>
            <option *ngFor="let value of dropdown.locations | async" [value]="value.id"> {{ value.name }}</option>
          </select>
        </div>
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Status: </label>
            <select *ngIf="dropdown.statuses" class="col-8 form-control" formControlName="status" (change)="onFocusField()">
              <option *ngFor="let value of dropdown.statuses; let i = index;" [value]="i">{{ value }}</option>
            </select>
        </div>
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Start Date Time <span class="text-danger">*</span> </label>
          <div class="col-8 input-group date">
              <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack?.start_datetime" [ngModelOptions]="{standalone: true}">
              <input type="text" formControlName="start_datetime" class="form-control">
              <span class="input-group-append" [owlDateTimeTrigger]="dateStartRef">
                  <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
              </span>
              <owl-date-time #dateStartRef></owl-date-time>
          </div>
        </div>
        <div class="col-12 form-group row">
            <label class="col-4 col-form-label">End Date Time </label>
            <div class="col-8 input-group date">
              <input class="hidden" [owlDateTime]="dateEndRef" [ngModel]="dateTimeStack?.end_datetime" [ngModelOptions]="{standalone: true}">
              <input type="text" formControlName="end_datetime" class="form-control">
              <span class="input-group-append" [owlDateTimeTrigger]="dateEndRef">
                  <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
              </span>
              <owl-date-time #dateEndRef></owl-date-time>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.announcement, data.mode)" #focusfield></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
