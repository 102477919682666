import { Component, OnInit, Pipe } from '@angular/core';
import { tap } from "rxjs/operators";
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { OperatorDataService } from '@views/pages/apps/superuser/operators/services/operator-data.service';

@Component({
  selector: 'twofa-pending',
  templateUrl: './twofa-pending.component.html',
  styleUrls: ['./twofa-pending.component.scss']
})
export class TwofaPendingComponent implements OnInit {

  form: FormGroup;
  buttonLoading: boolean = false;
  errorText: boolean = false;
  constructor(
    private router: Router,
    private operatorDataService: OperatorDataService,
  ) { }

  ngOnInit(): void {
    const twoFaAccess = localStorage.getItem('twofa_access') == 'true' ? true : false;
    const userData = JSON.parse(localStorage.getItem('user_data'));

    if (!twoFaAccess) {
      this.router.navigate(['/dashboard']);
    }

    if (userData) {
      this.operatorDataService.twofaStatus(userData?.id).pipe(
        tap((res) => {
          userData.two_factor_authentication_status = res.two_factor_authentication_status;
          localStorage.setItem('user_data', JSON.stringify(userData));

          if (userData.two_factor_authentication_status !== 2) { // 2FA Status != Pending for reset
            this.router.navigate(['/twofafirstsetup']); 
          }
        })
      ).subscribe();
    }
  }

  onRefresh() {
    window.location.reload();
  }
}
