<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <!-- Filter -->
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <div class="col-12 row mb-2 pr-0">
                <!-- Name -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Name:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input formControlName="name" class="form-control" />
                </div>
                <!-- Currency -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Currency:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="currency_id" class="form-control">
                    <option value="all">All</option>
                    <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
                <!-- Status -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="status" class="form-control">
                    <option [value]="'all'">All</option>
                    <ng-container *ngFor="let item of statuses | keyvalue">
                      <option [value]="item.value" *ngIf="!(+item.key >= 0)">{{ item.key }}</option>
                    </ng-container>
                  </select>
                </div>
                <!-- Member Group Type -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Member Group Type:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="member_group_type" class="form-control">
                    <option [value]="'all'">All</option>
                    <ng-container *ngFor="let item of dropdown.memberGroupTypes">
                      <option [value]="item.id">{{ item.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Action -->
      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <button class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')" *ngIf="canCreateVipSettings"><i class="fas fa-plus"></i>Create</button>
      </div>
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="sort-enabled text-center" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                  <th class="sort-enabled text-center" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                  <th class="sort-enabled text-center" (click)="onSortColumn('currency_id')" [ngClass]="sortingConfig.currency_id" [class.sort-selected]="sortingSelection.sort_by === 'currency_id'">Currency</th>
                  <th class="text-center">VIP Details</th>
                  <th class="text-center">CMS Info</th>
                  <th class="sort-enabled text-center" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                  <th class="sort-enabled date-column text-center" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created by</th>
                  <th class="sort-enabled date-column text-center" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated by</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody *ngIf="(vipSettings$ | async) as rows">
                <tr *ngFor="let row of rows">
                  <td>{{ row.id }}</td>
                  <td>{{ row.name === '' ? '-' : row.name }}</td>
                  <td>{{ row.currency_code }}</td>
                  <td>
                    Upgrade Type: {{ row.upgrade_requirement_type_id === 1 ? 'Invited' : 
                    row.upgrade_requirement_type_id === 2 ? 'FTD' : 
                    row.upgrade_requirement_type_id === 3 ? 'Lifetime Deposit' : 
                    row.upgrade_requirement_type_id === 4 ? 'Annual Deposit' : '' }}
                    <br>
                    Upgrade Requirement: {{ row.upgrade_requirement === '' ? '-' : (row.upgrade_requirement | number : '1.2-2') }}<br>
                    Upgrade Bonus: {{row.upgrade_promotion_name === '' || row.upgrade_promotion_name === null ? '-' : row.upgrade_promotion_name}}<br>
                    Birthday Bonus: {{row.birthday_promotion_name === '' || row.birthday_promotion_name === null ? '-' : row.birthday_promotion_name}}<br>
                    Next VIP Upgrade: {{row.next_member_group_name === '' || row.next_member_group_name === null ? '-' : row.next_member_group_name}}<br>
                    Auto Approve: <i [ngClass]="row.auto_approve === 1 ? 'fas fa-check check-icon' : 'fas fa-times kt-font-danger'"></i>
                  </td>
                  <td>
                    Days: {{ row.days === '' ? '-' : row.days }}<br>
                    Service Priority: {{ row.service_priority === '' ? '-' : row.service_priority }}<br>
                    <!-- Target Amount: {{ row.upgrade_requirement === '' ? '-' : (row.upgrade_requirement | number : '1.2-2') }}<br> -->
                    Upgrade Bonus: {{ row.reward_bonus === '' ? '-' : (row.reward_bonus | number : '1.2-2') }}<br>
                    Birthday Bonus: {{ row.birth_rebate === '' ? '-' : (row.birth_rebate | number : '1.2-2') }}<br>
                    Rebates: <br>
                    <ul>
                      <li>Live: {{ row.lc_rebate }}</li>
                      <li>Slots: {{ row.sl_rebate }}</li>
                      <li>Sports: {{ row.sp_rebate }}</li>
                    </ul>
                  <!-- <td>
                    Upgrade Bonus: {{ row.upgrade_promotion_name === null ? '-' : row.upgrade_promotion_name }}<br>
                    Birthday Bonus: {{ row.birthday_promotion_name === null ? '-' : row.birthday_promotion_name }}<br>
                  </td> -->
                  <td class="text-center">
                    <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ statuses[row.status] }}</span>
                  </td>
                  <td>
                    {{ row.created_by ? row.created_by : 'System' }} <br />
                    <span placement="top" [ngbTooltip]="createddDateTime" container="body" class="text-ellipsis">{{ isValidDate(row.created_at) ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</span>
                    <ng-template #createddDateTime>
                      <ng-container *ngIf="isValidDate(row.created_at)">
                        {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                      </ng-container>
                    </ng-template>
                  </td>
                  <td>
                    {{ row.updated_by ? row.updated_by : 'System' }} <br />
                    <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ isValidDate(row.updated_at) ? (row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</span>
                    <ng-template #updatedDateTime>
                      <ng-container *ngIf="isValidDate(row.updated_at)">
                        {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                      </ng-container>
                    </ng-template>
                  </td>
                  <td class="text-center">
                    <button matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" *ngIf="canEditVipSettings" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
