import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { AllAffiliateMemberReportHttpService } from './../../core/services/all-affiliate-member-report-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Pagination } from '@core/models/pagination.model';
import { Observable, Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'kt-all-affiliate-member-dialog',
  templateUrl: './all-affiliate-member-dialog.component.html',
  styleUrls: ['./all-affiliate-member-dialog.component.scss']
})
export class AllAffiliateMemberDialogComponent implements OnInit, OnDestroy {

  allAffMember$: Observable<any[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  perPageDropdown = this.dropdownHttpService.perPage;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { subAffiliate: any, params: any },
    public dialogRef: MatDialogRef<AllAffiliateMemberDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private aAllAffiliateMemberReportHttpService: AllAffiliateMemberReportHttpService
  ) { }

  ngOnInit() {
    this.pagination = this.aAllAffiliateMemberReportHttpService.pagination;
    this.reload();
  }

  ngOnDestroy() {
   this.subscription.unsubscribe();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onViewPageBy(page = 1, pageSize?: number) {
    pageSize = this.pageSize;
    const dateParams = `&start_date_time=${moment(this.data.params.start_date_time).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date_time=${moment(this.data.params.end_date_time).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}`
    return this.allAffMember$ = this.aAllAffiliateMemberReportHttpService.getAllAffiliateMemberReport(`?affiliate_username=${this.data.subAffiliate.username}&page=${page}&perPage=${pageSize}${dateParams}`).pipe(
      tap(res => {
        this.pagination = this.aAllAffiliateMemberReportHttpService.pagination;
      })
    );
  }

  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize);
  }

  private reload() {
    this.onViewPageBy(this.page);
  }

}
