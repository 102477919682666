import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TicketInfo } from '@core/models/ticket-info.model';

@Component({
  selector: 'kt-ticket-info-dialog',
  templateUrl: './ticket-info.component.html',
  styleUrls: ['./ticket-info.component.scss']
})
export class TicketInfoDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      transactionId: string;
      gameProviderCode: string;
      ticketInfo: TicketInfo[];
    },
    public dialogRef: MatDialogRef<TicketInfoDialogComponent>,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

}
