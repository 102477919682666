import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, delay, map, tap } from 'rxjs/operators';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignAdCost } from '@core/models/campaign-ad-cost.model';
import { Dropdown } from '@core/models/dropdown.model';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { CampaignAdCostDataService } from './../service/campaign-ad-cost-data.service';
import * as moment from 'moment-timezone';
@Component({
  selector: 'kt-campaign-ad-cost-details',
  templateUrl: './campaign-ad-cost-details.component.html',
  styleUrls: ['./campaign-ad-cost-details.component.scss']
})
export class CampaignAdCostDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;
  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  buttonLoading = false;
  form: FormGroup;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    campaign: this.dropdownHttpService.campaigns
  };
  selectedCurrency: any;
  dateTimeStack = null;
  messages$ = this.campaignAdCostDataService.messages$;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  dateTimeFormat = 'yyyy-MM-dd HH:mm:';
  private subscription = new Subscription();
  private datePickerSubscription = new Subscription();
  refreshStatus: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, adCost: CampaignAdCost, campaignID: number },
    public dialogRef: MatDialogRef<CampaignAdCostDialogComponent>,
    private campaignAdCostDataService: CampaignAdCostDataService,

    private dropdownHttpService: DropdownHttpService,
    private datePipe: DatePipe,
    private transactionHttpService: TransactionHttpService
  ) {}

  ngOnInit() {
    this.setCurrency();
    this.formInit();
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    this.onRefresh();
  }

  ngAfterViewInit() {
    if (this.data.mode === 'createAdCost' || this.data.mode === 'edit' || this.data.mode === 'create'){
      this.datePickerSubscription = forkJoin([
        this.buildDatePicker(0, 'date'),
      ]).subscribe();
    }
  }

  onFocusField() {
    of(null).pipe(
        delay(0), tap(() => this.focusfield.first.nativeElement.focus()
    )).subscribe();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onSave(adCost: CampaignAdCost, mode?: string) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      id: adCost ? adCost.id : null,
      ...this.form.value,
      date: moment(this.form.value.date).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss'),
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    if (mode === 'edit'){
      this.subscription = this.campaignAdCostDataService.updateAdCost(data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }else{
      this.subscription = forkJoin([
        this.campaignAdCostDataService.add(data).pipe(
          tap((res: any) => {
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            // To enable "Save" button after get response
            this.form.setErrors(null);
            throw error;
          })
        ),
        this.campaignAdCostDataService.messages$
      ]).subscribe();
    }
    this.refreshStatus = true;
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private setCurrency(){
    const selectCurrency = () => {
      if (this.data.mode === 'edit'){
        this.selectedCurrency = [this.dropdown.currencies.find(currency => currency.name === this.data.adCost.currency_code)];
      }
    };

    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
        selectCurrency();
      });
    }else{
      selectCurrency();
    }
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, index === 0 ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
        this.onFocusField();
      })
    );
  }

  private formInit() {
    let campaignId = null;
    let currencyId = null;
    let date = null;
    let costUsd = null;
    let costLocal = null;
    let targetCountry = null;
    let remarks = null;
    if (this.data.mode === 'createAdCost'){
      campaignId = this.data.campaignID;
    }else if (this.data.mode === 'edit'){
      this.dateTimeStack = new Date(new Date(this.data.adCost.date).getTime() + this.offset - this.clientOffset);
      campaignId = this.data.adCost.campaign_id;
      currencyId = this.data.adCost.settings_currency_id;
      date = moment(new Date(new Date(this.data.adCost.date).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss');
      costUsd = this.data.adCost.ad_cost_usd;
      costLocal = this.data.adCost.ad_cost_local;
      targetCountry = this.data.adCost.target_country;
      remarks = this.data.adCost.remarks;
    }
    this.form = new FormGroup({
      campaign_id: new FormControl(campaignId, [Validators.required]),
      currency_id: new FormControl(currencyId),
      date: new FormControl(date, [Validators.required]),
      ad_cost_usd: new FormControl(costUsd, [Validators.required]),
      ad_cost_local: new FormControl(costLocal, [Validators.required]),
      target_country: new FormControl(targetCountry, [Validators.required]),
      remarks: new FormControl(remarks)
    });
  }
}
