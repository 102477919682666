import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewRef } from '@angular/core';
import { SubheaderService } from '@core/_base/layout';
import { GameProviderEntityService } from './services/game-provider-entity.service';
import { GameProviderDataService } from './services/game-provider-data.service';
import { Status } from '@core/enums/status.enum';
import { GameProviderType } from '@core/enums/game-provider-type.enum';
import { Subscription, interval, of, Observable, forkJoin } from 'rxjs';
import { Pagination } from '@core/models/pagination.model';
import { tap, map, exhaustMap } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MatDialog } from '@angular/material/dialog';
import { GameProvider } from '@core/models/game-provider.model';
import { GameProviderEditDialogComponent } from './dialogs/game-provider-edit.component';
import { QuickLinkSettingsComponent } from './dialogs/quick-link-settings/quick-link-settings.component';
import { SitePrefix } from '@core/enums/site-prefix.enum';
import { environment } from '@env/environment';
import { GameProviderPositionEditDialogComponent } from '../game-provider-position/dialogs/game-provider-position-edit.component';
import { GameProviderPositionDataService } from '../game-provider-position/services/game-provider-position-data.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'kt-game-providers',
  templateUrl: './game-providers.component.html',
  styleUrls: ['./game-providers.component.scss']
})
export class GameProvidersComponent implements OnInit, OnDestroy, AfterViewInit {

  AccessQuickLinkSetting = localStorage.getItem('user_permissions') !== null ? JSON.parse(localStorage.getItem('user_permissions')).quick_link_setting : false ;

  form: FormGroup;
  status = Status;
  perPageDropdown = this.dropdownHttpService.perPage;
  gameProviders$: Observable<GameProvider[]>;

  newurl: string;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = 'currency_id=1';
  messages$ = this.gameproviderDataService.messages$;
  recentParams = JSON.parse(sessionStorage.getItem('gameprovider_params'));
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    categories: this.dropdownHttpService.gameCategories.pipe(
      tap(res => {
        sessionStorage.setItem('game_category', JSON.stringify(res));
      })
    ),
    type: GameProviderType
  };
  dataLength = 0;
  loading = false;
  sitePrefix: string = environment.sitePrefix;
  clearBtnLoading = false;
  searchBtnLoading = false;

  sortingConfig = {
    'code': 'asc',
    'name': 'desc',
    'maintain_from': 'desc',
    'maintain_to': 'desc',
    'updated_at': 'desc'
  }
  sortingSelection = {
    'sort_by': 'code',
    'sort_order': 'asc',
  };

  // permissions
  canViewGameProviderDetails: boolean;
  canEditGameProvider: boolean;
  canUpdateGameProviderStatus: boolean;
  canEditPosition: boolean;
  canViewMiniGamesList: boolean;

  private subscriptions = new Subscription();
  private subscription = new Subscription();
  private refreshSubcription = new Subscription();
  private messageSubscription = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private cdr: ChangeDetectorRef,
    private gameproviderEntity: GameProviderEntityService,
    private dialog: MatDialog,
    private gameproviderDataService: GameProviderDataService,
    private dropdownHttpService: DropdownHttpService,
    private gameproviderPositionDataService: GameProviderPositionDataService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.reload();
    this.formInit();
    this.pagination = this.gameproviderDataService.pagination;
    this.dropdownHttpService.language.pipe(tap(res => {
      this.gameproviderDataService.language$.next(res);
    })).subscribe();
    this.setCurrencyDropdown();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewGameProviderDetails = appPermissions.view_game_provider_details;
      this.canEditGameProvider = appPermissions.edit_game_provider;
      this.canUpdateGameProviderStatus = appPermissions.update_game_provider_status;
      this.canEditPosition = appPermissions.edit_position;
      this.canViewMiniGamesList = appPermissions.view_mini_games_list;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.subscription.unsubscribe();
    this.refreshSubcription.unsubscribe();
    this.messageSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    if (JSON.parse(sessionStorage.getItem('recent_page')) === 'mini-games') {
      this.setRecentParams();
    }
    sessionStorage.setItem('recent_page', null);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.gameProviders$ = this.gameproviderEntity.getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.dataLength = res.length;
        this.loading = false;
        this.pagination = this.gameproviderDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.gameProviders$ = this.gameproviderEntity.getWithQuery(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.dataLength = res.length;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.page = 1;
            this.pagination = this.gameproviderDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onOpenDialog(type: string, gameProvider?: any) {
    if(type === 'edit-maintenance') { // edit-maintenance is "Game Provider Settings"
      forkJoin([
        this.gameproviderDataService.getGameProvider(gameProvider.id),
        this.dropdownHttpService.gameCategories
      ]).subscribe(res => {
        this.openDialogBy(GameProviderEditDialogComponent, { mode: type, gameProvider: res[0][0], gameCategories: res[1], gfGameProvider: res[0][0]['gf_game_providers'] });
      })
    } else if(type === 'edit-position') {
      const gameProviderData = this.gameproviderPositionDataService.getGameProvider(gameProvider.id);
      this.subscription = gameProviderData.pipe(
        tap((res: any) => {
          if (res) {
            this.openDialogBy(GameProviderPositionEditDialogComponent, { mode: type, gameProviderPosition: res });
          }
        })
      ).subscribe();
    }  else if (type === 'quick-link-settings') {
      this.openDialogBy(QuickLinkSettingsComponent, { mode: type, gameProvider: gameProvider});
    }

    // const data = {
    //   ...gameProvider
    // };
    // this.openDialogBy(GameProviderEditDialogComponent, { gameProvider: data }); //
  }

  private openDialogBy(componentRef: any, data?: { mode: any, gameProvider?: any, gameCategories?: any, gfGameProvider?: any, gameProviderPosition?: any}) {    
    if (data.mode === 'edit-maintenance' || data.mode === 'quick-link-settings') { // edit-maintenance is "Game Provider Settings"
      const dialogRef = this.dialog.open(componentRef, {
        width: '1200px',
        data: {
          gameProvider: data.gameProvider,
          gameCategories: data.gameCategories,
          gfGameProvider: data.gfGameProvider
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true){
          this.onViewPageBy(this.page).subscribe();
        }
      });
    } else if (data.mode === 'edit-position') {
      this.dialog.open(componentRef, {
        width: '800px',
        data: {
          gameProviderPosition: data.gameProviderPosition
        }
      });
    }
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      category_code: new FormControl('all'),
      currency_id: new FormControl(1),
      type: new FormControl('all')
    });
  }

  private refreshEvery(seconds: number) {
    const refreshSource = interval(seconds * 1000);
    refreshSource.subscribe(() => {
      setTimeout(() => {
        if (this.cdr && !(this.cdr as ViewRef).destroyed) {
          this.cdr.detectChanges();
          this.reload();
        }
      });
    });
  }

  reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  onSetSession(category_code, provider, providerName) {
    const data = {
      ...this.form.value,
    };
    sessionStorage.setItem('mini_game_provider', providerName);
    this.newurl = window.location.pathname + `/?category=${category_code}&provider=${provider}`;
    window.history.pushState({ path: this.newurl }, '', this.newurl);
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] === 'all') && delete data[key]);
    sessionStorage.setItem('gameprovider_params', JSON.stringify(data));
  }

  onSetLink(category, provider) {
    return `/game/category/${category}/provider/${provider}`;
  }

  onToggleStatus(row: any){
    const checkbox = document.getElementById(row.id) as HTMLInputElement;
    if (checkbox) {
      if(checkbox.checked === false){
        const data = {
          id: row.id,
          status: row.status === 0 ? 1 : 0
        };
        this.subscription = this.gameproviderDataService.toggleStatus(row.id, data).subscribe(() =>
          this.messages$ = this.gameproviderDataService.messages$
        );
      }
      else{
        checkbox.checked = row.status === 1 ? true : false;
        Swal.fire({
          icon: "warning",
          title: `<div>Are you sure you want to enable \n <b>${row.name}</b>?</div>`,
          html: `Please note that enabling a game provider requires setting up all relevant configurations and settings. Ensure that all necessary settings are configured properly before proceeding.
          <br><br>Proceed with enabling the game provider?`,
          showCancelButton: true,
          confirmButtonText: 'Setup',
          cancelButtonText: 'Proceed',
          confirmButtonColor: '#52c41a',
          cancelButtonColor: '#1890ff',
        }).then((result) => {
          if (result.isConfirmed) {
            this.openDialogBy(QuickLinkSettingsComponent, { mode: 'quick-link-settings', gameProvider: row});
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            const data = {
              id: row.id,
              status: row.status === 0 ? 1 : 0
            };
            this.subscription = this.gameproviderDataService.toggleStatus(row.id, data).subscribe(() =>
              this.messages$ = this.gameproviderDataService.messages$
            );
          }
          else{
            // do nothing
          }
        });
      }
    }
  }

  private setRecentParams() {
    Object.keys(this.recentParams).forEach(key => {
      this.form.patchValue({
        [key]: this.recentParams[key]
      });
    }
    );
  }

  //Refine to use the MaintenanceHoursStatus enum instead like 10.2 Maintenance Hours page
  getColorStatusCode(status: string) {
    switch (status) {
      case 'FINISHED': {
        return 1;
      }
      case 'ONGOING': {
        return 5;
      }
      case 'UPCOMING': {
        return 3;
      }
    }
  }

  getTypeCode(type: number) {
    switch (type) {
      case 1: {
        return 'API';
      }
      case 2: {
        return 'BOT';
      }
    }
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

}
