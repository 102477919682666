<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog d-block">
  <form class="kt-form" [formGroup]="form">
    <div class="modal-content">
      <!-- Header -->
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'create' ? 'Create' : 'Edit'}} Raffle Events</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <!-- Body -->
      <div class="modal-body">
        <div class="row">
          <!-- Basic Info -->
          <section class="col-md-12 card border-0 form-group">
            <div class="col-12 card rounded-0 p-0">
              <div class="card-header">
                Event Settings
              </div>
              <section class="col-12 px-4 pt-4">
                <div class="row">
                   <!-- Name -->
                  <div class="col-6 form-group">
                    <label>Name: <span class="text-danger">*</span></label>
                    <input type="text" formControlName="name" class="col-12 form-control">
                  </div>
                  <!-- Start Date -->
                  <div class="col-6 form-group">
                      <label>Start Date: <span class="text-danger">*</span></label>
                      <div class="input-group date">
                        <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack?.dateStart" [ngModelOptions]="{standalone: true}">
                        <input type="text" placeholder="Search" class="form-control" formControlName="start_datetime">
                        <span class="input-group-append" [owlDateTimeTrigger]="dateStartRef">
                          <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                        </span>
                        <owl-date-time #dateStartRef></owl-date-time>
                      </div>
                  </div>
                  <!-- Reset Frequency -->
                  <div class="col-6 form-group">
                    <div class="row">
                      <label class="col-12">Ticket Reset Frequency: <span class="text-danger">*</span>
                        <i class="fas fa-exclamation-circle" matTooltip="Indicates the frequency of resetting player's ticket count of the event and recording a new set of ticket count." matTooltipClass="custom-tooltip" matTooltipHideDelay="0"></i>
                      </label>
                      <div class="col-6 d-flex pr-0">
                          <select formControlName="reset_frequency" class="form-control mt-2" (change)="onResetFrequency()">
                            <option [value]="value.id" *ngFor="let value of frequencyTypes; let i = index">{{ value.name }}</option>
                          </select>
                          <span class="pt-3 pl-2"> on </span>
                      </div>
                      <div class="col-6 reset-frequency-select1">
                        <select class="form-control mt-2" *ngIf="form.value.reset_frequency == 0 || form.value.reset_frequency == 1">
                          <option value="null" [disabled]="true">-</option>
                        </select>
                        <select formControlName="reset_dayWeekly" class="form-control mt-2" *ngIf="form.value.reset_frequency == 2 || form.value.reset_frequency == 3">
                          <option value="null" [disabled]="true">Days</option>
                          <option [value]="week.id" *ngFor="let week of frequencyWeekdays">{{ week.name }}</option>
                        </select>
                        <select formControlName="reset_dayMonthly" class="form-control mt-2" *ngIf="form.value.reset_frequency == 4">
                          <option value="null" [disabled]="true">Days</option>
                          <option [value]="month.id" *ngFor="let month of frequencyMonths">{{ month.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!-- End Date -->
                  <div class="col-6 form-group">
                      <label>End Date: <span class="text-danger">*</span></label>
                      <div class="input-group date">
                        <input class="hidden" [owlDateTime]="dateEndRef" [ngModel]="dateTimeStack?.dateEnd" [ngModelOptions]="{standalone: true}">
                        <input type="text" placeholder="Search" class="form-control" formControlName="end_datetime">
                        <span class="input-group-append" [owlDateTimeTrigger]="dateEndRef">
                          <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
                        </span>
                        <owl-date-time #dateEndRef></owl-date-time>
                      </div>
                  </div>  
                  <!-- Status -->
                  <div class="col-6 form-group" *ngIf="data.mode == 'edit'">
                    <label>Status: <span class="text-danger">*</span></label>
                    <select class="form-control" *ngIf="dropdown.statuses" formControlName="status">
                      <option *ngFor="let value of dropdown.statuses; let i = index" [value]="i">{{ value }}</option>
                    </select>
                  </div>
                </div>
              </section>
            </div>
          </section>
          <!-- Ticket Amount -->
          <section class="col-md-12 card border-0 form-group">
            <div class="col-12 card rounded-0 p-0">
              <div class="card-header">
                Enter Amount Setting
              </div>
              <section class="col-12 px-4 pt-4" formGroupName="raffle_event_setting_ticket_amount">
                <div class="row">
                  <div class="col-4 form-group" *ngFor="let currency of dropdown.currencies; let i = index" [formGroupName]="i">
                    <label>{{ currency.name }}: <span class="text-danger">*</span></label>
                    <div class="input-group date">
                      <input type="number" (wheel)="false" formControlName="amount_per_ticket" class="form-control" min="0" step="0.01" [ngClass]="{'is-invalid': form.get('raffle_event_setting_ticket_amount.'+i+'.amount_per_ticket').errors?.pattern}">
                      <ng-container *ngIf="form.get('raffle_event_setting_ticket_amount.'+i+'.amount_per_ticket').errors?.pattern">
                        <div class="col-12 text-danger">only allowed max 2 decimal places</div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          <!-- Message Template -->
          <section class="col-md-12 card border-0 form-group">
            <div class="col-12 card rounded-0 p-0">
              <div class="card-header">
                Message
              </div>
              <section class="col-12 px-4 pt-4">
                <div class="table-responsive mb-2">
                  <table class="table table-bordered thead-dark">
                    <thead class="table-header-bg">
                      <tr>
                        <th class="text-center">Eligible for Raffle Event</th>
                        <th class="text-center">Not Eligible for Raffle Event</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="p-4">
                          <label>SMS: </label>
                          <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'smsTemplateList'
                            [dropdownSettings] = 'messageTemplatesSettings'
                            [formName] = "'message_template_sms_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] ='smsTemplateSelectedItem'>
                          </kt-dropdown-wo-lazyload>
                          <label class="mt-4">Message: </label>
                          <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'messageTemplateList'
                            [dropdownSettings] = 'messageTemplatesSettings'
                            [formName] = "'message_template_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] ='messageTemplateSelectedItem'>
                          </kt-dropdown-wo-lazyload>
                        </td>
                        <td class="p-4">
                          <label>SMS: </label>
                          <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'smsTemplateList'
                            [dropdownSettings] = 'messageTemplatesSettings'
                            [formName] = "'non_eligible_message_template_sms_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] ='nonEligibleSmsTemplateSelectedItem'>
                          </kt-dropdown-wo-lazyload>
                          <label class="mt-4">Message: </label>
                          <kt-dropdown-wo-lazyload
                            [form] = 'form'
                            [dropdownList] = 'messageTemplateList'
                            [dropdownSettings] = 'messageTemplatesSettings'
                            [formName] = "'non_eligible_message_template_id'"
                            [selectionAttributes] = "'id'"
                            [selectedItems] ='nonEligibleMessageTemplateSelectedItem'>
                          </kt-dropdown-wo-lazyload>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </section>
        </div>
      </div>
      <!-- Footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button *ngIf="canEditEvent" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<!-- Swal Alert -->
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog()"></kt-swal-alert>
