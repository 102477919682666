<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">
        Game Result
      </h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body d-flex align-items-center">
      <ng-container *ngIf="loading; else notLoading">
        <div class="col-12 d-flex justify-content-center">
          <mat-spinner [diameter]="35"></mat-spinner>
        </div>
      </ng-container>
      <ng-template #notLoading>
        <ng-container *ngIf="resultURL != null && resultURL != 'null' && resultURL != undefined; else noData">
          <iframe [src]="resultURL | safe:'resourceUrl'"></iframe>
        </ng-container>
        <ng-template #noData>
          <div class="col-12 d-flex justify-content-center">
            <label class="control-label">
              <h5> No Data Available</h5>
            </label>
          </div>
        </ng-template>
      </ng-template>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
  </div>
</div>
