<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Merchant Bank {{ isTypeCrypto() ? '(Crypto)' : '' }}</h5>
                <span class="modal-x-button" (click)="onCloseDialog()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
            </div>
            <div class="modal-body">
                <!--Basic info-->
                <div class="row">
                    <div class="col-12 mb-3">
                        <strong>Basic info</strong>
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Currency <span class="text-danger">*</span></label>
                        <fieldset [disabled]="data.mode === 'edit' ? true : false">
                            <select (change)="onSelectCurrency(form.value.currency_id)" formControlName="currency_id" class="form-control">
                                <option [value]="null" [disabled]="true">Please Select</option>
                                <option *ngFor="let value of dropdown.currencies" [value]="value.id">{{ value.name }}</option>
                            </select>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Payment Method <span class="text-danger">*</span> </label>
                        <fieldset [disabled]="data.mode === 'edit' ? true : false">
                            <select formControlName="method" class="form-control" (change)="onSelectMethod()">
                                <option [value]="null" [disabled]="true">Please Select</option>
                                <ng-container *ngFor="let value of (isTypeCrypto() ? dropdown.cryptoMethods : dropdown.methods)">
                                    <option [value]="value.id">
                                        {{ value.name }}
                                    </option>
                                </ng-container>
                            </select>
                        </fieldset>
                    </div>
                    <div class="col-lg-6 form-group" [ngClass]="{ 'invisible': isTypeCrypto() && (!isSelectedCurrency || !form.value.method) }">
                        <label class="control-label"> {{ isTypeCrypto() ? 'Payment Gateway' : (selectedBankType == 3 ? 'Ewallet' : 'Bank') }} <span class="text-danger">*</span> </label>
                        <fieldset [disabled]="data.mode === 'edit' ? true : false">
                            <select (change)="onSelectBank()" *ngIf="isSelectedCurrency && form.value.method !== null" formControlName="bank_id" class="form-control">
                                <option [value]="null" [disabled]="true">Please Select</option>
                                <ng-container *ngFor="let value of dropdown.merchantBanks">
                                    <option *ngIf="value.id !== 0" [value]="value.id">
                                        {{ value.name }}
                                    </option>
                                </ng-container>
                            </select>
                            <select disabled *ngIf="!isSelectedCurrency || form.value.method === null" class="form-control">
                                <option></option>
                            </select>
                        </fieldset>
                    </div>
                    <!-- <div class="col-lg-6 form-group">
                        <label class="control-label"> Payment Gateway <span class="text-danger" *ngIf="+form.value.method === 2">*</span> </label>
                        <kt-dropdown-wo-lazyload
                        [form]="form"
                        [dropdownList]="dropdown.paymentGateway"
                        [selectedItems]="paymentGatewaysSelectedItems"
                        [selectionAttributes]="'id'"
                        [formName]="'payment_gateways'"
                        [dropdownSettings]="paymentGatewaysDropdownSettings"
                        ></kt-dropdown-wo-lazyload>
                    </div> -->
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Account Name <span class="text-danger">*</span> </label>
                        <input class="form-control" type="text" formControlName="account_name">
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> {{selectedBankType == 3 ? 'Ewallet Number' : 'Account Number' }} <span class="text-danger">*</span> </label>
                        <fieldset [disabled]="data.mode === 'edit' ? true : false">
                            <input class="form-control" type="text" formControlName="account_number">
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <ng-container *ngIf="selectedBankType == 3 || selectedBankType == 1">
                        <div class="col-lg-6 form-group">
                            <label class="control-label"> QR Image </label>
                            <div class="col-12">
                                <div class="row m-0 p-0">
                                    <label class="btn btn-default btn-sm float-left">
                                        <span>Choose file</span>
                                        <input type="file" #fileInput hidden accept="image/*" (change)="onUploadFile($event)" />
                                    </label>
                                    <div class="spinner-wrapper" *ngIf="isImageUploading">
                                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 form-group">
                            <ng-container *ngIf="form.get('qr_image')?.value != null">
                                <label class="control-label">Preview</label>
                                <div class="col-12">
                                    <div class="image-wrapper">
                                    <img
                                        style="max-width:50%"
                                        class="img-fluid" alt="QR Image"
                                        [src]="form.get('qr_image').value" (click)="openImage(account_name, form.get('qr_image').value)"/>
                                    </div>
                                    <br>
                                    <button type="button" class="btn btn-secondary" (click)="onRemoveFile()">Remove File</button>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <div class="col-lg-6 form-group">
                        <label class="control-label">Type</label>
                        <select class="form-control" formControlName="type">
                            <option [value]="null" hidden>Please Select</option>
                            <ng-container *ngFor="let value of dropdown.types">
                                <option [value]="value.id">{{ value.name }}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-lg-6 form-group" *ngIf="data.mode === 'edit' && +form.value.method == 2">
                        <label class="control-label"> Status</label>
                        <select class="form-control" formControlName="status">
                            <ng-container *ngFor="let key of object.keys(status)">
                                <option [value]="key" *ngIf="+key >= 0">{{ status[key] }}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-lg-12 form-group">
                        <label class="control-label"> Member Groups <span class="text-danger">*</span></label>
                        <div class="pl-0">
                            <kt-dropdown-wo-lazyload
                            [form]="form"
                            [dropdownList]="memberGroupDropdownList"
                            [selectedItems]="memberGroupSelectedItems"
                            [selectionAttributes]="'id'"
                            [formName]="'member_groups'"
                            [dropdownSettings]="memberGroupDropdownSettings"
                            ></kt-dropdown-wo-lazyload>
                        </div>
                    </div>
                </div>
                <mat-tab-group
                    animationDuration="0ms"
                    class="form-group"
                    [(selectedIndex)]="selectedIndex"
                    *ngIf="form.value.currency_id && (+form.value.method == 2 || +form.value.method == 4)"
                    style='min-height:800px'
                >
                    <mat-tab *ngFor="let tab of tabs; let i = index">
                        <ng-template mat-tab-label>
                            <span>{{tab.label}}</span>
                            <span class="modal-x-button close-button" (click)="removeTab(i)">
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </span>
                        </ng-template>
                        <merchant-bank-transaction-config-edit
                            [detail]="tab.detail"
                            [mode]="data.mode"
                            [currency_code]="selectedCurrencyCode"
                            [method]="+form.value.method"
                        >
                            
                        </merchant-bank-transaction-config-edit>
                    </mat-tab>
                    <mat-tab *ngIf="!isAddingTab">
                        <ng-template mat-tab-label>
                            <button class="btn btn-primary btn-icon-sm float-right btn-cus" (click)="toggleAddingTab()"><i class="fas fa-plus"></i>Add</button>
                        </ng-template>
                    </mat-tab>
                    <mat-tab *ngIf="isAddingTab">
                        <ng-template mat-tab-label>
                            <span (click)="openSelect()">Please Select</span>
                            <mat-select #selectElement placeholder="Select a tab" formControlName="selectedTab" (selectionChange)="addTab()" [panelClass]="'add-tab-dropdown'">
                                <mat-option [value]="null" disabled selected>Select a tab</mat-option>
                                <mat-option *ngFor="let option of dropdown.paymentGateway" [value]="option.id">
                                {{ option.name }}
                                </mat-option>
                            </mat-select>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
                <div class="row" *ngIf="form.value.currency_id && (+form.value.method == 1 || +form.value.method == 3)">
                    <merchant-bank-transaction-config-edit
                            style="padding: 1.25rem;"
                            [detail]="data.mode === 'edit' ? data.bank.merchant_bank_transaction_configs[0] : {}"
                            [mode]="data.mode"
                            [currency_code]="selectedCurrencyCode"
                            [method]="+form.value.method"
                        >
                            
                    </merchant-bank-transaction-config-edit>
                </div>

                <ng-container *ngIf="selectedBankType === 1">
                <div class="row">
                    <!--Bot Configs-->
                    <div class="col-12 mb-3 mt-2">
                        <strong>Bot Configs</strong>
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Username <span class="text-danger">*</span> </label>
                        <input class="form-control" type="text" formControlName="username">
                    </div>
                    <div class="col-lg-6 form-group">
                        <label class="control-label"> Password 
                            <!-- <span class="text-danger" *ngIf="data.mode === 'create'">*</span> --> 
                        </label>
                        <input class="form-control" type="text" formControlName="password">
                    </div>
                    <div class="col-md-2 col-6 form-group">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" formControlName="business" class="custom-control-input" id="businessCheck">
                            <label class="custom-control-label" for="businessCheck"> Business </label>
                        </div>
                    </div>
                    <div class="col-6 form-group">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" formControlName="sync" class="custom-control-input" id="syncCheck">
                            <label class="custom-control-label" for="syncCheck">Sync 
                                <br>
                                <span class="text-danger" *ngIf="data.mode === 'create'">Please tick if want to setup with bank bot</span>
                            </label>
                        </div>
                    </div>
                </div>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
                <kt-submit-button [isDisabled]="!form.valid || !minMaxValid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.bank, data.mode)"></kt-submit-button>
            </div>
        </div>
    </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
