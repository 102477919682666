import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { MemberDataService } from '../members/services/member-data.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { specialPermissions } from '@core/store/auth/auth.selectors';
import { AccountManagement } from '@core/models/account-management.model';
import { AccountManagementDataService } from './../account-management/services/account-management-data.service';
import { MemberInformationDialogComponent } from '../members/dialogs/member-information/member-information.component';
import Swal from 'sweetalert2';
import { AccountManagementLogDialogComponent } from './dialogs/account-managment-log/account-management-log-dialog.component';
import { AccountManagementKpiSettingsDialogComponent } from './dialogs/kpi-settings/kpi-settings-dialog.component';
import { AssignLeadDialogComponent } from './dialogs/assign-lead/assign-lead-dialog.component';
import { AccountManagementRemarkComponent } from './dialogs/account-management-remark/account-management-remark.component';
import { MemberEntityService } from '../members/services/member-entity.service';
import { MemberRewardsDialogComponent } from '../members/dialogs/member-rewards/member-rewards.component';
import { DateTime } from 'luxon';

@Component({
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.scss']
})
export class AccountManagementComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('textArea', { read: ElementRef }) textArea: ElementRef;
  form: FormGroup;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    accountManager: this.dropdownHttpService.accountManager,
    perPage: this.dropdownHttpService.perPage,
    statuses: [
      {
        name: 'Closed', value: 0, checked: false
      },
      {
        name: 'Active', value: 1, checked: true
      },
      {
        name: 'Failed', value: 2, checked: false
      },
    ],
    developmentStage: this.dropdownHttpService.developmentStage,
    normalLeadStage: this.dropdownHttpService.normalLeadStage,
    vipLeadStage: this.dropdownHttpService.vipLeadStage,
  };
  messages$ = this.AccountManagementDataService.messages$;
  data$ = this.AccountManagementDataService.data$;

  dropdownSettings = {};
  AccountManagement$: Observable<AccountManagement[]>;
  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  pagination: Pagination;
  maxSize = 5;
  pageSize = 30;
  page = 1;
  params = '';
  buttonLoading = false;
  currentContact = '';
  selectedContactType = 'Mobile';
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone: any;
  todayDate: any;
  searchStatus = [1];

  default_sort_by = 'id';
  default_sort_order = 'desc';

  sortingConfig = {
    'id': 'desc',
    'development_stage': 'desc',
    'lead_stage': 'desc',
    'lead_assigned_datetime': 'desc',
    'username': 'desc',
    'name': 'desc',
    'member_group': 'desc',
    'mobile': 'desc',
    'date_of_birth': 'desc',
    'locale': 'desc',
    'currency': 'desc',
    'account_manager': 'desc',
    'kpi_deposit_count': 'desc',
    'kpi_deposit_amount': 'desc',
    'last_activity': 'desc',
    'last_login': 'desc',
    'last_deposit': 'desc',
    'lead_validity_datetime': 'desc',
    'status': 'desc',
    'fav_game_category': 'desc',
  };
  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order
  };
  dataLength: number;
  access$ = this.authHttpService.getUserAccess(13, 'All Leads');
  userData = JSON.parse(localStorage.getItem('user_data'));
  isAdmin: boolean = JSON.parse(localStorage.getItem('user_data')).is_admin;
  userPermissions$ = this.store.pipe(select(specialPermissions));

  // permissions
  canViewMemberDialog: boolean;
  canViewRemarkHistory: boolean;
  canAssignLeads: boolean;
  canEditRemark: boolean;
  canExportAccountManagement: boolean;
  canViewKPISetting: boolean;
  canAssignReward: boolean;
  canAssignLead: boolean;
  canViewAccountManagerHistory: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    public loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private AccountManagementDataService: AccountManagementDataService,
    private memberDataService: MemberDataService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
    private store: Store<AppState>,
    private memberService: MemberEntityService,
  ) { }

  ngOnInit() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.todayDate = DateTime.now().setZone(this.timezone);
    this.setCurrencyDropdown();
    this.formInit();
    this.pagination = this.AccountManagementDataService.pagination;
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
    this.ranges = this.transactionHttpService.ranges;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.account_management_view_member_dialog;
      this.canViewRemarkHistory = appPermissions.account_management_view_remark_history;
      this.canEditRemark = appPermissions.account_management_edit_remark;
      this.canViewKPISetting = appPermissions.view_kpi_settings;
      this.canExportAccountManagement = appPermissions.export_account_management;
      this.canViewAccountManagerHistory = appPermissions.view_account_manager_history;
      this.canAssignReward = appPermissions.account_management_assign_rewards;
      this.canAssignLead = appPermissions.account_management_assign_leads;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        tap((data) => {
          if (data['start_date']) {
            data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
            data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          }

          this.params = Object.keys(data)
            .map((key) => key + "=" + data[key])
            .join("&");
          const statusParams = this.generateStatusParams();
          this.params = this.params.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
          this.sortingConfig[this.sortingSelection.sort_by] = 'desc';
          this.sortingSelection.sort_by = this.default_sort_by;
          this.sortingSelection.sort_order = this.default_sort_order;
          this.sortingConfig[this.default_sort_by] = this.default_sort_order;
          this.page = 1;
          this.onViewPageBy();
        })
      )
      .subscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : "";
    this.loadingBar.start();
    return (this.AccountManagement$ = this.AccountManagementDataService
      .getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`)
      .pipe(
        tap(res => {
          this.dataLength = res.length;
          this.pagination = this.AccountManagementDataService.pagination;
          this.loadingBar.useRef().complete();
          this.onSearch = true;
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
        }),
        catchError((err) => {
          this.dataLength = 0;
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.loadingBar.useRef().complete();
          return throwError(err);
        })
      ));
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private generateStatusParams() {
    return {
      newStatusParams: Object.keys(this.searchStatus).map(key => `status[${key}]=${this.searchStatus[key]}`).join('&'),
      oldStatusParams: 'status=' + Object.keys(this.searchStatus).map(key => this.searchStatus[key]).join(','),
    };
  }

  onOpenDialog(type: string, id?: number, data?: any, option?: any) {
    if (type === 'member-information') {
      if (!this.canViewMemberDialog) {
        Swal.fire('Permission Error', 'You do not have permission to view member information', 'error');
        return;
      }

      this.subscription = this.memberDataService.getById(id, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}
      &end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&additional_info=1`).pipe(
        tap((res) => {
          if (res) {
            this.openDialogBy(MemberInformationDialogComponent, { mode: type, member: res });
          }
        })
      ).subscribe();
    } else if (type === 'account-management-logs') {
      this.openDialogBy(AccountManagementLogDialogComponent, { mode: type, member: data });
    } else if (type === 'kpi-settings') {
      this.openDialogBy(AccountManagementKpiSettingsDialogComponent, { mode: type });
    } else if (type === 'assign-lead') {
      this.openDialogBy(AssignLeadDialogComponent, { mode: type });
    } else if (type === 'remarks') {
      this.openDialogBy(AccountManagementRemarkComponent, {mode: type, member: data})
    } else if (type === 'show-remarks') {
      this.openDialogBy(AccountManagementRemarkComponent, {mode: type, member: data})
    } else if (type === 'assign-rewards') {
      const member = this.memberService.getByKey(id);
      this.subscription = member.pipe(
        tap((res) => {
          if (res) {
            this.openDialogBy(MemberRewardsDialogComponent, { member: res, mode: 'member_reward_by_account_management' });
          }
        })
      )
        .subscribe();  
    }
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, member?: any}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'kpi-settings' || data.mode ===  'remarks' || data.mode === 'member_reward_by_account_management' ? '800px' : data.mode === 'assign-lead' ? '900px' : '1500px',
      height: data.mode === 'assign-lead' || data.mode === 'kpi-settings' || data.mode ===  'account-management-logs' || data.mode === 'remarks' || data.mode === 'show-remarks' || data.mode === 'member_reward_by_account_management' ? 'auto' : '80vh',
      data: {
        mode: data.mode,
        member: data.member,
        accountManagement: data.member
      },
      autoFocus: false
    });
    if (data.mode === 'assign-lead' || data.mode === 'remarks') {
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          if (data.mode === 'assign-lead') {
            this.page = 1;
          }
          this.onClear();
        }
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.selectedContactType = 'Mobile';
    this.searchStatus = [1];
    this.dropdown.statuses.forEach((child) => {
      if (child.name === 'Active') {
        child.checked = true;
      } else {
        child.checked = false;
      }
    });
    this.form.patchValue({
      username: null,
      name: null,
      mobile: null,
      email: null,
      currency_id: 'all',
      account_manager: null,
      date_type: 'lead_assigned_datetime',
      start_date: null,
      end_date: null,
      defaultDate: null,
      development_stage: null,
      lead_stage: null,
      status: this.searchStatus,
      dummy: 1
    })
    this.onSubmit(true);
  }

  onChangeStatus(event: any, id: number, ind: number) {
    const position = this.searchStatus.indexOf(id);
    if (event.target.checked) {
      this.searchStatus.push(id);
      this.dropdown.statuses[ind].checked = true;
    } else {
      this.searchStatus.splice(position, 1);
      this.dropdown.statuses[ind].checked = false;
    }
    this.form.patchValue({ status: this.searchStatus });
  }

  onContactType(label: string) {
    this.selectedContactType = label;
    this.contactChanges();
    this.cdr.detectChanges();
  }

  onContact(event: Event) {
    this.currentContact = (event.target as HTMLInputElement).value;
    this.contactChanges();
    this.cdr.detectChanges();
  }

  private contactChanges() {
    if (this.selectedContactType !== '' || this.selectedContactType !== null) {

      if (this.selectedContactType === 'Mobile') {
        this.form.patchValue({
          [this.selectedContactType.toLocaleLowerCase()]: this.currentContact,
          email: null,
        });
      }
      if (this.selectedContactType === 'Email') {
        this.form.patchValue({
          mobile: null,
          [this.selectedContactType.toLocaleLowerCase()]: this.currentContact,
        });
      }
    }
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      name: new FormControl(null),
      mobile: new FormControl(null),
      email: new FormControl(null),
      currency_id: new FormControl('all'),
      account_manager: new FormControl(null),
      date_type: new FormControl('lead_assigned_datetime'),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      defaultDate: new FormControl(null),
      development_stage: new FormControl(null),
      lead_stage: new FormControl(null),
      status: new FormControl(this.searchStatus),
      dummy: new FormControl(1)
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && formData[key] !== false && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onExport() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.buttonLoading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        data['paginate'] = false;
        data['timezone'] = this.timezone;

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const statusParams = this.generateStatusParams();
        this.params = this.params.replace(statusParams.oldStatusParams, statusParams.newStatusParams);
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start(); 
        return this.AccountManagementDataService.export(`?${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.buttonLoading = false;
          }),
          catchError((err) => {
            this.buttonLoading = false;
            this.loadingBar.complete();
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  isKpiAchieve(kpi, currentValue) {
    return +currentValue >= +kpi;
  }

  calculateDayLeft(date: any){
    // Target date
    const targetDate = DateTime.fromISO(date, { zone: this.timezone });

    // Check if the target date is today or in the past
    if (targetDate <= this.todayDate || targetDate.hasSame(this.todayDate, 'day')) {
      return 0;
    }

    // Calculate the difference in days
    const daysLeft = targetDate.startOf('day').diff(this.todayDate.startOf('day'), 'days').days;
    return daysLeft > 0 ? `${Math.ceil(daysLeft)}` : 0;
  }

  onChangeDummy($event) {
    let isChecked = $event.target.checked ? 1 : 0;
    this.form.patchValue({
      dummy: isChecked
    });
  }
}
