import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AllEvents } from '@core/models/all-events.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import * as moment from 'moment-timezone';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { DatePipe } from '@angular/common';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { forkJoin, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AllEventsDataService } from '../../services/all-events-data.service';
import { MessageTemplateDataService } from '@views/pages/apps/superuser/message-template/services/message-template-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';


@Component({
  selector: 'kt-all-events-edit',
  templateUrl: './all-events-edit.component.html',
  styleUrls: ['./all-events-edit.component.scss']
})
export class AllEventsEditDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;

  form: FormGroup;

  messages$ = this.allEventsDataService.messages$;
  predictionTypeIs1 = false;

  dropdown = {
    currencies: this.data.currencies,
    predictionType: this.dropdownHttpService.predictionTypes,
    eventGroupTypes: this.dropdownHttpService.eventGroupTypes,
    statuses: [...this.dropdownHttpService.statuses, 'Ended'],
    smstemplates: this.messageTemplateDataService.getMessageTemplateList(2, 10), // Get sms templates with type = 2 (SMS) and section = 10 (Events)
    messagetemplates: this.messageTemplateDataService.getMessageTemplateList(1, 10) // Get message templates with type = 1 (Message) and section = 10 (Events)
  };

  dateTimeStack = {
    publishDateStart: null,
    publishDateEnd: null,
    predictionDateStart: null,
    predictionDateEnd: null,
    grandFinalDate: null
  };
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
  dateTimeFormat = 'yyyy-MM-dd HH:mm:';

  buttonLoading = false;

  messageTemplatesSettings = {};
  messageTemplateList = [];
  messageTemplateSelectedItem = [];
  smsTemplateList = [];
  smsTemplateSelectedItem = [];
  nonEligibleMessageTemplateSelectedItem = [];
  nonEligibleSmsTemplateSelectedItem = [];

  private datePickerSubscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  canEditEvent: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { row: AllEvents, mode: string; currencies: any },
    public dialogRef: MatDialogRef<AllEventsEditDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private datePipe: DatePipe,
    private allEventsDataService: AllEventsDataService,
    private messageTemplateDataService: MessageTemplateDataService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.setMessagesDropdown();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditEvent = appPermissions.edit_event;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'publish_date_start'),
      this.buildDatePicker(1, 'publish_date_end'),
      this.buildDatePicker(2, 'prediction_date_start'),
      this.buildDatePicker(3, 'prediction_date_end'),
      this.buildDatePicker(4, 'grand_final')
    ]).subscribe();
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onSave() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;
    this.buttonLoading = true;

    const data = {
      ...this.form.value,
      publish_date_start: this.isValidDate(this.form.value.publish_date_start) ? moment(this.form.value.publish_date_start).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') : null,
      publish_date_end: this.isValidDate(this.form.value.publish_date_end) ? moment(this.form.value.publish_date_end).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') : null,
      prediction_date_start: this.isValidDate(this.form.value.prediction_date_start) ? moment(this.form.value.prediction_date_start).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') : null,
      prediction_date_end: this.isValidDate(this.form.value.prediction_date_end) ? moment(this.form.value.prediction_date_end).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') : null,
      grand_final: this.isValidDate(this.form.value.grand_final) ? moment(this.form.value.grand_final).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss') : null,
    };

    if (this.data.mode == 'create') {
      this.allEventsDataService.addEvent(data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          throw error;
        })
      ).subscribe();
    } else {
      this.allEventsDataService.updateEvent(this.data.row.id, data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          throw error;
        })
      ).subscribe();
    }
  }

  onCloseDialog() {
    this.dialogRef.close(true);
  }

  onChangePredictionType(predictionType) {
    this.predictionTypeIs1 = predictionType === '1' ? true : false;
    this.form.patchValue({
      number_of_teams: null,
      winning_score: null
    })
    //default number of team for other prediction should be 2 as we are going to predict for home and away team score
    if (predictionType !== '1') {
      this.form.patchValue({
        number_of_teams: 2
      })
      this.form.get('winning_score').setValidators(Validators.required);
      this.form.get('winning_score').updateValueAndValidity();
    }
    else {
      this.form.get('number_of_teams').setValidators(Validators.required);
      this.form.get('number_of_teams').updateValueAndValidity();
    }
  }

  onChangeGroupType(groupType) {
    this.form.patchValue({
      group_type: groupType
    })
  }

  private formInit() {
    if (this.data.mode == 'edit') {
      this.predictionTypeIs1 = this.data.row.prediction_type === 1 ? true : false;
    }
    // Define default value
    let
      name = this.data.mode == 'edit' ? this.data.row.name : null,
      code = this.data.mode == 'edit' ? this.data.row.code : null,
      prediction_type = this.data.mode == 'edit' ? this.data.row.prediction_type : null,
      winning_score = this.data.mode == 'edit' ? this.data.row.winning_score : null,
      group_type = this.data.mode == 'edit' ? this.data.row.group_type : null,
      number_of_teams = this.data.mode == 'edit' ? this.data.row.number_of_teams : null,
      publish_date_start = this.data.mode == 'edit' ? this.isValidDate(this.data.row?.publish_date_start) ? moment(new Date(new Date(this.data.row.publish_date_start).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null : null,
      publish_date_end = this.data.mode == 'edit' ? this.isValidDate(this.data.row?.publish_date_end) ? moment(new Date(new Date(this.data.row.publish_date_end).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null : null,
      prediction_date_start = this.data.mode == 'edit' ? this.isValidDate(this.data.row?.prediction_date_start) ? moment(new Date(new Date(this.data.row.prediction_date_start).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null : null,
      prediction_date_end = this.data.mode == 'edit' ? this.isValidDate(this.data.row?.prediction_date_end) ? moment(new Date(new Date(this.data.row.prediction_date_end).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null : null,
      grand_final = this.data.mode == 'edit' ? this.isValidDate(this.data.row?.grand_final) ? moment(new Date(new Date(this.data.row.grand_final).getTime() + this.offset - this.clientOffset)).format('YYYY-MM-DD HH:mm:ss') : null : null,
      seed_amount = this.data.mode == 'edit' ? this.data.row.seed_amount : null,
      amount_contribution = this.data.mode == 'edit' ? this.data.row.amount_contribution : null,
      status = this.data.mode == 'edit' ? this.data.row.status : 1,
      smsTemplate = this.data.mode == 'edit' ? this.data.row.message_template_sms_id : 0,
      messageTemplate = this.data.mode == 'edit' ? this.data.row.message_template_id : 0,
      nonEligibleSmsTemplate = this.data.mode == 'edit' ? this.data.row.non_eligible_message_template_sms_id : 0,
      nonEligibleMessageTemplate = this.data.mode == 'edit' ? this.data.row.non_eligible_message_template_id : 0;

    // Set date time if edit
    if (this.data.mode == 'edit') {
      this.dateTimeStack = {
        publishDateStart: this.data.row.publish_date_start !== null ? new Date(new Date(this.data.row.publish_date_start).getTime() + this.offset - this.clientOffset) : null,
        publishDateEnd: this.data.row.publish_date_end !== null ? new Date(new Date(this.data.row.publish_date_end).getTime() + this.offset - this.clientOffset) : null,
        predictionDateStart: this.data.row.prediction_date_start !== null ? new Date(new Date(this.data.row.prediction_date_start).getTime() + this.offset - this.clientOffset) : null,
        predictionDateEnd: this.data.row.prediction_date_end !== null ? new Date(new Date(this.data.row.prediction_date_end).getTime() + this.offset - this.clientOffset) : null,
        grandFinalDate: this.data.row.grand_final !== null ? new Date(new Date(this.data.row.grand_final).getTime() + this.offset - this.clientOffset) : null
      };
    }

    // Build sub form for ticket amount
    const buildTicketAmount = () => {
      let result = {};
      this.dropdown.currencies.forEach((item, index) => {
        const ticketAmountGroup = new FormGroup({
          settings_currency_id: new FormControl(item.id),
          amount_per_ticket: new FormControl(this.data.row?.ticket_amounts.find(x => x.settings_currency_id === item.id)?.amount_per_ticket, [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)])
        })
        result = { ...result, [index]: ticketAmountGroup };
      });
      return result;
    }

    // Build main form
    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      code: new FormControl({ value: code, disabled: this.data.mode === 'edit' }, [Validators.required, Validators.pattern(/^\S*$/)]),
      prediction_type: new FormControl(prediction_type, [Validators.required]),
      winning_score: new FormControl(winning_score),
      group_type: new FormControl(group_type, [Validators.required]),
      number_of_teams: new FormControl(number_of_teams),
      publish_date_start: new FormControl(publish_date_start, [Validators.required]),
      publish_date_end: new FormControl(publish_date_end, [Validators.required]),
      prediction_date_start: new FormControl(prediction_date_start, [Validators.required]),
      prediction_date_end: new FormControl(prediction_date_end, [Validators.required]),
      grand_final: new FormControl(grand_final, [Validators.required]),
      seed_amount: new FormControl(seed_amount, [Validators.required, Validators.pattern('^(?=.*[1-9])[0-9]+(\.[0-9]{0,2})?$')]),
      amount_contribution: new FormControl(amount_contribution, [Validators.required, Validators.pattern('^(?=.*[1-9])[0-9]+(\.[0-9]{0,2})?$')]),
      status: new FormControl(status, [Validators.required]),
      event_setting_ticket_amount: new FormGroup(buildTicketAmount()),
      message_template_id: new FormControl(messageTemplate),
      message_template_sms_id: new FormControl(smsTemplate),
      non_eligible_message_template_id: new FormControl(nonEligibleMessageTemplate),
      non_eligible_message_template_sms_id: new FormControl(nonEligibleSmsTemplate),
    });
  }

  private isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, formKey.includes('start') ? this.dateTimeFormat + '00' : this.dateTimeFormat + '59')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      })
    );
  }

  private setMessagesDropdown() {
    // Message Templates Dropdown Settings
    this.messageTemplatesSettings = {
      maxHeight: 200,
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'code',
      showCheckbox: false,
      autoPosition: false,
      position: 'bottom',
    };

    // Dropdown list for SMS templates
    this.dropdown.smstemplates.subscribe(res => {
      this.smsTemplateList = res;
      if (this.data.mode === 'edit') {
        if (this.data.row.message_template_sms_id !== 0) {
          this.smsTemplateSelectedItem = [
            this.smsTemplateList.find(x => x.id === this.data.row.message_template_sms_id) ?
              this.smsTemplateList.find(x => x.id === this.data.row.message_template_sms_id) : []
          ];
        }

        if (this.data.row.non_eligible_message_template_sms_id !== 0) {
          this.nonEligibleSmsTemplateSelectedItem = [
            this.smsTemplateList.find(x => x.id === this.data.row.non_eligible_message_template_sms_id) ?
              this.smsTemplateList.find(x => x.id === this.data.row.non_eligible_message_template_sms_id) : []
          ];
        }

      }
    });
    // Dropdown list for Message templates
    this.dropdown.messagetemplates.subscribe(res => {
      this.messageTemplateList = res;
      if (this.data.mode === 'edit') {
        if (this.data.row.message_template_id !== 0) {
          this.messageTemplateSelectedItem = [
            this.messageTemplateList.find(x => x.id === this.data.row.message_template_id) ?
              this.messageTemplateList.find(x => x.id === this.data.row.message_template_id) : []
          ];
        }

        if (this.data.row.non_eligible_message_template_id !== 0) {
          this.nonEligibleMessageTemplateSelectedItem = [
            this.messageTemplateList.find(x => x.id === this.data.row.non_eligible_message_template_id) ?
              this.messageTemplateList.find(x => x.id === this.data.row.non_eligible_message_template_id) : []
          ];
        }
      }
    });
  }
}
