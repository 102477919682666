import { GameProviderDataService } from './../game-providers/services/game-provider-data.service';
import { MatDialog } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Status } from '@core/enums/status.enum';
import { FormGroup, FormControl } from '@angular/forms';
import { MiniGameDataService } from './service/mini-game-data.service';
import { Pagination } from '@core/models/pagination.model';
import { MiniGameEntityService } from './service/mini-game-entity.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { tap, map, exhaustMap, catchError } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MiniGame } from '@core/models/mini-games.model';
import { ActivatedRoute } from '@angular/router';
import { MiniGamesEditDialogComponent } from './dialogs/mini-games-edit.component';
import { AuthHttpService } from '@core/services/auth-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-mini-games',
  templateUrl: './mini-games.component.html',
  styleUrls: ['./mini-games.component.scss']
})
export class MiniGamesComponent implements OnInit, OnDestroy {

  miniGames$: Observable<MiniGame[]>;
  messages$ = this.miniGameDataService.messages$;
  localMiniGames: any;
  status = Status;
  category = this.route.snapshot.paramMap.get('category');
  provider = this.route.snapshot.paramMap.get('provider');
  params = `category_code=${this.category}&game_provider_code=${this.provider}`;
  access$ = this.authHttpService.getUserAccess(15, 'All Game Providers');
  button_loading = false;

  form: FormGroup;
  pagination: Pagination;
  pageSize = 15; // Only for GameSys
  page = 1;
  maxSize = 5;
  isPaginated = 1;
  games$: any;
  games: any;
  perPageDropdown = this.dropdownHttpService.perPage;

  subCategoryDropdown = [];
  gameProvidersDropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'name',
    labelKey: 'labelKey',
    lazyLoading: true,
    noDataLabel: '',
    maxHeight: 150,
    showCheckbox: false
  };
  dropdown = {
    status: this.dropdownHttpService.miniGameStatus,
    gameTag:[]
  }

  gameTagsDropdownSettings = {};
  selectedGameTags=[];
  selectedSubCategoryName=[];

  dataLength = 0;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;

  // permissions
  canEditMiniGame: boolean;
  canCreateMiniGame: boolean;
  canExportMiniGameList: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    public loadingBar: LoadingBarService,
    private miniGameEntityService: MiniGameEntityService,
    private route: ActivatedRoute,
    private miniGameDataService: MiniGameDataService,
    private dropdownHttpService: DropdownHttpService,
    private dialog: MatDialog,
    private gameProviderDataService: GameProviderDataService,
    private authHttpService: AuthHttpService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.loadingBar.start();
    this.reload();
    this.formInit();
    this.pagination = this.miniGameDataService.pagination;

    this.gameProviderDataService.getSubCategory(`?game_provider_code=${this.provider}&category_code=${this.category}`).subscribe(res => {
      this.subCategoryDropdown = res.map(x => ({'id': x.id, 'name': x.name, 'labelKey': x.name}));
    });

    this.setGameTagsDropdown();

    if (sessionStorage.getItem('mini_game_provider') === null) {
      window.location.href = '/game/providers';
    }

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditMiniGame = appPermissions.edit_mini_game;
      this.canCreateMiniGame = appPermissions.create_mini_game;
      this.canExportMiniGameList = appPermissions.export_mini_game_list;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    sessionStorage.removeItem('mini_game_provider')
  }

  private setGameTagsDropdown(){
    this.dropdownHttpService.gameTags.subscribe(res => {
      this.dropdown.gameTag = res.map(x => ({'id': x.id, 'name': x.name}));
    });

    this.gameTagsDropdownSettings = {
      autoPosition: true,
      maxHeight: 150,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
  }

  setSession() {
    sessionStorage.setItem('recent_page', JSON.stringify('mini-games'));
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';

    if (this.isPaginated) {
      params += '&paginate=1';
    }
    this.loadingBar.start();

    return this.miniGames$ = this.miniGameEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.miniGameDataService.pagination;
        this.dataLength = res.length;
        this.loading = false;

        this.games = JSON.parse(JSON.stringify(this.miniGameDataService.miniGames));

        this.games.map(item => {
          var imageExist = this.localMiniGames.filter((x) => (x.game_category_code + x.game_provider_code + x.code) == (item.game_category_code + item.game_provider_code + item.code));
          if (imageExist.length) {
            item.local_image_path = imageExist[0]['image_path'];
          }
          else {
            item.local_image_path = '';
          }
        });

        this.games$ = this.games.map(game => {
          return {
            ...game,
            tag: game.tags.map(tag => tag.toUpperCase())
          };
        });

        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(formMode: string, miniGame?: any) {
    const data = {
      ...miniGame,
      provider_code: this.provider,
      category_code: this.category,
    };
    this.openDialogBy(MiniGamesEditDialogComponent, { gameProvider: data, mode: formMode }); //
  }

  onClear() {
    this.clearBtnLoading = true;
    this.selectedGameTags = [];
    this.selectedSubCategoryName = [];
    this.formInit();
    this.onSubmit(true);
  }

  onExport() {
    this.button_loading = true;
    let paramsRef;
    const data = {
      ...this.form.value
    };
    const subCategoryParams = this.generateSubCategoryParams();
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] === 'all' || data[key] === undefined || data[key].length === 0) && delete data[key]);
    paramsRef = Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&');
    const withSubCategoryParams = subCategoryParams.newSubCategoryParam !== '' ?
      paramsRef.replace(subCategoryParams.oldSubCategoryParam, subCategoryParams.newSubCategoryParam)
      : paramsRef;
    this.params = (withSubCategoryParams ? withSubCategoryParams : '') + `&category_code=${this.category}&game_provider_code=${this.provider}`;
    this.loadingBar.start();
    return this.miniGameDataService.export(this.params).pipe(
      tap(res => {
        this.loadingBar.complete();
        this.button_loading = false;
      }),
      catchError((error) => {
        this.loadingBar.complete();
        this.button_loading = false;
        throw error;
      })
    ).subscribe();
  }

  async onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    this.loadingBar.start();

    await this.getImages();

    const pageSize = this.pageSize;
    let paramsRef;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        const subCategoryParams = this.generateSubCategoryParams();
        paramsRef = Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&');
        const withSubCategoryParams = subCategoryParams.newSubCategoryParam !== '' ?
          paramsRef.replace(subCategoryParams.oldSubCategoryParam, subCategoryParams.newSubCategoryParam)
          : paramsRef;
        this.params = (withSubCategoryParams ? '&' + withSubCategoryParams : '') + `&category_code=${this.category}&game_provider_code=${this.provider}`;
        return this.miniGames$ = this.miniGameEntityService.getWithQuery(`?page=1&perPage=${pageSize}&paginate=1${this.params}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.page = 1;
            this.dataLength = res.length;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.pagination = this.miniGameDataService.pagination;

            this.games = JSON.parse(JSON.stringify(this.miniGameDataService.miniGames));

            this.games.map(item => {
              var imageExist = this.localMiniGames.filter((x) => (x.game_category_code + x.game_provider_code + x.code) == (item.game_category_code + item.game_provider_code + item.code));
              if (imageExist.length) {
                item.local_image_path = imageExist[0]['image_path'];
              }
              else {
                item.local_image_path = '';
              }
            });

            this.games$ = this.games.map(game => {
              return {
                ...game,
                tag: game.tags.map(tag => tag.toUpperCase())
              };
            });

            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  private generateSubCategoryParams() {
    let newParams = '';
    let oldParams = 'sub_category_name=';
    if (this.form.value.sub_category_name.length > 0) {
      this.form.value.sub_category_name.map((res) => newParams += `sub_category_name=${res}`).join('&');
      this.form.value.sub_category_name.map(res => oldParams += res).join(',');
    }
    return {
      oldSubCategoryParam: oldParams,
      newSubCategoryParam: newParams
    };
  }

  private openDialogBy(componentRef: any, data?: { gameProvider?: any, mode?: string }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        gameProvider: data.gameProvider,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        this.loading = true;
        this.loadingBar.start();
        await this.getImages();
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  async reload() {
    await this.getImages();
    this.onViewPageBy(this.page).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(),
      code: new FormControl(),
      image: new FormControl(''),
      status: new FormControl(''),
      game_tag:new FormControl([]),
      sub_category_name: new FormControl([])
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null) ? fields[key] = formData[key] : key);
    return fields;
  }

  private getImages() {
    return this.gameProviderDataService.getGameImages().pipe(
      tap(res => this.localMiniGames = res)
    ).toPromise();
  }
}
