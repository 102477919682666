import { Component, Inject, OnInit, EventEmitter, Output } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { EventEmitterService } from '@core/services/event-emitter.service';

@Component({
  selector: 'kt-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<PushNotificationComponent>,
    private sanitizer: DomSanitizer,
    private router: Router,
    private eventEmitterService: EventEmitterService,
  ) { }

  ngOnInit(): void { }

  onCloseNotification() {
    // dismiss with action, push notification assume has been read
    this.snackBarRef.dismissWithAction();
  }
  
  onNavigate() {
    // close the snack first
    this.onCloseNotification();

    let type = JSON.parse(this.data.type);
    if( 'autoLoadKey' in type && 'autoLoadData' in type ) {
      if( this.router.url == type.link ) {
        this.eventEmitterService.onNotificationRedirect(type);
      } else {
        localStorage.setItem(type.autoLoadKey, type.autoLoadData);
      }
    }
    
    this.router.navigateByUrl(type.link);
  }

  sanitize(content: any) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}
