import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
} from "@ngrx/data";
import { Injectable } from "@angular/core";
import { RegistrationReport } from "@core/models/registration-report.model";

@Injectable()
export class RegistrationReportEntityService extends EntityCollectionServiceBase<RegistrationReport> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super("RegistrationReport", serviceElementsFactory);
  }
}
