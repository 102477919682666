<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-content" class="modal-title">{{ data.mode !== 'create' ? 'Edit' : 'Create New'}} Game</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 form-group">
            <label class="control-label">Provider Code <span class="text-danger">*</span></label>
            <input type="text" formControlName="game_provider_code" class="form-control" disabled>
          </div>
          <div class="col-md-6 form-group required">
            <label class="control-label">Sub Category Name <span class="text-danger">*</span></label>
            <select formControlName="game_sub_category_name" class="form-control" disabled>
              <option *ngFor="let value of subCategoryDropdown" [value]="value.name">{{ value.name }}</option>
            </select>
          </div>
          <div class="col-md-6 form-group required">
            <label class="control-label">Name <span class="text-danger">*</span></label>
            <input type="text" formControlName="name" class="form-control">
          </div>
          <div class="col-md-6 form-group required">
            <label class="control-label">Code <span class="text-danger">*</span></label>
            <input type="text" formControlName="code" class="form-control" disabled>
          </div>
          <div class="col-md-6 form-group">
            <label class="control-label">Status <span class="text-danger">*</span></label>
            <select formControlName="status" class="form-control">
              <option *ngFor="let item of dropdown.status" [value]="item.id">{{ item.name }}</option>
            </select>
          </div>
          <!-- <div class="col-md-6 form-group" *ngIf="data.gameProvider.game_category_code == 'SL' || data.gameProvider.game_category_code == 'FS'">
            <label class="control-label">Game Tag</label>
            <div class="col-md-12 p-0 kt-form__control">
              <kt-dropdown-wo-lazyload
                  [form] = 'form'
                  [dropdownList] = 'dropdown.gameTag'
                  [dropdownSettings] = 'gameTagsDropdownSettings'
                  [formName] = "'game_tag'"
                  [selectionAttributes] = "'id'"
                  [selectedItems] = 'selectedGameTags'
                  (selectedItemsChanged)="onSelectGameTag($event)">
                </kt-dropdown-wo-lazyload>
            </div>
          </div> -->
          <div class="col-md-6 form-group" *ngIf="data.gameProvider.provider_code === 'PNG'">
            <label class="control-label">FRB code <span class="text-danger">*</span></label>
            <input type="text" formControlName="frb_code" class="form-control" disabled>
          </div>
          <div class="col-md-6 form-group required">
            <label class="control-label">Device Type <span class="text-danger">*</span></label><br />
            <div class="d-flex flex-wrap status-filter-pt">
              <div class="custom-control custom-checkbox mr-3">
                <input type="checkbox" formControlName="desktop" class="custom-control-input" id="desktop" disabled>
                <label class="custom-control-label text-nowrap" for="desktop">Desktop</label>
              </div>
              <div class="custom-control custom-checkbox mr-3">
                <input type="checkbox" formControlName="mobile" class="custom-control-input" id="mobile" disabled>
                <label class="custom-control-label text-nowrap" for="mobile">Mobile</label>
              </div>
            </div>
          </div>
          <div class="col-md-6 form-group required">
            <label class="control-label">Image</label><br>
            <label class="col-4 btn btn-default btn-sm image-button">
              <span>Choose file</span>
              <input class="form-control" type="file" hidden accept="image/*" (change)="onUploadFile($event)">
            </label>
            <div class="spinner-wrapper" *ngIf="imageUploading">
              <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
            </div>
          </div>
          <div class="col-12 form-group" *ngIf="(((data.gameProvider.local_image_path || data.gameProvider.image_path) && data.mode === 'edit') || imagePreview.length > 0) && !imageUploading">
            <label class="col-form-label">Preview Image </label>
            <div class="image-wrapper">
              <img class="img-fluid preview-image" alt="Image Banner" [src]="imagePreview[0] || (data.gameProvider.local_image_path ? data.gameProvider.local_image_path : data.gameProvider.image_path)" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.gameProvider)"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
