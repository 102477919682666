<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <!-- <form class="kt-form w-webkit-fill-available" [formGroup]="form"> -->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> Game Log Details</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <ng-container *ngIf="loading">
                <div class="spinner-wrapper">
                  <mat-progress-spinner mode="indeterminate" class="spinner" diameter="20"></mat-progress-spinner>
                </div>
          </ng-container>
          <div class="table-responsive" id="contentDetailsTable" [innerHtml]="tableContentDetails" AppMotationObserver (innerHtmlRendered)="htmlRenderDetails()">
          </div>
          <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
            <div class="dropdown dropdown-inline">
              <button type="button" class="btn btn-secondary" (click)="onChangePage('previous')" ><i class="fas fa-angle-double-left"></i>Previous</button>
              <button type="button" class="btn btn-secondary" (click)="onChangePage('next')" >Next <i class="fas fa-angle-double-right"></i></button>
            </div>
            <div class="dropdown dropdown-inline">
              <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </form> -->
</div>
