<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body">
          <!-- Filter -->
          <div class="kt-form kt-form--label-right">
              <div class="row align-items-center">
                  <div class="col-xl-12 order-2 order-xl-1 pr-0">
                      <form class="row align-items-center" [formGroup]="form">
                          <div class="col-12 row pr-0">
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Name:</label>
                              </div>
                              <div class="col-md-2 kt-form__control ">
                                  <input type="text" formControlName="name" placeholder="Search" class="form-control">
                              </div>
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Currency:</label>
                              </div>
                              <div class="col-md-2 kt-form__control ">
                                <select class="form-control" formControlName="currency_id">
                                    <option value="all">
                                        All
                                    </option>
                                    <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                        {{ value.name }}
                                    </option>
                                </select>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>

          <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
            <div class="dropdown dropdown-inline">
                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
            </div>
            <div class="dropdown dropdown-inline">
              <button *ngIf="canCreateLuckyDrawSettings" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>
        </div>
          <!-- Table -->
          <div class="kt-section">
              <div class="kt-section__content">
                  <div class="table-responsive">
                      <table class="table table-bordered table-hover table-striped">
                          <thead>
                              <tr>
                                  <th>Name</th>
                                  <th class="text-right">Entry Amount</th>
                                  <th class="text-right">Max Entry Per Deposit</th>
                                  <th>Promo Period</th>
                                  <th>Rewards</th>
                                  <th class="text-center">Status</th>
                                  <th>Updated By</th>
                                  <th class="text-center">Actions</th>
                              </tr>
                          </thead>
                          <tbody *ngIf="(luckyDrawSettings$ | async) as rows">
                              <tr *ngFor="let row of rows">
                                <td>{{ row.name }}</td>
                                <td class="text-right">{{ row.entry_amount | number : '1.2-2' }}</td>
                                <td class="text-right">{{ row.max_amount_per_entry < 1 ? 'Unlimited' : row.max_amount_per_entry | number : '1.2-2' }}</td>
                                <td>{{ row.promo_period }}</td>
                                <td>
                                    <ng-container *ngIf="row.rewards.length > 0">
                                        <p *ngFor="let reward of row.rewards; let ind = index">
                                            {{ reward.name }}
                                            <span *ngIf="ind > 0 && ind !== (row.rewards.length - 1)">,</span>
                                        </p>
                                    </ng-container>
                                </td>
                                <td class="text-center">
                                    <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status_name }}</span>
                                </td>
                                <td>
                                    {{ row.updated_by || 'System' }} <br> 
                                    <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                    <ng-template #updatedDateTime>
                                        {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                    </ng-template>
                                </td>
                                <td class="text-center">
                                  <button *ngIf="canViewLuckyDrawSettings" matTooltip="Settings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)">
                                    <i class="fas fa-cog"></i>
                                  </button>
                                </td>
                              </tr>
                          </tbody>
                          <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                      </table>
                  </div>
                  <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                      <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                          [directionLinks]="true" [boundaryLinks]="true" [rotate]="true"
                          [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                      </ngb-pagination>
                      <div class="kt-pagination__toolbar">
                          <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                              class="form-control kt-font-brand page-size">
                              <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                          </select>
                          <span class="pagination__desc">
                              Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                          </span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>