import { Pagination } from '@core/models/pagination.model';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ApplicationSection } from '@core/models/application-section.model';
import { ApplicationPermission } from '@core/models/application-permission.model';
import { ApplicationPermissionDetails } from '@core/models/application-permission-details.model';
import { ApplicationSectionPermission } from '@core/models/application-section-permission.model';
import { ApplicationRole } from '@core/models/application-role.model';
import { ApplicationRoleDetails } from '@core/models/application-role-details.model';
import { AppPermissions } from '@core/models/app-permissions.model';

@Injectable()
export class ApplicationHttpService {

  permissionPagination: Pagination;
  permissionMessages$ = new Subject<any[]>();

  rolePagination: Pagination;
  roleMessages$ = new Subject<any[]>();

  allSection: ApplicationSection[];

  constructor(private http: HttpClient) { }

  getAllSection(): Observable<ApplicationSection[]> {
    return this.http.get<ApiResponse>(`/applicationsectionsubsection`).pipe(
      map(res => res.data.tree),
      tap(res => this.allSection = res)
    );
  }

  // Start: Permission 
  getPermissions(params): Observable<ApplicationPermission[]> {
    return this.http.get<ApiResponse>(`/application/permissions?${params}`).pipe(
      tap(res => this.permissionPagination = res?.data.paginations),
      map(res => res.data.rows)
    );
  }

  getPermissionDetails(id: number): Observable<ApplicationPermissionDetails> {
    return this.http.get<ApiResponse>(`/application/permissions/${id}`).pipe(
      map(res => res.data)
    );
  }

  getSectionPermissions(): Observable<ApplicationSectionPermission[]> {
    return this.http.get<ApiResponse>(`/application/permissions/sectionpermissions`).pipe(
      map(res => res.data.rows)
    );
  }

  updatePermission(id: number, data: any) {
    return this.http.put<ApiResponse>(`/application/permissions/${id}`, { details: data }).pipe(
      tap((res) => this.permissionMessages$.next(res.message))
    );
  }

  getDisabledAppPermissions(): Observable<Array<keyof AppPermissions>> {
    return this.http.get<ApiResponse>(`/disabledapppermissions`).pipe(
      map(res => res.data.rows)
    );
  }
  // End: Permission 

  // Start: Role
  getRoles(params): Observable<ApplicationRole[]> {
    return this.http.get<ApiResponse>(`/application/roles?${params}`).pipe(
      tap(res => this.rolePagination = res?.data.paginations),
      map(res => res.data.rows)
    );
  }

  getRole(id): Observable<ApplicationRole> {
    return this.http.get<ApiResponse>(`/application/roles/${id}`).pipe(
      map(res => res.data)
    );
  }

  getRoleDetails(id: number): Observable<ApplicationRoleDetails> {
    return this.http.get<ApiResponse>(`/application/roles/${id}/edit`).pipe(
      map(res => res.data)
    );
  }

  addRole(data: { name: string, status: number, remarks: string, permissions: { application_permission_id: number, is_granted: number }[] }) {
    return this.http.post<ApiResponse>(`/application/roles`, data).pipe(
      tap((res) => this.roleMessages$.next(res.message))
    );
  }

  updateRole(id: number, data: { name: string, status: number, remarks: string, permissions: { application_permission_id: number, is_granted_number }[] }) {
    return this.http.put<ApiResponse>(`/application/roles/${id}`, data).pipe(
      tap((res) => this.roleMessages$.next(res.message))
    );
  }

  getRolesDropdown(): Observable<Partial<ApplicationRole>[]> {
    return this.http.get<ApiResponse>('/applicationrolesdropdown').pipe(
      map(res => res.data.rows)
    );
  }
  // End: Role
}
