<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
      <div class="modal-header" style="display: inline">
        <h5 class="modal-title" style="display: inline">Member Promotion History ({{ promotion_id }})</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div *ngIf="(memberPromotionHistory$ | async) as rows" class="modal-body">
        <div class="row kt-margin-b-20">
          <div class="col-3">
            <div class="kt-margin-b-5">
              Username
            </div>
            <h4>{{ rows[0].member_account_username }}</h4>
          </div>
          <div class="col-4">
            <div class="kt-margin-b-5">
              Promo Code
            </div>
            <h4>{{ rows[0].promotion_code }}</h4>
          </div>
          <div class="col-2">
            <div class="kt-margin-b-5">
              Currency
            </div>
            <h4>{{ rows[0].settings_currency_code }}</h4>
          </div>
          <div class="col-3">
            <div class="kt-margin-b-5">
              Current Status
            </div>
            <h4><span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + rows[0].current_status">{{ promotionTransferStatus[rows[0].current_status] }}</span></h4>
          </div>
        </div>
        <div class="kt-section">
          <div class="kt-section__content">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped table-sortable">
                <thead>
                  <tr>
                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                    <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'statusid'">Status</th>
                    <th class="text-center sort-enabled" (click)="onSortColumn('amount')" [ngClass]="sortingConfig.amount" [class.sort-selected]="sortingSelection.sort_by === 'amount'">Amount</th>
                    <th>Remarks</th>
                    <th class="sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated by</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of rows">
                    <td>{{ row.id }}</td>
                    <td class="text-center"><span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status">{{ promotionTransferStatus[row.status] }}</span></td>
                    <td class="text-right">{{ row.amount | number : '1.2-2' }}<br>
                      <span *ngIf="row.bonus_amount > 0" class="tranfer-green-text">
                        +{{ row.bonus_amount | number : '1.2-2' }}
                      </span>
                      <span *ngIf="row.bonus_amount < 0" class="red-text">
                        {{ row.bonus_amount | number : '1.2-2' }}
                      </span>
                    </td>
                    <td>{{ row.remarks }}</td>
                    <td>
                      {{ row.bo_user_username ? row.bo_user_username : 'System' }}
                      <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
                      <ng-template #createdDateTime>
                        {{ row.created_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
                      </ng-template>
                    </td>
                  </tr>
                  <kt-fallback-row [collection]="rows"></kt-fallback-row>
                </tbody>
              </table>
            </div>
            <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
              <ngb-pagination
                [pageSize]="pageSize"
                [(page)]="page"
                [maxSize]="maxSize"
                [directionLinks]="true"
                [boundaryLinks]="true"
                [rotate]="true"
                [collectionSize]="pagination.total"
                (pageChange)="onViewPageBy(page)">
              </ngb-pagination>
              <div class="kt-pagination__toolbar">
                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                  <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                </select>
                <span class="pagination__desc">
                  Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
      </div>
    </div>
  </div>