import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { AppPermissionService } from '@core/services/app-permission.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ReportDetailsComponent } from '@views/pages/apps/reports/all-member-reports/dialogs/report-details/report-details.component';
import { AllMemberReportDataService } from '@views/pages/apps/reports/all-member-reports/services/all-member-report-data.service';
import { AllMemberReportEntityService } from '@views/pages/apps/reports/all-member-reports/services/all-member-report-entity.service';
import { MemberReportDetailsDialogComponent } from '@views/pages/apps/reports/member-report/dialogs/member-report-details.component';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'kt-member-report-list',
  templateUrl: './member-report-list.component.html',
  styleUrls: ['./member-report-list.component.scss']
})
export class MemberReportListComponent implements OnInit, OnDestroy {

  @Input()
  isModal = false;

  @Input()
  data: Observable<any[]>;

  @Input()
  isAgent: boolean;

  @Input()
  summary: any;

  @Input()
  showSummary = true;

  @Input()
  form: any;

  @Input()
  pageSize: number;

  @Input()
  page: number;

  @Input()
  maxSize: number;

  @Input()
  pagination: Pagination;

  @Input()
  params = ``;

  @Input()
  listType: 'member-report' | 'all-member-report';

  @Input()
  fromGameProvider: boolean = false;

  @Input()
  fromGameTypeReport: boolean = false;

  @Input()
  gameProviderData: any;

  @Output()
  submodal = new EventEmitter<any>();

  @Input()
  loading: any;

  @Input()
  onSearch: any;

  @Input()
  betLogsDetails = false;

  dropdown = {
    perPage: this.dropdownHttpService.perPage,
    gameProviders: this.dropdownHttpService.gameProviders
  };

  // permissions (these permissions highly depending on this component's @input)
  canViewMemberReport: boolean;
  canViewMemberBetLogsReport: boolean;
  canViewGameResult: boolean;
  canExportMemberBetLogsReport: boolean;

  private subscriptions = new Subscription();

  constructor(
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private memberReportDataService: AllMemberReportDataService,
    private memberReportEntityService: AllMemberReportEntityService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if (this.listType === 'all-member-report'){
      this.data?.subscribe(() => {
        this.onSearch = !this.onSearch;
      });
    }

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      // from 5.10 Game Type Report
      // Note that both 5.3 & 5.10 will have this.fromGameProvider = true, but only 5.10 has this.fromGameTypeReport = true
      // So we should put this.fromGameTypeReport before this.fromGameProvider
      if (this.fromGameTypeReport) {
        this.canViewMemberBetLogsReport = appPermissions.game_type_report_view_member_bet_logs_report;
        this.canViewGameResult = appPermissions.game_type_report_view_game_result;
        this.canExportMemberBetLogsReport = appPermissions.game_type_report_export_member_bet_logs_report;
      }
      // from 5.3 Game Provider Report
      else if (this.fromGameProvider) {
        this.canViewMemberBetLogsReport = appPermissions.game_provider_report_view_member_bet_logs_report;
        this.canViewGameResult = appPermissions.game_provider_report_view_game_result;
        this.canExportMemberBetLogsReport = appPermissions.game_provider_report_export_member_bet_logs_report;
      } 
      // from 5.1 Member Report
      else if (this.listType === 'member-report') {
        this.canViewMemberBetLogsReport = appPermissions.view_member_bet_logs_report;
        this.canViewGameResult = appPermissions.view_game_result;
        this.canExportMemberBetLogsReport = appPermissions.export_member_bet_logs_report;
      }
      // from 5.2 All Member Report
      else if (this.listType === 'all-member-report' && !this.fromGameProvider) {
        this.canViewMemberReport = appPermissions.all_member_report_view_member_report;
        this.canViewMemberBetLogsReport = appPermissions.all_member_report_view_member_bet_logs_report;
      }
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onOpenDialog(memberReportRow: any, type?: string) {
    // used in 5.1, 5.2
    if (this.betLogsDetails && (this.gameProviderData?.viewType === undefined || this.gameProviderData?.viewType !== 'dialog')) {
      memberReportRow = {
        ...memberReportRow,
        viewType: 'dialog' // Set for 2nd level
      };
      this.submodal.emit(memberReportRow);
    } else {
      if (this.fromGameProvider) {
        // used in 5.3
        if(this.gameProviderData.game_provider_id && memberReportRow.username) {
          if (!this.canViewMemberBetLogsReport) {
            Swal.fire({
              title: 'Error!',
              text: 'You do not have permission to view member bet logs report',
              icon: 'error'
            });
            return;
          }

          this.dropdown.gameProviders.subscribe((res) => {
            const params = {
              category_code: this.gameProviderData.category_code,
              game_account: memberReportRow.game_account,
              game_provider_id: this.gameProviderData.game_provider_id,
              game_provider_code: this.gameProviderData.game_provider_code,
              game_sub_category_name: ((this.gameProviderData.option == 'game_sub_category' || this.gameProviderData.option == 'game') && this.gameProviderData.game_sub_category_name != 'all') ? this.gameProviderData.game_sub_category_name: 'all',
              option: this.gameProviderData.option,
              username: memberReportRow.username,
              member_account_id: memberReportRow.member_id,
              start_date_time: this.gameProviderData.start_date_time,
              end_date_time: this.gameProviderData.end_date_time,
              timezone: this.gameProviderData.timezone,
              type: 'member-report',
              viewType: 'dialog',
              currency_code: memberReportRow.currency_code,
              game_type: (res.find((x) => { return x.code === this.gameProviderData.game_provider_code })).type,
            };
            this.openDialogBy(MemberReportDetailsDialogComponent, { params: params, from: this.fromGameTypeReport ? 'game-type-report' : 'game-provider-report' });
          });
        }

      } else {
        if (this.listType === 'all-member-report' && !this.canViewMemberReport) {
          Swal.fire({
            title: 'Error!',
            text: 'You do not have permission to view member report.',
            icon: 'error'
          });
          return;
        }

        this.openDialogBy(ReportDetailsComponent, {
          memberReport: memberReportRow,
          form: this.form.value,
          from: 'all-member-report',
        });
      }
    }

  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    this.loading = true;

    const reportData = (dataService: any) => {
      this.pagination = dataService.pagination;
      this.summary = this.showSummary ? dataService.summary : false;
      this.loadingBar.complete();
      this.loading = false;
    };

    const queryParams = `&page=${page}&perPage=${pageSize}&${params}`;

    return this.data = this.memberReportEntityService.getWithQuery(queryParams).pipe(
      tap(res => reportData(this.memberReportDataService))
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private openDialogBy(componentRef: any, data?: { memberReport?: any, form?: any, params?: any, from?: any }) {
    if (this.fromGameProvider) {
      this.dialog.open(componentRef, {
        width: '90vw',
        data: {
          category_code: data.params.category_code,
          game_account: data.params.game_account,
          game_provider_id: data.params.game_provider_id,
          game_provider_code: data.params.game_provider_code,
          game_sub_category_name: data.params.game_sub_category_name,
          option: data.params.option,
          username: data.params.username,
          member_account_id: data.params.member_account_id,
          start_date_time: data.params.start_date_time,
          end_date_time: data.params.end_date_time,
          type: data.params.type,
          currency_code: data.params.currency_code,
          timezone: data.params.timezone,
          viewType: data.params?.viewType,
          game_type: data.params?.game_type,
          from: data.from,
        }
      })
    } else {
      this.dialog.open(componentRef, {
        width: '90vw',
        data: {
          memberReport: data.memberReport,
          formValue: data.form,
          from: data.from,
        }
      });
    }
  }

  sanitizeNumericField(previousValue: any) {
    const value = String(previousValue).replace(/[,]+/, '');
    return value;
  }

}
