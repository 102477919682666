<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Assign Rewards</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="col-md-12">

          <!-- Currency Field | 3.7, 13.7 -->
          <div class="row col-12 mb-3" *ngIf="data.mode === 'bulk' || data.mode === 'bulk_reward_by_telemarketer'">
            <label class="col-3 kt-form__label col-form-label">Currency <span class="text-danger">*</span></label>
            <div class="col-9 kt-form__control">
              <select formControlName="currency_id" class="form-control" (change)="onChangeCurrency($event.target.value)">
                <option [disabled]="true" value="all">{{ 'Please Select' }}</option>
                <option [value]="value.id" *ngFor="let value of dropdown.currencies | async">
                    {{ value.name }}
                </option>
              </select>
            </div>
          </div>

          <!-- Member Field | 3.7, 13.7 -->
          <div class="row col-12 mb-3" *ngIf="data.mode === 'bulk' || data.mode === 'bulk_reward_by_telemarketer'">
            <label class="col-3 kt-form__label col-form-label">Members <span class="text-danger">*</span></label>
            <div class="col-9 kt-form__control">
              <kt-member-dropdown class="col-8 pl-0 p-0 dropdown-maxheight" [isFilterByCurrency]="isFilterByCurrency" [currencyId]="currencyId" [isShowLeadOnly]="data.mode == 'bulk_reward_by_telemarketer'" [telemarketer_ids]="telemarketerId" [form]="form" [dropdownSettings]='dropdownSettings'
              [normal_account_manager_ids]="normalAccountManagerId" [vip_account_manager_ids]="vipAccountManagerId"
              [formName]="'member_account_id'" [selectionAttributes]="'id'" (selectedItemsChanged)="onSelectedMemberChanged($event)" [ngClass]="{'is-invalid': checkValidation && form.controls.member_account_id.errors }"></kt-member-dropdown>
            </div>
          </div>

          <!-- Campaign Field | 3.7, 1.1 -->
          <div class="row col-12 mb-3" *ngIf="showCampaign">
            <label class="col-3 kt-form__label col-form-label">{{ 'Campaign' }}</label>
            <div class="col-9 kt-form__control">            
              <div class="input-group campaign-selection">
                <div class="input-group-prepend">
                  <select class="form-control" formControlName="campaign_type" (change)="onSelectCampaignType($event.target.value)">
                    <ng-container *ngFor="let value of dropdown.campaignType">
                      <option [value]="+value.id">{{ value.name }}</option>
                    </ng-container>
                  </select>
                </div>
                <input type="text" formControlName="campaign" placeholder="{{ 'Search' | translate }}" class="form-control" [ngbTypeahead]="this.searchCampaign" [resultFormatter]="this.formatCampaign" [inputFormatter]="this.formatCampaign" (selectItem)="onSelectedCampaignChanged($event)">
              </div>
            </div>
          </div>

          <!-- Campaign Field | 11.1 -->
          <div class="row col-12 mb-3" *ngIf="data.mode === 'assignRewards_campaign'">
            <label class="col-3 kt-form__label col-form-label">{{ 'Campaign' }} </label>
            <div class="col-9 kt-form__control">
              <input type="text" [disabled]="true" class="form-control" [value]="data.campaign.name">
            </div>
          </div>
          
          <!-- Reward Field | 1.1, 3.7, 13.2, 13.7, 13.9 -->
          <ng-container *ngIf="data?.mode !== 'assignRewards_campaign'">
            <ng-container *ngIf="showCampaign && selectedCampaign !== undefined && selectedCampaign.promotion_code !== null; else showSelectableReward">
              <div class="row col-12 mb-3">
                <label class="col-3 kt-form__label col-form-label">{{ 'Rewards' }} <span class="text-danger">*</span></label>
                <div class="col-9 kt-form__control">
                  <select formControlName="promotion_id" class="form-control" disabled="true">
                    <ng-container *ngFor="let item of rewardsDropdown; let first = first">
                      <option [value]="+item?.id"  [selected]="first">
                          {{ item?.name }}
                      </option>
                    </ng-container>
                </select>
                </div>
              </div>
            </ng-container>
            <ng-template #showSelectableReward>
              <div class="row col-12 mb-3">
                <label class="col-3 kt-form__label col-form-label">{{ 'Rewards' }} <span class="text-danger">*</span></label>
                <div class="col-9 kt-form__control">
                  <kt-dropdown-wo-lazyload [form]='form' [dropdownList]='rewardsDropdown' [dropdownSettings]='rewardsDropdownSettings' [formName]="'promotion_id'" [selectionAttributes]="'id'" (selectedItemsChanged)="onSelectedItemChange($event)" [ngClass]="{'is-invalid': checkValidation && form.controls.promotion_id.errors }" [currency] = "currency">
                  </kt-dropdown-wo-lazyload>
                </div>
              </div>
            </ng-template>
          </ng-container>

          <!-- Reward Field | 11.1 -->
          <ng-container *ngIf="data?.mode === 'assignRewards_campaign'">
            <div class="row col-12 mb-3" *ngIf="data.campaign.promotion_code === null">
              <label class="col-3 kt-form__label col-form-label">{{ 'Rewards' }} <span class="text-danger">*</span></label>
              <div class="col-9 kt-form__control">
                <kt-dropdown-wo-lazyload [form]='form' [dropdownList]='rewardsDropdown' [dropdownSettings]='rewardsDropdownSettings' [formName]="'promotion_id'" [selectionAttributes]="'id'" (selectedItemsChanged)="onSelectedItemChange($event)" [ngClass]="{'is-invalid': checkValidation && form.controls.promotion_id.errors }" [currency] = "currency">
                </kt-dropdown-wo-lazyload>
              </div>
            </div>
            <div class="row col-12 mb-3" *ngIf="(data.campaign.promotion_code !== null)">
              <label class="col-3 kt-form__label col-form-label">{{ 'Rewards' }} </label>
              <div class="col-9 kt-form__control">
                <select formControlName="promotion_id" class="form-control" disabled="true">
                  <ng-container *ngFor="let item of rewardsDropdown; let first = first">
                    <option [value]="+item?.id"  [selected]="first">
                        {{ item?.name }}
                    </option>
                  </ng-container>
              </select>
              </div>
            </div>
          </ng-container>

          <!-- Bonus Amount Field | 1.1, 3.7, 13.2, 13.9 -->
          <div class="row col-12 mb-3" *ngIf="data.mode !== 'assignRewards_campaign' && showBonusAmount === true && data.mode !== 'member_reward_by_telemarketer' && data.mode !== 'bulk_reward_by_telemarketer'">
            <label class="col-3 kt-form__label col-form-label">{{ 'Bonus Amount' }} </label>
            <div class="col-9 kt-form__control">
              <input type="number" formControlName="bonus_amount" placeholder="{{ 'Please Enter Bonus Amount' }}" class="form-control" min="0" step="0.01" (change)="onBonusAmountChange()" [ngClass]="{'is-invalid': checkValidation && form.controls.bonus_amount.errors }">
            </div>
          </div>

          <!-- Status Field | 1.1, 3.7, 11.1 -->
          <div class="row col-12 mb-3" *ngIf="data.mode !== 'member_reward_by_telemarketer' && data.mode !== 'bulk_reward_by_telemarketer' && data.mode !== 'member_reward_by_account_management'">
            <label class="col-3 kt-form__label col-form-label">{{ 'Status' }} <span class="text-danger">*</span></label>
            <div class="col-9 kt-form__control">
              <select formControlName="status" class="form-control" [ngClass]="{'is-invalid': checkValidation && form.controls.status.errors }">
                <option [value]="null"  [disabled]="true">{{ 'Please Select' }} </option>
                <ng-container *ngFor="let item of status | keyvalue">
                  <option [value]="item.key" *ngIf="+item.key == 0 || +item.key  == 4">{{ item.value }}</option>
                  <option [value]="item.key" *ngIf="+item.key == 5 && selectedRewards !== undefined && selectedRewards !== null && selectedRewards?.promo_type === 2">{{ item.value }}</option>
                </ng-container>
              </select>
            </div>
          </div>

          <!-- Remarks Field | All -->
          <div class="row col-12">
            <label class="col-3 kt-form__label col-form-label">{{ 'Remarks' }} </label>
            <div class="col-9 kt-form__control">
              <input type="text" formControlName="remarks" placeholder="{{ 'Please Enter Remarks' }}" class="form-control" [ngClass]="{'is-invalid': checkValidation && form.controls.remarks.errors }">
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" [data]="data$ | async" (confirmed)="onCloseDialog($event)" (reassign)="reassign($event)" (cancelled)="resetData()"></kt-swal-alert>