import { Deposit } from '@core/models/deposit.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '@core/models/api-response.model';

@Injectable()
export class DepositEntityService extends EntityCollectionServiceBase<Deposit> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('Deposit', serviceElementsFactory);
  }
}
