import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReferralSetting } from '@core/models/referral-setting.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { forkJoin, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PromotionCodeDataService } from '../../promotion-codes/services/promotion-code-data.service';
import { ReferralSettingsDataService } from '../services/referral-settings-data.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  templateUrl: './referral-settings-edit.component.html',
  styleUrls: ['./referral-settings-edit.component.scss']
})
export class ReferralSettingsEditDialogComponent implements OnInit, OnDestroy  {

  form: FormGroup; 
  messages$ = this.referralSettingsDataService.messages$;
  refreshStatus: boolean;

  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: this.dropdownHttpService.statuses
    //promotionCode : this.promotionCodeEntityService.getWithQuery(`?paginate=false`)
  };
  buttonLoading = false;
  promotionCodeDropdownSettings = {};
  promotionCodeDropdownList = [];
  referralBonusSelectedItems = [];
  invitationBonusSelectedItems = [];

  // permissions
  canViewPromotionCodeList: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { referralSetting: ReferralSetting, mode: string},
    public dialogRef: MatDialogRef<ReferralSettingsEditDialogComponent>,
    private referralSettingsDataService: ReferralSettingsDataService,
    private dropdownHttpService: DropdownHttpService,
    private promotionCodeDataService: PromotionCodeDataService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.formInit();
    this.promotionCodeDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };
    this.promotionCodeDataService.getWithQuery(`?paginate=false`).subscribe(res => {
      this.promotionCodeDropdownList = res;
      this.promotionCodeDropdownList.map(function (elm) {
        elm['name'] = elm.code + ' - ' + elm.name;
      });

      if(this.data.mode === 'edit'){
        if(this.data.referralSetting.referral_bonus_id !== 0 ){
          this.referralBonusSelectedItems.push({
            id: this.data.referralSetting.referral_bonus_id,
            name: this.data.referralSetting.referral_promotion_code + ' - ' + this.data.referralSetting.referral_promotion_name
          });
        };
        if(this.data.referralSetting.invitation_bonus_id !== 0){
          this.invitationBonusSelectedItems.push({
            id: this.data.referralSetting.invitation_bonus_id,
            name: this.data.referralSetting.invitation_promotion_code + ' - ' + this.data.referralSetting.invitation_promotion_name
          });
        };
      };
    });

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewPromotionCodeList = appPermissions.view_promotion_codes_list;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(mode?: string) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    const data = {
      ...this.form.value,
    };
    Object.keys(data).forEach((key) => (data[key] == null) && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.referralSettingsDataService.updateReferral(this.data.referralSetting.id, data).pipe(
        tap((res: any) => {
          this.messages$.next([...res.message]);
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          // To enable "Save" button after get response
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.referralSettingsDataService.add(data).pipe(
            tap((res: any) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              this.messages$.next([...res.message]);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              // To enable "Save" button after get response
              this.form.setErrors(null);
              throw error;
            })
          )
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private formInit() {
    let settingCurrencyId = 1;
    let targetDepositAmount = null;
    let targetReferralCount = null;
    let validity = null;
    let referralBonus = null;
    let invitationBonus = null;
    let name = null;
    let status = 1;

    if (this.data.mode === 'edit'){
      settingCurrencyId = this.data.referralSetting.settings_currency_id,
      name = this.data.referralSetting.name,
      targetDepositAmount = (+this.data.referralSetting.target_deposit_amount).toFixed(2),
      targetReferralCount = this.data.referralSetting.target_referral_count,
      validity = this.data.referralSetting.validity,
      referralBonus = this.data.referralSetting.referral_bonus_id === 0 ? '' : this.data.referralSetting.referral_bonus_id,
      invitationBonus = this.data.referralSetting.invitation_bonus_id === 0 ? '' : this.data.referralSetting.invitation_bonus_id,
      status = this.data.referralSetting.status
    } else {
      referralBonus = '',
      invitationBonus = ''
    }
    this.form = new FormGroup({
      currency_id: new FormControl({ value: settingCurrencyId, disabled: this.data.mode === 'edit' ? true : false },  [Validators.required]),
      name: new FormControl(name, [Validators.required]),
      target_deposit_amount: new FormControl(targetDepositAmount, [Validators.required]),
      target_referral_count: new FormControl(targetReferralCount, [Validators.required]),
      validity: new FormControl(validity),
      referral_bonus: new FormControl(referralBonus),
      invitation_bonus: new FormControl(invitationBonus),
      status: new FormControl(status),
    });
  }

}
