<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div  class="kt-portlet kt-portlet--mobile">
      <div  class="kt-portlet__body">
          <div  >
              <div  class="kt-portlet__head pl-0 pr-0">
                  <div  class="kt-portlet__head-toolbar">
                      <div  class="kt-portlet__head-wrapper">
                          <div  class="dropdown dropdown-inline">
                              <button    data-target="#MaintenanceModal" class="btn btn-brand btn-icon-sm"><i   class="fas fa-plus"></i>Create
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
              <div  class="kt-form kt-form--label-right  kt-margin-b-10">
                  <div  class="row align-items-center">
                      <div  class="col-xl-12 order-2 order-xl-1">
                          <div  class="row align-items-center">

                          </div>
                          <div  class="row align-items-center">
                              <div  class="col-md-3 kt-margin-b-20-tablet-and-mobile">
                                  <div  class="kt-input-icon kt-input-icon&amp;#45;&amp;#45;left">
                                      <form >
                                          <input  type="text" placeholder="Search" class="form-control">
                                      </form>
                                  </div>
                              </div>
                              <div  class="col-md-3 kt-margin-b-20-tablet-and-mobile">
                                  <div  class="kt-form__group kt-form__group--inline">
                                      <div  class="kt-form__label">
                                          <label>Status:</label>
                                      </div>
                                      <div  class="kt-form__control">
                                          <select  class="form-control">
                                              <option  value="0">
                                                  Inactive
                                              </option>
                                              <option  value="1">
                                                  Active
                                              </option>
                                              <option  value="all">
                                                  All
                                              </option>
                                          </select>
                                      </div>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div  class="kt-section">
              <div  class="kt-section__content">
                  <div  class="table-responsive">
                      <table  class="table table-bordered table-hover table-striped">
                          <thead>
                              <tr>
                                  <th >ID</th>
                                  <th class="date-column">Start</th>
                                  <th class="date-column">End</th>
                                  <th >Remarks</th>
                                  <th  class="text-center">Status</th>
                                  <th >Actions</th>
                              </tr>
                          </thead>
                          <tbody>

                              <tr>
                                  <td >1</td>
                                  <td >2020-01-08 23:00:00</td>
                                  <td >2020-01-09 01:00:00</td>
                                  <td >Maintenance backup</td>
                                  <td  class="text-center"><span  class="kt-badge kt-badge--info kt-badge--inline kt-badge--pill">Inactive</span></td>
                                  <td  class="kt-datatable__cell"><span  style="overflow: visible; position: relative; width: 110px;"></span></td>
                              </tr>
                          </tbody>
                      </table>
                      <div  class="kt-pagination  kt-pagination--brand">
                          <ul class="kt-pagination__links">
                              <li class="kt-pagination__link--first"><a><i class="fa fa-angle-double-left kt-font-brand"></i></a></li>
                              <li class="kt-pagination__link--next"><a><i class="fa fa-angle-left kt-font-brand"></i></a></li>
                              <li class="kt-pagination__link--active"><a>1</a></li>
                              <li class="kt-pagination__link--prev"><a><i class="fa fa-angle-right kt-font-brand"></i></a></li>
                              <li class="kt-pagination__link--last"><a><i class="fa fa-angle-double-right kt-font-brand"></i></a></li>
                          </ul>
                          <div class="kt-pagination__toolbar">
                              <select class="form-control kt-font-brand" style="width: 60px;">
                                  <option value="10">10</option>
                                  <option value="20" selected="selected">20</option>
                                  <option value="30">30</option>
                                  <option value="50">50</option>
                              </select> <span class="pagination__desc">
          Showing 1 to 1 of 1 records
      </span></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div    tabindex="-1" role="dialog" aria-hidden="true" class="modal fade" id="MaintenanceModal">
          <div  role="document" class="modal-dialog modal-lg">
              <div  class="modal-content">
                  <div  class="modal-header">
                      <h5 class="modal-title">Create Maintenance</h5>
                  </div>
                  <div  class="modal-body">
                      <div  >
                          <form   class="kt-form">
                              <div   class="kt-portlet__body">
                                  <div   class="row">
                                      <div   class="form-group required col-md-12">
                                          <label   class="control-label">Start</label>
                                          <div   class="input-group date">
                                              <input   type="text" name="start" id="start_dt" readonly="readonly" class="form-control datetime">
                                              <div   class="input-group-append"><span   class="input-group-text"><i   class="la la-calendar glyphicon-th"></i></span></div>
                                          </div>
                                      </div>
                                      <div   class="form-group required col-md-12">
                                          <label   class="control-label">End</label>
                                          <div   class="input-group date">
                                              <input   type="text" name="end" id="end_dt" readonly="readonly" class="form-control datetime">
                                              <div   class="input-group-append"><span   class="input-group-text"><i   class="la la-calendar glyphicon-th"></i></span></div>
                                          </div>
                                      </div>
                                      <div   class="form-group required col-md-12">
                                          <label   class="control-label">Status</label>
                                          <select   id="status" name="status"  class="form-control">
                                              <option   value="1"> Active</option>
                                              <option   value="0"> Inactive</option>
                                          </select>
                                      </div>
                                      <div   class="form-group col-md-12">
                                          <label   class="control-label">Remarks</label>
                                          <input   id="remarks" name="remarks" class="form-control">
                                      </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
                  <div  class="modal-footer">
                      <button   type="button" data-dismiss="modal" class="btn btn-secondary">
                          Close
                      </button>
                      <button   type="button" data-dismiss="modal" class="btn btn-primary">
                          Submit
                      </button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
