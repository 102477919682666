<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <ng-container *ngIf="(merchantList| async) as list; else elseLoading">
                            <mat-tab-group animationDuration="0ms" [selectedIndex]="activeTab" (selectedTabChange)="onSelectTab($event)">
                                <ng-container *ngFor="let value of merchantList | async">
                                    <mat-tab label="{{value.name}}"></mat-tab>
                                </ng-container>
                            </mat-tab-group>
                        </ng-container>
                        <ng-container *ngIf="(merchantList| async) as list;">  
                            <div class="table-responsive">
                                <table class="table table-bordered table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Section</th>
                                            <th class="text-center">On/Off</th>
                                            <th>Position (Row) <i class="fas fa-exclamation-circle" matTooltip="Number of Rows Available: &#8734;&#13;&#13;Row sizes are fixed and cannot be merged." matTooltipClass="custom-tooltip line-broken-tooltip" matTooltipHideDelay="0"></i></th>
                                            <th>Position (Column) <i class="fas fa-exclamation-circle" matTooltip="Number of Columns Available: 2&#13;&#13;If the section takes more than one column, start the next column on the next available column number.&#13;&#13;i.e: Secction A takes two spaces (column 1 & 2), then set Section A as column 1 and Secetion B as column 3" matTooltipClass="custom-tooltip line-broken-tooltip" matTooltipHideDelay="0"></i></th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="mobileDisplays.length > 0 ">
                                        <ng-container  formGroupName="footer_section_merchant">
                                            <tr *ngFor="let row of mobileDisplays;  let i = index" [formGroupName]="i">
                                                <input type="hidden" formControlName="footer_section_merchant_id">
                                                <!-- <td>{{ row.id }}</td>   -->
                                                <td>{{ row.footer_section_name }}</td>
                                                <td class="text-center">
                                                    <div class="custom-control custom-switch">
                                                        <input [disabled]="!canUpdateMobileDisplayStatus" type="checkbox" class="custom-control-input" (click)="onToggleStatus(row, i)" [id]="row.id" [checked]="row.mobile === 1 ? true : false">
                                                        <label class="custom-control-label" [for]="row.id"></label>
                                                    </div>
                                                </td>
                                                <td class="form-group">
                                                    <input class="form-control" type="number" formControlName="mobile_row" step="1" min="0" (keyup)="findDuplicate(i)" [ngClass]="{'is-invalid': form.get('footer_section_merchant.'+i+'.mobile_row').errors}">
                                                    <div *ngIf="form.get('footer_section_merchant.'+i+'.mobile_row').invalid" class="text-danger">
                                                        <div *ngIf="form.get('footer_section_merchant.'+i+'.mobile_row').errors['min'] ">The Min row for mobile-view is {{ form.get('footer_section_merchant.'+i+'.mobile').value == 1 ? '1' : '0' }}</div>
                                                        <div *ngIf="form.get('footer_section_merchant.'+i+'.mobile_row').errors.required">Position (row) is required.</div>
                                                        <div *ngIf="form.get('footer_section_merchant.'+i+'.mobile_row').errors.pattern">Please enter only integer.</div> 
                                                    </div>
                                                    <div *ngIf="form.get('footer_section_merchant.'+i+'.mobile_row').invalid" class="text-danger">
                                                        <div *ngIf="form.get('footer_section_merchant.'+i+'.mobile_row').errors['duplicate'] ">
                                                            Duplicate Found
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="form-group">
                                                    <input type="number" class="form-control" formControlName="mobile_column" step="1" min="0" (keyup)="findDuplicate(i)" [ngClass]="{'is-invalid':form.get('footer_section_merchant.'+i+'.mobile_column').errors}">
                                                    <div *ngIf="form.get('footer_section_merchant.'+i+'.mobile_column').invalid" class="text-danger">
                                                        <div *ngIf="form.get('footer_section_merchant.'+i+'.mobile_column').errors.max ">The Max column for mobile-view is 2</div>    
                                                        <div *ngIf="form.get('footer_section_merchant.'+i+'.mobile_column').errors.min ">The Min column for mobile-view is {{ form.get('footer_section_merchant.'+i+'.mobile').value == 1 ? '1' : '0' }}</div>
                                                        <div *ngIf="form.get('footer_section_merchant.'+i+'.mobile_column').errors.required">Position (column) is required.</div>
                                                        <div *ngIf="form.get('footer_section_merchant.'+i+'.mobile_column').errors.pattern">Please enter only integer.</div>
                                                    </div>
                                                    <div *ngIf="form.get('footer_section_merchant.'+i+'.mobile_column').invalid" class="text-danger">
                                                        <div *ngIf="form.get('footer_section_merchant.'+i+'.mobile_column').errors['duplicate'] ">
                                                            Duplicate Found
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <button *ngIf="canViewMobileDisplaySettings" title="Display Settings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog(row)"><i class="fas fa-cog"></i></button>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                                </table>
                            </div>
                            <div class="text-right">
                                <button type="button" class="btn btn-secondary mr-2" (click)="reset()"><i class="fas fa-sync"></i>Reset</button>
                                <kt-submit-button *ngIf="canEditMobileDisplayPosition" [buttonLoading]="buttonLoading"  [isDisabled]="!form.valid" (confirmed)="onSave()"></kt-submit-button> 
                            </div>
                        </ng-container>
                        <ng-template #elseLoading>
                            <div class="col-md-12 row justify-content-center">
                                <i class="fas fa-circle-notch fa-spin p-0 mr-2"></i>
                            </div>
                        </ng-template>  
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>