<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Browse Receipts</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <form class="kt-form" [formGroup]="form">
        <div class="row">
          <div class="col-md-4 form-group">
            <label class="control-label">Bank Name</label>
            <input type="text" formControlName="bank_name" class="col-12 form-control readonly" readonly>
          </div>
          <div class="col-md-4 form-group">
            <label class="control-label">Account Name</label>
            <input type="text" formControlName="account_name" class="col-12 form-control readonly" readonly>
          </div>
          <div class="col-md-4 form-group">
            <label class="control-label">Account Number</label>
            <input type="text" formControlName="account_number" class="col-12 form-control readonly" readonly>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 form-group">
            <label class="control-label">Amount <span class="text-danger">*</span></label>
            <input type="number" (wheel)="false" min="0.01" formControlName="credit" placeholder="Search" class="form-control">
          </div>
          <div class="col-md-3 form-group">
            <label class="control-label">Keyword</label>
            <input type="text" formControlName="keyword" placeholder="Search" class="form-control">
          </div>
          <div class="col-md-3 form-group">
            <label class="control-label">Start Date Time <span class="text-danger">*</span></label>
            <div class="input-group date" [owlDateTimeTrigger]="dateStartRef">
              <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack?.start_date" [ngModelOptions]="{standalone: true}">
              <input type="text" formControlName="start_date" class="form-control">
              <span class="input-group-append">
                <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
              </span>
              <owl-date-time #dateStartRef></owl-date-time>
            </div>
          </div>
          <div class="col-md-3 form-group">
            <label class="control-label">End Date Time <span class="text-danger">*</span></label>
            <div class="input-group date" [owlDateTimeTrigger]="dateEndRef">
              <input class="hidden" [owlDateTime]="dateEndRef" [ngModel]="dateTimeStack?.end_date" [ngModelOptions]="{standalone: true}">
              <input type="text" formControlName="end_date" class="form-control">
              <span class="input-group-append">
                <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
              </span>
              <owl-date-time #dateEndRef></owl-date-time>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 form-group text-right">
            <img src="/assets/media/loader.gif" class="loader" *ngIf="!loaded" />
          </div>
          <div class="col-md-6 form-group text-right">
            <button type="button" class="btn btn-primary" [disabled]="!form.valid" (click)="onBrowse()"><i
                class="fas fa-search"></i>Search</button>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-md-12 form-group">
          <table class="table table-bordered table-hover table-striped">
            <thead>
              <tr>
                <th>Description</th>
                <th class="info-column">Bank Time</th>
                <th class="text-center">Currency</th>
                <th>Amount</th>
                <th class="text-center">Status</th>
                <th>Remarks</th>
                <th class="info-column">Scanned Time</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody *ngIf="(bankReceipts$ | async) as rows">
              <tr *ngFor="let row of rows">
                <td>{{ row.desc }}</td>
                <td>{{ row.bank_time | timezoneDate: 'YYYY-MM-DD HH:mm':localGMT }}</td>
                <td class="text-center">{{ row.currency }}</td>
                <td class="text-right">
                  {{ +row.credit === 0 ? '' : row.credit | number : '1.2-2' }}
                  {{ +row.debit === 0 ? '' : '-' + row.debit | number : '1.2-2' }}
                </td>
                <td class="text-center">
                  <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide"
                    [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                </td>
                <td>{{ row.remarks }}</td>
                <td>{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm':localGMT }}</td>
                <td class="text-center">
                  <button *ngIf="+row.credit === +data.depositDetails.confirmed_amount" type="button" class="btn btn-primary" (click)="onSelectReceipt(row)">Select</button>
                </td>
              </tr>
              <kt-fallback-row [collection]="rows"></kt-fallback-row>
            </tbody>
          </table>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true"
              [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total"
              (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
  </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)" [icon]="invalid_receipt ? 'error' : 'info'"></kt-swal-alert>