<div class="google-authenticator-container">
    <h4 class="twofa-title">
        Google Authenticator
    </h4>
    <img src="/assets/media/misc/pending-reset-2fa.gif" class="pending-img" alt="Pending" />
    <span class="info-text text-center">
        Please wait while we process your request.
    </span>
    <div class="refresh-container">
        <button type="button" class="btn refresh-btn" (click)="onRefresh()">
            {{ 'Refresh' }}
        </button>
    </div>
</div>
