import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { AllMemberReport } from '@core/models/allmember-report.model';

@Injectable()
export class AllMemberReportEntityService extends EntityCollectionServiceBase<AllMemberReport> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('AllMemberReport', serviceElementsFactory);
  }
}
