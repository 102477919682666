import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { PromotionApplication } from '@core/models/promotion-application.model';

@Injectable()
export class PromotionApplicationEntityService extends EntityCollectionServiceBase<PromotionApplication> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('PromotionApplication', serviceElementsFactory);
    }
}

