<div>
  <div>
    <div class="row align-items-center">
      <div class="col-xl-12 order-2 order-xl-1 pr-0">
        <form class="row align-items-center" [formGroup]="form">
          <div class="col-12 row mb-2 pr-0">
            <div class="col-md-1 kt-form__label col-form-label">
              <label>Currency:</label>
            </div>
            <div class="col-md-2 kt-form__control">
              <select class="form-control" formControlName="currency_id">
                <option [value]="null" [disabled]="true">Please Select</option>
                <option value="all">All</option>
                <ng-container *ngFor="let value of currencies">
                  <option [value]="value.id">{{ value.name }}</option>
                </ng-container>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
    <div class="dropdown dropdown-inline">
      <kt-search-button
        [loading]="searchBtnLoading"
        [disabledButton]="loading"
        (search)="onSubmit()"
      ></kt-search-button>
      <kt-clear-button
        [loading]="clearBtnLoading"
        [disabledButton]="loading"
        (clear)="onClear()"
      ></kt-clear-button>
    </div>
  </div>

  <div class="kt-section">
    <div class="kt-section__content">
      <div class="table-responsive">
        <table
          class="table table-bordered table-hover table-striped table-sortable"
        >
          <thead>
            <tr>
              <th rowspan="2">Currency</th>
              <th colspan="6">SMS Type</th>
              <th rowspan="6">Actions</th>
            </tr>
            <tr>
              <th>OTP</th>
              <th>Transaction</th>
              <th>Register</th>
              <th>Forgot Password</th>
              <th>Recovery</th>
              <th>Promotional</th>
            </tr>
          </thead>
          <tbody *ngIf="settings$ | async as rows">
            <tr *ngFor="let row of rows; let i = index">
              <td>{{ row.currency_code }}</td>
              <td [ngClass]="{'fill-background': row.sms_type.otp == '-'}">{{ row.sms_type.otp }}</td>
              <td [ngClass]="{'fill-background': row.sms_type.transaction == '-'}">{{ row.sms_type.transaction }}</td>
              <td [ngClass]="{'fill-background': row.sms_type.register == '-'}">{{ row.sms_type.register }}</td>
              <td [ngClass]="{'fill-background': row.sms_type.forgot_password == '-'}">{{ row.sms_type.forgot_password }}</td>
              <td [ngClass]="{'fill-background': row.sms_type.recovery == '-'}">{{ row.sms_type.recovery }}</td>
              <td [ngClass]="{'fill-background': row.sms_type.promotional == '-'}">{{ row.sms_type.promotional }}</td>
              <td>
                <button *ngIf="canEditSMSProviders" title="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
              </td>
            </tr>
          </tbody>
          <tbody
            shared-table-handler
            [loading]="loading"
            [dataLength]="dataLength"
            class="text-gray-600 fw-bold"
          ></tbody>
        </table>
      </div>
      <div
        class="kt-pagination kt-pagination--brand mt-2"
        *ngIf="pagination !== undefined"
      >
        <ngb-pagination
          [pageSize]="pageSize"
          [(page)]="page"
          [maxSize]="maxSize"
          [directionLinks]="true"
          [boundaryLinks]="true"
          [rotate]="true"
          [collectionSize]="pagination.total"
          (pageChange)="onViewPageBy(page)"
        >
        </ngb-pagination>
        <div class="kt-pagination__toolbar">
          <select
            (change)="onPerPage($event)"
            [(ngModel)]="pageSize"
            class="form-control kt-font-brand page-size"
          >
            <option [value]="item" *ngFor="let item of dropdown.perPage">
              {{ item }}
            </option>
          </select>
          <span class="pagination__desc">
            Showing {{ pagination.from }} to {{ pagination.to }} of
            {{ pagination.total | number: "1.0" }} records
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
