import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { CurrencyTimezone } from "@core/enums/currency-timezone.enum";
import { RebateReleaseStatus } from "@core/enums/rebate-release.enum";
import { Pagination } from "@core/models/pagination.model";
import { RebateRelease } from "@core/models/rebate-release-model";
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from "@core/services/dropdown-http.service";
import { EventEmitterService } from "@core/services/event-emitter.service";
import { TransactionHttpService } from "@core/services/transaction-http.service";
import { LoadingBarService } from "@ngx-loading-bar/core";
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from "rxjs";
import { exhaustMap, map, tap } from "rxjs/operators";
import Swal from "sweetalert2";
import { MemberInformationDialogComponent } from "../../general/members/dialogs/member-information/member-information.component";
import { MemberDataService } from "../../general/members/services/member-data.service";
import { NewRebateReleaseDataService } from "./services/new-rebate-release-data.service";
import { NewRebateReleaseEntityService } from "./services/new-rebate-release-entity.service";
import { CurrencyHttpService } from "@core/services/currency-http.service";
import { NewRebateGenerateDialogComponent  } from "./dialogs/rebate-generate/new-rebate-generate.component";
import { RebateUpdateSettingDialogComponent } from "./dialogs/rebate-update-setting/rebate-update-setting.component";
import { RebateDetailsDialogComponent } from './dialogs/rebate-details/rebate-details.component';
import { RebateSettingDetailsDialogComponent } from './dialogs/rebate-setting-details/rebate-setting-details.component';
import { RebateEntityService } from '../../rebates/rebates/services/rebate-entity.service';
import { AppPermissionService } from "@core/services/app-permission.service";
import { TranslateService } from '@ngx-translate/core';
import { RebateListDialogComponent } from './dialogs/rebate-list/rebate-list.component';
@Component({
  selector: "kt-new-rebate-releases",
  templateUrl: "./new-rebate-releases.component.html",
  styleUrls: ["./new-rebate-releases.component.scss"],
})
export class NewRebateReleasesComponent implements OnInit, OnDestroy {

  dropdown = {
    groups: this.dropdownHttpService.groups,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage,
    statuses: RebateReleaseStatus,
    dateType: this.dropdownHttpService.rebateDateTypes,
  };
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  rebateRelease$: Observable<RebateRelease[]>;
  gameProvidersDropdownList = [];
  gameProvidersSelectedItems = [];
  gameProvidersDropdownSettings = {};
  form: FormGroup;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = "";
  dropdownSettings = {};
  messages$ = this.rebateReleaseDataService.messages$;
  data$ = this.rebateReleaseDataService.data$;
  ranges = this.transactionHttpService.ranges;
  private subscription = new Subscription();

  checkboxForm: FormGroup;
  selectedRebate = [];
  availableRebate = [];
  toggleAll: boolean = false;
  currencyTimezone = CurrencyTimezone;
  selectedTotalRebates = 0;
  currentPageTotalRebates = 0;
  totalRebates = 0;
  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '4.2';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'member_group_name': 'desc',
    'game_provider_name': 'desc',
    'category_name': 'desc',
    'currency': 'desc',
    'start_datetime': 'desc',
    'valid_turnover': 'desc',
    'promo_turnover': 'desc',
    'rate': 'desc',
    'winloss': 'desc',
    'total_rebate_amount': 'desc',
    'release_amount': 'desc',
    'status': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',

  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  accountTypeDropdownSettings = {};
  accountTypeDropdownList = [];
  accountTypeSelectedItems = [];

  // permissions
  private subscriptions = new Subscription();
  canViewMemberDialog: boolean;
  canGenerateRebate: boolean;
  canReleaseRebate: boolean;
  canRejectRebate: boolean;
  canUpdateRebate: boolean;
  canViewRebateDetailsDialog: boolean;

  constructor(
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private rebateReleaseEntityService: NewRebateReleaseEntityService,
    private rebateReleaseDataService: NewRebateReleaseDataService,
    private eventEmitterService: EventEmitterService,
    private transactionHttpService: TransactionHttpService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private memberDataService: MemberDataService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private rebateEntityService: RebateEntityService,
    private appPermissionService: AppPermissionService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();

    this.accountTypeDropdownList = this.dropdownHttpService.accountTypesLists;
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    
    this.accountTypeDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: true,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.formInit();
    this.reload();

    this.eventEmitterService.onClearMerchantAccountSearch();

    this.gameProvidersDropdownSettings = {
      singleSelection: true,
      text: "Please Select",
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: "dropdown",
      primaryKey: "name",
      labelKey: "labelKey",
      // lazyLoading: true,
      noDataLabel: "",
      showCheckbox: false
    };

    this.dropdownHttpService.gameProviders.subscribe((res) => {
      this.gameProvidersDropdownList = res;
      this.gameProvidersDropdownList.map(function (elm) {
        elm['labelKey'] = elm.code + ' - ' + elm.name;
      });
      this.gameProvidersDropdownList.unshift({
        id: "all",
        labelKey: "All",
      });
    });
    this.gameProvidersSelectedItems.push({
      labelKey: "All",
      id: "all",
    });

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.rebate_releases_view_member_dialog_v2;
      this.canGenerateRebate = appPermissions.generate_rebates_v2;
      this.canReleaseRebate = appPermissions.release_rebates_v2;
      this.canRejectRebate = appPermissions.reject_rebates_v2;
      this.canUpdateRebate = appPermissions.update_rebates_setting;
      this.canViewRebateDetailsDialog = appPermissions.view_rebate_details_v2;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog() {
    this.reload();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : "";
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        exhaustMap((data: any) => {

          // Status checkbox as query string
          let statusParameter = ''
          Object.keys(data.status).forEach((row, index) => {
            if (data.status[row] === true) {
              statusParameter += `&status[${index}]=${row}`
            }
          });
          delete data.status;

          this.loadingBar.start();
          return (this.rebateRelease$ = this.rebateReleaseEntityService
            .getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}${statusParameter}`)
            .pipe(
              tap((res) => {
                this.loading = false;
                this.dataLength = res.length;
                this.selectedTotalRebates = 0;
                this.currentPageTotalRebates = this.rebateReleaseDataService.currentPageTotal;
                this.totalRebates = this.rebateReleaseDataService.totalAmount;
                this.toggleAll = false;
                this.selectedRebate = [];
                this.availableRebate = res;
                this.checkboxFormInit();
                this.pagination = this.rebateReleaseDataService.pagination;
                this.loadingBar.complete();
              })
            ));
        })
      )
      .subscribe();
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, id?: number, row?: any) {
    if (type === 'member-information') {
      this.subscription = this.memberDataService.getById(id, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&additional_info=1`).pipe(
        tap((res) => {
          if (res) {
            this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' }, '1500px');
          }
        })
      ).subscribe();
    }else if( type === 'update-setting'){
      this.openDialogBy(RebateUpdateSettingDialogComponent, null, {
        width: '800px',
        height : '80vh',
        class: 'generate-rebate-custom-dialog'
      });
    } else if( type === 'show-rebates-details'){
      this.rebateReleaseDataService.showRebateDetails(id).subscribe((res) => {
        this.openDialogBy(RebateDetailsDialogComponent, { rebateRelease : row, details: res }, {
          width: '1500px'
        });
      });
    } else if( type === 'show-rebates-setting-details'){
      const operator = this.rebateEntityService.getByKey(id);
      this.subscription = operator.pipe(
        tap((res) => {
          this.openDialogBy(RebateSettingDetailsDialogComponent, { rebate: res ,mode :'RebateSetting'}, {
            width: '800px'
          });
        })
      ).subscribe();
    }else if( type === 'show-rebates-setting-update-details'){
      this.openDialogBy(RebateUpdateSettingDialogComponent, { rebate: row}, {
        width: '800px',
        height : '80vh',
        class: 'generate-rebate-custom-dialog'
      });
    }else if( type === 'rebate-list'){
      this.openDialogBy(RebateListDialogComponent, { rebateRelease: row}, {
        width: '1500px'
      });
    }else {
      this.openDialogBy(NewRebateGenerateDialogComponent , null, {
        width: '800px',
        class: 'generate-rebate-custom-dialog'
      });
    }

  }

  private openDialogBy(componentRef: any, data?: any, options?: any) {
    const dialogRef = this.dialog.open(componentRef, {
      width: options.width,
      panelClass: options.class ? options.class : '',
      data
    });

    if (data?.mode !== 'member-information') {
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.onViewPageBy(this.page);
        }
      });
    }
  }

  onClearDate(type: string) {
    if (this.form.value.start_datetime !== null) {
      this.form.patchValue({
        start_datetime: null,
        end_datetime: null,
        defaultDate: null,
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.accountTypeSelectedItems = [
      { id: 1, name: 'Normal'},
      { id: 2, name: 'With-Label'},
      { id: 3, name: 'Dummy'}
    ];
    this.gameProvidersSelectedItems = [
      this.gameProvidersDropdownList.find((v) => v.id === "all"),
    ];
    this.form.patchValue({
      username: null,
      member_group_id: "all",
      game_provider_id: "all",
      currency_id: "all",
      rebates: null,
      start_datetime: null,
      end_datetime: null,
      defaultDate: null,
      status: this.getStatusControls(true),
      account_type_condition: 'Or',
      account_type: [1, 2, 3],
      date_type: this.dropdown.dateType[0].value
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        exhaustMap((data: any) => {
          if (data['start_datetime']) {
            data['start_datetime'] = moment(data['start_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
            data['end_datetime'] = moment(data['end_datetime']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          }

          if(data['account_type']){
            data['account_type'] = this.form.controls['account_type'].value.toString();
          }

          // Status checkbox as query string
          let statusParameter = ''
          Object.keys(data.status).forEach((row, index) => {
            if (data.status[row] === true) {
              statusParameter += `&status[${index}]=${row}`
            }
          });
          delete data.status;

          this.params = Object.keys(data)
            .map((key) => key + "=" + data[key])
            .join("&");
          const parameter = this.params ? "&" + this.params : "";
          this.loadingBar.start();
          return (this.rebateRelease$ = this.rebateReleaseEntityService
            .getWithQuery(`?perPage=${this.pageSize}${parameter}${statusParameter}`)
            .pipe(
              tap((res) => {
                this.loading = false;
                this.clearBtnLoading = false;
                this.searchBtnLoading = false;
                this.dataLength = res.length;
                this.selectedTotalRebates = 0;
                this.currentPageTotalRebates = this.rebateReleaseDataService.currentPageTotal;
                this.totalRebates = this.rebateReleaseDataService.totalAmount;
                this.toggleAll = false;
                this.selectedRebate = [];
                this.availableRebate = res;
                this.checkboxFormInit();
                this.page = 1;
                this.pagination = this.rebateReleaseDataService.pagination;
                this.loadingBar.complete();
              })
            ));
        })
      )
      .subscribe();
  }

  onDateRange(event: any, type: string) {
    if (event) {
      this.form.patchValue({
        start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }
    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach((key) => {
      if (
        formData[key] !== "" &&
        formData[key] !== null &&
        formData[key] !== undefined &&
        key !== "defaultDate"
      ) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private reload() {
    this.onViewPageBy(this.page);
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      member_group_id: new FormControl("all"),
      game_provider_id: new FormControl("all"),
      currency_id: new FormControl("all"),
      rebates: new FormControl(null),
      start_datetime: new FormControl(null),
      end_datetime: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range,
      status: new FormGroup(this.getStatusControls()),
      account_type_condition: new FormControl('Or'),
      account_type: new FormControl([1,2,3]),
      date_type: new FormControl(this.dropdown.dateType[0].value)
    });
  }

  private checkboxFormInit() {
    let prepareForm = {};
    this.availableRebate.forEach(item => {
      prepareForm = { ...prepareForm, [item.id]: [{ value: false, disabled: item.status_id != 0 }] }
    });
    this.checkboxForm = this.fb.group(prepareForm);

    this.checkboxForm.valueChanges.subscribe(val => {
      this.selectedRebate = [];
      this.selectedTotalRebates = 0;
      Object.keys(val).forEach(key => {
        if (val[key]) {
          this.selectedTotalRebates += +this.availableRebate.find(x => x.id == key).total_rebate_amount;
          this.selectedRebate.push(key);
        }
      })
    })
  }

  private getStatusControls(isReset: boolean = false) {
    let statusControls = {};
    const statusValues = (Object.values(this.dropdown.statuses)).filter(row => +row >= 0);

    statusValues.forEach(row => {
      if (isReset) {
        statusControls = { ...statusControls, [row]: (row === 0 ? true : false) }
      } else {
        statusControls = { ...statusControls, [row]: new FormControl(row === 0 ? true : false) };
      }
    });

    // Pending selected by default
    return statusControls;
  }

  checkboxToggleAll() {
    let patchValue = {};
    if (this.toggleAll) {
      this.availableRebate.forEach(item => {
        if (item.status_id == 0) {
          patchValue = { ...patchValue, [item.id]: true };
        }
      });
    } else {
      this.availableRebate.forEach(item => {
        patchValue = { ...patchValue, [item.id]: false };
      });
    }
    this.checkboxForm.patchValue(patchValue);
  }

  rebateAction(action: string, mode: string, id?: number,) {
    let message = '';
    let data = {};
    switch (mode) {
      case 'single':
        message = `Do you want to ${action} the rebate ?`;
        data = { rebate_id: [id] };
        break;
      case 'selected':
        message = `Do you want to ${action} the selected rebate below ?`;
        data = { rebate_id: this.selectedRebate };
        break;
      case 'all':
        message = `Do you want to ${action} all the rebate ?`;
        data = {};
        break;
    }

    Swal.fire({
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (action === 'release') {
          this.rebateReleaseDataService.release(data).subscribe();
        } else {
          this.rebateReleaseDataService.reject(data).subscribe();
        }
      }
    });
  }
}
