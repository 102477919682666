<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body">
        <div class="kt-form kt-form--label-right">
            <div class="row align-items-center">
                <div class="col-xl-12 order-2 order-xl-1 pr-0">
                    <form class="row align-items-center" [formGroup]="form">
                        <div class="col-12 row mb-2 pr-0">
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Campaign:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                                <input type="text" formControlName="campaign" placeholder="Search" class="form-control">
                            </div>
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Country:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                                <input type="text" formControlName="country" placeholder="Search" class="form-control">
                            </div>
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Currency:</label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                                <select class="form-control" formControlName="currency_id">
                                    <option [value]="null" [disabled]="true">Please Select</option>
                                    <option value="all">All</option>
                                    <ng-container *ngFor="let value of dropdown.currencies">
                                        <option [value]="value.id">{{ value.name }}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 row pr-0">
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Date/Time:</label>
                            </div>
                            <div class="col-md-5 kt-form__control">
                                <div class="input-group date">
                                    <input type="text" class="form-control" placeholder="Search"
                                        (change)="onDateRange($event)" formControlName="defaultDate"
                                        [timePicker]="true" [timePickerSeconds]="true"
                                        [alwaysShowCalendars]="true" [ranges]="ranges"
                                        [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                        [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                    ngxDaterangepickerMd/>
                                    <span class="input-group-append">
                                        <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
            <div class="dropdown dropdown-inline">
                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
            </div>
            <div class="dropdown dropdown-inline">
                <button *ngIf="canImportAdCost" class="btn btn-import btn-icon-sm mr-2" [disabled]="loading || importBtnLoading" (click)="myFileInput.click()">
                    <i [ngClass]="importBtnLoading ? 'spinner-border spinner-border-sm align-middle' : 'fas fa-file-import'"></i>Import
                    <input type="file" accept=".csv" hidden [disabled]="loading || importBtnLoading ? 'disabled' : null" (change)="onUploadFile($event)" #myFileInput>
                </button>
                <button *ngIf="canCreateAdCost" class="btn btn-brand btn-icon-sm " [disabled]="loading ? 'disabled' : null" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>

        </div>
        <div class="kt-section">
            <div class="kt-section__content">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover table-striped">
                        <thead>
                            <tr>
                            <th>ID</th>
                            <th>Campaign Name</th>
                            <th class="text-center">Currency</th>
                            <th class="date-column">Date</th>
                            <th class="text-center">Ad Cost USD</th>
                            <th class="text-center">Ad Cost Local</th>
                            <th>Target Country</th>
                            <th>Remarks</th>
                            <th>Created By</th>
                            <th>Updated By</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="(campaignAdCost$ | async) as rows">
                            <tr *ngFor="let row of rows">
                                <td>{{ row.id }}</td>
                                <td>{{ row.campaign_name }}</td>
                                <td class="text-center">{{ row.currency_code }}</td>
                                <td>{{ row.date | timezoneDate: 'YYYY-MM-DD HH:mm' }}</td>
                                <td class="text-right">{{ row.ad_cost_usd | number : '1.2-2' }}</td>
                                <td class="text-right">{{ row.ad_cost_local | number : '1.2-2' }}</td>
                                <td>{{ row.target_country }}</td>
                                <td>{{ row.remarks }}</td>
                                <td>
                                    {{ row.created_by ? row.created_by : 'System' }}<br/>
                                    <span placement="bottom" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                    <ng-template #createdDateTime>
                                        {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                    </ng-template>
                                </td>
                                <td>
                                    {{ row.updated_by ? row.updated_by : 'System' }}<br/>
                                    <span placement="bottom" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                    <ng-template #updatedDateTime>
                                        {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                    </ng-template>
                                </td>
                                <td class="text-center">
                                    <button *ngIf="canEditAdCost" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)"><i class="fas fa-edit"></i></button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                    </table>
                </div>
                <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                    <ngb-pagination
                    [pageSize]="pageSize"
                    [(page)]="page"
                    [maxSize]="maxSize"
                    [directionLinks]="true"
                    [boundaryLinks]="true"
                    [rotate]="true"
                    [collectionSize]="pagination.total"
                    (pageChange)="onViewPageBy(page)">
                    </ngb-pagination>
                    <div class="kt-pagination__toolbar">
                        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                        </select>
                        <span class="pagination__desc">
                        Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                        </span>
                    </div>
                </div>
            </div>
        </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async" ></kt-swal-alert>
