<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body">
          <div class="kt-section">
              <div class="mb-2">
                <a routerLink="/game/providers"><i class="fas fa-angle-left"></i> Back</a>
              </div>
              <div class="kt-section__content">
                <div class="kt-form kt-form--label-right kt-margin-b-10">
                  <div class="row align-items-center">
                      <div class="col-xl-12 order-2 order-xl-1 pr-0">
                          <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Name:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                <input type="text" class="form-control" placeholder="Search" formControlName="name">
                              </div>
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Code:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                <input type="text" class="form-control" placeholder="Search" formControlName="code">
                              </div>
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Status:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                <select formControlName="status" class="form-control">
                                  <option value=""> All </option>
                                  <option *ngFor="let item of dropdown.status" [value]="item.id">{{ item.name }}</option>
                                </select>
                              </div>
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Thumbnail:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                <select formControlName="image" class="form-control">
                                  <option value=""> ALL </option>
                                  <option value=1> YES </option>
                                  <option value=0> NO </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Subcategory:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                <kt-dropdown-wo-lazyload
                                  [form] = 'form'
                                  [dropdownList] = 'subCategoryDropdown'
                                  [dropdownSettings] = 'gameProvidersDropdownSettings'
                                  [formName] = "'sub_category_name'"
                                  [selectionAttributes] = "'name'"
                                  [selectedItems]='selectedSubCategoryName'>
                                </kt-dropdown-wo-lazyload>
                              </div>
                              <!-- <div class="col-md-1 kt-form__label col-form-label">
                                <label>Game Tag:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                <kt-dropdown-wo-lazyload #mySelect [form]='form' [dropdownList]='dropdown.gameTag'
                                  [dropdownSettings]='gameTagsDropdownSettings' [formName]="'game_tag'"
                                  [selectionAttributes]="'id'" [selectedItems]='selectedGameTags'>
                                </kt-dropdown-wo-lazyload>
                              </div> -->
                            </div>
                          </form>
                      </div>
                  </div>
                </div>
                <div class="kt-section col-12 row d-flex justify-content-between mb-05">
                    <div class="dropdown dropdown-inline">
                      <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                      <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                    </div>
                    <div class="dropdown dropdown-inline">
                      <button *ngIf="canExportMiniGameList" class="btn btn-warning btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="button_loading">
                          <i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>
                          Export
                      </button>
                      <button *ngIf="canCreateMiniGame" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
                  </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Game Sub Category Name</th>
                                <th>Name</th>
                                <th class="text-center">Code</th>
                                <th>Thumbnail</th>
                                <!-- <th>Game Tag</th> -->
                                <th class="text-center">Status</th>
                                <th class="date-column">Created By</th>
                                <th class="date-column">Updated By</th>
                                <!-- <th>Type</th> -->
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="(miniGames$ | async) as rows">
                          <tr *ngFor="let row of miniGames$ | async; let i = index">
                            <td>{{ games$[i].game_sub_category_name }}</td>
                            <td>{{ games$[i].name }}</td>
                            <td class="text-center">{{ games$[i].code }}</td>
                            <td *ngIf="games$[i].image_path || games$[i].local_image_path; else elseBlock">
                              <img [src]="games$[i].local_image_path ? games$[i].local_image_path : games$[i].image_path"  alt="-" class="preview">
                            </td>
                            <ng-template #elseBlock>
                              <td>-</td>
                            </ng-template>
                            <!-- <td class="text-center" *ngIf="games$[i].tags.length>0; else noTag">{{ games$[i].tags }}</td>
                            <ng-template #noTag>
                              <td class="text-center">-</td>
                            </ng-template> -->
                            <td class="text-center">
                              <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + games$[i].status"> {{ games$[i].status_name }}</span>
                            </td>
                            <td>
                              <ng-container *ngIf="games$[i].created_by != null && games$[i].created_by != 0 else elseIsNull">
                                {{ games$[i].created_by }}<br />
                              </ng-container>
                              <ng-template #elseIsNull>
                                -<br />
                              </ng-template>
                              <ng-container *ngIf="games$[i].created_at != '-'">
                                {{ games$[i].created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                              </ng-container>
                            </td>

                            <td>
                              <ng-container *ngIf="games$[i].updated_by != null && games$[i].updated_by != 0 else elseIsNull">
                                {{ games$[i].updated_by }}<br />
                              </ng-container>
                              <ng-template #elseIsNull>
                                -<br />
                              </ng-template>
                              <ng-container *ngIf="games$[i].updated_at != '-'">
                                {{ games$[i].updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                              </ng-container>
                            </td>
                            <!-- <td>{{ games$[i].TYPE }}</td> -->
                            <td class="text-center">
                            <button *ngIf="canEditMiniGame" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', games$[i])"><i class="fas fa-edit"></i></button>
                            </td>
                          </tr>
                        </tbody>
                        <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                    </table>
                </div>
                <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                  <ngb-pagination
                    [pageSize]="pageSize"
                    [(page)]="page"
                    [maxSize]="maxSize"
                    [directionLinks]="true"
                    [boundaryLinks]="true"
                    [rotate]="true"
                    [collectionSize]="pagination.total"
                    (pageChange)="onViewPageBy(page)">
                  </ngb-pagination>
                  <div class="kt-pagination__toolbar">
                    <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                        <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
                    </select>
                    <span class="pagination__desc">
                      Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                    </span>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="loadingBar.complete()"></kt-swal-alert>