import { UploadHttpService } from '@core/services/upload-http.service';
import { GameProviderDataService } from './../services/game-provider-data.service';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject, OnDestroy, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { GameProvider } from '@core/models/game-provider.model';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { GameProviderType } from '@core/enums/game-provider-type.enum';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-game-provider-edit',
  templateUrl: './game-provider-edit.component.html',
  styleUrls: ['./game-provider-edit.component.scss']
})
export class GameProviderEditDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;
  form: FormGroup;

  messages$ = this.gameProviderDataService.messages$;
  gameProviders$: Observable<GameProvider[]>;
  refreshStatus: boolean;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    language$: this.gameProviderDataService.language$,
    gameProviders: []
  };
  imagePreview = [];
  languages: { lang_code: string }[] = [];

  currencyDropdownSettings = {};
  currencySelectedItems = [];
  currentSelectedCurrency = [];

  categoryDropdownSettings = {};
  categoryDropdownList = [];
  categorySelectedItems = [];

  providerDetails: any;

  is_bot: boolean = false;
  is_credential: boolean = false;
  gf_username = [];
  gf_password = [];
  gf_pin = [];
  gf_url = [];
  gf_id = [];
  gf_site_game_provider_id = [];
  gf_balance = [];

  // permissions
  canEditGameProvider: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  buttonLoading = false;
  imageUploading = new Array(5).fill(false);
  isImageUploading = [];
  isCategoryExist = [];

  isUpdateGameDetails = false;

  dateTimeStack = {
    suspend_date: null,
    suspend_transfer_in_date: null,
    suspend_transfer_out_date: null,
  };

  dateTimeFormat = 'yyyy-MM-dd HH:mm:';
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  clientOffset = moment().utcOffset() * 60 * 1000;
  offset = moment.tz(this.timezone).utcOffset() * 60 * 1000;

  private datePickerSubscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { gameProvider: any, gameCategories: any, gfGameProvider: any },
    public dialogRef: MatDialogRef<GameProviderEditDialogComponent>,
    private gameProviderDataService: GameProviderDataService,
    private uploadService: UploadHttpService,
    private dropdownHttpService: DropdownHttpService,
    private datePipe: DatePipe,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.data.gameCategories.forEach(item => {
      this.imagePreview[item.code] = new Array(5);
      this.isImageUploading[item.code] = new Array(5).fill(false);
    });
    if (this.data.gameProvider.type === GameProviderType.BOT && this.data.gameProvider.credential == '1') {
      this.is_credential = true;
      this.is_bot = true;
      this.data.gfGameProvider.forEach((ele, index) => {
        this.gf_id[ele.currency_code] = ele.game_provider_id,
          this.gf_site_game_provider_id[ele.currency_code] = ele.site_game_provider_id,
          this.gf_username[ele.currency_code] = ele.username;
        this.gf_password[ele.currency_code] = ele.signature;
        this.gf_pin[ele.currency_code] = ele.other_key;
        this.gf_url[ele.currency_code] = ele.url;
        this.gf_balance[ele.currency_code] = parseFloat(ele.balance).toFixed(2);
      });
    }

    this.setCurrencyDropdown();
    this.setCategoryDropdown();
    this.setLanguage();
    this.setCategoryExist();
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditGameProvider = appPermissions.edit_game_provider;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.onRefresh();
    this.datePickerSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'suspend_date'),
      this.buildDatePicker(1, 'suspend_transfer_in_date'),
      this.buildDatePicker(2, 'suspend_transfer_out_date')
    ]).subscribe();
  }

  onUploadFile(event: any, category_code: string, num: number) {
    var formControlName = "";
    var formGroup = "";
    if (num == 0 || num == 3) {
      formControlName = "full_logo"
    } else if (num == 1 || num == 4) {
      formControlName = "icon_logo"
    } else {
      formControlName = "lobby_button_background"
    }
    formGroup = "media";

    // if (num < 3) {
    //   formGroup = "media";
    // } else {
    //   formGroup = "mobile_image";
    // }
    this.isImageUploading[category_code][num] = true;
    // Patch the value to null first to trigger the validation function
    this.form.patchValue({
      [formGroup]: {
        [formControlName]: null
      }
    });
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'gameprovider');

    this.uploadService.upload(formData).subscribe(res => {
      let index = this.data.gameProvider.category.findIndex(x => x.category_code === category_code);

      this.imagePreview[category_code][num] = res;
      res.forEach(x => {
        this.form.patchValue({
          category: {
            [index]: {
              [formGroup]: {
                [formControlName]: x
              }
            }
          }
        });
      });
      this.form.markAllAsTouched();
      this.isImageUploading[category_code][num] = false;
    });
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(gameProvider: GameProvider) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });

    // Create variable to hold the value will be pass to API
    const data = {
      id: gameProvider.id ? gameProvider.id : null,
      ...this.form.value,
    };

    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);

    if (data['suspend_date']) data['suspend_date'] = moment(data['suspend_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')
    if (data['suspend_transfer_in_date']) data['suspend_transfer_in_date'] = moment(data['suspend_transfer_in_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')
    if (data['suspend_transfer_out_date']) data['suspend_transfer_out_date'] = moment(data['suspend_transfer_out_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')

    this.gameProviderDataService.updateGameProvider(data).pipe(
      tap((res: any) => {
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        // To enable "Save" button after get response
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();

    this.refreshStatus = true;
  }

  onSubmit(id, site_game_provider_id, password, pin, url) {
    const data = {
      game_provider_id: id,
      site_game_provider_id: site_game_provider_id,
      signature: password,
      other_key: pin,
      url: url
    };
    this.gameProviderDataService.updateGfGameProvider(data).pipe(
      tap((res: any) => {
        this.gf_password[res.currency_code] = res.signature;
        this.gf_pin[res.currency_code] = res.other_key;
        this.gf_url[res.currency_code] = res.url;
        this.buttonLoading = false;
        this.isUpdateGameDetails = true;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.isUpdateGameDetails = true;
        throw error;
      })
    ).subscribe();
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onChangeCurrency(selected) {
    this.currencySelectedItems = selected;
  }

  onToggleSuspendStatus() {
    this.form.patchValue({
      suspend_status: this.form.value.suspend_status ? 0 : 1
    })
  }

  onToggleSuspendTransferInStatus() {
    this.form.patchValue({
      suspend_transfer_in_status: this.form.value.suspend_transfer_in_status ? 0 : 1
    })
  }

  onToggleSuspendTransferOutStatus() {
    this.form.patchValue({
      suspend_transfer_out_status: this.form.value.suspend_transfer_out_status ? 0 : 1
    })
  }

  private formInit() {

    this.dateTimeStack = {
      suspend_date: this.data.gameProvider.suspend_date ? new Date(new Date(this.data.gameProvider.suspend_date).getTime() + this.offset - this.clientOffset) : null,
      suspend_transfer_in_date: this.data.gameProvider.suspend_transfer_in_date ? new Date(new Date(this.data.gameProvider.suspend_transfer_in_date).getTime() + this.offset - this.clientOffset) : null,
      suspend_transfer_out_date: this.data.gameProvider.suspend_transfer_out_date ? new Date(new Date(this.data.gameProvider.suspend_transfer_out_date).getTime() + this.offset - this.clientOffset) : null,
    };

    let name = this.data.gameProvider.name ?? null;
    let currencyID = this.data.gameProvider.currency ?? null;
    let details = this.providerDetails;
    let suspend_status = this.data.gameProvider.suspend_status ?? 0;
    let suspend_date = this.dateTimeStack.suspend_date;
    let suspend_transfer_in_status = this.data.gameProvider.suspend_transfer_in_status ?? 0;
    let suspend_transfer_out_status = this.data.gameProvider.suspend_transfer_out_status ?? 0;
    let suspend_transfer_in_date = this.dateTimeStack.suspend_transfer_in_date;
    let suspend_transfer_out_date = this.dateTimeStack.suspend_transfer_out_date;
    let supported_target_type_name = this.data.gameProvider.supported_target_type_name ?? null;

    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      settings_currency_id: new FormControl(currencyID, [Validators.required]),
      category: new FormGroup(this.buildCategory()),
      details: new FormGroup(this.buildSubDetails()),
      type: new FormControl(this.data.gameProvider.type === GameProviderType.BOT ? 'BOT' : 'API'),
      suspend_status: new FormControl(suspend_status, [Validators.required]),
      suspend_date: new FormControl(suspend_date),
      suspend_transfer_in_status: new FormControl(suspend_transfer_in_status, [Validators.required]),
      suspend_transfer_out_status: new FormControl(suspend_transfer_out_status, [Validators.required]),
      suspend_transfer_in_date: new FormControl(suspend_transfer_in_date,),
      suspend_transfer_out_date: new FormControl(suspend_transfer_out_date,),
      supported_target_type_name: new FormControl(supported_target_type_name),
    });

    this.form.patchValue({ details });
    this.form.markAllAsTouched();
    this.form.markAsDirty();
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, this.dateTimeFormat + '00')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      })
    );
  }

  private buildCategory = () => {
    let formgroup = {};
    this.data.gameProvider.category.forEach((ele, index) => {
      formgroup[index] = new FormGroup({
        id: new FormControl(ele.category_id),
        media: new FormGroup(this.buildDestopImage(ele)),
      })
    });
    return formgroup;
  }

  private buildDestopImage = (ele) => {
    let desktopImage = {};
    let media = ele.media ?? null;
    if (media) {
      this.imagePreview[ele.category_code][0] = media.full_logo;
      this.imagePreview[ele.category_code][1] = media.icon_logo;
      this.imagePreview[ele.category_code][2] = media.lobby_button_background;
      desktopImage = {
        full_logo: new FormControl(media.full_logo),
        icon_logo: new FormControl(media.icon_logo),
        lobby_button_background: new FormControl(media.lobby_button_background)
      };
    } else {
      desktopImage = {
        full_logo: new FormControl(null, [Validators.required]),
        icon_logo: new FormControl(null, [Validators.required]),
        lobby_button_background: new FormControl(null, [Validators.required])
      }
    }
    return desktopImage;
  };

  // private buildMobileImage = (ele) => {
  //   let mobileImage = {}
  //   let mobile_image = ele.mobile_image ?? null;
  //   if (mobile_image) {
  //     this.imagePreview[ele.category_code][3] = mobile_image.full_logo;
  //     this.imagePreview[ele.category_code][4] = mobile_image.icon_logo;
  //     mobileImage = {
  //       full_logo: new FormControl(mobile_image.full_logo),
  //       icon_logo: new FormControl(mobile_image.icon_logo),
  //     };
  //   } else {
  //     mobileImage = {
  //       full_logo: new FormControl(null, [Validators.required]),
  //       icon_logo: new FormControl(null, [Validators.required])
  //     }
  //   }

  //   return mobileImage
  // };

  private buildSubDetails = () => {
    let details = {};
    this.languages.map(element => {
      const detailsGroup = new FormGroup({
        lang_code: new FormControl(element),
        description: new FormControl(''),
      });
      details = { ...details, [this.languages.indexOf(element)]: detailsGroup };
    });
    return details;
  };

  private setCurrencyDropdown() {
    const selectCurrency = () => {
      if (this.data.gameProvider.currency !== null) {
        this.currencySelectedItems = this.dropdown.currencies.filter(x => this.data.gameProvider.currency.includes(x.id));
      }
    };
    if (this.dropdown.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe(res => {
        this.dropdown.currencies = res;
        selectCurrency();
      });
    } else {
      selectCurrency();
    }


    this.currencyDropdownSettings = {
      autoPosition: true,
      maxHeight: 150,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: true,
      enableSearchFilter: false,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
  }

  private setCategoryDropdown() {

    // this.dropdown.categories.subscribe(res => {
    this.categoryDropdownList = this.data.gameCategories;
    if (this.data.gameProvider.category !== null) {
      this.categorySelectedItems = this.data.gameCategories.filter(x => this.data.gameProvider.category.some(e => e.category_code === x.code));
    }
    // })

    this.categoryDropdownSettings = {
      autoPosition: true,
      maxHeight: 150,
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: true,
      enableSearchFilter: false,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false,
      disabled: true,
    };
  }

  private setLanguage() {

    this.dropdown.language$.pipe(tap(res => res.forEach(item => {
      this.languages.push(item.lang_code);
    }))).subscribe(
      res => {
        this.setProviderDetails(res);
      }
    );
  }

  private setProviderDetails(res) {
    res.forEach((item, index) => {
      let language_id = this.data.gameProvider.details.findIndex(x => x.lang_code === item.lang_code);;
      let details = null;

      if (this.data.gameProvider.details[language_id]) {
        Object.keys(this.data.gameProvider.details[language_id]).forEach((key) => {
          details = {
            ...details,
            [key]: this.data.gameProvider.details[language_id][key]
          };
        });
      }

      this.providerDetails = {
        ...this.providerDetails,
        [index]: {
          ...details
        }
      }
    });
  }

  private setCategoryExist() {
    this.data.gameProvider.category.forEach((ele) => {
      this.isCategoryExist[ele.category_code] = true;
    });
  }

  syncBalance(game_provider_code, currency_code) {
    this.buttonLoading = true;
    const data = {
      game_provider_code: game_provider_code,
      currency_code: currency_code
    };
    this.gameProviderDataService.syncGfGameProvider(data).pipe(
      tap((res: any) => {
        this.gf_balance[currency_code] = parseFloat(res.balance).toFixed(2);
        this.buttonLoading = false;
        this.isUpdateGameDetails = true;
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.isUpdateGameDetails = true;
        throw error;
      })
    ).subscribe();
  }

}
