import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Promotion } from '@core/models/promotion.model';
import { Pagination } from '@core/models/pagination.model';
import { BlacklistedRequest } from '../dialogs/game-provider-new-blacklist/get-blacklisted-request.interface';
import { BlacklistedData } from '../dialogs/game-provider-new-blacklist/get-blacklisted-data.interface';
import { UpdateBlacklistedRequest } from '../dialogs/game-provider-new-blacklist/update-blacklisted-request.interface';
import { promotionDropdown } from '@core/models/promotion-dropdown.model';
@Injectable()
export class PromotionCodeDataService extends DefaultDataService<Promotion> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  newBlacklistedGameProviders$ = new BehaviorSubject<any>(null);

  promotionTypes = [
    { value: 1, name: 'Percentage' },
    { value: 2, name: 'Fixed Amount' }
  ];

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('Promotion', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<Promotion[]> {
    return this.http.get<ApiResponse>('/promotion').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getActive(): Observable<Promotion[]> {
    return this.http.get<ApiResponse>('/promotion?status=1&sort_by=id&sort_order=DESC').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(contact?: Promotion): Observable<Promotion>{
    return this.http.post<ApiResponse>(`/promotion`, contact).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/promotion/${entity.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  duplicate(promotion?: Promotion): Observable<Promotion>{
    return this.http.post<ApiResponse>(`/promotion/duplicate?${promotion.id}`, promotion).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/promotion/${entity.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  getById(id: number): Observable<Promotion>{
    return this.http.get<ApiResponse>(`/promotion/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<Promotion[]>{
    return this.http.get<ApiResponse>(`/promotion${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  importMemberList(data: any, type: string){
    return this.http.post<ApiResponse>(`/promotion/bulkImportMember`, data).pipe(
      tap(res => { if (type === 'edit'){ this.messages$.next(res.message) } })
    );
  }

  getBlacklistedData(blacklistedRequest: BlacklistedRequest): Observable<BlacklistedData[]>{
    return this.http.post<ApiResponse>(`/promotion/blacklistgame`, blacklistedRequest).pipe(
      map(res => res.data.rows)
    )
  }

  updateBlacklistedData(updateBlacklistedRequest: UpdateBlacklistedRequest){
    return this.http.post<ApiResponse>(`/promotion/updateblacklistgame`, updateBlacklistedRequest);
  }

  getPromotionDropdown(): Observable<promotionDropdown[]> {
    return this.http.get<ApiResponse>(`/promotiondropdown`).pipe(
      map(res => res.data.rows)
    );
  }

  checkEligibility(promotion_id: number, member_id: number, is_reward: number, game_provider_code?: string) {
    return this.http.get<ApiResponse>(`/promotion/checkeligibility?promotion_id=${promotion_id}&member_account_id=${member_id}&is_reward=${is_reward}&game_provider_code=${game_provider_code}`).pipe(
      map(res => res)
    );
  }

  getPromotionDetailsByGameProvider(game_provider_id: number): Observable<any[]>{
    return this.http.get<ApiResponse>(`/promotion/getPromotionDetailsByGameProvider`, {
      params: { game_provider_id: game_provider_id.toString() }
    }).pipe(
      map(res => res.data.rows)
    )
  }

  updatePromotionByGameProvider(promotionDetails: any){
    return this.http.post<ApiResponse>(`/promotion/updatePromotionByGameProvider`, promotionDetails).pipe(
      tap( res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }

}
