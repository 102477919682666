import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'kt-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.scss']
})
export class SearchButtonComponent implements OnInit {

  @Output()
  search = new EventEmitter();

  @Input()
  disabledButton = false;

  @Input()
  loading = false;

  clicked = false;

  constructor(
  ) { }

  ngOnInit() {
    if (!this.loading || !this.disabledButton) {
      this.clicked = true;
    }
  }

  onSearch() {
    this.clicked = true;
    this.search.emit();
  }

}
