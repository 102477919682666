<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <!-- Filter -->
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <!-- Filter Form -->
            <form class="row align-items-center" [formGroup]="form">
              <!-- Filter first row -->
              <div class="col-12 row mb-2 pr-0">
                <!-- Title -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Title:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="title" placeholder="Search" class="form-control">
                </div>

                <!-- Status -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="status">
                    <option value="null"> All </option>
                    <ng-container *ngFor="let status of statuses; let i = index">
                      <option [value]="i">{{ status }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Filter Action -->
      <div class="kt-section col-12 row mb-3 mt-2">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
      </div>

      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped">
              <thead>
                <tr>
                  <th class="text-center" rowspan="2">ID</th>
                  <th class="text-left" rowspan="2">Title</th>
                  <th class="text-left" rowspan="2">Parameters</th>
                  <th class="text-center" rowspan="2">Status</th>
                  <th class="text-center" rowspan="2">Created At</th>
                  <th class="text-center" colspan="2">Export</th>
                  <th class="text-center" rowspan="2">Actions</th>

                </tr>
                <tr>
                  <th class="text-center">Started At</th>
                  <th class="text-center">Completed At</th>
                </tr>

              </thead>
              <tbody *ngIf="reports.length > 0">
                <tr *ngFor="let row of reports">
                  <td class="text-center">{{ row.id }}</td>
                  <td class="text-left">{{ row.title }}</td>
                  <td class="text-break w-25 parameters">{{ row.parameters }}</td>
                  <td class="text-center">
                    <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'report-kt-badge--' + row.status"> {{ row.status_name }}</span></td>
                  <td class="text-center">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</td>
                  <td class="text-center">{{ row.started_at !== null ? (row.started_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</td>
                  <td class="text-center">{{ row.completed_at !== null ? (row.completed_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</td>
                  <td class="text-center">
                    <button matTooltip="Download" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" [disabled]="row.status !== 1" (click)="onDownload(row.downlod_link)"><i class="fa fa-download"></i></button>
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="reports.length" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination
              [pageSize]="pageSize"
              [(page)]="page"
              [maxSize]="maxSize"
              [directionLinks]="true"
              [boundaryLinks]="true"
              [rotate]="true"
              [collectionSize]="pagination.total"
              (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                  <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
