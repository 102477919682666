import { RebateReleaseDataService } from '../services/rebate-release-data.service';
import { Subscription, forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { TargetAmountDetailsDialogComponent } from "./target-amount-details.component";
import * as moment from 'moment-timezone';
import { MemberInformationDialogComponent } from "../../../general/members/dialogs/member-information/member-information.component";
import { MemberDataService } from "../../../general/members/services/member-data.service";
import { TransactionHttpService } from "@core/services/transaction-http.service";
import { tap } from "rxjs/operators";
import { CurrencyTimezone } from "@core/enums/currency-timezone.enum";
@Component({
  selector: 'kt-promo-turnover-details',
  templateUrl: './promo-turnover-details.component.html',
  styleUrls: ['./promo-turnover-details.component.scss'],
})
export class PromoTurnoverDetailsDialogComponent implements OnInit, OnDestroy {


  private subscription = new Subscription();

  loading = true;
  promoTurnoverDetails : any = [];
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  params = "";
  currencyTimezone = CurrencyTimezone;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { row: any},
    private rebateReleaseDataService: RebateReleaseDataService,
    public dialogRef: MatDialogRef<PromoTurnoverDetailsDialogComponent>,
    public dialog: MatDialog,
    private memberDataService: MemberDataService,
    private transactionHttpService: TransactionHttpService
  ) { }

  ngOnInit() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    const data = {
      member_account_id: this.data.row['member_account_id'],
      game_provider_id: this.data.row['game_provider_id'],
      start: moment(this.data.row['start_datetime']).utc().format('YYYY-MM-DD HH:mm:ss'),
      end: moment(this.data.row['end_datetime']).utc().format('YYYY-MM-DD HH:mm:ss'),
      paginate: false
    };
    this.params = Object.keys(data).map((key) => key + "=" + data[key]).join("&");

    this.rebateReleaseDataService.getmemberpromotionlist(this.params).subscribe(val => {
      this.promoTurnoverDetails = val;
      this.loading = false;
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onOpenDialog(type: string, id?: number, row?: any) {
    if (type === 'member-information') {
      this.subscription = this.memberDataService.getById(id, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format("YYYY-MM-DD HH:mm:ss")}&additional_info=1`).pipe(
        tap((res) => {
          if (res) {
            this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' }, '1500px');
          }
        })
      ).subscribe();
    }else{
      this.openDialogBy(TargetAmountDetailsDialogComponent, {row:row , details:this.data.row}, '1500px');
    }
  }
  
  private openDialogBy(componentRef: any, data?: any, options?: any) {
    const dialogRef = this.dialog.open(componentRef, {
      width: options.width,
      panelClass: options.class ? options.class : '',
      data
    });

  }

 
}
