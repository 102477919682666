import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Transfer } from '@core/models/transfer.model';

@Injectable()
export class TransferEntityService extends EntityCollectionServiceBase<Transfer> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('Transfer', serviceElementsFactory);
  }
}
