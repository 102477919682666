import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RewardsHttpService } from '@core/services/rewards-http.service';
import { Member } from '@core/models/member.model';
import { Subscription, Subject, Observable } from 'rxjs';
import { map, filter, tap, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { RewardStatus } from '@core/enums/reward.enum';
import { TranslateService } from '@ngx-translate/core';
import { CampaignHttpService } from '@core/services/campaign-http.service';
import { AffiliateCampaignHttpService } from '@core/services/affiliate-campaign-http.service';

interface Campaign {
  id: number;
  name: string;
  code: string;
}

@Component({
  selector: 'kt-member-rewards',
  templateUrl: './member-rewards.component.html',
  styleUrls: ['./member-rewards.component.scss']
})
export class MemberRewardsDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  subscription = new Subscription();
  messages$ = this.rewardsHttpService.messages$;
  data$ = this.rewardsHttpService.data$;
  pageSize = 10;
  page = 1;
  params = `?status=1&paginate=false`;
  selectedItems = [];
  rewardsDropdown = [];
  rewardsDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    autoPosition: false,
    classes: 'dropdown',
    maxHeight: '150',
    primaryKey: 'id',
    labelKey: 'name',
    showCheckbox: false
  };
  selectedRewards: string;
  dropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'id',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false
  };
  refreshStatus: boolean;
  buttonLoading = false;
  checkValidation = false;
  showReadOnlyReward = false;
  selectedCampaign: any;
  dropdown = {
    currencies: this.dropdownHttpService.currencies,
    campaign: [],
    campaignType: [
      { id: 1, name: 'Normal' },
      { id: 2, name: 'Affiliate' },
    ]
  };
  status = RewardStatus;
  promo_currency_bonus_type: number;
  currencyId: number = -1;
  isFilterByCurrency: boolean = true;
  currencyCode: string;
  showBonusAmount: boolean = true;
  currency: number;
  userData = JSON.parse(localStorage.getItem('user_data'));
  boUserId = JSON.parse(localStorage.getItem('user_data')).id;
  telemarketerId = JSON.parse(localStorage.getItem('user_data')).application_role_name?.toLowerCase() === 'telemarketer' ? [this.boUserId] : '';
  normalAccountManagerId = this.userData.role_name.toLowerCase() === 'account manager' ? [this.boUserId] : '';
  vipAccountManagerId = this.userData.role_name.toLowerCase() === 'vip account manager' ? [this.boUserId] : '';

  showCampaign = false;
  campaignList: any;
  affCampaignList: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { member: Member, mode: string, campaign: any },
    public dialogRef: MatDialogRef<MemberRewardsDialogComponent>,
    private rewardsHttpService: RewardsHttpService,
    private dropdownHttpService: DropdownHttpService,
    private translateService: TranslateService,
    private campaignHttpService: CampaignHttpService,
    private affiliateCampaignHttpService: AffiliateCampaignHttpService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    (document.querySelector('.mat-dialog-container') as HTMLElement).style.overflow = 'visible';

    //when 11.1.1 campaign member open assign reward dialog
    this.params = (this.data.mode === 'assignRewards_campaign' && this.data.campaign.promotion_code !== null)? 
                  this.params+'&code='+this.data.campaign.promotion_code+'&currency_id='+this.data.member['settings_currency_id']+'&promotion_id='+this.data.campaign.promotion_id: 
                  this.data.mode == 'member_reward_by_telemarketer' ? this.params+'&type=assign_reward&eligibility_type=4&currency_id='+this.data.member['settings_currency_id']+'&telemarketer_id='+this.boUserId :
                  this.data.mode == 'bulk_reward_by_telemarketer' ? JSON.parse(localStorage.getItem('user_data')).application_role_name?.toLowerCase() === 'telemarketer' ? this.params +'&eligibility_type=4'+'&telemarketer_id='+this.boUserId : this.params +'&eligibility_type=4' : this.params;   

    if (this.userData.role_name.toLowerCase() === 'account manager') {
      this.params = this.params +'&eligibility_type=4'+'&normal_account_manager_id='+this.boUserId
    }else if (this.userData.role_name.toLowerCase() === 'vip account manager') {
      this.params = this.params +'&eligibility_type=4'+'&vip_account_manager_id='+this.boUserId
    }

    if (this.data.mode === 'member_reward_by_account_management') {
      this.params = this.params + '&currency_id='+this.data.member['settings_currency_id'];
    }
    
    if(this.data.mode !== 'bulk' && this.data.mode !== 'bulk_reward_by_telemarketer'){
      this.rewardsHttpService.getRewardsList(this.params).subscribe(res => {
        this.rewardsDropdown = res;
        this.rewardsDropdown.map(function (elm) {
          elm['name'] = elm.code + ' - ' + elm.name;
        });
      
        if(this.data.mode === 'assignRewards_campaign' && this.data.campaign.promotion_code !== null){
          if(this.rewardsDropdown.length > 0){
            this.onSelectedItemChange(this.rewardsDropdown);
            this.form.get('promotion_id').setValue(+this.rewardsDropdown[0].id);
          }
        }
      });
    }

    this.formInit();

    if( this.data.mode == 'member_reward' ) {
      if( this.data.member.campaign_id === null && this.data.member.affiliate_campaign_id === null ) {
        this.showCampaign = true;
        this.getCampaignListing(this.data.member.settings_currency_id);
      }
    }

  }

  ngOnDestroy() {
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.resetData();
    this.dialogRef.close();
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  reassign(event?: Event) {
    this.resetData();

    if (this.data.mode === 'bulk' || this.data.mode === 'bulk_reward_by_telemarketer') {
      this.rewardsHttpService.batchAssign(this.form.getRawValue()).pipe(
        tap((res: any) => {
          this.rewardsHttpService.messages$.next(res.message);
          this.buttonLoading = false;
          this.checkValidation = false;
          this.refreshStatus = true;
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.checkValidation = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else if(this.data.mode === 'member_reward' || this.data.mode === 'member_reward_by_telemarketer' || this.data.mode === 'member_reward_by_account_management') {
      this.rewardsHttpService.assign({...this.form.getRawValue()}).pipe(
        tap((res: any) => {
          this.rewardsHttpService.messages$.next(res.message);
          this.buttonLoading = false;
          this.checkValidation = false;
          this.refreshStatus = true;
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.checkValidation = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else {
      this.rewardsHttpService.assign({...this.form.getRawValue(), type: 'campaign', campaign_id: +this.data.campaign.id}).pipe(
        tap((res: any) => {
          this.rewardsHttpService.messages$.next(res.message);
          this.buttonLoading = false;
          this.checkValidation = false;
          this.refreshStatus = true;
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.checkValidation = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
  }

  onSave() {
    this.checkValidation = true;
    if (this.form.valid) {
      this.buttonLoading = true;

      //when status active and auto approve == 0
      if(this.form.value.status == 0 && (this.selectedRewards && this.selectedRewards['auto_approve'] == 0)){

        Swal.fire({
          title: this.translateService.instant('Are you sure?'),
          text: this.translateService.instant('Do you want to create this rewards as status Active'),
          icon: 'info',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          showCancelButton: true,
          confirmButtonText: this.translateService.instant('Yes'),
          cancelButtonText: this.translateService.instant('No'),
          reverseButtons: true
        }).then((resp) => {

          // when close close the popup
          if(resp.dismiss === Swal.DismissReason.backdrop){
            this.buttonLoading = false;
            return;
          } else if(resp.dismiss == Swal.DismissReason.cancel){ //when click button NO
            this.form.get('status').setValue(4);// change status to pending approval
            this.onCreateReward();

          } else if(resp.value){ //when click button YES
            this.onCreateReward();
          }
        });
      } else{
        this.onCreateReward();
      }
    }
  }

  onSelectedItemChange(item) {
    this.selectedRewards = item[0];
    this.onShowBonusAmount();
    this.putInactiveStatus();
  }

  onSelectedMemberChanged(event:any){
    //reset campaign_id & promotion_id
    this.form.get('campaign_id').setValue(null);
    this.form.get('promotion_id').setValue(null);
    this.showCampaign = false;

    if(event.length > 0){
      if( this.data.mode === 'bulk' ) {
        this.showCampaign = true;
      }
      for (const member of event) {
        if( this.data.mode === 'bulk' && (member.campaign_id !== null || member.affiliate_campaign_id !== null) ) {
          this.showCampaign = false;
        }
        if(member.campaign_id !== null){
          this.showReadOnlyReward = false
          return;
        } else{
          this.showReadOnlyReward = true;
        }
        
      }
    }
  }

  onSelectedCampaignChanged(event:any){
    let selected_campaign_id = event.item.id;

    //find the campaign
    if( this.form.value.campaign_type == 1 ) {
      this.selectedCampaign = this.campaignList.find((item) => {
        return item.id === +selected_campaign_id;
      });
    } else {
      this.selectedCampaign = this.affCampaignList.find((item) => {
        return item.id === +selected_campaign_id;
      });
    }

    if(this.selectedCampaign !== undefined){
      const rewardData = this.rewardsDropdown.find((reward) => {
                          return reward.code === this.selectedCampaign.promotion_code;
                        });
      // set the form data
      if(rewardData !== undefined){
        this.onSelectedItemChange([rewardData]);
        this.form.get('promotion_id').setValue(+rewardData.id);
      } else{
        this.onSelectedItemChange([null]);
        this.form.get('promotion_id').setValue(null);
      }                            
    } else {
      this.form.get('campaign_id').setValue(null);
    }
  }

  onCreateReward(){
    if (this.data.mode === 'bulk' || this.data.mode === 'bulk_reward_by_telemarketer') {
      
      const formValue = this.form.value;
      if (formValue.campaign) {
        if (formValue.campaign.code != undefined && formValue.campaign.name != undefined) {
          formValue.campaign_id = formValue.campaign.id;
          delete formValue.campaign;
        }
      }

      this.rewardsHttpService.batchAssign({
        ...formValue,
          
        // To pop up a dialog if the member is not eligible for the promo
        check_eligibility: this.data.mode == 'bulk_reward_by_telemarketer' ? false : true
      }).pipe(
        tap((res: any) => {
          this.rewardsHttpService.messages$.next(res.message);
          this.buttonLoading = false;
          this.checkValidation = false;
          this.refreshStatus = true;
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.checkValidation = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else {
      Swal.fire({
        text: 'Are you sure you want to assign ' + this.selectedRewards['name'] + ' to ' + this.data.member.username + ' ?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Yes',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {

          //prepare json data
          let formValue;
          if(this.data.mode === 'assignRewards_campaign'){
            formValue = {...this.form.getRawValue(), type: 'campaign', campaign_id: +this.data.campaign.id};
          } else{
            formValue = {...this.form.getRawValue()};
          }

          if (formValue.campaign) {
            if (formValue.campaign.code != undefined && formValue.campaign.name != undefined) {
              formValue.campaign_id = formValue.campaign.id;
              delete formValue.campaign;
            }
          }

          this.rewardsHttpService.assign({
            ...formValue,

            // To pop up a dialog if the member is not eligible for the promo
            check_eligibility: this.data.mode == 'bulk_reward_by_telemarketer' ? false : true
          }).pipe(
            tap((res: any) => {
              this.rewardsHttpService.messages$.next(res.message);
              this.buttonLoading = false;
              this.checkValidation = false;
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.checkValidation = false;
              this.form.setErrors(null);
              throw error;
            })
          ).subscribe();
        } else {
          this.checkValidation = false;
          this.buttonLoading = false;
        }
      });
    }
  }

  private formInit() {
    const memberAccountId = this.data.mode === 'bulk' || this.data.mode === 'bulk_reward_by_telemarketer' ? null : this.data.member.id;
    this.form = new FormGroup({
      member_account_id: new FormControl(memberAccountId, [Validators.required]),
      promotion_id: new FormControl(null, [Validators.required]),
      bonus_amount: new FormControl(null, [Validators.pattern('^-?(?=.*[1-9])[0-9]+(\.[0-9]+)?$'), Validators.min(0)]),
      status: new FormControl(this.data.mode === 'member_reward_by_telemarketer' 
                              || this.data.mode === 'bulk_reward_by_telemarketer' 
                              || this.data.mode === 'member_reward_by_account_management' ? 0 : null, [Validators.required]),
      campaign_id: new FormControl(null),
      campaign: new FormControl({value: null, disabled: false}),
      remarks: new FormControl(null),
      currency_id: new FormControl('all', this.data.mode === 'bulk' || this.data.mode === 'bulk_reward_by_telemarketer'? [Validators.required]: []),
      campaign_type: new FormControl(1),
    });
  }

  resetData() {
    this.rewardsHttpService.data$.next(null);
  }

  onBonusAmountChange(){
    this.putInactiveStatus();
  }

  onChangeCurrency(currency_id:number){
    this.form.patchValue({
      member_account_id: this.data.mode === 'bulk' || this.data.mode === 'bulk_reward_by_telemarketer' ? null : this.data.member.id,
      promotion_id: null,
      bonus_amount: null,
      status: this.data.mode === 'member_reward_by_telemarketer' || this.data.mode === 'bulk_reward_by_telemarketer' ? 0 : null,
      campaign_id: null,
      remarks: null,
    });
    this.showReadOnlyReward = false;
    this.selectedCampaign = undefined;
    this.currency = currency_id;
    this.form.get('status').enable();
    this.dropdown.currencies.subscribe(currencies => {
      const selectedCurrency = currencies.find(value => {
        return value.id === +currency_id
      });
      this.currencyCode = selectedCurrency.name;
    });

    //member list settings
    this.currencyId = currency_id;
    this.isFilterByCurrency = true;
 
    this.rewardsHttpService.getRewardsList(this.params+'&currency_id='+this.currencyId).subscribe(res => {
      this.rewardsDropdown = res;
      this.rewardsDropdown.map(function (elm) {
        elm['name'] = elm.code + ' - ' + elm.name;
      });
    });

    // retrieve campaign & affiliate campaign listing by currency
    this.getCampaignListing(currency_id);
  }

  private putInactiveStatus() {

    if(this.selectedRewards !== undefined && this.selectedRewards !== null){
      const currency_code = (this.data.mode === 'bulk' || this.data.mode === 'bulk_reward_by_telemarketer')? this.currencyCode: this.data.member.currency_code;
      const promo_currency = this.selectedRewards['currencies_bonus_type'].filter(item => item.name == currency_code);

      this.promo_currency_bonus_type = promo_currency[0] !== undefined? promo_currency[0].bonus_type: null;
      if(+this.selectedRewards['promo_type'] === 2 && +this.promo_currency_bonus_type === 2 && (this.form.value.bonus_amount == null || this.form.value.bonus_amount == undefined || +this.form.value.bonus_amount <= 0)){
        this.form.get('status').setValue(5);
        this.form.get('status').disable();
      }else{
        if (this.data.mode === 'member_reward_by_telemarketer' || this.data.mode === 'bulk_reward_by_telemarketer' || this.data.mode === 'member_reward_by_account_management') {
          // Always set to active for telemarketer assign rewards
          this.form.get('status').setValue(0);
          this.form.get('status').disable();
        } else {
          this.form.get('status').setValue(null);
          this.form.get('status').enable();
        }
      }
    }

  }

  onShowBonusAmount() {
    if(this.selectedRewards !== undefined && this.selectedRewards !== null) {
      if(this.selectedRewards['promo_type'] === 4) {
        this.showBonusAmount = false;
        this.form.patchValue({
          bonus_amount: null,
        });
      }
      else {
        this.showBonusAmount = true;
      }
    } else {
      this.showBonusAmount = true;
    }
  }

  onSelectCampaignType(type_id: number) {
    this.form.patchValue({
      campaign_id: null,
      campaign: null,
      promotion_id: null,
    });
    this.selectedCampaign = undefined;
    this.onSelectedItemChange([null]);

    if( type_id == 1 ) {
      this.dropdown.campaign = this.campaignList;
    } else {
      this.dropdown.campaign = this.affCampaignList;
    }
    this.cdr.detectChanges();
  }

  getCampaignListing(currency_id) {
    if( this.data.mode !== 'bulk' && this.data.mode !== 'member_reward' ) {
      return;
    }

    // get campaigns
    this.campaignHttpService.getCampaignByCurrency(currency_id).subscribe(res => {
      this.campaignList = res;
      if( this.form.value.campaign_type == 1 ) {
        this.dropdown.campaign = this.campaignList;
      }
      this.cdr.detectChanges();
    });

    // get affiliate campaigns
    this.affiliateCampaignHttpService.getAffiliateCampaignByCurrency(currency_id).subscribe(res => {
      this.affCampaignList = res;
      if( this.form.value.campaign_type == 2 ) {
        this.dropdown.campaign = this.affCampaignList;
      }
      this.cdr.detectChanges();
    });
  }

  searchCampaign = (search$: Observable<string>): Observable<Campaign[]> =>{
    return search$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(term => term.trim() === '' ? [] : this.filterCampaign(term))
    )
  }

  formatCampaign(campaign: Campaign): string {
    return `${campaign.code} - ${campaign.name}`;
  }

  filterCampaign(term: string): Campaign[] {
    if (this.dropdown.campaign) {
      return this.dropdown.campaign.filter(
        campaign => campaign.name.toLowerCase().includes(term.toLowerCase()) || campaign.name.toLowerCase().includes(term.toLowerCase())
      );
    } else {
      return [];
    }
  }

}
