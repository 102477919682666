import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { RebateRelease } from '@core/models/rebate-release-model';

@Injectable()
export class RebateReleaseEntityService extends EntityCollectionServiceBase<RebateRelease>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('RebateRelease', serviceElementsFactory);
  }
}
