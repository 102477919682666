import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
    selector: '[AppTwoDigitDecimal]'
})
export class TwoDigitDecimalDirective {

    private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
    private specialKeys: Array<string> = ['Tab', 'Backspace', 'Del', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.includes(event.key)) {
            return true;
        } else {
            let current: string = this.el.nativeElement.value;
            const position = this.el.nativeElement.selectionStart;
            const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
            return next.match(this.regex) ? true : false;
        }
    }
}