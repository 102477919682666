import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { AllLeads } from '@core/models/all-leads.model';

@Injectable()
export class AllLeadsEntityService extends EntityCollectionServiceBase<AllLeads> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('AllLeads', serviceElementsFactory);
    }
}

