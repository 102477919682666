import { AllSubAffiliateReport } from '@core/models/all-sub-affiliate-report.model';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class AllSubAffiliateReportEntityService extends EntityCollectionServiceBase<AllSubAffiliateReport>  {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('AllSubAffiliateReport', serviceElementsFactory);
  }
}
