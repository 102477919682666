import { Faq } from '@core/models/faq.model';
import { Observable, Subject } from 'rxjs';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';

@Injectable()
export class FaqDataService extends DefaultDataService<Faq> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Faq', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<Faq[]> {
    return this.http.get<ApiResponse>('/faq').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<Faq[]> {
    return this.http.get<ApiResponse>(`/faq${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data)
    )
  }

  getById(id: number): Observable<Faq> {
    return this.http.get<ApiResponse>(`/faq/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  addContent(faq: Faq) {
    return this.http.post<ApiResponse>(`/faq/contents`, faq).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  updateFaqContent(id: number, faq: any) {
    return this.http.put<ApiResponse>(`/faq/contents/${id}`, faq).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getFaqSection() {
    return this.http.get<ApiResponse>('/faqsections').pipe(
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.faq_contents.paginations;
    }
  }

  updateStatus(param: any) {
    return this.http.put<ApiResponse>(`/faq/statusupdate`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  duplicate(param: any) {
    return this.http.post<ApiResponse>(`/faq/duplicate`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

}
