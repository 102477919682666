import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'member-verification-wrapper',
  templateUrl: './member-verification-wrapper.component.html',
  styleUrls: ['./member-verification-wrapper.component.scss'],
})
export class MemberVerificationWrapperComponent implements OnInit {

  totalBanking = 0;

  // permissions
  canViewWalletTab: boolean;
  canViewAccountTab: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private eventEmitterService: EventEmitterService,
    private appPermissionService: AppPermissionService,
  ) {
    
  }

  ngOnInit() {
    // this.eventEmitterService.updateDepositTabBadge.subscribe((res) => {
    //   if( res.isTypeCrypto === false ) {
    //     this.totalBanking = res.total;
    //   }
    // });

    // const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
    //   this.canViewBankingTab = appPermissions.view_banking_deposits;
    //   this.canViewCryptoTab = appPermissions.view_crypto_deposits;

    //   this.cdr.detectChanges();
    // });

    this.canViewWalletTab = true;
    this.canViewAccountTab = true;
  }
}